import {Button, styled} from "@mui/material";
import theme from "theme/theme";
import {ButtonProps} from "types/ButtonProps";
import {enActivityFeedButton} from "constants/index";

function ResendButton(props: ButtonProps) {
  const {onClickFcn, id="resend-button"} = props;
  return (
    <ResendButtonStyle onClick={onClickFcn} id={id}>
      {enActivityFeedButton.resend}
    </ResendButtonStyle>
  )
}

const ResendButtonStyle = styled(Button)({
  height: "32px",
  backgroundColor: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.background.paper,
  width: "106px",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark
  }
});

export default ResendButton;