import React from "react";
import {Stack, Typography, Button} from "@mui/material";
import DatePicker from "components/DatePicker";
import {enCommonButton, enCommonLabel} from "constants/index";

interface DatePanelProps {
  selectedDateFilter: Date | null;
  setSelected: (obj: Date | null) => void;
}

function DatePanel(props: DatePanelProps) {
  const {selectedDateFilter, setSelected} = props;

  return (
    <Stack
      sx={{display: "flex", mt: 1, mb: 1}}
      direction="column"
      alignContent="center">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Typography
          variant="h4"
          sx={{fontSize: "14px"}}>
          {enCommonLabel.byDate}
        </Typography>
        <Button
          id="filter-date-clear-button"
          disabled={selectedDateFilter === null}
          onClick={() => setSelected(null)}>
          {enCommonButton.clear}
        </Button>
      </Stack>
      <DatePicker
        selectedDate={selectedDateFilter}
        setSelectedDate={(date: Date | null) => setSelected(date)}
        disablePreviousDate={false}
      />
    </Stack>
  );
}


export default DatePanel;