import {Entity} from "enums/entity";
import {SidebarIcons} from "../icons/menu/menu.index";
import {SidebarIcons as MenuIcons} from "../../assets/icons";
import {SystemIcons} from "assets/icons/system/system.index";
import {
  enActivityFeedLabel,
  enAssetLabel,
  enChatLabel, enCommonLabel, enFileRequirementLabel,
  enFormsLabel, enInviteLabel, enMemberLabel,
  enOrganizationLabel,
  enOrgDataTagsLabel, enProjectLabel, enReferencesLabel, enTaskLabel,
  enTeamsAndPeopleLabel, enTemplateLabel, enUserLabel
} from "constants/index";
import {en} from "language/en";

export type EmptyItem = {
  title: string;
  description: string;
  icon: any;
};

export type EmptyPageParams = Record<Entity, EmptyItem>;

/**
 * Returns title, description and icon to be displayed in an empty page for the defined entities (enum)
 */
export const EmptyPage: EmptyPageParams = {
  [Entity.Organization]: {
    title: enOrganizationLabel.emptyTitle,
    description: enOrganizationLabel.emptyDescription,
    icon: SidebarIcons.Projects,
  },
  [Entity.Project]: {
    title: enProjectLabel.emptyTitle,
    description: enProjectLabel.emptyDescription,
    icon: SidebarIcons.Projects,
  },
  [Entity.Asset]: {
    title: enAssetLabel.emptyTitle,
    description: enAssetLabel.emptyDescription,
    icon: SidebarIcons.Assets,
  },
  [Entity.OrganizationAsset]: {
    title: enAssetLabel.emptyTitle,
    description: enAssetLabel.emptyDescription,
    icon: SidebarIcons.Assets,
  },
  [Entity.PLTask]: {
    title: enTaskLabel.emptyTitle,
    description: enTaskLabel.emptyDescription,
    icon: SystemIcons.Tasks,
  },
  [Entity.Task]: {
    title: enTaskLabel.emptyTitle,
    description: enTaskLabel.emptyDescription,
    icon: SystemIcons.Tasks,
  },
  [Entity.FileRequirement]: {
    title: enProjectLabel.emptyTitle,
    description: enProjectLabel.emptyDescription,
    icon: SidebarIcons.Projects,
  },
  [Entity.PLFileRequirement]: {
    title: enProjectLabel.emptyTitle,
    description: enProjectLabel.emptyDescription,
    icon: SidebarIcons.Projects,
  },
  [Entity.OrganizationTemplateFileRequirement]: {
    title: enProjectLabel.emptyTitle,
    description: enProjectLabel.emptyDescription,
    icon: SidebarIcons.Projects,
  },
  [Entity.File]: {
    title: enFileRequirementLabel.emptyTitle,
    description: enFileRequirementLabel.emptyDescription,
    icon: SidebarIcons.Projects,
  },
  [Entity.ReferenceFiles]: {
    title: enReferencesLabel.emptyTitle,
    description: enReferencesLabel.emptyDescription,
    icon: SidebarIcons.File,
  },
  [Entity.FormRequirement]: {
    title: enProjectLabel.emptyTitle,
    description: enProjectLabel.emptyDescription,
    icon: SidebarIcons.Projects,
  },
  [Entity.PLFormRequirement]: {
    title: enProjectLabel.emptyTitle,
    description: enProjectLabel.emptyDescription,
    icon: SidebarIcons.Projects,
  },
  [Entity.OrganizationTemplateFormRequirement]: {
    title: enProjectLabel.emptyTitle,
    description: enProjectLabel.emptyDescription,
    icon: SidebarIcons.Projects,
  },
  [Entity.Form]: {
    title: enFormsLabel.emptyTitle,
    description: enFormsLabel.emptyDescription,
    icon: SidebarIcons.AddFile,
  },
  [Entity.User]: {
    title: enUserLabel.emptyTitle,
    description: enUserLabel.emptyDescription,
    icon: SidebarIcons.User,
  },
  [Entity.Invite]: {
    title: enInviteLabel.emptyTitle,
    description: enInviteLabel.emptyDescription,
    icon: SystemIcons.Send,
  },
  [Entity.OrganizationInvite]: {
    title: enInviteLabel.emptyTitle,
    description: enInviteLabel.emptyDescription,
    icon: SystemIcons.Send,
  },
  [Entity.UserInvite]: {
    title: enInviteLabel.emptyTitle,
    description: enInviteLabel.emptyDescription,
    icon: SystemIcons.Send,
  },
  [Entity.AccessList]: {
    title: enProjectLabel.emptyTitle,
    description: enProjectLabel.emptyDescription,
    icon: SidebarIcons.Projects,
  },
  [Entity.Member]: {
    title: enMemberLabel.emptyTitle,
    description: enMemberLabel.emptyDescription,
    icon: MenuIcons.User,
  },
  [Entity.Teams]: {
    title: en.screen.Team.label.emptyTitle,
    description: en.screen.Team.label.emptyDescription,
    icon: MenuIcons.Teams,
  },
  [Entity.TeamsAndPeople]: {
    title: enInviteLabel.emptyTitle,
    description: enTeamsAndPeopleLabel.emptyInviteDescription,
    icon: SystemIcons.Send,
  },
  [Entity.ActivityFeed]: {
    title: enActivityFeedLabel.emptyTitle,
    description: enActivityFeedLabel.emptyActivityFeedDescription,
    icon: SystemIcons.Notifications,
  },
  [Entity.ManageTeamCategories]: {
    title: enTeamsAndPeopleLabel.emptyTitle,
    description: en.screen.ManageTeamCategories.label.emptyListDescription,
    icon: SystemIcons.Teams,
  },
  [Entity.Chat]: {
    title: enChatLabel.emptyTitle,
    description: enChatLabel.emptyTitleDescription,
    icon: SystemIcons.ChatFilled,
  },
  [Entity.EntityChat]: {
    title: enChatLabel.emptyTitle,
    description: enChatLabel.emptyTitleDescription,
    icon: SystemIcons.ChatFilled,
  },
  [Entity.Milestone]: { // FOR DUMMY
    title: enChatLabel.emptyTitle,
    description: enChatLabel.emptyTitleDescription,
    icon: SystemIcons.ChatFilled,
  },
  [Entity.ProjectLevelTask]: {
    title: enProjectLabel.emptyTitle,
    description: enProjectLabel.emptyDescription,
    icon: SidebarIcons.Projects,
  },
  [Entity.Templates]: {
    title: enTemplateLabel.emptyTitle,
    description: enTemplateLabel.emptyDescription,
    icon: MenuIcons.Templates,
  },
  [Entity.OrganizationTemplate]: {
    title: enTemplateLabel.emptyTitle,
    description: enTemplateLabel.emptyDescription,
    icon: MenuIcons.Templates,
  },
  [Entity.OrganizationTemplateMilestone]: {
    title: enTemplateLabel.emptyTitle,
    description: enTemplateLabel.emptyDescription,
    icon: MenuIcons.Templates,
  },
  [Entity.OrganizationTemplateTask]: {
    title: enTemplateLabel.emptyTitle,
    description: enTemplateLabel.emptyDescription,
    icon: MenuIcons.Templates,
  },
  [Entity.TimeLog]: {
    title: en.screen.TimeLog.emptyListTitle,
    description: en.screen.TimeLog.emptyListDescription,
    icon: MenuIcons.LightClock
  },
  [Entity.DataTag]: {
    title: enOrgDataTagsLabel.emptyListTitle,
    description: enOrgDataTagsLabel.emptyListText,
    icon: SidebarIcons.DataTags
  },
  [Entity.Timers]: {
    title: en.screen.Sidebar.timers.emptyListDescription,
    description: en.screen.Sidebar.timers.emptyListDescription,
    icon: SystemIcons.DownloadTimeData
  },
  [Entity.TeamCategory]: {
    title: enTeamsAndPeopleLabel.emptyTitle,
    description: en.screen.ManageTeamCategories.label.emptyListDescription,
    icon: SystemIcons.Teams
  },
  [Entity.SwiftFile]: {
    title: enFileRequirementLabel.emptyTitle,
    description: enFileRequirementLabel.emptyDescription,
    icon: SidebarIcons.Projects,
  },
  [Entity.PLFile]: {
    title: enFileRequirementLabel.emptyTitle,
    description: enFileRequirementLabel.emptyDescription,
    icon: SidebarIcons.Projects,
  },
  [Entity.PLMilestone]: {
    title: enFileRequirementLabel.emptyTitle,
    description: enFileRequirementLabel.emptyDescription,
    icon: SidebarIcons.Projects,
  },
  [Entity.RolesAndPermissions]: {
    title: enFileRequirementLabel.emptyTitle,
    description: enFileRequirementLabel.emptyDescription,
    icon: SidebarIcons.Projects,
  },
  [Entity.Upload]: {
    title: enCommonLabel.emptyUploads,
    description: enCommonLabel.emptyUploadDescription,
    icon: SystemIcons.Upload,
  },
  [Entity.Export]: {
    title: enCommonLabel.emptyExport,
    description: enCommonLabel.emptyExportDescription,
    icon: SystemIcons.Export
  }
}

