import {Navigate, Outlet, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Entity} from "enums/entity";
import {
  collection as firebaseCollection,
  CollectionReference,
  getDocs,
  query,
  where
} from "firebase/firestore";
import {
  assetsPath,
  fileRequirementsPath,
  formRequirementsPath,
  organizationsPath,
  projectsPath,
  tasksPath,
  templatesPath
} from "../screens/utility";
import {SelectedOrg} from "types/SelectedOrg";
import {useAccess} from "hooks/index";
import {PermissionEntity, PermissionOperationKey} from "types/Permission";
import {SystemRole} from "enums/systemRole";
import {defaultSelectedOrg} from "constants/defaultSelectedOrg";
import useTemplateAccessList from "hooks/useTemplateAccessList";
import {db} from "../firebase";

const withAllowedUsersEntities = [
  Entity.Organization,
  Entity.Project,
  Entity.Asset,
  Entity.Task,
]

interface RestrictedPageProps {
  collection: Entity;
  selectedOrg: SelectedOrg;
  uid: string;
  isSuperUser: boolean;
  fromParentPermission?: boolean;
  documentDocId?: PermissionEntity;
}

export interface OutletContextType {
  isLoading: boolean;
}

/**
 * Checks if the user has access of the given document/collection
 * this is done by:
 * 1. checking if that document/collection exists in users' db
 * 2. checking if the user has access to that document/collection using useAccess hook
 */
function RestrictedPage(props: RestrictedPageProps) {
  const {collection, selectedOrg, uid, isSuperUser, documentDocId, fromParentPermission} = props;

  const {orgId, projId, assetId, milestoneId, taskId, fileRequirementId, formRequirementId, templateId} = useParams();

  const [withDBAccess, setWithDBAccess] = useState<boolean | null>(null);
  const [withPermissionAccess, setWithPermissionAccess] = useState<boolean | null>(null);

  const [id, setId] = useState<string>("");
  const [collectionRef, setCollectionRef] = useState<CollectionReference | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {accessList, updateColRef, accessListRefPath} = useTemplateAccessList(null);

  const [access, role, , updateAccessListRef] = useAccess({
    uid,
    entity: collection,
    documentDocId: PermissionEntity.Organization,
    ids: {projId},
    fromParentPermission,
  });

  useEffect(() => {
    if(!access || role === SystemRole.SystemUser || !documentDocId) return;
    if (!isLoading) return;

    setWithPermissionAccess(Boolean(access[PermissionOperationKey.View]));
  }, [access]);

  // set collection reference based on the passed parameter
  useEffect(() => {
    if (isSuperUser) {
      setIsLoading(false);
      setWithDBAccess(true);
      return;
    }

    const organizationId = orgId ?? selectedOrg.id;
    switch (collection) {
      case Entity.Organization:
        setId(orgId ?? selectedOrg.id);
        setCollectionRef(organizationsPath());
        break;
      case Entity.Project:
        setId(projId!);
        setCollectionRef(projectsPath(organizationId));
        break;
      case Entity.Asset:
        setId(assetId!);
        setCollectionRef(assetsPath(organizationId, projId!));
        break;
      case Entity.Task:
        setId(taskId!);
        setCollectionRef(tasksPath(organizationId, projId!, assetId!, milestoneId!, undefined))
        break;
      case Entity.FileRequirement:
        setId(fileRequirementId!);
        setCollectionRef(fileRequirementsPath(organizationId, projId!, assetId!, milestoneId!, taskId!))
        break;
      case Entity.FormRequirement:
        setId(formRequirementId!);
        setCollectionRef(formRequirementsPath(organizationId, projId!, assetId!, milestoneId!, taskId!))
        break;
      case Entity.Templates:
        setId(templateId!);
        setCollectionRef(templatesPath(organizationId));
        break;
    }
  }, [collection]);

  // if there is an update in the collectionRef, update the accessList col ref as well
  useEffect(() => {
    if (id === defaultSelectedOrg.id) return;
    if (!collectionRef) return;
    if (!isLoading) return;

    updateColRef(`${collectionRef.path}/${id}/accessList`);
  }, [collectionRef]);

  useEffect(() => {
    if (accessListRefPath === null) return;
    if (withPermissionAccess) return;
    if (!isLoading) return;

    updateAccessListRef(firebaseCollection(db, accessListRefPath));
  }, [accessListRefPath]);

  // id in the assigned collection
  useEffect(() => {
    if (collectionRef === null) return;

    if (!isLoading) return;

    if (id === defaultSelectedOrg.id && collection === Entity.Organization) {
      setWithDBAccess(false);
      return;
    }

    let constraints = [where("id", "==", id)];
    if (withAllowedUsersEntities.includes(collection))
      constraints = [...constraints, where("@allowedUsers", "array-contains", uid)];

    const queriedData = query(collectionRef, ...constraints);
    const exists = async () => {
      const snapshot = await getDocs(queriedData);
      let idExists = false;
      snapshot.forEach(() => {
        idExists = true;
        return;
      });

      setWithDBAccess(idExists);
    }

    exists();
  }, [collectionRef?.path]);

  useEffect(() => {
    if (!isLoading) return;

    if (access === null && typeof(documentDocId) !== "undefined") {
      setIsLoading(true);
      return;
    }
    if (withDBAccess === null && withPermissionAccess === null) {
      setIsLoading(true);
      return;
    }
    setIsLoading(false);
  }, [access, documentDocId, withDBAccess, withPermissionAccess]);

  if (id === defaultSelectedOrg.id) {
    return <Navigate to={`/`}/>
  }

  if (isLoading) return (
    <Outlet context={{isLoading}}/>
  );

  return (
    <>
      {
        Boolean(withDBAccess) || Boolean(withPermissionAccess)
          ? <Outlet context={{isLoading}}/>
          : <Navigate to={`/${selectedOrg.id}/no-permission`}/>
      }
    </>
  );
}

export default RestrictedPage;