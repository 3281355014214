export const task = {
  title: "Task",
  subtitle: "",
  description: "",
  status: {
    inProgress: "In Progress",
    pending: "Pending",
    submitted: "Submitted",
    rejected: "Rejected",
    hasRejection: "Has Rejection",
    cancelled: "Cancelled",
    approved: "Approved"
  },
  label: {
    cannotDeleteWithTemplate: "Task from template cannot be deleted",
    updateError: "Encountered an error while updating task/tasks",
    reqsRejected: "reqs rejected",
    reqsPending: "reqs pending",
    tasks: "Tasks",
    edit: "Edit Task",
    openInNewTab: "Open in New Tab",
    emptyTitle: "No Tasks yet",
    emptyRequirements: "No Requirements yet",
    emptyRequirementsDescription: "Create your first requirement.",
    emptyDescription: "No Tasks yet",
    emptyDescriptionTemplate: "Add new milestone before the task.",
    search: "Search Tasks...",
    showHiddenTasks: "Show Hidden Tasks",
    showHiddenReqs: "Show Hidden Reqs",
    new: "New Task",
    unassigned: "Unassigned",
    assignedTo: "Assigned To",
    assignee: "Assignee",
    you: "You",
    teams: "Teams",
    people: "People",
    searchAssignee: "Search Team or Person",
    editSuccess: "Task was edited successfully",
    createSuccess: "Task was created successfully",
    deleteSuccess: "Task was deleted successfully",
    deleteTaskAndMilestoneSuccess: "Task/Milestone was deleted successfully",
    deleteError: "Encountered an error while deleting this task",
    approver: "Approver",
    observer: "Observer",
    status: "Task Status",
    files: "Files",
    requirements: "Requirements",
    taskSchedule: "Task Schedule",
    lastChanged: "Last changed by",
    nameRequired: "Task Name is required",
    reason: "Reason for Change",
    reasonRequired: "Reason for change is required",
    updateSuccess: "Task was successfully updated",
    timeLog: "Time Log",
    manageAccess: "Manage Task Access",
    chat: "Task Chat",
    activityFeed: "Activity Feed",
    deleteConfirmationTitle: "Are you sure you want to delete this task?",
    deleteConfirmationText: "These item will be deleted immediately. You can't undo this action.",
    multipleDeleteConfirmationTitle: `Are you sure you want to delete selected tasks?`,
    multipleDeleteConfirmationText: `These item/s will be deleted immediately. You can't undo this action.`,
    rejectionReason: "Reason for Rejection",
    requiredRejectionReason: "Reason for Rejection is Required",
    allRequirements: "All Requirements",
    cancelledBy: (canceller: string) => `Cancelled by ${canceller}`,
    rejectedBy: (rejector: string) => `Rejected by ${rejector}`,
    dropFiles: "Drop files to instantly upload them.",
    alreadyApprovedError: "Cannot upload a file. The File Requirement has already been Approved.",
    maximumFilesError: "Cannot upload a file. Maximum file has been reached.",
    ongoingUploadProcess: "Please wait for the ongoing upload process to finish.",
    createNewFileRequirement: "Create New File Requirement",
    minimumFiles: "Minimum files",
    maximumFiles: "Maximum files",
    submittedSubtext: "Please wait for an approval",
    allFilesApproved: "All files are approved",
    noDescription: "No description",
    tasksStatisticsCounter: "Approved Tasks / Total Tasks",
    requirementsStatisticsCounter: "Approved Requirements / Total Requirements",
    forms: "Forms",
    searchTeamsOrPeople: "Search Teams or People...",
    emptyRequirementTitle: (entity: string) => `No ${entity} yet`,
    createFirstRequirement: (entity: string) => `Create the first ${entity}`,
    newRequirement: (entity: string) => `New ${entity}`,
    requirementCount: (entity: string) =>  `${entity} Count`,
    deleteTask: "Delete Task",
    tooltip:{
      uploadedFilesCount: "Uploaded files",
      minimumFilesCount: "Minimum files"
    },
    startTimer: "Start Timer",
    notCaptured: "Not Captured",
    cannotDeleteTemplateTask: "Cannot delete template task",
    buildingTask: "Building Task...",
    unHideSuccess: "Task unhidden",
    hideSuccess: "Task hidden",
    referenceFile: "Reference File",
  },
  button: {
    create: "Create New Task",
    deleteTask: "Delete Task",
    downloadTimeData: "Download Time Data",
    downloadAllFiles: "Download All Files",
    downloadAllFilesWith: "Download All Files With...",
    newFileRequirement: "New File Requirement",
    newFormRequirement: "New Form Requirement"
  },
}
