import {useEffect, useState} from "react";
import isNotPlayableMedia from "../screens/utility/isNotPlayableMedia";
import {enCommonLabel} from "constants/index";

function useNotPlayableMedia(fileName: string | null | undefined): string {
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    if (!fileName) return;

    const isNotPlayable = isNotPlayableMedia(fileName);
    if (isNotPlayable) {
      setMessage(enCommonLabel.notPlayable);
      return;
    }

    setMessage("");
  }, [fileName]);

  return message;
}

export default useNotPlayableMedia;