import {Hidden, IconButton, Stack} from "@mui/material";
import React, {useState} from "react";
import {useNavigate, useParams} from "react-router";
import {DotIndicator, ExportDialog, PilledNavigationBar} from "components";
import {Asset} from "types/index";
import {SidebarIcons} from "assets/icons/menu/menu.index";
import {SystemIcons} from "assets/icons/system/system.index";
import {BaseProps} from "../BaseProps";
import {PillItemProps} from "components/PilledNavBar/PillItem";
import theme from "theme/theme";
import HeaderMenu from "./HeaderMenu";
import {enAssetLabel, enCommonButton, enMilestoneLabel} from "constants/index";
import ChatDrawer from "components/ChatDrawer";
import {CounterFields, Entity} from "enums/index";
import ActivityFeed from "components/ActivityFeed";
import {
  assetAccessListPath,
  assetChatMessagesPath,
  assetPath,
  assetsPath,
  getDocPath,
  projectAssetActivityFeedPath,
  projectAssetExportsPath,
} from "../utility";
import {useAccess, useComponentToggler, useRTDBDocField} from "hooks/index";
import {EditAssetDrawer, ManageAccessDrawer} from "components/Drawers";
import {ImportDialog} from "components/Dialogs";
import {ChatIconButton, SwiftIconButton} from "components/Button";
import {AccessType, PermissionEntity, PermissionOperationKey} from "types/Permission";
import useImportBuild from "hooks/useImportBuild";

interface AssetHeaderProps extends BaseProps {
  asset: Asset;
  assetDocPath: string;
  assetAccess: AccessType | null;
  downloadAccess: AccessType | null;
  projectAssetTemplateId: string | undefined | null;
}

function Header(props: AssetHeaderProps) {
  const {asset, assetAccess, downloadAccess, uid, toastProps, projectAssetTemplateId} = props;
  const {orgId, projId, assetId} = useParams();

  // user specific fields:
  const userDocPath = getDocPath(uid, props.assetDocPath);
  const hasNewChat = useRTDBDocField<boolean>(userDocPath, "hasNewChat") ?? false;
  const hasNewActivity = useRTDBDocField<boolean>(userDocPath, "hasNewActivity") ?? false;
  const chatEmpty = useRTDBDocField<boolean>(props.assetDocPath, "chatEmpty") ?? true;
  const filesCount = useRTDBDocField<number>(userDocPath, CounterFields.FilesCount) ?? 0;

  const [isActionsShowing, setIsActionsShowing] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isEditAssetDrawerShown, setIsEditAssetDrawerShown] = useState<boolean>(false);
  const [isChatDrawerOpen, setIsChatDrawerOpen] = useState<boolean>(false);
  const [isActivityFeedOpen, setIsActivityFeedOpen] = useState<boolean>(false);
  const [isImportDialogOpen, {open: openImportDialog, close: closeImportDialog}] = useComponentToggler(false);
  const [isExportDialogOpen, {open: openExportDialog, close: closeExportDialog}] = useComponentToggler(false);

  const {isProcessing} = useImportBuild({
    path: props.assetDocPath,
  });

  const [exportAccess] = useAccess({
    uid: uid!,
    entity: Entity.Asset,
    documentDocId: PermissionEntity.ProjectAssetExport,
  });

  const [importAccess] = useAccess({
    uid: uid!,
    entity: Entity.Asset,
    documentDocId: PermissionEntity.ProjectAssetImport,
  });

  const [isManageAccessDrawerOpen, {
    open: openManageAccessDrawer,
    close: closeManageAccessDrawer
  }] = useComponentToggler(false);

  function toggleEditAssetDrawer() {
    setIsEditAssetDrawerShown(!isEditAssetDrawerShown);
  }

  const nav = useNavigate();

  const pills: PillItemProps[] = [
    {
      id: "org-name",
      label: `${asset.orgName} / ${asset.projectName}`,
      icon: <SidebarIcons.Projects fill={theme.palette.neutral.dark} width={16} height={16}/>,
      onClickFcn: () => nav(`/${orgId}/projects/${projId}/overview`)
    },
    {
      id: "asset-name",
      label: asset.name,
      icon: <SidebarIcons.Assets width={16} height={16} color={theme.palette.neutral.dark}/>,
      onClickFcn: () => nav(`/${orgId}/projects/${projId}/assets/${assetId}`)
    }
  ];

  function toggleMenu(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    setAnchorEl(anchorEl === null ? e.currentTarget : null);
    setIsActionsShowing(!isActionsShowing);
  }

  function onChatDrawerClick() {
    setIsChatDrawerOpen(true);
  }

  function onImportClose() {
    closeImportDialog();
  }

  return (
    <>
      <ImportDialog
        isOpen={isImportDialogOpen}
        entity={Entity.Asset}
        toastProps={toastProps!}
        closeDialog={onImportClose}
        parentDocumentRef={assetPath(orgId!, projId!, assetId!)!}
        uid={uid}
      />
      <ExportDialog
        isOpen={isExportDialogOpen}
        onClose={closeExportDialog}
        toastProps={toastProps!}
        title={enAssetLabel.exportDialogTitle}
        exportingTitle={enAssetLabel.exportingDialogTitle}
        message={enAssetLabel.exportDialogText}
        exportColRef={projectAssetExportsPath(orgId!, projId!, assetId!)!}
      />
      <ActivityFeed
        uid={uid!}
        collectionReference={projectAssetActivityFeedPath(orgId!, projId!, assetId!)}
        isOpen={isActivityFeedOpen}
        onClose={() => setIsActivityFeedOpen(false)}
        entity={Entity.Asset}
      />
      <ChatDrawer
        isOpen={isChatDrawerOpen}
        entity={Entity.Asset}
        pathPair={[asset.projectName!, asset.name]}
        onClose={() => setIsChatDrawerOpen(false)}
        colRef={assetChatMessagesPath(orgId!, projId!, assetId!)}
        toastProps={toastProps!}
        uid={uid}
      />
      <ManageAccessDrawer
        uid={uid}
        isOpen={isManageAccessDrawerOpen}
        entity={Entity.Asset}
        documentId={projId!}
        collectionRef={assetAccessListPath(orgId!, projId!, assetId!)}
        onDrawerClose={closeManageAccessDrawer}
        toastProps={toastProps!}
      />
      {isEditAssetDrawerShown && <EditAssetDrawer
          asset={asset}
          isDrawerShown={isEditAssetDrawerShown}
          onDrawerClose={toggleEditAssetDrawer}
          toastProps={toastProps!}
          uid={uid}
          collectionReference={assetsPath(orgId!, projId!)}
      />}

      <PilledNavigationBar pills={pills}>
        <Stack direction="row" gap={0.5}>
          <Hidden mdDown>
            <SwiftIconButton
              onClickFcn={toggleEditAssetDrawer}
              disabled={Boolean(!assetAccess?.[PermissionOperationKey.Update])}
              title={enAssetLabel.edit}
              startIcon={SystemIcons.Edit}
            />
            <IconButton
              title={enAssetLabel.manageAssetAccess}
              onClick={openManageAccessDrawer}
            >
              <SystemIcons.Teams width={24} height={24}/>
            </IconButton>
            <IconButton title={enMilestoneLabel.activityFeed} onClick={() => setIsActivityFeedOpen(true)}>
              <DotIndicator isVisible={hasNewActivity}>
                <SystemIcons.Notifications width={24} height={24}/>
              </DotIndicator>
            </IconButton>
            <ChatIconButton
              id="asset-overview-header-chat-button"
              entity={Entity.Asset}
              withNewMessage={hasNewChat}
              chatEmpty={chatEmpty}
              onClickFcn={onChatDrawerClick}
            />
          </Hidden>
          <IconButton
            title={enCommonButton.more}
            onClick={toggleMenu}
          >
            <SystemIcons.MoreVertical stroke={theme.palette.text.secondary}/>
          </IconButton>
          {isActionsShowing && (
            <HeaderMenu
              toastProps={toastProps!}
              uid={uid!}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              closeMenu={toggleMenu}
              filesCount={filesCount}
              openImportDialog={openImportDialog}
              usesTemplate={!!projectAssetTemplateId}
              entity={Entity.Asset}
              openExportDialog={openExportDialog}
              canDownload={Boolean(downloadAccess?.[PermissionOperationKey.Create])}
              canImport={Boolean(importAccess?.[PermissionOperationKey.Create]) && !isProcessing}
              canExport={Boolean(exportAccess?.[PermissionOperationKey.Create])}
            />
          )}
        </Stack>
      </PilledNavigationBar>
    </>
  )
}

export default Header;
