import {Button, Stack, Typography} from "@mui/material";
import {enCommonButton, enFormsButton, enFormsLabel} from "constants/index";
import {BaseProps} from "../BaseProps";
import {LoadingButton} from "@mui/lab";
import {useParams} from "react-router-dom";

interface HeadProps extends BaseProps {
  onClear: () => void;
  onSubmit: () => void;
  isLoading: boolean;
  isCreateButtonEnabled: boolean;
}

export function Head(props: HeadProps) {
  const {isMobile, isCreateButtonEnabled, isLoading, onClear, onSubmit} = props;
  const {formId} = useParams();
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h1">
        {!!formId ? enFormsLabel.editForm : enFormsLabel.createForm}
      </Typography>
      <Stack direction="row" gap={1} sx={isMobile ? {flex: 0} : {}} alignItems="center">
        <Button variant="text" color="secondary" sx={{whiteSpace: "noWrap"}} onClick={onClear}>
          {enFormsButton.clearChanges}
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          sx={{maxHeight: 40}}
          onClick={onSubmit}
          disabled={!isCreateButtonEnabled}
          loading={isLoading}>
          {!!formId ? enCommonButton.save : enCommonButton.create}
        </LoadingButton>
      </Stack>
    </Stack>
  )
}