import {BaseProps} from "screens/BaseProps";
import {Asset, Milestone, Personnel, Project, Task} from "types/index";
import React, {useEffect, useMemo, useState} from "react";
import {useAccess, useComponentToggler} from "hooks/index";
import {collection, doc} from "firebase/firestore";
import {db} from "../../firebase";
import {InProgress} from "components/index";
import {
  areObjectsEqual,
  projectLevelTasksAccessListPath,
  sortObjectsBy,
  submitForm,
} from "screens/utility";
import MilestoneItem from "./MilestoneItem";
import {Box, Stack} from "@mui/material";
import PageHeader from "./PageHeader";
import {enMilestoneLabel, enTaskLabel} from "constants/index";
import {ActionType, Entity, Severity, ViewStatus} from "enums/index";
import {AccessType, PermissionEntity, PermissionOperationKey} from "types/Permission";
import {errorStatuses} from "constants/errorStatuses";
import EmptySearchResults from "components/EmptySearchResults";
import ConfirmDialog from "components/ConfirmDialog";
import {statusSubmitHandler} from "screens/utility/statusSubmitHandler";
import {useParams} from "react-router";
import useCollectionWithTemplate, {TemplateDataType} from "hooks/useCollectionWithTemplate";
import {FiltersState} from "screens/Home/TaskSearchAndFilters";
import {InitialFilterState} from "screens/Home/queryAlgolia";

interface MilestonesViewProps extends BaseProps {
  milestonesCollectionRef: string;
  parentDocPath: string;
  algoliaPath: string;
  parent: Asset | Project;
  parentEntity: Entity;
  parentAccess: AccessType | null;
  downloadAccess: AccessType | null;
  asset?: Asset | null;
  projectAssetTemplateId?: string | null;
}

function MilestonesView(props: MilestonesViewProps) {
  const {milestonesCollectionRef, toastProps, asset, projectAssetTemplateId} = props;
  const {orgId, projId} = useParams();

  const {data: milestonesDB} = useCollectionWithTemplate<Milestone>({colRef: collection(db, milestonesCollectionRef)});

  const [algoliaResult, setAlgoliaResult] = useState<Task[] | null>(null);
  const [milestones, setMilestones] = useState<TemplateDataType<Milestone>[]>([]);
  const [displayedTasks, setDisplayedTasks] = useState<{ taskId: string, milestoneId: string }[]>([]);

  const [expandedIds, setExpandedIds] = useState<Set<string>>(new Set());
  const [checkedTasks, setCheckedTasks] = useState<{ taskId: string, milestoneId: string, templateId?: string }[]>([]);
  const [isCheckboxShown, setIsCheckboxShown] = useState<boolean>(false);

  const [deleteStatus, setDeleteStatus] = useState<ViewStatus[]>([]);
  const [updateStatus, setUpdateStatus] = useState<ViewStatus[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedMilestone, setSelectedMilestone] = React.useState<Milestone | null>(null);
  const [isDeleteDialogOpen, {open: openDeleteDialog, close: closeDeleteDialog}] = useComponentToggler(false);

  const [filters, setFilters] = useState<FiltersState>(InitialFilterState);

  const [taskAccess] = useAccess({
    uid: props.uid!,
    entity: props.parentEntity,
    documentDocId: props.parentEntity === Entity.Project ? PermissionEntity.PLTask : PermissionEntity.Task,
    accessListRef: props.parentEntity === Entity.Project ? projectLevelTasksAccessListPath(orgId!, projId!) : undefined,
  });

  const [milestoneAccess] = useAccess({
    uid: props.uid!,
    entity: props.parentEntity,
    documentDocId: PermissionEntity.Milestone,
    accessListRef: props.parentEntity === Entity.Project ? projectLevelTasksAccessListPath(orgId!, projId!) : undefined,
  });

  // if there are changes in milestonesDB, set it to milestones
  useEffect(() => {
    // algoliaResult should be prioritized over milestonesDB
    if (algoliaResult !== null) return;

    // if milestonesDB is null, set milestones to empty array
    if (milestonesDB === null) {
      setMilestones([]);
      setDisplayedTasks([]);
      setCheckedTasks([]);
      return;
    }

    const newMilestonesDB = milestonesDB.map(milestone => {
      return {
        "@id": milestone["@id"]!,
        name: milestone.name,
        docColRef: milestone.docColRef,
        fromTemplate: milestone.fromTemplate

      } as TemplateDataType<Milestone>
    });

    const misc = newMilestonesDB.find(milestone => milestone["@id"] === "misc");
    const newMilestonesDBWithoutMisc = newMilestonesDB.filter(milestone => milestone["@id"] !== "misc");
    let sortedMilestonesDB = sortObjectsBy(newMilestonesDBWithoutMisc, "name");
    if (misc) sortedMilestonesDB.push(misc);

    setExpandedIds(new Set(sortedMilestonesDB.map(milestone => milestone["@id"]!)));
    if (areObjectsEqual(sortedMilestonesDB, milestones)) return;

    setMilestones(sortedMilestonesDB);
  }, [milestonesDB]);

  useEffect(() => {
    const taskWithNoTemplateId = checkedTasks.filter(task => task.templateId === undefined);

    // if zero, return
    if (deleteStatus.length === 0 || taskWithNoTemplateId.length === 0) return;

    // if not equal, return
    if (taskWithNoTemplateId.length > deleteStatus.length) return;

    // check if with error
    const hasError = deleteStatus.some(status => errorStatuses.includes(status));
    if (hasError) {
      showMessage(enTaskLabel.deleteError, Severity.Error);
      resetBulkStates();
      return;
    }

    showMessage(enTaskLabel.deleteSuccess, Severity.Success);
    resetBulkStates();
  }, [deleteStatus, checkedTasks]);

  useEffect(() => {
    // if zero, return
    if (updateStatus.length === 0 || checkedTasks.length === 0) return;

    // if not equal, return
    if (checkedTasks.length > updateStatus.length) return;

    // check if with error
    const hasError = updateStatus.some(status => errorStatuses.includes(status));
    if (hasError) {
      showMessage(enTaskLabel.updateError, Severity.Error);
      resetBulkStates();
      return;
    }

    showMessage(enTaskLabel.updateSuccess, Severity.Success);
    resetBulkStates();
  }, [updateStatus]);

  // if there are changes is algolia result
  useMemo(() => {
    if (algoliaResult === null) {
      if (milestonesDB === null) return;

      setDisplayedTasks([]);
      setCheckedTasks([]);

      const currentMilestones = milestones.map(milestone => milestone["@id"]);
      const lastMilestones = milestonesDB.map(milestone => milestone["@id"]);
      if (!areObjectsEqual(currentMilestones, lastMilestones)) {
        setMilestones(milestonesDB.map(milestone => {
          return {
            "@id": milestone["@id"]!,
            name: milestone.name,
            docColRef: milestone.docColRef,
            fromTemplate: milestone.fromTemplate
          } as TemplateDataType<Milestone>
        }));
      }
      return;
    }

    if (algoliaResult.length === 0) {
      setMilestones([]);
      setDisplayedTasks([]);
      setCheckedTasks([]);
      return;
    }

    // get distinct milestone ids
    const milestoneIds = Array.from(new Set(algoliaResult.map(task => task.milestoneId)));
    let newMilestones: TemplateDataType<Milestone>[] = [];
    milestoneIds.forEach(milestoneId => {
      const milestone = milestonesDB?.find(milestone => milestone["@id"] === milestoneId);
      if (milestone)
        newMilestones.push({
          "@id": milestone["@id"]!,
          name: milestone.name,
          docColRef: milestone.docColRef,
          fromTemplate: milestone.fromTemplate
        } as TemplateDataType<Milestone>)
    });
    const sortedMilestones = sortObjectsBy(newMilestones, "name");

    let tasks: Task[] = [];
    sortedMilestones.forEach(milestone => {
      const milestoneTasks = algoliaResult.filter(task => task.milestoneId === milestone["@id"]!);
      const sortedMilestoneTasks = sortObjectsBy(milestoneTasks, "name");
      tasks = [...tasks, ...sortedMilestoneTasks];
    });
    setMilestones(sortedMilestones);
    setDisplayedTasks(tasks.map(task => ({taskId: task["@id"]!, milestoneId: task.milestoneId!})));
  }, [algoliaResult]);

  function deleteSelected() {
    if (isLoading) return;

    if (checkedTasks.length === 0) return;

    setIsLoading(true);
    checkedTasks.forEach(item => {
      if (item.templateId) return;
      const docRef = doc(db, milestonesCollectionRef, item.milestoneId, "tasks", item.taskId);
      submitForm(
        docRef,
        ActionType.Delete,
        (status, isLastUpdate) => isLastUpdate && setDeleteStatus(prev => [...prev, status]),
      )
    })
  }

  async function deleteSelectedMilestone() {
    if (!selectedMilestone) return;

    setIsLoading(true);
    const milestoneDocRef = doc(db, milestonesCollectionRef, selectedMilestone["@id"]!);

    await submitForm(
      milestoneDocRef,
      ActionType.Delete,
      (status, data, isLastUpdate) => statusSubmitHandler({
        status,
        data,
        isLastUpdate,
        successCallback: () => {
          showMessage(enMilestoneLabel.deleteSuccess, Severity.Success);
          closeDeleteDialog();
        },
        errorCallback: () => {
          showMessage(enMilestoneLabel.deleteError, Severity.Error);
          closeDeleteDialog();
        }
      }),
    );
  }

  async function bulkAssignSelected(assignee: Personnel) {
    if (isLoading) return;

    if (checkedTasks.length === 0) return;

    setIsLoading(true);
    await Promise.all(
      Array.from(checkedTasks).map(async item => {
        const docRef = doc(db, milestonesCollectionRef, item.milestoneId!, "tasks", item.taskId);
        return await submitForm(
          docRef,
          ActionType.Update,
          (status, isLastUpdate) => isLastUpdate && setUpdateStatus(prev => [...prev, status]),
          {assignedTo: assignee}
        )
      })
    );
  }

  function showMessage(message: string, severity: Severity) {
    const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;
    setToastMessage(message);
    setToastSeverity(severity);
    setIsToastOpen(true);
  }

  function toggleExpandAll() {
    if (expandedIds.size === milestones.length) {
      setExpandedIds(new Set());
      setCheckedTasks([]);
      return;
    }

    const newExpandedIds = new Set(milestones.map(milestone => milestone["@id"]!));
    setExpandedIds(newExpandedIds);
  }

  function resetBulkStates() {
    setCheckedTasks([]);
    setDeleteStatus([]);
    setUpdateStatus([]);
    setIsCheckboxShown(false);
    closeDeleteDialog();
    setIsLoading(false);
  }

  function expandMilestone(milestoneId: string) {
    setExpandedIds(prev => {
      const newExpandedIds = new Set(prev);
      newExpandedIds.has(milestoneId) ? newExpandedIds.delete(milestoneId) : newExpandedIds.add(milestoneId);
      return newExpandedIds;
    });
  }

  function checkTask(taskId: string, milestoneId: string, templateId?: string) {
    setCheckedTasks(prev => {
      const newCheckedTasks = [...prev];
      const exists = newCheckedTasks.some(item => item.taskId === taskId);
      if (exists) {
        return newCheckedTasks.filter(item => item.taskId !== taskId);
      } else {
        newCheckedTasks.push({taskId, milestoneId, templateId});
        return newCheckedTasks;
      }
    });
  }

  return (
    <Stack direction="column" gap={1}>
      <PageHeader
        parent={props.parent}
        algoliaPath={props.algoliaPath}
        canCreateAccess={Boolean(taskAccess?.[PermissionOperationKey.Create])}
        isMilestonesEmpty={milestonesDB ? milestonesDB.length === 0: false}
        assetDocPath={props.parentDocPath}
        uid={props.uid!}
        isCollapseAll={expandedIds.size !== milestones.length}
        setIsCollapseAll={toggleExpandAll}
        setAlgoliaResults={setAlgoliaResult}
        toastProps={props.toastProps!}
        isCheckboxShown={isCheckboxShown}
        setIsCheckboxShown={setIsCheckboxShown}
        checkedTasks={checkedTasks}
        setSelectAllTasks={() => setCheckedTasks([])}
        deleteSelected={deleteSelected}
        bulkAssignSelected={bulkAssignSelected}
        parentAccess={props.parentAccess}
        parentEntity={props.parentEntity}
        downloadAccess={props.downloadAccess}
        milestonesCollectionRef={milestonesCollectionRef}
        canCreateMilestone={Boolean(milestoneAccess?.[PermissionOperationKey.Create])}
        filters={filters}
        setFilters={setFilters}
        projectAssetTemplateId={projectAssetTemplateId}
      />
      {milestones.length === 0 && algoliaResult !== null && (
        <Box marginY={5}>
          <EmptySearchResults entity={Entity.Task}/>
        </Box>
      )}
      {milestonesDB === null ? <InProgress/>: milestones.map(milestone => {
        return (
          <MilestoneItem
            asset={asset}
            key={milestone["@id"]!}
            milestone={milestone}
            milestonesCollectionRef={milestone.docColRef ?? null}
            isExpanded={expandedIds.has(milestone["@id"]!)}
            checkedTasks={checkedTasks}
            parentEntity={props.parentEntity}
            checkTask={checkTask}
            uid={props.uid!}
            toastProps={toastProps!}
            canCreateAccess={Boolean(taskAccess?.[PermissionOperationKey.Create])}
            isCheckboxShown={isCheckboxShown}
            displayedTasks={displayedTasks}
            expandMilestone={() => expandMilestone(milestone["@id"]!)}
            milestoneAccess={milestoneAccess}
            showDeleteDialog={(milestone: Milestone) => {
              setSelectedMilestone(milestone);
              openDeleteDialog()
            }}
            fromSearch={algoliaResult !== null}
            filterState={filters}
          />
        )
      })}
      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        title={enMilestoneLabel.deleteConfirmationTitle}
        text={enMilestoneLabel.deleteConfirmationText}
        handleClose={closeDeleteDialog}
        handleConfirm={deleteSelectedMilestone}
        id="bulk-delete-drawer"
      />
    </Stack>
  )
}

export default MilestonesView;