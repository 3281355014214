import {PermissionEntity, PermissionOperationKey} from "types/Permission";

/**
 * The list of entities and their operations that are displayed in the permissions table.
 */
export const ENTITY_OPERATIONS: Record<string, PermissionOperationKey[]> = {
  [PermissionEntity.Asset]: [
    PermissionOperationKey.View,
    PermissionOperationKey.Create,
    PermissionOperationKey.Update,
    PermissionOperationKey.ChangeStatus,
    PermissionOperationKey.OverrideStatus,
  ],
  [PermissionEntity.ProjectAssetAccessList]: [
    PermissionOperationKey.ManageOwner,
    PermissionOperationKey.ManageAdmin,
    PermissionOperationKey.ManagePowerUser,
    PermissionOperationKey.ManageUser,
    PermissionOperationKey.ManageGuest,
  ],
  [PermissionEntity.FileRequirementFilesDownload]: [
    PermissionOperationKey.Create,
  ],
  [PermissionEntity.ProjectAssetImport]: [
    PermissionOperationKey.Create,
  ],
  [PermissionEntity.ProjectAssetExport]: [
    PermissionOperationKey.Create,
  ],
  [PermissionEntity.AssetFilesDownload]: [
    PermissionOperationKey.Create,
  ],
  [PermissionEntity.FileRequirement]: [
    PermissionOperationKey.View,
    PermissionOperationKey.Create,
    PermissionOperationKey.Update,
    PermissionOperationKey.Delete,
    PermissionOperationKey.ChangeStatus,
    PermissionOperationKey.OverrideStatus,
  ],
  [PermissionEntity.Form]: [
    PermissionOperationKey.View,
    PermissionOperationKey.Create,
    PermissionOperationKey.Update,
    PermissionOperationKey.Delete,
  ],
  [PermissionEntity.FormExport]: [
    PermissionOperationKey.Create,
  ],
  [PermissionEntity.FormRequirement]: [
    PermissionOperationKey.View,
    PermissionOperationKey.Create,
    PermissionOperationKey.Update,
    PermissionOperationKey.Delete,
    PermissionOperationKey.ChangeStatus,
    PermissionOperationKey.OverrideStatus,
  ],
  [PermissionEntity.Milestone]: [
    PermissionOperationKey.View,
    PermissionOperationKey.Create,
    PermissionOperationKey.Update,
    PermissionOperationKey.Delete,
  ],
  [PermissionEntity.MilestoneFilesDownload]: [
    PermissionOperationKey.Create,
  ],
  [PermissionEntity.Organization]: [
    PermissionOperationKey.Update,
    PermissionOperationKey.Delete,
  ],
  [PermissionEntity.OrganizationAsset]: [
    PermissionOperationKey.View,
    PermissionOperationKey.Create,
    PermissionOperationKey.Update,
    PermissionOperationKey.Delete,
  ],
  [PermissionEntity.OrganizationMember]: [
    PermissionOperationKey.View,
    PermissionOperationKey.Create,
    PermissionOperationKey.Update,
    PermissionOperationKey.Delete,
  ],
  [PermissionEntity.OrganizationTemplate]: [
    PermissionOperationKey.View,
    PermissionOperationKey.Create,
    PermissionOperationKey.Update,
    PermissionOperationKey.Delete,
  ],
  [PermissionEntity.OrganizationTemplateImport]: [
    PermissionOperationKey.Create,
  ],
  [PermissionEntity.OrganizationTemplateExport]: [
    PermissionOperationKey.Create,
  ],
  [PermissionEntity.Project]: [
    PermissionOperationKey.View,
    PermissionOperationKey.Create,
    PermissionOperationKey.Update,
    PermissionOperationKey.Delete,
    PermissionOperationKey.ChangeStatus,
    PermissionOperationKey.OverrideStatus,
  ],
  [PermissionEntity.ProjectImport]: [
    PermissionOperationKey.Create,
  ],
  [PermissionEntity.ProjectExport]: [
    PermissionOperationKey.Create,
  ],
  [PermissionEntity.ProjectAccessList]: [
    PermissionOperationKey.ManageOwner,
    PermissionOperationKey.ManageAdmin,
    PermissionOperationKey.ManagePowerUser,
    PermissionOperationKey.ManageUser,
    PermissionOperationKey.ManageGuest,
  ],
  [PermissionEntity.ReferenceFile]: [
    PermissionOperationKey.Create,
    PermissionOperationKey.Update,
    PermissionOperationKey.Delete,
  ],
  [PermissionEntity.SwiftFile]: [
    PermissionOperationKey.View,
    PermissionOperationKey.Create,
    PermissionOperationKey.Delete,
    PermissionOperationKey.ChangeStatus,
    PermissionOperationKey.OverrideStatus,
  ],
  [PermissionEntity.Task]: [
    PermissionOperationKey.View,
    PermissionOperationKey.Create,
    PermissionOperationKey.Update,
    PermissionOperationKey.Delete,
    PermissionOperationKey.ChangeStatus,
    PermissionOperationKey.OverrideStatus,
  ],
  [PermissionEntity.TaskAccessList]: [
    PermissionOperationKey.ManageOwner,
    PermissionOperationKey.ManageAdmin,
    PermissionOperationKey.ManagePowerUser,
    PermissionOperationKey.ManageUser,
    PermissionOperationKey.ManageGuest,
  ],
  [PermissionEntity.TaskExport]: [
    PermissionOperationKey.Create,
  ],
  [PermissionEntity.TaskFilesDownload]: [
    PermissionOperationKey.Create,
  ],
  [PermissionEntity.Team]: [
    PermissionOperationKey.View,
    PermissionOperationKey.Create,
    PermissionOperationKey.Update,
    PermissionOperationKey.Delete,
  ],
  [PermissionEntity.TeamCategory]: [
    PermissionOperationKey.Create,
    PermissionOperationKey.Update,
    PermissionOperationKey.Delete,
  ],
}