import {Typography, TypographyProps} from "@mui/material";
import {ReactNode} from "react";

interface TimestampTypoProps extends TypographyProps {
  children: ReactNode
}

function TimestampTypo(props: TimestampTypoProps) {
  const {children, ...rest} = props;
  return <Typography variant="bodySmall" color="text.secondary" {...rest}>{children}</Typography>
}

export default TimestampTypo