export const home = {
  label: {
    removeTaskFailed: `Failed to remove task`,
    taskRemoved: "Task removed successfully",
    removeFromList: "Remove from List",
    home: "Home",
    AllTasks: "All Tasks",
    tasksAndTimers: "Tasks and Timers",
    tasks: "Tasks",
    TasksAssignedToMe: "Assigned to me",
    ImTheApprover: "I'm the approver",
    ImTheObserver: "I'm the observer",
    TasksCreatedByMe: "Created by me",
    FavoriteTasks: "Favorite Tasks",
    noTasksDesc: "This will show all tasks you are involved to from different organizations",
    noFavoriteTasksDesc: "This will show all favorite tasks from different organizations",
    noFavoriteTasksTitle: "No Favorite Tasks",
    exportAllTasksTitle: "Export All Tasks",
    exportFavoriteTasksTitle: "Export Favorite Tasks",
    exportCreatedByMeTasksTitle: "Export Tasks Created by Me",
    exportImTheApproverTasksTitle: "Export Tasks that I'm the approver",
    exportImTheObserverTasksTitle: "Export Tasks that I'm the observer",
    exportAssignedToMeTasksTitle: "Export Tasks Assigned to Me",
    exportAllTasksText: "Exporting all tasks from home page. Uses the current list filter settings. Select Report Format:",
    clearAllApprovedTasksTitle: "Clear all approved tasks?",
    clearAllApprovedTasksText:"Are you sure you want to clear all approved tasks?",
    noApprovedTasksTitle: "No tasks to clear",
    noApprovedTasksText: "There are no approved tasks to clear",
    removeAllTasksTitle: "Remove all tasks listed?",
    removeAllTasksText: "Are you sure you want to remove all tasks listed? This action is irreversible",
    allTasksDeleted: "Removed all tasks successfully",
    exportingAllTasksTitle: "Exporting All Tasks",
    exportingFavoriteTasksTitle: "Exporting Favorite Tasks",
    exportingCreatedByMeTasksTitle: "Exporting Tasks Created by Me",
    exportingImTheApproverTasksTitle: "Exporting Tasks that I'm the approver",
    exportingImTheObserverTasksTitle: "Exporting Tasks that I'm the observer",
    exportingAssignedToMeTasksTitle: "Exporting Tasks Assigned to Me",
    emptyFilteredNothingToExport: "Filtered tasks is empty. There is nothing to export",
    noMoreTasks: "No more tasks to load",
    noTasksToExport: "No tasks to export",
  },
  button: {
    exportTasks: "Export Tasks",
    removeAll: "Remove all",
    removeApproved: "Remove approved"
  }
}
