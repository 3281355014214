export enum FeatureType {
  ExportTemplate = "ExportTemplate",
  ExportProjectAsset = "ExportProjectAsset",
  ImportProjectAsset = "ImportProjectAsset",
  SAP = "SAP",
  Profile = "Profile",
  Settings = "Settings",
  GlobalActivityFeed = "GlobalActivityFeed",
  ImportTemplate = "ImportTemplate",
  ImportDataTag = "ImportDataTag",
  ImportOrganizationAsset = "ImportOrganizationAsset",
  ImportProject = "ImportProject",
  Template = "Template",
}