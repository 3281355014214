import {EntityChat} from "types/EntityChat";

export function generatePathPair(entityChat: EntityChat & {requirementName?: string, formRequirementName?: string, fileRequirementName?: string}) {
  const {orgName, projectName, assetName, taskName, requirementName, formRequirementName, fileRequirementName} = entityChat;
  const entityNames = [orgName, projectName, assetName, taskName, requirementName ?? formRequirementName ?? fileRequirementName];
  const reversedEntityNames = entityNames.reverse();
  const lastUndefinedIndex = reversedEntityNames.findIndex(entityName => !!entityName);
  const secondUndefinedIndex = reversedEntityNames.indexOf(undefined, lastUndefinedIndex + 1) + 1;

  return [
    `${orgName}/${entityNames[secondUndefinedIndex] ?? ""}`,
    reversedEntityNames[lastUndefinedIndex] ?? "",
  ]
}