import {Divider, Stack, styled, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {enProjectLabel} from "constants/index";
import ChatDrawer from "components/ChatDrawer";
import {useComponentToggler} from "hooks/index";
import {Project} from "types/Project";
import {Entity} from "enums/entity";
import {projectLevelTasksChatMessagesPath} from "screens/utility/FirebasePath";
import {BaseProps} from "screens/BaseProps";
import React from "react";
import {ChatIconButton} from "components/Button";

interface ProjectLevelTasksProps extends BaseProps {
  project: Project;
  pltChatEmpty: boolean;
  projectLevelTasksApprovedCount?: number;
  projectLevelTasksCount?: number;
  projectLevelTaskHasNewChat?: boolean;
}

export default function ProjectLevelTasks(props: ProjectLevelTasksProps) {
  const {project, uid, toastProps} = props;
  const [isChatDrawerOpen, {
    open: openChatDrawer,
    close: closeChatDrawer
  }] = useComponentToggler(false);

  const navigate = useNavigate();

  function onChatClick(e: React.MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
    openChatDrawer();
  }

  return (
    <>
      <ChatDrawer
        uid={uid}
        isOpen={isChatDrawerOpen}
        entity={Entity.ProjectLevelTask}
        pathPair={[project.orgName!, project.name]}
        onClose={closeChatDrawer}
        colRef={projectLevelTasksChatMessagesPath(project.orgId!, project["@id"]!)}
        toastProps={toastProps!}
      />
      <MainContainer onClick={() => navigate(`../project-level-tasks`)}>
        <Stack>
          <Typography variant="h4">{enProjectLabel.projectLevelTasks}</Typography>
          <Typography color="text.secondary" variant="bodySmall">
            {enProjectLabel.unassignedTasksText}
          </Typography>
        </Stack>
        <Stack direction="row" gap={3} alignItems="center">
          <Stack direction="row" alignItems="center" gap={1}>
            <SystemIcons.Tasks/>
            <Typography variant="h5" color="text.secondary">
              {props.projectLevelTasksApprovedCount || 0}/{props.projectLevelTasksCount || 0}
            </Typography>
          </Stack>
          <Divider orientation="vertical" flexItem/>
          <ChatIconButton
            id="project-level-task-chat-button"
            entity={Entity.ProjectLevelTask}
            withNewMessage={props.projectLevelTaskHasNewChat || false}
            chatEmpty={props.pltChatEmpty}
            onClickFcn={onChatClick}
          />
        </Stack>
      </MainContainer>
    </>
  );
};

const MainContainer = styled(Stack)({
  flexDirection: "row",
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(1),
  justifyContent: "space-between",
  cursor: "pointer",
});
