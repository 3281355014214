import {TimeLog} from "types/TimeLog";
import {EmptyList, InProgress} from "components/index";
import {Entity} from "enums/entity";
import TimeLogsList from "./TimeLogsList";
import "firebase/compat/firestore";
import {orderBy} from "firebase/firestore";
import {timeLogsPath} from "../utility";
import {useCollection} from "hooks/index";
import {useParams} from "react-router-dom";
import {useEffect} from "react";

function TimeLogsView() {
  const {orgId, projId, assetId, milestoneId, taskId} = useParams()
  const [timeLogs,, updateColRef] = useCollection<TimeLog>(null, null, [orderBy("endDateTime", "desc")], true);

  useEffect(() => {
    if (!orgId || !projId || !milestoneId || !taskId) return;

    const timeLogsColRef = timeLogsPath(orgId!, projId!, assetId, milestoneId!, taskId!);
    updateColRef(timeLogsColRef);
  }, [orgId, projId, assetId, milestoneId, taskId]);

  if (!timeLogs)
    return <InProgress/>

  if (timeLogs.length === 0)
    return <EmptyList entity={Entity.TimeLog}/>

  return <TimeLogsList timeLogs={timeLogs}/>
}

export default TimeLogsView
