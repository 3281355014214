import {FormControlLabel, FormGroup, Stack, Switch, Typography,} from "@mui/material";
import React from "react";
import {enTaskLabel} from "constants/index";

interface HideTaskSwitchProps {
  showHidden: boolean;
  toggleHiddenTasks: () => void;
  hiddenTasksCount: undefined | number;
}

function HideTaskSwitch(props: HideTaskSwitchProps) {
  const {showHidden, hiddenTasksCount, toggleHiddenTasks} = props;

  return (
    <Stack direction="row" display="flex" justifyContent="flex-end" alignItems="flex-end" mb={-1} mt={-3}>
      <FormGroup>
        <FormControlLabel
          control={<Switch onChange={toggleHiddenTasks}/>}
          checked={showHidden}
          labelPlacement="start"
          label={
            <Stack direction="row" gap={2} alignItems="center">
              <Typography variant="subtitle1" color="text.primary">
                {enTaskLabel.showHiddenTasks}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" sx={{ml: -1.5}}>
                {((hiddenTasksCount ?? 0) > 0) && hiddenTasksCount}
              </Typography>
            </Stack>
          }
        />
      </FormGroup>
    </Stack>
  );
}

export default HideTaskSwitch;
