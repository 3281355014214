import {useNavigate} from "react-router-dom";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@mui/material";
import {ExportDialog, LongText, PilledNavigationBar} from "components";
import theme from "theme/theme";
import {
  emptyFunction,
  enCommonLabel,
  enFormsButton,
  enFormsLabel,
  previousPage
} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {useComponentToggler} from "hooks/index";
import React, {useRef, useState} from "react";
import {collection, DocumentReference} from "firebase/firestore";
import {submitForm} from "../utility";
import {ActionType, ExportFormType, ProcessType, Severity} from "enums/index";
import {BaseProps} from "../BaseProps";
import {DeleteDialog} from "components/Dialogs";
import {FormImportDialog} from "./FormImportDialog";
import {Form} from "types/Form";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {PillItemProps} from "components/PilledNavBar/PillItem";
import {SidebarIcons} from "assets/icons/menu/menu.index";
import {statusSubmitHandler} from "../utility/statusSubmitHandler";

interface HeaderProps extends BaseProps {
  isEdit?: boolean;
  formName?: string;
  orgFormsAccess: AccessType | null;
  docRef: DocumentReference | null;
  formData: Form | null | undefined;
  updateAllFormData: (data: Form) => void;
  onBack?: () => void;
}

export function Header(props: HeaderProps) {
  const {isEdit, toastProps, orgFormsAccess, docRef, formData, formName, updateAllFormData, onBack} = props;
  const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps!;

  const [isMoreMenuOpen, {open: openMoreMenu, close: closeMoreMenu}] = useComponentToggler(false);
  const [isDeleteDialogOpen, {open: showDeleteDialog, close: closeDeleteDialog}] = useComponentToggler(false);
  const [
    isExportDialogOpen,
    {open: showExportDialog, close: closeExportDialog}
  ] = useComponentToggler(false);
  const [exportType, setExportType] = useState<ExportFormType>(ExportFormType.FormData);

  const [isImportDialogOpen, {open: openImportDialog, close: closeImportDialog}] = useComponentToggler(false);

  const moreButtonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  const headerMenuItems: { Icon: any, label: React.ReactNode, onClick: () => void, disabled: boolean }[] = [
    {
      label: enFormsButton.importForm,
      Icon: SystemIcons.Import,
      onClick: openImportDialog,
      disabled: (!orgFormsAccess || !orgFormsAccess[PermissionOperationKey.Update])
    },
    {
      label: enFormsButton.exportForm,
      Icon: SystemIcons.Export,
      onClick: () => onExport(ExportFormType.FormData),
      disabled: (!orgFormsAccess || !orgFormsAccess[PermissionOperationKey.Update])
    },
    {
      label: enFormsButton.exportFormResponses,
      Icon: SystemIcons.Export,
      onClick: () => onExport(ExportFormType.FormResponses),
      disabled: (!orgFormsAccess || !orgFormsAccess[PermissionOperationKey.Update])
    },
    {
      label: (
        <Typography
          color={(!orgFormsAccess || !orgFormsAccess[PermissionOperationKey.Delete]) ? theme.palette.neutral.main : "red"}
          variant="h4"
        >
          {enFormsButton.deleteForm}
        </Typography>
      ),
      Icon: SystemIcons.DeleteOutlined,
      onClick: () => showDeleteDialog(),
      disabled: (!orgFormsAccess || !orgFormsAccess[PermissionOperationKey.Delete])
    }
  ];

  const pills: PillItemProps[] = [
    {
      id: "category-name",
      label: isEdit ? "" : enFormsLabel.createForm,
      icon: isEdit ? (
          <>
            {enFormsLabel.editingForm + ": "}
            <SidebarIcons.File fill={theme.palette.neutral.dark} width={16} height={16}/>
            <LongText variant="h5">{formName}</LongText>
          </>
        )
      : <SidebarIcons.File fill={theme.palette.neutral.dark} width={16} height={16}/>,
      onClickFcn: emptyFunction
    }
  ];

  async function deleteForm() {
    await submitForm(
      docRef!,
      ActionType.Delete,
      (status, data, isLastUpdate) => statusSubmitHandler<Form>({status, data, isLastUpdate, successCallback, errorCallback})
    );
  }

  function successCallback() {
    setToastMessage(enFormsLabel.deleteFormSuccess);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    navigate(previousPage);
  }

  function errorCallback() {
    setToastMessage(enCommonLabel.errorProcess(ProcessType.Delete));
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
  }

  function onExport(type: ExportFormType) {
    if (!docRef) {
      setToastMessage(enFormsLabel.exportNoLiveData);
      setToastSeverity(Severity.Error);
      setIsToastOpen(true);
      return;
    }
    setExportType(type);
    showExportDialog();
  }

  return (<>
    <DeleteDialog
      isOpen={isDeleteDialogOpen}
      title={enFormsLabel.deleteFormConfirmationTitle}
      text={enFormsLabel.deleteFormConfirmationTitle}
      handleClose={closeDeleteDialog}
      handleConfirm={deleteForm}
    />
    {
      !!formData && (
        <FormImportDialog
          isOpen={isImportDialogOpen}
          toastProps={toastProps!}
          closeDialog={closeImportDialog}
          onParse={updateAllFormData}
          formData={formData}
          uid={props.uid}
        />
      )
    }
    {
      !!docRef && (
        <ExportDialog
          isOpen={isExportDialogOpen}
          onClose={closeExportDialog}
          toastProps={toastProps!}
          title={
            exportType === ExportFormType.FormData ?
              enFormsButton.exportForm :
              enFormsButton.exportFormResponses
          }
          exportingTitle={
            exportType === ExportFormType.FormData ?
              enFormsLabel.exportingFormTitle :
              enFormsLabel.exportingFormResponsesTitle
          }
          message={
            exportType === ExportFormType.FormData ?
              enFormsLabel.exportFormText :
              enFormsLabel.exportFormResponsesText}
          exportColRef={collection(docRef, "exports")}
          exportType={exportType}
        />
      )
    }
    {
      isEdit && (
        <Menu open={isMoreMenuOpen} onClose={closeMoreMenu} anchorEl={moreButtonRef.current}>
          {
            headerMenuItems.map(({Icon, label, onClick, disabled}) => (
                !disabled ? (
                  <MenuItem
                    key={`menuItem_${label}`}
                    onClick={() => {
                      onClick();
                      closeMoreMenu();
                    }}>
                    <ListItemIcon>
                      <Icon/>
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="h4">
                        {label}
                      </Typography>
                    </ListItemText>
                  </MenuItem>
                ) : (
                  <Tooltip title={enFormsLabel.noPermission}>
                    <MenuItem
                      key={`menuItem_${label}`}
                      onClick={emptyFunction}
                      sx={{
                        cursor: "default"
                      }}
                      disableRipple
                      disableTouchRipple
                    >
                      <ListItemIcon>
                        <Icon stroke={theme.palette.neutral.dark}/>
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="h4" color={theme.palette.neutral.main}>
                          {label}
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  </Tooltip>
                )
              )
            )
          }
        </Menu>
      )
    }

    <PilledNavigationBar pills={pills} onBack={onBack}>
      {
        isEdit && (
          <Box sx={{display: "flex", flex: 1, justifyContent: "flex-end"}}>
            <IconButton onClick={openMoreMenu} ref={moreButtonRef}>
              <SystemIcons.MoreVertical/>
            </IconButton>
          </Box>
        )
      }
    </PilledNavigationBar>

  </>)
}
