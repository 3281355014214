import {Stack} from "@mui/material";
import SmartSearchInput from "components/inputs/SmartSearchInput";
import {algoliaRequirementsPath,} from "screens/utility/algoliaColPath";
import {useParams} from "react-router-dom";
import {Entity} from "enums/entity";
import FilterOptions from "./FilterOptions";
import {enCommonLabel} from "constants/index";
import ProvidedTimer from "components/FloatingTimer";

interface SearchAndFilterProps {
  tasksDocRef: string;
  statusFilter: string;
  showHidden: boolean;
  parentEntity: Entity.Asset | Entity.ProjectLevelTask | Entity.Templates;
  handleStatusFilterChange: (newFilter: string) => void;
  searchResultCallback: (results: (any[] | null)) => void;
  toggleHiddenReqs: () => void;
  userId: string
}

function SearchAndFilter(props: SearchAndFilterProps) {
  const {searchResultCallback, userId, ...rest} = props
  const {orgId, projId, assetId, milestoneId, taskId, templateId} = useParams();

  return (
    <Stack
      direction={{xs: "column", sm: "row"}}
      justifyContent={{xs: "center", sm: "space-between"}}
      alignItems="center"
      gap={1}
    >
      <Stack direction="row" gap={1}>
        <SmartSearchInput
          id="search-task"
          placeholder={enCommonLabel.searchEntity("Requirements")}
          colPath={algoliaRequirementsPath(orgId!, projId!, milestoneId!, assetId!, taskId!, templateId!)}
          resultCallback={searchResultCallback}
        />
        <ProvidedTimer userId={userId}/>
      </Stack>
      <FilterOptions {...rest} tasksDocRef={props.tasksDocRef}/>
    </Stack>
  )
}

export default SearchAndFilter;
