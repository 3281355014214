import {Entity} from "enums/entity";
import {SystemIcons} from "assets/icons/system/system.index";
import {SidebarIcons} from "assets/icons";

interface EntityIconProps {
  entity: Entity;
  svgProps?: React.SVGProps<SVGSVGElement>;
}

function EntityIcon(props: EntityIconProps) {
  const {entity, svgProps} = props;

  switch (entity) {
    case Entity.Project:
      return <SystemIcons.Projects {...svgProps}/>
    case Entity.Asset:
      return <SystemIcons.Assets {...svgProps}/>
    case Entity.Task:
    case Entity.PLTask:
      return <SystemIcons.Tasks {...svgProps}/>
    case Entity.FileRequirement:
    case Entity.PLFileRequirement:
    case Entity.FormRequirement:
    case Entity.PLFormRequirement:
      return <SystemIcons.Requirement {...svgProps}/>
    case Entity.SwiftFile:
    case Entity.ReferenceFiles:
      return <SystemIcons.FilesOutlined {...svgProps}/>
    case Entity.Templates:
      return <SystemIcons.Templates {...svgProps}/>
    case Entity.Form:
      return <SidebarIcons.AddFile {...svgProps}/>
    default:
      return <></>
  }
}

export default EntityIcon;
