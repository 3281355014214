import {StatusIcons} from "assets/icons/status/status.index";
import theme from "theme/theme";
import {en} from "language/en";
import {StatusItem} from "assets/arrayList/ProjectStatus";

const enTask = en.screen.Task.status;

const cancelled: StatusItem = {
  title: enTask.cancelled,
  icon: StatusIcons.Cancelled,
  color: theme.palette.neutral.dark,
  checked: false,
}

export default cancelled;