import {BaseProps} from "screens/BaseProps";
import {Entity, FileFormat, FileUploadType, Severity} from "enums/index";
import {DialogActions, DialogContentText,} from "@mui/material";
import React, {useEffect, useState} from "react";
import {enCommonButton, enCommonLabel, mimeType} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {BaseDialog} from "components/Dialogs/index";
import {collection, doc, DocumentReference, getDoc, Timestamp} from "firebase/firestore";
import {userPath} from "screens/utility";
import {ImportButton} from "components/Button";
import {db} from "../../firebase";
import * as xlsx from "xlsx";
import {ImportEntity, requiredImportHeaders} from "constants/headerConstants";
import useFileUpload from "hooks/useFileUpload/useFileUpload";
import {generateUniqueId} from "hooks/useFileUpload/reducer/reducer";
import {getImportInstructionsData,} from "components/Dialogs/utils/getImportInstructionsData";
import {isImportFileValid} from "components/Dialogs/utils/isImportFileValid";
import getEnvKey from "../../screens/utility/getEnvKey";
import {importMaxRows} from "constants/envKeys";
import ImportButtonInfoDialog from "components/Dialogs/ImportButtonInfoDialog";

interface ImportDialogProps extends BaseProps {
  isOpen: boolean;
  entity: ImportEntity;
  parentDocumentRef: DocumentReference;
  closeDialog: () => void;
  manualClose?: () => void;
}

type SelectedState = Record<FileFormat, { isLoading: boolean, selectedFile: File | null, valid: boolean }>;

const MAX_ROWS = getEnvKey(importMaxRows) as number || 1;

const selectedInitialState = {
  [FileFormat.CSV]: {isLoading: false, selectedFile: null, valid: true},
  [FileFormat.Excel]: {isLoading: false, selectedFile: null, valid: true},
}

function ImportDialog(props: ImportDialogProps) {
  const {
    entity,
    parentDocumentRef: documentRef,
    toastProps,
    isOpen,
    closeDialog,
    uid,
  } = props;

  const {setToastMessage, setIsToastOpen, setToastSeverity} = toastProps!;

  const [parentDocumentRef, setParentDocumentRef] = useState<DocumentReference | null>(null);
  const [selectedState, setSelectedState] = useState<SelectedState>(selectedInitialState);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [requiredHeaders] = useState<string[]>(requiredImportHeaders[entity]);

  // upload file related states
  const [currentUpload, setCurrentUpload] = useState<{queueId: string | null, fileType: FileFormat}>({queueId: null, fileType: FileFormat.CSV});
  const {errorFiles, fileUploadState, addFiles} = useFileUpload({uid: uid!});
  const {completedFiles} = fileUploadState;

  const {dialogTitle} = getImportInstructionsData(entity);

  // handle success/error messages for file upload
  useEffect(() => {
    // if no uploadId, return
    if (!currentUpload.queueId) return;

    if (errorFiles.some(file => file.queueId === currentUpload.queueId)) {
      onCreateImportError(currentUpload.fileType);
      setCurrentUpload(prev => ({...prev, queueId: null}));
      setIsImporting(false);
    }

    const completedFile = completedFiles.filter(file => file.queueId === currentUpload.queueId);
    if (completedFile.length > 0) {
      // @ts-ignore
      setSelectedState((prevState) => ({
        ...prevState,
        [currentUpload.fileType]: {
          isLoading: false,
          selectedFile: null,
        }
      }));
      setCurrentUpload(prev => ({...prev, queueId: null}));
      setIsImporting(false);
      closeDialog();
    }
  }, [currentUpload, completedFiles, errorFiles]);

  //check for validations
  useEffect(() => {
    const fileType = selectedState[FileFormat.Excel].selectedFile ? FileFormat.Excel : FileFormat.CSV;
    const file = selectedState[FileFormat.Excel].selectedFile || selectedState[FileFormat.CSV].selectedFile;

    if (!file) return;

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString

    if (rABS) reader.readAsBinaryString(file)
    else reader.readAsArrayBuffer(file)

    const listener = reader.onload = (e) => {
      const bufferArray = e?.target?.result;

      const wb = xlsx.read(bufferArray, {
        type: rABS ? "binary" : "array",
        dateNF: "MM/dd/yyyy hh:mm a",
        cellDates: true
      })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]

      let data = xlsx.utils.sheet_to_json(ws);

      if(data.length > MAX_ROWS){
        setSelectedState((prevState) => ({
          ...prevState,
          [fileType]: {
            isLoading: false,
            selectedFile: null,
          }
        }))
        toastProps?.setToastSeverity(Severity.Error);
        toastProps?.setToastMessage(enCommonLabel.maxImportLimit(MAX_ROWS));
        toastProps?.setIsToastOpen(true);

        return;
      }

      let headers: string[] = []
      if (fileType === FileFormat.CSV) {
        const sheetData2 = xlsx.utils.sheet_to_json(ws, {header: 1});
        headers = sheetData2.shift() as string[];
      } else {
        const columnCount = xlsx.utils.decode_range(ws['!ref']!).e.c + 1
        for (let i = 0; i < columnCount; ++i) {
          const cellAddress = `${xlsx.utils.encode_col(i)}1`;
          const cell = ws[cellAddress];
          if (cell && cell.v) {
            headers[i] = cell.v;
          }
        }
      }

      headers = headers.map((header) => header.toLowerCase())

      const missingHeaders = requiredHeaders.filter((requiredHeader) => !headers.includes(requiredHeader.toLowerCase()));

      if (missingHeaders.length > 0) {
        setSelectedState((prevState) => ({
          ...prevState,
          [fileType]: {
            isLoading: false,
            selectedFile: null,
          }
        }))
        toastProps?.setToastSeverity(Severity.Error);
        toastProps?.setToastMessage(enCommonLabel.missingRequiredHeaders(missingHeaders));
        toastProps?.setIsToastOpen(true);
      }
    }

    return reader.removeEventListener("load", listener)
  }, [selectedState])

  // reset states on close
  useEffect(() => {
    if (!isOpen) {
      setSelectedState(selectedInitialState);
      setIsImporting(false);
    }
  }, [isOpen]);

  // update parentDocumentRef
  useEffect(() => {
    if (!isOpen) return;

    const checkParentDocumentRef = async () => {
      const userDoc = (await getDoc(userPath(uid))).data();
      if (!userDoc || !documentRef) return;

      setParentDocumentRef(doc(db, documentRef.path));
    }

    checkParentDocumentRef();
  }, [documentRef, isOpen, uid]);

  async function openFileExplorer(fileType: FileFormat) {
    if (selectedState[fileType].selectedFile !== null) {
      await createImportDoc(fileType);
      return;
    }

    const input = document.createElement("input");
    input.type = "file";
    input.accept = mimeType[fileType];
    input.addEventListener("change", async () => {
      const file = input.files?.[0];
      if (!file) return;

      const isFileValid = isImportFileValid(file, fileType);
      // check file type before uploading
      if (!isFileValid) {
        setToastMessage(enCommonLabel.invalidFileType);
        setToastSeverity(Severity.Error);
        setIsToastOpen(true);

        setSelectedState((prevState) => ({
          ...prevState,
          [fileType]: {
            ...selectedState[fileType],
            isLoading: false,
          },
        }));
        return;
      }

      setSelectedState((prevState) => ({
        ...prevState,
        [fileType]: {
          isLoading: false,
          selectedFile: file
        },
      }));
    });

    input.click();
  }

  function getFileUploadType() {
    switch (entity) {
      case Entity.Templates:
        return FileUploadType.ImportTemplate;
      case Entity.Asset:
        return FileUploadType.ImportAsset;
      case Entity.Project:
        return FileUploadType.ImportProject;
      case Entity.DataTag:
        return FileUploadType.ImportDataTags;
      case Entity.OrganizationAsset:
        return FileUploadType.ImportOrganizationAsset;
    }
  }

  async function createImportDoc(fileType: FileFormat) {
    if (!parentDocumentRef) return;
    const uploadId = generateUniqueId();

    const importDoc = {
      queueId: uploadId,
      timeUploaded: Timestamp.now(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      fileType: fileType,
      entity,
      parentCollectionPath: collection(parentDocumentRef, "imports").path,
      timeCreated: Timestamp.now(),
      file: selectedState[fileType].selectedFile!,
      importFileType: fileType,
      type: getFileUploadType()
    }

    setSelectedState((prevState) => ({
      ...prevState,
      [fileType]: {
        ...selectedState[fileType],
        isLoading: true,
      }
    }));
    setIsImporting(true);
    setCurrentUpload({queueId: uploadId, fileType});
    await addFiles([importDoc]);
  }

  function onCreateImportError(fileType: FileFormat) {
    setToastMessage(enCommonLabel.importFailed);
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);

    setSelectedState((prevState) => ({
      ...prevState,
      [fileType]: {
        isLoading: false,
        selectedFile: null,
      }
    }));
  }

  return (
    <BaseDialog
      {...props}
      title={dialogTitle}
      additionalActions={<ImportButtonInfoDialog entity={entity}/>}
    >
      <>
        <DialogContentText
          sx={{fontSize: 14, color: "black", maxWidth: {sm: "auto", md: "24rem"}}}
          id="alert-dialog-description"
        >
          {enCommonLabel.importEntityDescription(entity)}
        </DialogContentText>
        <DialogActions sx={{display: "flex", justifyContent: "center", paddingLeft: 0, paddingRight: 0, gap: 1.5}}>
          <ImportButton
            id="upload-button-csv"
            isLoading={selectedState[FileFormat.CSV].isLoading}
            isImportAvailable={!!selectedState[FileFormat.CSV].selectedFile}
            onClickFcn={() => openFileExplorer(FileFormat.CSV)}
            label={enCommonButton[FileFormat.CSV]}
            disabled={selectedState[FileFormat.CSV].isLoading || isImporting}
            startIcon={SystemIcons.CSV}
          />
          <ImportButton
            id="upload-button-excel"
            isLoading={selectedState[FileFormat.Excel].isLoading}
            isImportAvailable={!!selectedState[FileFormat.Excel].selectedFile}
            onClickFcn={() => openFileExplorer(FileFormat.Excel)}
            label={enCommonButton[FileFormat.Excel]}
            disabled={selectedState[FileFormat.Excel].isLoading || isImporting}
            startIcon={SystemIcons.Excel}
          />
        </DialogActions>
      </>
    </BaseDialog>
  )
}

export default ImportDialog;
