import {Asset, EntityChat, StatusItem, Task, User} from "types/index";
import {Box, Divider, Grid, Hidden, Paper, Skeleton, Stack, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {ActionOption, DotIndicator, LongText, MenuCard, SelectStatus,} from "components/index";
import {collection, doc, getDocs} from "firebase/firestore";
import MilestoneTaskStatusObjectList
  from "assets/arrayList/StatusObjectList/MilestoneTaskStatusObjectList";
import React, {Dispatch, SetStateAction, useEffect, useMemo, useRef, useState} from "react";
import {
  generateEntityBreadcrumbs,
  getDocPath,
  getStatusOptions,
  hexToRGBA, myTasksPath,
  submitForm,
} from "screens/utility";
import {db} from "../../../../../firebase";
import {useNavigate} from "react-router-dom";
import {enCommonLabel, enHomeLabel, enMilestoneLabel, enTaskLabel} from "constants/index";
import {ActionType, CounterFields, Entity, Severity} from "enums/index";
import {ChatIconButton, FavoriteButton} from "components/Button";
import {toastProps} from "screens/BaseProps";
import {getTaskBorderColor} from "components/MilestoneView/utils/getTaskBorderColor";
import {getScheduleDateAndTime} from "components/MilestoneView/utils/getScheduleDateAndTime";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import {styled} from "@mui/material/styles";
import BreadcrumbsDisplay from "components/BreadcrumbsDisplay";
import CardBadge, {OverlayType} from "components/CardBadge";
import {useComponentToggler, useDocument, useRTDBDocField} from "hooks/index";
import {TaskItemAction} from "../../../TaskItemAction";
import PersonnelAssigned from "components/Task/PersonnelAssigned";
import CalendarLabel from "components/Task/CalendarLabel";
import {UserPersonnel} from "types/UserPersonnel";
import {toProgressCount} from "../../../../utility/toProgressCount";
import useGetProjectBasedPath from "hooks/useGetProjectBasedPath";
import useTemplateRTDBDocField from "hooks/useTemplateRTDBDocField";
import DeleteDialog from "../../../../../components/Dialogs/DeleteDialog";
import AssignableAvatar from "components/AssignableAvatar";
import {filterTaskBySearch} from "components/MilestoneView/utils/filterTaskBySearch";
import {FiltersState} from "../../../TaskSearchAndFilters";
import {BreadCrumbType} from "../../../../utility/generateEntityBreadcrumbs";
import EditTaskDrawer from "components/Drawers/EditTaskDrawer/EditTaskDrawer";

type TaskItemProps = {
  uid: string;
  onTaskItemDrawerOpen: (task: Task, action: TaskItemAction) => void;
  toastProps: toastProps;
  taskReferencePath: string;
  canUpdateStatus?: boolean;
  disableCardMenu?: boolean;
  isHomeTask?: boolean;
  asset?: Asset | null;
  fromSearch?: boolean;
  filterState?: FiltersState;
  actualTaskId: string;
  myTasksId?: string;
  onRemoveFromListSuccess?: () => void;
  favoriteSubmitCallback?: () => void;
  openTaskActivityFeedDrawer?: (task: Task) => void;
  setBrokenTasks?: Dispatch<SetStateAction<string[]>>
}

type ContextMenuOption = {
  onClick: (...args: any) => void;
  label: string;
  Icon: any;
  disabled: boolean;
}

export function TaskItem(props: TaskItemProps) {
  const {
    taskReferencePath,
    toastProps,
    uid,
    canUpdateStatus = true,
    onTaskItemDrawerOpen,
    isHomeTask,
    asset,
    onRemoveFromListSuccess,
    favoriteSubmitCallback,
    openTaskActivityFeedDrawer,
    setBrokenTasks
  } = props;
  const {disableCardMenu = false, actualTaskId} = props;

  const navigate = useNavigate();
  const taskRef = useRef(null);

  const [task, setRef] = useDocument<Task | null>(null);

  let referencePathResult = useGetProjectBasedPath(taskReferencePath) ?? taskReferencePath;
  // no need to derive the correct path if from home
  referencePathResult = props.isHomeTask ? taskReferencePath : referencePathResult;
  const referencePath = `${referencePathResult.split('/').slice(0, -1).join('/')}/${actualTaskId}`;

  const splitTaskReference = taskReferencePath.split("/");
  const userBasedPath = referencePathResult.split("/").includes(uid!) ? referencePathResult
    : splitTaskReference.includes(uid) ? splitTaskReference.slice(0, 1).join("/") + "/" + referencePathResult
      : getDocPath(uid, referencePathResult);

  // identify which hook to use, if from home, no need to check for template fields
  const rtdbFunction = props.isHomeTask ? useRTDBDocField : useTemplateRTDBDocField;
  const fileRequirementsApprovedCount = rtdbFunction<number>(userBasedPath, CounterFields.FileRequirementsApprovedCount) ?? 0;
  const fileRequirementsCount = rtdbFunction<number>(userBasedPath, CounterFields.FileRequirementsCount) ?? 0;
  const formRequirementsApprovedCount = rtdbFunction<number>(userBasedPath, CounterFields.FormRequirementsApprovedCount) ?? 0;
  const formRequirementsCount = rtdbFunction<number>(userBasedPath, CounterFields.FormRequirementsCount) ?? 0;
  const hasNewActivity = rtdbFunction<boolean>(userBasedPath, "hasNewActivity") ?? false;
  const hasNewChat = rtdbFunction<boolean>(userBasedPath, "hasNewChat") ?? false;
  const chatEmpty = rtdbFunction<boolean>(referencePathResult, "chatEmpty") ?? true;
  const favorite = rtdbFunction<boolean>(userBasedPath, "favorite") ?? false;

  const [statusOption, setStatusOption] = useState<Record<string, StatusItem> | null>(null);
  const [contextMenuAnchor, setContextMenuAnchor] = useState<HTMLElement | null>(null);
  const [contextMenuPos, setContextMenuPos] = useState<{ top: number; left: number }>({top: 0, left: 0});
  const [isRemoving, setIsRemoving] = useState(false);
  const [assignees, setAssignees] = useState<UserPersonnel[]>([]);
  const [accessRoleText, setAccessRoleText] = useState<string | null>(null)
  const [contextMenuOptions, setContextMenuOptions] = useState<ContextMenuOption[]>([])
  const [isOpen, {open: openEditDrawer, close: closeEditDrawer}] = useComponentToggler(false)
  const [isDialogOpen, {open: openDialog, close: closeDialog}] = useComponentToggler(false);
  const [displayNull, setDisplayNull] = useState<boolean>(false);

  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumbType[]>([]);

  useMemo(() => {
    if(task === undefined && setBrokenTasks)
      setBrokenTasks((prev) => ([...prev, taskReferencePath]))

    if (!props.isHomeTask) return;

    if (!props.fromSearch) return;

    if (!task) return;

    if (!props.filterState) return;

    const filteredTask = filterTaskBySearch([task], props.filterState, uid);
    setDisplayNull(!isHomeTask && filteredTask.length === 0);
  }, [task, props.fromSearch, props.isHomeTask]);

  // generate breadcrumbs once task in defined
  useEffect(() => {
    if (!task) return;
    // if task is from milestone, manually generate the required breadcrumbs
    const newBreadcrumbs = generateEntityBreadcrumbs({
      ...(asset ? {
        orgName: asset.orgName,
        orgId: asset.orgId,
        projectName: asset.projectName,
        projectId: asset.projectId,
        assetName: asset.name,
        assetId: asset["@id"],
        taskName: task?.name,
        taskId: task?.taskId ?? task?.["@id"],
        orgTaskRefPath: referencePath
      } : {
        ...task,
        taskName: task?.name,
        entityRefPath: task?.orgTaskRefPath
      }),
    } as unknown as EntityChat);

    setBreadCrumbs(newBreadcrumbs);
  }, [task]);

  //populate context menu
  useEffect(() => {
    if (isHomeTask) {
      setContextMenuOptions([
        {
          onClick: () => openInNewTab(),
          label: enTaskLabel.openInNewTab,
          Icon: <SystemIcons.OpenInNewTab/>,
          disabled: false
        },
        {
          onClick: () => openTaskActivityFeed(),
          label: enCommonLabel.activityFeed,
          Icon: (
            <DotIndicator isVisible={Boolean(hasNewActivity)}>
              <SystemIcons.Notifications/>
            </DotIndicator>
          ),
          disabled: false
        },
        {
          onClick: onRemoveFromListClick,
          label: enHomeLabel.removeFromList,
          Icon: <SystemIcons.DeleteOutlined/>,
          disabled: false
        }])
      return;
    }

    setContextMenuOptions(
      [
        {
          onClick: () => openInNewTab(),
          label: enTaskLabel.openInNewTab,
          Icon: <SystemIcons.OpenInNewTab/>,
          disabled: false
        },
        {
          onClick: () => openTaskActivityFeed(),
          label: enCommonLabel.activityFeed,
          Icon: (
            <DotIndicator isVisible={Boolean(hasNewActivity)}>
              <SystemIcons.Notifications/>
            </DotIndicator>
          ),
          disabled: false
        },
        {
          onClick: openEditDrawer,
          label: enTaskLabel.edit,
          Icon: <SystemIcons.Edit/>,
          disabled: false
        },
        {
          onClick: openDialog,
          label: enTaskLabel.deleteTask,
          Icon: <SystemIcons.DeleteOutlined/>,
          disabled: taskReferencePath.split('/').includes('templates') || !!task?.templateId
        },
      ])
  }, [isHomeTask, taskReferencePath, task?.templateId, breadCrumbs, hasNewActivity]);

  useEffect(() => {
    if (!taskReferencePath) return;

    setRef(doc(db, taskReferencePath));
  }, [taskReferencePath]);

  useEffect(() => {
    const [options] = getStatusOptions(MilestoneTaskStatusObjectList, "Pending");
    setStatusOption(options);
  }, []);

  useEffect(() => {
    async function getAccessRole() {
      if (assignees.length !== 1) return

      let path: string;
      if (task && task.orgTaskRefPath) {
        path = `users/${uid}/` + task.orgTaskRefPath
      } else {
        path = taskReferencePath
      }
      const accessListRef = collection(db, path, "accessList")
      const accessListDocs = await getDocs(accessListRef)
      const accessListData = accessListDocs.docs.map(doc => doc.data())
      const role = accessListData.find(data => data.id === assignees[0].id)?.role

      setAccessRoleText(role ?? null);
    }

    getAccessRole()
  }, [assignees]);

  useEffect(() => {
    if (!task) return;
    const {assignedTo, approver, observer} = task!;
    setAssignees([
      assignedTo as unknown as UserPersonnel,
      approver as unknown as UserPersonnel,
      observer as unknown as UserPersonnel
    ]);
  }, [
    task?.taskSchedule,
    task?.taskStatus,
    task?.assignedTo,
    task?.approver,
    task?.observer,
  ]);

  if (task === null)
    return <Skeleton variant="rounded" height={100} sx={{marginY: props.isHomeTask ? 0.5 : 1, marginRight: 1}}/>

  if (task === undefined) {
    onRemoveFromListSuccess && onRemoveFromListSuccess();
    return null
  }

  if (task.deleted) return null;

  function openTaskActivityFeed() {
    openTaskActivityFeedDrawer && openTaskActivityFeedDrawer(task!);
  }

  function openInNewTab() {
    if (breadCrumbs.length === 0) return;
    const lastCrumb = breadCrumbs[breadCrumbs.length - 1];
    window.open(`${lastCrumb.path}`, '_blank');
  }

  function openDrawer(e: React.MouseEvent, action: TaskItemAction) {
    if (!task) return;

    e.preventDefault();
    e.stopPropagation();

    onTaskItemDrawerOpen(task, action);
  }

  async function onCardClick() {
    if (breadCrumbs.length === 0) return;
    const lastCrumb = breadCrumbs[breadCrumbs.length - 1];
    navigate(lastCrumb.path);
  }

  const selectedStatus = statusOption?.[task.taskStatus] ?? undefined;

  function openContextMenu(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setContextMenuPos({top: e.clientY, left: e.clientX});
    setContextMenuAnchor(e.currentTarget);
  }

  function closeContextMenu(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    setContextMenuAnchor(null);
  }

  async function onRemoveFromListClick() {
    if (props.isHomeTask) {
      if (!props.myTasksId) return;

      setIsRemoving(true);
      submitForm(doc(myTasksPath(uid), props.myTasksId), ActionType.Delete, () => {
        toastProps.setToastSeverity(Severity.Success);
        toastProps.setToastMessage(enHomeLabel.taskRemoved);
        toastProps.setIsToastOpen(true);
        onRemoveFromListSuccess && onRemoveFromListSuccess();
      });
      setContextMenuAnchor(null);
      return;
    }

    setIsRemoving(true);
    submitForm(doc(db, taskReferencePath), ActionType.Delete, () => {
      toastProps.setToastSeverity(Severity.Success);
      toastProps.setToastMessage(enHomeLabel.taskRemoved);
      toastProps.setIsToastOpen(true);
      onRemoveFromListSuccess && onRemoveFromListSuccess();
    });
    setContextMenuAnchor(null);
  }

  const {borderColor, calendarIcon} = getTaskBorderColor(
    task.taskStatus,
    task.dueDate || null,
    task.dueTime,
    {
      width: 32,
      height: 32
    }
  );

  const scheduleDateAndTime = getScheduleDateAndTime(task!);
  const backgroundColor = hexToRGBA(borderColor || theme.palette.secondary.main, 0.1);

  function getRoleText(personnel: UserPersonnel, index: number) {
    if (personnel["@id"] === "") return;
    if (!task) return;

    if (personnel["@id"] === task.assignedTo?.id && index === 0) return enTaskLabel.assignee
    else if (personnel["@id"] === task.approver?.id && index === 1) return enTaskLabel.approver
    else if (personnel["@id"] === task.observer?.id && index === 2) return enTaskLabel.observer
  }

  if (displayNull) return null;

  return (
    <>
      {isOpen && <EditTaskDrawer
        isOpen={isOpen}
        onClose={closeEditDrawer}
        task={task ?? null}
        taskCollectionRef={doc(db, taskReferencePath).parent}
        uid={task.userId}
        toastProps={toastProps}
      />}

      <MenuCard
        anchorPosition={contextMenuPos}
        open={!!contextMenuAnchor}
        anchorEl={contextMenuAnchor}
        origin="left"
        closeMenu={closeContextMenu}
      >
        {contextMenuOptions.map((option) =>
          <ActionOption {...option} onClick={() => {
            option.onClick();
            setContextMenuAnchor(null);
          }}/>)}
      </MenuCard>
      <Paper
        sx={{
          position: "relative",
          cursor: isRemoving ? "default" : "pointer",
          boxShadow: "none",
          outline: `1px solid ${theme.palette.neutral.light}`,
          "&:hover": {
            outlineColor: isRemoving ? undefined : theme.palette.primary.main,
          },
          backgroundColor: isRemoving ? theme.palette.neutral.light : undefined,
          maxWidth: "100%",
          marginY: props.isHomeTask ? 0.5 : 1,
          marginRight: 1,
        }}
        onClick={isRemoving ? undefined : onCardClick}
        onContextMenu={isRemoving || disableCardMenu ? undefined : openContextMenu}
        onMouseDown={(e) => {
          (e.button === 1) && openInNewTab();
        }}
        ref={taskRef}
      >
        {isRemoving &&
          <Box
            width="100%"
            height="100%"
            position="absolute"
            sx={{
              backgroundColor: theme.palette.neutral.medium,
              top: 0,
              left: 0,
              zIndex: 1,
              opacity: 0.5
            }}
          >
          </Box>
        }
        <Grid
          container
          sx={{
            backgroundColor: "#fff",
            borderRadius: 1,
            minHeight: "60px",
            overflow: "inherit",
            borderLeftColor: borderColor,
            flexWrap: "nowrap",
            ...((task[CounterFields.BuildingProgressCount] ?? 0) ? {border: `1px solid ${theme.palette.warning.main}`} : {})
          }}
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          borderLeft={4}
          position="relative"
        >
          <CardBadge
            id={`task-badge-${task["@id"]}`}
            text={enTaskLabel.buildingTask}
            buildingProgressCount={toProgressCount(task[CounterFields.BuildingProgressCount] ?? 0)}
            toastProps={toastProps!}
            process={OverlayType.Building}
          />
          <Grid item sx={{alignSelf: "stretch"}}>
            <Item
              sx={{height: "100%"}}
              padding={{
                xs: theme.spacing(0.2),
                sm: theme.spacing(0.3),
                md: theme.spacing(0.4),
                lg: theme.spacing(0.5)
              }}
            >
              {statusOption !== null && (
                <SelectStatus
                  documentRef={doc(db, isHomeTask ? task?.orgTaskRefPath! : referencePath)}
                  statusField="taskStatus"
                  displayIconOnSelected={true}
                  displayTextOnSelected={false}
                  items={statusOption}
                  selected={selectedStatus}
                  isDisabled={!canUpdateStatus}
                  toastProps={toastProps}
                  selectSx={{
                    height: "100%",
                    minHeight: "100%",
                    border: "none",
                    "&:disabled": {
                      border: "none"
                    },
                    "&:hover": {
                      border: "none",
                      backgroundColor: theme.palette.background.paper
                    },
                  }}
                  width="50px"
                />
              )}
            </Item>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
          />
          <Grid
            item
            xs={8}
            sm={4}
            md={5}
            lg={6}
            justifyContent="flex-start"
            alignItems="center"
            sx={{pointerEvents: isRemoving ? "none" : "auto"}}
          >
            <Stack
              direction="column"
              textAlign="left"
              sx={{maxWidth: 400}}
              padding={{
                xs: theme.spacing(0.1),
                sm: theme.spacing(0.2),
                md: theme.spacing(0.5),
                lg: theme.spacing(0.8)
              }}
            >
              <Stack
                direction="row"
                sx={{padding: 0, margin: 0}}
                gap={1}
                alignItems="center"
                position="relative"
              >
                {
                  task.isPrivate && (
                    <SystemIcons.LockFilled
                      stroke={theme.palette.neutral.dark}
                      fill={theme.palette.neutral.dark}
                      height={16}
                      width={16}
                    />
                  )
                }
                <LongText
                  id="taskName"
                  variant="h5"
                  overflowWrap={"anywhere"}
                >
                  {task.name}
                </LongText>
              </Stack>
              <BreadcrumbsDisplay
                entityBreadcrumbs={breadCrumbs}
                orgName={task.orgName}
              />
              <LongText
                className={`taskDescription_${task.assetId}`}
                textColor={theme.palette.neutral.dark}
                variant="caption"
                maxWidth={"100ch"}
                overflowWrap={"anywhere"}
              >
                {!!task.description ? task.description : enCommonLabel.noDescription}
              </LongText>

              <Stack direction="row" gap={3} alignItems="center">
                <Hidden mdDown>
                  <DataTagsDisplay
                    listSize="medium"
                    dataTagsIds={task.dataTagsIds ?? []}
                    toastProps={toastProps!}
                    documentRef={doc(db, isHomeTask ? task?.orgTaskRefPath! : referencePath)}
                    canEditDocumentRef={true}
                    uid={props.uid}
                    fromTemplates={!isHomeTask && taskReferencePath.split('/').includes('templates')}
                    originalPath={referencePath}
                  />
                </Hidden>
              </Stack>
            </Stack>
          </Grid>
          <Hidden smDown>
            <Divider orientation="vertical" flexItem/>
            <Grid
              item
              xs={1.5}
              justifyContent="center"
              alignItems="center"
            >
              <Item
                direction="column"
                justifyContent="space-around"
              >
                <Item
                  direction="row"
                  alignItems="center"
                  sx={{
                    padding: 0,
                    margin: 0,
                    justifyContent: "center"
                  }}
                  gap={0.5}
                >
                  <SystemIcons.Requirement
                    stroke={theme.palette.neutral.dark}
                    height={16}
                    width={16}
                  />
                  <Typography
                    sx={{
                      padding: 0,
                      margin: 0,
                      color: theme.palette.neutral.dark,
                    }}
                    title={enMilestoneLabel.milestoneRequirementsCounter}
                  >
                    {formRequirementsApprovedCount} / {formRequirementsCount}
                  </Typography>
                </Item>
                <Item
                  direction="row"
                  alignItems="center"
                  sx={{
                    padding: 0,
                    margin: 0,
                    justifyContent: "center"
                  }}
                  gap={0.5}
                >
                  <SystemIcons.FilesOutlined
                    stroke={theme.palette.neutral.dark}
                    height={16}
                    width={16}
                  />
                  <Typography
                    sx={{
                      padding: 0,
                      margin: 0,
                      color: theme.palette.neutral.dark,
                    }}
                    title={enMilestoneLabel.milestoneFilesCounter}
                  >
                    {fileRequirementsApprovedCount} / {fileRequirementsCount}
                  </Typography>
                </Item>
              </Item>
            </Grid>
            <Hidden mdDown>
              <ScheduleGrid
                item
                onClick={(e) => openDrawer(e, TaskItemAction.TaskSchedule)}
                id={`task-schedule-button_${task["@id"]}`}
                sx={{
                  backgroundColor,
                  px: 2,
                }}
              >
                <Item
                  sx={{
                    height: "100%",
                    width: 141,
                    justifyContent: 'flex-start'
                  }}
                  direction="row"
                  alignItems="center"
                >
                  <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1}>
                    <Stack>
                      {calendarIcon}
                    </Stack>
                    <Stack direction="column" sx={{textAlign: 'left'}}>
                      <CalendarLabel
                        text={scheduleDateAndTime.dueText ?? ''}
                        sx={{color: borderColor}}
                      />
                      <CalendarLabel
                        text={scheduleDateAndTime.dueDate ?? ''}
                        sx={{fontWeight: 500, color: borderColor}}
                      />
                      {scheduleDateAndTime.dueText && (
                        <div style={{height: '10px'}}/>
                      )}
                      <CalendarLabel
                        text={scheduleDateAndTime.statusText ?? ''}
                        sx={{color: borderColor}}
                      />
                      <CalendarLabel
                        text={scheduleDateAndTime.statusDate ?? ''}
                        sx={{fontWeight: 500, color: borderColor}}
                      />
                      {scheduleDateAndTime.actionText && (
                        <Stack flexDirection="row" alignItems="center" gap={0.5} sx={{marginTop: 0.5}}>
                          <CalendarLabel
                            text={scheduleDateAndTime.actionText ?? ''}
                            sx={{marginTop: 0.2, color: borderColor}}
                          />
                          <AssignableAvatar
                            {...(scheduleDateAndTime.actionBy ?? {}) as User}
                            sx={{
                              width: 16,
                              height: 16,
                              fontSize: 8,
                              border: "none"
                            }}
                          />
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Item>
              </ScheduleGrid>
            </Hidden>
            <Stack
              sx={{px: 2, width: 174}}
              alignItems="center"
              justifyContent="center"
              gap={0.2}
              onClick={(e) => openDrawer(e, TaskItemAction.TaskSchedule)}
            >
              {assignees.map((assignee, i) =>
                <PersonnelAssigned
                  key={`personnel-${task["@id"]!}-${i}`}
                  user={assignee}
                  taskId={task["@id"]!}
                  roleText={accessRoleText ?? getRoleText(assignee, i)}
                />)
              }
            </Stack>
            <Divider orientation="vertical" flexItem/>
          </Hidden>
          <Grid item xs="auto" justifyContent="flex-end" alignItems="center">
            <Item direction="column" justifyContent="space-around" sx={{px: 1}}>
              <ChatIconButton
                id={`${task["@id"]}-task-item-chat-button`}
                entity={Entity.Task}
                withNewMessage={hasNewChat}
                chatEmpty={chatEmpty}
                onClickFcn={(e) => openDrawer(e, TaskItemAction.ChatDrawer)}
                iconProps={{
                  width: 30,
                  height: 30,
                  strokeWidth: 1.2
                }}
              />
              <Hidden smDown>
                <FavoriteButton
                  favorite={Boolean(favorite) || (task?.favoriteUserIds ?? []).includes(uid)}
                  docRef={doc(db, isHomeTask ? task?.orgTaskRefPath! : referencePath)}
                  toastProps={toastProps!}
                  disabled={false}
                  width={30}
                  height={30}
                  strokeWidth={1.2}
                  fromTemplate={taskReferencePath.split('/').includes('templates')}
                  submitCallback={favoriteSubmitCallback}
                />
              </Hidden>
            </Item>
          </Grid>
        </Grid>
      </Paper>
      <DeleteDialog
        overlayId="delete-milestone-dialog"
        confirmButtonId={`confirm-button`}
        cancelButtonId={`cancel-button`}
        isOpen={isDialogOpen}
        title={enTaskLabel.deleteConfirmationTitle}
        text={enTaskLabel.deleteConfirmationText}
        handleClose={closeDialog}
        handleConfirm={onRemoveFromListClick}
      />
    </>
  )
}

const Item = styled(Stack)(() => ({
  textAlign: "center",
  alignContent: "center",
  justifyContent: "space-evenly",
  flex: 1
}));

const ScheduleGrid = styled(Grid)({
  alignSelf: "stretch",
  "&:hover": {
    cursor: "pointer",
    border: 1,
    borderColor: theme.palette.primary.main,
  }
});

/** end of styles */
