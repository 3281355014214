import {useEffect, useState} from "react";
import {TimerState} from "types/Task";
import convertMilliseconds from "../screens/utility/convertMilliseconds";
import calculateMsFromStartDate from "../screens/utility/TimerUtils/calculateMsFromStartDate";

import {SimpleTimer} from "components/FloatingTimer";


interface TimerDetails {
  hours: number | string
  minutes: number | string
  seconds: number | string
}


function useTimerHook(timer: SimpleTimer | null, forceStopTimer?: boolean) {
  const [timerDetails, setTimerDetails] = useState<TimerDetails | null>(null)
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timer | null>(null)
  const timerState = timer?.timerState ?? TimerState.Stopped
  const lastTimerStartedDateTime = timer?.lastTimerStartedDateTime ?? null

  useEffect(() => {
    if (!timer)
      return

    if (!timerDetails) {
      setTimerDetails(convertMilliseconds(timer.totalLoggedTimeInMs ?? 0))
      return
    }


    setTimerDetails(() => {
      const msFromStartDate = calculateMsFromStartDate(lastTimerStartedDateTime)

      const {
        seconds,
        minutes,
        hours
      } = convertMilliseconds(msFromStartDate + (timer?.totalLoggedTimeInMs ?? 0))

      return {
        hours, minutes, seconds
      }
    })

  }, [JSON.stringify(timer)])

  //handle start and stop of timer
  useEffect(() => {

    const isTimerStopped = timerState === TimerState.Stopped || timerState === TimerState.Paused;
    const isTimerRunning = (timerState === TimerState.Running || timerState === TimerState.Resumed || timerState === TimerState.Started) && !forceStopTimer

    if (!timerInterval && isTimerRunning) {
      const interval = setInterval(() => {
          if (forceStopTimer)
            return;

          setTimerDetails(() => {
            const msFromStartDate = calculateMsFromStartDate(lastTimerStartedDateTime)

            const {
              seconds,
              minutes,
              hours
            } = convertMilliseconds(msFromStartDate + (timer?.totalLoggedTimeInMs ?? 0))

            return {
              hours, minutes, seconds
            }
          })
        },
        999)
      setTimerInterval(interval)
    }

    if (timerInterval && isTimerStopped) {
      clearInterval(timerInterval)
      setTimerInterval(null)
    }

  }, [timer])

  return timerDetails ?? {hours: 0, minutes: 0, seconds: 0}
}

export default useTimerHook
