import {Checkbox, FormControlLabel, FormGroup, Stack} from "@mui/material";
import {enCommonLabel, enMemberLabel} from "constants/index";
import React, {Dispatch} from "react";
import {useAccess} from "hooks/index";
import {auth} from "../../../../firebase";
import {Entity} from "enums/entity";
import {PermissionEntity, PermissionOperationKey} from "types/Permission";
import DeleteButton from "components/Button/BulkActionButtons/DeleteButton";
import ChangeRoleButton from "components/Button/BulkActionButtons/ChangeRoleButton";

interface BulkActionsProps {
  isAllSelected: boolean;
  isIndeterminate: boolean;
  totalOwnersCount: number;
  selectedMembersSize: number;
  screenName: string;
  setIsDeleteDialogOpen: Dispatch<boolean>;
  setIsBulkChangeRoleDrawerOpen: Dispatch<boolean>;
  handleSelectAllMembers: (e: React.SyntheticEvent) => void;
}

function BulkActions(props: BulkActionsProps) {
  const {totalOwnersCount, selectedMembersSize, isIndeterminate, screenName, isAllSelected,} = props;
  const {setIsBulkChangeRoleDrawerOpen, setIsDeleteDialogOpen, handleSelectAllMembers} = props;

  const {uid} = auth.currentUser!;

  const [memberAccess] = useAccess({
    uid: uid!,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationMember,
  });

  if (totalOwnersCount <= 0) return null;

  return (
    <FormGroup>
      <Stack gap={1} direction="row">
        <FormControlLabel
          sx={{ml: 1}}
          checked={isAllSelected}
          control={<Checkbox indeterminate={isIndeterminate}/>}
          label={
            <b>
              {selectedMembersSize > 0 ?
                enMemberLabel.selectedMembers(selectedMembersSize) :
                enCommonLabel.allEntity(screenName)}
            </b>
          }
          onChange={handleSelectAllMembers}
        />
        {(selectedMembersSize > 0 && memberAccess) && (
          <>
            <ChangeRoleButton
              disabled={!Boolean(memberAccess[PermissionOperationKey.Update])}
              onButtonClick={() => setIsBulkChangeRoleDrawerOpen(true)}
            />
            <DeleteButton
              disabled={!Boolean(memberAccess[PermissionOperationKey.Delete])}
              onButtonClick={() => setIsDeleteDialogOpen(true)}
            />
          </>
        )}
      </Stack>
    </FormGroup>
  )
}

export default BulkActions;