import {enCommonLabel, enMilestoneLabel, enTaskLabel, initialTask} from "constants/index";
import {BaseSearchInput, DotIndicator, LongText} from "components/index";
import React, {useState} from "react";
import {Hidden, IconButton, Stack, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {styled} from "@mui/material/styles";
import Button, {ButtonProps} from "@mui/material/Button";
import MilestonesFilterDrawer, {TaskFilterProps} from "components/Drawers/MilestoneFilterDrawer/MilestonesFilterDrawer";
import {useComponentToggler, useDebounce} from "hooks/index";
import {FiltersState} from "screens/Home/TaskSearchAndFilters";
import {queryAlgolia} from "screens/Home/queryAlgolia";
import formatDateToYYYYMMDD from "screens/utility/formatDateToYYYYMMDD";
import {areObjectsEqual} from "screens/utility";
import BulkActions, {BulkActionsProps} from "./BulkActions";
import {Task} from "types/Task";
import {Entity} from "enums/entity";
import {useParams} from "react-router-dom";
import StickySearchAndFilter from "components/StickySearchAndFilter";

export interface TaskSearchAndFilterProps extends BulkActionsProps {
  uid: string;
  algoliaPath: string;
  isCollapseAll: boolean;
  parentEntity: Entity;
  setIsCollapseAll: (collapse: boolean) => void;
  setAlgoliaResults: (results: Task[] | null) => void;
  filters: FiltersState;
  projectAssetTemplateId?: string | null;
  setFilters: (filters: FiltersState) => void;
}

function TaskSearchAndFilter(props: TaskSearchAndFilterProps) {
  const {algoliaPath, uid, isCollapseAll, filters, setFilters, setIsCollapseAll, setAlgoliaResults, ...rest} = props;

  const [isFilterDrawerOpen, {open: openFilterDrawer, close: closeFilterDrawer}] = useComponentToggler(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {orgId, projId} = useParams();

  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);

  useDebounce(search, 500, [filters]);

  function applyFiltersButtonClick(drawerFilters: TaskFilterProps) {
    setFilters({
      ...filters,
      assigned: drawerFilters.assignedTo,
      status: drawerFilters.taskStatus,
      schedule: drawerFilters.scheduleStatus,
      date: drawerFilters.dueDate === null ? undefined : formatDateToYYYYMMDD(drawerFilters.dueDate),
    });

    const isFilterUsed = !areObjectsEqual(initialTask, {
      assignedTo: drawerFilters.assignedTo,
      taskStatus: drawerFilters.taskStatus,
      scheduleStatus: drawerFilters.scheduleStatus,
      dueDate: drawerFilters.dueDate ? new Date(drawerFilters.dueDate) : null,
    });

    setIsFilterApplied(isFilterUsed);

    closeFilterDrawer();
  }

  async function search() {
    if (!isFilterApplied && filters.searchText === "") {
      setAlgoliaResults(null);
      return;
    }

    setIsLoading(true);
    let pathFilters: any[] = [];
    if (props.parentEntity === Entity.Asset) {
      if (!!props.projectAssetTemplateId) {
        pathFilters.push([
          `templateColPath:organizations/${orgId!}/templates/${props.projectAssetTemplateId!}`,
          `assetColPath:${algoliaPath}`
        ]);
        pathFilters.push([`entity:Task`, `entity:OrganizationTemplateTask`]);
      } else {
        pathFilters.push(`entity:Task`);
        pathFilters.push(`assetColPath:${algoliaPath}`);
      }
    }

    if (props.parentEntity === Entity.Project) {
      pathFilters.push(`orgId:${orgId!}`);
      pathFilters.push(`projectId:${projId!}`);
      pathFilters.push(`entity:PLTask`);
    }

    const {result} = await queryAlgolia(filters, uid, pathFilters, false, !!props.projectAssetTemplateId);

    if (result === undefined) {
      setAlgoliaResults([]);
      setIsLoading(false);
      return;
    }

    // filter possible duplicates returned by algolia
    const searchResult = result as unknown as Task[];
    const distinctResultIds: Set<string> = new Set(searchResult?.map((task) => task["@id"]!));
    setAlgoliaResults([...Array.from(distinctResultIds).map((id) => result?.find((task) => task["@id"] === id) as Task)]);
    setIsLoading(false);
  }

  return (
    <>
      <StickySearchAndFilter topPosition={50}>
        <Stack
          flex={1}
          direction={{xs: "column", md: "row"}}
          justifyContent="space-between"
          sx={{
            backgroundColor: theme.palette.background.default,
            paddingY: {xs: 0, md: 1}
          }}
          gap={{xs: 1, md: 0}}
        >
          <BaseSearchInput
            id="search-task"
            placeholder={enTaskLabel.search}
            searchFn={(text) => setFilters({...filters, searchText: text})}
            loading={isLoading}
          />
          <Stack direction="row" gap={1}>
            <ColorButton
              id="expand-milestones-btn"
              sx={{
                width: {xs: "100%", sm: "100%", md: "auto", lg: "auto"},
                justifyContent: {xs: "left", sm: "left", md: "right", lg: "right"}
              }}
              endIcon={
                !isCollapseAll ? (
                  <SystemIcons.Collapse
                    stroke={theme.palette.neutral.dark}
                    height={16}
                    width={16}
                  />
                ) : (
                  <SystemIcons.Expand
                    stroke={theme.palette.neutral.dark}
                    height={16}
                    width={16}
                  />
                )
              }
              onClick={() => setIsCollapseAll(!isCollapseAll)}
            >
              <LongText
                variant="h4"
                // sx={{ml: {lg: 1}, color: theme.palette.secondary.main}}
              >
                {!isCollapseAll ? enCommonLabel.collapse : enCommonLabel.expand} {enMilestoneLabel.all}
              </LongText>
            </ColorButton>
            <>
              <Hidden mdDown>
                <IconButton
                  id="filter-milestones-btn"
                  sx={{
                    width: {xs: "100%", sm: "100%", md: "auto", lg: "auto"},
                    justifyContent: {xs: "left", sm: "left", md: "center", lg: "center"}
                  }}
                  onClick={openFilterDrawer}
                >
                  <DotIndicator isVisible={isFilterApplied}>
                    <SystemIcons.Filter stroke={theme.palette.neutral.dark}/>
                  </DotIndicator>
                </IconButton>
              </Hidden>
              <Hidden mdUp>
                <ColorButton
                  onClick={openFilterDrawer}
                  endIcon={(
                    <DotIndicator isVisible={isFilterApplied}>
                      <SystemIcons.Filter stroke={theme.palette.neutral.dark}/>
                    </DotIndicator>
                  )}
                >
                  <Typography
                    variant="h4"
                    sx={{ml: {lg: 1}, color: theme.palette.secondary.main}}
                  >
                    {enCommonLabel.filters}
                  </Typography>
                </ColorButton>
              </Hidden>
            </>
          </Stack>
          <MilestonesFilterDrawer
            isDrawerOpen={isFilterDrawerOpen}
            taskFilters={{
              assignedTo: filters.assigned,
              taskStatus: filters.status,
              scheduleStatus: filters.schedule,
              dueDate: filters.date ? new Date(filters.date) : null,
            }}
            isLoading={isLoading}
            closeFilterDrawer={closeFilterDrawer}
            applySelectedFilter={applyFiltersButtonClick}
            uid={uid}
          />
        </Stack>
      </StickySearchAndFilter>
      <BulkActions {...rest} />
    </>
  )
}

/** start of styles */
const ColorButton = styled(Button)<ButtonProps>(({theme}) => ({
  "&:hover": {
    backgroundColor: theme.palette.neutral.light,
  },
  "&:active": {
    backgroundColor: theme.palette.neutral.light,
  },
}));

/** end of styles */

export default TaskSearchAndFilter;