import {useEffect, useState} from "react";
import {Button, Checkbox, Stack, Typography} from "@mui/material";
import {milestoneTaskandAssetStatus as TaskStatus} from "enums/milestoneTaskandAssetStatus";
import {CheckBoxOutlineBlank} from "@mui/icons-material";
import {FilterPanelProps} from "../FilterPanelProps";
import theme from "theme/theme";
import {enCommonButton, enCommonLabel} from "constants/index";
import MilestoneTaskStatusObjectList from "assets/arrayList/StatusObjectList/MilestoneTaskStatusObjectList";
import {MilestoneAssignedStatus as Status} from "enums/milestoneAssignedStatus";

function StatusPanel(props: FilterPanelProps<TaskStatus>) {
  const {selectedFilter, setSelected} = props;

  const [isStatusClearButtonEnabled, setIsStatusClearButtonEnabled] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState(MilestoneTaskStatusObjectList);

  // check there is a previous selected (from parent), if yes, set checked to true
  useEffect(() => {
    let initialStatus = MilestoneTaskStatusObjectList;
    let newStatus: any = {};

    setIsStatusClearButtonEnabled(selectedFilter.length > 0);

    Object.entries(initialStatus).map(obj => {
      let statusLabel = obj[0];
      let item = obj[1];
      let found = selectedFilter.indexOf(statusLabel as unknown as TaskStatus);

      newStatus[statusLabel] = {...item, checked: (found >= 0)}

      return true;
    });
    setStatusFilter(newStatus);
  }, [selectedFilter]);

  function renderStatusFilter() {
    const statusFilters: any = [];

    Object.entries(statusFilter).forEach((obj, index) => {
      statusFilters.push(
        <Stack direction="row"
               key={index}
               justifyContent="space-between"
               alignItems="center">
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center">
            {obj[1].icon({fill: obj[1].color, ...(obj[0] === TaskStatus.Submitted ? {width: 18}: {})})}
            <Typography ml={1} variant="h4">
              {obj[1].title}
            </Typography>
          </Stack>
          <Checkbox
            id={`status-item-${obj[1].title.split(" ").join("-").toLowerCase()}`}
            icon={
              <CheckBoxOutlineBlank
                sx={{
                  color: theme.palette.neutral.main,
                  borderWidth: "1px"
                }}
              />
            }
            checked={obj[1].checked}
            onChange={() => onStatusChange(obj[0])}/>
        </Stack>
      );
    });

    return statusFilters;
  }

  function onStatusChange(objectName: string) {
    let selected: TaskStatus[] = [];
    const updatedStatusFilter = { ...statusFilter }; // Create a copy of the state

    updatedStatusFilter[objectName].checked = !updatedStatusFilter[objectName].checked;

    setIsStatusClearButtonEnabled(Object.values(updatedStatusFilter).some(obj => obj.checked));

    Object.entries(updatedStatusFilter).forEach(([str, obj]) => {
      if (obj.checked) {
        selected.push(str as unknown as TaskStatus);
      }
    });

    setSelected(selected);
  }


  return (
    <Stack sx={{display: "flex", mt: 1, mb: 1}}
           direction="column"
           alignContent="center">
      <Stack direction="row"
             justifyContent="space-between"
             alignItems="center">
        <Typography variant="h4" sx={{fontSize: "14px"}}>
          {enCommonLabel.status}
        </Typography>
        <Button
          id="filter-status-clear-button"
          disabled={!isStatusClearButtonEnabled}
          onClick={() => setSelected([])}>
          {enCommonButton.clear}
        </Button>
      </Stack>
      {renderStatusFilter()}
    </Stack>
  );
}

export default StatusPanel;