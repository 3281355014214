import OrganizationsList from "./OrganizationsList";
import {Organization} from "types/Organization";
import {BaseProps} from "../../BaseProps";
import EmptySearchResults from "components/EmptySearchResults";
import {Entity} from "enums/entity";

interface DynamicViewProps extends BaseProps {
  organizations: Organization[];
  fromAlgolia: boolean;
  parentPath: string;
  updateSelected: (obj: Organization | null) => void;
  deleteOrg: (org: Organization) => void;
}

function DynamicView(props: DynamicViewProps) {
  const {organizations, fromAlgolia, setSelectedOrgId} = props

  if (organizations.length === 0 && fromAlgolia)
    return <EmptySearchResults entity={Entity.Organization}/>

  return (
    <OrganizationsList
      setSelectedOrgId={setSelectedOrgId}
      {...props}
    />
  )
}

export default DynamicView