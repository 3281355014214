import {BaseProps} from "screens/BaseProps";
import {TeamCategory} from "types/index";
import {EmptyList} from "components/index";
import {ActionType, Entity} from "enums/index";
import theme from "theme/theme";
import React from "react";
import TeamCategoryList from "./TeamCategoryList";
import {AccessType} from "types/Permission";

interface MainContentProps extends BaseProps {
  categoryAccess: AccessType | null;
  organizationId: string;
  teamCategoriesCount: number;
  onItemActionClick: (action: ActionType, teamCategory: TeamCategory) => void;
}

function MainContent(props: MainContentProps) {
  const {organizationId, categoryAccess, onItemActionClick} = props;

  if((props.teamCategoriesCount ?? 0) <= 1)
    return <EmptyList
      entity={Entity.ManageTeamCategories}
      logoProperties={{
        height: 64,
        width: 64,
        stroke: theme.palette.neutral.light,
      }}
    />

  return (
    <TeamCategoryList
      uid={props.uid}
      categoryAccess={categoryAccess}
      organizationId={organizationId}
      onItemActionClick={onItemActionClick}
    />
  )
}

export default MainContent;