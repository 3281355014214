import React, {Dispatch, useEffect, useState} from "react";
import {BaseSearchInput, DotIndicator, LongText} from "components/index";
import {enCommonLabel, enHomeLabel, enTaskLabel} from "constants/index";
import {IconButton, MenuItem, Select, Stack} from "@mui/material";
import {ExportTaskType} from "enums/exportTaskType";
import {SystemIcons} from "assets/icons/system/system.index";
import {useCollection, useComponentToggler, useDebounce} from "hooks/index";
import {Organization} from "types/Organization";
import {organizationsPath, submitForm, userPath} from "../utility";
import MilestonesFilterDrawer, {TaskFilterProps} from "components/Drawers/MilestoneFilterDrawer/MilestonesFilterDrawer";
import {MilestoneAssignedStatus} from "enums/milestoneAssignedStatus";
import {milestoneTaskandAssetStatus} from "enums/milestoneTaskandAssetStatus";
import {MilestoneScheduleStatus as ScheduleStatus} from "enums/milestoneScheduleStatus";
import formatDateToYYYYMMDD from "../utility/formatDateToYYYYMMDD";
import {DeleteDialog} from "components/Dialogs";
import {bulkStatusSubmitHandler, statusSubmitHandler} from "../utility/statusSubmitHandler";
import {toastProps} from "../BaseProps";
import {Severity} from "enums/severity";
import {where} from "firebase/firestore";
import {en} from "language/en";
import StickySearchAndFilter from "components/StickySearchAndFilter";
import {ActionType} from "enums/actionType";

const DefaultSelectedOrg = {
  id: "allOrgs",
  title: "All Organizations",
}

export interface FiltersState {
  searchText: string;
  status: milestoneTaskandAssetStatus[];
  assigned: MilestoneAssignedStatus[];
  schedule: ScheduleStatus[];
  date: string | undefined; // date format: YYYY-MM-DD
  exportTasksType: ExportTaskType;
  selectedOrg: typeof DefaultSelectedOrg;
}

export interface TaskSearchAndFiltersProps {
  uid: string;
  visible?: boolean;
  filters: FiltersState;
  loading: boolean;
  isFiltered: boolean;
  setFilters: Dispatch<FiltersState>;
  toastProps: toastProps;
  approvedTasksLength: number;
  reFetchTotalCount: () => void;
  algoliaFilters: any;
}

function TaskSearchAndFilters(props: TaskSearchAndFiltersProps) {
  const {visible = false, filters, uid, approvedTasksLength, setFilters, toastProps, algoliaFilters, reFetchTotalCount} = props;
  const {setToastMessage, setIsToastOpen, setToastSeverity} = toastProps;

  // filter related states
  const [organizations] = useCollection<Organization>(null, organizationsPath(), [where("@allowedUsers", "array-contains", uid)]);
  const [selectableOrgs, setSelectableOrgs] = useState<typeof DefaultSelectedOrg[]>([]);
  const [isFilterDrawerOpen, {open: openFilterDrawer, close: closeFilterDrawer}] = useComponentToggler(false);

  // delete related states
  const [isDeleteDialogOpen, {open: showDeleteDialog, close: closeDeleteDialog}] = useComponentToggler(false);
  const [submittedData, setSubmittedData] = useState<any []>([]);
  const [deleteLength, setDeleteLength] = useState<number>(0);
  const [searchText, setSearchText] = useState("")

  useDebounce(async () => {
    setFilters({...filters, searchText})
  }, 500, [searchText])

  // add to selectableOrgs
  useEffect(() => {
    if (!organizations) {
      setSelectableOrgs([DefaultSelectedOrg]);
      return;
    }

    const selectOptionsData = organizations.map(org => {
      return {
        id: org["@id"] ?? "fillerId",
        title: org.name,
      }
    });

    setSelectableOrgs([DefaultSelectedOrg, ...selectOptionsData]);
  }, [organizations]);

  useEffect(() => {
    if (submittedData.length === 0 || submittedData.length !== deleteLength) return;

    bulkStatusSubmitHandler({
      data: submittedData,
      successCallback: deleteSuccessCallback,
      errorCallback: deleteErrorCallback
    });
    setSubmittedData([]);
    setDeleteLength(0);
  }, [submittedData]);

  function deleteSuccessCallback() {
    reFetchTotalCount();
    setToastMessage(enHomeLabel.allTasksDeleted);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    closeDeleteDialog();
  }

  function deleteErrorCallback() {
    setToastMessage(enHomeLabel.removeTaskFailed);
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
    closeDeleteDialog();
  }

  function applyFiltersButtonClick(drawerFilters: TaskFilterProps) {
    setFilters({
      ...filters,
      assigned: drawerFilters.assignedTo,
      status: drawerFilters.taskStatus,
      schedule: drawerFilters.scheduleStatus,
      date: drawerFilters.dueDate === null ? undefined : formatDateToYYYYMMDD(drawerFilters.dueDate),
    })
    closeFilterDrawer();
  }

  async function deleteAllListedTasks() {

    if (props.isFiltered) {
      await submitForm(
        userPath(uid!),
        ActionType.Update,
        (status, data, isLastUpdate) => statusSubmitHandler({status, data, isLastUpdate, successCallback: deleteSuccessCallback, errorCallback: deleteErrorCallback}),
        {filterState: algoliaFilters}
      );
      return;
    }

    await submitForm(
      userPath(uid!),
      ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler({status, data, isLastUpdate, successCallback: deleteSuccessCallback, errorCallback: deleteErrorCallback}),
      {filterState: null}
    );
  }

  if (!visible) return null;

  return (
    <>
      <MilestonesFilterDrawer
        uid={uid}
        isDrawerOpen={isFilterDrawerOpen}
        taskFilters={{
          assignedTo: filters.assigned,
          taskStatus: filters.status,
          scheduleStatus: filters.schedule,
          dueDate: filters.date ? new Date(filters.date) : null,
        }}
        isLoading={false}
        closeFilterDrawer={closeFilterDrawer}
        applySelectedFilter={applyFiltersButtonClick}
      />
      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        title={approvedTasksLength > 0 ? enHomeLabel.clearAllApprovedTasksTitle : enHomeLabel.noApprovedTasksTitle}
        text={approvedTasksLength > 0 ? enHomeLabel.clearAllApprovedTasksText : enHomeLabel.noApprovedTasksText}
        confirmButtonText={en.common.button.clear}
        handleClose={closeDeleteDialog}
        handleConfirm={deleteAllListedTasks}
        disableConfirmButton={approvedTasksLength === 0}
      />
      <StickySearchAndFilter topPosition={0}>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" flexWrap="wrap">
          <BaseSearchInput
            id="taskSearchInput"
            placeholder={enTaskLabel.search}
            textLabel={searchText}
            searchFn={setSearchText}
            loading={props.loading}
          />
          <Stack direction="row" alignItems="center" flexWrap="wrap" alignSelf="flex-end">
            <Select
              defaultValue={filters.selectedOrg.id}
              variant="standard"
              disableUnderline
              onChange={(e) => setFilters({
                ...filters,
                selectedOrg: selectableOrgs.find(org => org.id === e.target.value) ?? DefaultSelectedOrg
              })}
              value={filters.selectedOrg.id}
              sx={{pl: 0, maxWidth: "100%", whiteSpace: "unset", paddingLeft: 0, paddingRight: 0}}
            >
              {
                selectableOrgs.map((org) => (
                  <MenuItem key={`orgFilterItem_${org.id}`} value={org.id} sx={{whiteSpace: "unset"}}>
                    <LongText delay={250} maxWidth={"50ch"} variant="h5">{org.title}</LongText>
                  </MenuItem>
                ))
              }
            </Select>
            <IconButton onClick={openFilterDrawer}>
              <DotIndicator isVisible={props.isFiltered}>
                <SystemIcons.Filter/>
              </DotIndicator>
            </IconButton>
            <a title={enCommonLabel.clearApproved}>
              <IconButton onClick={showDeleteDialog}>
                <SystemIcons.ClearApproved width={25} height={25}/>
              </IconButton>
            </a>
          </Stack>
        </Stack>
      </StickySearchAndFilter>
    </>
  )
}

export default TaskSearchAndFilters