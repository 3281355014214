import {ActionType, Entity, FileStatus, Previews, Severity} from "enums/index";
import React, {Dispatch, useContext, useRef, useState} from "react";
import {SwiftFile} from "types/index";
import {Box, Menu, MenuItem, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {ConfirmDialog, PreviewItem} from "components/index";
import {styled} from "@mui/system";
import {ChatIconButton} from "components/Button";
import {emptyFunction, enCommonButton, enFileRequirementLabel} from "constants/index";
import {getDocPath, submitForm} from "../../utility";
import {CollectionReference, doc} from "firebase/firestore";
import {AccessType, PermissionOperationKey} from "types/Permission";
import getEnvKey from "../../utility/getEnvKey";
import {downloadUrlKey} from "constants/envKeys";
import {statusSubmitHandler} from "../../utility/statusSubmitHandler";
import {toastProps} from "../../BaseProps";
import {UploadContext} from "hooks/useFileUpload/context/UploadContext";
import {UploadActionType} from "hooks/useFileUpload/reducer/UploadActionType";
import {Buffer} from "buffer";
import {useRTDBDocField} from "hooks/index";

const DOWNLOAD_URL = getEnvKey(downloadUrlKey) || "";

interface PreviewBlockProps {
  uid: string;
  fileAccess: AccessType | null;
  icon: JSX.Element;
  fileStatus: FileStatus;
  contentType: Previews;
  file: SwiftFile;
  previewUrl?: string;
  onChatClick?: (file: SwiftFile) => void;
  collectionRef: CollectionReference;
  onFilePreviewClick?: () => void;
  isMoreActionsOpen: boolean;
  setIsMoreActionsOpen: Dispatch<boolean>;
  toastProps: toastProps;
  isLoading?: boolean;
}

function PreviewBlock(props: PreviewBlockProps) {
  const {
    fileAccess,
    fileStatus,
    contentType,
    previewUrl,
    file,
    onChatClick,
    collectionRef,
    onFilePreviewClick,
    isMoreActionsOpen,
    setIsMoreActionsOpen,
    isLoading = false
  } = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = props.toastProps;

  const context = useContext(UploadContext);

  // user specific data:
  const userDocPath = getDocPath(props.uid, collectionRef.path, file["@id"]!);
  const chatEmpty = useRTDBDocField<boolean>(collectionRef.path+"/"+file["@id"], "chatEmpty") ?? true;
  const hasNewChat = useRTDBDocField<boolean>(userDocPath, "hasNewChat") ?? false;

  const {"@id": id = "fillerId", name} = file;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const anchorEl = useRef<any>(null);

  const canDelete = Boolean(fileAccess?.[PermissionOperationKey.Delete]);

  const base64 = Buffer.from(doc(collectionRef, id).path, 'utf-8').toString('base64');
  const downloadUrl = DOWNLOAD_URL+`${base64}`;

  function onDeleteFileClick() {
    setIsMoreActionsOpen(false);
    setIsDeleteDialogOpen(true);
  }

  async function deleteFile() {
    const currentId = file["@id"]!;
    const docReference = doc(collectionRef, currentId);
    await submitForm(docReference, ActionType.Delete,
      (status, data, isLastUpdate) =>
        statusSubmitHandler({
          status,
          data,
          isLastUpdate,
          successCallback: () => {
            showMessage(enFileRequirementLabel.deleteFileSuccess, Severity.Success);
            if (!context) return;
            context.fileUploadDispatch({
              type: UploadActionType.addToDeletedIds,
              payload: currentId
            });
          },
          errorCallback: (error) => {
            showMessage((typeof error === 'string' ? error : undefined) || enFileRequirementLabel.deleteFileError, Severity.Error);
          }})
    )
  }

  function showMessage(message: string, severity: Severity) {
    setToastSeverity(severity);
    setToastMessage(message);
    setIsToastOpen(true);
    setIsDeleteDialogOpen(false)
    setIsMoreActionsOpen(false);
  }

  function onCardRightClick(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    setIsMoreActionsOpen(true);
  }

  return <>
    <ConfirmDialog
      isOpen={isDeleteDialogOpen}
      handleClose={() => setIsDeleteDialogOpen(false)}
      handleConfirm={deleteFile}
      title={enFileRequirementLabel.fileDeleteConfirmationTitle}
      text={enFileRequirementLabel.fileDeleteConfirmationText}
    />
    <Menu
      anchorEl={anchorEl?.current}
      open={isMoreActionsOpen}
      onClose={() => setIsMoreActionsOpen(false)}
    >
      <a
        href={`${downloadUrl}?useOriginal=true`}
        style={{textDecoration: "none", color: theme.palette.secondary.main}}
      >
        <MenuItem sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 1, py: 0.5}}>
          <SystemIcons.Download stroke={theme.palette.primary.main} width={16} height={16} strokeWidth={2}/>
          <Typography variant="h5" color={theme.palette.common.black}>{enCommonButton.downloadFile}</Typography>
        </MenuItem>
      </a>
      <MenuItem
        sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 1, py: 0.5}}
        onClick={canDelete ? onDeleteFileClick : emptyFunction}
        disabled={!canDelete}
      >
        <SystemIcons.DeleteOutlined stroke={theme.palette.error.main} width={16} height={16} strokeWidth={2}/>
        <Typography variant="h5" color={theme.palette.error.main}>{enCommonButton.delete}</Typography>
      </MenuItem>
    </Menu>
    <Stack
      display={"flex"}
      position={!previewUrl ? "relative" : "inherit"}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      padding={1}
    >
      <IconOnly>
        <ChatIconButton
          id={`${file["@id"]}-file-item-chat-button`}
          entity={Entity.File}
          withNewMessage={hasNewChat}
          chatEmpty={chatEmpty}
          onClickFcn={(e) => {
            e.stopPropagation();
            onChatClick && onChatClick(file)
          }}
        />
      </IconOnly>
      <StatusWithIcon
        padding="4px 1rem"
        direction="row"
        gap={1}
        alignItems="center"
      >
        <Typography
          variant="h5"
          color={theme.palette.common.black}
          id={`file-list-item-status-${id}`}
        >
          {fileStatus}
        </Typography>
      </StatusWithIcon>
      <Stack direction="row" gap={1} alignItems="center">
        <IconOnly ref={anchorEl} onClick={(e) => {
          e.stopPropagation();
          setIsMoreActionsOpen(true);
        }}>
          <SystemIcons.MoreVertical fill={theme.palette.text.primary} width={18} height={18}/>
        </IconOnly>
      </Stack>
    </Stack>
    <Box
      onContextMenu={onCardRightClick}
      onClick={onFilePreviewClick}
      minWidth="296px"
      minHeight="200px"
      position="relative"
      sx={() => ({
        borderRadius: "4px 4px 0 0",
        backgroundColor: theme.palette.neutral.medium,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: !previewUrl ? null : `url(${previewUrl})`,
        cursor: "pointer",
      })}
      id={`file-list-item-preview-box-${id}`}
    >
      {!previewUrl && <PreviewItem
        filePreview={{downloadUrl: previewUrl, contentType: contentType, fileStatus, id, name}}
        itemSx={{
          pointerEvents: "none",
          display: "grid",
          placeItems: "center",
          position: "absolute",
          left: 0,
          top: 0,
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
        }}
        iconSize={42}
        onClick={emptyFunction}
        isLoading={isLoading}
      />}
    </Box>
  </>
}

/** styles **/
const StatusWithIcon = styled(Stack)({
  borderRadius: "4px",
});

const IconOnly = styled(Box)({
  width: "32px",
  height: "32px",
  borderRadius: "4px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer"
});

export default PreviewBlock;
