import {CustomTab, CustomTabLabel, CustomTabs, DrawerProps, SimpleFormDrawer, TabPanel} from "components/index";
import {en} from "language/en";
import React, {useEffect, useMemo, useState} from "react";
import MyTimersView from "components/Timers/TimersViews/MyTimersView";
import {organizationMembersPath, organizationPath, userPath} from "screens/utility/FirebasePath";
import {useCollection, useRTDBDocField} from "hooks/index";
import {Member} from "types/index";
import {CounterFields, AccessRole} from "enums/index";
import OrganizationTimersView from "components/Timers/TimersViews/OrganizationTimersView";
import useManualParams from "hooks/useManualParams";
import {getDocPath} from "screens/utility";

const enTimers = en.screen.Sidebar.timers

enum TabIndex {
  MyTimers,
  OrganizationTimers
}

interface TimerProps extends DrawerProps {
  uid: string;
}

function Timers(props: TimerProps) {
  const {uid, ...rest} = props;
  const {organizations} = useManualParams()

  const myTimersCount = useRTDBDocField<number>(userPath(uid).path, CounterFields.MyTimersCount)
  const orgTimersCount = useRTDBDocField<number>(getDocPath(uid, organizationPath(organizations ?? "org").path), CounterFields.OrganizationTimersCount)
  const [orgMembers, , updateColRef] = useCollection<Member>(null, null);
  const [activeTab, setActiveTab] = useState<TabIndex>(TabIndex.MyTimers);

  const role = useMemo(() => getRoleFromMembers(orgMembers, uid), [orgMembers, uid])
  const isAllowedToViewOrgTimers = useMemo(() => role === AccessRole.Admin || role === AccessRole.Owner,[role])

  //listen when manual org param loads
  useEffect(() => {
    if (!organizations) return;

    updateColRef(organizationMembersPath(organizations));
  }, [organizations])

  return <SimpleFormDrawer title={enTimers.title} {...rest} >
    <CustomTabs
      value={activeTab}
      onChange={(_, newValue) => setActiveTab(newValue)}
    >
      <CustomTab
        label={
          <CustomTabLabel
            label={enTimers.myTimers}
            count={myTimersCount ?? 0}
          />
        }
      />
      {
        isAllowedToViewOrgTimers &&
        <CustomTab
          sx={{marginLeft: 5}}
          label={
            <CustomTabLabel
              label={enTimers.organizationTimers}
              count={orgTimersCount ?? 0}
            />
          }
        />
      }
    </CustomTabs>
    <TabPanel tabIndex={TabIndex.MyTimers} value={activeTab} sx={{height: "100%"}}>
      <MyTimersView emptyPage uid={uid}/>
    </TabPanel>
    {
      isAllowedToViewOrgTimers &&
      <TabPanel tabIndex={TabIndex.OrganizationTimers} value={activeTab} sx={{height: "100%"}}>
        <OrganizationTimersView uid={uid}/>
      </TabPanel>
    }
  </SimpleFormDrawer>
}

function getRoleFromMembers(members: Member[] | null, ownerId: string): AccessRole | null {
  if (!members)
    return null

  const ownerDoc = members.find((member) => member.uid === ownerId);
  if (!ownerDoc)
    return null

  return ownerDoc.role
}

export default Timers
