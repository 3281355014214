import { MilestoneScheduleStatus as Status } from "../../enums/milestoneScheduleStatus";

export type ScheduleItem = {
    selected: boolean;
}

export type MilestoneScheduleProps = Record<string, ScheduleItem>;

export const MilestoneScheduleStatus: MilestoneScheduleProps = {
    [Status.Complete]: { selected: false },
    [Status.PastDue]: { selected: false },
    [Status.Upcoming]: { selected: false },
    [Status.Scheduled]: { selected: false },
    [Status.NotScheduled]: { selected: false }
}