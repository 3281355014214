import {Timer} from "types/Timer";
import PausedTimerCard from "components/Timers/TimerCard/PausedTimerCard";
import BaseTimersList, {TimerLoadFunctions,} from "components/Timers/TimersList/BaseTimersList";
import {Dispatch, useEffect, useState} from "react";
import {CollapseStates} from "components/Timers/TimersViews/MyTimersView";
import {useCollection} from "hooks/index";
import {CollectionReference, limit, QueryConstraint, where} from "firebase/firestore";
import {TimerState} from "types/Task";
import {baseListCount, listIncrementCount} from "components/Timers/constants";

interface PausedTimersListProps extends TimerLoadFunctions {
  uid: string;
  organizationTimer?: boolean
  collapsible?: boolean
  collapseStates? : CollapseStates
  setCollapseStates?: Dispatch<CollapseStates>
  colRef: CollectionReference
  totalPausedCount: number
}

function PausedTimersList(props: PausedTimersListProps) {
  const {
    organizationTimer,
    collapsible = false,
    collapseStates,
    setCollapseStates,
    colRef,
    totalPausedCount
  } = props;

  const [limitCount, setLimitCount] = useState(baseListCount);
  const [isListLoading, setIsListLoading] = useState(false);
  const [timers, setTimersConstraints, setTimersColRef] = useCollection<Timer>(null, null, undefined, false, true)

  const timersComponentsMap =  (timers ? timers.slice(0, limitCount - 1) : []).map((timer) => <PausedTimerCard
    collapsible={collapsible}
    timer={timer}
    organizationTimer={organizationTimer}
    key={timer["@id"] + "paused"}
    isListLoading={isListLoading}
    uid={props.uid}
  />)

  useEffect(() => {
    const queryConstraints: QueryConstraint[] = organizationTimer || collapsible ? [limit(limitCount)] : []
    queryConstraints.push(where("timerState", "in", [TimerState.Paused]))
    setTimersConstraints(queryConstraints);
  }, [])

  useEffect(() => {
    setTimersColRef(colRef)
  }, [colRef])

  function onLoadMoreClick() {
    setLimitCount(limitCount + listIncrementCount);
    const queryConstraints: QueryConstraint[] = [limit(limitCount + listIncrementCount)]
    queryConstraints.push(where("timerState", "in", [TimerState.Paused]))
    setTimersConstraints(queryConstraints)
  }

  function onSeeLessClick(){
    setLimitCount(baseListCount);
    const queryConstraints: QueryConstraint[] = [limit(baseListCount)]
    queryConstraints.push(where("timerState", "in", [TimerState.Paused]))
    setTimersConstraints(queryConstraints)
  }

  return <BaseTimersList
    uid={props.uid}
    totalCount={totalPausedCount}
    listCount={limitCount}
    collapseStates={collapseStates}
    setCollapseStates={setCollapseStates}
    collapsible={collapsible}
    onLoadMoreClick={onLoadMoreClick}
    onSeeLessClick={onSeeLessClick}
    setIsListLoading={setIsListLoading}
    timers={timers ?? []}
    timersComponentsMap={timersComponentsMap}
    timersListType="Paused"
    organizationTimer={organizationTimer}
  />
}

export default PausedTimersList
