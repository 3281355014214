import {InvitationStatus} from "enums/invitationStatus";
import {AcceptedTag, CancelledTag, DeclinedTag} from "components/Tags";
import {AcceptButton, DeclineButton} from "components/Button";

interface StatusButtonsProps {
  isAccepting?: boolean;
  isDeclining?: boolean;
  status: InvitationStatus;
  processInvite: (status: InvitationStatus) => void;
}

function StatusButtons(props: StatusButtonsProps) {
  const {isAccepting = false, isDeclining = false, status, processInvite} = props;

  switch (status) {
    case InvitationStatus.Cancelled:
      return <CancelledTag/>;
    case InvitationStatus.Expired:
      return <></>;
    case InvitationStatus.Accepted:
      return <AcceptedTag/>;
    case InvitationStatus.Declined:
      return <DeclinedTag/>;
    default:
      return (
        <>
          <DeclineButton
            onClick={() => processInvite(InvitationStatus.Declined)}
            loading={isDeclining}
            disabled={isAccepting || isDeclining}
          />
          <AcceptButton
            onClick={() => processInvite(InvitationStatus.Accepted)}
            loading={isAccepting}
            disabled={isAccepting || isDeclining}
          />
        </>
      )
  }
}

export default StatusButtons;
