import {Typography} from "@mui/material";
import {LongText} from "components/index";
import {Entity} from "enums/entity";

export const activityFeed = {
  title: "Activity Feed",
  label: {
    recent: "Recent activities",
    teamsAndPeople: "Teams & People",
    projects: "Projects",
    assets: "Assets",
    tasks: "Tasks",
    requirements: "Requirements",
    files: "Files",
    invites: "Invites",
    timers: "Timers",
    invitationToJoin: (orgName: string, by: string) => {
      return <Typography>
        You have been invited to join<strong>{` ${orgName} `}</strong>organization by <strong>{by}</strong>
      </Typography>
    },
    thirdPartyInvitationToJoin: (orgName: string, by: string, from: string, email: string) => {
      return <Typography>
        <strong>{from}{" "}{email}</strong> have been invited to join<strong>{` ${orgName} `}</strong>organization
        by <strong>{by}</strong>
      </Typography>
    },
    noMoreActivities: "No more activities",
    invitationAccepted: "Invitation Accepted",
    invitationDeclined: "Invitation Declined",
    invitationResent: "Invitation Resent",
    inviteSent: "Invite Sent",
    invitedCancelled: "Invite Cancelled",
    emptyTitle: "No Activities yet",
    emptyActivityFeedDescription: "When you get notifications they will show up here.",
    statusChange: (to: string, from: string, by: string, name: string, entity: string, reasonForChange?: string) => {
      return <>
        <Typography>
          <strong>{entity + " "}</strong>
          <strong>{name}</strong> Status changed
          to <strong>{to}</strong> from <strong>{from}</strong> by <strong>{by}</strong>.
        </Typography>
        {
          reasonForChange && <LongText maxLines={2}>
            Reason for change: <strong>{reasonForChange}</strong>
          </LongText>
        }
      </>;
    },
    unassigned: (by: string) => {
      return <Typography>
        Unassigned by <strong>{by}</strong>.
      </Typography>;
    },
    assigned: (to: string, by: string, name: string, entity?: string) => {
      return <Typography>
        {entity && <strong>{`${entity} `}</strong>}<strong>{name}</strong> Assigned
        to <strong>{to}</strong> by <strong>{by}</strong>.
      </Typography>;
    },
    updated: (entity: Entity, name: string, by: string) => {
      return <>
        <LongText maxLines={3}>
          Following updates were made to{" "}
          <strong>{ENTITY_LABEL[entity]}</strong>{" "}
          <strong>{name}</strong> by <strong>{by}</strong>
        </LongText>
      </>
    },
    updatedChangesDescription: (from: any = {}, to: any = {}) => {
      const renderValue = (value: any, key: string) => {
        if (key === "dueDate" && value) {
          const {seconds, _seconds, nanoseconds, _nanoseconds} = value;

          const parsedSeconds = seconds ?? _seconds ?? 0;
          const parsedNanoSeconds = nanoseconds ?? _nanoseconds ?? 0;

          const fireBaseTime = new Date(parsedSeconds * 1000 + parsedNanoSeconds / 1000000);

          return fireBaseTime.toDateString();
        }
        if (typeof value === "object" && value !== null) {
          // Render object as JSON string
          return value.name ?? "";
        } else {
          // Render string value
          return value ?? "";
        }
      };

      const transformCamelCase = (str: string) => {
        return str.replace(/([a-z])([A-Z])/g, (_, m1, m2) => {
          return `${m1} ${m2.toLowerCase()}`;
        });
      }

      return <>
        {Object.keys({...from, ...to}).map(key => {
          if (!renderValue(from[key], key))
            return <LongText maxLines={3}>
              Set <strong>{transformCamelCase(key)}</strong> to <strong>{renderValue(to[key], key)}</strong>
            </LongText>

          if (!renderValue(to[key], key))
            return <LongText maxLines={3}>
              Removed <strong>{transformCamelCase(key)}</strong> <strong>{renderValue(from[key], key)}</strong>
            </LongText>

          return <LongText maxLines={3}>
            Changed <strong>{transformCamelCase(key)}</strong> from <strong>{renderValue(from[key], key)}</strong> to <strong>{renderValue(to[key], key)}</strong>
          </LongText>
        })}
      </>
    },
    answeredForm: (name: string, by: string) => {
      return <Typography>
        Form <strong>{name}</strong> answered by <strong>{by}</strong>.
      </Typography>;
    },
    newMember: (member: string, by: string) => {
      return <LongText maxLines={3}>
        <strong>{member}</strong> was added by <strong>{by}</strong>.
      </LongText>;
    },
    reasonForChange: (reason: string) => (<LongText>Reason for change: <strong>{reason}</strong></LongText>),
    removedMember: (member: string, by: string) => <LongText maxLines={3}>
      <strong>{member}</strong> was removed by <strong>{by}</strong>.
    </LongText>,
    scheduled: (schedule: string, by: string) => {
      return <LongText maxLines={3}>
        Task scheduled on <strong>{schedule}</strong> added by <strong>{by}</strong>.
      </LongText>;
    },
    created: (entity: string, name: string, by: string) => {
      return <LongText maxLines={3}>
        <strong>{ENTITY_LABEL[entity]}</strong>{" "}
        <strong>{name}</strong> created by <strong>{by}</strong>.
      </LongText>;
    },
    addedTo: (name: string, entity: string, by: string) => {
      return <LongText maxLines={3}>
        <strong>{name}</strong> added to <strong>{entity}</strong> by <strong>{by}</strong>.
      </LongText>;
    },
    deleted: (entity: string, name: string, by: string) => {
      return <LongText maxLines={3}>
        {entity} <strong>{name}</strong> deleted by <strong>{by}</strong>.
      </LongText>;
    },
    fileStatusChange: (status: string, by: string, name: string) => {
      return <LongText maxLines={3}>
        File <strong>{name}</strong> has been <strong>{status}</strong> by <strong>{by}</strong>.
      </LongText>;
    },
    failedUpload: (by: string, name: string) => {
      return <LongText maxLines={3}>
        File <strong>{name}</strong> upload by <strong>{by}</strong> Failed.
      </LongText>;
    },
    profileUploadSuccess: (status: string, by: string, name: string, timeCreated: string) => {
      return <LongText maxLines={3}>
        File <strong>{name}</strong> has been
        successfully <strong>{status}</strong> by <strong>{by}</strong> at {timeCreated}.
      </LongText>;
    },
    uploading: "Uploading...",
    overdue: <Typography>Task is now <strong>Overdue</strong></Typography>,
    dueSoon: <Typography>Task is now <strong>due soon</strong></Typography>,
    activityPinned: "Activity Pinned",
    unpinnedActivity: "Unpinned Activity",
    invitePinned: "Invite Pinned",
    unpinnedInvite: "Unpinned Invite",
  },
  button: {
    decline: "Decline",
    declined: "Declined",
    accept: "Accept",
    accepted: "Accepted",
    resend: "Resend",
  }
}

//temp work around
const ENTITY_LABEL: Record<string, string> = {
  "User": "User",
  "Organization": "Organization",
  "Project": "Project",
  "Asset": "Asset",
  "Milestone": "Milestone",
  "PLTask": "Project Level Task",
  "Task": "Task",
  "FileRequirement": "File Requirement",
  "File": "File",
  "FormRequirement": "Form Requirement",
  "Form": "Form",
  "Invite": "Invite",
  "AccessList": "Access List",
  "Member": "Member",
  "Teams": "Teams",
  "ManageTeamCategories": "Manage Team Categories",
  "TeamsAndPeople": "Teams & People",
  "ActivityFeed": "Activity Feed",
  "Chat": "Chat",
  "ProjectLevelTask": "Project Level Task",
  "Templates": "Templates",
  "TimeLogs": "TimeLog",
  "DataTag": "Data Tag",
  "Timers": "Timers",
  "ReferenceFiles": "Reference Files",
  "OrganizationInvite": "Organization Invite",
  "UserInvite": "User Invite",
  "PLFileRequirement": "File Requirement",
  "PLFormRequirement": "Form Requirement",
  "SwiftFile": "File",
  "PLFile": "File",
  "PLMilestone": "Milestone",
  "RolesAndPermissions": "Roles & Permissions",
}
