import {Box, Stack} from "@mui/material";
import SystemIndex from "assets/icons/system/system.index";
import {AnsweredFormItemType} from "types/ActivityFeedTypes";
import TimestampTypo from "components/Typography/TimestampTypo";
import {enActivityFeedLabel} from "constants/index";
import {formatDateTimeFirestoreDate} from "screens/utility/formatDateTimeFirestoreDate";
import ItemContainer from "components/ActivityFeed/ItemContainer";

interface AnsweredItemProps {
  item: AnsweredFormItemType
}

function AnsweredItem(props: AnsweredItemProps) {
  const {item} = props;
  const {details, timestamp} = item;
  const {by, name} = details;
  const {date, time} = formatDateTimeFirestoreDate(timestamp);

  return (
    <ItemContainer>
      <Box width={24} height={24}>
        <SystemIndex.SystemIcons.Edit width={24} height={24}/>
      </Box>
      <Stack direction="column">
        {enActivityFeedLabel.answeredForm(name, by.name ?? "")}
        <TimestampTypo>{`${date} ${time}`}</TimestampTypo>
      </Stack>
    </ItemContainer>
  );
}

export default AnsweredItem;
