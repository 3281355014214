import React, {useContext, useEffect, useMemo, useState} from "react";
import {Fab, Grid, Stack, useMediaQuery} from "@mui/material";
import {Route, Routes, useNavigate} from "react-router-dom";
import {AccessRole, ActionType, Entity, Severity} from "enums/index";
import {Member, Organization, Team, User} from "types/index";
import Home from "screens/Home";
import ExploreOrganizations from "screens/ExploreOrganizations";
import Projects from "screens/Projects";
import FileUploadPanel from "screens/FileUploadPanel";
import {Loader, RestrictedPage, RouteToSelected, Sidebar, Toast} from "components";
import ProjectOverview from "./ProjectOverview";
import AssetOverview from "./AssetOverview";
import TaskOverview from "./TaskOverview";
import FileRequirementOverview from "./FileRequirementOverview";
import ManageProjectAssets from "./ManageProjectAssets";
import PageNotFound from "./PageNotFound";
import ActivityFeed from "./ActivityFeed";
import OrgAssets from "./OrgAssets";
import TeamsAndPeople from "./TeamsAndPeople";
import Calendar from "./Calendar";
import ReferenceFiles from "./ReferenceFiles";
import Forms from "./Forms";
import AdminPanel from "./AdminPanel";
import NoPermission from "./NoPermission";
import Templates from "./Templates";
import ViewInvitation from "./ViewInvitation";
import ManageTeamCategories from "./ManageTeamCategories";
import OrgAssetsOverview from "./OrgAssetsOverview";
import ProjectLevelTasks from "./ProjectLevelTasks";
import {FormEditCreate} from "./FormEditCreate";
import {useCollection, useComponentToggler, useDebounce, useDocument} from "hooks/index";
import {auth, getPushToken} from "../firebase";
import {signOut} from "firebase/auth";
import {
  configPath,
  getSuperUid,
  organizationMembersPath, organizationPath,
  submitForm,
  teamsPath,
  userPath
} from "./utility";
import FormRequirementOverview from "./FormRequirementOverview";
import {MenuRounded} from "@mui/icons-material";
import theme from "theme/theme";
import ProfileAndSettings from "./ProfileAndSettings";
import VideoCall from "components/VideoCall";
import TemplatesOverview from "./TemplatesOverview";
import TimeLogs from "./TimeLogs";
import OrganizationDataTags from "./OrganizationDataTags";
import SAPUsers from "./SAPUsers";
import RolesAndPermissions from "./RolesAndPermissions";
import GlobalChat from "./GlobalChat";
import {PermissionEntity} from "types/Permission";
import useFileUpload from "hooks/useFileUpload/useFileUpload";
import {SelectedOrgContext} from "./SelectedOrgContextProvider";
import {defaultSelectedOrg} from "constants/defaultSelectedOrg";
import {Config} from "types/Config";
import TemplateChecker from "components/TemplateChecker";
import {getDoc, getDocs, where} from "firebase/firestore";
import {UploadContext} from "hooks/useFileUpload/context/UploadContext";

function Dashboard() {
  const firebaseUser = auth.currentUser;
  const {uid} = firebaseUser ?? {};
  const [user] = useDocument<User>(userPath(uid!));
  const navigate = useNavigate();

  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [toastSeverity, setToastSeverity] = useState<Severity>(Severity.Info);
  // intentional any, since toast can contain JSX.Element or string
  const [toastMessage, setToastMessage] = useState<any>(<>This is a toast.</>);

  // token related states
  const [foundToken, setFoundToken] = useState<boolean>(false);
  const [fetchedToken, setFetchedToken] = useState<number>(0);

  const [superUid, setSuperUid] = useState<string | undefined>(undefined);
  const [selectedOrgId, setSelectedOrgId] = useState<string | null>(null);

  const selectedOrgContext = useContext(SelectedOrgContext);
  const {selectedOrg, setSelectedOrg} = selectedOrgContext!;

  const [isMounted, setIsMounted] = useState(false);
  const [teams, , updateTeamsRef,, teamsColRef] = useCollection<Team>(null, null, [], false, isMounted);
  const [members, , updateMembersRef,, membersColRef] = useCollection<Member>(null, null, [where("active", "==", true)], false, isMounted);

  const [isSidebarOpen, {toggle: toggleSidebar}] = useComponentToggler(false);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const storedSelectedOrg = localStorage.getItem("lastSelectedOrg");
  const lastSelectedOrg = !!storedSelectedOrg ? JSON.parse(storedSelectedOrg) : selectedOrg;

  const properties = {
    uid: superUid ?? uid!,
    isMobile,
    toastProps: {
      setIsToastOpen: (isOpen: boolean) => setIsToastOpen(isOpen),
      setToastMessage: (message: any) => setToastMessage(message),
      setToastSeverity: (value: Severity) => setToastSeverity(value),
    },
    selectedOrg,
    setSelectedOrgId,
  }

  const {
    handleFileUpload,
    addFiles
  } = useFileUpload({uid: (Boolean(lastSelectedOrg.isAdminView) ? superUid : uid!) ?? uid!});
  const uploadContext = useContext(UploadContext);
  const {fileUploadState} = uploadContext!;
  const {uploadBatch, isWorking, processingCount, completedCount} = fileUploadState;

  const [config] = useDocument<Config>(configPath());

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useMemo(() => {
    if (!selectedOrg) return;

    const {members: lastMembers, ...rest} = selectedOrg!;
    setSelectedOrg({...rest, members: members || lastMembers});
  }, [members]);

  useMemo(() => {
    if (!selectedOrg) return;

    const {teams: lastTeams, ...rest} = selectedOrg!;
    setSelectedOrg({...rest, teams: teams || lastTeams});
  }, [teams]);

  useEffect(() => {
    if (!config) return;
    if (!config.underMaintenance) return;
    if (config.allowedEmailToLogin.includes('*') || config.allowedEmailToLogin.includes(user!.email)) return;

    setTimeout(() => {
      (async () => {
        await auth.signOut();
        navigate(`/under-maintenance`);
      })();
    }, 3000);
  }, [config]);

  useDebounce(async () => {
    if (uploadBatch.length === 0) return;

    if (isWorking) return;

    let index = 0;
    let currentBatch = structuredClone(uploadBatch[index]);
    while (!!currentBatch && fileUploadState.processingBatchId.includes(currentBatch.id)) {
      currentBatch = structuredClone(uploadBatch[index + 1]);
    }

    currentBatch && handleFileUpload(currentBatch.files, currentBatch.id);
  }, 1000, [uploadBatch.length, isWorking]);

  useEffect(() => {
    // if no user
    if (!user) return;

    // if already found
    if (foundToken) return;

    // if done fetching token -> check twice
    if (fetchedToken > 2) return;

    saveUserToken();
  }, [user, foundToken]);

  useMemo(() => {
    const settings = localStorage.getItem('userSettings')
      ? JSON.parse(localStorage.getItem('userSettings') ?? "")
      : {volume: 0.5};

    if (user && user.settings) {
      settings.volume = user.settings.enableSoundNotifications
        ? user.settings.soundNotificationsVolume / 100
        : 0;
    }

    localStorage.setItem('userSettings', JSON.stringify(settings));

    // fetch selected org
    if (selectedOrg.id === defaultSelectedOrg.id) {
      const currentAdminOrgsList = user?.adminSidebarView.orgsIdList ?? [];
      const newSelectedOrg = user?.sidebarView.selectedOrg ?? defaultSelectedOrg;
      const newSelectedOrgId = newSelectedOrg.id;
      setSelectedOrg({...newSelectedOrg, isAdminView: currentAdminOrgsList.includes(newSelectedOrgId)});
    }
  }, [user?.settings, user?.sidebarView]);

  // initialize value for selectedOrgId & get superUid
  useEffect(() => {
    // Hides and show open sidebar FAB onscroll/onstop scroll
    window.addEventListener("scroll", () => {
      setIsUserScrolling(true);
    });

    async function fetchSuperUid() {
      const currentSuperUid = await getSuperUid();
      setSuperUid(typeof currentSuperUid === "string" ? currentSuperUid : undefined);
    }

    fetchSuperUid();

    // when refresh, save the isAdminView to localStorage
    const handleBeforeUnloadLastSelectedOrg = () => {
      if (selectedOrg.id === defaultSelectedOrg.id) return;
      localStorage.setItem("lastSelectedOrg", JSON.stringify(selectedOrg));
    };

    // Attach the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnloadLastSelectedOrg);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnloadLastSelectedOrg);
    };
  }, []);

  // show toast when processingCount is not zero and user tries to refresh
  useEffect(() => {
    const currentProcessing = processingCount - completedCount;

    // when refresh, check if user has pending uploads
    const handleBeforeUnloadPendingUploads = (event: BeforeUnloadEvent) => {
      if (currentProcessing <= 0) return;

      const message = "You have pending uploads. Are you sure you want to leave?";
      event.returnValue = message;
      // For compatibility with some browsers, you return the message directly
      return message;
    }

    window.addEventListener('beforeunload', handleBeforeUnloadPendingUploads);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnloadPendingUploads);
    };
  }, [processingCount, completedCount]);

  useDebounce(async () => {
    if (!isUserScrolling) return;

    setIsUserScrolling(false);
  }, 1500, [isUserScrolling])

  useMemo(() => {
    if (!selectedOrgId) return;

    if (selectedOrgId === defaultSelectedOrg.id) return;

    if (teamsColRef && membersColRef && teamsColRef.split("/").includes(selectedOrgId)) return;

    const getNewOrg = async () => {
      const newOrg = await getDoc(organizationPath(selectedOrgId));
      if (newOrg.exists()) {
        const newOrgData = newOrg.data() as Organization;
        const newMembers = await getDocs(organizationMembersPath(selectedOrgId));
        const newTeams = await getDocs(teamsPath(selectedOrgId));

        setSelectedOrg({
          name: newOrgData.name,
          id: newOrg.id || newOrgData["@id"]!,
          accessRole: newOrgData.createdBy.id === uid ? AccessRole.Owner : AccessRole.Admin,
          isAdminView: newOrgData.createdBy.id === uid,
          members: newMembers?.docs.map((doc) => doc.data() as Member) || [] as Member[],
          teams: newTeams?.docs.map((doc) => doc.data() as Team) || [] as Team[],
        })

        updateMembersRef(organizationMembersPath(selectedOrgId));
        updateTeamsRef(teamsPath(selectedOrgId));
        return;
      }

      setSelectedOrg(defaultSelectedOrg);
    }

    getNewOrg();
  }, [selectedOrgId]);

  async function saveUserToken() {
    const pushTokens = user?.pushTokens ?? [];

    const newPushToken = await getPushToken();
    setFetchedToken(fetchedToken+1);
    if (!newPushToken) {
      setFetchedToken(fetchedToken + 1);
      return;
    }

    if (pushTokens.includes(newPushToken)) {
      setFoundToken(true);
      return;
    }

    // save copy of last inserted token
    localStorage.setItem("messagingPushToken", newPushToken);
    await submitForm<Partial<User>>(
      userPath(uid!), ActionType.Update,
      () => setFoundToken(true),
      {pushTokens: [...pushTokens, newPushToken]}
    )
  }

  if (!user || !selectedOrg) return <Loader/>;
  if (!user?.isVerified) {
    signOut(auth);
  }

  return (
    <Grid container padding={0} margin={0}>
      <Grid item sx={{width: isMobile ? 0 : "280px"}}>
        <Sidebar
          {...properties}
          isSuperAdmin={!!superUid}
          isOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />
        {(isMobile && !isSidebarOpen && !isUserScrolling) && (
          <Fab
            onClick={toggleSidebar}
            variant="circular"
            sx={{
              transition: "all ease 0.2s",
              bottom: 16,
              left: 16,
              position: "fixed",
              zIndex: 99,
              backgroundColor: theme.palette.secondary.main,
              "&:hover": {
                backgroundColor: theme.palette.secondary.main
              }
            }}>
            <MenuRounded sx={{fill: theme.palette.common.white}}/>
          </Fab>
        )}
        {/*{!fileUploadState.isPanelOpen && (
          <Hidden smDown>
            <Fab
              onClick={() => fileUploadDispatch({type: UploadActionType.openPanel})}
              variant="circular"
              sx={{
                transition: "all ease 0.2s",
                bottom: 16,
                right: 16,
                position: "fixed",
                zIndex: 99,
                backgroundColor: theme.palette.secondary.main,
                "&:hover": {
                  backgroundColor: theme.palette.secondary.main
                }
              }}
            >
              <NotificationsActiveOutlined sx={{fill: theme.palette.common.white}}/>
            </Fab>
          </Hidden>
        )}*/}
      </Grid>
      <Grid item xs justifyContent="center">
        <Toast
          open={isToastOpen}
          messageComponent={toastMessage}
          onClose={(e) => {
            e?.preventDefault();
            e?.stopPropagation()
            setIsToastOpen(false)
          }}
          severity={toastSeverity}
          sx={{position: "fixed"}}
        />
        <Stack
          sx={{
            position: "fixed",
            bottom: "2%",
            right: "8px",
            zIndex: 3,
            alignItems: "center",
            gap: 2,
            pointerEvents: "none"
          }}
        >
          <FileUploadPanel
            toastProps={properties.toastProps}
            uid={(Boolean(selectedOrg.isAdminView) ? superUid : uid!) ?? uid!}
          />
        </Stack>

        <Routes>
          <Route
            path="/"
            element={<RouteToSelected selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} user={user}/>}
          />
          <Route
            path="/videoCall"
            element={<VideoCall callJoined={true}/>}
          />
          <Route
            path=":orgId/home"
            element={<Home {...properties} invitationId={localStorage.getItem("invitationId")}/>}
          />
          <Route
            path=":orgId/assets/:assetId"
            element={
              <RestrictedPage
                collection={Entity.Organization}
                selectedOrg={selectedOrg}
                uid={properties.uid}
                isSuperUser={!!superUid}
                documentDocId={PermissionEntity.OrganizationAsset}
              />
            }
          >
            <Route
              index
              element={<OrgAssetsOverview {...properties} />}
            />
          </Route>
          <Route
            path=":orgId/explore-organizations"
            element={<ExploreOrganizations {...properties} uid={superUid ?? uid!} isSuperAdmin={!!superUid}/>}
          />
          <Route
            path=":orgId/projects"
            element={
              <RestrictedPage
                uid={properties.uid}
                collection={Entity.Organization}
                selectedOrg={selectedOrg}
                isSuperUser={!!superUid}
              />
            }
          >
            <Route
              index
              element={
                <Projects
                  {...properties}
                />
              }
            />
            <Route
              path=":projId"
              element={
                <TemplateChecker>
                  <RestrictedPage
                    uid={properties.uid}
                    collection={Entity.Project}
                    selectedOrg={selectedOrg}
                    isSuperUser={!!superUid}
                  />
                </TemplateChecker>
              }
            >
              <Route
                index
                element={<ProjectOverview {...properties}/>}
              />
              <Route
                path="overview"
                element={<ProjectOverview {...properties} />}
              />
              <Route
                path="assets&tasks"
                element={<ProjectOverview {...properties} />}
              />
              <Route
                path="project-template"
                element={<ProjectOverview {...properties} />}
              />
              <Route
                path="manage-assets"
                element={<ManageProjectAssets {...properties} />}
              />
              <Route
                path="project-level-tasks"
              >
                <Route
                  index
                  element={<ProjectLevelTasks {...properties}/>}
                />
                <Route
                  path="milestones/:milestoneId/tasks/:taskId"
                  // element={
                  //   <RestrictedPage
                  //     uid={properties.uid}
                  //     collection={Entity.Task}
                  //     selectedOrg={selectedOrg}
                  //     isSuperUser={!!superUid}
                  //   />
                  // }
                >
                  <Route
                    index
                    element={<TaskOverview {...properties} addFiles={addFiles}/>}
                  />
                  <Route
                    path="time-logs"
                    element={
                      <TimeLogs
                        {...properties}
                      />
                    }
                  />
                  <Route
                    path="file-requirements/:fileRequirementId"
                    element={
                      <RestrictedPage
                        uid={properties.uid}
                        collection={Entity.Task}
                        selectedOrg={selectedOrg}
                        isSuperUser={!!superUid}
                      />
                    }
                  >
                    <Route
                      index
                      element={(
                        <FileRequirementOverview
                          {...properties}
                          addFiles={addFiles}
                        />
                      )}
                    />
                  </Route>
                  <Route
                    path="form-requirements/:formRequirementId"
                    element={
                      <RestrictedPage
                        uid={properties.uid}
                        collection={Entity.Task}
                        selectedOrg={selectedOrg}
                        isSuperUser={!!superUid}
                      />
                    }
                  >
                    <Route
                      index
                      element={<FormRequirementOverview {...properties}/>}
                    />
                  </Route>
                </Route>
              </Route>
              <Route
                path="assets/:assetId"
                element={
                  <RestrictedPage
                    uid={properties.uid}
                    collection={Entity.Asset}
                    selectedOrg={selectedOrg}
                    isSuperUser={!!superUid}
                  />
                }
              >
                <Route
                  index
                  element={
                    <AssetOverview
                      {...properties}
                    />
                  }
                />
                <Route
                  path="milestones/:milestoneId/tasks/:taskId"
                  // element={
                  //   <RestrictedPage
                  //     uid={properties.uid}
                  //     collection={Entity.Task}
                  //     selectedOrg={selectedOrg}
                  //     isSuperUser={!!superUid}
                  //   />
                  // }
                >
                  <Route
                    index
                    element={<TaskOverview {...properties} addFiles={addFiles}/>}
                  />
                  <Route
                    path="time-logs"
                    element={
                      <TimeLogs
                        {...properties}
                      />
                    }
                  />
                  <Route
                    path="file-requirements/:fileRequirementId"
                    // element={
                    //   <RestrictedPage
                    //     uid={properties.uid}
                    //     collection={Entity.Task}
                    //     selectedOrg={selectedOrg}
                    //     isSuperUser={!!superUid}
                    //   />
                    // }
                  >
                    <Route
                      index
                      element={(
                        <FileRequirementOverview
                          {...properties}
                          addFiles={addFiles}
                        />
                      )}
                    />
                    <Route path="*" element={<PageNotFound/>}/>
                  </Route>
                  <Route
                    path="form-requirements/:formRequirementId"
                    // element={
                    //   <RestrictedPage
                    //     uid={properties.uid}
                    //     collection={Entity.FormRequirement}
                    //     selectedOrg={selectedOrg}
                    //     isSuperUser={!!superUid}
                    //   />
                    // }
                  >
                    <Route
                      index
                      element={
                        <FormRequirementOverview
                          {...properties}
                        />
                      }
                    />
                  </Route>
                  <Route path="*" element={<PageNotFound/>}/>
                </Route>
              </Route>

              <Route
                path="*"
                element={<PageNotFound/>}
              />
            </Route>

          </Route>
          <Route
            path=":orgId/organization-assets"
            element={
              <RestrictedPage
                uid={properties.uid}
                collection={Entity.Organization}
                selectedOrg={selectedOrg}
                isSuperUser={!!superUid}
                documentDocId={PermissionEntity.OrganizationAsset}
              />
            }
          >
            <Route
              index
              element={<OrgAssets {...properties}/>}
            />
          </Route>
          <Route
            path=":orgId/teams-and-people"
            element={
              <RestrictedPage
                uid={properties.uid}
                collection={Entity.Organization}
                selectedOrg={selectedOrg}
                isSuperUser={!!superUid}
                documentDocId={PermissionEntity.Organization}
              />
            }
          >
            <Route
              index
              element={<TeamsAndPeople {...properties}/>}
            />
          </Route>
          <Route
            path=":orgId/manage-categories"
            element={
              <RestrictedPage
                uid={properties.uid}
                collection={Entity.Organization}
                selectedOrg={selectedOrg}
                isSuperUser={!!superUid}
                documentDocId={PermissionEntity.TeamCategory}
              />
            }
          >
            <Route
              index
              element={<ManageTeamCategories {...properties}/>}
            />
          </Route>
          <Route path=":orgId/feed">
            <Route
              index
              element={<ActivityFeed {...properties}/>}
            />
          </Route>
          <Route path=":orgId/chat">
            <Route
              index
              element={<GlobalChat {...properties}/>}
            />
          </Route>
          <Route path=":orgId/calendar">
            <Route
              index
              element={<Calendar {...properties} />}
            />
          </Route>
          <Route
            path=":orgId/forms"
            element={
              <RestrictedPage
                uid={properties.uid}
                collection={Entity.Organization}
                selectedOrg={selectedOrg}
                isSuperUser={!!superUid}
                documentDocId={PermissionEntity.Form}
              />
            }
          >
            <Route
              index
              element={<Forms {...properties}/>}
            />
            <Route
              path=":formId/edit"
              element={<FormEditCreate {...properties}/>}
            />
            <Route
              path="create"
              element={<FormEditCreate {...properties}/>}
            />
          </Route>
          <Route path=":orgId/reference-files">
            <Route
              index
              element={<ReferenceFiles {...properties}/>}
            />
          </Route>
          <Route path=":orgId/admin-panel">
            <Route
              index
              element={<AdminPanel {...properties}/>}
              {...properties}
            />
          </Route>
          <Route path=":orgId/templates">
            <Route
              index
              element={<Templates {...properties}/>}
            />
            <Route
              path=":templateId"
              element={
                <RestrictedPage
                  uid={properties.uid}
                  collection={Entity.Templates}
                  selectedOrg={selectedOrg}
                  isSuperUser={!!superUid}
                  documentDocId={PermissionEntity.OrganizationTemplate}
                />
              }
            >
              <Route
                index
                element={<TemplatesOverview {...properties}/>}
              />
              <Route
                path="overview"
                element={<TemplatesOverview {...properties} />}
              />
              <Route
                path="projects"
                element={<TemplatesOverview {...properties} />}
              />
              <Route
                path="milestones/:milestoneId/tasks/:taskId"
                element={<TaskOverview {...properties} addFiles={addFiles}/>}
              />
            </Route>
          </Route>
          <Route
            path=":orgId/datatags"
            element={
              <RestrictedPage
                uid={properties.uid}
                collection={Entity.Organization}
                selectedOrg={selectedOrg}
                isSuperUser={!!superUid}
                documentDocId={PermissionEntity.Form}
              />
            }
          >
            <Route
              index
              element={<OrganizationDataTags {...properties}/>}
            />
          </Route>
          <Route
            path=":orgId/roles-and-permissions"
            element={<RolesAndPermissions {...properties}/>}
          />
          <Route
            path=":orgId/users"
            element={<SAPUsers {...properties}/>}
          />
          <Route
            path=":orgId/system-status"
            element={<></>}
          />
          <Route
            path="view-invite"
            element={<ViewInvitation {...properties}/>}
          />
          <Route
            path="profile-and-settings"
            element={<ProfileAndSettings {...properties} uid={uid!}/>}
          />
          <Route
            path=":orgId/no-permission"
            element={<NoPermission {...properties} fullHeight={true}/>}
          />
          <Route
            path="/*"
            element={<PageNotFound/>}/>
        </Routes>
      </Grid>
    </Grid>
  )
}

export default Dashboard;