import React, {useContext, useEffect, useState} from "react";
import {emptyFunction} from "constants/index";
import {BaseProps} from "screens/BaseProps";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {Virtuoso} from "react-virtuoso";
import PageHeader, {ActivityFeedTabs} from "./PageHeader";
import PageItems from "./PageItems";
import PageFooter from "./PageFooter";
import useCollectionData, {Data} from "hooks/useCollectionData";
import {userActivityFeedPath} from "../utility";
import {orderBy} from "firebase/firestore";
import {ActivityType, Entity} from "enums/index";
import {ProcessingFile} from "hooks/useFileUpload/useFileUpload";
import {UploadContext} from "hooks/useFileUpload/context/UploadContext";

interface ActivityFeedData extends Data {
  entity: Entity;
  type: ActivityType;
}


function ActivityFeed(props: BaseProps) {
  const {toastProps, selectedOrg, uid, setSelectedOrgId = emptyFunction} = props;

  const {orgId} = useParams();
  const navigate = useNavigate();

  const context = useContext(UploadContext);

  const [activeTab, setActiveTab] = useState<ActivityFeedTabs>(ActivityFeedTabs.RecentActivities);
  const [processingFiles, setProcessingFiles] = useState<ProcessingFile[]>([]);

  // list of all activities
  const {data: allActivities, fetching, totalCount, loadMore} = useCollectionData<ActivityFeedData>({
    collectionRef: userActivityFeedPath(uid),
    constraints: [orderBy("pinned", "desc"), orderBy("timestamp", "desc")],
    returnedFields: ["@id", "entity", "type"],
  });

  // activity list for each tab
  const [invites, setInvites] = useState<ActivityFeedData[]>([]);
  const [projects, setProjects] = useState<ActivityFeedData[]>([]);
  const [assets, setAssets] = useState<ActivityFeedData[]>([]);
  const [tasks, setTasks] = useState<ActivityFeedData[]>([]);
  const [requirements, setRequirements] = useState<ActivityFeedData[]>([]);
  const [files, setFiles] = useState<ActivityFeedData[]>([]);

  const [displayedActivities, setDisplayedActivities] = useState<any[]>([]);

  useEffect(() => {
    setProcessingFiles(context!.fileUploadState.processingFiles);
  }, [context!.fileUploadState.processingFiles]);

  // update local tab list when there are changes in allActivities
  useEffect(() => {
    setInvites((allActivities ?? []).filter(activity => activity.entity === Entity.UserInvite));
    setProjects((allActivities ?? []).filter(activity => activity.entity === Entity.Project));
    setAssets((allActivities ?? []).filter(activity => activity.entity === Entity.Asset));
    setTasks((allActivities ?? []).filter(activity => [Entity.Task, Entity.PLTask].includes(activity.entity)));
    setRequirements((allActivities ?? []).filter(activity => [Entity.FileRequirement, Entity.FormRequirement, Entity.PLFileRequirement, Entity.PLFormRequirement].includes(activity.entity)));
    setFiles((allActivities ?? []).filter(activity => [Entity.SwiftFile, Entity.PLFile].includes(activity.entity)));
  }, [allActivities]);

  // update displayedActivities when activeTab changes
  useEffect(() => {
    const processingActivity = processingFiles.filter(file => file.length !== file.lengthUploaded)
      .map(file => ({
      ...file,
      fromProcessing: true,
    }));

    switch (activeTab) {
      case ActivityFeedTabs.RecentActivities:
        setDisplayedActivities([...processingActivity, ...(allActivities ?? [])]);
        break;
      case ActivityFeedTabs.Invites:
        setDisplayedActivities(invites);
        break;
      case ActivityFeedTabs.Projects:
        setDisplayedActivities(projects);
        break;
      case ActivityFeedTabs.Assets:
        setDisplayedActivities(assets);
        break;
      case ActivityFeedTabs.Tasks:
        setDisplayedActivities(tasks);
        break;
      case ActivityFeedTabs.Requirements:
        setDisplayedActivities(requirements);
        break;
      case ActivityFeedTabs.Files:
        setDisplayedActivities([...processingActivity, ...files]);
        break;
      default:
        setDisplayedActivities([]);
    }
  }, [activeTab, allActivities, invites, projects, assets, tasks, requirements, files, processingFiles]);


  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  async function onLoadMore() {
    // if all activities are not loaded
    if (allActivities === null) return;

    await loadMore();
  }

  function updatePinnedValue(index: number, pinned: boolean) {
    const feedCopy = [...displayedActivities];
    feedCopy[index].pinned = pinned;
    feedCopy.sort((a, b) => (a.pinned === b.pinned) ? 0 : a.pinned ? -1 : 1);
    setDisplayedActivities(feedCopy);
  }

  const virtuosoContext = {
    activeTab,
    setActiveTab,
    toastProps: toastProps!,
    atBottom: totalCount !== null && (allActivities ?? []).length >= totalCount,
    isEmpty: allActivities !== null && displayedActivities.length === 0,
    isLoading: fetching,
  }

  return (
    <Virtuoso
      style={{height: "100vh", maxWidth: "100hw", paddingLeft: "10%", overflowX: "hidden"}}
      context={virtuosoContext}
      overscan={10}
      data={displayedActivities}
      endReached={onLoadMore}
      atBottomThreshold={100}
      components={{
        Header: VirtuosoHeader,
        Footer: VirtuosoFooter,
      }}
      itemContent={(index, itemData) => (
        <PageItems
          updatePinnedValue={updatePinnedValue}
          itemData={itemData}
          index={index}
          toastProps={toastProps!}
          uid={uid!}
        />
      )}
    />
  )
}

const VirtuosoHeader = ({context}: any) => {
  return <PageHeader {...context} />
}

const VirtuosoFooter = ({context}: any) => {
  return <PageFooter {...context} />
}

export default ActivityFeed;
