import {Box, Stack, Typography,} from "@mui/material";
import React from "react";
import {enProjectLabel} from "constants/index";

type HeadProps = {
  projectsCount?: number;
}

function Head(props: HeadProps) {
  const {projectsCount} = props;

  return (
    <Stack direction="row" display="flex" mb={1} gap={1}>
      <Box flex={3}>
        <Stack direction="column" sx={{gap: 1}}>
          <Stack direction="row" sx={{gap: 1}}>
            <Typography variant="h1">
              {`${enProjectLabel.projects} `}
              {
                !!projectsCount && (
                  <Typography
                    id="projects-count"
                    variant="body"
                    sx={(theme) => ({
                      display: "inline",
                      color: theme.palette.text.secondary,
                    })}
                  >
                    {projectsCount}
                  </Typography>
                )
              }
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}

export default Head;
