import {Box, CircularProgress, SxProps, Theme} from "@mui/material";
import React from "react";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";

interface SelectRightIconProps {
  isLoading: boolean;
  isMenuShowing: boolean;
	selectIconSx?: SxProps<Theme>;
  iconSize?: number;
}

function SelectRightIcon(props: SelectRightIconProps) {
  const {isLoading, isMenuShowing, selectIconSx, iconSize = 16} = props;

  if(isLoading)
    return (
      <Box sx={selectIconSx} mt={0.5}>
        <CircularProgress size={iconSize} />
      </Box>
    );

  if(isMenuShowing)
    return (
      <Box sx={selectIconSx} mt={0.5}>
        <SystemIcons.ChevronUp height={iconSize} width={iconSize} stroke={theme.palette.neutral.medium}/>
      </Box>
    );

  return (
    <Box sx={selectIconSx} mt={0.5}>
      <SystemIcons.ChevronDown height={iconSize} width={iconSize} stroke={theme.palette.neutral.medium}/>
    </Box>
  );
}

export default SelectRightIcon;