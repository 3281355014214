import {userInvitesPath} from "screens/utility/FirebasePath";
import {Entity} from "enums/index";
import {BaseProps} from "screens/BaseProps";
import InvitesView from "components/InvitesView";
import {Stack} from "@mui/material";

function InvitesList(props: BaseProps) {
  return (
    <Stack flex={1} mt={3}>
      <InvitesView
        invitationsReference={userInvitesPath(props.uid)}
        entity={Entity.Organization}
        withSearch={true}
        {...props}
      />
    </Stack>
  );
}

export default InvitesList;