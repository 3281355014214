import {FilterType} from "./FilterType";
import {Box, MenuItem, Select, Typography} from "@mui/material";

interface FilterMenuProps {
  changeFilter: (filter: FilterType) => void;
}

function FilterMenu(props: FilterMenuProps) {
  const {changeFilter} = props;

  return (
    <Box sx={{display: "flex", alignItems: "center"}} id="sort-org-field">
      <Select
        id="org-select-field"
        defaultValue={FilterType.all}
        sx={{
          width: "min-content",
          minWidth: 100,
          padding: 0,
          ".MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          ".MuiOutlinedInput-input": {
            padding: "0px 4px",
          },
        }}
        onChange={(e) => changeFilter(e.target.value as FilterType)}
      >
        <MenuItem value={FilterType.all} id="filter-menu-all">
          <Typography
            sx={(theme) => ({
              color: theme.palette.secondary.main,
            })}
            variant="body"
          >
            {FilterType.all}
          </Typography>
        </MenuItem>
        <MenuItem value={FilterType.withoutOrg} id="filter-menu-withoutOrg">
          <Typography
            sx={(theme) => ({
              color: theme.palette.secondary.main,
            })}
            variant="body"
          >
            {FilterType.withoutOrg}
          </Typography>
        </MenuItem>
      </Select>
    </Box>
  )
}

export default FilterMenu;