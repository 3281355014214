import {AccessType, PermissionOperationKey} from "types/Permission";

export const ownerAccess: AccessType = {
  [PermissionOperationKey.Create]: true,
  [PermissionOperationKey.Update]: true,
  [PermissionOperationKey.Delete]: true,
  [PermissionOperationKey.View]: true,
  [PermissionOperationKey.ChangeStatus]: true,
  [PermissionOperationKey.OverrideStatus]: true,
  [PermissionOperationKey.ManageOwner]: true,
  [PermissionOperationKey.ManageAdmin]: true,
  [PermissionOperationKey.ManagePowerUser]: true,
  [PermissionOperationKey.ManageUser]: true,
  [PermissionOperationKey.ManageGuest]: true,
};