/* eslint-disable react-hooks/exhaustive-deps */
import {Box, Stack, Typography} from "@mui/material";
import {EmailTooltip, SelectInput, SimpleFormDrawer} from "components/index";
import React, {useEffect, useState} from "react";
import {Member} from "types/Member";
import {AccessRole, ActionType, ProcessType, Severity} from "enums/index";
import {defaultUser, enCommonLabel, enMemberLabel, SELECTABLE_ROLES} from "constants/index";
import {onChangeInput, submitForm} from "screens/utility";
import {doc} from "firebase/firestore";
import {organizationMembersPath} from "screens/utility/FirebasePath";
import {BaseProps} from "screens/BaseProps";
import theme from "theme/theme";
import {PartialUserData} from "types/PartialUserData";
import {AccessType} from "types/Permission";
import {SelectInputItem} from "components/SelectInput";
import {statusSubmitHandler} from "../../screens/utility/statusSubmitHandler";

interface EditMemberDrawerProps extends BaseProps {
  open: boolean
  actionTargetMember: Member | null;
  editMemberIcon: React.ReactNode;
  closeEditMemberDrawer: () => void;
  access: AccessType | null;
}

export default function EditMemberDrawer(props: EditMemberDrawerProps) {
  const {open, actionTargetMember, editMemberIcon, toastProps, closeEditMemberDrawer, access} = props;
  const {setIsToastOpen, setToastSeverity, setToastMessage} = toastProps!;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newRole, setNewRole] = useState<AccessRole>(actionTargetMember?.role ?? AccessRole.User);
  const [validationMessage, setValidationMessage] = useState<[boolean, string]>([true, ""]);
  const [items, setItems] = useState<SelectInputItem[]>([])

  //generate role selection with permissions
  useEffect(() => {
    if (!access) return;

    const itemsWithValidity: SelectInputItem[] = [];

    SELECTABLE_ROLES.forEach(role => {
      // @ts-ignore
      itemsWithValidity.push({...role, validity: actionTargetMember?.role === role.value ? false : access[`Manage${role.value}`]})
    });

    setItems(itemsWithValidity);
  }, [access, actionTargetMember])

  useEffect(() => {
    if (!actionTargetMember) return;
    setNewRole(actionTargetMember.role)
  }, [actionTargetMember]);

  async function onSaveButtonClick() {
    setIsLoading(true);

    const memberReference = doc(organizationMembersPath(actionTargetMember!.orgId), actionTargetMember?.uid);
    await submitForm<Partial<Member>>(memberReference, ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler({status, data, isLastUpdate, successCallback, errorCallback}),
      {role: newRole}
    )
  }

  function successCallback() {
    setToastSeverity(Severity.Success);
    setToastMessage(enMemberLabel.editSuccess);
    setIsToastOpen(true);
    setIsLoading(false);
    closeEditMemberDrawer();
  }

  function errorCallback(message: any) {
    setIsLoading(false);
    if (typeof message === "object") {
      setValidationMessage([false, message[0]]);
      return;
    }
    setToastSeverity(Severity.Error);
    setToastMessage(enCommonLabel.errorProcess(ProcessType.Update));
    setIsToastOpen(true);
    closeEditMemberDrawer();
  }

  return (
    <SimpleFormDrawer
      isOpen={open}
      title={actionTargetMember?.name ?? ""}
      subtitle={
        <EmailTooltip user={(actionTargetMember || defaultUser) as unknown as PartialUserData}>
          <Typography color={theme.palette.primary.main}>
            {actionTargetMember?.email ?? ""}
          </Typography>
        </EmailTooltip>
      }
      id="formDrawer-editMember"
      buttonId="btn-saveChanges"
      icon={editMemberIcon}
      isFormValid={(actionTargetMember?.role ?? AccessRole.User) !== newRole}
      isLoading={isLoading}
      iconProps={{
        mb: 0,
        px: 0
      }}
      customIcon={true}
      onSubmit={onSaveButtonClick}
      onClose={closeEditMemberDrawer}
      unsavedChanges={(actionTargetMember?.role ?? AccessRole.User) !== newRole}
    >
      {
        (actionTargetMember?.role ?? AccessRole.User) === AccessRole.Owner ? (
          <Typography variant="h4" align="center">{enMemberLabel.notEditable}</Typography>
        ) : (
          <Stack>
            <SelectInput
              onChange={(e) => onChangeInput(e, setNewRole)}
              items={items}
              value={newRole}
              label={enMemberLabel.role}
              validator={validationMessage}
            />
          </Stack>
        )
      }
      <Box flex={1}/>
    </SimpleFormDrawer>
  )
}
