import {ActivityItem} from "types/ActivityFeedTypes";
import {Entity} from "enums/entity";


export function getActivityName(item: ActivityItem) {
  const {entity, orgName, projectName, assetName, taskName, requirementName} = item

  switch (entity) {
    case Entity.Organization:
      return orgName
    case Entity.Project:
      return projectName
    case Entity.Asset:
      return assetName
    case Entity.Task:
    case Entity.PLTask:
      return taskName
    case Entity.FormRequirement:
    case Entity.FileRequirement:
      return requirementName
    default:
      return ""
  }
}
