const BriefcaseIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#F8F8F8" />
      <path
        d="M28 15H12C10.8954 15 10 15.8954 10 17V27C10 28.1046 10.8954 29 12 29H28C29.1046 29 30 28.1046 30 27V17C30 15.8954 29.1046 15 28 15Z"
        stroke="#1D1D1E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 15V13C24 12.4696 23.7893 11.9609 23.4142 11.5858C23.0391 11.2107 22.5304 11 22 11H18C17.4696 11 16.9609 11.2107 16.5858 11.5858C16.2107 11.9609 16 12.4696 16 13V15"
        stroke="#1D1D1E"
        strokeWidth="2"
    strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BriefcaseIcon;
