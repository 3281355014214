import {DirectionalOrder} from "enums/DirectionalOrder";
import React, {Dispatch} from "react";
import {Data} from "hooks/useCollectionData";
import {Stack} from "@mui/material";
import SmartSearchInput from "components/inputs/SmartSearchInput";
import {SortMenu} from "components/index";
import StickySearchAndFilter from "components/StickySearchAndFilter";
import {algoliaOrganizationAssetsPath} from "../utility/algoliaColPath";
import {useParams} from "react-router-dom";

export interface AssetData extends Data {
  idCode: string;
}

export interface SearchAndSortProps {
  sortOrder: DirectionalOrder;
  setSortOrder: Dispatch<DirectionalOrder>;
  setAlgoliaResults: Dispatch<AssetData[]| null>;
}

function SearchAndSort(props: SearchAndSortProps) {
  const {sortOrder, setSortOrder, setAlgoliaResults} = props;

  const {orgId} = useParams();

  function setSearchResult(results: AssetData[] | null) {
    if (results === null) {
      setAlgoliaResults(null);
      return;
    }

    // remove possible duplicates
    const uniqueResults = results.filter((result, index, self) =>
      index === self.findIndex((t) => (
        t["@id"] === result["@id"]
      ))
    );
    setAlgoliaResults([...uniqueResults]);
  }

  return (
    <StickySearchAndFilter topPosition={0}>
      <Stack
        display="flex"
        alignItems={{xs: "left", sm: "left", md: "center", lg: "center", xl: "center"}}
        justifyContent="space-between"
        mt={{xs: 0, sm: 0, md: 1, lg: 1, xl: 1}}
        gap={2}
        direction={{xs: "column", sm: "column", md: "row", lg: "row", xl: "row"}}
      >
        <SmartSearchInput<AssetData>
          resultCallback={setSearchResult}
          colPath={algoliaOrganizationAssetsPath(orgId!)}
        />
        <Stack direction={{xs: "column", sm: "row", md: "row", lg: "row", xl: "row"}} gap={1}>
          <SortMenu currentOrder={sortOrder} setter={setSortOrder}/>
        </Stack>
      </Stack>
    </StickySearchAndFilter>
  )
}

export default SearchAndSort;