import React from "react";
import {useNavigate} from "react-router-dom";
import MenuCard from "components/MenuCard";
import ActionOption from "components/ActionOption";
import {SystemIcons} from "assets/icons/system/system.index";
import {emptyFunction, enOrganizationLabel, enProject} from "constants/index";

interface SAPMenuOptionsProps {
  anchorEl: HTMLElement | null;
  orgId: string;
  open: boolean;
  closeMenu: (e: React.MouseEvent<HTMLElement>) => void;
  editMenuClick: (e: React.MouseEvent<HTMLElement>) => void;
  deleteMenuClick: (e: React.MouseEvent<HTMLElement>) => void;
}

function SAPMenuOptions(props: SAPMenuOptionsProps) {
  const {anchorEl, orgId, open} = props;
  const {closeMenu, editMenuClick, deleteMenuClick} = props;

  const navigate = useNavigate();

  return (
    <MenuCard anchorEl={anchorEl} open={open} closeMenu={closeMenu}>
      <ActionOption
        id={`${orgId}-edit-org-menu-item`}
        Icon={<SystemIcons.Edit height={16} width={16}/>}
        label={enOrganizationLabel.edit}
        onClick={editMenuClick}
      />
      <ActionOption
        id={`${orgId}-manage-members-menu-item`}
        Icon={<SystemIcons.Teams height={16} width={16}/>}
        label={enOrganizationLabel.manageMembers}
        onClick={() => navigate(`/${orgId}/teams-and-people`)}
      />
      <ActionOption
        id={`${orgId}-view-projects-menu-item`}
        Icon={<SystemIcons.Projects height={16} width={16}/>}
        label={enProject.label.projects}
        onClick={() => navigate(`/${orgId}/projects`)}
      />
      <ActionOption
        id={`${orgId}-delete-org-menu-item`}
        Icon={<SystemIcons.DeleteOutlined height={16} width={16}/>}
        label={enOrganizationLabel.delete}
        labelSx={(theme) => ({color: theme.palette.error.main})}
        onClick={deleteMenuClick}
      />
    </MenuCard>
  );
}

export default SAPMenuOptions;