import {Box, MenuItem, Typography} from "@mui/material";
import theme from "theme/theme";
import React from "react";
import {SelectItem} from "components/inputs/SelectWithSearch";
import {enCommonLabel} from "constants/index";
import {LongText} from "components/index";

interface MenuItemsListProps {
  selected: string;
  itemsLength: number;
  displayedItems: SelectItem[];
  displayedItemsNum: number;
  handleSelectItem: (selection: string) => void;
  clearable?: boolean
}

function MenuItemsList(props: MenuItemsListProps) {
  const {selected, itemsLength, displayedItems, displayedItemsNum, handleSelectItem, clearable = false} = props;

  if (itemsLength > 0 && displayedItems.length === 0)
    return (
      <MenuItem disabled={true}>
        <Typography>{enCommonLabel.search.noResultsTitle}</Typography>
      </MenuItem>
    )

  return (
    <>
      {displayedItems.slice(0, displayedItemsNum).map((item) => (
        <MenuItem
          onClick={() => handleSelectItem(item.value)}
          key={item.value}
          value={item.value}
          className={"search-item"}
          sx={{
            width: "100%",
            borderRadius: 1,
            whiteSpace: "unset",
            ...(!!selected && selected === item.value ? {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            } : {})
          }}
        >
          <LongText textColor={selected === item.value ? theme.palette.text.onDark : theme.palette.text.primary}
                    variant={typeof item.displayItem === "string" ? "h5" : "body"} delay={500} placement="left">
            {item.displayItem}
          </LongText>
        </MenuItem>
      ))}
      {displayedItems.length > 0 && clearable &&
        <MenuItem
          onClick={() => handleSelectItem("")}
          key={null}
          value={undefined}
          className={"search-item"}
          sx={{
            width: "100%",
            borderRadius: 1,
            whiteSpace: "unset",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Box width="max-content" alignSelf="center">
            <Typography
              color={theme.palette.error.main}
              sx={{
                textAlign: "center",
              }}
              variant="h5"
            >
              CLEAR
            </Typography>
          </Box>
        </MenuItem>
      }

    </>
  )
}

export default MenuItemsList;
