import {CircularProgress, IconButton, SxProps} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";

interface PinProps{
  loading: boolean;
  onPinClick: () => void;
  pinned: boolean;
  sx?: SxProps
}

function Pin(props: PinProps){
  const {loading, onPinClick, pinned, sx} = props;

  const pinIcon = loading ? <CircularProgress size={24}/>
    : pinned ? <SystemIcons.PinFilled fill={theme.palette.success.main} stroke={theme.palette.success.main} />
      : <SystemIcons.Pin stroke={theme.palette.secondary.main} />;

  return <IconButton sx={sx} onClick={onPinClick}>
    {pinIcon}
  </IconButton>
}

export default Pin;
