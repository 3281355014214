import {Alert, Box, CircularProgress, Hidden, Link, Stack, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import React, {ChangeEvent, useEffect, useState} from "react";
import {auth, db} from "../firebase";
import {
  onAuthStateChanged,
  signInAnonymously,
  signInWithEmailAndPassword,
  signOut,
  User as FirebaseUser
} from "firebase/auth";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import {FirebaseError} from "firebase/app";
import {Severity} from "enums/severity";
import {ValidationMessage} from "components";
import {doc, getDoc, onSnapshot} from "firebase/firestore";
import {User} from "types/User";
import {SelectedOrg} from "types/SelectedOrg";
import useSound from "use-sound";
import loginSfx from "../sounds/login.mp3"
import theme from "theme/theme";
import FormContainer from "components/FormContainer";
import {SwiftLogoOnly} from "assets/icons/SwiftLogo";
import StandardInput from "components/inputs/StandardInput";
import StandardPasswordInput from "components/inputs/StandardPasswordInput";
import {emptyFunction, enCommonButton, enCommonLabel} from "constants/index";
import {useDocument} from "hooks/index";
import {configPath, getDocumentStatus, getSuperUid, resendVerificationEmailPath, submitForm} from "./utility";
import {ActionType} from "enums/actionType";
import {ResendVerificationEmailRequest} from "types/ResendVerificationEmailRequest";
import {ViewStatus} from "enums/viewStatus";
import {MessageBox} from "components/MessageBox";
import {AlertMessage} from "types/Alert";
import {Config} from "types/Config";
import useMaintenance from "hooks/useMaintenance";

interface UserDetails {
  email: string;
  password: string;
}

enum EmailError {
  Invalid = "Incorrect email or password",
  Deactivated = "Account has been deactivated",
  NotVerified = "Email not Verified"
}

interface LoginProps {
  isDeveloper?: boolean
}

function LogIn({isDeveloper = false}: LoginProps) {
  useMaintenance(isDeveloper);
  const [userData, setUserData] = useState<UserDetails>({email: "", password: "",});
  const [superUid, setSuperUid] = useState<string | undefined>(undefined);
  const user = auth.currentUser;

  const [loading, setLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>("");
  const [isFormValidated, setIsFormValidated] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertMessage>({
    show: false,
    message: null,
    severity: Severity.Error
  });

  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(user);
  const [selectedLoginOrg, setSelectedLoginOrg] = useState<SelectedOrg | null>(null);
  const [playSound] = useSound(loginSfx, {volume: 1, soundEnabled: true, playbackRate: 1});
  const [context, setContext] = useState<AudioContext | null>(null);
  const [verificationEmailDoc, setVerificationEmailDocRef] = useDocument<ResendVerificationEmailRequest>(null);
  const [isVerificationSent, setIsVerificationSent] = useState<boolean>(false);
  const [underMaintenance, setUnderMaintenance] = useState<boolean>(false);
  const [allowedEmailToLogin, setAllowedEmailToLogin] = useState<string[] | null>([]);

  const [config] = useDocument<Config>(configPath());
  const navigate = useNavigate();

  const {email, password} = userData;

  // retrieve invitationId from location state
  const location = useLocation();
  const locationState = location.state as { invitationId: string };

  useEffect(() => {
    if (!config) {
      setUnderMaintenance(false);
      setAllowedEmailToLogin(null);
      return;
    }

    setUnderMaintenance(config.underMaintenance);
    setAllowedEmailToLogin(config.allowedEmailToLogin);
  }, [config]);

  useEffect(() => {
    document.title = "Swifttt";
    setContext(new AudioContext())

    return onAuthStateChanged(auth, setFirebaseUser)
  }, []);

  useEffect(() => {
    if (selectedLoginOrg && locationState === null) {
      if (!!superUid) {
        navigate(`/${selectedLoginOrg.id}/explore-organizations`);
        return;
      }

      navigate(`/${selectedLoginOrg.id}/home`);
      return;
    }

    if (!user) return;

    const invitationId = locationState?.invitationId;

    const verifyInvite = async() => {
      // fetch invite doc
      const inviteDoc = await getDoc(doc(db, "users", user.uid, "invites", invitationId));
      if (inviteDoc.exists())
        return navigate(`/view-invite`);

      return navigate(`/${selectedLoginOrg?.id}/home`);
    }

    if (selectedLoginOrg && !!invitationId) {
      verifyInvite();
    }
  }, [selectedLoginOrg, superUid]);

  useEffect(() => {
    if (!user) return;
    return onSnapshot(doc(db, "users", user.uid), (querySnapshot) => {
      const data = querySnapshot.data() as User;

      if (!data) return;

      if(querySnapshot.metadata.fromCache && !data?.isVerified)
        return;

      if (typeof data?.active !== "undefined" && !data?.active) {
        setEmailError(EmailError.Deactivated);
        signOut(auth);
        return;
      }

      if (!data?.isVerified) {
        // TODO: Temporary only. navigate to not verified page instead.
        setEmailError(EmailError.NotVerified);
        // TODO:let the not verified page do the signing out.
        signOut(auth);
        return;
      }
      const selectedOrg = data.sidebarView.selectedOrg;
      setSelectedLoginOrg({...selectedOrg, isAdminView: false});

      playSound();
    });
  }, [firebaseUser]);

  useEffect(() => {
    if (!isVerificationSent) return;
    if (!verificationEmailDoc) {
      setAlert({ 
        show: true,  
        message: <Typography variant="body" color={theme.palette.success.main}>Verification email sent</Typography>,
        severity: Severity.Success
      });
      return;
    }
    const status = getDocumentStatus(verificationEmailDoc);
    if (status === ViewStatus.ValidationError) {
      const errorList = Object.entries(verificationEmailDoc!["@form"]?.["@messages"]!);
      setAlert({ 
        show: true,  
        message: (
          <Typography variant="body" color={theme.palette.error.main}>
            {enCommonLabel.somethingWentWrong}: {errorList[0][1]}
          </Typography>
        ),
        severity: Severity.Error
      });
      return;
    }
  }, [isVerificationSent]);

  useEffect(() => {
    async function fetchSuperUid() {
      const currentSuperUid = await getSuperUid();
      setSuperUid(typeof currentSuperUid === "string" ? currentSuperUid : undefined);
    }

    fetchSuperUid();
  }, [user]);

  useEffect(() => {
    if (!email || !password || emailError || alert.show) {
      setIsFormValidated(false);
      return;
    }

    setIsFormValidated(true);

  }, [email, password, emailError, alert]);

  function handleUserDataChange(key: string, value: string) {
    if (context && context.state === "suspended") {
      context.resume();
    }
    setUserData((prev) => ({...prev, [key]: value}));
    setEmailError("");
    setAlert({show: false, message: null, severity: Severity.Error});
  }

  async function logIn(e?: React.MouseEvent<HTMLElement>) {
    if (context && context.state !== "running") context.resume();
    if (underMaintenance && allowedEmailToLogin && !allowedEmailToLogin.includes(email)) {
      return;
    }

    !!e && e.preventDefault();

    setLoading(true);
    signInWithEmailAndPassword(auth, email, password).catch((err: FirebaseError) => {
      switch (err.code) {
        case "auth/user-not-found":
        case "auth/invalid-email":
        case "auth/wrong-password":
          setEmailError(EmailError.Invalid);
          break;
        case "auth/too-many-requests":
          setAlert({
            show: true,
            message: (
              <Typography variant="body" color={theme.palette.error.main}>
                {enCommonLabel.tooManyLoginAttemps}
              </Typography>
            ),
            severity: Severity.Error
          });
          break;
        default:
          setAlert({ 
            show: true,  
            message: (
              <Typography variant="body" color={theme.palette.error.main}>
                {enCommonLabel.somethingWentWrong}
              </Typography>
            ),
            severity: Severity.Error
          });
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  function emailInputChange(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | React.ClipboardEvent<HTMLDivElement>) {
    const element = e.currentTarget as HTMLInputElement;
    const value = element.value.trim();

    handleUserDataChange("email", value);
  }

  function passwordInputChange(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | React.ClipboardEvent<HTMLDivElement>) {
    const element = e.currentTarget as HTMLInputElement;
    const value = element.value;

    handleUserDataChange("password", value);
  }

  async function onEnterKeyPress(e: React.KeyboardEvent) {
    if (!isFormValidated) return;

    if (e.key === "Enter") {
      await logIn();
    }
  }

  async function resendVerificationLinkHandler(uid: string) {
    const docRef = doc(resendVerificationEmailPath(uid!));
    await submitForm(docRef, ActionType.Create, emptyFunction, {email});
    setVerificationEmailDocRef(docRef);
    setIsVerificationSent(true);
    setEmailError("");
  }

  function resendVerificationLink() {
    signInAnonymously(auth)
    .then((userData) => {
      const {uid} = userData.user;
      resendVerificationLinkHandler(uid!);
      signOut(auth);
    })
  }

  const isResendEmailFinished = (isVerificationSent && !verificationEmailDoc);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        zIndex: "-2!important",
        backgroundImage: `url("/assets/Artboard6.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{height: "100vh"}}
      >
        <FormContainer sx={{
          maxWidth: {xs: "unset", sm: "unset", md: "30%", lg: "20%", xl: "20%"},
        }}>
          <Stack direction="column" gap={1} justifyContent="center" textAlign="center" py="2%" flex={1}>
            <Box
              sx={{
                alignSelf: "center",
                display: "flex",
                gap: 1
              }}
            >
              <SwiftLogoOnly
                height={70}
                width={70}
                fill={theme.palette.secondary.main}
                stroke={theme.palette.secondary.main}
              />
            </Box>
            <Stack direction="column" marginTop={3}>
              <Typography variant="h2">
                {enCommonLabel.welcome}
              </Typography>
              <hr
                style={{
                  borderRadius: 5,
                  width: "25%",
                  height: "5px",
                  border: "none",
                  backgroundColor: theme.palette.secondary.main
                }}
              />
            </Stack>
            <Stack direction="column" gap={1} marginTop={5} textAlign="left">
              <MessageBox
                message={[
                  enCommonLabel.emailNotVerified,
                  enCommonLabel.checkEmailForVerification
                ]}
                isOpen={emailError === EmailError.NotVerified}
                actionComponent={(
                  <Typography
                    variant="h5"
                    sx={{cursor: (!isVerificationSent) ? "pointer" : "default", textDecoration: "underline"}}
                    onClick={(!isVerificationSent) ? resendVerificationLink : emptyFunction}>
                    {enCommonLabel.resendVerificationEmail} {(!!verificationEmailDoc && !isResendEmailFinished) && (
                    <CircularProgress size={15} sx={{marginLeft: 1}}/>
                  )}
                  </Typography>
                )}
                isVisible={true}
              />
              {underMaintenance && (
                <Alert severity={Severity.Error} icon={false}>
                  <Typography variant="body" color={theme.palette.error.main}>
                    {enCommonLabel.loginRestriction}
                  </Typography>
                </Alert>
              )}
              {alert.show && <Alert severity={alert.severity} icon={false}>{alert.message}</Alert>}
              <StandardInput
                id="email-field"
                label={enCommonLabel.username}
                type="text"
                variant="standard"
                onChange={emailInputChange}
                onKeyPress={onEnterKeyPress}
                value={email}
              />
              <ValidationMessage validationMessage={emailError}/>
              <StandardPasswordInput
                id="password-field"
                label={enCommonLabel.password}
                type="password"
                variant="standard"
                onChange={passwordInputChange}
                onKeyPress={onEnterKeyPress}
                value={password}
              />
              <Typography variant="body1">
                <Link
                  component={RouterLink}
                  to="/reset-password"
                  sx={{
                    color: theme.palette.secondary.main,
                    textDecoration: "none",
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                  id="forgot-password-link"
                >
                  <strong>{enCommonLabel.forgotPassword}</strong>
                </Link>
              </Typography>
              <LoadingButton
                type="submit"
                loading={loading}
                onClick={logIn}
                disabled={!isFormValidated}
                variant="contained"
                id="login-btn"
                sx={{marginTop: 2, borderRadius: 5, backgroundColor: theme.palette.secondary.main, padding: 1}}
              >
                {enCommonButton.login}
              </LoadingButton>
              <Stack direction="row" flex={1} justifyContent="center" alignItems="center" gap={0} mt={0.5}>
                <Typography variant="body1">
                  Don't have an account?&nbsp;
                  <Link
                    component={RouterLink}
                    to="/sign-up"
                    sx={{
                      color: theme.palette.secondary.main,
                      textDecoration: "none",
                      "&:hover": {
                        color: theme.palette.primary.main,
                      }
                    }}
                    id="forgot-password-link"
                  >
                    <strong>{enCommonButton.signup}</strong>
                  </Link>
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </FormContainer>
      </Stack>
      <Hidden smDown>
        <Box sx={{position: "absolute", bottom: "2%", right: "2%", zIndex: 2}}>
          <SwiftLogoOnly
            height={40}
            width={40}
            stroke={theme.palette.background.default}
            fill={theme.palette.background.default}
          />
        </Box>
      </Hidden>
    </Box>
  );
}

export default LogIn;
