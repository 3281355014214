import {Alert, Checkbox, Hidden, InputAdornment, Stack, styled, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {app, auth, db} from "../../firebase";
import {AuthError, createUserWithEmailAndPassword} from "firebase/auth";
import {User} from "types/User";
import {ValidationMessage} from "components/index";
import {MuiTelInput, MuiTelInputCountry} from "mui-tel-input";
import theme from "theme/theme";
import {doc, getDoc} from "firebase/firestore";
import {LoadingButton} from "@mui/lab";
import {configPath, parseToken, submitForm, usersPath} from "../utility";
import {useNavigate} from "react-router-dom";
import {ActionType, Severity, ViewStatus} from "enums/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {DataPolicyDialog, TermsOfServiceDialog} from "components/Dialogs";
import useQuery from "hooks/useQuery";
import validEmail from "../utility/validEmail";
import {SwiftLogoOnly} from "assets/icons/SwiftLogo";
import FormContainer from "components/FormContainer";
import StandardInput from "components/inputs/StandardInput";
import StandardPasswordInput from "components/inputs/StandardPasswordInput";
import {emptyFunction, enCommonButton, enCommonLabel} from "constants/index";
import {FirebaseAuthError} from "enums/FirebaseAuthError";
import {en} from "language/en";
import {AlertMessage} from "types/Alert";
import validatePassword from "../utility/validatePassword";
import {useDocument} from "hooks/index";
import {Config} from "types/Config";
import useMaintenance from "hooks/useMaintenance";
import {initClient} from "emberflow-web-client/lib";

const MAX_PHONE_NO_LENGTH = 16;

export default function SignUp() {
  useMaintenance(false);

  const token = useQuery().get("token");

  const [userData, setUserData] = useState<User>({} as User);
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [emailValidationError, setEmailValidationError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentCountryCode, setCurrentCountryCode] = useState<MuiTelInputCountry | null>(null);
  const [isTermsOfServiceOpen, setIsTermsOfServiceOpen] = useState(false);
  const [isDataPolicyOpen, setIsDataPolicyOpen] = useState(false);
  const [alert, setAlert] = useState<AlertMessage>({
    show: false,
    message: null,
    severity: Severity.Error
  });
  const [config] = useDocument<Config>(configPath());

  const {email, firstName, lastName, phoneNumber} = userData;
  const navigate = useNavigate();
  const parsedToken = !!token ? parseToken(token) : null;
  useEffect(() => {
    if (!parsedToken || !parsedToken?.email) return;
    setUserData({...userData, email: parsedToken.email});
  }, []);

  // Email validation
  useEffect(() => {
    if (!email) return;
    if (!validEmail(email)) {
      setEmailValidationError(enCommonLabel.enterValidEmail);
    } else {
      setEmailValidationError('');
    }
  }, [email]);

  useEffect(() => {
    if (!password) return;
    if (!validatePassword(password)) {
      setPasswordError(enCommonLabel.passwordValidationError);
      return;
    }
    setPasswordError("");
  }, [password]);

  useEffect(() => {
    if (!!auth.currentUser) {
      auth.signOut();
    }
  }, []);

  // validate confirm password
  useEffect(() => {
    if (!confirmPassword)
      setConfirmPasswordError("");
    else if (password !== confirmPassword)
      setConfirmPasswordError(enCommonLabel.passwordDoesNotMatch);
    else
      setConfirmPasswordError("");
  }, [confirmPassword, password]);

  function handleUserDataChange(key: string, value: string) {
    if (key === "email") setEmailValidationError("");
    if (key === "phoneNumber" && value.length > MAX_PHONE_NO_LENGTH) return;
    setUserData((prev) => ({...prev, [key]: value}));
  }

  async function submitCreateUser() {
    setIsLoading(true);

    // check if email is listed in allowed emails/domains
    const isEmailAllowed = await isAllowedEmail(email);
    const isEmailDomainAllowed = await isAllowedDomain(email);

    if (!isEmailAllowed && !isEmailDomainAllowed) {
      setEmailValidationError(en.screen.Signup.label.notAllowed);
      setIsLoading(false);
      return;
    }

    if (!!auth.currentUser) {
      auth.signOut();
    }

    try {
      const userCredentials = await createUserWithEmailAndPassword(auth, email, password);
      initClient(
        app,
        userCredentials.user.uid,
        undefined,
        {
          "submit": ViewStatus.Submit,
          "submitted": ViewStatus.Submitted,
          "validation-error": ViewStatus.ValidationError,
          "security-error": ViewStatus.SecurityError,
          "finished": ViewStatus.Finished,
          "delay": ViewStatus.Delay,
          "cancel": ViewStatus.Cancel,
          "cancelled": ViewStatus.Cancelled,
          "error": ViewStatus.Error,
        }
      );

      const userDocRef = doc(usersPath(), userCredentials.user.uid);

      submitForm(userDocRef, ActionType.Create,
        emptyFunction,
        {
        email,
        firstName,
        lastName,
        ...(!!phoneNumber ? {phoneNumber} : {}),
        phoneNumberCountryCode: currentCountryCode
      }).then(async () => {
        await auth.signOut();
        navigate("/sign-up/success", {
          state: {
            email
          }
        });
      });
    } catch (e: any) {
      console.log("has error ", e);
      const err = e as AuthError;
      setIsLoading(false);

      switch (err.code) {
        case FirebaseAuthError.EmailAlreadyInUse:
          setEmailValidationError(en.screen.Signup.label.accountAlreadyExists);
          break;
        case FirebaseAuthError.InvalidEmail:
          setEmailValidationError(en.screen.Signup.label.invalidEmail);
          break;
        default:
          setAlert({
            show: true,
            message: (
              <Typography variant="body" color={theme.palette.error.main}>
                {en.common.validations.generalError}
              </Typography>
            ),
            severity: Severity.Error
          });
          break;
      }
      return;
    }
  }

  const isFormValidated =
    email && validEmail(email) && validatePassword(password) && firstName && lastName && isAgree && confirmPassword && !confirmPasswordError;

  function onKeyPress(e: React.KeyboardEvent) {
    if (e.key === "Enter" && isFormValidated) submitCreateUser();
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        zIndex: "-2!important",
        backgroundImage: `url("/assets/Artboard6.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <DataPolicyDialog isDialogOpen={isDataPolicyOpen} setIsDialogOpen={setIsDataPolicyOpen}/>
      <TermsOfServiceDialog isDialogOpen={isTermsOfServiceOpen} setIsDialogOpen={setIsTermsOfServiceOpen}/>

      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{height: "100vh"}}
      >
        <FormContainer
          sx={{
            minHeight: {xs: "95%", sm: "80%", md: "60%", lg: "60%", xl: "60%"},
            maxWidth: "393px",
          }}
        >
          <Stack direction="column" gap={1} justifyContent="center" textAlign="center" py="2%" flex={1}>
            <Stack direction="column" marginTop={0.5}>
              <Typography variant="h1">{enCommonLabel.signup}</Typography>
              <hr
                style={{
                  borderRadius: 5,
                  width: "25%",
                  height: "5px",
                  border: "none",
                  backgroundColor: theme.palette.secondary.main
                }}
              />
            </Stack>
            <Stack gap={1} marginTop={1}>
              {config?.underMaintenance && (
                <Alert severity={Severity.Error} icon={false} sx={{px: 0}}>
                  <Typography variant="body" color={theme.palette.error.main}>
                    {enCommonLabel.loginRestriction}
                  </Typography>
                </Alert>
              )}
              {alert.show && <Alert severity={alert.severity} icon={false}>{alert.message}</Alert>}
              <StandardInput
                id="email-field"
                label={enCommonLabel.email}
                type="text"
                variant="standard"
                value={userData.email}
                disabled={!!parsedToken?.email}
                onChange={(e) => handleUserDataChange("email", (e.target as HTMLInputElement).value.replace(/\s/g, ''))}
                onKeyPress={onKeyPress}
              />
              <ValidationMessage validationMessage={emailValidationError}/>
              <StandardPasswordInput
                id="password-field"
                label={enCommonLabel.password}
                type="password"
                variant="standard"
                onChange={(e) => setPassword((e.target as HTMLInputElement).value)}
                value={password}
                onKeyPress={onKeyPress}
              />
              <ValidationMessage
                sx={{
                  maxWidth: "328px",
                  textAlign: "left",
                  lineHeight: "14px"
                }}
                validationMessage={passwordError}
              />
              <StandardPasswordInput
                id="confirm-password-field"
                label={enCommonLabel.confirmPassword}
                type="password"
                variant="standard"
                onChange={(e) => setConfirmPassword((e.target as HTMLInputElement).value)}
                value={confirmPassword}
                onKeyPress={onKeyPress}
              />
              <ValidationMessage validationMessage={confirmPasswordError}/>
              <StandardInput
                id="first-name-field"
                label="First Name"
                type="text"
                variant="standard"
                value={userData.firstName}
                onChange={(e) => handleUserDataChange("firstName", (e.target as HTMLInputElement).value)}
                onKeyPress={onKeyPress}
              />
              <StandardInput
                id="last-name-field"
                label={enCommonLabel.lastName}
                type="text"
                variant="standard"
                value={userData.lastName}
                onChange={(e) => handleUserDataChange("lastName", (e.target as HTMLInputElement).value)}
                onKeyPress={onKeyPress}
              />
              <Stack gap={1} mt={1}>
                <Stack direction="row" position="relative">
                  <InputAdornment
                    position="start"
                    sx={{
                      position: "absolute",
                      top: 32,
                      left: 35,
                      zIndex: 2,
                    }}
                  >
                    <SystemIcons.ChevronDown width={16} height={16}/>
                  </InputAdornment>
                  <MuiTelInput
                    variant="standard"
                    flagSize="small"
                    label={enCommonLabel.phoneOptional}
                    InputProps={{
                      sx: {
                        padding: 0,
                      },
                    }}
                    sx={{
                      width: "100%",
                      ".MuiOutlinedInput-input": {
                        paddingTop: 1.2,
                        paddingBottom: 1.2,
                      },
                      ".MuiTelInput-IconButton": {
                        paddingRight: 2,
                      },
                      "& .MuiInputBase-root": {
                        padding: 0.2,
                        color: theme.palette.secondary.main,
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: theme.palette.secondary.main,
                      },
                      "& .MuiInputBase-root:after": {
                        borderBottom: theme.palette.secondary.main,
                      },
                    }}
                    value={(userData.phoneNumber ?? "") as string}
                    defaultCountry={currentCountryCode ?? "US"}
                    onChange={(e) => handleUserDataChange("phoneNumber", e)}
                  />
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="flex-start" gap={1} mt={1} justifyContent="flex-start">
                <Checkbox sx={{padding: 0}} onChange={(_, checked) => setIsAgree(checked)} checked={isAgree}/>
                <Typography
                  variant="body1"
                  textAlign="left"
                  flexWrap="wrap"
                >
                  {enCommonLabel.iAgree}&nbsp;
                  <StyledAnchor
                    href="#"
                    onClick={() => setIsTermsOfServiceOpen(true)}
                  >
                    {enCommonLabel.termsOfService}
                  </StyledAnchor>
                  &nbsp;{enCommonLabel.and}&nbsp;
                  <StyledAnchor
                    href="#"
                    onClick={() => setIsDataPolicyOpen(true)}
                  >
                    {enCommonLabel.privacyPolicy}
                  </StyledAnchor>
                </Typography>
              </Stack>
              <LoadingButton
                variant="contained"
                onClick={submitCreateUser}
                disabled={!isFormValidated || emailValidationError !== ""}
                loading={isLoading}
                sx={{marginTop: 2, borderRadius: 5, backgroundColor: theme.palette.secondary.main, padding: 1}}
              >
                {enCommonButton.signup}
              </LoadingButton>
              <Typography
                variant="body1"
                align="center"
              >
                {enCommonLabel.alreadyHaveAnAccount} <StyledAnchor href="login">{enCommonLabel.login}</StyledAnchor>
              </Typography>
            </Stack>
          </Stack>
        </FormContainer>
      </Stack>
      <Hidden smDown>
        <Box sx={{position: "absolute", bottom: "2%", right: "2%", zIndex: 2}}>
          <SwiftLogoOnly
            height={40}
            width={40}
            stroke={theme.palette.background.default}
            fill={theme.palette.background.default}
          />
        </Box>
      </Hidden>
    </Box>
  );
}

const StyledAnchor = styled('a')`text-decoration: none;
  color: ${theme.palette.primary.main};`


async function isAllowedEmail(email: string) {
  const ref = doc(db, "allowedSignUps", "email");

  let emailDoc = await getDoc(ref).then((doc) => doc.data())
    .catch((err) => {console.log(err)});
  if (!emailDoc) return false;

  const allowedEmails = emailDoc.emails;

  if (allowedEmails.includes('*')) return true;

  return allowedEmails.includes(email);
}

async function isAllowedDomain(email: string) {
  const domain = email.split('@')[1];

  const domainDoc = (await getDoc(doc(db, "allowedSignUps", "domain"))).data();
  if (!domainDoc) return false;

  const allowedDomains = domainDoc.domains;

  if (allowedDomains.includes('*')) return true;

  return allowedDomains.includes(domain);
}
