import {Popover} from "@mui/material";
import DatePicker from "components/DatePicker";

interface DueDatePopoverProps {
  isOpen: boolean;
  selectedDate: Date | null;
  anchorElement: HTMLElement | null;
  disablePreviousDate?: boolean;
  setSelectedDate: (date: Date | null) => void;
  closePopover: () => void;
}

function DueDatePopover(props: DueDatePopoverProps) {
  const {isOpen, anchorElement, selectedDate, disablePreviousDate = true} = props;
  const {setSelectedDate, closePopover} = props;

  function updateSelectedDate(date: Date | null) {
    setSelectedDate(date);
    closePopover();
  }

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorElement}
      onClose={closePopover}>
      <DatePicker
        disablePreviousDate={disablePreviousDate}
        selectedDate={selectedDate}
        setSelectedDate={(date: Date | null) => updateSelectedDate(date)}
      />
    </Popover>
  )
}

export default DueDatePopover;