import {Stack, styled} from "@mui/material";
import theme from "theme/theme";

export default styled(Stack)({
  alignItems: "center",
  padding: 1,
  paddingRight: 0,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 4,
  justifyContent: "flex-start",
  flex:1,
});