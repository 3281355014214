import {Project} from "types/Project";
import {Box, Checkbox, Divider, IconButton, Stack, styled, Typography} from "@mui/material";
import theme from "theme/theme";
import {LongText} from "components/index";
import {SidebarIcons} from "assets/icons/menu/menu.index";
import {SystemIcons} from "assets/icons/system/system.index";
import {enTemplateButton} from "constants/index";
import {useNavigate, useParams} from "react-router-dom";
import {CounterFields} from "enums/counterFields";
import useRTDBDocField from "hooks/useRTDBDocField";

type TemplateProjectCardProps = {
  index: number;
  project: Project;
  parentPath: string;
  uid: string;
  onDeleteClick: (id: string) => void;
  onProjectCardSelect: (id: string, checked: boolean) => void;
  isSelected: boolean;
}

export function TemplateProjectCard(props: TemplateProjectCardProps) {
  const {project, isSelected, onDeleteClick, onProjectCardSelect} = props;

  const {orgId} = useParams()
  const {name, description, "@id": id} = project;

  const assetsCount = useRTDBDocField<number>(props.parentPath+"/"+id ?? "", CounterFields.AssetsCount) ?? 0;
  const navigate = useNavigate();

  function onSelect(e: React.ChangeEvent<HTMLInputElement>) {
    const isChecked = e.target.checked;
    onProjectCardSelect(project["@id"]!, isChecked);
  }

  function onUnlinkClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    onDeleteClick(id ?? "");
  }

  if (!id) return null; // only display projects with @id (processed by BE)

  return (
    <Box
      onClick={() => navigate(`/${orgId}/projects/${project["@id"]}`)}
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <CardWrapper isSelected={isSelected}>
        <Stack direction="row" alignItems="center" paddingY={0} paddingX={0.5}>
          <Checkbox
            onClick={(e) => e.stopPropagation()}
            onChange={onSelect}
            checked={isSelected}
          />
          <Divider
            orientation="vertical"
            flexItem
            sx={{mx: 1, minHeight: "100%"}}
          />
          <Stack direction="column">
            <LongText variant="h4" flex={1}>{name}</LongText>
            {!!description && <LongText variant="bodySmall" flex={1}>{description}</LongText>}
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1.5}>
          <Divider
            orientation="vertical"
            flexItem
            sx={{mx: 1}}
          />
          <Stack direction="row" gap={1} alignItems="center" py={1}>
            <SidebarIcons.Assets width={16} height={16}/>
            <Typography variant="h5">{assetsCount}</Typography>
            <IconButton onClick={(e) => onUnlinkClick(e)} title={enTemplateButton.unlinkProject}>
              <SystemIcons.Unlink/>
            </IconButton>
          </Stack>
        </Stack>
      </CardWrapper>
    </Box>
  )
}

function CardWrapper({isSelected, children}: { isSelected: boolean, children: JSX.Element | JSX.Element[] }) {
  const Wrapper = styled(Stack)({
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    border: `1px solid ${isSelected ? theme.palette.primary.main : theme.palette.neutral.light}`,
    borderRadius: 4,
    "&:hover": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  })
  return <Wrapper>{children}</Wrapper>
}
