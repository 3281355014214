import {createTheme} from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Lato",
    button: {
      textTransform: "none",
    },
    h1: {
      fontWeight: "bold",
      fontSize: "30px",
      lineHeight: "40px",
    },
    h2: {
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "36px",
    },
    h3: {
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "28px",
    },
    h4: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "24px",
    },
    h5: {
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "22px",
    },
    body1: {
      fontSize: "14px",
      lineHeight: "22px",
    },
    body: {
      fontWeight: "regular",
      fontSize: "14px",
      lineHeight: "22px",
    },
    bodySmall: {
      fontWeight: "regular",
      fontSize: "12px",
      lineHeight: "20px",
    },
  },
  palette: {
    primary: {
      main: "#2B8DFF",
      dark: "#0072f7",
    },
    secondary: {
      main: "#1D1D1E",
      light: "#363638",
    },
    background: {
      paper: "#FFFFFF",
      default: "#FFFFFF",
      swiftDefault: "#F8F8F8",
    },
    text: {
      secondary: "#858585",
      onDark: "white",
    },
    error: {
      main: "#FF5230",
    },
    neutral: {
      main: "#cbcbcb",
      dark: "#858585",
      light: "#EAEAEA",
      medium: "#CBCBCB",
    },
    divider: "#EAEAEA",
    success: {
      main: "#3CB877",
      light: "#5DCA91",
      dark: "#2F925E",
    },
    chip: {
      main: "#F8F8F8",
      contrastText: "#0072f7",
    },
    avatar: {
      pink: "#FF5EA8",
      purple: "#7A49A5",
      gold: "#CA915D",
      orange: "#FE9745",
      blue: "#5EA8FF",
      green: "#5DCA91"
    },
    warning: {
      main: "#F8CF1C",
      dark: "#DAB307",
    },
    task: {
      main: "#FF9800",
      dark: "#DAB307",
    },
    favorite: {
      main: "#F8CF1C",
      dark: "#F8CF1C",
    }
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          textTransform: "none",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderColor: "#FF5230",
          padding: "9px 16px",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          height: '32px',
          width: '32px',
          marginLeft: '0px !important',
          marginTop: '-20px',
          fontSize: '16px'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflow: "auto",
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#FFFFFF",
            width: '0.6em'
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            minHeight: 24,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    }
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
    chip: Palette["primary"];
    avatar: Record<string, string>;
    favorite: Record<string, string>;
    task: Record<string, string>;
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
    chip?: PaletteOptions["primary"];
    avatar?: Record<string, string>;
    favorite?: Record<string, string>;
    task?: Record<string, string>;
  }

  // interface TypeAvatar {
  //   pink?: string;
  //   gold?: string;
  //   purple?: string;
  // }

  interface TypeText {
    onDark?: string;
    medium?: string;
  }

  interface TypeBackground {
    swiftDefault?: string;
  }

  interface PaletteColor {
    medium?: string;
    favorite?: string;
  }

  interface SimplePaletteColorOptions {
    medium?: string;
  }

  interface TypographyVariants {
    body: React.CSSProperties;
    bodySmall: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body?: React.CSSProperties;
    bodySmall?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body: true;
    bodySmall: true;
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    chip: true;
  }
}

export default theme;
