import {Box} from "@mui/material";
import {Asset} from "types/Asset";
import AssetCard from "./AssetCard";
import {DirectionalOrder} from "enums/index";
import {sortObjectsBy} from "screens/utility";
import {toastProps} from "../../BaseProps";
import {useParams} from "react-router-dom";

interface AssetTableProps {
  disabled: boolean;
  assets: Asset[];
  onCheckChange: (id: string, checked: boolean) => void;
  modifiedAssets: Asset[];
  parentCollectionPath: string;
  toastProps: toastProps;
  uid: string;
}

function AssetTable(props: AssetTableProps) {
  const {assets, disabled, modifiedAssets, onCheckChange, ...rest} = props;
  const sortedAssets = sortObjectsBy([...assets] || [], "name", DirectionalOrder.asc);

  const {projId} = useParams()

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {sortedAssets.map((asset: Asset) => {
        const checked = asset.projectIds ? asset.projectIds.includes(projId!) : false;

        return (
          <AssetCard
            key={asset["@id"]!}
            asset={asset}
            isAssetChecked={checked}
            onCheck={onCheckChange}
            disabled={disabled}
            {...rest}
          />
        )}
      )}
    </Box>
  )
}

export default AssetTable;