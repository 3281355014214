import {Checkbox, Stack, Typography} from "@mui/material";
import CheckboxSwitch from "components/CheckboxSwitch";
import React from "react";
import {enCommonLabel} from "constants/index";
import DeleteButton from "components/Button/BulkActionButtons/DeleteButton";

export interface BulkActionsProps {
  canDelete: boolean;
  checkedAssets: Set<string>;
  isCheckboxShown: boolean;
  isAllChecked: boolean;
  openDeleteDialog: () => void;
  setIsAllChecked: (checked: boolean) => void;
  toggleCheckbox: (shown: boolean) => void;
}

function BulkActions(props: BulkActionsProps) {
  const {checkedAssets, isCheckboxShown, isAllChecked, setIsAllChecked, toggleCheckbox} = props;

  return (
    <Stack
      direction={{md: "column", lg: "row"}}
      alignItems={{md: "flex-start", lg:"center"}}
      height={{md: "unset", lg: "1.5rem"}}
      gap={{md: 0, lg: 1}}
    >
      <CheckboxSwitch
        isCheckboxShown={isCheckboxShown}
        toggleCheckbox={toggleCheckbox}
      />
      {isCheckboxShown && (
        <Stack direction="row" alignItems="center" justifyContent="flex-start" justifyItems="center">
          <Checkbox
            onChange={(e) => setIsAllChecked(e.target.checked)}
            checked={isAllChecked}
          />
          <Typography variant="h5">
            {isAllChecked ? enCommonLabel.unselectAll("") : enCommonLabel.selectAll("")}
          </Typography>
          {checkedAssets.size > 0 && (
            <DeleteButton
              disabled={!props.canDelete}
              onButtonClick={props.openDeleteDialog}
              sx={{marginLeft: 2}}
            />
          )}
        </Stack>
      )}
    </Stack>
  )
}

export default BulkActions;