import {Entity} from "enums/entity";
import {FileRequirement} from "types/FileRequirement";
import {collection, CollectionReference} from "firebase/firestore";
import {BaseProps} from "screens/BaseProps";
import RequirementItem from "./RequirementItem";
import {AccessType, PermissionEntity} from "types/Permission";
import React, {useState} from "react";
import {Alert, Backdrop, Snackbar, Stack} from "@mui/material";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {enTaskLabel} from "constants/index";
import {UploadFile} from "hooks/useFileUpload/useFileUpload";
import useTemplateAccess from "hooks/useTemplateAccess";
import {db} from "../../../firebase";
import {useParams} from "react-router-dom";

interface RequirementsListProps extends BaseProps {
  fileRequirementAccess: AccessType | null;
  parentEntity: Entity;
  displayedRequirements: FileRequirement[];
  selectedRequirements: Set<string>;
  onCheckboxToggle: (checked: boolean, id: string) => void;
  fileRequirementsRef: CollectionReference;
  deleteRequirement: (requirement: string) => void;
  openRequirementDrawer: (requirement: FileRequirement) => void;
  addFiles: (files: UploadFile[]) => void;
  showCheckBox: boolean;
  taskCollectionRefPath?: string;
}

function RequirementsList(props: RequirementsListProps) {
  const {parentEntity, fileRequirementAccess, displayedRequirements, selectedRequirements, showCheckBox} = props;

  const {taskId} = useParams();

  const [clickedCarouselIndex, setClickedCarouselIndex] = useState<number | null>(null);
  const [fileDropItem, setFileDropItem] = useState<string | null>(null);
  const [fileAccess] = useTemplateAccess({
    uid: props.uid!,
    entity: Entity.Task,
    documentDocId: parentEntity === Entity.Asset ? PermissionEntity.SwiftFile
      : PermissionEntity.PLFile,
    accessListRef: props.taskCollectionRefPath ? collection(db, props.taskCollectionRefPath, taskId!, "accessList")
      : undefined
  });

  if (parentEntity === Entity.Templates) return null;

  function onFilePreviewClick(index: number) {
    setClickedCarouselIndex(index);
  }

  return (
    <>
      <Snackbar
        open={fileDropItem !== null}
        onClose={() => setFileDropItem(null)}
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
        sx={{marginLeft: "140px"}}
        className="file-upload-snackbar"
      >
        <Alert
          onClose={() => setFileDropItem(null)}
          sx={{
            width: "100%",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.onDark,
          }}
          icon={SystemIcons.CloudUpload({fill: theme.palette.background.swiftDefault})}
        >
          {enTaskLabel.dropFiles}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
        open={clickedCarouselIndex !== null}
      >
        <Stack
          direction="row"
          position="relative"
          sx={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
        </Stack>
      </Backdrop>
      {displayedRequirements.map((fileRequirement) => {
        return (
          <RequirementItem
            fileDropItem={fileDropItem}
            setFileDropItem={setFileDropItem}
            fileAccess={fileAccess}
            key={fileRequirement["@id"]!}
            fileRequirementAccess={fileRequirementAccess}
            fileRequirement={fileRequirement}
            isChecked={selectedRequirements.has(fileRequirement["@id"] ?? "")}
            fileRequirementsRef={props.fileRequirementsRef}
            onCheckboxToggle={props.onCheckboxToggle}
            onFilePreviewClick={onFilePreviewClick}
            openRequirementDrawer={props.openRequirementDrawer}
            toastProps={props.toastProps!}
            addFiles={props.addFiles}
            showCheckBox={showCheckBox}
            uid={props.uid}
          />
        )
      })}
    </>
  )
}

export default RequirementsList;
