import React from "react";
import {SystemIcons} from "assets/icons/system/system.index";
import MenuCard from "components/MenuCard";
import ActionOption from "components/ActionOption";
import {emptyFunction, enOrgAssetsButton} from "constants/index";

interface OptionsProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  closeMenu: (e: React.MouseEvent<HTMLElement>) => void;
  onDelete: () => void;
  canDelete: boolean;
}

function HeaderMoreOptionsMenu(props: OptionsProps) {
  const {open, anchorEl, closeMenu, onDelete, canDelete} = props;

  return (
    <MenuCard open={open} anchorEl={anchorEl} closeMenu={closeMenu}>
      <ActionOption
        Icon={<SystemIcons.DeleteOutlined height={16} width={16}/>}
        label={enOrgAssetsButton.deleteAsset}
        onClick={canDelete ? onDelete : emptyFunction}
        disabled={!canDelete}
        labelSx={(theme) => ({color: theme.palette.error.main})}
      />
    </MenuCard>
  );
}

export default HeaderMoreOptionsMenu;