// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {connectAuthEmulator, getAuth} from "firebase/auth";
import {
  connectFirestoreEmulator,
  initializeFirestore,
  persistentMultipleTabManager,
  persistentLocalCache,
  CACHE_SIZE_UNLIMITED
} from "firebase/firestore";
import {getStorage, connectStorageEmulator} from "firebase/storage";
import {getDatabase, connectDatabaseEmulator} from "firebase/database";
import getEnvKey from "./screens/utility/getEnvKey";
import {firebaseConfigKey, useLocalDBKey, vapidKey} from "constants/envKeys";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import {getMessaging, getToken} from "firebase/messaging";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = getEnvKey(firebaseConfigKey)

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = initializeFirestore(
  app,
  {
    localCache: persistentLocalCache(/*settings*/{tabManager: persistentMultipleTabManager(), cacheSizeBytes: CACHE_SIZE_UNLIMITED})
  }
);
export const storage = getStorage(app);
export const rtdb = getDatabase(app);

//instructions: set this to true to use localDB
const useLocalDb = getEnvKey(useLocalDBKey);

// eslint-disable-next-line no-restricted-globals
if (location.hostname === "localhost" && useLocalDb) {
  connectFirestoreEmulator(db, "localhost", 8080);
  connectAuthEmulator(auth, "http://localhost:9099/", {
    disableWarnings: true,
  });
  connectStorageEmulator(storage, "localhost", 9199);
  connectDatabaseEmulator(rtdb, "localhost", 9000);
}

const messaging = () => {
  if (!app) return;
  // not all browser supports getMessage firebase function
  try {
    return getMessaging(app);
  } catch (e) {
    return null;
  }
}

export const getPushToken = async () => {
  const message = messaging();
  // incase browser doesn't support messaging
  if (!message) {
    console.log('Messaging is not supported by this browser');
    return Promise.reject('Messaging is not supported by this browser');
  }

  return getToken(message, {vapidKey: getEnvKey(vapidKey)}).then((currentToken) => {
    if (currentToken) {
      return Promise.resolve(currentToken);
    }

    // No registration token available. Request permission to generate one.
    return requestPermission();
  });
}

function requestPermission() {
   Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      // Notification permission granted. Generate token.
      return getPushToken();
    } else {
      return Promise.reject('Unable to get permission to notify.');
    }
  });
}