import useEmblaCarousel from "embla-carousel-react";
import React, {useCallback, useEffect, useRef, useState} from "react";
import "components/ChatDrawer/ChatItemsView/emblaStyles.css";
import {SystemIcons} from "assets/icons/system/system.index";
import AutoHeight from 'embla-carousel-auto-height'
import {Box, IconButton, Stack, Tooltip} from "@mui/material";
import theme from "theme/theme";
import {enCommonButton} from "constants/index";
import {getPreviewFromMime} from "../../../screens/utility";
import {Previews} from "enums/supportedPreviews";
import PDFImg from "assets/img/PDFImg.png";
import {InProgress} from "components/index";
import Thumbnail from "components/ChatDrawer/ChatItemsView/Thumbnail";

export type CarouselItem = {
  source: string;
  type?: string;
  previewType?: Previews;
  nameWithExt: string;
}

interface ChatAttachmentCarouselProps {
  carouselItems: CarouselItem[];
  clickedIndex: number;
  toggleAttachmentCarousel: () => void;
  isCarouselItemsLoading?: boolean;
}

export default function ChatAttachmentCarousel(props: ChatAttachmentCarouselProps) {
  const {carouselItems, clickedIndex, toggleAttachmentCarousel, isCarouselItemsLoading} = props;
  const [mainViewportRef, embla] = useEmblaCarousel(
    {
      dragFree: true,
      startIndex: clickedIndex,
    }, [AutoHeight({destroyHeight: 'auto'})]);
  const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
    containScroll: "keepSnaps",
    dragFree: true
  });
  const [selectedIndex, setSelectedIndex] = useState(clickedIndex);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const thumbnailContainerRef = useRef<HTMLDivElement>(null);
  const arrowLeftRef = useRef<HTMLButtonElement>(null);
  const arrowRightRef = useRef<HTMLButtonElement>(null);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  useEffect(() => {
    if (!thumbnailContainerRef)
      return

    if (!thumbnailContainerRef.current)
      return

    setIsOverflowing(thumbnailContainerRef.current.clientWidth < thumbnailContainerRef.current.scrollWidth)
  }, [thumbnailContainerRef, thumbnailContainerRef.current])

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape')
        return toggleAttachmentCarousel();
      if (event.key === "ArrowRight")
        return arrowRightRef && arrowRightRef.current && arrowRightRef.current.click();
      if (event.key === "ArrowLeft")
        return arrowLeftRef && arrowLeftRef.current && arrowLeftRef.current.click()
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const onThumbClick = useCallback(
    (index) => {
      if (!embla || !emblaThumbs) return;
      if (emblaThumbs.clickAllowed()) embla.scrollTo(index);
    },
    [embla, emblaThumbs]
  );

  const onSelect = useCallback(() => {
    if (!embla || !emblaThumbs) return;
    const nextIndex = embla.selectedScrollSnap();
    setSelectedIndex(() => nextIndex);
    emblaThumbs.scrollTo(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, emblaThumbs]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
  }, [embla, onSelect]);

  function downloadFileAttachment() {
    const currentItem = carouselItems[selectedIndex];
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const blob: Blob = xhr.response;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a')
      a.href = url;
      a.download = currentItem.nameWithExt;
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a)
    };
    xhr.open('GET', currentItem.source);
    xhr.send();
  }

  if (!carouselItems.length) return <></>

  function renderMainViewFromUrl(item: CarouselItem, isActive: boolean) {
    const {source, type} = item;

    if (!isActive) return null;

    if (isCarouselItemsLoading) return <InProgress sx={{position: "absolute", top: "50%", left: "50%"}}/>;

    const preview = getPreviewFromMime(type ?? "image/*");
    switch (preview) {
      case Previews.Image:
        return <img
          className="embla__slide__img"
          src={source}
          alt="chat attachment"
          style={{objectFit: "scale-down", maxHeight: "90%", maxWidth: "100%"}}
        />
      case Previews.Video:
        return <video
          className="embla__slide__img"
          style={{height: "auto", width: "100%"}}
          src={source}
          controls
        />
      case Previews.PDF:
        return <img
          className="embla__slide__img"
          src={PDFImg}
          alt="chat attachment"
        />
      default:
        return <Box
          className="embla__slide__img">
          <SystemIcons.FilesFilled fill="white" width={64} height={64}/>
        </Box>
    }
  }

  return (
    <Stack
      width="100%"
      tabIndex={0}
      sx={{
        "&:focus": {
          outline: "none",
        }
      }}
    >
      <Stack
        direction="row"
        sx={{
          position: "absolute",
          top: 16,
          left: 16,
          zIndex: 1
        }}>
        <Tooltip title={enCommonButton.close}>
          <IconButton
            onClick={toggleAttachmentCarousel}>
            <SystemIcons.Close stroke={theme.palette.common.white} style={{
              width: 28,
              height: 28
            }}/>
          </IconButton>
        </Tooltip>
        <Tooltip title={`Download ${carouselItems[selectedIndex]?.nameWithExt}`}>
          <IconButton
            onClick={downloadFileAttachment}>
            <SystemIcons.Download stroke={theme.palette.common.white} style={{
              width: 28,
              height: 28
            }}/>
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack width="100%" height="100%" justifyContent="center">
        <div className="embla" id="carouselMain">
          <div className="embla__viewport" ref={mainViewportRef}>
            <div className="embla__container">
              {
                carouselItems.map((val, index) => (
                  <div className="embla__slide" key={index}>
                    <div className="embla__slide__inner">
                      {renderMainViewFromUrl(val, index === selectedIndex)}
                    </div>
                  </div>
                ))
              }
            </div>
            <IconButton
              className="embla__button--prev"
              ref={arrowLeftRef}
              onClick={scrollPrev}
              disabled={!prevBtnEnabled}
              style={{
                height: "100%",
                top: 0,
                left: 16,
                borderRadius: 0,
                background: "linear-gradient(90deg, rgba(0,0,0,0.2) 0%, rgba(255,255,255,0) 100%)",
                position: "absolute",
                visibility: carouselItems.length <= 1 ? "hidden" : "visible"
              }}
            >
              <Box
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: "100%",
                  width: 48,
                  height: 48,
                  opacity: 0.75
                }}
              >
                <SystemIcons.ChevronLeft
                  stroke={prevBtnEnabled ? theme.palette.common.white : theme.palette.neutral.dark}
                  style={{width: 48, height: 48}}
                />
              </Box>
            </IconButton>
            <IconButton
              ref={arrowRightRef}
              className="embla__button--next"
              onClick={scrollNext}
              disabled={!nextBtnEnabled}
              style={{
                height: "100%",
                top: 0,
                right: 16,
                borderRadius: 0,
                background: "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.1) 100%)",
                position: "absolute",
                visibility: carouselItems.length <= 1 ? "hidden" : "visible"
              }}
            >
              <Box
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: "100%",
                  width: 48,
                  height: 48,
                  opacity: 0.75
                }}
              >
                <SystemIcons.ChevronRight
                  stroke={nextBtnEnabled ? theme.palette.common.white : theme.palette.neutral.dark}
                  style={{width: 48, height: 48}}
                />
              </Box>
            </IconButton>
          </div>
        </div>
        <div className="embla embla--thumb" style={{width: "100%"}}>
          <div className="embla__viewport" ref={thumbViewportRef}>
            <div
              ref={thumbnailContainerRef}
              className="embla__thumb__container embla__container--thumb"
              style={{justifyContent: isOverflowing ? "flex-start" : "center"}}
            >
              {carouselItems.map((val, index) => (
                <Thumbnail
                  onClick={() => onThumbClick(index)}
                  selected={index === selectedIndex}
                  item={val}
                  viewable={Math.abs(index - selectedIndex) <= 2}
                  key={index}
                />
              ))}
            </div>
          </div>
        </div>
      </Stack>
    </Stack>
  )
}
