import {Stack} from "@mui/material";
import {emptyFunction, enCommonLabel, enMemberLabel} from "constants/index";
import {ConfirmDialog} from "components/index";
import MemberCard from "./MemberCard";
import {Member} from "types/index";
import React, {useCallback, useState} from "react";
import {doc} from "firebase/firestore";
import {organizationMembersPath} from "screens/utility/FirebasePath";
import BulkChangeRoleDrawer from "components/Drawers/BulkChangeRoleDrawer";
import {AccessRole, ActionType, Entity, MemberActionType, ProcessType, Severity, ViewStatus} from "enums/index";
import {submitForm} from "screens/utility";
import {BaseProps} from "screens/BaseProps";
import EmptySearchResults from "components/EmptySearchResults";
import {AccessType} from "types/Permission";
import {useNavigate} from "react-router-dom";
import BulkActions from "./BulkActions";
import {errorStatuses} from "constants/errorStatuses";

interface MembersListProps extends BaseProps {
  access: AccessType,
  screenName: string,
  members: Member[],
  onMemberCardActionClick: (member: Member | null, action: MemberActionType) => void;
  handleAlgoliaResultsCallback: (results: Member[] | null) => void,
  fromAlgolia: boolean
}

export default function MembersList(props: MembersListProps) {
  const {members, toastProps, uid, screenName, fromAlgolia, access, setSelectedOrgId = emptyFunction} = props;

  const {onMemberCardActionClick} = props;
  const navigate = useNavigate();

  const {setToastMessage, setIsToastOpen, setToastSeverity} = toastProps!
  const [selectedMembers, setSelectedMembers] = useState<Set<string>>(new Set<string>());

  //bulk related code
  const totalMembers = members.filter((member) => member.role !== AccessRole.Owner).length;
  const totalOwnersCount = members.length - totalMembers;
  const isAllSelected = selectedMembers.size === totalMembers && totalOwnersCount < totalMembers;
  const isIndeterminate = selectedMembers.size > 0 && !isAllSelected;

  //bulk change role drawer related code
  const [isBulkChangeRoleDrawerOpen, setIsBulkChangeRoleDrawerOpen] = useState(false);
  const [newRole, setNewRole] = useState<AccessRole>(AccessRole.User);
  const [isBulkChangeRoleDrawerLoading, setIsBulkChangeRoleDrawerLoading] = useState(false);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const ownerStartIndex = 0;

  async function handleBulkChangeRoleSubmit() {
    setIsBulkChangeRoleDrawerLoading(true);
    let references: ViewStatus[] = [];
    await Promise.all(
      Array.from(selectedMembers).map(async (memberId) => {
        const member = members.find((member) => member.uid === memberId);
        if (member) {
          const reference = doc(organizationMembersPath(member.orgId), memberId)
          await submitForm<Partial<Member>>(reference,
            ActionType.Update,
            (status) => {references.push(status)},
            {role: newRole}
          )
        }
      })
    );

    setIsBulkChangeRoleDrawerOpen(false)
    setIsBulkChangeRoleDrawerLoading(false);

    if (references.some((status) => errorStatuses.includes(status))) {
      setToastMessage(enCommonLabel.errorProcess(ProcessType.Update));
      setToastSeverity(Severity.Error);
      setIsToastOpen(true);
      return;
    }

    setToastMessage(enMemberLabel.bulkEditSuccess);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
  }

  async function handleBulkDeleteMembersSubmit() {
    let references: ViewStatus[] = [];
    await Promise.all(
      Array.from(selectedMembers).map(async (memberId) => {
        const member = members.find((member) => member["@id"]! === memberId);
        if (member) {
          const reference = doc(organizationMembersPath(member.orgId), memberId);
          await submitForm<Partial<Member>>(
            reference,
            ActionType.Delete,
            (status) => {
              references.push(status)
            },
          );
        }
      })
    );

    setIsDeleteDialogOpen(false);

    if (references.some((status) => errorStatuses.includes(status))) {
      setToastMessage(enCommonLabel.errorProcess(ProcessType.Delete));
      setToastSeverity(Severity.Error);
      setIsToastOpen(true);
      return;
    }

    setToastMessage(enMemberLabel.deleteSuccess);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);

    // if one of the selected members is the current user, navigate to home
    if (selectedMembers.has(uid)) {
      setSelectedOrgId("defaultOrg");
      navigate("/defaultOrg/explore-organizations");
    }
  }

  function handleSelectRole(roleSelected: AccessRole) {
    setNewRole(roleSelected);
  }

  function onCardSelect(id: string, selected: boolean) {
    if (selected) {
      setSelectedMembers(() => new Set(selectedMembers.add(id)));
      return;
    }
    setSelectedMembers((prev) => {
      const newSelectedMembers = new Set(prev);
      newSelectedMembers.delete(id);
      return newSelectedMembers;
    });
  }

  function handleSelectAllMembers(e: React.SyntheticEvent) {
    const element = e.target as HTMLInputElement;
    const checked = element.checked;
    if (checked && !isIndeterminate) {
      setSelectedMembers(() => new Set(members.filter(member => member.role !== AccessRole.Owner).map((member) => member.uid)));
    } else {
      setSelectedMembers(() => new Set());
    }
  }

  const checkIsSelected = useCallback((uid: string) => {
    return selectedMembers.has(uid);
  }, [selectedMembers.size])

  if (fromAlgolia && members.length === 0)
    return (
      <Stack flex={1}>
        <EmptySearchResults entity={Entity.Member}/>
      </Stack>
    );

  return <>
    <BulkChangeRoleDrawer
      isOpen={isBulkChangeRoleDrawerOpen}
      closeDrawer={() => setIsBulkChangeRoleDrawerOpen(false)}
      onSubmit={handleBulkChangeRoleSubmit}
      isLoading={isBulkChangeRoleDrawerLoading}
      handleSelectRole={handleSelectRole}
    />
    <ConfirmDialog
      isOpen={isDeleteDialogOpen}
      handleClose={() => setIsDeleteDialogOpen(false)}
      handleConfirm={handleBulkDeleteMembersSubmit}
      title={enMemberLabel.bulkDeleteConfirmationTitle(screenName)}
      text={enMemberLabel.bulkDeleteConfirmationText(screenName)}
    />
    <Stack gap={1}>
      <Stack gap={1}>
        <BulkActions
          isAllSelected={isAllSelected}
          isIndeterminate={isIndeterminate}
          handleSelectAllMembers={handleSelectAllMembers}
          setIsBulkChangeRoleDrawerOpen={setIsBulkChangeRoleDrawerOpen}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          selectedMembersSize={selectedMembers.size}
          totalOwnersCount={totalOwnersCount}
          screenName={screenName}
        />

        <Stack gap={1}>
          {members.map(member => {
            const isSelected = checkIsSelected(member["@id"]!);
            let ownerIndex = ownerStartIndex + (member.role === AccessRole.Owner ? 1 : 0);
            return (
              <MemberCard
                uid={uid}
                key={`memberCard_${member["@id"]}`}
                member={member}
                onMemberActionClick={onMemberCardActionClick}
                onSelect={onCardSelect}
                isSelected={isSelected}
                access={access}
                totalOwnersCount={totalOwnersCount}
                ownerIndex={ownerIndex}
              />
            )
          })}
        </Stack>
      </Stack>
    </Stack>
  </>
}
