import OrganizationsView from "../OrganizationsView";
import React, {useState} from "react";
import {Organization} from "types/Organization";
import {BaseProps} from "screens/BaseProps";
import {useComponentToggler} from "hooks/index";
import ManageOrganizationDrawer from "components/Drawers/ManageOrganizationDrawer";
import {enCommonLabel, enOrganizationLabel} from "constants/index";
import {ConfirmDialog} from "components/index";
import {doc} from "firebase/firestore";
import {organizationsPath, submitForm} from "../../utility";
import {ActionType, ProcessType, Severity, ViewStatus} from "enums/index";

interface OrganizationsListProps extends BaseProps {
  fromSAP: boolean;
}

function OrganizationsList(props: OrganizationsListProps) {
  const {toastProps} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  // edit org related states
  const [isDrawerOpen, {open: openDrawer, close: closeDrawer}] = useComponentToggler(false);
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);

  const [isDialogOpen, {open: openDialog, close: closeDialog}] = useComponentToggler(false);

  function updateSelectedOrg(org: Organization | null) {
    setSelectedOrg(org);
    openDrawer();
  }

  function closeEditDrawer() {
    setSelectedOrg(null);
    closeDrawer();
  }

  function deleteOrg(org: Organization) {
    setSelectedOrg(org);
    openDialog();
  }

  async function handleDeleteOrg() {
    const docReference = doc(organizationsPath(), selectedOrg!["@id"]);
    await submitForm(docReference, ActionType.Delete, statusHandler);
  }

  function statusHandler(status: ViewStatus, data: Organization, isLastUpdate: boolean) {
    if(!isLastUpdate) return;

    switch (status) {
      case ViewStatus.Finished:
        setToastSeverity(Severity.Success);
        setToastMessage(enOrganizationLabel.deleteSuccess);
        setIsToastOpen(true);
        setSelectedOrg(null);
        break;
      case ViewStatus.Error:
      case ViewStatus.ValidationError:
      case ViewStatus.SecurityError:
        setToastSeverity(Severity.Error);
        setToastMessage(enCommonLabel.errorProcess(ProcessType.Delete));
        setIsToastOpen(true);
        break;
    }

    closeDialog();
  }

  return (
    <>
      <ManageOrganizationDrawer
        organization={selectedOrg}
        isDrawerOpen={selectedOrg !== null && isDrawerOpen}
        closeDrawer={closeEditDrawer}
        toastProps={toastProps!}
        uid={props.uid}
      />
      <OrganizationsView
        updateSelected={(obj: Organization | null) => updateSelectedOrg(obj)}
        deleteOrg={deleteOrg}
        {...props}
      />
      {selectedOrg && (
        <ConfirmDialog
          id="delete-dialog"
          isOpen={isDialogOpen}
          handleClose={closeDialog}
          handleConfirm={handleDeleteOrg}
          title={enOrganizationLabel.deleteConfirmationTitle(selectedOrg!.name)}
          text={enOrganizationLabel.deleteConfirmationText}
        />
      )}
    </>
  );
}

export default OrganizationsList;