import {BaseProps} from "../BaseProps";
import {useAccess, useDocument} from "hooks/index";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {Project} from "types/index";
import {
  projectLevelTasksAccessListPath,
  projectMilestonesPath,
  projectsPath
} from "screens/utility";
import {doc} from "firebase/firestore";
import {Content, InProgress} from "components/index";
import Header from "./Header";
import {Stack} from "@mui/material";
import React, {useEffect} from "react";
import {emptyFunction} from "constants/index";
import {Entity} from "enums/index";
import {PermissionEntity} from "types/Permission";
import MilestonesView from "components/MilestoneView/MilestonesView";
import {algoliaAssetPath} from "../utility/algoliaColPath";
import {OutletContextType} from "components/RestrictedPage";

function ProjectLevelTasks(props: BaseProps) {
  const {toastProps, selectedOrg, uid, setSelectedOrgId = emptyFunction} = props;
  const {isLoading} = useOutletContext<OutletContextType>();

  const {orgId, projId} = useParams();
  const algoliaPath = algoliaAssetPath(orgId!, projId!);

  const milestonesPath = projectMilestonesPath(orgId!, projId!);

  const navigate = useNavigate();

  const projectRef = doc(projectsPath(orgId!), projId!)
  const [project] = useDocument<Project>(projectRef);

  const [taskAccess] = useAccess({
    uid: uid!,
    entity: Entity.Project,
    documentDocId: PermissionEntity.PLTask,
    accessListRef: projectLevelTasksAccessListPath(orgId!, projId!)
  });

  const [downloadAccess] = useAccess({
    uid: uid!,
    entity: Entity.Project,
    documentDocId: PermissionEntity.PLDownload,
    accessListRef: projectLevelTasksAccessListPath(orgId!, projId!)
  });

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  if (project === null || isLoading) {
    return (
      <Content>
        <InProgress/>
      </Content>
    );
  }

  if (project === undefined) return null;

  return (
    <>
      <Header
        uid={uid!}
        downloadAccess={downloadAccess}
        project={project}
        toastProps={toastProps}
      />
      <Content>
        <Stack sx={{maxWidth: "90vw"}} height="100%">
          <Stack direction="column" spacing={3} gap={2}>
            <MilestonesView
              milestonesCollectionRef={milestonesPath.path}
              parentDocPath={projectRef.path}
              algoliaPath={algoliaPath}
              parent={project}
              uid={uid!}
              parentEntity={Entity.Project}
              parentAccess={taskAccess}
              downloadAccess={downloadAccess}
              toastProps={toastProps!}
            />
          </Stack>
        </Stack>
      </Content>
    </>
  )
}

export default ProjectLevelTasks;
