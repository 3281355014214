import {milestoneTaskandAssetStatus} from "enums/milestoneTaskandAssetStatus";
import {Timestamp} from "firebase/firestore";
import theme from "theme/theme";
import {getHourDifference} from "components/MilestoneView/utils/getHourDifference";
import {appendTimeToDate} from "components/MilestoneView/utils/appendTimeToDate";
import {CalendarIcons} from "assets/icons/system/system.index";
import React from "react";

/**
 * Returns the color of the border of the task and calendar icon
 */
export function getTaskBorderColor(
  taskStatus: milestoneTaskandAssetStatus,
  taskDueDate: Timestamp | null, dueTime: string | undefined,
  size: Record<string, number> = {width: 32, height: 32}) {

  // If task has rejection/rejected regardless of due date value, use border color red
  if ([milestoneTaskandAssetStatus.HasRejection, milestoneTaskandAssetStatus.Rejected].includes(taskStatus))
    return {
      borderColor: theme.palette.error.main,
      calendarIcon: <CalendarIcons.CalendarExclamation width={size.width} height={size.height}/>
    };

  // If task is cancelled regardless of due date value, use black (x) calendar icon
  if (taskStatus === milestoneTaskandAssetStatus.Cancelled)
    return {
      calendarIcon: <CalendarIcons.CalendarCancelled width={size.width} height={size.height}/>
    };

  // If task is submitted regardless of due date value, use border color blue
  if (taskStatus === milestoneTaskandAssetStatus.Submitted)
    return {
      borderColor: theme.palette.primary.main,
      calendarIcon: <CalendarIcons.CalendarBlue width={size.width} height={size.height}/>
    };

  // If task is approved regardless of due date value, use border color green
  if (taskStatus === milestoneTaskandAssetStatus.Approved)
    return {
      borderColor: theme.palette.success.main,
      calendarIcon: <CalendarIcons.CalendarGreen width={size.width} height={size.height}/>
    };

  // If task has no due date, use border color black
  if (!taskDueDate)
    return {
      borderColor: theme.palette.secondary.main,
      calendarIcon: <CalendarIcons.CalendarQuestion width={size.width} height={size.height}/>
    };

  // Manually parse date to Timestamp, since algolia will return _seconds and _nanoseconds
  // @ts-ignore
  const {seconds, _seconds, nanoseconds, _nanoseconds} = taskDueDate;
  const parsedSeconds = seconds ?? _seconds ?? 0;
  const parsedNanoSeconds = nanoseconds ?? _nanoseconds ?? 0;
  const jsDate = new Date(parsedSeconds * 1000 + parsedNanoSeconds / 1000000);

  // Append received time manually to due date
  const dueDate = !!dueTime ? appendTimeToDate(jsDate, dueTime) : jsDate;

  const timeToday = new Date().getTime();
  const timeDueDate = dueDate.getTime();
  const dateDiff = getHourDifference(timeToday, timeDueDate);

  // If task due date is today and has no due time, use border color orange
  const today = new Date();
  const isDueDateToday = `${dueDate.getDate()}-${dueDate.getMonth()}-${dueDate.getFullYear()}` === `${today.getDate()}-${today.getMonth()}-${today.getFullYear()}`;
  if (!dueTime&& isDueDateToday)
    return {
      borderColor: theme.palette.task.main,
      calendarIcon: <CalendarIcons.CalendarBell width={size.width} height={size.height}/>
    };

  // If task due date is today or overdue, use border color red
  if (dateDiff <= 0)
    return {
      borderColor: theme.palette.error.main,
      calendarIcon: <CalendarIcons.CalendarExclamation width={size.width} height={size.height}/>
    };

  // If task due date is within 24 hours, use border color yellow
  if (dateDiff <= 24)
    return {
      borderColor: theme.palette.task.main,
      calendarIcon: <CalendarIcons.CalendarBell width={size.width} height={size.height}/>
    };

  // If task due date is more than 24 hours away, use border color blue
  if (dateDiff > 24)
    return {
      borderColor: theme.palette.primary.main,
      calendarIcon: <CalendarIcons.CalendarBlue width={size.width} height={size.height}/>
    };
  return {
    calendarIcon: <CalendarIcons.CalendarGray width={size.width} height={size.height}/>
  };
}