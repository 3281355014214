import {Member} from "types/index";
import {EmptyList, InProgress, SortMenu} from "components/index";
import {Entity, MemberActionType, DirectionalOrder} from "enums/index";
import theme from "theme/theme";
import MembersList from "./MembersList";
import {BaseProps} from "screens/BaseProps";
import {orderBy, where} from "firebase/firestore";
import React, {useEffect, useState} from "react";
import useCollection from "hooks/useCollection";
import {organizationMembersPath} from "screens/utility/FirebasePath";
import {sortObjectsBy} from "screens/utility";
import SmartSearchInput from "components/inputs/SmartSearchInput";
import {algoliaOrganizationMemberColPath} from "screens/utility/algoliaColPath";
import {emptyFunction, enMemberLabel} from "constants/index";
import {Box, Stack} from "@mui/material";
import {AccessType, PermissionOperationKey} from "types/Permission";
import NoPermission from "screens/NoPermission";

interface DynamicViewProps extends BaseProps {
  access: AccessType;
  screenName: string,
  membersCount: number,
  orgId: string;
  onMemberCardActionClick: (member: Member | null, action: MemberActionType) => void;
}

const activeConstraint = where("active", "==", true);

function DynamicView(props: DynamicViewProps) {
  const {
    screenName,
    onMemberCardActionClick,
    toastProps,
    membersCount,
    access,
    uid,
    orgId,
    setSelectedOrgId
  } = props;

  const [membersCollection, setMembersCollectionConstraints, setMembersCollectionRef] = useCollection<Member>(
    null, organizationMembersPath(orgId!), [activeConstraint, orderBy("name")])
  const [algoliaMembersCollection, setAlgoliaMembersCollection] = useState<Member[] | null>(null)
  const [isSearchAndFilterShown, setIsSearchAndFilterShown] = useState<boolean>(false);

  const [sortOrder, setSortOrder] = useState<DirectionalOrder>(DirectionalOrder.asc);

  const memberProperties = {
    access,
    screenName,
    onMemberCardActionClick: onMemberCardActionClick,
    members: algoliaMembersCollection ?? [],
    toastProps: toastProps,
    setSelectedOrgId,
    handleAlgoliaResultsCallback,
  }

  //listen to correct collection reference when organization loads
  useEffect(() => {
    setMembersCollectionRef(organizationMembersPath(orgId!))
  }, [orgId]);

  useEffect(() => {
    if (isSearchAndFilterShown || !membersCollection) return;
    setIsSearchAndFilterShown(true);
  }, [membersCollection]);

  useEffect(() => {
    setMembersCollectionConstraints([activeConstraint, orderBy("name", sortOrder)])

    if (algoliaMembersCollection) {
      const collection = algoliaMembersCollection.filter(col => Boolean(col.active));
      setAlgoliaMembersCollection(sortObjectsBy(collection, "name", sortOrder));
    }
  }, [sortOrder])

  function handleAlgoliaResultsCallback(results: Member[] | null) {
    setAlgoliaMembersCollection(results);
  }

  function getMembersToRender() {
    if (algoliaMembersCollection)
      return algoliaMembersCollection

    return membersCollection || []
  }

  if (!isSearchAndFilterShown || !access)
    return <InProgress/>;

  if (membersCount < 1)
    return <EmptyList
      entity={Entity.Member}
      logoProperties={{
        fill: theme.palette.neutral.main
      }}
    />

  if (Boolean(!access[PermissionOperationKey.View]))
    return <NoPermission fullHeight={false}/>

  return (
    <Stack gap={1} flex={1}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <SmartSearchInput<Member>
          resultCallback={handleAlgoliaResultsCallback}
          colPath={algoliaOrganizationMemberColPath(orgId!)}
          placeholder={enMemberLabel.searchMember}
        />
        <SortMenu
          changeOrder={(newOrder: DirectionalOrder) =>
            setMembersCollectionConstraints([activeConstraint, orderBy("userData.name", newOrder)])
          }
          setter={setSortOrder || emptyFunction}
          id="sort-org-field"
        />
      </Box>
      <MembersList
        fromAlgolia={algoliaMembersCollection !== null}
        {...memberProperties}
        uid={uid}
        members={getMembersToRender()}
      />
    </Stack>
  )
}

export default DynamicView;
