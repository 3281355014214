import {SwiftFile} from "types/index";
import React, {MutableRefObject} from "react";
import {InProgress, PreviewItem} from "components/index";
import {FileStatus} from "enums/index";
import {Box, Hidden, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {getFileType} from "../../utility";
import {Previews as FilePreviews} from "enums/supportedPreviews";

interface FileUploadWithDownloadUrl extends SwiftFile {
  downloadUrl?: string;
  unrenderableItemsCount?: number;
}

interface PreviewsProps {
  isLoadingPreviews: boolean;
  previews: FileUploadWithDownloadUrl[];
  onFilePreviewClick: (index: number) => void;
  parentRef: MutableRefObject<HTMLElement | null>;
  extraItemsCount: number;
  displayableCount: number;
  isDownloading: Record<number, boolean> | null;
}

export default function Previews(props: PreviewsProps) {
  const {
    isLoadingPreviews,
    previews,
    onFilePreviewClick,
    parentRef,
    extraItemsCount,
    displayableCount,
    isDownloading
  } = props;

  if (isLoadingPreviews && previews.length === 0)
    return <Stack ml={2} height={64}><InProgress/></Stack>;

  const parentWidth = parentRef?.current?.offsetWidth || 0;

  const lastItemIndex = previews.length - 1
  const lastItem = previews[lastItemIndex];

  return (
    <Hidden smDown>
      <Stack
        direction="row"
        gap={0.5}
        width={isLoadingPreviews || previews.length > 0 ? parentWidth : 0}
        height={isLoadingPreviews || previews.length > 0 ? 64 : 0}
      >
        {previews.slice(0, displayableCount).map((filePreview, index) => {
          const {
            downloadUrl,
            contentType,
            fileStatus = FileStatus.Uploaded,
            "@id": id = "fillerId",
            name
          } = filePreview;

          const fileType = getFileType(contentType);

          if (index === displayableCount - 1 && extraItemsCount > 0) {
            return <Box
              key={filePreview["@id"]}
              position="relative"
              onClick={() => onFilePreviewClick(lastItemIndex)}
              sx={{
                width: 64,
                height: 64,
                overflow: "hidden",
                borderRadius: 1,
                border: `1px solid ${theme.palette.neutral.light}`,
              }}
            >
              <PreviewItem
                {...(fileType === FilePreviews.Zip ? {iconSize: 56} : {})}
                filePreview={{
                  contentType: lastItem?.contentType,
                  fileStatus: lastItem?.fileStatus ?? FileStatus.Uploaded,
                  downloadUrl: lastItem?.downloadUrl,
                  id: lastItem!["@id"]!,
                  name: lastItem?.name,
                }}
                onClick={() => onFilePreviewClick(lastItemIndex)}
                withBorder
              />
              <Box
                position="absolute"
                sx={{
                  backgroundColor: "rgba(0,0,0,0.5)",
                  top: 0,
                  left: 0,
                  width: 64,
                  height: 64
                }}
              >
              </Box>
              <Stack position="absolute" sx={{
                top: 0,
                right: 2,
                height: 64,
                width: 64,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row"
              }}>
                <SystemIcons.Plus strokeWidth={3} width={12} height={12} stroke="white"/>
                <Typography variant="h4" color="common.white">{extraItemsCount + 1}</Typography>
              </Stack>
            </Box>
          }

          return (
            <PreviewItem
              {...(fileType === FilePreviews.Zip || fileType === FilePreviews.Document ? {iconSize: 56} : {})}
              key={filePreview["@id"]}
              filePreview={{downloadUrl: downloadUrl, contentType: contentType, fileStatus: fileStatus, id, name}}
              differentRejectedPreview={true}
              onClick={() => onFilePreviewClick(index)}
              withBorder
              isLoading={isDownloading?.[index] ?? false}
            />
          )
        })}
        {isLoadingPreviews && <Stack ml={2} height={64}><InProgress/></Stack>}
      </Stack>
    </Hidden>
  )
}
