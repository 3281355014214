import {Box, Grid, Stack} from "@mui/material";
import {SwiftFile} from "types/SwiftFile";
import {TempFile} from "components/ChatDrawer/Types/TempFile";
import ChatAttachmentItem from "components/ChatDrawer/ChatAttachments/ChatAttachmentItem";
import {collection, CollectionReference, DocumentReference} from "firebase/firestore";
import {CarouselItem} from "components/ChatDrawer/ChatItemsView/ChatAttachmentCarousel";
import React, {useEffect, useMemo, useState} from "react";
import LazyCarousel from "components/LazyCarousel";
import getFileDownloadPath from "screens/utility/getFileDownloadPath";
import downloadFile from "screens/utility/downloadFile";
import {SkeletonItem} from "components/index";
import isNotPlayableOrNoPreview from "screens/utility/isNotPlayableOrNoPreview";
import theme from "theme/theme";
import SystemIndex from "assets/icons/system/system.index";

interface ChatAttachmentWrapperProps {
  documentRef?: DocumentReference;
  attachments: SwiftFile [] | TempFile [] | null;
  sx?: any;
  isFromUnsent?: boolean;
  onClick: (attachments: CarouselItem[], selectedIndex: number) => void;
  id?: string | undefined;
  colRef?: CollectionReference;
  attachmentsCount?: number;
}

const MAX_ATTACHMENT_DISPLAY = 9;
const FILE_HEIGHT = 72;

export default function ChatAttachmentWrapper(props: ChatAttachmentWrapperProps) {
  const {attachments = [], onClick, isFromUnsent = false, id, colRef, sx = {}, attachmentsCount = 0} = props;

  const [carouselIndex, setCarouselIndex] = useState<number | null>(null);
  const [displayableAttachments, setDisplayableAttachments] = useState<SwiftFile[] | TempFile[]>([]);
  const [extraItemsLength, setExtraItemsLength] = useState<number>(0);
  const [isDownloading, setIsDownloading] = useState<Record<number, boolean> | null>(null);
  const [height, setHeight] = useState<number>(FILE_HEIGHT);

  useEffect(() => {
    if (attachmentsCount <= 3) return setHeight(FILE_HEIGHT);
    if (attachmentsCount <= 6) return setHeight(FILE_HEIGHT * 2);

    setHeight(FILE_HEIGHT * 3);
  }, [attachmentsCount]);

  // if there are changes in the received attachements, update the displayable attachments
  useMemo(() => {
    if (attachments === null) {
      setDisplayableAttachments([]);
      setExtraItemsLength(0);
      return;
    }

    setDisplayableAttachments(attachments.slice(0,9));
    setExtraItemsLength(attachments.length - 9);
  }, [attachments, isFromUnsent]);

  async function handleCarouselClick(index: number) {
    const currentFile = displayableAttachments[index] as SwiftFile;
    if (!currentFile) {
      setCarouselIndex(index);
      return;
    }

    const notSupported = isNotPlayableOrNoPreview(currentFile.name);
    if (!notSupported) {
      setCarouselIndex(index);
      return;
    }

    setIsDownloading(previousState => {
      if (previousState === null) return {[index]: true};
      return {...previousState, [index]: true};
    });
    const downloadPath = await getFileDownloadPath(currentFile.destPath);
    await downloadFile(downloadPath, currentFile.name);
    setIsDownloading(previousState => {
      // If the state is currently null, or the key does not exist, just return the state as is
      if (previousState === null || !(index in previousState)) return previousState;
      // Create a new object without the key to remove
      const {[index]: _, ...newState} = previousState;
      return newState;
    });
  }

  if (attachments === null && !isFromUnsent) {
    const numberArray = [...Array(attachmentsCount > MAX_ATTACHMENT_DISPLAY ? MAX_ATTACHMENT_DISPLAY: attachmentsCount).keys()];
    return (
      <Stack direction="row" gap={0.5} flexWrap="wrap" maxWidth={"200px"} justifyContent="flex-end">
        {numberArray.map((number) => (
          <SkeletonItem key={`skeleton-${id}-${number}`} height={"64px"} sx={{width: "64px"}} />
        ))}
      </Stack>
    );
  }

  if (attachments?.length === 0 && !isFromUnsent && attachmentsCount > 0) {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.neutral.light,
          width: 64,
          height: 64,
          display: "grid",
          placeItems: "center",
          borderRadius: 1,
          marginX: 0.5,
        }}
        title="Attachment is broken"
      >
        <SystemIndex.SystemIcons.CorruptedFileOutline/>
      </Box>
    )
  }

  return (
    <>
      {
        carouselIndex !== null && colRef && id &&
        <LazyCarousel
          filesColRef={collection(colRef, id, "files")}
          clickedIndex={carouselIndex}
          toggleAttachmentCarousel={() => setCarouselIndex(null)}
        />
      }
      <Grid container rowSpacing={1} width={200} sx={[sx, {height: `${height}px`}]}>
        {displayableAttachments.map((attachment, index) => {
          return (
            <Grid
              key={`chat-attachment-${id}-${index}`}
              item
              xs={4}
            >
              <ChatAttachmentItem
                colRef={colRef && id ? collection(colRef, id, "files") : undefined}
                extraItems={extraItemsLength}
                index={index}
                attachment={attachment as SwiftFile}
                attachments={attachments as SwiftFile[]}
                onClick={onClick}
                fromUnsent={isFromUnsent}
                handleCarouselClick={() => handleCarouselClick(index)}
                isDownloading={isDownloading?.[index] ?? false}
              />
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}
