import {useDebounce} from "hooks/index";
import {CollectionReference, getDocs} from "firebase/firestore";
import {useEffect, useState} from "react";

//provide id when you want to check for uniqueness other than the current document
//for editing in forms
function useCheckUniqueness(collectionRef: CollectionReference, field: string, value: string = "", id?: string | undefined, isSensitive = false) {
  const [isUnique, setIsUnique] = useState(true)

  //immediately reset state when value is changed
  useEffect(() => {
    setIsUnique(true)
  }, [value])

  useDebounce(async () => {
    // do not evaluate if value is empty
    if (value === "" && isUnique) return;

    const docs = await getDocs(collectionRef);
    let foundDocs = await Promise.all(docs.docs.map(async (doc) => await doc.data()))

    if (isSensitive) {
      foundDocs = foundDocs.filter(doc => doc[field] === value);
    } else {
      foundDocs = foundDocs.filter(doc => (doc[field] ?? "").toLowerCase() === value.toLowerCase());
    }

    if (foundDocs.length > 0) {
      if (id && id !== foundDocs[0].id) {
        setIsUnique(false);
        return;
      } else if (!id) {
        setIsUnique(false)
        return;
      }
    } else {
      setIsUnique(true)
      return;
    }
  }, 200, [value])

  return isUnique
}

export default useCheckUniqueness
