import {Box, IconButton, Stack, styled, Typography} from "@mui/material";
import theme from "theme/theme";
import {enCommonButton, enCommonLabel} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import React from "react";
import {UploadActionType} from "hooks/useFileUpload/reducer/UploadActionType";
import {submitForm, userPath} from "../utility";
import {ActionType} from "enums/actionType";
import {UploadAction} from "hooks/useFileUpload/reducer/UploadAction";
import {LoadingButton} from "@mui/lab";

interface PanelTitleProps {
  uid: string;
  isMinimized: boolean;
  setIsMinimized: React.Dispatch<boolean>;
  isCollapsed: boolean;
  isClearVisible: boolean;
  isClearLoading: boolean;
  setIsCollapsed: React.Dispatch<boolean>;
  fileUploadDispatch: React.Dispatch<UploadAction>;
  clearAllExports: () => void;
}

function PanelTitle(props: PanelTitleProps) {
  const {isCollapsed, isMinimized, isClearVisible, isClearLoading} = props;
  const {setIsCollapsed, setIsMinimized, fileUploadDispatch, clearAllExports} = props;

  async function closePanelClick() {
    fileUploadDispatch({
      type: UploadActionType.closePanel
    });

    // send request to BE to clean-up the fileUploads collection
    await submitForm(
      userPath(props.uid),
      ActionType.Update,
      () => {
      },
      {showUploadFileComponent: false}
    );
  }

  return (
    <BoxTitle>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{height: "100%"}}
        alignItems="center"
        padding={1}
      >
        <Typography
          sx={{color: theme.palette.background.swiftDefault}}
          id="file-requirement-upload-panel-progress-label"
          variant={"h5"}
        >
          {enCommonLabel.processPanel}
        </Typography>
        <Stack direction="row" alignItems="center">
          {isClearVisible && (
            <LoadingButton
              disabled={isClearLoading}
              loading={isClearLoading}
              onClick={clearAllExports}
            >
              <Typography
                sx={{color: theme.palette.primary.main}}
              >
                {enCommonButton.clearAll}
              </Typography>
            </LoadingButton>
          )}

          {!isCollapsed && (
            <IconButton
              id="file-requirement-upload-panel-minimize-button"
              title={"Minimize"}
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              <SystemIcons.Minus stroke={theme.palette.background.swiftDefault}/>
            </IconButton>
          )}
          <IconButton
            id="file-requirement-upload-panel-collapse-button"
            title={isMinimized ? "Maximize" : "Minimize"}
            onClick={() => {
              setIsCollapsed(false);
              setIsMinimized(!isMinimized)
            }}
          >
            {isMinimized ? <SystemIcons.Expand stroke={theme.palette.background.swiftDefault}/>
              : <SystemIcons.Collapse stroke={theme.palette.background.swiftDefault}/>}
          </IconButton>
          <IconButton
            id="file-requirement-upload-panel-close-button"
            title="Close"
            onClick={closePanelClick}
          >
            <SystemIcons.Close stroke={theme.palette.background.swiftDefault}/>
          </IconButton>
        </Stack>
      </Stack>
    </BoxTitle>
  )
}

const BoxTitle = styled(Box)({
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  height: "56px",
  backgroundColor: theme.palette.secondary.main,
});

export default PanelTitle;