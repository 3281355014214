import Header from "./Header";
import {BaseProps} from "screens/BaseProps";
import {organizationPath} from "screens/utility/FirebasePath";
import {useDocument, useCollection, useAccess} from "hooks/index";
import {Organization, SelectedOrg} from "types/index";
import {teamCategoriesPath} from "screens/utility/FirebasePath";
import ManageTeamFormDrawer from "components/Drawers/ManageTeamFormDrawer/ManageTeamFormDrawer";
import {useState} from "react";
import {TeamCategory} from "types/TeamCategory";
import {Stack} from "@mui/material";
import DynamicView from "./DynamicView";
import {Variant} from "@mui/material/styles/createTypography";
import {useParams} from "react-router-dom";
import {Entity} from "enums/entity";
import {PermissionEntity} from "types/Permission";

interface TeamsViewProps extends BaseProps {
  selectedOrg: SelectedOrg;
  titleVariant?: Variant;
}

function TeamsView(props: TeamsViewProps) {
  const {selectedOrg, toastProps, titleVariant="h2", uid} = props;
  const {orgId} = useParams();

  const orgRef = organizationPath(orgId!);
  const [orgDoc] = useDocument<Organization>(orgRef);
  const [isAddTeamDrawerOpen, setIsAddTeamDrawerOpen] = useState<boolean>(false);
  const [teamCategories] = useCollection<TeamCategory>(null, teamCategoriesPath(orgId!));

  const [access] = useAccess({
    uid: uid!,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.Team,
  });

  const [categoryAccess] = useAccess({
    uid: uid!,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.TeamCategory,
  });

  function onAddTeamDrawerClose() {
    setIsAddTeamDrawerOpen(false);
  }

  function onAddTeamDrawerOpen() {
    setIsAddTeamDrawerOpen(true);
  }

  if (orgDoc === undefined) return null;

  return (
    <Stack gap={1} flex={1}>
      {!!orgDoc && (
        <ManageTeamFormDrawer
          uid={uid}
          org={orgDoc}
          isOpen={isAddTeamDrawerOpen}
          onClose={onAddTeamDrawerClose}
          toastProps={toastProps}
          team={null}
        />
      )}
      <Header
        categoryAccess={categoryAccess}
        access={access}
        selectedOrgId={selectedOrg.id!}
        onDrawerOpen={onAddTeamDrawerOpen}
        titleVariant={titleVariant}
      />
      <DynamicView
        access={access}
        toastProps={toastProps}
        orgDoc={orgDoc}
        uid={uid}
        teamCategories={teamCategories}
      />
    </Stack>
  )
}

export default TeamsView;