import {Checkbox, Divider, Paper, Stack} from "@mui/material";
import UniqueAssetIcon from "assets/icons/UniqueAssetIcon";
import {LongText, SkeletonItem} from "components/index";
import {enAssetLabel, enTemplateLabel} from "constants/index";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import {organizationAssetPath} from "../utility";
import {SwiftIconButton} from "components/Button";
import {SystemIcons} from "assets/icons/system/system.index";
import React, {useRef} from "react";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {Asset} from "types/Asset";
import {useDocument} from "hooks/index";
import {toastProps} from "../BaseProps";

interface AssetItemProps {
  uid: string;
  assetId: string;
  isChecked: boolean;
  isCheckboxShown: boolean;
  toastProps: toastProps;
  canEdit: boolean;
  onAssetCheck: (id: string, checked: boolean) => void;
  onEditAssetClick: (asset: Asset) => void;
  onDeleteAssetClick: (assetId: string) => void;
  onMenuOpen: (e: Element | undefined, asset: Asset, anchorPosition?: { left: number, top: number }) => void;
}

function AssetItem(props: AssetItemProps) {
  const {uid, assetId, isChecked, onAssetCheck, toastProps, onMenuOpen} = props;

  const {orgId} = useParams();
  const navigate = useNavigate();
  const assetRef = useRef<HTMLDivElement | null>(null);
  const [asset] = useDocument<Asset>(organizationAssetPath(orgId!, assetId));

  function onCardClick() {
    navigate(`/${orgId}/assets/${assetId}`);
  }

  function onEditClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (!asset) return;

    e.preventDefault();
    e.stopPropagation();
    props.onEditAssetClick(asset!);
  }

  function onDeleteClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
    props.onDeleteAssetClick(assetId);
  }

  if (asset === null) {
    return <SkeletonItem height={"92px"} sx={{marginTop: "5px", width:"100%"}}/>;
  }

  if (asset === undefined) return null;

  return (
    <Paper
      sx={{
        position: "relative",
        boxShadow: "none",
        width: "100%",
        transition: "all 0.3s ease-in-out",
      }}
      onClick={onCardClick}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onMenuOpen(assetRef.current!, asset, {left: e.clientX, top: e.clientY})
      }}
    >
      <Stack
        key={`asset_${assetId}`}
        padding={0}
        className="asset-row-item"
        sx={(theme) => ({
          border: `1px solid ${isChecked ? theme.palette.primary.main : theme.palette.divider}`,
          borderRadius: 1,
          cursor: "pointer",
          ":hover": {
            border: `1px solid ${theme.palette.primary.main}`,
          },
        })}
        flex={1}
        ref={assetRef}
      >
        <Stack direction="row" flex={1} justifyContent="space-between">
          <Stack
            direction="row"
            width="90%"
            gap={1}
            alignItems="center"
            marginLeft={props.isCheckboxShown ? 0 : 2}
          >
            {props.isCheckboxShown && (
              <>
                <Checkbox
                  onClick={(e) => e.stopPropagation()}
                  checked={isChecked}
                  onChange={(e, checked) => onAssetCheck(assetId!, checked)}
                  id={`assetCheckbox_${assetId}`}
                  sx={{ml: 1}}
                />
                <Divider orientation="vertical" flexItem/>
              </>
            )}
            <Stack direction="row" justifyContent="center" py={1} alignItems="center">
              <UniqueAssetIcon color={asset.avatarColor}/>
              <Stack direction="column" gap={0} alignItems="flex-start" flex={1} ml={1}>
                <LongText variant="h4" maxWidth="100%" overflowWrap="anywhere">{asset.name}</LongText>
                <LongText color="text.secondary" variant="bodySmall" maxWidth="100%" mb={1} overflowWrap="anywhere">
                  {!!asset.idCode ? `ID: ${asset.idCode}` : enAssetLabel.noIdCode}
                </LongText>
                <DataTagsDisplay
                  dataTagsIds={asset.dataTagsIds || []}
                  toastProps={toastProps!}
                  listSize="medium"
                  uid={uid!}
                  canEditDocumentRef={props.canEdit}
                  documentRef={organizationAssetPath(orgId!, assetId!)}
                />
              </Stack>
            </Stack>
          </Stack>
          <Divider orientation="vertical" flexItem/>
          <Stack direction="row" alignItems="center" justifyContent="center" py={1} mx={1}>
            <SwiftIconButton
              onClickFcn={onEditClick}
              disabled={false}
              disabledHoverLabel={enTemplateLabel.cannotEdit}
              startIcon={SystemIcons.Edit}
            />
            <SwiftIconButton
              onClickFcn={onDeleteClick}
              disabled={false}
              disabledHoverLabel={enTemplateLabel.cannotDelete}
              startIcon={SystemIcons.DeleteOutlined}
            />
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  )
}

export default AssetItem;