export enum UploadActionType {
  addToProcessingFileList = "addToProcessingFileList",
  addToErrorFileList = "addToErrorFileList",
  closePanel = "closePanel",
  addToProcessingCount = "addToProcessingCount",
  addToCompletedCount = "addToCompletedCount",
  addToBatch = "addToBatch",
  removeBatch = "removeBatch",
  addToProcessingBatchId = "addToProcessingBatchId",
  addToRemovedFiles = "addToRemovedFiles",
  addToDeletedIds = "addToDeletedIds",
  openPanel = "openPanel"
}