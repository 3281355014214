import {EmptyList, InProgress} from "components/index";
import {ActionType, Entity, ProcessType, Severity, ViewStatus} from "enums/index";
import TeamCategoryList from "./TeamCategoryList";
import {BaseProps} from "screens/BaseProps";
import React, {useState} from "react";
import {Organization, Team, TeamCategory} from "types/index";
import EmptySearchResults from "components/EmptySearchResults";
import SmartSearchInput from "components/inputs/SmartSearchInput";
import {algoliaTeamsPath} from "screens/utility/algoliaColPath";
import {doc, DocumentReference} from "firebase/firestore";
import {submitForm, teamsPath} from "screens/utility";
import DeleteDialog from "components/Dialogs/DeleteDialog";
import {useComponentToggler} from "hooks/index";
import {enCommonLabel, enTeamsAndPeopleLabel} from "constants/index";
import {AccessType, PermissionOperationKey} from "types/Permission";
import NoPermission from "../../../NoPermission";
import {errorStatuses} from "constants/errorStatuses";

interface DynamicViewProps extends BaseProps {
  access: AccessType | null;
  orgDoc: Organization | null;
  teamCategories: TeamCategory[] | null;
}

function DynamicView(props: DynamicViewProps) {
  const {access, orgDoc, teamCategories, uid, toastProps} = props;

  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  const [algoliaTeams, setAlgoliaTeams] = useState<Team[] | null>(null);
  const [selectedTeamsId, setSelectedTeamsId] = useState<Set<string>>(new Set());
  const [selectedTeamCategoriesId, setSelectedTeamCategoriesId] = useState<Set<string>>(new Set());
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);

  const [isDialogOpen, {open: openDialog, close: closeDialog}] = useComponentToggler(false);
  const [deleteRef, setDeleteRef] = useState<DocumentReference[]>([]);

  function handleAlgoliaResultsCallback(teams: Team[] | null) {
    setAlgoliaTeams(teams)
  }

  function getTeamCategories() {
    if (algoliaTeams) {
      const algoliaTeamCategories = algoliaTeams.map((team) => team.teamCategory!)
      const existingIds: string[] = [];

      const filteredCategories: TeamCategory[] = [];

      algoliaTeamCategories.forEach((teamCategory) => {
        const catId = teamCategory["@id"];

        if (existingIds.indexOf(catId) === -1) {
          filteredCategories.push(teamCategory as TeamCategory)
          existingIds.push(catId)
        }

      })

      return filteredCategories
    }

    if (!teamCategories) return []

    return teamCategories;
  }

  function deleteTeams() {
    const refs = Array.from(selectedTeamsId).map(selectedTeamId => doc(teamsPath(orgDoc!["@id"]!), selectedTeamId))
    setDeleteRef(refs);
    openDialog();
  }

  async function deleteAllSelectedTeams() {
    if (deleteRef.length === 0) return;

    let viewStatuses: ViewStatus[] = [];
    deleteRef.forEach(ref => submitForm(ref, ActionType.Delete, (status) => viewStatuses.push(status)));

    setDeleteRef([]);
    if (viewStatuses.some(status => errorStatuses.includes(status))) {
      setToastMessage(enCommonLabel.errorProcess(ProcessType.Delete));
      setToastSeverity(Severity.Error);
      setIsToastOpen(true);
      closeDialog();
      return;
    }

    setToastSeverity(Severity.Success);
    setToastMessage(enTeamsAndPeopleLabel.deleteSuccess);
    setIsToastOpen(true);
    setSelectedTeamsId(new Set());
    setSelectedTeamCategoriesId(new Set());
    closeDialog();
  }

  function handleCheckTeam(teamId: string, teams: Team[], teamCategoryId: string) {
    setSelectedTeamsId(prev => {
      const newSet = new Set(prev);
      newSet.has(teamId) ? newSet.delete(teamId) : newSet.add(teamId);
      return newSet;
    });

    setSelectedTeamCategoriesId(prev =>
      teams.every(team => selectedTeamsId.has(team["@id"]!))
        ? new Set([...prev, teamCategoryId])
        : new Set([...prev].filter(id => id !== teamCategoryId))
    );
  }

  function handleCheckTeamCategory(checked: boolean, teams: Team[], teamCategoryId: string) {
    const action = checked ? "add" : "delete";

    setSelectedTeamsId(prev => {
      teams.forEach(team => prev[action](team["@id"]!));
      return new Set(prev);
    });

    setSelectedTeamCategoriesId(prev => {
      const newSet = new Set(prev);
      newSet[action](teamCategoryId);
      return newSet;
    });
  }

  if (orgDoc === null || teamCategories === null || access === null)
    return <InProgress/>

  if (!access?.[PermissionOperationKey.View])
    return <NoPermission fullHeight={false}/>

  if (!teamCategories.length)
    return <EmptyList
      entity={Entity.Teams}
      logoProperties={{width: 64, height: 64}}
    />

  return (
    <>
      <DeleteDialog
        isOpen={isDialogOpen}
        title={enCommonLabel.deleteMultipleEntities("teams")}
        text={enCommonLabel.deleteBulkConfirmationText}
        handleClose={closeDialog}
        handleConfirm={deleteAllSelectedTeams}
      />
      <SmartSearchInput<Team>
        resultCallback={handleAlgoliaResultsCallback}
        colPath={algoliaTeamsPath(orgDoc["@id"]!)}
      />

      {algoliaTeams && algoliaTeams.length === 0 ?
        <EmptySearchResults entity={Entity.Teams}/>
        :
        <TeamCategoryList
          uid={uid}
          access={access}
          toastProps={toastProps!}
          teamCategories={getTeamCategories()}
          orgDoc={orgDoc}
          selectedTeamsId={selectedTeamsId}
          setSelectedTeamsId={setSelectedTeamsId}
          algoliaTeams={algoliaTeams}
          setAlgoliaTeams={setAlgoliaTeams}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          deleteTeams={deleteTeams}
          selectedTeamCategoriesId={selectedTeamCategoriesId}
          setSelectedTeamCategoriesId={setSelectedTeamCategoriesId}
          handleCheckTeamCategory={handleCheckTeamCategory}
          handleCheckTeam={handleCheckTeam}
        />
      }
    </>
  )
}

export default DynamicView;