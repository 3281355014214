import {FileRequirement} from "types/FileRequirement";
import {CollectionReference, doc} from "firebase/firestore";
import {BaseProps} from "screens/BaseProps";
import {enCommonLabel, enFileRequirementLabel, enTaskLabel} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {areObjectsEqual, referenceFilespath} from "screens/utility";
import {RequirementFormData} from "types/Forms/RequirementFormData";
import {Input, OverFlowBox, SimpleFormDrawer} from "components/index";
import {getValueFromInputEvent} from "screens/utility/formUtils";
import NumberInput from "components/inputs/NumberInput";
import DataTagInput from "components/DataTag/DataTagInput";
import {initialRequirementFormError, RequirementFormError} from "types/Forms/RequirementFormError";
import {en} from "language/en";
import {ActionType, Entity, Severity} from "enums/index";
import {useCollection} from "hooks/index";
import useCheckUniqueness from "hooks/useCheckUniqueness";
import {useParams} from "react-router";
import {ReferenceFile} from "types/ReferenceFile";
import handleEnterKeyPress from "screens/utility/handleEnterKeyPress";
import {Box} from "@mui/material";
import {useEffect, useState} from "react";
import FileReferenceInput from "components/inputs/FileReferenceInput";
import templateSubmitForm from "screens/utility/templateSubmitForm";
import {statusSubmitHandler} from "screens/utility/statusSubmitHandler";

interface ManageFileRequirementDrawerProps extends BaseProps {
  isOpen: boolean;
  onClose: () => void;
  fileRequirement: FileRequirement | null;
  collectionRef: CollectionReference;
}

function ManageFileRequirementDrawer(props: ManageFileRequirementDrawerProps) {
  const {isOpen, onClose, fileRequirement, toastProps, collectionRef} = props;
  const {setToastSeverity, setToastMessage, setIsToastOpen} = toastProps!;
  const {orgId, assetId} = useParams();

  const [initialRequirement, setInitialRequirement] = useState({
    name: fileRequirement?.name ?? "",
    description: fileRequirement?.description ?? "",
    dataTagsIds: fileRequirement?.dataTagsIds ?? [],
    minimumFiles: fileRequirement?.minimumFiles ?? 0,
    maximumFiles: fileRequirement?.maximumFiles,
    hidden: false,
    referenceFileId: fileRequirement?.referenceFileId ?? null
  })

  const {templateId} = fileRequirement ?? {};
  const readOnly = typeof templateId === "string" && templateId.trim() !== "" && !!assetId;

  const [formData, setFormData] = useState<RequirementFormData>({...initialRequirement});
  const [requirementFormErrors, setRequirementFormErrors] = useState<RequirementFormError>(initialRequirementFormError)

  const [isFormValid, setIsFormValid] = useState(false)
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const isNameUnique = useCheckUniqueness(collectionRef, "name", formData.name, fileRequirement ? fileRequirement["@id"] : undefined)
  const [referenceFiles] = useCollection<ReferenceFile>(null, referenceFilespath(orgId!));

  useEffect(() => {
    if (!isOpen) return;

    const newRequirement = {
      name: fileRequirement?.name ?? "",
      description: fileRequirement?.description ?? "",
      dataTagsIds: fileRequirement?.dataTagsIds ?? [],
      minimumFiles: fileRequirement?.minimumFiles ?? 0,
      maximumFiles: fileRequirement?.maximumFiles,
      hidden: false,
      referenceFileId: fileRequirement?.referenceFileId ?? null
    }

    setIsFormSubmitting(false);
    setInitialRequirement(newRequirement);
    setFormData({...(newRequirement as RequirementFormData)});
    setRequirementFormErrors(initialRequirementFormError);
  }, [isOpen]);

  // listen to changes in input and validate them
  useEffect(() => {
    const newRequirementFormError: RequirementFormError = {
      name: "",
      minimumFiles: "",
      maximumFiles: "",
      dataTagsIds: ""
    };

    if (!isOpen) return;

    // validations
    if ((formData.name ?? "").trim() === "")
      newRequirementFormError.name = en.common.validations.requiredName

    if (!isNameUnique)
      newRequirementFormError.name = en.common.validations.uniqueName

    const {minimumFiles = 0, maximumFiles} = formData;
    const min = isNaN(minimumFiles) ? 0 : parseInt(minimumFiles.toString(), 10);
    const max = maximumFiles ? (isNaN(maximumFiles) ? null : parseInt(maximumFiles.toString(), 10)) : null;

    if (max !== null && max % 1 !== 0)
      newRequirementFormError.maximumFiles = en.screen.FileRequirement.errorMessages.nonWholeNumberInput;

    if (max !== null && min > max)
      newRequirementFormError.maximumFiles = en.screen.FileRequirement.errorMessages.invalidMaximum

    setRequirementFormErrors(newRequirementFormError);

  }, [JSON.stringify(formData), isNameUnique]);

  //listen if to validation messages
  useEffect(() => {
    if (!isOpen) return;
    const errors = Boolean(requirementFormErrors.name || requirementFormErrors.minimumFiles || requirementFormErrors.maximumFiles || requirementFormErrors.dataTagsIds)
    setIsFormValid(!errors && !areObjectsEqual(formData, initialRequirement));
  }, [JSON.stringify(formData), JSON.stringify(requirementFormErrors), initialRequirement]);

  useEffect(() => {
    // get all reference files that are in dataTagsIds
    setRequirementFormErrors({...requirementFormErrors, dataTagsIds: ""});
    const foundRefFiles = referenceFiles?.filter(refFile => formData.dataTagsIds.includes(refFile["@id"]!)) ?? [];
    if (foundRefFiles.length > 1) {
      setRequirementFormErrors({...requirementFormErrors, dataTagsIds: enFileRequirementLabel.multipleReferenceFiles})
    }
  }, [formData.dataTagsIds])

  function updateFormData<Type>(key: keyof RequirementFormData, value: Type) {
    setFormData(() => ({...formData, [key]: value}))
  }

  async function onSubmit() {
    setIsFormSubmitting(true);
    const documentRef = !fileRequirement ? doc(collectionRef) : doc(collectionRef, fileRequirement["@id"] ?? "fillerId");

    const {minimumFiles = 0, maximumFiles, ...rest} = formData;

    const min = isNaN(minimumFiles) ? 0 : parseInt(minimumFiles.toString(), 10);
    const max = maximumFiles && !isNaN(maximumFiles)
      ? parseInt(maximumFiles.toString(), 10)
      : undefined;

    const data = {
      ...rest,
      minimumFiles: min,
      maximumFiles: max || null
    };

    if (formData.referenceFileId) {
      const referenceFileIdsArray = referenceFiles?.map(ref => ref.id);
      const clearedTags = formData.dataTagsIds.filter(id => !referenceFileIdsArray?.includes(id))
      data.dataTagsIds = [...clearedTags, formData.referenceFileId]
    } else {
      data.dataTagsIds = data.dataTagsIds.filter((id) => id !== initialRequirement.referenceFileId);
    }

    await templateSubmitForm(
      documentRef.path,
      !fileRequirement ? ActionType.Create : ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler<FileRequirement>({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback
      }),
      data,
      fileRequirement
    );
  }

  function successCallback() {
    setIsFormSubmitting(false);
    setToastSeverity(Severity.Success);
    setToastMessage(fileRequirement === null ?
      en.screen.FileRequirement.toastMessages.createFileRequirementSuccess
      : en.screen.FileRequirement.toastMessages.updateFileRequirementSuccess);
    setIsToastOpen(true);
    setIsFormValid(false);
    onDrawerClose();
  }

  function errorCallback(messages: any) {
    setIsFormSubmitting(false);

    if (messages && typeof (messages) === "object") {
      setRequirementFormErrors(messages);
      return;
    }

    setToastSeverity(Severity.Error);
    setToastMessage(en.screen.FileRequirement.errorMessages.securityError);
    setIsToastOpen(true);
  }

  function onDrawerClose() {
    setIsFormValid(false);
    setRequirementFormErrors(initialRequirementFormError);
    onClose();
  }

  function removeLeadingZeros(inputValue: string) {
    const numberValue = parseInt(inputValue, 10);
    return numberValue.toString();
  }

  return (
    <SimpleFormDrawer
      title={!fileRequirement ? enFileRequirementLabel.createFileRequirement
        : enFileRequirementLabel.editFileRequirement}
      isOpen={isOpen}
      id="manage-file-requirement-drawer"
      buttonId="manage-file-requirement-submitButton"
      icon={!fileRequirement ? <SystemIcons.Plus width={24} height={24}/>
        : <SystemIcons.Edit width={24} height={24}/>}
      isFormValid={(isFormValid)}
      isLoading={isFormSubmitting}
      onSubmit={onSubmit}
      onClose={onDrawerClose}
      unsavedChanges={!areObjectsEqual(formData, initialRequirement)}
    >
      <OverFlowBox>
        <Input
          id="edit-file-requirement-name"
          label={enCommonLabel.name}
          placeholder={enCommonLabel.name}
          validationMessage={requirementFormErrors.name}
          sx={{mb: 1}}
          value={formData.name}
          disabled={readOnly}
          onChange={(e) => updateFormData<string>("name", getValueFromInputEvent(e))}
          onKeyPress={(e) => handleEnterKeyPress(e, isFormValid, isFormSubmitting, onSubmit)}
        />
        <Input
          id="edit-file-requirement-description"
          optional={true}
          label={enCommonLabel.description}
          placeholder={enCommonLabel.description}
          sx={{mb: 1}}
          value={formData.description}
          multiline
          minRows={5}
          disabled={readOnly}
          onChange={(e) => updateFormData<string>("description", getValueFromInputEvent(e))}
          onKeyPress={(e) => handleEnterKeyPress(e, isFormValid, isFormSubmitting, onSubmit)}
        />
        <NumberInput
          id="edit-file-requirement-minimum-files"
          value={formData.minimumFiles}
          label={enTaskLabel.minimumFiles}
          placeholder={enTaskLabel.minimumFiles}
          sx={{mb: 1}}
          minimumNumber={0}
          disabled={readOnly}
          validationMessage={requirementFormErrors.minimumFiles}
          onChange={(e) => updateFormData<number | string>("minimumFiles", removeLeadingZeros(getValueFromInputEvent(e)))}
          onKeyPress={(e) => handleEnterKeyPress(e, isFormValid, isFormSubmitting, onSubmit)}
        />
        <NumberInput
          id="edit-file-requirement-maximum-files"
          value={formData.maximumFiles ?? undefined}
          label={enTaskLabel.maximumFiles}
          placeholder={enTaskLabel.maximumFiles}
          sx={{mb: 1}}
          disabled={readOnly}
          minimumNumber={formData.minimumFiles}
          validationMessage={requirementFormErrors.maximumFiles}
          onChange={(e) => updateFormData<number | string>("maximumFiles", removeLeadingZeros(getValueFromInputEvent(e)))}
          onKeyPress={(e) => handleEnterKeyPress(e, isFormValid, isFormSubmitting, onSubmit)}
        />
        <FileReferenceInput
          referenceFiles={referenceFiles ?? []}
          handleSelectCallback={(value) => {
            updateFormData<string | null>("referenceFileId", value === "" ? null : value)
          }}
          defaultValue={fileRequirement?.referenceFileId ?? undefined}
        />
        <DataTagInput
          uid={props.uid}
          toastProps={toastProps}
          initialTags={formData.dataTagsIds}
          handleSelectCallback={(selectedIds) => updateFormData<string[]>("dataTagsIds", selectedIds)}
          componentId="file-requirement-data-tag"
          entity={Entity.FileRequirement}
          onKeyPress={(e) => handleEnterKeyPress(e, isFormValid, isFormSubmitting, onSubmit)}
        />
      </OverFlowBox>
      <Box flex={1}></Box>
    </SimpleFormDrawer>
  )
}

export default ManageFileRequirementDrawer;
