import {Stack, Typography} from "@mui/material";
import {enActivityFeedLabel} from "constants/index";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";

export default function CancelledTag() {
  return (
    <Stack direction="row" height="32px" width="auto" alignItems="center" gap={1}>
      <SystemIcons.Close stroke={theme.palette.error.main} height={16} width={16} strokeWidth={3}/>
      <Typography variant="h4" sx={{wordBreak: "keep-all"}}>
        {enActivityFeedLabel.invitedCancelled}
      </Typography>
    </Stack>
  )
}