/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useMemo, useState} from "react";
import {SystemIcons} from "assets/icons/system/system.index";
import {en} from "language/en";
import {
  areObjectsEqual,
  getKeyValue,
  getTimeSlots,
  onChangeInput,
  referenceFilespath,
} from "screens/utility";
import {Typography,} from "@mui/material";
import {collection, CollectionReference, doc, orderBy, Timestamp} from "firebase/firestore";
import {BaseProps} from "screens/BaseProps";
import {
  AssignableField,
  AssigneeSelectionDrawer,
  DueDateField,
  DueDatePopover,
  Input,
  SelectWithSearch,
  SimpleFormDrawer,
  ValidationMessage
} from "components/index";
import {useCollection} from "hooks/index";
import {AccessListUser, Milestone, Personnel, ReferenceFile, Task} from "types/index";
import {AccessUserType, ActionType, Entity, ProcessType, Severity} from "enums/index";
import OverflowBox from "components/OverflowBox";
import {
  defaultAssignee,
  enCommonButton,
  enCommonLabel,
  enMilestone,
  enMilestoneLabel,
  enTaskLabel,
  timePickerMinutesInterval
} from "constants/index";
import {SelectItem} from "components/inputs/SearchInput/SearchPopUp";
import DataTagInput from "components/DataTag/DataTagInput";
import {useParams} from "react-router-dom";
import {
  assetAccessListPath,
  organizationMembersPath,
  projectLevelTasksAccessListPath,
} from "screens/utility/FirebasePath";
import handleEnterKeyPress from "screens/utility/handleEnterKeyPress";
import {statusSubmitHandler} from "screens/utility/statusSubmitHandler";
import FileReferenceInput from "components/inputs/FileReferenceInput";
import {db} from "../../../firebase";
import templateSubmitForm from "screens/utility/templateSubmitForm";
import useCollectionWithTemplate from "hooks/useCollectionWithTemplate";
import useTemplateAccessList from "hooks/useTemplateAccessList";
import useGetProjectBasedPath from "hooks/useGetProjectBasedPath";
import {SelectedOrgContext} from "screens/SelectedOrgContextProvider";

interface DrawerProps extends BaseProps {
  collectionRef: CollectionReference;
  milestone: Milestone;
  onDrawerClose: () => void;
  isDrawerShown: boolean;
  parentEntity: Entity.ProjectLevelTask | Entity.Asset | Entity.Templates;
}

const drawerTitle = en.screen.Task.button.create;
const drawerId = "create-task-drawer";

const initialTask: Partial<Task> = {
  name: "",
  description: "",
  dueDate: null,
  dueTime: "",
  dueTimeAllDay: false,
  assignedTo: defaultAssignee,
  approver: defaultAssignee,
  observer: defaultAssignee,
  milestoneId: "misc",
  dataTagsIds: [],
  isPrivate: false,
  referenceFileId: null,
}

function CreateTaskDrawer(props: DrawerProps) {
  const {isDrawerShown, milestone, collectionRef, parentEntity,} = props;
  const {onDrawerClose, toastProps} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;
  const {orgId, projId, assetId} = useParams()

  const timeSlots = getTimeSlots(timePickerMinutesInterval);
  // Task Fields States
  const [isTaskPrivate, setIsTaskPrivate] = useState<boolean>(false);
  const [taskName, setTaskName] = useState<string>("");
  const [taskDescription, setTaskDescription] = useState<string>("");
  const [taskAssignee, setTaskAssignee] = useState<Personnel>(defaultAssignee);
  const [taskApprover, setTaskApprover] = useState<Personnel>(defaultAssignee);
  const [taskObserver, setTaskObserver] = useState<Personnel>(defaultAssignee);
  const [referenceFileId, setReferenceFileId] = useState<string | null>(null);

  const [taskDueDate, setTaskDueDate] = useState<Date | null>(null); // this is the actual value from the backend
  const [taskDueDateAnchor, setTaskDueDateAnchor] = useState<HTMLElement | null>(null);
  const [isTaskPopoverOpen, setIsTaskPopoverOpen] = useState<boolean>(false);
  const isTaskDueDatePopoverOpen = Boolean(taskDueDateAnchor);

  const [taskDueTime, setTaskDueTime] = useState<string | null | undefined>("");
  const [isAlldayBoxSelected, setIsAlldayBoxSelected] = useState<boolean>(false);
  const [dataTagsIds, setDataTagsIds] = useState<string[]>([]);
  const [referenceFiles] = useCollection<ReferenceFile>(null, referenceFilespath(orgId!));

  const [taskNameValidationMessage, setTaskNameValidationMessage] = useState<string>("");
  const [datatagValidationMessage, setDatatagValidationMessage] = useState<string>("");

  // Milestone Related
  const milestoneRef = useGetProjectBasedPath(collectionRef.path)
  // const [milestones] = useCollection<Milestone>(null, milestoneRef, [orderBy("timeCreated")]);
  // const milestonesRef = milestone.
  const {data: milestones} = useCollectionWithTemplate<Milestone>({
    colRef: milestoneRef ? collection(db, milestoneRef) : null,
    projectConstraints: [orderBy("timeCreated")],
    templateConstraints: [orderBy("timeCreated")],
  });
  const selectableMilestones: SelectItem[] = milestones ? milestones.map((milestone) => ({
    searchable: milestone.name,
    value: milestone["@id"]!,
    displayItem: milestone.name
  })) : [];

  // Form related states
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAssignedDrawerOpen, setIsAssignedDrawerOpen] = useState<boolean>(false);
  const [isApproverDrawerOpen, setIsApproverDrawerOpen] = useState<boolean>(false);
  const [isObserverDrawerOpen, setIsObserverDrawerOpen] = useState<boolean>(false);
  const [selectedMilestone, setSelectedMilestone] = useState<Milestone>(milestone); // Temporary Value SHOULD BE MISC

  // selection drawers
  const accessListCollectionRef = parentEntity === Entity.ProjectLevelTask ? projectLevelTasksAccessListPath(orgId!, projId!)
    : parentEntity === Entity.Asset ? assetAccessListPath(orgId!, projId!, assetId!)
      : organizationMembersPath(orgId!);

  const selectedOrgContext = useContext(SelectedOrgContext);
  const {selectedOrg} = selectedOrgContext!;

  const {accessList: personnel} = useTemplateAccessList(accessListCollectionRef.path);
  const [displayedPersonnel, setDisplayedPersonnel] = useState<AccessListUser[]>([]);

  useMemo(() => {
    if (personnel === null || personnel?.length === 0) {
      setDisplayedPersonnel([]);
      return;
    }

    let newPersonnelList: AccessListUser[] = [];
    personnel.forEach((personnelMember: AccessListUser) => {
      // if person, look it up in the members
      if (personnelMember.accessUserType === AccessUserType.Person) {
        const member = selectedOrg?.members?.find((member) => member["@id"] === personnelMember["@id"]);
        // @ts-ignore
        member && newPersonnelList.push({...member, ...personnelMember});
      }
      // if team, look it up in the teams
      const team = selectedOrg?.teams?.find((team) => team["@id"] === personnelMember["@id"]);
      // @ts-ignore
      team && newPersonnelList.push({...team, ...personnelMember});
    });

    setDisplayedPersonnel(newPersonnelList);
  }, [personnel, selectedOrg?.members, selectedOrg?.teams]);

  // validate taskname and date on change
  useEffect(() => {
    const nameNotEmpty = (taskName.trim()).length > 0
    setIsFormValid(nameNotEmpty);
    setTaskNameValidationMessage((nameNotEmpty || !isDrawerModified()) ? "" : enTaskLabel.nameRequired);

    if (taskDueDate && !taskDueTime && !isAlldayBoxSelected && parentEntity !== Entity.Templates) {
      setIsFormValid(false)
    }

    if (isAlldayBoxSelected && !taskDueDate && parentEntity !== Entity.Templates) {
      setIsFormValid(false)
    }
  }, [taskName, taskDueTime, isAlldayBoxSelected, taskDueDate]);

  // update selected milestone when passed milestone is changed
  useEffect(() => {
    if (!milestone) return;

    setSelectedMilestone(milestone);
  }, [milestone]);

  useEffect(() => {
    setTaskName("");
    setTaskDescription("");
    setTaskAssignee(defaultAssignee);
    setTaskApprover(defaultAssignee);
    setTaskObserver(defaultAssignee);
    setTaskDueDate(null);
    setTaskDueTime("");
    setIsAlldayBoxSelected(false);
    setDataTagsIds([]);
    setIsTaskPrivate(false);

    setTaskNameValidationMessage("");
    setDatatagValidationMessage("");
  }, [isDrawerShown]);

  function successCallback() {
    setToastMessage(enTaskLabel.createSuccess);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    clearTaskForm();
    setIsLoading(false);
    onDrawerClose();
  }

  function errorCallback(message: any) {
    setIsFormValid(false);
    setIsLoading(false);

    if (typeof (message) === "object") {
      setTaskNameValidationMessage(getKeyValue(message, "name", ""));
      return;
    }

    setToastMessage(message || enCommonLabel.errorProcess(ProcessType.Create));
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
  }

  function selectTargetMilestone(selectedMilestoneId: string) {
    if (!milestones) return
    const selectedMilestone = milestones.find(
      (milestone) => milestone["@id"] === selectedMilestoneId
    );
    if (selectedMilestone) {
      setSelectedMilestone(selectedMilestone!);
    }
  }

  function onTimeChange(newValue: string | null | undefined) {
    setTaskDueTime(newValue);
  }

  function onAllDayChange(newValue: boolean) {
    setIsAlldayBoxSelected(newValue);
  }

  function closeDueDatePopover() {
    setTaskDueDateAnchor(null);
    setIsTaskPopoverOpen(false);
  }

  function openSelectAssigneeDrawer() {
    setIsAssignedDrawerOpen(true);
  }

  function openSelectObserverDrawer() {
    setIsObserverDrawerOpen(true);
  }

  function clearDateTime() {
    setTaskDueDate(null);
    setTaskDueTime(null);
  }

  function clearTaskForm() {
    setTaskName("");
    setTaskDescription("");
    setIsAlldayBoxSelected(false);
    setTaskAssignee(defaultAssignee);
    setTaskObserver(defaultAssignee);
    setTaskApprover(defaultAssignee);
    setDataTagsIds([]);
    clearDateTime();
  }

  function onDueDateChange(date: Date | null) {
    setTaskDueDate(date);
  }

  async function createTask() {
    setIsLoading(true);

    const dueDate = (!taskDueDate ? {dueDate: null} : {
      dueDate: Timestamp.fromDate(taskDueDate),
      dueTime: (isAlldayBoxSelected || !taskDueTime) ? "" : taskDueTime,
      dueTimeAllDay: isAlldayBoxSelected,
    });

    const newTask: Partial<Task> = {
      name: taskName,
      description: taskDescription,
      ...dueDate,
      assignedTo: taskAssignee,
      approver: taskApprover,
      observer: taskObserver,
      isPrivate: isTaskPrivate,
      dataTagsIds: [
        ...dataTagsIds,
        ...(referenceFileId ? [referenceFileId] : [])
      ],
      hidden: false,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if (referenceFileId)
      newTask.referenceFileId = referenceFileId;

    let docRef = doc(collection(db, `${milestoneRef!}/${selectedMilestone["@id"]}/tasks`));

    await templateSubmitForm(docRef.path, ActionType.Create,
      (status, data, isLastUpdate) => statusSubmitHandler<Task>({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback
      }),
      newTask
    );
  }


  function isDrawerModified() {
    const dueDate = (!taskDueDate ? {dueDate: null} : {
      dueDate: Timestamp.fromDate(taskDueDate),
      dueTime: (isAlldayBoxSelected || !taskDueTime) ? "" : taskDueTime,
      dueTimeAllDay: isAlldayBoxSelected,
    });

    const initialTaskClone = {...initialTask};

    const updatedTask: Partial<Task> = {
      name: taskName,
      description: taskDescription,
      dueDate: dueDate.dueDate ?? null,
      assignedTo: taskAssignee,
      approver: taskApprover,
      observer: taskObserver,
      milestoneId: milestone["@id"]!,
      dataTagsIds,
      dueTimeAllDay: isAlldayBoxSelected,
      dueTime: taskDueTime ?? "",
      isPrivate: isTaskPrivate,
      referenceFileId
    }
    delete updatedTask["milestoneId"];
    delete initialTaskClone["milestoneId"];

    return !areObjectsEqual(updatedTask, initialTaskClone);
  }

  function closeDrawer() {
    clearTaskForm();
    onDrawerClose();
  }

  return (
    <>
      <SimpleFormDrawer
        isOpen={isDrawerShown}
        onClose={closeDrawer}
        id={drawerId}
        icon={<SystemIcons.Tasks width={24} height={24}/>}
        title={drawerTitle}
        isFormValid={isFormValid && isDrawerModified()}
        onSubmit={createTask}
        isLoading={isLoading}
        buttonId="createnew-task-button"
        leftButtonLabel={enCommonButton.cancel}
        rightButtonLabel={enCommonButton.create}
        unsavedChanges={isDrawerModified()}
      >
        <OverflowBox>
          <Input
            label={enCommonLabel.name}
            id="create-task-drawer-name-input"
            onChange={(e) => onChangeInput(e, setTaskName)}
            sx={{mb: 1}}
            value={taskName}
            optional={false}
            validationMessage={taskNameValidationMessage}
            onKeyPress={(e) => handleEnterKeyPress(e, (isFormValid && isDrawerModified()), isLoading, createTask)}
          />
          <Input
            label={enCommonLabel.description}
            id="create-task-drawer-description-input"
            onChange={(e) => onChangeInput(e, setTaskDescription)}
            sx={{mb: 1}}
            value={taskDescription}
            optional={true}
            minRows={5}
            maxRows={5}
            multiline
            onKeyPress={(e) => handleEnterKeyPress(e, (isFormValid && isDrawerModified()), isLoading, createTask)}
          />
          <SelectWithSearch
            id="create-task-drawer-search-select"
            searchInputId="searchMilestone"
            searchPlaceholder={enMilestoneLabel.search}
            LabelComponent={
              <Typography variant="h5">
                {enMilestone.title}
              </Typography>
            }
            items={
              selectableMilestones
            }
            defaultValue={selectedMilestone["@id"]}
            handleSelectCallback={selectTargetMilestone}
            sx={{mb: 1}}
          />
          {parentEntity !== Entity.Templates && (
            <>
              <AssignableField
                id="create-task-drawer-assignedto"
                label={enTaskLabel.assignedTo}
                entity={taskAssignee}
                isPrivate={isTaskPrivate}
                isCheckboxShown={true}
                sx={{mb: 1}}
                onContentClick={openSelectAssigneeDrawer}
                onCheckboxChange={(e, checked) => setIsTaskPrivate(checked)}
              />
              <AssignableField
                id="create-task-drawer-approver"
                label={enTaskLabel.approver}
                entity={taskApprover}
                isCheckboxShown={false}
                isPrivate={isTaskPrivate}
                sx={{mb: 1}}
                onContentClick={() => setIsApproverDrawerOpen(true)}
              />
              <AssignableField
                id="create-task-drawer-observer"
                label={enTaskLabel.observer}
                entity={taskObserver}
                isCheckboxShown={false}
                isPrivate={isTaskPrivate}
                sx={{mb: 1}}
                onContentClick={openSelectObserverDrawer}
              />
              <DueDateField
                isClearButtonVisible={(!!taskDueTime) || (!!taskDueDate)}
                timeSlots={timeSlots}
                onClearButtonClick={clearDateTime}
                onDueDateChange={onDueDateChange}
                onTimeChange={onTimeChange}
                onAllDayChange={onAllDayChange}
                defaultValue={false}
              />
            </>
          )}
          <FileReferenceInput
            referenceFiles={referenceFiles ?? []}
            handleSelectCallback={(value) => {
              setReferenceFileId(value === "" ? null : value)
            }}
          />
          <DataTagInput
            handleSelectCallback={setDataTagsIds}
            sx={{mb: 1}}
            toastProps={toastProps}
            initialTags={dataTagsIds}
            entity={Entity.Task}
            uid={props.uid}
            onKeyPress={(e) => handleEnterKeyPress(e, (isFormValid && isDrawerModified()), isLoading, createTask)}
          />
          <ValidationMessage validationMessage={datatagValidationMessage}/>
        </OverflowBox>
      </SimpleFormDrawer>
      <AssigneeSelectionDrawer
        drawerLabel={enTaskLabel.assignedTo}
        taskAssignee={taskAssignee}
        personnel={displayedPersonnel}
        isDrawerOpen={isAssignedDrawerOpen}
        closeDrawer={() => setIsAssignedDrawerOpen(false)}
        setter={(obj: Personnel) => setTaskAssignee(obj)}
      />
      <AssigneeSelectionDrawer
        drawerLabel={enTaskLabel.assignedTo}
        taskAssignee={taskApprover}
        personnel={displayedPersonnel}
        isDrawerOpen={isApproverDrawerOpen}
        closeDrawer={() => setIsApproverDrawerOpen(false)}
        setter={(obj: Personnel) => setTaskApprover(obj)}
      />
      {/*There is a label for Observer on en.ts*/}
      <AssigneeSelectionDrawer
        drawerLabel={enTaskLabel.assignedTo}
        taskAssignee={taskObserver}
        personnel={displayedPersonnel}
        isDrawerOpen={isObserverDrawerOpen}
        closeDrawer={() => setIsObserverDrawerOpen(false)}
        setter={(obj: Personnel) => setTaskObserver(obj)}
      />

      {isTaskPopoverOpen && (
        <DueDatePopover
          isOpen={isTaskDueDatePopoverOpen}
          selectedDate={taskDueDate}
          anchorElement={taskDueDateAnchor}
          setSelectedDate={(date: Date | null) => setTaskDueDate(date)}
          closePopover={closeDueDatePopover}
        />
      )}
    </>
  );
}

export default CreateTaskDrawer;
