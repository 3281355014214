export function sortObjectsBy<Type>(
  arr: Type[],
  field: keyof Type,
  order: "asc" | "desc" = "asc"
) {
  return arr.sort((a, b) => {
    const valueA = String(a[field]);
    const valueB = String(b[field]);

    if (order === 'asc') {
      return valueA.localeCompare(valueB, undefined, { numeric: true, sensitivity: 'base' });
    } else {
      return valueB.localeCompare(valueA, undefined, { numeric: true, sensitivity: 'base' });
    }
  });
}

export function sortStringsBy(arr: string[], order: "asc" | "desc" = "asc") {
  const result = arr.sort((a, b) => {
    if (a > b) {
      return order === "desc" ? -1 : 1;
    }
    if (b > a) {
      return order === "desc" ? 1 : -1;
    }

    return 0;
  });

  return result;
}
