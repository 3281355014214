import {doc, DocumentReference} from "firebase/firestore";
import {useDocument} from "hooks/index";
import {useEffect} from "react";
import {db} from "../firebase";
import {getTemplateCopyPath} from "../screens/utility/templateSubmitForm";
import {UseDocumentReturn} from "hooks/useDocument";

function useTemplateDocument<T>(docRef: DocumentReference | null): UseDocumentReturn<T> {
  const [projectBasedDoc, setProjectBasedDocRef, projectRef] = useDocument<T>(docRef)
  const [templateBasedDoc, setTemplateBasedDocRef, templateRef] = useDocument<T>(null)

  useEffect(() => {
    // wait for project based doc to fetch before fetching copy from templates
    if(projectBasedDoc || projectBasedDoc === null)
      return

    if(!docRef)
      return

    setTemplateBasedDocRef(doc(db, getTemplateCopyPath(docRef.path)))

  }, [docRef?.path, projectBasedDoc]);

  return [(projectBasedDoc || templateBasedDoc), setProjectBasedDocRef, (!!templateBasedDoc ? templateRef : projectRef)]
}

export default useTemplateDocument