import {Backdrop, Stack} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import {StatisticsItem} from "components";
import theme from "theme/theme";
import {ReferenceFile} from "types/index";
import {emptyFunction, enTaskLabel,} from "constants/index";
import React, {useEffect, useState} from "react";
import DownloadOptionsMenu from "components/Dialogs/DownloadConfirmDialog/DownloadOptionsMenu";
import {Entity} from "enums/index";
import {DownloadIconButton} from "components/Button";
import {PermissionEntity, PermissionOperationKey} from "types/Permission";
import {useComponentToggler, useDocument} from "hooks/index";
import {useParams} from "react-router-dom";
import {ReferenceFileUsage} from "components/ReferenceFileUsage";
import ChatAttachmentCarousel, {
  CarouselItem
} from "components/ChatDrawer/ChatItemsView/ChatAttachmentCarousel";
import {doc} from "firebase/firestore";
import {referenceFilespath} from "../utility";
import {toastProps} from "../BaseProps";
import useTemplateAccess from "hooks/useTemplateAccess";

interface StatisticsProps {
  referenceFileId?: string | undefined | null;
  parentEntity: Entity;
  uid: string;
  toastProps: toastProps;
  filesCount: number;
  requirementsCount: number;
  requirementsApprovedCount: number;
}

function Statistics(props: StatisticsProps) {
  const {parentEntity, referenceFileId, uid} = props;

  const {orgId, assetId} = useParams();

  const [downloadOptionsAnchor, setDownloadOptionsAnchor] = useState<Element | null>(null);
  const [activeCarouselIndex] = useState<number>(0);
  const [isFileCarouselOpen, {toggle: toggleFileCarousel, open: openFileCarousel}] = useComponentToggler(false);
  const [carouselItems, setCarouselItems] = useState<CarouselItem[]>([]);
  const [referenceFile, setReferenceFileRef] = useDocument<ReferenceFile>(null);

  const [downloadAccess] = useTemplateAccess({
    uid,
    entity: Entity.Task,
    documentDocId: PermissionEntity.TaskFilesDownload,
  });

  const isDownloadEnabled = Boolean(downloadAccess?.[PermissionOperationKey.Create]);

  useEffect(() => {
    if (!referenceFileId) {
      setReferenceFileRef(null)
      return
    }
    const referenceFileRef = doc(referenceFilespath(orgId!), referenceFileId);
    setReferenceFileRef(referenceFileRef)
  }, [referenceFileId]);

  if (parentEntity === Entity.Templates && !!referenceFile) return (
    <Stack
      direction="column"
      alignItems={{xs: "flex-start", lg: "flex-end"}}
    >
      <ReferenceFileUsage
        referenceFile={referenceFile}
        setCarouselItems={setCarouselItems}
        onFilePreviewClick={openFileCarousel}
      />
    </Stack>
  )
  if (parentEntity === Entity.Templates) return null;

  return (
    <>
      <Stack
        alignItems={{xs: "flex-start", sm: "flex-start", md: "flex-start", lg: "center"}}
        direction={{xs: "column", sm: "column", md: "column", lg: "row"}}
        justifyContent={{xs: "left", sm: "left", md: "left", lg: "space-between"}}
        justifyItems="flex-start"
      >
        <Stack direction="row" spacing={5} marginBottom={{xs: 2, sm: 2, md: 0, lg: 0}} justifyContent="flex-start">
          <Stack direction="column">
            <StatisticsItem
              Icon={<SystemIcons.Requirement stroke={theme.palette.text.secondary}/>}
              count={`${props.requirementsApprovedCount ?? 0}/${props.requirementsCount ?? 0}`}
              label={enTaskLabel.requirements}
              id="task-overview-statistics-requirements"
            />
          </Stack>
          <Stack direction="row" alignItems="center">
            <StatisticsItem
              Icon={<SystemIcons.FilesOutlined stroke={theme.palette.text.secondary}/>}
              count={props.filesCount ?? 0}
              label={enTaskLabel.files}
              id="task-overview-statistics-files"
            />
            <DownloadIconButton
              uid={uid}
              onClickFcn={emptyFunction}
              filesCount={props.filesCount}
              entity={!!assetId ? Entity.Task : Entity.PLTask}
              disabled={!isDownloadEnabled}
              toastProps={props.toastProps!}
              sx={{marginTop: -2}}
            />
          </Stack>
        </Stack>
        {!!referenceFile && (
          <Stack
            direction="column"
            alignItems={{xs: "flex-start", lg: "flex-end"}}
          >
            <ReferenceFileUsage
              referenceFile={referenceFile}
              setCarouselItems={setCarouselItems}
              onFilePreviewClick={openFileCarousel}
            />
          </Stack>
        )}
      </Stack>
      <DownloadOptionsMenu
        uid={uid}
        filesCount={props.filesCount}
        entity={!!assetId ? Entity.Task : Entity.PLTask}
        anchorEl={downloadOptionsAnchor}
        onClose={() => setDownloadOptionsAnchor(null)}
        transformOrigin={{
          vertical: 0,
          horizontal: 132
        }}
        toastProps={props.toastProps!}
      />
      <Backdrop
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
        open={isFileCarouselOpen}
      >
        <Stack
          direction="row"
          position="relative"
          sx={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {(isFileCarouselOpen) && (
            <ChatAttachmentCarousel
              carouselItems={carouselItems}
              toggleAttachmentCarousel={toggleFileCarousel}
              clickedIndex={activeCarouselIndex}
            />
          )}
        </Stack>
      </Backdrop>
    </>
  )
}

export default Statistics;
