/* eslint-disable react-hooks/exhaustive-deps */
import React, {MouseEvent, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Box, Checkbox, Divider, Grid, Hidden, Paper, Stack, Tooltip, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {LongText, SelectStatus} from "components/index";
import {EntityChat, FileRequirement, StatusItem, SwiftFile} from "types/index";
import {
  ActionType,
  CounterFields,
  Entity,
  FileStatus,
  RequirementStatus,
  RequirementStatus as Status,
  Severity
} from "enums/index";
import {useNavigate, useParams} from "react-router-dom";
import {BaseProps} from "screens/BaseProps";
import {
  emptyFunction,
  enCommonLabel,
  enFileRequirementLabel,
  enTaskLabel,
  initialRequirement
} from "constants/index";
import {generateEntityBreadcrumbs, getDocPath, getStatusOptions} from "screens/utility";
import {fileRequirementPath} from "screens/utility/FirebasePath";
import {collection, CollectionReference, doc, getDoc, limit, orderBy, Timestamp, where,} from "firebase/firestore";
import FileRequirementStatusObjectList from "assets/arrayList/StatusObjectList/RequirementStatusObjectList";
import {useCollection, useRTDBDocField} from "hooks/index";
import {getDownloadURL, ref} from "firebase/storage";
import {db, storage} from "../../../firebase";
import Previews from "./Previews";
import {ChatIconButton, DownloadIconButton, SwiftIconButton} from "components/Button";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import {AccessType, PermissionEntity, PermissionOperationKey} from "types/Permission";
import getEnvKey from "../../utility/getEnvKey";
import {storageBucketKey} from "constants/envKeys";
import RightClickMenu from "../components/RightClickMenu";
import LazyCarousel from "components/LazyCarousel";
import {getBorderColor} from "./utils/getBorderColor";
import useFileUpload, {UploadFile} from "hooks/useFileUpload/useFileUpload";
import {generateUniqueId} from "hooks/useFileUpload/reducer/reducer";
import {BreadCrumbType} from "../../utility/generateEntityBreadcrumbs";
import {UploadContext} from "hooks/useFileUpload/context/UploadContext";
import {en} from "language/en";
import getFileDownloadPath from "../../utility/getFileDownloadPath";
import downloadFile from "../../utility/downloadFile";
import isNotPlayableOrNoPreview from "../../utility/isNotPlayableOrNoPreview";
import useTemplateAccess from "hooks/useTemplateAccess";
import templateSubmitForm from "../../utility/templateSubmitForm";
import {statusSubmitHandler} from "../../utility/statusSubmitHandler";
import {styled} from "@mui/material/styles";

interface RequirementItemProps extends BaseProps {
  fileRequirement: FileRequirement;
  fileRequirementAccess: AccessType | null;
  fileAccess: AccessType | null;
  isChecked: boolean;
  onCheckboxToggle: (checked: boolean, id: string) => void;
  fileRequirementsRef: CollectionReference;
  openRequirementDrawer: (requirement: FileRequirement) => void;
  onFilePreviewClick: (index: number, filesCount: number) => void;
  fileDropItem: string | null;
  setFileDropItem: (fileId: string | null) => void;
  addFiles: (files: UploadFile[]) => void;
  showCheckBox: boolean;
}

interface FileUploadWithDownloadUrl extends SwiftFile {
  downloadUrl?: string;
}

function RequirementItem(props: RequirementItemProps) {
  const {
    fileRequirement,
    fileRequirementAccess,
    isChecked,
    toastProps,
    fileRequirementsRef,
    fileAccess,
    showCheckBox,
    uid,
  } = props;

  const {openRequirementDrawer, onCheckboxToggle, setFileDropItem, fileDropItem} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  const {orgId, projId, assetId, milestoneId, taskId} = useParams();
  const context = useContext(UploadContext);

  const processingFiles = context ? context!.fileUploadState.processingFiles.filter(
    (file) => file.parentCollectionPath.startsWith(doc(fileRequirementsRef, fileRequirement["@id"]).path)
      && file.lengthUploaded !== file.length
  ) : [];

  const {addFiles} = useFileUpload({uid: uid!});
  const [requirement, setRequirement] = useState<Partial<FileRequirement>>({...initialRequirement});
  const [selectedStatus, setSelectedStatus] = useState<StatusItem | undefined>(undefined);
  const [statusOption, setStatusOption] = useState<Record<string, StatusItem> | null>(null);
  const [isRequirementChecked, setIsRequirementChecked] = useState<boolean>(isChecked);
  const [anchorPosition, setAnchorPosition] = useState<{ top: number, left: number }>({top: 0, left: 0});
  const [clickedCarouselIndex, setClickedCarouselIndex] = useState<number | null>(null);
  const [isFileUploadFocus, setIsFileUploadFocus] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [filesPreview, setFilePreviewConstraints, setColRef] = useCollection<SwiftFile>(null, null,
    [orderBy("timeCreated", "asc"), limit(1)]);

  // user specific file requirement
  const userDocPath = getDocPath(uid, fileRequirementsRef.path, fileRequirement["@id"]!);
  const hasNewChat = useRTDBDocField<boolean>(userDocPath, "hasNewChat") ?? false;
  const chatEmpty = useRTDBDocField<boolean>(fileRequirementsRef.path+"/"+fileRequirement["@id"]!, "chatEmpty") ?? true;
  const filesCount = useRTDBDocField<number>(userDocPath, CounterFields.FilesCount) ?? 0;

  const [filePreviewWithDownloadUrls, setFilePreviewWithDownloadUrls] = useState<FileUploadWithDownloadUrl[]>([]);
  const [entityBreadCrumbs, setEntityBreadCrumbs] = useState<BreadCrumbType[]>([]);
  const [isLoadingPreviews, setLoadingPreviews] = useState<boolean>(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState<Record<number, boolean> | null>(null);

  const nav = useNavigate();

  const storageBucket = getEnvKey(storageBucketKey);
  const anchorEl = useRef<HTMLDivElement | null>(null);
  const previewsParentRef = useRef<HTMLDivElement | null>(null);
  const parentWidth = previewsParentRef?.current?.offsetWidth || 0;
  const displayableCount = Math.floor((parentWidth - 65) / 65);
  const fetchCount = displayableCount;
  const extraItemsCount = (filesCount ?? 0) - displayableCount;
  const [downloadAccess] = useTemplateAccess({
    uid,
    entity: Entity.Task,
    documentDocId: PermissionEntity.FileRequirementFilesDownload,
  });
  const canDownload = Boolean(downloadAccess?.[PermissionOperationKey.Create]);
  const canUpdateStatus = Boolean(fileRequirementAccess?.[PermissionOperationKey.OverrideStatus]) || Boolean(fileRequirementAccess?.[PermissionOperationKey.ChangeStatus]);

  //when displayable count is calculated load appropriate amount of previews
  useEffect(() => {
    if (displayableCount < 1) return;

    setFilePreviewConstraints([
      where("fileStatus", "!=", FileStatus.Uploading),
      orderBy("fileStatus"), orderBy("timeCreated", "asc"),
      limit(displayableCount)
    ]);
  }, [displayableCount, filesCount])

  useMemo(() => {
    if (!fileRequirement["@id"]) return;

    const path = fileRequirement?.docColRef ? fileRequirement.docColRef : fileRequirementsRef.path;
    const fileReqDoc = doc(db, path, fileRequirement["@id"]);
    setColRef(collection(fileReqDoc, "files"));

    const breadCrumbs = generateEntityBreadcrumbs({
      ...fileRequirement,
      fileRequirementName: fileRequirement.name,
      entity: !!assetId ? Entity.FileRequirement : Entity.PLFileRequirement,
      entityRefPath: fileReqDoc.path
    } as unknown as EntityChat);

    setEntityBreadCrumbs(breadCrumbs);
  }, [fileRequirement]);

  //listen to file previews and get their download urls
  useEffect(() => {
    if (!filesPreview || filesPreview.length === 0) {
      setLoadingPreviews(false);
      return;
    }

    setLoadingPreviews(true);

    async function getDownloadURLs() {
      //add two for the extra item to load with the +n sign
      const newFilePreviewWithDownloadUrls = await Promise.all(filesPreview ? filesPreview.slice(0, fetchCount).map(async (filePreview) => {
        const additionalProps: any = {};
        if ((/image/).test(filePreview.contentType!)) {
          const filePreviewRef = await ref(storage, `${storageBucket}${filePreview.previewPath}`)
          additionalProps.downloadUrl = await getDownloadURL(filePreviewRef).catch(() => undefined);
        }

        return {...filePreview, ...additionalProps}
      }) : [])
      setFilePreviewWithDownloadUrls(newFilePreviewWithDownloadUrls);
      setLoadingPreviews(false);
    }

    getDownloadURLs()
  }, [filesPreview?.length]);

  async function onFilePreviewClick(index: number) {
    const currentFile = filePreviewWithDownloadUrls[index] as FileUploadWithDownloadUrl;
    if (!currentFile) {
      setClickedCarouselIndex(index);
      return;
    }
    const notSupported = isNotPlayableOrNoPreview(currentFile.name);
    if (!notSupported) {
      setClickedCarouselIndex(index);
      return;
    }
    setIsDownloading(prev => {
      if (prev === null) return {[index]: true};
      return {...prev, [index]: true};
    });
    const downloadPath = await getFileDownloadPath(currentFile.destPath);
    await downloadFile(downloadPath, currentFile.name);
    setIsDownloading(prev => {
      if (prev === null || !(index in prev)) return prev;
      const {[index]: _, ...newState} = prev;
      return newState;
    });
  }

  // if there are any changes in the parent file fileRequirement, reflect it in the local copy
  useEffect(() => {
    setRequirement({
      ...fileRequirement,
      fileRequirementStatus: fileRequirement.fileRequirementStatus ?? Status.Pending,
    });
  }, [fileRequirement]);

  // once the component is rendered, set the fileRequirement status options
  useEffect(() => {
    const [options, selected] = getStatusOptions(FileRequirementStatusObjectList, requirement.fileRequirementStatus ?? RequirementStatus.Pending);
    setStatusOption(options);
    setSelectedStatus(selected);
  }, [requirement.fileRequirementStatus]);

  useEffect(() => {
    setIsRequirementChecked(isChecked);
  }, [isChecked]);

  function onCheckboxChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    onCheckboxToggle(checked, fileRequirement["@id"] ?? "");
    setIsRequirementChecked(checked);
  }

  function onFileUploadButtonClick(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (canUploadFile(0)) {
      fileInputRef!.current!.click();
    }
  }

  function canUploadFile(selectedFilesCount: number): boolean {
    if (requirement.fileRequirementStatus === Status.Approved) {
      showMessage(enTaskLabel.alreadyApprovedError, Severity.Error);
      return false;
    }

    const maximumFiles = requirement.maximumFiles;
    if (!maximumFiles) return true;

    const currentFilesCount = (filesCount || 0) + processingFiles.length;
    const availableFilesCount = maximumFiles - currentFilesCount;
    if (availableFilesCount < selectedFilesCount) {
      showMessage(enTaskLabel.maximumFilesError, Severity.Error);
      return false;
    }

    return true;
  }

  function showMessage(message: string, severity: Severity) {
    setToastMessage(message);
    setToastSeverity(severity);
    setIsToastOpen(true);
  }

  async function onFileUploadInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.files && e.target.files[0]) {
      await handleFileUpload(e.target.files);
    }
  }

  function handleDragEnter(e: React.DragEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (Boolean(!fileAccess?.[PermissionOperationKey.Create])) return;

    if (fileRequirement["@id"] !== fileDropItem)
      setFileDropItem(fileRequirement["@id"] || null);

    !isFileUploadFocus && setIsFileUploadFocus(true);
  }

  function handleDragLeave(e: React.DragEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (fileRequirement["@id"] === fileDropItem) setFileDropItem(null);
    isFileUploadFocus && setIsFileUploadFocus(false);
  }

  async function handleDrop(e: React.DragEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();

    setIsFileUploadFocus(false);
    setFileDropItem(null);

    if (e.dataTransfer.files
      && e.dataTransfer.files[0]
      && canUploadFile(e.dataTransfer.files.length ?? 0)) {
      await handleFileUpload(e.dataTransfer.files);

    }
  }

  async function handleFileUpload(files: FileList) {
    if (!fileRequirement["@id"]) return;

    let collectionPath = collection(fileRequirementsRef, fileRequirement["@id"], "files").path;

    if (files && files.length > 0) {
      let newFiles: UploadFile[] = [];
      for (let index = 0; index < files.length; index++) {
        const file = files.item(index);
        if (!file) continue;

        newFiles.push({
          file,
          overwrite: false,
          queueId: generateUniqueId() + index,
          parentCollectionPath: collection(db, collectionPath).path,
          timeCreated: Timestamp.now(),
          breadCrumbs: entityBreadCrumbs,
        })
      }

      const parentCreated = await createParentDoc();
      parentCreated && await addFiles(newFiles);
    }
  }

  async function createParentDoc() {
    if (!fileRequirement["@id"])
      return Promise.resolve(true);

    // check if parent doc already exists
    const docExists = (await getDoc(doc(fileRequirementsRef, fileRequirement["@id"]!))).exists();
    if (docExists)
      return Promise.resolve(true);

    showMessage(enCommonLabel.preparingParentDoc, Severity.Info);

    return await templateSubmitForm(
      fileRequirementsRef.path + "/" + fileRequirement["@id"]!,
      ActionType.Create,
      (status, data, isLastUpdate) => statusSubmitHandler({
        status, data, isLastUpdate,
        successCallback: () => {
          Promise.resolve(true)
        },
        errorCallback: () => {
          showMessage(enCommonLabel.errorCreatingParentDoc, Severity.Error);
          throw new Error("Error in creating parent doc");
        }
      }),
      {name: fileRequirement.name}
    ).then(() => {
      return Promise.resolve(true)
    }).catch(() => {
      return Promise.resolve(false);
    })
  }

  function onChatIconButtonClick(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    openRequirementDrawer(fileRequirement);
  }

  function onCardClick(e: React.MouseEvent) {
    const target = e.target as HTMLElement;
    if (target instanceof SVGElement) return;
    if (target instanceof HTMLParagraphElement) return;
    if (target.classList.contains('MuiModal-backdrop')) return;

    nav(`./file-requirements/${requirement["@id"]!}`);
  }

  function onCardRightClick(e: MouseEvent<HTMLElement>) {
    setAnchorPosition({top: e.clientY, left: e.clientX})
    e.preventDefault();
    setIsMenuOpen(!isMenuOpen);
  }

  if (!fileRequirement["@id"]) return null;

  return (
    <>
      {
        (clickedCarouselIndex !== null) && (
          <LazyCarousel
            clickedIndex={clickedCarouselIndex}
            toggleAttachmentCarousel={() => setClickedCarouselIndex(null)}
            filesColRef={collection(fileRequirementsRef, fileRequirement["@id"], "files")}
          />
        )
      }
      <input
        id="file"
        type="file"
        ref={fileInputRef}
        style={{display: "none"}}
        onChange={onFileUploadInputChange}
        multiple={true}
      />
      <RightClickMenu
        filesCount={filesCount || 0}
        uid={props.uid}
        anchorPosition={anchorPosition}
        isOpen={isMenuOpen}
        anchorEl={anchorEl.current}
        onClose={() => setIsMenuOpen(false)}
        fileRequirement={fileRequirement}
        toastProps={toastProps!}
        collectionRef={fileRequirementsRef}
        entity={!!assetId ? Entity.FileRequirement : Entity.PLFileRequirement}
        fromTemplates={!!fileRequirement?.templateId}
      />
      <Stack direction="row" alignItems="center">
        {showCheckBox &&
          <Checkbox
            checked={isRequirementChecked}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={onCheckboxChange}
            sx={{
              flex: 0,
              "&.Mui-disabled": {
                cursor: "not-allowed",
                pointerEvents: "unset"
              }
            }}
            title={!!fileRequirement.templateId ? enFileRequirementLabel.cannotDeleteWithTemplate: ""}
            disabled={!!fileRequirement.templateId}
          />
        }
        <Paper
          ref={anchorEl}
          onDragEnter={handleDragEnter}
          onDragOver={(e) => e.preventDefault()}
          sx={{
            position: "relative",
            cursor: "pointer",
            boxShadow: "none",
            outline: `1px solid ${theme.palette.neutral.light}`,
            "&:hover": {
              outlineColor: theme.palette.primary.main,
            },
            width: "100%"
          }}
          onClick={onCardClick}
          onContextMenu={onCardRightClick}
        >
          {isFileUploadFocus && (
            <Box
              position="absolute"
              sx={{
                display: "grid",
                zIndex: 1,
                height: "100%",
                width: "100%",
                placeItems: "center",
                alignItems: "center",
                backgroundColor: 'rgba(0, 0, 0, 0)',
              }}
              onDragLeave={handleDragLeave}
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleDrop}
            />
          )}
          <Stack
            className="file-requirement-item"
            id={`file-requirement-item-${fileRequirement["@id"]!}`}
            borderRadius={1}
            border={1}
            sx={{
              border: `1px solid ${getBorderColor(isFileUploadFocus, fileRequirement.fileRequirementStatus, isRequirementChecked)}`,
              cursor: "pointer",
              backgroundColor: theme.palette.background.paper,
            }}
            direction="row"
          >
              <Grid item sx={{alignSelf: "stretch"}}>
              {statusOption !== null && (
                <SelectStatus
                  documentRef={fileRequirementPath(orgId!, projId!, assetId!, milestoneId!, taskId!, requirement["@id"] ?? "defaultId")}
                  statusField="fileRequirementStatus"
                  displayIconOnSelected={true}
                  displayTextOnSelected={false}
                  items={statusOption}
                  isDisabled={!canUpdateStatus}
                  selected={selectedStatus}
                  toastProps={toastProps!}
                  selectSx={{
                    height: "100%",
                    width: "100%",
                    minHeight: "100%",
                    border: "none",
                    "&:disabled": {
                      border: "none"
                    },
                    "&:hover": {
                      border: "none",
                      backgroundColor: "transparent"
                    },
                  }}
                  width="50px"
                />
                )}
              </Grid>
            <Divider orientation="vertical" flexItem sx={{marginRight: 1}}/>
            <Stack
              ref={previewsParentRef!}
              flex={1}
              direction="column"
              py={1} gap={1 / 2}
              alignItems="flex-start"
              justifyContent="center"
            >
              <LongText
                variant="h4"
                maxWidth="100%"
                overflowWrap={"anywhere"}
              >
                {requirement.name}
              </LongText>
              <LongText
                textColor="text.secondary"
                variant="bodySmall"
                maxWidth="100%"
                overflowWrap={"anywhere"}
              >
                {!!requirement.description ? requirement.description : enCommonLabel.noDescription}
              </LongText>
              <DataTagsDisplay
                dataTagsIds={requirement.dataTagsIds || []}
                toastProps={toastProps!}
                listSize="medium"
                uid={props.uid}
                isParentHidden={Boolean(requirement.hidden)}
                canEditDocumentRef={Boolean(fileRequirementAccess?.[PermissionOperationKey.Update])}
                documentRef={doc(fileRequirementsRef, fileRequirement["@id"]!)}
              />
              <Previews
                extraItemsCount={extraItemsCount}
                isLoadingPreviews={isLoadingPreviews}
                previews={filePreviewWithDownloadUrls}
                displayableCount={displayableCount}
                onFilePreviewClick={onFilePreviewClick}
                parentRef={previewsParentRef}
                isDownloading={isDownloading}
              />
            </Stack>
            <Stack direction="row" justifyContent="flex-end">
              <Hidden smDown>
                <Stack direction="row" gap={0.5} alignItems="center" marginRight={1}>
                  <SystemIcons.FilesOutlined color={theme.palette.text.secondary}/>
                  <Stack direction="row">
                    <Tooltip title={enTaskLabel.tooltip.uploadedFilesCount}>
                      <Typography color="text.secondary" variant="h5" className="requirement-count">
                        {`${filesCount ?? 0}`}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Stack>
                <Divider orientation="vertical" sx={{marginX: 1}} flexItem/>
              </Hidden>
              <Stack direction="row" alignItems="center" flex={1} paddingRight={2}>
                <DownloadIconButton
                  withDropdown={false}
                  sx={{mt: 0}}
                  uid={uid}
                  onClickFcn={emptyFunction}
                  filesCount={filesCount ?? 0}
                  entity={!!assetId ? Entity.FileRequirement : Entity.PLFileRequirement}
                  disabled={!canDownload}
                  toastProps={toastProps!}
                  requirementId={fileRequirement["@id"]}
                />
                <SwiftIconButton
                  title={en.screen.FileRequirement.label.uploadFiles}
                  id={`${requirement["@id"]!}-file-requirement-upload-button`}
                  className="file-requirement-upload-button"
                  onClickFcn={onFileUploadButtonClick}
                  disabled={Boolean(!fileAccess?.[PermissionOperationKey.Create])}
                  startIcon={SystemIcons.Upload}
                />
                <ChatIconButton
                  id={`${fileRequirement["@id"]}-file-requirement-item-chat-button`}
                  entity={Entity.FileRequirement}
                  withNewMessage={Boolean(hasNewChat)}
                  chatEmpty={Boolean(chatEmpty)}
                  onClickFcn={onChatIconButtonClick}
                />
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </>
  )
}

export default RequirementItem;
