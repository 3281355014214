export enum EnvNames{
  REACT_APP_FIREBASE_CONFIG = "REACT_APP_FIREBASE_CONFIG",
  REACT_APP_GOOGLE_MAPS_API = "REACT_APP_GOOGLE_MAPS_API",
  REACT_APP_ALGOLIA_APP_ID = "REACT_APP_ALGOLIA_APP_ID",
  REACT_APP_ALGOLIA_API_KEY = "REACT_APP_ALGOLIA_API_KEY",
  REACT_APP_ALGOLIA_INDEX_NAME = "REACT_APP_ALGOLIA_INDEX_NAME",
  REACT_APP_VAPID_KEY = "REACT_APP_VAPID_KEY",
  REACT_APP_USE_LOCAL_DB = "REACT_APP_USE_LOCAL_DB",
  REACT_APP_STORAGE_BUCKET = "REACT_APP_STORAGE_BUCKET",
  REACT_APP_MAP_RESTRICTION_COUNTRIES = "REACT_APP_MAP_RESTRICTION_COUNTRIES",
  REACT_APP_DOWNLOAD_URL = "REACT_APP_DOWNLOAD_URL",
  REACT_APP_SUBMIT_TIMEOUT = "REACT_APP_SUBMIT_TIMEOUT",
  REACT_APP_SUBMIT_TIMEOUT_EXPORT = "REACT_APP_SUBMIT_TIMEOUT_EXPORT",
  REACT_APP_MAX_IMPORT_ROWS = "REACT_APP_MAX_IMPORT_ROWS",
  REACT_APP_VERSION = "REACT_APP_VERSION",
}