import {Stack, Typography} from "@mui/material";
import {enActivityFeedLabel} from "constants/index";
import React from "react";
import {EmptyList, InProgress} from "components/index";
import {Entity} from "enums/entity";

interface PageFooterProps {
  isEmpty: boolean;
  atBottom: boolean;
  isLoading: boolean;
}

function PageFooter(props: PageFooterProps) {
  const {isEmpty, atBottom, isLoading} = props;

  if (isEmpty) {
    return (
      <Stack justifyContent="center" height="80vh" paddingRight={'20%'}>
        <EmptyList entity={Entity.ActivityFeed}/>
      </Stack>
    )
  }

  if (atBottom) {
    return (
      <div
        style={{
          paddingRight: '20%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="body" textAlign="center" color="text.secondary" paddingY={1}>
          {enActivityFeedLabel.noMoreActivities}
        </Typography>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div
        style={{
          paddingRight: '20%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <InProgress/>
      </div>
    )
  }

  return null;
}

export default PageFooter;