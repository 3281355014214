import {Box, Button, Drawer as MuiDrawer, IconButton, SxProps, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import React, {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import theme from "theme/theme";
import DrawerFieldsWrapper from "./DrawerFieldsWrapper";
import {UnsavedChangesDialog} from "components/Dialogs";
import {DrawerHeaderIcon, OverFlowBox} from "components/index";

export interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  id?: string;
  buttonId?: string;
  icon: React.ReactNode;
  iconProps?: any;
  children?: React.ReactNode;
  title?: string;
  subtitle?: React.ReactNode | string;
  isFormValid: boolean;
  isLoading: boolean;
  onSubmit?: () => void;
  leftButtonLabel?: string;
  rightButtonLabel?: string;
  hiddenFooter?: boolean;
  useBackButton?: boolean;
  bodySx?: SxProps;
  wrapperSx?: SxProps;
  unsavedChanges?: boolean;
  closeIcon?: JSX.Element;
  customIcon?: boolean;
  customFooter?: React.ReactNode;
}

function SimpleFormDrawer(props: DrawerProps) {
  const {
    isOpen,
    id,
    icon,
    title,
    children,
    isLoading,
    isFormValid,
    subtitle,
    buttonId,
    leftButtonLabel = "Cancel",
    rightButtonLabel = "Save",
    hiddenFooter,
    useBackButton,
    onClose,
    onSubmit,
    unsavedChanges,
    closeIcon,
    customIcon = false,
    bodySx,
    wrapperSx,
    customFooter
  } = props;

  // Create a state and Listen to changes in props
  const [isOpenState, setIsOpenState] = useState<boolean>(isOpen);
  const [isLoadingState, setIsLoadingState] = useState<boolean>(isLoading);
  const [isFormValidState, setIsFormValidState] = useState<boolean>(isFormValid);
  const [isUnsavedChangesDialogOpen, setIsUnsavedChangesOpen] = useState(false);

  useEffect(() => {
    setIsOpenState(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setIsLoadingState(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setIsFormValidState(isFormValid);
  }, [isFormValid]);

  function handleFormClose() {
    if (unsavedChanges) {
      setIsUnsavedChangesOpen(true)
    } else {
      onClose();
    }
  }

  function handleUnsavedChangesConfirm() {
    onClose();
    setIsUnsavedChangesOpen(false);
  }

  return (
    <>
      <UnsavedChangesDialog
        isOpen={isUnsavedChangesDialogOpen}
        handleClose={() => setIsUnsavedChangesOpen(false)}
        handleConfirm={handleUnsavedChangesConfirm}
      />
      <MuiDrawer
        id={id}
        anchor="right"
        open={isOpenState}
        onClose={handleFormClose}
        ModalProps={{
          keepMounted: false
        }}
        style={{zIndex: 3}}
      >
        <DrawerFieldsWrapper sx={wrapperSx}>
          {!!closeIcon ? closeIcon : !useBackButton ? (
            <IconButton
              size="small"
              onClick={handleFormClose}
              sx={{alignSelf: "flex-end"}}
              disabled={isLoadingState}
            >
              <SystemIcons.Close
                id="drawer-close-head-button"
                stroke={theme.palette.neutral.dark}
              />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              onClick={handleFormClose}
              sx={{alignSelf: "flex-start"}}
              disabled={isLoadingState}
            >
              <SystemIcons.ArrowLeft
                id="drawer-back-head-button"
                stroke={theme.palette.neutral.dark}
              />
            </IconButton>
          )}
          {customIcon ? icon : <DrawerHeaderIcon>{icon}</DrawerHeaderIcon>}
          <Typography sx={{alignSelf: "center", mb: (subtitle ? 1 : 2), textAlign: "center"}} variant="h3">
            {title}
          </Typography>
          {
            subtitle && (
              <Typography sx={{alignSelf: "center", mb: 4}} color={theme.palette.neutral.dark} variant="body">
                {subtitle}
              </Typography>
            )
          }
          <OverFlowBox sx={{height: "100%", flex: 1, display: "flex", flexDirection: "column", ...bodySx}}>
            {children}
          </OverFlowBox>
        </DrawerFieldsWrapper>
        {!hiddenFooter && (
          <Box sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
            borderTop: 1,
            padding: 1,
            borderColor: theme.palette.divider,
            marginTop: 0
          }}>
            {customFooter ?
              customFooter
              :
              <>
                <Button
                  id="drawer-close-button"
                  onClick={handleFormClose}
                  sx={(theme) => ({
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.secondary,
                  })}
                  disabled={isLoadingState}
                >
                  <Typography variant="h4">{leftButtonLabel}</Typography>
                </Button>
                <LoadingButton
                  loading={isLoadingState}
                  onClick={onSubmit}
                  disabled={!isFormValidState || isLoadingState}
                  variant="contained"
                  id={buttonId}
                >
                  <Typography variant="h4">{rightButtonLabel}</Typography>
                </LoadingButton>
              </>
            }
          </Box>
        )}
      </MuiDrawer>
    </>
  )
}

export default SimpleFormDrawer;
