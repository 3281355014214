import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

interface Params {
  organizations?: string
  projects?: string;
  assets?: string
  milestones?: string
  tasks?: string
  "file-requirements"?: string
  "form-requirements"?: string
}

function useManualParams() {
  const [params, setParams] = useState<Params>({})

  const url = useLocation();

  //listen to url and update the params
  useEffect(() => {
    const pathname = url.pathname;
    const sections = pathname.split("/")
    const paramObject: any = {};

    for (let i = 0; i < sections.length; i += 2) {
      const key = sections[i]
      const value = sections[i + 1]

      if (key === "project-level-tasks") {
        i--;
        continue;
      }
      paramObject[key] = value
      if (key === "")
        paramObject.organizations = value
    }

    setParams(paramObject)
  }, [url])

  return params
}

export default useManualParams
