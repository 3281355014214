/**
 * Converts hex string to RGBA
 * @param {string} hex
 * @param {number} opacity
 * returns RGBA equivalent of the given hex
 */
export function hexToRGBA(hex: string, opacity: number) {
  let c: any;

  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join("")}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ","
    )},${opacity})`;
  }

  throw new Error("Bad Hex");
}