import {BaseProps} from "../BaseProps";
import {Box} from "@mui/system";
import {enReferencesButton, enReferencesLabel} from "constants/index";
import {PrimaryButton} from "components/Button";
import {PageTitle} from "components/index";
import {PermissionOperationKey} from "types/Permission";

interface HeadProps extends BaseProps {
  orgReferenceFilesAccess: any;
  onCreate: () => void;
  referenceFilesCount: number;
}

export default function Head(props: HeadProps) {
  const {orgReferenceFilesAccess, onCreate} = props;

  return (
    <Box sx={{display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: 1}}>
      <PageTitle title={enReferencesLabel.referenceFiles} count={props.referenceFilesCount} />
      <PrimaryButton
        id="create-new-form-button"
        label={enReferencesButton.createNewReference}
        onClickFcn={onCreate}
        disabledHoverLabel={enReferencesLabel.noPermission}
        disabled={Boolean(!orgReferenceFilesAccess?.[PermissionOperationKey.Create])}
      />
    </Box>
  )
}