import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography
} from "@mui/material";
import {enProfileAndSettingsLabel} from "constants/index";
import SystemIndex from "assets/icons/system/system.index";
import theme from "theme/theme";
import {Dispatch} from "react";

interface TermsOfServiceDialogProps {
  isDialogOpen: boolean;
  setIsDialogOpen: Dispatch<boolean>;
}

function TermsOfServiceDialog(props: TermsOfServiceDialogProps) {
  const {isDialogOpen, setIsDialogOpen} = props;

  return <Dialog open={isDialogOpen} scroll="paper" onClose={() => setIsDialogOpen(false)}>
    <DialogTitle>
      <Typography variant="h2">
        {enProfileAndSettingsLabel.termsOfService}
      </Typography>
      <IconButton
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
        onClick={() => setIsDialogOpen(false)}
      >
        <SystemIndex.SystemIcons.Close stroke={theme.palette.neutral.dark}/>
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <DialogContentText color="text.primary" sx={{marginBottom: 3}}>
        <Typography>
          {enProfileAndSettingsLabel.termsOfServiceDescription}
        </Typography>
        <br/>
        {"dummy data".repeat(1000)}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="secondary" sx={{marginRight: 1}} onClick={() => setIsDialogOpen(false)}>
        <Typography variant="h4" color="text.secondary">
          Close
        </Typography>
      </Button>
    </DialogActions>
  </Dialog>
}

export default TermsOfServiceDialog
