// @ts-nocheck
import {Asset, Project} from "types/index";
import {Entity, ProjectStatus} from "enums/index";
import {AccessType, PermissionOperationKey} from "types/Permission";
import React, {useEffect, useState} from "react";
import {StatusItem} from "types/StatusItem";
import {ProjectStatusWithIcons} from "assets/arrayList/ProjectStatus";
import {Box, Stack, styled, Typography} from "@mui/material";
import {CollapsableText, LongText, SelectStatus} from "components/index";
import {ChevronRight, Layers} from "@mui/icons-material";
import theme from "theme/theme";
import Coordinates from "./Coordinates";
import {enAssetLabel, enCommonLabel, enProjectLabel} from "constants/index";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import Statistics from "./Statistics";
import {db} from "../../firebase";
import {doc} from "firebase/firestore";
import TaskSearchAndFilter, {TaskSearchAndFilterProps} from "./TaskSearchAndFilter";
import CardBadge, {OverlayType} from "components/CardBadge";
import useImportBuild from "hooks/useImportBuild";

interface PageHeaderProps extends TaskSearchAndFilterProps {
  algoliaPath: string;
  uid: string;
  isMilestonesEmpty: boolean; // if milestones is empty
  parent: Asset | Project;
  parentAccess: AccessType | null;
  downloadAccess: AccessType | null;
  canCreateAccess: boolean;
  parentEntity: Entity;
  milestonesCollectionRef: string;
  canCreateMilestone: boolean;
}

function PageHeader(props: PageHeaderProps) {
  const {uid, assetDocPath, toastProps, parent, parentAccess, downloadAccess} = props;
  const [selectedStatus, setSelectedStatus] = useState<StatusItem | undefined>(undefined);
  const canUpdateStatus = Boolean(parentAccess?.[PermissionOperationKey.OverrideStatus]) || Boolean(parentAccess?.[PermissionOperationKey.ChangeStatus]);
  const {progress, isImport} = useImportBuild({path: assetDocPath});

  // set selected option
  useEffect(() => {
    if (!parent) return;

    if (props.parentEntity !== Entity.Asset) return;

    const {assetStatus = ProjectStatus.Pending} = parent as Asset;
    setSelectedStatus(ProjectStatusWithIcons[assetStatus]);
  }, [parent]);

  return (
    <Stack direction="column" display="flex" py={0} gap={0}>
      {props.parentEntity === Entity.Asset && (
        <Stack direction="column" gap={2}>
          <Stack
            direction={{xs: "column", sm: "column", md: "row", lg: "row"}}
            display="flex"
            gap={2}
            alignItems={{xs: "left", sm: "left", md: "center", lg: "start"}}
            justifyContent={{xs: "flex-start", sm: "flex-start", md: "space-between", lg: "space-between"}}
          >
            <Stack direction="column" sx={{gap: 1}}>
              <Stack
                direction="row"
                alignItems={{xs: "left", sm: "left", md: "center", lg: "center"}}
                sx={{gap: 2}}
              >
                <LongText variant="h1" overflowWrap="anywhere">{parent.name}</LongText>
                <AssetIconWrapper direction="row">
                  <Layers sx={{width: 16, height: 16, color: theme.palette.neutral.dark}}/>
                  <ChevronRight sx={{width: 16, height: 16, color: theme.palette.neutral.dark}}/>
                </AssetIconWrapper>
                <CardBadge
                  id={`asset-badge-${parent.id}`}
                  sx={{
                    borderTopLeftRadius: 4,
                    borderBottomRightRadius: 4,
                    position: "relative"
                  }}
                  text={isImport ? enAssetLabel.importingAssets : enAssetLabel.buildingAssets}
                  buildingProgressCount={progress}
                  toastProps={props.toastProps!}
                  process={isImport ? OverlayType.Importing: OverlayType.Building}
                />
              </Stack>
              <Stack direction="column">
                <CollapsableText gap={0} text={`ID: ${parent?.idCode}`} overflowWrap={"anywhere"}/>
                <Coordinates latitude={parent?.latitude} longitude={parent?.longitude}/>
                <CollapsableText gap={0} text={parent?.address || enCommonLabel.addressNotAvailable} overflowWrap={"anywhere"}/>
              </Stack>
            </Stack>
            <Stack
              direction={{xs: "row", sm: "row", md: "column", lg: "column"}}
              alignItems={{xs: "center", sm: "center", md: "unset", lg: "unset"}}
              mt={1}
            >
              <Typography variant="bodySmall" color={theme.palette.neutral.dark}>
                {enAssetLabel.projectAssetsStatus}
              </Typography>
              <SelectStatus
                toastProps={toastProps!}
                id="assetStatusMenu"
                documentRef={doc(db, assetDocPath)}
                statusField="assetStatus"
                items={ProjectStatusWithIcons}
                selectItemSx={{padding: 0.5, variant: "body1"}}
                selected={selectedStatus}
                isDisabled={!canUpdateStatus}
              />
            </Stack>
          </Stack>
          <DataTagsDisplay
            dataTagsIds={parent?.dataTagsIds || []}
            toastProps={toastProps!}
            uid={uid}
            canEditDocumentRef={Boolean(parentAccess?.[PermissionOperationKey.Update])}
            documentRef={doc(db, assetDocPath)}
          />
        </Stack>
      )}
      {props.parentEntity === Entity.Project && (
        <Stack direction="column" gap={2}>
          <Stack direction="row" display="flex" gap={2} alignItems="center">
            <Box flex={3}>
              <Stack direction="column" sx={{gap: 1}}>
                <Stack direction="row" alignItems="center" sx={{gap: 2}}>
                  <Typography variant="h1">
                    {enProjectLabel.projectLevelTasks}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" sx={{gap: 2}}>
                  <Typography variant="body" color={theme.palette.neutral.dark}>
                    {enProjectLabel.projectLevelTasksDescription}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      )}
      <Stack direction="row" display="flex" mb={1} paddingTop={2} paddingBottom={0} gap={2}>
        <Statistics
          parentEntity={props.parentEntity}
          uid={uid}
          toastProps={toastProps}
          canCreateAccess={props.canCreateAccess}
          downloadAccess={downloadAccess}
          milestonesCollectionRef={props.milestonesCollectionRef}
          parentDocPath={props.assetDocPath}
          canCreateMilestone={props.canCreateMilestone}
        />
      </Stack>
      {!props.isMilestonesEmpty && (<TaskSearchAndFilter {...props} />)}
    </Stack>
  )
}

const AssetIconWrapper = styled(Stack)({
  alignItems: "center",
  borderRadius: "4px",
  border: `1px solid ${theme.palette.background.swiftDefault}`,
  gap: 2,
  padding: "4px 6px",
});

export default PageHeader;