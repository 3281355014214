import {Box, Card, CardContent, Divider, Grid, Typography,} from "@mui/material";
import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {Project, User} from "types/index";
import {LongText} from "components/index";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {ProjectStatusList} from "assets/arrayList/ProjectStatus";
import {ProjectStatus, Severity} from "enums/index";
import {emptyFunction, enCommonLabel, enProjectLabel} from "constants/index";
import {useNavigate, useParams} from "react-router-dom";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import {BaseProps} from "screens/BaseProps";
import {getDocumentStatus, userPath} from "screens/utility";
import {useDocument} from "hooks/index";
import {collection, doc} from "firebase/firestore";
import CardBadge, {OverlayType} from "components/CardBadge";
import {db} from "../../firebase";

interface AssetProjectCardProps extends BaseProps {
  project: Project;
  projectsRefPath: string;
}

function AssetProjectCard(props: AssetProjectCardProps) {
  const {project, uid, toastProps, selectedOrg, projectsRefPath, setSelectedOrgId = emptyFunction} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;
  const [user] = useDocument<User>(userPath(uid));

  const navigate = useNavigate();
  const {orgId, assetId} = useParams();
  const [hovered, setHovered] = useState<boolean>(false);

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg?.id) setSelectedOrgId(orgId);
  }, []);

  //handle algolia misssing status
  const status = getDocumentStatus(project);

  const {
    name,
    description,
    "@id": projId = "fillerId",
    isPrivate,
    projectStatus = ProjectStatus.Pending,
    projectAssetTemplateId
  } = project;

  const isImporting = Boolean(project.importing) || Boolean(project.building);

  function navigateToOverview() {
    if (!user) return;

    if (!!projectAssetTemplateId) {
      setToastMessage("Project that uses a template are temporarily disabled.");
      setToastSeverity(Severity.Error);
      setIsToastOpen(true);
      return;
    }

    navigate(`/${orgId}/projects/${projId}/assets/${assetId}`)
  }

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={4}
      xl={3}
      justifyContent="center"
      alignItems="center"
      paddingTop={5}
      paddingRight={1}
      flexShrink={0}
    >
      <Card
        key={projId + "-options"}
        id={"project-card-" + projId}
        className="proj-card"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          position: "relative",
          overflow: "inherit",
          border: `1px solid ${hovered ? theme.palette.primary.main : "transparent"
          }`,
          height: "100%",
          cursor: "pointer",
          opacity: status !== "Finished" ? 0.35 : 1,
          ...(isImporting ? {borderColor: theme.palette.warning.main}: {})
        })}
      >
        <CardBadge
          id={`project-badge-${projId}`}
          text={!!projectAssetTemplateId ? "Temporarily disabled" : project.building ? enProjectLabel.buildingProjectData : enProjectLabel.importingProjectData}
          buildingProgressCount={project.buildingProgressCount ?? 0}
          toastProps={toastProps!}
          process={project.building ? OverlayType.Building : OverlayType.Importing}
          disabled={!!projectAssetTemplateId}
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            paddingTop: 2,
            flex: 1,
          }}
        >
          <StyledHeaderTypography
            className="org-name"
            gutterBottom
            variant="h3"
            id={`project-name-${projId}`}
            onClick={navigateToOverview}
            title={name}
          >
            {name}
          </StyledHeaderTypography>
          <LongText
            variant="body"
            color="text.secondary"
            id={`project-desc-${projId}`}
            onClick={navigateToOverview}
          >
            {!!description ? description : enCommonLabel.noDescription}
          </LongText>
          <DataTagsDisplay
            uid={uid!}
            dataTagsIds={project.dataTagsIds || []}
            toastProps={toastProps!}
            listSize="short"
            projId={projId}
            canEditDocumentRef={true}
            documentRef={doc(collection(db, projectsRefPath), projId)}
          />
        </CardContent>
        <Divider/>
        <Box
          display="flex"
          p={1}
          gap={1}
          alignItems="center"
          justifyContent="space-between"
          onClick={navigateToOverview}
        >
          <Box sx={{display: "flex", gap: 2, alignItems: "center"}}>
            {isPrivate && (
              <Box
                id={`project-private-icon-${projId}`}
                sx={{display: "flex", alignItems: "center", gap: 1}}
                title={enProjectLabel.private}
              >
                <SystemIcons.LockFilled
                  stroke={theme.palette.neutral.dark}
                  fill={theme.palette.neutral.dark}
                  height={16}
                  width={16}
                />
              </Box>
            )}
          </Box>
          <Box sx={{display: "flex", gap: 1, alignItems: "center"}}>
            {ProjectStatusList[projectStatus].icon({fill: ProjectStatusList[projectStatus].color})}
            <Typography variant="h5" id={`project-status-${projId}`}>
              {ProjectStatusList[projectStatus].title}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
}

/** start of styles */
const StyledHeaderTypography = styled(Typography)({
  paddingRight: 5,
  display: "-webkit-box",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitBoxOrient: "vertical",
  overflowWrap: "break-word"
});
/** end of styles */

export default AssetProjectCard;
