import {StatusItem} from "types/StatusItem";
import StatusObjects from "assets/arrayList/StatusObjectList/StatusObjects";
import {milestoneTaskandAssetStatus as TaskStatus} from "enums/milestoneTaskandAssetStatus";

const MilestoneTaskStatusObjectList: Record<string, StatusItem> = {
  [TaskStatus.Cancelled]: StatusObjects.cancelled,
  [TaskStatus.Pending]: StatusObjects.pending,
  [TaskStatus.InProgress]: StatusObjects.inProgress,
  [TaskStatus.Submitted]: StatusObjects.submitted,
  [TaskStatus.Rejected]: StatusObjects.rejected,
  [TaskStatus.HasRejection]: StatusObjects.hasRejection,
  [TaskStatus.Approved]: StatusObjects.approved,
}

export default MilestoneTaskStatusObjectList