import {ToastProps} from "../../BaseProps";
import {OverdueItemType} from "types/ActivityFeedTypes";
import BaseItem from "./BaseItem";
import theme from "theme/theme";
import SystemIndex from "assets/icons/system/system.index";
import IconBaseProps from "./IconBaseProps";
import {enActivityFeedLabel} from "constants/index";
import {useDocument} from "hooks/index";
import {doc} from "firebase/firestore";
import {userActivityFeedPath} from "../../utility";
import {SkeletonItem} from "components/index";

interface OverdueItemProps extends ToastProps {
  itemId: string;
  uid: string;
  index: number;
  updatePinnedValue: (index: number, pinned: boolean) => void;
}

function OverdueItem(props: OverdueItemProps) {
  const {toastProps, itemId, uid, updatePinnedValue, index} = props;

  const [activityFeed] = useDocument<OverdueItemType>(doc(userActivityFeedPath(uid), itemId));

  if (!activityFeed) {
    return <SkeletonItem height={"5rem"} sx={{marginTop: "5px", width: "80%"}}/>
  }

  return <BaseItem
    index={index}
    uid={uid}
    item={activityFeed}
    icon={<SystemIndex.SystemIcons.Calendar stroke={theme.palette.error.main} {...IconBaseProps}/>}
    text={enActivityFeedLabel.overdue}
    toastProps={toastProps}
    updatePinnedValue={updatePinnedValue}
  />
}

export default OverdueItem
