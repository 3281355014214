/* eslint-disable react-hooks/exhaustive-deps */
import Header from "./Header";
import {BaseProps} from "../BaseProps";
import Statistics from "./Statistics";
import {useOutletContext, useParams} from "react-router-dom";
import {
  fileRequirementPath,
  generateEntityBreadcrumbs,
  getDocPath,
  projectLevelTasksFileRequirementPath
} from "../utility";
import {useRTDBDocField} from "hooks/index";
import {emptyFunction, enCommonLabel, enTaskLabel} from "constants/index";
import {Content, InProgress} from "components/index";
import {Stack} from "@mui/material";
import FilesView from "./FilesView";
import PageHead from "./Head"
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {EntityChat, FileRequirement, Task} from "types/index";
import {collection, Timestamp} from "firebase/firestore";
import {
  ActionType,
  CounterFields,
  Entity,
  RequirementStatus as Status,
  Severity
} from "enums/index";
import {useNavigate} from "react-router";
import {db} from "../../firebase";
import {PermissionEntity} from "types/Permission";
import LazyCarousel from "components/LazyCarousel";
import {UploadFile} from "hooks/useFileUpload/useFileUpload";
import {generateUniqueId} from "hooks/useFileUpload/reducer/reducer";
import {BreadCrumbType} from "../utility/generateEntityBreadcrumbs";
import {UploadContext} from "hooks/useFileUpload/context/UploadContext";
import useTemplateDocument from "hooks/useTemplateDocument";
import useTemplateAccess from "hooks/useTemplateAccess";
import templateSubmitForm from "../utility/templateSubmitForm";
import useGetProjectBasedPath from "hooks/useGetProjectBasedPath";
import {statusSubmitHandler} from "../utility/statusSubmitHandler";
import {OutletContextType} from "components/RestrictedPage";

interface FileRequirementOverviewProps extends BaseProps {
  addFiles: (files: UploadFile[]) => void;
}

export default function FileRequirementOverview(props: FileRequirementOverviewProps) {
  const {toastProps, selectedOrg, uid, addFiles} = props;
  const {isLoading} = useOutletContext<OutletContextType>();
  const {setSelectedOrgId = emptyFunction} = props;
  const {setIsToastOpen, setToastSeverity, setToastMessage} = toastProps!;

  const navigate = useNavigate();
  const {orgId, projId, assetId, milestoneId, taskId, fileRequirementId} = useParams();

  const documentRef = assetId! ? fileRequirementPath(orgId!, projId!, assetId!, milestoneId!, taskId!, fileRequirementId!)
    : projectLevelTasksFileRequirementPath(orgId!, projId!, milestoneId!, taskId!, fileRequirementId!);
  const filesCollectionRefPath = collection(documentRef, "files");

  const [fileRequirement, , actualTemplateDocRef] = useTemplateDocument<FileRequirement | null>(documentRef);
  const projectsBasedPath = useGetProjectBasedPath(documentRef.path);

  const docFilesCount = useRTDBDocField<number>(getDocPath(uid, documentRef.path), CounterFields.FilesCount) || 0;

  const [task] = useTemplateDocument<Task | null>(documentRef.parent.parent);
  const [statusFilter, setStatusFilter] = useState<string>("All");

  const context = useContext(UploadContext);
  const {processingFiles, deletedIds} = context!.fileUploadState;
  const processingFilesList = processingFiles.filter(file =>
    file.parentCollectionPath.startsWith(filesCollectionRefPath.path) // filter based on the initial collection path
      && !deletedIds.has(file.id) // filter out deleted files
        && !file.parentCollectionPath.split("/").includes("chatMessages") // filter out files from chatMessages collection
  );

  //Carousel related states
  const [activeCarouselIndex, setActiveCarouselIndex] = useState<number | null>(null);

  // file upload related states
  const fileInputRef = useRef<HTMLInputElement>(null);

  // state for entityBreadCrumbs
  const [entityBreadCrumbs, setEntityBreadCrumbs] = useState<BreadCrumbType[]>([]);

  const [fileAccess] = useTemplateAccess({
    uid: uid!,
    entity: Entity.Task,
    documentDocId: assetId! ? PermissionEntity.SwiftFile : PermissionEntity.PLFile,
    accessListRef: collection(documentRef, "accessList"),
  });

  const [requirementAccess] = useTemplateAccess({
    uid: uid!,
    entity: Entity.Task,
    documentDocId: assetId! ? PermissionEntity.FileRequirement : PermissionEntity.PLFileRequirement,
    accessListRef: collection(documentRef, "accessList"),
  });

  const [downloadAccess] = useTemplateAccess({
    uid: uid!,
    entity: Entity.Task,
    documentDocId: PermissionEntity.FileRequirementFilesDownload,
    accessListRef: collection(documentRef, "accessList")
  });

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  // once fileRequirement is set, set entityBreadCrumbs
  useMemo(() => {
    if (!fileRequirement) return;

    const breadCrumbs = generateEntityBreadcrumbs({
      ...fileRequirement,
      fileRequirementName: fileRequirement.name,
      entity: !!assetId ? Entity.FileRequirement : Entity.PLFileRequirement,
      entityRefPath: documentRef.path
    } as unknown as EntityChat);

    setEntityBreadCrumbs(breadCrumbs);
  }, [fileRequirement]);

  if (!fileRequirement || isLoading)
    return <Content><InProgress/></Content>;

  async function onFileUploadInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    if (e.target.files && e.target.files[0]!) {
      await handleFileUpload(e.target.files);
      fileInputRef!.current!.value = "";
    }
  }

  async function handleFileUpload(files: FileList | File[], overwrite: boolean = false) {
    if (!canUploadFile(files.length ?? 0)) return;

    const collectionPath = filesCollectionRefPath.path;

    let newFiles: UploadFile[] = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (!file) continue;

      newFiles.push({
        file,
        overwrite,
        queueId: generateUniqueId() + index,
        parentCollectionPath: collection(db, collectionPath).path,
        timeCreated: Timestamp.fromDate(new Date()),
        breadCrumbs: entityBreadCrumbs,
      })
    }

    const parentCreated = await createdParentDoc();
    parentCreated && await addFiles(newFiles);
  }

  function canUploadFile(filesCount: number): boolean {
    if (fileRequirement!.fileRequirementStatus === Status.Approved) {
      showMessage(enTaskLabel.alreadyApprovedError, Severity.Error);
      return false;
    }

    // check maximum files
    const maximumFiles = fileRequirement!.maximumFiles;
    if (!maximumFiles) return true;

    const currentFilesCount = processingFilesList.length + (docFilesCount);
    const availableFiles = maximumFiles - currentFilesCount;
    if (availableFiles < filesCount) {
      showMessage(enTaskLabel.maximumFilesError, Severity.Error);
      return false;
    }

    return true;
  }

  function showMessage(message: string, severity: Severity) {
    setToastMessage(message);
    setToastSeverity(severity);
    setIsToastOpen(true);
  }

  function onFileUploadButtonClick(e: React.MouseEvent) {
    e.stopPropagation();
    if (canUploadFile(0)) { // 0 because we are not uploading any file here
      fileInputRef!.current!.click();
    }
  }

  function onFilePreviewClick(index: number) {
    setActiveCarouselIndex(index);
  }

  async function addFilesToUpload(files: UploadFile[]) {
    const parentCreated = await createdParentDoc();
    if (parentCreated) {
      await addFiles(
        files.map(file => ({
          ...file,
          breadCrumbs: entityBreadCrumbs,
        }))
      );
    }
  }

  async function createdParentDoc() {
    // if no reference
    if (!actualTemplateDocRef)
      return Promise.resolve(true);

    if (!projectsBasedPath)
      return Promise.resolve(true);

    // if it's not from templates
    if (!actualTemplateDocRef?.path.split("/").includes("templates"))
      return Promise.resolve(true);

    showMessage(enCommonLabel.preparingParentDoc, Severity.Info);

    // if it's from templates
    return await templateSubmitForm(
      projectsBasedPath!,
      ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler({
        status, data, isLastUpdate,
        successCallback: () => {
          Promise.resolve(true)
        },
        errorCallback: () => {
          showMessage(enCommonLabel.errorCreatingParentDoc, Severity.Error);
          throw new Error("Error in creating parent doc");
        }
      }),
      {name: fileRequirement?.name},
    ).then(() => {
      return Promise.resolve(true)
    }).catch(() => {
      return Promise.resolve(false);
    })
  }

  if (task === undefined) return null;

  if (!!task?.templateTaskId) return <Content>Temporarily Disabled {task?.templateTaskId}</Content>;

  return (<>
      {activeCarouselIndex !== null && <LazyCarousel
        toggleAttachmentCarousel={() => setActiveCarouselIndex(null)}
        clickedIndex={activeCarouselIndex}
        filesColRef={filesCollectionRefPath}
      />}

      <Header
        downloadAccess={downloadAccess}
        requirementAccess={requirementAccess}
        documentRef={documentRef.path}
        {...props}
      />
      <Content>
        <Stack sx={{maxWidth: "90vw"}}>
          <Stack spacing={3} direction="column">
            <PageHead
              uid={uid}
              requirementAccess={requirementAccess}
              toastProps={toastProps!}
              fileRequirement={fileRequirement}
              statusFilter={statusFilter}
              documentRef={documentRef}
              setStatusFilter={(status: string) => setStatusFilter(status)}
            />
            <Statistics
              toastProps={toastProps!}
              uid={uid!}
              filesCount={docFilesCount}
              downloadAccess={downloadAccess}
              fileAccess={fileAccess}
              fileRequirement={fileRequirement}
              triggerFileUpload={onFileUploadButtonClick}
              setCarouselItems={emptyFunction}
              setIsCarouselItemsLoading={emptyFunction}
              onFilePreviewClick={emptyFunction}
            />
            <input
              id="file"
              type="file"
              ref={fileInputRef}
              style={{display: "none"}}
              onChange={onFileUploadInputChange}
              multiple={true}
            />
          </Stack>
          <Stack flex={1}>
            <FilesView
              uid={uid!}
              fileAccess={fileAccess}
              fileRequirement={fileRequirement}
              docFilesCount={docFilesCount}
              task={task}
              statusFilter={statusFilter}
              toastProps={toastProps}
              collectionRef={filesCollectionRefPath}
              handleStatusChange={(newStatus) => setStatusFilter(newStatus)}
              onFilePreviewClick={onFilePreviewClick}
              processingFiles={processingFilesList}
              addFiles={addFilesToUpload}
              deletedIds={deletedIds}
            />
          </Stack>
        </Stack>
      </Content>
    </>
  )
}
