import {SwiftFile} from "types/index";
import {enCommonButton} from "constants/index";
import {Box, Stack} from "@mui/material";
import theme from "theme/theme";
import {LongText} from "components/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {CollectionReference, doc} from "firebase/firestore";
import React, {useEffect, useState} from "react";
import {BaseProps} from "../../../BaseProps";
import {ViewStatus} from "enums/index";
import useDocument from "hooks/useDocument";
import {SecondaryButton} from "components/Button";
import {formatDateTimeFirestoreDate, getDocumentStatus} from "screens/utility";
import {displayFileName} from "./utils";

interface RejectedFileCardProps extends BaseProps {
  collectionRef: CollectionReference;
  enabled: boolean;
  file: SwiftFile;
  previewBlock: JSX.Element;
  reUploadFile: (file: File, targetRef: string) => void;
}

export default function RejectedFileCard(props: RejectedFileCardProps) {
  const {file, previewBlock, collectionRef, enabled, reUploadFile} = props;
  const [fileDocument, setFileDocListener] = useDocument<SwiftFile>(null);
  const [isLoading, setIsLoading] = useState(false);

  const {shortDate, simpleTime} = formatDateTimeFirestoreDate(file.timeCreated)

  useEffect(() => {
    const tempInput = (document.getElementById("tempFileInput"));
    if (!tempInput) return;
    document.removeChild(tempInput);
  }, [file.previewPath]);

  useEffect(() => {
    if (!fileDocument) return;
    const status = getDocumentStatus(fileDocument);

    setIsLoading([ViewStatus.Finished, ViewStatus.SecurityError, ViewStatus.ValidationError, ViewStatus.Error].includes(status));
  }, [fileDocument]);

  async function onFileReUploadButtonClick() {
    if(!enabled) return;

    const docReference = doc(collectionRef, file["@id"]!);
    const input = (document.getElementById("tempFileInput")
      ?? document.createElement('input')) as HTMLInputElement;
    input.id = "tempFileInput";
    input.type = 'file';
    input.multiple = false;
    input.onchange = async (e: any) => {
      const newFile: File | undefined = e.target.files[0];
      if (!newFile) return;
      reUploadFile(newFile, docReference.id);
      setFileDocListener(docReference);
    }
    input.click();
  }

  return (
    <Box
      maxWidth="296px"
      minHeight="298px"
      position="relative"
      sx={{
        borderRadius: "4px",
        border: `1px solid ${theme.palette.error.main}`,
        overflow: "hidden"
      }}
      className="file-list-item"
      id={`file-list-item-${file["@id"]}`}
    >
      <Stack spacing={0}>
        {previewBlock}
        <Box
          sx={{padding: "4px 1rem", zIndex: 1}}
        >
          <LongText
            variant="body"
            textColor={theme.palette.neutral.dark}
            maxLines={1}
            tooltipText={`${file.name} - ${shortDate} ${simpleTime}`}
            id={`file-list-item-name-${file["@id"]}`}
          >
            {displayFileName(file.name)} - {shortDate} {simpleTime}
          </LongText>
        </Box>

        <Box flex={1}></Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} px={2} pb={1}>
          <SecondaryButton
            onClickFcn={onFileReUploadButtonClick}
            disabled={!enabled}
            startIcon={(props) => <SystemIcons.Retake stroke={enabled ? theme.palette.primary.main : theme.palette.neutral.medium} strokeWidth={4} {...props} />}
            label={enCommonButton.reupload}
            id={`file-list-item-reupload-button-${file["@id"]}`}
            loading={isLoading}
            sx={{width: "100%"}}
          />
        </Stack>
      </Stack>
    </Box>
  );
}
