import theme from "theme/theme";
import {Box, Stack, Typography} from "@mui/material";
import {LongText} from "components/index";
import BreadcrumbsDisplay from "components/BreadcrumbsDisplay";
import TimestampTypo from "components/Typography/TimestampTypo";
import {ProcessingFile} from "hooks/useFileUpload/useFileUpload";
import {spinTransform} from "constants/spinTransform";
import {SystemIcons} from "assets/icons/system/system.index";
import IconBaseProps from "./IconBaseProps";
import React from "react";
import {enActivityFeedLabel} from "constants/index";
import {formatDateTimeFirestoreDate} from "../../utility";

interface UploadingItemProps {
  item: ProcessingFile;
}
function UploadingItem(props: UploadingItemProps) {
  const {item} = props;
  const {date, time} = formatDateTimeFirestoreDate(item.timeCreated);

  return (
    <Stack
      sx={{
        flexDirection: {sm: "column", md: "row"},
        border: `1px solid ${theme.palette.divider}`,
        padding: 1,
        paddingX: 3,
        pr: 0,
        borderRadius: 1,
        position: "relative",
        marginY: 1,
        marginRight: "20%",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: theme.spacing(1),
          left: theme.spacing(1),
        }}
      >
        <Box sx={{animation: `${spinTransform} 1s infinite linear`}}>
          <SystemIcons.LoaderOnWhite {...IconBaseProps}/>
        </Box>
      </Box>
      <Stack flex={1} mx={2} gap={0.5}>
        <Stack
          direction={{xs: "column", md: "row"}}
          alignItems={{xs: "flex-start", md: "center"}}
          gap={1}
        >
          File
          <LongText maxLines={1}>
            <Typography color={theme.palette.secondary.main}>
              <strong>{item.file.name}</strong>
            </Typography>
          </LongText>
        </Stack>
        {item.breadCrumbs && item.breadCrumbs.length > 0 && <BreadcrumbsDisplay entityBreadcrumbs={item.breadCrumbs}/>}
        <LongText maxLines={1} variant="bodySmall" color="text.secondary">{enActivityFeedLabel.uploading}</LongText>
        <TimestampTypo>{`${date} ${time}`}</TimestampTypo>
      </Stack>
    </Stack>
  )
}

export default UploadingItem;