import {Stack, Switch, SxProps, Typography} from "@mui/material";
import {enCommonLabel} from "constants/index";

interface CheckboxSwitchProps {
  isCheckboxShown: boolean;
  toggleCheckbox: (shown: boolean) => void;
  sx?: SxProps
}

function CheckboxSwitch(props: CheckboxSwitchProps) {
  const {isCheckboxShown, toggleCheckbox, sx} = props;

  return (
    <Stack direction="row" sx={sx}>
      <Switch
        checked={isCheckboxShown || false}
        onChange={() => toggleCheckbox(!isCheckboxShown)}
        size="small"
      />
      <Typography variant="h5">
        {isCheckboxShown ? enCommonLabel.hideCheckbox : enCommonLabel.showCheckBox}
      </Typography>
    </Stack>
  )
}

export default CheckboxSwitch;