import {Button, Divider, Popover, Stack, Typography} from "@mui/material";
import Input from "components/Input";
import React, {useEffect, useState} from "react";
import theme from "theme/theme";
import {en} from "language/en";
import {getKeyValue, onChangeInput, submitForm} from "screens/utility";
import {DataTag} from "types/DataTag";
import {doc} from "firebase/firestore";
import {organizationDataTagsPath} from "screens/utility/FirebasePath";
import {useParams} from "react-router-dom";
import {ActionType, ProcessType} from "enums/index";
import {LoadingButton} from "@mui/lab";
import {enCommonLabel} from "constants/index";
import AutocompleteInput from "components/DataTag/DataTagInput/AutocompleteInput";
import {ErrorMessageType, statusSubmitHandler} from "screens/utility/statusSubmitHandler";
import ColorSelectorButton from "components/DataTag/DataTagInput/ColorSelectorButton";
import {datatagToneColor} from "enums/datatagToneColor";

interface CreateDataTagPopupProps {
  isOpen: boolean;
  anchorEl?: Element;
  onClose: () => void;
  addTag: (tag: { id: string, label: string }) => void;
  defaultName?: string;
  uid: string;
}

function CreateDataTagPopup(props: CreateDataTagPopupProps) {
  const {isOpen, anchorEl, onClose, addTag, defaultName = "", uid} = props
  const {orgId} = useParams();

  const [name, setName] = useState(defaultName);
  const [description, setDescription] = useState("");
  const [docRef, setDocRef] = useState(doc(organizationDataTagsPath(orgId!)))
  const [isLoading, setIsLoading] = useState(false);
  const [documentError, setDocumentError] = useState<string | undefined>(undefined);
  const [color, setColor] = useState<datatagToneColor>(datatagToneColor.tone_0_0);

  // Clears name on close
  useEffect(() => {
    if (!isOpen) setName("");
  }, [isOpen]);

  // check when default name loads
  useEffect(() => {
    if (defaultName !== "") setName(defaultName)
  }, [defaultName])

  //reset document errors on change
  useEffect(() => {
    setDocumentError(undefined)
  }, [name])

  //reset state after exiting popup
  useEffect(() => {
    setDescription("")
    setDocumentError(undefined)
    setIsLoading(false)

    const newDoc = doc(organizationDataTagsPath(orgId!))
    setDocRef(newDoc);
  }, [isOpen])

  async function submitCreateDataTag(e: any) {
    e.preventDefault();
    e.stopPropagation();

    setIsLoading(true);

    const formData = {name, description, color};

    await submitForm(docRef, ActionType.Create,
      (status, data, isLastUpdate) => statusSubmitHandler<DataTag>({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback
      }),
      formData
    );
  }

  function successCallback() {
    setIsLoading(false);
    addTag({id: docRef.id!, label: name});
    onClose();
    setName("")
    setDescription("");

    const newDoc = doc(organizationDataTagsPath(orgId!));
    setDocRef(newDoc);
  }

  function errorCallback(message: ErrorMessageType) {
    setIsLoading(false);
    const errorMessage = typeof (message) === "object" ? getKeyValue(message, "name", undefined) : enCommonLabel.errorProcess(ProcessType.Create);
    setDocumentError(errorMessage);
  }

  const isFormValid = name.trim() !== "" && !documentError;

  function onKeyPress(e: React.KeyboardEvent) {
    if (e.key === "Enter" && !e.shiftKey && isFormValid && !isLoading) {
      e.preventDefault();
      submitCreateDataTag(e)
    }
  }

  return <Popover
    open={isOpen}
    anchorEl={anchorEl}
    onClose={onClose}
    PaperProps={{
      style: {
        width: "310px",//anchorEl?.clientWidth,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        transform: `translateY(22px)`,
        border: `2px solid ${theme.palette.neutral.medium}`
      },
    }}
    onClick={(e) => e.stopPropagation()}
  >
    <Stack direction="row" justifyContent="space-between">
      <AutocompleteInput
        uid={uid}
        setName={setName}
        defaultName={name}
        onKeyPress={onKeyPress}
        sx={{
          width: "100%",
          "& .MuiAutocomplete-option": {
            color: "red!",
          },
          "& .MuiOutlinedInput-root": {
            py: 1 / 2,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }
        }}
      />
      <ColorSelectorButton setColor={setColor}/>
    </Stack>

    <Typography color="text.secondary" sx={{marginLeft: 2}}>
      {name.length}/32
    </Typography>
    {
      documentError ?
        <>
          <Divider
            sx={{
              borderColor: theme.palette.error.main
            }}
          />
          <Typography color="error.main" marginLeft={2} marginY={1}>
            {documentError}
          </Typography>
        </> :
        <Divider/>
    }
    <Input
      onChange={(e) => onChangeInput(e, setDescription)}
      placeholder={en.common.label.description}
      multiline
      minRows={5}
      textfieldSx={{
        "& fieldset": {
          borderRadius: 0,
          border: 0,
        },
        '&:hover fieldset': {
          borderColor: theme.palette.neutral.medium,
        },
      }}
      InputProps={{
        maxLength: 128
      }}
      onKeyPress={onKeyPress}
    />
    <Stack
      direction="row"
      sx={{
        padding: "9px 16px",
        borderRadius: 1,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderTop: `1px solid ${theme.palette.neutral.medium}`
      }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography color="text.secondary">
        {description.length}/128
      </Typography>
      <Stack direction="row" gap={1}>
        <Button
          disabled={isLoading}
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.secondary,
          })}
          onClick={onClose}
        >
          {en.common.button.cancel}
        </Button>
        <LoadingButton
          loading={isLoading}
          disabled={!isFormValid}
          onClick={submitCreateDataTag}
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.neutral.medium}`,
          })}
        >
          {en.common.button.create}
        </LoadingButton>
      </Stack>
    </Stack>
  </Popover>
}

export default CreateDataTagPopup;