import {BaseProps} from "screens/BaseProps";
import {FormRequirement} from "types/FormRequirement";
import {Stack, Typography} from "@mui/material";
import {CollapsableText, LongText, SelectStatus} from "components/index";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import theme from "theme/theme";
import {enFileRequirementLabel} from "constants/index";
import {RequirementStatus} from "enums/requirementStatus";
import StatusSubText from "./StatusSubText";
import React, {Dispatch} from "react";
import getStatusOptions from "screens/utility/getStatusOptions";
import RequirementStatusObjectList
  from "assets/arrayList/StatusObjectList/RequirementStatusObjectList";
import StatusButton from "./StatusButton";
import {DocumentReference} from "firebase/firestore";
import {AccessType, PermissionOperationKey} from "types/Permission";

interface PageHeadProps extends BaseProps {
  access: AccessType | null;
  formRequirement: FormRequirement;
  documentRef: DocumentReference;
  updateStatus: (status: RequirementStatus, setLoading: Dispatch<boolean>) => void;
  uid: string;
}

function PageHead(props: PageHeadProps) {
  const {formRequirement, access, toastProps, documentRef, uid, updateStatus} = props;
  const {name, description, formRequirementStatus} = formRequirement;

  const [options] = getStatusOptions(RequirementStatusObjectList, formRequirementStatus);
  const selectedOption = options[formRequirementStatus];
  const canChangeStatus = Boolean(access?.[PermissionOperationKey.OverrideStatus]) || Boolean(access?.[PermissionOperationKey.ChangeStatus]);

  return (
    <>
      <Stack direction="column" gap={2} mb={2}>
        <Stack
          width={1}
          direction="row"
          justifyContent="space-between"
          sx={{
            flexWrap: {
              xs: "wrap",
              md: "noWrap"
            }
          }}
        >
          <Stack direction="column" gap={2} flexGrow={1}>
            <Stack direction="column">
              <LongText variant="h1" id="task-name" width="100%" overflowWrap={"anywhere"}>
                {name ?? ""}
              </LongText>
              <CollapsableText text={description} id="task-description" overflowWrap={"anywhere"}/>
            </Stack>
            <DataTagsDisplay
              uid={uid}
              dataTagsIds={formRequirement?.dataTagsIds || []}
              toastProps={toastProps!}
              documentRef={documentRef}
              canEditDocumentRef={Boolean(access?.[PermissionOperationKey.Update])}
            />
          </Stack>
          <Stack direction="column" alignItems="start" justifyContent="start">
            <Typography variant="body" color={theme.palette.neutral.dark}>
              {enFileRequirementLabel.requirementStatus}
            </Typography>
            <Stack direction="column" spacing={0.5}>
              <Stack direction="row" spacing={2}>
                <Stack direction="column">
                  <SelectStatus
                    toastProps={toastProps!}
                    displayIconOnSelected={true}
                    statusField="formRequirementStatus"
                    documentRef={documentRef}
                    items={options}
                    selected={selectedOption}
                    isDisabled={!canChangeStatus}
                    width="180px"
                    disabledStatuses={[RequirementStatus.HasRejection]}
                  />
                </Stack>
                <StatusButton
                  isDisabled={!canChangeStatus}
                  selectedStatus={selectedOption}
                  updateStatus={updateStatus}
                />
              </Stack>
              <StatusSubText
                selectedStatus={selectedOption}
                formRequirement={formRequirement}
                uid={uid}
                docRef={documentRef.path}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default PageHead;
