import {FormFieldProps} from "./FormFieldProps";
import {Button, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {formatDateTimeFirestoreDate} from "../../utility";
import {enCommonButton} from "constants/index";
import {CollapsableText, Input, LongText} from "components/index";
import React, {useEffect, useState} from "react";
import DueDatePopover from "components/DueDatePopover";
import UnEditedBaseField from "./UnEditedBaseField";
import RequiredResponse from "../Objects/RequiredResponse";
import {FormField} from "types/FormField";
import {Timestamp} from "firebase/firestore";
import {isFormFieldValid} from "../utils";
import {FormFieldType} from "enums/formFieldType";
import TimeSelector from "components/TimeSelector";

interface DateFormFieldProps extends FormFieldProps {
  field: FormField<FormFieldType.Date>
  formField: FormField<FormFieldType.Date>;
}

function DateFormField(props: DateFormFieldProps) {
  const {isEditMode, field, index, formField, updateLocalField} = props;
  const {title, description, value = {date: null, time: null}, responseIsRequired, includeTime, disablePreviousDates = false} = field;

  const fieldId = `date-field-${index}`;

  const [date, setDate] = useState<Date | null>(null);
  const [dateString, setDateString] = useState<string | null>(null);
  const [time, setTime] = useState<string | null>(null);
  const [isDatePopoverOpen, setIsDatePopoverOpen] = useState<boolean>(false);
  const [isClearShown, setIsClearShown] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const newDate = new Date();

  useEffect(() => {
    setErrorMessage("");
  }, [isEditMode]);

  useEffect(() => {
    const {date = null, time = null} = value;
    if(date !== null) {
      const {fireBaseTime} = formatDateTimeFirestoreDate(date);
      setDate(fireBaseTime);
    } else {
      setDate(date);
    }
    setTime(time);
  }, [value]);

  useEffect(() => {
    setDateString(date !== null ? date.toLocaleDateString() : date);
    if(date === null) return;

    // @ts-ignore
    const [, message] = isFormFieldValid({...field, value: {date, time}});
    setErrorMessage(message);
  }, [date]);

  useEffect(() => {
    const {date: initialDate = null, time: initialTime = null} = formField.value ?? {};

    setIsClearShown(date !== initialDate || time !== initialTime);
  }, [date, time]);

  if (!isEditMode) {
    return (
      <UnEditedBaseField
        fieldId={fieldId}
        defaultValue={"--/--/--"}
        {...field}
      />
    )
  }

  function changeDate(date: Date | null) {
    updateLocalField({date: Timestamp.fromDate(date ?? newDate), time}, index);
  }

  function changeTime(newValue: string | null | undefined) {
    if (!date) changeDate(newDate);
    updateLocalField({date: Timestamp.fromDate(date ?? newDate), time: newValue}, index);
  }

  function clearFields() {
    updateLocalField({date: null, time: null}, index);
  }

  return (
    <Stack direction="row" paddingLeft={1} id={fieldId} gap={1}>
      <Stack direction="column">
        <Stack direction="row">
          <Typography color={theme.palette.secondary.main} variant="h5">
            {title}
          </Typography>
          <RequiredResponse isRequired={responseIsRequired}/>
          {isClearShown && (
            <Button
              id="due-date-clear-button"
              onClick={clearFields}
              sx={{padding: 0, marginTop: -0.5}}
            >
              {enCommonButton.clear}
            </Button>
          )}
        </Stack>
        <Stack direction="row">
          {description ? (
              <CollapsableText text={description} id="form-field-description" overflowWrap={"anywhere"}/>
          ) : (
              <></>
          )}
        </Stack>
        <Stack direction="row" gap={1}>
          <Input
            id={`${fieldId}-input-date`}
            onClick={() => setIsDatePopoverOpen(true)}
            onChange={() => {
            }}
            value={dateString ?? ""}
            optional={true}
            validationMessage={errorMessage}
            placeholder={title}
            textfieldSx={{width: "150px", backgroundColor: theme.palette.background.paper}}
            
          />
          {includeTime && (
            <TimeSelector
              time={time}
              onTimeChange={changeTime}
              sx={{
                width: 150,
                background: theme.palette.background.paper
              }}
            />
          )}
        </Stack>
      </Stack>
      {isDatePopoverOpen && (
        <DueDatePopover
          isOpen={isDatePopoverOpen}
          selectedDate={date}
          anchorElement={document.getElementById(`${fieldId}-input-date`)}
          setSelectedDate={changeDate}
          closePopover={() => setIsDatePopoverOpen(false)}
          disablePreviousDate={disablePreviousDates}
        />
      )}
    </Stack>
  )
}

export default DateFormField;