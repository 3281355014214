import AssignedItem, {Label} from "components/AssigneeSelectionDrawer/AssignedItemList/AssignedItem";
import {Personnel} from "types/Personnel";
import {Divider, Stack} from "@mui/material";
import theme from "theme/theme";
import {auth} from "../../../firebase";
import {AccessListUser} from "types/AccessListUser";

export interface AssignedItemListProps {
  personnelList: AccessListUser[]
  updateSelected: (assigned: Personnel) => void
  label: string
  assigned: Personnel
  isListTeams: boolean;
}

function AssignedItemList(props: AssignedItemListProps) {

  const {personnelList, updateSelected, label, isListTeams, assigned} = props;
  const {uid} = auth.currentUser!;

  if (personnelList.length === 0)
    return null;

  return <>
    <Stack direction="column">
      <Label
        mb={1}
        sx={{color: theme.palette.secondary.main}}>
        {label}
      </Label>
      {personnelList.map((personnel, index) =>
        <AssignedItem
          key={index}
          assigned={{...personnel, isYou: personnel["@id"] === uid, isTeam: isListTeams, uid: personnel["@id"]}}
          isSelected={personnel["@id"] === assigned["uid"]}
          updateSelected={updateSelected}/>
      )}
    </Stack>
    {isListTeams && <Divider/>}
  </>
}

export default AssignedItemList