import {Checkbox, FormControlLabel, Stack, Typography} from "@mui/material";
import {enAssetLabel, enCommonLabel} from "constants/index";
import React from "react";
import CheckboxSwitch from "components/CheckboxSwitch";
import {SystemIcons} from "assets/icons/system/system.index";
import {SecondaryButton} from "components/Button";
import {useNavigate} from "react-router-dom";
import DeleteButton from "components/Button/BulkActionButtons/DeleteButton";

export interface BulkActionsProps {
  isLoading: boolean;
  canView: boolean;
  canDelete: boolean;
  isCheckboxShown: boolean;
  checkedAssets: Set<string>;
  checkAllAssets: () => void;
  toggleCheckbox: (shown: boolean) => void;
  openDeleteDialog: () => void;
}

function BulkActions(props: BulkActionsProps) {
  const {checkedAssets, isCheckboxShown, toggleCheckbox, checkAllAssets} = props;

  const navigate = useNavigate();

  return (
    <Stack direction="row" justifyContent="space-between" height={20} alignItems="center" mt={1}>
      <Stack direction="row" alignItems="center" justifyContent="center" mt={1}>
        <CheckboxSwitch
          isCheckboxShown={isCheckboxShown}
          toggleCheckbox={toggleCheckbox}
        />
        {isCheckboxShown && (
          <>
            <FormControlLabel
              sx={(theme) => ({ml: 1, color: theme.palette.text.secondary})}
              control={
                <Checkbox
                  onChange={checkAllAssets}
                  checked={checkedAssets.size > 0}
                  id="assetCheckbox_all"
                />
              }
              label={
                <Stack direction="row">
                  {checkedAssets.size > 0 ? (
                    <Typography variant="h5" color="secondary.main" id="selectedAssetsCounter">
                      {enCommonLabel.selectedItems(checkedAssets.size)}
                    </Typography>
                  ) : (
                    <Typography variant="h5" color="secondary.main">
                      {enAssetLabel.selectAllAssets}
                    </Typography>
                  )}
                </Stack>
              }
            />
            {checkedAssets.size > 0 && (
              <DeleteButton
                id="deleteSelectedAssets"
                disabled={!(props.canDelete || !props.isLoading)}
                onButtonClick={props.openDeleteDialog}
              />
            )}
          </>
        )}
      </Stack>
      <SecondaryButton
        onClickFcn={() => navigate('../manage-assets')}
        disabled={!props.canView}
        startIcon={SystemIcons.Settings}
        label={enAssetLabel.manageAsset}
        sx={{height: 40, width: "max-content"}}
      />
    </Stack>
  )
}

export default BulkActions;