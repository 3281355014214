import {Timestamp, GeoPoint} from "firebase/firestore";

export function deepEqual(a: any, b: any) {
  if (a === b)
    return true;
  if (a instanceof Timestamp && b instanceof Timestamp) {
    return a.isEqual(b);
  }
  if (a instanceof GeoPoint && b instanceof GeoPoint) {
    return a.latitude === b.latitude && a.longitude === b.longitude;
  }
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length)
      return false;
    for (let i = 0; i < a.length; i++) {
      if (!deepEqual(a[i], b[i]))
        return false;
    }
    return true;
  }
  if (isObject(a) && isObject(b)) {
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);
    if (keysA.length !== keysB.length)
      return false;
    for (const key of keysA) {
      if (!keysB.includes(key))
        return false;
      if (!deepEqual(a[key], b[key]))
        return false;
    }
    return true;
  }
  return false;
}

function isObject(object: any) {
  return object != null && typeof object === "object";
}