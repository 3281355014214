import {Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {enCommonLabel} from "constants/index";

function InviteTag() {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      paddingX={1}
      sx={{
        backgroundColor: theme.palette.primary.main,
        borderRadius: 1,
        maxHeight: 24,
      }}
    >
      <Typography color={theme.palette.background.paper} sx={{fontSize: "14px"}}>
        {enCommonLabel.invite}
      </Typography>
    </Stack>
  )
}

export default InviteTag;