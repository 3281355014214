import {Box} from "@mui/material";
import theme from "theme/theme";
import {StatusIcons} from "assets/icons/status/status.index";
import React from "react";

function RejectedOverlay() {
  return (
    <Box
      height="inherit"
      position="absolute"
      top={0}
      display="grid"
      sx={{placeItems: "center"}}
      width={64}
    >
      <Box
        position="absolute"
        width="inherit"
        height="inherit"
        sx={{backgroundColor: theme.palette.error.main, opacity: 0.7, borderRadius: 1}}
      />
      <Box position="relative" top={2}>
        <StatusIcons.Rejected fill={theme.palette.text.onDark}/>
      </Box>
    </Box>
  )
}

export default RejectedOverlay;