import {Dispatch, useState} from "react";
import {algoliaIndex} from "../algoliaConfig";
import useDebounce from "./useDebounce";
import {auth} from "../firebase";
import {getDoc} from "firebase/firestore";
import {User} from "types/User";
import {userPath} from "../screens/utility";

function useAlgoliaSearch<Type>(
  searchQuery: string,
  colPath: string | string[],
  setLoading?: Dispatch<boolean>,
  alternatePathName?: string,
  additionalFilters: string[] = [],
  byPassUserAndTeamsFilters?: boolean
): Type[] | null {

  const {uid} = auth.currentUser!;
  const index = algoliaIndex;
  const [hits, setHits] = useState<Type[] | null>(null);
  const [currentFilters, setCurrentFilters] = useState<string[]>([]);

  useDebounce(searchFnWrapper, 300, [searchQuery, JSON.stringify(additionalFilters)]);

  const pathName = alternatePathName ?? "colPath";

  async function searchFnWrapper() {
    if (searchQuery === "" && JSON.stringify(currentFilters) === JSON.stringify(additionalFilters)) {
      return setHits(null);
    }

    if (setLoading) setLoading(true);

    const colPathFilters = getColPathFilters();
    const userAndTeamFilters = byPassUserAndTeamsFilters ? [] : await getUserAndTeamFilters()

    index.transporter.responsesCache.clear();
    index.search(searchQuery, {
      hitsPerPage: 1000,
      facetFilters: [
        colPathFilters,
        userAndTeamFilters,
        ...additionalFilters
      ],
    }).then(res => {
      //colPath:orgs/abc OR colPath:orgs/def OR status:Submitted OR
      setHits(res.hits as unknown as Type[]);
      if (setLoading) setLoading(false);
      setCurrentFilters([...additionalFilters]);
    });
  }

  function getColPathFilters() {
    if (typeof colPath === "string")
      return [`${pathName}:${colPath}`]
    return colPath.map(path => `${pathName}:${path}`) || [];
  }

  async function getUserAndTeamFilters() {
    const userDoc = await getDoc(userPath(uid))
    const userData = await userDoc.data() as User
    const filters = [uid, ...userData.teamIds]

    return filters.map(filter => `userIds:${filter}`)
  }

  return hits;
}

export default useAlgoliaSearch;
