import {Checkbox, Hidden, Stack, Typography} from "@mui/material";
import {AccessRole} from "enums/accessRole";
import React from "react";
import {ACCESS_ROLE_LABEL} from "constants/accessRoleLabel";

interface ItemRoleProps {
  accessRole: AccessRole;
  checked: boolean;
  isView: boolean;
  onChange: (checked: boolean) => void;
}

function ItemRole(props: ItemRoleProps) {
  const {accessRole, checked, isView, onChange} = props;
  const isOwner = accessRole === AccessRole.Owner;

  return (
    <Stack direction="row" justifyContent="space-between">
      <Hidden mdUp>
        <Typography variant="h5">
          {ACCESS_ROLE_LABEL[accessRole]}
        </Typography>
      </Hidden>
      <Checkbox
        checked={checked}
        disabled={isOwner || (isView && ![AccessRole.User, AccessRole.Guest, AccessRole.PowerUser].includes(accessRole))}
        onChange={(e, checked) => isOwner ? undefined : onChange(checked)}
      />
    </Stack>
  )
}

export default ItemRole;