import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {enCommonButton} from "constants/index";
import {MouseEventHandler} from "react";
import {LoadingButton} from "@mui/lab";

interface ApproveButtonProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

export default function ApproveButton(props: ApproveButtonProps) {
  const {isLoading = false, isDisabled = false, onClick} = props
  return <LoadingButton
    id="task-status-approve-button"
    variant="contained"
    sx={{
      minWidth: 114,
      backgroundColor: theme.palette.success.main,
      "&:hover": {
        backgroundColor: theme.palette.success.dark
      }
    }}
    startIcon={isLoading ? undefined :
      <SystemIcons.Check
        height={16}
        width={16}
        stroke={theme.palette.background.paper}
      />
    }
    onClick={onClick}
    disabled={isLoading || isDisabled}
    loading={isLoading}
    disableElevation
  >
    {enCommonButton.approve}
  </LoadingButton>
}
