import {AccessListUser, AlgoliaObject} from "types/index";
import {CollectionReference, doc, where} from "firebase/firestore";
import {Box, Divider, Drawer, IconButton, Stack, styled, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {enCommonButton, enCommonLabel, enCommonManageAccess} from "constants/index";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {useCollection} from "hooks/index";
import {getParentAccess, submitForm} from "screens/utility";
import TeamOrPeopleEmptyPage from "./Objects/TeamOrPeopleEmptyPage";
import {AccessRole, AccessUserType, ActionType, Entity, ProcessType, Severity} from "enums/index";
import {LoadingButton} from "@mui/lab";
import SmartSearchInput from "components/inputs/SmartSearchInput";
import {toastProps} from "screens/BaseProps";
import DynamicList from "./DynamicList";
import {AccessType} from "types/Permission";
import {bulkStatusSubmitHandler, StatusSubmitHandler} from "screens/utility/statusSubmitHandler";
import {SelectedOrgContext} from "screens/SelectedOrgContextProvider";

export interface SelectedItem {
  "@id": string;
  role: AccessRole | undefined;
}

interface AddTeamOrPeopleDrawerProps {
  entity: Entity;
  parentEntity?: Entity;
  teams: AccessListUser[];
  people: AccessListUser[];
  collectionReference: CollectionReference;
  onDrawerClose: () => void;
  access: AccessType | null;
  onAddSuccess?: (message: string) => void;
  toastProps: toastProps;
}

/**
 * @param props - contains the ff:
 * <ul style="list-style: none;">
 * <li>teams: AccessListUser[] - list of teams already in the access list. Used to check what NOT to display</li>
 * <li>people: AccessListUser[] - list of people in access list.</li>
 * <li>collectionReference: CollectionReference - where to save the selected teams/people if added </li>
 * <li>onDrawerClose: void fcn - inform the parent component that the drawer is closed</li>
 * </ul>
 * @returns a JSX.Element
 */

type AlgoliaAccessList = AccessListUser & AlgoliaObject

function AddTeamOrPeopleDrawer(props: AddTeamOrPeopleDrawerProps) {
  const {teams, people, entity, parentEntity, access, collectionReference} = props;
  const {onDrawerClose, toastProps, onAddSuccess} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  const [teamsCollectionRef, membersCollectionRef] = getParentAccess(collectionReference, entity, parentEntity);
  // access list of parent
  const [parentTeamsList, setParentTeamsConstraints] = useCollection<AccessListUser>(null, teamsCollectionRef);
  const [parentMembersList, setParentMembersConstraints] = useCollection<AccessListUser>(null, membersCollectionRef);

  const [selectedTeams, setSelectedTeams] = useState<SelectedItem[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<SelectedItem[]>([]);
  const [algoliaAccessList, setAlgoliaAccessList] = useState<AlgoliaAccessList[] | null>();

  const teamIds = useMemo(() => teams.map(t => t["@id"]), [teams]);
  const peopleIds = useMemo(() => people.map(p => p["@id"]), [people]);

  const selectedOrgContext = useContext(SelectedOrgContext);
  const {selectedOrg} = selectedOrgContext!;

  let algoliaTeams = algoliaAccessList ? algoliaAccessList.filter((accessList) =>
    accessList.entity === "Team") : [];
  let algoliaMembers = algoliaAccessList ? algoliaAccessList.filter((accessList) =>
   accessList.entity === "OrganizationMember") : [];

  const fromAlgolia = algoliaAccessList !== null;

  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const getParentTeamsList = useMemo(() => {
    // if current selected orgs is empty, return empty array
    if (!selectedOrg?.teams) return [];

    // if fromAlgolia, use the algoliaTeams, else use the parentTeamsList
    const newTeamsList = fromAlgolia ? algoliaTeams: (parentTeamsList || []);

    // remove teams that are already in the access list
    const removedFromParent = newTeamsList.filter(teamData => !teamIds.includes(teamData["@id"]));

    // only display teams if from selectedOrg.teams
    const fromContextTeams = removedFromParent.filter(teamData => selectedOrg?.teams?.find(team => team["@id"]=== teamData["@id"]));

    // structure data, always display the role from the access list not from the context
    return fromContextTeams.map(team => {
      const teamInfo = selectedOrg.teams?.find(teamData => teamData["@id"]! === team["@id"]);
      if (teamInfo) {
        const {"@form": form, ...rest} = teamInfo;
        return {
          ...rest,
          ...team,
        }
      }

      return team;
    });
  }, [fromAlgolia, algoliaTeams, parentTeamsList, teamIds, selectedOrg?.teams]);

  const getParentMembersList = useMemo(() => {
    // if current selected orgs is empty, return empty array
    if (!selectedOrg?.members) return [];

    // if fromAlgolia, use the algoliaMembers, else use the parentMembersList
    const newMembersList = fromAlgolia ? algoliaMembers: (parentMembersList || []);

    // remove members that are already in the access list
    const removedFromParent = newMembersList.filter(memberData => !peopleIds.includes(memberData["@id"]));

    // only display members if from selectedOrg.members
    const fromContextMembers = removedFromParent.filter(memberData => selectedOrg?.members?.find(member => member["@id"]=== memberData["@id"]));

    // structure data, always display the role from the access list not from the context

    return fromContextMembers.map(member => {
      const memberInfo = selectedOrg.members?.find(memberData => memberData["@id"]! === member["@id"]);
      if (memberInfo) {
        const {"@form": form, ...rest} = memberInfo;
        return {
          ...rest,
          ...member,
        }
      }

      return member;
    })
  }, [fromAlgolia, algoliaMembers, parentMembersList, peopleIds, selectedOrg?.members]);

  useEffect(() => {
    if (teams === null || entity === Entity.Project) return;

    setParentTeamsConstraints([where("accessUserType", "==", AccessUserType.Team)]);
  }, [teams, entity]);

  useEffect(() => {
    if (people === null || entity === Entity.Project) return;
    const newConstraints = [where("accessUserType", "==", AccessUserType.Person)];

    setParentMembersConstraints([...newConstraints]);
  }, [people, entity]);

  useEffect(() => {
    let teamsSelected = selectedTeams.some(team => team.role !== undefined);
    let membersSelected = selectedMembers.some(member => member.role !== undefined);

    setIsDisabled(!teamsSelected && !membersSelected);
  }, [JSON.stringify(selectedTeams), JSON.stringify(selectedMembers)]);

  async function addTeamOrPeople() {
    setIsLoading(true);
    const filteredMembers = selectedMembers.filter(member => member.role !== undefined);
    const memberIds = Object.fromEntries(
      filteredMembers.map(member => [member["@id"], {role: member.role}])
    );

    const filteredTeams = selectedTeams.filter(team => team.role !== undefined);
    const teamIds = Object.fromEntries(
      filteredTeams.map(team => {
        const teamMemberUids = getParentTeamsList.filter(orgTeam => orgTeam["@id"] === team["@id"])[0]?.teamMemberUids ?? [];
        return [team["@id"], {role: team.role, teamMemberUids}];
      })
    );

    const bulkActions = {...memberIds, ...teamIds};

    const toBeAddedMembers = filteredMembers.map(member => {
      const memberData = (parentMembersList ?? []).filter(orgMember => orgMember["@id"] === member["@id"]);
      const {"@form": memberForm, ...restMemberData} = memberData[0];
      return {
        ...restMemberData,
        id: member["@id"],
        "@id": member["@id"],
        role: member.role,
        accessUserType: AccessUserType.Person,
        bulkActions
      };
    });

    const toBeAddedTeams = filteredTeams.map(team => {
      const teamData = (parentTeamsList ?? []).filter(orgTeam => orgTeam["@id"] === team["@id"]);
      const {"@form": teamForm, ...restTeamData} = teamData[0];

      return {
        ...restTeamData,
        id: team["@id"],
        "@id": team["@id"],
        role: team.role,
        accessUserType: AccessUserType.Team,
        bulkActions
      };
    });
    const combinedArray = [...toBeAddedTeams, ...toBeAddedMembers];

    let submittedData: StatusSubmitHandler<AccessListUser>[] = [];
    await Promise.all(combinedArray.map(async item => {
      let ref = doc(collectionReference, item["@id"]);
      await submitForm(ref, ActionType.Create,
        (status, data, isLastUpdate) => {
          isLastUpdate && submittedData.push({status, data, isLastUpdate})
        },
        item
      );
    }));

    bulkStatusSubmitHandler<AccessListUser>({
      data: submittedData,
      successCallback: () => {
        setIsLoading(false);
        onAddSuccess?.(enCommonManageAccess.addSuccess("teams/peoples"))
      },
      errorCallback
    })
  }

  function errorCallback(message: any) {
    setIsLoading(false);
    setToastSeverity(Severity.Error);
    setToastMessage(message || enCommonLabel.errorProcess(ProcessType.Update));
    setIsToastOpen(true);
  }

  function getAlgoliaPathFromFirebasePath(firebasePath: string) {
    const regex = /organizations.+/g
    const match = firebasePath.match(regex);

    if (match)
      return match[0]

    return ""
  }

  return (
    <>
      <Drawer id={"add-team-or-people-drawer"} anchor="right" open={true} onClose={onDrawerClose}>
        <DrawerFieldsWrapper>
          <Stack direction="row" gap={1}>
            <IconButton
              size="small"
              disabled={isLoading}
              onClick={onDrawerClose}
              sx={{alignSelf: "flex-start"}}
              id={"back-button"}
            >
              <SystemIcons.ArrowLeft
                id="add-team-or-people-drawer-back-head-button"
                stroke={theme.palette.neutral.dark}
              />
            </IconButton>
            <Typography sx={{alignSelf: "center", mb: 4}} variant="h3">
              {enCommonManageAccess.addNewTeamOrPeople}
            </Typography>
          </Stack>
          {getParentTeamsList.length === 0 && getParentMembersList.length === 0 && !fromAlgolia ? (
              <TeamOrPeopleEmptyPage entity={entity}/>
          ) : (
            <>
              <SmartSearchInput<AlgoliaAccessList>
                id="add-team-or-people-drawer-search"
                placeholder={enCommonManageAccess.searchTeamOrPeople}
                sx={{mx: 1, mb: 2, width: "310px"}}
                resultCallback={setAlgoliaAccessList}
                colPath={[getAlgoliaPathFromFirebasePath(teamsCollectionRef.path), getAlgoliaPathFromFirebasePath(membersCollectionRef.path)]}
                byPassUsersAndTeamsFilter={true}
              />
              <DynamicList
                parentTeamsList={getParentTeamsList}
                parentMembersList={getParentMembersList}
                fromAlgolia={fromAlgolia}
                access={access}
                collectionReference={collectionReference}
                setSelectedTeams={(newSelected) => setSelectedTeams(newSelected || [])}
                setSelectedMembers={(newSelected) => setSelectedMembers(newSelected || [])}
              />
            </>
          )}
          <Box flex={1}/>
          <Divider sx={{mb: 1}}/>
          <Box sx={{display: "flex", justifyContent: "flex-end", alignItems: "center"}} gap={1}>
            <Typography variant="h4" color={theme.palette.neutral.dark} mr={1}>
              {enCommonManageAccess.selectedCounters(selectedTeams.length, selectedMembers.length)}
            </Typography>
            <LoadingButton
              loading={isLoading}
              id={"add-team-or-people-drawer-add-button"}
              variant="contained"
              onClick={addTeamOrPeople}
              disabled={isDisabled}
              autoFocus
            >
              {enCommonButton.add}
            </LoadingButton>
          </Box>
        </DrawerFieldsWrapper>
      </Drawer>
    </>
  )
}

/** css **/
const DrawerFieldsWrapper = styled(Box)({
  height: "100%",
  width: "360px",
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  overflowX: "hidden",
  overflowY: "hidden"
});
/** css **/
export default AddTeamOrPeopleDrawer;