import {en} from "language/en";
import {noAccess} from "./noAccess";
import {noOne} from "./noOne";
import {ownerAccess} from "constants/ownerAccess";
import {initialOrganization} from "./initialOrganization";
import {initialAsset} from "./initialAsset";
import {initialTask} from "./initialTask";
import {initialRequirement} from "./initialRequirement";
import {initialInvites} from "constants/initialInvites";
import {overrideStatusCombinations} from "./overrideStatusCombinations";
import {defaultTimelinePersonnel} from "./defaultTimelinePersonnel";
import {defaultAssignee} from "./defaultAssignee";
import {unAssigned} from "./unAssigned";
import {defaultUser} from "./defaultUser";
import {defaultTeam} from "constants/defaultTeam";
import {spinTransform} from "constants/spinTransform";
import {defaultSelectedOrg} from "constants/defaultSelectedOrg";
import {AccessRole} from "enums/accessRole";
import {entitySystemIcons} from "./entitySystemIcons";
import {mimeType} from "constants/mimeType";
import {ACCESS_ROLE_LABEL} from "constants/accessRoleLabel";

export const previousPage = -1;

export const enChatLabel = en.screen.Chat.label;
export const enCommonLabel = en.common.label;
export const enCommonButton = en.common.button;
export const enCommonStatus = en.common.status;
export const enCommonManageAccess = en.common.manageAccess;
export const enTaskLabel = en.screen.Task.label;
export const enTaskButton = en.screen.Task.button;
export const enFileRequirementButton = en.screen.FileRequirement.button;
export const enProject = en.screen.Project;
export const enProjectLabel = en.screen.Project.label;
export const enFormsLabel = en.screen.Forms.label;
export const enFormsButton = en.screen.Forms.button;
export const enReferencesButton = en.screen.ReferenceFiles.button;
export const enReferencesLabel = en.screen.ReferenceFiles.label;
export const enProjectButton = en.screen.Project.button;
export const enAssetLabel = en.screen.Asset.label;
export const enOrganizationLabel = en.screen.Organization.label;
export const enOrgAssets = en.screen.OrgAssets;
export const enOrgDataTagsLabel = en.screen.OrganizationDataTags.label;
export const enOrgDataTagsButton = en.screen.OrganizationDataTags.button;
export const enOrgAssetsButton = enOrgAssets.button;
export const enMilestone = en.screen.Milestone;
export const enMilestoneLabel = en.screen.Milestone.label;
export const enMilestoneButton = en.screen.Milestone.button;
export const enFileRequirementLabel = en.screen.FileRequirement.label;
export const enSidebar = en.screen.Sidebar;
export const enMemberLabel = en.screen.Member.label;
export const enMemberButton = en.screen.Member.button;
export const enMemberValidation = en.screen.Member.validationMessages;
export const enActivityFeed = en.screen.ActivityFeed;
export const enActivityFeedLabel = en.screen.ActivityFeed.label;
export const enActivityFeedButton = en.screen.ActivityFeed.button;
export const enTeamsAndPeople = en.screen.TeamsAndPeople;
export const enTeamsAndPeopleLabel = en.screen.TeamsAndPeople.label;
export const enTeamsAndPeopleButton = en.screen.TeamsAndPeople.button;
export const enManageTeamCategories = en.screen.ManageTeamCategories;
export const enManageTeamCategoriesLabel = en.screen.ManageTeamCategories.label;
export const enManageTeamCategoriesButton = en.screen.ManageTeamCategories.button;
export const enInviteLabel = en.screen.Invite.label;
export const enFormRequirementLabel = en.screen.FormRequirement.label;
export const enProfileAndSettings = en.screen.ProfileAndSettings;
export const enProfileAndSettingsLabel = en.screen.ProfileAndSettings.label;
export const enTemplate = en.screen.Template;
export const enTemplateLabel = en.screen.Template.label;
export const enTemplateButton = en.screen.Template.button;
export const enHomeLabel = en.screen.Home.label;
export const enHomeButton = en.screen.Home.button;
export const enUser = en.screen.User;
export const enUserLabel = en.screen.User.label;
export const enRolesAndPermissions = en.screen.RolesAndPermissions;
export const enRolesAndPermissionsLabel = en.screen.RolesAndPermissions.label;

export const defaultTeamCategory = {"@id": "uncategorized", id: "uncategorized", name: "Uncategorized"};
export const defaultMilestoneId = "misc";

export const SELECTABLE_ROLES = Object.entries(AccessRole).map((entry) => ({name: ACCESS_ROLE_LABEL[entry[0] as AccessRole], value: entry[1]}));

export const allStatus = "All";

export const timePickerMinutesInterval = 15;
// firestore limit for maximum number elements in the value array.
export const firebaseMaxArrayQuerySize = 10;

export const emptyFunction = function (x?: any) {}

// file preview upload util
export const filePreviewTargetHeight = 192;

export const quietPeriodMs = 500;

export const emailRegex =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/;

export {
  ACCESS_ROLE_LABEL,
  initialOrganization,
  initialAsset,
  initialRequirement,
  initialInvites,
  initialTask,
  defaultTimelinePersonnel,
  defaultAssignee,
  defaultSelectedOrg,
  spinTransform,
  unAssigned,
  defaultUser,
  defaultTeam,
  noOne,
  mimeType,
  // permission related constants
  noAccess,
  ownerAccess,
  overrideStatusCombinations,
};

export const entityOutlinedSystemIcons = entitySystemIcons;
