import {Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {CustomTab, CustomTabs, TabPanel} from "components";
import {enProjectLabel, enTemplateLabel} from "constants/index";
import {BaseProps} from "../BaseProps";
import Projects from "./Projects";
import Overview from "./Overview";
import {Template} from "types/index";
import theme from "theme/theme";
import {useAccess, useRTDBDocField} from "hooks/index";
import {useNavigate} from "react-router";
import {PermissionEntity} from "types/Permission";
import {Entity, CounterFields} from "enums/index";
import {templatesPath} from "../utility";

enum TabIndex {
  Overview,
  Projects
}

interface TabinationProps extends BaseProps{
  template: Template,
}

function Tabination(props: TabinationProps) {
  const {toastProps, template, uid} = props;
  const navigate = useNavigate();
  let {orgId} = useParams();
  const {pathname} = useLocation();
  const countersPath = `${templatesPath(orgId!).path}/${template.id}`;
  const projectsCount = useRTDBDocField<number>(countersPath, CounterFields.ProjectsCount);
  const hiddenTasksCount = useRTDBDocField<number>(countersPath, CounterFields.HiddenTasksCount);
  const [activeTab, setActiveTab] = useState<TabIndex>(TabIndex.Overview);
  const currentTab = pathname.substring(pathname.lastIndexOf("/"));
  const [access] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationTemplateTask,
  });

  useEffect(() => {
    if(currentTab === "/projects") {
      setActiveTab(TabIndex.Projects);
      return;
    }
    setActiveTab(TabIndex.Overview);
  }, [currentTab]);

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");
  }, []);

  function handleTabChange(event: React.SyntheticEvent, newValue: number) {
    setActiveTab(newValue);
  }

  return (
    <>
      <CustomTabs
        value={activeTab}
        onChange={handleTabChange}
      >
        <CustomTab
          disableRipple
          label={
            <Stack direction="row" gap={0.5}>
              <Typography variant="h5">{enTemplateLabel.overview}</Typography>
            </Stack>
          }
        />
        <CustomTab
          disableRipple
          label={
            <Stack direction="row" gap={0.5}>
              <Typography variant="h5">{enProjectLabel.projects}</Typography>
              <Typography
                id="projects-counter"
                color={theme.palette.neutral.dark}
                variant="h5"
              >
                {!!projectsCount && projectsCount}
              </Typography>
            </Stack>
          }
          sx={{ml: 5}}
        />
      </CustomTabs>
      <TabPanel tabIndex={TabIndex.Overview} value={activeTab}>
        <Overview
          uid={uid}
          template={template}
          toastProps={toastProps!}
          taskAccess={access}
          hiddenTasksCount={hiddenTasksCount || 0}
        />
      </TabPanel>
      <TabPanel tabIndex={TabIndex.Projects} value={activeTab}>
        <Projects
          projectsCount={projectsCount || 0}
          uid={uid}
          template={template}
          toastProps={toastProps!}
        />
      </TabPanel>
    </>
  );
}

export default Tabination;
