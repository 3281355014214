import SettingsItem from "./SettingsItem";
import {
  Button,
  Typography
} from "@mui/material";
import {enProfileAndSettingsLabel} from "constants/index";
import {useState} from "react";
import {TermsOfServiceDialog} from "components/Dialogs";

function TermsOfService() {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  return <>
    <TermsOfServiceDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen}/>
    <SettingsItem>
      <Button onClick={() => setIsDialogOpen(true)}>
        <Typography>{enProfileAndSettingsLabel.termsOfService}</Typography>
      </Button>
    </SettingsItem>
  </>
}

export default TermsOfService;
