import {ToastProps} from "../../BaseProps";
import {DeletedItemType} from "types/ActivityFeedTypes";
import BaseItem from "./BaseItem";
import SystemIndex from "assets/icons/system/system.index";
import IconBaseProps from "./IconBaseProps";
import theme from "theme/theme";
import {enActivityFeedLabel} from "constants/index";
import {useDocument} from "hooks/index";
import {doc} from "firebase/firestore";
import {userActivityFeedPath} from "../../utility";
import {SkeletonItem} from "components/index";

interface DeletedItemProps extends ToastProps {
  itemId: string;
  uid: string;
  index: number;
  updatePinnedValue: (index: number, pinned: boolean) => void;
}

function DeletedItem(props: DeletedItemProps) {
  const {toastProps, itemId, uid, updatePinnedValue, index} = props;

  const [activityFeed] = useDocument<DeletedItemType>(doc(userActivityFeedPath(uid), itemId));

  if (!activityFeed) {
    return <SkeletonItem height={"5rem"} sx={{marginTop: "5px", width: "80%"}}/>
  }

  const {details, entity} = activityFeed;
  const {name, by} = details;

  return <BaseItem
    index={index}
    uid={uid}
    item={activityFeed}
    icon={<SystemIndex.SystemIcons.DeleteOutlined stroke={theme.palette.error.main} {...IconBaseProps}/>}
    text={enActivityFeedLabel.deleted(entity, name, by.name ?? "")}
    toastProps={toastProps}
    updatePinnedValue={updatePinnedValue}
  />
}

export default DeletedItem
