import {Typography} from "@mui/material";
import {StatusIcons} from "assets/icons/status/status.index";
import {StatusChangeItemType} from "types/ActivityFeedTypes";
import {enActivityFeedLabel} from "constants/index";
import BaseItem from "./BaseItem";
import IconBaseProps from "./IconBaseProps";
import {ToastProps} from "../../BaseProps";
import {RequirementStatus} from "enums/requirementStatus";
import {getActivityName} from "components/ActivityFeed/utils";
import {useDocument} from "hooks/index";
import {userActivityFeedPath} from "../../utility";
import {doc} from "firebase/firestore";
import {SkeletonItem} from "components/index";
import {ENTITY_LABEL} from "language/en/common";

interface StatusChangeItemProps extends ToastProps {
  itemId: string;
  uid: string;
  index: number;
  updatePinnedValue: (index: number, pinned: boolean) => void;
}

function StatusChangeItem(props: StatusChangeItemProps) {
  const {itemId, toastProps, uid, updatePinnedValue, index} = props;
  const [activityFeed] = useDocument<StatusChangeItemType>(doc(userActivityFeedPath(uid), itemId));

  if (!activityFeed) {
    return <SkeletonItem height={"5rem"} sx={{marginTop: "5px", width: "80%"}}/>
  }

  const {details, entity} = activityFeed;
  const {to, from, by, reasonForChange} = details;

  let icon = <></>;

  switch (to) {
    case RequirementStatus.Approved:
      icon = <StatusIcons.Approved {...IconBaseProps}/>;
      break;
    case RequirementStatus.Pending:
      icon = <StatusIcons.Pending{...IconBaseProps}/>;
      break
    case RequirementStatus.InProgress:
      icon = <StatusIcons.InProgress{...IconBaseProps}/>;
      break
    case RequirementStatus.Cancelled:
      icon = <StatusIcons.Cancelled {...IconBaseProps}/>;
      break
    case RequirementStatus.Rejected:
      icon = <StatusIcons.Rejected {...IconBaseProps}/>;
      break;
    case RequirementStatus.Submitted:
      icon = <StatusIcons.Submitted {...IconBaseProps}/>;
      break;
    case RequirementStatus.HasRejection:
      icon = <StatusIcons.HasRejection {...IconBaseProps}/>;
  }

  return (
    <BaseItem
      index={index}
      uid={uid}
      toastProps={toastProps}
      item={activityFeed}
      icon={icon}
      updatePinnedValue={updatePinnedValue}
      text={
        <Typography>
          {enActivityFeedLabel.statusChange(to, from, by.name ?? "", getActivityName(activityFeed), ENTITY_LABEL[entity], reasonForChange)}
        </Typography>
      }
    />
  )
}


export default StatusChangeItem
