import React, {useMemo, useState} from "react";
import {DrawerProps, MemberSelectionDrawer} from "components/index";
import {Button, Stack, Typography} from "@mui/material";
import {enTeamsAndPeopleButton, enTeamsAndPeopleLabel} from "constants/index";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {useCollection, useUser} from "hooks/index";
import {Member, Team} from "types/index";
import {organizationMembersPath} from "screens/utility/FirebasePath";
import {where} from "firebase/firestore";
import chunkArray from "screens/utility/chunkArray";
import {useParams} from "react-router-dom";

interface ManageMembersDrawerProps {
  selectedMembers: Set<string>;
  onMemberSelect: (uid: string) => void;
  updateSelectedMembers: (newSet: Set<string>) => void;
  withMemberSize?: boolean;
  uid: string;
  team?: Team;
}

const UID_LIST_CHUNK_SIZE = 10;
const activeConstraint = where("active", "==", true);

export default function ManageMembersDrawer(props: ManageMembersDrawerProps) {
  const {withMemberSize, team,  uid, selectedMembers, onMemberSelect, updateSelectedMembers} = props;

  const {orgId} = useParams();
  const user = useUser(uid);
  const {teamMemberUids} = team ?? {teamMemberUids: []};
  const [drawerProps, setDrawerProps] = useState<Partial<DrawerProps>>({
    onClose: onDrawerClose
  });
  const [membersCollection, setMembersConstraints, setColRef] = useCollection<Member>(null, null, [activeConstraint]);

  function onDrawerOpen() {
    setDrawerProps({...drawerProps, isOpen: true});
  }

  function onDrawerClose() {
    setDrawerProps({...drawerProps, isOpen: false});
  }

  function setMembersCollectionConstraints() {
    const chunkedTeamMembersUIDs = chunkArray(teamMemberUids, UID_LIST_CHUNK_SIZE);
    const constraints = chunkedTeamMembersUIDs.map((memberUIDs) => where("uid", "not-in", memberUIDs));
    setMembersConstraints([...constraints, activeConstraint]);
  }

  useMemo(() => {
    if (!user) return;
    if (teamMemberUids.length) setMembersCollectionConstraints();
    setColRef(organizationMembersPath(orgId!));
  }, [user]);

  return (<>
    <MemberSelectionDrawer
      selectedMembers={selectedMembers}
      onMemberSelect={onMemberSelect}
      updateSelectedMembers={updateSelectedMembers}
      members={membersCollection ?? []}
      {...drawerProps}
    />
    <Stack>
      {(withMemberSize && selectedMembers !== undefined) && (
        <Stack direction="row" gap={1}>
          <Typography variant="h5">
            {enTeamsAndPeopleLabel.teamMembers}
          </Typography>
          <Typography variant="h5" color={theme.palette.neutral.medium} id="teamMembersCount">
            {selectedMembers.size}
          </Typography>
        </Stack>
      )}
      <Button
        variant="outlined"
        startIcon={<SystemIcons.Settings
          width={16}
          height={16}
          stroke={theme.palette.primary.main}
          strokeWidth={3}/>}
        sx={(theme) => ({
          borderColor: theme.palette.neutral.light,
          color: theme.palette.secondary.main,
          gap: 0.5
        })}
        onClick={onDrawerOpen}
      >
        {enTeamsAndPeopleButton.manageTeamMembers}
      </Button>
    </Stack>
  </>)
}
