import {Box, Stack} from "@mui/material";
import SystemIndex from "assets/icons/system/system.index";
import {NewAccessListMemberItemType} from "types/ActivityFeedTypes";
import TimestampTypo from "components/Typography/TimestampTypo";
import {enActivityFeedLabel} from "constants/index";
import {formatDateTimeFirestoreDate} from "screens/utility/formatDateTimeFirestoreDate";
import ItemContainer from "components/ActivityFeed/ItemContainer";

interface NewAccessListMemberItemProps {
  item: NewAccessListMemberItemType
}

function NewAccessListMemberItem(props: NewAccessListMemberItemProps) {
  const {item} = props;
  const {details, timestamp} = item;
  const {name, by} = details;
  const {date, time} = formatDateTimeFirestoreDate(timestamp);

  return <ItemContainer>
    <Box width={24} height={24}>
      <SystemIndex.SystemIcons.Teams style={{minWidth: 20, height: 20}} fontWeight={700}/>
    </Box>
    <Stack>
      {enActivityFeedLabel.newMember(name, by.name ?? "")}
      <TimestampTypo>{`${date} ${time}`}</TimestampTypo>
    </Stack>
  </ItemContainer>
}

export default NewAccessListMemberItem;
