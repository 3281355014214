import {ChatMessage} from "types/ChatMessage";
import {formatDateTimeFirestoreDate, groupBy} from "screens/utility";


export function groupMessagesByDate(messages: ChatMessage[]): [groupsCount: number[], groups: string[]] {
  const formattedMessages = messages
    .sort((a, b) => a.timestamp.toDate().getTime() - b.timestamp.toDate().getTime())
    .map(message => {
    const {month, day, year} = formatDateTimeFirestoreDate(message.timestamp);
    return {
      ...message,
      timeSent: `${month} ${day}, ${year}`
    };
  });

  const groupedMessages = groupBy(formattedMessages, "timeSent");
  const groupCounts = Object.values(groupedMessages).map((message) => message.length)
  const groups = Object.keys(groupedMessages);

  return [groupCounts, groups];
}