import {DrawerProps} from "components/SimpleFormDrawer";
import {Member} from "types/Member";
import {EmptyList, OverFlowBox, SimpleFormDrawer} from "components/index";
import {Box, IconButton, Stack} from "@mui/material";
import React from "react";
import {enTeamsAndPeopleLabel} from "constants/index";
import theme from "theme/theme";
import {SidebarIcons} from "assets/icons/menu/menu.index";
import MemberItem from "./MemberItem";
import {SystemIcons} from "assets/icons/system/system.index";
import {Entity} from "enums/entity";

interface MembersListProps extends Partial<DrawerProps> {
  members: Member[];
  teamName: string;
}

export default function MembersList(props: MembersListProps) {
  const {
    members,
    teamName,
    isOpen,
    onClose,
  } = props;

  return (
    <SimpleFormDrawer
      isOpen={isOpen!}
      onClose={onClose!}
      id="membersSelectionDrawer"
      buttonId="saveMembers"
      title={enTeamsAndPeopleLabel.teamMembersList(teamName)}
      icon={<Box
        sx={theme => ({
          display: "flex",
          borderRadius: "50%",
          backgroundColor: theme.palette.background.swiftDefault,
          alignItems: "center",
          justifyContent: "center",
          padding: 1,
          width: 48,
          height: 48,
        })}>
        <SidebarIcons.User fill={theme.palette.common.black}/>
      </Box>}
      closeIcon={(
        <IconButton
          size="small"
          onClick={onClose}
          sx={{alignSelf: "flex-start"}}
        >
          <SystemIcons.Close
            id="drawer-back-head-button"
            stroke={theme.palette.neutral.dark}
          />
        </IconButton>
      )}
      iconProps={{p: 0}}
      isFormValid
      hiddenFooter
      useBackButton
      isLoading={false}
    >
      <OverFlowBox sx={{height: "100%"}}>
        <Stack
          gap={1}
          sx={{
            mx: 1,
            userSelect: "none",
            justifyContent: !!members.length ? "flex-start" : "center",
            minHeight: "80%"
          }}>
          {
            !!members.length ?
              members.map((member) => (
                <MemberItem
                  key={`memberItem_${member.uid}`}
                  member={member}
                />
              )) :
              <EmptyList entity={Entity.Member} logoProperties={{
                width: 48,
                height: 48
              }}/>
          }
        </Stack>
      </OverFlowBox>
    </SimpleFormDrawer>
  )
}