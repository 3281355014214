export function algoliaOrganizationMemberColPath(orgId: string) {
  return `organizations/${orgId}/members`
}

export function algoliaOrganizationColPath() {
  return "organizations"
}

export function algoliaProjectsColPath(orgId: string) {
  return `organizations/${orgId}/projects`
}

export function algoliaDataTagsColPath(orgId: string) {
  return `organizations/${orgId}/dataTags`
}

export function algoliaTemplatesColPath(orgId: string) {
  return `organizations/${orgId}/templates`
}

export function algoliaTemplatesProjectsColPath(templateId: string) {
  return `templates/${templateId}/projects`
}

export function algoliaOrgAssetProjectsColPath(orgId: string, assetId: string) {
  return `organizations/${orgId}/assets/${assetId}/projects`
}

export function algoliaAssetsPath(orgId: string, projId: string) {
  return `organizations/${orgId}/projects/${projId}/assets`;
}

export function algoliaOrganizationAssetsPath(orgId: string) {
  return `organizations/${orgId}/assets`
}

export function algoliaAvailableAssetsPath(orgId: string, projectId: string) {
  return `organizations/${orgId}/projects/${projectId}/availableAssets`
}

export function algoliaOrganizationsInvitesPath(orgId: string) {
  return `organizations/${orgId}/invites`
}

export function algoliaAssetAccessListPath(orgId: string, projId: string, assetId: string) {
  return `organizations/${orgId}/projects/${projId}/assets/${assetId}/accessList`;
}

export function algoliaProjectLevelAccessListPath(orgId: string, projId: string) {
  return `organizations/${orgId}/projects/${projId}/projectLevelTasksAccessList`;
}

export function algoliaAssetPath(orgId: string, projId: string | undefined, assetId?: string, templateId?: string) {
  // for templates
  if (typeof projId === "undefined")
    return `organizations/${orgId}/templates/${templateId}`;

  // for PLT
  if (typeof (assetId) === "undefined")
    return `organizations/${orgId}/projects/${projId}`;

  // for NLT
  return `organizations/${orgId}/projects/${projId}/assets/${assetId}`;
}

export function algoliaTasksPath(orgId: string, projId: string | undefined, milestoneId: string, assetId?: string, templateId?: string) {
  // for templates
  if (typeof projId === "undefined")
    return `organizations/${orgId}/templates/${templateId}/milestones/${milestoneId}/tasks`;

  // for PLT
  if (typeof (assetId) === "undefined")
    return `organizations/${orgId}/projects/${projId}/milestones/${milestoneId}/tasks`;

  // for NLT
  return `organizations/${orgId}/projects/${projId}/assets/${assetId}/milestones/${milestoneId}/tasks`;
}

export function algoliaRequirementsPath(orgId: string, projId: string | undefined, milestoneId: string, assetId: string | undefined, taskId: string, templateId: string | undefined) {
  // if template
  if(typeof projId === "undefined")
    return [
      `organizations/${orgId}/templates/${templateId}/milestones/${milestoneId}/tasks/${taskId}/fileRequirements`,
      `organizations/${orgId}/templates/${templateId}/milestones/${milestoneId}/tasks/${taskId}/formRequirements`
    ];
  // if PLT
  if (typeof (assetId) === "undefined")
    return [
      `organizations/${orgId}/projects/${projId}/milestones/${milestoneId}/tasks/${taskId}/fileRequirements`,
      `organizations/${orgId}/projects/${projId}/milestones/${milestoneId}/tasks/${taskId}/formRequirements`,
    ];
  // if NLT
  return [
    `organizations/${orgId}/projects/${projId}/assets/${assetId}/milestones/${milestoneId}/tasks/${taskId}/fileRequirements`,
    `organizations/${orgId}/projects/${projId}/assets/${assetId}/milestones/${milestoneId}/tasks/${taskId}/formRequirements`
  ];
}

export function algoliaTeamsPath(orgId: string) {
  return `organizations/${orgId}/teams`
}

export function algoliaUserInvitesPath() {
  return `invites`
}

export function algoliaTeamCategoriesPath(orgId: string) {
  return `organizations/${orgId}/teamCategories`
}

export function algoliaReferenceFilesPath(orgId: string) {
  return `organizations/${orgId}/referenceFiles`
}
