import {Box, Checkbox, FormControlLabel, FormGroup, Stack} from "@mui/material";
import React from "react";
import {Entity} from "enums/entity";
import HideOrUnHideButton from "components/Button/BulkActionButtons/HideOrUnHideButton";
import DeleteButton from "components/Button/BulkActionButtons/DeleteButton";

interface BulkActionsProps {
  parentEntity: Entity;
  isAllRequirementsChecked: boolean
  label: string;
  showBulkActionsButtons: boolean;
  showHidden: boolean;
  openDeleteDialog: () => void;
  handleSelectAll: (checked: boolean) => void;
  toggleHiddenStatus: {
    toggleLoading: boolean;
    toggleFunction: () => void;
  }
  ml?: number,
  disabled?: boolean;
}

function BulkActions(props: BulkActionsProps) {
  const {isAllRequirementsChecked, parentEntity, label, showBulkActionsButtons, showHidden, ml = 3.5, disabled = false} = props;
  const {openDeleteDialog, handleSelectAll, toggleHiddenStatus} = props;
  const {toggleLoading, toggleFunction} = toggleHiddenStatus;

  return (
    <FormGroup sx={{ml: ml}}>
      <Stack gap={1} direction="row" alignItems="center">
        <FormControlLabel
          disabled={disabled}
          checked={isAllRequirementsChecked}
          control={(
            <Checkbox
              sx={{
                "&.Mui-disabled": {
                  cursor: "not-allowed",
                  pointerEvents: "unset"
                }
              }}
            />
          )}
          label={<b>{label}</b>}
          onChange={(_, checked) => handleSelectAll(checked)}
        />
        {showBulkActionsButtons && (
          <>
            <HideOrUnHideButton
              parentEntity={parentEntity}
              showHidden={showHidden}
              isLoading={toggleLoading}
              onButtonClick={toggleFunction}
              disabled={false}
            />
            <Box sx={{alignSelf: 'center'}}>
              <DeleteButton
                onButtonClick={openDeleteDialog}
                disabled={false}
              />
            </Box>
          </>
        )}
      </Stack>
    </FormGroup>
  )
}

export default BulkActions;
