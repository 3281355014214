import {StatusIcons} from "assets/icons/status/status.index";
import theme from "theme/theme";
import { en } from "language/en";
import {StatusItem} from "assets/arrayList/ProjectStatus";
const enTask = en.screen.Task.status;

const inProgress : StatusItem = {
  title: enTask.inProgress,
  icon: StatusIcons.InProgress,
  color: theme.palette.secondary.main,
  checked: false,
}

export default inProgress