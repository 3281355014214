import {ChatMessage} from "types/ChatMessage";

export const chat = {
  label: {
		subscribersCounter: (number: number): string => (
			number === 0 ? 'No subscribers yet' :
				number === 1 ? '1 Subscriber' :
					`${number} Subscribers`
		),
    typeAMessage: "Type a message...",
    emptyTitle: "There is nothing to read.",
    emptyTitleDescription: "Send a message to start conversation.",
    scrollToNewMessage: "Scroll to read new messages",
    personTyping: (typingDocs: ChatMessage[]) => (
      typingDocs.length < 2 ?
        <><strong>{`${typingDocs[0].sender.name} `}</strong> is typing</> :
        <><strong>{`${typingDocs[0].sender.name} and ${typingDocs[1].sender.name}`}</strong> are typing</>
    ),
    manyPeopleTyping: "Many people are typing",
    chats: "Chats",
    join: "Join",
    chatPinned: "Chat Pinned",
    chatUnpinned: "Chat Unpinned",
    search: "Search Chats...",
    markAllAsRead: "Mark all as Read",
    attachedAFile: "Attached a file",
    allChatsAreMarkedAsRead: "All chats are marked as read",
    encounteredAnError: "Encountered an error",
    readBy: "READ BY",
  }
}
