import styled from "@emotion/styled";
import {Box, Collapse, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import theme from "theme/theme";
import Chevron from "./Objects/Chevron";
import TablePagination, {TablePaginationProps} from "./Objects/TablePagination";
import {AssetListType} from "enums/assetListType";

interface WrapperProps extends TablePaginationProps {
  children: any;
  assetList: AssetListType;
}

function AssetListWrapper(props: WrapperProps) {
  const {children, assetCount, assetList, assetTypeIdx, ...rest} = props;
  const [open, setOpen] = useState(true);

  function toggleCollapse() {
    setOpen(!open);
  }

  function handleAssetClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
  }

  return (
    <Wrapper p={2} pt={3} id={`${assetTypeIdx}-wrapper`} onClick={toggleCollapse} sx={{cursor: 'pointer'}}>
      <Stack direction="column" display="flex" gap={1}>
        <Stack direction="row" display="flex" gap={1} justifyItems="between">
          <Stack direction="row" display="flex" gap={1} width="100%">
            <Typography variant="h4">{assetList}</Typography>
            <Typography variant="h4" color="#858585" id={`${assetTypeIdx}-count`}>{assetCount}</Typography>
          </Stack>
          <Chevron
            assetCount={assetCount}
            assetTypeIdx={assetTypeIdx}
            open={open}
            toggleCollapse={toggleCollapse}
          />
        </Stack>
        <Collapse in={open} onClick={handleAssetClick}>
          {children}
          <TablePagination
            assetCount={assetCount}
            assetTypeIdx={assetTypeIdx}
            {...rest}
          />
        </Collapse>
      </Stack>
    </Wrapper>
  );
}

const Wrapper = styled(Box)({
  backgroundColor: theme.palette.background.swiftDefault,
  borderRadius: "4px",
});

export default AssetListWrapper;