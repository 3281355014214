import {BaseProps} from "../BaseProps";
import {User} from "types/User";
import EmptySearchResults from "components/EmptySearchResults";
import {Stack} from "@mui/material";
import UserCard from "./UserCard";
import {Dispatch, useState} from "react";
import BulkActions from "./BulkActions";
import {ConfirmDialog, EmptyList, InProgress} from "components/index";
import {useComponentToggler} from "hooks/index";
import {enCommonLabel, enUserLabel} from "constants/index";
import {CollectionReference, doc} from "firebase/firestore";
import {submitForm} from "../utility";
import {ActionType, ProcessType, Severity, Entity} from "enums/index";
import {bulkStatusSubmitHandler, StatusSubmitHandler} from "../utility/statusSubmitHandler";

interface UsersListProps extends BaseProps {
  users: User[] | null;
  isLoading: boolean;
  fromSearch: boolean;
  viewUserOverview: Dispatch<User>;
  editUser: Dispatch<User>;
  collectionRef: CollectionReference;
  deactivateUser: (user: User) => void;
}

function UsersList(props: UsersListProps) {
  const {users, fromSearch, viewUserOverview, editUser, collectionRef, isLoading, deactivateUser, toastProps} = props;
  const {setToastSeverity, setToastMessage, setIsToastOpen} = toastProps!;

  const [checkedUsers, setCheckedUsers] = useState<string[]>([]);
  const [isDialogOpen, {open: openDialog, close: closeDialog}] = useComponentToggler(false);

  if(isLoading) return <InProgress/>;

  if ((users || []).length === 0 && fromSearch)
    return <EmptySearchResults entity={Entity.User}/>;

  if((users || []).length === 0 && !fromSearch)
    return <EmptyList entity={Entity.User}/>;

  async function deactivateSelectedUsers() {
    let submittedData: StatusSubmitHandler<User>[] = [];
    await Promise.all(
      Array.from(checkedUsers).map(async (uid) => {
        const reference = doc(collectionRef, uid);
        await submitForm(reference, ActionType.Update,
          (status, data, isLastUpdate) => {
            isLastUpdate && submittedData.push({status, data, isLastUpdate})
          },
          {active: false}
          );
      })
    )

    bulkStatusSubmitHandler<User>({data: submittedData, successCallback, errorCallback});
  }

  function successCallback() {
    setToastMessage(enUserLabel.deactivateSuccess);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    closeDialog();
    setCheckedUsers([]);
  }

  function errorCallback(message: any) {
    let toastMessage = enCommonLabel.errorProcess(ProcessType.Delete);
    // if type of message is obj, get first obj value
    if (typeof message === "object" && (typeof Object.values(message)[0] === "string")) {
      toastMessage = Object.values(message)[0] as string;
    }

    closeDialog();
    setCheckedUsers([]);
    setToastMessage(toastMessage);
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
  }

  return (
    <Stack gap={1} mb={2}>
      <BulkActions
        users={users}
        checkedUsers={checkedUsers}
        setCheckedUsers={setCheckedUsers}
        deactivateSelectedUsers={openDialog}
      />
      <ConfirmDialog
        isOpen={isDialogOpen}
        handleClose={closeDialog}
        handleConfirm={deactivateSelectedUsers}
        title={enUserLabel.bulkDeactivateConfirmationTitle}
        text={enUserLabel.bulkDeactivateConfirmationText}
        confirmButtonText={enUserLabel.deactivate}
      />
      {(users || []).map((user) => (
        <UserCard
          key={`user-card-${user["@id"]!}`}
          user={user}
          checkedUsers={checkedUsers}
          setCheckedUsers={setCheckedUsers}
          viewUserOverview={viewUserOverview}
          editUser={editUser}
          deactivateUser={() => deactivateUser(user)}
        />
      ))}
    </Stack>
  )
}

export default UsersList;