import {Entity} from "enums/entity";

export interface InstructionData {
  headerName: string;
  description: string;
  required: string;
  defaultValue: string;
  format: string;
  example: string;
}

export type ImportEntity = Entity.Templates | Entity.Project | Entity.Asset | Entity.DataTag| Entity.OrganizationAsset | Entity.Form;

export enum headerNames {
  // START FORM
  "title" = "title",
  "field_type" = "field_type",
  "field_description" = "field_description",
  "response_is_required" = "response_is_required",
  "minimum_characters" = "minimum_characters",
  "maximum_characters" = "maximum_characters",
  "include_time" = "include_time",
  "items" = "items",
  "select_multiple" = "select_multiple",
  "allow_decimal" = "allow_decimal",
  // END FORM

  "name" = "name",
  "description" = "description",
  "color" = "color",
  "last_changed_by" = "last_changed_by",
  "asset_name" = "asset_name",
  "asset_id_code" = "asset_id_code",
  "asset_address" = "asset_address",
  "asset_latitude" = "asset_latitude",
  "asset_longitude" = "asset_longitude",
  "asset_data_tags" = "asset_data_tags",
  "asset_status" = "asset_status",
  "milestone_name" = "milestone_name",
  "milestone_description" = "milestone_description",
  "task_name" = "task_name",
  "task_description" = "task_description",
  "task_hidden" = "task_hidden",
  "task_data_tags" = "task_data_tags",
  "task_scheduled" = "task_scheduled",
  "task_use_time" = "task_use_time",
  "task_assignment" = "task_assignment",
  "task_status" = "task_status",
  "task_scheduled_by" = "task_scheduled_by",
  "task_scheduled_at" = "task_scheduled_at",
  "task_observer" = "task_observer",
  "task_approver" = "task_approver",
  "task_submitted_by" = "task_submitted_by",
  "task_submitted_at" = "task_submitted_at",
  "task_approved_by" = "task_approved_by",
  "task_approved_at" = "task_approved_at",
  "task_rejected_by" = "task_rejected_by",
  "task_rejected_at" = "task_rejected_at",
  "task_cancelled_by" = "task_cancelled_by",
  "task_cancelled_at" = "task_cancelled_at",
  "task_reference_file_name" = "task_reference_file_name",
  "requirement_name" = "requirement_name",
  "requirement_description" = "requirement_description",
  "requirement_type" = "requirement_type",
  "requirement_status" = "requirement_status",
  "requirement_hidden" = "requirement_hidden",
  "requirement_reference_file_name" = "requirement_reference_file_name",
  "requirement_data_tags" = "requirement_data_tags",
  "file_requirement_min" = "file_requirement_min",
  "file_requirement_max" = "file_requirement_max",
  "file_requirement_data_tags" = "file_requirement_data_tags",
  "reference_file_name" = "reference_file_name",
  "form_name" = "form_name",
}

export const instructionRows: Record<headerNames, InstructionData> = {
  // START FORM
  title: {
    headerName: `title`,
    description: `Title of the field`,
    required: `Yes`,
    defaultValue: ``,
    format: `Alphanumeric`,
    example: `Email`
  },
  field_description: {
    headerName: `description`,
    description: `Description of the field`,
    required: `No`,
    defaultValue: ``,
    format: `Alphanumeric`,
    example: `Personal email`
  },
  field_type: {
    headerName: `field_type`,
    description: `Input type of the field`,
    required: `Yes`,
    defaultValue: ``,
    format: `Text, Numeric, Paragraph, Date, List`,
    example: `Text`
  },
  response_is_required: {
    headerName: `response_is_required`,
    description: `Indicator either the input field is required or not`,
    required: `Yes`,
    defaultValue: `FALSE`,
    format: `TRUE or FALSE`,
    example: `TRUE`
  },
  minimum_characters: {
    headerName: `minimum_characters`,
    description: `Minimum Characters for input field "Paragraph"`,
    required: `No`,
    defaultValue: `0`,
    format: `number`,
    example: `10`
  },
  maximum_characters: {
    headerName: `maximum_characters`,
    description: `Maximum Characters for input field "Paragraph"`,
    required: `No`,
    defaultValue: ``,
    format: `number`,
    example: `100`
  },
  include_time: {
    headerName: `include_time`,
    description: `Indicator either to include time if the input field is "Date"`,
    required: `No`,
    defaultValue: `FALSE`,
    format: `TRUE or FALSE`,
    example: `FALSE`
  },
  items: {
    headerName: `items`,
    description: `List if item options if the input type "List"`,
    required: `No`,
    defaultValue: ``,
    format: `alphanumeric`,
    example: `Male,Female`
  },
  select_multiple: {
    headerName: `select_multiple`,
    description: `Indicator either the item options can be multiple selected if the input type is "List"`,
    required: `No`,
    defaultValue: `FALSE`,
    format: `TRUE or FALSE`,
    example: `FALSE`
  },
  allow_decimal: {
    headerName: `allow_decimal`,
    description: `Indicator either to allow decimal numbers in input field if the field type is "Numeric"`,
    required: `No`,
    defaultValue: `FALSE`,
    format: `TRUE or FALSE`,
    example: `FALSE`
  },
  // END FORM

  // START DATATAG
  name: {
    headerName: `name`,
    description: `Name of the datatag`,
    required: `Yes`,
    defaultValue: ``,
    format: `Alphanumeric`,
    example: `Important`
  },
  description: {
    headerName: `description`,
    description: `Description of the datatag`,
    required: `No`,
    defaultValue: ``,
    format: `Alphanumeric`,
    example: `Tag for important entity`
  },
  color: {
    headerName: `color`,
    description: `Tone color of the datatag`,
    required: `No`,
    defaultValue: ``,
    format: `tone_x_x`,
    example: `tone_0_0`
  },
  // END DATATAG

  milestone_name: {
    headerName: `milestone_name`,
    description: `Name of the milestone`,
    required: `Yes`,
    defaultValue: ``,
    format: `Alphanumeric`,
    example: `January`
  },
  milestone_description: {
    headerName: `milestone_description`,
    description: `Description of the milestone`,
    required: `No`,
    defaultValue: ``,
    format: `Alphanumeric`,
    example: `1st month of the year`
  },
  task_name: {
    headerName: `task_name`,
    description: `Name of a task`,
    required: `Yes`,
    defaultValue: ``,
    format: `Alphanumeric`,
    example: `01/15/2023`
  },
  task_description: {
    headerName: `task_description`,
    description: `Description of the task`,
    required: `No`,
    defaultValue: ``,
    format: `Alphanumeric`,
    example: `Please start and stop the task timer at the beginning and end of each day.`
  },
  task_scheduled: {
    headerName: `task_scheduled`,
    description: `When the task is scheduled`,
    required: `No`,
    defaultValue: ``,
    format: `MM/DD/YYYY or MM/DD/YYYY, HH:MM`,
    example: `10/15/2023`
  },
  task_use_time: {
    headerName: `task_use_time`,
    description: `Whether the task_schedule to be imported should include the time`,
    required: `No`,
    defaultValue: `False`,
    format: `True or False`,
    example: `False`
  },
  task_assignment: {
    headerName: `task_assignment`,
    description: `Email of the assigned person of the task`,
    required: `No`,
    defaultValue: ``,
    format: `Email address`,
    example: `juandelacruz@email.com`
  },
  task_status: {
    headerName: `task_status`,
    description: `Status of the task`,
    required: `Yes`,
    defaultValue: `Pending`,
    format: `Cancelled, Pending, In Progress, Submitted, Rejected, Approved`,
    example: `Approved`
  },
  task_data_tags: {
    headerName: `task_data_tags`,
    description: `Data tags of a task`,
    required: `No`,
    defaultValue: ``,
    format: `Comma-separated data tags`,
    example: `Daily Report,Employee`
  },
  task_scheduled_by: {
    headerName: `task_scheduled_by`,
    description: `Name of the person who scheduled the task`,
    required: `No`,
    defaultValue: ``,
    format: `Letters only`,
    example: `Juan Dela Cruz`
  },
  task_scheduled_at: {
    headerName: `task_scheduled_at`,
    description: `Date or date/time the task is scheduled`,
    required: `No`,
    defaultValue: ``,
    format: `MM/DD/YYYY or MM/DD/YYYY, HH:MM`,
    example: `10/15/2023`
  },
  task_submitted_by: {
    headerName: `task_submitted_by`,
    description: `Name of the person who submitted the task`,
    required: `No`,
    defaultValue: ``,
    format: `Letters only`,
    example: `Maria Clara`
  },
  task_submitted_at: {
    headerName: `task_submitted_at`,
    description: `Date or date/time the task was submitted`,
    required: `No`,
    defaultValue: `10/16/2023, 16:30`,
    format: `MM/DD/YYYY or MM/DD/YYYY, HH:MM`,
    example: `10/16/2023, 16:30`
  },
  task_approved_by: {
    headerName: `task_approved_by`,
    description: `Name of the person who approved the task`,
    required: `No`,
    defaultValue: `Jose Mari`,
    format: `Letters only`,
    example: `Jose Mari`
  },
  task_approved_at: {
    headerName: `task_approved_at`,
    description: `Date or date/time the task was approved`,
    required: `No`,
    defaultValue: `10/20/2023, 05:15`,
    format: `MM/DD/YYYY or MM/DD/YYYY, HH:MM`,
    example: `10/20/2023, 05:15`
  },
  task_rejected_by: {
    headerName: `task_rejected_by`,
    description: `Name of the person who rejected the task`,
    required: `No`,
    defaultValue: ``,
    format: `Letters only`,
    example: ``
  },
  task_rejected_at: {
    headerName: `task_rejected_at`,
    description: `Date or date/time the task was rejected`,
    required: `No`,
    defaultValue: ``,
    format: `MM/DD/YYYY or MM/DD/YYYY, HH:MM`,
    example: ``
  },
  task_cancelled_by: {
    headerName: `task_cancelled_by`,
    description: `Name of the person who cancelled the task`,
    required: `No`,
    defaultValue: ``,
    format: `Letters only`,
    example: ``
  },
  requirement_name: {
    headerName: `requirement_name`,
    description: `Name of the requirement`,
    required: `Yes`,
    defaultValue: ``,
    format: `Alphanumeric`,
    example: `Employee Review`
  },
  requirement_description: {
    headerName: `requirement_description`,
    description: `Description of the requirement`,
    required: `No`,
    defaultValue: ``,
    format: `Alphanumeric`,
    example: `Give a review to a specific employee`
  },
  requirement_type: {
    headerName: `requirement_type`,
    description: `Whether requirement is a File or a Form`,
    required: `Yes`,
    defaultValue: ``,
    format: `File or Form`,
    example: `Form`
  },
  requirement_status: {
    headerName: `requirement_status`,
    description: `Status of the requirement`,
    required: `No`,
    defaultValue: ``,
    format: `Cancelled, Pending, In Progress, Submitted, Rejected, Approved`,
    example: `Pending`
  },
  requirement_data_tags: {
    headerName: `requirement_data_tags`,
    description: `Data tags of a file requirement`,
    required: `No`,
    defaultValue: ``,
    format: `Comma-separated data tags`,
    example: `Employee,Review`
  },
  file_requirement_min: {
    headerName: `file_requirement_min`,
    description: `Minimum files required in a file requirement`,
    required: `No`,
    defaultValue: `0`,
    format: `Numeric`,
    example: ``
  },
  file_requirement_max: {
    headerName: `file_requirement_max`,
    description: `Maximum files required in a file requirement`,
    required: `No`,
    defaultValue: ``,
    format: `Numeric`,
    example: ``
  },
  form_name: {
    headerName: `form_name`,
    description: `Name of the form used in a form requirement`,
    required: `Yes – if requirement_type is “Form”`,
    defaultValue: ``,
    format: `Alphanumeric`,
    example: `Employee Review Form`
  },
  reference_file_name: {
    headerName: `reference_file_name`,
    description: `Name of the reference file used in the requirement`,
    required: `No`,
    defaultValue: ``,
    format: `Alphanumeric`,
    example: ``
  },
  asset_name: {
    headerName: `asset_name`,
    description: `Name of the asset`,
    required: `Yes`,
    defaultValue: ``,
    format: `Alphanumeric`,
    example: `Juan Dela Cruz`
  },
  asset_id_code: {
    headerName: `asset_id_code`,
    description: `ID code of the asset`,
    required: `Yes`,
    defaultValue: ``,
    format: `Alphanumeric`,
    example: `Ontel/2023/JuanDelaCruz`
  },
  asset_address: {
    headerName: `asset_address`,
    description: `Complete address of the asset`,
    required: `No`,
    defaultValue: ``,
    format: ``,
    example: ``
  },
  asset_latitude: {
    headerName: `asset_latitude`,
    description: `Latitude equivalent in the address`,
    required: `No`,
    defaultValue: ``,
    format: `Numeric`,
    example: `14.148423`
  },
  asset_longitude: {
    headerName: `asset_longitude`,
    description: `Longitude equivalent in the address`,
    required: `No`,
    defaultValue: ``,
    format: `Numeric`,
    example: `122.678455`
  },
  asset_data_tags: {
    headerName: `asset_data_tags`,
    description: `Data tags of an asset`,
    required: `No`,
    defaultValue: ``,
    format: `Comma-separated data tags`,
    example: `Employee,Ontel`
  },
  task_hidden: {
    headerName: `task_hidden`,
    description: `Whether the task is hidden or not`,
    required: `Yes`,
    defaultValue: `False`,
    format: `True or False`,
    example: `True`
  },
  requirement_hidden: {
    headerName: `requirement_hidden`,
    description: `Whether the requirement is hidden or not`,
    required: `Yes`,
    defaultValue: `False`,
    format: `True or False`,
    example: `False`
  },
  file_requirement_data_tags: {
    headerName: `file_requirement_data_tags`,
    description: `Data tags of a file requirement`,
    required: `No`,
    defaultValue: ``,
    format: ``,
    example: `Daily Reports`
  },
  task_cancelled_at: {
    headerName: `task_cancelled_at`,
    description: `Date or date/time the task was cancelled`,
    required: `No`,
    defaultValue: ``,
    format: `MM/DD/YYYY or MM/DD/YYYY, HH:MM`,
    example: ``
  },
  task_reference_file_name: {
    headerName: `task_reference_file_name`,
    description: `Name of the reference file`,
    required: `No`,
    defaultValue: ``,
    format: ``,
    example: ``
  },
  requirement_reference_file_name: {
    headerName: `requirement_reference_file_name`,
    description: `Name of the reference file`,
    required: `No`,
    defaultValue: ``,
    format: ``,
    example: ``
  },
  asset_status: {
    headerName: `asset_status`,
    description: `Date or date/time the task was cancelled`,
    required: `No`,
    defaultValue: ``,
    format: `Cancelled, Pending, In Progress, Approved`,
    example: `Approved`
  },
  task_observer: {
    headerName: `task_observer`,
    description: `Name/email of the observer`,
    required: `No`,
    defaultValue: ``,
    format: ``,
    example: ``
  },
  task_approver: {
    headerName: `task_approver`,
    description: `Name/email of the approver`,
    required: `No`,
    defaultValue: ``,
    format: ``,
    example: ``
  },
  last_changed_by: {
    headerName: `last_changed_by`,
    description: `Name/email of the last person modify the data`,
    required: `No`,
    defaultValue: ``,
    format: ``,
    example: ``
  },
}

export const requiredImportHeadersNotes: Record<ImportEntity, headerNames[]> = {
  [Entity.OrganizationAsset]: [
    headerNames["asset_name"],
  ],
  [Entity.Asset]: [
    headerNames["milestone_name"],
  ],
  [Entity.Project]: [
    headerNames["asset_name"],
    headerNames["asset_id_code"],
  ],
  [Entity.Templates]: [
    headerNames["milestone_name"],
  ],
  [Entity.DataTag]: [
    headerNames["name"],
  ],
  [Entity.Form]: [
    headerNames["title"],
    headerNames["field_type"],
    headerNames["response_is_required"],
  ]
}

export const requiredImportHeaders: Record<ImportEntity, headerNames[]> = {
  [Entity.OrganizationAsset]: [
    headerNames["asset_name"],
  ],
  [Entity.Asset]: [
    headerNames["milestone_name"],
    headerNames["task_name"],
    headerNames["requirement_name"],
    headerNames["requirement_type"],
    headerNames["requirement_status"],
    headerNames["file_requirement_min"],
    headerNames["file_requirement_max"],
    headerNames["form_name"],
  ],
  [Entity.Project]: [
    headerNames["asset_name"],
    headerNames["asset_id_code"],
    headerNames["milestone_name"],
    headerNames["task_name"],
    headerNames["requirement_name"],
    headerNames["requirement_type"],
    headerNames["requirement_status"],
    headerNames["file_requirement_min"],
    headerNames["file_requirement_max"],
    headerNames["requirement_data_tags"],
    headerNames["form_name"],
  ],
  [Entity.Templates]: [
    headerNames["milestone_name"],
    headerNames["task_name"],
    headerNames["requirement_name"],
    headerNames["requirement_type"],
    headerNames["file_requirement_min"],
    headerNames["file_requirement_max"],
    headerNames["form_name"],
  ],
  [Entity.DataTag]: [
    headerNames["name"],
  ],
  [Entity.Form]: [
    headerNames["title"],
    headerNames["field_type"],
    headerNames["response_is_required"],
  ]
}

export const entityInstructionsKeysDisplay: Record<ImportEntity, headerNames[]> = {
  [Entity.OrganizationAsset]: [
    headerNames["asset_name"],
  ],
  [Entity.Asset]: [
    headerNames["milestone_name"],
    headerNames["milestone_description"],
  ],
  [Entity.Project]: [
    headerNames["asset_name"],
    headerNames["asset_id_code"],
    headerNames["asset_address"],
    headerNames["asset_latitude"],
    headerNames["asset_longitude"],
    headerNames["asset_data_tags"],
  ],
  [Entity.Templates]: [
    headerNames["milestone_name"],
    headerNames["milestone_description"],
  ],
  [Entity.DataTag]: [
    headerNames["name"],
    headerNames["description"],
    headerNames["color"],
  ],
  [Entity.Form]: [
    headerNames["title"],
    headerNames["field_type"],
    headerNames["field_description"],
    headerNames["response_is_required"],
    headerNames["minimum_characters"],
    headerNames["maximum_characters"],
    headerNames["include_time"],
    headerNames["items"],
    headerNames["select_multiple"],
    headerNames["allow_decimal"],
  ]
}

export const allHeaders: Record<ImportEntity, headerNames[]> = {
  [Entity.Templates]: [
    headerNames["milestone_name"],
    headerNames["milestone_description"],
    headerNames["task_name"],
    headerNames["task_description"],
    headerNames["task_hidden"],
    headerNames["task_data_tags"],
    headerNames["task_reference_file_name"],
    headerNames["requirement_name"],
    headerNames["requirement_description"],
    headerNames["requirement_type"],
    headerNames["requirement_hidden"],
    headerNames["requirement_data_tags"],
    headerNames["requirement_reference_file_name"],
    headerNames["file_requirement_min"],
    headerNames["file_requirement_max"],
    headerNames["form_name"],
  ],
  [Entity.Project]: [
    headerNames["asset_name"],
    headerNames["asset_id_code"],
    headerNames["asset_status"],
    headerNames["asset_address"],
    headerNames["asset_latitude"],
    headerNames["asset_longitude"],
    headerNames["asset_data_tags"],
    headerNames["milestone_name"],
    headerNames["milestone_description"],
    headerNames["task_name"],
    headerNames["task_description"],
    headerNames["task_scheduled"],
    headerNames["task_use_time"],
    headerNames["task_assignment"],
    headerNames["task_observer"],
    headerNames["task_approver"],
    headerNames["task_status"],
    headerNames["task_data_tags"],
    headerNames["task_reference_file_name"],
    headerNames["task_scheduled_by"],
    headerNames["task_scheduled_at"],
    headerNames["task_submitted_by"],
    headerNames["task_submitted_at"],
    headerNames["task_approved_by"],
    headerNames["task_approved_at"],
    headerNames["task_rejected_by"],
    headerNames["task_rejected_at"],
    headerNames["task_cancelled_by"],
    headerNames["task_cancelled_at"],
    headerNames["requirement_name"],
    headerNames["requirement_description"],
    headerNames["requirement_type"],
    headerNames["requirement_status"],
    headerNames["requirement_data_tags"],
    headerNames["requirement_reference_file_name"],
    headerNames["file_requirement_min"],
    headerNames["file_requirement_max"],
    headerNames["form_name"],
  ],
  [Entity.Asset]: [
    headerNames["milestone_name"],
    headerNames["milestone_description"],
    headerNames["task_name"],
    headerNames["task_description"],
    headerNames["task_scheduled"],
    headerNames["task_use_time"],
    headerNames["task_assignment"],
    headerNames["task_observer"],
    headerNames["task_approver"],
    headerNames["task_status"],
    headerNames["task_data_tags"],
    headerNames["task_reference_file_name"],
    headerNames["task_scheduled_by"],
    headerNames["task_scheduled_at"],
    headerNames["task_submitted_by"],
    headerNames["task_submitted_at"],
    headerNames["task_approved_by"],
    headerNames["task_approved_at"],
    headerNames["task_rejected_by"],
    headerNames["task_rejected_at"],
    headerNames["task_cancelled_by"],
    headerNames["task_cancelled_at"],
    headerNames["requirement_name"],
    headerNames["requirement_description"],
    headerNames["requirement_type"],
    headerNames["requirement_status"],
    headerNames["requirement_data_tags"],
    headerNames["requirement_reference_file_name"],
    headerNames["file_requirement_min"],
    headerNames["file_requirement_max"],
    headerNames["form_name"],
  ],
  [Entity.DataTag]: [
    headerNames["name"],
    headerNames["description"],
    headerNames["color"],
    headerNames["last_changed_by"],
  ],
  [Entity.OrganizationAsset]: [
    headerNames["asset_name"],
  ],
  [Entity.Form]: [
    headerNames["title"],
    headerNames["field_type"],
    headerNames["description"],
    headerNames["response_is_required"],
    headerNames["minimum_characters"],
    headerNames["maximum_characters"],
    headerNames["include_time"],
    headerNames["items"],
    headerNames["select_multiple"],
    headerNames["allow_decimal"],
  ],
}