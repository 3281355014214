import {userPath} from "./FirebasePath";

/**
 * Get a document reference from a user's path.
 * @param uid - The user's id.
 * @param parentPath  - The parent document's path (collection reference path).
 * @param docId - The document id.
 * @returns string.
 */
export function getDocPath(uid: string, parentPath: string, docId?: string) {
  if (!docId) return `${userPath(uid).path}/${parentPath}`;

  return `${userPath(uid).path}/${parentPath}/${docId}`;
}