import {Chip, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import theme from "theme/theme";
import {en} from "language/en";
import {SystemIcons} from "assets/icons/system/system.index";
import React, {useRef} from "react";
import {DataTag} from "types/DataTag";
import {useAccess, useComponentToggler} from "hooks/index";
import {ToastProps} from "screens/BaseProps";
import {emptyFunction} from "constants/index";
import EditDataTagPopup from "components/DataTag/DataTagInput/EditDataTagPopup";
import {Entity} from "enums/entity";
import {PermissionEntity, PermissionOperationKey} from "types/Permission";
import {datatagColor} from "constants/datatagColor";
import {datatagToneColor} from "enums/datatagToneColor";
import {Box} from "@mui/system";

interface DataTagProps extends ToastProps {
  tag: DataTag & { type?: string };
  openEditDataTagPopover?: () => void;
  setToBeEditedTag?: (tag: DataTag) => void;
  isParentHidden?: boolean;
  maxChipWidth?: number;
  uid: string;
}

function DataTagComponent(props: DataTagProps) {
  const {
    tag,
    toastProps,
    isParentHidden,
    openEditDataTagPopover = emptyFunction,
    setToBeEditedTag = emptyFunction,
    maxChipWidth,
    uid,
    ...rest
  } = props;

  const chipRef = useRef<any>();
  const [isEditDataTagOpen, {open: openEditDataTag, close: closeEditDataTag}] = useComponentToggler(false);
  const [access] = useAccess({
    uid: uid!,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationDataTag
  });
  const {color = datatagToneColor.tone_0_0} = tag;

  function editTag(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    e.preventDefault();

    if (props.openEditDataTagPopover === undefined) {
      openEditDataTag();
      return;
    }

    setToBeEditedTag(tag);
    openEditDataTagPopover();
  }

  return <>
    <EditDataTagPopup
      toastProps={toastProps!}
      isOpen={isEditDataTagOpen}
      onClose={closeEditDataTag}
      tag={tag}
      anchorEl={chipRef.current}
      uid={uid}
    />
    <Tooltip
      placement="bottom-start"
      enterNextDelay={300}
      enterDelay={400}
      color="#000"
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: theme.palette.secondary.light,
            ".MuiTooltip-arrow": {
              color: theme.palette.secondary.light,
            },
            zIndex: -1,
          },
        },
      }}
      title={(
        <>
          <Typography>
            {tag.description}
          </Typography>
          <Stack direction="row" alignItems="center" gap={1} justifyContent="space-between">
            {typeof (tag.type) === "undefined" && (
              <Typography color="neutral.main">
                {en.common.label.dataTags.lastChangedBy(tag.lastChangedBy?.name ?? "")}
              </Typography>
            )}
            {(access?.[PermissionOperationKey.Update] && !tag.type) && <IconButton onClick={editTag}>
                <SystemIcons.Edit stroke="white"/>
            </IconButton>
            }
          </Stack>
        </>
      )}
      arrow>
      <Chip
        size="small"
        deleteIcon={(
          <Box style={{marginTop: 6}}>
            <SystemIcons.Close stroke={datatagColor[color].color} width="16px" height="16px"/>
          </Box>
        )}
        {...rest}
        ref={chipRef}
        label={!!tag.type ? `ref:${tag.name}` : tag.name}
        color="chip"
        sx={{
          paddingBottom: 0.3,
          borderRadius: 1,
          cursor: "pointer",
          ...(isParentHidden ? {
            borderWidth: 1,
            borderStyle: "solid",
          } : {}),
          "&:hover": {},
          "& .MuiChip-label": {
            maxWidth: maxChipWidth ?? theme.spacing(28)
          },
          ...datatagColor[color],
        }}
        key={`${tag.id}`}
        className="data-tag-chip-item"
      />
    </Tooltip>
  </>
}

export default DataTagComponent
