import {Grid} from "@mui/material";
import {Project} from "types/Project";
import ProjectCard, {MENU_BUTTONS} from "components/ProjectsView/ProjectCard";
import {Entity, ExportProjectType} from "enums/index";
import AssetProjectCard from "components/ProjectsView/AssetProjectCard";
import {projectExportsPath, projectPath} from "screens/utility/FirebasePath";
import {ManageAccessDrawer} from "components/Drawers";
import React, {useEffect, useState} from "react";
import useComponentToggler from "hooks/useComponentToggler";
import {BaseProps} from "screens/BaseProps";
import {useParams} from "react-router-dom";
import {collection, DocumentReference} from "firebase/firestore";
import EmptySearchResults from "components/EmptySearchResults";
import {milestoneTaskandAssetStatus} from "enums/milestoneTaskandAssetStatus";
import {allStatus, enProjectLabel} from "constants/index";
import ImportDialog from "components/Dialogs/ImportDialog";
import {ProjectAction} from "screens/Projects";
import {ExportDialog} from "components/Dialogs";
import {AllOrder, MiscOrder} from "enums/DirectionalOrder";

interface ProjectListProps extends BaseProps {
  projects: Project[];
  projectsRefPath: string;
  onSelectedClick: (obj: Project | null, action: ProjectAction) => void;
  parentEntity: Entity.Organization | Entity.Asset;
  fromAlgolia: boolean;
  statusFilter: milestoneTaskandAssetStatus | string;
  deleteProject: (obj: Project) => void;
  order: AllOrder;
}

function ProjectList(props: ProjectListProps) {
  const {projects, parentEntity, statusFilter, toastProps, fromAlgolia, uid, order} = props;
  const {onSelectedClick, deleteProject} = props;

  const {orgId} = useParams();

  const [documentId, setDocumentId] = useState<string | null>(null);
  const [documentRef, setDocumentRef] = useState<DocumentReference | null>(null);
  const [isManageAccessDrawerOpen, {
    open: openManageAccessDrawer,
    close: closeManageAccessDrawer
  }] = useComponentToggler(false);

  const [isImportDialogOpen, {
    open: openImportDialog,
    close: closeImportDialog
  }] = useComponentToggler(false);

  const [isExportDialogOpen, {
    open: openExportDialog,
    close: closeExportDialog
  }] = useComponentToggler(false);

  useEffect(() => {
    if (documentRef !== null) return;
    closeManageAccessDrawer();
  }, [documentRef]);

  function updateSelectedProj(obj: Project | null, button: MENU_BUTTONS) {
    if (!obj) return;

    setDocumentId(obj["@id"]!);
    setDocumentRef(projectPath(orgId!, obj["@id"]!));

    switch (button) {
      case MENU_BUTTONS.EDIT:
        return onSelectedClick(obj, ProjectAction.Edit);
      case MENU_BUTTONS.DOWNLOAD_TIME_DATA:
        return onSelectedClick(obj, ProjectAction.DownloadTimeData);
      case MENU_BUTTONS.EXPORT:
        return openExportDialog();
      case MENU_BUTTONS.IMPORT:
        return openImportDialog();
      default:
        return openManageAccessDrawer();
    }
  }

  if (projects.length === 0 && (fromAlgolia || statusFilter !== allStatus || order === MiscOrder.lastVisited))
    return <EmptySearchResults entity={Entity.Project}/>

  const exportsPath = !!documentId ? projectExportsPath(orgId!, documentId!) : null;
  return (
    <>
      <ImportDialog
        isOpen={isImportDialogOpen}
        entity={Entity.Project}
        toastProps={toastProps!}
        closeDialog={closeImportDialog}
        parentDocumentRef={documentRef!}
        uid={uid}
      />
      <ExportDialog
        isOpen={isExportDialogOpen}
        onClose={closeExportDialog}
        toastProps={toastProps!}
        title={enProjectLabel.exportProjectData}
        exportingTitle={enProjectLabel.exportingProjectData}
        message={enProjectLabel.exportProjectDataText}
        exportColRef={exportsPath!}
        exportType={ExportProjectType.ProjectData}
      />
      {documentId && <ManageAccessDrawer
        uid={uid!}
        isOpen={isManageAccessDrawerOpen}
        entity={Entity.Project}
        documentId={documentRef?.id!}
        collectionRef={collection(documentRef!, "accessList")}
        onDrawerClose={closeManageAccessDrawer}
        toastProps={toastProps}
      />}
      <Grid container gap={0} flexWrap="wrap" maxWidth="100%" mt={1}>
        {projects.map((proj: Project) => {
          if (parentEntity === Entity.Organization) {
            return (
              <ProjectCard
                project={proj}
                parentPath={props.projectsRefPath}
                uid={uid!}
                toastProps={toastProps!}
                key={proj["@id"]}
                updateSelected={updateSelectedProj}
                deleteProject={deleteProject}
              />
            )
          }

          return (
            <AssetProjectCard
              project={proj}
              uid={uid!}
              toastProps={toastProps!}
              key={proj["@id"]}
              projectsRefPath={props.projectsRefPath}
            />
          )
        })}
      </Grid>
    </>
  );
}

export default ProjectList;
