import {onSnapshot, query, QueryConstraint, CollectionReference, orderBy,} from "firebase/firestore";
import {useEffect, useState} from "react";
import {DirectionalOrder} from "enums/DirectionalOrder";

const defaultQueryConstraints = [orderBy("@id")];

function useCollection<Type>(
  searchedIdArray: string[] | null,
  colRef: CollectionReference | null,
  constraints: QueryConstraint[] | null = [orderBy("name", DirectionalOrder.asc)],
  byPassDefaultConstraints: boolean = false,
  forceStopRerender = false,
  isParentMounted = true
): [
  Type[] | null,
  (constraints: QueryConstraint[]) => void,
  (newColRef: CollectionReference | null) => void,
  boolean,
  string | undefined,
  any | null
] {
  const [collectionData, setCollectionData] = useState<Type[] | null>(null);

  const [providedConstraints, setProvidedConstraints] = useState<QueryConstraint[]>(
    [
      ...(constraints === null ? [] : constraints),
      ...(byPassDefaultConstraints ? [] : defaultQueryConstraints)
    ]
  );
  const [collectionReference, setCollectionReference] = useState<CollectionReference | null>(null);
  const [lastDoc, setLastDoc] = useState<any | null>(null); // for pagination
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (colRef === null) return;

    // reset collection data if parent is unmounted
    if (!isParentMounted) {
      setCollectionData(null);
      setCollectionReference(null);
      return;
    }

    if (collectionReference !== null) return;
    return () => setCollectionReference(colRef);
  }, [colRef]);

  useEffect(() => {
    if (!isParentMounted) return;

    if (collectionReference === null) {
      setCollectionData(null);
      return;
    }

    if (searchedIdArray && searchedIdArray.length === 0) {
      setCollectionData([]);
      return;
    }

    // disable query if defaultOrg is selected
    if (collectionReference.path.split("/").includes("defaultOrg")) {
      setCollectionData([]);
      return;
    }

    setIsLoading(true);

    const collectionQuery = query(collectionReference, ...providedConstraints);
    const collectionListener = onSnapshot(collectionQuery,
      (querySnapshot) => {
        setIsLoading(false);
        // to fix firebase error where it only allows 10 array elements in the "in" query
        if (searchedIdArray !== null && searchedIdArray.length > 0) {
          let returnedCollection: Type[] = querySnapshot.docs.filter(doc => {
            if (searchedIdArray.includes(doc.id)) {
              return true;
            }
          }).map((doc) => {
            const data = doc.data() as Type;
            // @ts-ignore
            return ("id" in data ? data : {"id": doc.id, ...data});
          });
          setCollectionData([...returnedCollection]);
          return;
        }

        let returnedCollection: Type[] = querySnapshot.docs.map((doc) => {
          const data = doc.data() as Type;
          // @ts-ignore
          return ("id" in data ? data : {"id": doc.id, ...data});
        });

        setCollectionData([...returnedCollection]);
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1] || null);
      }, (e) => {
        setIsLoading(false);
        setCollectionData([]);
        console.log('Error getting documents', e, " for collection ", collectionReference.path)
      });

    return () => {
      collectionListener();
    }
  }, [searchedIdArray, providedConstraints, collectionReference]);

  function updateColRef(newColRef: CollectionReference | null) {
    setCollectionReference(newColRef);
  }

  function updateProvidedConstraints(constraints: QueryConstraint[]) {
    if (!forceStopRerender)
      setCollectionData(null);

    setProvidedConstraints([
      ...constraints,
      ...(byPassDefaultConstraints ? [] : defaultQueryConstraints)
    ]);
  }

  return [collectionData, updateProvidedConstraints, updateColRef, isLoading, collectionReference?.path, lastDoc];
}

export default useCollection;
