import {AccessListUser} from "types/AccessListUser";
import {Stack, Typography} from "@mui/material";
import {Avatar, LongText} from "components/index";
import React from "react";
import {AccessUserType} from "enums/accessUserType";
import theme from "theme/theme";
import {enCommonLabel} from "constants/index";
import {auth} from "../../../firebase";

interface ListItemProps {
  member: AccessListUser;
}

function ListItem(props: ListItemProps) {
  const {member} = props;
  const {name, "@id": id} = member;
  const currUserId = auth.currentUser?.uid;

  return (
    <Stack direction="row" gap={1.5} alignItems="center">
      <Avatar
        {...member}
        isTeam={member.accessUserType === AccessUserType.Team}
        sx={{mt: "0px", fontSize: "12px"}}
      />
      <Stack direction="column">
        <Stack direction="row" gap={1}>
          <LongText
            maxLines={1}
            id={`${id}-member-item-name`}
            sx={{fontWeight: 600, fontSize: "14px", color: theme.palette.secondary.dark}}
          >
            {name}
          </LongText>
          <Typography variant="body1" color={theme.palette.neutral.medium}>
            {currUserId === id && `(${enCommonLabel.you})`}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ListItem;
