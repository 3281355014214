import {LongText} from "components/index";
import {enCommonLabel} from "constants/index";
import React from "react";
import {Typography} from "@mui/material";

interface TagDescriptionProps {
  description: string | undefined;
}
function TagDescription(props: TagDescriptionProps) {
  const {description} = props;

  if (!!description) {
    return (
      <LongText color="text.secondary" variant="bodySmall" maxWidth="100%" mb={1} overflowWrap="anywhere">
        {description}
      </LongText>
    )
  }

  return (
    <Typography variant="bodySmall" color="text.secondary" textAlign="center">
      <i>{enCommonLabel.noDescription}</i>
    </Typography>
  )
}

export default TagDescription;