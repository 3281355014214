import {
  entityInstructionsKeysDisplay,
  headerNames,
  InstructionData,
  instructionRows
} from "constants/headerConstants";
import {Entity} from "enums/entity";

export function getImportInstructionRows(entity: Entity) {
  const rows: InstructionData[] = [];

  // @ts-ignore
  entityInstructionsKeysDisplay[entity].forEach((key) => {
    rows.push(instructionRows[key as headerNames])
  })

  return rows;
}