import styled from "@emotion/styled";
import {LoadingButton} from "@mui/lab";
import {Box, Typography} from "@mui/material";
import {StatusIcons} from "assets/icons/status/status.index";
import {assetPath, organizationAssetPath} from "screens/utility/FirebasePath";
import submitForm from "screens/utility/submitForm";
import {ActionType} from "enums/actionType";
import {Severity} from "enums/severity";
import {enAssetLabel, enCommonButton, enCommonLabel} from "constants/index";
import theme from "theme/theme";
import {bulkStatusSubmitHandler, StatusSubmitHandler} from "../../../utility/statusSubmitHandler";
import {Asset} from "types/Asset";
import {ProcessType} from "enums/processType";
import {BaseProps} from "../../../BaseProps";
import {useNavigate, useParams} from "react-router-dom";

interface SaveButtonProps extends BaseProps {
  isSaving: boolean;
  modifiedAssets: Asset[];
  modifiedAssetsSetter: React.Dispatch<React.SetStateAction<Asset[]>>;
  savingStateSetter: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

function AssetSaveButton(props: SaveButtonProps) {
  const {isSaving, modifiedAssets, modifiedAssetsSetter, savingStateSetter, disabled, toastProps} = props;
  const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps!;
  const {orgId, projId} = useParams();

  const navigate = useNavigate();
  function handleSaveButtonClick() {
    savingStateSetter(true);
    let submittedData: StatusSubmitHandler<Asset>[] = [];
    modifiedAssets.forEach(async (asset) => {
      const reference = asset.isChecked ? organizationAssetPath(orgId!, asset["@id"]!) : assetPath(orgId!, projId!, asset["@id"]!)

      await submitForm(
        reference,
        ActionType.Update,
        (status, data, isLastUpdate) => {
          isLastUpdate && submittedData.push({status, data, isLastUpdate});
        },
        {
          isChecked: asset.isChecked,
          projectId: projId!
        });
    });

    bulkStatusSubmitHandler<Asset>({data: submittedData, successCallback, errorCallback});
  }

  function successCallback() {
    savingStateSetter(false);
    modifiedAssetsSetter([]);

    const checkedAssets = modifiedAssets.filter(asset => asset.isChecked);
    const unCheckedAssets = modifiedAssets.filter(asset => !asset.isChecked);

    let toastMessage = enAssetLabel.assetUpdatedSuccess;

    if (checkedAssets.length === 0) {
      toastMessage = enAssetLabel.assetRemovedSuccess;
    } else if (unCheckedAssets.length === 0) {
      toastMessage = enAssetLabel.assetAddedSuccess;
    }

    setToastMessage(toastMessage);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);

    navigate(`/${orgId}/projects/${projId}/assets&tasks`);
  }

  function errorCallback(message: any) {
    setToastMessage(message || enCommonLabel.errorProcess(ProcessType.Update));
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
  }

  return (
    <ButtonWrapper sx={{pt: 4}}>
      <SaveButton
        loading={isSaving}
        startIcon={!isSaving && <StatusIcons.Saved stroke={theme.palette.background.paper}/>}
        onClick={handleSaveButtonClick}
        id="saveAssetsButton"
        disabled={disabled}
      >
        <Typography variant="h4">
          {enCommonButton.save}
        </Typography>
      </SaveButton>
    </ButtonWrapper>
  );
}

const SaveButton = styled(LoadingButton)({
  backgroundColor: theme.palette.primary.main,
  color: "white",
  padding: "4px 16px",
  width: 90,
  height: 40,
  borderRadius: 4,
  ":hover": {
    backgroundColor: "#0261fa"
  },
  ":disabled": {
    backgroundColor: theme.palette.neutral.main,
  }
});

const ButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
});

export default AssetSaveButton;
