import {MenuCard, MenuCardProps} from "components/index";
import {RequirementStatus} from "enums/requirementStatus";
import {MenuItem, Typography} from "@mui/material";

interface FilterStatusMenuProps extends MenuCardProps {
  onClick: (status: string) => void
}

function FilterStatusMenu(props: FilterStatusMenuProps) {
  const {anchorEl, open, closeMenu, onClick} = props;

  let statusValues = ["All"];
  Object.values(RequirementStatus).forEach(value => statusValues.push(value));

  return (
    <>
      <MenuCard anchorEl={anchorEl} open={open} closeMenu={closeMenu}>
        {
          statusValues.map(status =>
            (<MenuItem
              value={status}
              id={`task-overview-filter-status-${status}`}
              key={`task-overview-filter-status-${status}`}
              onClick={() => onClick(status)}
            >
              <Typography variant="body">{status}</Typography>
            </MenuItem>)
          )
        }
      </MenuCard>
    </>
  )
}

export default FilterStatusMenu;