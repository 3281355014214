import theme from "theme/theme";
import React from "react";
import {PreviewProps} from "components/FilePreviews/PreviewProps";

interface ImagePreviewProps extends PreviewProps {
  differentRejectedPreview: boolean
  itemSx?: any;
  withBorder?: boolean;
}

function ImagePreview(props: ImagePreviewProps) {
  const {downloadUrl, id = "n", differentRejectedPreview, onClick, itemSx, withBorder} = props;

  if(!downloadUrl)
    return <></>

  return <div id={`image-preview-${id}`} className="file-preview-item" style={{
    backgroundColor: theme.palette.background.swiftDefault,
    borderRadius: 4,
    ...(withBorder && {
      border: `1px solid ${theme.palette.neutral.light}`,
      overflow: "hidden",
    })
  }}>
    <img
      style={{
        minWidth: differentRejectedPreview ? undefined: "64px",
        minHeight: differentRejectedPreview ? undefined : "64px",
        width: differentRejectedPreview ? "64px" : undefined,
        height: differentRejectedPreview ? "64px" : undefined,
        objectFit: "contain",
        ...itemSx
      }}
      className="image-preview"
      alt="file preview"
      src={downloadUrl}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
    />
  </div>
}

export default ImagePreview;
