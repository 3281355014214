import {filePreviewTargetHeight} from "constants/index";

export default function createPreviewFile(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (e) => {
      let imgUrl = e.target!.result;
      const img = document.createElement("img")
      img.src = imgUrl as string;

      img.onload = (e) => {
        const imgElement = e.target as HTMLImageElement;

        const ratio = filePreviewTargetHeight / imgElement.height

        const canvas = document.createElement("canvas");
        canvas.height = filePreviewTargetHeight
        canvas.width = ratio * imgElement.width
        const context = canvas.getContext("2d")!;

        context.drawImage(img, 0, 0, canvas.width, canvas.height)
        const newImgUrl = context.canvas.toDataURL("image/jpeg");
        resolve(newImgUrl as string)
      }
    }
  });
}