import {useContext, useState} from "react";
import {toastProps} from "screens/BaseProps";
import {createPreviewFile, fileUploadsPath} from "screens/utility";
import {doc, Timestamp} from "firebase/firestore";
import {FileUploadStatus, FileUploadType, UserFileType} from "enums/index";
import {UploadActionType} from "hooks/useFileUpload/reducer/UploadActionType";
import {BreadCrumbType} from "screens/utility/generateEntityBreadcrumbs";
import {groupBy} from "hooks/useFileUpload/utils";
import {UploadContext} from "hooks/useFileUpload/context/UploadContext";

export interface UploadFile {
  file: File;
  queueId: string;
  parentCollectionPath: string;
  type?: FileUploadType | UserFileType;
  overwrite?: boolean;
  referenceId?: string; // for re-upload
  timeCreated: Timestamp;
  breadCrumbs?: BreadCrumbType[]; // for file upload panel
}

export interface UploadWorkerFile extends UploadFile {
  fileUploadsDocPath: string;
  "#previewValue"?: string; // preview value for image
}

export interface ProcessingFile extends UploadFile {
  index: number; // to be used when sorting files
  id: string; // file uploads doc id
  length: number; // file size
  lengthUploaded: number; // bytes uploaded
  contentType: string; // content type of the attached file
  "#previewValue"?: string; // preview value for image
  tempStorageFileName: string; // needed by backend
  parentId: string; // id of parent doc, derived from the parentCollectionPath (e.g. fileReqId, chatMessageId)
  timeDrop: Timestamp; // time the file was dropped, used to sort files;
  timezone: string; // timezone of the user, needed by BE
  fileUploadStatus: FileUploadStatus;
  tempFilePreviewPath: string | null; // for reference file
  timeCreatedNumber: number;
}

interface FileUploadProps {
  toastProps?: toastProps;
  uid: string;
}

function useFileUpload(props: FileUploadProps) {
  const {uid} = props;
  const context = useContext(UploadContext);

  const {fileUploadState, fileUploadDispatch} = context!;

  const [processingFiles, setProcessingFiles] = useState<ProcessingFile[]>([]);
  const [errorFiles, setErrorFiles] = useState<ProcessingFile[]>([]);

  function addToProcessing(processingFile: ProcessingFile) {
    fileUploadDispatch && fileUploadDispatch({
      type: UploadActionType.addToProcessingFileList,
      payload: processingFile,
    })
  }

  function addToErrors(errorFile: ProcessingFile) {
    fileUploadDispatch && fileUploadDispatch({
      type: UploadActionType.addToErrorFileList,
      payload: errorFile,
    })
  }

  function addToCompleted() {
    fileUploadDispatch && fileUploadDispatch({
      type: UploadActionType.addToCompletedCount,
      payload: 1,
    });
  }

  const handleFileUpload = async (fileToUpload: UploadWorkerFile[], batchId: number) => {
    fileUploadDispatch && fileUploadDispatch({
      type: UploadActionType.addToProcessingBatchId,
      payload: batchId,
    });

    const workerInstance = new Worker(new URL('hooks/useFileUpload/worker.ts', import.meta.url));
    let completedIds: string[] = [];

    workerInstance.onmessage = async (e) => {
      const {completed, ...rest} = e.data;

      switch (e.data.fileUploadStatus) {
        case FileUploadStatus.Uploading:
          addToProcessing(rest);
          break;
        case FileUploadStatus.Failed:
          addToErrors(rest)
          break;
        case FileUploadStatus.Uploaded:
          completedIds.push(rest.id);
          addToCompleted();
          if (completedIds.length === fileToUpload.length) {
            workerInstance.terminate();
            fileUploadDispatch && fileUploadDispatch({
              type: UploadActionType.removeBatch,
              payload: {batchId, id: rest.id, queueId: rest.queueId}
            });
          }
          break;
        default:
          break;
      }
    }

    workerInstance.postMessage({fileToUpload, uid});
  }

  const addFiles = async (files: UploadFile[]) => {
    if (files.length === 0) return;

    // group files by 5
    const groupedFiles = groupBy(files, 5);
    for (const groupedFile of groupedFiles) {
      const preparedFiles = await Promise.all(
        groupedFile.map(
          async (file) => {
            const {referenceId, file: fileObj} = file;
            const fileUploadsDoc = referenceId ? doc(fileUploadsPath(uid), referenceId) : doc(fileUploadsPath(uid));
            const additionalProps: any = {};
            if ((/image/).test(fileObj.type)) {
              additionalProps["#previewValue"] = await createPreviewFile(fileObj);
            }

            return {
              ...file,
              fileUploadsDocPath: fileUploadsDoc.path,
              ...additionalProps,
              timeCreatedNumber: new Date().getTime(),
            } as UploadWorkerFile;
          }
        )
      );

      fileUploadDispatch({
        type: UploadActionType.addToBatch,
        payload: preparedFiles,
      })
    }
  }

  return {
    processingFiles,
    errorFiles,
    fileUploadState,
    fileUploadDispatch,
    addFiles,
    handleFileUpload,
  };
}

export default useFileUpload;