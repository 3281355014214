import {useLocation, useNavigate} from "react-router-dom";
import {Box, Button, Hidden, Stack, styled, Typography} from "@mui/material";
import React, {useEffect} from "react";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {InProgress} from "components/index";
import {SwiftLogoOnly} from "assets/icons/SwiftLogo";
import FormContainer from "components/FormContainer";
import {enCommonButton, enCommonLabel} from "constants/index";
import {emailSupport} from "constants/email";
import useMaintenance from "hooks/useMaintenance";
import {auth} from "../../firebase";

export default function SignUpSuccess() {
  const {state} = useLocation();
  useMaintenance(false);
  const navigate = useNavigate();
  const email = (state as any)?.email;

  useEffect(() => {
    if (!email) navigate("/");
  }, []);

  if (!email) {
    return <InProgress/>
  }

  window.history.replaceState({}, document.title); // Remove history state after getting email

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.secondary.main,
        zIndex: "-2!important",
        backgroundImage: `url("/assets/Artboard6.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{height: "100vh"}}
      >
        <FormContainer>
          <Stack justifyContent="center" gap={2} textAlign="center">
            <Box
              sx={{
                backgroundColor: theme.palette.neutral.light,
                borderRadius: "50%",
                alignSelf: "center",
                display: "flex",
                padding: 1,
              }}
            >
              <SystemIcons.Selected fill={theme.palette.primary.main} width={48} height={48}/>
            </Box>
            <Typography variant="h2">{enCommonLabel.almostThere}</Typography>
            <Stack>
              <Typography variant="body">{enCommonLabel.signupSuccessSubTextLine1}</Typography>
              <Typography variant="body">{enCommonLabel.signupSuccessSubTextLine2}</Typography>
            </Stack>
            <Typography
              variant="h4"
              sx={(theme) => ({
                padding: 1,
                backgroundColor: theme.palette.background.swiftDefault,
                borderRadius: 1
              })}
            >
              {email}
            </Typography>
            <Stack>
              <Typography variant="body">{enCommonLabel.signupSuccessSubTextLine3}</Typography>
              <Typography variant="body">{enCommonLabel.signupSuccessSubTextLine4}</Typography>
            </Stack>
            <Stack>
              <Typography variant="body">{enCommonLabel.signupSuccessSubTextLine5}</Typography>
              <Typography variant="body">{enCommonLabel.signupSuccessSubTextLine6}</Typography>
            </Stack>
            <Typography variant="body">
              {enCommonLabel.stillFacingProblems}
              <StyledAnchor href={`mailto:${emailSupport}`}>
                <b>&nbsp;{enCommonLabel.contactUs}</b>
              </StyledAnchor>.
            </Typography>
            <Button
                variant="outlined"
                onClick={async () => {
                  await auth.signOut();
                  navigate(`/defaultOrg/home?adminPanel=false`);
                }}
                sx={{
                  marginTop: 2,
                  borderRadius: 5,
                  color: theme.palette.secondary.main,
                  borderColor: theme.palette.secondary.main,
                  padding: 1,
                  "&:hover": {
                    color: theme.palette.primary.main,
                  }
                }}
            >
              {enCommonLabel.goToLogin}
            </Button>
          </Stack>
        </FormContainer>
      </Stack>
      <Hidden smDown>
        <Box sx={{position: "absolute", bottom: "2%", right: "2%", zIndex: 2}}>
          <SwiftLogoOnly
            height={40}
            width={40}
            stroke={theme.palette.background.default}
            fill={theme.palette.background.default}
          />
        </Box>
      </Hidden>
    </Stack>
  )
}

const StyledAnchor = styled('a')`text-decoration: none;
  color: ${theme.palette.primary.main};`