import {Entity} from "enums/entity";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";

export const entitySystemIcons = {
  [Entity.Project]: (
    <SystemIcons.Projects
      strokeWidth={2}
      stroke={theme.palette.neutral.dark}
    />
  ),
  [Entity.Asset]: (
    <SystemIcons.Assets
      strokeWidth={2}
      stroke={theme.palette.neutral.dark}
    />
  ),
  [Entity.Task]: (
    <SystemIcons.Tasks
      strokeWidth={2}
      stroke={theme.palette.neutral.dark}
    />
  ),
  [Entity.FileRequirement]: (
    <SystemIcons.Requirement
      strokeWidth={2}
      stroke={theme.palette.neutral.dark}
    />
  ),
  [Entity.File]: (
    <SystemIcons.FilesOutlined
      strokeWidth={2}
      stroke={theme.palette.neutral.dark}
    />
  ),
  [Entity.ProjectLevelTask]: (
    <SystemIcons.Tasks
      strokeWidth={2}
      stroke={theme.palette.neutral.dark}
    />
  ),
  [Entity.Form]: (
    <SystemIcons.Form
      strokeWidth={2}
      stroke={theme.palette.neutral.dark}
    />
  ),
  [Entity.FormRequirement]: (
    <SystemIcons.Form
      strokeWidth={2}
      stroke={theme.palette.neutral.dark}
    />
  ),
  [Entity.PLFormRequirement]: (
    <SystemIcons.Form
      strokeWidth={2}
      stroke={theme.palette.neutral.dark}
    />
  ),
  [Entity.PLTask]: (
    <SystemIcons.Tasks
      strokeWidth={2}
      stroke={theme.palette.neutral.dark}
    />
  ),
  [Entity.PLFile]: (
    <SystemIcons.FilesOutlined
      strokeWidth={2}
      stroke={theme.palette.neutral.dark}
    />
  ),
  [Entity.SwiftFile]: (
    <SystemIcons.FilesOutlined
      strokeWidth={2}
      stroke={theme.palette.neutral.dark}
    />
  ),
  [Entity.PLFileRequirement]: (
    <SystemIcons.Requirement
      strokeWidth={2}
      stroke={theme.palette.neutral.dark}
    />
  ),
}