import {Stack} from "@mui/material";

interface TabGroupProps {
  children?: React.ReactNode,
  id?: string,
}

function TabGroup(props: TabGroupProps) {
  const {children, id = "tabGroup"} = props
  return (
    <Stack direction="row" gap={2} id={id}>
      {children}
    </Stack>
  );
}

export default TabGroup;
