import React, {useContext, useEffect, useRef, useState} from "react";
import {Box, Paper, Stack, styled, Typography} from "@mui/material";
import UploadList from "./Tabs/Uploads/UploadList";
import theme from "theme/theme";
import {toastProps} from "../BaseProps";
import {UploadContext} from "hooks/useFileUpload/context/UploadContext";
import PanelTitle from "./PanelTitle";
import {CustomTab, CustomTabs, TabPanel} from "components/index";
import {enCommonLabel} from "constants/index";
import ExportList from "./Tabs/Exports/ExportList";
import {submitForm, userExportsPath, userPath} from "../utility";
import {en} from "language/en";
import {DeleteDialog} from "components/Dialogs";
import {useCollection, useComponentToggler} from "hooks/index";
import {Severity} from "enums/severity";
import {ActionType} from "enums/actionType";
import {statusSubmitHandler} from "../utility/statusSubmitHandler";
import {limit, where} from "firebase/firestore";

enum TabIndex {
  Uploads,
  Exports
}

interface FileUploadPanelProps {
  toastProps: toastProps;
  uid: string;
}

function FileUploadPanel(props: FileUploadPanelProps) {
  const {toastProps, uid, ...rest} = props;
  const {setToastMessage, setIsToastOpen, setToastSeverity} = props.toastProps;

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isMinimized, setIsMinimized] = useState<boolean>(true);

  const [activeTab, setActiveTab] = useState<TabIndex>(TabIndex.Uploads);

  // clear related states
  const [isClearLoading, setIsClearLoading] = useState<boolean>(false);
  const [isClearDialogOpen, {open: openClearDialog, close: closeClearDialog}] = useComponentToggler(false);

  // count current exports
  const [exports] = useCollection(null, userExportsPath(uid!), [where("downloaded", "==", true), limit(1)]);

  const context = useContext(UploadContext);
  const {fileUploadState, fileUploadDispatch} = context!;
  const {isPanelOpen} = fileUploadState;

  const containerRef = useRef(null);

  useEffect(() => {
    if (isCollapsed && !isMinimized)
      setIsMinimized(true);
  }, [isCollapsed]);

  function handleTabChange(event: React.SyntheticEvent, newValue: number) {
    setActiveTab(newValue);
  }

  async function clearAllExports() {
    setIsClearLoading(true);
    const userDoc = userPath(uid!);
    await submitForm(
      userDoc,
      ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler({
        data,
        status,
        isLastUpdate,
        successCallback: clearSuccessCallback,
        errorCallback: clearErrorCallback,
      }),
      {deleteExports: true}
    );
  }

  function clearSuccessCallback() {
    setToastMessage(enCommonLabel.clearAllDowloadedSuccess);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    setIsClearLoading(false);
    closeClearDialog();
  }

  function clearErrorCallback() {
    setToastMessage(enCommonLabel.clearAllDownloadedFailed);
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
    setIsClearLoading(false);
    closeClearDialog();
  }

  const boxHeight = !isPanelOpen ? 0 : isMinimized ? "336px" : "100%";
  return (
    <BoxWrapper
      id="file-upload-floating-panel"
      sx={{
        visibility: isPanelOpen ? "visible" : "hidden",
        pointerEvents: "auto"
      }}
    >
      <DeleteDialog
        isOpen={isClearDialogOpen}
        title={enCommonLabel.clearAllDownloadedTitle}
        text={enCommonLabel.clearAllDownloadedDescription}
        confirmButtonText={en.common.button.clear}
        handleClose={closeClearDialog}
        handleConfirm={clearAllExports}
      />
      <Stack
        direction="column"
        sx={{width: "320px", maxHeight: boxHeight}}
      >
        <PanelTitle
          clearAllExports={() => openClearDialog()}
          isClearLoading={isClearLoading}
          isClearVisible={activeTab === TabIndex.Exports && (exports || []).length > 0}
          isCollapsed={isCollapsed}
          isMinimized={isMinimized}
          fileUploadDispatch={fileUploadDispatch}
          setIsMinimized={setIsMinimized}
          setIsCollapsed={setIsCollapsed}
          uid={uid}
        />
        {(!isCollapsed && isPanelOpen) && (
          <Box
            ref={containerRef}
            sx={{
              overflowX: "hidden",
              overflowY: "hidden",
              height: isCollapsed && isMinimized ? 0 : "100%",
              paddingY: 0,
              paddingX: 1,
            }}
          >
            <CustomTabs
              value={activeTab}
              onChange={handleTabChange}
            >
              <CustomTab
                disableRipple
                sx={{marginRight: 2}}
                label={
                  <Stack direction="row">
                    <Typography variant="h5">{enCommonLabel.uploads}</Typography>
                  </Stack>
                }
              />
              <CustomTab
                disableRipple
                label={
                  <Stack direction="row" gap={0.5}>
                    <Typography variant="h5">{enCommonLabel.exports}</Typography>
                  </Stack>
                }
              />
            </CustomTabs>
            <TabPanel
              tabIndex={TabIndex.Uploads}
              value={activeTab}
              sx={{height: isMinimized ? "35vh" : "85vh"}}
            >
              {activeTab === TabIndex.Uploads && (
                <UploadList
                  isMinimized={isMinimized}
                  toastProps={toastProps}
                  fileUploadState={fileUploadState}
                  isUploadPanelShown={!isCollapsed && isPanelOpen}
                  {...rest}
                  fileUploadDispatch={fileUploadDispatch!}
                  containerRef={containerRef}
                />
              )}
            </TabPanel>
            <TabPanel
              tabIndex={TabIndex.Exports}
              value={activeTab}
              sx={{height: isMinimized ? "35vh" : "85vh"}}
            >
              {activeTab === TabIndex.Exports && (
                <ExportList
                  uid={props.uid!}
                  toastProps={toastProps}
                />
              )}
            </TabPanel>
          </Box>
        )}
      </Stack>
    </BoxWrapper>
  )
}

/** start of custom css */
const BoxWrapper = styled(Paper)({
  zIndex: 4,
  borderRadius: 4,
  backgroundColor: theme.palette.background.default,
});

/** end of custom css */

export default FileUploadPanel;
