import {ToastProps} from "../../BaseProps";
import {InvitedItemType} from "types/ActivityFeedTypes";
import BaseItem from "./BaseItem";
import theme from "theme/theme";
import {Box, Typography} from "@mui/material";
import generateInviteText from "../utils/generateInviteText";
import {useDocument} from "hooks/index";
import {doc} from "firebase/firestore";
import {userActivityFeedPath} from "../../utility";
import {SkeletonItem} from "components/index";

interface OrganizationInviteItemProps extends ToastProps {
  itemId: string;
  uid: string;
  index: number;
  updatePinnedValue: (index: number, pinned: boolean) => void;
}

function OrganizationInviteItem(props: OrganizationInviteItemProps) {
  const {toastProps, itemId, uid, updatePinnedValue, index} = props;
  const [activityFeed] = useDocument<InvitedItemType>(doc(userActivityFeedPath(uid), itemId));

  if (!activityFeed) {
    return <SkeletonItem height={"5rem"} sx={{marginTop: "5px", width: "80%"}}/>
  }

  const {orgName} = activityFeed;
  const inviteText = generateInviteText(activityFeed);

  return (
    <BaseItem
      index={index}
      uid={uid}
      item={activityFeed}
      icon={(
        <Box
          sx={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.onDark,
            height: 22,
            width: 22,
            display: "grid",
            placeItems: "center",
            borderRadius: 2,
            marginRight: 1,
          }}
        >
          <Typography>{(orgName ?? "O").slice(0, 1)}</Typography>
        </Box>
      )}
      text={inviteText}
      toastProps={toastProps}
      updatePinnedValue={updatePinnedValue}
    />
  )
}

export default OrganizationInviteItem
