import React, {useEffect, useState} from "react";
import {FileStatus, Previews} from "enums/index";
import UploadedFileCard from "./FileStatusCard/UploadedFileCard";
import RejectedFileCard from "./FileStatusCard/RejectedFileCard";
import ApprovedFileCard from "./FileStatusCard/ApprovedFileCard";
import UploadingFileCard from "./FileStatusCard/UploadingFileCard";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../../../firebase";
import {getFileType} from "screens/utility";
import {CollectionReference} from "firebase/firestore";
import {StatusIcons} from "assets/icons/status/status.index";
import {BaseProps} from "screens/BaseProps";
import PreviewBlock from "./PreviewBlock";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {SwiftFile, Task} from "types/index";
import getEnvKey from "../../utility/getEnvKey";
import {storageBucketKey} from "constants/envKeys";
import {Box} from "@mui/material";
import {ProcessingFile} from "hooks/useFileUpload/useFileUpload";
import useFileTitle from "hooks/useFileTitle";

interface FileItemProps extends BaseProps {
  fileAccess: AccessType | null;
  task: Task | null;
  file?: SwiftFile;
  openFileChatDrawer?: (file: SwiftFile) => void;
  collectionRef: CollectionReference;
  onFilePreviewClick?: () => void;
  processingFile?: ProcessingFile;
  reUploadFile: (file: File, targetRef: string) => void;
  isLoading?: boolean;
}

function FileItem(props: FileItemProps) {
  const {
    file,
    fileAccess,
    collectionRef,
    toastProps,
    task,
    processingFile,
    openFileChatDrawer,
    onFilePreviewClick,
    uid,
    isLoading
  } = props;

  const [fileCard, setFileCard] = useState<JSX.Element>(<></>);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();
  const [isMoreActionsOpen, setIsMoreActionsOpen] = useState(false);
  const canUpdateStatus = Boolean(fileAccess?.[PermissionOperationKey.ChangeStatus]) || Boolean(fileAccess?.[PermissionOperationKey.OverrideStatus]);
  const isApprover = Boolean(task?.approver.uid === uid);
  const title = useFileTitle(file?.name!);
  const storageBucket = getEnvKey(storageBucketKey);

  useEffect(() => {
    if (!file) return;
    if (getFileType(file.contentType) === Previews.Image && !!file.previewPath) {
      const previewReference = ref(storage, `${storageBucket}${file.previewPath}`);
      getDownloadURL(previewReference)
        .then(setPreviewUrl)
        .catch(() => setPreviewUrl(undefined));
    }
  }, [file]);

  useEffect(() => {
    if (!file) return;
    let card;

    const previewDetails = {
      icon: <StatusIcons.Uploaded/>,
      fileStatus: file.fileStatus ?? FileStatus.Uploaded,
      contentType: file.contentType!,
      file: file!,
      previewUrl: previewUrl,
      onChatClick: openFileChatDrawer,
      collectionRef,
    }

    if (file.fileStatus === FileStatus.Rejected || file.fileStatus === FileStatus.Failed) {
      previewDetails.icon = <StatusIcons.Rejected/>;
    }

    const previewBlock = <PreviewBlock
      {...previewDetails}
      uid={uid}
      isMoreActionsOpen={isMoreActionsOpen}
      setIsMoreActionsOpen={setIsMoreActionsOpen}
      fileAccess={fileAccess}
      onFilePreviewClick={onFilePreviewClick}
      toastProps={toastProps!}
      isLoading={isLoading}
    />;

    switch (file.fileStatus) {
      case FileStatus.Uploaded:
        card = (
          <UploadedFileCard
            uid={uid}
            enabled={canUpdateStatus || isApprover}
            collectionRef={collectionRef}
            file={file}
            previewBlock={previewBlock}
            toastProps={toastProps!}
          />
        );
        break;
      case FileStatus.Failed:
      case FileStatus.Rejected:
        card = !file ? <></> : (
          <RejectedFileCard
            previewBlock={previewBlock}
            {...props}
            file={file}
            enabled={canUpdateStatus || isApprover}
          />
        );
        break;
      case FileStatus.Approved:
        card = <ApprovedFileCard
          collectionRef={collectionRef}
          file={file}
          previewBlock={previewBlock}
          enabled={canUpdateStatus || isApprover}
        />
        break;
      default:
        card = <></>
        break;
    }
    setFileCard(card);
    // eslint-disable-next-line
  }, [file, previewUrl, isMoreActionsOpen, isLoading]);

  function onRightClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.preventDefault();
    setIsMoreActionsOpen(true);
  }

  if (processingFile) {
    return (
      <Box title={title}>
        <UploadingFileCard processingFile={processingFile}/>
      </Box>
    )
  }

  return (
    <Box title={title} onContextMenu={onRightClick}>
      {fileCard}
    </Box>
  );
}

export default FileItem;
