import {LongText, TextWithHelper} from "components/index";
import {useAccess, useCollection, useComponentToggler} from "hooks/index";
import {DataTag} from "types/DataTag";
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Divider,
  Stack,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import React, {useCallback, useEffect, useRef, useState} from "react";
import Theme from "theme/theme";
import {en} from "language/en";
import CreateDataTagPopup from "components/DataTag/DataTagInput/CreateDataTagPopup";
import {BaseProps} from "screens/BaseProps";
import DataTagComponent from "components/DataTag/DataTagComponent";
import {organizationDataTagsPath} from "screens/utility/FirebasePath";
import {useParams} from "react-router-dom";
import EditDataTagPopup from "components/DataTag/DataTagInput/EditDataTagPopup";
import {Entity} from "enums/entity";
import {PermissionEntity, PermissionOperationKey} from "types/Permission";
import {datatagToneColor} from "enums/datatagToneColor";
import {datatagColor} from "constants/datatagColor";

const CREATE_TAG = {
  label: en.common.label.dataTags.createTag,
  id: "CREATE_TAG"
}

interface DataTagInputProps extends BaseProps {
  sx?: SxProps<typeof Theme>;
  handleSelectCallback: (values: string[]) => void;
  initialTags?: string[],
  componentId?: string;
  fieldSx?: SxProps<typeof Theme>;
  entity?: Entity;
  onKeyPress?: (e: React.KeyboardEvent) => void;
  subText?: string;
}

interface AutoCompleteOption {
  id: string;
  label: string;
}

function DataTagInput(props: DataTagInputProps) {
  const {
    sx,
    fieldSx,
    handleSelectCallback,
    initialTags,
    componentId = "data-tag-input",
    toastProps,
    uid,
    onKeyPress
  } = props;
  const {orgId} = useParams();

  const [selectedTags, setSelectedTags] = useState<AutoCompleteOption[]>([]);
  const boxRef = useRef<Element>();
  const searchRef = useRef<HTMLInputElement>();

  const [toBeEditedTag, setToBeEditedTag] = useState<DataTag | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [isEditDataTagOpen, {open: openEditDataTag, close: closeEditDataTag}] = useComponentToggler(false);
  const [isCreateDataTagPopupOpen, {
    open: openCreateDataTagPopup,
    close: closeCreateDataTagPopup
  }] = useComponentToggler(false);

  const [access] = useAccess({
    uid: uid!,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationDataTag
  });

  let [availableDataTags] = useCollection<DataTag>(null, organizationDataTagsPath(orgId!));

  const _filterOptions = createFilterOptions();
  const filterOptions = (options: AutoCompleteOption[], state: any) => {
    const results = _filterOptions(options, state) as AutoCompleteOption[];

    if (results.filter(option => option.id === CREATE_TAG.id).length === 0) {
      results.push(CREATE_TAG)
    }

    return results as AutoCompleteOption[];
  };

  // load default data tags for edit
  useEffect(() => {
    if (!initialTags || !availableDataTags) return;
    const selectedDefaultTags = ([...availableDataTags]
    ).filter(tag => initialTags.includes(tag.id!))
      .map(tag => ({id: tag.id!, label: tag.name!}))
    setSelectedTags(selectedDefaultTags);
  }, [availableDataTags, initialTags]);

  function handleAutocompleteChange(value: AutoCompleteOption[]) {
    if (value.some(tag => tag.id === CREATE_TAG.id)) {
      handleSelectCallback([...value.filter(tag => tag.id !== CREATE_TAG.id)].map(tag => tag.id))
      openCreateDataTagPopup();
      return;
    }
    handleSelectCallback(value.map(tag => tag.id))
  }

  function addTag(newTag: AutoCompleteOption) {
    handleSelectCallback([...selectedTags.map(tag => tag.id), newTag.id]);
  }

  function closeEditTagPopup() {
    closeEditDataTag();
    setToBeEditedTag(null);
  }

  function getAutocompleteOptions() {
    if (!availableDataTags) return [];

    const options = [
      ...([
        ...availableDataTags,
      ]).map(dataTag => ({id: dataTag.id!, label: dataTag.name!, color: dataTag.color!}))
        .filter((tag: { id: string }) => selectedTags.filter(selected => selected.id === tag.id).length === 0)
    ];

    return access?.[PermissionOperationKey.Create] ? [...options, CREATE_TAG] : options
  }

  const getTagFromOption = useCallback((option: AutoCompleteOption) => {
    const {id} = option;

    if (!availableDataTags) return {} as DataTag;
    const selectedItem = [...availableDataTags].find(tag => tag.id === id);
    return selectedItem ?? {} as DataTag;
  }, [availableDataTags]);

  function onCreateDataTagPopupClose() {
    closeCreateDataTagPopup();
    setInputValue("");
  }

  return <>
    <CreateDataTagPopup
      addTag={addTag}
      onClose={onCreateDataTagPopupClose}
      anchorEl={boxRef.current}
      isOpen={isCreateDataTagPopupOpen}
      defaultName={inputValue}
      uid={uid!}
    />
    {toBeEditedTag && <EditDataTagPopup
      toastProps={toastProps!}
      isOpen={isEditDataTagOpen}
      onClose={closeEditTagPopup}
      tag={toBeEditedTag}
      anchorEl={boxRef.current}
      uid={uid!}
    />}
    <Box sx={{...sx, position: "relative"}} ref={boxRef}>
      <TextWithHelper
        mainVariant="h5"
        subVariant="body1"
        mainText={en.common.label.dataTags.title + " "}
        subText={props.subText || en.common.label.optionalWithParentheses}
      />
      <Autocomplete
        filterOptions={filterOptions}
        sx={{
          "& .MuiAutocomplete-option": {
            color: "red!",
          },
          "& .MuiOutlinedInput-root": {
            py: 1 / 2
          },
        }}
        onKeyPress={(e) => {
          if (e.key !== "Enter") return;
          // if inputValue is empty, trigger parent onKeyPress
          if (inputValue === "" && onKeyPress) return onKeyPress(e);

          // check if value is in options
          const option = getAutocompleteOptions().find(option => option.label === inputValue);
          // if yes, add it
          if (option) {
            handleSelectCallback([...selectedTags.map(tag => tag.id), option.id]);
            setInputValue("");
            return;
          }
          // if no, open popup
          openCreateDataTagPopup();
        }}
        multiple
        getOptionLabel={(option: AutoCompleteOption) => option.label}
        id={componentId}
        options={getAutocompleteOptions()}
        onChange={(_, value) => handleAutocompleteChange(value)}
        value={selectedTags}
        renderTags={(value: any[], getTagProps) =>
          <Stack direction="row" flexWrap="wrap" gap={1 / 2}>
            {/* eslint-disable-next-line array-callback-return */}
            {value.map((option: AutoCompleteOption, index: number) => {
                const tag = getTagFromOption(option);
                if (tag !== undefined) {
                  return (
                    <DataTagComponent
                      toastProps={toastProps!}
                      tag={tag!}
                      {...getTagProps({index})}
                      openEditDataTagPopover={openEditDataTag}
                      setToBeEditedTag={setToBeEditedTag}
                      key={tag.id!}
                      uid={uid!}
                    />
                  )
                }
              }
            )}
          </Stack>
        }
        renderOption={(props, option) => {
          const {className, ...rest} = props;

          if (option.id === CREATE_TAG.id)
            return (
              <Stack sx={{marginTop: 0.5}}>
                <Divider/>
                <Typography {...props} color="primary.main">
                  {option.label}
                </Typography>
              </Stack>
            )

          const {color = datatagToneColor.tone_0_0} = option as unknown as DataTag;
          //let's force to use button instead of li
          // @ts-ignore
          return <Button
            fullWidth
            {...rest}
            sx={{
              display: "inline-block",
              textAlign: "left",
              marginBottom: 0,
              py: 0,
              px: 1,
          }}>
            <LongText
              sx={{
                px: 1,
                py: 0.2,
                marginBottom: 0.5,
                borderRadius: 1,
                width: "min-content",
                textWrap: "nowrap",
                ...datatagColor[color]
            }}>
              {option.label}
            </LongText>
          </Button>
        }}
        renderInput={(params) => (
          <TextField
            inputRef={searchRef}
            sx={{
              "& .MuiOutlinedInput-input": {
                padding: "9px 16px",
              },
              ...fieldSx
            }}
            {...params}
            id={componentId}
            onChange={(e) => setInputValue(e.target.value)}
            InputProps={{...params.InputProps, inputProps: {...params.inputProps, maxLength: 32}}}
          />
        )}
      />
    </Box>
  </>
}

export default DataTagInput;
