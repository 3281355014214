import {MenuItem, Typography} from "@mui/material";
import {enCommonLabel, enReferencesLabel, enTaskLabel} from "constants/index";
import {SelectWithSearch} from "components/index";
import {ReferenceFile} from "types/ReferenceFile";
import React from "react";

interface FileRequirementInputProps {
  referenceFiles: ReferenceFile[]
  handleSelectCallback: (value: string) => void
  defaultValue?: string
}

function FileReferenceInput(props: FileRequirementInputProps) {

  const {referenceFiles, handleSelectCallback, defaultValue} = props;

  return <>
    <Typography variant="h5">
      {enTaskLabel.referenceFile}{" "}
      <Typography component="span" color="text.secondary">({enCommonLabel.optional})</Typography>
    </Typography>
    <SelectWithSearch
      sx={{marginBottom: 1}}
      items={
        referenceFiles?.map(ref => ({
          displayItem: ref.name,
          value: ref.id ?? "",
          searchable: ref.name
        })) ?? []
      }
      handleSelectCallback={handleSelectCallback}
      defaultValue={defaultValue}
      clearable
      extraMenuItem={
        !referenceFiles.length ? (
          <MenuItem disabled={true}>
            <Typography>{enReferencesLabel.noAvailableReferenceFiles}</Typography>
          </MenuItem>
        ) : undefined
      }
    />
  </>
}

export default FileReferenceInput