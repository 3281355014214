import SettingsItem from "./SettingsItem";
import {Button, Collapse, Stack, Typography} from "@mui/material";
import {enCommonLabel, enProfileAndSettingsLabel} from "constants/index";
import {useEffect, useState} from "react";
import {auth} from "../../../../firebase";
import {LoadingButton} from "@mui/lab";
import {Input} from "components/index";
import {useDebounce} from "hooks/index";
import {EmailAuthProvider, reauthenticateWithCredential, signOut, updatePassword} from "firebase/auth"
import {toastProps as toastPropsType} from "../../../BaseProps";
import {Severity} from "enums/severity";
import validatePassword from "../../../utility/validatePassword";

interface ChangePasswordProps {
  toastProps: toastPropsType
}

function ChangePassword(props: ChangePasswordProps) {
  const {toastProps} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps;

  const firebaseUser = auth.currentUser;
  const [isChangePasswordFieldsShown, setIsChangePasswordFieldsShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");
  const [newPasswordError, setNewPasswordError] = useState<string>("");
  const [oldPasswordError, setOldPasswordError] = useState<string>("");

  const areInputsValid = !isChangePasswordFieldsShown || (
    !((newPassword ?? "").length < 6) &&
    !((confirmPassword ?? "").length < 6) &&
    !confirmPasswordError &&
    !oldPasswordError &&
    !newPasswordError
  )

  useEffect(() => {
    if (!isChangePasswordFieldsShown) return;
    setOldPasswordError("");
  }, [oldPassword]);

  useDebounce(async () => {
    if (!isChangePasswordFieldsShown) return;
    // New Password Error
    if (!newPassword) return;
    if (!validatePassword(newPassword)) {
      setNewPasswordError(enCommonLabel.passwordValidationError);
      return;
    }
    setNewPasswordError("");

    // Confirm Password Error
    if (!confirmPassword) return;
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError(enProfileAndSettingsLabel.passwordsDoNotMatch);
      return;
    }
    setConfirmPasswordError("");
  }, 200, [confirmPassword, newPassword]);

  function onChangePasswordClick() {
    if (!areInputsValid || !firebaseUser) return;
    setIsLoading(true);
    const credential = EmailAuthProvider.credential(firebaseUser.email!, oldPassword);
    reauthenticateWithCredential(firebaseUser, credential).then(() => {
      updatePassword(firebaseUser, newPassword).then(onUpdatePasswordSuccess)
    }).catch((error) => {
      setIsLoading(false);
      if (error.code === "auth/wrong-password") {
        setOldPasswordError(enProfileAndSettingsLabel.oldPasswordIncorrect);
        return;
      }
      setToastMessage(enCommonLabel.unknownError(error.code));
      setToastSeverity(Severity.Error);
      setIsToastOpen(true);
    });
  }

  function onResetAll() {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setConfirmPasswordError("");
    setOldPasswordError("");
    setIsLoading(false);
    setIsChangePasswordFieldsShown(false);
  }

  function onUpdatePasswordSuccess() {
    let countdown = 3;
    setToastMessage(enProfileAndSettingsLabel.passwordUpdated(3));
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    onResetAll();
    const interval = setInterval(() => {
      countdown--;
      if (countdown < 1) {
        clearInterval(interval);
        signOut(auth);
        return;
      }
      setToastMessage(enProfileAndSettingsLabel.passwordUpdated(countdown));
    }, 1000);
  }

  function onKeyPress(e: React.KeyboardEvent) {
    if (e.key === "Enter" && areInputsValid && !isLoading) {
      onChangePasswordClick();
    }
  }

  return <>
    <SettingsItem>
      <Stack gap={isChangePasswordFieldsShown ? 1 : 0} padding={1}>
        {
          isChangePasswordFieldsShown && (
            <Typography>
              {enProfileAndSettingsLabel.passwordChangeWarning}
            </Typography>
          )
        }
        <Collapse in={isChangePasswordFieldsShown}>
          <Stack gap={1}>
            <Input
              type="password"
              validationMessage={oldPasswordError}
              value={oldPassword}
              onChange={(e) => setOldPassword((e.currentTarget as HTMLInputElement).value)}
              label={enProfileAndSettingsLabel.oldPassword}
              onKeyPress={onKeyPress}
            />
            <Input
              sx={{maxWidth: "303px"}}
              type="password"
              validationMessage={newPasswordError}
              label={enProfileAndSettingsLabel.newPassword}
              value={newPassword}
              onChange={(e) => setNewPassword((e.currentTarget as HTMLInputElement).value)}
              onKeyPress={onKeyPress}
            />
            <Input
              type="password"
              validationMessage={confirmPasswordError}
              label={enProfileAndSettingsLabel.confirmNewPassword}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword((e.currentTarget as HTMLInputElement).value)}
              onKeyPress={onKeyPress}
            />
          </Stack>
        </Collapse>
        <Stack direction="row" justifyContent={isChangePasswordFieldsShown ? "flex-end" : "unset"} gap={1}>
          <LoadingButton
            disabled={!areInputsValid}
            loading={isLoading}
            onClick={() => {
              isChangePasswordFieldsShown ?
                onChangePasswordClick() :
                setIsChangePasswordFieldsShown(true)
            }}
            variant={isChangePasswordFieldsShown ? "contained" : "text"}
          >
            <Typography>
              {isChangePasswordFieldsShown ? enProfileAndSettingsLabel.updatePassword : enProfileAndSettingsLabel.changePassword}
            </Typography>
          </LoadingButton>
          {
            isChangePasswordFieldsShown && (
              <Button variant="contained" color="error" onClick={onResetAll}>
                {enProfileAndSettingsLabel.cancel}
              </Button>
            )
          }
        </Stack>
      </Stack>
    </SettingsItem>
  </>
}

export default ChangePassword;
