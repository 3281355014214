import {common} from "./en/common";
import {screen} from "./en/screens";

export const en = {
  common: {...common},
  screen: {...screen},
  dataTag: {
    label: "Create Data Tags",
    tooltip: "Data Tags",
    nameIsRequired: "Name is required",
  }
}

