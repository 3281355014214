import {Box, SxProps, Theme, Typography} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import theme from "theme/theme";
import {toastProps} from "../screens/BaseProps";
import {Severity} from "enums/severity";
import {enCommonLabel} from "constants/index";

export enum OverlayType {
  Building = "Building",
  Importing = "Importing",
}

interface CardOverlayProps {
  id: string;
  text: string;
  sx?: SxProps<Theme>;
  process: OverlayType;
  toastProps: toastProps | undefined;
  buildingProgressCount: number;
  disabled?: boolean;
}

const LOADING_CHECKPOINT = 25;

function CardBadge(props: CardOverlayProps) {
  const {id, text, sx = {}, buildingProgressCount = 0, toastProps, process = OverlayType.Building, disabled = false} = props;
  const {setIsToastOpen, setToastSeverity, setToastMessage} = toastProps!;

  const [isDisplayed, setIsDisplayed] = React.useState<boolean>(false);
  const [pseudoProgressCount, setPseudoProgressCount] = useState<number>(() => {
    const storedValue = sessionStorage.getItem(id);
    return storedValue !== null ? parseInt(storedValue) : 0;
  });
  const pseudoPercentage = useMemo(() => buildingProgressCount < LOADING_CHECKPOINT ? pseudoProgressCount : buildingProgressCount, [
    pseudoProgressCount,
    buildingProgressCount
  ]);

  useEffect(() => {
    const isNotBuilding = buildingProgressCount >= 100 || buildingProgressCount === 0;

    setIsDisplayed(prev => {
      if (isNotBuilding && prev) {
        setIsToastOpen(true);
        setToastSeverity(Severity.Success);
        setToastMessage(enCommonLabel.processCompleted(process));
        setPseudoProgressCount(0);
        sessionStorage.removeItem(id);
      }
      return !isNotBuilding;
    });
  }, [buildingProgressCount]);

  useEffect(() => {
    if (!pseudoPercentage || !isDisplayed) return;
    sessionStorage.setItem(id, pseudoPercentage.toString());
  }, [pseudoPercentage, isDisplayed]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (pseudoProgressCount >= LOADING_CHECKPOINT || buildingProgressCount > LOADING_CHECKPOINT) return;
      setPseudoProgressCount(prev => prev >= LOADING_CHECKPOINT ? prev : prev + 1);
    }, 1500);

    return () => clearInterval(interval);
  }, [pseudoProgressCount, buildingProgressCount]);

  if (!isDisplayed && !disabled) return null;

  return (
    <Box
      position="absolute"
      sx={{
        display: "grid",
        placeItems: "center",
        borderRadius: 1,
        zIndex: 1,
        alignItems: "center",
        backgroundColor: disabled ? theme.palette.error.main : theme.palette.warning.main,
        paddingX: 1,
        top: 0,
        right: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: 0,
        height: "auto",
        ...sx
      }}
    >
      <Typography
        variant="caption"
        sx={{
          color: "#fff",
          lineHeight: "20px",
        }}
      >
        {disabled ? `${text}` : `${text} ${pseudoPercentage}%`}
      </Typography>
    </Box>
  )
}

export default CardBadge;