import theme from "theme/theme";

interface SkeletonItemProps {
  height: string;
  sx?: any
}

function SkeletonItem(props: SkeletonItemProps) {
  const {height, sx = {}} = props;

  return (
    <div
      style={{
        height: height,
        width: "100%",
        backgroundColor: theme.palette.neutral.light,
        borderRadius: 5,
        ...sx
      }}
    />
  )
}

export default SkeletonItem;