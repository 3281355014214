export enum ExportTaskType {
  AllTasks = "AllTasks",
  TasksAssignedToMe = "TasksAssignedToMe",
  FavoriteTasks = "FavoriteTasks",
  TimeData = "TimeData",
  FilteredTasks = "FilteredTasks",

  // ADDED FOR HOME SCREEN FILTER
  ImTheApprover = "ImTheApprover",
  ImTheObserver = "ImTheObserver"
}