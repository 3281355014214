/* eslint-disable react-hooks/exhaustive-deps */
import {Box, Divider, Drawer as MuiDrawer, Stack, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import React, {useEffect, useState} from "react";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import AssignedItem from "./AssignedItemList/AssignedItem";
import {Personnel} from "types/Personnel";
import {defaultAssignee, enTaskLabel} from "constants/index";
import {AccessUserType} from "enums/accessUserType";
import AssignedItemList from "components/AssigneeSelectionDrawer/AssignedItemList/AssignedItemList";
import {AccessListUser} from "types/AccessListUser";
import EmptySearchResults from "components/EmptySearchResults";
import {Entity} from "enums/entity";
import {BaseSearchInput, InProgress} from "components/index";
import {useDebounce} from "hooks/index";
import {searchInArrayWithStringify} from "screens/utility";

interface AssigneeSelectionDrawerDrops {
  drawerLabel: string;
  isDrawerOpen: boolean;
  taskAssignee: Personnel;
  setter: (obj: Personnel) => void;
  closeDrawer: () => void;
  personnel?: AccessListUser[] | null;
}

function AssigneeSelectionDrawer(props: AssigneeSelectionDrawerDrops) {
  const {isDrawerOpen, taskAssignee, drawerLabel, personnel = [], setter, closeDrawer} = props;

  const [assigned, setAssigned] = useState<Personnel>(defaultAssignee);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [algoliaPersonnel, setAlgoliaPersonnel] = useState<AccessListUser[] | null>([]);
  const currentTeams = (personnel || []).filter(person => person.accessUserType === AccessUserType.Team);
  const currentMembers = (personnel || []).filter(person => person.accessUserType !== AccessUserType.Team);

  useDebounce(searchFn, 500, [searchQuery]);

  useEffect(() => {
    setAssigned(taskAssignee);
  }, [taskAssignee]);

  function getTeamsToRender() {
    if (algoliaPersonnel)
      return algoliaPersonnel.filter((personnel) => personnel?.accessUserType === AccessUserType.Team)

    return currentTeams || [];
  }

  function getPeopleToRender() {
    if (algoliaPersonnel) {
      return algoliaPersonnel.filter((personnel) => personnel?.accessUserType === AccessUserType.Person)
    }

    return (personnel || []).filter(person => person.accessUserType === AccessUserType.Person) || []
  }

  function setSelectedAssignee(person: Personnel) {
    // @ts-ignore
    const {active, uid, initials, isTeam, name, avatarColor, id, accessUserType, teamMemberUids, avatarPhotoUrl} = person;
    const newPersonnel = {
      active,
      uid,
      initials,
      isTeam,
      name,
      avatarColor,
      id,
      accessUserType,
      teamMemberUids,
      avatarPhotoUrl,
    }
    setAssigned(newPersonnel);
    setter(newPersonnel);
    closeDrawer();
  }

  function checkNoSearchResults() {
    if (!algoliaPersonnel)
      return false;

    return algoliaPersonnel.length <= 0;
  }

  async function searchFn() {
    if (searchQuery === "") {
      setAlgoliaPersonnel(null);
      return;
    }

    // if there are no teams or people
    if (currentTeams?.length === 0 && currentMembers?.length === 0)
      return;

    setLoading(true);
    const searchTeams = searchInArrayWithStringify(currentTeams, searchQuery);
    const searchPeople = searchInArrayWithStringify(currentMembers, searchQuery);
    setAlgoliaPersonnel([...searchTeams, ...searchPeople]);
    setLoading(false);
  }

  const isPersonnelLoading = personnel === null;

  return (
    <MuiDrawer id="milestones-filter-drawer" anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
      <BoxMain sx={{overflow: "scroll"}} gap={3}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" alignContent="center">
          <SystemIcons.ArrowLeft
            onClick={closeDrawer}
            height={24}
            width={24}
            color={theme.palette.neutral.dark}/>
          <Typography
            variant="h4"
            sx={{alignSelf: "flex-start", marginLeft: 2, color: theme.palette.secondary.main}}>
            {drawerLabel}
          </Typography>
        </Stack>
        <Stack>
          <BaseSearchInput
            sx={{mx: 1, mb: 2, minWidth: "300px"}}
            id="mange-access-drawer-search"
            placeholder={enTaskLabel.searchAssignee}
            searchFn={(searchText: string) => setSearchQuery(searchText)}
            loading={loading}
          />
        </Stack>
        <AssignedItem
          assigned={defaultAssignee}
          isSelected={assigned.name === null}
          updateSelected={(person) => setSelectedAssignee(person)}/>
        <Divider/>
        {isPersonnelLoading && <InProgress />}
        {!isPersonnelLoading && checkNoSearchResults() && <EmptySearchResults entity={Entity.TeamsAndPeople}/>}

        {!isPersonnelLoading && !checkNoSearchResults() && <>
          <AssignedItemList
            personnelList={getTeamsToRender()}
            assigned={assigned}
            updateSelected={(person) => setSelectedAssignee(person)}
            label={enTaskLabel.teams}
            isListTeams={true}
          />
          <AssignedItemList
            personnelList={getPeopleToRender()}
            assigned={assigned}
            updateSelected={(person) => setSelectedAssignee(person)}
            label={enTaskLabel.people}
            isListTeams={false}
          />
        </>}
      </BoxMain>
    </MuiDrawer>
  )
}

/** styles start */
const BoxMain = styled(Box)({
  height: "100%",
  width: "360px",
  display: "flex",
  flexDirection: "column",
  padding: "24px",
});

/** styles end */

export default AssigneeSelectionDrawer;
