import {EnvKey} from "types/EnvKeys";
import {EnvNames} from "enums/envNames";

export const firebaseConfigKey: EnvKey = {
  name: EnvNames.REACT_APP_FIREBASE_CONFIG,
  type: Object
}

export const googleMapsApiKey: EnvKey = {
  name: EnvNames.REACT_APP_GOOGLE_MAPS_API,
  type: String
}

export const algoliaAppIdKey: EnvKey = {
  name: EnvNames.REACT_APP_ALGOLIA_APP_ID,
  type: String
}

export const algoliaApiKey: EnvKey = {
  name: EnvNames.REACT_APP_ALGOLIA_API_KEY,
  type: String
}

export const algoliaIndexKey: EnvKey = {
  name: EnvNames.REACT_APP_ALGOLIA_INDEX_NAME,
  type: String
}

export const vapidKey: EnvKey = {
  name: EnvNames.REACT_APP_VAPID_KEY,
  type: String
}

export const useLocalDBKey: EnvKey = {
  name: EnvNames.REACT_APP_USE_LOCAL_DB,
  type: Boolean
}

export const storageBucketKey: EnvKey = {
  name: EnvNames.REACT_APP_STORAGE_BUCKET,
  type: String
}

export const mapCountryRestrictionKey: EnvKey = {
  name: EnvNames.REACT_APP_MAP_RESTRICTION_COUNTRIES,
  type: Object
}

export const downloadUrlKey: EnvKey = {
  name: EnvNames.REACT_APP_DOWNLOAD_URL,
  type: String
}

export const versionKey: EnvKey = {
  name: EnvNames.REACT_APP_VERSION,
  type: String
}

export const submitTimeout: EnvKey = {
  name: EnvNames.REACT_APP_SUBMIT_TIMEOUT,
  type: String
}

export const submitTimeoutExport: EnvKey = {
  name: EnvNames.REACT_APP_SUBMIT_TIMEOUT_EXPORT,
  type: String
}

export const importMaxRows: EnvKey = {
  name: EnvNames.REACT_APP_MAX_IMPORT_ROWS,
  type: String
}
