import {Grid, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {enMilestoneLabel} from "constants/index";
import React from "react";
import {styled} from "@mui/material/styles";

function EmptyItem() {
  return (
    <Grid
      container
      sx={{bgcolor: "#fff", mb: 1, borderRadius: 1, padding: "8px"}}
      justifyContent="space-between"
      direction="row"
      alignItems="center"
    >
      <Grid item xs={10} sm={8} justifyContent="flex-start" alignItems="center">
        <Item direction="column" textAlign="left" justifyContent="flex-start">
          <Typography
            sx={{color: theme.palette.secondary.main, fontWeight: 600}}
            variant="subtitle2"
            noWrap>
            {enMilestoneLabel.emptyTitle}
          </Typography>
          <Typography sx={{color: theme.palette.neutral.dark}} variant="caption">
            {enMilestoneLabel.createFirst}
          </Typography>
        </Item>
      </Grid>
      <Grid item justifyContent="flex-end" alignItems="center" pr={1}>
      </Grid>
    </Grid>
  )
}

/** start of styles */
const Item = styled(Stack)(({theme}) => ({
  padding: theme.spacing(1),
  textAlign: 'left',
  alignContent: 'center',
  justifyContent: 'flex-start',
}));

/** end of styles */

export default EmptyItem;