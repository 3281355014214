import React, {useEffect} from "react";
import {Stack, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {FileRequirement} from "types/FileRequirement";
import {emptyFunction, enCommonLabel, enFileRequirementLabel, enTaskLabel,} from "constants/index";
import {ReferenceFileUsage} from "components/ReferenceFileUsage";
import {useDocument} from "hooks/index";
import {ReferenceFile} from "types/ReferenceFile";
import {doc} from "firebase/firestore";
import {referenceFilespath} from "../utility";
import {useParams} from "react-router";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {DownloadIconButton, SecondaryButton} from "components/Button";
import {Entity} from "enums/entity";
import {CarouselItem} from "components/ChatDrawer/ChatItemsView/ChatAttachmentCarousel";
import {toastProps} from "../BaseProps";
import {StatisticsItem} from "components/index";

interface StatisticsProps {
  uid: string;
  fileAccess: AccessType | null;
  downloadAccess: AccessType | null;
  fileRequirement: FileRequirement;
  filesCount: number;
  triggerFileUpload: (e: React.MouseEvent) => void;
  setCarouselItems: React.Dispatch<React.SetStateAction<CarouselItem[]>>;
  setIsCarouselItemsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onFilePreviewClick: () => void;
  toastProps: toastProps
}

function Statistics(props: StatisticsProps) {
  const {fileRequirement, fileAccess, downloadAccess, uid, triggerFileUpload, onFilePreviewClick, setCarouselItems} = props;
  const {orgId, assetId} = useParams();
  const {minimumFiles, maximumFiles} = fileRequirement;
  const [referenceFile, setReferenceFileRef] = useDocument<ReferenceFile>(null);

  const referenceFileId = fileRequirement.referenceFileId;
  const canDownload = Boolean(downloadAccess?.[PermissionOperationKey.Create]);

  useEffect(() => {
    if (!referenceFileId) {
      setReferenceFileRef(null);
      return;
    }

    const referenceFileRef = doc(referenceFilespath(orgId!), referenceFileId);
    setReferenceFileRef(referenceFileRef);
  }, [referenceFileId]);


  return (
    <>
      <Stack
        direction={{xs: "column", sm: "column", md: "column", lg: "row", xl: "row"}}
        alignItems={{xs: "baseline", sm: "baseline", md: "baseline", lg: "center", xl: "center"}}
        justifyContent="space-between"
        spacing={2}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          spacing={1}
        >
          <Stack direction="row" alignItems="center">
            <StatisticsItem
              Icon={<SystemIcons.FilesOutlined stroke={theme.palette.text.secondary}/>}
              count={props.filesCount ?? 0}
              label={enTaskLabel.files}
              id="task-overview-statistics-files"
            />
            <DownloadIconButton
              uid={uid}
              onClickFcn={emptyFunction}
              filesCount={props.filesCount}
              entity={!!assetId ? Entity.FileRequirement : Entity.PLFileRequirement}
              disabled={!canDownload}
              toastProps={props.toastProps!}
              sx={{marginTop: -2}}
            />
          </Stack>
          <Stack
            id="file-requirement-min-count"
            direction="row"
            alignItems="center"
            paddingBottom={2}
          >
            <Stack direction="row" alignItems="flex-end" gap={1}>
              <Typography
                variant="bodySmall"
                paddingBottom={0.5}
                color={theme.palette.text.secondary}
              >
                {enCommonLabel.min}
              </Typography>
              <Typography variant="h2" id="file-requirement-statistic-min-count">{minimumFiles}</Typography>
            </Stack>
          </Stack>
          {maximumFiles && maximumFiles > 0 && (
            <Stack
              id="file-requirement-max-count"
              direction="row"
              alignItems="center"
              paddingBottom={2}
            >
              <Stack direction="row" alignItems="flex-end" gap={1}>
                <Typography
                  variant="bodySmall"
                  paddingBottom={0.5}
                  color={theme.palette.text.secondary}
                >
                  {enCommonLabel.max}
                </Typography>
                <Typography variant="h2" id="file-requirement-statistic-max-count">{maximumFiles}</Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack
          justifyContent="flex-end"
          direction="row"
          spacing={2}
        >
          {
            referenceFile ? (
              <ReferenceFileUsage referenceFile={referenceFile} setCarouselItems={setCarouselItems} onFilePreviewClick={onFilePreviewClick}/>
            ) : null
          }
          <SecondaryButton
            id="file-requirement-overview-upload-button"
            onClickFcn={triggerFileUpload}
            disabled={Boolean(!fileAccess?.[PermissionOperationKey.Create])}
            startIcon={SystemIcons.Upload}
            label={enFileRequirementLabel.uploadFiles}
            sx={{mt: !referenceFile ? 0 : 1}}
          />
        </Stack>
      </Stack>
    </>
  );
}

export default Statistics;
