import {Dispatch, useState} from "react";
import {Box, MenuItem, Select, SelectChangeEvent, Typography} from "@mui/material";
import {en} from "language/en";
import {onChangeInput} from "screens/utility/formUtils";
import {AllOrder, DirectionalOrder} from "enums/DirectionalOrder";
import {orderBy, QueryConstraint} from "firebase/firestore";

interface SortMenuProps {
  setter?: Dispatch<any>;
  id?: string;
  changeOrder?: (order: DirectionalOrder) => any;
  firestoreConstraintsUpdator?: (constraints: QueryConstraint[]) => void;
  additionalConstraints?: QueryConstraint[];
  currentOrder?: AllOrder;
  enableLastVisited?: boolean;
  extraMenu?: AllOrder[];
}

const defaultMenu = [DirectionalOrder.asc, DirectionalOrder.desc];

function SortMenu(props: SortMenuProps) {
  const {
    setter,
    id,
    changeOrder,
    firestoreConstraintsUpdator,
    additionalConstraints = [],
    extraMenu = [],
  } = props;

  const [order, setOrder] = useState<AllOrder>(DirectionalOrder.asc);

  function handleSelectChange(e: SelectChangeEvent<string>) {

    if (setter)
      onChangeInput(e, setter);

    if (changeOrder)
      changeOrder(e.target.value as DirectionalOrder)

    if (firestoreConstraintsUpdator) { // @ts-ignore
      firestoreConstraintsUpdator([orderBy("name", e.target.value as DirectionalOrder), ...additionalConstraints])
    }

    setOrder(e.target.value as DirectionalOrder);

  }

  return (
    <Box sx={{display: "flex", alignItems: "center"}} id={id ?? "sort-menu"}>
      <Typography
        sx={(theme) => ({
          display: "inline-block",
          color: theme.palette.text.secondary,
        })}
        variant="body"
      >
        {en.common.label.sort}
      </Typography>
      <Select
        id="org-select-field"
        defaultValue="asc"
        sx={{
          width: "min-content",
          minWidth: 100,
          padding: 0,
          ".MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          ".MuiOutlinedInput-input": {
            padding: "0px 4px",
          },
        }}
        onChange={handleSelectChange}
        value={order}
      >
        {([...defaultMenu, ...extraMenu]).map((order) => (
          <MenuItem value={order} key={order} id={`menu-${order}`}>
            <Typography variant="body">{en.common.sort[order]}</Typography>
          </MenuItem>
        ))}
        {/*<MenuItem value="asc" id="menu-asc">
          <Typography
            sx={(theme) => ({
              color: theme.palette.secondary.main,
            })}
            variant="body"
          >
            {en.common.sort.ascending}
          </Typography>
        </MenuItem>
        <MenuItem value="desc" id="menu-des">
          <Typography variant="body">{en.common.sort.descending}</Typography>
        </MenuItem>
        {enableLastVisited && <MenuItem value={MiscOrder.lastVisited} id="menu-des">
          <Typography variant="body">{en.common.sort.recentlyAccessed}</Typography>
        </MenuItem>}*/}
      </Select>
    </Box>
  );
}

export default SortMenu;
