import useEmblaCarousel from "embla-carousel-react";
import React, {useCallback, useEffect, useRef, useState} from "react";
import "components/ChatDrawer/ChatItemsView/emblaStyles.css";
import {SystemIcons} from "assets/icons/system/system.index";
import AutoHeight from 'embla-carousel-auto-height'
import {Backdrop, Box, debounce, IconButton, Stack, Tooltip} from "@mui/material";
import theme from "theme/theme";
import {enCommonButton} from "constants/index";
import {downloadFile, getPreviewFromMime} from "../screens/utility";
import {Previews} from "enums/supportedPreviews";
import PDFImg from "assets/img/PDFImg.png";
import {InProgress} from "components/index";
import {useCollection} from "hooks/index";
import {SwiftFile} from "types/SwiftFile";
import {CollectionReference, orderBy} from "firebase/firestore";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../firebase";
import getEnvKey from "../screens/utility/getEnvKey";
import {downloadUrlKey, storageBucketKey} from "constants/envKeys";
import {Buffer} from "buffer";
import getFileDownloadPath from "../screens/utility/getFileDownloadPath";
import useFileTitle from "hooks/useFileTitle";

const DOWNLOAD_URL = getEnvKey(downloadUrlKey) || "";

export type CarouselItem = {
  "@id": string;
  destPath?: string;
  previewPath: string;
  type?: string;
  previewType?: Previews;
  nameWithExt: string;
}

interface ChatAttachmentCarouselProps {
  clickedIndex: number | null;
  toggleAttachmentCarousel: () => void;
  filesColRef: CollectionReference | null;
  displayedFiles?: SwiftFile[] | null
}

function LazyCarousel(props: ChatAttachmentCarouselProps) {
  const {clickedIndex, toggleAttachmentCarousel, filesColRef, displayedFiles = []} = props;

  const [mainViewportRef, embla] = useEmblaCarousel(
    {
      dragFree: true,
      startIndex: clickedIndex ?? 0,
    }, [AutoHeight({destroyHeight: 'auto'})]);
  const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
    containScroll: "keepSnaps",
    dragFree: true
  });
  const [collectionFiles, , setFilesRef] = useCollection<SwiftFile>(
    null,
    filesColRef,
    [
      orderBy("timeCreated", "asc"),
    ],
    false,
  );
  const [files, setFiles] = useState<SwiftFile[] | null>(displayedFiles);
  const [carouselItems, setCarouselItems] = useState<CarouselItem[]>([]);
  const [limitCount, setLimitCount] = useState(10);
  const [selectedIndex, setSelectedIndex] = useState(clickedIndex);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const thumbnailContainerRef = useRef<HTMLDivElement>(null);
  const arrowLeftRef = useRef<HTMLButtonElement>(null);
  const arrowRightRef = useRef<HTMLButtonElement>(null);

  const [downloadUrl, setDownloadUrl] = useState<string>("");

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => {
    if (!embla)
      return
    embla.reInit()
    embla.scrollNext()
  }, [embla, carouselItems.length]);
	const [hasMoved, setHasMoved] = useState<boolean>(false);

	const onThumbClick = useCallback(
    (index) => {
      if (!embla || !emblaThumbs) return;
      if (emblaThumbs.clickAllowed()) embla.scrollTo(index);
    },
    [embla, emblaThumbs]
  );

  const onSelect = useCallback(() => {
    if (!embla || !emblaThumbs) return;
    const nextIndex = embla.selectedScrollSnap();
    setSelectedIndex(() => nextIndex);
    emblaThumbs.scrollTo(embla.selectedScrollSnap());
  }, [embla, emblaThumbs]);

	let resetHasMovedDebounced: any;

  useEffect(() => {
    if (displayedFiles?.length !== 0) {
      setFiles(displayedFiles);
      return;
    }

    setFiles(collectionFiles);
  }, [displayedFiles, collectionFiles]);

	useEffect(() => {
		if (!embla) return;

		resetHasMovedDebounced = debounce(() => {
			setHasMoved(false);
		}, 100);  // 100ms debounce time, adjust as needed

		const onScroll = () => {
			setHasMoved(true);
			resetHasMovedDebounced();
		};

		embla.on('scroll', onScroll);

		return () => {
			embla.off('scroll', onScroll);
		};
	}, [embla]);

	//fetch more files while scrolling for more
  useEffect(() => {
    if (!selectedIndex)
      return;

    if (!files)
      return

    setLimitCount(Math.max(limitCount, selectedIndex + 10))
  }, [selectedIndex]);

  //fetch files
  useEffect(() => {
    setFilesRef(filesColRef);
  }, [filesColRef])

  //load thumbnails based on selected index
  useEffect(() => {

    //if there are no files no need to fetch the thumbnails
    if (!files)
      return;

    //prevent rerendering when files fetch few documents
    if (files.length <= carouselItems.length)
      return

    //do not load if no view is selected
    if (selectedIndex === null)
      return

    async function loadCarouselItems() {
      if (!files)
        return;

      const newCarouselItems: CarouselItem[] = [];

      for await (const file of files) {
        const {previewPath, name, contentType, destPath, "@id": id = ""} = file;

        newCarouselItems.push({
          previewPath,
          type: contentType,
          nameWithExt: name,
          destPath,
          "@id": id
        });
      }

      setCarouselItems([...carouselItems, ...newCarouselItems]);
    }

    loadCarouselItems();

    if (DOWNLOAD_URL === "") return;
    if (downloadUrl === "") {
      const currentItem = files[0];
      if (!currentItem) return;

      const base64 = Buffer.from(filesColRef?.path! + '/' + currentItem.id, 'utf-8').toString('base64');
      setDownloadUrl(DOWNLOAD_URL+`${base64}?useOriginal=true`);
    }
  }, [files, files?.length, selectedIndex, limitCount]);

  useEffect(() => {
    if (!carouselItems || selectedIndex === null) return;
    (async() => {
      const activeCarousel = carouselItems[selectedIndex];
      const downloadPath = await getFileDownloadPath(activeCarousel.destPath!);
      setDownloadUrl(downloadPath);
    })();
  }, [selectedIndex, carouselItems]);

  // updated selectedIndex when clickedIndex is updated
  useEffect(() => {
    setSelectedIndex(clickedIndex);
    if (DOWNLOAD_URL === "" || !clickedIndex) return;

    const currentItem = carouselItems[clickedIndex];
    if (!currentItem) return;

    const base64 = Buffer.from(filesColRef?.path! + '/' + currentItem["@id"]!, 'utf-8').toString('base64');
    setDownloadUrl(DOWNLOAD_URL+`${base64}?useOriginal=true`);
  }, [clickedIndex])

  useEffect(() => {
    if (!thumbnailContainerRef)
      return

    if (!thumbnailContainerRef.current)
      return

    setIsOverflowing(thumbnailContainerRef.current.clientWidth < thumbnailContainerRef.current.scrollWidth)
  }, [thumbnailContainerRef, thumbnailContainerRef.current])

  useEffect(() => {
    setSelectedIndex(clickedIndex);

    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        toggleAttachmentCarousel();
        setSelectedIndex(null)
      }
      if (event.key === "ArrowRight")
        return arrowRightRef && arrowRightRef.current && arrowRightRef.current.click();
      if (event.key === "ArrowLeft")
        return arrowLeftRef && arrowLeftRef.current && arrowLeftRef.current.click()
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      setSelectedIndex(null)
    };
  }, []);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", () => {
      onSelect()
    });
  }, [embla, files, files?.length, selectedIndex]);

	function ifUserIsSliding(e: React.MouseEvent<HTMLDivElement>) {
		if (hasMoved) {
			e.stopPropagation();
			setHasMoved(true);
		}
	}

	if (selectedIndex === null)
    return <></>

  if (!carouselItems.length) {
    return (
      <Box
        sx={{
          position: "fixed",
          display: "flex",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          zIndex: 3,
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          top: 0,
          left: 0
        }}
      >
      <InProgress/>
    </Box>
    )
  }

  return (
    <>
      <Backdrop
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          zIndex: 3,
        }}
        open={true}
      >
        <Stack
          direction="row"
          position="fixed"
          sx={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
        </Stack>
      </Backdrop>
      <Stack
        width="100%"
        tabIndex={0}
        sx={{
					height: "100%",
          position: "fixed",
          zIndex: 3,
          top: 0,
          left: 0,
          "&:focus": {
            outline: "none",
          }
        }}
        onClick={toggleAttachmentCarousel}
      >
        <Stack
          direction="row"
          sx={{
            position: "absolute",
            top: 16,
            left: 16,
            zIndex: 1,
          }}
        >
          <Tooltip title={enCommonButton.close}>
            <IconButton
							sx={{
								zIndex: 2
							}}
              onClick={toggleAttachmentCarousel}
            >
              <SystemIcons.Close stroke={theme.palette.common.white} style={{
                width: 28,
                height: 28,
              }}/>
            </IconButton>
          </Tooltip>
          <Tooltip title={`Download ${carouselItems[selectedIndex]?.nameWithExt}`}>
            <IconButton
              sx={{
                zIndex: 2
              }}
              onClick={() => {
                downloadFile(downloadUrl, carouselItems[selectedIndex]?.nameWithExt)
              }}
            >
            <SystemIcons.Download
              stroke={theme.palette.common.white} style={{
              width: 28,
              height: 28
            }}
            />
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack width="100%" height="100%" justifyContent="center">
          <div className="embla" id="carouselMain" style={{
						width: "100%",
						padding: 0
					}}>
            <div className="embla__viewport" ref={mainViewportRef}>
              <div className="embla__container">
                {
                  carouselItems.map((val, index) => {
                    return (
                      <div className="embla__slide" key={index}>
                        <div className="embla__slide__inner" onClick={ifUserIsSliding} style={{
													minHeight: "100%",
												}}>
                          <MainView carouselItem={val} isActive={Math.abs(index - selectedIndex) <= 1}/>
                        </div>
                      </div>
                    )
                  })
                }
              </div>

							{selectedIndex !== 0 && (
								<IconButton
									className="embla__button--prev"
									ref={arrowLeftRef}
									onClick={(e) => {
										e.stopPropagation();
										scrollPrev()
									}}
									style={{
										zIndex: 0,
										height: "100%",
										top: 0,
										left: 0,
										borderRadius: 0,
										background: "linear-gradient(90deg, rgba(0,0,0,0.2) 0%, rgba(255,255,255,0) 100%)",
										position: "absolute",
										visibility: carouselItems.length <= 1 ? "hidden" : "visible"
									}}
								>
									<Box
										sx={{
											backgroundColor: theme.palette.secondary.main,
											borderRadius: "100%",
											width: 48,
											height: 48,
											opacity: 0.75
										}}
									>
										<SystemIcons.ChevronLeft
											stroke={selectedIndex !== 0 ? theme.palette.common.white : theme.palette.neutral.dark}
											style={{width: 48, height: 48}}
										/>
									</Box>
								</IconButton>
							)}

							{selectedIndex !== carouselItems.length - 1 && (
								<IconButton
									ref={arrowRightRef}
									className="embla__button--next"
									onClick={(e) => {
										e.stopPropagation();
										scrollNext()
									}}
									style={{
										height: "100%",
										top: 0,
										right: 0,
										borderRadius: 0,
										background: "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.1) 100%)",
										position: "absolute",
									}}
								>
									<Box
										sx={{
											backgroundColor: theme.palette.secondary.main,
											borderRadius: "100%",
											width: 48,
											height: 48,
											opacity: 0.75
										}}
									>
										<SystemIcons.ChevronRight
											stroke={selectedIndex === carouselItems.length - 1 ? theme.palette.neutral.dark : theme.palette.common.white}
											style={{width: 48, height: 48}}
										/>
									</Box>
								</IconButton>
							)}
            </div>
          </div>
          <div className="embla embla--thumb" style={{width: "100%", position: "absolute", bottom: 10}}>
            <div className="embla__viewport" ref={thumbViewportRef}>
              <div ref={thumbnailContainerRef} className="embla__thumb__container embla__container--thumb"
                   style={{justifyContent: isOverflowing ? "flex-start" : "center", alignItems: "end"}}>
                {carouselItems.map((val, index) => (
                  <Thumbnail
                    onClick={() => onThumbClick(index)}
                    selected={index === selectedIndex}
                    item={val}
                    viewable={Math.abs(index - selectedIndex) <= 2}
                    key={index}
                  />
                ))}
              </div>
            </div>
          </div>
        </Stack>
      </Stack>
    </>
  )
}

interface MainViewProps {
  carouselItem: CarouselItem;
  isActive: boolean;
}

function MainView(props: MainViewProps) {
  const {carouselItem, isActive} = props;
  const {type, destPath, nameWithExt} = carouselItem;
  const fileTitle = useFileTitle(nameWithExt);

  const [source, setSource] = useState<undefined | string>(undefined)
  const storageBucket = getEnvKey(storageBucketKey);
  const preview = getPreviewFromMime(type ?? "image/*");

  const needsSource = preview === Previews.Image || preview === Previews.Video;


   const [videoStyle, setVideoStyle] = useState<{ height: string, maxWidth: string }>({ height: "auto", maxWidth: "60%" });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setVideoStyle({ height: "auto", maxWidth: "90%" });
      } else {
        setVideoStyle({ height: "auto", maxWidth: "60%" });
      }
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {

    if (!isActive)
      return

    if (!needsSource)
      return

    if (source)
      return;

    async function getSource() {
      const sourceReference = ref(storage, `${storageBucket}${destPath}`);
      getDownloadURL(sourceReference).then((downloadURL) => setSource(downloadURL))
    }

    getSource()
  }, [destPath, needsSource, source, isActive])

	const handleImageClick = (e: React.MouseEvent<HTMLImageElement|HTMLVideoElement|HTMLDivElement|HTMLButtonElement>) => {
		e.stopPropagation();
	}

  if (!isActive)
    return null

  if (!source && needsSource) return null;

  switch (preview) {
    case Previews.Image:
      return (
        <Tooltip title={fileTitle}>
          <img
            onClick={handleImageClick}
            className="embla__slide__img"
            src={source}
            alt="chat attachment"
            style={{objectFit: "scale-down", maxHeight: "85%", maxWidth: "90%"}}
          />
        </Tooltip>
      );
    case Previews.Video:
      return (
        <Tooltip title={fileTitle}>
          <video
            onClick={handleImageClick}
            className="embla__slide__img"
            style={videoStyle}
            src={source}
            controls
          />
        </Tooltip>
      )
    case Previews.PDF:
      return (
        <Tooltip title={fileTitle}>
          <img
            onClick={handleImageClick}
            className="embla__slide__img"
            src={PDFImg}
            alt="chat attachment"
          />
        </Tooltip>
      )
    case Previews.Zip:
      return (
        <Box
          title={fileTitle}
          onClick={handleImageClick}
          className="embla__slide__img">
          <SystemIcons.Zip fill="white" width={64} height={64}/>
        </Box>
      )
    case Previews.Document:
      return (
        <Box
          title={fileTitle}
          onClick={handleImageClick}
          className="embla__slide__img">
          <SystemIcons.Document fill="white" width={64} height={64}/>
        </Box>
      )
    default:
      return (
        <Box
          title={fileTitle}
          onClick={handleImageClick}
          className="embla__slide__img">
          <SystemIcons.FilesFilled fill="white" width={64} height={64}/>
        </Box>
      )
  }
}

const Thumbnail = ({
                     selected,
                     onClick,
                     item,
                     viewable
                   }: { selected: boolean, onClick: () => void, item: CarouselItem, viewable: boolean }) => {
  const {type, destPath} = item;
  const [source, setSource] = useState<undefined | string>(undefined)
  const storageBucket = getEnvKey(storageBucketKey);
  const preview = getPreviewFromMime(type ?? "image/*");
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const needsSource = preview === Previews.Image || preview === Previews.Video;

  useEffect(() => {

    if (!viewable)
      return

    if (!needsSource)
      return

    //don't fetch if source is already fetched
    if (source)
      return;

    async function getSource() {
      const sourceReference = ref(storage, `${storageBucket}${destPath}`);
      getDownloadURL(sourceReference).then((downloadURL) => setSource(downloadURL))
    }

    getSource()
  }, [destPath, needsSource, source, viewable])

  useEffect(() => {
    if (preview !== Previews.Video)
      return;

    async function generateVideoThumbnail() {
      const sourceReference = ref(storage, `${storageBucket}${destPath}`);
      const vidSource = await getDownloadURL(sourceReference)

      const videoPlayer = document.createElement('video');
      videoPlayer.src = vidSource;
      videoPlayer.preload = "metadata";
      videoPlayer.crossOrigin = "anonymous";
      videoPlayer.load();
      videoPlayer.addEventListener('loadedmetadata', () => {
        if (videoPlayer.duration < 0.0) {
          return;
        }
        setTimeout(() => {
          videoPlayer.currentTime = 0.0;
        }, 200);
        videoPlayer.addEventListener('seeked', () => {
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          const ctx = canvas.getContext("2d")!;
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          setPreviewUrl(ctx.canvas.toDataURL(
            "image/jpeg",
            0.75
          ));
        });
      });
    }

    generateVideoThumbnail()
  }, [preview, destPath]);

  if (!viewable) {
    return null;
  }

  return <div
    className={`embla__slide embla__slide--thumb ${
      selected ? "is-selected" : ""
    }`}
    style={{height: "50px", minWidth: "50px"}}
  >
    <button
      onClick={(e) => {
        e.stopPropagation();
        onClick()
      }}
      className="embla__slide__inner embla__slide__inner--thumb"
      type="button"
			style={{height: "100%"}}
    >
      <DynamicThumbnail source={source} preview={preview} vidThumbnail={previewUrl ?? undefined}/>
    </button>
  </div>
};

interface DynamicThumbnailProps {
  source: string | undefined;
  preview: Previews;
  vidThumbnail?: string
}

function DynamicThumbnail(props: DynamicThumbnailProps) {
  const {source, preview, vidThumbnail} = props;

  const needsSource = preview === Previews.Image || preview === Previews.Video;

  if ((!source && !vidThumbnail) && needsSource)
    return null;

  switch (preview) {
    case Previews.Image:
      return <img
        className="embla__slide__thumbnail"
        src={source}
        alt="chat thumb"
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
					maxHeight: "100px",
        }}
      />
    case Previews.Video:
      return !vidThumbnail ? (
        <Box
          className="embla__slide__thumbnail"
          sx={{
            backgroundColor: "rgba(255,255,255,0.2)",
            padding: 8,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transform: "scale(1)",
          }}>
          <SystemIcons.Video strokeWidth={0} fill="white" width={32} height={32}/>
        </Box>
      ) : <img
        className="embla__slide__thumbnail"
        style={{height: "100%"}}
        src={vidThumbnail}
        alt="chat thumb"
      />
    case Previews.PDF:
      return <Box
        className="embla__slide__thumbnail"
        sx={{
          backgroundColor: "rgba(255,255,255,0.2)",
          padding: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transform: "scale(1)"
        }}
      >
        <img
          src={PDFImg}
          alt="chat thumb"
        />
      </Box>
    case Previews.Zip:
      return <Box
        className="embla__slide__thumbnail"
        sx={{
          backgroundColor: "rgba(255,255,255,0.2)",
          padding: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transform: "scale(1)",
        }}>
        <SystemIcons.Zip fill="white" width={45} height={45}/>
      </Box>
    case Previews.Document:
      return <Box
        className="embla__slide__thumbnail"
        sx={{
          backgroundColor: "rgba(255,255,255,0.2)",
          padding: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transform: "scale(1)",
        }}>
        <SystemIcons.Document fill="white" width={45} height={45}/>
      </Box>
    default:
      return <Box
        className="embla__slide__thumbnail"
        sx={{
          backgroundColor: "rgba(255,255,255,0.2)",
          padding: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transform: "scale(1)",
        }}>
        <SystemIcons.FilesFilled fill="white" width={32} height={32}/>
      </Box>
  }
}

export default LazyCarousel;
