import {SelectedItem} from "components/Drawers/AddTeamOrPeopleDrawer";
import {Button, Checkbox, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {enCommonButton, enCommonLabel} from "constants/index";
import React from "react";
import AccessRoleMenu, {AccessRoleMenuType} from "components/Drawers/ManageAccessDrawer/Objects/AccessRoleMenu";
import {AccessRole} from "enums/index";

interface BulkListHeaderProps {
  isAllSelected: boolean;
  selectedItems: SelectedItem[];
  access: any;
  isLoading: boolean;
  selectAll: () => void;
  triggerMultipleDelete: () => void;
  triggerBulkEdit: (selected: AccessRole) => void;
  type: AccessRoleMenuType;
  listLength: number;
}

function BulkListHeader(props: BulkListHeaderProps) {
  const {isAllSelected, type, selectedItems, access, isLoading, listLength} = props;
  const {triggerMultipleDelete, triggerBulkEdit, selectAll} = props;

  if(listLength === 0) return null;

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center">
        <Checkbox
          id={"select-all-checkbox"}
          checked={isAllSelected}
          onChange={selectAll}
        />
        <Typography
          variant="h5"
          id="label"
          display="inline"
          color={theme.palette.secondary.main}
        >
          {selectedItems.length === 0 ? AccessRoleMenuType[type] : `${selectedItems.length}  ${enCommonLabel.selected}`}
        </Typography>
        {selectedItems.length === 0 && (
          <Typography color={theme.palette.text.secondary} ml={0.5} variant="h5">{listLength}</Typography>
        )}
      </Stack>
      {selectedItems.length > 1 && (
        <Stack direction="row" alignItems="center" gap={1} paddingY={1}>
          <AccessRoleMenu
            id="manage-access-drawer-edit-role-button"
            onUpdateAccessRole={triggerBulkEdit}
            displayChevron={false}
            updateLabelOnSelect={false}
            textLabel={enCommonButton.editRole}
            selectSx={{width: "40px", color: "primary"}}
            textVariant={"h5"}
            enabled={!isLoading}
            access={access}
          />
          <Button
            size="small"
            sx={{fontSize: "14px"}}
            color="error"
            id="manage-access-drawer-remove-button"
            disabled={isLoading}
            onClick={triggerMultipleDelete}
          >
            {enCommonButton.remove}
          </Button>
        </Stack>
      )}
    </Stack>
  )
}

export default BulkListHeader;