import {InProgress, OverFlowBox} from "components/index";
import TeamsList from "components/Drawers/AddTeamOrPeopleDrawer/Objects/TeamsList";
import PeopleList from "components/Drawers/AddTeamOrPeopleDrawer/Objects/PeopleList";
import React from "react";
import {AccessListUser} from "types/AccessListUser";
import {CollectionReference} from "firebase/firestore";
import {SelectedItem} from "components/Drawers/AddTeamOrPeopleDrawer/index";
import EmptySearchResults from "components/EmptySearchResults";
import {Entity} from "enums/entity";
import {AccessType} from "types/Permission";

interface DynamicListProps {
  parentTeamsList: AccessListUser[] | null
  parentMembersList: AccessListUser[] | null
  fromAlgolia: boolean;
  access: AccessType | null;
  collectionReference: CollectionReference
  setSelectedTeams: (newSelected?: SelectedItem[]) => void;
  setSelectedMembers: (newSelected?: SelectedItem[]) => void;
}

function DynamicList(props: DynamicListProps) {
  const {
    parentTeamsList,
    parentMembersList,
    fromAlgolia,
    access,
    setSelectedTeams,
    setSelectedMembers
  } = props;

  if ((parentTeamsList === null && parentMembersList === null) || !access)
    return <InProgress/>

  if(fromAlgolia && parentTeamsList?.length === 0 && parentMembersList?.length === 0)
    return <EmptySearchResults entity={Entity.TeamsAndPeople}/>

  return <OverFlowBox>
    <TeamsList
      teams={parentTeamsList}
      setSelectedTeams={setSelectedTeams}
      access={access}
    />
    <PeopleList
      people={parentMembersList}
      setSelectedMembers={setSelectedMembers}
      access={access}
    />
  </OverFlowBox>
}

export default DynamicList;