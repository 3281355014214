import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import React, {useEffect, useState} from "react";
import {Meeting} from "types/Meeting";
import {LoadingButton} from "@mui/lab";
import {DocumentReference, Timestamp} from "firebase/firestore";
import {submitForm} from "screens/utility";
import {ActionType} from "enums/actionType";
import {Typography} from "@mui/material";
import {emptyFunction, enCommonButton} from "constants/index";
import {InProgress} from "components/index";
import useTemplateDocument from "hooks/useTemplateDocument";

interface VideoCallButtonProps {
  documentReference: DocumentReference
}

function VideoCallButton(props: VideoCallButtonProps) {
  const {documentReference} = props;
  const [document] = useTemplateDocument<Meeting>(documentReference)

  const [loading, setLoading] = useState(false)
  const [waitingRoomLink, setWaitingRoomLink] = useState(false);

  useEffect(() => {
    if (!waitingRoomLink)
      return

    if (!document)
      return

    if (document.meetingLink) {
      window.open(`/videoCall?meeting-link=${document.meetingLink}`)
      setLoading(false)
      setLoading(false)
      setWaitingRoomLink(false)
    }
  }, [waitingRoomLink, document])

  async function startVideoChat() {
    if (!document)
      return

    await requestNewMeeting()
  }

  async function joinVideoChat() {
    if (!document)
      return

    if (document.meetingExpirationDateTime < Timestamp.now()) {
      await requestNewMeeting()
    } else {
      await submitForm<Partial<Meeting>>(documentReference, ActionType.Update, emptyFunction, {meetingLastMemberToJoinDateTime: Timestamp.now()})
      window.open(`/videoCall?meeting-link=${document.meetingLink}`)
    }
  }

  async function requestNewMeeting() {
    setLoading(true)
    setWaitingRoomLink(true);
    await submitForm<Partial<Meeting>>(documentReference, ActionType.Update, emptyFunction, {requestMeetingLink: true})
  }

  if (!document)
    return <InProgress/>

  if (!document.meetingLink)
    return <LoadingButton
      id="drawer-close-head-button"
      size="small"
      disableRipple
      onClick={startVideoChat}
      loading={loading}
      color="secondary"
      sx={{backgroundColor: loading ? theme.palette.neutral.light : "none"}}
    >
      <SystemIcons.Video
        fill="none"
        stroke={theme.palette.text.primary}
        opacity={loading ? 0.1 : 1}
      />
    </LoadingButton>

  return <LoadingButton
    sx={{minWidth: 34, borderRadius: 4}}
    id="drawer-close-head-button"
    size="small"
    disableRipple
    onClick={joinVideoChat}
    loading={loading}
    variant="contained"
    color="secondary"
  >
    <SystemIcons.Video
      fill="none"
      stroke={theme.palette.background.paper}
    />
    <Typography variant="h5" sx={{marginLeft: 1}}>{enCommonButton.joinCall}</Typography>
  </LoadingButton>
}

export default VideoCallButton
