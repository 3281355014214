import {styled, Tab, TabProps} from "@mui/material";
import theme from "theme/theme";

function CustomTab(props: TabProps){
  return <StyledTab disableRipple {...props}/>
}

const StyledTab =  styled(Tab)({
  paddingY: 1,
  '&.Mui-selected': {
    color: theme.palette.secondary.main
  },
});

export default CustomTab;