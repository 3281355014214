import {FormBase} from "types/FormBase";
import {ViewStatus} from "enums/viewStatus";

function isDocLoading(doc: { "@form"?: FormBase } | null | undefined) {

  if(!doc)
    return true

  if(!doc["@form"])
    return false

  switch (doc["@form"]["@status"]) {
    case ViewStatus.Submit:
    case ViewStatus.Submitted:
    case ViewStatus.Processing:
      return true

    default:
      return false
  }
}

export default isDocLoading
