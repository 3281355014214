import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import React, {ChangeEvent, FC, FocusEvent, MouseEvent, ReactElement, useState} from "react";
import theme from "theme/theme";

interface InputProps {
  label?: string;
  LabelComponent?: ReactElement;
  type?: "text" | "password" | "number";
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | React.ClipboardEvent<HTMLDivElement>) => void;
  validator?: [validity: boolean, errorMessage: string];
  value?: string | number;
  dataTestId?: string;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  placeholder?: string;
  InputProps?: any;
  sx?: SxProps<Theme>;
  textfieldSx?: any;
  defaultValue?: string;
  id?: string;
  optional?: boolean;
  validationMessage?: string;
  onFocus?: (e: FocusEvent) => void;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  minimumNumber?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  onKeyPress?: (e: React.KeyboardEvent) => void;
  error?: boolean;
  addon?: ReactElement;
}

const Input: FC<InputProps> = (props: InputProps) => {
  const {
    label,
    LabelComponent,
    type = "string",
    onChange,
    validator, // todo: remove this
    value,
    dataTestId,
    multiline,
    minRows,
    maxRows,
    placeholder,
    InputProps,
    sx,
    textfieldSx,
    defaultValue,
    id,
    optional,
    validationMessage,
    onFocus,
    onClick,
    minimumNumber,
    autoFocus,
    disabled = false,
    onKeyPress,
    error = false,
    addon
  } = props;

  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
  const [typeState, setTypeState] = useState<string>(type);
  let [valid, errorMessage] = [true, ""];

  if (validator) {
    [valid, errorMessage] = validator;
  }
  if (validationMessage) {
    valid = false;
  }

  let endAdornment = <></>;
  if (type === "password") {
    endAdornment = (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => {
            setIsPasswordShown(!isPasswordShown);
            setTypeState(typeState === "string" ? "password" : "string");
          }}
          sx={{mr: "0px"}}
          edge="end"
          id="toggle-password-btn"
        >
          {isPasswordShown ? (
            <SystemIcons.Eye id="show-password-icon" stroke={theme.palette.neutral.dark}/>
          ) : (
            <SystemIcons.EyeOff id="show-password-icon" stroke={theme.palette.neutral.dark}/>
          )}
        </IconButton>
      </InputAdornment>
    );
  }

  return (
    <Box sx={{alignSelf: "stretch", ...sx}}>
      {label && (
        <Typography variant="h5">
          {`${label} `}
          {optional ? (
            <Typography
              component="span"
              variant="body"
              fontWeight={400}
              color={theme.palette.neutral.dark}>
              (optional)
            </Typography>
          ): (
            <Typography
              component="span"
              variant="body"
              color={theme.palette.error.main}>
              *
            </Typography>
          )}
        </Typography>
      )}
      {LabelComponent}
      <Stack direction="row" justifyContent="space-between">
        <TextField
          error={error}
          autoFocus={autoFocus}
          id={id}
          placeholder={placeholder}
          inputProps={{
            "data-testid": dataTestId,
          }}
          minRows={minRows}
          maxRows={maxRows}
          multiline={multiline}
          InputProps={{
            endAdornment,
            inputProps: {
              min: minimumNumber,
              ...InputProps,
            }
          }}
          value={value}
          defaultValue={defaultValue}
          InputLabelProps={{shrink: true}}
          type={typeState}
          fullWidth
          disabled={disabled}
          onChange={(e) => onChange(e)}
          onPaste={(e) => onChange(e)}
          onFocus={onFocus}
          onClick={onClick}
          onKeyPress={onKeyPress}
          required={!optional}
          sx={{
            "& .MuiOutlinedInput-root": {
              padding: 0,
              "& fieldset": {
                borderColor: valid ? theme.palette.neutral.medium : theme.palette.error.main,
              },
              ...(textfieldSx ? textfieldSx : {}),
            },
            "& .MuiInputBase-root": {
              color: disabled ? theme.palette.secondary.light : valid ? "primary" : "error",
              backgroundColor: disabled ? theme.palette.background.swiftDefault : theme.palette.background.paper,
            },
            "& .MuiOutlinedInput-input": {
              padding: "9px 16px",
            },
          }}
        />
        {addon}
      </Stack>

      {(validator || validationMessage) && (
        <Box sx={{height: 0, marginBottom: "20px"}}>
          <Typography
            sx={{
              mt: 0.5,
              minHeight: "1em",
              color: "#FF5230",
              alignSelf: "flex-start",
              width: "max-content",
              fontSize: "12px",
              lineHeight: "20px",
              ml: 1,
            }}
            id={id + "-validation-error"}
          >
            {validator && !valid && errorMessage}
            {validationMessage}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Input;
