import { Typography, TypographyProps} from "@mui/material";
import theme from "theme/theme";

interface LabelTextProps extends TypographyProps {
  showOnMobile?: boolean
}

function LabelText(props: LabelTextProps) {
  const {showOnMobile, ...rest} = props
  return <Typography
    sx={{
      color: theme.palette.text.secondary,
      display: {sx: showOnMobile ? "block" : "none", md: showOnMobile ? "none" : "block"}
    }}
    {...rest}>
  </Typography>
}

export default LabelText