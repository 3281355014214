import {toastProps} from "screens/BaseProps";
import {Stack} from "@mui/material";
import CheckboxSwitch from "components/CheckboxSwitch";
import LabeledCheckbox from "components/LabeledCheckbox";
import {emptyFunction, enCommonLabel, enMilestoneLabel, enTaskLabel} from "constants/index";
import {ActionType, ViewStatus, Severity, Entity} from "enums/index";
import ActionButtons from "components/MilestoneView/ActionButtons";
import ConfirmDialog from "components/ConfirmDialog";
import {useComponentToggler} from "hooks/index";
import {useEffect, useState} from "react";
import {doc} from "firebase/firestore";
import {submitForm} from "../../utility";
import {db} from "../../../firebase";

interface BulkActionsProps {
  toastProps: toastProps;
  isCheckboxShown: boolean;
  showHidden: boolean;
  milestonesCollectionRefPath: string;
  checkedTasks: {taskId: string, milestoneId: string}[];
  setIsCheckboxShown: (value: boolean) => void;
  resetCheckedTasks: () => void;
  deleteSelected: () => void;
}

function BulkActions(props: BulkActionsProps) {
  const {isCheckboxShown, checkedTasks, showHidden, setIsCheckboxShown} = props;

  const [isDeleteDialogOpen, {open: openDeleteDialog, close: closeDeleteDialog}] = useComponentToggler(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updateStatus, setUpdateStatus] = useState<ViewStatus[]>([]);

  useEffect(() => {
    if (!isDeleteDialogOpen) return;

    if (checkedTasks.length === 0) {
      closeDeleteDialog();
    }
  }, [checkedTasks.length]);

  useEffect(() => {
    if (updateStatus.length === 0) return;

    if (updateStatus.length < checkedTasks.length) return;

    showMessage(props.showHidden ? enMilestoneLabel.unHideSuccess : enMilestoneLabel.hideSuccess, Severity.Success);
    setIsCheckboxShown(false);
    setIsLoading(false);
    setUpdateStatus([]);
    closeDeleteDialog();
    props.resetCheckedTasks();
  }, [updateStatus]);

  async function toggleHiddenTasks() {
    // if already processing
    if (isLoading) return;

    // if no tasks selected
    if (checkedTasks.length === 0) return;

    setIsLoading(true);

    checkedTasks.forEach(task => {
      const taskRef = doc(db, props.milestonesCollectionRefPath, task.milestoneId, "tasks", task.taskId);
      submitForm(taskRef, ActionType.Update,
        (isLastUpdate) => isLastUpdate && setUpdateStatus(prevState => [...prevState, ViewStatus.Finished]),
        {hidden: !showHidden}
      )
    });
  }

  function showMessage(message: string, severity: Severity) {
    const {setToastMessage, setToastSeverity, setIsToastOpen} = props.toastProps!;

    setToastMessage(message);
    setToastSeverity(severity);
    setIsToastOpen(true);
  }

  return (
    <Stack
      direction={{xs: "column", sm: "column", md: "row", lg: "row"}}
      gap={{xs: 0, sm: 0, md: 1, lg: 1}}
      justifyContent="flex-start"
      alignItems="center"
      marginY={1}
      sx={{backgroundColor: "white"}}
    >
      <CheckboxSwitch
        isCheckboxShown={isCheckboxShown}
        toggleCheckbox={() => setIsCheckboxShown(!isCheckboxShown)}
      />
      {isCheckboxShown && checkedTasks.length > 0 && (
        <Stack marginLeft={1} title="Click to unselect all">
          <LabeledCheckbox
            id="select-multiple-checkbox"
            onChange={() => props.resetCheckedTasks()}
            checked={checkedTasks.length > 0}
            label={enCommonLabel.countSelected(checkedTasks.length)}
          />
        </Stack>
      )}
      <ActionButtons
        parentEntity={Entity.Templates}
        atLeastOneSelected={checkedTasks.length > 0}
        openDialog={openDeleteDialog}
        openDrawer={emptyFunction}
        showHidden={showHidden}
        toggleHiddenTasks={{
          toggleLoading: isLoading,
          toggleFunction: toggleHiddenTasks,
        }}
      />
      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        title={enTaskLabel.multipleDeleteConfirmationTitle}
        text={enTaskLabel.multipleDeleteConfirmationText}
        handleClose={closeDeleteDialog}
        handleConfirm={props.deleteSelected}
        id="bulk-delete-drawer"
      />
    </Stack>
  )
}

export default BulkActions;