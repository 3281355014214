import useUser from "hooks/useUser";
import SidebarHead from "./SidebarHead";
import SidebarOrgLabel from "./SidebarOrgLabel";
import MainAndBottom from "components/SidebarMenu/MainAndBottom";
import {BaseProps} from "screens/BaseProps";
import SAPSideBar from "components/SidebarMenu/SidebarLinks/SAPSideBar";
import {Stack} from "@mui/material";

interface SidebarMenuProps extends BaseProps {
  isSuperAdmin?: boolean;
  toggleSidebar: () => void;
}

function SidebarMenu(props: SidebarMenuProps) {
  const {uid, toggleSidebar, isSuperAdmin = false} = props;
  const user = useUser(uid);

  return (
    <>
      <Stack minHeight="100vh" maxHeight="100vh">
        {/**Top */}
        <SidebarHead isSuperAdmin={isSuperAdmin} uid={uid!}/>
        {isSuperAdmin && <SAPSideBar {...props} />}
        {/**Explore Organizations Dropdown */}
        <SidebarOrgLabel {...props} adminSidebarView={user?.adminSidebarView}/>
        {/**Main Frame*/}
        <MainAndBottom
          {...props}
          toggleSidebar={toggleSidebar}
        />
      </Stack>
    </>
  );
}

export default SidebarMenu;
