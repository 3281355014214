export const asset = {
  title: "Asset",
  subtitle: "",
  description: "",
  label: {
    uniqueNameError: "Asset name must be unique",
    requiredName: "Asset name is required",
    exportDialogTitle: "Export project asset data",
    pltExportDialogTitle: "Export project level data",
    exportingDialogTitle: "Exporting project asset data...",
    pltExportingDialogTitle: "Exporting project level data...",
    exportDialogText: "Download project asset data as CSV/Excel file.",
    pltExportDialogText: "Download project level data as CSV/Excel file.",
    emptyTitle: "No Assets yet",
    emptyDescription: "Create the first Asset.",
    createParentAsset: (parent: string) => `Create ${parent} Asset`,
    search: "Search Assets...",
    edit:"Edit Asset",
    new: "New Asset",
    manage: "Manage Project Assets",
    createSuccess: "Asset was successfully created.",
    updateSuccess: "Asset updated successfully",
    updateError: "Asset update has failed.",
    nameRequired: "Asset name is required.",
    idCodeRequired: "Asset ID Code is required.",
    existingIdCode: "Asset with this ID code already exists.",
    invalidInput: "Invalid Input Value",
    deleteConfirmationTitle:(label: number | string) =>`Are you sure you want to delete ${typeof label === "number" ? label + " assets" : label}?`,
    deleteConfirmationText: "These items will be deleted permanently. You can't undo this action.",
    deleteSuccess: "Asset/s Deleted successfully.",
    deleteError: "Encountered an error while deleting asset/s.",
    recentAssets: "Recent Assets",
    selectAllAssets: "Select All Assets",
    importingAssets: "Importing Project Asset Data...",
    buildingAssets: "Building Project Asset Data...",
    noIdCode: "No ID Code",
    noAddress: "No Address",
    tasksCount: "Tasks Count",
    firstAsset: "Add the first asset",
    manageAsset: "Manage Assets",
    manageAccess: "Manage Access",
    manageAssetAccess: "Manage Assets Access",
    requirements: "Requirements",
    idCode: "ID Code",
    files: "Files",
    assets: "Assets",
    tasks: "Tasks",
    projectAssetsStatus: "Project Asset Status",
    cannotOverrideStatus: "You are not allowed to override the status of this asset",
    noFilesToDownload: "No Files to Download.",
    preparingFiles: "Preparing files for download...",
    preparingFilesText: "We are preparing your files. This may take a few moments.",
    readyForDownload: "Files are ready for download",
    clickDownload: "Click Download below",
    approvedOverTotalTasks: "Approved Tasks / Total Tasks",
    approvedOverTotalRequirements: "Approved Requirements / Total Requirements",
    latitudeRequired: "Latitude is required when longitude is given.",
    longitudeRequired: "Longitude is required when latitude is given.",
    assetAddedSuccess: "Asset/s has been added successfully",
    assetUpdatedSuccess: "Asset/s has been updated successfully",
    assetRemovedSuccess: "Asset/s has been removed successfully",
    noMoreAssets: "No more assets to load",
  },
  button: {
    create: "Create New Asset"
  }
}
