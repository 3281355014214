import {PermissionEntity} from "types/Permission";

/**
 * List of groups of entities that are displayed in the permissions table.
 * Each group is displayed in a separate panel.
 * The first entity in each group is the main entity and will be used to name group.
 */
export const ENTITY_GROUPS = [
  [
    PermissionEntity.Asset,
    PermissionEntity.ProjectAssetAccessList,
    PermissionEntity.ProjectAssetImport,
    PermissionEntity.ProjectAssetExport,
    PermissionEntity.AssetFilesDownload
  ],
  [PermissionEntity.FileRequirement, PermissionEntity.FileRequirementFilesDownload],
  [PermissionEntity.Form, PermissionEntity.FormExport],
  [PermissionEntity.FormRequirement],
  [PermissionEntity.Milestone, PermissionEntity.MilestoneFilesDownload],
  [PermissionEntity.Organization],
  [PermissionEntity.OrganizationAsset],
  [PermissionEntity.OrganizationMember],
  [
    PermissionEntity.OrganizationTemplate,
    PermissionEntity.OrganizationTemplateImport,
    PermissionEntity.OrganizationTemplateExport
  ],
  [
    PermissionEntity.Project,
    PermissionEntity.ProjectImport,
    PermissionEntity.ProjectExport,
    PermissionEntity.ProjectAccessList
  ],
  [PermissionEntity.ReferenceFile],
  [PermissionEntity.SwiftFile],
  [
    PermissionEntity.Task,
    PermissionEntity.TaskExport,
    PermissionEntity.TaskFilesDownload,
    PermissionEntity.TaskAccessList,
  ],
  [PermissionEntity.Team],
  [PermissionEntity.TeamCategory]
]