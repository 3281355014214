import {useEffect, useState} from "react";
import {enCommonLabel} from "constants/index";
import isNoPreviewFile from "../screens/utility/isNoPreviewFile";

function useNoPreviewFile(fileName: string | null | undefined): string {
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    if (!fileName) return;

    const isNoPreview = isNoPreviewFile(fileName);
    if (isNoPreview) {
      setMessage(enCommonLabel.noPreviewFile);
      return;
    }

    setMessage("");
  }, [fileName]);

  return message;
}

export default useNoPreviewFile;