import {Stack, Typography} from "@mui/material";
import {CustomTabs, CustomTab} from "components";
import {Dispatch} from "react";
import {enActivityFeed, enActivityFeedLabel} from "constants/index";

export enum ActivityFeedTabs {
  RecentActivities,
  Invites,
  Projects,
  Assets,
  Tasks,
  Requirements,
  Files,
}

interface PageHeaderProps {
  activeTab: ActivityFeedTabs;
  setActiveTab: Dispatch<ActivityFeedTabs>;
}

function PageHeader(props: PageHeaderProps) {
  const {activeTab, setActiveTab} = props;

  return (
    <Stack
      paddingY={2}
      maxWidth={"100vw"}
      paddingRight={"20%"}
      flexDirection={"column"}
      gap={1}
    >
      <Typography variant="h1" sx={{width: "100%"}} paddingY={1}>{enActivityFeed.title}</Typography>
      <Stack direction="column" width={"100%"} flex={1} spacing={2}>
        <CustomTabs
          value={activeTab}
          onChange={(_, value) => setActiveTab(value as ActivityFeedTabs)}
        >
          <CustomTab
            disableRipple
            label={
              <Stack direction="row" gap={0.5}>
                <Typography variant="h5">{enActivityFeedLabel.recent}</Typography>
              </Stack>
            }
          />
          <CustomTab
            disableRipple
            label={
              <Stack direction="row" gap={0.5}>
                <Typography variant="h5">{enActivityFeedLabel.invites}</Typography>
              </Stack>
            }
            sx={{ml: 5}}
          />
          <CustomTab
            disableRipple
            label={
              <Stack direction="row" gap={0.5}>
                <Typography variant="h5">{enActivityFeedLabel.projects}</Typography>
              </Stack>
            }
            sx={{ml: 5}}
          />
          <CustomTab
            disableRipple
            label={
              <Stack direction="row" gap={0.5}>
                <Typography variant="h5">{enActivityFeedLabel.assets}</Typography>
              </Stack>
            }
            sx={{ml: 5}}
          />
          <CustomTab
            disableRipple
            label={
              <Stack direction="row" gap={0.5}>
                <Typography variant="h5">{enActivityFeedLabel.tasks}</Typography>
              </Stack>
            }
            sx={{ml: 5}}
          />
          <CustomTab
            disableRipple
            label={
              <Stack direction="row" gap={0.5}>
                <Typography variant="h5">{enActivityFeedLabel.requirements}</Typography>
              </Stack>
            }
            sx={{ml: 5}}
          />
          <CustomTab
            disableRipple
            label={
              <Stack direction="row" gap={0.5}>
                <Typography variant="h5">{enActivityFeedLabel.files}</Typography>
              </Stack>
            }
            sx={{ml: 5}}
          />
        </CustomTabs>
      </Stack>
    </Stack>
  )
}

export default PageHeader;