import {Box, InputAdornment, Stack, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {auth} from "../../../../firebase";
import {useNavigate} from "react-router";
import {emptyFunction, enCommonLabel, enProfileAndSettingsLabel} from "constants/index";
import {useDocument} from "hooks/index";
import {onChangeInput, submitForm, userPath} from "../../../utility";
import {User} from "types/User";
import React, {Dispatch, forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Input} from "components/index";
import {MuiTelInput, MuiTelInputCountry} from "mui-tel-input";
import {ActionType, ProcessType, Severity} from "enums/index";
import {BaseProps} from "../../../BaseProps";
import Avatar from "./Avatar";
import {en} from "language/en";
import FeatureWrapper from "components/FeatureWrapper";
import {FeatureType} from "enums/featureType";
import useSoundWithSettings from "hooks/useSoundWithSettings";
import logoutSfx from "../../../../sounds/logout.mp3"
import {statusSubmitHandler} from "screens/utility/statusSubmitHandler";
import {LoadingButton} from "@mui/lab";

interface ProfileProps extends BaseProps {
  setLoading: Dispatch<boolean>
  setIsSaveButtonValid: Dispatch<boolean>
}

const Profile = forwardRef((props: ProfileProps, ref)  => {
  const {toastProps, setLoading, uid, setIsSaveButtonValid} = props;
  const {setToastMessage, setIsToastOpen, setToastSeverity} = toastProps!;

  const [user] = useDocument<User>(userPath(uid));
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<number | undefined>(undefined);
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState<MuiTelInputCountry>("US");
  const [userErrorData, setUserErrorData] = useState<User>({} as User);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const playSound = useSoundWithSettings(logoutSfx);

  //reset button state on load
  useEffect(() => {
    setIsSaveButtonValid(false);
  }, []);

  // Use `useImperativeHandle` to expose functions to parent component
  useImperativeHandle(ref, () => ({
    saveProfile,
  }));

  // validate difference in fields to check ability of save button
  useEffect(() => {
    if (!user)
      return

    if (user.firstName !== firstName) {
      setIsSaveButtonValid(true)
      return
    }

    if (user.lastName !== lastName) {
      setIsSaveButtonValid(true)
      return
    }

    if (user.phoneNumber !== phoneNumber) {
      setIsSaveButtonValid(true)
      return
    }

    if (user.phoneNumberCountryCode !== phoneNumberCountryCode) {
      setIsSaveButtonValid(true)
      return
    }

    setIsSaveButtonValid(false)
  }, [firstName, lastName, phoneNumber, phoneNumberCountryCode]);

  //load userData
  useEffect(() => {
    if (!user) return;

    firstName !== user.firstName && setFirstName(user.firstName);
    lastName !== user.lastName && setLastName(user.lastName);
    phoneNumber !== user.phoneNumber && setPhoneNumber(user.phoneNumber);
    phoneNumberCountryCode !== user.phoneNumberCountryCode && setPhoneNumberCountryCode(user.phoneNumberCountryCode);
    setLoading(false)
  }, [user]);

  const saveProfile = async () => {
    setLoading(true)
    await submitForm(userPath(uid), ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler<User>({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback
      }),
      {
        firstName,
        lastName,
        phoneNumber,
        phoneNumberCountryCode
      }
    );
  }

  function successCallback() {
    setToastMessage(enProfileAndSettingsLabel.saveSuccessToast);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    setLoading(false);
  }

  function errorCallback(message: any) {
    let errorMessage = enCommonLabel.errorProcess(ProcessType.Update);

    if (typeof message === "string" && message !== "") {
      errorMessage = message;
    }

    else if (typeof message === "object" && message !== null) {
      if (Object.keys(message).includes("details")) {
        errorMessage = message.details;
      } else {
        // set first key as error message
        const firstKey = Object.keys(message)[0];
        if (firstKey) {
          errorMessage = message[firstKey];
          setUserErrorData(message);
        }
      }
    }

    setToastMessage(errorMessage);
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
    setLoading(false);
  }

  async function removePushTokenFromUser(uid: string, savedPushToken: string | null) {
    const currentTokens = (user?.pushTokens ?? []);
    if (!savedPushToken || !currentTokens.includes(savedPushToken)) return;

    const updatedTokens = currentTokens.filter(token => token !== savedPushToken);
    await submitForm<Partial<User>>(
      userPath(uid), ActionType.Update,
      () => localStorage.removeItem("messagingPushToken"),
      { pushTokens: updatedTokens }
    );
  }

  async function logout() {
    setIsLoading(true);

    if (!uid) return;

    try {
      // Cleanup saved tokens
      const savedPushToken = localStorage.getItem("messagingPushToken");
      await removePushTokenFromUser(uid, savedPushToken);

      // Sign out from authentication system
      await auth.signOut();

      // Additional cleanup actions
      localStorage.removeItem("invitationId");
      playSound();

      // Navigate to login page
      navigate("/login");
    } catch (error) {
      setToastMessage(enCommonLabel.errorProcess('logging out'));
      console.error("Error during logout:", error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Stack gap={1.5}>
        <FeatureWrapper feature={FeatureType.Profile}>
          <Box padding={1.5} sx={{backgroundColor: theme.palette.background.swiftDefault}}>
            <Box maxWidth="sm">
              <Stack gap={2}>
                <Avatar user={user ?? {} as User} toastProps={toastProps!}/>
                <Input
                  label={enProfileAndSettingsLabel.firstName}
                  sx={{flex: 1}}
                  value={firstName}
                  onChange={(e) => onChangeInput(e, setFirstName)}
                  validationMessage={userErrorData.firstName}
                />
                <Input
                  label={enProfileAndSettingsLabel.lastName}
                  sx={{flex: 1}}
                  value={lastName}
                  onChange={(e) => onChangeInput(e, setLastName)}
                  validationMessage={userErrorData.lastName}
                />
                <Box>
                  <Input
                    label={enProfileAndSettingsLabel.email}
                    sx={{flex: 1}}
                    value={user?.email}
                    onChange={(e) => emptyFunction(e)}
                    disabled
                  />
                  <Typography
                    color="text.secondary"
                    variant="bodySmall">{enProfileAndSettingsLabel.emailInputSubtext}
                  </Typography>
                </Box>
                <Box>
                  <Stack direction="row" gap={0.5} alignSelf="stretch">
                    <Typography variant="h5">{enProfileAndSettingsLabel.phone}</Typography>
                    <Typography variant="body" color="text.secondary">({en.common.label.optional})</Typography>
                  </Stack>
                  <Stack direction="row" position="relative">
                    <InputAdornment
                      position="start"
                      sx={{
                        position: "absolute",
                        top: 20,
                        left: 30,
                        zIndex: 2,
                      }}
                    >
                      <SystemIcons.ChevronDown width={16} height={16}/>
                    </InputAdornment>
                    <MuiTelInput
                      flagSize="small"
                      InputProps={{
                        sx: {
                          padding: 0,
                        },
                      }}
                      sx={{
                        width: "100%",
                        ".MuiOutlinedInput-input": {
                          paddingTop: 1.2,
                          paddingBottom: 1.2,
                        },
                        ".MuiTelInput-IconButton": {
                          paddingRight: 2,
                        },
                        ".MuiInputBase-root": {
                          backgroundColor: theme.palette.background.paper,
                        }
                      }}
                      value={(phoneNumber ?? "") as string}
                      defaultCountry={phoneNumberCountryCode ?? "US"}
                      onChange={(e, info) => {
                        setPhoneNumber(e as unknown as number);
                        setPhoneNumberCountryCode(info.countryCode ?? "US");
                      }}
                    />
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Box>
        </FeatureWrapper>
        <LoadingButton
          id="logoutButton"
          variant="outlined"
          color="secondary"
          disabled={isLoading}
          loading={isLoading}
          startIcon={<SystemIcons.Logout stroke={theme.palette.error.main} height="18"/>}
          onClick={logout}
          sx={{alignSelf: "flex-start", borderColor: theme.palette.neutral.light}}
        >
          {enProfileAndSettingsLabel.logout}
        </LoadingButton>
      </Stack>
    </>
  )
});

export default Profile;
