import {Virtuoso} from "react-virtuoso";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {toastProps} from "../BaseProps";
import PageHeader from "./PageHeader";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import useCollectionData, {Data} from "hooks/useCollectionData";
import {db} from "../../firebase";
import {collection, doc, orderBy, where} from "firebase/firestore";
import React, {useEffect, useState} from "react";
import AssetItem from "./Overview/AssetItem";
import {Asset} from "types/Asset";
import {sortObjectsBy, submitForm} from "../utility";
import PageFooter from "./PageFooter";
import {Entity, Severity, ViewStatus, ActionType, DirectionalOrder} from "enums/index";
import ChatDrawer from "components/ChatDrawer";
import {useComponentToggler} from "hooks/index";
import AssetItemMenu from "components/AssetView/AssetItemMenu";
import {enAssetLabel} from "constants/index";
import {EditAssetDrawer} from "components/Drawers";
import ConfirmDialog from "components/ConfirmDialog";
import {statusSubmitHandler} from "../utility/statusSubmitHandler";

interface ContentProps {
  projectDocPath: string;
  access: AccessType;
  toastProps: toastProps;
  uid: string;
}

function OverviewContent(props: ContentProps) {
  const {access, projectDocPath, uid} = props;

  const navigate = useNavigate();
  const {orgId, projId} = useParams();
  const currentLocation = useLocation();

  const {data: assets, fetching, totalCount, loadMore} = useCollectionData<Data>({
    collectionRef: collection(db, projectDocPath, "assets"),
    constraints: [where("@allowedUsers", "array-contains", uid), orderBy("name", "asc")],
    returnedFields: ["@id", "name"],
  });

  const [displayedAssets, setDisplayedAssets] = useState<Data[]>([]);
  const [algoliaAssets, setAlgoliaAssets] = useState<Data[] | null>(null);
  const [sortOrder, setSortOrder] = useState(DirectionalOrder.asc);

  const [isCheckboxShown, setIsCheckboxShown] = useState<boolean>(false);
  const [checkedAssets, setCheckedAssets] = useState<Set<string>>(new Set());
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // drawers and dialogs related states
  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
  const [isEditOpen, {open: openEdit, close: closeEdit}] = useComponentToggler(false);
  const [isChatOpen, {open: openChat, close: closeChat}] = useComponentToggler(false);
  const [isMenuOpen, {open: openMenu, close: closeMenu}] = useComponentToggler(false);
  const [targetRef, setTargetRef] = useState<Element | null>(null);
  const [contextMenuPos, setContextMenuPos] = useState<{ top: number; left: number }>({top: 0, left: 0});

  // delete specific state
  const [isDialogOpen, {open: openDialog, close: closeDialog}] = useComponentToggler(false);
  const [status, setStatus] = useState<ViewStatus[]>([]);

  useEffect(() => {
    setIsLoading(fetching);

    if (algoliaAssets !== null) {
      if (JSON.stringify(algoliaAssets) === JSON.stringify(displayedAssets)) return;

      setDisplayedAssets(algoliaAssets);
      setIsLoading(false);
      return;
    }

    if (JSON.stringify(assets) === JSON.stringify(displayedAssets)) return;

    setDisplayedAssets(assets ?? []);
  }, [assets, algoliaAssets]);

  useEffect(() => {
    // if status is not equal to checked assets, return
    if (status.length < checkedAssets.size || status.length === 0) return;

    let message = enAssetLabel.deleteSuccess;
    let severity = Severity.Success;

    // check if with error
    const hasError = status.some(s => s === ViewStatus.Error);
    if (hasError) {
      message = enAssetLabel.deleteError;
      severity = Severity.Error;
    }

    // manual cleanup if from algolia
    if (algoliaAssets !== null) {
      const newAssets = displayedAssets.filter(asset => !checkedAssets.has(asset["@id"]!));
      setDisplayedAssets(newAssets);
      setAlgoliaAssets(newAssets);
    }

    showMessage(message, severity);
    closeDialog();
    setIsLoading(false);
    setCheckedAssets(new Set());
    setIsCheckboxShown(false);
    setStatus([]);
  }, [status]);

  // sort list
  useEffect(() => {
    let assetsCopy = [...displayedAssets];
    assetsCopy = sortObjectsBy(assetsCopy, "name", sortOrder);
    setDisplayedAssets(assetsCopy);
  }, [sortOrder]);

  function openChatDrawer(asset: Asset) {
    setSelectedAsset(asset);
    openChat();
  }

  function onAssetChecked(id: string, checked: boolean) {
    setCheckedAssets(prev => {
      const newSet = new Set(prev);
      if (checked) newSet.add(id);
      else newSet.delete(id);
      return newSet;
    });
  }

  function checkAllAssets() {
    if (checkedAssets.size === 0) {
      const allAssetIds = new Set(displayedAssets.map(asset => asset["@id"]!));
      setCheckedAssets(allAssetIds);
      return;
    }

    setCheckedAssets(new Set());
  }

  async function onLoadMore() {
    // if assets is null, it means that the assets are being fetched
    if (!assets) return;

    // if displayed assets is from algolia, return;
    if (algoliaAssets !== null) return;

    if (displayedAssets.length >= (totalCount ?? 0) && (totalCount ?? 0) > 0) return;

    setIsLoading(true);
    loadMore();
  }

  function onMenuOpen(e: Element | undefined, asset: Asset, anchorPosition?: { left: number, top: number }) {
    if (!e) return;

    setSelectedAsset(asset);
    setTargetRef(e);
    openMenu();
    setContextMenuPos(anchorPosition ?? {left: 0, top: 0});
  }

  function onCloseMenu() {
    setSelectedAsset(null);
    setTargetRef(null);
    closeMenu();
  }

  function deleteCheckedAssets() {
    if (checkedAssets.size === 0) return;

    setIsLoading(true);

    [...checkedAssets].map(id => {
      const assetRef = doc(db, projectDocPath, "assets", id);
      submitForm<Partial<Asset>>(assetRef, ActionType.Update,
        (status, data, isLastUpdate) => statusSubmitHandler({
          status,
          data,
          isLastUpdate,
          successCallback: () => setStatus(prev => [...prev, ViewStatus.Finished]),
          errorCallback: () => setStatus(prev => [...prev, ViewStatus.Error]),
        }),
        {isChecked: false, projectId: projId!});
    });
  }

  function showMessage(message: string, severity: Severity) {
    const {setToastMessage, setToastSeverity, setIsToastOpen} = props.toastProps!;

    setToastMessage(message);
    setToastSeverity(severity);
    setIsToastOpen(true);
  }

  const virtuosoContext = {
    orgId,
    projId,
    projectDocPath,
    uid,
    sortOrder,
    toastProps: props.toastProps!,
    canDelete: Boolean(access?.[PermissionOperationKey.Delete]),
    canView: Boolean(access?.[PermissionOperationKey.View]),
    canUpdate: Boolean(access?.[PermissionOperationKey.Update]),
    canUpdateStatus: Boolean(access?.[PermissionOperationKey.OverrideStatus]) || Boolean(access?.[PermissionOperationKey.ChangeStatus]),
    setAlgoliaAssets,
    setSortOrder,
    checkedAssets,
    isCheckboxShown,
    checkAllAssets,
    toggleCheckbox: () => setIsCheckboxShown(!isCheckboxShown),
    openDeleteDialog: openDialog,
    // footer props
    isLoading: isLoading && algoliaAssets === null,
    isEmpty: assets !== null && assets.length === 0,
    atBottom: displayedAssets.length >= (totalCount ?? 0) && (totalCount ?? 0) > 0,
    isSearchEmpty: algoliaAssets !== null && displayedAssets.length === 0,
  }

  return (
    <>
      <Virtuoso
        style={{height: "90vh",  maxWidth: "100hw", paddingLeft: "10%", overflowX: "hidden"}}
        data={displayedAssets}
        components={{
          Header: VirtuosoHeader,
          Footer: VirtuosoFooter,
        }}
        endReached={onLoadMore}
        context={virtuosoContext}
        overscan={5}
        atBottomThreshold={100}
        itemContent={(_, itemData) => {
          const assetDocPath = `${projectDocPath}/assets/${itemData["@id"]!}`;
          return (
            <div key={`project-asset-item-${itemData["@id"]!}`} style={{paddingRight: '20%'}}>
              <AssetItem
                uid={uid}
                assetId={itemData["@id"]!}
                assetDocPath={assetDocPath}
                toastProps={props.toastProps!}
                openChatDrawer={openChatDrawer}
                isChecked={checkedAssets.has(itemData["@id"]!)}
                isCheckboxShown={isCheckboxShown}
                onAssetCheck={onAssetChecked}
                onMenuOpen={onMenuOpen}
              />
            </div>
          )
        }}
      />
      {selectedAsset && (
        <ChatDrawer
          uid={uid}
          isOpen={isChatOpen}
          entity={Entity.Asset}
          pathPair={[selectedAsset.projectName!, selectedAsset.name]}
          onClose={() => {
            setSelectedAsset(null);
            closeChat();
          }}
          colRef={collection(db, projectDocPath, "assets", selectedAsset["@id"]!, "chatMessages")}
          navigateToOverview={() => navigate(currentLocation.pathname)}
          toastProps={props.toastProps!}
        />
      )}
      {isMenuOpen && selectedAsset && (
        <AssetItemMenu
          isOpen={isMenuOpen}
          anchorEl={targetRef}
          closeMenu={onCloseMenu}
          openEditAsset={() => {
            closeMenu();
            openEdit();
          }}
          access={access}
          anchorPosition={contextMenuPos}
          deleteAsset={() => {
            closeMenu();
            openDialog();
            setCheckedAssets(new Set([selectedAsset["@id"]!]));
          }}
        />
      )}
      {selectedAsset && isEditOpen && (
        <EditAssetDrawer
          uid={uid}
          asset={selectedAsset}
          isDrawerShown={isEditOpen}
          onDrawerClose={() => {
            setSelectedAsset(null);
            closeEdit();
          }}
          toastProps={props.toastProps!}
          collectionReference={collection(db, projectDocPath, "assets")}
        />
      )}
      <ConfirmDialog
        isOpen={isDialogOpen}
        title={enAssetLabel.deleteConfirmationTitle(checkedAssets.size)}
        text={enAssetLabel.deleteConfirmationText}
        handleClose={() => {
          if (!!selectedAsset) {
            setCheckedAssets(new Set());
            onCloseMenu();
          }
          closeDialog();
        }}
        handleConfirm={deleteCheckedAssets}
      />
    </>
  )
}

const VirtuosoHeader =  ({context} : any) => {
  return <PageHeader {...context} />
}

const VirtuosoFooter = ({context} : any) => {
  return <PageFooter {...context} />
}

export default OverviewContent;