import React from "react";
import {BaseProps} from "screens/BaseProps";
import {PilledNavigationBar} from "components";
import {PillItemProps} from "components/PilledNavBar/PillItem";
import {SidebarIcons} from "assets/icons/menu/menu.index";
import {SystemIcons} from "assets/icons/system/system.index";
import {useNavigate, useParams} from "react-router-dom";
import theme from "theme/theme";
import Icons from "assets/icons";
import {Task} from "types/Task";
import {useDocument} from "hooks/index";
import {taskPath} from "../utility";

function Header(props: BaseProps) {
  const {orgId, projId, assetId, taskId, milestoneId} = useParams();

  const taskDocRef = taskPath(orgId!, projId!, assetId!, milestoneId!, taskId!);

  const [task] = useDocument<Task>(taskDocRef);
  const {orgName, projectName, assetName, name} = task ?? {};

  const navigate = useNavigate();
  const projectsPath = `/${task?.orgId}/projects/${task?.projectId}/${assetId ? `assets/${task?.assetId}` : `project-level-tasks`}`;
  let pills: PillItemProps[] = [
    {
      id: "header-org-name",
      label: "...",
      icon: <Icons.SidebarIcons.Folder fill={theme.palette.neutral.dark} width={16} height={16}/>,
      onClickFcn: () => navigate(projectsPath),
      hoverLabel: `${orgName} / ${projectName}` // hoverLabel: `${orgName} / ${projectName}`,
    },
  ];

  if (assetId !== undefined) {
    pills = [...pills, {
      id: "asset-name",
      label: assetName ?? "AssetName",
      icon: <SidebarIcons.Assets width={16} height={16}/>,
      onClickFcn: () => navigate(`/${orgId}/projects/${projId}/assets/${assetId}`)
    }];
  }

  const tasksPath = `${projectsPath}/milestones/${task?.milestoneId}/tasks/${task?.["@id"]}`;
  pills = [...pills, {
    id: "task-name",
    label: name ?? "TaskName",
    icon: <SystemIcons.Tasks width={16} height={16} stroke={theme.palette.neutral.dark}/>,
    onClickFcn: () => navigate(tasksPath)
  }, {
    id: "time-log",
    label: "Time Log",
    icon: <SystemIcons.DownloadTimeData width={16} height={16} stroke={theme.palette.neutral.dark}/>,
    onClickFcn: () => {
    }
  }];

  return (
    <PilledNavigationBar {...props} pills={pills}>
    </PilledNavigationBar>
  )
}

export default Header;