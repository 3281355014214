import {Box} from "@mui/material";
import theme from "theme/theme";
import React from "react";
import {SystemIcons} from "assets/icons/system/system.index";
import RejectedOverlay from "./RejectedOverlay";

interface RejectedFilePreviewProps {
  id?: string;
  iconSize: number;
  onClick: () => void;
  title?: string;
}

function RejectedFilePreview(props: RejectedFilePreviewProps) {
  const {id = "n", iconSize, onClick, title = ""} = props;
  return (
    <Box
      title={title}
      id={`rejected-file-preview-${id}`}
      style={{
        display: "grid",
        placeItems: "center",
        height: 64,
        backgroundColor: theme.palette.background.swiftDefault,
        borderRadius: 2,
        maxWidth: 64
      }}
      className="file-preview-item"
      position="relative"
      width="inherit"
      height="inherit"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
    >
      <SystemIcons.FilesFilled fill="black" width={iconSize} height={iconSize}/>
      <RejectedOverlay/>
    </Box>
  )
}

export default RejectedFilePreview;