import theme from "theme/theme";
import React from "react";

export const linkify = (text: string) => {
  const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

  // Find all matches and their positions
  const urls = [...text.matchAll(urlPattern)];
  const parts = [];
  let lastIndex = 0;

  urls.forEach(match => {
    const url = match[0];
    const index = match.index ?? 0;

    // Add text before URL
    if (index > lastIndex) {
      parts.push(text.substring(lastIndex, index));
    }

    // Add URL as link
    parts.push(
      <a href={url} key={url + index} target="_blank" rel="noopener noreferrer" style={{color: theme.palette.primary.light}}>{url}</a>
    );

    lastIndex = index + url.length;
  });

  // Add any remaining text after the last URL
  if (lastIndex < text.length) {
    parts.push(text.substring(lastIndex));
  }

  return parts;
};