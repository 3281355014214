import {Entity, InvitationStatus} from "enums/index";
import {useCollection} from "hooks/index";
import {Invite} from "types/index";
import {InProgress} from "components/index";
import {CollectionReference, orderBy} from "firebase/firestore";
import {BaseProps} from "screens/BaseProps";
import React, {useEffect, useState} from "react";
import {allStatus, enTeamsAndPeopleLabel} from "constants/index";
import {Box, Stack} from "@mui/material";
import DisplayEmpty from "components/InvitesView/DisplayEmpty";
import SmartSearchInput from "components/inputs/SmartSearchInput";
import {algoliaOrganizationsInvitesPath, algoliaUserInvitesPath} from "screens/utility/algoliaColPath";
import InvitesList from "./InvitesList"
import {useParams} from "react-router-dom";
import theme from "theme/theme";
import InvitationSortStatusMenu from "components/InvitesView/InvitationSortStatusMenu";

interface InvitesViewProps extends BaseProps {
  invitationsReference: CollectionReference;
  entity: Entity.TeamsAndPeople | Entity.Member | Entity.ActivityFeed | Entity.Organization;
  withSearch?: boolean;
  fromHome?: boolean;
}

function InvitesView(props: InvitesViewProps) {
  const {invitationsReference, entity, toastProps, fromHome, withSearch = false} = props;
  const {orgId} = useParams();

  let constraints = fromHome ? [orderBy("pinned", "desc")] : [];
  constraints.push(orderBy("inviteDate", "desc"));

  const [invitations, , setInvitationsColRef] = useCollection<Invite>(null, invitationsReference, constraints);
  const [algoliaInvitations, setAlgoliaInvitations] = useState<Invite[] | null>(null);
  const [statusFilter, setStatusFilter] = useState<InvitationStatus | string>(allStatus);

  useEffect(() => {
    if (invitations !== null) return;
    setInvitationsColRef(invitationsReference);
  }, [invitationsReference.path]);

  function updateAlgoliaResults(resultInvites: Invite[] | null) {
    if (resultInvites === null || resultInvites.length === 0) {
      setAlgoliaInvitations(resultInvites);
      return;
    }

    const validInvites = resultInvites.filter(algoliaInvite => invitations?.some(invite => invite["@id"]! === algoliaInvite["@id"]!))
    setAlgoliaInvitations(validInvites);
  }

  if (invitations === null) return <InProgress/>;

  if (invitations.length === 0) return <Box
    sx={{
      position: "relative",
      top: theme.spacing(28)
    }}
  >
    <DisplayEmpty entity={entity}/>
  </Box>;

  return (
    <Stack gap={1} flex={1}>
      <Stack
        direction={{xs: "column", sm: "column", md: "column", lg: "row", xl: "row"}}
        justifyContent={{xs: "left", sm: "left", md: "left", lg: "space-between", xl: "space-between"}}
      >
        {withSearch && (
          <SmartSearchInput<Invite>
            placeholder={enTeamsAndPeopleLabel.searchInvites}
            colPath={entity === Entity.Organization ? algoliaUserInvitesPath() : algoliaOrganizationsInvitesPath(orgId!)}
            resultCallback={updateAlgoliaResults}
            additionalFilters={statusFilter !== 'All' ? [`invitationStatus: ${statusFilter}`] : undefined}
            byPassUsersAndTeamsFilter={entity === Entity.Organization}
          />
        )}
        <Stack
          direction={{xs: "column", sm: "column", md: "row", lg: "row", xl: "row"}}
          gap={1}
          mt={{xs: 1, sm: 1, md: 1, lg: 1, xl: "unset"}}
        >
          <InvitationSortStatusMenu currentValue={statusFilter} setter={setStatusFilter}/>
        </Stack>
      </Stack>
      <InvitesList
        uid={props.uid}
        statusFilter={statusFilter}
        invites={algoliaInvitations ?? invitations ?? []}
        toastProps={toastProps!}
        fromAlgolia={algoliaInvitations !== null}
        entity={entity}
      />
    </Stack>
  )
}

export default InvitesView;
