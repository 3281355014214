import {CounterFields} from "enums/counterFields";
import {useRTDBDocField} from "hooks/index";
import {useEffect, useState} from "react";
import {toProgressCount} from "../screens/utility/toProgressCount";

interface HookProps {
  path: string;
  counterField: CounterFields | null;
  processLabel?: string;
  processingLabel?: string;
}

interface HookReturns {
  progress: number;
  isProcessComplete: boolean;
  isProcessing: boolean;
  label: string;
}

function useProgressCount(props: HookProps): HookReturns {
  const {
    path,
    counterField,
    processLabel = "",
    processingLabel = "",
  } = props;

  const progressCount = useRTDBDocField<number>(path, counterField ?? "") ?? 0;
  const [progress, setProgress] = useState<number>(0);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isProcessComplete, setIsProcessComplete] = useState<boolean>(false);
  const [label, setLabel] = useState<string>(processLabel);

  useEffect(() => {
    if (progress > 0) {
      setLabel(processingLabel ? `${processingLabel} ${progress}%`: `${progress}%`);
      return;
    }

    setLabel(processLabel);
  }, [progress, processLabel, processingLabel]);

  useEffect(() => {
    if (!progressCount) {
      setIsProcessComplete(false);
      setProgress(0);
      return;
    }

    const currentCount = Math.floor(progressCount || 0);
    if (currentCount > 0 && currentCount < 100) {
      setIsProcessing(true);
    }

    if (currentCount >= 100) {
      setProgress(0);
      if (isProcessing) {
        setIsProcessing(false);
        setIsProcessComplete(true);
      }
      return;
    }

    setIsProcessComplete(false);
    setProgress(toProgressCount(currentCount));
  }, [progressCount]);

  return {progress, isProcessComplete, label, isProcessing};
}

export default useProgressCount;