import {DocumentData, DocumentReference, getDoc} from "firebase/firestore";
import {ActionType, ViewStatus} from "enums/index";
import {FormActionType} from "emberflow-web-client/lib/types";
import {submitForm as submitFormClient} from "emberflow-web-client/lib";
import getEnvKey from "./getEnvKey";
import {submitTimeout, submitTimeoutExport} from "constants/envKeys";
import {deepEqual} from "./deepEqual";

const SUBMIT_TIMEOUT_MS = getEnvKey(submitTimeout) || "1000";
const SUBMIT_TIMEOUT_EXPORT = getEnvKey(submitTimeoutExport) || "1000";

async function submitForm<Type>(
  formRef: DocumentReference,
  actionType: ActionType,
  statusHandler: (...params: any[]) => void,
  formData?: Type,
  document?: Type | null,
) {
  if (actionType !== ActionType.Delete && formData === undefined) {
    throw new Error(`Form data is needed on ${actionType} action`);
  }

  // check if submit request is from export
  const fromExport = formRef.path.split('/').includes("exports");
  // define timeout if fromExport and convert to number
  const timeout = (fromExport ? SUBMIT_TIMEOUT_EXPORT : SUBMIT_TIMEOUT_MS) as unknown as number;

  // count number of ms this request took
  const timeoutPromise = new Promise<{"@status": ViewStatus, "@messages": string}>((resolve) => {
    setTimeout(async () => {
      const docExists = await checkIfDocExists(formRef);

      // if action requested is deletion
      if (actionType === ActionType.Delete) {
        // if doc requested still persists after timeout, show timeout error
        if (docExists) {
          return resolve({"@status": ViewStatus.Error, "@messages": "Timeout error"});
        }

        // if doc requested is deleted after timeout, show success
        return resolve({"@status": ViewStatus.Finished, "@messages": "Request finished"});
      }

      // for update/add, if doc requested still persists after timeout, show success
      if (await checkIfDocExists(formRef)) {
        return resolve({"@status": ViewStatus.Finished, "@messages": "Request finished"});
      }

      return resolve({"@status": ViewStatus.Error, "@messages": "Timeout error"});
    }, timeout);
  });

  const newFormData = actionType === ActionType.Create ? {...(formData ?? {})} : await getModifiedDocData(formData ?? {}, document ?? {});

  const formDataPromise = submitFormClient({
    ...newFormData,
    "@docPath": formRef.path,
    "@actionType": actionType.toLowerCase() as FormActionType,
  });

  const formDataResp = await Promise.race([formDataPromise, timeoutPromise]);

  statusHandler(formDataResp["@status"], formDataResp, true);
}

async function checkIfDocExists(formRef: DocumentReference) {
  const submittedDoc = await getDoc(formRef);
  return submittedDoc.exists();
}

async function getModifiedDocData(form: DocumentData, document: DocumentData) {
  const modifiedDocData = {};
  for (const key in form) {
    if (key.startsWith("@"))
      continue;
    if (!(key in document) || !deepEqual(document[key], form[key])) {
      // @ts-ignore
      modifiedDocData[key] = form[key];
    }
  }
  return modifiedDocData;
}

export default submitForm;
