import {Box, Stack, Typography} from "@mui/material";
import SystemIndex from "assets/icons/system/system.index";
import theme from "theme/theme";
import {en} from "language/en";
import {useDocument} from "hooks/index";
import {taskPath} from "../../utility";
import {useParams} from "react-router-dom";
import {Task} from "types/Task";
import convertMilliseconds from "screens/utility/convertMilliseconds";

function TotalLoggedTime() {
  const {orgId, projId, assetId, milestoneId, taskId} = useParams()

  const taskDocRef = taskPath(orgId!, projId!, assetId, milestoneId!, taskId!);
  const [task] = useDocument<Task>(taskDocRef)

  const {hours, minutes, seconds} = convertMilliseconds(task?.totalLoggedTimeInMs ?? 0)

  return <Stack direction="row" gap={1}>

    <Box position="relative" top={7}>
      <SystemIndex.SystemIcons.DownloadTimeData height={24} stroke={theme.palette.neutral.dark}/>
    </Box>

    <Stack>
      <Typography variant="h2">
        {hours}:{minutes}
        <Typography variant="h2" color={theme.palette.text.secondary} component="span">:{seconds}</Typography>
      </Typography>
      <Typography variant="bodySmall">{en.screen.TimeLog.label.totalLoggedTime}</Typography>
    </Stack>

  </Stack>
}

export default TotalLoggedTime;