import {emptyFunction, enFileRequirementLabel} from "constants/index";
import React, {useEffect, useState} from "react";
import {Box, LinearProgress, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {LongText, PreviewItem} from "components/index";
import {styled} from "@mui/system";
import {FileStatus} from "enums/fileStatus";
import {formatDateTimeFirestoreDate} from "../../../utility";
import {displayFileName} from "./utils";
import {ProcessingFile} from "hooks/useFileUpload/useFileUpload";
import {Previews} from "enums/supportedPreviews";

interface UploadingFileCardProps {
  processingFile: ProcessingFile;
}

export default function UploadingFileCard(props: UploadingFileCardProps) {
  const {processingFile} = props;

  const {shortDate, simpleTime} = formatDateTimeFirestoreDate(processingFile.timeCreated);

  const [uploadProgress, setUploadProgress] = useState<number>(0);

  // Update upload progress
  useEffect(() => {
    const {length = 1,lengthUploaded} = processingFile;

    const progress = Math.ceil((lengthUploaded / length) * 100);
    setUploadProgress(lengthUploaded >= length ? 100 : progress);
  }, [processingFile.lengthUploaded, processingFile.length]);

  function getDynamicPreview() {
    if ((/^image\//).test(processingFile.contentType)) return <></>;
    return (
      <PreviewItem
        filePreview={{
          contentType: processingFile.contentType as Previews,
          fileStatus: FileStatus.Uploading,
          previewValue: processingFile["#previewValue"],
          id: processingFile.id,
        }}
        onClick={emptyFunction}
      />
    );
  }

  return (
    <Box
      maxWidth="296px"
      minHeight="323px"
      position="relative"
      sx={{
        borderRadius: "4px",
        border: `1px solid ${theme.palette.neutral.light}`,
        overflow: "hidden"
      }}
      className="file-list-item"
      id={`file-list-item-${processingFile.id}`}
    >
      <Stack spacing={0}>
        <Box
          sx={{padding: 1, backgroundColor: theme.palette.background.paper}}
        >
          <StatusWithIcon
            padding="4px 1rem"
            direction="row"
            justifyContent="center"
            gap={1}
            alignItems="center"
            margin="0 auto"
          >
            <Typography
              variant="h5"
              textAlign="center"
              color={theme.palette.common.black}
              id={`file-list-item-status-${processingFile.id}`}
            >
              {enFileRequirementLabel.uploadProgress(uploadProgress)}
            </Typography>
          </StatusWithIcon>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          minWidth="296px"
          minHeight="200px"
          position="inherit"
          sx={(theme) => ({
            borderRadius: "4px 4px 0 0",
            backgroundImage: `url(${processingFile["#previewValue"]})`,
            backgroundColor: theme.palette.background.swiftDefault,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          })}
        >
          <LinearProgress sx={{borderRadius: 1}} variant="determinate" value={uploadProgress}/>
          <Box flex={1}></Box>
          {getDynamicPreview()}
          <Box flex={1}></Box>
        </Box>
        <Box
          sx={{padding: "4px 1rem", zIndex: 1}}
        >
          <LongText
            variant="body"
            textColor={theme.palette.neutral.dark}
            maxLines={1}
            id={`file-list-item-name-${processingFile.id}`}
            tooltipText={`${processingFile.file.name} - ${shortDate} ${simpleTime}`}
          >
            {displayFileName(processingFile.file.name)} - {shortDate} {simpleTime}
          </LongText>
        </Box>

        <Box flex={1}></Box>
      </Stack>
    </Box>
  );
}

const StatusWithIcon = styled(Stack)({
  backgroundColor: "rgba(255,255,255,0.8)",
  borderRadius: "4px",
})
