/**
 * extracts the month, date and year of the given date
 * @param {Date} date
 * @returns date in string format: M/D/YYYY
 */
export function extractDate(date: Date) {
  return date.toLocaleDateString();
}

export function getStartOfDay(date: Date){
  let day = new Date(date);
  day.setHours(0, 0, 0, 0);

  return day;
}

export function getEndOfDay(date: Date) {
  let day = new Date(date);
  day.setHours(23, 59, 59, 999);

  return day;
}