export const profileAndSettings = {
  title: "Profile and Settings",
  label: {
    passwordChangeWarning: <>Warning: Changing your password will log you out</>,
    passwordTooShort: "Password must be at least 6 characters",
    passwordUpdated: (logoutInX: number) => `Password updated. You will be logged out in ${logoutInX} seconds.`,
    oldPasswordIncorrect: "Old Password Incorrect",
    oldPassword: "Old Password",
    newPassword: "New Password",
    confirmNewPassword: "Confirm New Password",
    passwordsDoNotMatch: "Passwords do not match",
    updatePassword: "Update Password",
    profile: "Profile",
    settings: "Settings",
    logout: "Log Out",
    save: "Save",
    emailInputSubtext: "Registration email cannot be changed.",
    saveSuccessToast: "Profile and Settings saved successfully.",
    securityErrorToast: "Encountered security error",
    generalErrorToast: "Something went wrong",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    notifications: "Notifications",
    sendEmailNotifications: "Send notifications to my email",
    sendDeviceNotifications: "Send notifications to my device",
    soundNotifications: "Sound notifications",
    enableSoundNotifications: "Enable sound notifications",
    language: "Language",
    english: "English",
    englishSubText: "(currently we only support English)",
    privacy: "Privacy",
    changePassword: "Change Password",
    changePasswordInstructions: "Click Reset Password below and we’ll send instructions to reset the password",
    resetPassword: "Reset Password",
    about: "About",
    version: "Version",
    dataPolicy: "Data Policy",
    dataPolicyDescription: "            When you use our services, you’re trusting us with your information. We understand this is a big responsibility and work hard to protect your information and put you in control",
    understandingTypesOfInformation: "We want you to understand the types of information we collect as you use our services",
    understandingTypesOfInformationParagraphs: [
      "We collect information to provide better services to all our users — from figuring out basic stuff like which language you speak, to more complex things like which ads you’ll find most useful, the people who matter most to you online, or which YouTube videos you might like. The information Google collects, and how that information is used, depends on how you use our services and how you manage your privacy controls",
      "When you’re not signed in to a Swift Account, we store the information we collect with unique identifiers tied to the browser, application, or device you’re using. This helps us do things like maintain your language preferences across browsing sessions.",
      "When you’re signed in, we also collect information that we store with your Swift Account, which we treat as personal information"
    ],
    yourActivity: "Your Activity",
    activityList: ["Terms you search for", "Videos you watch", "Views and interactions with content and ads", "Voice and audio information when you use audio features", "Purchase activity"],
    termsOfService: "Terms of Service",
    termsOfServiceDescription: "Description for terms of service",
    emailSubText: "Registered email cannot be changed",
    resetPasswordInstructions: (email: string) => `We’ve sent password reset instructions to ${email}.`,
    followTheLinkInstructions: "Follow the link and change your password. Then go back to the app and enter your new password on the Login page.",
    goToLogin: "Go to Login",
    changeAvatar: "Change Avatar",
    deleteAvatar: "Delete Avatar",
    phone: "Phone",
    profileUploadSuccess: "Profile photo updated successfully",
    profileDeleteSuccess: "Profile photo deleted successfully",
    cancel: "Cancel"
  }
}
