import {Asset} from "types/index";
import {assetsPath, availableAssetsPath, getDocPath, projectsPath} from "screens/utility";
import {useParams} from "react-router-dom";
import React, {useState} from "react";
import {Stack} from "@mui/material";
import {AssetListType} from "enums/assetListType";
import AssetSaveButton from "./AssetsSaveButton";
import AssetsListView from "./AssetsListView";
import {useRTDBDocField} from "hooks/index";
import {BaseProps} from "screens/BaseProps";
import SmartSearchInput from "components/inputs/SmartSearchInput";
import {algoliaAssetsPath, algoliaAvailableAssetsPath} from "screens/utility/algoliaColPath";
import {AlgoliaObject} from "types/AlgoliaObject";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {CounterFields} from "enums/counterFields";

const PAGE_ITEM_COUNT = 10;
type AlgoliaAssetObject = Asset & AlgoliaObject;

interface AssetViewsProps extends BaseProps {
  access: AccessType | null;
  allProjectAssets: Asset[];
  allAvailableAssets: Asset[];
}

function AssetViews(props: AssetViewsProps) {
  const {toastProps, access, allAvailableAssets, allProjectAssets} = props;

  const {projId, orgId} = useParams();

  const assetsCount = useRTDBDocField<number>(getDocPath(props.uid, projectsPath(orgId!).path, projId!), CounterFields.AssetsCount) ?? 0;
  const availableAssetsCount = useRTDBDocField<number>(getDocPath(props.uid, projectsPath(orgId!).path, projId!), CounterFields.AvailableAssetsCount) ?? 0;

  const [projectCurrentPage, setProjectCurrentPage] = useState<number>(1);
  const [availableCurrentPage, setAvailableCurrentPage] = useState<number>(1);

  const [modifiedAssets, setModifiedAssets] = useState<Asset[]>([]);

  const [algoliaAssetsCollection, setAlgoliaAssetsCollection] = useState<AlgoliaAssetObject[] | null>(null)

  const [isSaving, setIsSaving] = useState<boolean>(false);

  function getAssetsToView(assetListType: AssetListType) {
    if (!algoliaAssetsCollection) {
      let list = assetListType === AssetListType.ProjectAsset ? [...allProjectAssets] : [...allAvailableAssets];
      let start = ((assetListType === AssetListType.ProjectAsset ? projectCurrentPage : availableCurrentPage) - 1) * PAGE_ITEM_COUNT;
      let end = start + PAGE_ITEM_COUNT;
      return list.slice(start, end);
    }

    if (assetListType === AssetListType.ProjectAsset)
      return algoliaAssetsCollection.filter((obj) => obj.colPath === algoliaAssetsPath(orgId!, projId!));

    if (assetListType === AssetListType.AvailableAsset)
      return algoliaAssetsCollection.filter((obj) => obj.colPath === algoliaAvailableAssetsPath(orgId!, projId!));

    return [];
  }

  function handleCheckboxChange(id: string, checked: boolean) {
    if (modifiedAssets.some((asset) => asset["@id"]! === id)) {
      setModifiedAssets(modifiedAssets.filter((asset) => asset["@id"]! !== id));
      return;
    }

    // meaning it was from the available assets list
    if (checked) {
      const collectionAsset = (allAvailableAssets ?? []).find((asset) => asset["@id"]! === id)!;
      if (!collectionAsset) return;

      setModifiedAssets([...modifiedAssets, {...collectionAsset, isChecked: checked}]);
      return;
    }

    // find the asset in the allAssets collection
    const collectionAsset = (allProjectAssets ?? []).find((asset) => asset["@id"]! === id)!;
    if (!collectionAsset) return;

    setModifiedAssets([...modifiedAssets, {...collectionAsset, isChecked: checked}]);
  }

  function setCurrentPage(assetListType: AssetListType, page: number) {
    if (assetListType === AssetListType.AvailableAsset) {
      setAvailableCurrentPage(page);
    } else {
      setProjectCurrentPage(page);
    }
  }

  return <Stack spacing={2} height="100%" direction="column">
    <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
      <SmartSearchInput
        sx={{pt: 4}}
        resultCallback={setAlgoliaAssetsCollection}
        colPath={[algoliaAssetsPath(orgId!, projId!), algoliaAvailableAssetsPath(orgId!, projId!)]}
      />
      <AssetSaveButton
        isSaving={isSaving}
        modifiedAssets={modifiedAssets}
        modifiedAssetsSetter={setModifiedAssets}
        savingStateSetter={setIsSaving}
        toastProps={toastProps}
        uid={props.uid}
        disabled={!modifiedAssets.length || !Boolean(access?.[PermissionOperationKey.Update])}
      />
    </Stack>
    <AssetsListView
      disabled={!Boolean(access?.[PermissionOperationKey.Update])}
      assets={getAssetsToView(AssetListType.ProjectAsset) ?? []}
      assetCount={assetsCount}
      modifiedAssets={modifiedAssets}
      setCurrentPage={setCurrentPage}
      handleCheckboxChange={handleCheckboxChange}
      assetListType={AssetListType.ProjectAsset}
      assetTypeIdx="projectAssets"
      parentCollectionPath={assetsPath(orgId!, projId!).path}
      listFromAlgolia={algoliaAssetsCollection !== null}
      toastProps={toastProps!}
      uid={props.uid}
    />
    <AssetsListView
      disabled={!Boolean(access?.[PermissionOperationKey.Update])}
      assets={getAssetsToView(AssetListType.AvailableAsset) ?? []}
      assetCount={availableAssetsCount}
      modifiedAssets={modifiedAssets}
      setCurrentPage={setCurrentPage}
      handleCheckboxChange={handleCheckboxChange}
      assetListType={AssetListType.AvailableAsset}
      assetTypeIdx="availableAssets"
      parentCollectionPath={availableAssetsPath(orgId!, projId!).path}
      listFromAlgolia={algoliaAssetsCollection !== null}
      toastProps={toastProps!}
      uid={props.uid}
    />
  </Stack>
}

export default AssetViews