export function formatDateTime(datetime: Date) {
  const date = new Date(datetime).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const time = new Date(new Date()).toLocaleString("en-us", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return {date, time};
}