import {CircularProgress, Box, SxProps} from "@mui/material";
import Theme from "theme/theme";

interface InProgressProps {
  size?: number
  sx?: SxProps<typeof Theme>
}

function InProgress(props: InProgressProps) {
  const {size, sx} = props;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        ...sx,
      }}
    >
      <CircularProgress size={size}/>
    </Box>
  );
}

export default InProgress;
