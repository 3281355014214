import {useLocation} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {SidebarProps} from "./SidebarProps";
import SideBar from "components/SidebarMenu/SidebarLinks/SideBar";
import {SelectedOrgContext} from "screens/SelectedOrgContextProvider";

function SidebarLinks(props: SidebarProps) {
  const {toggleSidebar, uid} = props;
  const currentLocation = useLocation();

  const selectedOrgContext = useContext(SelectedOrgContext);
  const {selectedOrg} = selectedOrgContext!;
  const selectedOrgId = selectedOrg?.id ?? "defaultOrg";
  const [routeOrgId, setRouteOrgId] = useState<string>(selectedOrgId);

  useEffect(() => {
    // update route org id if it is not the same as selected org id
    if (!!selectedOrg)
      return setRouteOrgId(selectedOrg.id);

    if (!currentLocation.pathname || selectedOrgId === routeOrgId) return;
    if (selectedOrgId !== "defaultOrg")
      return setRouteOrgId(selectedOrgId);

    const locationOrgId = currentLocation.pathname.split("/")[1];
    setRouteOrgId(locationOrgId);
  }, [currentLocation, selectedOrgId, selectedOrg]);

  return (
    <SideBar
      uid={uid}
      orgRole={selectedOrg?.accessRole}
      routeOrgId={routeOrgId}
      toggleSidebar={toggleSidebar}
    />
  );

}

export default SidebarLinks;