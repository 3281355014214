import {Box} from "@mui/material";
import SwiftttLogo from "../assets/icons/SwiftttLogo.svg";

function Loader() {
  return (
    <Box
      sx={(theme) => ({
        width: "100vw",
        height: "100vh",
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 2,
      })}
    >
        <Box width={{xs: "40%", sm: "35%", md: "30%", lg: "25%"}} height={{xs: "40%", sm: "35%", md: "30%", lg: "25%"}}>
            <img src={SwiftttLogo} alt="Swifttt" height={"100%"} width={"100%"}/>
        </Box>
    </Box>
  );
}

export default Loader;