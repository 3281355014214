export enum DirectionalOrder {
  asc = "asc",
  desc = "desc",
}

export enum MiscOrder {
  lastVisited = "lastVisited",
  timeCreated = "timeCreated",
}

export type AllOrder = DirectionalOrder | MiscOrder
