import TeamItem from "./TeamItem";
import {Checkbox, Divider, Stack, Typography} from "@mui/material";
import {AccessListUser} from "types/index";
import theme from "theme/theme";
import {emptyFunction, enCommonLabel} from "constants/index";
import React, {useEffect, useState} from "react";
import {InProgress} from "components/index";
import {SelectedItem} from "components/Drawers/AddTeamOrPeopleDrawer";
import {AccessRole} from "enums/index";
import AccessRoleMenu from "components/Drawers/ManageAccessDrawer/Objects/AccessRoleMenu";
import {AccessListUserWithPermission} from "components/Drawers/AddTeamOrPeopleDrawer/Objects/PeopleItem";
import {AccessType} from "types/Permission";
import {sortObjectsBy} from "../../../../screens/utility";

interface TeamListProps {
  teams: AccessListUser[] | null;
  setSelectedTeams?: (newSelected: SelectedItem[]) => void;
  access: AccessType;
}

function TeamsList(props: TeamListProps) {
  const {teams, access} = props;
  const {setSelectedTeams: setSelectedTeamsParent = emptyFunction} = props;

  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [selectedTeams, setSelectedTeams] = useState<SelectedItem[]>([]);

  const [localTeams, setLocalTeams] = useState<AccessListUserWithPermission[] | null>(teams);
  const [updateFromBulk, setUpdateFromBulk] = useState<boolean>(false);

  useEffect(() => {
    // @ts-ignore
    const teamsCopy = (teams ?? []).map(team => ({...team, canAdd: access[`Manage${team.role?.replace(" ", "")}`], canDelete: access[`Manage${team.role?.replace(" ", "")}`],}));
    setLocalTeams(sortObjectsBy(teamsCopy, "name"));
  }, [JSON.stringify(teams), access]);

  useEffect(() => {
    if (localTeams === null) return;

    setIsAllSelected(selectedTeams.length === localTeams.length);
    setSelectedTeamsParent(selectedTeams);
  }, [JSON.stringify(selectedTeams), localTeams]);

  function selectAllTeams() {
    if (localTeams === null) return;

    if (isAllSelected) {
      // delete everything
      setSelectedTeams([]);
      return;
    }

    const newSelectedTeams = localTeams.map(team => {
      let obj: SelectedItem = {"@id": team["@id"], role: AccessRole.User};
      return obj;
    });

    setSelectedTeams(newSelectedTeams);
  }

  function updateSelectedList(id: string, checked: boolean, role?: AccessRole) {
    let selectedCopy = selectedTeams.map(team => ({...team}));

    selectedCopy = selectedCopy.filter(team => team["@id"] !== id);
    if (checked) {
      selectedCopy = [...selectedCopy, {"@id": id, role}];
    }

    setSelectedTeams(selectedCopy);
  }

  function chooseRoleBulk(selected: AccessRole) {
    const selectedCopy = selectedTeams.map(team => ({...team, role: selected}));
    const teamsCopy = (localTeams ?? []).map(team => {
      return {...team, role: selected};
    });

    setLocalTeams(teamsCopy);
    setSelectedTeams(selectedCopy);
    setUpdateFromBulk(true);

    return selectedCopy;
  }

  if (localTeams === null) return <InProgress/>;
  if (localTeams.length === 0) return <></>;

  return (
    <Stack direction="column" id="teams-list">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center">
          <Checkbox
            id={"select-all-teams-checkbox"}
            checked={isAllSelected}
            onChange={selectAllTeams}
          />
          <Typography
            variant="h5"
            id={"teams-list-label"}
            color={theme.palette.secondary.main}>
            {selectedTeams.length === 0 ? `${enCommonLabel.teams} ` : `${selectedTeams.length}  ${enCommonLabel.selected}`}
            <Typography
              variant="body1"
              sx={{display: "inline", color: theme.palette.text.secondary}}>
              {selectedTeams.length === 0 ? localTeams.length : null}
            </Typography>
          </Typography>
        </Stack>
        {selectedTeams.length > 1 &&
          <AccessRoleMenu
            onUpdateAccessRole={chooseRoleBulk}
            displayChevron={false}
            updateLabelOnSelect={false}
            selectSx={{width: "100px", color: "primary"}}
            textVariant={"h5"}
            access={access}
          />}
      </Stack>
      {localTeams.map((team, index) => {
          let selected = isAllSelected || (selectedTeams.filter(selected => selected["@id"] === team["@id"]).length > 0);
          return <TeamItem
            {...props}
            key={`team-${index}`}
            team={team}
            isSelected={selected}
            setSelected={updateSelectedList}
            updateFromBulk={updateFromBulk}
          />
        })}
      <Divider sx={{mt: 3, mb: 3}}/>
    </Stack>
  )
}

export default TeamsList;