import {CircularProgress, Skeleton, Stack, Typography} from "@mui/material";
import {LongText} from "components/index";
import theme from "theme/theme";
import {emptyFunction, enCommonLabel} from "constants/index";
import BreadcrumbsDisplay from "components/BreadcrumbsDisplay";
import TimestampTypo from "components/Typography/TimestampTypo";
import {useDocument} from "hooks/index";
import {db, storage} from "../../../../firebase";
import {doc} from "firebase/firestore";
import {Export} from "types/Export";
import {ActionType, Entity, ExportStatus, Severity} from "enums/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {RefObject, useState} from "react";
import {downloadFile, formatDateTimeFirestoreDate, generateEntityBreadcrumbs, submitForm} from "screens/utility";
import {toastProps} from "screens/BaseProps";
import {ActivityItem} from "types/ActivityFeedTypes";
import getEnvKey from "../../../utility/getEnvKey";
import {storageBucketKey} from "constants/envKeys";
import {getDownloadURL, ref} from "firebase/storage";

interface ExportItemProps {
  exportId: string;
  collectionRefPath: string;
  containerRef?: RefObject<any>;
  toastProps: toastProps;
}

function ExportItem(props: ExportItemProps) {
  const {exportId, collectionRefPath, containerRef, toastProps} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps;

  const docRef = doc(db, collectionRefPath, exportId);
  const [exportDoc] = useDocument<Export>(docRef);
  const {date, time} = formatDateTimeFirestoreDate(exportDoc?.timeCreated);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const storageBucket = getEnvKey(storageBucketKey);

  if (exportDoc === null)
    return <Skeleton height={110} sx={{marginY: -2.3}} />

  if (exportDoc === undefined)
    return null;

  function exportFile() {
    if (!exportDoc) return;

    if (!exportDoc.filepath === null) return;

    setIsLoading(true);
    const path = exportDoc.filepath;

    const filePathArr = path.split("/");
    const fileName = filePathArr[filePathArr.length - 1];
    getDownloadURL(ref(storage, `${storageBucket}${path}`))
      .then((downloadUrl) => {
        downloadFile(downloadUrl, fileName, async () => {
          setToastSeverity(Severity.Success);
          setToastMessage(enCommonLabel.fileExported);
          setIsToastOpen(true);
          await submitForm(docRef, ActionType.Update, emptyFunction, {downloaded: true});
        });
      })
      .catch(() => {
        setToastSeverity(Severity.Error);
        setToastMessage(enCommonLabel.errorExporting); // This will be removed in the future after BE impl.
        setIsToastOpen(true);
      }).finally(() => {
        setIsLoading(false);
      });
  }

  const breadCrumbs = generateEntityBreadcrumbs({...(exportDoc as unknown as ActivityItem), entity: Entity.Export});
  const isExporting = exportDoc.exportStatus === ExportStatus.Exporting;
  const isFinished = exportDoc.exportStatus === ExportStatus.Finished;
  const downloaded = exportDoc.downloaded || false;

  return (
    <Stack
      direction="column"
      padding={0}
      margin={0}
      className="file-upload-panel-list-item"
      sx={{borderBottom: "1px solid #e0e0e0", backgroundColor: downloaded ? "inherit" : theme.palette.grey["100"]}}
      title={isFinished ? enCommonLabel.clickToDownload: isExporting ? "Still exporting" : ""}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        gap={1}
        paddingY={0.5}
        paddingX={2}
        onClick={exportFile}
      >
        <Stack direction="row">
          {isExporting || isLoading ? <CircularProgress size={26} />
            : <SystemIcons.CheckCircle width={26} height={26} strokeWidth={2}/>}
        </Stack>
        <Stack direction="column">
          <Typography
            id={`${exportDoc["@id"]}-file-status`}
            variant="h5"
            color={theme.palette.secondary.main}
          >
            {isExporting ? <i>{enCommonLabel.exporting}</i>
              : enCommonLabel.exportComplete}
          </Typography>
          <a
            href={"#"}
            style={{
              textDecoration: "underline",
              color: theme.palette.secondary.main
            }}
          >
            <LongText
              maxLines={1}
              containerRef={containerRef}
              variant="body2"
              fontSize={12}
            >
              {exportDoc.fileName}
            </LongText>
          </a>
          {breadCrumbs && breadCrumbs.length > 0 && (
            <BreadcrumbsDisplay
              entityBreadcrumbs={breadCrumbs}
              orgName={breadCrumbs[0].name ?? "fillerOrgName"}
            />
          )}
          <TimestampTypo>{`${date} ${time}`}</TimestampTypo>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ExportItem;