import {Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import BouncingDots from "components/BouncingDots";
import React, {useEffect, useState} from "react";
import {useCollection, useDebounce} from "hooks/index";
import {CollectionReference, doc, orderBy, Timestamp, where} from "firebase/firestore";
import {MessageType} from "enums/messageType";
import {emptyFunction, enChatLabel} from "constants/index";
import {ChatMessage} from "types/ChatMessage";
import {submitForm} from "screens/utility";
import {ActionType} from "enums/actionType";
import {auth} from "../../../firebase";

interface TypingLabelProps {
  colRef: CollectionReference;
}

function TypingLabel(props: TypingLabelProps) {
  const {colRef} = props;

  const {uid} = auth.currentUser!;

  const [allTypingDocuments] = useCollection<ChatMessage>(null, colRef, [
    where("type", "==", MessageType.Typing),
    where("timestamp", ">=", Timestamp.fromDate(new Date(Date.now() - 1000 * 10))),
    orderBy("timestamp"),
  ]);

  // created another collection since there is a firebase limitation with using multiple inequality
  const [displayedTypingDocs, setDisplayedTypingDocs] = useState<ChatMessage[]>([]);

  useEffect(() => {
    if (!allTypingDocuments) return;
    setDisplayedTypingDocs(allTypingDocuments.filter(docs => docs.sender.id !== uid!));
  }, [allTypingDocuments]);

  useDebounce(deleteTypingDocuments, 500, [displayedTypingDocs]);

  async function deleteTypingDocuments() {
    if (!displayedTypingDocs) return;

    displayedTypingDocs.forEach((typingDoc) => {
      submitForm(doc(colRef, typingDoc["@id"]!), ActionType.Delete, emptyFunction); // Not awaited for faster iteration
    });
  }

  if (displayedTypingDocs.length === 0)
    return null

  return (
    <Stack
      direction="row"
      gap={1}
      sx={{padding: "0.5em"}}
      alignItems="center"
    >
      <Typography fontSize={14} fontStyle="italic" color={theme.palette.neutral.dark}>
        {displayedTypingDocs.length > 2 ? enChatLabel.manyPeopleTyping
          : enChatLabel.personTyping(displayedTypingDocs)}
      </Typography>
      <BouncingDots dotSize={4} color={theme.palette.neutral.dark}/>
    </Stack>
  )
}

export default TypingLabel;
