import {User} from "types/User";
import {PartialUserData} from "types/PartialUserData";
import {Box, Typography} from "@mui/material";
import {Avatar} from "components/index";
import React from "react";

function generateUserDisplayItem(user: User | PartialUserData, reverse?: boolean) {
  const {name} = user;

  return <Box display="flex" alignItems="center" gap={1}>
    {!reverse ? <>
      <Avatar {...user} sx={{fontSize: 12, mt: 0, height: "28px", width: "28px", borderWidth: 0}}/>
      <Typography variant="h5">{`${name}`}</Typography>
    </> : <>
      <Typography variant="h5">{`${name}`}</Typography>
      <Avatar {...user} sx={{fontSize: 12, mt: 0, height: "28px", width: "28px", borderWidth: 0}}/>
    </>
    }
  </Box>
}

export default generateUserDisplayItem