import PanelTitle from "./Objects/PanelTitle";
import PanelItem from "./Objects/PanelItem";
import {Permission, PermissionEntity, PermissionOperationKey} from "types/Permission";
import {PERMISSION_LABEL} from "../constants/permissionLabel";
import {enRolesAndPermissionsLabel} from "constants/index";
import {en} from "language/en";
import {getAvailableOperations} from "../utils/getAvailableOperations";
import {Dispatch, useEffect, useState} from "react";
import {Action} from "../reducer/action";
import {ReducerActionType} from "../reducer/actionType";

interface PanelProps {
  permissions: Permission[];
  entityGroup: PermissionEntity[];
  isExpandAll: boolean;
  reducerDispatch: Dispatch<Action>;
}

function Panel(props: PanelProps) {
  const {entityGroup, permissions, reducerDispatch, isExpandAll} = props;
  const panelTitle = PERMISSION_LABEL[entityGroup[0]];

  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const isDisplayed = entityGroup.map(entityGroup => permissions.filter(permission => permission.id === entityGroup).length > 0).some(Boolean);

  // update local state when isExpandAll is changed
  useEffect(() => {
    setIsExpanded(isExpandAll)
  }, [isExpandAll]);

  if(!isDisplayed) return null;

  return (
    <>
      <PanelTitle
        panelName={panelTitle}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      />
      {entityGroup.map(entity => {
        const permissionPanel = entity;
        const permission = permissions.filter(permission => permission.id === entity)[0];
        if(!permission) return null;

        const permissionOperations = getAvailableOperations(permissionPanel, permission);
        return permissionOperations.map((operation) => {
            const operationLabel = en.screen.RolesAndPermissions.operation(permissionPanel, operation as PermissionOperationKey);
            return (
              <PanelItem
                isExpanded={isExpanded}
                isView={operation === PermissionOperationKey.View}
                key={`panel-operation-item-${operation}`}
                label={enRolesAndPermissionsLabel.allowTo(operationLabel)}
                role={permission[operation as PermissionOperationKey]}
                updatePermissions={(role, checked) =>
                  reducerDispatch({
                    type: ReducerActionType.Update,
                    payload: {
                      permissionEntity: permissionPanel,
                      operation: operation as PermissionOperationKey,
                      role,
                      checked
                    }
                  })}
              />
            )
          }
        )
      })}
    </>
  )
}

export default Panel;