import {toastProps} from "screens/BaseProps";
import {db} from "../../../firebase";
import {collection, doc} from "firebase/firestore";
import {Milestone, Task} from "types/index";
import {useAccess, useCollection, useComponentToggler, useDebounce} from "hooks/index";
import {Content, InProgress} from "components/index";
import React, {useEffect, useState} from "react";
import {Box, Stack} from "@mui/material";
import TaskSearchAndFilter from "./TaskSearchAndFilter";
import EmptySearchResults from "components/EmptySearchResults";
import {Entity, ActionType, ViewStatus, Severity} from "enums/index";
import {PermissionEntity} from "types/Permission";
import MilestoneItem, {MilestoneData} from "./MilestoneItem";
import {areObjectsEqual, sortObjectsBy, submitForm} from "screens/utility";
import BulkActions from "./BulkActions";
import {enMilestoneLabel, enTaskLabel} from "constants/index";
import {InitialFilterState, queryAlgolia} from "../../Home/queryAlgolia";
import {algoliaAssetPath} from "../../utility/algoliaColPath";
import {useParams} from "react-router-dom";
import ConfirmDialog from "components/ConfirmDialog";
import {statusSubmitHandler} from "../../utility/statusSubmitHandler";
import DeleteDialog from "../../../components/Dialogs/DeleteDialog";

interface MilestonesViewProps {
  toastProps: toastProps;
  milestonesCollectionRefPath: string;
  showHidden: boolean;
  uid: string
}

function MilestonesView(props: MilestonesViewProps) {
  const {toastProps, uid, milestonesCollectionRefPath} = props;

  const {orgId, projId, assetId, templateId} = useParams();
  const algoliaPath = algoliaAssetPath(orgId!, projId!, assetId!, templateId!);

  const [milestonesDB] = useCollection<Milestone>(null, collection(db, milestonesCollectionRefPath));
  const [milestones, setMilestones] = React.useState<MilestoneData[]>([]);
  const [displayedTasks, setDisplayedTasks] = useState<{taskId: string, milestoneId: string}[]>([]);

  const [algoliaResult, setAlgoliaResult] = useState<Task[] | null>(null);

  const [expandedIds, setExpandedIds] = useState<Set<string>>(new Set());
  const [checkedTasks, setCheckedTasks] = useState<{taskId: string, milestoneId: string}[]>([]);
  const [isCheckboxShown, setIsCheckboxShown] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteStatus, setDeleteStatus] = useState<ViewStatus[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  const [selectedMilestone, setSelectedMilestone] = React.useState<Milestone | null>(null);
  const [isDeleteDialogOpen, {open: openDeleteDialog, close: closeDeleteDialog}] = useComponentToggler(false);

  const [milestoneAccess] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationTemplateMilestone,
  });

  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [isDialogOpen, {open: openDialog, close: closeDialog}] = useComponentToggler(false);

  useDebounce(search, 500, [searchText]);

  useEffect(() => {
    if (algoliaResult !== null) return;

    if (milestonesDB === null) {
      setMilestones([]);
      setDisplayedTasks([]);
      setCheckedTasks([]);
      setExpandedIds(new Set());
      return;
    }

    const newMilestonesDB = milestonesDB.map(milestone => {
      return {
        "@id": milestone["@id"]!,
        name: milestone.name,
      }
    });

    const misc = newMilestonesDB.find(milestone => milestone["@id"] === "misc");
    const newMilestonesDBWithoutMisc = newMilestonesDB.filter(milestone => milestone["@id"] !== "misc");
    let sortedMilestonesDB = sortObjectsBy(newMilestonesDBWithoutMisc, "name");
    if (misc) sortedMilestonesDB.push(misc);

    setExpandedIds(new Set(sortedMilestonesDB.map(milestone => milestone["@id"]!)));
    if (areObjectsEqual(sortedMilestonesDB, milestones)) return;

    setMilestones(sortedMilestonesDB);
  }, [milestonesDB]);

  // if there are changes is algolia result
  useEffect(() => {
    if (algoliaResult === null) {
      if (milestonesDB === null) return;

      setDisplayedTasks([]);
      setCheckedTasks([]);

      const currentMilestones = milestones.map(milestone => milestone["@id"]);
      const lastMilestones = milestonesDB.map(milestone => milestone["@id"]);
      if (!areObjectsEqual(currentMilestones, lastMilestones)) {
        setMilestones(milestonesDB.map(milestone => {
          return {
            "@id": milestone["@id"]!,
            name: milestone.name,
          }
        }));
      }
      return;
    }

    if (algoliaResult.length === 0) {
      setMilestones([]);
      setDisplayedTasks([]);
      setCheckedTasks([]);
      return;
    }

    // get distinct milestone ids
    const milestoneIds = Array.from(new Set(algoliaResult.map(task => task.milestoneId)));
    let newMilestones: MilestoneData[] = [];
    milestoneIds.forEach(milestoneId => {
      const milestone = milestonesDB?.find(milestone => milestone["@id"] === milestoneId);
      if (milestone)
        newMilestones.push( {
          "@id": milestone["@id"]!,
          name: milestone.name,
        })
    });
    const sortedMilestones = sortObjectsBy(newMilestones, "name");

    let tasks: Task[] = [];
    sortedMilestones.forEach(milestone => {
      const milestoneTasks = algoliaResult.filter(task => task.milestoneId === milestone["@id"]!);
      const sortedMilestoneTasks = sortObjectsBy(milestoneTasks, "name");
      tasks = [...tasks, ...sortedMilestoneTasks];
    });
    setMilestones(sortedMilestones)
    setDisplayedTasks(tasks.map(task => ({taskId: task["@id"]!, milestoneId: task.milestoneId!})));
  }, [algoliaResult]);

  useEffect(() => {
    if (checkedTasks.length === 0) return;

    if (checkedTasks.length > deleteStatus.length) return;

    showMessage(enTaskLabel.deleteSuccess, Severity.Success);
    setCheckedTasks([]);
    setDeleteStatus([]);
    setIsLoading(false);
    setIsCheckboxShown(false);
  }, [deleteStatus]);

  function toggleExpandAll() {
    if (expandedIds.size === milestones.length) {
      setExpandedIds(new Set());
      setCheckedTasks([]);
      return;
    }

    const newExpandedIds = new Set(milestones.map(milestone => milestone["@id"]!));
    setExpandedIds(newExpandedIds);
  }

  function checkTask(taskId: string, milestoneId: string) {
    setCheckedTasks(prev => {
      const newCheckedTasks = [...prev];
      const exists = newCheckedTasks.some(item => item.taskId === taskId);
      if (exists) {
        return newCheckedTasks.filter(item => item.taskId !== taskId);
      } else {
        newCheckedTasks.push({taskId, milestoneId});
        return newCheckedTasks;
      }
    });
  }

  function expandMilestone(milestoneId: string) {
    setExpandedIds(prev => {
      const newExpandedIds = new Set(prev);
      newExpandedIds.has(milestoneId) ? newExpandedIds.delete(milestoneId) : newExpandedIds.add(milestoneId);
      return newExpandedIds;
    });
  }

  function showDeleteDialog(milestone: Milestone) {
    if (isLoading) return;

    setSelectedMilestone(milestone);
    openDeleteDialog();
  }

  function deleteSelected() {
    if (isLoading) return;

    if (checkedTasks.length === 0) return;

    setIsLoading(true);
    checkedTasks.forEach(checkedTask => {
      const taskDocRef = doc(db, milestonesCollectionRefPath, checkedTask.milestoneId, "tasks", checkedTask.taskId);
      submitForm(taskDocRef, ActionType.Delete,
        (isLastUpdate) => isLastUpdate && setDeleteStatus(prev => [...prev, ViewStatus.Finished]),
        )
    })
  }

  function showMessage(message: string, severity: Severity) {
    const {setToastMessage, setToastSeverity, setIsToastOpen} = props.toastProps!;

    setToastMessage(message);
    setToastSeverity(severity);
    setIsToastOpen(true);
    setSelectedTask(null);
    closeDialog();
  }

  // task related deletion
  function deleteTask(task: Task) {
    setSelectedTask(task);
    openDialog();
  }

  function cancelDelete() {
    setSelectedTask(null);
    closeDialog();
  }

  async function startDelete() {
    if (!selectedTask) return;

    const docRef = doc(db, milestonesCollectionRefPath, selectedTask.milestoneId, "tasks", selectedTask!["@id"]!);
    await submitForm(
      docRef,
      ActionType.Delete,
      () => showMessage(enTaskLabel.deleteSuccess, Severity.Success),
    );
  }

  async function search() {
    if (isLoading) return;

    const {result} = await queryAlgolia(
      {...InitialFilterState, searchText},
      uid,
      [`templateColPath:${algoliaPath}`, `entity:OrganizationTemplateTask`, ...(props.showHidden ? [] : ["hidden:false"])],
    );

    if (result === undefined) {
      setAlgoliaResult([]);
      setIsLoading(false);
      return;
    }

    setAlgoliaResult(result);
    setIsLoading(false);
  }

  async function deleteSelectedMilestone() {
    if (!selectedMilestone) return;

    setIsLoading(true);
    const milestoneDocRef = doc(db, milestonesCollectionRefPath, selectedMilestone["@id"]!);
    await submitForm(
      milestoneDocRef,
      ActionType.Delete,
      (status, data, isLastUpdate) => statusSubmitHandler({
        status,
        data,
        isLastUpdate,
        successCallback: () => {
          setIsLoading(false);
          showMessage(enMilestoneLabel.deleteSuccess, Severity.Success);
          closeDeleteDialog();
        },
        errorCallback: () => {
          setIsLoading(false);
          showMessage(enMilestoneLabel.deleteError, Severity.Error);
          closeDeleteDialog();
        }
      }),
    );
  }

  if (milestonesDB === null) {
    return (
      <Content>
        <InProgress />
      </Content>
    )
  }

  return (
    <Stack direction="column">
      <TaskSearchAndFilter
        isCollapseAll={expandedIds.size > 0}
        setIsCollapseAll={toggleExpandAll}
        isLoading={isLoading}
        setSearchText={setSearchText}
      />
      <BulkActions
        milestonesCollectionRefPath={milestonesCollectionRefPath}
        showHidden={props.showHidden}
        toastProps={toastProps!}
        isCheckboxShown={isCheckboxShown}
        checkedTasks={checkedTasks}
        deleteSelected={deleteSelected}
        resetCheckedTasks={() => setCheckedTasks([])}
        setIsCheckboxShown={(value) => setIsCheckboxShown(value)}
      />

      {milestones.length === 0 && algoliaResult !== null && (
        <Box marginY={5}>
          <EmptySearchResults entity={Entity.Task} />
        </Box>
      )}

      {milestones.map(milestone => {
        return (
          <MilestoneItem
            milestoneId={milestone["@id"]!}
            milestonesCollectionRefPath={milestonesCollectionRefPath}
            isExpanded={expandedIds.has(milestone["@id"]!)}
            showDeleteDialog={showDeleteDialog}
            toastProps={toastProps!}
            uid={uid}
            checkTask={checkTask}
            showHidden={props.showHidden}
            checkedTasks={checkedTasks}
            expandMilestone={() => expandMilestone(milestone["@id"]!)}
            milestoneAccess={milestoneAccess}
            isCheckboxShown={isCheckboxShown}
            deleteTask={deleteTask}
            fromSearch={algoliaResult !== null}
            displayedTasks={displayedTasks.filter(task => task.milestoneId === milestone["@id"]!)}
          />
        )
      })}
      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        title={enMilestoneLabel.deleteConfirmationTitle}
        text={enMilestoneLabel.deleteConfirmationText}
        handleClose={closeDeleteDialog}
        handleConfirm={deleteSelectedMilestone}
        id="bulk-delete-drawer"
      />
      <DeleteDialog
        overlayId="delete-milestone-dialog"
        confirmButtonId={`confirm-button`}
        cancelButtonId={`cancel-button`}
        isOpen={isDialogOpen}
        title={enTaskLabel.deleteConfirmationTitle}
        text={enTaskLabel.deleteConfirmationText}
        handleClose={cancelDelete}
        handleConfirm={startDelete}
      />
    </Stack>
  )
}

export default MilestonesView;