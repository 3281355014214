import {BaseProps} from "screens/BaseProps";
import {Stack} from "@mui/material";
import {Content} from "components/index";
import Header from "./Header";
import Head from "./Head";
import {useParams} from "react-router-dom";
import {organizationPath, teamCategoriesPath, submitForm} from "../utility";
import {useAccess, useDocument, useRTDBDocField} from "hooks/index";
import {TeamCategory} from "types/index";
import MainContent from "./MainContent";
import ManageTeamCategoryFormDrawer from "./Drawers/ManageTeamCategoryFormDrawer";
import React, {useEffect, useState} from "react";
import {ActionType, CounterFields, Entity, ProcessType, Severity} from "enums/index";
import {doc} from "firebase/firestore";
import DeleteDialog from "components/Dialogs/DeleteDialog";
import {emptyFunction, enCommonLabel, enManageTeamCategoriesLabel} from "constants/index";
import {useNavigate} from "react-router";
import {PermissionEntity} from "types/Permission";
import {statusSubmitHandler} from "../utility/statusSubmitHandler";

function ManageTeamCategories(props: BaseProps) {
  const {toastProps, selectedOrg, uid, setSelectedOrgId = emptyFunction} = props;
  const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps!;

  const {orgId} = useParams();
  const navigate = useNavigate();

  const documentRef = organizationPath(orgId!);
  const [isManageTeamDrawerOpen, setIsManageTeamDrawerOpen] = useState(false);
  const [actionTargetCategory, setActionTargetCategory] = useState<TeamCategory | undefined>();
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [deleteCategoryDocument, setDeleteCategoryReference] = useDocument(null);

  const teamCategoriesCount = useRTDBDocField<number>(documentRef.path, CounterFields.TeamCategoriesCount) || 0;

  const [categoryAccess] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.TeamCategory,
  });

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  useEffect(() => {
    if (!actionTargetCategory) return;
    if (!!deleteCategoryDocument) return setIsDeleteConfirmationOpen(true);
  }, [deleteCategoryDocument]);

  async function onItemActionClick(action: ActionType, teamCategory: TeamCategory) {
    setActionTargetCategory(teamCategory);
    if (action === ActionType.Update) return setIsManageTeamDrawerOpen(true);
    setDeleteCategoryReference(doc(teamCategoriesPath(orgId!), teamCategory!["@id"]!));
  }

  function onAddTeamClick() {
    setActionTargetCategory(undefined);
    setIsManageTeamDrawerOpen(true);
  }

  async function onDeleteConfirm() {
    const categoryDocRef = doc(teamCategoriesPath(orgId!), actionTargetCategory!["@id"]!);
    await submitForm(
      categoryDocRef,
      ActionType.Delete,
      (status, data, isLastUpdate) => statusSubmitHandler<TeamCategory>({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback,
      })
    );
  }

  function successCallback() {
    setToastMessage(enManageTeamCategoriesLabel.deleteSuccess(actionTargetCategory?.name ?? ""));
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);

    onDeleteDialogClose();
  }

  function errorCallback(message: any) {
    let toastMessage = enCommonLabel.errorProcess(ProcessType.Delete);
    // if type of message is obj, get first obj value
    if (typeof message === "object" && (typeof Object.values(message)[0] === "string")) {
      toastMessage = Object.values(message)[0] as string;
    }

    setToastMessage(toastMessage);
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
    onDeleteDialogClose();
  }

  function onDeleteDialogClose() {
    setActionTargetCategory(undefined);
    setIsDeleteConfirmationOpen(false);
  }

  return (
    <>
      <ManageTeamCategoryFormDrawer
        uid={uid}
        toastProps={toastProps}
        isOpen={isManageTeamDrawerOpen}
        setIsOpen={setIsManageTeamDrawerOpen}
        teamCategory={actionTargetCategory}
      />
      {
        actionTargetCategory && (
          <DeleteDialog
            overlayId="delete-teamCategory"
            confirmButtonId={`del_${actionTargetCategory["@id"]!}_confirmBtn`}
            cancelButtonId={`del_${actionTargetCategory["@id"]!}_cancelBtn`}
            isOpen={isDeleteConfirmationOpen}
            title={enManageTeamCategoriesLabel.deleteTitle}
            text={enManageTeamCategoriesLabel.deleteText(actionTargetCategory.name)}
            handleClose={onDeleteDialogClose}
            handleConfirm={onDeleteConfirm}
          />
        )
      }
      <Header />
      <Content>
        <Stack flex={1}>
          <Head
            categoryAccess={categoryAccess}
            toastProps={toastProps}
            teamCategoriesCount={teamCategoriesCount}
            onAddCategoryClick={onAddTeamClick}
          />
          <MainContent
            categoryAccess={categoryAccess}
            organizationId={orgId!}
            teamCategoriesCount={teamCategoriesCount}
            {...props}
            onItemActionClick={onItemActionClick}
          />
        </Stack>
      </Content>
    </>
  )
}

export default ManageTeamCategories;