import {Milestone} from "types/Milestone";
import {Dispatch, MutableRefObject, useCallback, useEffect, useReducer} from "react";
import {
  CollectionReference,
  DocumentReference,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter
} from "firebase/firestore";
import {DirectionalOrder} from "enums/DirectionalOrder";
import {Task} from "types/Task";
import {sortObjectsBy} from "../screens/utility";

export interface MilestoneReducerState {
  milestoneList: Milestone[] | null;
  page: number;
  lastDocRef: DocumentReference | null;
  loading: boolean;
  displayedMilestones: Milestone[] | null;
  areEmptyMilestonesHidden: boolean;
  listedTasks: Task [];
  fromAlgolia: boolean;
}

export type MilestoneAction =
  | { type: MilestoneActionType.updateMilestones, payload: Milestone[] }
  | { type: MilestoneActionType.updateLastDocRef, payload: DocumentReference }
  | { type: MilestoneActionType.nextPage, payload?: any }
  | { type: MilestoneActionType.setLoading, payload: boolean }
  | { type: MilestoneActionType.updateMilestone, payload: { index: number, milestone: Milestone } }
  | { type: MilestoneActionType.addMilestone, payload: Milestone }
  | { type: MilestoneActionType.deleteMilestone, payload: string }
  | { type: MilestoneActionType.toggleHideShowEmptyMilestones }
  | { type: MilestoneActionType.updateListedTasks, payload: {newTasks: Task[], milestoneId: string, fromAlgolia: boolean }}

export enum MilestoneActionType {
  updateMilestones = "updateMilestones",
  updateLastDocRef = "updateLastDocRef",
  nextPage = "nextPage",
  setLoading = "setLoading",
  updateMilestone = "updateMilestone",
  addMilestone = "addMilestone",
  deleteMilestone = "deleteMilestone",
  toggleHideShowEmptyMilestones = "toggleHideShowEmptyMilestones",
  updateListedTasks = "updateListedTasks",
}

const initialState: MilestoneReducerState = {
  milestoneList: null,
  page: 1,
  lastDocRef: null,
  loading: false,
  displayedMilestones: null,
  areEmptyMilestonesHidden: false,
  listedTasks: [],
  fromAlgolia: false,
}

interface useMilestoneReducerProps {
  bottomBoundaryRef: MutableRefObject<any>;
  milestonesCollectionRef: CollectionReference;
}

type useMilestoneReducerReturn  = [
  state: MilestoneReducerState,
  dispatch: Dispatch<MilestoneAction>
];

export default function useMilestoneReducer(props: useMilestoneReducerProps): useMilestoneReducerReturn {
  const {bottomBoundaryRef, milestonesCollectionRef} = props;
  const [state, dispatch] = useReducer(reducer, initialState)

  const scrollObserver = useCallback(
    node => {
      new IntersectionObserver(entries => {
        entries.forEach(en => {
          if (en.intersectionRatio > 0) {
            dispatch({type: MilestoneActionType.nextPage});
          }
        });
      }, {rootMargin: "200px"}).observe(node);
    },
    [dispatch]
  );

  // listen when page changes
  useEffect(() => {

    async function queryMilestones() {
      if (state.loading) return;

      dispatch({type: MilestoneActionType.setLoading, payload: true})

      let milestoneQuery = query(milestonesCollectionRef)
      milestoneQuery = query(milestoneQuery, orderBy("name", DirectionalOrder.asc)); // revert order: orderBy("milestoneIndex", Order.desc)

      if (state.page > 1 && state.milestoneList && state.lastDocRef) {
        const lastDoc = await getDoc(state.lastDocRef);
        if(!!lastDoc) milestoneQuery = query(milestoneQuery, startAfter(lastDoc))
      }
      milestoneQuery = query(milestoneQuery, limit(5))

      const docs = (await getDocs(milestoneQuery)).docs;
      if (!docs.length) {
        dispatch({type: MilestoneActionType.setLoading, payload: false})
        return
      }

      let milestones = (await Promise.all(docs.map((doc, index) => {
        if (index === docs.length - 1) {
          dispatch({type: MilestoneActionType.updateLastDocRef, payload: doc.ref})
        }
        return {...doc.data()} as Milestone
      })))

      milestones = milestones.map((milestone) => ({...milestone}));
      const miscIndex = milestones.findIndex((milestone) => milestone?.["@id"] === "misc");
      // remove misc milestone from the list and add it at the end
      milestones.push(milestones.splice(miscIndex, 1)[0]);
      dispatch({type: MilestoneActionType.updateMilestones, payload: milestones});
      dispatch({type: MilestoneActionType.setLoading, payload: false})
    }

    queryMilestones();

  }, [state.page])

  useEffect(() => {
    if (bottomBoundaryRef.current) {
      scrollObserver(bottomBoundaryRef.current);
    }
  }, [scrollObserver, bottomBoundaryRef.current]);

  return [state, dispatch];
}

function reducer(state: MilestoneReducerState, action: MilestoneAction): MilestoneReducerState {

  switch (action.type) {
    case MilestoneActionType.nextPage:
      return {...state, page: state.page + 1};

    case MilestoneActionType.updateMilestones:
      return {
        ...state,
        milestoneList: [...state.milestoneList || [], ...action.payload],
        displayedMilestones: [...state.milestoneList || [], ...action.payload]
      }

    case MilestoneActionType.updateLastDocRef:
      return {...state, lastDocRef: action.payload}

    case MilestoneActionType.setLoading:
      return {...state, loading: action.payload};

    case MilestoneActionType.updateMilestone:
      let newMilestones = state.milestoneList!;
      newMilestones[action.payload.index] = action.payload.milestone;

      // fix possible bug: if milestone name is edited, it should be sorted again
      const miscMilestone = newMilestones.find(milestone => milestone["@id"] === "misc");

      newMilestones = newMilestones.filter(milestone => milestone["@id"] !== "misc");

      const sortedMilestones = [...sortObjectsBy<Milestone>(newMilestones, "name")];
      if (miscMilestone) sortedMilestones.push(miscMilestone);
      return {
        ...state,
        milestoneList: [...sortedMilestones],
        displayedMilestones: [...sortedMilestones],
      }

    case MilestoneActionType.addMilestone:
      // append new milestone to the list and remove misc milestone
      const appendedList = [...(state.milestoneList || []), action.payload]
        .filter(milestone => milestone["@id"] !== "misc");
      // extract misc milestone
      const misc = state.milestoneList?.find(milestone => milestone["@id"] === "misc");
      // sort the list
      const newMilestoneList = [...sortObjectsBy<Milestone>(appendedList, "name")];
      // add misc milestone at the end
      if (misc) newMilestoneList.push(misc);

      return {
        ...state,
        milestoneList: structuredClone(newMilestoneList),
        displayedMilestones: structuredClone(newMilestoneList),
      }

    case MilestoneActionType.deleteMilestone:
      if (!state.milestoneList) {
        throw new Error("Milestones is not populated")
      }

      const foundMilestone = state.milestoneList.find(milestone => milestone["@id"] === action.payload);
      if (!foundMilestone) return {...state}

      let afterDeleteMilestones = JSON.parse(JSON.stringify(state.milestoneList));
      const index = state.milestoneList.indexOf(foundMilestone);
      afterDeleteMilestones = (afterDeleteMilestones.slice(0, index).concat(afterDeleteMilestones.slice(index + 1, afterDeleteMilestones.length)))
      return {...state, milestoneList: afterDeleteMilestones, displayedMilestones: afterDeleteMilestones}

    case MilestoneActionType.toggleHideShowEmptyMilestones:
      return state.areEmptyMilestonesHidden ? {
        ...state,
        displayedMilestones: state.milestoneList,
        areEmptyMilestonesHidden: false
      } : {
        ...state,
        displayedMilestones: state.milestoneList ? state.milestoneList.filter((milestone) => milestone.displayedTasksCount || -1 > 0) : [],
        areEmptyMilestonesHidden: true
      };
    case MilestoneActionType.updateListedTasks:
      const newTasks = action.payload.newTasks;
      const milestoneId = action.payload.milestoneId;

      // remove all tasks listed in the given milestone
      let newList = [...state.listedTasks].filter(task => task["milestoneId"] !== milestoneId);
      // append received task to filtered list
      newList = [...newList, ...newTasks];

      return {
        ...state,
        fromAlgolia: action.payload.fromAlgolia,
        listedTasks: newList,
      };

    default:
      return {...state}
  }
}
