import {Box, Checkbox, Grid, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import theme from "theme/theme";
import {Avatar, EmailTooltip} from "components/index";
import {AccessListUser, PartialUserData} from "types/index";
import AccessRoleMenu from "components/Drawers/ManageAccessDrawer/Objects/AccessRoleMenu";
import {auth} from "../../../../firebase";
import {enCommonLabel} from "constants/index";
import {AccessRole} from "enums/index";

export interface AccessListUserWithPermission extends AccessListUser {
  canAdd?: boolean;
}

interface PeopleItemProps {
  index: number;
  person: AccessListUserWithPermission;
  isSelected: boolean;
  setSelected: (id: string, checked: boolean, role?: AccessRole) => void;
  allowSelect: boolean;
  updateFromBulk?: boolean;
  isLoading?: boolean;
  access: any;
}

function PeopleItem(props: PeopleItemProps) {
  const {person, isSelected, allowSelect, access} = props;
  const {isLoading = false, updateFromBulk = false, setSelected} = props;

  const {"@id": id = "fillerId", name} = person;

  const [documentLoading, setDocumentLoading] = useState<boolean>(isLoading);
  const [role, setRole] = useState<AccessRole>(AccessRole.User);

  const currUserId = auth.currentUser?.uid;

  useEffect(() => {
    setDocumentLoading(isLoading);
  }, [isLoading]);

  // update local role if there is an update from bulk selection
  useEffect(() => {
    if(!updateFromBulk || !person.role || documentLoading) return;
    setRole(person.role);
  }, [updateFromBulk, person.role]);

  function updateSelected(checked: boolean) {
    setSelected(id, checked, role);
  }

  // if there is an update with the selected role, automatically set the checkbox to checked
  async function onUpdateAccessRole(selectedRole: AccessRole) {
    setSelected(id, role !== selectedRole, selectedRole);
    setRole(selectedRole);
    return;
  }

  return (
    <Box sx={{flexGrow: 1}} className={"people-list-item"} id={`people-list-item-${person["@id"]}`}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        sx={{bgcolor: isSelected ? theme.palette.background.swiftDefault : null, borderRadius: 1}}
        py={0.5}
      >
        {allowSelect && (
          <Grid item xs>
            <Checkbox
              id={`${id}-person-item-checkbox`}
              checked={isSelected}
              disabled={documentLoading}
              onChange={() => updateSelected(!isSelected)}
            />
          </Grid>
        )}
        <Grid item xs={9} justifyContent="center" alignItems="center">
          <Stack direction="row" gap={1} justifyContent="flex-start" alignItems="center" width={"90%"}>
            <Avatar
              {...person}
              isTeam={false}
              sx={{mt: "0px", fontSize: "12px"}}
            />
            <Stack direction="column">
              <Stack direction="row" gap={1}>
                <EmailTooltip user={{...person as unknown as PartialUserData}}>
                  <Typography
                    id={`${id}-person-item-name`}
                    variant="h5">
                    {name}
                  </Typography>
                </EmailTooltip>
                <Typography variant="h5" color={theme.palette.neutral.medium}>
                  {currUserId === id && `(${enCommonLabel.you})`}
                </Typography>
              </Stack>
              <AccessRoleMenu
                id={`${id}-person-item-menu`}
                currentRole={role}
                onUpdateAccessRole={onUpdateAccessRole}
                enabled={!documentLoading}
                access={access}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs/>
      </Grid>
    </Box>
  )
}

export default PeopleItem;
