import theme from "theme/theme";
import {Alert, Box, Button, Hidden, Stack, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import useQuery from "hooks/useQuery";
import FormContainer from "components/FormContainer";
import {SwiftLogoOnly} from "assets/icons/SwiftLogo";
import {enCommonButton, enCommonLabel} from "constants/index";
import {ActionType} from "enums/actionType";
import {resendVerificationEmailPath, submitForm} from "../utility";
import {doc} from "firebase/firestore";
import {auth} from "../../firebase";
import {AlertMessage} from "types/Alert";
import {Severity} from "enums/severity";

interface TokenExpiredProps {
  user: { uid: string; email: string };
}
function TokenExpired(props: TokenExpiredProps) {
  const {user} = props;
  const queryParams = useQuery();

  const token = queryParams.get("token");
  const navigate = useNavigate();
  const [isNewTokenRequested, setIsNewTokenRequested] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertMessage>({
    show: false,
    message: null,
    severity: Severity.Error
  });

  function requestVerificationEmail() {
    if (!token) return;
    const tokenPayload = token.split(".");
    if (tokenPayload.length < 2) return;

    if (!user.uid) {
      setAlert({show: true, message: "No user found", severity: Severity.Error})
    }
    else if (!user.email) {
      setAlert({show: true, message: "No email found", severity: Severity.Error})
    }
    else{
      setAlert({show: false,  message: "", severity: Severity.Error})
    }

    setIsNewTokenRequested(true);
    const resendPath = doc(resendVerificationEmailPath(user.uid))
    submitForm(resendPath, ActionType.Create, async () => await auth.signOut(), {email: user.email});
  }

  return (
    <>
      <Stack
        sx={{
          backgroundColor: theme.palette.secondary.main,
          zIndex: "-2!important",
          backgroundImage: `url("/assets/Artboard6.png")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Box sx={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 4,
          width: "100%"
        }}>
        </Box>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{height: "100vh"}}
        >

          <FormContainer>
            <Stack justifyContent="center" gap={2} textAlign="center">
              <Box
                sx={{
                  backgroundColor: theme.palette.neutral.light,
                  borderRadius: "50%",
                  alignSelf: "center",
                  display: "flex",
                  padding: 1,
                }}
              >
                <SystemIcons.Close stroke={theme.palette.error.main} width={48} height={48}/>
              </Box>
              <Typography variant="h2" mb={2}>Invalid Token</Typography>
              {alert.show && (
                <Alert
                  sx={{textAlign: "center", width: "100%", display: "flex", justifyContent: "center"}}
                  severity={alert.severity}
                  icon={false}
                >
                  <Typography variant="h5">{alert.message}</Typography>
                </Alert>
              )}
              <Stack>
                <Typography variant="body">{enCommonLabel.invalidVerificationToken}</Typography>
                <Typography variant="body">{enCommonLabel.requestNewVerification}</Typography>
              </Stack>
              <Button
                variant={isNewTokenRequested ? "contained" : "outlined"}
                onClick={!isNewTokenRequested ? requestVerificationEmail : () => navigate("/login")}
                sx={{
                  marginTop: 2,
                  borderRadius: 5,
                  color: isNewTokenRequested ? theme.palette.background.default : theme.palette.secondary.main,
                  borderColor: theme.palette.secondary.main,
                  backgroundColor: isNewTokenRequested ? theme.palette.secondary.main : "unset",
                  padding: 1,
                  "&:hover": {
                    color: isNewTokenRequested ? theme.palette.background.default : theme.palette.primary.main,
                  }
                }}
              >
                {!isNewTokenRequested ? enCommonButton.resentVerificationEmail
                  : alert.show ? enCommonButton.backToLogin : enCommonButton.successBackToLogin}
              </Button>
            </Stack>
          </FormContainer>
        </Stack>
        <Hidden smDown>
          <Box sx={{position: "absolute", bottom: "2%", right: "2%", zIndex: 2}}>
            <SwiftLogoOnly
              height={40}
              width={40}
              stroke={theme.palette.background.default}
              fill={theme.palette.background.default}
            />
          </Box>
        </Hidden>
      </Stack>
    </>
  )
}

export default TokenExpired;