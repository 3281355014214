import {PermissionEntity} from "types/Permission";

/**
 * The list of entities and their corresponding labels that should be displayed in the permissions table.
 */
export const PERMISSION_LABEL: Record<string, string> = {
  [PermissionEntity.Asset]: "Project Assets",
  [PermissionEntity.ProjectAssetAccessList]: "Asset Access List",
  [PermissionEntity.ProjectAssetImport]: "Import Asset Data",
  [PermissionEntity.ProjectAssetExport]: "Export Asset Data",
  [PermissionEntity.AssetFilesDownload]: "Download Files",
  [PermissionEntity.FileRequirement]: "File Requirement",
  [PermissionEntity.FileRequirementFilesDownload]: "Download Files",
  [PermissionEntity.Form]: "Form",
  [PermissionEntity.FormExport]: "Export Forms and Form Responses",
  [PermissionEntity.FormRequirement]: "Form Requirement",
  [PermissionEntity.Milestone]: "Milestone",
  [PermissionEntity.MilestoneFilesDownload]: "Download Files",
  [PermissionEntity.Organization]: "Organization",
  [PermissionEntity.OrganizationAsset]: "Organization Asset",
  [PermissionEntity.OrganizationInvite]: "Organization Invite",
  [PermissionEntity.OrganizationMember]: "Organization Member",
  [PermissionEntity.OrganizationTemplate]: "Organization Template",
  [PermissionEntity.OrganizationTemplateImport]: "Import Template",
  [PermissionEntity.OrganizationTemplateExport]: "Export Template",
  [PermissionEntity.OrganizationTimer]: "Organization Timer",
  [PermissionEntity.Project]: "Project",
  [PermissionEntity.ProjectAccessList]: "Project Access List",
  [PermissionEntity.ReferenceFile]: "Reference File",
  [PermissionEntity.SwiftFile]: "File",
  [PermissionEntity.Task]: "Tasks",
  [PermissionEntity.TaskAccessList]: "Task Access List",
  [PermissionEntity.TaskFilesDownload]: "Download Files",
  [PermissionEntity.Team]: "Team",
  [PermissionEntity.TeamCategory]: "Team Category",
  [PermissionEntity.TaskExport]: "Download Time Data",
  [PermissionEntity.ProjectImport]: "Import Project",
  [PermissionEntity.ProjectExport]: "Export Project",
}