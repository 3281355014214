import {browser} from "constants/browser";

const GLOBAL_NOT_PLAYABLE = [
  "asf",
  "avi",
  "m2ts",
  "m2v",
  "mpeg",
  "mpg",
  "mts",
  "ogv",
  "ts",
  "vob",
  "wtv",
  "wmv",
  "rm",
];

export const notPlayableMedia = {
  [browser.Chrome]: GLOBAL_NOT_PLAYABLE,
  [browser.Edge]: GLOBAL_NOT_PLAYABLE,
  [browser.Opera]: GLOBAL_NOT_PLAYABLE,
  [browser.InternetExplorer]: GLOBAL_NOT_PLAYABLE,
  [browser.Firefox]: [...GLOBAL_NOT_PLAYABLE, "webm"],
  [browser.Safari]: [...GLOBAL_NOT_PLAYABLE, "webm"],
}


