import Head from "./Head";
import {BaseProps} from "screens/BaseProps";
import {templateProjectsPath} from "screens/utility/FirebasePath";
import {useParams} from "react-router";
import {Stack} from "@mui/material";
import React from "react";
import TemplateProjectView from "components/TemplateProjectView";
import {Template} from "types/Template";

interface ProjectProps extends BaseProps {
  template: Template;
  projectsCount: number;
}

function Projects(props: ProjectProps) {
  const {toastProps, template, uid, projectsCount} = props;
  const {orgId, templateId} = useParams();

  if (!template) return null;

  return (
    <Stack gap={1} flex={1}>
      <Head projectsCount={projectsCount}/>
      <TemplateProjectView
        templateProjectCollectionRef={templateProjectsPath(orgId!, templateId!)}
        toastProps={toastProps}
        uid={uid}
      />
    </Stack>
  );
}

export default Projects;
