import {TimerState} from "types/Task";
import {Dispatch, useReducer} from "react";
import timerReducer from "components/FloatingTimer/useTimerReducer/timerReducer";
import {TimerActions} from "components/FloatingTimer/useTimerReducer/actions";
import {Timer} from "types/Timer";

export interface TimerReducerState {
  timerState: TimerState
  timer: Timer
  fromDb: boolean
}

const initialState: TimerReducerState = {
  timerState: TimerState.Stopped,
  timer: {
    timerState: TimerState.Stopped,
    lastTimerStartedDateTime: null,
    totalLoggedTimeInMs: 0
  } as Timer,
  fromDb: false
}

function useTimerReducer(): [state: TimerReducerState, dispatch: Dispatch<TimerActions>] {
  const [state, dispatch] = useReducer(timerReducer, initialState)

  return [state, dispatch]
}

export default useTimerReducer
