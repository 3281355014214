import {IconButton, SxProps, Theme} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {identifyEntity} from "../screens/utility/identifyEntity";
import {Entity} from "enums/entity";

interface BackProps {
  sx?: SxProps<Theme>;
  onBack?: () => void;
}

const Back = ({sx, onBack}: BackProps) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const pathParts = pathname.split("/");
  const entity = identifyEntity(pathname);

  function onPress() {
    if (onBack) {
      onBack();
      return;
    }

    switch (entity) {
      // if current path is projects, go back to project list
      case Entity.Project:
        navigate(pathParts.slice(0, 3).join("/"), {replace: true});
        break;
      // if current path is assets, go back to project overview
      case Entity.Asset:
        navigate(pathParts.slice(0, 4).join("/") + "/overview", {replace: true});
        break;
      // if current path is task, go back to asset overview
      case Entity.Task:
        navigate(pathParts.slice(0, 6).join("/"), {replace: true});
        break;
      // if current path is file or form requirement, go back to task overview
      case Entity.FileRequirement:
      case Entity.FormRequirement:
        navigate(pathParts.slice(0, 10).join("/"), {replace: true});
        break;
      // if current path is pl-task, go back to pl overview
      case Entity.PLTask:
        navigate(pathParts.slice(0, 4).join("/")+'/project-level-tasks', {replace: true});
        break;
      // if current path is pl-file or pl-form requirement, go back to pl-task overview
      case Entity.PLFileRequirement:
      case Entity.PLFormRequirement:
        navigate(pathParts.slice(0, 9).join("/"), {replace: true});
        break;
      default:
        navigate(-1);
        break;
    }
  }

  return (
    <IconButton sx={sx} onClick={onPress}>
      <BackIcon />
    </IconButton>
  );
};

export default Back;

const BackIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 12H5"
      stroke="#1D1D1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 19L5 12L12 5"
      stroke="#1D1D1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
