// @ts-nocheck
import {GroupedVirtuoso, VirtuosoHandle} from "react-virtuoso";
import {
  collection,
  CollectionReference,
  doc, getDocs,
  limit,
  orderBy,
  query, startAfter,
  where,
} from "firebase/firestore";
import React, {Dispatch, useCallback, useEffect, useMemo, useState} from "react";
import {ChatMessage} from "types/index";
import {EmptyList, InProgress} from "components/index";
import ChatItem from "../ChatItemsView/ChatItem";
import {sortObjectsBy} from "screens/utility";
import {Entity, MessageType} from "enums/index";
import ChatItemUnsent from "../ChatItemsView/ChatItemUnsent";
import DateTypo from "./DateTypo";
import {groupMessagesByDate} from "../utility/groupMessagesByDate";
import {emptyFunction} from "constants/index";
import {Box} from "@mui/system";
import {useCollection} from "hooks/index";
import {ChatSubscriber} from "types/ChatSubscriber";
import {auth, db} from "../../../firebase";
import theme from "theme/theme";

interface ChatViewProps {
  colRef: CollectionReference;
  initialMessages: ChatMessage[];
  lastSentMessage: ChatMessage | null;
  virtuosoRef: React.RefObject<VirtuosoHandle>;
  displayNewChatIndicator: boolean;
  setMessagesLength: Dispatch<number>;
  setDisplayNewChatIndicator: Dispatch<boolean>;
  setLastUnreadMessageIndex: Dispatch<number>;
  entity: Entity;
  lastDoc: any | null
}

const INITIAL_ITEM_COUNT = 20;

export default function ChatItemsView(props: ChatViewProps) {
  const {colRef, initialMessages, lastSentMessage, virtuosoRef, displayNewChatIndicator, entity, lastDoc} = props;
  const {setMessagesLength, setDisplayNewChatIndicator, setLastUnreadMessageIndex} = props;
  const [isFetchingHistory, setIsFetchingHistory] = useState<boolean>(false);
  const uid = auth.currentUser?.uid

  const [groupsCount, setGroupsCount] = useState<number[]>([]);
  const [groupDates, setGroupDates] = useState<string[]>([]);
  // this is the collection that we will be listening and our initial data
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  // this is to keep track if we have triggered auto scroll on intial load
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const documentReference = doc(db, (colRef.parent?.path)!)
  const subscribersColRef = collection(db, documentReference.path, entity !== Entity.ProjectLevelTask ? "/chatSubscribers" : "/PLChatSubscribers")
  const [subscribers] = useCollection<ChatSubscriber>(null, subscribersColRef);
  const [updatedLastDoc, setUpdatedLastDoc] = useState<any>(lastDoc)

  // set updated last doc if there is a last doc in collection
  useEffect(() => {
    setUpdatedLastDoc(lastDoc)
  }, [lastDoc]);

  // copy to chatMessage on first render or if there are changes in the initial chat messages
  useMemo(() => {
    if (!initialMessages) return;
    insertChatMessages(initialMessages, false);
  }, [initialMessages]);

  //generate groups with new chat messages
  useMemo(() => {
    if (!chatMessages) return;

    const [groupsCount, groups] = groupMessagesByDate(chatMessages);
    setGroupDates([...groups]);
    setGroupsCount([...groupsCount]);
  }, [chatMessages]);

  // everytime we have changes in our local chatMessages, copy it to parent
  useMemo(() => {
    setMessagesLength(chatMessages.length);
  }, [chatMessages.length]);

  // manually insert the last sent message to the list
  useEffect(() => {
    if (!lastSentMessage) return;
    insertChatMessages([lastSentMessage], false);
  }, [lastSentMessage]);

  useMemo(() => {
    if (!virtuosoRef.current || initialMessages.length < 2) return;

    if (isScrolled) return;

    virtuosoRef.current.scrollIntoView({index: initialMessages.length - 1, behavior: "smooth", align: "center"});
  }, [initialMessages, virtuosoRef.current]);

  const loadMoreHistory = useCallback((atTop: boolean) => {
    if (!atTop) return;

    if (initialMessages.length < 2) return;
    setIsFetchingHistory(true);
    fetchMessages(updatedLastDoc);
  }, [setChatMessages, chatMessages, JSON.stringify(updatedLastDoc)]);

  function insertChatMessages(chatList: ChatMessage[], prepend: boolean) {
    let newMessages = chatList.filter(chat => chat["@id"] && !chatMessages.some(msg => msg["@id"] === chat["@id"]));
    if (newMessages.length < 1) return;

    newMessages = sortObjectsBy<ChatMessage>(newMessages, "timestamp", "asc");
    if (prepend) {
      setChatMessages([...newMessages, ...chatMessages]);
      return;
    }

    setChatMessages([...chatMessages, ...newMessages]);
  }

  async function fetchMessages() {
    if (!updatedLastDoc) return;

    const historyQuery = query(colRef,
      where("type", "!=", MessageType.Typing),
      orderBy("type"),
      orderBy("timestamp", "desc"),
      startAfter(updatedLastDoc),
      limit(INITIAL_ITEM_COUNT)
    );

    const snapshot = await getDocs(historyQuery);
    const queryResult: ChatMessage[] = snapshot.docs.map(doc => doc.data() as ChatMessage);
    setUpdatedLastDoc(snapshot.docs[snapshot.docs.length - 1]);
    insertChatMessages(queryResult, true);
    setIsFetchingHistory(false)
  }

  function onDisplayItemContent(index: number) {
    const chatItem = chatMessages[index];

    const message = chatItem as ChatMessage;
    if (!message) return;

    const readers = subscribers ? subscribers.filter(subscriber => subscriber.lastMessageReadId === message["@id"] && subscriber["@id"] !== uid) : [];

    if (lastSentMessage !== null && lastSentMessage["@id"] === message["@id"]) {
      return (
        <ChatItemUnsent
          readers={readers.map(reader => ({user: reader, lastMessageReadId: reader.lastMessageReadId ?? null}))}
          key={`message_unsent_${message["@id"]}`}
          message={lastSentMessage}
          colRef={colRef}
          setSelectedChatAttachments={emptyFunction}
        />
      )
    }

    return <ChatItem
      readers={readers.map(reader => ({user: reader, lastMessageReadId: reader.lastMessageReadId ?? null}))}
      key={`message_${message["@id"]}`}
      colRef={colRef}
      isLastItem={index === chatMessages.length - 1}
      setSelectedChatAttachments={emptyFunction}
      message={message}
    />
  }

  function onBottomStateChange(bottom: boolean) {
    if (!bottom && initialMessages.length > 0 && !displayNewChatIndicator) {
      const latestMessage = initialMessages[0];
      const lastMessageIndex = chatMessages.findIndex(message => message["@id"] === latestMessage["@id"]);
      setLastUnreadMessageIndex(lastMessageIndex);
      setDisplayNewChatIndicator(true);
      return;
    }

    setDisplayNewChatIndicator(false);
  }

  function onDisplayGroupContent(index: number) {
    return (
      <DateTypo
        sx={{
          backgroundColor: theme.palette.background.default,
          px: 1
        }}
      >
        {Array.from(groupDates)[index]}
      </DateTypo>
    );
  }

  if (chatMessages.length < 1)
    return (
      <EmptyList
        typographyVariants={{title: "h3"}}
        entity={Entity.Chat}
        logoProperties={{
          stroke: "none"
        }}
      />
    );

  return (
    <>
      <GroupedVirtuoso
        style={{position: "relative"}}
        ref={virtuosoRef}
        groupCounts={groupsCount}
        groupContent={onDisplayGroupContent}
        itemContent={onDisplayItemContent}
        atTopStateChange={loadMoreHistory}
        onScroll={() => setIsScrolled(true)}
        components={{
          TopItemList: Box,
          Header: () => isFetchingHistory ? <InProgress/> : null,
          Footer: () => <>
            <Box sx={{width: "100%", height: 50}}></Box>
          </>
        }}
        atBottomStateChange={onBottomStateChange}
        atBottomThreshold={25}
      />
    </>
  )
};
