import {FormRequirement} from "types/FormRequirement";
import {BaseProps} from "screens/BaseProps";
import theme from "theme/theme";
import {Checkbox, Divider, Grid, Paper, Stack} from "@mui/material";
import React, {MouseEvent, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Entity, RequirementStatus} from "enums/index";
import {LongText, SelectStatus} from "components/index";
import {StatusItem} from "types/StatusItem";
import {getDocPath, getStatusOptions} from "../../utility";
import RequirementStatusObjectList
  from "assets/arrayList/StatusObjectList/RequirementStatusObjectList";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import {CollectionReference, doc} from "firebase/firestore";
import {ChatIconButton} from "components/Button";
import {AccessType, PermissionOperationKey} from "types/Permission";
import RightClickMenu from "../components/RightClickMenu";
import {enCommonLabel, enFormRequirementLabel} from "constants/index";
import {useRTDBDocField} from "hooks/index";

interface RequirementItemProps extends BaseProps {
  formRequirementsRef: CollectionReference;
  formRequirementAccess: AccessType | null;
  formRequirement: FormRequirement;
  isChecked: boolean;
  onCheckboxToggle: (checked: boolean, id: string) => void;
  openRequirementDrawer: (requirement: FormRequirement) => void;
  showCheckBox: boolean;
}

function RequirementItem(props: RequirementItemProps) {
  const {formRequirement, formRequirementAccess, formRequirementsRef, toastProps, isChecked, showCheckBox} = props;
  const {openRequirementDrawer, onCheckboxToggle} = props;

  const userDocPath = getDocPath(props.uid, formRequirementsRef.path, formRequirement["@id"] ?? "");
  const hasNewChat = useRTDBDocField<boolean>(userDocPath, "hasNewChat") ?? false;
  const chatEmpty = useRTDBDocField<boolean>(formRequirementsRef.path +"/"+formRequirement["@id"] ?? "", "chatEmpty") ?? true;

  const [isRequirementChecked, setIsRequirementChecked] = useState<boolean>(isChecked);
  const [selectedStatus, setSelectedStatus] = useState<StatusItem | undefined>(undefined);
  const [statusOption, setStatusOption] = useState<Record<string, StatusItem> | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorPosition, setAnchorPosition] = useState<{ top: number, left: number }>({top: 0, left: 0})

  const canUpdateStatus = Boolean(formRequirementAccess?.[PermissionOperationKey.OverrideStatus]) || Boolean(formRequirementAccess?.[PermissionOperationKey.ChangeStatus]);

  const nav = useNavigate();

  const anchorEl = useRef(null)

  // once the component is rendered, set the fileRequirement status options
  useEffect(() => {
    const [options, selected] = getStatusOptions(RequirementStatusObjectList, formRequirement.formRequirementStatus);
    setStatusOption(options);
    setSelectedStatus(selected);
  }, [formRequirement.formRequirementStatus]);

  useEffect(() => {
    setIsRequirementChecked(isChecked);
  }, [isChecked]);

  function getBorderColor() {
    if (formRequirement.formRequirementStatus === RequirementStatus.HasRejection)
      return theme.palette.error.main;

    if (isRequirementChecked)
      return theme.palette.primary.main;

    return theme.palette.divider;
  }

  function onCheckboxChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    onCheckboxToggle(checked, formRequirement["@id"] ?? "");
    setIsRequirementChecked(checked);
  }

  function onChatIconButtonClick(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    openRequirementDrawer(formRequirement);
  }

  function onCardClick() {
    nav(`./form-requirements/${formRequirement["@id"]!}`);
  }

  function onCardRightClick(e: MouseEvent<HTMLElement>) {
    setAnchorPosition({top: e.clientY, left: e.clientX});
    e.preventDefault();
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <>
      <RightClickMenu
        filesCount={0}
        uid={props.uid}
        anchorPosition={anchorPosition}
        isOpen={isMenuOpen}
        anchorEl={anchorEl.current}
        onClose={() => setIsMenuOpen(false)}
        formRequirement={formRequirement}
        toastProps={toastProps!}
        collectionRef={formRequirementsRef}
        fromTemplates={!!formRequirement?.templateId}
      />
      <Stack direction="row" alignItems="center">
        {showCheckBox &&
          <Checkbox
            checked={isRequirementChecked}
            onClick={(e) => {
              e.stopPropagation();
            }}
            sx={{
              "&.Mui-disabled": {
                cursor: "not-allowed",
                pointerEvents: "unset"
              }
            }}
            title={!!formRequirement.templateId ? enFormRequirementLabel.cannotDeleteWithTemplate: ""}
            disabled={!!formRequirement.templateId}
            onChange={onCheckboxChange}
          />
        }
        <Paper
          ref={anchorEl}
          onContextMenu={onCardRightClick}
          sx={{
            position: "relative",
            cursor: "pointer",
            boxShadow: "none",
            outline: `1px solid ${theme.palette.neutral.light}`,
            "&:hover": {
              outlineColor: theme.palette.primary.main,
            },
            flex: 1
          }}
          onClick={onCardClick}
        >
          <Stack
            className="form-requirement-item"
            id={`file-requirement-item-${formRequirement["@id"]!}`}
            direction="row"
            borderRadius={1}
            border={1}
            sx={{
              border: `1px solid ${getBorderColor()}`,
              cursor: "pointer",
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Grid item sx={{alignSelf: "stretch"}}>
              {statusOption !== null && (
                <SelectStatus
                  toastProps={toastProps!}
                  documentRef={doc(formRequirementsRef, formRequirement["@id"] ?? "defaultId")}
                  statusField="formRequirementStatus"
                  displayIconOnSelected={true}
                  displayTextOnSelected={false}
                  items={statusOption}
                  isDisabled={!canUpdateStatus}
                  selected={selectedStatus}
                  selectSx={{
                    height: "100%",
                    width: "100%",
                    minHeight: "100%",
                    border: "none",
                    "&:disabled": {
                      border: "none"
                    },
                    "&:hover": {
                      border: "none",
                      backgroundColor: "transparent"
                    },
                  }}
                  width="50px"
                />
              )}
            </Grid>
            <Divider orientation="vertical" flexItem sx={{marginRight: 1}}/>
            <Stack flex={1} direction="column" py={1} gap={1 / 2} alignItems="flex-start" justifyContent="center">
              <LongText
                variant="h4"
                maxWidth="100%"
                overflowWrap={"anywhere"}
              >
                {formRequirement.name}
              </LongText>
              <LongText
                textColor="text.secondary"
                variant="bodySmall"
                maxWidth="100%"
                overflowWrap={"anywhere"}
              >
                {!!formRequirement.description ? formRequirement.description : enCommonLabel.noDescription}
              </LongText>
              <DataTagsDisplay
                uid={props.uid}
                dataTagsIds={formRequirement.dataTagsIds || []}
                toastProps={toastProps!}
                listSize="medium"
                canEditDocumentRef={Boolean(formRequirementAccess?.[PermissionOperationKey.Update])}
                documentRef={doc(formRequirementsRef, formRequirement["@id"]!)}
              />
            </Stack>
            <Divider orientation="vertical" sx={{marginX: 1}} flexItem/>
            <Stack direction="row" justifyContent="flex-end" py={0} paddingRight={2} alignItems="center">
              <ChatIconButton
                id={`${formRequirement["@id"]}-form-requirement-item-chat-button`}
                entity={Entity.FormRequirement}
                withNewMessage={hasNewChat}
                chatEmpty={chatEmpty}
                onClickFcn={onChatIconButtonClick}
              />
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </>
  )
}

export default RequirementItem;
