import {AccessListUser} from "types/AccessListUser";
import {CollectionReference, doc} from "firebase/firestore";
import {BaseProps} from "screens/BaseProps";
import {Stack} from "@mui/material";
import BulkListHeader from "./BulkListHeader";
import React, {Dispatch, useContext, useEffect, useState} from "react";
import {SelectedItem} from "components/Drawers/AddTeamOrPeopleDrawer";
import {AccessRole, ActionType, ProcessType, Severity} from "enums/index";
import {enCommonLabel, enCommonManageAccess} from "constants/index";
import DeleteDialog from "components/Dialogs/DeleteDialog";
import ListItem, {AccessListUserWithPermission} from "./ListItem";
import {AccessRoleMenuType} from "./AccessRoleMenu";
import {AccessType} from "types/Permission";
import {bulkStatusSubmitHandler, StatusSubmitHandler} from "screens/utility/statusSubmitHandler";
import {SelectedOrgContext} from "screens/SelectedOrgContextProvider";
import templateSubmitForm from "screens/utility/templateSubmitForm";

interface TeamsListProps extends BaseProps {
  teams: AccessListUser[] | null;
  collectionRef: CollectionReference;
  access: AccessType;
  allowSelect: boolean;
  allowRemove: boolean;
  selectedTeams: SelectedItem[];
  setSelectedTeams: Dispatch<SelectedItem[]>;
  setProcessedTeamMessage?: Dispatch<string>;
}

interface StringBooleanDictionary {
  [key: string]: boolean
}

enum DeleteMode {
  single,
  multiple
}

function TeamsList(props: TeamsListProps) {
  const {teams, allowRemove, allowSelect, collectionRef, access, toastProps, selectedTeams, setSelectedTeams, setProcessedTeamMessage} = props;
  const {setToastMessage, setIsToastOpen, setToastSeverity} = toastProps!;

  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [localTeams, setLocalTeams] = useState<AccessListUserWithPermission[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // delete states
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [deleteMode, setDeleteMode] = useState<DeleteMode>(DeleteMode.single);
  const [deleteItem, setDeleteItem] = useState<AccessListUser | null>(null);
  const [processingItems, setProcessingItems] = useState<StringBooleanDictionary>({});

  const [deleteLength, setDeleteLength] = useState<number>(0);
  const [deleteSubmittedData, setDeleteSubmittedData] = useState<any[]>([]);

  const selectedOrgContext = useContext(SelectedOrgContext);
  const {selectedOrg} = selectedOrgContext!;

  // only trigger bulkstatusdelete when deleteSubmittedData changes
  useEffect(() => {
    if (deleteSubmittedData.length === 0 || deleteSubmittedData.length !== deleteLength) return;

    bulkStatusSubmitHandler({
      data: deleteSubmittedData,
      successCallback: () => successCallback(enCommonManageAccess.deleteSuccess("team/s")),
      errorCallback: () => errorCallback(ProcessType.Delete)
    });
    setDeleteSubmittedData([]);
    setDeleteLength(0);
  }, [deleteSubmittedData]);

  // create a local copy for the teams list with additional canEdit and canDelete fields
  useEffect(() => {
    if (!teams) return;
    const newTeams = teams.map((team) => {
      // @ts-ignore
      const canEditPermission = access[`Manage${team.role?.replace(" ", "")}`];
      // @ts-ignore
      const canDeletePermission = access[`Manage${team.role?.replace(" ", "")}`];

      return {
        ...team,
        canEdit: canEditPermission,
        canDelete: canDeletePermission,
      }
    });

    setLocalTeams(newTeams);
  }, [JSON.stringify(teams), JSON.stringify(access)]);

  // update state for list checkbox (topmost) based on the selected updates
  useEffect(() => {
    if (localTeams.length === 0) return;
    const editablePeople = localTeams.filter(team => team.canEdit && team.canDelete);
    setIsAllSelected(editablePeople.length === selectedTeams.length);
  }, [JSON.stringify(selectedTeams)]);

  async function triggerBulkEdit(selected: AccessRole) {
    setIsLoading(true);

    const selectedCopy = chooseRoleBulk(selected);
    const selectedIds = {
      ...selectedCopy.reduce((acc, team) => {
        const teamMemberUids = teams?.filter(orgTeam => orgTeam["@id"] === team["@id"])[0]?.teamMemberUids ?? [];
        setProcessingItems((prev: StringBooleanDictionary) => ({...prev, [team["@id"]]: true}));
        return {
          ...acc,
          [team["@id"]]: {
            role: selected,
            teamMemberUids
          }
        };
      }, {})
    };

    let submittedData: StatusSubmitHandler<any>[] = [];

    await Promise.all(
      selectedCopy.map(async (team) => {
        const teamRef = doc(collectionRef, team["@id"]);
        const formData = {
          role: team.role,
          bulkActions: selectedIds
        }
        await templateSubmitForm(teamRef.path, ActionType.Update,
          (status, data, isLastUpdate) => {
            if (isLastUpdate) {
              submittedData.push({status, data, isLastUpdate});
              setProcessingItems((prev: StringBooleanDictionary) => ({...prev, [team["@id"]]: false}));
            }
          },
          formData
        );
      })
    );

    bulkStatusSubmitHandler({
      data: submittedData,
      successCallback: () => successCallback(enCommonManageAccess.editSuccess("team/s")),
      errorCallback: () => errorCallback(ProcessType.Update)
    });
  }

  function successCallback(message: string) {
    setIsLoading(false);
    setSelectedTeams([]);
    setDeleteItem(null);
    setIsDeleteDialogOpen(false);

    setProcessedTeamMessage?.(message);
  }

  function errorCallback(process: ProcessType) {
    setToastSeverity(Severity.Error);
    setToastMessage(enCommonLabel.errorProcess(process));
    setIsToastOpen(true);
    setIsLoading(false);
    setSelectedTeams([]);
    setDeleteItem(null);
    setIsDeleteDialogOpen(false);
  }

  function chooseRoleBulk(selected: AccessRole) {
    const selectedCopy = selectedTeams.map(team => ({...team, role: selected}));
    const teamsCopy = (localTeams ?? []).map(team => {
      return {...team, role: !allowSelect && !allowRemove ? undefined : team.role};
    });

    setLocalTeams(teamsCopy);
    setSelectedTeams(selectedCopy);

    return selectedCopy;
  }

  function triggerMultipleDelete() {
    setDeleteMode(DeleteMode.multiple);
    setIsDeleteDialogOpen(true);
  }

  function selectAllTeams() {
    if (isAllSelected) {
      // delete everything
      setSelectedTeams([]);
      return;
    }

    const editableTeams = localTeams.filter(team => team.canEdit && team.canDelete);

    const newSelectedTeams = editableTeams.map(team => {
      const obj: SelectedItem = {
        "@id": team["@id"],
        role: allowSelect && allowRemove ? team.role : undefined
      };

      return obj;
    });

    setSelectedTeams(newSelectedTeams);
  }

  async function removeSelectedTeams() {
    if(!localTeams) return;

    const toDeleteTeam = deleteMode === DeleteMode.single ? [deleteItem!]
      : selectedTeams.filter(team =>
        localTeams.filter(member => member["@id"] === team["@id"] && member.canDelete).length > 0);
    const teamIds = Object.fromEntries(
      toDeleteTeam.map(team => {
        setProcessingItems((prev: StringBooleanDictionary) => ({...prev, [team["@id"]]: true}));

        const teamMemberUids = teams?.filter(orgTeam => orgTeam["@id"] === team["@id"])[0]?.teamMemberUids ?? [];
        return [team["@id"], {role: team.role, teamMemberUids}]
      })
    );

    setDeleteLength(toDeleteTeam.length);
    await Promise.all(
      toDeleteTeam.map(async (team) => {
        const teamRef = doc(collectionRef, team["@id"]);
        return await templateSubmitForm(teamRef.path, ActionType.Delete,
          (status, data, isLastUpdate) => {
            isLastUpdate && setDeleteSubmittedData(prev => [...prev, {status, data, isLastUpdate}])
          },
          {bulkActions: {...teamIds}});
      })
    );
  }

  function triggerSingleDelete(item: AccessListUser) {
    setDeleteMode(DeleteMode.single);
    setDeleteItem(item);
    setIsDeleteDialogOpen(true);
  }

  function updateSelectedList(id: string, checked: boolean, role?: AccessRole) {
    let selectedCopy = selectedTeams.map(team => ({...team}));

    selectedCopy = selectedCopy.filter(team => team["@id"] !== id);
    if (checked) {
      selectedCopy = [...selectedCopy, {"@id": id, role}];
    }

    setSelectedTeams(selectedCopy);
  }

  if (!teams || !selectedOrg) return null;

  return (
    <Stack direction="column" id="people-list">
      <BulkListHeader
        listLength={teams.length}
        isAllSelected={isAllSelected}
        selectedItems={selectedTeams}
        access={access}
        isLoading={isLoading}
        triggerBulkEdit={triggerBulkEdit}
        triggerMultipleDelete={triggerMultipleDelete}
        selectAll={selectAllTeams}
        type={AccessRoleMenuType.Team}
      />
      {localTeams.map((team, index) => {
        const selected = (selectedTeams.filter(member => team["@id"] === member["@id"]).length > 0);
        return <ListItem
          uid={props.uid}
          collectionRef={collectionRef}
          key={`people-${index}`}
          allowSelect={allowSelect}
          allowRemove={allowRemove}
          member={team as AccessListUserWithPermission}
          isSelected={selected}
          isLoading={(selected && isLoading)|| processingItems[team["@id"]]}
          access={access}
          deleteSelected={triggerSingleDelete}
          setSelected={updateSelectedList}
          toastProps={toastProps}
          type={AccessRoleMenuType.Team}
          teamMemberUids={team.teamMemberUids ?? []}
        />
      })}
      <DeleteDialog
        overlayId="delete-selected-dialog"
        confirmButtonId={`teams-list-confirm-delete`}
        cancelButtonId={`teams-list-cancel-delete`}
        isOpen={isDeleteDialogOpen}
        title={enCommonManageAccess.deleteXTeamsTitle(
          deleteMode === DeleteMode.multiple ? selectedTeams.length
            : !deleteItem ? "" : deleteItem.name)}
        text={deleteMode === DeleteMode.multiple ? enCommonManageAccess.deleteMemberSubtitle
          : enCommonManageAccess.deleteSingleMemberSubtitle}
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleConfirm={removeSelectedTeams}
      />
    </Stack>
  )
}

export default TeamsList;