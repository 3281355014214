import theme from "theme/theme";
import {TextField, TextFieldProps} from "@mui/material";
import React from "react";

type StandardInputProps = Partial<TextFieldProps> & {
  id?: string;
  type: "text" | "password" | "number";
}

function StandardInput(props: StandardInputProps) {
  const {id = 'standard-input'} = props;

  return (
    <TextField
      id={id}
      {...props}
      sx={{
        "& .MuiInputBase-root": {
          padding: 0.2,
          color: theme.palette.secondary.main,
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: theme.palette.secondary.main,
        },
        "& .MuiInputBase-root:after": {
          borderBottom: theme.palette.secondary.main,
        },
      }}
    />
  );
}

export default StandardInput;