import {useEffect, useState} from "react";
import {where} from "firebase/firestore";
import {Organization} from "types/Organization";
import {Entity, DirectionalOrder} from "enums/index";
import {EmptyList, InProgress} from "components";
import useCollection from "hooks/useCollection";
import {BaseProps} from "screens/BaseProps";
import {Box} from "@mui/material";
import SmartSearchInput from "components/inputs/SmartSearchInput";
import {enOrganizationLabel} from "constants/index";
import {algoliaOrganizationColPath} from "../../utility/algoliaColPath";
import DynamicView from "./DynamicView";
import {organizationsPath, sortObjectsBy} from "../../utility";
import SortMenu, {SortValue, SortType} from "../SortMenu";

interface OrganizationsViewProps extends BaseProps {
  updateSelected: (obj: Organization | null) => void;
  deleteOrg: (org: Organization) => void;
  fromSAP?: boolean;
}

function OrganizationsView(props: OrganizationsViewProps) {
  const {setSelectedOrgId, updateSelected, ...rest} = props;

  const [isMounted, setIsMounted] = useState(true);
  const [sortOrder, setSortOrder] = useState<SortType>({field: "name", value: SortValue.NameAsc, order: DirectionalOrder.asc});
  const [organizations] = useCollection<Organization>(null, organizationsPath(), [where("@allowedUsers", "array-contains", props.uid)], true, false, isMounted);

  const [algoliaOrganizationsList, setAlgoliaOrganizationsList] = useState<Organization[] | null>(null);
  const [displayedOrgs, setDisplayedOrgs] = useState<Organization[]>([]);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (algoliaOrganizationsList) {
      setDisplayedOrgs(algoliaOrganizationsList);
      return;
    }
    // @ts-ignore
    const sortedOrgs = sortObjectsBy(organizations || [], sortOrder.field, sortOrder.order);
    setDisplayedOrgs(sortedOrgs);
  }, [algoliaOrganizationsList, organizations]);

  useEffect(() => {
    const copyDisplayedOrgs = [...displayedOrgs];
    // @ts-ignore
    const sortedDisplayedOrgs = sortObjectsBy(copyDisplayedOrgs, sortOrder.field, sortOrder.order);
    setDisplayedOrgs([...sortedDisplayedOrgs]);
  }, [sortOrder]);

  function handleAlgoliaResultsCallback(results: Organization[] | null) {
    setAlgoliaOrganizationsList(results);
  }

  if (organizations && organizations.length === 0)
    return <EmptyList entity={Entity.Organization}/>;

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        justifyContent="space-between"
        mt={{sm: 1, md: 2}}
        gap={1}
      >
        <SmartSearchInput
          id="search-org-field"
          placeholder={enOrganizationLabel.search}
          colPath={algoliaOrganizationColPath()}
          resultCallback={handleAlgoliaResultsCallback}
        />
        <SortMenu
          sortValue={sortOrder.value}
          fromSAP={props.fromSAP}
          changeOrder={setSortOrder}
        />
      </Box>
      {organizations === null ? <InProgress/>
        : (
          <DynamicView
            setSelectedOrgId={setSelectedOrgId}
            updateSelected={updateSelected}
            organizations={algoliaOrganizationsList ? algoliaOrganizationsList : displayedOrgs}
            fromAlgolia={algoliaOrganizationsList !== null}
            parentPath={organizationsPath().path}
            {...rest}
          />
        )}
    </>
  )
}

export default OrganizationsView;
