import {areObjectsEqual} from "../utility";
import {MilestoneAssignedStatus} from "enums/milestoneAssignedStatus";
import {ExportTaskType} from "enums/exportTaskType";
import formatDateToYYYYMMDD from "../utility/formatDateToYYYYMMDD";
import {MilestoneScheduleStatus} from "enums/milestoneScheduleStatus";
import {FiltersState} from "./TaskSearchAndFilters";
import {algoliaIndex} from "../../algoliaConfig";
import {Data} from "hooks/useCollectionData";
import {milestoneTaskandAssetStatus as TaskStatus} from "enums/milestoneTaskandAssetStatus";

const DefaultSelectedOrg = {
  id: "allOrgs",
  title: "All Organizations",
}

export interface TaskData extends Data {
  taskStatus: TaskStatus,
  milestoneId: string | undefined,
  tasksCount: number | undefined,
  taskId?: string,
  orgTaskRefPath?: string | undefined,
}

export const InitialFilterState: FiltersState = {
  searchText: "",
  status: [],
  assigned: [],
  schedule: [],
  date: undefined,
  exportTasksType: ExportTaskType.AllTasks,
  selectedOrg: DefaultSelectedOrg,
}

const index = algoliaIndex;

interface SearchResult {
  result: any[] | null | undefined,
  filters: {
    searchText: string,
    facetFilters: any[],
    numericFilters: any[],
  }
}

export async function queryAlgolia(filters: FiltersState, uid: string, pathFilters: any[], queryOnLoad: boolean = false, includeTemplate: boolean = false)
  : Promise<SearchResult> {
  const isFilterUnchanged = areObjectsEqual(InitialFilterState, filters);

  // if filter is unchanged, clear algolia results
  if (isFilterUnchanged && !queryOnLoad) {
    return {result: null, filters: {searchText: "", facetFilters: [], numericFilters: []}};
  }

  let algoliaFilters: any = [
    ...pathFilters,
    `userIds:${uid}`,
    ...(filters.selectedOrg.id !== "allOrgs" ? [`orgId:${filters.selectedOrg.id}`] : []),
  ];

  // filter by taskStatus
  const taskStatusFilters: string[] = [];
  filters.status.forEach((status) => taskStatusFilters.push(`taskStatus:${status}`));

  const assignedFilters: string[] = [];
  filters.assigned.forEach(assignee => {
    switch (assignee) {
      case MilestoneAssignedStatus.ToNoOne:
        assignedFilters.push(`unassigned:true`);
        break;
      case MilestoneAssignedStatus.ByMe:
        assignedFilters.push(`userId:${uid!}`);
        break;
      case MilestoneAssignedStatus.ToAnyone:
        // no filter needed
        break;
      case MilestoneAssignedStatus.ToMyTeam:
        assignedFilters.push(`assignedTo.isTeam:true`);
        assignedFilters.push(`assignedTo.teamMemberUids:${uid!}`);
        break;
      case MilestoneAssignedStatus.ToMe:
        assignedFilters.push(`assignedTo.uid:${uid!}`);
        break;
      case MilestoneAssignedStatus.ImTheApprover:
        assignedFilters.push(`approver.id:${uid}`);
        assignedFilters.push(`approver.teamMemberUids:${uid!}`);
        break;
      case MilestoneAssignedStatus.ImTheObserver:
        assignedFilters.push(`observer.id:${uid}`);
        assignedFilters.push(`observer.teamMemberUids:${uid!}`);
        break;
      case MilestoneAssignedStatus.FavoriteTasks:
        assignedFilters.push(`favoriteUserIds:${uid}`);
        break;
    }
  });

  let numericFilters: any[] = [];

  // filter by scheduleStatus
  const formattedDate = formatDateToYYYYMMDD(new Date());
  let newFormattedDate = formattedDate as unknown as number;

  filters.schedule.forEach(status => {
    switch (status) {
      case MilestoneScheduleStatus.PastDue:
        const dueFilter: string[] = [];
        if (filters.date !== undefined && (filters.date as unknown as number) < newFormattedDate) {
          newFormattedDate = filters.date as unknown as number;
        }

        if (filters.schedule.includes(MilestoneScheduleStatus.Upcoming)) {
          if (filters.date !== undefined && (filters.date as unknown as number) < newFormattedDate) {
            newFormattedDate = filters.date as unknown as number;
          }

          dueFilter.push(`algoliaDueDate < ${newFormattedDate as unknown as string}`);
          dueFilter.push(`algoliaDueDate >= ${newFormattedDate}`);

          numericFilters.push(dueFilter);
          numericFilters.push(`algoliaDueDate > 0`);
          break;
        }

        numericFilters.push(`algoliaDueDate < ${newFormattedDate as unknown as string}`);
        if (!filters.schedule.includes(MilestoneScheduleStatus.NotScheduled))
          numericFilters.push(`algoliaDueDate > 0`);
        break;
      case MilestoneScheduleStatus.Upcoming:
        if (filters.schedule.includes(MilestoneScheduleStatus.PastDue)) break;

        if (filters.date !== undefined && (filters.date as unknown as number) < newFormattedDate) {
          newFormattedDate = filters.date as unknown as number;
        }

        const upcomingFilter: string[] = [];
        if (filters.schedule.includes(MilestoneScheduleStatus.NotScheduled)) {
          upcomingFilter.push(`algoliaDueDate = 0`);
          upcomingFilter.push(`algoliaDueDate >= ${newFormattedDate}`);
          numericFilters.push(upcomingFilter);
          break;
        }

        numericFilters.push(`algoliaDueDate >= ${newFormattedDate}`);
        break;
      case MilestoneScheduleStatus.Scheduled:
        if (filters.schedule.length > 1) break;

        numericFilters.push(`algoliaDueDate > 0`);
        break;
      case MilestoneScheduleStatus.Complete:
        if (!taskStatusFilters.find((filter: string) => filter === `taskStatus:${TaskStatus.Approved}`)) {
          taskStatusFilters.push(`taskStatus:${TaskStatus.Approved}`);
        }
        break;
    }
  });

  // filter by Due Date if schedule does not include Past Due or Upcoming
  if (filters.date !== undefined) {
    if (!filters.schedule.includes(MilestoneScheduleStatus.PastDue)) {
      numericFilters.push(`algoliaDueDate = ${filters.date}`);
    }
  }

  index.transporter.responsesCache.clear();

  // consolidate filters
  if (!includeTemplate) {
    taskStatusFilters.length > 0 && algoliaFilters.push(taskStatusFilters);
    assignedFilters.length > 0 && algoliaFilters.push(assignedFilters);
  } else if (taskStatusFilters.length > 0 && includeTemplate) {
    const newFilter = taskStatusFilters.filter(status => status !== `taskStatus:${TaskStatus.Pending}`);
    if (newFilter.length > 0) newFilter.forEach(status => algoliaFilters[0].push(status));
  }

  const result = await index.search(filters.searchText, {
    hitsPerPage: 1000,
    facetFilters: [...algoliaFilters],
    numericFilters: [...numericFilters],
  }).then(({hits}) => {
    const algoliaResults = hits as unknown as any[];
    // manually filter hidden tasks
    return algoliaResults.filter(res => !res.hidden).map((result, index) => ({...result, index}));
  });

  return {result, filters: {searchText: filters.searchText, facetFilters: [...algoliaFilters], numericFilters: [...numericFilters]}};
}