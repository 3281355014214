import {StatusIcons} from "assets/icons/status/status.index";
import theme from "theme/theme";
import {en} from "language/en";
import {StatusItem} from "assets/arrayList/ProjectStatus";

const enTask = en.screen.Task.status;

const rejected: StatusItem = {
  title: enTask.rejected,
  icon: StatusIcons.Rejected,
  color: theme.palette.error.main,
  checked: false,
}

export default rejected