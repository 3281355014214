export const forms = {
  label: {
    importError: "An error has occured while importing form: Invalid Form",
    importSuccess: "Form imported successfully",
    emptyImportData: "Selected file has no form field data",
    atleastOneField: "Atleast one (1) field is required.",
    time: "Time",
    included: "Included",
    disablePreviousDates: "Disable Previous Dates",
    deleteError: `An error has occured while deleting data tag.`,
    deleteSuccess: "Data tag has been deleted successfully",
    noPermission: "You have no permission to do this action.",
    forms: "Forms",
    emptyTitle: "No Forms yet",
    emptyDescription: "Create the first form.",
    createForm: "Create Form",
    editForm: "Edit Form",
    editingForm: "Editing Form",
    formTitle: "Form Title",
    formDescription: "Form Description",
    createDataTags: "Create Data Tags",
    fillBeforeAdd: "Fill all required text fields before adding a new Field Type.",
    searchForms: "Search Form...",
    responseRequired: "Response is required",
    fieldType: "Field Type:",
    minimumCharacters: "Minimum characters",
    maximumCharacters: "Maximum characters",
    maximumCharactersError: "Maximum characters must be greater than Minimum character",
    includeTime: "Include time",
    selectMultiple: "Select more than one",
    listItems: "List Items",
    createError: (message: string) => `An error has occured while creating a form. ${message}`,
    updateError: (message: string) => `An error has occured while updating a form. ${message}`,
    formCreated: "Successfully created a form",
    formUpdated: "Successfully updated a form",
    allowDecimal: "Allow Decimal Value",
    deleteFieldConfirmationTitle: "Are you sure you want to delete this field?",
    deleteFieldConfirmationText: "You can still restore this field after deletion by  click the button \"Clear all changes\"",
    deleteFormConfirmationTitle: "Are you sure you want to delete this form?",
    deleteFormConfirmationText: "You can't go back after deleting. This action is irreversible.",
    deleteFormSuccess: "Form deleted successfully.",
    selectedItemError: "An item must be selected",
    timeIsRequired: "Time is required",
    invalidInputValue: "Invalid input value",
    wholeNumberError: "Input value must be a whole number",
    minCharsError: (min: number) => `This field requires a minimum of ${min} characters`,
    maxCharsError: "Input value is greater than the allowed maximum characters",
    deleteFormError: (message: string) => `An error has occured while deleting a form. ${message}`,
    uniqueNameError: "Name must be unique",
    exportingFormTitle: "Exporting Form",
    exportingFormResponsesTitle: "Exporting Form Responses",
    exportFormText: "Exporting all form data",
    exportFormResponsesText: "Exporting all form responses from form requirements",
    exportNoLiveData: "You cannot export something that isn't created!",
    bulkDeleteSuccess: (x: number) => `${x} reference files deleted successfully`,
  },
  button: {
    createNewForm: "Create New Form",
    clearChanges: "Clear Changes",
    addField: "Add Field",
    advancedOptions: "Advanced Options",
    newItem: "New Item",
    importForm: "Import Form",
    exportForm: "Export Form",
    exportFormResponses: "Export Form Responses",
    deleteForm: "Delete Form",
  }
}