import {User} from "types/User";
import {Checkbox, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {SwiftIconButton} from "components/Button";
import {ACCESS_ROLE_LABEL, enUserLabel} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import React, {Dispatch, useEffect, useState} from "react";
import {Avatar} from "components/index";
import OrgsList from "./OrgsList";

interface UserCardProps {
  user: User;
  checkedUsers: string[];
  setCheckedUsers: Dispatch<string[]>;
  viewUserOverview: Dispatch<User>;
  editUser: Dispatch<User>;
  deactivateUser: () => void;
}

function UserCard(props: UserCardProps) {
  const {user, checkedUsers, setCheckedUsers, viewUserOverview, editUser, deactivateUser} = props;
  const {"@id": id = "fillerId", name, organizations = [], email} = user;
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsChecked(checkedUsers.includes(id));
  }, [checkedUsers]);

  function onCardSelect(e: React.ChangeEvent, checked: boolean) {
    if (checked) {
      setCheckedUsers([...checkedUsers, id]);
      return;
    }

    setCheckedUsers(checkedUsers.filter((userId) => userId !== id));
  }

  function onCardClick(e: React.MouseEvent) {
    e.stopPropagation();
    viewUserOverview(user);
  }

  return (
    <Stack
      key={`user-card-${id}`}
      padding={1}
      sx={(theme) => ({
        border: `1px solid ${isChecked ? theme.palette.primary.main : theme.palette.divider}`,
        borderRadius: 1,
        cursor: "pointer",
        ":hover": {
          border: `1px solid ${theme.palette.primary.main}`,
        },
      })}
    >
      <Stack direction="row" gap="16px" justifyContent="start" width="100%" alignItems="center">
        <Checkbox
          onChange={onCardSelect}
          checked={checkedUsers.find((userId) => userId === id) !== undefined}
        />
        <Avatar
          isTeam={false}
          {...user}
          sx={{
            height: 40,
            width: 40,
            mt: 0,
          }}
        />
        <Stack
          direction="row"
          gap="16px"
          flex={1}
          alignItems="center"
          justifyItems="start"
          onClick={onCardClick}
        >
          <Stack gap={1}>
            <Stack direction="column" alignItems="start">
              <Typography variant="h4">
                {name}
              </Typography>
              <Typography variant="h5" color="text.secondary">
                {email}
              </Typography>
            </Stack>
            <OrgsList organizations={organizations}/>
          </Stack>
        </Stack>
        <Stack direction="row" width="50%" justifyContent="center" onClick={onCardClick}>
          <Typography variant="body" align="left">
            {organizations.length === 1 ? ACCESS_ROLE_LABEL[organizations[0].accessRole] : ""}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <SwiftIconButton
            onClickFcn={() => editUser(user)}
            disabled={false}
            title={enUserLabel.editUser}
          >
            <SystemIcons.Edit
              stroke={theme.palette.secondary.main}
            />
          </SwiftIconButton>
          <SwiftIconButton
            onClickFcn={deactivateUser}
            disabled={false}
            title={enUserLabel.deactivateUser}
          >
            <SystemIcons.Close
              stroke={theme.palette.error.main}
            />
          </SwiftIconButton>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default UserCard;
