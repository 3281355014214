import {Box, IconButton} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {emptyFunction, enAssetLabel, enCommonLabel, enProjectLabel} from "constants/index";
import {PilledNavigationBar} from "components/index";
import HeaderMoreOptionsMenu from "./HeaderMoreOptionsMenu";
import React, {useState} from "react";
import {Asset} from "types/index";
import {useComponentToggler} from "hooks/index";
import {organizationAssetsPath, submitForm} from "../utility";
import EditAssetDrawer from "components/Drawers/EditAssetDrawer";
import {BaseProps} from "../BaseProps";
import {useParams} from "react-router";
import ConfirmDialog from "components/ConfirmDialog";
import {doc} from "firebase/firestore";
import {ActionType, ProcessType, Severity} from "enums/index";
import {useNavigate} from "react-router-dom";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {SwiftIconButton} from "components/Button";
import {PillItemProps} from "components/PilledNavBar/PillItem";
import {SidebarIcons} from "assets/icons/menu/menu.index";
import {statusSubmitHandler} from "../utility/statusSubmitHandler";

interface HeadProps extends BaseProps {
  asset: Asset | null;
  access: AccessType | null;
}

function Head(props: HeadProps) {
  const {asset, toastProps, access, uid} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  const {orgId} = useParams();
  const navigate = useNavigate();

  const [isActionsShowing, setIsActionsShowing] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isDrawerOpen, {open: openDrawer, close: closeDrawer}] = useComponentToggler(false);

  const [isDeleteDialogOpen, {open: openDeleteDialog, close: closeDeleteDialog}] = useComponentToggler(false);

  const pills: PillItemProps[] = [
    {
      id: "org-name",
      label: `${asset?.name}`,
      icon:
        <SidebarIcons.Assets fill={theme.palette.neutral.dark} width={16} height={16}/>,
      onClickFcn: emptyFunction
    }
  ];

  function handleMenuOpen(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
    setIsActionsShowing(true);
  }

  function handleMenuClose(e?: React.MouseEvent<HTMLElement>) {
    e?.stopPropagation();

    setAnchorEl(null);
    setIsActionsShowing(false);
  }

  function onCloseDeleteDialog() {
    closeDeleteDialog();
    handleMenuClose();
  }

  async function deleteAsset() {
    if (!asset) return;

    const assetRef = doc(organizationAssetsPath(orgId!), asset["@id"]!);
    await submitForm(assetRef, ActionType.Delete,
      (status, data, isLastUpdate) => statusSubmitHandler<Asset>({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback
      }));
  }

  function successCallback() {
    closeDeleteDialog();
    setToastSeverity(Severity.Success);
    setToastMessage(enAssetLabel.deleteSuccess);
    setIsToastOpen(true);
    navigate(-1);
  }

  function errorCallback(message: any) {
    let toastMessage = enCommonLabel.errorProcess(ProcessType.Delete);
    // if type of message is obj, get first obj value
    if (typeof message === "object" && (typeof Object.values(message)[0] === "string")) {
      toastMessage = Object.values(message)[0] as string;
    }

    setToastSeverity(Severity.Error);
    setToastMessage(toastMessage);
    setIsToastOpen(true);
    closeDeleteDialog();
  }

  if (asset === null) return <></>;

  return (
    <>
      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        title={enAssetLabel.deleteConfirmationTitle(!asset ? "" : asset.name)}
        text={enAssetLabel.deleteConfirmationText}
        handleClose={onCloseDeleteDialog}
        handleConfirm={deleteAsset}
      />
      <PilledNavigationBar pills={pills}>
        <EditAssetDrawer
          uid={uid}
          asset={asset}
          isDrawerShown={isDrawerOpen}
          onDrawerClose={closeDrawer}
          toastProps={toastProps}
          collectionReference={organizationAssetsPath(orgId!)}
        />
        <Box
          sx={() => ({
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
          })}
        >
          <Box sx={{display: "flex", gap: 0.5}}>
            <SwiftIconButton
              id="project-overview-header-edit-button"
              onClickFcn={openDrawer}
              disabled={Boolean(!access?.[PermissionOperationKey.Update])}
              label={enProjectLabel.edit}
              startIcon={SystemIcons.Edit}
            />
            <IconButton
              id={"project-overview-header-more-options-button"}
              title={enProjectLabel.moreOptions}
              onClick={(e) => handleMenuOpen(e)}>
              <SystemIcons.MoreVertical stroke={theme.palette.text.secondary}/>
            </IconButton>
            {
              isActionsShowing &&
                <HeaderMoreOptionsMenu
                    open={isActionsShowing}
                    anchorEl={anchorEl}
                    closeMenu={handleMenuClose}
                    onDelete={openDeleteDialog}
                    canDelete={Boolean(access?.[PermissionOperationKey.Delete])}
                />
            }
          </Box>
        </Box>
      </PilledNavigationBar>
    </>
  );
}

export default Head;
