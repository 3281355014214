import theme from "theme/theme";
import {CircularProgress, IconButton} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import React from "react";

interface SendChatButtonProps {
  disabled: boolean;
  isLoadingState: boolean;
  sendMessage: () => void;
}

function SendChatButton(props: SendChatButtonProps) {
  const {disabled, isLoadingState, sendMessage} = props;

  return (
    <IconButton
      disabled={disabled}
      sx={{
        width: 40,
        height: 40,
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "4px 0 0 0",
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
        "&:disabled": {
          backgroundColor: theme.palette.neutral.dark,
        }
      }}
      onClick={sendMessage}
    >
      {
        isLoadingState ? <CircularProgress size={16} sx={{color: theme.palette.common.white}}/>
          : <SystemIcons.Send stroke={theme.palette.common.white} width={16} height={16}/>
      }
    </IconButton>
  )
}

export default SendChatButton;