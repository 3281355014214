import {Member} from "types/index";
import {Box, Checkbox, Divider, Stack, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import {Avatar} from "components/index";
import React, {useEffect, useState} from "react";
import {ACCESS_ROLE_LABEL} from "constants/index";
import {AccessRole, MemberActionType} from "enums/index";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {SwiftIconButton} from "components/Button";

interface MemberCardProps {
  uid: string;
  member: Member;
  onSelect: (id: string, selected: boolean) => void;
  onMemberActionClick: (member: Member | null, action: MemberActionType) => void
  isSelected: boolean;
  access: AccessType;
  totalOwnersCount: number,
  ownerIndex: number
}

export default function MemberCard(props: MemberCardProps) {
  const {member, onSelect, onMemberActionClick, isSelected, access} = props;

  const [isSelectedCopy, setIsSelectedCopy] = useState(isSelected);

  const canDelete = member.role !== AccessRole.Owner && Boolean(access?.[PermissionOperationKey.Delete]);

  useEffect(() => {
    setIsSelectedCopy(isSelected);
  }, [isSelected]);

  function onCardSelect(e: React.ChangeEvent, checked: boolean) {
    if (!canDelete) return;
    onSelect(member.uid, checked)
  }

  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${isSelected ? theme.palette.primary.main : theme.palette.divider}`,
        borderRadius: "4px",
        px: 1,
        py: 0
      })}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" flexGrow={0}>
        <Stack direction="row" gap={1} alignItems="center" width="100%">
          <Checkbox
            onChange={onCardSelect}
            checked={isSelectedCopy}
          />
          <Divider orientation="vertical" flexItem/>
          <Stack direction="row" gap="16px" alignItems="center" py={1}>
            <Avatar
              {...member}
              sx={{fontSize: "12px", mt: 0}}
              nameComponent={<Typography variant="h4">{member.name}</Typography>}
            />
          </Stack>
        </Stack>
        <Stack direction="row" width="50%" justifyContent="flex-start">
          <Typography variant="body" align="left">
            {ACCESS_ROLE_LABEL[member.role]}
          </Typography>
        </Stack>
        <Divider orientation="vertical" flexItem sx={{mr: 1.5}}/>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={0}>
          <SwiftIconButton
            onClickFcn={() => onMemberActionClick(member, MemberActionType.EDIT)}
            disabled={Boolean(!access?.[PermissionOperationKey.Update])}
            startIcon={SystemIcons.Edit}
          />
          <SwiftIconButton
            onClickFcn={() => onMemberActionClick(member, MemberActionType.DELETE)}
            disabled={!canDelete}
            startIcon={SystemIcons.DeleteOutlined}
          />
        </Stack>
      </Stack>
    </Box>
  )
}
