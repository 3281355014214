import {Project} from "types/Project";
import {BaseProps} from "../BaseProps";
import {PillItemProps} from "components/PilledNavBar/PillItem";
import {SidebarIcons} from "assets/icons/menu/menu.index";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import React, {useState} from "react";
import {useNavigate} from "react-router";
import {DotIndicator, ExportDialog, PilledNavigationBar} from "components/index";
import {
  emptyFunction,
  enAssetLabel,
  enCommonButton,
  enMilestoneLabel,
  enProjectLabel,
} from "constants/index";
import {Hidden, IconButton, Stack} from "@mui/material";
import HeaderMenu from "../AssetOverview/HeaderMenu";
import {useComponentToggler, useRTDBDocField} from "hooks/index";
import {
  projectLevelTasksAccessListPath,
  projectLevelTasksChatMessagesPath,
  projectsPath,
  submitForm,
  getDocPath, projectLevelExportsPath
} from "../utility";
import {ActionType, CounterFields, Entity} from "enums/index";
import {ManageAccessDrawer} from "components/Drawers";
import ChatDrawer from "components/ChatDrawer";
import {doc} from "firebase/firestore";
import {AccessType, PermissionOperationKey} from "types/Permission";

interface HeaderProps extends BaseProps {
  project: Project;
  downloadAccess: AccessType | null;
}

function Header(props: HeaderProps) {
  const {project, toastProps, downloadAccess, uid} = props;
  const {orgName: organizationName, name: projectName, orgId, "@id": projectId = "fillerId"} = project;

  const [isExportDialogOpen, {open: openExportDialog, close: closeExportDialog}] = useComponentToggler(false);

  // user specific data:
  const userDocPath = getDocPath(uid, projectsPath(orgId!).path, projectId);
  const projectLevelTaskHasNewChat = useRTDBDocField<boolean>(userDocPath, "projectLevelTaskHasNewChat") ?? false;
  const pltChatEmpty = useRTDBDocField<boolean>(projectsPath(orgId!).path+"/"+projectId, "pltChatEmpty") ?? true;
  const projectLevelFilesCount = useRTDBDocField<number>(userDocPath, CounterFields.ProjectLevelFilesCount) ?? 0;

  const [isActionsShowing, setIsActionsShowing] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isManageAccessDrawerOpen, {open: openManageAccessDrawer, close: closeManageAccessDrawer}] = useComponentToggler(false);
  const [isChatDrawerOpen, setIsChatDrawerOpen] = useState<boolean>(false);

  const nav = useNavigate();

  const pills: PillItemProps[] = [
    {
      id: "org-name",
      label: `${organizationName} / ${projectName}`,
      icon: <SidebarIcons.Projects fill={theme.palette.neutral.dark} width={16} height={16}/>,
      onClickFcn: () => nav(`/${orgId}/projects/${projectId}/overview`)
    },
    {
      id: "project-name",
      label: enProjectLabel.projectLevelTasks,
      icon: <SystemIcons.Tasks width={16} height={16} color={theme.palette.neutral.dark}/>,
      onClickFcn: () => emptyFunction(),
      isLast: true
    }
  ];

  function toggleMenu(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    setAnchorEl(anchorEl === null ? e.currentTarget : null);
    setIsActionsShowing(!isActionsShowing);
  }

  function onChatDrawerClick() {
    if (projectLevelTaskHasNewChat) {
      submitForm(doc(projectsPath(orgId!)), ActionType.Update, emptyFunction,{projectLevelTaskHasNewChat: false})
    }
    setIsChatDrawerOpen(true);
  }

  return (
    <>
      <ExportDialog
        isOpen={isExportDialogOpen}
        onClose={closeExportDialog}
        toastProps={toastProps!}
        title={enAssetLabel.pltExportDialogTitle}
        exportingTitle={enAssetLabel.pltExportDialogTitle}
        message={enAssetLabel.pltExportDialogText}
        exportColRef={projectLevelExportsPath(orgId!, projectId!)!}
      />
      <ManageAccessDrawer
        uid={uid!}
        isOpen={isManageAccessDrawerOpen}
        entity={Entity.ProjectLevelTask}
        parentEntity={Entity.Project}
        documentId={projectId}
        collectionRef={projectLevelTasksAccessListPath(orgId, projectId)}
        onDrawerClose={closeManageAccessDrawer}
        toastProps={toastProps!}
      />
      <ChatDrawer
        uid={uid!}
        isOpen={isChatDrawerOpen}
        entity={Entity.ProjectLevelTask}
        pathPair={[organizationName, projectName]}
        onClose={() => setIsChatDrawerOpen(false)}
        colRef={projectLevelTasksChatMessagesPath(orgId, projectId)}
        toastProps={toastProps!}
      />
      <PilledNavigationBar pills={pills}>
        <Stack direction="row" gap={0.5}>
          <Hidden mdDown>
            <IconButton
              title={enAssetLabel.manageAccess}
              onClick={openManageAccessDrawer}
            >
              <SystemIcons.Teams width={24} height={24}/>
            </IconButton>
            <IconButton
              title={enMilestoneLabel.chat}
              onClick={onChatDrawerClick}
            >
              <DotIndicator isVisible={projectLevelTaskHasNewChat}>
                {!Boolean(pltChatEmpty ?? true) ? <SystemIcons.ChatFilled/>
                  : <SystemIcons.ChatOutlined/>}
              </DotIndicator>
            </IconButton>
          </Hidden>
          <IconButton
            title={enCommonButton.more}
            onClick={toggleMenu}
          >
            <SystemIcons.MoreVertical stroke={theme.palette.text.secondary}/>
          </IconButton>
          {isActionsShowing && (
            <HeaderMenu
              toastProps={toastProps!}
              uid={uid!}
              canImport={false}
              canExport={false}
              canDownload={Boolean(downloadAccess?.[PermissionOperationKey.Create])}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              closeMenu={toggleMenu}
              usesTemplate={false}
              entity={Entity.ProjectLevelTask}
              filesCount={projectLevelFilesCount}
              openExportDialog={openExportDialog}
            />
          )}
        </Stack>
      </PilledNavigationBar>
    </>
  )
}

export default Header;
