import useCollection from "hooks/useCollection";
import {FileRequirement, SwiftFile, Task} from "types/index";
import {Alert, Backdrop, Snackbar} from "@mui/material";
import {Entity, FileStatus, RequirementStatus as Status, Severity} from "enums/index";
import {InProgress} from "components/index";
import {enTaskLabel} from "constants/index";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import React, {useEffect, useMemo, useState} from "react";
import {BaseProps} from "screens/BaseProps";
import {collection, CollectionReference, orderBy, Timestamp} from "firebase/firestore";
import ChatDrawer from "components/ChatDrawer";
import DynamicView from "./DynamicView";
import {AccessType, PermissionOperationKey} from "types/Permission";
import NoPermission from "../../NoPermission";
import {ProcessingFile, UploadFile} from "hooks/useFileUpload/useFileUpload";
import {generateUniqueId} from "hooks/useFileUpload/reducer/reducer";
import {sortObjectsBy} from "../../utility";
import getFileDownloadPath from "../../utility/getFileDownloadPath";
import downloadFile from "../../utility/downloadFile";
import isNotPlayableOrNoPreview from "../../utility/isNotPlayableOrNoPreview";

interface FilesViewProps extends BaseProps {
  fileAccess: AccessType | null;
  task: Task | null;
  fileRequirement: FileRequirement;
  docFilesCount: number;
  statusFilter: string;
  collectionRef: CollectionReference;
  processingFiles: ProcessingFile[];
  deletedIds: Set<string>;
  handleStatusChange: (status: string) => void;
  onFilePreviewClick: (index: number) => void;
  addFiles: (files: UploadFile[]) => void;
}

function FilesView(props: FilesViewProps) {
  const {statusFilter, fileRequirement, toastProps, docFilesCount, collectionRef, fileAccess, deletedIds} = props;
  const {handleStatusChange, onFilePreviewClick, addFiles} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  const [filesCollection] = useCollection<SwiftFile>(null, collectionRef, [orderBy("timeCreated", "asc")]);
  const [displayedFiles, setDisplayedFiles] = useState<any[]>([]);
  const [filteredFiles, setFilteredFiles] = useState<any[]>([]);

  // files related states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFileUploadFocus, setIsFileUploadFocus] = useState<boolean>(false);

  const [chatDrawerTarget, setChatDrawerTarget] = useState<SwiftFile | null>(null);
  const [isDownloading, setIsDownloading] = useState<Record<number, boolean> | null>(null);

  useEffect(() => {
    // if there is no files in the collection and no files being processed, display empty list
    if (!filesCollection && !props.processingFiles) {
      return setDisplayedFiles([]);
    }

    const filteredProcessing = [...(props.processingFiles ?? [])]
      .filter(file => !(filesCollection ?? []).some(colFile => colFile["@id"] === file.id) && !deletedIds.has(file.id))
      .map(file => ({...file, fromLocal: true, fileStatus: FileStatus.Uploading}));
    setFilteredFiles(filteredProcessing);

    const filteredFiles = [...(filesCollection ?? [])]
      .filter(file => !deletedIds.has(file["@id"]!))
      .map(file => ({...file, fromLocal: false}));

    // combine files that are already in the collection and files that are being processed
    const combinedFiles = [...filteredFiles, ...(sortObjectsBy(filteredProcessing, "timeCreatedNumber", "asc"))];

    if (statusFilter === "All") {
      setDisplayedFiles(combinedFiles);
      return;
    }

    setDisplayedFiles(combinedFiles.filter(file => file.fileStatus === statusFilter));
  }, [filesCollection, props.processingFiles, deletedIds]);

  // update collection constraints based on the selected status in the filter
  useMemo(() => {
    setIsLoading(true);
    if (statusFilter === "All") {
      setDisplayedFiles([...displayedFiles]);
      setIsLoading(false);
      return;
    }

    setDisplayedFiles([...displayedFiles.filter((file: SwiftFile) => file.fileStatus === statusFilter)]);
    setIsLoading(false);
  }, [statusFilter]);

  // add loading effect based on filesCollection updates
  useEffect(() => {
    setIsLoading(filesCollection === null);
  }, [filesCollection]);

  function handleDrag(e: React.DragEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragover" || Boolean(!fileAccess?.[PermissionOperationKey.Create])) return;

    setIsFileUploadFocus(e.type === "dragenter");
  }

  function openChatDrawer(file: SwiftFile) {
    setChatDrawerTarget(file);
  }

  function closeChatDrawer() {
    setChatDrawerTarget(null)
  }

  async function handleDrop(e: React.DragEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();

    setIsFileUploadFocus(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0] && canUploadFile(e.dataTransfer.files.length ?? 0)) {
      const filesList = e.dataTransfer.files;

      let newFiles: UploadFile[] = [];
      for (let index = 0; index < filesList.length; index++) {
        const file = filesList.item(index);
        if (!file) continue;

        newFiles.push({
          file,
          overwrite: false,
          queueId: generateUniqueId() + index,
          parentCollectionPath: collectionRef.path,
          timeCreated: Timestamp.fromDate(new Date()),
        })
      }

      await addFiles(newFiles);
    }
  }

  function canUploadFile(filesCount: number): boolean {
    if (fileRequirement.fileRequirementStatus === Status.Approved) {
      showMessage(enTaskLabel.alreadyApprovedError, Severity.Error);
      return false;
    }

    const maximumFiles = fileRequirement.maximumFiles;
    if (!maximumFiles) return true;

    const currentFilesCount = (docFilesCount || 0) + filteredFiles.length;
    const availableFiles = maximumFiles - currentFilesCount;
    if (availableFiles < filesCount) {
      showMessage(enTaskLabel.maximumFilesError, Severity.Error);
      return false;
    }

    return true;
  }

  function showMessage(message: string, severity: Severity) {
    setToastMessage(message);
    setToastSeverity(severity);
    setIsToastOpen(true);
  }

  function reUploadFile(file: File, targetRef: string) {
    addFiles([{
      file,
      overwrite: true,
      queueId: generateUniqueId(),
      parentCollectionPath: collectionRef.path,
      timeCreated: Timestamp.fromDate(new Date()),
      referenceId: targetRef,
    }])
  }

  async function onFilePreviewPress(index: number) {
    if (!filesCollection) {
      onFilePreviewClick(index);
      return;
    }
    const currentFile = filesCollection[index];
    if (!currentFile) {
      onFilePreviewClick(index);
      return;
    }
    const notSupported = isNotPlayableOrNoPreview(currentFile.name);
    if (!notSupported) {
      onFilePreviewClick(index);
      return;
    }
    setIsDownloading(prev => {
      if (prev === null) return {[index]: true};
      return {...prev, [index]: true};
    });
    const downloadPath = await getFileDownloadPath(currentFile.destPath);
    await downloadFile(downloadPath, currentFile.name);
    setIsDownloading(prev => {
      if (prev === null || !(index in prev)) return prev;
      const {[index]: _, ...newState} = prev;
      return newState;
    });
  }

  if (isLoading || !fileAccess) return <InProgress/>;

  if (Boolean(!fileAccess?.[PermissionOperationKey.View]))
    return <NoPermission fullHeight={false}/>;

  return (
    <>
      {
        chatDrawerTarget && (
          <ChatDrawer
            uid={props.uid}
            isOpen={!!chatDrawerTarget}
            entity={Entity.File}
            pathPair={[
              `${fileRequirement?.orgName} /
          ${fileRequirement?.projectName} /
          ${fileRequirement?.assetName} /
          ${fileRequirement?.taskName}`,
              chatDrawerTarget.name ?? ""
            ]}
            onClose={closeChatDrawer}
            colRef={collection(collectionRef, chatDrawerTarget["@id"]!, "chatMessages")}
            toastProps={toastProps!}
          />
        )
      }
      {isFileUploadFocus && fileAccess?.[PermissionOperationKey.Create] &&
          <Backdrop
              open={isFileUploadFocus}
              sx={{backgroundColor: "unset", zIndex: 999}}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
          />
      }
      <Snackbar
        open={isFileUploadFocus}
        onClose={() => setIsFileUploadFocus(false)}
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
        sx={{marginLeft: "140px"}}
        className="file-upload-snackbar"
      >
        <Alert
          onClose={() => setIsFileUploadFocus(false)}
          sx={{
            width: "100%",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.onDark,
          }}
          icon={SystemIcons.CloudUpload({fill: theme.palette.background.swiftDefault})}
        >
          {enTaskLabel.dropFiles}
        </Alert>
      </Snackbar>
      <DynamicView
        uid={props.uid}
        task={props.task}
        fileAccess={fileAccess}
        collectionRef={collectionRef}
        filesCollection={displayedFiles}
        isFileUploadFocus={isFileUploadFocus}
        statusFilter={statusFilter}
        handleStatusChange={handleStatusChange}
        handleDrag={handleDrag}
        openChatDrawer={openChatDrawer}
        toastProps={toastProps!}
        onFilePreviewClick={onFilePreviewPress}
        reUploadFile={reUploadFile}
        isDownloading={isDownloading}
      />
    </>
  )
}

export default FilesView;