import React from "react";
import {MenuCard} from "components/index";
import {Variant} from "@mui/material/styles/createTypography";
import SelectMenuItems from "components/SelectStatus/SelectMenuItems";
import {SelectBase} from "components/SelectStatus/index";

interface SelectMenuProps<T extends SelectBase> {
  id: string;
  anchorEl: HTMLElement | null;
  itemList: Record<string, T> | null;
  isMenuShowing: boolean;
  disabledStatuses?: string[];
  selectItemVariant?: Variant;
  selectItemSx?: any;
  setSelected: (obj: T) => void;
  onMenuToggle: (e: React.MouseEvent<HTMLElement>) => void;
}

function SelectMenu<T extends SelectBase>(props: SelectMenuProps<T>) {
  const {anchorEl, isMenuShowing, disabledStatuses, onMenuToggle, ...rest} = props;

  if(!isMenuShowing) return null;

  return (
    <MenuCard
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      closeMenu={onMenuToggle}
      origin="left"
    >
      <SelectMenuItems
        onMenuToggle={onMenuToggle}
        {...rest}
      />
    </MenuCard>
  )
}

export default SelectMenu;