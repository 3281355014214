import theme from "theme/theme";
import {RequirementStatus} from "enums/requirementStatus";

export function getBorderColor(isFileUploadFocus: boolean, fileRequirementStatus: RequirementStatus, isRequirementChecked: boolean) {
  if (isFileUploadFocus)
    return theme.palette.primary.main;

  if (fileRequirementStatus === RequirementStatus.HasRejection)
    return theme.palette.error.main;

  if (isRequirementChecked)
    return theme.palette.primary.main;

  return theme.palette.divider
}