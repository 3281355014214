import PDFImg from "assets/img/PDFImg.png";
import theme from "theme/theme";
import React from "react";
import {Box} from "@mui/material";
import RejectedOverlay from "./RejectedOverlay";

interface RejectedPDFPreviewProps {
  id?: string;
  onClick: () => void;
  title?: string;
}

function RejectedPDFPreview(props: RejectedPDFPreviewProps) {
  const {id = "n", onClick, title = ""} = props;
  return (
    <Box
      title={title}
      id={`rejected-pdf-preview-${id}`}
      style={{
        display: "grid",
        placeItems: "center",
        minWidth: 64,
        height: 64,
        backgroundColor: theme.palette.background.swiftDefault,
        borderRadius: 2,
      }}
      className="file-preview-item"
      position="relative"
      width="inherit"
      height="inherit"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
    >
      <img
        src={PDFImg}
        alt={"PDF"}
        style={{alignSelf: "center"}}
      />
      <RejectedOverlay />
    </Box>
  )
}

export default RejectedPDFPreview;