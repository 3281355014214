import {Stack, Box, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {Asset} from "types/Asset";
import {BaseProps} from "../BaseProps";
import theme from "theme/theme";
import {enCommonLabel, initialAsset} from "constants/index";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import {DocumentReference} from "firebase/firestore";
import {en} from "language/en";

interface HeaderProps extends BaseProps {
  asset: Asset;
  assetRef: DocumentReference;
  canEdit: boolean;
}

function Header(props: HeaderProps) {
  const {toastProps, assetRef, uid} = props;
  const asset = props.asset ?? initialAsset;

  const [assetData, setAssetData] = useState<Asset>(asset);
  const {name, idCode, longitude, latitude, address} = assetData;

  useEffect(() => {
    setAssetData(asset);
  }, [asset]);

  function getDataTagsIds() {
    if (!asset) return [];
    return asset.dataTagsIds || []
  }

  function Coordinates() {
    if (latitude && longitude) return (
			<Typography variant="body" color={theme.palette.neutral.dark}>
				{en.common.label.latitude}: {latitude}, {en.common.label.longitude}: {longitude}
			</Typography>
    );

    return (
      <Typography variant="body" color={theme.palette.neutral.dark}>
        {en.common.label.coordinatesNotAvailable}
      </Typography>
    );
  }

  return (
    <Stack direction="column" display="flex" py={1} gap={2} mb={1}>
      <Stack direction={"row"} display="flex" gap={2} alignItems={"center"}>
        <Box flex={3}>
          <Stack direction={"column"} sx={{gap: 1}}>
            <Stack direction="row" alignItems={"center"} sx={{gap: 2}}>
              <Typography variant="h1">{name}</Typography>
            </Stack>
            <Stack direction="column">
              <Typography variant="body" color={theme.palette.neutral.dark}>
                ID: {idCode}
              </Typography>
              <Coordinates/>
							<Typography variant="body" color={theme.palette.neutral.dark}>
								{address || enCommonLabel.addressNotAvailable}
							</Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <DataTagsDisplay
        uid={uid}
        dataTagsIds={getDataTagsIds()}
        toastProps={toastProps!}
        documentRef={assetRef}
        canEditDocumentRef={props.canEdit}
      />
    </Stack>
  );
}

export default Header;
