import React, {useState} from "react";
import {db} from "../../firebase";
import {Stack} from "@mui/material";
import {SecondaryButton} from "components/Button";
import {enMilestoneLabel, enTaskLabel} from "constants/index";
import {Entity} from "enums/entity";
import {CreateTaskDrawer, ManageMilestoneDrawer as MilestoneCreationDrawer} from "components/Drawers";
import {toastProps} from "../../screens/BaseProps";
import {Milestone} from "types/Milestone";
import {collection, doc} from "firebase/firestore";
import useGetProjectBasedPath from "hooks/useGetProjectBasedPath";
import useTemplateDocument from "hooks/useTemplateDocument";

interface StatisticsButtonGroupProps {
  canCreateAccess: boolean;
  uid: string;
  toastProps: toastProps;
  parentEntity: Entity;
  milestonesCollectionRef: string;
  canCreateMilestone: boolean;
}

function StatisticsButtonGroup(props: StatisticsButtonGroupProps) {
  const {canCreateAccess, uid, milestonesCollectionRef, canCreateMilestone} = props;
  const {toastProps} = props;

  // Permissions related
  const dynamicPath = useGetProjectBasedPath(milestonesCollectionRef)
  const [isMilestoneCreationOpen, setIsMilestoneCreationOpen] = useState(false);
  const [isCreateTaskFormOpen, setIsCreateTaskFormOpen] = useState<boolean>(false);

  const milestonesCollectionRefPath = dynamicPath ? collection(db, dynamicPath) : null
  const miscDocPath = milestonesCollectionRefPath ? doc(milestonesCollectionRefPath, "misc") : null
  const [miscMilestone] = useTemplateDocument<Milestone | null | undefined>(miscDocPath);

  // const [milestoneAccess] = useAccess({
  //   uid: uid!,
  //   entity: props.parentEntity,
  //   documentDocId: PermissionEntity.Milestone,
  // });

  if (!miscMilestone) return null;

  return (
    <Stack
      direction={{xs: "column", sm: "row", md: "row", lg: "row"}}
      alignItems="flex-start"
      justifyContent={{xs: "flex-start", sm: "flex-start", md: "flex-start", lg: "flex-end"}}
      spacing={{xs: 1, sm: 1, md: 1, lg: 2}}
      mt={{xs: 1, sm: 1, md: 1, lg: 0}}
    >
      <SecondaryButton
        id="asset-overview-head-create-milestone-button"
        onClickFcn={() => setIsMilestoneCreationOpen(true)}
        disabled={Boolean(!canCreateMilestone)}
        label={enMilestoneLabel.new}
        sx={{height: "32px"}}
      />
      <SecondaryButton
        id="asset-overview-head-create-task-button"
        onClickFcn={() => setIsCreateTaskFormOpen(true)}
        disabled={!canCreateAccess}
        label={enTaskLabel.new}
        sx={{height: "32px"}}
      />
      <CreateTaskDrawer
        parentEntity={props.parentEntity === Entity.Project ? Entity.ProjectLevelTask : Entity.Asset}
        collectionRef={milestonesCollectionRefPath!}
        milestone={miscMilestone!}
        onDrawerClose={() => setIsCreateTaskFormOpen(false)}
        isDrawerShown={isCreateTaskFormOpen && !!miscMilestone}
        toastProps={toastProps!}
        uid={uid}
      />
      <MilestoneCreationDrawer
        uid={uid}
        collectionRef={milestonesCollectionRefPath!}
        isDrawerOpen={isMilestoneCreationOpen}
        onClose={() => setIsMilestoneCreationOpen(false)}
        toastProps={toastProps!}
      />
    </Stack>
  );
}

export default StatisticsButtonGroup;