import {Box, Stack} from "@mui/material";
import SystemIndex from "assets/icons/system/system.index";
import {DeletedItemType} from "types/ActivityFeedTypes";
import TimestampTypo from "components/Typography/TimestampTypo";
import {formatDateTimeFirestoreDate} from "screens/utility/formatDateTimeFirestoreDate";
import {enActivityFeedLabel} from "constants/index";
import {ENTITY_LABEL} from "language/en/common";
import ItemContainer from "components/ActivityFeed/ItemContainer";

interface DeletedItemProps {
  item: DeletedItemType;
}

function DeletedItem(props: DeletedItemProps) {
  const {item} = props;
  const {details, timestamp, entity} = item;
  const {by, name} = details;
  const {date, time} = formatDateTimeFirestoreDate(timestamp);

  return (
    <ItemContainer>
      <Box width={22} height={22}>
        <SystemIndex.SystemIcons.DeleteOutlined {...IconProps}/>
      </Box>
      <Stack direction="column">
        {enActivityFeedLabel.deleted(ENTITY_LABEL[entity], name, by.name ?? "")}
        <TimestampTypo>{`${date} ${time}`}</TimestampTypo>
      </Stack>
    </ItemContainer>
  );
}

const IconProps = {
  width: 22,
  height: 22
}

export default DeletedItem;
