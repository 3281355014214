import {BaseProps} from "../BaseProps";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {emptyFunction} from "constants/index";
import {useNavigate} from "react-router";
import {templatesPath} from "../utility";
import {doc} from "firebase/firestore";
import {Template} from "types/Template";
import {useAccess, useDocument} from "hooks/index";
import {InProgress} from "components/index";
import {Stack} from "@mui/material";
import Header from "./Header";
import Tabination from "../TemplatesOverview/Tabination";
import Content from "components/Content";
import {Entity} from "enums/entity";
import {PermissionEntity} from "types/Permission";

function TemplatesOverview(props: BaseProps) {
  const {toastProps, selectedOrg, uid, setSelectedOrgId = emptyFunction} = props;

  const {orgId, templateId} = useParams();

  const navigate = useNavigate();

  const templateRef = doc(templatesPath(orgId!), templateId!);
  const [template] = useDocument<Template>(templateRef);

  const [access] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationTemplate,
  });

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  if (!access || !template)
    return (
      <Content>
        <InProgress/>
      </Content>
    );

  return (
    <>
      <Header
        template={template!}
        access={access}
        documentRef={templateRef!}
        toastProps={toastProps!}
        uid={uid}
      />
      <Content>
        <Stack spacing={2} height="100%">
          <Tabination
            uid={uid}
            template={template as Template}
            toastProps={toastProps!}
          />
        </Stack>
      </Content>
    </>
  )
}

export default TemplatesOverview;