import {BaseProps} from "screens/BaseProps";
import {emptyFunction} from "constants/index";
import React from "react";
import {useComponentToggler, useDocument} from "hooks/index";
import {CollectionReference, doc} from "firebase/firestore";
import {Entity} from "enums/index";
import {FormRequirement} from "types/FormRequirement";
import {ManageFormRequirementDrawer} from "components/Drawers";
import TemplateRequirementItem from "./TemplateRequirementItem";
import {AccessType} from "types/Permission";

interface TemplateRequirementsListProps extends BaseProps {
  parentEntity: Entity;
  formRequirementAccess: AccessType | null;
  displayedRequirements: FormRequirement[];
  selectedRequirements: Set<string>;
  onCheckboxToggle: (checked: boolean, id: string) => void;
  formRequirementsRef: CollectionReference;
  deleteRequirement: (requirementId: string) => void;
  showCheckBox: boolean;
}

function TemplateRequirementsList(props: TemplateRequirementsListProps) {
  const {displayedRequirements, formRequirementsRef, formRequirementAccess, selectedRequirements, parentEntity, showCheckBox} = props;
  const {onCheckboxToggle, deleteRequirement, toastProps} = props;

  const [selectedRequirement, setSelectedRequirement] = useDocument<FormRequirement | null>(null);

  const [isDrawerOpen, {open: openDrawer, close: closeDrawer}] = useComponentToggler(false);

  function editRequirement(requirement: FormRequirement) {
    const docRef = doc(formRequirementsRef, requirement["@id"]!);
    setSelectedRequirement(docRef);
    openDrawer();
  }

  if(parentEntity !== Entity.Templates) return null;

  return (
    <>
      {selectedRequirement && (
        <ManageFormRequirementDrawer
          uid={props.uid}
          isDrawerOpen={isDrawerOpen}
          onDrawerClose={closeDrawer}
          formRequirement={selectedRequirement}
          formRequirementCollectionRef={formRequirementsRef}
          toastProps={toastProps!}
        />
      )}
      {displayedRequirements.map(formRequirement => {
        return (
          <TemplateRequirementItem
            key={formRequirement["@id"]!}
            formRequirement={formRequirement}
            isChecked={selectedRequirements.has(formRequirement["@id"] ?? "")}
            onCheckboxToggle={onCheckboxToggle}
            collectionRef={formRequirementsRef}
            toastProps={toastProps}
            formRequirementAccess={formRequirementAccess}
            editRequirement={editRequirement}
            deleteRequirement={deleteRequirement}
            openRequirementDrawer={emptyFunction}
            uid={props.uid}
            showCheckBox={showCheckBox}
          />
        )
      })}
    </>
  )
}

export default TemplateRequirementsList;