import {useNavigate} from "react-router-dom";
import React from "react";
import {Box, Button, Hidden, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import FormContainer from "components/FormContainer";
import {SwiftLogoOnly} from "assets/icons/SwiftLogo";
import {enCommonButton} from "constants/index";

interface OtherErrorProps {
  errorMessage: string;
}

export function OtherError(props: OtherErrorProps) {
  const {errorMessage} = props;
  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.secondary.main,
        zIndex: "-2!important",
        backgroundImage: `url("/assets/Artboard6.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{height: "100vh"}}
      >
        <FormContainer>
          <Stack justifyContent="center" gap={2} textAlign="center">
            <Box
              sx={{
                backgroundColor: theme.palette.neutral.light,
                borderRadius: "50%",
                alignSelf: "center",
                display: "flex",
                padding: 1,
              }}
            >
              <SystemIcons.Close stroke={theme.palette.error.main} width={48} height={48}/>
            </Box>
            <Typography variant="h2" mb={2}>Error</Typography>
            <Stack>
              <Typography variant="body">{errorMessage}</Typography>
            </Stack>
            <Button
              variant="outlined"
              onClick={() => navigate("/login")}
              sx={{
                marginTop: 2,
                borderRadius: 5,
                color: theme.palette.secondary.main,
                borderColor: theme.palette.secondary.main,
                padding: 1,
                "&:hover": {
                  color: theme.palette.primary.main,
                }
              }}
            >
              {enCommonButton.backToLogin}
            </Button>
          </Stack>
        </FormContainer>
      </Stack>
      <Hidden smDown>
        <Box sx={{position: "absolute", bottom: "2%", right: "2%", zIndex: 2}}>
          <SwiftLogoOnly
            height={40}
            width={40}
            stroke={theme.palette.background.default}
            fill={theme.palette.background.default}
          />
        </Box>
      </Hidden>
    </Stack>
  )
}
