import {Organization} from "types/index";
import {defaultSelectedOrg} from "constants/defaultSelectedOrg";
import {defaultUser} from "constants/defaultUser";

export const initialOrganization: Partial<Organization> = {
  "@id": "",
  name: "",
  sidebarView: {selectedOrg: defaultSelectedOrg},
  createdBy: {...defaultUser},
  owner: {...defaultUser},
  nameIdx: "0",
}
