import {useNavigate} from "react-router";
import {BaseProps} from "../BaseProps";
import {useParams} from "react-router-dom";
import {emptyFunction, enActivityFeedLabel, enHomeLabel} from "constants/index";
import React, {useEffect, useMemo, useState} from "react";
import {useCollection, useComponentToggler, useDebounce, useRTDBDocField} from "hooks/index";
import {
  areObjectsEqual,
  generateEntityBreadcrumbs,
  homeExportsPath,
  myTasksPath, submitForm,
  tasksPath,
  userInvitesPath,
  userPath,
} from "../utility";
import {ActionType, CounterFields, Entity, ExportTaskType} from "enums/index";
import InvitesView from "components/InvitesView";
import {Stack, Typography} from "@mui/material";
import {collection, doc, limit, where} from "firebase/firestore";
import {FiltersState} from "./TaskSearchAndFilters";
import {ExportDialog} from "components/Dialogs";
import {tabValues} from "./Tabination/TabsContents/TaskAndTimers/constants/tabValues";
import {milestoneTaskandAssetStatus as TaskStatus} from "enums/milestoneTaskandAssetStatus";
import {TaskItemAction} from "./TaskItemAction";
import {Task} from "types/Task";
import TaskScheduleDrawer from "components/Drawers/TaskScheduleDrawer";
import ChatDrawer from "components/ChatDrawer";
import {InitialFilterState, queryAlgolia, TaskData} from "./queryAlgolia";
import {db} from "../../firebase";
import ActivityFeed from "components/ActivityFeed";
import useCollectionCount from "hooks/useCollectionCount";
import {Content, CustomTab, CustomTabs, DotIndicator, PageTitle, TabPanel} from "components/index";
import theme from "theme/theme";
import {statusSubmitHandler} from "../utility/statusSubmitHandler";
import {User} from "types/User";
import TaskAndTimersContents, {DefaultTaskConstraints} from "./Tabination/TabsContents/TaskAndTimers";

interface HomeProps extends BaseProps {
  invitationId?: string | null;
}

function Home(props: HomeProps) {
  const {selectedOrg, uid, setSelectedOrgId = emptyFunction, invitationId} = props;
  const navigate = useNavigate();
  const {orgId} = useParams();

  const hasNewInvites = useRTDBDocField<boolean>(userPath(uid).path, "hasNewInvites") ?? false;
  const pendingInvitesCount = useRTDBDocField<number>(userPath(uid).path, CounterFields.PendingInvitesCount) ?? 0;

  const [activeTabIndex, setActiveTabIndex] = useState<number>(!!invitationId ? 1 : 0);
  const [algoliaFilters, setAlgoliaFilters] = useState<any>(null);

  // tasks related states
  const [isTaskCollapsed, setIsTaskCollapsed] = useState<boolean>(false);

  const {count: totalCount, reFetchTotalCount} = useCollectionCount<TaskData>({
    collectionRef: myTasksPath(uid),
    constraints: DefaultTaskConstraints,
  });

  // list of tasks from algolia
  const [algoliaResults, setAlgoliaResults] = useState<TaskData[] | null>(null);
  // list that is displayed on screen
  const [displayedTasks, setDisplayedTasks] = useState<TaskData[]>([]);

  // approved tasks
  const [approvedTasks] = useCollection(null, myTasksPath(uid!),
    [
      where("taskStatus", "==", TaskStatus.Approved),
      where("deleted", "==", false),
      where("hidden", "==", false),
      limit(1)
    ]);

  const [filterApproved, setFilterApproved] = useState<number>(0);

  // search related states
  const [filters, setFilters] = useState<FiltersState>(InitialFilterState);
  const [loading, setLoading] = useState<boolean>(false);

  // drawer and dialog related states
  const [isExportTasksDialogOpen, {open: showExportTasksDialog, close: closeExportTasksDialog}] = useComponentToggler(false);
  const [isTaskScheduleDrawerOpen, {open: showTaskScheduleDrawer, close: closeTaskScheduleDrawer}] = useComponentToggler(false);
  const [isChatDrawerOpen, {open: showChatDrawer, close: closeChatDrawer}] = useComponentToggler(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [isTaskActivityFeedOpen, {
    open: openTaskActivityFeedDrawer,
    close: closeTaskActivityFeedDrawer
  }] = useComponentToggler(false);

  // @ts-ignore
  const breadcrumbs = selectedTask ? generateEntityBreadcrumbs({
    ...selectedTask,
    taskName: selectedTask.name,
    entityRefPath: selectedTask.orgTaskRefPath
  }) : [];
  const lastPath = breadcrumbs.length ? breadcrumbs[breadcrumbs.length - 1].path : "";

  useDebounce(search, 300, [filters]);

  useMemo(() => {
    // append algolia results to the displayedTasks
    if (algoliaResults !== null) {
      // check for possible duplicates
      const distinctAlgoliaIds: Set<string> = new Set(algoliaResults.map((task) => task.taskId ?? task["@id"] ));
      const newTasks = [
        ...Array.from(distinctAlgoliaIds)
          .map(id => algoliaResults.find(task => (task.taskId ?? task["@id"]) === id) as TaskData)
        ];
      setDisplayedTasks([...newTasks]);

      // count the approved
      const approvedCount = newTasks.filter(task => task.taskStatus === TaskStatus.Approved);
      setFilterApproved(approvedCount.length);
      return;
    }

    setDisplayedTasks([]);
  }, [algoliaResults])

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  function onTaskItemDrawerOpen(task: Task, action: TaskItemAction) {
    closeTaskScheduleDrawer();
    closeChatDrawer();
    setSelectedTask(task);
    action === TaskItemAction.TaskSchedule ? showTaskScheduleDrawer() : showChatDrawer();
  }

  async function search() {
    setLoading(true);
    const {result, filters: filtersUsed} = await queryAlgolia(filters, uid, ['colPath:myTasks']);
    setAlgoliaResults(result === undefined ? [] : result as TaskData[]);
    setAlgoliaFilters(filtersUsed);
    setLoading(false);
  }

  function onClearTaskItemAction() {
    closeTaskScheduleDrawer();
    closeChatDrawer();
    setSelectedTask(null);
    reFetchTotalCount();
  }

  const isFiltered = !areObjectsEqual(InitialFilterState, filters);

  function showActivityFeedDrawer(task: Task) {
    if (!task) return;
    setSelectedTask(task);
    openTaskActivityFeedDrawer();
  }

  async function onInviteClick() {
    await submitForm(
      userPath(props.uid),
      ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler<User>({status, data, isLastUpdate, errorCallback: emptyFunction}),
      {hasNewInvites: false}
    )
  }

  return (
    <>
      <ExportDialog
        isOpen={isExportTasksDialogOpen}
        toastProps={props.toastProps!}
        onClose={closeExportTasksDialog}
        exportColRef={homeExportsPath(uid)}
        exportingTitle={tabValues[filters.exportTasksType]?.exportingTitle}
        message={tabValues[filters.exportTasksType].message}
        title={tabValues[filters.exportTasksType].title}
        exportType={isFiltered ? ExportTaskType.FilteredTasks : filters.exportTasksType}
        filteredTaskIds={isFiltered ? displayedTasks?.map((task) => task.taskId ?? task["@id"]!) : undefined}
      />
      {selectedTask && (
        <ActivityFeed
          uid={props.uid}
          isOpen={isTaskActivityFeedOpen}
          onClose={closeTaskActivityFeedDrawer}
          collectionReference={collection(doc(db, selectedTask.orgTaskRefPath!), "activityFeed")}
          entity={Entity.Task}
        />
      )}

      {activeTabIndex === 0 && selectedTask && (
        <>
          <TaskScheduleDrawer
            uid={uid}
            task={selectedTask}
            isDrawerOpen={isTaskScheduleDrawerOpen}
            onClose={onClearTaskItemAction}
            collectionRef={tasksPath(orgId!, selectedTask.projectId, selectedTask.assetId, selectedTask.milestoneId, undefined)}
            toastProps={props.toastProps!}
            submitCallback={reFetchTotalCount}
          />
          <ChatDrawer
            uid={uid}
            navigateToOverview={() => navigate(lastPath)}
            isOpen={isChatDrawerOpen}
            entity={Entity.Task}
            pathPair={[
              `${selectedTask.orgName} / ${selectedTask.projectName} / ${selectedTask.assetName ?? ""}`,
              selectedTask.name ?? ""
            ]}
            onClose={onClearTaskItemAction}
            colRef={collection(doc(db, selectedTask.orgTaskRefPath!), "/chatMessages")}
            organizationId={selectedTask.orgId}
            projectId={selectedTask.projectId}
            toastProps={props.toastProps!}
          />
        </>
      )}
      <Content>
        <Stack
          height="100%"
          maxWidth="100vw"
          sx={{
            gap: 1.5,
            pt: 2,
            px: {
              xs: 1,
              sm: 1,
              md: 0,
            },
          }}
        >
          <PageTitle title={enHomeLabel.home}/>
          <CustomTabs
            value={activeTabIndex}
            onChange={(_: React.SyntheticEvent, index: number) => setActiveTabIndex(index)}
            sx={{
              maxWidth: "100%",
            }}
          >
            <CustomTab
              key={`tab-label-task-and-timers`}
              disableRipple
              label={
                <Stack direction="row" gap={0.5}>
                  <Typography variant="h5">{enHomeLabel.tasksAndTimers}</Typography>
                </Stack>
              }
            />
            <CustomTab
              disableRipple
              label={
                <DotIndicator isVisible={hasNewInvites}>
                  <Stack direction="row" gap={0.5} paddingRight={hasNewInvites ? 1 : 0} onClick={onInviteClick}>
                    <Typography variant="h5">{enActivityFeedLabel.invites}</Typography>
                    <Typography
                      id="all-invites-counter"
                      color={theme.palette.neutral.dark}
                      variant="h5"
                    >
                      {pendingInvitesCount > 0 ? pendingInvitesCount : ""}
                    </Typography>
                  </Stack>
                </DotIndicator>
              }
              sx={{ml: 5}}
            />
          </CustomTabs>
          <TabPanel
            tabIndex={0}
            value={activeTabIndex}
            key={'tab-panel-task-and-timers'}
          >
            <TaskAndTimersContents
              uid={uid}
              withTasks={totalCount > 0}
              myTasksCount={totalCount}
              isTaskCollapsed={isTaskCollapsed}
              filters={filters}
              loading={loading}
              approvedTasksLength={isFiltered ? filterApproved : approvedTasks?.length || 0}
              isFiltered={isFiltered}
              setFilters={(filters) => {
                setLoading(true);
                setFilters(filters)
              }}
              setIsTaskCollapsed={setIsTaskCollapsed}
              setAlgoliaResults={setAlgoliaResults}
              showExportTasksDialog={showExportTasksDialog}
              toastProps={props.toastProps!}
              reFetchTotalCount={() => reFetchTotalCount()}
              displayedTasks={displayedTasks}
              onTaskItemDrawerOpen={onTaskItemDrawerOpen}
              showActivityFeedDrawer={showActivityFeedDrawer}
              algoliaFilters={algoliaFilters}
            />
          </TabPanel>
          <TabPanel
            tabIndex={1}
            value={activeTabIndex}
            key={'tab-panel-invites'}
          >
            <InvitesView
              uid={uid}
              invitationsReference={userInvitesPath(uid)}
              entity={Entity.ActivityFeed}
              toastProps={props.toastProps!}
              fromHome={true}
            />
          </TabPanel>
        </Stack>
      </Content>
    </>
  )
}

export default Home;