import {SVGProps} from "react"

const Layers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={24}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.114 8.284a1 1 0 0 1 0 1.6l-2.058 1.543a1 1 0 0 0 0 1.6l2.058 1.544a1 1 0 0 1 0 1.6l-9.513 7.133a1 1 0 0 1-1.2 0L.887 16.17a1 1 0 0 1 0-1.6l2.058-1.544a1 1 0 0 0 0-1.6L.887 9.884a1 1 0 0 1 0-1.6l9.514-7.133a1 1 0 0 1 1.2 0l9.513 7.133Zm-9.94 12.819 7.681-5.732-2.441-1.802-5.24 3.898-5.239-3.93-2.44 1.834 7.68 5.732Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
)

const Organizations = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3336 3.16675H17.7083C18.975 3.16675 20 4.19175 20 5.45841V7.36675L10.2 10.6334C10.1333 10.6584 10.0667 10.6667 10 10.6667C9.93333 10.6667 9.86667 10.6584 9.8 10.6334L0 7.36675V5.45841C0 4.19175 1.025 3.16675 2.29167 3.16675H6.66699V2.33329C6.66699 1.41413 7.41449 0.666626 8.33366 0.666626H11.667C12.5861 0.666626 13.3336 1.41413 13.3336 2.33329V3.16675ZM8.33366 3.16675V2.33329H11.667V3.16675H8.33366ZM10.5917 11.8167C10.4417 11.875 10.225 11.9167 10 11.9167C9.775 11.9167 9.55833 11.875 9.35833 11.8L0 8.68335V15.0417C0 16.3083 1.025 17.3333 2.29167 17.3333H17.7083C18.975 17.3333 20 16.3083 20 15.0417V8.68335L10.5917 11.8167Z"
      fill="#858585"/>
  </svg>
)

const Home = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 20.065v-5a3 3 0 0 0-6 0v5H2a2 2 0 0 1-2-2V7.197a2 2 0 0 1 .971-1.715l8-4.8a2 2 0 0 1 2.058 0l8 4.8A2 2 0 0 1 20 7.197v10.868a2 2 0 0 1-2 2h-5Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
)

const Chat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19 9.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.379 8.379 0 0 1-3.8-.9L1 19l1.9-5.7A8.38 8.38 0 0 1 2 9.5a8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5Z"
      fill={props.fill ?? "#858585"}
      stroke={props.fill ?? "#858585"}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const ActivityFeed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={21}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.243 1.757A6 6 0 0 1 15 6c0 7 3 9 3 9H0s3-2 3-9a6 6 0 0 1 10.243-4.243ZM8.135 18.498a1 1 0 0 0-1.73 1.004 3 3 0 0 0 5.19 0 1 1 0 0 0-1.73-1.004 1 1 0 0 1-1.73 0Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
)

const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.333 8h9.333M8 3.333 12.667 8 8 12.667"
      stroke={props.fill ?? "#fff"}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

function Projects(props: SVGProps<SVGSVGElement>) {
  return <svg
    width={props.width ?? 24}
    height={props.height ?? 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.83 6H19C19.7956 6 20.5587 6.31607 21.1213 6.87868C21.6839 7.44129 22 8.20435 22 9V17C22 17.7956 21.6839 18.5587 21.1213 19.1213C20.5587 19.6839 19.7956 20 19 20H5C4.20435 20 3.44129 19.6839 2.87868 19.1213C2.31607 18.5587 2 17.7956 2 17V7C2 6.20435 2.31607 5.44129 2.87868 4.87868C3.44129 4.31607 4.20435 4 5 4H10C11.306 4 12.417 4.835 12.83 6Z"
      fill={props.fill ?? "#858585"}/>
  </svg>
}

function Assets(props: SVGProps<SVGSVGElement>) {
  return <svg
    width={props.width ?? 24}
    height={props.height ?? 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd"
          d="M22.1137 8.28402C22.6471 8.68397 22.6472 9.48407 22.1138 9.88411L20.056 11.4275C19.5227 11.8275 19.5227 12.6275 20.056 13.0275L22.1138 14.5708C22.6472 14.9709 22.6471 15.771 22.1137 16.1709L12.6002 23.3038C12.2447 23.5703 11.7559 23.5704 11.4004 23.3038L1.88694 16.1709C1.35349 15.771 1.35343 14.9709 1.88682 14.5708L3.94463 13.0275C4.47796 12.6275 4.47796 11.8275 3.94463 11.4275L1.88682 9.88411C1.35343 9.48407 1.35349 8.68397 1.88694 8.28402L11.4004 1.15113C11.7559 0.884581 12.2447 0.884581 12.6002 1.15113L22.1137 8.28402ZM12.1741 21.1026L19.8547 15.371L17.4133 13.5687L12.1741 17.4667L6.935 13.5373L4.49356 15.371L12.1741 21.1026Z"
          fill={props.fill ?? "#858585"}/>
  </svg>
}

const Teams = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={17}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 8a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM20 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM4 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM6 8.676c-.968-.774-1.845-.672-2.964-.672C1.362 8.004 0 9.326 0 10.95v4.765c0 .705.59 1.278 1.315 1.278 3.131 0 2.754.056 2.754-.131 0-3.377-.41-5.853 1.931-8.185ZM13.07 9.04c-1.937-.122-3.62 0-5.072.905-2.43 1.467-1.962 3.443-1.962 5.86 0 .64.69 1.17 1.551 1.17 9.348 0 9.72.228 10.275-.698.182-.313.132-.214.132-3.209 0-2.379-2.732-4.029-4.925-4.029ZM20.964 8.004c-1.125 0-1.997-.101-2.964.672 2.323 2.315 1.931 4.622 1.931 8.184 0 .189-.313.132 2.707.132.751 0 1.362-.594 1.362-1.324v-4.72c0-1.623-1.362-2.944-3.036-2.944Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
)

const Calendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 3.5v2a2 2 0 1 0 4 0v-2h6v2a2 2 0 1 0 4 0v-2a3 3 0 0 1 3 3v2H0v-2a3 3 0 0 1 3-3Zm17 5v8a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-8h20Zm-5-8a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1ZM5 .5a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
)

const File = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={20}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0h7l7 7v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2Zm12 7H9V2l1.875 1.875L11 4l1 1 2 2Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
)

const AddFile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={20}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0h7v6a1 1 0 0 0 1 1h6v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2Zm9 1 4 4h-4V1ZM8 9a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2H9v2a1 1 0 1 1-2 0v-2H5a1 1 0 1 1 0-2h2v-2a1 1 0 0 1 1-1Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
)

const Clock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10Zm-9-6a1 1 0 1 0-2 0v6a1 1 0 0 0 .553.894l4 2a1 1 0 1 0 .894-1.788L11 9.382V4Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
)

const Wrench = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m.474 16.032 8.29-8.29c.317.795.781 1.5 1.394 2.112a6.208 6.208 0 0 0 2.112 1.394L3.968 19.55c-.3.3-.666.45-1.096.45a1.56 1.56 0 0 1-1.107-.45l-1.29-1.315a1.447 1.447 0 0 1-.463-1.096c0-.43.154-.8.462-1.107ZM19.884 5.648a.355.355 0 0 0-.285-.121c-.073 0-.36.144-.859.432-.499.288-1.049.616-1.65.986l-.96.59-2.35-1.302V3.506l3.566-2.057c.13-.09.195-.203.195-.341s-.065-.252-.195-.341c-.357-.244-.793-.432-1.308-.566A5.882 5.882 0 0 0 14.558 0c-1.5 0-2.785.534-3.852 1.6-1.067 1.068-1.6 2.352-1.6 3.853 0 1.502.533 2.786 1.6 3.853 1.067 1.067 2.351 1.6 3.853 1.6 1.144 0 2.193-.338 3.147-1.016a5.354 5.354 0 0 0 2.002-2.647c.186-.544.28-.974.28-1.29a.453.453 0 0 0-.104-.305Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
);

const User = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12Z"
      fill={props.fill ?? "#858585"}
    />
    <path
      d="M13.0694 13.0394C11.133 12.9176 9.44984 13.0408 7.99805 13.9446C5.56857 15.4122 6.03611 17.3882 6.03611 19.8056C6.03611 20.4452 6.72611 20.9753 7.58706 20.9753C16.9354 20.9753 17.3074 21.2027 17.8618 20.2769C18.0436 19.9638 17.9938 20.0633 17.9938 17.0682C17.9938 14.6892 15.2624 13.0394 13.0694 13.0394Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
)

const DataTags = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m13.963 3.978 2.483 2.483a2.001 2.001 0 0 1 .498 2L15.71 12.49l-7.93 7.93L0 12.642l7.899-7.899 4.056-1.26a2 2 0 0 1 2.008.495Zm-3.71 6.19a1.5 1.5 0 1 0 2.121-2.122 1.5 1.5 0 0 0-2.121 2.121v.001Zm7.222 1.047c.542.022.935-.07 1.178-.273a1.5 1.5 0 0 0 .185-2.113c-.11-.131-.285-.252-.527-.364a2 2 0 0 0-.538-1.848L16.05 4.895l2.269-.706a2 2 0 0 1 2.008.496l2.483 2.483a2.001 2.001 0 0 1 .498 2l-1.235 4.028-7.93 7.931-2.795-2.794 5.688-5.688.439-1.43Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
)

const Permissions = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23 11C23 11.5522 22.5523 12 22 12H16C15.4477 12 15 11.5522 15 11V7C15 6.44769 15.4477 6 16 6V5C16 3.34314 17.3431 2 19 2C20.6569 2 22 3.34314 22 5V6C22.5523 6 23 6.44769 23 7V11ZM19 8C18.4477 8 18 8.44769 18 9C18 9.55225 18.4477 10 19 10C19.5523 10 20 9.55225 20 9C20 8.44769 19.5523 8 19 8ZM19 3C17.8972 3 17 3.89722 17 5V6H21V5C21 3.89722 20.1028 3 19 3Z"
      fill={props.fill ?? "#858585"}
    />
    <path
      d="M7 13C9.20914 13 11 11.2091 11 9C11 6.79086 9.20914 5 7 5C4.79086 5 3 6.79086 3 9C3 11.2091 4.79086 13 7 13Z"
      fill={props.fill ?? "#858585"}
    />
    <path
      d="M8.06942 14.0394C6.13299 13.9176 4.44984 14.0408 2.99805 14.9446C0.56857 16.4122 1.03611 18.3882 1.03611 20.8056C1.03611 21.4452 1.72611 21.9753 2.58706 21.9753C11.9354 21.9753 12.3074 22.2027 12.8618 21.2769C13.0436 20.9638 12.9938 21.0633 12.9938 18.0682C12.9938 15.6892 10.2624 14.0394 8.06942 14.0394Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
);

const SystemStatus = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22 17H2C1 17 0 17.6705 0 18.5C0 19.3295 1 20 2 20H22C23 20 24 19.3295 24 18.5C24 17.6705 23 17 22 17Z"
      fill={props.fill ?? "#858585"}
    />
    <path
      d="M20 5H4C2.897 5 2 5.897 2 7V17H4H12H16.5H20H22V7C22 5.897 21.103 5 20 5Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>

);

const SidebarIcons = {
  Home,
  Chat,
  ActivityFeed,
  Organizations,
  ArrowRight,
  Projects,
  Assets,
  Teams,
  Calendar,
  File,
  AddFile,
  Clock,
  Wrench,
  Layers,
  User,
  DataTags,
  Permissions,
  SystemStatus
};

const Icons = {
  SidebarIcons
}
export {SidebarIcons};
export default Icons;
