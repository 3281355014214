import {BaseProps} from "../BaseProps";
import {DocumentReference} from "firebase/firestore";
import {AccessType, PermissionEntity, PermissionOperationKey} from "types/Permission";
import ActionOption from "components/ActionOption";
import {SystemIcons} from "assets/icons/system/system.index";
import {emptyFunction, enCommonLabel, enTemplateButton, enTemplateLabel} from "constants/index";
import {ConfirmDialog, ExportDialog, MenuCard} from "components/index";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAccess, useComponentToggler} from "hooks/index";
import {ActionType, Entity, CounterFields, FeatureType, ProcessType, Severity, ViewStatus} from "enums/index";
import submitForm from "../utility/submitForm";
import ImportDialog from "../../components/Dialogs/ImportDialog";
import {templatesExportsPath} from "../utility";
import {Template} from "types/Template";
import FeatureWrapper from "components/FeatureWrapper";
import useProgressCount from "hooks/useProgressCount";

interface TemplateCardMenuProps extends BaseProps {
  isOpen: boolean;
  docRef: DocumentReference | null;
  closeMenu: () => void;
  access: AccessType | null;
  anchorEl?: Element | null | undefined;
  anchorPosition?: { top: number; left: number } | undefined;
}

function TemplateCardMenu(props: TemplateCardMenuProps) {
  const {isOpen, closeMenu, anchorEl, docRef, access, toastProps, uid, anchorPosition} = props;
  const {setIsToastOpen, setToastSeverity, setToastMessage} = toastProps!;

  const {orgId} = useParams();
  const templateId = docRef?.id ? docRef.id : "fillerId";
  const navigate = useNavigate();
  const {isProcessing} = useProgressCount({
    path: docRef?.path!,
    counterField: CounterFields.ImportingProgressCount,
  });

  const [isDeleteOpen, {open: openDeleteDialog, close: closeDeleteDialog}] = useComponentToggler(false);
  const [isImportDialogOpen, {open: openImportDialog, close: closeImportDialog}] = useComponentToggler(false);
  const [isExportDialogOpen, {open: openExportDialog, close: closeExportDialog}] = useComponentToggler(false);

  const [exportAccess] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationTemplateExport,
  });

  const [importAccess] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationTemplateImport,
  });

  function onDeleteCancel(e: React.MouseEvent<HTMLElement> | undefined) {
    closeDeleteDialog();
    if (e !== undefined) closeMenu();
  }

  async function onDeleteTemplateConfirm() {
    if (!docRef) return;
    await submitForm(docRef, ActionType.Delete, statusHandler);
  }

  function statusHandler(status: ViewStatus, data: Template, isLastUpdate: boolean) {
    if (!isLastUpdate) return;
    switch (status) {
      case ViewStatus.Finished:
        setToastSeverity(Severity.Success);
        setToastMessage(enTemplateLabel.deleteSuccess);
        setIsToastOpen(true);

        navigate(`/${orgId}/templates`);
        break;
      case ViewStatus.SecurityError:
      case ViewStatus.ValidationError:
      case ViewStatus.Error:
        setToastSeverity(Severity.Error);
        setToastMessage(enCommonLabel.errorProcess(ProcessType.Delete));
        setIsToastOpen(true);
        break;
    }
  }

  function onImportClose() {
    closeImportDialog();
    navigate(`/${orgId}/templates`);
    closeMenu();
  }

  if (!isOpen || !anchorEl) return null;

  return (
    <>
      <ImportDialog
        uid={props.uid}
        isOpen={isImportDialogOpen}
        entity={Entity.Templates}
        closeDialog={onImportClose}
        parentDocumentRef={docRef!}
        toastProps={toastProps!}
      />
      <ExportDialog
        isOpen={isExportDialogOpen}
        onClose={closeExportDialog}
        toastProps={toastProps!}
        title={enTemplateLabel.exportDialogTitle}
        exportingTitle={enTemplateLabel.exportingDialogTitle}
        message={enTemplateLabel.exportDialogText}
        exportColRef={templatesExportsPath(orgId!, templateId!)}
      />
      <MenuCard anchorPosition={anchorPosition} open={isOpen} anchorEl={anchorEl} closeMenu={closeMenu}>
        <FeatureWrapper feature={FeatureType.ImportTemplate} viewable>
          <ActionOption
            id="template-overview-import-menu-button"
            Icon={<SystemIcons.Import height={16} width={16}/>}
            label={enTemplateButton.importTemplate}
            onClick={importAccess?.[PermissionOperationKey.Create] ? openImportDialog : emptyFunction}
            disabled={!importAccess?.[PermissionOperationKey.Create] || isProcessing}
          />
        </FeatureWrapper>
        <FeatureWrapper feature={FeatureType.ExportTemplate} viewable>
          <ActionOption
            id="template-overview-export-menu-button"
            Icon={<SystemIcons.Export height={16} width={16}/>}
            label={enTemplateButton.exportTemplate}
            onClick={exportAccess?.[PermissionOperationKey.Create] ? openExportDialog : emptyFunction}
            disabled={!exportAccess?.[PermissionOperationKey.Create]}
          />
        </FeatureWrapper>
        <ActionOption
          id="template-overview-delete-menu-button"
          Icon={<SystemIcons.DeleteOutlined height={16} width={16}/>}
          label={enTemplateButton.deleteTemplate}
          onClick={access?.[PermissionOperationKey.Delete] ? openDeleteDialog : emptyFunction}
          disabled={!access?.[PermissionOperationKey.Delete]}
          labelSx={(theme) => ({color: theme.palette.error.main})}
        />
        <ConfirmDialog
          isOpen={isDeleteOpen}
          handleClose={(e?: React.MouseEvent<HTMLElement>) => onDeleteCancel(e)}
          handleConfirm={onDeleteTemplateConfirm}
          title={enTemplateLabel.deleteDialogTitle(1)}
          text={enTemplateLabel.deleteConfirmationText}
        />
      </MenuCard>
    </>
  );

}

export default TemplateCardMenu;
