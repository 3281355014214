import {useState} from "react";

type DisclosureReturnType = [
  boolean,
  {
    open: () => void;
    close: () => void;
    toggle: () => void;
  }
]

export default function useComponentToggler(initialValue: boolean): DisclosureReturnType {
  const [open, setOpen] = useState<boolean>(initialValue);

  return [open, {
    open: () => setOpen(true),
    close: () => setOpen(false),
    toggle: () => setOpen(!open),
  }];
}