import {Timer} from "types/Timer";
import {Divider, Stack, styled, Typography} from "@mui/material";
import {en} from "language/en";
import {formatDateTimeFirestoreDate, generateEntityBreadcrumbs} from "screens/utility";
import theme from "theme/theme";
import SystemIndex from "assets/icons/system/system.index";
import {doc, Timestamp} from "firebase/firestore";
import {myTimersPath} from "screens/utility/FirebasePath";
import SubmitForm from "screens/utility/submitForm";
import {ActionType} from "enums/actionType";
import {TimerState} from "types/Task";
import {LoadingButton} from "@mui/lab";
import isDocLoading from "screens/utility/isDocLoading";
import AssignableAvatar from "components/AssignableAvatar";
import {useNavigate} from "react-router-dom";
import useTimerHook from "hooks/useTimerHook";
import React, {useEffect, useState} from "react";
import {LongText} from "components/index";
import BreadcrumbsDisplay from "components/BreadcrumbsDisplay";
import {emptyFunction} from "constants/index";

export interface BaseTimerCardProps {
  uid: string;
  timer: Timer
  ActionIcon: JSX.Element
  onActionClick: () => void
  isListLoading: boolean
  actionButtonColor?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | "neutral"
  organizationTimer?: boolean
  collapsible: boolean
}

function BaseTimerCard(props: BaseTimerCardProps) {
  const {timer, ActionIcon, onActionClick, actionButtonColor, organizationTimer, isListLoading, collapsible} = props;
  const {taskName, startDateTime} = timer;
  const {month, day, year} = formatDateTimeFirestoreDate(startDateTime)
  const timerDetails = useTimerHook(timer, organizationTimer)
  const {hours, minutes} = timerDetails;
  const navigate = useNavigate();
  const breadcrumbs = generateEntityBreadcrumbs(timer);

  const [loading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(isDocLoading(timer))
  }, [timer])

  useEffect(() => {
    setIsLoading(isDocLoading(timer))
  }, [timer])

  const url = timer.orgTaskRefPath.slice(14);
  const splitPath = url.split("/");
  const isProjectLevelTask = !/assets/.test(url);

  function onStopButtonClick() {
    const docRef = doc(myTimersPath(props.uid), timer.id)
    SubmitForm<Partial<Timer>>(docRef, ActionType.Update, emptyFunction, {
      timerState: TimerState.Stopped,
      stopDateTime: Timestamp.now(),
      timerLocation: timer.timerLocation
    })
  }

  function handleTimerNavigation() {
    if (!!timer.assetName) navigate(`/${url}`);

    if (isProjectLevelTask)
      splitPath.splice(3, 0, "project-level-tasks");

    navigate(`/${splitPath.join("/")}`);
  }

  function stopButtonClickPropagation(e: React.MouseEvent<HTMLButtonElement>, actionFunction: () => void) {
    e.stopPropagation()
    setIsLoading(true)
    actionFunction()
  }

  return <Stack
    onClick={handleTimerNavigation}
    gap={1}
    direction="row"
    alignItems="center"
    sx={{
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(1),
      paddingX: theme.spacing(2),
      paddingY: theme.spacing(1 / 2),
      position: "relative",
      cursor: "pointer"
    }}
  >
    <Stack alignItems="center" margin="1 auto">
      <Typography variant="h5">
        {Number(hours)} <Typography variant="body" component="span" color="text.secondary">h</Typography>
      </Typography>
      <Typography variant="h5">
        {Number(minutes)} <Typography component="span" color="text.secondary">m</Typography>
      </Typography>
    </Stack>
    <Divider flexItem orientation="vertical"/>

    <Stack flex={1} justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        <LongText variant="h4">
          {taskName}
        </LongText>
      </Stack>
      {collapsible && <Stack direction="row" alignItems="center">
        <BreadcrumbsDisplay
          entityBreadcrumbs={breadcrumbs}
          orgName={timer.orgName}
        />
      </Stack>}
      <Stack direction="row" alignItems="center" gap={1}>
        {
          organizationTimer &&
          <AssignableAvatar {...timer.assigneeData} sx={{margin: 0}}/>
        }
        <Typography color="text.secondary" variant="caption">
          {en.screen.Sidebar.timers.StartedTimers}: {`${month} ${day}, ${year}`}
        </Typography>
      </Stack>
    </Stack>

    <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
      {
        !organizationTimer &&
        <Stack direction={collapsible ? "row" : "column"} gap={1}>
          <SquareButton
            variant="contained"
            color={actionButtonColor}
            onClick={(e) => stopButtonClickPropagation(e, onActionClick)}
            disabled={loading || isListLoading}
          >
            {ActionIcon}
          </SquareButton>
          <SquareButton
            onClick={(e) => stopButtonClickPropagation(e, onStopButtonClick)}
            variant="contained"
            color="error"
            disabled={loading || isListLoading}
          >
            <SystemIndex.SystemIcons.Stop
              width={18}
              height={18}
              stroke={theme.palette.background.paper}
              fill={theme.palette.background.paper}/>
          </SquareButton>
        </Stack>
      }
    </Stack>

  </Stack>
}

const SquareButton = styled(LoadingButton)({
  padding: 0,
  maxWidth: '32px',
  maxHeight: '32px',
  minWidth: '32px',
  minHeight: '32px',
  borderRadius: "100%",
})

export default BaseTimerCard
