import {Box, Hidden, Stack} from "@mui/material";
import {styled} from "@mui/material/styles";
import {Back} from "components";
import PillItem, {PillItemProps} from "./PillItem";
import theme from "theme/theme";
import Icons from "assets/icons";
import {ReactNode} from "react";

interface PilledNavBarProps {
  pills: PillItemProps [];
  middleComponent?: JSX.Element;
  children?: ReactNode;
  onBack?: () => void;
}

function PilledNavBar(props: PilledNavBarProps) {
  const {pills, children, middleComponent, onBack} = props;

  function renderPills() {
    let pillsList: any = [];

    pills.forEach((obj, index) => {
      // last pill will have a different color
      const isLast = index === pills.length - 1;

      // all of the pills have an arrow except for the last pill
      if (index !== pills.length && index !== 0) {
        pillsList.push(
          <Hidden smDown>
            <Icons.ManageAssetIcons.ArrowRightAlt key={`pill_${obj.id}_${index}`}/>
          </Hidden>
        );
      }

      if (!isLast) pillsList.push(<Hidden smDown><PillItem {...obj} key={`pill_${obj.id}_${index}`} isLast={isLast}/></Hidden>);
      else pillsList.push(<PillItem {...obj} key={`pill_${obj.id}_${index}`} isLast={isLast}/>);
    });

    return pillsList;
  }

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        sx={{backgroundColor: "white", position: "sticky", top: 0, zIndex: 2}}
      >
        <NavBox>
          <Back onBack={onBack}/>
          <Stack
            direction="row"
            sx={{gap: 1, alignItems: "center", width: "100%", justifyContent: "space-between"}}
          >
            <Stack direction="row" alignItems="center" gap={1} marginLeft={4}>
              {renderPills()}
            </Stack>
            {middleComponent}
            <Stack direction="row" sx={{gap: 1, justifyContent: 'right'}}>
              {children}
            </Stack>
          </Stack>
        </NavBox>
      </Stack>
    </>
  )
}

/** styles related */
const NavBox = styled(Box)({
  padding: 13,
  borderBottom: `1px solid ${theme.palette.divider}`,
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "relative",
});
/** end of styles */

export {PilledNavBar};
