import {Box, Typography, Link, Stack} from "@mui/material";
import {useState} from "react";
import {Link as RouterLink, useLocation} from "react-router-dom";
import theme from "theme/theme";

export interface SidebarLinkProps {
  selectedBarId: string;
  Label: string;
  Icon: any;
  Path: string;
  order: number;
  iconProps?: any;
  pathName: string,
  toggleSidebar: () => void;
}

type PathStyle = {
  typo: any;
  icon: any;
  marker: any
  hover: any
}

function Item(props: SidebarLinkProps) {
  const {Label, Icon, Path, order, selectedBarId, iconProps, pathName, toggleSidebar} = props;
  const [hoverState, setHoverState] = useState<boolean>(false);
  const mouseActivity = (e: any) => {
    setHoverState(e.type === "mouseenter");
  }
  const loc = useLocation();
  const style = ((loc.pathname.split("/")).includes(pathName)) ?
    pathStyling.active :
    pathStyling.inactive;

  return (
    <Link
      component={RouterLink}
      onClick={toggleSidebar}
      to={Path}
    >
      <Stack
        direction="row"
        flex={1}
        alignItems="center"
        justifyContent="flex-start"
        gap={2}
        onMouseEnter={mouseActivity}
        onMouseLeave={mouseActivity}
        order={order}
      >
        <Box
          id={selectedBarId}
          sx={{
            width: 4,
            height: 32,
            left: 0,
            top: 0,
            borderTopRightRadius: 2,
            borderBottomRightRadius: 2,
            ...style.marker
          }}
        />
        <Icon
          width={24}
          height={24}
          {...iconProps}
          style={{
            ...iconProps?.style
          }}
          {...((hoverState) ? style.hover.icon : style.icon)}
        />
        <Typography
          variant="h5"
          sx={{
            width: 200,
            height: 22,
            ...((hoverState) ? style.hover.typo : style.typo),
            ":hover": style.hover.typo
          }}
        >
          {Label}
        </Typography>
      </Stack>
    </Link>
  )
}

const pathStyling: { active: PathStyle, inactive: PathStyle } = {
  active: {
    typo: {
      color: theme.palette.common.white,
    },
    icon: {
      fill: theme.palette.common.white,
    },
    marker: {
      backgroundColor: "#2B8DFF",
    },
    hover: {
      container: {
        backgroundColor: theme.palette.neutral.dark,
      },
      typo: {
        color: theme.palette.common.white,
      },
      icon: {
        fill: theme.palette.common.white,
      }
    }
  },
  inactive: {
    typo: {
      color: theme.palette.neutral.dark
    },
    icon: {
      fill: theme.palette.neutral.dark
    },
    marker: {
      backgroundColor: "",
    },
    hover: {
      container: {
        backgroundColor: theme.palette.neutral.dark,
      },
      typo: {
        color: theme.palette.common.white,
      },
      icon: {
        fill: theme.palette.common.white,
      }
    }
  }
}

export default Item;
