export const project = {
  title: "Projects",
  subtitle: "",
  description: "",
  label: {
    assets: "Assets",
    noTemplates: "No Available Templates",
    new: "Create New Project",
    edit: "Edit Project",
    emptyTitle: "No Projects yet",
    emptyDescription: "Create the first project.",
    emptyDescriptionTemplate: "When you add projects to this template they will show up here.",
    search: "Search Project...",
    template: "Project Template",
    searchTemplate: "Search Template",
    chooseTemplate: "Choose Template",
    createSuccess: (name: string) =>  `Project ${name} was created successfully.`,
    editSuccess: (name: string) => `Project ${name} was edited successfully.`,
    deleteConfirmationTitle: (name: string) => `Are you sure you want to delete ${name} project?`,
    deleteConfirmationText: "These items will be deleted permanently. You can't undo this action",
    overview: "Project Overview",
    assetsAndTasks: "Assets & Tasks",
    manageAccess: "Manage Project Access",
    manageAsset: "Manage Project Assets",
    private: "Private Project",
    status: "Project Status",
    notifications: "Project Notifications",
    chat: "Project Chat",
    moreOptions: "More Options",
    projectLevelTasks: "Project Level Tasks",
    projectLevelTasksDescription: "Tasks that are not assigned to an asset.",
    unassignedTasksText: "Tasks that are not assigned to the asset",
    requiredProjectName: "Project Name is required",
    projectNameAlreadyExists: "Project Name already exists",
    deleteSuccess: "Project deleted successfully",
    projects: "Projects",
    importingProjectData: "Importing Project Data...",
    buildingProjectData: "Building Project Data...",
    exportProjectData: "Export Project Data",
    exportingProjectData: "Exporting Project Data",
    exportProjectDataText: "Exporting all Project data"
  },
  button: {
    create: "Create New Project",
    downloadTimeData: "Download Time Data",
    importProjectData: "Import Project Data",
    exportProjectData: "Export Project Data",
    deleteProject: "Delete Project",
  }
}