import { Link } from "@mui/material";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import theme from "../theme/theme";

interface LinkTabProps {
  isActive: boolean;
  to: string;
}

const Tab: FC<LinkTabProps> = ({ isActive, to, children }) => {
  return (
    <Link
      borderBottom={
        isActive
          ? `2px solid ${theme.palette.primary.main}`
          : "2px solid transparent"
      }
      paddingY={1}
      component={RouterLink}
      to={to}
    >
      {children}
    </Link>
  );
};

export default Tab;
