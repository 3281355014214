import {Checkbox, FormControl, FormControlLabel, Typography} from "@mui/material";

interface LabeledCheckboxProps {
  onChange: () => void;
  checked: boolean;
  label: string;
  id?: string;
}

export default function LabeledCheckbox(props: LabeledCheckboxProps) {
  const {onChange, checked, label, id = "labeled-checkbox"} = props;
  return <FormControl>
    <FormControlLabel
      value={checked}
      control={
        <Checkbox
          id={id}
          onChange={onChange}
          checked={checked}
        />
      }
      label={
        <Typography variant="h5">
          {label}
        </Typography>
      }
    />
  </FormControl>
}