import {BaseProps} from "screens/BaseProps";
import {Entity, FileFormat, FormFieldType, Severity} from "enums/index";
import {DialogActions, DialogContentText,} from "@mui/material";
import React, {useEffect, useState} from "react";
import {enCommonButton, enCommonLabel, enFormsLabel, mimeType} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {BaseDialog} from "components/Dialogs/index";
import {ImportButton} from "components/Button";
import {Form} from "types/Form";
import {FormField} from "types/FormField";
import toTitleCase from "../utility/toTitleCase";
import {read, utils as excelParser} from "xlsx";
import {formImportCsvToFrontendMap, formImportOptionsArray} from "enums/FormImportOptions";
import ImportButtonInfoDialog from "components/Dialogs/ImportButtonInfoDialog";

interface FormImportDialogProps extends BaseProps {
  isOpen: boolean;
  closeDialog: () => void;
  onParse: (data: Form) => void;
  formData: Form;
}

type SelectedState = Record<FileFormat, { isLoading: boolean, selectedFile: File | null }>;

const selectedInitialState = {
  [FileFormat.CSV]: {isLoading: false, selectedFile: null},
  [FileFormat.Excel]: {isLoading: false, selectedFile: null}
}

export function FormImportDialog(props: FormImportDialogProps) {
  const {toastProps, isOpen, formData, closeDialog, onParse} = props;
  const {setToastMessage, setIsToastOpen, setToastSeverity} = toastProps!;
  const [selectedState, setSelectedState] = useState<SelectedState>(selectedInitialState);

  // reset states on close
  useEffect(() => {
    if (!isOpen) setSelectedState(selectedInitialState);
  }, [isOpen]);

  useEffect(() => {
    const selectedFormat = Object.values(selectedState)
      .find((entry) => !!entry.selectedFile);
    if (!selectedFormat) return;
    const file = selectedFormat.selectedFile!;
    file.arrayBuffer().then((fileBuffer) => {
      const workBook = read(fileBuffer);
      const sheetNames = workBook.SheetNames;
      if (!sheetNames.length) {
        setSelectedState(selectedInitialState);
        setToastMessage(enFormsLabel.emptyImportData);
        setToastSeverity(Severity.Error);
        setIsToastOpen(true);
        return;
      }
      const sheet = workBook.Sheets[sheetNames[0]];
      const fileContents = excelParser.sheet_to_json<any>(sheet, {header: 0}); // any as there's a header and needs to be manually changed
      const parsedFields: (FormField<any> | undefined)[] = fileContents.map(parseJsonToCsv);
      if (parsedFields.includes(undefined)) {
        setToastMessage(enFormsLabel.importError);
        setToastSeverity(Severity.Error);
        setIsToastOpen(true);
        closeDialog();
        return;
      }

      onParse({...formData, fields: parsedFields as FormField<any>[]});
      setToastMessage(enFormsLabel.importSuccess);
      setToastSeverity(Severity.Success);
      setIsToastOpen(true);
      closeDialog();
    });
  }, [selectedState]);

  function parseJsonToCsv(field: any /*Intended any due to csv/xlsx headers*/): FormField<any> | undefined {
    const [title, type] = Object.values(field);
    // @ts-ignore
    const fieldType = FormFieldType[toTitleCase(type) as FormFieldType];
    if (!fieldType) {
      return;
    }

    return {
      // @ts-ignore
      title: title ?? "",
      // @ts-ignore
      type: fieldType,
      responseIsRequired: field["response_is_required"] ?? false,
      description: field["description"] ?? "",
      //get other advanced options, edit the enum Form input options to modify what we can read from the excel
      ...getAdvancedOptions(field)
    }
  }

  function getAdvancedOptions(field: any): Partial<FormField<any>> {
    const result: any = {}

    formImportOptionsArray.forEach((option) => {
      //process advanced options
      if (option === "items") {
        if (field["items"]) {
          result["items"] = field["items"].split(", ").map((item: string) => item.replaceAll("\"", ""));
        }
      }
      //otherwise, just copy the value over
      //excel uses snake case, while our data uses camel case
      else if (field[option]) {
        result[formImportCsvToFrontendMap[option]] = field[option];
      }
    })

    return result;
  }

  function openFileExplorer(fileType: FileFormat) {
    setSelectedState((prevState) => ({
      ...prevState,
      [fileType]: {
        ...selectedState[fileType],
        isLoading: true,
      }
    }));

    const input = document.createElement("input");
    input.type = "file";
    input.accept = mimeType[fileType];
    input.addEventListener("change", () => {
      const file = input.files?.[0];
      if (!file) return;

      // check file type before uploading
      if (file && file.type !== mimeType[fileType]) {
        setToastMessage(enCommonLabel.invalidFileType);
        setToastSeverity(Severity.Error);
        setIsToastOpen(true);

        setSelectedState((prevState) => ({
          ...prevState,
          [fileType]: {
            ...selectedState[fileType],
            isLoading: false,
          }
        }));
        return;
      }

      setSelectedState((prevState) => ({
        ...prevState,
        [fileType]: {
          isLoading: false,
          selectedFile: file
        }
      }));
    });

    input.click();
  }

  return (
    <BaseDialog
      {...props} title={enCommonLabel.importEntity(Entity.Form)}
      additionalActions={<ImportButtonInfoDialog entity={Entity.Form} />}
    >
      <>
        <DialogContentText
          sx={{fontSize: 14, color: "black", maxWidth: {sm: "auto", md: "24rem"}}}
          id="alert-dialog-description"
        >
          {enCommonLabel.importEntityDescription(Entity.Form)}
        </DialogContentText>
        <DialogActions sx={{display: "flex", justifyContent: "center", paddingLeft: 0, paddingRight: 0, gap: 1.5}}>
          <ImportButton
            id="upload-button-csv"
            isLoading={selectedState[FileFormat.CSV].isLoading}
            isImportAvailable={false /*Default to false as the dialog will be closed on file select*/}
            onClickFcn={() => openFileExplorer(FileFormat.CSV)}
            label={enCommonButton[FileFormat.CSV]}
            disabled={selectedState[FileFormat.CSV].isLoading}
            startIcon={SystemIcons.CSV}
          />
          <ImportButton
            id="upload-button-csv"
            isLoading={selectedState[FileFormat.Excel].isLoading}
            isImportAvailable={false /*Default to false as the dialog will be closed on file select*/}
            onClickFcn={() => openFileExplorer(FileFormat.Excel)}
            label={enCommonButton[FileFormat.Excel]}
            disabled={selectedState[FileFormat.Excel].isLoading}
            startIcon={SystemIcons.Excel}
          />
        </DialogActions>
      </>
    </BaseDialog>
  )
}
