import {Badge} from "@mui/material";
import theme from "theme/theme";
import React from "react";

interface DotIndicatorProps {
  badgeStyle?: any;
  children: React.ReactNode;
  isVisible?: boolean;
}

export default function DotIndicator(props: DotIndicatorProps) {
  const {isVisible, badgeStyle, children} = props;

  return (
    <Badge
      color="error"
      variant="dot"
      invisible={!isVisible}
      overlap="circular"
      componentsProps={{
        badge: {
          style: {
            width: "12px",
            height: "12px",
            top: 4,
            right: 4,
            boxSizing: "border-box",
            borderRadius: "50%",
            border: `2px solid ${theme.palette.background.swiftDefault}`,
            ...badgeStyle,
          }
        }
      }}>
      {children}
    </Badge>
  )
}