import {FileStatus, Previews} from "enums/index";
import {getFileType} from "screens/utility";
import PDFPreview from "./PDFPreview";
import RejectedVideoPreview from "./RejectedVideoPreview";
import VideoPreview from "./VideoPreview";
import RejectedImagePreview from "./RejectedImagePreview";
import ImagePreview from "./ImagePreview";
import RejectedPDFPreview from "components/FilePreviews/RejectedPDFPreview";
import FilePreview from "components/FilePreviews/FilePreview";
import RejectedFilePreview from "components/FilePreviews/RejectedFilePreview";
import React from "react";
import ZipPreview from "components/FilePreviews/ZipPreview";
import RejectedZipPreview from "components/FilePreviews/RejectedZipPreview";
import LoadingPreview from "components/FilePreviews/LoadingPreview";
import useFileTitle from "hooks/useFileTitle";
import RejectedDocumentPreview from "components/FilePreviews/RejectedDocumentPreview";
import DocumentPreview from "components/FilePreviews/DocumentPreview";

interface PreviewItemProps {
  key?: string;
  filePreview: {
    downloadUrl?: string;
    contentType?: Previews;
    fileStatus: FileStatus;
    previewValue?: string;
    id: string;
    name?: string;
  };
  itemSx?: any,
  differentRejectedPreview?: boolean;
  withBorder?: boolean;
  iconSize?: number;
  onClick: () => void;
  isLoading?: boolean;
}

function PreviewItem(props: PreviewItemProps) {
  const {filePreview, itemSx = {}, differentRejectedPreview = false, iconSize=24, withBorder, onClick, isLoading = false} = props;
  const {contentType = Previews.PDF, fileStatus, downloadUrl, id, name} = filePreview;
  const fileTitle = useFileTitle(name!);

  const type = getFileType(contentType);

  const isRejected = ([FileStatus.Rejected, FileStatus.Failed].includes(fileStatus)) && differentRejectedPreview;

  const prieviewProps = {
    id,
    title: fileTitle,
    onClick,
  }

  if (isLoading) return <LoadingPreview itemSx={itemSx} id={id} withBorder={withBorder}/>;

  switch (type) {
    case Previews.PDF:
      if (isRejected)
        return <RejectedPDFPreview {...prieviewProps}/>;
      return <PDFPreview {...prieviewProps} itemSx={itemSx} withBorder={withBorder}/>;
    case Previews.Video:
      if (isRejected)
        return <RejectedVideoPreview {...prieviewProps} iconSize={iconSize} />
      return <VideoPreview {...prieviewProps} itemSx={itemSx} iconSize={iconSize} withBorder={withBorder}/>
    case Previews.Image:
      if (isRejected)
        return <RejectedImagePreview {...prieviewProps} downloadUrl={downloadUrl} />
      return <ImagePreview {...prieviewProps} downloadUrl={downloadUrl} differentRejectedPreview={differentRejectedPreview} withBorder={withBorder}/>
    case Previews.Zip:
      if (isRejected)
        return <RejectedZipPreview {...prieviewProps} iconSize={iconSize} />
      return <ZipPreview {...prieviewProps} itemSx={itemSx} iconSize={iconSize}/>
    case Previews.Document:
      if (isRejected)
        return <RejectedDocumentPreview {...prieviewProps} iconSize={iconSize} />
      return <DocumentPreview {...prieviewProps} itemSx={itemSx} iconSize={iconSize}/>
    default:
      if (isRejected)
        return <RejectedFilePreview {...prieviewProps} iconSize={iconSize} />
      return <FilePreview {...prieviewProps} itemSx={itemSx} iconSize={iconSize}/>
  }
}

export default PreviewItem;
