import {Box, Stack} from "@mui/material";
import {StatusIcons} from "assets/icons/status/status.index";
import {StatusChangeItemType} from "types/ActivityFeedTypes";
import TimestampTypo from "components/Typography/TimestampTypo";
import {enActivityFeedLabel} from "constants/index";
import {formatDateTimeFirestoreDate} from "screens/utility/formatDateTimeFirestoreDate";
import {RequirementStatus} from "enums/requirementStatus";
import {getActivityName} from "components/ActivityFeed/utils";
import {ENTITY_LABEL} from "language/en/common";
import ItemContainer from "components/ActivityFeed/ItemContainer";

interface statusChangeProps {
  item: StatusChangeItemType
}

function StatusChangeItem(props: statusChangeProps) {
  const {item} = props;
  const {details, timestamp, entity} = item;
  const {to = "", by, from = "", reasonForChange} = details;
  const {date, time} = formatDateTimeFirestoreDate(timestamp);

  let icon = <></>;

  switch (to) {
    //TODO: investigate no completed icon

    case RequirementStatus.Approved:
      icon = <StatusIcons.Approved {...iconBaseProps}/>;
      break;
    case RequirementStatus.Pending:
      icon = <StatusIcons.Pending{...iconBaseProps}/>;
      break
    case RequirementStatus.InProgress:
      icon = <StatusIcons.InProgress{...iconBaseProps}/>;
      break
    case RequirementStatus.Cancelled:
      icon = <StatusIcons.Cancelled {...iconBaseProps}/>;
      break
    case RequirementStatus.Rejected:
      icon = <StatusIcons.Rejected {...iconBaseProps}/>;
      break;
    case RequirementStatus.Submitted:
      icon = <StatusIcons.Submitted {...iconBaseProps}/>;
      break;
    case RequirementStatus.HasRejection:
      icon = <StatusIcons.HasRejection {...iconBaseProps}/>;
  }

  return <ItemContainer>
    <Box width={24} height={24}>
      {icon}
    </Box>
    <Stack direction="column">
      {enActivityFeedLabel.statusChange(to, from, by.name ?? "", getActivityName(item), ENTITY_LABEL[entity], reasonForChange)}
      <TimestampTypo>{`${date} ${time}`}</TimestampTypo>
    </Stack>
  </ItemContainer>
}

//styles and base props

const iconBaseProps = {width: 24, height: 24}


export default StatusChangeItem
