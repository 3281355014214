import {Route, Routes} from "react-router-dom";
import RequireAuth from "./components/RequireAuth";
import CssBaseline from "@mui/material/CssBaseline";
import SignUp from "screens/SignUp";
import Dashboard from "screens/Dashboard";
import LogIn from "screens/LogIn";
import SignUpSuccess from "screens/SignUpSuccess";
import React, {useEffect, useState} from "react";
import InvalidInvitation from "screens/ViewInvitation/InvalidInvitation";
import InvitationRoute from "./screens/ViewInvitation/InvitationRoute";
import VerifyToken from "./screens/VerifyToken";
import {ResetPassword} from "./screens/ResetPassword";
import {Box} from "@mui/material";
import {UploadContextProvider} from "hooks/useFileUpload/context/UploadContext";
import SelectedOrgContextProvider from "./screens/SelectedOrgContextProvider";
import Maintenance from "./screens/Maintenance";
import DeveloperLogIn from "./screens/DeveloperLogin";

function App() {
  const [context, setContext] = useState<AudioContext | null>(null);

  useEffect(() => {
    if(localStorage.getItem("userSettings") === null){
      localStorage.setItem("userSettings", JSON.stringify({volume: 0.5}))
    }

    setContext(new AudioContext());
  }, []);

  return (
    <Box onClick={() => context?.resume()}>
      <CssBaseline/>
        <Routes>
          <Route
            path="under-maintenance"
            element={<Maintenance/>}
          />
          <Route path="developer-login" element={<DeveloperLogIn />}/>
          <Route path="login" element={<LogIn />}/>
          <Route path="reset-password" element={<ResetPassword/>}/>
          <Route path="sign-up" element={<SignUp/>}/>
          <Route path="sign-up/success" element={<SignUpSuccess/>}/>
          <Route path="verify" element={<VerifyToken/>}/>
          <Route
            path="viewInvitation/:invitationId"
            element={<InvitationRoute/>}
          />
          <Route
            path="invalid-invitation"
            element={<InvalidInvitation/>}
          />
          <Route
            path="/*"
            element={
              <RequireAuth>
                <UploadContextProvider>
                  <SelectedOrgContextProvider>
                    <Dashboard/>
                  </SelectedOrgContextProvider>
                </UploadContextProvider>
              </RequireAuth>
            }
          />
        </Routes>
    </Box>
  );
}

export default App;
