import {CollectionReference, doc} from "firebase/firestore";
import {BaseProps} from "screens/BaseProps";
import {Stack} from "@mui/material";
import BulkListHeader from "./BulkListHeader";
import React, {Dispatch, useContext, useEffect, useState} from "react";
import {SelectedItem} from "components/Drawers/AddTeamOrPeopleDrawer";
import {AccessRole, ActionType, ProcessType, Severity} from "enums/index";
import ListItem, {AccessListUserWithPermission} from "./ListItem";
import {enCommonLabel, enCommonManageAccess} from "constants/index";
import DeleteDialog from "components/Dialogs/DeleteDialog";
import {AccessListUser} from "types/AccessListUser";
import {AccessRoleMenuType} from "./AccessRoleMenu";
import {AccessType} from "types/Permission";
import {auth} from "../../../../firebase";
import {bulkStatusSubmitHandler, StatusSubmitHandler} from "screens/utility/statusSubmitHandler";
import {SelectedOrgContext} from "screens/SelectedOrgContextProvider";
import templateSubmitForm from "screens/utility/templateSubmitForm";

interface PeopleListProps extends BaseProps {
  people: AccessListUser[] | null;
  collectionRef: CollectionReference;
  access: AccessType;
  allowSelect: boolean;
  allowRemove: boolean;
  selectedTeams: SelectedItem[];
  selectedPeople: SelectedItem[];
  setSelectedPeople: Dispatch<SelectedItem[]>;
  setProcessedPeopleMessage?: Dispatch<string>;
}

interface StringBooleanDictionary {
  [key: string]: boolean
}

enum DeleteMode {
  single,
  multiple
}

function PeopleList(props: PeopleListProps) {
  const {
    people,
    allowRemove,
    allowSelect,
    collectionRef,
    access,
    toastProps,
    selectedPeople,
    setSelectedPeople,
    setProcessedPeopleMessage,
  } = props;
  const {setToastMessage, setIsToastOpen, setToastSeverity} = toastProps!;

  const {uid} = auth.currentUser ?? {uid: "fillerId"};
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [localPeople, setLocalPeople] = useState<AccessListUserWithPermission[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // delete states
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [deleteMode, setDeleteMode] = useState<DeleteMode>(DeleteMode.single);
  const [deleteItem, setDeleteItem] = useState<AccessListUser | null>(null);
  const [processingItems, setProcessingItems] = useState<StringBooleanDictionary>({});

  const [deleteLength, setDeleteLength] = useState<number>(0);
  const [deleteSubmittedData, setDeleteSubmittedData] = useState<any[]>([]);

  const selectedOrgContext = useContext(SelectedOrgContext);
  const {selectedOrg} = selectedOrgContext!;

  // only trigger bulkstatusdelete when deleteSubmittedData changes
  useEffect(() => {
    if (deleteSubmittedData.length === 0 || deleteSubmittedData.length !== deleteLength) return;
    bulkStatusSubmitHandler({
      data: deleteSubmittedData,
      successCallback: () => successCallback(enCommonManageAccess.deleteSuccess("member/s")),
      errorCallback: () => errorCallback(ProcessType.Delete)
    });
    setDeleteSubmittedData([]);
    setDeleteLength(0);
  }, [deleteSubmittedData, deleteLength]);

  // create a local copy for the people list with additional canEdit and canDelete fields
  useEffect(() => {
    if (!people) return;

    const newPeople = people.map((person) => {
      // @ts-ignore
      let canEditPermission = access[`Manage${person.role?.replace(" ", "")}`] && person["@id"] !== uid;
      // @ts-ignore
      let canDeletePermission = access[`Manage${person.role?.replace(" ", "")}`] && person["@id"] !== uid;

      return {
        ...person,
        canEdit: canEditPermission,
        canDelete: canDeletePermission,
      }
    });

    setLocalPeople(newPeople);
  }, [JSON.stringify(people), JSON.stringify(access)]);

  // update state for list checkbox (topmost) based on the selected updates
  useEffect(() => {
    if (localPeople.length === 0) return;
    const editablePeople = localPeople.filter(person => person.canEdit && person.canDelete);
    setIsAllSelected(editablePeople.length === selectedPeople.length);
  }, [JSON.stringify(selectedPeople)]);

  async function triggerBulkEdit(selected: AccessRole) {
    setIsLoading(true);

    const selectedCopy = chooseRoleBulk(selected);
    const memberIds = Object.fromEntries(
        selectedCopy.map(person => {
          setProcessingItems((prev: StringBooleanDictionary) => ({...prev, [person["@id"]]: true}));
          return [person["@id"], {role: person.role}];
        })
    );

    let submittedData: StatusSubmitHandler<any>[] = [];
    await Promise.all(
      selectedCopy.map(async (person) => {
        const personRef = doc(collectionRef, person["@id"]);

        const formData = {
          role: person.role,
          bulkActions: {...memberIds}
        }
        await templateSubmitForm(personRef.path, ActionType.Update,
          (status, data, isLastUpdate) => {
            if (isLastUpdate) {
              submittedData.push({status, data, isLastUpdate});
              setProcessingItems((prev: StringBooleanDictionary) => ({...prev, [person["@id"]]: false}));
            }
          }, formData);
      })
    );

    bulkStatusSubmitHandler({
      data: submittedData,
      successCallback: () => successCallback(enCommonManageAccess.editSuccess("member/s")),
      errorCallback: () => errorCallback(ProcessType.Update)
    })
  }

  function chooseRoleBulk(selected: AccessRole) {
    const selectedCopy = selectedPeople.map(person => ({...person, role: selected}));
    const peopleCopy = (localPeople ?? []).map(person => {
      return {...person, role: !allowSelect && !allowRemove ? undefined : person.role};
    });

    setLocalPeople(peopleCopy);
    setSelectedPeople(selectedCopy);

    return selectedCopy;
  }

  function triggerMultipleDelete() {
    setDeleteMode(DeleteMode.multiple);
    setIsDeleteDialogOpen(true);
  }

  function selectAllPeople() {
    if (isAllSelected) {
      // delete everything
      setSelectedPeople([]);
      return;
    }

    const editablePeople = localPeople.filter(person => person.canEdit && person.canDelete);

    const newSelectedPeople = editablePeople.map(person => {
      let obj: SelectedItem = {
        "@id": person["@id"],
        role: allowSelect && allowRemove ? person.role : undefined
      };

      return obj;
    });

    setSelectedPeople(newSelectedPeople);
  }

  async function removeSelectedPeople() {
    if (!localPeople) return;

    const toDeleteMember = deleteMode === DeleteMode.single ? [deleteItem!]
      : selectedPeople.filter(person =>
        localPeople.filter(member => member["@id"] === person["@id"] && member.canDelete).length > 0);
    const bulkActions = Object.fromEntries(
      toDeleteMember.map(member => {
        setProcessingItems((prev: StringBooleanDictionary) => ({...prev, [member["@id"]]: true}));

        return [member["@id"], {role: member.role}];
      })
    );
    setDeleteLength(toDeleteMember.length)
    await Promise.all(
      toDeleteMember.map(async (person) => {
        const personRef = doc(collectionRef, person["@id"]);
        await templateSubmitForm(personRef.path, ActionType.Delete,
          (status, data, isLastUpdate) => {
            isLastUpdate && setDeleteSubmittedData(prev => [...prev, {status, data, isLastUpdate}])
          },
          bulkActions
        );
      })
    );
  }

  function successCallback(message: string) {
    setIsLoading(false);
    setSelectedPeople([]);

    setDeleteItem(null);
    setIsDeleteDialogOpen(false);

    setProcessedPeopleMessage?.(message);
  }

  function errorCallback(process: ProcessType) {
    setToastSeverity(Severity.Error);
    setToastMessage(enCommonLabel.errorProcess(process));
    setIsToastOpen(true);
    setIsLoading(false);
    setSelectedPeople([]);
    setDeleteItem(null);
    setIsDeleteDialogOpen(false);
  }

  function triggerSingleDelete(item: AccessListUser) {
    setDeleteMode(DeleteMode.single);
    setDeleteItem(item);
    setIsDeleteDialogOpen(true);
  }

  function updateSelectedList(id: string, checked: boolean, role?: AccessRole) {
    let selectedCopy = selectedPeople.map(person => ({...person}));

    selectedCopy = selectedCopy.filter(person => person["@id"] !== id);
    if (checked) {
      selectedCopy = [...selectedCopy, {"@id": id, role}];
    }

    setSelectedPeople(selectedCopy);
  }

  if (!people || !selectedOrg) return null;

  return (
    <Stack direction="column" id="people-list">
      <BulkListHeader
        listLength={people.length}
        isAllSelected={isAllSelected}
        selectedItems={selectedPeople}
        access={access}
        isLoading={isLoading}
        triggerBulkEdit={triggerBulkEdit}
        triggerMultipleDelete={triggerMultipleDelete}
        selectAll={selectAllPeople}
        type={AccessRoleMenuType.Member}
      />
      {localPeople.map((person, index) => {
        const selected = (selectedPeople.filter(people => person["@id"] === people["@id"]).length > 0);

        return <ListItem
          collectionRef={collectionRef}
          key={`people-${index}`}
          allowSelect={allowSelect}
          allowRemove={allowRemove}
          member={person as AccessListUserWithPermission}
          isSelected={selected}
          isLoading={(selected && isLoading) || processingItems[person["@id"]]}
          access={access}
          deleteSelected={triggerSingleDelete}
          setSelected={updateSelectedList}
          toastProps={toastProps}
          uid={props.uid}
          teamMemberUids={[]}
          type={AccessRoleMenuType.Member}
        />
      })}
      <DeleteDialog
        overlayId="delete-selected-dialog"
        confirmButtonId={`people-list-confirm-delete`}
        cancelButtonId={`people-list-cancel-delete`}
        isOpen={isDeleteDialogOpen}
        title={enCommonManageAccess.deleteXMembersTitle(
          deleteMode === DeleteMode.multiple ? selectedPeople.length
            : !deleteItem ? "" : deleteItem.name)}
        text={deleteMode === DeleteMode.multiple ? enCommonManageAccess.deleteMemberSubtitle
          : enCommonManageAccess.deleteSingleMemberSubtitle}
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleConfirm={removeSelectedPeople}
      />
    </Stack>
  )
}

export default PeopleList;