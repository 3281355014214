import {Checkbox, Divider, Stack} from "@mui/material";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {emptyFunction, enCommonLabel} from "constants/index";
import {LongText} from "components/index";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import {BaseProps} from "../../BaseProps";
import {ReferenceFile} from "types/ReferenceFile";
import React from "react";
import {PermissionOperationKey} from "types/Permission";
import useGenerateFileReferenceIcon from "../utils/useGenerateFileReferenceIcon";
import {SwiftIconButton} from "components/Button";
import {useParams} from "react-router-dom";
import {referenceFilespath} from "../../utility";
import {doc} from "firebase/firestore";

interface FormItemProps extends BaseProps {
  referenceFile: ReferenceFile;
  orgReferenceFilesAccess: any;
  onDeleteClick: (formId: string) => void;
  isSelected: boolean;
  onItemSelectToggle: (refId: string, checked: boolean) => void;
  onEditClick: (referenceFile: ReferenceFile) => void;
}

export function ReferenceFileItem(props: FormItemProps) {
  const {
    referenceFile,
    orgReferenceFilesAccess,
    onDeleteClick,
    onItemSelectToggle,
    isSelected,
    toastProps,
    onEditClick
  } = props;
  const {name, description, dataTagsIds = []} = referenceFile;
  const displayedDescription = description?.replace(/<br>/g, " ");
  const id = referenceFile["@id"]!;
  const {orgId} = useParams();

  const withDeleteAccess = Boolean(orgReferenceFilesAccess?.[PermissionOperationKey.Delete]);
  const withUpdateAccess = Boolean(orgReferenceFilesAccess?.[PermissionOperationKey.Update]);

  function handleDeleteForm() {
    onDeleteClick(id); // Created a new function for simplicity in html part of the file.
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      px={1.5}
      alignItems="center"
      sx={{
        "&:hover": {
          borderColor: theme.palette.primary.main,
          cursor: "pointer",
        },
      }}
      border={`1px solid ${isSelected ? theme.palette.primary.main : theme.palette.neutral.light}`}
      borderRadius={1}
    >
      <Stack
        direction="row"
        gap={1.5}
        alignItems="center"
        flex={1}
        onClick={withUpdateAccess ? () => onEditClick(referenceFile) : emptyFunction}
        height="100%"
      >
        <Checkbox
          id="isPrivateBox"
          sx={{padding: 0}}
          checked={isSelected}
          onClick={(e) => e.stopPropagation()}
          onChange={(e, checked) => {
            onItemSelectToggle(id, checked);
          }}
        />
        <Divider orientation="vertical" flexItem/>
        {useGenerateFileReferenceIcon(referenceFile.filepath)}
        <Stack gap={0} flex={1} py={1}>
          <LongText variant="h4" maxWidth="100%">{name}</LongText>
          <LongText variant="body" color={theme.palette.text.secondary}>
            {!!displayedDescription ? displayedDescription : enCommonLabel.noDescription}
          </LongText>
          <DataTagsDisplay
            uid={props.uid}
            dataTagsIds={dataTagsIds}
            toastProps={toastProps!}
            listSize="medium"
            canEditDocumentRef={withUpdateAccess}
            documentRef={doc(referenceFilespath(orgId!), id)}
          />
        </Stack>
      </Stack>
      <Divider orientation="vertical" flexItem sx={{mr: 1.5}}/>
      <Stack direction="row" py={0.5} gap={1}>
        <SwiftIconButton
          onClickFcn={() => onEditClick(referenceFile)}
          disabled={!withUpdateAccess}
          startIcon={SystemIcons.Edit}
          sx={{padding: 0}}
        />
        <SwiftIconButton
          onClickFcn={handleDeleteForm}
          disabled={!withDeleteAccess}
          startIcon={SystemIcons.DeleteOutlined}
          sx={{padding: 0}}
        />
      </Stack>
    </Stack>
  )
}
