import {Milestone} from "types/Milestone";
import {BaseProps} from "screens/BaseProps";
import Drawer from "./Drawer";
import {Dispatch} from "react";
import {MilestoneAction} from "hooks/useMilestoneReducer";
import {CollectionReference} from "firebase/firestore";

interface ManageMilestoneDrawerProps extends BaseProps {
  collectionRef: CollectionReference;
  isDrawerOpen: boolean;
  isEdit?: boolean;
  milestone?: Milestone | null;
  onClose: () => void;
  index?: number;
  dispatch?: Dispatch<MilestoneAction>;
}

function ManageMilestoneDrawer(props: ManageMilestoneDrawerProps) {
  const {isDrawerOpen, onClose, index} = props

  return <>
    <Drawer
      index={index || 0}
      drawerVisibility={isDrawerOpen}
      onDrawerClose={onClose}
      {...props}
    />
  </>
}

export default ManageMilestoneDrawer;