import {MilestoneAssignedStatus as Status} from "enums/milestoneAssignedStatus";

export type AssignedItem = {
  selected: boolean;
}

export type MilestoneAssignedProps = Record<string, AssignedItem>;

export const MilestoneAssignedStatus: MilestoneAssignedProps = {
  [Status.ToMe]: { selected: false },
  [Status.ByMe]: { selected: false },
  [Status.ToMyTeam]: { selected: false },
  [Status.ToNoOne]: { selected: false },
  [Status.ToAnyone]: { selected: false },
  [Status.ImTheApprover]: { selected: false },
  [Status.ImTheObserver]: { selected: false },
  [Status.FavoriteTasks]: { selected: false },
}