import styled from "@emotion/styled";
import {Box, Checkbox, Divider, Stack, Typography} from "@mui/material";
import Icons from "assets/icons";
import {Asset} from "types/Asset";
import {LongText} from "components/index";
import {useState} from "react";
import {enAssetLabel, enCommonLabel} from "constants/index";
import SystemIndex from "assets/icons/system/system.index";
import EntityIcon from "../../GlobalChat/utils/EntityIcon";
import {Entity, CounterFields} from "enums/index";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import {toastProps} from "../../BaseProps";
import {db} from "../../../firebase";
import {doc} from "firebase/firestore";
import {useRTDBDocField} from "hooks/index";
import {getDocPath} from "screens/utility";

interface AssetCardProps {
  disabled: boolean;
  asset: Asset;
  isAssetChecked: boolean;
  uid: string;
  toastProps: toastProps;
  parentCollectionPath: string;
  onCheck: (id: string, checked: boolean) => void;
}

function AssetCard(props: AssetCardProps) {
  const {onCheck, asset, disabled, isAssetChecked} = props;
  const {avatarColor, name, idCode, "@id": id = "fillerId"} = asset;

  const tasksCount = useRTDBDocField<number>(getDocPath(props.uid, props.parentCollectionPath, id), CounterFields.TasksCount) ?? 0;
  const [isCheckedLocal, setIsCheckedLocal] = useState<boolean>(isAssetChecked ?? true);

  function onClick() {
    if (disabled) return;

    setIsCheckedLocal(!isCheckedLocal);
    onCheck(id, !isCheckedLocal);
  }

  return (
    <CardWrapper
      id={`asset_${id}_wrapper`}
      onClick={() => onClick()}
      title={disabled ? enCommonLabel.noPermissionForAction : undefined}
      margin={0}
      padding={0}
    >
      <Stack padding={1}>
        <Checkbox
          checked={isCheckedLocal}
          onChange={() => onClick()}
          disabled={disabled}
          id={`asset_${id}_checkbox`}
        />
      </Stack>
      <Divider orientation="vertical" flexItem/>
      <Stack paddingY={1} paddingLeft={2}>
        <Avatar avatarColor={avatarColor ?? "#000"}/>
      </Stack>
      <Stack display="flex" flexDirection="column" padding={1} width="50%">
        <LongText variant="h4" maxLines={1} minWidth={"100%"}>{name}</LongText>
        <LongText variant="bodySmall" maxLines={1}>
          {!!idCode ? `ID:${idCode}` : enAssetLabel.noIdCode}
        </LongText>
        <DataTagsDisplay
          dataTagsIds={asset.dataTagsIds ?? []}
          documentRef={doc(db, props.parentCollectionPath, id)}
          canEditDocumentRef={true}
          uid={props.uid}
          toastProps={props.toastProps}
        />
      </Stack>
      <Divider orientation="vertical" flexItem/>
      <Stack direction="row" alignItems="flex-start" justifyContent="center" padding={1} width="30%">
        <Box marginTop={0.1} marginRight={0.5}>
          <SystemIndex.SystemIcons.Location width={16} height={16} fill="#000"/>
        </Box>
        {!!asset.address ? (
          <Typography variant="body1" textAlign="center">
            {asset.address}
          </Typography>
        ): (
          <Typography variant="body1" color="text.secondary"><i>{enAssetLabel.noAddress}</i></Typography>
        )}
      </Stack>
      <Divider orientation="vertical" flexItem/>
      <Stack direction="row" alignItems="center" justifyContent="center" alignContent="center" padding={1} flex={1}>
        <Box marginTop={0.5} marginRight={0.5}>
          <EntityIcon entity={Entity.Task} svgProps={{width: 16}}/>
        </Box>
        <Typography variant="body1" color="text.secondary" title={enAssetLabel.tasksCount}>
          <b>{tasksCount}</b>
        </Typography>
      </Stack>
    </CardWrapper>
  )
}

const CardWrapper = styled(Stack)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  borderRadius: "4px",
  backgroundColor: "#FFF",
  border: "1px solid #EAEAEA",
  cursor: "pointer"
});

function Avatar({avatarColor}: { avatarColor: string }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      borderRadius="4px"
      minWidth="40px"
      minHeight="40px"
      maxWidth="40px"
      maxHeight="40px"
      sx={{backgroundColor: avatarColor}}>
      <Icons.SidebarIcons.Layers width={16} height={16} fill="#FFF"/>
    </Box>
  )
}

export default AssetCard;