import {AssetListType} from "enums/assetListType";
import AssetTable from "../AssetTable";
import AssetListWrapper from "../../AssetListWrapper";
import React from "react";
import {Asset} from "types/Asset";
import { toastProps } from "screens/BaseProps";

interface AssetsViewProps {
  disabled: boolean;
  assetCount: number;
  assets: Asset[]
  handleCheckboxChange: (id: string, checked: boolean) => void
  assetListType: AssetListType;
  assetTypeIdx: "projectAssets" | "availableAssets"
  listFromAlgolia: boolean;
  modifiedAssets: Asset[];
  parentCollectionPath: string;
  toastProps: toastProps;
  uid: string;
  setCurrentPage: (assetListType: AssetListType, page: number) => void;
}

function AssetsListView(props: AssetsViewProps) {
  const {assetCount, assetListType, assetTypeIdx, listFromAlgolia, ...rest} = props;

  return (
    <AssetListWrapper
      assetList={assetListType}
      assetTypeIdx={assetTypeIdx}
      assetCount={assetCount}
      setCurrentPage={(page: number) => props.setCurrentPage(assetListType, page)}
      listFromAlgolia={listFromAlgolia}
    >
      <AssetTable
        {...rest}
        onCheckChange={props.handleCheckboxChange}
      />
    </AssetListWrapper>
  )
}

export default AssetsListView