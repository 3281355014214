/**
 * Looks for a specific key in the given object and return its value if found.
 * Otherwise, it will return the defaultValue
 * @param obj
 * @param keyToFind
 * @param defaultValue
 */
export function getKeyValue<T>(obj: Object, keyToFind: string, defaultValue: T): string | T {
  // @ts-ignore
  return obj.hasOwnProperty(keyToFind) ? obj[keyToFind] : defaultValue;
}