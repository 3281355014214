import {Box, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {Project} from "types/index";
import {BaseProps} from "../BaseProps";
import {Content, PageTitle} from "components";
import {useAccess, useComponentToggler, useRTDBDocField} from "hooks/index";
import {defaultSelectedOrg, emptyFunction, enProject, enProjectLabel} from "constants/index";
import {getDocPath, organizationPath, projectExportsPath, projectsPath} from "../utility";
import ProjectsView from "components/ProjectsView";
import {ManageProjectDrawer} from "components/Drawers";
import {DownloadTimeDataDialog} from "components/Dialogs";
import {AccessRole, CounterFields, Entity} from "enums/index";
import {PermissionEntity, PermissionOperationKey} from "types/Permission";
import {PrimaryButton} from "components/Button";

export enum ProjectAction {
  Edit = "edit",
  DownloadTimeData = "downloadTimeData"
}

function Projects(props: BaseProps) {
  const {toastProps, selectedOrg, uid, setSelectedOrgId = emptyFunction} = props;

  const {orgId} = useParams();
  const navigate = useNavigate();

  const [selectedProj, setSelectedProj] = useState<Project | null>(null);
  const [isDrawerOpen, {open: openDrawer, close: closeDrawer}] = useComponentToggler(false);
  const [isDownloadTimeDataOpen, {
    open: openDownloadTimeData,
    close: closeDownloadTimeData
  }] = useComponentToggler(false);

  const [access] = useAccess({
    uid: uid!,
    entity: Entity.Project,
    documentDocId: PermissionEntity.Project,
    fromParentPermission: true,
  });

  // get projects count
  const projectsCount = useRTDBDocField<number>(getDocPath(uid, organizationPath(orgId!).path), CounterFields.ProjectsCount);

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined || orgId === defaultSelectedOrg.id)
      return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  function onDrawerClose() {
    setSelectedProj(null);
    closeDrawer();
  }

  function onSelectedClick(proj: Project | null, action: ProjectAction) {
    setSelectedProj(proj);
    if (action === ProjectAction.Edit) return openDrawer();
    openDownloadTimeData();
  }

  return (
    <Content>
      <ManageProjectDrawer
        selectedProject={selectedProj}
        isOpen={isDrawerOpen}
        closeDrawer={onDrawerClose}
        toastProps={toastProps!}
        role={selectedOrg?.accessRole || AccessRole.User}
        uid={uid}
      />
      {(isDownloadTimeDataOpen && !!selectedProj) &&
        <DownloadTimeDataDialog
          isOpen={isDownloadTimeDataOpen}
          toastProps={toastProps!}
          entity={Entity.Project}
          closeDialog={closeDownloadTimeData}
          downloadCollectionRef={projectExportsPath(orgId!, selectedProj["@id"]!)}
        />
      }
      <Stack mt={2} flex={1} maxWidth="90vw">
        <Box sx={{display: "flex", justifyContent: "space-between"}}>
          <PageTitle title={enProject.title} count={projectsCount || 0}/>
          <PrimaryButton
            id="create-new-project-button"
            label={enProjectLabel.new}
            onClickFcn={openDrawer}
            disabled={Boolean(!access?.[PermissionOperationKey.Create])}
          />
        </Box>
        <br/>
        <ProjectsView
          projectsRefPath={projectsPath(orgId!).path}
          onSelectedClick={onSelectedClick}
          toastProps={toastProps!}
          {...props}
        />
      </Stack>
    </Content>
  );
}

export default Projects;
