import {Feature} from "types/Feature";
import {useDocument} from "hooks/index";
import {featuresPath} from "screens/utility";
import {doc} from "firebase/firestore";
import {FeatureType} from "enums/featureType";

function useFeature(feature: FeatureType) {
  const featurePath = doc(featuresPath(), feature)
  const [featureDoc] = useDocument<Feature>(featurePath);

  if (!featureDoc)
    return false

  return featureDoc.isEnabled;
}

export default useFeature;
