import {Box, Stack, Tooltip, Typography} from "@mui/material";
import {ReadReceipt} from "types/ChatMessage";
import theme from "theme/theme";
import {formatDateTimeFirestoreDate} from "../../../screens/utility";
import {Avatar} from "components/index";
import {enChatLabel} from "constants/index";

interface ExcessAvatarTooltipProps {
  readReceiptsData: ReadReceipt[]
  children: JSX.Element;
}

function ExcessAvatarTooltip(props: ExcessAvatarTooltipProps) {
  const {children, readReceiptsData} = props;
  const totalReadReceipts = readReceiptsData.length;

  return (
    <Tooltip
      placement="left-start"
      disableInteractive={false}
      title={
        <Stack direction="column" gap={1} alignItems="left" justifyContent="center" padding={0.5}>
          <Typography>{enChatLabel.readBy} {totalReadReceipts}</Typography>
          <Box maxHeight={250} minWidth={150} maxWidth={250} gap={1} sx={{overflowY: "auto", overflowX: "hidden"}}>
            {Object.values(readReceiptsData).map((readReceipt, index) => {
              const {user, timeRead} = readReceipt;
              const {simpleTime} = formatDateTimeFirestoreDate(timeRead);
              return (
                <Stack
                  flex={1}
                  key={`read-receipt-list-item-${index}`}
                  direction="row"
                  gap={2}
                  minHeight={35}
                  alignItems="left"
                  justifyContent="flex-start"
                >
                  <Avatar
                    {...user}
                    sx={{
                      fontSize: "0.75rem",
                      width: "1.75rem",
                      height: "1.75rem",
                      mt: 0,
                      borderColor: theme.palette.secondary.main,
                    }}
                  />
                  <Stack
                    width={"100%"}
                    gap={2}
                    direction="row"
                    justifyContent="space-between">
                    <Typography variant="h4" color={theme.palette.background.default}>
                      {user.firstName}
                    </Typography>
                    <Typography variant="body2" color={theme.palette.neutral.medium} mt={0.5} mr={0.5}>
                      {simpleTime}
                    </Typography>
                  </Stack>
                </Stack>
              )
            })}
          </Box>
        </Stack>
      }
      componentsProps={{
        tooltip: {
          sx: {
            padding: 1,
            boxShadow: "4px 4px 16px -8px rgba(0,0,0,0.75)",
            backgroundColor: theme.palette.secondary.main,
            ".MuiTooltip-arrow": {
              color: theme.palette.secondary.main,
            }
          },
        },
      }}
      arrow
    >
      {children}
    </Tooltip>
  )
}

export default ExcessAvatarTooltip;