import {useEffect, useRef} from "react";

const sleep = async (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

function useDebounce(asyncFunc: () => Promise<void>, quietPeriodMs: number, dependencies: any[], leading = false) {
  const prevTS = useRef(Date.now());
  const running = useRef(false);


  useEffect(() => {
    async function executeAsyncFunction(){
      await asyncFunc();
      running.current = false;
    }

    async function debounce(){
      prevTS.current = Date.now();
      await sleep(quietPeriodMs);
      const currentTS = Date.now();

      if (!running.current && currentTS - prevTS.current >= quietPeriodMs) {
        running.current = true;
        await executeAsyncFunction();
      }
    }

    if (leading) {
      sleep(quietPeriodMs).then(() =>{
        const currentTS = Date.now();
        if (currentTS - prevTS.current >= quietPeriodMs) {
          prevTS.current = Date.now();
          executeAsyncFunction()
        }
      })
      return;
    }
    debounce();
  }, [...dependencies]);
}

export default useDebounce;
