import getEnvKey from "./getEnvKey";
import {storageBucketKey} from "constants/envKeys";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../../firebase";

const storageBucket = getEnvKey(storageBucketKey);

async function getFileDownloadPath(destPath: string) {
  const sourceReference = ref(storage, `${storageBucket}${destPath}`);
  return await getDownloadURL(sourceReference);
}

export default getFileDownloadPath;