import React from "react";

interface UniqueAssetIconProps {
  color?: string;
}
const UniqueAssetIcon = ({ color = "#5DCA91" }: UniqueAssetIconProps) => {
  return (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={40} height={40} rx={4} fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.387 17.256a1 1 0 0 1 0 1.6l-.66.496a1 1 0 0 0 0 1.6l.66.495a1 1 0 0 1 0 1.6l-5.787 4.34a1 1 0 0 1-1.2 0l-5.786-4.34a1 1 0 0 1 0-1.6l.66-.495a1 1 0 0 0 0-1.6l-.66-.495a1 1 0 0 1 0-1.6l5.787-4.34a1 1 0 0 1 1.2 0l5.786 4.34Zm-6.27 8.813 5.12-3.821-1.628-1.202-3.493 2.599-3.492-2.62-1.628 1.223 5.12 3.82Z"
        fill="#fff"
      />
    </svg>
  );
};

export default UniqueAssetIcon;
