import theme from "theme/theme";
import {AccessRole} from "enums/accessRole";
import {Team} from "types/Team";

export const defaultTeam: Team = {
  "@id": "defaultTeam",
  initials: "T",
  name: "Team",
  avatarColor: theme.palette.avatar.gold,
  role: AccessRole.User,
  orgId: "orgId",
  teamMemberUids: [],
}