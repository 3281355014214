import {Buffer} from "buffer";
export function parseToken(token: string): any {
    const base64payload = token.split('.')[1];
    let payload = {};
    try {
        payload = JSON.parse(Buffer.from(base64payload, 'base64').toString());
    } catch {
        return payload;
    }
    return payload;
}