import {StatusItem} from "types/StatusItem";

export default function getStatusOptions(statusList: Record<string, StatusItem>, status: string, exceptions?: string[]): [Record<string, StatusItem>, StatusItem | undefined] {
  let options: Record<string, StatusItem> = {};
  let selected: StatusItem | undefined = undefined;

  Object.entries(statusList).forEach((obj) => {
    const label = obj[0];
    const properties = obj[1];

    if (label === status) {
      selected = {...properties};
    }

    if (exceptions === undefined || !exceptions.includes(label)) {
      options = {
        ...options,
        [label]: {...properties}
      }
    }

  });

  return [options, selected]
}