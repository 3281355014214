import {BaseProps} from "../BaseProps";
import {
  emptyFunction,
  enCommonButton,
  enCommonLabel,
  enRolesAndPermissions,
  enRolesAndPermissionsLabel
} from "constants/index";
import React, {useEffect, useReducer, useState} from "react";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {BaseSearchInput, Content, PageTitle} from "components/index";
import {Box, Stack} from "@mui/material";
import {PrimaryButton, SecondaryButton} from "components/Button";
import RolesAndPermissionsView from "./RolesAndPermissionsView";
import {useCollection} from "hooks/index";
import {areObjectsEqual, permissionsPath, submitForm} from "../utility";
import {Permission, PermissionOperationKey} from "types/Permission";
import {ActionType, ProcessType, Severity} from "enums/index";
import {doc} from "firebase/firestore";
import {DISPLAYED_ENTITIES} from "./constants/displayedEntities";
import theme from "theme/theme";
import {reducer} from "./reducer";
import {initialState} from "./reducer/initialState";
import {ReducerActionType} from "./reducer/actionType";
import {SystemIcons} from "assets/icons/system/system.index";
import {bulkStatusSubmitHandler, StatusSubmitHandler} from "../utility/statusSubmitHandler";

function RolesAndPermissions(props: BaseProps) {
  const {selectedOrg, toastProps, setSelectedOrgId = emptyFunction} = props;
  const {setToastMessage, setIsToastOpen, setToastSeverity} = toastProps!;
  const navigate = useNavigate();
  const {orgId} = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isExpandAll, setIsExpandAll] = useState<boolean>(true);

  const [userPermissions] = useCollection<Permission>(DISPLAYED_ENTITIES, permissionsPath(), null, true);

  const [reducerData, reducerDispatch] = useReducer(reducer, initialState);

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  // if there are changes in the received userPermissions, copy it to localPermissions
  useEffect(() => {
    reducerDispatch({type: ReducerActionType.Reset, payload: {permissions: userPermissions}});
  }, [userPermissions]);

  function isFormModified() {
    if (!userPermissions || !reducerData.localPermissions) return false;
    return !areObjectsEqual(userPermissions, reducerData.localPermissions);
  }

  async function savePermissions() {
    if (!userPermissions || !reducerData.localPermissions) return;

    // identify which of the permissions are modified
    let modifiedPermissions: Permission[] = [];
    reducerData.localPermissions.forEach((permission: Permission, index: number) => {
      if (!areObjectsEqual(permission, userPermissions[index])) {
        modifiedPermissions.push(permission);
      }
    });

    // if there are no modified permissions, do nothing
    if (modifiedPermissions.length === 0) return;

    setIsLoading(true);
    // save each document reference
    let submittedData: StatusSubmitHandler<any>[] = [];

    await Promise.all(modifiedPermissions.map(async permission => {
        const permissionRef = doc(permissionsPath(), permission.id);
        // only pass fields that are modified and if field key exists in PermissionOperationKey
        const permissionData: { [key: string]: { [key: string]: boolean } } = Object.keys(permission)
          .filter(key => key in PermissionOperationKey
            && !areObjectsEqual(permission[key as PermissionOperationKey], userPermissions.find(p => p.id === permission.id)![key as PermissionOperationKey]))
          .reduce((acc, key) => ({...acc, [key]: {...permission[key as PermissionOperationKey]}}), {});

        return await submitForm(permissionRef, ActionType.Update,
          (status, data, isLastUpdate) => {
            isLastUpdate && submittedData.push({status, data, isLastUpdate});
          },
          permissionData);
      })
    );

    bulkStatusSubmitHandler<any>({data: submittedData, successCallback, errorCallback});
  }

  function successCallback() {
    setToastSeverity(Severity.Success);
    setToastMessage(enRolesAndPermissionsLabel.editSuccess);
    setIsToastOpen(true);
    setIsLoading(false);
  }

  function errorCallback(message: any) {
    let toastMessage = enCommonLabel.errorProcess(ProcessType.Update);
    // if type of message is obj, get first obj value
    if (typeof message === "object" && (typeof Object.values(message)[0] === "string")) {
      toastMessage = Object.values(message)[0] as string;
    }

    setIsLoading(false);
    setToastSeverity(Severity.Error);
    setToastMessage(toastMessage);
    setIsToastOpen(true);
  }

  return (
    <Content>
      <Stack flex={1}>
        <Box position="fixed" minWidth="65%" display="flex" mt={-3} pt={5} flexDirection="column"
             sx={{backgroundColor: theme.palette.background.paper, zIndex: 1}}>
          <Stack
            justifyContent={{xs: "left", sm: "space-between", md: "space-between", lg: "space-between"}}
            direction={{xs: "column", sm: "row", md: "row", lg: "row"}}
          >
            <PageTitle title={enRolesAndPermissions.title}/>
            <Stack direction="row" flex={0.25} justifyContent={{xs: "left", sm: "right", md: "right", lg: "right"}}>
              <PrimaryButton
                id="save-roles-and-permissions-button"
                label={enCommonButton.save}
                onClickFcn={savePermissions}
                disabled={!isFormModified() || isLoading}
                loading={isLoading}
              />
            </Stack>
          </Stack>
          <br/>
          <Stack
            justifyContent="space-between"
            mb={3}
            direction={{xs: "column", md: "row"}}
          >
            <BaseSearchInput
              id={"smart-search-input"}
              placeholder={enRolesAndPermissionsLabel.search}
              searchFn={(searchText: string) => reducerDispatch({
                type: ReducerActionType.Search,
                payload: {
                  searchText: searchText,
                }
              })}
            />
            <SecondaryButton
              onClickFcn={(e) => setIsExpandAll(!isExpandAll)}
              disabled={false}
              startIcon={(props) => isExpandAll ? <SystemIcons.Collapse {...props} /> :
                <SystemIcons.Expand {...props} />}
              label={isExpandAll ? `${enCommonLabel.collapse} all` : `${enCommonLabel.expand} all`}
            />
          </Stack>
        </Box>
        <br/>
        <RolesAndPermissionsView
          isExpandAll={isExpandAll}
          reducerDispatch={reducerDispatch}
          permissions={reducerData.localPermissions}
          searchResult={reducerData.searchResult}
          {...props}
        />
      </Stack>
    </Content>
  )
}

export default RolesAndPermissions;