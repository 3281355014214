import {CarouselItem} from "components/ChatDrawer/ChatItemsView/ChatAttachmentCarousel";
import {getPreviewFromMime} from "../../../screens/utility";
import {Previews} from "enums/supportedPreviews";
import {InProgress} from "components/index";
import {Box} from "@mui/material";
import PDFImg from "assets/img/PDFImg.png";
import {SystemIcons} from "assets/icons/system/system.index";
import React from "react";

interface ThumbnailViewProps {
  item: CarouselItem;
  previewUrl: string | null;
}

function ThumbnailView(props: ThumbnailViewProps) {
  const {item, previewUrl} = props;
  const {source, type, previewType} = item;

  const preview = previewType ?? getPreviewFromMime(type ?? "image/*");
  switch (preview) {
    case Previews.Image:
      return <img
        className="embla__slide__thumbnail"
        src={source}
        alt="chat thumb"
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover"
        }}
      />
    case Previews.Video:
      return !previewUrl ? <InProgress/> : <img
        className="embla__slide__thumbnail"
        style={{height: "100%"}}
        src={previewUrl}
        alt="chat thumb"
      />
    case Previews.PDF:
      return <Box
        className="embla__slide__thumbnail"
        sx={{
          backgroundColor: "rgba(255,255,255,0.2)",
          padding: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transform: "scale(1)"
        }}><img
        src={PDFImg}
        alt="chat thumb"
      />
      </Box>
    default:
      return <Box
        className="embla__slide__thumbnail"
        sx={{
          backgroundColor: "rgba(255,255,255,0.2)",
          padding: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transform: "scale(1)",
        }}>
        <SystemIcons.FilesFilled fill="white" width={32} height={32}/>
      </Box>
  }
}

export default ThumbnailView;