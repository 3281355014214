import {collection, doc, where} from "firebase/firestore";
import {Asset, Task} from "types/index";
import {useComponentToggler, useOnScreen} from "hooks/index";
import {Box, Checkbox, Collapse, Skeleton, Stack} from "@mui/material";
import React, {useMemo, useRef, useState} from "react";
import {TaskItem} from "screens/Home/Tabination/TabsContents/TaskAndTimers/TaskItem";
import {toastProps} from "screens/BaseProps";
import {TaskItemAction} from "screens/Home/TaskItemAction";
import TaskScheduleDrawer from "../Drawers/TaskScheduleDrawer";
import ChatDrawer from "components/ChatDrawer";
import {Entity} from "enums/entity";
import {sortObjectsBy, tasksPath} from "screens/utility";
import useCollectionWithTemplate, {TemplateDataType} from "hooks/useCollectionWithTemplate";
import {useParams} from "react-router-dom";
import {FiltersState} from "screens/Home/TaskSearchAndFilters";
import {filterTaskBySearch} from "components/MilestoneView/utils/filterTaskBySearch";
import EmptySearchResults from "components/EmptySearchResults";
import {useNavigate} from "react-router";
import {enTaskLabel} from "constants/index";
import ActivityFeed from "components/ActivityFeed";
import {db} from "../../firebase";

interface MilestoneTaskListProps {
  uid: string;
  toastProps: toastProps;
  isExpanded: boolean;
  isCheckboxShown: boolean;
  checkedTasks: { taskId: string, milestoneId: string }[];
  displayedTasks: { taskId: string, milestoneId: string }[];
  checkTask: (taskId: string, milestoneId: string, templateId?: string) => void;
  tasksCount: number | undefined;
  milestoneId: string;
  asset?: Asset | null;
  fromSearch: boolean;
  filterState: FiltersState;
}

function MilestoneTaskList(props: MilestoneTaskListProps) {
  const {isCheckboxShown, tasksCount, milestoneId, asset, fromSearch, filterState} = props;
  const {orgId, projId, assetId} = useParams();
  const navigate = useNavigate();

  const taskCollectionRef = tasksPath(orgId!, projId!, assetId, milestoneId, undefined)
  const {data: tasks} = useCollectionWithTemplate<Task>({colRef: taskCollectionRef, projectConstraints: [where("@allowedUsers", "array-contains", props.uid), where("deleted", "==", false)]});
  const [displayedTasks, setDisplayedTasks] = React.useState<TemplateDataType<Task>[] | null>(null);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);

  const [isTaskScheduleDrawerOpen, {
    open: showTaskScheduleDrawer,
    close: closeTaskScheduleDrawer
  }] = useComponentToggler(false);
  const [isChatDrawerOpen, {open: showChatDrawer, close: closeChatDrawer}] = useComponentToggler(false);
  const [isTaskActivityFeedOpen, {
    open: openTaskActivityFeedDrawer,
    close: closeTaskActivityFeedDrawer
  }] = useComponentToggler(false);

  useMemo(() => {
    if (tasks === null) return;

    if (fromSearch) {
      const newTasks: TemplateDataType<Task>[] = [];
      props.displayedTasks.forEach(task => {
        const taskItem = tasks?.find(taskDB => taskDB["@id"] === task.taskId && !taskDB.deleted);
        if (!taskItem) return;
        newTasks.push(taskItem);
      });

      setDisplayedTasks(newTasks);
      return;
    }

    const sortTasks = sortObjectsBy([...tasks].filter(task => !task.deleted), "name");

    if (displayedTasks === null) {
      setDisplayedTasks(sortTasks);
      return;
    }

    setDisplayedTasks(sortTasks);
  }, [fromSearch, props.displayedTasks]);

  // if there are changes in the listed task
  useMemo(() => {
    if (tasks === null) return;

    // if from filter, perform additional checking
    if (fromSearch) {
      const newTasks: TemplateDataType<Task>[] = [];
      props.displayedTasks.forEach(task => {
        const taskItem = tasks?.find(taskDB => taskDB["@id"] === task.taskId && !taskDB.deleted);
        if (!taskItem) return;
        newTasks.push(taskItem);
      });

      const filteredTasks = filterTaskBySearch(newTasks, filterState, props.uid!);
      setDisplayedTasks(filteredTasks as any);
      return;
    }

    const sortedTasks = sortObjectsBy(tasks.filter(task => !task.deleted && !task.hidden), "name");
    setDisplayedTasks(sortedTasks);
  }, [tasks]);

  function onTaskItemDrawerOpen(task: Task, action: TaskItemAction) {
    setSelectedTask(task);

    if (action === TaskItemAction.TaskSchedule) {
      showTaskScheduleDrawer();
      return;
    }

    showChatDrawer();
  }

  if (!props.isExpanded) return null;

  if (displayedTasks === null) {
    return <Skeleton variant="rounded" height={(tasksCount || 2) * 100} animation="wave"/>;
  }

  if (fromSearch && displayedTasks.length === 0) {
    return <EmptySearchResults entity={Entity.Task} />
  }

  function showActivityFeedDrawer(task: Task) {
    if (!task) return;
    setSelectedTask(task);
    openTaskActivityFeedDrawer();
  }

  return (
    <div>
      <Collapse
        in={props.isExpanded}
        sx={{transitionDelay: props.isExpanded ? '500ms' : '0ms'}}
        timeout={1000}
      >
        <Stack gap={2}>
          {displayedTasks.map(task => {
            return <Preloader element={
              <Stack
                key={`milestone-task-list-${task["@id"!]}`}
                direction="row"
              >
                {isCheckboxShown && (
                  <Stack justifyContent="center" alignItems="center">
                    <Checkbox
                      className="task-checkbox"
                      checked={props.checkedTasks.some(checkedTask => checkedTask.taskId === task["@id"])}
                      sx={{
                        paddingLeft: 1,
                        "&.Mui-disabled": {
                          cursor: "not-allowed",
                          pointerEvents: "unset"
                        }
                      }}
                      onClick={e => {
                        e.stopPropagation()
                      }}
                      title={!!task.templateId ? enTaskLabel.cannotDeleteWithTemplate: ""}
                      disabled={!!task.templateId}
                      onChange={() => props.checkTask(task["@id"]!, task.milestoneId!, task.templateId!)}
                    />
                  </Stack>
                )}
                <Box
                  border={0}
                  borderColor="transparent"
                  borderRadius={1}
                  flex={1}
                  sx={{cursor: "pointer", maxWidth: "100%",}}
                  marginY={-0.5}
                  marginLeft={0}
                  className="milestone-task-item"
                >
                  <TaskItem
                    asset={asset}
                    key={task["@id"]}
                    onTaskItemDrawerOpen={onTaskItemDrawerOpen}
                    toastProps={props.toastProps!}
                    taskReferencePath={`${task.docColRef}/${task["@id"]}`}
                    canUpdateStatus={true}
                    disableCardMenu={false}
                    uid={props.uid}
                    actualTaskId={task["@id"]}
                    openTaskActivityFeedDrawer={showActivityFeedDrawer}
                  />
                </Box>
              </Stack>
            }
            />
          })}
        </Stack>
      </Collapse>
      {selectedTask && (
        <>
          <ActivityFeed
            uid={props.uid}
            isOpen={isTaskActivityFeedOpen}
            onClose={closeTaskActivityFeedDrawer}
            collectionReference={collection(doc(db, selectedTask.orgTaskRefPath!), "activityFeed")}
            entity={Entity.Task}
          />
          <TaskScheduleDrawer
            uid={props.uid}
            task={selectedTask}
            isDrawerOpen={isTaskScheduleDrawerOpen}
            onClose={closeTaskScheduleDrawer}
            collectionRef={taskCollectionRef}
            toastProps={props.toastProps!}
          />
          <ChatDrawer
            uid={props.uid}
            navigateToOverview={() => navigate(`./milestones/${selectedTask?.milestoneId}/tasks/${selectedTask["@id"]!}`)}
            isOpen={isChatDrawerOpen}
            entity={Entity.Task}
            pathPair={[
              `${selectedTask.orgName} / ${selectedTask.projectName} / ${selectedTask.assetName ?? ""}`,
              selectedTask.name ?? ""
            ]}
            onClose={closeChatDrawer}
            colRef={collection(taskCollectionRef, selectedTask["@id"]!, "chatMessages")}
            organizationId={selectedTask.orgId}
            projectId={selectedTask.projectId}
            toastProps={props.toastProps!}
          />
        </>
      )}
    </div>
  )
}

function Preloader(props: { element: any }) {
  const ref = useRef(null);
  const onScreen = useOnScreen(ref, "300px");

  return <Box ref={ref} height={onScreen ? undefined : 100} marginY={onScreen ? -1 : -0.5}>
    {onScreen && props.element}
  </Box>
}

export default MilestoneTaskList;