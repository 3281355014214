import {FormFieldProps} from "./FormFieldProps";
import {Stack} from "@mui/material";
import theme from "theme/theme";
import {enCommonLabel} from "constants/index";
import React, {useEffect, useState} from "react";
import UnEditedBaseField from "./UnEditedBaseField";
import Input from "components/Input";
import FormFieldContainer from "./FormFieldContainer";
import {onChangeInput} from "../../utility";
import {isFormFieldValid} from "../utils";
import {FormFieldType} from "enums/formFieldType";
import {FormField} from "types/FormField";

interface TextFormFieldProps extends FormFieldProps {
  field: FormField<FormFieldType.Text>,
  onKeyPress: (e: React.KeyboardEvent) => void;
}

function TextFormField(props: TextFormFieldProps) {
  const {isEditMode, field, index, onKeyPress, updateLocalField} = props;
  const {value = ""} = field;

  const fieldId = `text-field-${index}`;

  const [text, setText] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    setErrorMessage("");
  }, [isEditMode]);

  useEffect(() => {
    if(text === value || text === null) return;
    updateLocalField(text.trim(), index);

    const [, message] = isFormFieldValid({...field, value: text.trim()});
    setErrorMessage(message);
  }, [text]);

  useEffect(() => {
    if (typeof value === "object") {
      setText("");
      return;
    }
    setText(value);
  }, [value]);

  if (!isEditMode) {
    return (
      <UnEditedBaseField
        fieldId={fieldId}
        defaultValue={enCommonLabel.none}
        {...field}
      />
    )
  }

  return (
    <FormFieldContainer fieldId={fieldId} {...field}>
      <Stack direction="row">
        <Input
          onChange={e => onChangeInput(e, setText)}
          value={text ?? ""}
          validationMessage={errorMessage}
          textfieldSx={{width: "300px", backgroundColor: theme.palette.background.paper}}
          onKeyPress={onKeyPress}
        />
      </Stack>
    </FormFieldContainer>
  )
}

export default TextFormField;