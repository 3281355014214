import {Box, MenuItem, Select, SelectChangeEvent, Typography} from "@mui/material";
import {en} from "language/en";
import {DirectionalOrder, CounterFields} from "enums/index";

export enum SortValue {
  NameAsc = "name-asc",
  NameDesc = "name-desc",
  ProjectAsc = "project-asc",
  ProjectDesc = "project-desc",
  MemberAsc = "member-asc",
  MemberDesc = "member-desc",
}

export interface SortType {
  field: string,
  value: SortValue,
  order: DirectionalOrder
}

interface SortMenuProps {
  fromSAP?: boolean;
  sortValue: string;
  changeOrder: (ob: SortType) => void;
}

function SortMenu(props: SortMenuProps) {
  const {changeOrder, sortValue, fromSAP} = props;

  let MenuItems = [
    {id: "menu-asc", value: SortValue.NameAsc, label: "Name (A-Z)", field: "name"},
    {id: "menu-desc", value: SortValue.NameDesc, label: "Name (Z-A)", field: "name"},
  ];

  if (fromSAP) {
    MenuItems = [
      ...MenuItems,
      {id: "menu-proj-asc", value: SortValue.ProjectAsc, label: "Projects (min-max)", field: CounterFields.ProjectsCount},
      {id: "menu-proj-desc", value: SortValue.ProjectDesc, label: "Projects (max-min)", field: CounterFields.ProjectsCount},
      {id: "menu-mem-asc", value: SortValue.MemberAsc, label: "Members (min-max)", field: CounterFields.MembersCount},
      {id: "menu-mem-desc", value: SortValue.MemberDesc, label: "Members (max-min)", field: CounterFields.MembersCount},
    ]
  }

  function handleSelectChange(e: SelectChangeEvent<string>) {
    const item = MenuItems.find((item) => item.value === e.target.value);
    changeOrder({
      field: item?.field || "name",
      value: (item?.value || SortValue.NameAsc) as SortValue,
      order: e.target.value.split("-")[1] as DirectionalOrder,
    });
  }

  return (
    <Box sx={{display: "flex", alignItems: "center"}} id="sort-org-field">
      <Typography
        sx={(theme) => ({
          display: "inline-block",
          color: theme.palette.text.secondary,
        })}
        variant="body"
      >
        {en.common.label.sort}
      </Typography>
      <Select
        id="org-select-field"
        defaultValue="name-asc"
        value={sortValue}
        sx={{
          width: "min-content",
          minWidth: 100,
          padding: 0,
          ".MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          ".MuiOutlinedInput-input": {
            padding: "0px 4px",
          },
        }}
        onChange={handleSelectChange}
      >
        {MenuItems.map((item) => (
          <MenuItem value={item.value} id={item.id} key={`menu-item-${item.id}`}>
            <Typography
              sx={(theme) => ({
                color: theme.palette.secondary.main,
              })}
              variant="body"
            >
              {item.label}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

export default SortMenu;
