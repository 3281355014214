import theme from "theme/theme";
import {Divider, Stack} from "@mui/material";
import {SidebarIcons} from "assets/icons/menu/menu.index";
import useFeature from "hooks/useFeature";
import {FeatureType} from "enums/featureType";
import {enSidebar} from "constants/index";
import Item from "components/SidebarMenu/SidebarLinks/Item";
import {useContext} from "react";
import {SelectedOrgContext} from "screens/SelectedOrgContextProvider";

interface SAPSideBarProps {
  toggleSidebar: () => void;
}

function SAPSideBar(props: SAPSideBarProps) {
  const {toggleSidebar} = props;

  const isSAPEnabled = useFeature(FeatureType.SAP);

  const selectedOrgContext = useContext(SelectedOrgContext);
  const {selectedOrg} = selectedOrgContext!;
  const selectedOrgId = selectedOrg?.id ?? "defaultOrg";

  if (!isSAPEnabled) return null;

  const Links = [
    {
      Icon: SidebarIcons.Permissions,
      Label: enSidebar.links.rolesAndPermissions,
      Path: `${selectedOrgId}/roles-and-permissions`,
      order: 1,
      pathName: "roles-and-permissions"

    },
    {
      Icon: SidebarIcons.User,
      Label: enSidebar.links.users,
      Path: `${selectedOrgId}/users`,
      order: 2,
      pathName: "users"
    },
    /* // todo: uncomment this when system status is ready
    {
      Icon: SidebarIcons.SystemStatus,
      Label: enSidebar.links.systemStatus,
      Path: `${routeOrgId}/system-status`,
      order: 3,
    },*/
  ];

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        left: 0,
        backgroundColor: theme.palette.secondary.light
      }}
      gap={1}
      pb={2}
    >
      {Links.map((link, index) => {
        return (
          <>
            <Item
              {...link}
              key={`sidebarLinkSAP_${index}`}
              selectedBarId={`sidebarLinkSAP_${index}`}
              toggleSidebar={toggleSidebar}
            />
            {link.Label === "Timers" &&
                <Divider flexItem sx={{position: "relative", top: 825, left: 10, borderColor: theme.palette.divider}}/>}
          </>
        )
      })}
    </Stack>
  )
}

export default SAPSideBar;