import {Box, Stack} from "@mui/material";
import SystemIndex from "assets/icons/system/system.index";
import TimestampTypo from "components/Typography/TimestampTypo";
import {enActivityFeedLabel} from "constants/index";
import {formatDateTimeFirestoreDate} from "screens/utility/formatDateTimeFirestoreDate";
import theme from "theme/theme";
import {DueSoonItemType} from "types/ActivityFeedTypes";
import ItemContainer from "components/ActivityFeed/ItemContainer";

interface DueSoonProps {
  item: DueSoonItemType
}

function OverdueItem(props: DueSoonProps) {
  const {item} = props;
  const {timestamp} = item;
  const {date, time} = formatDateTimeFirestoreDate(timestamp);

  return (
    <ItemContainer>
      <Box width={24} height={24}>
        <SystemIndex.SystemIcons.Calendar stroke={theme.palette.warning.main} width={24} height={24}/>
      </Box>
      <Stack direction="column">
        {enActivityFeedLabel.dueSoon}
        <TimestampTypo>{`${date} ${time}`}</TimestampTypo>
      </Stack>
    </ItemContainer>
  );
}

export default OverdueItem;
