import {Grid, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import React from "react";
import {en} from "language/en";

function EmptyTeamCategory() {
  return (
    <Grid
      container
      sx={{bgcolor: "#fff", marginY: 1, borderRadius: 1, padding: "8px"}}
      justifyContent="space-between"
      direction="row"
      alignItems="center"
    >
      <Grid item xs={10} sm={8} justifyContent="flex-start" alignItems="center">
        <Stack
          direction="column"
          textAlign="left"
          justifyContent="flex-start"
          padding={1}
          alignContent="center"
        >
          <Typography
            sx={{color: theme.palette.secondary.main, fontWeight: 600}}
            variant="subtitle2"
            noWrap>
            {en.screen.TeamsAndPeople.label.emptyTitle}
          </Typography>
          <Typography sx={{color: theme.palette.neutral.dark}} variant="caption">
            {en.screen.TeamsAndPeople.label.createFirst}
          </Typography>
        </Stack>
      </Grid>
      <Grid item justifyContent="flex-end" alignItems="center" pr={1}>
      </Grid>
    </Grid>
  )
}

export default EmptyTeamCategory;