import {Box, Typography} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../../../firebase";
import {SwiftFile} from "types/SwiftFile";
import {getFileType} from "screens/utility";
import {Previews} from "enums/supportedPreviews";
import {InProgress, SkeletonItem} from "components/index";
import {SystemIcons} from "assets/icons/system/system.index";
import PDFImg from "assets/img/PDFImg.png";
import theme from "theme/theme";
import {CarouselItem} from "components/ChatDrawer/ChatItemsView/ChatAttachmentCarousel";
import {onAttachmentClick} from "components/ChatDrawer/utility/onAttachmentClick";
import getEnvKey from "screens/utility/getEnvKey";
import {storageBucketKey} from "constants/envKeys";
import {CollectionReference} from "firebase/firestore";
import useFileTitle from "hooks/useFileTitle";

export enum ChatAttachmentSize {
  BIG = 172,
  SMALL = 44
}

interface ChatAttachmentItemProps {
  attachment: SwiftFile;
  attachments: SwiftFile[];
  onClick: (attachments: CarouselItem[], selectedIndex: number) => void
  handleCarouselClick: () => void;
  extraItems: number;
  index: number;
  fromUnsent?: boolean;
  colRef?: CollectionReference | undefined;
  isDownloading: boolean;
}

export default function ChatAttachmentItem(props: ChatAttachmentItemProps) {
  const {attachment, attachments, onClick, handleCarouselClick, extraItems, index, fromUnsent, isDownloading} = props;
  const {name, "#previewValue": previewValue, contentType} = attachment;
  const fileType = getFileType(contentType);
  const fileTitle = useFileTitle(name);

  const [previewUrl, setPreviewUrl] = useState<string | undefined>(previewValue);
  const [previewEl, setPreviewEl] = useState<JSX.Element>(<SkeletonItem height={"64px"} sx={{width: "64px"}} />);
  const [searchIconOpacity, setSearchIconOpacity] = useState(0);

  const storageBucket = getEnvKey(storageBucketKey);

  const isExtraAttachment = index === 8 && extraItems > 0;

  useMemo(() => {
    switch (fileType) {
      case Previews.Image:
        !!previewValue && setPreviewUrl(previewValue);
        break;
      case Previews.PDF:
        setPreviewUrl(PDFImg);
        break;
      case Previews.Video:
        setPreviewEl(<SystemIcons.Video fill="black" width={24} height={24}/>);
        break;
      case Previews.Zip:
        setPreviewEl(<SystemIcons.Zip fill="black" width={45} height={45}/>);
        break;
      case Previews.Document:
        setPreviewEl(<SystemIcons.Document fill="black" width={45} height={45}/>);
        break;
      default:
        setPreviewEl(<SystemIcons.FilesFilled fill="black" width={24} height={24}/>);
        break;
    }
  }, [previewValue, fileType]);

  useEffect(() => {
    // no need to re-fetch preview if it's from unsent message
    if (fromUnsent) return;
    const fileType = getFileType(attachment.contentType);

    // no need to re-fetch preview if it's not an image
    if (fileType !== Previews.Image) return;

    const previewReference = ref(storage, `${storageBucket}${attachment.previewPath}`);
    getDownloadURL(previewReference)
      .then(setPreviewUrl)
      .catch(console.error);
  }, [attachment.previewPath, attachment.contentType, fromUnsent]);

  return (
    <Box
      position="relative"
      borderRadius={theme.spacing(1)}
      sx={{
        cursor: "pointer",
        overflow: "hidden",
        border: `1px solid ${theme.palette.neutral.light}`,
      }}
      height={64}
      width={64}
      onMouseEnter={() => setSearchIconOpacity(1)}
      onMouseLeave={() => setSearchIconOpacity(0)}
      onClick={() => {
        onAttachmentClick({attachment, attachments, onClick})
        handleCarouselClick();
      }}
    >
      <Box
        sx={{
          position: "absolute",
          height: "100%",
          width: "100%",
          backgroundColor: "black",
          opacity: 0.6,
          zIndex: 1,
          borderRadius: theme.spacing(2),
          display: isExtraAttachment ? "grid" : "none",
          placeItems: "center"
        }}
      >
        <Typography variant="h3" color={theme.palette.text.onDark}>+{extraItems + 1}</Typography>
      </Box>
      <SystemIcons.Search
        style={{
          position: "absolute",
          opacity: searchIconOpacity,
          transition: "opacity ease 0.5s",
          top: 20,
          left: 20,
          zIndex: 2,
          pointerEvents: "none"
        }}
        strokeWidth={3}
        stroke={theme.palette.text.primary}
      />

      {!previewUrl ? (
        <Box
          title={fileTitle}
          sx={{
            position: "relative",
            display: "flex",
            padding: 1,
            backgroundColor: theme.palette.neutral.light,
            width: "100%",
            height: "100%",
            borderRadius: 1,
            alignItems: "center",
            justifyContent: "center",
            transition: "opacity 500ms, transform 500ms",
            "&:hover": {
              opacity: 0.3,
              transform: "scale(1.05)",
            }
          }}
        >
          {isDownloading && !fromUnsent ? <InProgress size={24}/>: previewEl}
        </Box>
      ) : (
        <Box
          title={fileTitle}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            width: "100%",
            height: "100%",
            transition: "opacity 500ms, transform 500ms",
            "&:hover": {
              opacity: 0.3,
              transform: "scale(1.05)",
            }
          }}
        >
          <img
            src={previewUrl}
            alt={name}
            style={{
              backgroundImage: `url(${previewUrl})`,
              objectFit: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      )}
    </Box>
  )
}
