import {Stack, Typography} from "@mui/material";
import {enActivityFeedButton} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";

function AcceptedTag() {
  return (
    <Stack direction="row" height="32px" width="auto" alignItems="center" gap={1}>
      <SystemIcons.Check stroke={theme.palette.success.main} height={16} width={16} strokeWidth={3}/>
      <Typography variant="h4">
        {enActivityFeedButton.accepted}
      </Typography>
    </Stack>
  )
}

export default AcceptedTag;