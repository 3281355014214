import {BaseProps} from "../BaseProps";
import {enProfileAndSettings, enProfileAndSettingsLabel} from "constants/index";
import {Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {Content, CustomTab, CustomTabs, TabPanel} from "components/index";
import React, {useRef, useState} from "react";
import Settings from "./Tabs/Settings";
import Profile from "./Tabs/Profile";
import {LoadingButton} from "@mui/lab";
import FeatureWrapper from "components/FeatureWrapper";
import {FeatureType} from "enums/featureType";

enum TabIndex {
  Profile,
  Settings,
}

function ProfileAndSettings(props: BaseProps) {
  const [activeTab, setActiveTab] = useState<TabIndex>(TabIndex.Profile);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaveButtonValid, setIsSaveButtonValid] = useState<boolean>(false);

  // tab references:
  const profileRef = useRef();
  const settingsRef = useRef();

  function handleTabChange(event: React.SyntheticEvent, newValue: number) {
    setActiveTab(newValue);
  }

  function onSaveClick() {
    if (activeTab === TabIndex.Profile && profileRef.current) {
      // @ts-ignore
      profileRef.current.saveProfile();
      return;
    }

    if (settingsRef.current) {
      // @ts-ignore
      settingsRef.current.saveSettings();
    }
  }

  return (
    <Content>
      <Stack spacing={2} height="100%" flex={1} pt={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h1" color={theme.palette.secondary.main}>
            {enProfileAndSettings.title}
          </Typography>
          <FeatureWrapper feature={FeatureType.Profile}>
            <LoadingButton disabled={!isSaveButtonValid} loading={loading} variant="contained" onClick={onSaveClick}>
              {enProfileAndSettings.label.save}
            </LoadingButton>
          </FeatureWrapper>
        </Stack>

        <CustomTabs
          value={activeTab}
          onChange={handleTabChange}
        >
          <CustomTab
            disableRipple
            label={
              <Stack direction="row" gap={0.5}>
                <Typography variant="h5">{enProfileAndSettingsLabel.profile}</Typography>
              </Stack>
            }
          />
          <FeatureWrapper feature={FeatureType.Settings}>
            <CustomTab
              onClick={() => setActiveTab(TabIndex.Settings)}
              label={
                <Stack direction="row" gap={0.5}>
                  <Typography variant="h5">{enProfileAndSettingsLabel.settings}</Typography>
                </Stack>
              }
              sx={{ml: 5}}
            />
          </FeatureWrapper>
        </CustomTabs>
        <TabPanel tabIndex={TabIndex.Profile} value={activeTab}>
          <Profile
            {...props}
            ref={profileRef}
            setLoading={setLoading}
            setIsSaveButtonValid={setIsSaveButtonValid}
          />
        </TabPanel>
        <FeatureWrapper feature={FeatureType.Settings}>
          <TabPanel tabIndex={TabIndex.Settings} value={activeTab}>
            <Settings
              {...props}
              ref={settingsRef}
              setLoading={setLoading}
              setIsSaveButtonValid={setIsSaveButtonValid}
            />
          </TabPanel>
        </FeatureWrapper>
      </Stack>
    </Content>
  )
}

export default ProfileAndSettings;
