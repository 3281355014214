/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import {BaseProps} from "screens/BaseProps";
import {Task} from "types/Task";
import {CollapsableText, LongText} from "components/index";
import Statistics from "../Statistics";
import {useParams} from "react-router-dom";
import {CollectionReference, doc} from "firebase/firestore";
import getStatusOptions from "../../utility/getStatusOptions";
import MilestoneTaskStatusObjectList from "assets/arrayList/StatusObjectList/MilestoneTaskStatusObjectList";
import {StatusItem} from "types/StatusItem";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import {Entity} from "enums/entity";
import TaskStatusAndButtonsView from "../Objects/TaskStatusAndButtonsView";
import TaskTimelineAndSchedule from "../Objects/TaskTimelineAndSchedule";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {SecondaryButton} from "components/Button";
import {enTaskButton} from "constants/index";
import TaskAssignees from "../Objects/TaskAssignees";
import {datatagColor} from "constants/datatagColor";

interface HeadProps extends BaseProps {
  task: Task;
  requirementsRejectedCount: number;
  filesCount: number;
  requirementsCount: number;
  requirementsApprovedCount: number;
  openCreateFileRequirement: () => void;
  openCreateFormRequirement: () => void;
  taskCollectionRef: CollectionReference;
  fileRequirementsAccess: AccessType | null,
  formRequirementAccess: AccessType | null;
  parentEntity: Entity;
  taskAccess: AccessType | null;
  originalPath?: string;
}

function Head(props: HeadProps) {
  const {
    task,
    toastProps,
    taskCollectionRef,
    fileRequirementsAccess,
    formRequirementAccess,
    parentEntity,
    openCreateFileRequirement,
    openCreateFormRequirement,
    taskAccess,
    ...rest
  } = props;

  const {name, description, taskStatus} = task;

  const {taskId} = useParams();

  const [selectedStatus, setSelectedStatus] = useState<StatusItem | undefined>(undefined);
  const [taskStatusOption, setTaskStatusOption] = useState<Record<string, StatusItem> | null>(null);

  const referenceFileId = task.referenceFileId;

  const canUpdate = Boolean(taskAccess?.[PermissionOperationKey.Update]);

  useEffect(() => {
    const [options, selected] = getStatusOptions(MilestoneTaskStatusObjectList, taskStatus);
    setTaskStatusOption(options);
    setSelectedStatus(selected);
  }, [task]);

  const taskProps = {
    parentEntity,
    openCreateFileRequirement,
    openCreateFormRequirement,
    task,
    formRequirementAccess,
    fileRequirementsAccess,
    referenceFileId,
  }

  return (
    <Stack direction="column" gap={1} mb={1}>
      <Stack
        width={1}
        direction={{xs: "column", sm: "column", md: "row", lg: "row"}}
        justifyContent={{xs: "left", sm: "left", md: "space-between", lg: "space-between"}}
      >
        <Stack direction="column" gap={0.5} maxWidth={{xs: "100%", sm: "100%", md: "55%", lg: "55%"}} sx={{marginBottom: -1}}>
          <Stack direction="column">
            <LongText variant="h1" id="task-name" overflowWrap={"anywhere"}>
              {name ?? ""}
            </LongText>
            <CollapsableText text={description} overflowWrap={"anywhere"}/>
          </Stack>
          <DataTagsDisplay
            uid={props.uid}
            dataTagsIds={task?.dataTagsIds || []}
            toastProps={toastProps!}
            canEditDocumentRef={canUpdate}
            fromTemplates={taskCollectionRef.path.split('/').includes("templates")}
            documentRef={doc(taskCollectionRef, taskId!)}
            originalPath={props.originalPath}
          />
        </Stack>
        <Stack
          direction="column"
          alignItems="start"
          justifyContent="start"
          mt={{xs: 1, sm: 1, md: 0, lg: 0}}
        >
          <TaskStatusAndButtonsView
            {...taskProps}
            requirementsRejectedCount={props.requirementsRejectedCount}
            filesCount={props.filesCount}
            uid={props.uid}
            toastProps={toastProps!}
            taskStatusOption={taskStatusOption}
            selectedStatus={selectedStatus}
            documentRef={doc(taskCollectionRef, taskId!)}
            access={taskAccess}
          />
        </Stack>
      </Stack>
      <TaskAssignees
          task={task}
          uid={props.uid}
          canUpdate={canUpdate}
          taskCollectionRef={taskCollectionRef}
          parentEntity={parentEntity}
          toastProps={toastProps!}
      />
      <Stack direction="column" sx={{marginTop: 2}}>
        <Statistics
        toastProps={toastProps!}
        {...taskProps}
        {...rest}
      />
      </Stack>
      <TaskTimelineAndSchedule
        task={task}
        uid={props.uid}
        canUpdate={canUpdate}
        taskCollectionRef={taskCollectionRef}
        parentEntity={parentEntity}
        toastProps={toastProps!}
      />
      <Stack
        direction={{sm: "column", xs: "column", md: "row", lg: "row"}}
        spacing={{sm: 1, xs: 1, md: 2, lg: 2}}
        justifySelf="flex-end"
        flex={1}
        alignSelf={{sm: "flex-start", xs: "flex-start", md: "flex-end", lg: "flex-end"}}
      >
        <SecondaryButton
          id="create-new-file-requirement-header-button"
          onClickFcn={openCreateFileRequirement}
          label={enTaskButton.newFileRequirement}
          disabled={!fileRequirementsAccess || !fileRequirementsAccess[PermissionOperationKey.Create]}
          sx={{height: "32px", maxWidth: "210px"}}
        />
        <SecondaryButton
          id="create-new-form-requirement-header-button"
          onClickFcn={openCreateFormRequirement}
          label={enTaskButton.newFormRequirement}
          disabled={!formRequirementAccess || !formRequirementAccess[PermissionOperationKey.Create]}
          sx={{height: "32px", maxWidth: "210px"}}
        />
      </Stack>
    </Stack>
  )
}

export default Head;
