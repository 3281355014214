import {Input, ValidationMessage} from "components/index";
import {enFormsButton, enFormsLabel} from "constants/index";
import {Button, IconButton, List, ListItem, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import React, {useEffect, useState} from "react";
import {SystemIcons} from "assets/icons/system/system.index";
import {FormFieldProps} from "../BaseField";
import {FormFieldType} from "enums/formFieldType";
import {FormField} from "types/FormField";

export function ListFormField(props: FormFieldProps) {
  const {changeFormData, formField, isEditable, index} = props;
  const {errors} = formField as FormField<FormFieldType.List>;
  const [items, setItems] = useState<string[]>((formField as FormField<FormFieldType.List>).items ?? []);

  useEffect(cleanItems, [isEditable]);
  useEffect(() => {
    if (items.length) return;
    addItem();
  }, []);

  if (!isEditable) return (
    <List style={{listStyle: "circle", padding: "0px 16px"}}>
      {
        items.map((item) => (
          <ListItem sx={{display: "list-item"}} key={`listItemPreview_${index}_${item}`} disableGutters>{item}</ListItem>
        ))
      }
    </List>
  )

  function addItem() {
    const newItems = [...items];
    newItems.push("");
    setItems(newItems);
    changeFormData(newItems, "items", index, true);
  }

  function deleteItem(i: number) {
    const newItems = [...items];
    newItems.splice(i, 1);
    setItems(newItems);
    changeFormData(newItems, "items", index, true);
  }

  function onItemChange(value: string, i: number) {
    const newItems = [...items];
    newItems[i] = value;
    setItems(newItems);
    changeFormData(newItems, "items", index, true);
  }

  function cleanItems() {
    if (isEditable) return;
    const newItems = items.filter((item) => !!item.trim());
    setItems(newItems);
    changeFormData(newItems, "items", index, true);
  }

  function onKeyPress(e: React.KeyboardEvent) {
    if (e.key === "Enter") {
      e.preventDefault();
      addItem();
    }
  }

  return (
    <Stack gap={0.5}>
      <Typography variant="h5">{enFormsLabel.listItems}</Typography>
      <ValidationMessage validationMessage={errors?.items}/>

      {
        items.map((item, index) => (
          <Stack direction="row" gap={1.5} key={`listItem_${index}`}>
            <Input
              sx={{".MuiOutlinedInput-input": {backgroundColor: theme.palette.common.white}, flex: 1}}
              onChange={(e: React.ChangeEvent<any>) => onItemChange(e.target.value, index)}
              placeholder={`Item ${index+1}`}
              value={item}
              onKeyPress={onKeyPress}
            />
            <IconButton onClick={() => deleteItem(index)}>
              <SystemIcons.Close stroke={theme.palette.neutral.dark}/>
            </IconButton>
          </Stack>
        ))
      }
      <Button
        sx={{alignSelf: "start", color: theme.palette.secondary.main}}
        startIcon={<SystemIcons.PlusCircle width={16} height={16} strokeWidth={3} stroke={theme.palette.primary.main}/>}
        onClick={addItem}
      >
        {enFormsButton.newItem}
      </Button>
    </Stack>
  )
}