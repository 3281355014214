export const user = {
  title: "Users",
  label: {
    emptyTitle: "No users yet",
    emptyDescription: "Add the first user",
    new: "Add New Users",
    search: "Search Users...",
    allUsers: "All Users",
    editUser: "Edit User",
    deactivateUser: "Deactivate User",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    invalidEmail: "Invalid Email",
    emailAlreadyExists: "Email already exists",
    editSuccess: "User edited successfully",
    fieldIsRequired: "This field is required",
    deactivate: "Deactivate",
    xSelected: (selected: number) => `${selected} Selected`,
    deactivateSuccess: "User/s deactivated successfully.",
    deactivateUserName: (name: string) => `Are you sure you want to deactivate ${name}?`,
    bulkDeactivateConfirmationTitle: `Are you sure you want to deactivate all selected users?`,
    bulkDeactivateConfirmationText: `The selected users will be deactivated immediately. You can't undo this action.`
  }
}