import toTitleCase from "screens/utility/toTitleCase";

export const ENTITY_LABEL: Record<string, string> = {
  "User": "User",
  "Organization": "Organization",
  "Project": "Project",
  "Asset": "Asset",
  "Milestone": "Milestone",
  "PLTask": "Project Level Task",
  "Task": "Task",
  "FileRequirement": "File Requirement",
  "File": "File",
  "FormRequirement": "Form Requirement",
  "Form": "Form",
  "Invite": "Invite",
  "AccessList": "Access List",
  "Member": "Member",
  "Teams": "Teams",
  "ManageTeamCategories": "Manage Team Categories",
  "TeamsAndPeople": "Teams & People",
  "ActivityFeed": "Activity Feed",
  "EntityChat": "Chat",
  "Chat": "Chat",
  "ProjectLevelTask": "Project Level Task",
  "Templates": "Templates",
  "TimeLogs": "TimeLog",
  "DataTag": "Data Tag",
  "Timers": "Timers",
  "ReferenceFiles": "Reference Files",
  "OrganizationInvite": "Organization Invite",
  "OrganizationAsset": "Organization Asset",
  "UserInvite": "User Invite",
  "PLFileRequirement": "File Requirement",
  "PLFormRequirement": "Form Requirement",
  "SwiftFile": "File",
  "PLFile": "File",
  "PLMilestone": "Milestone",
  "RolesAndPermissions": "Roles & Permissions",
  "OrganizationTemplateMilestone": "Templates",
  "OrganizationTemplateTask": "Templates",
  "OrganizationTemplate": "Templates",
  "Team Category": "Team Category",
  "OrganizationTemplateFileRequirement": "File Requirement",
  "OrganizationTemplateFormRequirement": "Form Requirement",
}

export const common = {
  button: {
    add: "Add",
    apply: "Apply",
    cancel: "Cancel",
    clear: "Clear",
    clearAll: "Clear All",
    close: "Close",
    create: "Create",
    delete: "Delete",
    download: "Download",
    upload: "Upload",
    downloadFile: "Download File",
    downloadSampleFile: "Download Sample File",
    hide: "Hide",
    unHide: "Unhide",
    save: "Save",
    submit: "Submit",
    more: "More",
    approve: "Approve",
    reject: "Reject",
    reupload: "Reupload",
    unapprove: "Un-Approve",
    resend: "Resend",
    viewAllInvites: "View all Invites",
    editRole: "Edit Role",
    remove: "Remove",
    viewAllOrgs: "View All Organizations",
    xlsx: "Excel",
    csv: "CSV",
    deactivate: "Deactivate",
    joinCall: "JOIN",
    login: "Log In",
    signup: "Sign Up",
    backToLogin: "Back to Login",
    sendEmail: "Send Email",
    resetPassword: "Reset Password",
    resentVerificationEmail: "Resend Verification Email",
    successBackToLogin: "Success, Go back to Login",
  },
  label: {
    colorTone: "Color ",
    colorName: "Color Name",
    clickToCopy: "Copy Color",
    background: "Background",
    foreground: "Foreground",
    dataTagColors: "DataTag Colors",
    address: "Address",
    addressNotAvailable: "Address not available",
    all: "All",
    allDay: "All Day",
    assigned: "Assigned",
    assignedTo: "Assigned To",
    assignee: "Assignee",
    approvedOn: "Approved on",
    approver: "Approver",
    byDate: "By Date",
    cancelledOn: "Cancelled on",
    changeRole: "Change a role",
    chatMembers: "Chat Members",
    checkEmailForVerification: "Please check your email for the verification link.",
    chooseColor: "Choose color",
    clearAllDownloadedDescription: "Are you sure you want to clear all downloaded exports",
    clearAllDownloadedFailed: "Failed to clear all downloaded exports",
    clearAllDowloadedSuccess: "All downloaded exports has been successfully cleared",
    clearAllDownloadedTitle: "Clear all downloaded exports?",
    clickToDownload: "Click to download",
    collapse: "Collapse",
    coordinates: "Coordinates",
    coordinatesNotAvailable: "Coordinates not available",
    date: "Date",
    description: "Description",
    download: "Download",
    downloadFilesError: "Error downloading files",
    downloadFilesSuccess: "The files has been successfully processed and is now prepared for download.",
    dueDate: "Due Date",
    dueOn: "Due on",
    emptyExport: "No files to export yet",
    emptyExportDescription: "Your export list is empty",
    emptyUploads: "No uploaded files yet",
    emptyUploadDescription: "Your upload list is empty.",
    expand: "Expand",
    exportComplete: "Export Complete",
    exporting: "Exporting",
    exports: "Exports",
    fileExported: "File Exported",
    noPreviewFile: "Unable to display file preview. Click icon to download.",
    notPlayable: "Unable to Play File. Click icon to download.",
    notifications: "Notifications",
    unavailableFeature: "Feature currently unavailable",
    loginRestriction: "Website access is temporarily unavailable due to maintenance. We apologize for any inconvenience.",
    rejectedOn: "Rejected on",
    somethingWentWrong: "Something went wrong",
    stillExporting: "Still exporting",
    submittedOn: "Submitted on",
    tooManyLoginAttemps: "Access to this account has been temporarily disabled due to many" +
      " failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
    unscheduled: "Unscheduled",
    noApprovedDate: "No approved date",
    noCancellationDate: "No cancellation date",
    noRejectedDate: "No rejected date",
    noSubmissionDate: "No submission date",
    notAvailable: "Not available",
    emailNotVerified: "Your email is not yet verified",
    resendVerificationEmail: "Resend verification link",
    signup: "Sign Up",
    teamCategory: "Team Category",
    fileUpload: (name: string) => `${name} has been uploaded successfully.`,
    unknownError: (errCode: string) => `An unknown error has occurred: ${errCode}`,
    unknownErrorMessage: 'An unknown error has occurred',
    errorExporting: "An unknown error occurred while exporting. Please contact system administrator.",
    successExporting: "Preparing data for export..",
    projectTemplate: "Project Template",
    pageNotFoundLine1: "The page you're trying to access does not exist.",
    pageNotFoundLine2: "You can view all of your organizations by clicking the button below.",
    importFailed: "Import Failed",
    importCompleted: "Import Completed",
    buildingCompleted: "Building Completed!",
    processCompleted: (process: string) => `${process} Completed!`,
    unAssigned: "Unassigned",
    observer: "Observer",
    latitude: "Latitude",
    longitude: "Longitude",
    hide: "Hide",
    hideCheckbox: "Hide Checkbox",
    showCheckBox:" Show Checkbox",
    unHide: "Unhide",
    name: "Name",
    noDescription: "No description",
    subscribers: "Subscribers",
    optional: "optional",
    none: "None",
    optionalWithParentheses: "(optional)",
    private: "Private",
    time: "Time",
    today: "Today",
    tomorrow: "Tomorrow",
    status: "Status",
    sort: "Sorted by",
    filterStatus: "Filter by Status",
    filters: "Filters",
    selected: "Selected",
    more: "More",
    showMore: "Show More",
    showLess: "Show Less",
    selectMultiple: "Select Multiple",
    seeMore: "See More...",
    selectedItems: (num: number) => `${num} Selected`,
    title: "Title",
    uploaded: "Uploaded",
    uploadComplete: "Upload Complete",
    uploading: "Uploading",
    processing: "Processing",
    preparing: "Preparing",
    failedUpload: "Failed",
    files: "Files",
    min: "Min",
    max: "Max",
    invites: "Invites",
    teams: "Teams",
    people: "People",
    for: "for",
    you: "You",
    searchEntity: (str: string) => `Search ${str}...`,
    searchEntityAccess: (str: string) => `Search ${str} Access...`,
    allEntity: (str: string) => `All ${toTitleCase(str ?? "")}`,
    noPermissionForAction: "You don't have enough permission to do this action",
    noPermissionLine1: (type: string) => `You do not have permission to view this ${type}.`,
    noPermissionLine2: "If you believe this is an error, please contact your system administrator.",
    importInProgress: "Import in progress",
    exportInProgress: "Export in progress",
    invalidInviteLine1: "Invitation code is invalid or does not exists.",
    invalidInviteLine2: "If you believe this is an error, please contact the person that invited you.",
    invalidFileType: "Invalid file type",
    organization: "Organization",
    project: "Project",
    asset: "Asset",
    organizationAsset: "Organization Asset",
    milestone: "Milestone",
    task: "Task",
    fileRequirement: "File Requirement",
    formRequirement: "Form Requirement",
    file: "File",
    form: "Form",
    invite: "Invite",
    expired: "Expired",
    accessList: "Access List",
    member: "Member",
    manageTeamCategories: "Manage Team Categories",
    teamsAndPeople: "Teams & People",
    activityFeed: "Activity Feed",
    chat: "Chat",
    projectLevelTask: "Project Level Task",
    templates: "Templates",
    timelog: "TimeLog",
    datatag: "Data Tag",
    timers: "Timers",
    referenceFiles: "Reference Files",
    organizationInvite: "Organization Invite",
    userInvite: "User Invite",
    rolesAndPermissions: "Roles & Permissions",
    user: "User",
    powerUser: "Power User",
    admin: "Admin",
    owner: "Owner",
    guest: "Guest",
    downloadFilesWith: "Download all files with...",
    downloadOptions: {
      originalFileName: "Original Filename",
      requirementFileName: "Requirement Filename",
    },
    noFilesToDownload: "There are no files to download.",
    deleteConfirmationText: "This item will be deleted immediately. You can't undo this action.",
    selectAll: (entity: string) => `Select All ${entity}`,
    unselectAll: (entity: string) => `Unselect All ${entity}`,
    countSelected: (count: number) => `${count} Selected`,
    defaultSearchPlaceHolder: "Search...",
    deleteMultipleEntities: (entity: string) => `Delete selected ${entity}`,
    deleteBulkConfirmationText: "This items will be deleted immediately. You can't undo this action.",
    unsavedChangesText: "Your form has unsaved changes. Are you sure you want to discard it?",
    areYouSure: "Are you sure?",
    dataTags: {
      createTag: "Create tag ...",
      manageDataTags: "Manage data tags",
      title: "Data Tags",
      lastChangedBy: (name: string) => `Last changed by ${name}`,
      editSuccessToast: (tagName: string) => `Successfully edited ${tagName}`,
      noPermissionToAdd: "You don't have permission to add data tags",
      noDocumentRef: "No document reference to edit",
    },
    seeLess: "See Less...",
    search: {
      noResultsTitle: "No Results found",
      noResultsSubtext: (entity: string) => `We did not find any ${entity} for your search`
    },
    searchChatMembers: "Search Chat Members",
    statusUpdateError: "Encountered an error while updating status",
    statusUpdateSuccess: "Status successfully updated!",
    errorProcess: (process: string) => `Encountered an error while ${process}`,
    importEntity: (entity: string) => `Import ${entity}`,
    successfullyUpdated: "successfully updated",
    importEntityDescription: (entity: string) => `Importing a file to ${entity}. Select Report Format to import:`,
    downloadTimeData: (entity: string) => `Download ${entity} Time Data`,
    downloadTimeDataSuccess: "Time data has been successfully processed and is now prepared for export.",
    downloadTimeDataError: "Encountered an error while downloading time data",
    entityChat: (entity: string) => `${entity} Chat`,
    submittedBy: (name: string) => `Submitted by ${name}`,
    approvedBy: (name: string) => `Approved by ${name}`,
    cancelledBy: (name: string) => `Cancelled by ${name}`,
    rejectedBy: (name: string) => `Rejected by ${name}`,
    noDataToExport: "There is no data to export",
    goToLogin: "Go to Login",
    goToSignup: "Go to Sign Up",
    securityError: "Encountered a Security Error while submitting this form",
    passwordValidationError: "Password must be, at least 8 characters long, include 1 number, and 1 uppercase letter",
    passwordDoesNotMatch: "Password does not match",
    email: "Email",
    viewChatMembers: "View Chat Members",
    clearApproved: "Clear Approved",
    password: "Password",
    processPanel: "Process Panel",
    confirm: "Confirm",
    confirmPassword: "Confirm Password",
    lastName: "Last Name",
    phoneOptional: "Phone (optional)",
    login: "Log In",
    alreadyHaveAnAccount: "Already have an account?",
    iAgree: "I agree to the",
    and: "and",
    termsOfService: "Terms of Service",
    privacyPolicy: "Privacy Policy",
    resetPasswordText: "Please enter your email to receive password reset instructions.",
    resetPasswordInstruction: "We have sent a reset password instruction to ",
    passwordReset: "Password Reset",
    enterValidEmail: "Please enter a valid email.",
    invalidEmailInstruction: "Cannot send the instructions due to incorrect/invalid email",
    invalidToken: "The token is either expired or invalid.",
    cannotFindAccount: "An error was encountered while finding your account. Please try again later.",
    newPasswordText: "Please set a new password for your account",
    successfulResetText: "You successfully changed your password. You will be redirected to login in",
    seconds: "seconds",
    newPassword: "New Password",
    confirmNewPassword: "Confirm New Password",
    facingProblems: "Still facing problems?",
    contactUs: "Contact us",
    alreadyVerified: "Your account has been verified and ready to use.",
    returnToLogin: "You can click the button below to go to login.",
    requestNewVerification: "Click the button below to request a new verification email.",
    invalidVerificationToken: "The verification token that is used is either invalid or expired.",
    forgotPassword: "Forgot Password?",
    welcome: "Welcome",
    username: "Username",
    almostThere: "You are almost there!",
    stillFacingProblems: "Still facing problems?",
    signupSuccessSubTextLine1: "We have sent a verification mail to the email address",
    signupSuccessSubTextLine2: "you used to register with us.",
    signupSuccessSubTextLine3: "To complete the registration process,",
    signupSuccessSubTextLine4: "you can click on the link attached in the email.",
    signupSuccessSubTextLine5: "If you didn't receive any email from us,",
    signupSuccessSubTextLine6: "you may need to check your spam/junk folder.",
    favoriteSuccess: "Successfully added to favorites",
    unfavoriteSuccess: "Successfully removed from favorites",
    googleAutocompleteInfo: "Enter the address in the input field and select from the dropdown list.",
    subscribe: "Subscribe",
    unsubscribe: "Unsubscribe",
    subscribeToastSuccess: "Successfully subscribed to chat",
    unsubscribeToastSuccess: "Successfully unsubscribed from chat",
    errorWhenProcessingUpload: "Encountered an error while processing one of the uploaded files",
    headers: "Headers",
    missingRequiredHeaders: (missingHeaders: string[]) => `Missing required headers: ${missingHeaders.join(", ")}, click the i button for more information`,
    requiredHeadersInstructions: "Check the file and make sure required headers are present",
    importHeader: (entity: string) => `How to import ${entity} Data`,
    maxImportLimit : (limit: number) => `Exceeded max number of rows, limit imports to ${limit} rows only`,
    dummyInstructions: "This is a dummy instruction",
    uploadError: "Encountered an error while uploading.",
    importInstructionsProjectAsset:
      "During the import process, Milestones, Tasks, and Requirements are matched by name. To avoid unexpected results, we recommend that you do not intentionally create duplicate named tasks under the same milestone or duplicate named requirements under the same task.\n" +
      "You may include the same task on multiple rows to import multiple tasks under the same milestone and/or multiple requirements under the same task.\n" +
      "When including a requirement, make sure to specify the type of requirement (File/Form). This means that you can have requirements with duplicate names, but they must have different requirement types.\n" +
      "Duplicate tasks names under different milestones will create different tasks under each milestone. The same applies to duplicate requirement names under different task names.\n" +
      "If a milestone name is not provided but other details related to the milestone are provided, it will be ignored. The same goes for tasks and requirements.\n" +
      "For requirements with \"requirement_type\" equal to \"Form,\" the \"form_name\" should exist in the Forms page. If not, the requirement will not be imported. As for the \"reference_file,\" if the name does not exist in the Reference Files page, the requirement will still be imported, but the reference file field will be ignored.\n" +
      "The import system currently does not support importing duplicates under the same milestone or task by design. This means that you cannot create two tasks with the same name under the same milestone. This will only create the named task once. This is intended to support including multiple requirements under a single task. Similarly, you cannot create duplicate requirements under the same task. If you attempt to import tasks with duplicate names but with different details like description or status, only the details of the first task will be imported.\n" +
      "To use the import system to update task dates, you must include the date in the following formats:\n" +
      "For a date only, use MM/DD/YYYY (e.g., 10/15/2023).\n" +
      "For a date and time, use MM/DD/YYYY, HH:MM (e.g., 10/15/2023, 14:30). Be sure to change the \"task_use_time\" column to \"true\" or \"false\" and add the comma separating the date and time to ensure that your dates are imported correctly.",
    importInstructionsTemplate: "During the import process, Milestones, Tasks, and Requirements are matched by name. To avoid unexpected results, we recommend that you do not intentionally create duplicate named tasks under the same milestone or duplicate named requirements under the same task.\n" +
      "You may include the same task on multiple rows to import multiple tasks under the same milestone and/or multiple requirements under the same task.\n" +
      "When including a requirement, make sure to specify the type of requirement (File/Form). This means that you can have requirements with duplicate names, but they must have different requirement types.\n" +
      "Duplicate tasks names under different milestones will create different tasks under each milestone. The same applies to duplicate requirement names under different task names.\n" +
      "If a milestone name is not provided but other details related to the milestone are provided, it will be ignored. The same goes for tasks and requirements.\n" +
      "For requirements with \"requirement_type\" equal to \"Form,\" the \"form_name\" should exist in the Forms page. If not, the requirement will not be imported. As for the \"reference_file,\" if the name does not exist in the Reference Files page, the requirement will still be imported, but the reference file field will be ignored.\n" +
      "The import system currently does not support importing duplicates under the same milestone or task by design. This means that you cannot create two tasks with the same name under the same milestone. This will only create the named task once. This is intended to support including multiple requirements under a single task. Similarly, you cannot create duplicate requirements under the same task. If you attempt to import tasks with duplicate names but with different details like description or status, only the details of the first task will be imported.",
    importInstructionsNotes: "Please ensure that you complete the mandatory fields as per the" +
      " table provided below.\nTo avoid unexpected results, we recommend that you do not" +
      " intentionally create duplicate named tasks under the same milestone/task.\nWhen" +
      " incorporating a requirement, please be certain to specify the type of requirement" +
      " (File/Form).\nWhen importing data with a form, ensure that the corresponding form exists" +
      " in the current organization.",
    ignoreMilestoneAndTaskText: "If you do not wish to include a \"Milestone Name\" or \"Task" +
      " Name\" for an" +
      " asset, you can simply omit its columns name.\nThe absence of these names will indicate" +
      " that there is no milestone or task associated with the asset.",
    ignoreMilestoneAndTask: "Ignoring Milestone or Task Entry",
    importDuplicateMilestoneAndTaskWarning: "The system currently does not support the importation of duplicates" +
      " within the same milestone or task by design.\nIn the event of duplicate names, only the" +
      " first entity will be imported.",
    importDuplicateOrganizationAssetWarning: "The system currently does not support the" +
      " importation of duplicates organization asset name by design.\nIn the event of" +
      " duplicate names, only the first entity will be imported.",
    importDuplicateDatatagWarning: "The system currently does not support the importation of" +
      " duplicates" +
      " within the same organization by design.\nIn the event of duplicate names, only the" +
      " first entity will be imported.",
    importFormWarning: "Importing data will overwrite any existing form fields in the current form.",
    importInstructionsDataTag: "Please ensure that you complete the mandatory fields as per the" +
      " table provided below.\nTo avoid unexpected results, we recommend that you do not" +
      " intentionally create duplicate datatag name.",
    importDataTagColorInstruction: "Datatag colors and their responding color code.\nUse the" +
      " color code value in the colors column if you want the datatag to be displayed in that color.",
    importInstructionsOrganizationAsset: "Please ensure that you complete the mandatory fields as" +
      " per the table provided below.\nTo avoid unexpected results, we recommend that you do not" +
      " intentionally create duplicate asset name.",
    importInstructionsForm: "Please ensure that you complete the mandatory fields as per the" +
      " table provided below.",
    notes: "Notes",
    addingOrganizationAssets: "Adding organization assets",
    addingAssets: "Adding assets",
    addingDatatags: "Adding datatags",
    addingForm: "Adding Form",
    addingMilestones: "Adding milestones",
    organizationAssetNameRequired: "Asset Name [Required].\nEvery row" +
      " must contain an \"Asset Name\" entry." ,
    assetNameAndCodeRequired: "Asset Name and Asset ID Code [Required and Unique].\nEvery row" +
      " must contain an \"Asset Name\" and an \"Asset ID Code\" entry.",
    milestoneNameRequired: "Milestone Name [Required and Unique].\nEvery row" +
      " must contain a \"Milestone Name\" entry.",
    datatagNameRequired: "Name [Required and Unique].\nEvery row" +
      " must contain a \"Name\" entry." +
      "\nResponse is required - set to false if response is not required for this field.",
    formFieldsRequired: "Title - title of the form field inserted." +
      "\nField Type - type of field you want to import, possible values are: Text, Paragraph," +
      " Numeric, Date & List." +
      "\nResponse is required - set to false if response is not required for this field.",
    step: (step: string) => `Step ${step}`,
    prepareData: "Prepare Data",
    prepareDataDescription: "Use provided CSV/Excel below for template or Download CSV/Excel Data" +
      " from" +
      " existing Project",
    selectImportData: "Select Import Data",
    selectImportDataDescription: "This can either be CSV/Excel",
    waitForDataImported: "Wait for data to be imported",
    waitForDataImportedDescription: "The time for import varies base on how big the data.\n\nApprox. 3 - 5 min",
    warning: "Warning",
    null: "Null",
    errorCreatingParentDoc: "Error creating parent document",
    preparingParentDoc: "Please wait while we prepare the document",
    uploads: "Uploads",
  },
  status: {
    inProgress: "In Progress",
    pending: "Pending",
    submitted: "Submitted",
    rejected: "Rejected",
    cancelled: "Cancelled",
    approved: "Approved",
  },
  validations: {
    requiredName: "Name is required",
    uniqueName: "Name should be unique",
    generalError: "Something went wrong"
  },
  restrictions: {
    noPermission: "You do not have permission to view this page.",
    notFound: "Page not found",
  },
  manageAccess: {
    title: (entity: string) => `Manage ${entity} Access`,
    addNew: "Add New",
    ooops: "Ooops",
    addNewTeamOrPeople: "Add New Team or People",
    searchTeamOrPeople: "Search Team or People",
    chooseARole: "Choose a role",
    cannotDeleteAssignNewOwner: "Assign a new Owner to delete this member.",
    cannotEditAssignNewOwner: "Assign a new Owner to edit this member.",
    cannotEditNotEnoughPermission: (text: string) => `You don't have enough permission to edit this ${text}`,
    deleteThisMember: "Delete this member",
    selectedCounters: (team: number, people: number) => {
      if (team > 0 && people > 0) return `${team} team, ${people} people`;
      if (team > 0) return `${team} team`;
      if (people > 0) return `${people} people`;
      return "";
    },
    allAreAdded: (entity: string) => `You have already added all the people and teams to this ${ENTITY_LABEL[entity]}`,
    emptyManageAccessTitle: (entity: string) => `Currently, you are the only member of this ${ENTITY_LABEL[entity]}`,
    emptyManageAccessSubtitle: (entity: string) => `Add the first team or person to your ${ENTITY_LABEL[entity]}`,
    deleteXTeamsTitle: (label: number | string) => `Delete ${label} ${typeof (label) === "number" ? "Team(s)" : ""}`,
    deleteXMembersTitle: (label: number | string) => `Delete ${label} ${typeof (label) === "number" ? "Member(s)" : ""}?`,
    deleteSingleTeamSubtitle: "The members of this team will no longer have access to the project. You can't undo this action.",
    deleteTeamSubtitle: "The members of these teams will no longer have access to the project. You can't undo this action.",
    deleteSingleMemberSubtitle: "This member will no longer have access to the project. You can't undo this action.",
    deleteMemberSubtitle: "These members will no longer have access to the project. You can't undo this action.",
    deleteSuccess: (str: string) => `Selected ${str} has been deleted successfully.`,
    editSuccess: (str: string) => `Selected ${str} has been updated successfully.`,
    addSuccess: (str: string) => `Selected ${str} has been added successfully.`,
  },
  sort: {
    asc: `Name (A-Z)`,
    desc: `Name (Z-A)`,
    lastVisited: `Recently Accessed`,
    timeCreated: `Time Created`,
  }
}