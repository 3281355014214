import {Stack, Button, Typography, Chip, Box} from "@mui/material";
import {useEffect, useState} from "react";
import {MilestoneAssignedStatus, MilestoneAssignedProps} from "assets/arrayList/MilestoneAssignedStatus";
import {MilestoneAssignedStatus as Status} from "enums/milestoneAssignedStatus";
import {FilterPanelProps} from "../FilterPanelProps";
import {enCommonButton, enCommonLabel} from "constants/index";

function AssignedPanel(props: FilterPanelProps<Status>) {
  const {selectedFilter, setSelected} = props;

  const [isAssignedClearButtonEnabled, setIsAssignedClearButtonEnabled] = useState<boolean>(false);
  const [assignedFilter, setAssignedFilter] = useState<MilestoneAssignedProps>(MilestoneAssignedStatus);

  // check if there is a previous selected (from parent), if yes, set selected to true
  useEffect(() => {
    let initialStatus = MilestoneAssignedStatus;
    let newStatus: any = {};

    setIsAssignedClearButtonEnabled(selectedFilter.length > 0);

    Object.entries(initialStatus).map(obj => {
      let statusLabel = obj[0];
      let found = selectedFilter.indexOf(statusLabel as unknown as Status);

      newStatus[statusLabel] = {selected: (found >= 0)}

      return true;
    });
    setAssignedFilter(newStatus);
  }, [selectedFilter]);

  function renderChipFilters() {
    let chipFiltersList: any = [];

    Object.entries(assignedFilter).forEach((obj, index) => {
      let chipLabel: string = obj[0].toString();

      chipFiltersList.push(
        <Chip
          id={`assigned-item-${chipLabel.split(" ").join("-").toLowerCase()}`}
          label={chipLabel}
          key={index}
          sx={{margin: 0.5, padding: 1}}
          color={obj[1].selected ? "primary" : "default"}
          onClick={() => onChipClick(obj[0])}
        />
      );
    });

    return chipFiltersList;
  }

  function onChipClick(objectName: string) {
    let selected: Status[] = [];
    const updatedAssignedFilter = { ...assignedFilter }; // Create a copy of the state

    updatedAssignedFilter[objectName].selected = !updatedAssignedFilter[objectName].selected;

    setIsAssignedClearButtonEnabled(Object.values(updatedAssignedFilter).some(obj => obj.selected));

    Object.entries(updatedAssignedFilter).forEach(([str, obj]) => {
      if (obj.selected) {
        selected.push(str as unknown as Status);
      }
    });

    setSelected(selected);
  }

  return (
    <Stack
      sx={{display: "flex", mt: 1, mb: 1}}
      direction="column"
      alignContent="center"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="h4"
          sx={{fontSize: "14px"}}>
          {enCommonLabel.assigned}
        </Typography>
        <Button
          id="filter-assigned-clear-button"
          disabled={!isAssignedClearButtonEnabled}
          onClick={() => setSelected([])}>
          {enCommonButton.clear}
        </Button>
      </Stack>
      <Box sx={{pb: 1, pt: 1}}>
        {renderChipFilters()}
      </Box>
    </Stack>
  );
}

export default AssignedPanel;