import React, {useEffect, useState} from "react";
import {Button, SxProps, Theme, Typography} from "@mui/material";
import theme from "theme/theme";
import {Variant} from "@mui/material/styles/createTypography";
import SelectMenu from "components/SelectStatus/SelectMenu";
import {StatusItem} from "types/StatusItem";
import {ActionType, RequirementStatus, Severity} from "enums/index";
import {RejectionDialog} from "components/Dialogs";
import {DocumentReference} from "firebase/firestore";
import {ToastProps} from "screens/BaseProps";
import {enCommonLabel} from "constants/index";
import SelectRightIcon from "components/SelectStatus/SelectRightIcon";
import {statusSubmitHandler} from "../../screens/utility/statusSubmitHandler";
import {milestoneTaskandAssetStatus as TaskStatus} from "enums/milestoneTaskandAssetStatus";
import templateSubmitForm from "../../screens/utility/templateSubmitForm";

export interface SelectBase {
  icon?: any;
  color: string;
  title: string;
  isSelected?: boolean;
  isHidden?: boolean;
}

interface SelectStatusProps<T extends SelectBase> extends ToastProps {
  documentRef: DocumentReference;
  statusField: string
  id?: string;
  displayIconOnSelected?: boolean;
  displayTextOnSelected?: boolean;
  selected?: T;
  items: Record<string, T>;
  width?: string;
  selectSx?: any;
  selectItemSx?: any;
  isDisabled?: boolean; // for permission
  disabledStatuses?: string[];
  labelVariant?: Variant;
	selectIconSx?: SxProps<Theme>;
}

function SelectStatus<T extends SelectBase>(props: SelectStatusProps<T>) {
  const {selected, items, documentRef, statusField, id = "select-status", toastProps, ...rest} = props;
  const {displayIconOnSelected = true, displayTextOnSelected = true, isDisabled = false} = props;
  const {selectSx = {}, labelVariant = "h4", selectIconSx} = props;
  const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps;

  const [itemList, setItemList] = useState<Record<string, T> | null>(null);
  const [selectedItem, setSelectedItem] = useState<T | null>(null);
  const [isMenuShowing, setIsMenuShowing] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRejectionDialogOpen, setIsRejectionDialogOpen] = useState<boolean>(false);
  useEffect(() => {
    let options: Record<string, T> = {};
    const firstObject = Object.entries(items).filter((_obj, index) => index === 0);
    const newSelectedItem = selected === undefined ? firstObject[0][1] : selected;
    setSelectedItem(newSelectedItem);

    const isSelectedHasRejection = newSelectedItem.title === "Has Rejection";
    Object.entries(items).forEach(obj => {
      const label = obj[0];
      const properties = obj[1];

      options = {
        ...options,
        [label]: {
          ...properties,
          isSelected: label === selected?.title,
          isHidden: obj[0] === "Has Rejection" && !isSelectedHasRejection
        }
      }
    });

    setItemList(options);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  async function onUpdateSelectedStatus(obj: any) {
    const status = obj as StatusItem;

    switch (status.title as RequirementStatus) {
      case RequirementStatus.Rejected:
        setIsRejectionDialogOpen(true);
        break;
      default:
        setIsLoading(true);
        await updateStatus(status.title as RequirementStatus);
        break;
    }
  }

  async function confirmRejectionDialog(reason: string) {
    setIsLoading(true);

    await updateStatus(RequirementStatus.Rejected, {reasonForRejection: reason});
    setIsRejectionDialogOpen(false);
  }

  async function updateStatus(status: RequirementStatus, otherFields: any = {}) {
    await templateSubmitForm(documentRef.path, ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler({status, data, isLastUpdate, successCallback, errorCallback}),
      {[statusField]: status, ...otherFields}
    );
  }

  function successCallback() {
    setToastMessage(enCommonLabel.statusUpdateSuccess);
    setToastSeverity(Severity.Success);

    setIsToastOpen(true);
    setIsLoading(false);
  }

  function errorCallback() {
    setToastMessage(enCommonLabel.statusUpdateError);
    setToastSeverity(Severity.Error);

    setIsToastOpen(true);
    setIsLoading(false);
  }

  function onMenuToggle(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    e.preventDefault();

    if (isDisabled || isLoading) return;

    setAnchorEl(anchorEl === null ? e.currentTarget : null);
    setIsMenuShowing(!isMenuShowing);
  }

  function getStartIcon() {
    const disabledProps = isDisabled ? {stroke: theme.palette.neutral.dark} : {};

    if (selectedItem !== null && displayIconOnSelected) {
      const submittedProps = selectedItem.title === TaskStatus.Submitted ? {stroke: theme.palette.secondary.dark} : {};
      return selectedItem.icon({fill: selectedItem.color, width: 28, height: 28, ...disabledProps, ...submittedProps});
    }

    return null;
  }

  function closeRejectionDialog(e?: any) {
    if (!!e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsRejectionDialogOpen(false)
  }

  return (
    <>
      <Button
        id={id}
        variant="outlined"
        disableRipple={true}
        startIcon={getStartIcon()}
        onClick={onMenuToggle}
        sx={{
          color: theme.palette.secondary.main,
          backgroundColor: "transparent",
          borderColor: theme.palette.neutral.light,
          width: props.width ?? "180px",
          minWidth: "30px",
          ...selectSx,
          "& .MuiButton-startIcon": {
            marginRight: "4px",
            marginLeft: "0px",
          }
        }}
        title={isDisabled ? enCommonLabel.noPermissionForAction : undefined}
      >
        {displayTextOnSelected && (
          <Typography variant={labelVariant}>
            {selectedItem !== null ? selectedItem.title : ""}
          </Typography>
        )}

        <SelectRightIcon iconSize={16} selectIconSx={selectIconSx}  isLoading={isLoading} isMenuShowing={isMenuShowing}/>
      </Button>
      <SelectMenu
        id={id}
        anchorEl={anchorEl}
        isMenuShowing={isMenuShowing}
        onMenuToggle={onMenuToggle}
        itemList={itemList}
        setSelected={onUpdateSelectedStatus}
        {...rest}
      />
      <RejectionDialog
        isOpen={isRejectionDialogOpen}
        handleClose={closeRejectionDialog}
        handleConfirm={(reason: string) => confirmRejectionDialog(reason)}
      />
    </>
  )
}

export default SelectStatus;
