import {User} from "types/User";
import {SelectItem} from "components/inputs/SearchInput/SearchPopUp";
import generateUserDisplayItem from "./generateUserDisplayItem";

function generateSelectableUserItemFromUser(user: User): SelectItem {
  const {email, name} = user;
  return {
    value: email,
    searchable: name,
    displayItem: generateUserDisplayItem(user)
  }
}

export default generateSelectableUserItemFromUser