import {RequirementStatus} from "enums/index";
import SubmitButton from "components/Button/SubmitButton";
import ApproveButton from "components/Button/ApproveButton";
import {StatusItem} from "types/index";
import {Dispatch, useState} from "react";

interface StatusButtonProps {
  isDisabled: boolean;
  selectedStatus: StatusItem | undefined,
  updateStatus: (status: RequirementStatus, setLoading: Dispatch<boolean>) => void;
}

function StatusButton(props: StatusButtonProps) {
  const {selectedStatus, isDisabled, updateStatus} = props;
  const [loading, setLoading] = useState(false);

  if (!selectedStatus) return <></>;

  switch (selectedStatus.title) {
    case RequirementStatus.InProgress:
      return <SubmitButton
        onClick={() => {
          updateStatus(RequirementStatus.Submitted, setLoading)
          setLoading(true);
        }}
        isLoading={loading}
        isDisabled={isDisabled}
      />
    case RequirementStatus.Submitted:
      return <ApproveButton
        onClick={() => {
          updateStatus(RequirementStatus.Approved, setLoading)
          setLoading(true);
        }}
        isLoading={loading}
        isDisabled={isDisabled}
      />
    default:
      return <></>
  }
}

export default StatusButton;
