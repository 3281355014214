import {Box, Stack} from "@mui/material";
import SystemIndex from "assets/icons/system/system.index";
import {CreatedItemType} from "types/ActivityFeedTypes";
import TimestampTypo from "components/Typography/TimestampTypo";
import {formatDateTimeFirestoreDate} from "screens/utility/formatDateTimeFirestoreDate";
import {Entity} from "enums/entity";
import {enActivityFeedLabel} from "constants/index";
import ItemContainer from "components/ActivityFeed/ItemContainer";

interface AssignItemProps {
  item: CreatedItemType;
  entity: Entity;
}

function CreatedItem(props: AssignItemProps) {
  const {item} = props;
  const {details, timestamp, entity} = item;
  const {by, name} = details;
  const {date, time} = formatDateTimeFirestoreDate(timestamp);

  return (
    <ItemContainer>
      <Box width={22} height={22}>
        <SystemIndex.SystemIcons.Plus {...IconProps}/>
      </Box>
      <Stack direction="column">
        {enActivityFeedLabel.created(entity ?? "entity", name, by.name || "")}
        <TimestampTypo>{`${date} ${time}`}</TimestampTypo>
      </Stack>
    </ItemContainer>
  );
}

const IconProps = {
  width: 22,
  height: 22
}

export default CreatedItem;
