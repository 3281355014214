import {Divider, Grid, Hidden, Icon, Stack, styled, Typography} from "@mui/material";
import {taskTimelineScheduleStatus as TimelineStatus} from "enums/taskTimelineScheduleStatus";
import {TaskTimelineStatus} from "assets/arrayList/TaskTimelineScheduleStatus";
import theme from "theme/theme";
import {Task, PartialUserData} from "types/index";
import {defaultTimelinePersonnel} from "constants/index";
import {milestoneTaskandAssetStatus as TaskStatus} from "enums/milestoneTaskandAssetStatus";
import {Timestamp} from "firebase/firestore";
import {formatDateTimeFirestoreDate} from "screens/utility";
import AssignableAvatar from "components/AssignableAvatar";

export interface TimelineItemProps {
  task?: Task;
  type: TimelineStatus;
  by: PartialUserData;
  at: Timestamp | undefined;
  openTaskScheduleDrawer: () => void;
  taskStatus: TaskStatus;
}

function TimelineItem(props: TimelineItemProps) {
  const {type, at, taskStatus, openTaskScheduleDrawer} = props;
  const by = props.by ?? defaultTimelinePersonnel;

  let dateTextLong = TaskTimelineStatus.NotScheduled.title;
  let dateTextShort = TaskTimelineStatus.NotScheduled.title;

  if (!!at) {
    const {date, simpleTime, shortDate} = formatDateTimeFirestoreDate(at);
    dateTextLong = `${date} ${simpleTime}`;
    dateTextShort = `${shortDate} ${simpleTime}`;
  }

  return (
    <Grid
      item
      xs={3}
      margin={0}
      padding={0}
      key={type}
      sx={{cursor: "pointer"}}
      onClick={() => openTaskScheduleDrawer()}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{minHeight: "60px"}}
      >
        <Stack direction="column" alignItems="left" gap={0} spacing={0}>
          <Stack justifyContent="left">
            <Typography
              variant="body"
              color={theme.palette.text.secondary}
              sx={{fontSize: "12px", margin: 0, padding: 0}}>
              {TaskTimelineStatus[type].title}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="left"
            gap={1}
            sx={{margin: 0, padding: 0}}
          >
            <AssignableAvatar
              sx={{
                marginTop: 0,
                width: 20,
                height: 20,
                fontSize: '8px'
              }}
              // initials={by.initials}
              // avatarColor={by.avatarColor}
              {...by}
            />

            <Hidden mdDown>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: {xs: "8px", sm: "10px", md: "12px", lg: "14px"},
                  lineHeight: "40px",
                }}
              >
                {dateTextLong}
              </Typography>
            </Hidden>
            <Hidden mdUp>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: {xs: "8px", sm: "10px"},
                  lineHeight: "40px",
                }}
              >
                {dateTextShort}
              </Typography>
            </Hidden>
          </Stack>
        </Stack>
      </Stack>
      <Separator
        sx={{
          '&.MuiDivider-root': {
            '&::before': {
              borderTop: `3px solid ${taskStatus === TaskStatus.Pending ?
                theme.palette.neutral.main : TaskTimelineStatus[type].color}`,
            },
            '&::after': {
              borderTop: `3px solid ${taskStatus === TaskStatus.Pending ?
                theme.palette.neutral.main : TaskTimelineStatus[type].color}`,
            },
            span: {
              padding: 0,
            }
          }
        }}>
        <Icon sx={{marginTop: 1}}>
          {TaskTimelineStatus[type].icon({height: 24, width: 24, stroke: TaskTimelineStatus[type].color})}
        </Icon>
      </Separator>
    </Grid>
  )
}

/** start of styles */
const Separator = styled(Divider)({
  margin: 0,
  padding: 0,
  marginTop: "-10px",
});
/** end of styles */

export default TimelineItem;
