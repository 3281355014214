import {Box, Checkbox, Divider, Stack, Typography} from "@mui/material";
import VisibilityIcon from "components/VisibilityIcon";
import {doc} from "firebase/firestore";
import {enCommonLabel, enTaskLabel, enTemplateLabel} from "constants/index";
import {LongText} from "components/index";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {SwiftIconButton} from "components/Button";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDocument} from "hooks/index";
import {Task} from "types/Task";
import {db} from "../../../firebase";
import {toastProps} from "../../BaseProps";
import {CounterFields} from "enums/counterFields";
import useRTDBDocField from "hooks/useRTDBDocField";

interface TaskItemProps {
  taskId: string;
  taskRefPath: string;
  isChecked: boolean;
  isMilestoneCheckboxShown: boolean;
  toastProps: toastProps;
  taskAccess: AccessType | null;
  uid: string;
  editTask: (task: Task) => void;
  deleteTask: (task: Task) => void;
  checkTask: (taskId: string) => void;
}

function TaskItem(props: TaskItemProps) {
  const {taskId, isChecked, taskAccess, checkTask} = props;

  const taskDocRef = doc(db, props.taskRefPath);
  const [task] = useDocument<Task>(taskDocRef);
  const fileRequirementsCount = useRTDBDocField<number>(props.taskRefPath, CounterFields.FileRequirementsCount);
  const formRequirementsCount = useRTDBDocField<number>(props.taskRefPath, CounterFields.FormRequirementsCount);

  const navigate = useNavigate();
  const {orgId, templateId} = useParams();

  function onTaskClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();

    if (!task) return;

    const taskPathName = `/${orgId!}/templates/${templateId!}/milestones/${task.milestoneId!}/tasks/${taskId}`;
    navigate(taskPathName);
  }

  function onDeleteClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();

    props.deleteTask(task!);
  }

  function onEditClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();

    props.editTask(task!);
  }

  if (!task) return null;

  return (
    <Stack py={1}>
      {props.isMilestoneCheckboxShown && (
        <Checkbox
          className="task-checkbox"
          checked={isChecked}
          sx={{alignSelf: "center", paddingLeft: 0}}
          onClick={(e) => e.stopPropagation()}
          onChange={() => checkTask(taskId)}
        />
      )}
      <Stack
        key={`milestone-task-item-${taskId}`}
        padding={0}
        className="milestone-task-item"
        sx={(theme) => ({
          border: `1px solid ${isChecked ? theme.palette.primary.main : theme.palette.divider}`,
          borderRadius: 1,
          cursor: "pointer",
          ":hover": {
            border: `1px solid ${theme.palette.primary.main}`,
            cursor: "pointer",
          },
          backgroundColor: task.hidden ? undefined : theme.palette.background.default
        })}
        flex={1}
        onClick={onTaskClick}
      >
        <Stack direction="row" alignItems="center">
          <Stack direction="row" flex={1} gap={1}>
            <Box sx={{alignSelf: "center", marginLeft: 1, justifyContent: "center"}}>
              <VisibilityIcon
                hidden={task.hidden}
                documentRef={taskDocRef}
                hiddenMessage={enTaskLabel.hideSuccess}
                unHiddenMessage={enTaskLabel.unHideSuccess}
                toastProps={props.toastProps}
              />
            </Box>
            <Divider
              orientation="vertical"
              flexItem
            />
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="center"
              flex={1}
              ml={1}
              gap={0}
              py={1}
            >
              <Stack flex={1} width="100%" justifyContent="center">
                <LongText
                  variant="h4"
                  maxWidth="100%"
                >
                  {task.name}
                </LongText>
                <LongText
                  color="text.secondary"
                  variant="bodySmall"
                  maxWidth="100%"
                >
                  {!!task.description ? task.description : enCommonLabel.noDescription}
                </LongText>
              </Stack>
              <DataTagsDisplay
                dataTagsIds={task.dataTagsIds || []}
                toastProps={props.toastProps!}
                listSize="short"
                uid={props.uid}
                canEditDocumentRef={Boolean(taskAccess?.[PermissionOperationKey.Update])}
                isParentHidden={Boolean(task.hidden)}
                documentRef={taskDocRef}
              />
            </Stack>
            <Divider
              orientation="vertical"
              flexItem
            />
            <Stack direction="column" minWidth="7%" py={1} justifyContent="center">
              <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                <SystemIcons.FilesOutlined
                  stroke={theme.palette.neutral.dark}
                  height={16}
                  width={16}
                />
                <Typography
                  sx={{
                    padding: 0,
                    margin: 0,
                    color: theme.palette.neutral.dark,
                  }}
                  title={enTemplateLabel.fileRequirementsCount}
                >
                  {fileRequirementsCount || 0}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                <SystemIcons.Requirement
                  stroke={theme.palette.neutral.dark}
                  height={16}
                  width={16}
                />
                <Typography
                  sx={{
                    padding: 0,
                    margin: 0,
                    color: theme.palette.neutral.dark,
                  }}
                  title={enTemplateLabel.formRequirementsCount}
                >
                  {formRequirementsCount || 0}
                </Typography>
              </Stack>
            </Stack>
            <Divider
              orientation="vertical"
              flexItem
            />
            <Stack direction="column" py={1} pr={0.2} justifyContent="center">
              <Stack direction="row" alignItems="center" justifyContent="center">
                <SwiftIconButton
                  onClickFcn={onEditClick}
                  disabled={Boolean(!taskAccess?.[PermissionOperationKey.Update])}
                  startIcon={(props) => <SystemIcons.Edit stroke={theme.palette.secondary.main} {...props}/>}
                />
                <SwiftIconButton
                  onClickFcn={onDeleteClick}
                  disabled={Boolean(!taskAccess?.[PermissionOperationKey.Update])}
                  startIcon={(props) => <SystemIcons.DeleteOutlined stroke={theme.palette.error.main} {...props}/>}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default TaskItem;