import {Avatar, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import React from "react";
import {AccessListUser} from "types/AccessListUser";
import {SuggestionDataItem} from "react-mentions";

interface MentionSuggestionItemProps {
  suggestion: SuggestionDataItem;
  currentSuggestion: AccessListUser;
  mentionablesLength: number
}

function MentionSuggestionItem(props: MentionSuggestionItemProps) {
  const {mentionablesLength, suggestion, currentSuggestion} = props;

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "6px",
        mb: mentionablesLength < 5 ? 0 : 1,
        padding: "0.25rem",
        cursor: "pointer",
        borderRadius: "0.5rem",
        transition: "all ease 0.3s",
        "&:hover": {
          backgroundColor: theme.palette.neutral.light
        }
      }}
    >
      <Avatar sx={{bgcolor: currentSuggestion.avatarColor, mt: 0}}>{currentSuggestion.initials}</Avatar>
      <Stack direction="column" marginY={0.5}>
        <Typography variant="body">{suggestion.display}</Typography>
        <Typography variant="caption" marginTop={-0.5}>{currentSuggestion.email}</Typography>
      </Stack>
    </Stack>
  )
}

export default MentionSuggestionItem;