import {Variant} from "@mui/material/styles/createTypography";
import {Button, Stack, Typography} from "@mui/material";
import {useComponentToggler} from "hooks/index";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {enCommonLabel} from "constants/index";
import {useMemo} from "react";

interface CollapsableTextProps {
  id?: string;
  text?: string;
  maxLength?: number;
  previewTextVariant?: Variant;
  overflowWrap?: string;
  gap?: number;
  wordThreshold?: number;
  fontSize?: string;
}
const MAX_LENGTH = 128;
function CollapsableText(props: CollapsableTextProps) {
  const {
    id = "description",
    text = "",
    previewTextVariant = "body",
    maxLength = MAX_LENGTH,
    overflowWrap = "initial",
    gap = 0.5,
    fontSize = "inherit",
  } = props;

  const [isCollapsed, {toggle: toggleCollapse}] = useComponentToggler(true);

  const truncatedText = useMemo(() => {
    if (!text) {
      return enCommonLabel.noDescription;
    }

    if (isCollapsed) {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
      } else {
        const lines = text.split('\n');
        if (lines.length > 3) {
          const truncatedLines = lines.slice(0, 3).join('\n');
          return truncatedLines + '...';
        }
      }
    } else {
      return text;
    }
  }, [text, isCollapsed]);

  const displayedText = useMemo(() => {
    if (!isCollapsed) return text;
    if (truncatedText) return truncatedText;
    return text;
  }, [text, truncatedText, isCollapsed]);
  
  return (
    <Stack direction="column" gap={gap}>
      <Stack direction="row">
        <Typography variant={previewTextVariant} id={id} sx={{ overflowWrap, fontSize }} dangerouslySetInnerHTML={{ __html: displayedText.replace(/\n/g, '<br>') }} />
      </Stack>
      <Stack direction="row">
        {truncatedText && (
          <Button
            variant="text"
            color="secondary"
            size="small"
            onClick={toggleCollapse}
            sx={{
              width: "100px",
              p: 0,
              alignItems: "space-between",
            }}
            endIcon={isCollapsed ?
              <SystemIcons.ChevronDown
                stroke={theme.palette.text.secondary}
                height={16}
                width={16}
              />
              :
              <SystemIcons.ChevronUp
                stroke={theme.palette.text.secondary}
                height={16}
                width={16}
              />}
          >
            <Typography
              variant="subtitle2"
              sx={(theme) => ({color: theme.palette.text.secondary})}
            >
              {isCollapsed ? enCommonLabel.showMore : enCommonLabel.showLess}
            </Typography>
          </Button>
        )}
      </Stack>
    </Stack>
  )
}

export default CollapsableText;