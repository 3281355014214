import {Box, SxProps, Theme, Typography} from "@mui/material";
import React from "react";

interface ValidationMessageProps {
  validationMessage: string | undefined;
  sx?: SxProps<Theme>;
}

function ValidationMessage(props: ValidationMessageProps) {
  const {validationMessage, sx = {}} = props;

  if(!validationMessage) return null;

  return (
    <Box sx={{height: 0, marginBottom: "20px"}}>
      <Typography
        sx={{
          minHeight: "1em",
          color: "#FF5230",
          alignSelf: "flex-start",
          width: "max-content",
          fontSize: "12px",
          lineHeight: "20px",
          ml: 0,
          ...sx
        }}
        id={"validation-error"}
      >
        {validationMessage}
      </Typography>
    </Box>
  )
}

export default ValidationMessage;