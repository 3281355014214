import {BaseProps} from "../BaseProps";
import {Asset, FormRequirement} from "types/index";
import {PillItemProps} from "components/PilledNavBar/PillItem";
import Icons from "assets/icons";
import theme from "theme/theme";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {SidebarIcons} from "assets/icons/menu/menu.index";
import {SystemIcons} from "assets/icons/system/system.index";
import {emptyFunction, enCommonButton, enFormRequirementLabel} from "constants/index";
import {DotIndicator, PilledNavigationBar} from "components/index";
import {Hidden, IconButton, Stack} from "@mui/material";
import NavBarMenu from "./Objects/NavBarMenu";
import {collection, doc} from "firebase/firestore";
import ActivityFeed from "components/ActivityFeed";
import {Entity} from "enums/entity";
import ChatDrawer from "components/ChatDrawer";
import {useComponentToggler, useDocument, useRTDBDocField} from "hooks/index";
import {ManageFormRequirementDrawer} from "components/Drawers";
import {assetPath, getDocPath, tasksPath} from "../utility";
import {ChatIconButton, SwiftIconButton} from "components/Button";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {db} from "../../firebase";

interface HeaderProps extends BaseProps {
  formRequirement: FormRequirement;
  documentRef: string;
  access: AccessType | null;
}

function Header(props: HeaderProps) {
  const {formRequirement, documentRef, toastProps, access, uid} = props;
  const {orgId, projId, assetId, milestoneId, taskId} = useParams();
  const navigate = useNavigate();

  const {name, orgName, projectName, taskName} = formRequirement;
  const taskCollectionRef = tasksPath(orgId!, projId!, assetId!, milestoneId!, undefined);
  const [asset, setAssetRef] = useDocument<Asset>(null);

  // user specific data for this form requirement
  const userDocPath = getDocPath(uid, documentRef);
  const hasNewChat = useRTDBDocField<boolean>(userDocPath, "hasNewChat") ?? false;
  const hasNewActivity = useRTDBDocField<boolean>(userDocPath, "hasNewActivity") ?? false;
  const chatEmpty = useRTDBDocField<boolean>(documentRef, "chatEmpty") ?? true;

  const [isMenuShowing, setIsMenuShowing] = useState<boolean>(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [isChatDrawerOpen, {open: openChatDrawer, close: closeChatDrawer}] = useComponentToggler(false);
  const [isActivityFeedOpen, {open: openActivityFeed, close: closeActivityFeed}] = useComponentToggler(false);
  const [isEditReqOpen, {open: openEditReq, close: closeEditReq}] = useComponentToggler(false);

  useEffect(() => {
    if (!assetId) return;
    setAssetRef(assetPath(orgId!, projId!, assetId!));
  }, [assetId]);

  const projectsPath = `/${orgId!}/projects/${projId!}/${assetId ? `assets/${assetId!}` : `project-level-tasks`}`;
  let pills: PillItemProps[] = [
    {
      id: "org-name",
      label: "...",
      icon: <Icons.SidebarIcons.Folder fill={theme.palette.neutral.dark} width={16} height={16}/>,
      onClickFcn: () => navigate(`${projectsPath}`),
      hoverLabel: `${orgName ?? "Org Name"} / ${projectName ?? "Project Name"}`
    },
  ];

  if (assetId !== undefined) {
    pills = [...pills, {
      id: "asset-name",
      label: asset?.name ?? "Asset Name",
      icon: <SidebarIcons.Assets width={16} height={16}/>,
      onClickFcn: () => navigate(`/${orgId!}/projects/${projId!}/assets/${assetId!}`),
      hoverLabel: asset?.name ?? "AssetName",
    }];
  }

  const taskPath = `${projectsPath}/milestones/${milestoneId!}/tasks/${taskId!}`;

  pills = [...pills, {
    id: "task-name",
    label: taskName ?? "Task Name",
    icon: <SystemIcons.Tasks width={16} height={16} stroke={theme.palette.neutral.dark}/>,
    onClickFcn: () => navigate(taskPath),
    hoverLabel: taskName,
  }, {
    id: "form-requirement-name",
    label: name ?? "Requirement Name",
    icon: <SystemIcons.Requirement width={16} height={16} stroke={theme.palette.neutral.dark}/>,
    onClickFcn: emptyFunction,
    hoverLabel: name,
  }];

  function openMoreMenu(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    setMenuAnchor(e.currentTarget);
    setIsMenuShowing(true);
  }

  function closeMoreMenu() {
    setMenuAnchor(null);
    setIsMenuShowing(false);
  }

  return (
    <>
      <ActivityFeed
        uid={uid}
        onClose={closeActivityFeed}
        isOpen={isActivityFeedOpen}
        collectionReference={collection(doc(db, documentRef), "activityFeed")}
        entity={Entity.FormRequirement}
      />
      <ChatDrawer
        uid={uid}
        isOpen={isChatDrawerOpen}
        entity={Entity.FormRequirement}
        pathPair={[
          `${projectName ?? "ProjName"} / ${asset?.name ?? "AssetName"} / ${taskName ?? "TaskName"}`,
          name ?? ""
        ]}
        onClose={closeChatDrawer}
        colRef={collection(doc(db, documentRef), "chatMessages")}
        toastProps={toastProps!}
      />
      <ManageFormRequirementDrawer
        uid={uid}
        formRequirement={formRequirement}
        formRequirementCollectionRef={collection(doc(taskCollectionRef, taskId), "formRequirements")}
        isDrawerOpen={isEditReqOpen}
        onDrawerClose={closeEditReq}
        toastProps={toastProps!}
      />
      <PilledNavigationBar pills={pills}>
        <Stack direction="row" gap={0.5}>
          <Hidden smDown>
            <SwiftIconButton
              id="requirement-nav-bar-edit-task-button"
              onClickFcn={openEditReq}
              disabled={Boolean(!access?.[PermissionOperationKey.Update])}
              startIcon={SystemIcons.Edit}
            />
            <IconButton
              id="requirement-nav-bar-activity-feed-button"
              title={enFormRequirementLabel.activityFeed}
              onClick={openActivityFeed}
            >
              <DotIndicator isVisible={Boolean(hasNewActivity)}>
                <SystemIcons.Notifications/>
              </DotIndicator>
            </IconButton>
            <ChatIconButton
              id="requirement-nav-bar-chat-button"
              entity={Entity.FormRequirement}
              withNewMessage={hasNewChat}
              chatEmpty={chatEmpty}
              onClickFcn={openChatDrawer}
            />
          </Hidden>
          <IconButton
            id="requirement-nav-bar-more-button"
            title={enCommonButton.more}
            onClick={openMoreMenu}
          >
            <SystemIcons.MoreVertical fill={theme.palette.text.secondary}/>
          </IconButton>
          <NavBarMenu
            access={access}
            anchorEl={menuAnchor}
            closeMenu={closeMoreMenu}
            toastProps={toastProps!}
            formRequirement={formRequirement}
            open={isMenuShowing}
            documentRef={doc(db, documentRef)}
          />
        </Stack>
      </PilledNavigationBar>
    </>
  )
}

export default Header;
