import {Member} from "types/Member";
import {Stack, Typography} from "@mui/material";
import React from "react";
import {Avatar} from "components/index";

interface MemberItemProps {
  member: Member;
}

export default function MemberItem(props: MemberItemProps) {
  const {member} = props;
  const {name} = member;

  return (
    <Stack direction="row" gap={1.5} alignItems="center">
      <Avatar
        {...member}
        sx={{width: 32, height: 32, fontSize: 12, mt: 0, border: 0}}
        nameComponent={(
          <Stack>
            <Typography variant="h5">{name}</Typography>
            <Typography variant="bodySmall">{member.role}</Typography>
          </Stack>
        )}
      />
    </Stack>
  )
}
