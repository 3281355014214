import {Stack, Switch, Typography} from "@mui/material";
import {enFormsLabel} from "constants/index";
import {BasicAdvancedOptions, FormFieldAdvancedOptionsProps, OptionColumnWrapper} from "./index";
import {ValidationMessage} from "components/index";
import React from "react";
import {FormFieldType} from "enums/formFieldType";
import {FormField} from "types/FormField";

export function DateAdvancedOptions(props: FormFieldAdvancedOptionsProps) {
  const {changeFormData, index, formField} = props;
  const {includeTime = false, disablePreviousDates = false, errors} = formField as FormField<FormFieldType.Date>;

  return (
    <>
      <BasicAdvancedOptions {...props}/>
      <OptionColumnWrapper>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">{enFormsLabel.includeTime}</Typography>
          <Switch
            onChange={(_, checked) => changeFormData(checked, "includeTime", index, true)}
            checked={includeTime}
          />
        </Stack>
        <ValidationMessage validationMessage={errors?.includeTime}/>
      </OptionColumnWrapper>
      <OptionColumnWrapper>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">{enFormsLabel.disablePreviousDates}</Typography>
          <Switch
            onChange={(_, checked) => changeFormData(checked, "disablePreviousDates", index, true)}
            checked={disablePreviousDates}
          />
          <ValidationMessage validationMessage={errors?.disablePreviousDates}/>
        </Stack>
      </OptionColumnWrapper>
    </>
  )
}