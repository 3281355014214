export function appendTimeToDate(jsDate: Date, time: string): Date {
  // Separate hours and minutes
  const [hours, minutesStr] = time.split(':');
  const hoursNum = parseInt(hours, 10);

  // Separate minutes and period (AM/PM)
  const [minutes, period] = minutesStr.split(' ');
  const minutesNum = parseInt(minutes, 10);

  // Convert the period (AM/PM) to 24-hour format
  const isPM = period.trim().toLowerCase() === 'pm';
  const adjustedHours = isPM && hoursNum !== 12 ? hoursNum + 12 : hoursNum;

  jsDate.setHours(adjustedHours, minutesNum, 0, 0);
  return jsDate;
}