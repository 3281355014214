import {taskTimelineScheduleStatus as TimelineStatus} from "enums/taskTimelineScheduleStatus";
import theme from "theme/theme";
import {defaultUser} from "constants/defaultUser";

export const defaultTimelinePersonnel = {
  type: TimelineStatus.NotScheduled,
  by: {
    ...defaultUser,
    "@id": "",
    id: "defaultUser",
    email: "",
    initials: "UA",
    name: "UnAssigned",
    avatarColor: theme.palette.secondary.main,
    firstName: "",
    lastName: "",
  },
  at: undefined
}
