// @ts-nocheck
import {BaseSearchInput} from "components/index";
import {enChatLabel} from "constants/index";
import {MenuItem, Select, Stack, Typography} from "@mui/material";
import {Dispatch, useEffect, useMemo, useState} from "react";
import {Organization} from "types/Organization";
import {getObjectKeyByValue, submitForm, userChatsPath} from "screens/utility";
import {EntityChat} from "types/EntityChat";
import {TabIndex} from "../../enums/TabIndex";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {LoadingButton} from "@mui/lab";
import {doc} from "firebase/firestore";
import {toastProps} from "../../../BaseProps";
import {ActionType} from "enums/actionType";
import {Severity} from "enums/severity";
import {bulkStatusSubmitHandler} from "../../../utility/statusSubmitHandler";
import {ChatFilters} from "./index";

interface SearchAndFiltersProps {
  uid: string;
  tabIndex: TabIndex;
  organizations: Organization[] | null;
  currentOrgFilter: string;
  setCurrentOrgFilter: Dispatch<string>;
  setSearchQuery: Dispatch<string>;
  chats: EntityChat[] | null;
  fromAlgolia: boolean;
  toastProps: toastProps
  chatFilter: ChatFilters
  setChatFilter: Dispatch<ChatFilters>
}

function SearchAndFilters(props: SearchAndFiltersProps) {
  const {tabIndex, organizations, fromAlgolia, currentOrgFilter, chats, toastProps, chatFilter, setChatFilter} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps;
  const {setCurrentOrgFilter, setSearchQuery} = props;

  const [isNotVisible, setNotVisible] = useState<boolean>(true);
  const [isMarkAllReadLoading, setIsMarkAllReadLoading] = useState(false);
  const [submittedData, setSubmittedData] = useState<any []>([]);
  const [updateLength, setUpdateLength] = useState<number>(0);

  const docReferences = chats?.filter(chat => !chat.read).map((chat) => doc(userChatsPath(props.uid), chat.id)) ?? [];
  const selectableChatFilters = useMemo(() => ([
    {label: ChatFilters.read, value: ChatFilters.read},
    {label: ChatFilters.unread, value: ChatFilters.unread},
    {label: ChatFilters.none, value: ChatFilters.none}
  ]), [])

  useEffect(() => {
    if(!chats || !isNotVisible) return;

    setNotVisible(chats.length === 0 && !fromAlgolia);
  },[chats, fromAlgolia]);


  useEffect(() => {
    if (submittedData.length === 0 || submittedData.length !== updateLength) return;

    bulkStatusSubmitHandler({
      data: submittedData,
      successCallback: () => {
        setIsMarkAllReadLoading(false);
        setToastMessage(enChatLabel.allChatsAreMarkedAsRead);
        setToastSeverity(Severity.Success);
        setIsToastOpen(true);
        setNotVisible(true);
      },
      errorCallback: (message) => {
        setIsMarkAllReadLoading(false);
        setToastMessage((typeof message !== "string" ? enChatLabel.encounteredAnError : message) || enChatLabel.encounteredAnError);
        setToastSeverity(Severity.Error);
        setIsToastOpen(true);
      }
    });
    setSubmittedData([]);
    setUpdateLength(0);
  }, [submittedData]);

  const orgFilters: { [key: string]: string } = {
    "All Organizations": "allOrganizations",
    ...(organizations ?? []).reduce((result, org) => ({ ...result, [org.name!]: org["@id"]! }), {}),
  };

  function onMarkAsAllReadClick() {
    if (docReferences.length === 0) return;

    setIsMarkAllReadLoading(true);
    setUpdateLength(docReferences.length);
    docReferences.forEach((docRef) => {
      submitForm(docRef, ActionType.Update,
        (status, data, isLastUpdate) => isLastUpdate && setSubmittedData(prev => [...prev, {
          data,
          status,
          isLastUpdate
        }]),
        {read: true}
      );
    });
  }

  if ((isNotVisible && docReferences.length === 0) || !chats) return null; // Include this line in place of the existing conditional check

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" mt={2} flexWrap="wrap">
      <BaseSearchInput id={`${tabIndex}Search`} placeholder={enChatLabel.search} searchFn={setSearchQuery}/>
      <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap" alignSelf="flex-end">
        <Select
          defaultValue={ChatFilters.none}
          variant="standard"
          disableUnderline
          onChange={(e) => setChatFilter(e.target.value)}
          value={chatFilter}
          renderValue={(value: string) => {
            return  <b>{value}</b>;
          }}
          sx={{
            paddingLeft: {
              xs: 0
            },
            paddingRight: {
              xs: 0
            },
          }}
        >
          {selectableChatFilters.map(({label, value}) => (
            <MenuItem key={`orgFilterItem_${value}`} value={value}>
              <Typography variant="h5">{label}</Typography>
            </MenuItem>
          ))}
        </Select>
        <Select
          defaultValue={orgFilters["All Organizations"]}
          variant="standard"
          disableUnderline
          onChange={(e) => setCurrentOrgFilter(e.target.value)}
          value={currentOrgFilter}
          renderValue={(value: string) => {
            let label = getObjectKeyByValue(orgFilters, value) ?? "";
            label = label.length > 30 ? `${label.substring(0, 30)}...` : label;

            return  <b>{label}</b>;
          }}
          sx={{
            paddingLeft: {
              xs: 0
            },
            paddingRight: {
              xs: 0
            },
          }}
        >
          {Object.entries(orgFilters).map(([label, value]) => (
              <MenuItem key={`orgFilterItem_${value}`} value={value}>
                <Typography variant="h5">{label}</Typography>
              </MenuItem>
            ))}
        </Select>
        <LoadingButton
          onClick={onMarkAsAllReadClick}
          endIcon={!isMarkAllReadLoading && (
            <SystemIcons.Check
              width={16}
              height={16}
              stroke={docReferences.length > 0 ? theme.palette.success.main : theme.palette.divider}
            />
          )}
          sx={{
            color: theme.palette.secondary.main,
            paddingLeft: 0,
            paddingRight: 0,
          }}
          loading={isMarkAllReadLoading}
          disabled={docReferences.length === 0 || isMarkAllReadLoading}
        >
          <Typography variant="h5" color={docReferences.length > 0 ? theme.palette.secondary.main : theme.palette.divider}>
            {enChatLabel.markAllAsRead}
          </Typography>
        </LoadingButton>
      </Stack>
    </Stack>
  )
}

export default SearchAndFilters;