import {useEffect, useRef, useState} from "react";
import DailyIframe, {DailyCall} from "@daily-co/daily-js";
import {Box} from "@mui/material";
import {Loader} from "components/index";
import useQuery from "hooks/useQuery";

interface VideoCallProps {
  callJoined: boolean
}

const CALL_OPTIONS = {
  iframeStyle: {
    // add styles here
    width: "100%",
    height: "100%"
  },
};

function VideoCall(props: VideoCallProps) {
  const {callJoined} = props

  const query = useQuery()

  const videoRef = useRef(null);
  const [callFrame, setCallFrame] = useState<DailyCall | null>(null)

  //initialize call frame
  useEffect(() => {
    if (!videoRef || !videoRef?.current) return;

    const callFrame = DailyIframe.createFrame(
      videoRef.current,
      {
        ...CALL_OPTIONS,
        url: query.get("meeting-link") || undefined,
        theme: {
          colors: {
              "accent": '#2A8CFE',
              "accentText": '#121A24',
              "background": '#1D1D1E',
              "backgroundAccent": '#353638',
              "baseText": '#FFFFFF',
              "border": '#232425',
              "mainAreaBg": '#1D1D1E',
              "mainAreaBgAccent": '#353638',
              "mainAreaText": '#FFFFFF',
              "supportiveText": '#C8D1DC',
          }
        }
      }
    );

    setCallFrame(callFrame);
    callFrame.join()

  }, [videoRef]);

  //listen when user joins or leaves call
  useEffect(() => {
    if (!callFrame)
      return

    if (callJoined)
      callFrame.join()
    else
      callFrame.leave()
  }, [callJoined]);

  return <>
    <Loader/>
    <Box
      ref={videoRef}
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 3,
        display: callJoined ? "block" : "none"
      }}
    />
  </>
}

export default VideoCall;
