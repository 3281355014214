import {Box, Chip, Stack} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import DataTagComponent from "components/DataTag/DataTagComponent";
import {ToastProps} from "screens/BaseProps";
import useDataTags from "hooks/useDataTags";
import {mediumMaxTagsDisplay, normalMaxTagsDisplay, shortMaxTagsDisplay} from "constants/maxTagsDisplay";
import {useLocation, useNavigate} from "react-router-dom";
import {useComponentToggler} from "hooks/index";
import {DocumentReference} from "firebase/firestore";
import DataTagInputPopup from "components/DataTag/DataTagInput/DataTagInputPopup";
import AddMoreChip from "components/DataTag/DataTagsDisplay/AddMoreChip";
import SeeLessChip from "components/DataTag/DataTagsDisplay/SeeLessChip";

interface DataTagsDisplayProps extends ToastProps {
  dataTagsIds: string[]
  listSize?: "short" | "normal" | "medium"
  projId?: string;
  isParentHidden?: boolean;
  maxWidth?: number
  maxChipWidth?: number;
  documentRef: DocumentReference;
  canEditDocumentRef: boolean;
  uid: string;
  fromTemplates?: boolean;
  originalPath?: string;
}

interface historyState {
  defaultCollapseState: boolean;
}

function DataTagsDisplay(props: DataTagsDisplayProps) {
  const {
    toastProps,
    dataTagsIds,
    listSize = "normal",
    isParentHidden = false,
    projId,
    maxWidth,
    maxChipWidth,
    documentRef,
    canEditDocumentRef,
    uid
  } = props;
  const location = useLocation();
  const pathname = location.pathname;
  const state = location.state as historyState || {};

  const nav = useNavigate();

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [maxTagsDisplay, setMaxTagsDisplay] = useState<number>(normalMaxTagsDisplay);
  const [isDataTagInputOpen, {open: openDataTagInput, close: closeDataTagInput}] = useComponentToggler(false);

  const extraTagsChipRef = useRef<any>();
  const displayRef = useRef<HTMLDivElement>(null);

  const tags = useDataTags(dataTagsIds, uid)

  const trimmedTags = tags.slice(0, maxTagsDisplay);

  useEffect(() => {
    if (listSize === "short")
      setMaxTagsDisplay(shortMaxTagsDisplay);
    else if (listSize === "medium")
      setMaxTagsDisplay(mediumMaxTagsDisplay);
  }, [])

  useEffect(() => {
    // don't do anything if the displayRef is not set
    if (!displayRef.current) return;

    if (displayRef.current.scrollWidth > displayRef.current.clientWidth) {
      const newMaxTagsDisplay = maxTagsDisplay - 1;
      setMaxTagsDisplay(prev => newMaxTagsDisplay < 0 ? prev : newMaxTagsDisplay);
    }

  }, [displayRef, displayRef.current, maxTagsDisplay, trimmedTags])


  //set default collapse state upon loading
  useEffect(() => {

    if (state?.defaultCollapseState === undefined)
      setIsCollapsed(true)
    else
      setIsCollapsed(state.defaultCollapseState)

  }, [state?.defaultCollapseState])

  function expandTags(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    setIsCollapsed(false);
    if (listSize === "short")
      nav(`${pathname}/${projId}/overview`, {state: {defaultCollapseState: false}})
  }

  function collapseTags(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    setIsCollapsed(true);
    window.history.replaceState({usr: {defaultCollapseState: true}}, "")
  }

  function onAddDataTag(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    e.preventDefault();
    openDataTagInput();
  }

  if (tags.length === 0) {
    return (
      <Stack
        alignItems="center"
        direction="row"
        overflow="hidden"
        gap={0.5}
        ref={displayRef}
      >
        <DataTagInputPopup
          canEdit={canEditDocumentRef}
          documentRef={documentRef}
          isOpen={isDataTagInputOpen}
          onClose={closeDataTagInput}
          uid={uid}
          toastProps={toastProps!}
          anchorEl={displayRef!.current!}
          initialTags={tags.map(tag => tag["id"]!)}
          fromTemplates={props.fromTemplates}
          originalPath={props.originalPath}
        />
        <AddMoreChip
          canEditDocumentRef={canEditDocumentRef}
          extraTagsChipRef={extraTagsChipRef}
          onAddDataTag={onAddDataTag}
        />
      </Stack>
    )
  }

  if (isCollapsed || listSize === "short")
    return (
      <>
        <DataTagInputPopup
          canEdit={canEditDocumentRef}
          documentRef={documentRef}
          isOpen={isDataTagInputOpen}
          onClose={closeDataTagInput}
          uid={uid}
          toastProps={toastProps!}
          anchorEl={displayRef!.current!}
          initialTags={tags.map(tag => tag["id"]!)}
          fromTemplates={props.fromTemplates}
          originalPath={props.originalPath}
        />
        <Stack
          alignItems="center"
          direction="row"
          overflow="hidden"
          gap={0.5}
          ref={displayRef}
        >
          <Stack gap={0.5} direction="row" alignItems="center" maxWidth={maxWidth}>
            {trimmedTags.map((tag) =>
              <DataTagComponent
                toastProps={toastProps}
                tag={tag}
                isParentHidden={isParentHidden}
                maxChipWidth={maxChipWidth}
                uid={uid}
              />)}
          </Stack>
          {tags.length > maxTagsDisplay && (
            <Box>
              <Chip
                ref={extraTagsChipRef}
                onClick={expandTags}
                label={`+${tags.length - maxTagsDisplay}`}
                color="chip"
                sx={(theme) => ({
                  borderRadius: 1,
                  cursor: "pointer",
                  ":hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.text.onDark,
                  },
                })}
                size={"small"}
              />
            </Box>
          )}
          <AddMoreChip
            canEditDocumentRef={canEditDocumentRef}
            extraTagsChipRef={extraTagsChipRef}
            onAddDataTag={onAddDataTag}
          />
        </Stack>
      </>
    )

  return <Stack gap={0.5}>
    <DataTagInputPopup
      canEdit={canEditDocumentRef}
      documentRef={documentRef}
      isOpen={isDataTagInputOpen}
      onClose={closeDataTagInput}
      uid={uid}
      toastProps={toastProps!}
      anchorEl={displayRef!.current!}
      initialTags={tags.map(tag => tag["id"]!)}
      fromTemplates={props.fromTemplates}
      originalPath={props.originalPath}
    />
    <Stack
      alignItems="baseline"
      direction="row"
      flexWrap="wrap"
      gap={0.5}
      ref={displayRef}
    >
      {tags.map((tag) => (
        <DataTagComponent toastProps={toastProps} tag={tag} uid={uid}/>
      ))}
      <AddMoreChip
        canEditDocumentRef={canEditDocumentRef}
        extraTagsChipRef={extraTagsChipRef}
        onAddDataTag={onAddDataTag}
      />
      <SeeLessChip
        extraTagsChipRef={extraTagsChipRef}
        collapseTags={collapseTags}
      />
    </Stack>
  </Stack>
}

export default DataTagsDisplay;
