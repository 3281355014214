import React, {useEffect, useState} from "react";
import {EmptyList, InProgress} from "components";
import {Project} from "types/index";
import {useCollection, useComponentToggler} from "hooks/index";
import {CollectionReference, doc} from "firebase/firestore";
import {BaseProps} from "screens/BaseProps";
import {ActionType, Entity, ProcessType, Severity, ViewStatus} from "enums/index";
import {enCommonLabel, enProjectLabel, enTemplateButton, enTemplateLabel} from "constants/index";
import {Box, Button, Checkbox, FormControlLabel, Stack, Typography} from "@mui/material";
import {TemplateProjectCard} from "components/TemplateProjectView/TemplateProjectCard";
import {SystemIcons} from "assets/icons/system/system.index";
import {submitForm, templateProjectsPath} from "screens/utility";
import DeleteDialog from "components/Dialogs/DeleteDialog";
import {errorStatuses} from "constants/errorStatuses";
import SmartSearchInput from "components/inputs/SmartSearchInput";
import {algoliaTemplatesProjectsColPath} from "screens/utility/algoliaColPath";
import {useParams} from "react-router-dom";
import EmptySearchResults from "components/EmptySearchResults";

interface ProjectViewProps extends BaseProps {
  templateProjectCollectionRef: CollectionReference;
}

function TemplateProjectView(props: ProjectViewProps) {
  const {templateProjectCollectionRef, toastProps} = props;
  const {setIsToastOpen, setToastSeverity, setToastMessage} = toastProps!;

  const {orgId, templateId} = useParams();

  const [projects,, updateColRef] = useCollection<Project>(null, null);
  const [projectIdsToDelete, setProjectIdsToDelete] = useState<Set<string>>(new Set());
  const [selectedProjectIds, setSelectedProjectIds] = useState<Set<string>>(new Set());
  const [isDeleteDialogOpen, {open: showDeleteDialog, close: closeDeleteDialog}] = useComponentToggler(false);

  const [algoliaResult, setAlgoliaResult] = useState<Project[] | null>(null);
  const [displayedProjects, setDisplayedProjects] = useState<Project[]>([]);

  useEffect(() => {
    if (!orgId || !templateId) return;
    updateColRef(templateProjectsPath(orgId!, templateId!));
  }, [orgId, templateId]);

  useEffect(() => {
    if (algoliaResult !== null) {
      setDisplayedProjects([...algoliaResult]);
      return;
    }

    setDisplayedProjects([...(projects ?? [])]);
  }, [projects, algoliaResult]);

  function onDeleteSingleProjectClick(id: string) {
    const newTargetIds = new Set(projectIdsToDelete);
    newTargetIds.add(id);
    setProjectIdsToDelete(newTargetIds);
    showDeleteDialog();
  }

  function onProjectCardSelect(id: string, checked: boolean) {
    const newSelectedIds = new Set(selectedProjectIds);
    checked ? newSelectedIds.add(id) : newSelectedIds.delete(id);
    setSelectedProjectIds(newSelectedIds);
  }

  function onBulkSelectChange(e: React.ChangeEvent<HTMLInputElement>) {
    const isChecked = e.target.checked;
    isChecked ?
      setSelectedProjectIds(new Set(projects!.map((proj) => proj["@id"]!))) :
      setSelectedProjectIds(new Set())
  }

  function onDeleteSelectedProjectsClick() {
    setProjectIdsToDelete(new Set(selectedProjectIds));
    showDeleteDialog();
  }

  function onDeleteConfirm() {
    let viewStatuses: ViewStatus[] = [];
    projectIdsToDelete.forEach((id) => {
      const docRef = doc(templateProjectCollectionRef, id);
      submitForm(docRef, ActionType.Delete, (status) => viewStatuses.push(status));
    });

    setProjectIdsToDelete(new Set());
    setSelectedProjectIds(new Set());
    closeDeleteDialog();

    if(viewStatuses.some(status => errorStatuses.includes(status))) {
      setToastSeverity(Severity.Error);
      setToastMessage(enCommonLabel.errorProcess(ProcessType.Delete));
      setIsToastOpen(true);
      return;
    }

    setToastMessage(enTemplateLabel.deleteProjectSuccess);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
  }

  if (projects === null) return <InProgress/>;

  if (!projects.length) return (
    <EmptyList
      entity={Entity.Project}
      description={enProjectLabel.emptyDescriptionTemplate}
    />
  );

  return (
    <>
      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        title={enTemplateLabel.deleteProjectsTitle}
        text={enTemplateLabel.deleteProjectsText(projectIdsToDelete.size)}
        handleClose={closeDeleteDialog}
        handleConfirm={onDeleteConfirm}
        confirmButtonText={enTemplateButton.unlinkProject}
      />
      <Stack>
        {projects && projects.length > 0 && (
          <>
            <Box>
              <SmartSearchInput<Project>
                id="searchProject"
                placeholder={enProjectLabel.search}
                resultCallback={setAlgoliaResult}
                colPath={algoliaTemplatesProjectsColPath(templateId!)}
                additionalFilters={["entity:OrganizationTemplateProject", `orgId:${orgId!}`]}
              />
            </Box>
            <Stack direction="row" gap={1} px="1rem" py={0.5} alignItems="center">
              <FormControlLabel
                control={<Checkbox checked={selectedProjectIds.size === projects.length} onChange={onBulkSelectChange}/>}
                label={<Typography variant="h5">
                  {
                    !!selectedProjectIds.size ?
                      `${selectedProjectIds.size} selected` :
                      enProjectLabel.projects
                  }
                </Typography>}
              />
              {!!selectedProjectIds.size && (
                <Button
                  startIcon={<SystemIcons.Unlink width={20} height={20}/>} color="secondary"
                  onClick={onDeleteSelectedProjectsClick}
                >
                  <Typography variant="h5">{enTemplateButton.unlinkProject}</Typography>
                </Button>
              )}
            </Stack>
          </>
        )}
        <Stack gap={1}>
          {(algoliaResult !== null && algoliaResult.length === 0) && (
            <EmptySearchResults entity={Entity.Project} />
          )}
          {(projects && projects.length === 0) && (
            <EmptyList entity={Entity.Project} description={enProjectLabel.emptyDescriptionTemplate} />
          )}
          {displayedProjects.map((project, index) => (
            <TemplateProjectCard
              key={`templateProj_${project["@id"]}`}
              index={index}
              project={project}
              uid={props.uid}
              parentPath={templateProjectCollectionRef.path}
              onDeleteClick={onDeleteSingleProjectClick}
              onProjectCardSelect={onProjectCardSelect}
              isSelected={selectedProjectIds.has(project["@id"]!)}
            />
          ))}
        </Stack>
      </Stack>
    </>
  );
}

export default TemplateProjectView;
