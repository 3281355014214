import {Avatar} from "components/index";
import AvatarGroup from "@mui/material/AvatarGroup";
import { ReadReceipt} from "types/ChatMessage";
import {styled} from "@mui/material/styles";
import ExcessAvatar from "components/ChatDrawer/ChatItemsView/ExcessAvatar";

interface ReadReceiptsProps {
  readers: ReadReceipt[]
}

const MAX_DISPLAYED_AVATARS = 3;

function ReadReceipts(props: ReadReceiptsProps) {
  const {readers} = props;

  const totalReadReceipts = readers.length
  const excessReadReceipts = totalReadReceipts - MAX_DISPLAYED_AVATARS;
  const displayedUsers = readers.slice(0, MAX_DISPLAYED_AVATARS);

  return (
    <CustomizedAvatarGroup>
      {displayedUsers.map(({user}, index) => {
        return (
          <Avatar
            key={`read-receipt-avatar-${index}`}
            {...user}
          />
        )
      })}
      <ExcessAvatar excessReadReceipts={excessReadReceipts} readReceiptsData={readers}/>
    </CustomizedAvatarGroup>
  );
}

const CustomizedAvatarGroup = styled(AvatarGroup)({
  "& .MuiAvatar-root": {
    border: "2px solid white",
    fontSize: "0.7rem",
    width: "1.3rem",
    height: "1.3rem",
  }
});

export default ReadReceipts;
