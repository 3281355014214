import React from "react";
import {styled} from "@mui/material/styles";
import {Stack, Typography} from "@mui/material";
import {enCommonLabel, enOrganizationLabel} from "constants/index";
import {CounterFields} from "enums/counterFields";
import {useRTDBDocField} from "hooks/index";
import {getDocPath} from "../../utility";

interface CardContentProps {
  orgId: string;
  description?: string;
  parentPath: string;
  fromSAP?: boolean;
  membersCount: number;
  uid: string;
}

function OrganizationCardContent(props: CardContentProps) {
  const {fromSAP = false, description = enCommonLabel.noDescription, orgId, uid} = props;
  const projectsCount = useRTDBDocField<number>(getDocPath(uid, props.parentPath, orgId), CounterFields.ProjectsCount) ?? 0;
  const delayedTasksCount = useRTDBDocField<number>(getDocPath(uid, props.parentPath, orgId), CounterFields.DelayedTasksCount) ?? 0;
  const unassignedTasksCount = useRTDBDocField<number>(getDocPath(uid, props.parentPath, orgId), CounterFields.UnassignedTasksCount) ?? 0;

  if (fromSAP)
    return (
      <Stack direction="column" justifyContent="center">
        <Stack direction="row" gap={1}>
          <StyledTypography variant="body2" color="text.secondary">
            {enOrganizationLabel.projects}
          </StyledTypography>
          <StyledTypography variant="h5">
            {projectsCount}
          </StyledTypography>
        </Stack>
        <Stack direction="row" gap={1}>
          <StyledTypography variant="body2" color="text.secondary">
            {enOrganizationLabel.members}
          </StyledTypography>
          <StyledTypography variant="h5">
            {props.membersCount}
          </StyledTypography>
        </Stack>
        <Stack direction="row" gap={1}>
          <StyledTypography variant="body2" color="text.secondary">
            {enOrganizationLabel.delayedTasks}
          </StyledTypography>
          <StyledTypography variant="h5">
            {delayedTasksCount}
          </StyledTypography>
        </Stack>
        <Stack direction="row" gap={1}>
          <StyledTypography variant="body2" color="text.secondary">
            {enOrganizationLabel.unassignedTasks}
          </StyledTypography>
          <StyledTypography variant="h5">
            {unassignedTasksCount}
          </StyledTypography>
        </Stack>
      </Stack>
    );

  return (
    <StyledTypography
      className="org-description"
      variant="body2"
      color="text.secondary"
      title={description}
      sx={{overflowWrap: "anywhere"}}
    >
      {!!description ? description : enCommonLabel.noDescription}
    </StyledTypography>
  )
}

/** styles start */
const StyledTypography = styled(Typography)({
  display: "-webkit-box",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitBoxOrient: "vertical",
});
/** styles end */

export default OrganizationCardContent;