import {BaseProps} from "screens/BaseProps";
import {SelectedOrg} from "types/index";
import TeamsView from "./TeamsView";

interface TeamsProps extends BaseProps {
  selectedOrg: SelectedOrg,
}

function Teams(props: TeamsProps) {

  return (
    <>
      <TeamsView {...props} />
    </>
  )
}

export default Teams;