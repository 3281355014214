import {useNavigate} from "react-router-dom";
import {onAuthStateChanged, User} from "firebase/auth";
import React, {useEffect, useState} from "react";
import Loader from "./Loader";
import {app, auth} from "../firebase";
import {initClient} from "emberflow-web-client/lib";
import {ViewStatus} from "enums/viewStatus";

enum TokenStatus {
  checking,
  valid,
  expired,
  noUser
}

interface RequireAuthProps {
  user?: User | null;
  children: JSX.Element;
}

export default function RequireAuth({ children }: RequireAuthProps) {
  const [tokenStatus, setTokenStatus] = useState<TokenStatus>(TokenStatus.checking);
  const [uid, setUid] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async(firebaseUser) => {
      if (!firebaseUser) {
        navigate("/login");
        return;
      }

      try {
        const tokenResult = await firebaseUser.getIdTokenResult();
        const expirationTime = tokenResult.expirationTime; // Expiration time as a string
        const expirationDate = new Date(expirationTime).getTime(); // Convert to milliseconds
        const now = new Date().getTime(); // Current time in milliseconds

        if (now > expirationDate) {
          setTokenStatus(TokenStatus.expired);
        } else {
          const superUid = tokenResult?.claims.superUid;
          setUid(!!superUid && typeof superUid === "string" ? superUid : firebaseUser.uid!);
          setTokenStatus(TokenStatus.valid);
        }
      } catch (error) {
        console.error("Error getting token result:", error, firebaseUser);
        setTokenStatus(TokenStatus.expired); // Assume expired if there's an error fetching the token result
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (tokenStatus === TokenStatus.checking) return;

    if (tokenStatus === TokenStatus.noUser || tokenStatus === TokenStatus.expired) {
      navigate("/login");
      return;
    }

    if (!!uid) {
      initClient(
        app,
        uid,
        undefined,
        {
          "submit": ViewStatus.Submit,
          "submitted": ViewStatus.Submitted,
          "validation-error": ViewStatus.ValidationError,
          "security-error": ViewStatus.SecurityError,
          "finished": ViewStatus.Finished,
          "delay": ViewStatus.Delay,
          "cancel": ViewStatus.Cancel,
          "cancelled": ViewStatus.Cancelled,
          "error": ViewStatus.Error,
        }
      );

      setLoading(false);
    }
  }, [tokenStatus]);

  if (loading) {
    return <Loader />;
  }

  return children;
}
