import isNotPlayableMedia from "./isNotPlayableMedia";
import isNoPreviewFile from "./isNoPreviewFile";

function isNotPlayableOrNoPreview(fileName: string) {
  const isNotPlayable = isNotPlayableMedia(fileName);
  const isNoPreview = isNoPreviewFile(fileName);

  return isNotPlayable || isNoPreview;
}

export default isNotPlayableOrNoPreview;