import {Stack, Typography} from "@mui/material";
import {StatusIcons} from "assets/icons/status/status.index";
import {LongText} from "components/index";
import theme from "theme/theme";
import {enCommonLabel} from "constants/index";
import BreadcrumbsDisplay from "components/BreadcrumbsDisplay";
import {useNavigate} from "react-router-dom";
import {RefObject} from "react";
import {ProcessingFile} from "hooks/useFileUpload/useFileUpload";

interface FailedItemProps {
  file: ProcessingFile;
  containerRef?: RefObject<any>;
}

function FailedItem(props: FailedItemProps) {
  const {file, containerRef} = props;
  const navigate = useNavigate();

  const {id = "fillerId", file: actualFile, breadCrumbs} = file;

  return (
    <Stack direction="column" padding={1} className="file-upload-panel-list-item">
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        gap={1}
        onClick={() => breadCrumbs && breadCrumbs.length > 0 && navigate(breadCrumbs[breadCrumbs.length -1].path)}
      >
        <Stack direction="row">
          <StatusIcons.Rejected width={26} height={26}/>
        </Stack>
        <Stack direction="column">
          <LongText
            maxLines={1}
            followCursor={true}
            containerRef={containerRef}
            variant="h5"
          >
            {actualFile.name}
          </LongText>
          <Typography
            id={`${id}-file-status`}
            variant="body2"
            color={theme.palette.neutral.dark}
          >
            {enCommonLabel.failedUpload}
          </Typography>
          {breadCrumbs && breadCrumbs.length > 0 && (
            <BreadcrumbsDisplay
              entityBreadcrumbs={breadCrumbs}
              orgName={breadCrumbs[0].name ?? "fillerOrgName"}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default FailedItem;
