import React from 'react';
import DrawerFieldsWrapper from "components/DrawerFieldsWrapper";
import {Button, Divider, IconButton, Stack, Typography} from "@mui/material";
import {enChatLabel, enCommonLabel, entityOutlinedSystemIcons} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {LongText} from "components/index";
import {Entity} from "enums/entity";
import VideoCallButton from "components/VideoCall/VideoCallButton";
import {collection, DocumentReference} from "firebase/firestore";
import MembersDrawer from "components/ChatDrawer/MembersDrawer";
import {db} from "../../firebase";
import {toastProps} from "screens/BaseProps";

interface HeaderProps {
  entity: Entity;
  title: string;
  subtitle: string;
  onClose: () => void;
  navigateToOverview: () => void;
  documentReference: DocumentReference;
  toastProps: toastProps;
  chatSubscribersCount: number
}

const Header = React.forwardRef((props: HeaderProps, ref) => {
  const {entity, title, subtitle, documentReference, toastProps, chatSubscribersCount} = props;
  const {onClose, navigateToOverview} = props;

  const [isMemberDrawerOpen, setIsMemberDrawerOpen] = React.useState(false);

  function onNavigateClick() {
    navigateToOverview();
    onClose();
  }

  return (
    <>
      <DrawerFieldsWrapper id="chatDrawerHeader" maxHeight="150px" minHeight="150px" gap={1} ref={ref}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row">
            {
              // @ts-ignore
              entityOutlinedSystemIcons[entity]
            }
          </Stack>
          <Stack direction="row" gap={1.5}>
            {/* <IconButton*/}
            {/*  id="chatDrawer-phoneButton"*/}
            {/*  size="small"*/}
            {/*>*/}
            {/*<SystemIcons.Phone*/}
            {/*  fill="none"*/}
            {/*  stroke={theme.palette.secondary.main}*/}
            {/*/>*/}
            {/*</IconButton>*/}
            <VideoCallButton documentReference={documentReference}/>
            {/*<Link to="/videoCall" target="_blank" rel="noopener noreferrer">*/}
            {/*  <IconButton*/}
            {/*    sx={{paddingX: 0}}*/}
            {/*    id="drawer-close-head-button"*/}
            {/*    size="small"*/}
            {/*    disableRipple*/}
            {/*  >*/}
            {/*    <SystemIcons.Video*/}
            {/*      fill="none"*/}
            {/*      stroke={theme.palette.secondary.main}*/}
            {/*    />*/}
            {/*  </IconButton>*/}
            {/*</Link>*/}
            {/*<IconButton*/}
            {/*  id="chatDrawer-addUserButton"*/}
            {/*  size="small"*/}
            {/*>*/}
            {/*  <SystemIcons.AddUser*/}
            {/*    fill="none"*/}
            {/*    stroke={theme.palette.secondary.main}*/}
            {/*  />*/}
            {/*</IconButton>*/}
            {/*<IconButton*/}
            {/*  id="chatDrawer-infoButton"*/}
            {/*  size="small"*/}
            {/*>*/}
            {/*  <SystemIcons.Info*/}
            {/*    fill="none"*/}
            {/*    stroke={theme.palette.secondary.main}*/}
            {/*  />*/}
            {/*</IconButton>*/}
            <IconButton
              sx={{alignSelf: "flex-end"}}
              id="drawer-close-head-button"
              size="small"
              onClick={onClose}
            >
              <SystemIcons.Close
                stroke={theme.palette.neutral.dark}
              />
            </IconButton>
          </Stack>
        </Stack>
        <Stack>
          <LongText variant="caption" color={theme.palette.text.secondary} maxLines={1}>
            {title.trim().endsWith('/') ? title.trim().slice(0, -1) : title}
          </LongText>
          <Stack direction="row" gap={0.5} alignItems="center" onClick={onNavigateClick}>
            <LongText variant="h3" color={theme.palette.secondary.main} maxLines={1}>
              {subtitle}
            </LongText>
            <Button
              disableRipple
              sx={{padding: 0, minWidth: 0}}
              endIcon={<SystemIcons.ChevronRight width={12} height={12}/>}
            />
          </Stack>
          <a onClick={() => setIsMemberDrawerOpen(true)} title={enCommonLabel.viewChatMembers}>
            <Typography
              variant="body"
              color={theme.palette.text.secondary}
              sx={{cursor: "pointer"}}
            >
              {enChatLabel.subscribersCounter(chatSubscribersCount)}
            </Typography>
          </a>
        </Stack>
      </DrawerFieldsWrapper>
      <Divider/>
      <MembersDrawer
        collectionPath={collection(db, documentReference.path, entity !== Entity.ProjectLevelTask ? "/chatSubscribers" : "/PLChatSubscribers").path}
        // collectionReference={collection(db, documentReference.path, entity !== Entity.ProjectLevelTask ? "/chatSubscribers" : "/PLChatSubscribers")}
        onClose={() => setIsMemberDrawerOpen(false)}
        isOpen={isMemberDrawerOpen}
        toastProps={toastProps}
      />
    </>
  )
})

export default Header;
