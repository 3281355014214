import {ActivityItem} from "types/ActivityFeedTypes";
import {ProcessingFile} from "hooks/useFileUpload/useFileUpload";
import UploadingItem from "./ActivityItems/UploadingItem";
import React from "react";
import StatusChangeItem from "./ActivityItems/StatusChangeItem";
import {ActivityType} from "enums/ActivityType";
import {toastProps} from "../BaseProps";
import OrganizationInviteItem from "./ActivityItems/OrganizationInviteItem";
import AssignedItem from "./ActivityItems/AssignedItem";
import CreatedItem from "./ActivityItems/CreatedItem";
import DeletedItem from "./ActivityItems/DeletedItem";
import OverdueItem from "./ActivityItems/OverdueItem";
import UnassignedItem from "./ActivityItems/UnassignedItem";
import FileStatusChangeItem from "./ActivityItems/FileStatusChangeItem";
import UpdatedItem from "./ActivityItems/UpdatedItem";
import AnsweredFormItem from "./ActivityItems/AnsweredFormItem";
import NewAccessListMemberItem from "./ActivityItems/NewAccessListMemberItem";

interface PageItemsProps {
  itemData: any;
  index: number;
  toastProps: toastProps;
  uid: string;
  updatePinnedValue: (index: number, pinned: boolean) => void;
}

function PageItems(props: PageItemsProps) {
  const {itemData, index, ...rest} = props;
  const fromProcessing = Boolean(itemData.fromProcessing);

  if (fromProcessing) {
    return (
      <UploadingItem
        key={`processing-file-${itemData.id}-${index}`}
        item={itemData as ProcessingFile}
      />
    )
  }

  const activityData = itemData as ActivityItem;
  const {"@id": itemId, type} = activityData;

  if (!itemId) return null;

  switch (type) {
    case ActivityType.StatusChange:
      return <StatusChangeItem itemId={itemId} key={itemId} index={index} {...rest}/>
    case ActivityType.Invited:
      return <OrganizationInviteItem itemId={itemId} key={itemId} index={index} {...rest}/>
    case ActivityType.Assigned:
      return <AssignedItem itemId={itemId} key={itemId} index={index} {...rest}/>
    case ActivityType.Created:
      return <CreatedItem itemId={itemId} key={itemId} index={index} {...rest}/>
    case ActivityType.Deleted:
      return <DeletedItem itemId={itemId} key={itemId} index={index} {...rest}/>
    case ActivityType.Overdue:
      return <OverdueItem itemId={itemId} key={itemId} index={index} {...rest}/>
    case ActivityType.Unassigned:
      return <UnassignedItem itemId={itemId} key={itemId} index={index} {...rest}/>
    case ActivityType.FileUpload:
      return <FileStatusChangeItem itemId={itemId} key={itemId} index={index} {...rest}/>
    case ActivityType.Updated:
      return <UpdatedItem itemId={itemId} key={itemId} index={index} {...rest}/>
    case ActivityType.AnsweredForm:
      return <AnsweredFormItem itemId={itemId} key={itemId} index={index} {...rest}/>
    case ActivityType.AddedNewAccessListMember:
      return <NewAccessListMemberItem itemId={itemId} key={itemId} index={index} {...rest}/>
    default:
      console.error(`Unknown activity type: ${type}`);
      return <div style={{height: 1}}/>
  }
}

export default PageItems;