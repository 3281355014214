import React, {Dispatch } from "react";
import { ComparisonQuery } from "types/ComparisonQuery";
import {SelectChangeEvent} from "@mui/material";

export const onChangeInput = (
    e:  React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | React.ClipboardEvent<HTMLDivElement> | SelectChangeEvent,
    setter: Dispatch<any>
) => {
    const el = e.target as HTMLInputElement;
    const value = el.value;
    setter(value);
};

export function compareValues(query: ComparisonQuery) {
    const { firstValue:first = "", operator, secondValue:second = "" } = query;
    const firstValue = first.replaceAll(/ /g,"");
    const secondValue = second.replaceAll(/ /g,"");
    switch (operator) {
        case "==":
            return firstValue === secondValue;
        case "!=":
            return firstValue !== secondValue;
        case ">":
            return firstValue > secondValue;
        case "<":
            return firstValue < secondValue;
        case ">=":
            return firstValue >= secondValue;
        case "<=":
            return firstValue <= secondValue;
        default:
            return false;
    }
}

export function sortAlphabetically(arr: any[], ascending: boolean) {
    return arr.sort((a, b) => {
        const { name: nameA } = a;
        const { name: nameB } = b;

        if (nameA.toLowerCase() > nameB.toLowerCase()) return 1;
        return -1;
    });
}

export function checkLongLat(key: string, value: string) {
    const num = parseFloat(value);
    return (!isNaN(num) && (key === "Latitude") ? (num >= -90 && num <= 90) : (num >= -180 && num <= 180));
}

export function getValueFromInputEvent(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | React.ClipboardEvent<HTMLDivElement> | SelectChangeEvent) {
    const el = e.target as HTMLInputElement;
    return el.value;
}

export function handleKeyPressNumeric(event: React.KeyboardEvent){
	const key = event.key;

	return ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "-"].includes(key);
}