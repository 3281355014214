export enum datatagToneColor {
  tone_0_0 = "tone_0_0",
  tone_1_0 = "tone_1_0",
  tone_2_0 = "tone_2_0",
  tone_3_0 = "tone_3_0",
  tone_4_0 = "tone_4_0",
  tone_5_0 = "tone_5_0",
  tone_6_0 = "tone_6_0",

  tone_0_1 = "tone_0_1",
  tone_1_1 = "tone_1_1",
  tone_2_1 = "tone_2_1",
  tone_3_1 = "tone_3_1",
  tone_4_1 = "tone_4_1",
  tone_5_1 = "tone_5_1",
  tone_6_1 = "tone_6_1",

  tone_0_2 = "tone_0_2",
  tone_1_2 = "tone_1_2",
  tone_2_2 = "tone_2_2",
  tone_3_2 = "tone_3_2",
  tone_4_2 = "tone_4_2",
  tone_5_2 = "tone_5_2",
  tone_6_2 = "tone_6_2",

  tone_0_3 = "tone_0_3",
  tone_1_3 = "tone_1_3",
  tone_2_3 = "tone_2_3",
  tone_3_3 = "tone_3_3",
  tone_4_3 = "tone_4_3",
  tone_5_3 = "tone_5_3",
  tone_6_3 = "tone_6_3",

  tone_0_4 = "tone_0_4",
  tone_1_4 = "tone_1_4",
  tone_2_4 = "tone_2_4",
  tone_3_4 = "tone_3_4",
  tone_4_4 = "tone_4_4",
  tone_5_4 = "tone_5_4",
  tone_6_4 = "tone_6_4",
}