import {BaseProps} from "screens/BaseProps";
import {Member, Organization, PartialUserData, User} from "types/index";
import {SelectWithSearch, SimpleFormDrawer} from "components/index";
import Input from "components/Input";
import {
  areObjectsEqual,
  onChangeInput,
  organizationMembersPath,
  organizationsPath,
  submitForm,
  userPath
} from "screens/utility";
import {
  emptyFunction,
  enCommonButton,
  enCommonLabel,
  enOrganizationLabel,
  noOne
} from "constants/index";
import React, {useContext, useEffect, useState} from "react";
import BriefcaseIcon from "assets/icons/BriefcaseIcon";
import SystemIndex from "assets/icons/system/system.index";
import {useCollection, useDebounce, useDocument} from "hooks/index";
import {useParams} from "react-router-dom";
import {doc, getDoc} from "firebase/firestore";
import {SelectItem} from "components/inputs/SearchInput/SearchPopUp";
import createPartialUserDataFromUser from "screens/utility/createPartialUserDataFromUser";
import {AccessRole, ActionType, Severity, ViewStatus} from "enums/index";
import {generatePointOfContactsList} from "./utils/generatePointOfContactsList";
import {db} from "../../../firebase";
import handleEnterKeyPress from "screens/utility/handleEnterKeyPress";
import {SelectedOrgContext} from "screens/SelectedOrgContextProvider";
import {useNavigate} from "react-router";

interface ManageOrganizationDrawerProps extends BaseProps {
  organization: Organization | null;
  isDrawerOpen: boolean;
  closeDrawer: () => void;
  fromSAP?: boolean;
}

function ManageOrganizationDrawer(props: ManageOrganizationDrawerProps) {
  const {organization, isDrawerOpen, toastProps, uid, closeDrawer, setSelectedOrgId = emptyFunction} = props;
  const {setIsToastOpen, setToastSeverity, setToastMessage} = toastProps!;

  const selectedOrgContext = useContext(SelectedOrgContext);
  const {setSelectedOrg} = selectedOrgContext!;
  const navigate = useNavigate();

  const [initialOrg, setInitialOrg] = useState({
    name: organization?.name ?? "",
    description: organization?.description ?? "",
    pointOfContact: organization?.pointOfContact ?? null,
  })

  const {orgId} = useParams();

  const [orgDoc, setOrgDoc] = useDocument<Organization>(null);

  const [name, setName] = useState<string>(initialOrg.name);
  const [description, setDescription] = useState<string>(initialOrg.description);
  const [pointOfContact, setPointOfContact] = useState<PartialUserData | null>(initialOrg.pointOfContact);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [documentValidationError, setDocumentValidationError] = useState<any | null>(null);
  const [orgNameError, setOrgNameError] = useState<string | undefined>(undefined);

  const [members, ,updateMembersRef] = useCollection<Member>(null, null);
  const [selectPointOfContactItems, setSelectPointOfContactItems] = useState<SelectItem[]>([noOne]);

  const selectDefaultValue = initialOrg.pointOfContact?.email ?? enOrganizationLabel.noPointOfContact;
  const [userIsTyping, setUserIsTyping] = useState<boolean>(false);

  useDebounce(validateOrgName, 500, [name]);

  // update members path based on the received organization
  useEffect(() =>  {
    if (!organization?.["@id"]) {
      updateMembersRef(null);
      return;
    }

    const membersPath = organizationMembersPath(organization["@id"]);
    updateMembersRef(membersPath);
  }, [organization?.["@id"]])

  useEffect(() => {
    const getList = async () => {
      const items = await generatePointOfContactsList({
        organizationId: !!orgDoc ? orgDoc["@id"]! : null,
        members: members ?? [],
        uid: uid!,
        isAdmin: props.fromSAP ?? false,
      });
      setSelectPointOfContactItems(items);
    }

    getList();
  }, [members]);

  // update initialOrg based on received organization
  useEffect(() => {
    let newOrgData = {
      name: organization?.name ?? "",
      description: organization?.description ?? "",
      pointOfContact: organization?.pointOfContact ?? null
    }
    // @ts-ignore
    setInitialOrg(newOrgData);

    setName(newOrgData.name);
    setDescription(newOrgData.description)
    setPointOfContact(newOrgData.pointOfContact);

    setDocumentValidationError(null);
    setOrgNameError(undefined);
    setIsLoading(false);
    setOrgDoc(null);
  }, [isDrawerOpen, organization]);

  function handleDocumentError() {
    if (!orgDoc || !orgDoc["@form"] || !orgDoc["@form"]["@messages"]) return;

    const errorMessage = orgDoc["@form"]["@messages"];
    if (typeof (errorMessage) === "object") {
      setDocumentValidationError(errorMessage);
    } else { //typeof(errorMessage) === "string"
      setToastMessage(errorMessage);
      setToastSeverity(Severity.Error);
      setIsToastOpen(true);
    }
    setOrgDoc(null);
  }

  function isFormValid() {
    return (
      !!organization ?
        (isFormEdited() && documentValidationError === null && !isLoading) :
        documentValidationError === null && !isLoading
    ) && !!name.trim();
  }

  function isFormEdited() {
    const extractPocData = (poc: PartialUserData | null) => {
      const {firstName, lastName, id, ...rest} = poc || {};
      return rest;
    };

    const newOrg = {
      name,
      description,
      pointOfContact: extractPocData(pointOfContact),
    };

    const initialOrgData = {
      name: initialOrg.name,
      description: initialOrg.description,
      pointOfContact: extractPocData(initialOrg.pointOfContact),
    };

    return !areObjectsEqual(newOrg, initialOrgData);
  }

  async function onSubmitForm() {
    const organizationId = organization && organization["@id"] ? organization["@id"] : orgId!;
    const docRef = organization ? doc(organizationsPath(), organizationId)
      : doc(organizationsPath());

    setIsLoading(true);
    setDocumentValidationError(null);

    const action = !organization ? ActionType.Create : ActionType.Update;
    if (!orgDoc) setOrgDoc(docRef);

    // @ts-ignore
    await submitForm<Partial<Organization>>(
      docRef,
      action,
      (status, data, isLastUpdate) => {onSubmitStatusHandler(docRef.id, status, data, isLastUpdate);},
      {
        name,
        description,
        pointOfContact: !pointOfContact ? pointOfContact : {...pointOfContact, id: pointOfContact["@id"]}
      },
      organization
    );
  }

  function handleCloseDrawer() {
    setUserIsTyping(false);
    closeDrawer();
  }

  function saveNewSidebar(orgId: string) {
    const newSidebarView = {
      sidebarView: {
        selectedOrg: {
          id: orgId,
          name,
          accessRole: AccessRole.Owner,
        },
      },
    };
    setSelectedOrg(newSidebarView.sidebarView.selectedOrg);

    submitForm(
      doc(db, "users", uid!),
      ActionType.Update,
      emptyFunction,
      {
        ...newSidebarView,
        "@actionType": ActionType.Update,
      },
    );
  }

  function onSubmitStatusHandler(orgId: string, status: string, data: FormData, isLastUpdate: boolean) {
    if (!isLastUpdate) return;
    setIsLoading(false);

    switch (status) {
      case ViewStatus.Finished:
        setToastMessage(!organization ? enOrganizationLabel.createSuccess(name) : enOrganizationLabel.updateSuccess);
        setToastSeverity(Severity.Success);
        setIsToastOpen(true);

        // update sidebar view
        if (!organization) {
          saveNewSidebar(orgId!);
          setSelectedOrgId(orgId!);
          navigate(`/${orgId!}/explore-organizations`);
        }

        handleCloseDrawer();
        break;
      case ViewStatus.SecurityError:
      case ViewStatus.ValidationError:
      case ViewStatus.Error:
        handleDocumentError();
        break;
    }
  }

  async function handleSelectChange(selected: string) {
    //wait for user doc to load
    if (selected === noOne.value) return setPointOfContact(null);

    if(organization && members) {
      const selectedUser = members.find((member) => member.email === selected);
      if (!!selectedUser) {
        setPointOfContact(createPartialUserDataFromUser(selectedUser as unknown as User));
      } else {
        const currentUser = (await getDoc(userPath(uid))).data() as User;
        return setPointOfContact(createPartialUserDataFromUser(currentUser));
      }
    } else {
      // TODO: FIX PROPERLY. THIS IS ONLY A QUICK FIX FOR POC NOT SAVED WHEN CREATING NEW ORG
      const currentUser = (await getDoc(userPath(uid))).data() as User;
      return setPointOfContact(createPartialUserDataFromUser(currentUser));
    }
  }

  async function validateOrgName() {
    if (name.trim() === "" && userIsTyping) {
      setOrgNameError(enOrganizationLabel.requiredOrganizationName);
    }
  }

  return (
    <SimpleFormDrawer
      id="manage-organization-drawer"
      isOpen={isDrawerOpen}
      onClose={handleCloseDrawer}
      icon={!organization ? <BriefcaseIcon/>
        : <SystemIndex.SystemIcons.Edit/>
      }
      title={!organization ? enOrganizationLabel.new : enOrganizationLabel.edit}
      isFormValid={isFormValid()}
      isLoading={isLoading}
      onSubmit={onSubmitForm}
      buttonId="save-or-create-button"
      rightButtonLabel={organization ? enCommonButton.save : enCommonButton.create}
      unsavedChanges={isFormEdited()}
    >
      <Input
        id="name-input"
        sx={{mb: 1}}
        value={name}
        label="Name"
        onChange={(e) => {
          setUserIsTyping(true);
          onChangeInput(e, setName);
          setOrgNameError(undefined);
        }}
        validationMessage={orgNameError}
        onKeyPress={(e) => handleEnterKeyPress(e, isFormValid(), isLoading, onSubmitForm)}
      />
      <Input
        id="description-input"
        value={description}
        multiline
        maxRows={14}
        minRows={4}
        sx={{mb: 1}}
        label={enCommonLabel.description}
        onChange={(e) => onChangeInput(e, setDescription)}
        validationMessage={
          documentValidationError
            ? documentValidationError.description
            : null
        }
        optional
        onKeyPress={(e) => handleEnterKeyPress(e, isFormValid(), isLoading, onSubmitForm)}
      />
      <SelectWithSearch
        label={enOrganizationLabel.pointOfContact}
        items={selectPointOfContactItems}
        handleSelectCallback={handleSelectChange}
        defaultValue={selectDefaultValue}
      />
    </SimpleFormDrawer>
  )
}

export default ManageOrganizationDrawer;
