import {InvitationStatus} from "enums/invitationStatus";
import {emptyFunction, enCommonButton, enMemberLabel} from "constants/index";
import {AcceptedTag, CancelledTag, DeclinedTag, InviteSentTag} from "components/Tags";
import {Invite} from "types/Invite";
import {styled} from "@mui/system";
import {Button, Stack} from "@mui/material";
import theme from "theme/theme";
import {DisabledButton} from "components/Button";
import React from "react";

interface InviteContentProps {
  invitation: Invite;
  submitResendInvite: () => void;
  openCancelDialog: () => void;
}

function InviteContent(props: InviteContentProps) {
  const {invitation, openCancelDialog, submitResendInvite} = props;
  const {invitationStatus, invitedByUserData} = invitation;

  switch (invitationStatus) {
    case InvitationStatus.Sent:
      return (
        <Stack direction={{sm: "column", md: "row"}} gap={1} justifyContent="space-between" flex={1}>
          {enMemberLabel.invitationToJoin(invitedByUserData.name)}
          <Stack direction="row" alignItems="center" gap={1}>
            <InviteSentTag/>
            {invitation.resend ? (
              <DisabledButton
                disabled={true}
                label={enCommonButton.resend}
                onClickFcn={emptyFunction}
              />
            ): (
              <ResendButton
                onClick={submitResendInvite}
                variant="contained"
              >
                {enCommonButton.resend}
              </ResendButton>
            )}

            <CancelButton onClick={openCancelDialog} variant="contained">
              {enCommonButton.cancel}
            </CancelButton>
          </Stack>
        </Stack>
      )
    case InvitationStatus.Accepted:
      return (
        <Stack direction={{sm: "column", md: "row"}} gap={1} justifyContent="space-between" flex={1}>
          {enMemberLabel.invitationToJoin(invitedByUserData.name)}
          <AcceptedTag/>
        </Stack>
      )
    case InvitationStatus.Declined:
      return (
        <Stack direction={{sm: "column", md: "row"}} gap={1} justifyContent="space-between" flex={1}>
          {enMemberLabel.invitationToJoin(invitedByUserData.name)}
          <Stack direction="row" gap={1}>
            <DeclinedTag/>
            <ResendButton onClick={submitResendInvite} variant="contained">
              {enCommonButton.resend}
            </ResendButton>
          </Stack>
        </Stack>
      )
    case InvitationStatus.Expired:
      return (
        <Stack direction={{sm: "column", md: "row"}} gap={1} justifyContent="space-between" flex={1}>
          {enMemberLabel.expiredInvitation(invitedByUserData.name)}
          <ResendButton variant="contained">
            {enCommonButton.resend}
          </ResendButton>
        </Stack>
      )
    case InvitationStatus.Cancelled:
      return (
        <Stack direction={{sm: "column", md: "row"}} gap={1} justifyContent="space-between" flex={1}>
          {enMemberLabel.cancelledInvitation(invitedByUserData.name)}
          <CancelledTag/>
        </Stack>
      )
    default:
      return null;
  }
}

const ResendButton = styled(Button)({
  padding: "8px 16px",
  height: "32px"
});

const CancelButton = styled(Button)({
  padding: "8px 16px",
  height: "32px",
  backgroundColor: theme.palette.error.main,
  "&:hover": {
    backgroundColor: theme.palette.error.dark
  }
});

export default InviteContent;