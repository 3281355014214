import {SystemIcons} from "assets/icons/system/system.index";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Input, SimpleFormDrawer} from "components/index";
import {en} from "language/en";
import {teamCategoriesPath} from "screens/utility/FirebasePath";
import {useParams} from "react-router-dom";
import {TeamCategory} from "types/TeamCategory";
import {ActionType, ProcessType, Severity} from "enums/index";
import {doc} from "firebase/firestore";
import {BaseProps} from "../../BaseProps";
import {Box} from "@mui/material";
import {enCommonButton, enCommonLabel, enManageTeamCategoriesLabel} from "constants/index";
import {onChangeInput, submitForm} from "screens/utility";
import handleEnterKeyPress from "../../utility/handleEnterKeyPress";
import {statusSubmitHandler} from "../../utility/statusSubmitHandler";

interface ManageTeamCategoryFormDrawerProps extends BaseProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  teamCategory?: TeamCategory;
}

function ManageTeamCategoryFormDrawer(props: ManageTeamCategoryFormDrawerProps) {
  const {isOpen, setIsOpen, toastProps, teamCategory} = props;
  const {setIsToastOpen, setToastSeverity, setToastMessage} = toastProps!;
  const {orgId} = useParams();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [errorMessages, setErrorMessages] = useState<any>({})

  const [isLoading, setIsLoading] = useState(false)

  const [docReference, setDocReference] = useState(doc(teamCategoriesPath(orgId!)))

  const firstLoad = useRef(true);

  const isFormValid = !teamCategory ? areFieldsDirty(true) : areFieldsDirty()

  useEffect(() => {
    setErrorMessages({})
  }, [name])

  useEffect(() => {
    if (!firstLoad.current) {
      if (name.trim() === "")
        setErrorMessages({name: en.common.validations.requiredName});
      else
        setErrorMessages({})
    }
    firstLoad.current = false;
  }, [name]);

  useEffect(() => {
    firstLoad.current = true;
    setErrorMessages({})
    if (teamCategory) {
      setName(teamCategory.name);
      setDescription(teamCategory.description ?? "");
      setDocReference(doc(teamCategoriesPath(orgId!), teamCategory["@id"]!))
      return;
    }
    resetForm();
  }, [isOpen])

  async function onAddTeamCategoryClick() {
    setIsLoading(true);
    await submitForm<Partial<TeamCategory>>(
      docReference,
      (teamCategory ? ActionType.Update : ActionType.Create),
      (status, data, isLastUpdate) => statusSubmitHandler<TeamCategory>({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback,
      }),
      {name, description}
    )
    ;
  }

  function successCallback() {
    const message = teamCategory ? en.screen.ManageTeamCategories.toasts.editSuccess(teamCategory?.name ?? name)
      : en.screen.ManageTeamCategories.toasts.createSuccess(name);
    setToastMessage(message);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    setIsLoading(false);
    setIsOpen(false)
  }

  function errorCallback(message: any) {
    setIsLoading(false);
    if (typeof message === "string") {
      setToastMessage(message || enCommonLabel.errorProcess(ProcessType.Update));
      setToastSeverity(Severity.Success);
      setIsToastOpen(true);
      return;
    }

    setErrorMessages(message)
  }

  function resetForm() {
    setName("");
    setDescription("");
    setDocReference(doc(teamCategoriesPath(orgId!)));
  }

  function areFieldsDirty(requiredOnly: boolean = false) {
    return requiredOnly ? (
      teamCategory ?
        (teamCategory.name !== name) :
        (name.trim() !== "")
    ) : (
      teamCategory ?
        (teamCategory.name !== name || teamCategory.description !== description) :
        (name.trim() !== "" || description.trim() !== "")
    );
  }

  // function named onKeyPress that does the following:
  // if the key pressed is the enter key and isFormValid value from simpleformdrawer is true and is not loading, then call onSubmit function used in SimpleFormDrawer

  return <SimpleFormDrawer
    isOpen={isOpen}
    id={"manage-team-category-drawer"}
    onClose={() => setIsOpen(false)}
    isFormValid={isFormValid}
    icon={<Box
      sx={theme => ({
        display: "flex",
        borderRadius: "50%",
        backgroundColor: theme.palette.background.swiftDefault,
        alignItems: "center",
        justifyContent: "center",
        padding: 1,
        width: 48,
        height: 48
      })}><SystemIcons.Plus/></Box>}
    iconProps={{p: 0}}
    isLoading={isLoading}
    title={!!teamCategory ? enManageTeamCategoriesLabel.editTeamCategoryTitle : enManageTeamCategoriesLabel.createTeamCategoryTitle}
    bodySx={{gap: 1}}
    rightButtonLabel={!!teamCategory ? enCommonButton.save : enCommonButton.create}
    onSubmit={onAddTeamCategoryClick}
    unsavedChanges={areFieldsDirty()}
    buttonId={"manage-team-category-save-button"}
  >
    <Input
      label={en.common.label.name}
      onChange={(e) => onChangeInput(e, setName)}
      validationMessage={errorMessages.name}
      value={name}
      id={"manage-category-name-input"}
      onKeyPress={(e) => handleEnterKeyPress(e, isFormValid, isLoading, onAddTeamCategoryClick)}
    />
    <Input
      label={en.common.label.description}
      onChange={(e) => onChangeInput(e, setDescription)}
      multiline
      minRows={5}
      maxRows={10}
      value={description}
      optional
      id={"manage-category-description-input"}
      onKeyPress={(e) => handleEnterKeyPress(e, isFormValid, isLoading, onAddTeamCategoryClick)}
    />
    <Box flex={1}/>
  </SimpleFormDrawer>
}

export default ManageTeamCategoryFormDrawer;
