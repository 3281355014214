import React, {Dispatch, useContext, useState} from "react";
import {AccessListUser} from "types/AccessListUser";
import {AccessRole, AccessUserType, Entity} from "enums/index";
import {CollectionReference} from "firebase/firestore";
import {enCommonLabel} from "constants/index";
import ManageAccessEmptyPage from "components/Drawers/ManageAccessDrawer/Objects/ManageAccessEmptyPage";
import PeopleList from "components/Drawers/ManageAccessDrawer/Objects/PeopleList";
import {BaseSearchInput, InProgress, OverFlowBox} from "components/index";
import {BaseProps} from "screens/BaseProps";
import EmptySearchResults from "components/EmptySearchResults";
import {Box, Divider} from "@mui/material";
import TeamsList from "components/Drawers/ManageAccessDrawer/Objects/TeamsList";
import {AccessType} from "types/Permission";
import {SelectedItem} from "components/Drawers/AddTeamOrPeopleDrawer";
import {SelectedOrgContext} from "screens/SelectedOrgContextProvider";
import {useDebounce} from "hooks/index";
import {searchInArrayWithStringify, sortObjectsBy} from "screens/utility";

interface DynamicViewProps extends BaseProps {
  isLoading: boolean;
  teams: AccessListUser[] | null;
  people: AccessListUser[] | null;
  collectionRef: CollectionReference;
  entity: Entity;
  access: AccessType | null;
  openAddTeamsAndPeopleDrawer: () => void;
  setProcessedPeopleMessage?: Dispatch<string>;
  setProcessedTeamMessage?: Dispatch<string>;
}

function DynamicView(props: DynamicViewProps) {
  const {isLoading, teams, people, entity, collectionRef, access} = props;
  const {toastProps, openAddTeamsAndPeopleDrawer, setProcessedPeopleMessage, setProcessedTeamMessage} = props;

  const ownerCount = (people ?? []).filter(person => person.role === AccessRole.Owner).length;

  const selectedOrgContext = useContext(SelectedOrgContext);
  const {selectedOrg} = selectedOrgContext!;

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [algoliaAccessList, setAlgoliaAccessList] = useState<AccessListUser[] | null>(null);
  const [selectedTeams, setSelectedTeams] = useState<SelectedItem []>([]);
  const [selectedPeople, setSelectedPeople] = useState<SelectedItem []>([]);

  const algoliaTeams = algoliaAccessList ? algoliaAccessList.filter((accessList) => accessList.accessUserType === AccessUserType.Team) : [];
  const algoliaPeople = algoliaAccessList ? algoliaAccessList.filter((accessList) => accessList.accessUserType === AccessUserType.Person) : [];

  const displayedTeams = sortObjectsBy(
    ((algoliaAccessList ? algoliaTeams : teams) || []).map(team => {
      const newTeam = selectedOrg?.teams?.find(selectedTeam => selectedTeam.id === team["@id"]);
      if (!newTeam) return team;
      return {
        ...newTeam,
        name: newTeam.name.trim(),
        role: team.role || newTeam.role,
        accessUserType: AccessUserType.Team
      }
    }),
    "name"
  );

  const displayedPeople = sortObjectsBy(
    ((algoliaAccessList ? algoliaPeople : people) || []).map(member => {
      const newMember = selectedOrg?.members?.find(selectedMember => selectedMember.uid === member["@id"]);
      if (!newMember) return member;
      return {
        ...newMember,
        name: newMember.name.trim(),
        role: member.role || newMember.role,
        accessUserType: AccessUserType.Person,
      }
    }),
    "name"
  );

  const currentTeams = [...(teams || [])].map((team) => {
    const selectedTeam = selectedOrg?.teams?.find((selectedTeam) => selectedTeam.id === team["@id"]);
    if (!selectedTeam) return team;
    return {
      ...selectedTeam,
      role: team.role,
      accessUserType: AccessUserType.Team
    }
  });

  const currentMembers = [...(people || [])].map((member) => {
    const selectedMember = selectedOrg?.members?.find((selectedMember) => selectedMember.uid === member["@id"]);
    if (!selectedMember) return member;
    return {
      ...selectedMember,
      role: member.role,
      accessUserType: AccessUserType.Person
    }
  });

  useDebounce(searchFn, 500, [searchQuery, selectedOrg]);

  async function searchFn() {
    if (searchQuery === "") {
      setAlgoliaAccessList(null);
      return;
    }

    // if there are no teams or people
    if (currentTeams?.length === 0 && currentMembers?.length === 0)
      return;

    setLoading(true);
    const searchTeams = searchInArrayWithStringify(currentTeams, searchQuery);
    const searchPeople = searchInArrayWithStringify(currentMembers, searchQuery);
    setAlgoliaAccessList([...searchTeams, ...searchPeople]);
    setLoading(false);
  }

  if (!access || (!teams && !people && !algoliaAccessList))
    return <InProgress/>;

  // means we are still fetching the collection
  if ((teams === null && people === null) || isLoading || !access) {
    return (
      <OverFlowBox sx={{height: 'inherit'}}>
        <InProgress sx={{height: 'inherit'}}/>
      </OverFlowBox>
    )
  }

  const withZeroOrOneOwner = ownerCount <= 1 && (people ?? []).length <= 1;
  if (teams?.length === 0 && withZeroOrOneOwner) {
    return <>
      <OverFlowBox>
        <PeopleList
          allowSelect={false}
          allowRemove={false}
          people={people}
          collectionRef={collectionRef}
          toastProps={toastProps!}
          access={access}
          uid={props.uid}
          selectedPeople={selectedPeople}
          selectedTeams={selectedTeams}
          setSelectedPeople={setSelectedPeople}
          setProcessedPeopleMessage={setProcessedPeopleMessage}
        />
      </OverFlowBox>
      <ManageAccessEmptyPage
        entity={entity}
        setIsAddTeamsAndPeopleDrawerOpen={openAddTeamsAndPeopleDrawer}
      />
    </>
  }

  return (
    <>
      <BaseSearchInput
        sx={{mx: 1, mb: 2, minWidth: "300px"}}
        id="mange-access-drawer-search"
        placeholder={enCommonLabel.searchEntityAccess(entity)}
        searchFn={(searchText: string) => setSearchQuery(searchText)}
        loading={loading}
      />
      <OverFlowBox sx={{flex: 1}}>
        {algoliaAccessList !== null && algoliaTeams.length === 0 && algoliaPeople.length === 0 ?
          <Box sx={{transform: "translateY(50%)"}}>
            <EmptySearchResults entity={Entity.AccessList}/>
          </Box>
          : (
            <>
              <TeamsList
                allowSelect={true}
                allowRemove={true}
                teams={displayedTeams as any[]}
                collectionRef={collectionRef}
                toastProps={toastProps!}
                access={access}
                uid={props.uid}
                selectedTeams={selectedTeams}
                setSelectedTeams={setSelectedTeams}
                setProcessedTeamMessage={setProcessedTeamMessage}
              />
              {(displayedTeams ?? []).length > 0 && (displayedPeople ?? []).length > 0 && <Divider/>}
              <PeopleList
                allowSelect={true}
                allowRemove={true}
                people={displayedPeople as any[]}
                collectionRef={collectionRef}
                toastProps={toastProps!}
                access={access}
                uid={props.uid}
                selectedPeople={selectedPeople}
                setSelectedPeople={setSelectedPeople}
                selectedTeams={selectedTeams}
                setProcessedPeopleMessage={setProcessedPeopleMessage}
              />
            </>
          )
        }
      </OverFlowBox>
    </>
  )
}

export default DynamicView;
