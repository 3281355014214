import {organizationDataTagsPath} from "screens/utility/FirebasePath";
import {useCollection} from "hooks/index";
import {DataTag} from "types/DataTag";
import {useParams} from "react-router-dom";

function useDataTags(tagIds: string[], uid: string) {
  const {orgId} = useParams();
  const [dataTags] = useCollection<DataTag>(null, organizationDataTagsPath(orgId!));
  return dataTags ? dataTags.filter(el => (tagIds.includes(el.id!))) : []
}

export default useDataTags