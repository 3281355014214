import theme from "theme/theme";
import {Box} from "@mui/material";
import React from "react";
import {InProgress} from "components/index";

interface VideoPreviewProps {
  id?: string;
  itemSx: any;
  withBorder?: boolean;
}

function LoadingPreview(props: VideoPreviewProps) {
  const {id = "loading", itemSx, withBorder} = props;

  return <Box
    className="file-preview-item"
    id={`loading-preview-${id}`}
    sx={{
      display: "grid",
      placeItems: "center",
      height: 64,
      minWidth: 64,
      backgroundColor: theme.palette.background.swiftDefault,
      borderRadius: 1,
      marginX: 0,
      ...(withBorder && {
        border: `1px solid ${theme.palette.neutral.light}`,
      }),
      ...itemSx
    }}
  >
    <InProgress size={20}/>
  </Box>
}

export default LoadingPreview;