/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect} from "react";
import {Stack} from "@mui/material";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {Asset, Project} from "types/index";
import {Content, InProgress} from "components";
import Header from "./Header";
import {assetPath, milestonesPath, projectPath} from "../utility";
import {useAccess, useDocument} from "hooks/index";
import {Entity} from "enums/index";
import {BaseProps} from "../BaseProps";
import {emptyFunction} from "constants/index";
import {PermissionEntity} from "types/Permission";
import MilestonesView from "components/MilestoneView/MilestonesView";
import {algoliaAssetPath} from "../utility/algoliaColPath";
import {SelectedOrgContext} from "../SelectedOrgContextProvider";
import {OutletContextType} from "components/RestrictedPage";

function AssetOverview(props: BaseProps) {
  const {toastProps, uid, setSelectedOrgId = emptyFunction} = props;
  const {isLoading} = useOutletContext<OutletContextType>();

  const {orgId, projId, assetId, templateId} = useParams();
  const algoliaPath = algoliaAssetPath(orgId!, projId!, assetId!, templateId!);

  const selectedOrgContext = useContext(SelectedOrgContext);
  const {selectedOrg} = selectedOrgContext!;

  const assetDocPath = assetPath(orgId!, projId!, assetId!);
  const [assetData] = useDocument<Asset | null>(assetDocPath);
  const [project] = useDocument<Project>(projectPath(orgId!, projId!));

  const navigate = useNavigate();

  const milestonesCollectionRef = milestonesPath(orgId!, projId!, assetId!);

  const [assetAccess] = useAccess({
    uid: uid!,
    entity: Entity.Asset,
    documentDocId: PermissionEntity.Asset,
  });

  const [downloadAccess] = useAccess({
    uid: uid!,
    entity: Entity.Asset,
    documentDocId: PermissionEntity.AssetFilesDownload,
  });

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  if (!assetData || isLoading)
    return (
      <Content>
        <InProgress/>
      </Content>
    );

  if (!project) return null;

  return (
    <>
      <Header
        uid={uid}
        asset={assetData!}
        assetDocPath={assetDocPath.path}
        assetAccess={assetAccess}
        downloadAccess={downloadAccess}
        toastProps={toastProps!}
        projectAssetTemplateId={project.projectAssetTemplateId}
      />
      <Content>
        <Stack sx={{maxWidth: "90vw"}} height="100%">
          <Stack direction="column" spacing={3} gap={0}>
            <MilestonesView
              asset={assetData}
              milestonesCollectionRef={milestonesCollectionRef.path}
              parentDocPath={assetDocPath.path}
              algoliaPath={algoliaPath}
              parent={assetData!}
              parentAccess={assetAccess}
              parentEntity={Entity.Asset}
              downloadAccess={downloadAccess}
              projectAssetTemplateId={project.projectAssetTemplateId}
              {...props}
            />
          </Stack>
        </Stack>
      </Content>
    </>
  );
}

export default AssetOverview;
