import {PageTitle} from "components/index";
import {enCommonLabel, enOrgAssets, enOrgAssetsButton} from "constants/index";
import {PrimaryButton, SecondaryButton} from "components/Button";
import {Stack} from "@mui/material";
import React, {useEffect} from "react";
import {AssetCreationDrawer} from "components/Drawers";
import {Entity} from "enums/entity";
import {organizationAssetsPath} from "../utility";
import {useComponentToggler} from "hooks/index";
import {Organization} from "types/Organization";
import {toastProps} from "../BaseProps";
import SearchAndSort, {SearchAndSortProps} from "./SearchAndSort";
import BulkActions, {BulkActionsProps} from "./BulkActions";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {Severity} from "enums/severity";
import {doc} from "firebase/firestore";
import {db} from "../../firebase";
import {ImportDialog} from "components/Dialogs";
import {FeatureType} from "enums/featureType";
import FeatureWrapper from "components/FeatureWrapper";

type PageHeaderProps = SearchAndSortProps & BulkActionsProps & {
  uid: string;
  organization: Organization | null | undefined;
  organizationAssetsCount: number;
  canCreate: boolean;
  lastAssetIdCode: string;
  toastProps: toastProps;
  organizationRefPath: string;
  progressCount: {
    progress: number;
    isProcessComplete: boolean;
    isProcessing: boolean;
    label: string;
  }
}

function PageHeader(props: PageHeaderProps) {
  const {uid, organization, toastProps, canCreate, organizationAssetsCount, organizationRefPath, ...rest} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps;
  const {progress, isProcessComplete, label} = props.progressCount;

  const [isImportDialogOpen, {open: openImportDialog, close: closeImportDialog}] = useComponentToggler(false);
  const [isDrawerOpen, {open: openDrawer, close: closeDrawer}] = useComponentToggler(false);

  useEffect(() => {
    if (!isProcessComplete) return;
    setToastMessage(enCommonLabel.importCompleted);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
  }, [isProcessComplete]);

  if (!organization) return null;

  return (
    <Stack
      paddingY={2}
      maxWidth={"100vw"}
      flexDirection="column"
      gap={1}
    >
      <ImportDialog
        uid={props.uid}
        isOpen={isImportDialogOpen}
        entity={Entity.OrganizationAsset}
        toastProps={toastProps!}
        closeDialog={closeImportDialog}
        parentDocumentRef={doc(db, organizationRefPath)}
      />
      <AssetCreationDrawer
        lastIdCode={props.lastAssetIdCode}
        drawerVisibility={isDrawerOpen}
        onDrawerClose={closeDrawer}
        orgName={organization.name}
        organization={organization}
        projName={""}
        uid={uid}
        toastProps={toastProps}
        parentEntity={Entity.Organization}
        collectionRef={organizationAssetsPath(organization["@id"]!)}
      />
      <Stack flexDirection="row" justifyContent="space-between">
        <PageTitle title={enOrgAssets.title} count={organizationAssetsCount}/>
        <Stack direction="row" gap={1}>
          <FeatureWrapper viewable feature={FeatureType.ImportOrganizationAsset}>
            <SecondaryButton
              sx={{px: 2}}
              id="import-datatag-button"
              onClickFcn={openImportDialog}
              disabled={progress > 0}
              startIcon={(props) => <SystemIcons.Upload stroke={theme.palette.primary.main} {...props} />}
              label={label}
              disabledHoverLabel={enCommonLabel.importInProgress}
            />
          </FeatureWrapper>
          <PrimaryButton
            onClickFcn={openDrawer}
            disabled={!canCreate}
            label={enOrgAssetsButton.createNewAsset}
          />
        </Stack>
      </Stack>
      {organizationAssetsCount > 0 && (
        <>
          <SearchAndSort {...rest} />
          <BulkActions
            {...rest}
            openDeleteDialog={props.openDeleteDialog}
          />
        </>
      )}
    </Stack>
  )
}

export default PageHeader;