import {CollectionReference, doc} from "firebase/firestore";
import {BaseProps} from "screens/BaseProps";
import {Box, Checkbox, Divider, Stack} from "@mui/material";
import {LongText} from "components/index";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import {SwiftIconButton} from "components/Button";
import {enCommonLabel, enFormRequirementLabel, enTemplateLabel} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import React, {useEffect, useRef, useState} from "react";
import {FormRequirement} from "types/FormRequirement";
import {AccessType, PermissionOperationKey} from "types/Permission";
import RightClickMenu from "../components/RightClickMenu";
import VisibilityIcon from "components/VisibilityIcon";

interface TemplateRequirementItemProps extends BaseProps {
  formRequirement: FormRequirement;
  isChecked: boolean;
  formRequirementAccess: AccessType | null;
  onCheckboxToggle: (checked: boolean, id: string) => void;
  collectionRef: CollectionReference;
  openRequirementDrawer: (requirement: FormRequirement) => void;
  editRequirement: (requirement: FormRequirement) => void;
  deleteRequirement: (requirementId: string) => void;
  showCheckBox: boolean;
}

function TemplateRequirementItem(props: TemplateRequirementItemProps) {
  const {formRequirement, isChecked, toastProps, formRequirementAccess, collectionRef, showCheckBox} = props;
  const {onCheckboxToggle, editRequirement, deleteRequirement} = props;
  const {"@id": id = "fillerId", name, description, hidden} = formRequirement;
  const anchorEl = useRef(null)

  const [isRequirementChecked, setIsRequirementChecked] = useState(isChecked);
  const [isRightClickMenuOpen, setIsRightClickMenuOpen] = useState(false);
  const [anchorPosition, setAnchorPosition] = useState<{ top: number, left: number }>({top: 0, left: 0});

  useEffect(() => {
    setIsRequirementChecked(isChecked);
  }, [isChecked]);

  function onCheckboxChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    onCheckboxToggle(checked, formRequirement["@id"] ?? "");
    setIsRequirementChecked(checked);
  }

  function handleFormRequirementRightClick(e: React.MouseEvent<HTMLDivElement>) {
    setAnchorPosition({top: e.clientY, left: e.clientX})
    e.preventDefault()
    setIsRightClickMenuOpen(true)
  }

  return (<>
      <RightClickMenu
        filesCount={0}
        uid={props.uid}
        anchorPosition={anchorPosition}
        anchorEl={anchorEl.current}
        isOpen={isRightClickMenuOpen}
        onClose={() => setIsRightClickMenuOpen(false)}
        toastProps={toastProps!}
        collectionRef={collectionRef}
        formRequirement={formRequirement}
      />
      <Stack direction="row">
        {showCheckBox && <Checkbox
            checked={isRequirementChecked}
            onClick={(e) => e.stopPropagation()}
            onChange={onCheckboxChange}
            sx={{alignSelf: "center"}}
        />}
        <Stack
          ref={anchorEl}
          onContextMenu={handleFormRequirementRightClick}
          key={`requirement-item-${id}`}
          className="requirement-item"
          sx={(theme) => ({
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            cursor: "default",
            ":hover": {
              border: `1px solid ${theme.palette.primary.main}`,
            },
            backgroundColor: hidden ? undefined : theme.palette.background.default
          })}
          flex={1}
        >
          <Stack direction="row" alignItems="center">
            <Stack direction="row" flex={1} gap={1}>
              <Box sx={{alignSelf: "center", marginLeft: 1, justifyContent: "center"}}>
                <VisibilityIcon
                  hidden={hidden}
                  documentRef={doc(collectionRef, id!)}
                  hiddenMessage={enFormRequirementLabel.singleHideSuccess}
                  unHiddenMessage={enFormRequirementLabel.singleUnHideSuccess}
                  toastProps={toastProps}
                />
              </Box>
              <Divider orientation="vertical" flexItem/>
              <Stack
                direction="column"
                gap={0.5}
                alignItems="flex-start"
                flex={1}
                ml={1}
                pr={1}
                py={1}
              >
                <LongText variant="h4" maxLines={2} maxWidth="100%">{name}</LongText>
                <LongText
                  color="text.secondary"
                  variant="bodySmall"
                  maxLines={2}
                  maxWidth="100%"
                  mb={1}
                >
                  {!!description ? description : enCommonLabel.noDescription}
                </LongText>
                <DataTagsDisplay
                  uid={props.uid}
                  dataTagsIds={formRequirement.dataTagsIds || []}
                  toastProps={toastProps!}
                  listSize="medium"
                  isParentHidden={hidden}
                  canEditDocumentRef={Boolean(formRequirementAccess?.[PermissionOperationKey.Update])}
                  documentRef={doc(collectionRef, formRequirement["@id"]!)}
                />
              </Stack>
              <Divider orientation="vertical" flexItem/>
              <Stack direction="column">
                <Stack direction="row" alignItems="center" flex={1}>
                  <SwiftIconButton
                    onClickFcn={() => editRequirement(formRequirement)}
                    disabled={!Boolean(formRequirementAccess?.[PermissionOperationKey.Update])}
                    disabledHoverLabel={enTemplateLabel.cannotEdit}
                    startIcon={(props) => <SystemIcons.Edit stroke={theme.palette.secondary.main} {...props}/>}
                  />
                  <SwiftIconButton
                    onClickFcn={() => deleteRequirement(formRequirement["@id"]!)}
                    disabled={!Boolean(formRequirementAccess?.[PermissionOperationKey.Delete])}
                    disabledHoverLabel={enTemplateLabel.cannotDelete}
                    startIcon={(props) => <SystemIcons.DeleteOutlined stroke={theme.palette.error.main} {...props}/>}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default TemplateRequirementItem;