import {Box, Button, Hidden, Stack, Typography} from "@mui/material";
import {enCommonLabel} from "constants/index";
import {useNavigate} from "react-router-dom";
import React from "react";
import theme from "theme/theme";
import FormContainer from "components/FormContainer";
import {SwiftLogoOnly} from "assets/icons/SwiftLogo";
import {SystemIcons} from "assets/icons/system/system.index";
import useMaintenance from "hooks/useMaintenance";

interface InvalidInvitationProps {
  loggedIn?: boolean;
}

function InvalidInvitation(props: InvalidInvitationProps) {
  useMaintenance(false);
  const {loggedIn} = props;

  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.secondary.main,
        zIndex: "-2!important",
        backgroundImage: `url("/assets/Artboard6.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minWidth: loggedIn ? "100vw!important" : "unset",
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{height: "100vh"}}
      >
        <FormContainer>
          <Stack direction="column" gap={1} justifyContent="center" textAlign="center" py="2%" flex={1}>
            <Box
              sx={{
                backgroundColor: theme.palette.neutral.light,
                borderRadius: "50%",
                alignSelf: "center",
                display: "flex",
                padding: 1,
              }}
            >
              <SystemIcons.Close stroke={theme.palette.error.main} width={48} height={48}/>
            </Box>
            <Stack direction="column" marginTop={2}>
              <Typography variant="h2">
                Invalid Invitation
              </Typography>
              <hr
                style={{
                  borderRadius: 5,
                  width: "25%",
                  height: "5px",
                  border: "none",
                  backgroundColor: theme.palette.secondary.main
                }}
              />
            </Stack>
            <Stack textAlign="center" mt={3}>
              <Typography variant={"body1"}>
                {enCommonLabel.invalidInviteLine1}
              </Typography>
              <Typography variant="body1" mb={3}>
                {enCommonLabel.invalidInviteLine2}
              </Typography>
            </Stack>
            {loggedIn && (
              <Button
                variant="outlined"
                id="go-to-login-btn"
                onClick={() => navigate("/")}
                sx={{
                  marginTop: 2,
                  borderRadius: 5,
                  color: theme.palette.secondary.main,
                  borderColor: theme.palette.secondary.main,
                  padding: 1,
                  "&:hover": {
                    color: theme.palette.primary.main,
                  }
                }}
              >
                Go to Explore Orgs
              </Button>
            )}
            {!loggedIn && (
              <>
                <Button
                  variant="outlined"
                  id="go-to-login-btn"
                  onClick={() => navigate("/login")}
                  sx={{
                    marginTop: 2,
                    borderRadius: 5,
                    color: theme.palette.secondary.main,
                    borderColor: theme.palette.secondary.main,
                    padding: 1,
                    "&:hover": {
                      color: theme.palette.primary.main,
                    }
                  }}
                >
                  {enCommonLabel.goToLogin}
                </Button>
                <Button
                  variant="contained"
                  id="go-to-signup-btn"
                  onClick={() => navigate("/sign-up")}
                  sx={{marginTop: 2, borderRadius: 5, backgroundColor: theme.palette.secondary.main, padding: 1}}
                >
                  {enCommonLabel.goToSignup}
                </Button>
              </>
            )}
          </Stack>
        </FormContainer>
      </Stack>
      <Hidden smDown>
        <Box sx={{position: "absolute", bottom: "2%", right: "2%", zIndex: 2}}>
          <SwiftLogoOnly
            height={40}
            width={40}
            stroke={theme.palette.background.default}
            fill={theme.palette.background.default}
          />
        </Box>
      </Hidden>
    </Stack>
  )
}

export default InvalidInvitation;