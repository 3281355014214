import {styled} from "@mui/system";
import {Typography} from "@mui/material";

export const ChatMessageWrapper = styled(Typography)({
  display: "inline",
  gap: 0,
  paddingTop: 4,
  paddingBottom: 4,
  paddingLeft: 8,
  paddingRight: 8,
  borderRadius: "8px",
  width: "17rem"
});