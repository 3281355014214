import {Hidden, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import {InProgress} from "components/index";
import React, {useEffect, useState} from "react";
import {signInWithCustomToken} from "firebase/auth";
import {auth} from "../../firebase";
import TokenError from "./TokenError";
import ChangePasswordForm from "./ChangePasswordForm";
import {SwiftLogoOnly} from "assets/icons/SwiftLogo";
import theme from "theme/theme";
import {enCommonLabel} from "constants/index";
import {User as FirebaseUser} from "@firebase/auth";

interface ResetPasswordConfirmProps {
  token: string;
}

export function ResetPasswordConfirm(props: ResetPasswordConfirmProps) {
  const {token} = props;

  const [tokenError, setTokenError] = useState<string>("");
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null | undefined>(null);
  const [email, setEmail] = useState<string | null>(null);

  useEffect(() => {
    signInWithCustomToken(auth, token)
      .then(async ({user}) => {
        const tokenResult = await user.getIdTokenResult();
        const expirationTime = tokenResult.expirationTime; // Expiration time as a string
        const expirationDate = new Date(expirationTime).getTime(); // Convert to milliseconds
        const now = new Date().getTime(); // Current time in milliseconds

        if (now > expirationDate) {
          setTokenError('Password token already expired');
          await auth.signOut();
          return;
        }

        const newUser = user as unknown as FirebaseUser;
        setEmail(newUser.email!);
        setFirebaseUser(newUser);
      })
      .catch((e) => {
        setFirebaseUser(undefined);

        if (e.code === "auth/invalid-custom-token") {
          setTokenError(enCommonLabel.invalidToken);
          return;
        }

        setTokenError(e.code);
    });
  }, [token]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        zIndex: "-2!important",
        backgroundImage: `url("/assets/Artboard6.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{height: "100vh"}}
      >
        {tokenError !== "" || firebaseUser === undefined ? (<TokenError tokenError={tokenError}/>)
          : (firebaseUser === null) ? <InProgress/>
            : <ChangePasswordForm token={token} email={email || ""} />}
      </Stack>
      <Hidden smDown>
        <Box sx={{position: "absolute", bottom: "2%", right: "2%", zIndex: 2}}>
          <SwiftLogoOnly
            height={40}
            width={40}
            stroke={theme.palette.background.default}
            fill={theme.palette.background.default}
          />
        </Box>
      </Hidden>
    </Box>
  )
}