import {Breakpoint, Dialog, DialogContent, DialogTitle, IconButton, Stack} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import React, {ReactElement} from "react";

interface BaseDialogProps {
  title: string;
  isOpen: boolean;
  closeDialog: () => void;
  children: ReactElement;
  additionalActions?: ReactElement;
  manualClose?: () => void;
  maxWidth?:  false | Breakpoint | undefined
}

function BaseDialog (props: BaseDialogProps) {
  const {title, isOpen, children, closeDialog, manualClose, additionalActions, maxWidth} = props;

  function handeClose(event: Object, reason: string) {
    if(reason === "backdropClick") {
      return;
    }

    closeDialog();
  }

  function handleManualClose(event: Object) {
    (typeof manualClose === 'function') ? manualClose() : closeDialog();
  }

  return (
    <Dialog open={isOpen} onClose={handeClose} maxWidth={maxWidth}>
      <DialogTitle
        sx={{
          display: "flex",
          fontWeight: "bold",
          fontSize: 20,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {title}
        <Stack direction="row" gap={1} alignItems="center">
          {additionalActions}
          <IconButton onClick={handleManualClose}>
            <SystemIcons.Close/>
          </IconButton>
        </Stack>

      </DialogTitle>
      <DialogContent sx={{minWidth: {sm: "unset", xs: "unset", md: "unset", lg: 450, xl: 450}}}>
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default BaseDialog;
