import {ToastProps} from "screens/BaseProps";
import {FileUploadItemType} from "types/ActivityFeedTypes";
import {FileStatus} from "enums/fileStatus";
import {StatusIcons} from "assets/icons/status/status.index";
import IconBaseProps from "./IconBaseProps";
import BaseItem from "./BaseItem";
import {enActivityFeedLabel, spinTransform} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {Box, Typography} from "@mui/material";
import React from "react";
import theme from "theme/theme";
import {FileUploadStatus} from "enums/fileUploadStatus";
import {formatDateTimeFirestoreDate, userActivityFeedPath} from "../../utility";
import {useDocument} from "hooks/index";
import {doc} from "firebase/firestore";
import {SkeletonItem} from "components/index";

interface FileUploadItemProps extends ToastProps {
  itemId: string;
  uid: string;
  index: number;
  updatePinnedValue: (index: number, pinned: boolean) => void;
}

function FileStatusChangeItem(props: FileUploadItemProps) {
  const {itemId, toastProps, uid, updatePinnedValue, index} = props;

  const [activityFeed] = useDocument<FileUploadItemType>(doc(userActivityFeedPath(uid), itemId));

  if (!activityFeed) {
    return <SkeletonItem height={"5rem"} sx={{marginTop: "5px", width: "80%"}}/>
  }

  const {details} = activityFeed;

  const {fileStatus, fileUploadStatus, timeCreated} = details.fileUpload;
  const createdTime = formatDateTimeFirestoreDate(timeCreated)

  let Icon = <></>;
  let text = <></>;
  switch (fileStatus) {
    case FileStatus.Approved:
      Icon = <StatusIcons.Approved {...IconBaseProps}/>;
      text = enActivityFeedLabel.fileStatusChange(fileStatus, details.by.name || "", details.name);
      break;
    case FileStatus.Failed:
      Icon = <StatusIcons.Rejected {...IconBaseProps}/>;
      text = enActivityFeedLabel.failedUpload(details.by.name || "", details.name);
      break;
    case FileStatus.Rejected:
      Icon = <StatusIcons.Rejected {...IconBaseProps}/>;
      text = enActivityFeedLabel.fileStatusChange(fileStatus, details.by.name || "", details.name);
      break;
    case FileStatus.Uploaded:
      Icon = <StatusIcons.Uploaded {...IconBaseProps}/>;
      text = enActivityFeedLabel.profileUploadSuccess(fileStatus, details.by.name || "", details.name, `${createdTime.date} ${createdTime.time}`);
      break;
    case FileStatus.Uploading:
      Icon = (
        <Box sx={{animation: `${spinTransform} 1s infinite linear`}}>
          <SystemIcons.LoaderOnWhite {...IconBaseProps}/>
        </Box>
      );
      text = <>{enActivityFeedLabel.uploading}</>
      break;
  }

  if (fileUploadStatus && fileUploadStatus === FileUploadStatus.Failed) {
    Icon = <StatusIcons.Rejected {...IconBaseProps}/>;
    text = enActivityFeedLabel.failedUpload(details.by.name || "", details.name);
  }

  return (
    <BaseItem
      index={index}
      uid={uid}
      item={activityFeed}
      icon={Icon}
      text={<Typography color={theme.palette.secondary.main}><strong>{details.name}</strong></Typography>}
      additionalText={text}
      updatePinnedValue={updatePinnedValue}
      toastProps={toastProps!}
    />
  )
}

export default FileStatusChangeItem;
