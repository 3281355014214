import {Stack} from "@mui/material";
import {BaseProps} from "../BaseProps";
import {Permission} from "types/Permission";
import Panel from "./Panel";
import {Dispatch} from "react";
import {Action} from "./reducer/action";
import {ENTITY_GROUPS} from "./constants/entityGroups";
import {Entity} from "enums/entity";
import EmptySearchResults from "components/EmptySearchResults";

interface RolesAndPermissionsViewProps extends BaseProps {
  permissions: Permission[] | null;
  searchResult: Permission[] | null;
  isExpandAll: boolean;
  reducerDispatch: Dispatch<Action>;
}

function RolesAndPermissionsView(props: RolesAndPermissionsViewProps) {
  const {permissions, searchResult, reducerDispatch, isExpandAll} = props;

  if(!permissions) return null;

  if(searchResult && searchResult.length === 0)
    return <EmptySearchResults entity={Entity.RolesAndPermissions} />

  return (
    <Stack direction="column" gap={0.5} mt={12}>
      {ENTITY_GROUPS.map((entityGroup, index: number) => (
        <Panel
          isExpandAll={isExpandAll}
          permissions={searchResult ?? permissions}
          entityGroup={entityGroup}
          key={`roles-and-permission-panel-${index}`}
          reducerDispatch={reducerDispatch}
        />
      ))}
    </Stack>
  )
}

export default RolesAndPermissionsView;