import BulkAssigneeSelectionDrawer from "components/Drawers/BulkAssigneeSelectionDrawer/BulkAssigneeSelectionDrawer";
import {collection, doc} from "firebase/firestore";
import {defaultAssignee, emptyFunction, enCommonLabel, enTaskLabel} from "constants/index";
import {Personnel} from "types/Personnel";
import ConfirmDialog from "components/ConfirmDialog";
import {Stack} from "@mui/material";
import ActionButtons from "components/MilestoneView/ActionButtons";
import {toastProps} from "../../screens/BaseProps";
import {db} from "../../firebase";
import {Dispatch, useEffect, useState} from "react";
import {useComponentToggler} from "hooks/index";
import {Entity} from "enums/entity";
import CheckboxSwitch from "components/CheckboxSwitch";
import LabeledCheckbox from "components/LabeledCheckbox";

export interface BulkActionsProps {
  toastProps: toastProps;
  assetDocPath: string;
  isCheckboxShown: boolean;
  setIsCheckboxShown: Dispatch<boolean>;
  checkedTasks: {taskId: string, milestoneId: string}[];
  setSelectAllTasks: () => void;
  deleteSelected: () => void;
  bulkAssignSelected: (assignee: Personnel) => void;
}

function BulkActions(props: BulkActionsProps) {
  const {checkedTasks, isCheckboxShown, setIsCheckboxShown, assetDocPath} = props;

  const [taskAssignee, setTaskAssignee] = useState<Personnel>(defaultAssignee);
  const [isBulkAssignDrawerOpen, {
    open: openBulkAssignDrawer,
    close: closeBulkAssignDrawer
  }] = useComponentToggler(false);

  const [isDeleteDialogOpen, {open: openDeleteDialog, close: closeDeleteDialog}] = useComponentToggler(false);

  useEffect(() => {
    if (checkedTasks.length === 0) {
      closeBulkAssignDrawer();
      closeDeleteDialog();
    }
  }, [checkedTasks.length]);

  return (
    <>
      <BulkAssigneeSelectionDrawer
        accessListRef={collection(doc(db, assetDocPath), "accessList")}
        drawerLabel={enCommonLabel.assignedTo}
        taskAssignee={taskAssignee}
        isDrawerOpen={isBulkAssignDrawerOpen}
        closeDrawer={closeBulkAssignDrawer}
        setter={(obj: Personnel) => setTaskAssignee(obj)}
        onSaveClick={() => props.bulkAssignSelected(taskAssignee)}
      />
      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        title={enTaskLabel.multipleDeleteConfirmationTitle}
        text={enTaskLabel.multipleDeleteConfirmationText}
        handleClose={closeDeleteDialog}
        handleConfirm={props.deleteSelected}
        id="bulk-delete-drawer"
      />
      <Stack
        direction={{xs: "column", sm: "column", md: "row", lg: "row"}}
        gap={{xs: 0, sm: 0, md: 1, lg: 1}}
        justifyContent="flex-start"
        alignItems="center"
        marginY={1}
        sx={{backgroundColor: "white"}}
      >
        <CheckboxSwitch
          isCheckboxShown={isCheckboxShown}
          toggleCheckbox={() => setIsCheckboxShown(!isCheckboxShown)}
        />
        {isCheckboxShown && checkedTasks.length > 0 && (
          <Stack marginLeft={1} title="Click to unselect all">
            <LabeledCheckbox
              id="select-multiple-checkbox"
              onChange={() => props.setSelectAllTasks()}
              checked={checkedTasks.length > 0}
              label={enCommonLabel.countSelected(checkedTasks.length)}
            />
          </Stack>
        )}
        <ActionButtons
          parentEntity={Entity.Asset}
          atLeastOneSelected={checkedTasks.length > 0 && isCheckboxShown}
          openDialog={openDeleteDialog}
          openDrawer={openBulkAssignDrawer}
          showHidden={false}
          toggleHiddenTasks={{
            toggleLoading: false,
            toggleFunction: emptyFunction
          }}
        />
      </Stack>
    </>
  )
}

export default BulkActions;