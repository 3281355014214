import {User} from "types/User";
import React, {Dispatch} from "react";
import {Button, Checkbox, FormControlLabel, Stack, Typography} from "@mui/material";
import {enCommonButton, enUserLabel} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";

interface BulkActionsProps {
  users: User[] | null;
  checkedUsers: string[];
  setCheckedUsers: Dispatch<string[]>;
  deactivateSelectedUsers: () => void;
}
function BulkActions(props: BulkActionsProps) {
  const {users, checkedUsers, setCheckedUsers, deactivateSelectedUsers} = props;

  const isCheckedBulkActions = checkedUsers.length === (users || []).length && checkedUsers.length > 0;

  if((users || []).length === 0) return null;

  function selectAllUsers(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    if (checked) {
      setCheckedUsers((users || []).map((user) => user["@id"] ?? ""));
      return;
    }

    setCheckedUsers([]);
  }

  return (
    <Stack direction="row" marginY={1}>
      <FormControlLabel
        sx={{alignSelf: "flex-start", ml: 2}}
        control={
          <Checkbox
            id="bulk-select-checkbox"
            checked={isCheckedBulkActions}
            onChange={selectAllUsers}
          />
        }
        label={<strong>{checkedUsers.length === 0 ? enUserLabel.allUsers
          : enUserLabel.xSelected(checkedUsers.length)}</strong>
        }
      />
      {checkedUsers.length > 0 && (
        <Button size="small" color="secondary" onClick={deactivateSelectedUsers}>
          <Stack direction="row" gap={1} alignItems="center">
            <SystemIcons.Close width={20} stroke={theme.palette.error.main}/>
            <Typography variant="h5">{enCommonButton.deactivate}</Typography>
          </Stack>
        </Button>
      )}
    </Stack>
  )
}

export default BulkActions;