import {FormFieldProps} from "./FormFieldProps";
import UnEditedBaseField from "./UnEditedBaseField";
import {enCommonLabel} from "constants/index";
import React, {useEffect, useState} from "react";
import {Checkbox, FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import FormFieldContainer from "./FormFieldContainer";
import {areObjectsEqual} from "../../utility";
import {FormField} from "types/FormField";
import {FormFieldType} from "enums/formFieldType";

interface ListFormFieldProps extends FormFieldProps {
  field: FormField<FormFieldType.List>
}

type Items = {
  item: string,
  checked: boolean
}

function ListFormField(props: ListFormFieldProps) {
  const {isEditMode, field, index, updateLocalField} = props;
  const {value = [], selectMultiple, items} = field;

  const [selected, setSelected] = useState<Items[]>(
    items.map((item: string) => ({
      item,
      checked: Array.isArray(value) && value.includes(item)
    }))
  );

  const fieldId = `list-field-${index}`;

  useEffect(() => {
    const checkedItems = selected.filter(item => item.checked).map(item => item.item);

    if (areObjectsEqual(value, checkedItems)) return;
    updateLocalField(checkedItems, index);
  }, [selected]);

  if (!isEditMode) {
    return (
      <UnEditedBaseField
        fieldId={fieldId}
        defaultValue={enCommonLabel.none}
        {...field}
      />
    )
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    if (!selectMultiple) {
      const selectedValue = parseInt((e.target as HTMLInputElement).value);
      const selectedCopy = selected.map(item => ({...item, checked: false}));
      selectedCopy[selectedValue].checked = true;
      setSelected([...selectedCopy]);
      return;
    }

    const selectedCopy = [...selected];
    selectedCopy[index].checked = e.target.checked;

    setSelected([...selectedCopy]);
  }

  if (!selectMultiple) {
    let defaultValue: undefined | number = undefined;
    selected.forEach((item, index) => {
      if (item.checked) {
        defaultValue = index;
        return;
      }
    });

    return (
      <FormFieldContainer
        fieldId={fieldId}
        {...field}
      >
        <FormControl>
          <RadioGroup defaultValue={defaultValue} onChange={(e) => handleChange(e, 0)}>
            {(items ?? []).map((val: string, index) =>
              <FormControlLabel
                value={index}
                control={<Radio/>}
                label={val}
                id={`radio-button-${val.toLowerCase().split(" ").join("-")}`}
              />
            )}
          </RadioGroup>
        </FormControl>
      </FormFieldContainer>
    )
  }

  return (
    <FormFieldContainer
      fieldId={fieldId}
      {...field}
    >
      <>
        {(items ?? []).map((val: string, index) =>
          <FormControlLabel
            control={
              <Checkbox
                checked={selected[index].checked}
                id={`checkbox-${val.toLowerCase().split(" ").join("-")}`}
                onChange={(e) => handleChange(e, index)}
                inputProps={{'aria-label': 'controlled'}}
                value={val}
              />
            }
            label={val}
          />
        )}
      </>
    </FormFieldContainer>
  )
}

export default ListFormField;