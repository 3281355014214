import {enCommonButton, enCommonLabel} from "constants/index";
import {useNavigate} from "react-router-dom";
import {Box, Button, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";

function PageNotFound() {
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{bgcolor: theme.palette.background.paper, height: '100vh'}}
    >
      <Box p={2}>
        <SystemIcons.EyeOff
          stroke={theme.palette.neutral.light}
          style={{
            width: 64,
            height: 64,
          }}/>
      </Box>
      <Typography variant="h4" color={theme.palette.secondary.main} maxWidth={350} align="center">
        {enCommonLabel.pageNotFoundLine1}
      </Typography>
      <Typography variant="h4" color={theme.palette.secondary.main} maxWidth={350} align="center" mb={4}>
        {enCommonLabel.pageNotFoundLine2}
      </Typography>
      <Button
        variant="contained"
        endIcon={<SystemIcons.ChevronRight stroke={theme.palette.background.paper}/>}
        onClick={() => navigate(`/defaultOrg/explore-organizations`)}
      >
        {enCommonButton.viewAllOrgs}
      </Button>
    </Stack>
  )
}

export default PageNotFound;