import {BaseProps} from "../BaseProps";
import FeatureWrapper from "components/FeatureWrapper";
import {FeatureType} from "enums/index";
import {Content, PageTitle} from "components/index";
import {Stack} from "@mui/material";
import {emptyFunction, enUser, enUserLabel} from "constants/index";
import React, {useEffect} from "react";
import {useComponentToggler, useDocumentOnly} from "hooks/index";
import {User} from "types/User";
import {userPath} from "../utility";
import {PrimaryButton} from "components/Button";
import UsersView from "./UsersView";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {AddMemberDrawer} from "components/Drawers";

function SAPUsers(props: BaseProps) {
  const {selectedOrg, uid, setSelectedOrgId = emptyFunction} = props;
  const userRef = userPath(uid);
  const userDoc = useDocumentOnly<User>(userRef);

  const [isDrawerOpen, {open: openDrawer, close: closeDrawer}] = useComponentToggler(false);

  const usersCount = 0;

  const navigate = useNavigate();
  const {orgId} = useParams();
  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");
    if(!uid) return navigate("/no-permission");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  if (!userDoc) return null;

  return (
    <FeatureWrapper feature={FeatureType.SAP}>
      <Content>
        <AddMemberDrawer
          isAddDrawerOpen={isDrawerOpen}
          onDrawerClose={closeDrawer}
          selectedOrg={selectedOrg!}
          toastProps={props.toastProps!}
          fromSAP={true}
          uid={uid!}
        />
        <Stack mt={2} flex={1}>
          <Stack sx={{display: "flex", justifyContent: "space-between"}} direction="row">
            <PageTitle title={enUser.title} count={usersCount}/>
            <PrimaryButton
              id="create-new-user-button"
              onClickFcn={openDrawer}
              label={enUserLabel.new}
              disabled={false}
            />
          </Stack>
          <br/>
          <UsersView userRef={userRef} {...props} toastProps={props.toastProps!}/>
        </Stack>
      </Content>
    </FeatureWrapper>
  )
}

export default SAPUsers;