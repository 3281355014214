import {Grid, styled} from "@mui/material";
import {BaseProps} from "../../BaseProps";
import TimelineItem from "./TimelineItem";
import EmptyTimelineItem from "./EmptyTimelineItem";
import {taskTimelineScheduleStatus as TimelineStatus} from "enums/taskTimelineScheduleStatus";
import {Task, PartialUserData} from "types/index";
import {defaultTimelinePersonnel, emptyFunction} from "constants/index";
import {milestoneTaskandAssetStatus} from "enums/milestoneTaskandAssetStatus";
import {CollectionReference, Timestamp} from "firebase/firestore";

interface TaskTimelineScheduleProps extends BaseProps {
  task: Task;
  canUpdate: boolean;
  taskCollectionRef: CollectionReference;
  timelineStatus: TimelineStatus;
  taskStatus: milestoneTaskandAssetStatus;
  taskSchedule?: {
    submittedBy?: PartialUserData;
    submittedAt?: Timestamp;
    scheduledBy?: PartialUserData;
    scheduledAt?: Timestamp;
    inProgressBy?: PartialUserData
    inProgressAt?: Timestamp
    approvedBy?: PartialUserData;
    approvedAt?: Timestamp;
    rejectedBy?: PartialUserData;
    rejectedAt?: Timestamp;
    cancelledBy?: PartialUserData;
    cancelledAt?: Timestamp;
  },
  openTaskScheduleDrawer?: () => void;
  openDrawer: () => void;
}

function TimelineSchedule(props: TaskTimelineScheduleProps) {
  const {
    timelineStatus = TimelineStatus.NotScheduled,
    taskSchedule,
    taskStatus,
    canUpdate,
    openDrawer,
  } = props;
  const openTaskScheduleDrawer = (canUpdate ? emptyFunction : props.openTaskScheduleDrawer) ?? emptyFunction;

  function renderScheduleItems() {
    const timelineScheduleItems: JSX.Element[] = [];

    function addItem(type: TimelineStatus, key: number, by?: PartialUserData, at?: Timestamp) {
      const openDrawerCallback = type === TimelineStatus.NotScheduled ? (canUpdate ? openDrawer : emptyFunction): openTaskScheduleDrawer;

      timelineScheduleItems.push(
        <TimelineItem
          type={type}
          key={key}
          by={by ?? defaultTimelinePersonnel.by}
          at={at ?? defaultTimelinePersonnel.at}
          openTaskScheduleDrawer={openDrawerCallback}
          taskStatus={taskStatus}
        />
      );
    }

    function addEmptyItem(key: number) {
      timelineScheduleItems.push(<EmptyGrid key={key} item xs={3}><EmptyTimelineItem/></EmptyGrid>);
    }

    function addScheduleItem() {
      addItem(!!taskSchedule?.scheduledBy ? TimelineStatus.Scheduled : TimelineStatus.NotScheduled, 0, taskSchedule?.scheduledBy!, taskSchedule?.scheduledAt!);
    }

    switch (timelineStatus) {
      case TimelineStatus.NotScheduled:
        addItem(TimelineStatus.NotScheduled, 0);
        addEmptyItem(1);
        addEmptyItem(2);
        addEmptyItem(3);
        break;
      case TimelineStatus.Scheduled:
        addScheduleItem();
        addEmptyItem(1);
        addEmptyItem(2);
        addEmptyItem(3);
        break;
      case TimelineStatus.InProgress:
        addScheduleItem();
        addItem(TimelineStatus.InProgress, 1, taskSchedule?.inProgressBy!, taskSchedule?.inProgressAt!);
        addEmptyItem(2);
        addEmptyItem(3);
        break;
      case TimelineStatus.Submitted:
        addScheduleItem();
        if (!!taskSchedule?.inProgressBy) {
          addItem(TimelineStatus.InProgress, 1, taskSchedule?.inProgressBy!, taskSchedule?.inProgressAt!);
          addItem(TimelineStatus.Submitted, 2, taskSchedule?.submittedBy!, taskSchedule?.submittedAt!);
          addEmptyItem(3);
        } else {
          addItem(TimelineStatus.Submitted, 1, taskSchedule?.submittedBy!, taskSchedule?.submittedAt!);
          addEmptyItem(2);
          addEmptyItem(3);
        }
        break;
      case TimelineStatus.Approved:
        addScheduleItem();
        if (!!taskSchedule?.inProgressBy) {
          addItem(TimelineStatus.InProgress, 1, taskSchedule?.inProgressBy!, taskSchedule?.inProgressAt!);
          addItem(TimelineStatus.Submitted, 2, taskSchedule?.submittedBy!, taskSchedule?.submittedAt!);
          addItem(TimelineStatus.Approved, 3, taskSchedule?.approvedBy!, taskSchedule?.approvedAt!);
        } else {
          addItem(TimelineStatus.Submitted, 1, taskSchedule?.submittedBy!, taskSchedule?.submittedAt!);
          addItem(TimelineStatus.Approved, 2, taskSchedule?.approvedBy!, taskSchedule?.approvedAt!);
          addEmptyItem(3);
        }
        break;
      case TimelineStatus.Rejected:
        addScheduleItem();
        if (!!taskSchedule?.inProgressBy) {
          addItem(TimelineStatus.InProgress, 1, taskSchedule?.inProgressBy!, taskSchedule?.inProgressAt!);
          addItem(TimelineStatus.Submitted, 2, taskSchedule?.submittedBy!, taskSchedule?.submittedAt!);
          addItem(TimelineStatus.Rejected, 3, taskSchedule?.rejectedBy!, taskSchedule?.rejectedAt!);
        } else {
          addItem(TimelineStatus.Submitted, 1, taskSchedule?.submittedBy!, taskSchedule?.submittedAt!);
          addItem(TimelineStatus.Rejected, 2, taskSchedule?.rejectedBy!, taskSchedule?.rejectedAt!);
          addEmptyItem(3);
        }
        break;
      case TimelineStatus.Cancelled:
        addScheduleItem();

        !!taskSchedule?.inProgressBy && addItem(TimelineStatus.InProgress, timelineScheduleItems.length + 1, taskSchedule?.inProgressBy!, taskSchedule?.inProgressAt!);
        !!taskSchedule?.submittedBy && addItem(TimelineStatus.Submitted, timelineScheduleItems.length + 1, taskSchedule?.submittedBy!, taskSchedule?.submittedAt!);
        addItem(TimelineStatus.Cancelled, timelineScheduleItems.length + 1, taskSchedule?.cancelledBy, taskSchedule?.cancelledAt!);
        while (timelineScheduleItems.length < 3) {
          addEmptyItem(timelineScheduleItems.length + 1);
        }

        break;
    }

    return timelineScheduleItems;
  }

  return (
    <Grid container>
      {renderScheduleItems()}
    </Grid>
  )
}

/** start of styles */
const EmptyGrid = styled(Grid)({
  marginTop: "15px"
});

/** end of styles */

export default TimelineSchedule;
