export const fileRequirement = {
  title: "File Requirement",
  subtitle: "",
  description: "",
  status: {},
  label: {
    cannotDeleteWithTemplate: "File Requirement from template cannot be deleted",
    multipleReferenceFiles: "Only 1 Reference File is allowed",
    edit: "Edit File Requirement",
    requirementStatus: "Requirement Status",
    processUploadTitle: "Please wait..",
    processUploadSubtext: "Processing your files. Please don't close the browser.",
    createFileRequirement: "Create New File Requirement",
    editFileRequirement: "Edit File Requirement",
    deleteFileRequirement: "Delete File Requirement",
    deleteConfirmationTitle: "Are you sure you want to delete this requirement?",
    deleteConfirmationText: "This item will be deleted immediately. You can't undo this action.",
    fileDeleteConfirmationTitle: "Are you sure you want to delete this file?",
    fileDeleteConfirmationText: "This file will be deleted immediately. You can't undo this action.",
    massDeleteConfirmationText: "These items will be deleted immediately. You can't undo this action.",
    deleteXRequirements: (num: number) => `Delete ${num} requirement(s)?`,
    deleteXRequirementsSuccess: (num: number) =>`${num} Requirement(s) deleted`,
    deleteSuccess: "Requirement deleted successfully.",
    deleteError: "Encountered an error while deleting this file requirement",
    deleteFileSuccess: "File deleted successfully.",
    deleteFileError: "Encountered an error while deleting this file",
    rejectFileSuccess: "File rejected successfully.",
    approveFileSuccess: "File approved successfully.",
    seeReferenceFile: "See Reference File",
    uploadFiles: "Upload Files",
    emptyTitle: "No Files yet",
    uploadProgress: (num: number) => `Uploading ${num}%...`,
    emptyDescription: "Upload or drag and drop here.",
    cannotDelete: "You don't have enough permission to delete this Form Requirement",
    uploading: (uploading: number, total: number) => `Uploading ${uploading} of ${total} ${total > 1 ? 'items' : 'item'} `,
    uploadComplete: (total: number) => total > 1 ? `${total} uploads complete` : `${total} upload complete`,
    allFiles: "All Files",
    files: "Files",
    hideSuccess: "Requirements hidden",
    unHideSuccess: "Requirements unhidden",
    singleHideSuccess: "File requirement hidden",
    singleUnHideSuccess: "File requirement unhidden",
    download: "Download",
  },
  dialog: {
    title: "Unsaved Changes",
    text: "Dialogs disable all app functionality when they appear, and remain on screen until confirmed, dismissed, or a required action has been taken.",
    confirmButtonText: "Yes, Quit",
  },
  button: {
    downloadAllRequirementFiles: "Download All Requirement Files",
    deleteRequirement: "Delete Requirement",
  },
  errorMessages: {
    invalidMaximum: "Maximum should be greater than or equal to minimum",
    nonWholeNumberInput: "Please enter a whole number",
    securityError: "Encountered an error while submitting this file requirement"
  },
  toastMessages: {
    createFileRequirementSuccess: "File requirement was created successfully",
    updateFileRequirementSuccess: "File requirement was updated successfully"
  }
}
