import {User} from "types/User";
import {BaseProps} from "../BaseProps";
import {useCollection, useComponentToggler} from "hooks/index";
import {collection, doc, DocumentReference, orderBy, QueryConstraint, where} from "firebase/firestore";
import React, {useEffect, useState} from "react";
import {BaseSearchInput, ConfirmDialog, InProgress, SortMenu} from "components/index";
import {Stack} from "@mui/material";
import {defaultUser, enCommonLabel, enUserLabel} from "constants/index";
import FilterMenu from "./FilterMenu";
import {FilterType} from "./FilterType";
import {ActionType, DirectionalOrder, ProcessType, Severity} from "enums/index";
import {submitForm} from "../utility";
import UsersList from "./UsersList";
import {EditUserDrawer, UserOverviewDrawer} from "components/Drawers";
import useDebounce from "hooks/useDebounce";
import {algoliaIndex} from "../../algoliaConfig";
import {statusSubmitHandler} from "../utility/statusSubmitHandler";

interface UsersViewProps extends BaseProps {
  userRef: DocumentReference;
}

function UsersView(props: UsersViewProps) {
  const {userRef, uid, toastProps} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  //!note: replace this with organizationMembersPath(orgId!) if you want to check the list (account should be: jehane.ong@ontel.co)
  const collectionRef = collection(userRef, "users"); //collection(userRef, "users");
  const [users, setConstraints] = useCollection<User>(null, collectionRef, [where("active", "==", true)]);
  const [algoliaUsers, setAlgoliaUsers] = useState<User[] | null>(null);
  const [sortBy, setSortBy] = useState<DirectionalOrder>(DirectionalOrder.asc);
  const [filter, setFilter] = useState<FilterType>(FilterType.all);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [selectedViewUser, setSelectedViewUser] = useState<User | null>(null);
  const [selectedEditUser, setSelectedEditUser] = useState<Partial<User>>(defaultUser);
  const [selectedDeleteUser, setSelectedDeleteUser] = useState<User | null>(null);
  const [isEditDrawerOpen, {open: openEditDrawer, close: closeEditDrawer}] = useComponentToggler(false);
  const [isSearchAndFilterShown, setIsSearchAndFilterShown] = useState<boolean>(false);

  const displayedUsers = algoliaUsers !== null ? algoliaUsers : users;
  const [isDialogOpen, {open: openDialog, close: closeDialog}] = useComponentToggler(false);

  useDebounce(algoliaSearchFcn, 500, [searchQuery, filter]);

  useEffect(() => {
    if (isSearchAndFilterShown || !users) return;
    setIsSearchAndFilterShown(true);
  }, [users]);

  useEffect(() => {
    if (searchQuery !== "") return;

    let constraints: QueryConstraint[] = [];
    if (filter === FilterType.withoutOrg)
      constraints = [where("organizations", "==", [])];
    else
      constraints = [where("active", "==", true)];

    constraints = [...constraints, orderBy("name", sortBy)];

    setConstraints(constraints);
  }, [filter, sortBy, searchQuery]);

  async function algoliaSearchFcn() {
    if (searchQuery === "") {
      setAlgoliaUsers(null);
      return;
    }
    setAlgoliaUsers(null);

    const index = algoliaIndex;
    let algoliaFilters: string[] = [
      `userIds:${uid}`,
      `colPath:users`,
    ];

    const algoliaResult = await index.search(searchQuery, {
      hitsPerPage: 1000,
      facetFilters: algoliaFilters
    });

    // filter results based on filter type
    const filteredResults = (algoliaResult.hits as unknown as User[]).filter(user => {
      const {organizations = [], active} = user;
      if (filter === FilterType.withoutOrg)
        return organizations.length === 0;
      else
        return active;
    });

    setAlgoliaUsers(filteredResults);
  }

  function openEditDrawerWithUser(user: User) {
    setSelectedEditUser(user);
    openEditDrawer();
  }

  function deactivateUser(user: User) {
    setSelectedDeleteUser(user);
    openDialog();
  }

  async function confirmDeactivateUser() {
    if (!selectedDeleteUser) return;
    const docRef = doc(collectionRef, selectedDeleteUser["@id"]!);
    await submitForm(docRef, ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler<User>({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback
      }),
      {active: false}
    );
  }

  function successCallback() {
    setToastMessage(enUserLabel.deactivateSuccess);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    closeDialog();
  }

  function errorCallback(message: any) {
    let toastMessage = enCommonLabel.errorProcess(ProcessType.Delete);
    // if type of message is obj, get first obj value
    if (typeof message === "object" && (typeof Object.values(message)[0] === "string")) {
      toastMessage = Object.values(message)[0] as string;
    }

    setToastMessage(toastMessage);
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
    closeDialog();
  }

  if (!isSearchAndFilterShown)
    return <InProgress/>;

  return (
    <Stack gap={3} flex={1}>
      {selectedViewUser && (
        <UserOverviewDrawer
          user={selectedViewUser}
          onClose={() => setSelectedViewUser(null)}
        />
      )}
      <EditUserDrawer
        isOpen={isEditDrawerOpen}
        user={selectedEditUser!}
        onClose={closeEditDrawer}
        collectionRef={collectionRef}
        toastProps={toastProps!}
      />
      <ConfirmDialog
        isOpen={isDialogOpen}
        handleClose={closeDialog}
        handleConfirm={confirmDeactivateUser}
        title={enUserLabel.deactivateUserName((!selectedDeleteUser ? "" : selectedDeleteUser.name) || "")}
        text={enUserLabel.bulkDeactivateConfirmationText}
        confirmButtonText={enUserLabel.deactivate}
      />
      <Stack direction="row" justifyContent="space-between">
        <BaseSearchInput
          id={"smart-search-input"}
          placeholder={enUserLabel.search}
          searchFn={(searchText: string) => setSearchQuery(searchText)}
        />
        <Stack direction="row" gap={1}>
          <FilterMenu changeFilter={setFilter}/>
          <SortMenu setter={setSortBy}/>
        </Stack>
      </Stack>
      <UsersList
        users={displayedUsers || []}
        fromSearch={algoliaUsers !== null || filter !== FilterType.all}
        viewUserOverview={setSelectedViewUser}
        editUser={openEditDrawerWithUser}
        collectionRef={collectionRef}
        uid={uid!}
        toastProps={toastProps!}
        deactivateUser={deactivateUser}
        isLoading={displayedUsers === null}
      />
    </Stack>
  )
}

export default UsersView;
