import React, {useEffect, useRef, useState} from "react";
import theme from "theme/theme";
import {Hidden, IconButton, Stack} from "@mui/material";
import {useDocument, useRTDBDocField} from "hooks/index";
import {Milestone} from "types/Milestone";
import {LongText, SkeletonItem} from "components/index";
import {db} from "../../firebase";
import {collection, doc} from "firebase/firestore";
import {enMilestoneLabel} from "constants/index";
import {CounterFields, Entity} from "enums/index";
import CardBadge, {OverlayType} from "components/CardBadge";
import {toastProps} from "screens/BaseProps";
import MilestoneCounterLabel from "components/MilestoneView/MilestoneCounterLabel";
import {SwiftIconButton} from "components/Button";
import {SystemIcons} from "assets/icons/system/system.index";
import MilestoneTaskList from "components/MilestoneView/MilestoneTaskList";
import EmptyItem from "./EmptyItem";
import {CreateTaskDrawer, ManageMilestoneDrawer} from "components/Drawers";
import MilestoneMenu from "components/MilestoneView/MilestoneMenu";
import {AccessType} from "types/Permission";
import {getDocPath} from "screens/utility";
import {toProgressCount} from "screens/utility/toProgressCount";
import useTemplateRTDBDocField from "hooks/useTemplateRTDBDocField";
import {TemplateDataType} from "hooks/useCollectionWithTemplate";
import {Asset} from "types/Asset";
import {FiltersState} from "screens/Home/TaskSearchAndFilters";

interface MilestoneItemProps {
  milestonesCollectionRef: string | null
  milestone: TemplateDataType<Milestone>;
  isExpanded: boolean;
  toastProps: toastProps;
  isCheckboxShown: boolean;
  canCreateAccess: boolean;
  checkTask: (taskId: string, milestoneId: string, templateId?: string) => void;
  expandMilestone: () => void;
  displayedTasks: { taskId: string; milestoneId: string }[];
  checkedTasks: { taskId: string; milestoneId: string }[];
  uid: string;
  parentEntity: Entity;
  showDeleteDialog: (milestone: Milestone) => void;
  asset?: Asset | null;
  fromSearch: boolean;
  milestoneAccess: AccessType | null;
  filterState: FiltersState;
}

function MilestoneItem(props: MilestoneItemProps) {
  const {
    milestone,
    isExpanded,
    uid,
    isCheckboxShown,
    canCreateAccess,
    expandMilestone,
    milestonesCollectionRef,
    asset,
    milestoneAccess
  } = props;
  const milestoneItemRef = useRef(null);

  const [milestoneData, setRef] = useDocument<Milestone>(null);
  const counterRef = milestonesCollectionRef ? getDocPath(uid, milestonesCollectionRef, milestone["@id"]) : null;
  const tasksCount = useTemplateRTDBDocField<number>(counterRef, CounterFields.TasksCount) ?? 0;
  const tasksApprovedCount = useRTDBDocField<number>(counterRef, CounterFields.TasksApprovedCount) ?? 0;
  const tasksCancelledCount = useRTDBDocField<number>(counterRef, CounterFields.TasksCancelledCount) ?? 0;

  const [isCreateTaskFormOpen, setIsCreateTaskFormOpen] = useState<boolean>(false);
  // anchor element for the menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMilestoneOpen, setIsMilestoneOpen] = useState(false);

  useEffect(() => {
    if (!milestone["@id"]) return;

    if (milestonesCollectionRef === null) {
      setRef(null)
      return;
    }

    setRef(doc(db, milestonesCollectionRef, milestone["@id"]));
  }, [milestone["@id"]]);

  function onMenuButtonClick(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }

  function onCloseMenu(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    setAnchorEl(null);
  }

  // while still fetching milestone data
  if (milestoneData === null) {
    return <SkeletonItem height={"3rem"}/>
  }

  // if for some reason milestone data is not found
  if (milestoneData === undefined) {
    return null;
  }

  return (
    <div ref={milestoneItemRef}>
      {milestonesCollectionRef && isCreateTaskFormOpen &&
        <CreateTaskDrawer
          parentEntity={props.parentEntity === Entity.Project ? Entity.ProjectLevelTask : Entity.Asset}
          collectionRef={collection(db, milestonesCollectionRef)}
          milestone={milestoneData!}
          onDrawerClose={() => setIsCreateTaskFormOpen(false)}
          isDrawerShown={isCreateTaskFormOpen && !!milestoneData}
          toastProps={props.toastProps!}
          uid={uid}
        />
      }
      <Stack
        direction="column"
        gap={0}
        sx={{
          bgcolor: theme.palette.background.swiftDefault,
          padding: 1,
          borderRadius: 1,
          position: "relative",
          overflow: "inherit",
        }}
      >
        <CardBadge
          id={`milestone-badge-${milestone["@id"]}`}
          text={enMilestoneLabel.buildingMilestone}
          sx={{mt: -1, ml: -1}}
          buildingProgressCount={toProgressCount(milestoneData?.[CounterFields.BuildingProgressCount] ?? 0)}
          toastProps={props.toastProps!}
          process={OverlayType.Building}
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{width: 1, cursor: 'pointer', flex: 1}}
          onClick={expandMilestone}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            flex={1}
            gap={1}
          >
            <LongText variant="h4" sx={{ml: 1}} maxLines={1}>
              {milestone.name}
            </LongText>
            <Stack direction="row" gap={1} width={{lg: "60%"}} alignItems="baseline">
              <MilestoneCounterLabel
                tasksApprovedCount={tasksApprovedCount}
                tasksCount={(tasksCount || 0) - (tasksCancelledCount || 0)}
              />
              {!!milestoneData.description && (
                <Hidden lgDown>
                  <LongText maxLines={1} width={"100%"}>
                    {milestoneData.description}
                  </LongText>
                </Hidden>
              )}
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Hidden smDown>
              <SwiftIconButton
                onClickFcn={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  canCreateAccess && setIsCreateTaskFormOpen(true);
                }}
                id="milestone-item-add-task-button"
                disabled={!canCreateAccess}
                startIcon={(props) => <SystemIcons.PlusCircle stroke={theme.palette.primary.main} {...props} />}
              />
            </Hidden>
            <SwiftIconButton
              onClickFcn={onMenuButtonClick}
              id={`milestoneMenuBtn_${milestone["@id"]}`}
              disabled={Boolean(milestoneData?.building)}
              className="milestone-item-menu-button"
              startIcon={(props) => <SystemIcons.MoreVertical stroke={theme.palette.neutral.dark} {...props} />}
            />
            <Hidden smDown>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  expandMilestone();
                }}
                id="milestone-item-expand-button">
                {isExpanded ?
                  <SystemIcons.ChevronUp stroke={theme.palette.neutral.dark}/>
                  : <SystemIcons.ChevronDown stroke={theme.palette.neutral.dark}/>}
              </IconButton>
            </Hidden>
          </Stack>
        </Stack>
        {(tasksCount + tasksCancelledCount) === 0 && isExpanded && (
          <EmptyItem/>
        )}
        {(tasksCount + tasksCancelledCount) > 0 && (
          <MilestoneTaskList
            asset={asset}
            uid={uid}
            toastProps={props.toastProps!}
            isExpanded={isExpanded}
            isCheckboxShown={isCheckboxShown}
            checkTask={props.checkTask}
            checkedTasks={props.checkedTasks}
            displayedTasks={props.displayedTasks}
            tasksCount={(tasksCount + tasksCancelledCount)}
            milestoneId={milestone["@id"]}
            fromSearch={props.fromSearch}
            filterState={props.filterState}
          />
        )}
      </Stack>
      <MilestoneMenu
        uid={uid!}
        anchorEl={anchorEl}
        id={milestone["@id"]!}
        toastProps={props.toastProps!}
        closeMenu={onCloseMenu}
        openEditMilestone={() => {
          setIsMilestoneOpen(true);
          setAnchorEl(null);
        }}
        openDeleteMilestoneDialog={() => {
          props.showDeleteDialog(milestoneData!);
          setAnchorEl(null);
        }}
        open={Boolean(anchorEl)}
        parentEntity={props.parentEntity === Entity.Project ? Entity.ProjectLevelTask : Entity.Asset}
        milestoneAccess={milestoneAccess}
        filesCount={1}
        readOnly={!!milestoneData.templateId}
      />
      {milestoneData && milestonesCollectionRef && (
        <ManageMilestoneDrawer
          uid={uid}
          collectionRef={collection(db, milestonesCollectionRef)}
          isDrawerOpen={isMilestoneOpen}
          onClose={() => setIsMilestoneOpen(false)}
          milestone={milestoneData!}
          toastProps={props.toastProps!}
          isEdit={true}
        />
      )}
    </div>
  )
}

export default MilestoneItem;