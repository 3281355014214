import {CreatedItemType} from "types/ActivityFeedTypes";
import {Entity} from "enums/entity";
import {enActivityFeedLabel} from "constants/index";

function generateAddedToText(item: CreatedItemType) {
  const {details, entity, orgName, projectName, assetName, taskName} = item;
  const {name, by} = details;

  switch (entity) {
    case Entity.Project:
      return enActivityFeedLabel.addedTo(name, orgName, by.name ?? "")
    case Entity.Asset:
      return enActivityFeedLabel.addedTo(name, projectName, by.name ?? "")
    case Entity.Task:
      return enActivityFeedLabel.addedTo(name, assetName, by.name ?? "")
    case Entity.FileRequirement:
    case Entity.PLFileRequirement:
    case Entity.FormRequirement:
    case Entity.PLFormRequirement:
      return enActivityFeedLabel.addedTo(name, taskName, by.name ?? "")
    default:
      return enActivityFeedLabel.created(entity ?? "", name, by.name ?? "")
  }
}

export default generateAddedToText;
