import {BaseProps} from "../BaseProps";
import {Content, InProgress} from "components/index";
import {Header} from "./Header";
import {Stack} from "@mui/material";
import {enFormsLabel} from "constants/index";
import {Head} from "./Head";
import {FormInputs} from "./FormInputs";
import React, {useEffect, useReducer, useState} from "react";
import {
  areRequiredFieldsFilled,
  DEFAULT_FORM_DATA,
  formDataReducer,
  ReducerActionType
} from "./FormCreationReducer";
import {useAccess, useComponentToggler, useDocument} from "hooks";
import {Form} from "types/Form";
import {ActionType, Entity, Severity, ViewStatus} from "enums/index";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {formsPath, getDocumentStatus, submitForm} from "../utility";
import {doc} from "firebase/firestore";
import DeleteDialog from "components/Dialogs/DeleteDialog";
import {PermissionEntity} from "types/Permission";
import {StringDictionary} from "types/FormBase";
import {statusSubmitHandler} from "../utility/statusSubmitHandler";
import UnsavedChangesDialog from "../../components/Dialogs/UnsavedChangesDialog";
import {OutletContextType} from "components/RestrictedPage";

export function FormEditCreate(props: BaseProps) {
  const {toastProps, uid} = props;
  const {isLoading: hasAccess} = useOutletContext<OutletContextType>();
  const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps!;
  const {orgId, formId} = useParams();

  // Form data
  const [formData, formDataDispatch] = useReducer(formDataReducer, DEFAULT_FORM_DATA);
  const [editLiveData, setFormLiveDataRef] = useDocument<Form>(null);
  const [oldForm, setOldForm] = useState<Form | null>(null);
  const [validationMessages, setValidationMessages] = useState<Record<string, string>>({});
  const navigate = useNavigate();
  const [isDeleteDialogOpen, {open: openDeleteDialog, close: closeDeleteDialog}] = useComponentToggler(false);
  const [deleteFieldIndex, setDeleteFieldIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentDocRef, setCurrentDocRef] = useState<any>(!!formId ? doc(formsPath(orgId!), formId!) : doc(formsPath(orgId!)));
  const [showBackDialog, setShowBackDialog] = useState<boolean>(false);
  const [orgFormsAccess] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.Form,
  });
  const [areFieldsFilled, setAreFieldsFilled] = useState<boolean>(false);

  useEffect(() => {
    setAreFieldsFilled(areRequiredFieldsFilled(formData, editLiveData ?? undefined)
      && (Object.keys(validationMessages).length === 0)
      && !formData.fields.some((item: StringDictionary) => 'errors' in item)
      && !formData.fields.some((item: StringDictionary) => item.title === ""));
  }, [formData, editLiveData, validationMessages]);

  useEffect(() => {
    if (!formId) return;
    setFormLiveDataRef(doc(formsPath(orgId!), formId));
  }, []);

  useEffect(() => {
    if (!editLiveData) return;
    // Fixed bug where formData is reverted to initial state split second after save click
    const liveDataStatus = getDocumentStatus(editLiveData);
    if (liveDataStatus === ViewStatus.Finished) updateAllFormData(editLiveData);
  }, [editLiveData]);

  useEffect(() => {
    // reset on screen load
    formDataDispatch({type: ReducerActionType.Reset})
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (!areFieldsFilled) {
        navigate(-1);
        return;
      }
      e.preventDefault();
      e.returnValue = '';
    };

    // Adding event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [areFieldsFilled]);

  function updateAllFormData(newData: any) {
    formDataDispatch({
      type: ReducerActionType.UpdateAll,
      payload: newData,
    });
  }

  async function submitFormData() {
    const action = formData["@id"] || editLiveData ? ActionType.Update : ActionType.Create;
    const newFormData = Object.assign({}, formData);
    delete newFormData.validationErrors;

    setIsLoading(true);
    await submitForm(currentDocRef, action,
      (status, data, isLastUpdate) => statusSubmitHandler({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback: (message) => errorCallback(message, newFormData)
      }),
      newFormData
    );
  }

  function successCallback() {
    setToastMessage(!!editLiveData ? enFormsLabel.formUpdated : enFormsLabel.formCreated);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    navigate("..");
  }

  function errorCallback(message: any, data: any) {
    setIsLoading(false);
    if (typeof message === 'string') {
      setToastMessage(!!formId ? enFormsLabel.updateError(message as string) : enFormsLabel.createError(message as string));
      setToastSeverity(Severity.Error);
      setIsToastOpen(true);
      setOldForm(structuredClone(data));
      setCurrentDocRef(doc(formsPath(orgId!)));
      return;
    }

    const messages = message ?? {};
    const newFormData = data ?? formData;
    const newErrors: Record<string, string> = {};
    Object.entries(messages).forEach(([key, value]) => {
      if (key === 'fields' && typeof value === 'object') {
        // @ts-ignore
        Object.keys(value).forEach(index => {
          // @ts-ignore
          newFormData['fields'][index]['errors'] = value[index];
        });
      } else {
        // @ts-ignore
        newErrors[key] = value;
      }
    });
    setValidationMessages((prev) => ({...prev, ...newErrors}))
    setOldForm(structuredClone(data));

    updateAllFormData(newFormData);
    setCurrentDocRef(doc(formsPath(orgId!)));
  }

  function changeFormData(value: any, key: any, index?: number, isFormFieldData = false) {
    formDataDispatch({
      type: ReducerActionType.Update,
      isFormFieldData,
      key,
      index,
      payload: value,
    });

    const {[key]: _, ...remainingValidationMessages} = validationMessages;
    setValidationMessages({...remainingValidationMessages});
  }

  function deleteFormField(index: number) {
    if (!!editLiveData) {
      setDeleteFieldIndex(index);
      openDeleteDialog();
      return;
    }
    formDataDispatch({
      type: ReducerActionType.Delete,
      index
    });
  }

  function confirmDeleteFormField(index: number) {
    formDataDispatch({
      type: ReducerActionType.Delete,
      index
    });
    setDeleteFieldIndex(null);
    closeDeleteDialog();
  }

  function onBack() {
    if (!areFieldsFilled) {
      navigate(-1);
      return;
    }

    setShowBackDialog(true);
  }

  return (<>
    <UnsavedChangesDialog
      isOpen={showBackDialog}
      handleClose={() => setShowBackDialog(false)}
      handleConfirm={() => navigate(-1)}
    />
    {
      !!editLiveData && (
        <DeleteDialog
          isOpen={isDeleteDialogOpen}
          title={enFormsLabel.deleteFieldConfirmationTitle}
          text={enFormsLabel.deleteFieldConfirmationText}
          handleClose={closeDeleteDialog}
          handleConfirm={() => confirmDeleteFormField(deleteFieldIndex!)}
        />
      )
    }
    <Header
      onBack={onBack}
      isEdit={!!editLiveData}
      orgFormsAccess={orgFormsAccess!}
      formName={oldForm?.name ?? formData.name}
      docRef={!!editLiveData ? doc(formsPath(orgId!), formId) : currentDocRef}
      updateAllFormData={updateAllFormData}
      formData={editLiveData}
      {...props}
    />
    {(!!formId && !editLiveData || hasAccess) ? <Content><InProgress/></Content> : <Content>
      <Stack gap={4}>
        <Head
          onSubmit={submitFormData}
          onClear={() => (!!formData["@id"] || !!editLiveData) ? updateAllFormData(editLiveData) : formDataDispatch({type: ReducerActionType.Reset})}
          isCreateButtonEnabled={areFieldsFilled}
          isLoading={isLoading}
          {...props}
        />
        <FormInputs
          submitFormData={submitFormData}
          validationMessages={validationMessages}
          changeFormData={changeFormData}
          formData={formData}
          deleteFormField={deleteFormField}
          areFieldsFilled={areFieldsFilled}
          isEdit={!!editLiveData}
          {...props}
        />
      </Stack>
    </Content>}
  </>)
}
