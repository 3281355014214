import {Button, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {emptyFunction, enCommonLabel} from "constants/index";
import React from "react";
import {BulkActionButtonProps} from "components/Button/BulkActionButtons/BulkActionButtonProps";

function ChangeRoleButton(props: BulkActionButtonProps) {
  const {id = "change-role-button", onButtonClick, disabled, sx} = props;

  const icon = disabled ? <SystemIcons.Retake width={16} stroke={theme.palette.text.secondary}/>
    : <SystemIcons.Retake width={16} stroke={theme.palette.primary.main}/>;

  return (
    <Button
      id={id}
      size="small"
      color="secondary"
      onClick={disabled ? emptyFunction : onButtonClick}
      startIcon={icon}
      title={disabled ? enCommonLabel.noPermissionForAction : undefined}
      sx={{
        alignSelf: {xs: "flex-start", sm: "flex-start", md: "center", lg: "center", xl: "center"},
        ...(disabled ? {
          cursor: "default",
          ":hover": {
            backgroundColor: "transparent"
          }
        } : {}),
        ...sx
      }}
    >
      <Typography variant="h5">{enCommonLabel.changeRole}</Typography>
    </Button>
  )
}

export default ChangeRoleButton;