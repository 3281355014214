import {FormFieldProps} from "../BaseField";
import {Stack, Typography} from "@mui/material";
import {enFormsLabel} from "constants/index";
import {FormField} from "types/FormField";
import {FormFieldType} from "enums/formFieldType";

export function DateFormField(props: FormFieldProps) {
  const {formField, isEditable} = props;
  const {includeTime} = formField as FormField<FormFieldType.Date>;

  if (!isEditable && !!includeTime) return (
    <Stack>
      <Typography variant="body">
        {enFormsLabel.time}
      </Typography>
      <Typography variant="h4">
        {enFormsLabel.included}
      </Typography>
    </Stack>
  )
  // Edit mode additional fields
  return <></>
}