import theme from "theme/theme";
import {Box, Paper} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import React from "react";
import {getPreviewFromMime} from "screens/utility/getPreviewFromMime";
import {Previews} from "enums/supportedPreviews";
import PDFImg from "assets/img/PDFImg.png";

interface ChatAttachmentPreviewProps {
  id: string;
  name: string;
  source: string;
  onDelete: (id: string) => void;
}

export default function ChatAttachmentPreview(props: ChatAttachmentPreviewProps) {
  const {id, source, onDelete, name = ""} = props;

  function getChatAttachmentPreview(preview: Previews) {
    switch (preview) {
      case Previews.Image:
        return null;
      case Previews.Video:
        return (<Box sx={{position: "absolute", top:"1.8rem", right: "1.1rem"}}>
          <SystemIcons.Video fill="black" width={24} height={24}/>
        </Box>);
      case Previews.PDF:
        return (<img
          alt="pdfPreview"
          src={PDFImg}
          style={{position: "absolute", top:"0.5rem", right: 6}}
        />);
      case Previews.Zip:
        return <SystemIcons.Zip fill="black" width={56} height={56}/>;
      case Previews.Document:
        return <SystemIcons.Document fill="black" width={56} height={56}/>;
      default:
        return <SystemIcons.FilesFilled fill="black" width={24} height={24}/>;
    }
  }

  return (
    <Paper
      sx={{
        height: 80,
        width: 64,
        borderRadius: 2,
        backgroundColor: theme.palette.background.swiftDefault,
      ...(source.includes("image") ? {backgroundImage: `url(${source})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat"} : {})
      }}>
      <Box
        title={name}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          height: 80,
          width: 64,
          borderRadius: 2,
          backgroundColor: "rgba(0,0,0,0.3);",
        }}>
          {getChatAttachmentPreview(getPreviewFromMime(source))}
        <Box
          sx={{
            position: "absolute",
            cursor: "pointer",
            top: 2,
            right: 2,
            "&:hover": {
              transform: "scale(1.1)"
            }
          }}
          onClick={() => onDelete(id)}
        >
          <SystemIcons.Close
            stroke={theme.palette.common.white}
            strokeWidth={3}
            style={{
              width: 16,
              height: 16,
            }}/>
        </Box>
      </Box>
    </Paper>
  )
}
