import {createContext, Dispatch, ReactElement, useContext} from "react";
import useTimerReducer, {TimerReducerState} from "components/FloatingTimer/useTimerReducer/index";
import {TimerActions} from "components/FloatingTimer/useTimerReducer/actions";

interface ContextWrapperProps {
  children: ReactElement
}

type TimerContextType = [state: TimerReducerState, dispatch: Dispatch<TimerActions>] | null

export const TimerContext = createContext<TimerContextType>(null)

export function TimerContextWrapper(props: ContextWrapperProps) {
  const {children} = props
  const timerReducer = useTimerReducer()
  return <TimerContext.Provider value={timerReducer}>
    {children}
  </TimerContext.Provider>
}

export function useTimerContext(){
  return useContext<TimerContextType>(TimerContext)
}
