import React from "react";
import {Export} from "types/Export";
import {userExportsPath} from "screens/utility";
import ExportItem from "./ExportItem";
import {toastProps} from "../../../BaseProps";
import VirtualList from "components/VirtualList";
import {Entity} from "enums/entity";
import theme from "theme/theme";
import OverflowBox from "components/OverflowBox";
import {orderBy} from "firebase/firestore";
import {DirectionalOrder} from "enums/DirectionalOrder";

interface ExportListProps {
  uid?: string;
  toastProps: toastProps;
}

function ExportList(props: ExportListProps) {
  const {uid} = props;

  return (
    <OverflowBox flex={1}>
      <VirtualList<Export>
        collectionPath={userExportsPath(uid!)}
        entity={Entity.Export}
        hasOrderByTimestamp={true}
        defaultConstraints={[orderBy("timeCreated", "desc")]}
        sortOrder={DirectionalOrder.desc}
        sortByField={"timeCreated"}
        renderElement={item => {
          return (
            <ExportItem
              key={item["@id"]}
              exportId={item["@id"]}
              collectionRefPath={userExportsPath(uid!).path}
              toastProps={props.toastProps!}
            />
          )
        }}
        emptyListProps={{
          entity: Entity.Export,
          logoProperties: {
            stroke: theme.palette.neutral.light,
            fill: "transparent"
          },
          sx:{marginTop: 6}
        }}
        listSx={{gap: 0}}
      />
    </OverflowBox>
  )
}

export default ExportList;