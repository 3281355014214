import {Checkbox, FormControlLabel, Stack} from "@mui/material";
import {enFormsLabel, enTemplateLabel} from "constants/index";
import React from "react";
import {AccessType, PermissionOperationKey} from "types/Permission";
import DeleteButton from "components/Button/BulkActionButtons/DeleteButton";

interface BulkActionsProps {
  orgFormsAccess: AccessType | null;
  formsLength: number;
  selectedFormIds: Set<string>;
  onSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onBulkDeleteClick: () => void;
}

function BulkActions(props: BulkActionsProps) {
  const {formsLength, selectedFormIds, onSelectAllClick, orgFormsAccess, onBulkDeleteClick} = props;

  if (formsLength === 0) return null;

  return (
    <Stack direction="row" gap={2}>
      <FormControlLabel
        sx={{alignSelf: "flex-start", ml: 0.5}}
        control={
          <Checkbox
            id="bulk-select-checkbox"
            checked={selectedFormIds.size === formsLength}
            onChange={onSelectAllClick}
          />
        }
        label={<strong>{!selectedFormIds.size ? enFormsLabel.forms
          : enTemplateLabel.xSelected(selectedFormIds.size)}</strong>
        }
      />
      {selectedFormIds.size > 0 && (
        <DeleteButton
          disabled={Boolean(!orgFormsAccess?.[PermissionOperationKey.Delete])}
          onButtonClick={onBulkDeleteClick}
        />
      )}
    </Stack>
  )
}

export default BulkActions;