import {Box, Button, Checkbox, FormControlLabel, Stack, Typography} from "@mui/material";
import theme from "../theme/theme";
import React, {ChangeEvent, useEffect, useState} from "react";
import {Personnel} from "types/Personnel";
import {CheckBoxOutlineBlank} from "@mui/icons-material";
import {Avatar} from "components";
import {enCommonLabel, enTaskLabel} from "constants/index";

interface AssignedToFieldProps {
  id: string;
  label: string;
  entity: Personnel;
  isCheckboxShown: boolean;
  isPrivate: boolean;
  sx?: any;
  onCheckboxChange?: (e: ChangeEvent, checked: boolean) => void;
  onContentClick: () => void;
}

function AssignableField(props: AssignedToFieldProps) {
  const {id, label, entity, isPrivate, isCheckboxShown, sx} = props;
  const {onContentClick, onCheckboxChange} = props;

  // Entity Related
  const [targetEntityName, setTargetEntityName] = useState<string>();

  useEffect(() => {
    let entityName = entity.name ?? enTaskLabel.unassigned;
    setTargetEntityName(entityName.length > 20 ? entityName.substring(0, 20) + ".." : entityName);
  }, [entity]);

  return (
    <Stack direction="column" sx={sx}>
      <Typography variant="h5">
        {label}
      </Typography>
      <Stack direction="row" justifyContent="space-between" gap={2}>
        <Box
          sx={{
            borderStyle: "solid",
            borderColor: theme.palette.neutral.main,
            borderWidth: "1px",
            padding: 0.5,
            borderRadius: 1,
            flex: 1,
            height: "40px"
          }}
        >
          <Button
            id={id}
            startIcon={
              <Avatar
                {...entity}
                sx={{
                  marginTop: 0,
                  height: "20px",
                  width: "20px",
                  bgcolor: entity.avatarColor ?? theme.palette.background.paper,
                  fontSize: "9px !important",
                }}
                nameComponent={(
                  <Typography
                    paddingLeft={0}
                    id={`${id}-label`}
                    sx={{whiteSpace: "nowrap"}}
                  >
                    {targetEntityName}
                  </Typography>
                )}
              />
            }
            sx={{
              padding: 0.5,
              paddingLeft: 2,
              paddingRight: 2,
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.divider,
            }}
            onClick={onContentClick}
          />
        </Box>
        {
          isCheckboxShown && (
            <FormControlLabel
              label={enCommonLabel.private}
              control={
                <Checkbox
                  id="isPrivateBox"
                  onChange={onCheckboxChange}
                  checked={isPrivate}
                  icon={
                    <CheckBoxOutlineBlank
                      sx={{
                        color: theme.palette.neutral.main,
                        borderWidth: "1px"
                      }}
                    />
                  }
                />
              }
            />
          )
        }
      </Stack>
    </Stack>
  )
}

export default AssignableField;
