import {Typography} from "@mui/material";
import theme from "theme/theme";
import {enTaskLabel} from "constants/index";
import {milestoneTaskandAssetStatus as TaskStatus} from "enums/milestoneTaskandAssetStatus";
import {FileRequirement} from "types/FileRequirement";
import {StatusItem} from "types/StatusItem";
import {useRTDBDocField} from "hooks/index";
import {getDocPath} from "../../utility";
import {CounterFields} from "enums/counterFields";
import {BaseProps} from "../../BaseProps";

interface StatusSubTextProps extends BaseProps {
  selectedStatus: StatusItem | undefined,
  fileRequirement: FileRequirement
  documentPath: string;
}

export default function StatusSubText(props: StatusSubTextProps) {
  const {selectedStatus, fileRequirement, uid, documentPath} = props;
  const filesRejectedCount = useRTDBDocField<number>(getDocPath(uid, documentPath), CounterFields.FilesRejectedCount);

  if (!selectedStatus) return <></>;

  switch (selectedStatus.title) {
    case TaskStatus.Submitted:
      return <Typography variant="body" color={theme.palette.neutral.dark} className="task-status-subtext">
        {enTaskLabel.submittedSubtext}
      </Typography>
    case TaskStatus.Cancelled:
      return <Typography variant="body" color={theme.palette.neutral.dark} className="task-status-subtext">
        Cancelled by {fileRequirement.statusLastChangedBy?.name}
      </Typography>
    case TaskStatus.HasRejection:
      return <Typography variant="body" color={theme.palette.neutral.dark} className="task-status-subtext">
        {filesRejectedCount && `${filesRejectedCount} files rejected`}
      </Typography>
    case TaskStatus.Rejected:
      return <Typography variant="body" color={theme.palette.neutral.dark} className="task-status-subtext">
        Rejected by {fileRequirement.statusLastChangedBy?.name}
      </Typography>
    case TaskStatus.Approved:
      return <Typography variant="body" color={theme.palette.neutral.dark} className="task-status-subtext">
        {enTaskLabel.allFilesApproved}
      </Typography>
  }

  return <></>
}