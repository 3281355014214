import React, {useState} from "react";
import {ActionOption, ConfirmDialog, MenuCard, MenuCardProps} from "components";
import {SystemIcons} from "assets/icons/system/system.index";
import {
  emptyFunction,
  enCommonLabel,
  enTaskButton,
  enTaskLabel,
  previousPage
} from "constants/index";
import {Task} from "types/Task";
import submitForm from "screens/utility/submitForm";
import {ActionType, Entity, ProcessType, Severity, ViewStatus} from "enums/index";
import {useNavigate} from "react-router-dom";
import {CollectionReference, doc} from "firebase/firestore";
import {AccessType, PermissionEntity, PermissionOperationKey} from "types/Permission";
import {toastProps} from "../../BaseProps";
import useTemplateAccess from "hooks/useTemplateAccess";

interface NavBarMenuProps extends MenuCardProps {
  uid: string;
  task: Task,
  taskAccess: AccessType | null;
  taskCollectionRef: CollectionReference;
  toastProps: toastProps;
  parentEntity: Entity;
  downloadTimeData: () => void;
}

function NavBarMenu(props: NavBarMenuProps) {
  const {anchorEl, open, parentEntity, uid, closeMenu, task, taskCollectionRef, toastProps, taskAccess, downloadTimeData} = props;
  const {"@id": taskId = "fillerId"} = task!;
  const {setToastSeverity, setIsToastOpen, setToastMessage} = toastProps!;

  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

  const [exportAccess] = useTemplateAccess({
    uid,
    entity: Entity.Task,
    documentDocId: PermissionEntity.TaskExport,
  });

  const canExport = Boolean(exportAccess?.[PermissionOperationKey.Create]);
  const canDelete = Boolean(!task.templateTaskId) && Boolean(taskAccess?.[PermissionOperationKey.Delete]);

  const navigate = useNavigate();

  function onDeleteCancel(e: React.MouseEvent<HTMLElement> | undefined) {
    setIsDeleteOpen(false);

    if (e !== undefined) closeMenu(e);
  }

  async function onDeleteTaskConfirm() {
    const documentRef = doc(taskCollectionRef, taskId);
    await submitForm(documentRef, ActionType.Delete, statusHandler);
  }

  function statusHandler(status: ViewStatus, data: Task, isLastUpdate: boolean) {
    if(!isLastUpdate) return;
    switch (status) {
      case ViewStatus.Finished:
        setToastSeverity(Severity.Success);
        setToastMessage(enTaskLabel.deleteSuccess);
        setIsToastOpen(true);

        navigate(previousPage);
        break;
      case ViewStatus.Error:
      case ViewStatus.ValidationError:
      case ViewStatus.SecurityError:
        setToastSeverity(Severity.Error);
        setToastMessage(enCommonLabel.errorProcess(ProcessType.Delete));
        setIsToastOpen(true);
        break;
    }
  }

  return (
    <>
      <MenuCard
        anchorEl={anchorEl}
        open={open}
        closeMenu={closeMenu}
      >
        {parentEntity !== Entity.Templates && (
          <ActionOption
            id="task-overview-download-time-data-menu-button"
            Icon={<SystemIcons.DownloadTimeData height={16} width={16}/>}
            label={enTaskButton.downloadTimeData}
            onClick={canExport ? downloadTimeData : emptyFunction}
            disabled={!canExport}
          />
        )}
        <ActionOption
          id="task-overview-delete-menu-button"
          Icon={<SystemIcons.DeleteOutlined height={16} width={16}/>}
          label={enTaskButton.deleteTask}
          labelSx={(theme) => ({color: theme.palette.error.main})}
          onClick={() => canDelete ? setIsDeleteOpen(true) : emptyFunction()}
          disabled={!canDelete}
        />
      </MenuCard>
      <ConfirmDialog
        isOpen={isDeleteOpen}
        handleClose={(e?: React.MouseEvent<HTMLElement>) => onDeleteCancel(e)}
        handleConfirm={onDeleteTaskConfirm}
        confirmButtonId="confirm-delete-button"
        cancelButtonId="cancel-delete-button"
        title={enTaskLabel.deleteConfirmationTitle}
        text={enTaskLabel.deleteConfirmationText}
      />
    </>
  )
}

export default NavBarMenu;