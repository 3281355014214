import {PilledNavigationBar} from "components/index";
import theme from "theme/theme";
import {emptyFunction, enManageTeamCategories} from "constants/index";
import {PillItemProps} from "components/PilledNavBar/PillItem";
import {SidebarIcons} from "assets/icons/menu/menu.index";
import React from "react";

function Header() {

  const pills: PillItemProps[] = [
    {
      id: "category-name",
      label: enManageTeamCategories.title,
      icon:
        <SidebarIcons.Teams fill={theme.palette.neutral.dark} width={16} height={16}/>,
      onClickFcn: emptyFunction
    }
  ];

  return (
    <PilledNavigationBar pills={pills}>
    </PilledNavigationBar>
  )
}
export default Header;
