import {Typography} from "@mui/material";
import theme from "theme/theme";
import {StatusItem} from "types/StatusItem";
import {FormRequirement} from "types/FormRequirement";
import {RequirementStatus} from "enums/requirementStatus";
import {enCommonLabel, enFormRequirementLabel} from "constants/index";
import {getDocPath} from "../../utility";
import {CounterFields} from "enums/counterFields";
import useTemplateRTDBDocField from "hooks/useTemplateRTDBDocField";

interface StatusSubTextProps {
  selectedStatus: StatusItem | undefined,
  formRequirement: FormRequirement,
  uid: string
  docRef: string
}

export default function StatusSubText(props: StatusSubTextProps) {
  const {selectedStatus, formRequirement, uid, docRef} = props;
  const {formLastChangedBy, formTemplate} = formRequirement;
  // const path = formRequirementPath(formRequirement.orgId, formRequirement.projectId, formRequirement.assetId, formRequirement.milestoneId, formRequirement.taskId, formRequirement.id)
  const fieldsFilledCount = useTemplateRTDBDocField<number>(getDocPath(uid, docRef), CounterFields.FieldsFilledCount) ?? 0
  // const fieldsFilledCount = 0;
  const {fields} = formTemplate;

  if (!selectedStatus) return null;

  switch (selectedStatus.title) {
    case RequirementStatus.Submitted:
      return <Typography
        variant="body"
        color={theme.palette.neutral.dark}
        className="requirement-status-subtext"
        title={enFormRequirementLabel.filledFieldsLabel}
      >
        {enFormRequirementLabel.filledFields(fieldsFilledCount, fields.length)}
      </Typography>
    case RequirementStatus.Cancelled:
      return <Typography variant="body" color={theme.palette.neutral.dark} className="requirement-status-subtext">
        {enCommonLabel.cancelledBy(formLastChangedBy?.name ?? "")}
      </Typography>
    case RequirementStatus.HasRejection:
      return <Typography variant="body" color={theme.palette.neutral.dark} className="requirement-status-subtext">
        files rejected
      </Typography>
    case RequirementStatus.Rejected:
      return <Typography variant="body" color={theme.palette.neutral.dark} className="requirement-status-subtext">
        {enCommonLabel.rejectedBy(formLastChangedBy?.name ?? "")}
      </Typography>
    case RequirementStatus.Approved:
      return <Typography variant="body" color={theme.palette.neutral.dark} className="requirement-status-subtext">
        {enFormRequirementLabel.approvedForm}
      </Typography>
    default: // pending & inprogress
      return <Typography
        variant="body"
        color={theme.palette.neutral.dark}
        className="requirement-status-subtext"
        title={enFormRequirementLabel.filledFieldsLabel}
      >
        {enFormRequirementLabel.filledFields(fieldsFilledCount, fields.length)}
      </Typography>
  }
}
