import { Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import theme from "../theme/theme";

interface TextWithHelperProps {
  mainVariant: Variant;
  subVariant: Variant;
  mainText: string;
  subText: string;
  color?: string;
  subTypographyId?: string;
}

const TextWithHelper = ({
  mainVariant,
  subVariant,
  mainText,
  subText,
  color = theme.palette.text.primary,
  subTypographyId,
}: TextWithHelperProps) => {
  return (
    <Typography variant={mainVariant} color={color}>
      {mainText}
      <Typography
        variant={subVariant}
        component="span"
        color={theme.palette.neutral.dark}
        id={subTypographyId}
      >
        {subText}
      </Typography>
    </Typography>
  );
};

export default TextWithHelper;
