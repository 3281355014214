import {Permission, PermissionEntity, PermissionOperationKey} from "types/Permission";
import {PERMISSION_ORDER} from "../constants/permissionOrder";
import {ENTITY_OPERATIONS} from "../constants/entityOperations";

/**
 * Only display the operations defined in PermissionOperationKey
 * @param entity
 * @param permission
 * @returns string[]
 */
export function getAvailableOperations(entity: PermissionEntity, permission: Permission) {
  const entityOperations = ENTITY_OPERATIONS[entity];

  let permissionOperations = Object.keys(permission).filter(key => entityOperations.includes(key as PermissionOperationKey));
  permissionOperations.sort((a, b) => {
    const indexA = PERMISSION_ORDER.indexOf(PermissionOperationKey[a as PermissionOperationKey]);
    const indexB = PERMISSION_ORDER.indexOf(PermissionOperationKey[b as PermissionOperationKey]);
    return indexA - indexB;
  });
  return permissionOperations;
}