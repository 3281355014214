import {Badge, IconButton, Stack} from "@mui/material";
import {SidebarIcons} from "assets/icons";
import {useLocation, useNavigate} from "react-router-dom";
import {User} from "types/User";
import {useContext, useEffect, useState} from "react";
import {Avatar} from "components/index";
import theme from "theme/theme";
import SubmitForm from "screens/utility/submitForm";
import {userPath} from "screens/utility/FirebasePath";
import {ActionType} from "enums/index";
import {emptyFunction} from "constants/index";
import {SelectedOrgContext} from "screens/SelectedOrgContextProvider";
import {useDocument, useRTDBDocField} from "hooks/index";
import useSoundWithSettings from "hooks/useSoundWithSettings";
import messageSfx from "../../sounds/message.mp3";

const hoveredStyle = {
  fill: theme.palette.background.swiftDefault
}

interface SidebarHeadProps {
  uid: string;
  isSuperAdmin?: boolean;
}
function SidebarHead(props: SidebarHeadProps) {
  const {isSuperAdmin = false, uid} = props;
  // always use normal account when displaying sidebar header user info

  const [user] = useDocument<User>(userPath(uid));
  const hasNewInvites = useRTDBDocField<boolean>(userPath(uid).path, "hasNewInvites") ?? false;
  const hasNewChat = useRTDBDocField<boolean>(userPath(uid).path, "hasNewChat") ?? false;

  const [userId, setUserId] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [isHomeHovered, setIsHomeHovered] = useState(false);
  const [isChatHovered, setIsChatHovered] = useState(false);
  const [isFeedHovered, setIsFeedHovered] = useState(false);
  const playMessageSound = useSoundWithSettings(messageSfx)

  const selectedOrgContext = useContext(SelectedOrgContext);
  const {selectedOrg} = selectedOrgContext!;

  const navigate = useNavigate();
  const loc = useLocation();

  //play sound when there is new chat
  useEffect(() => {
    if(hasNewChat)
      playMessageSound()
  }, [hasNewChat]);

  useEffect(() => {
    if (!user) return;

    setFirstName(user.firstName);
    setLastName(user.lastName);
    setUserId(user["@id"] ?? "");
  }, [user]);

  const userEntity = {
    ...user,
    name: firstName + " " + lastName,
    isTeam: false,
    uid: userId,
  }

  function onBellIconClick() {
    if (!user) return;

    SubmitForm(userPath(user["@id"]!), ActionType.Update,
      emptyFunction,
      {hasNewInvites: false}
    );

    navigate(`/${selectedOrg.id}/feed`);
  }

  function onChatIconClick() {
    if (!user) return;

    SubmitForm(userPath(user["@id"]!), ActionType.Update,
        emptyFunction,
        {hasNewChat: false}
    );

    navigate(`/${selectedOrg.id}/chat`);
  }

  function isActive(pathName: string) {
    return loc.pathname.split("/").includes(pathName);
  }

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-evenly"
        paddingY={1.5}
        sx={{backgroundColor: theme.palette.secondary.light}}
      >
        {!isSuperAdmin && (
          <>
            <IconButton
              onClick={() => {
                navigate(`/${selectedOrg.id}/home`);
              }}
              id="sidebarTopHome"
              onMouseEnter={() => setIsHomeHovered(true)}
              onMouseLeave={() => setIsHomeHovered(false)}
            >
              <Badge color="error" variant="dot" invisible={!hasNewInvites}>
                <SidebarIcons.Home {...((isHomeHovered || isActive("home")) ? hoveredStyle : {})}/>
              </Badge>
            </IconButton>
            <IconButton
              onClick={onChatIconClick}
              id="sidebarTopChat"
              onMouseEnter={() => setIsChatHovered(true)}
              onMouseLeave={() => setIsChatHovered(false)}
            >
              <Badge color="error" variant="dot" invisible={!hasNewChat}>
                <SidebarIcons.Chat {...((isChatHovered || isActive("chat")) ? hoveredStyle : {})}/>
              </Badge>
            </IconButton>
            <IconButton
              onClick={onBellIconClick}
              id="sidebarTopFeed"
              onMouseEnter={() => setIsFeedHovered(true)}
              onMouseLeave={() => setIsFeedHovered(false)}
            >
              <Badge color="error" variant="dot" invisible={!hasNewInvites}>
                <SidebarIcons.ActivityFeed {...((isFeedHovered || isActive("feed")) ? hoveredStyle : {})}/>
              </Badge>
            </IconButton>
          </>
        )}

        <IconButton
          id="sidebarTopAvatar"
          sx={{mt: 0}}
          onClick={() => navigate(`/profile-and-settings`)}
        >
          <Avatar
            {...userEntity}
            sx={{borderWidth: 0}}
          />
        </IconButton>
      </Stack>
    </>
  )
}

export default SidebarHead;
