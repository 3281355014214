import {Button, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import {emptyFunction, enCommonButton, enCommonLabel} from "constants/index";
import {BulkActionButtonProps} from "./BulkActionButtonProps";
import theme from "theme/theme";

function DeleteButton(props: BulkActionButtonProps) {
  const {id = "delete-button", onButtonClick, disabled, sx} = props;

  const icon = disabled ? <SystemIcons.DeleteOutlined width={16} stroke={theme.palette.text.secondary}/>
    : <SystemIcons.DeleteOutlined width={16}/>

  return (
    <Button
      id={id}
      size="small"
      color="secondary"
      onClick={disabled ? emptyFunction : onButtonClick}
      startIcon={icon}
      title={disabled ? enCommonLabel.noPermissionForAction : undefined}
      sx={{
        alignSelf: {xs: "flex-start", sm: "flex-start", md: "center", lg: "center", xl: "center"},
        ...(disabled ? {
          cursor: "default",
          ":hover": {
            backgroundColor: "transparent"
          }
        } : {}),
        ...sx
      }}
    >
      <Typography variant="h5">{enCommonButton.delete}</Typography>
    </Button>
  )
}

export default DeleteButton;
