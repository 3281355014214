import {useAccessProps} from "hooks/useAccess";
import {useAccess} from "hooks/index";
import {useEffect, useState} from "react";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../firebase";
import {getParentAccessList} from "hooks/useTemplateAccessList";
import {AccessType} from "types/Permission";

function useTemplateAccess(props: useAccessProps) {
  const {accessListRef} = props

  const [access, , , setAccessListRef] = useAccess({...props, accessListRef: null});
  const [response, setResponse] = useState<AccessType | null>(null);

  useEffect(() => {
    if (access === null || response !== null) return;
    setResponse(access);
  }, [access]);

  useEffect(() => {
    async function getTemplateAccessListRef() {
      if (!accessListRef)
        return

      let found = false;
      let runningPath = accessListRef.path

      while (!found) {
        const accessListDocs = await getDocs(collection(db, runningPath));
        const isEmpty = accessListDocs.empty || accessListDocs.size === 0;

        if (isEmpty) {
          runningPath = getParentAccessList(runningPath) ?? "";
          setAccessListRef(null);
        } else {
          found = true;
          setAccessListRef(collection(db, runningPath))
          break;
        }
      }
    }

    getTemplateAccessListRef();
  }, []);

  return [response]
}

export default useTemplateAccess