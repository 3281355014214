export default async function downloadFile(
  downloadUrl: string,
  fileName: string,
  loadEndCb?: () => void,
  errorCb?: (e: any) => void
) {
  return fetch(downloadUrl)
    .then(async response => {
      if (response.ok) return await response.blob();
      throw new Error(`HTTP error! Status: ${response}`, );
    })
    .then(blob => {
      const link = document.createElement('a');
      const objectUrl = window.URL.createObjectURL(blob);
      link.href = objectUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      // cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(objectUrl);
    })
    .catch(error => {
      errorCb && errorCb(error);
    })
    .finally(() => {
      loadEndCb && loadEndCb();
    })
}


