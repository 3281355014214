import {
  AssignableField,
  AssigneeSelectionDrawer,
  DueDateField,
  Input,
  SimpleFormDrawer
} from "components/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {Personnel} from "types/Personnel";
import {Task} from "types/Task";
import React, {ChangeEvent, useEffect, useState} from "react";
import {CollectionReference, doc, Timestamp} from "firebase/firestore";
import {useUser} from "hooks/index";
import {areObjectsEqual, getTimeSlots, sortObjectParams} from "screens/utility";
import {ActionType, Entity, ProcessType, Severity, ViewStatus} from "enums/index";
import {BaseProps} from "screens/BaseProps";
import {
  defaultAssignee,
  enCommonLabel,
  enTaskLabel,
  timePickerMinutesInterval
} from "constants/index";
import {Box, Typography} from "@mui/material";
import theme from "theme/theme";
import handleEnterKeyPress from "screens/utility/handleEnterKeyPress";
import useTemplateAccessList from "hooks/useTemplateAccessList";
import templateSubmitForm from "screens/utility/templateSubmitForm";

interface TaskScheduleDrawerProps extends BaseProps {
  task: Task;
  isDrawerOpen: boolean;
  onClose: () => void;
  collectionRef: CollectionReference;
  entity?: Entity;
  submitCallback?: () => void;
}

const TIME_SLOTS = getTimeSlots(timePickerMinutesInterval);

function TaskScheduleDrawer(props: TaskScheduleDrawerProps) {
  const {isDrawerOpen, task, uid, collectionRef, submitCallback} = props;
  const {setIsToastOpen, setToastSeverity, setToastMessage} = props.toastProps!;

  const user = useUser(uid);

  const {"@id": id = "fillerId", taskId,  lastChangedBy} = task;
  const actualId = taskId ?? id;

  const initialTask = {
    name: task.name,
    ...(!!task.dueTime && {dueTime: task.dueTime}),
    dueDate: task.dueDate ? task.dueDate.toDate().toDateString() : null,
    dueTimeAllDay: task.dueTimeAllDay ?? false,
    isPrivate: task.isPrivate ?? false,
    assignedTo: sortObjectParams(task.isPrivate ? defaultAssignee : (task.assignedTo ?? defaultAssignee)) as unknown as Personnel,
    approver: sortObjectParams(task.isPrivate ? defaultAssignee : (task.approver ?? defaultAssignee)) as unknown as Personnel,
    observer: sortObjectParams(task.isPrivate ? defaultAssignee : (task.observer ?? defaultAssignee)) as unknown as Personnel,
    timezone: task.timezone
  }

  const [taskName, setTaskName] = useState<string>(initialTask.name);
  const [reasonForChange, setReasonForChange] = useState<string>("");
  const [isPrivate, setIsPrivate] = useState<boolean>(initialTask.isPrivate);
  const [assignedTo, setAssignedTo] = useState<Personnel>(initialTask.assignedTo);
  const [taskApprover, setTaskApprover] = useState<Personnel>(initialTask.approver);
  const [taskObserver, setTaskObserver] = useState<Personnel>(initialTask.observer);
  const [taskDueDate, setTaskDueDate] = useState<Date | null>(task.dueDate ? task.dueDate.toDate() : null);
  const [taskDueTime, setTaskDueTime] = useState<string | null | undefined>(initialTask.dueTime);
  const [isAllDayChecked, setIsAllDayChecked] = useState<boolean>(initialTask.dueTimeAllDay);
  const [timezone, setTimezone] = useState<string | undefined>(initialTask.timezone);

  // Form states
  const [isAssigneeSelectionDrawerOpen, setIsAssigneeSelectionDrawerOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subtitleText, setSubtitleText] = useState<string>("");
  const [isApproverDrawerOpen, setIsApproverDrawerOpen] = useState<boolean>(false);
  const [isObserverDrawerOpen, setIsObserverDrawerOpen] = useState<boolean>(false);

  // Validations
  const [taskNameValidationMessage, setTaskNameValidationMessage] = useState<string>("");
  const [reasonValidationMessage, setReasonValidationMessage] = useState<string>("");
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  // task schedule drawer is only triggered when you edit a task
  // thus, we need to use the accessList of the task
  const taskDocRef = doc(collectionRef, actualId);

  const {accessList: displayedPersonnel, updateColRef} = useTemplateAccessList(null);

  useEffect(() => {
    if (!isDrawerOpen) {
      updateColRef(null);
      return;
    }

    // update accesslist path
    updateColRef(taskDocRef.path + "/" + "accessList");

    setTaskName(task.name);
    setIsPrivate(Boolean(task.isPrivate));
    setAssignedTo(task.assignedTo ?? defaultAssignee);
    setTaskObserver(task.observer ?? defaultAssignee);
    setTaskApprover(task.approver ?? defaultAssignee);
    setTaskDueDate(task.dueDate ? task.dueDate.toDate() : null);
    setTaskDueTime(task.dueTime);
    setIsAllDayChecked(Boolean(task.dueTimeAllDay));
    setTaskNameValidationMessage("");
    setReasonValidationMessage("");
    setReasonForChange("")
  }, [isDrawerOpen]);

  useEffect(() => {
    if (!lastChangedBy) {
      setSubtitleText("");
      return;
    }
    const lastChangedByName = `${lastChangedBy.firstName} ${lastChangedBy.lastName}`;
    setSubtitleText(`${enTaskLabel.lastChanged}: ${lastChangedByName}`);
  }, [lastChangedBy]);

  // VALIDATIONS
  useEffect(() => {
    if (taskName !== "") {
      setTaskNameValidationMessage("");
      return;
    }
    setTaskNameValidationMessage(enTaskLabel.nameRequired);
  }, [taskName]);

  useEffect(() => {
    if (reasonForChange !== "") {
      setReasonValidationMessage("");
      return;
    }
    setReasonValidationMessage(isFormEdited() ? enTaskLabel.reasonRequired : "");
  }, [reasonForChange]);

  useEffect(() => {
    const checkFormValidity = (): boolean =>
      !((isAllDayChecked && !taskDueDate) ||
        (!isAllDayChecked && taskDueDate && !taskDueTime) ||
        taskName.length === 0 || reasonForChange.length === 0 || !isFormEdited());

    setIsFormValid(checkFormValidity());
  }, [taskName, reasonForChange, isFormEdited, isAllDayChecked, taskDueTime, taskDueDate]);

  function handleAssigneeSelectionDrawerOpen() {
    setIsAssigneeSelectionDrawerOpen(true);
  }

  function handleAssigneeSelectionDrawerClose() {
    setIsAssigneeSelectionDrawerOpen(false);
  }

  function handleTaskNameChange(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | React.ClipboardEvent<HTMLDivElement>) {
    const element = e.target as HTMLInputElement;
    setTaskName(element.value);
  }

  function handleAssigneeChange(newAssignee: Personnel) {
    setAssignedTo(newAssignee);
  }

  function handleReasonFieldChange(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | React.ClipboardEvent<HTMLDivElement>) {
    const element = e.target as HTMLInputElement;
    setReasonForChange(element.value);
  }

  function handleDueDateClearButtonClick() {
    setTaskDueDate(null);
    setTaskDueTime(undefined);
  }

  function handleDueDateChange(date: Date | null) {
    setTaskDueDate(date);
    if (!!date && !timezone) setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }

  function handleDueTimeChange(newValue: string | null | undefined) {
    setTaskDueTime(newValue)
  }

  function handleAllDayBoxChange(checked: boolean) {
    setIsAllDayChecked(checked);
  }

  function isFormEdited() {
    const currentTask = {
      name: taskName,
      isPrivate,
      dueDate: taskDueDate ? taskDueDate.toDateString() : null,
      dueTimeAllDay: isAllDayChecked,
      ...(taskDueTime && !isAllDayChecked && {dueTime: taskDueTime}),
      assignedTo: sortObjectParams(isPrivate ? defaultAssignee : assignedTo),
      approver: sortObjectParams(isPrivate ? defaultAssignee : taskApprover),
      observer: sortObjectParams(isPrivate ? defaultAssignee : taskObserver),
      timezone
    };

    return !areObjectsEqual(initialTask, currentTask);
  }

  async function handleSaveButtonClick() {
    setIsLoading(true);
    const taskValues = {
      name: taskName,
      isPrivate,
      dueDate: taskDueDate !== null ? Timestamp.fromDate(taskDueDate) : null,
      dueTime: (isAllDayChecked || !taskDueTime) ? "" : taskDueTime,
      dueTimeAllDay: isAllDayChecked,
      assignedTo: assignedTo,
      approver: taskApprover,
      observer: taskObserver,
      ...(!!timezone && {timezone})
    };
    const lastChangedBy = {
      uid: uid,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      reasonForChange
    };

    await templateSubmitForm(taskDocRef.path, ActionType.Update, statusHandler, {lastChangedBy, ...taskValues}, task);
  }

  function statusHandler(status: ViewStatus, data: Task, isLastUpdate: boolean) {
    if (!isLastUpdate) return;
    setIsLoading(false);

    switch (status) {
      case ViewStatus.Finished:
        setToastMessage(enTaskLabel.updateSuccess);
        setToastSeverity(Severity.Success);
        setIsToastOpen(true);
        onDrawerClose();
        submitCallback && submitCallback();
        return;
      case ViewStatus.ValidationError:
      case ViewStatus.SecurityError:
      case ViewStatus.Error:
        setToastMessage(enCommonLabel.errorProcess(ProcessType.Update));
        setToastSeverity(Severity.Error);
        setIsToastOpen(true);
        onDrawerClose();
        return;
    }
  }

  function onDrawerClose() {
    props.onClose();
  }

  return (
    <>
      <SimpleFormDrawer
        title={enTaskLabel.taskSchedule}
        subtitle={subtitleText}
        isOpen={isDrawerOpen}
        id={"taskScheduleDrawer"}
        buttonId={"taskScheduleSave"}
        icon={<SystemIcons.Calendar width={24} height={24}/>}
        isFormValid={isFormValid}
        isLoading={isLoading}
        onSubmit={handleSaveButtonClick}
        unsavedChanges={isFormEdited()}
        onClose={onDrawerClose}
      >
        <Input
          id="task-schedule-drawer-taskName"
          value={taskName}
          validationMessage={taskNameValidationMessage}
          label={enCommonLabel.name}
          sx={{mb: 1}}
          onChange={handleTaskNameChange}
          onKeyPress={(e) => handleEnterKeyPress(e, isFormValid, isLoading, handleSaveButtonClick)}
        />
        <AssignableField
          id="task-schedule-drawer-assignedto"
          label={enCommonLabel.assignedTo}
          entity={assignedTo}
          isCheckboxShown={true}
          isPrivate={isPrivate}
          sx={{mb: 1}}
          onContentClick={handleAssigneeSelectionDrawerOpen}
          onCheckboxChange={(e, checked) => setIsPrivate(checked)}
        />
        <AssignableField
          id="task-schedule-drawer-approver"
          label={enTaskLabel.approver}
          entity={taskApprover}
          isCheckboxShown={false}
          isPrivate={isPrivate}
          sx={{mb: 1}}
          onContentClick={() => setIsApproverDrawerOpen(true)}
        />
        <AssignableField
          id="task-schedule-drawer-observer"
          label={enTaskLabel.observer}
          entity={taskObserver}
          isCheckboxShown={false}
          isPrivate={isPrivate}
          sx={{mb: 1}}
          onContentClick={() => setIsObserverDrawerOpen(true)}
        />
        <DueDateField
          dueDateId={"task-schedule-drawer-duedate"}
          dueTimeId={"task-schedule-drawer-duetime"}
          isClearButtonVisible={(!!taskDueDate) || (!!taskDueTime)}
          timeSlots={TIME_SLOTS}
          onClearButtonClick={handleDueDateClearButtonClick}
          onDueDateChange={handleDueDateChange}
          onTimeChange={handleDueTimeChange}
          onAllDayChange={handleAllDayBoxChange}
          dueDate={taskDueDate}
          dueTime={taskDueTime}
          isAllDay={isAllDayChecked}
        />
        <Input
          id="task-schedule-drawer-reason"
          value={reasonForChange}
          LabelComponent={<Typography variant="h5">{enTaskLabel.reason} <strong
            style={{color: theme.palette.error.main}}>*</strong></Typography>}
          validationMessage={reasonValidationMessage}
          sx={{mb: 1, height: 128}}
          minRows={5}
          maxRows={5}
          onChange={handleReasonFieldChange}
          multiline
          onKeyPress={(e) => handleEnterKeyPress(e, isFormValid, isLoading, handleSaveButtonClick)}
        />
        <Box flex={1}/>
      </SimpleFormDrawer>
      <AssigneeSelectionDrawer
        drawerLabel={enCommonLabel.assignedTo}
        taskAssignee={assignedTo}
        personnel={displayedPersonnel}
        isDrawerOpen={isAssigneeSelectionDrawerOpen}
        closeDrawer={handleAssigneeSelectionDrawerClose}
        setter={handleAssigneeChange}
      />
      <AssigneeSelectionDrawer
        drawerLabel={enCommonLabel.approver}
        taskAssignee={taskApprover}
        personnel={displayedPersonnel}
        isDrawerOpen={isApproverDrawerOpen}
        closeDrawer={() => setIsApproverDrawerOpen(false)}
        setter={setTaskApprover}
      />
      <AssigneeSelectionDrawer
        drawerLabel={enCommonLabel.observer}
        taskAssignee={taskObserver}
        personnel={displayedPersonnel}
        isDrawerOpen={isObserverDrawerOpen}
        closeDrawer={() => setIsObserverDrawerOpen(false)}
        setter={setTaskObserver}
      />
    </>
  )
}

export default TaskScheduleDrawer;
