import {MenuCard} from "components/index";
import {ACCESS_ROLE_LABEL, enCommonManageAccess, SELECTABLE_ROLES} from "constants/index";
import theme from "theme/theme";
import {Button, ListItemText, MenuItem, Typography} from "@mui/material";
import {AccessRole} from "enums/accessRole";
import React, {useEffect, useState} from "react";
import {SystemIcons} from "assets/icons/system/system.index";
import {Variant} from "@mui/material/styles/createTypography";
import {AccessType} from "types/Permission";

interface AccessRoleMenuProps {
  id?: string;
  currentRole?: AccessRole;
  onUpdateAccessRole: (selected: AccessRole) => void;
  enabled?: boolean;
  displayChevron?: boolean;
  selectSx?: any;
  updateLabelOnSelect?: boolean;
  textLabel?: string;
  textVariant?: Variant;
  access: AccessType;
}

export enum AccessRoleMenuType {
  "Team",
  "Member"
}

function AccessRoleMenu(props: AccessRoleMenuProps) {
  const {currentRole, access, textLabel, textVariant = "body1", onUpdateAccessRole} = props;
  const {id = "access-menu", enabled = true, displayChevron = true, selectSx = {}, updateLabelOnSelect = true} = props;

  const [selected, setSelected] = useState<AccessRole | undefined>(undefined);
  const [isMenuShowing, setIsMenuShowing] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setSelected(currentRole);
  }, [currentRole]);

  useEffect(() => {
    if(selected === currentRole || !selected) return;
    onUpdateAccessRole(selected);
  }, [selected]);

  let selectableItems = {};

  SELECTABLE_ROLES.forEach(role => {
    let itemRole = {
      [role.name]: {
        color: theme.palette.secondary.main,
        title: role.name,
        checked: (currentRole !== undefined && currentRole === role.value),
      }
    };

    selectableItems = {
      ...selectableItems,
      ...itemRole
    };
  });

  function onMenuToggle(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();

    setAnchorEl(anchorEl === null ? e.currentTarget : null);
    setIsMenuShowing(!isMenuShowing);
  }

  function onSelectChange(e: React.MouseEvent<HTMLElement>, obj: any) {
    const accessName = `Manage${obj.value.replace(" ", "")}`;
    // @ts-ignore
    const isDisabled = obj.value === currentRole|| !access[accessName];

    if (isDisabled) return;

    onMenuToggle(e);
    setSelected(obj.value);
  }

  function renderList() {
    const statusItem: any = [];

    Object.entries(SELECTABLE_ROLES).forEach((obj, index) => {
      let objProperties = obj[1];
      const accessName = `Manage${objProperties.name.replace(" ", "")}`;

      // @ts-ignore
      const isDisabled = objProperties.value === currentRole || !access[accessName];
      statusItem.push(
        <MenuItem
          id={`select-access-role-menu-${index}`}
          key={`select-status-${index}`}
          onClick={(e) => onSelectChange(e, obj[1])}
          sx={{padding: 1, margin: 0.5}}
          className={isDisabled ? "disabled-role" : "enabled-role"}
        >
          <ListItemText>
            <Typography
              variant="h5"
              color={isDisabled ? theme.palette.neutral.dark : theme.palette.secondary.main}
            >
              {objProperties.name}
            </Typography>
          </ListItemText>
        </MenuItem>
      )
    });

    return statusItem;
  }

  return (
    <>
      <Button
        id={id}
        disabled={!enabled}
        variant="outlined"
        sx={{
          color: theme.palette.secondary.main,
          backgroundColor: "transparent",
          borderColor: "transparent",
          width: "120px",
          "&:hover": {
            borderColor: "transparent",
            backgroundColor: "transparent"
          },
          ":disabled": {
            borderColor: "transparent",
            backgroundColor: "transparent"
          },
          padding: 0,
          justifyContent: "flex-start",
          ...selectSx
        }}
        endIcon={displayChevron &&
          (isMenuShowing ? <SystemIcons.ChevronUp height={16} width={16} stroke={theme.palette.neutral.medium}/>
          : <SystemIcons.ChevronDown height={16} width={16} stroke={theme.palette.neutral.medium}/>)
        }
        onClick={onMenuToggle}
      >
        <Typography variant={textVariant}>
          {textLabel ? textLabel : !updateLabelOnSelect || !selected ? enCommonManageAccess.chooseARole : ACCESS_ROLE_LABEL[selected]}
        </Typography>
      </Button>
      {
        isMenuShowing &&
          <MenuCard
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              closeMenu={onMenuToggle}
              origin="left"
          >
            {renderList()}
          </MenuCard>
      }
    </>
  )
}

export default AccessRoleMenu;