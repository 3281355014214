import { Entity } from "enums/entity";

/**
 * Identify the entity from the location path
 * @param locationPath
 */
export function identifyEntity(locationPath: string) {
  const parts = locationPath.split('/');
  // Basic checks to ensure path contains enough segments
  if (parts.length < 3) {
    return null; // or throw an error
  }

  // Check each pattern and return the matching entity
  for (const [entity, pattern] of Object.entries(patterns)) {
    if (pattern.test(locationPath)) {
      return entity;
    }
  }

  // No matching pattern found
  return null; // or throw an error
}

// Define patterns to match against the location path
const patterns = {
  [Entity.Organization]: /^\/[^\/]+\/projects$/,
  [Entity.Project]: /^\/[^\/]+\/projects\/[^\/]+\/(overview|project-level-tasks)$/,
  [Entity.Asset]: /^\/[^\/]+\/projects\/[^\/]+\/assets\/[^\/]+$/,
  [Entity.Task]: /^\/[^\/]+\/projects\/[^\/]+\/assets\/[^\/]+\/milestones\/[^\/]+\/tasks\/[^\/]+$/,
  [Entity.FileRequirement]: /^\/[^\/]+\/projects\/[^\/]+\/assets\/[^\/]+\/milestones\/[^\/]+\/tasks\/[^\/]+\/file-requirements\/[^\/]+$/,
  [Entity.FormRequirement]: /^\/[^\/]+\/projects\/[^\/]+\/assets\/[^\/]+\/milestones\/[^\/]+\/tasks\/[^\/]+\/form-requirements\/[^\/]+$/,
  [Entity.PLTask]: /^\/[^\/]+\/projects\/[^\/]+\/project-level-tasks\/milestones\/[^\/]+\/tasks\/[^\/]+$/,
  [Entity.PLFormRequirement]: /^\/[^\/]+\/projects\/[^\/]+\/project-level-tasks\/milestones\/[^\/]+\/tasks\/[^\/]+\/form-requirements\/[^\/]+$/,
  [Entity.PLFileRequirement]: /^\/[^\/]+\/projects\/[^\/]+\/project-level-tasks\/milestones\/[^\/]+\/tasks\/[^\/]+\/file-requirements\/[^\/]+$/
};