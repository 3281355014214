import {User} from "types/User";
import {EmailTooltip, Input, SimpleFormDrawer} from "components/index";
import {Avatar, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import React from "react";
import {ACCESS_ROLE_LABEL, emptyFunction} from "constants/index";
import {PartialUserData} from "types/PartialUserData";

interface UserOverviewDrawerProps {
  user: User;
  onClose: () => void;
}

function UserOverviewDrawer(props: UserOverviewDrawerProps) {
  const {user, onClose} = props;
  const {name, email, organizations = [], initials = "AA", avatarColor = "white"} = user;

  return (
    <SimpleFormDrawer
      title={name ?? ""}
      subtitle={
        <EmailTooltip user={user as PartialUserData}>
          <Typography color={theme.palette.primary.main}>
            {email}
          </Typography>
        </EmailTooltip>
      }
      icon={
        <Stack direction="row" justifyContent="center" mb={1}>
          <Avatar
            sx={(theme) => ({
              backgroundColor: avatarColor ?? theme.palette.common.black,
              width: 40,
              height: 40,
              mt: 0,
            })}
          >
            {initials}
          </Avatar>
        </Stack>
      }
      customIcon={true}
      hiddenFooter={true}
      isFormValid={false}
      isLoading={false}
      isOpen={true}
      onClose={onClose}
    >
      {organizations.map(organization => (
        <Input
          key={`org-input-${organization["id"]}`}
          onChange={emptyFunction}
          value={ACCESS_ROLE_LABEL[organization.accessRole]}
          sx={{mb: 3}}
          disabled={true}
          label={organization.name}
          
        />
      ))}
    </SimpleFormDrawer>
  )
}

export default UserOverviewDrawer;
