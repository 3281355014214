import {Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import theme from "theme/theme";
import {ValidationMessage} from "components/index";
import {LoadingButton} from "@mui/lab";
import {StyledAnchor} from "./index";
import React, {useEffect, useState} from "react";
import {signInWithCustomToken, signOut, updatePassword} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {onChangeInput} from "../utility";
import FormContainer from "components/FormContainer";
import {SwiftLogoOnly} from "assets/icons/SwiftLogo";
import StandardPasswordInput from "components/inputs/StandardPasswordInput";
import {enCommonButton, enCommonLabel} from "constants/index";
import validatePassword from "../utility/validatePassword";
import {auth} from "../../firebase";

interface ChangePasswordFormProps {
  token: string;
  email: string;
}

function ChangePasswordForm(props: ChangePasswordFormProps) {
  const {token, email} = props;

  const navigate = useNavigate();

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordValidationError, setNewPasswordValidationError] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [confirmNewPasswordValidationError, setConfirmNewPasswordValidationError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [countDown, setCountDown] = useState<number>(3);

  const isFormValid = (
    newPasswordValidationError === "" &&
    confirmNewPasswordValidationError === "" &&
    newPassword !== "" &&
    newPassword === confirmNewPassword
  )

  useEffect(() => {
    // New Password Error
    if (newPassword === "") return;
    if (!validatePassword(newPassword)) {
      setNewPasswordValidationError(enCommonLabel.passwordValidationError);
      return;
    }
    setNewPasswordValidationError("");

    // Confirm Password Error
    if (confirmNewPassword === "") return;
    if (newPassword !== confirmNewPassword) {
      setConfirmNewPasswordValidationError(enCommonLabel.passwordDoesNotMatch);
      return;
    }
    setConfirmNewPasswordValidationError("");
  }, [newPassword, confirmNewPassword]);

  async function onResetPasswordClick() {
    if (!isFormValid) return;
    setIsLoading(true);

    signInWithCustomToken(auth, token)
      .then(async (user) => {
        if (!auth.currentUser) {
          setNewPasswordValidationError('Unable to retrieve user info using this password token.');
          return;
        }

        updatePassword(auth.currentUser, newPassword)
          .then((value) => {
            setIsSuccess(true);
            setIsLoading(false);
            let countdown = 3;
            const interval = setInterval(async () => {
              countdown--;
              if (countdown < 1) {
                clearInterval(interval);
                await signOut(auth).then(() => {
                  navigate("/login");
                });
                return;
              }
              setCountDown(countdown)
            }, 1000);
          })
          .catch(e => {
            setIsLoading(false);
            setConfirmNewPasswordValidationError('Unknown error encountered while updating password.');
            console.error('Unknown error encountered while updating password', e);
          });
      })
      .catch(e => {
        setIsLoading(false);
        setConfirmNewPasswordValidationError('Unknown error encountered while updating password.');
        console.error('Unknown error encountered while signing in while before updating user password', e);
      });
  }

  function onKeyPress(e: React.KeyboardEvent) {
    if (e.key === "Enter" && isFormValid && !isLoading) onResetPasswordClick();
  }

  return (
    <FormContainer
      sx={{
        maxWidth: {xs: "unset", sm: "unset", md: "30%", lg: "20%", xl: "20%"},
      }}
    >
      <Stack direction="column" gap={1} justifyContent="center" textAlign="center" py="2%" flex={1}>
        <Box
          sx={{
            alignSelf: "center",
            display: "flex",
            gap: 1
          }}
        >
          <SwiftLogoOnly
            height={70}
            width={70}
            fill={theme.palette.secondary.main}
            stroke={theme.palette.secondary.main}
          />
        </Box>
        <Stack direction="column" marginTop={3}>
          <Typography variant="h2">
            Password Reset
          </Typography>
          <hr
            style={{
              borderRadius: 5,
              width: "25%",
              height: "5px",
              border: "none",
              backgroundColor: theme.palette.secondary.main
            }}
          />
        </Stack>
        <Stack gap={1} marginY={2} alignItems="start">
          <Typography variant="body">
            {enCommonLabel.newPasswordText}: &nbsp;
            <b>{email}</b>
          </Typography>
        </Stack>
        <Stack gap={1.5} width="100%">
          {isSuccess && (
            <Box
              sx={{
                backgroundColor: `${theme.palette.success.light}40`,
                padding: 2,
                borderRadius: 1,
              }}
            >
              <Typography variant="body" color={theme.palette.success.dark}>
                {enCommonLabel.successfulResetText} <b>{countDown}</b> {enCommonLabel.seconds}.
              </Typography>
            </Box>
          )}
          <Stack gap={0.5}>
            <StandardPasswordInput
              id="password-field"
              label={enCommonLabel.newPassword}
              type="password"
              variant="standard"
              onChange={(e) => onChangeInput(e, setNewPassword)}
              value={newPassword}
              onKeyPress={onKeyPress}
            />
            <ValidationMessage
              sx={{
                marginTop: 0.3,
                maxWidth: "268px",
                textAlign: "left",
                lineHeight: "14px"
              }}
              validationMessage={newPasswordValidationError}
            />
          </Stack>
          <Stack gap={0.5}>
            <StandardPasswordInput
              id="confirm-password-field"
              label={enCommonLabel.confirmNewPassword}
              type="password"
              variant="standard"
              onChange={(e) => onChangeInput(e, setConfirmNewPassword)}
              value={confirmNewPassword}
              onKeyPress={onKeyPress}
            />
            <ValidationMessage validationMessage={confirmNewPasswordValidationError}/>
          </Stack>
          <LoadingButton
            variant="contained"
            onClick={onResetPasswordClick}
            disabled={!isFormValid || isSuccess}
            loading={isLoading}
            sx={{marginTop: 3, borderRadius: 5, backgroundColor: theme.palette.secondary.main, padding: 1}}
          >
            {enCommonButton.resetPassword}
          </LoadingButton>
          <Typography variant="body" align="center">
            <StyledAnchor href="login" onClick={async () => {await auth.signOut()}}>
              {enCommonButton.backToLogin}
            </StyledAnchor>
          </Typography>
        </Stack>
      </Stack>
    </FormContainer>
  )
}

export default ChangePasswordForm;