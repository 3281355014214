import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {Typography} from "@mui/material";
import React from "react";

interface CalendarLabelProps {
  text: string,
  sx?: SxProps<Theme>,
}

function CalendarLabel({text, sx}: {text: string, sx?: SxProps<Theme>}) {
  if (!text) return <></>;
  return (
    <Typography
      sx={{
        padding: 0,
        margin: 0,
        fontWeight: 600,
        lineHeight: 1.2,
        fontSize: 10,
        ...sx
      }}>
      {text}
    </Typography>
  );
}

export default CalendarLabel;
