import {defaultSelectedOrg, emptyFunction} from "constants/index";
import {useNavigate} from "react-router";
import {useOutletContext, useParams} from "react-router-dom";
import {BaseProps} from "../BaseProps";
import React, {useEffect, useMemo, useState} from "react";
import {Box, Stack} from "@mui/material";
import {Content, InProgress} from "components/index";
import Head from "./Head";
import {Form} from "types/index";
import {formsPath, organizationPath, sortObjectsBy} from "../utility";
import {useAccess, useCollection, useRTDBDocField} from "hooks/index";
import {CounterFields, DirectionalOrder, Entity} from "enums/index";
import {PermissionEntity} from "types/Permission";
import FormContent from "./FormContent";
import {OutletContextType} from "components/RestrictedPage";

function Forms(props: BaseProps) {
  const {selectedOrg, uid, setSelectedOrgId = emptyFunction} = props;
  const {isLoading} = useOutletContext<OutletContextType>();

  const navigate = useNavigate();
  const {orgId} = useParams();

  const formsCount = useRTDBDocField<number>(organizationPath(orgId!).path, CounterFields.FormsCount) || 0;
  const [sortOrder, setSortOrder] = useState(DirectionalOrder.asc);
  const [forms] = useCollection<Form>(null, !orgId ? null : formsPath(orgId));
  const [algoliaForms, setAlgoliaForms] = useState<Form[] | null>(null);
  const [displayedForms, setDisplayedForms] = useState<Form[]>([]);

  const [orgFormsAccess] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.Form,
  });

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined || orgId === defaultSelectedOrg.id)
      return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  useMemo(() => {
    const currentForms = algoliaForms || forms || [];
    const sortedForms = sortObjectsBy(currentForms, "name", sortOrder);
    setDisplayedForms(sortedForms);
  }, [forms, algoliaForms, sortOrder]);

  if (forms === null || isLoading)
    return <Content><InProgress/></Content>

  return (
    <Content>
      <Stack flex={1} maxWidth="90vw" paddingTop={2}>
        <Stack gap={4} direction="column">
          <Head {...props} formsCount={formsCount} orgFormsAccess={orgFormsAccess!}/>
        </Stack>
        <Box mt={4} height="100%">
          <FormContent
            setAlgoliaForms={setAlgoliaForms}
            displayedForms={displayedForms}
            isEmpty={forms.length === 0}
            fromAlgolia={algoliaForms !== null}
            orgFormsAccess={orgFormsAccess}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            {...props}
          />
        </Box>
      </Stack>
    </Content>
  )
}

export default Forms;
