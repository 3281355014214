import {FormFieldType} from "enums/formFieldType";
import {Form} from "types/Form";
import {defaultUser} from "constants/defaultUser";
import {Timestamp} from "firebase/firestore";
import {areObjectsEqual} from "../utility";
import {FormField} from "types/FormField";

export enum ReducerActionType {
  UpdateAll = "UPDATE_ALL",
  Update = "UPDATE",
  Reset = "RESET",
  Delete = "DELETE"
}

export type ReducerAction = {
  type: ReducerActionType;
  key?: string;
  index?: number;
  payload?: any;
  isFormFieldData?: boolean;
}

export const DEFAULT_TEXT_NUMERIC_FIELD: FormField<FormFieldType.Text> = {
  type: FormFieldType.Text,
  title: "",
  description: "",
  responseIsRequired: false
}

export type ReducerForm = Form;

export const DEFAULT_FORM_DATA: ReducerForm = {
  id: "",
  avatarColor: "#000",
  createdBy: defaultUser,
  timeCreated: Timestamp.now(),
  name: "",
  description: "",
  dataTagsIds: [],
  fields: [
    DEFAULT_TEXT_NUMERIC_FIELD,
  ],
}

export const REQUIRED_FIELDS = {
  Main: ["name"], // Made list of string for uniform approach
  [FormFieldType.Numeric]: ["title"],
  [FormFieldType.Text]: ["title"],
  [FormFieldType.Paragraph]: ["title"],
  [FormFieldType.Date]: ["title"],
  [FormFieldType.List]: ["title"],
}

export function areRequiredFieldsFilled(formData: Form, oldFormData?: Form) {
  if ((!!oldFormData && areObjectsEqual(formData, oldFormData))) return false;
  if ((!!oldFormData && !areObjectsEqual(formData, oldFormData))) return true;

  // @ts-ignore
  const main = REQUIRED_FIELDS.Main.map((key) => !!formData[key].trim());
  let formFields: boolean[] = [];
  formData.fields.forEach((field) => {
    // @ts-ignore
    formFields = formFields.concat(REQUIRED_FIELDS[field.type].map((key) => !!field[key].trim()));
    if (field.type === FormFieldType.List) {
      formFields.push(!!(field.items ?? []).find((item) => !!item.trim()) ?? false);
    }
  });
  return main.concat(formFields).find((bool) => !bool) === undefined;
}

export function formDataReducer(state: Form & ReducerForm, action: ReducerAction) {
  const {type: actionType, key, index, payload, isFormFieldData} = action;

  switch (actionType) {
    case ReducerActionType.UpdateAll:
      return {...payload, fields: [...payload.fields]};

    case ReducerActionType.Update:
      if (!isFormFieldData)
        return {...state, [key!]: payload};

      const formFields = [...state.fields];
      if (key === "type") {
        delete formFields[index!].errors;
      }

      const updatedField = { ...formFields[index!], [key!]: payload };
      if (updatedField?.errors) {
        delete updatedField.errors[key!];

        if (Object.keys(updatedField.errors).length === 0) {
          delete updatedField.errors;
        }
      }

      formFields[index!] = updatedField;

      return {
        ...state,
        fields: formFields
      };

    case ReducerActionType.Reset:
      // Passing default_form_data alone does not do it.
      return {...DEFAULT_FORM_DATA, fields: [DEFAULT_TEXT_NUMERIC_FIELD]};

    case ReducerActionType.Delete:
      const stateFields = state.fields;
      index === 0 ? stateFields.shift() : stateFields.splice(index!, 1);

      return {...state, fields: stateFields};
  }
}