import {ActionType} from "enums/actionType";
import {submitForm} from "./index";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../firebase";
import {TemplateId} from "components/TemplateChecker";

async function templateSubmitForm(docRef: string, actionType: ActionType, statusHandler: (...params: any[]) => void, data: any, document?: any | null,) {
  // Check if the entity already exists, if it does update or delete it immediately no need to check for parents
  // Not necessary but will just avoid parent checking for efficiency
  if (await doesDocExist(docRef)) {
    return await submitForm<any>(doc(db, docRef), actionType, statusHandler, data, document);
  }

  //get parent entity
  const parentPath = getParentPath(docRef);
  const parentExists = await doesDocExist(parentPath);

  //if parent does not exist create a submit form for the parent
  if (!parentExists) {
    // TODO: add status handler for handling parent errors
    //No need to pass data since it will be handled on merging below
    await templateSubmitForm(parentPath, ActionType.Create, () => {
    }, {})
  }

  // if doc exists continue with update
  if (await doesDocExist(docRef)) {
    return await submitForm<any>(doc(db, docRef), actionType, statusHandler, data, document)
  }
  // if doc does not exist force create the document even if it is an update
  else {
    // merge template document and updates to project based collection
    const templateDoc = await getDocFromTemplate(docRef)
    return await submitForm<any>(doc(db, docRef), ActionType.Create, statusHandler, {...templateDoc, ...data})
  }
}

async function getDocFromTemplate(docRef: string) {
  const templatesPath = getTemplateCopyPath(docRef)
  const docSnap = await getDoc(doc(db, templatesPath))

  return docSnap.data()
}

export function getTemplateCopyPath(docRef: string){
  let templateId = localStorage.getItem("templateId");
  const templateIds = localStorage.getItem("templateIds");
  const objectified : Record<string, TemplateId> = templateIds ?JSON.parse(templateIds) : {}

  const projectIdRegex = /projects\/(\w+)/
  const projectIdMatch = docRef.match(projectIdRegex);
  const projectId = projectIdMatch ? projectIdMatch[1] : null

  if (objectified && projectId){
    templateId = objectified[projectId] ? objectified[projectId].id : "";
  }

  if (!templateId) {
    return docRef;
  }

  // remove users/userId path
  // there is no user path for templates
  let templatePath = docRef.replace(/users\/\w+\//, "")

  // return template path replacing projects and assets with templates/templateId
  return templatePath.replace(/projects\/.+\/assets\/\w+/g, `templates/${templateId}`);
}

export async function doesDocExist(docRef: string) {
  const docSnap = await getDoc(doc(db, docRef))
  return docSnap.exists()
}

export function getParentPath(docRef: string){
  return docRef.split("/").slice(0, -2).join("/")
}

export default templateSubmitForm