import {Stack, Switch, Typography} from "@mui/material";
import {enFormsLabel} from "constants/index";
import {BasicAdvancedOptions, FormFieldAdvancedOptionsProps, OptionColumnWrapper} from "./index";
import {ValidationMessage} from "components/index";
import React from "react";
import {FormFieldType} from "enums/formFieldType";
import {FormField} from "types/FormField";

export function ListAdvancedOptions(props: FormFieldAdvancedOptionsProps) {
  const {changeFormData, index, formField} = props;
  const {selectMultiple = false, errors} = formField as FormField<FormFieldType.List>;

  return (
    <>
      <BasicAdvancedOptions {...props}/>
      <OptionColumnWrapper>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">{enFormsLabel.selectMultiple}</Typography>
          <Switch
            onChange={(_, checked) => changeFormData(checked, "selectMultiple", index, true)}
            checked={selectMultiple}
          />
        </Stack>
        <ValidationMessage validationMessage={errors?.selectMultiple}/>
      </OptionColumnWrapper>
    </>
  )
}