import React, {Dispatch, useRef} from "react";
import {Button, Collapse, Stack, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {enFormsButton} from "constants/index";
import {FormFieldType} from "enums/formFieldType";
import {DateAdvancedOptions} from "./DateAdvancedOptions";
import {ParagraphAdvancedOptions} from "./ParagraphAdvancedOptions";
import {ListAdvancedOptions} from "./ListAdvancedOptions";
import {NumericAdvancedOptions} from "./NumericAdvancedOptions";
import {BasicAdvancedOptions} from "./BasicAdvancedOptions";
import {FormField} from "types/FormField";

interface AdvanceOptionsProps {
  isEditable: boolean;
  isAdvancedOptionsOpen: boolean;
  setIsAdvancedOptionsOpen: Dispatch<boolean>;
  changeFormData: (value: any, key: any, index?: number, isFormFieldData?: boolean) => void;
  index: number;
  formField: FormField<FormFieldType>;
}

function AdvanceOptions(props: AdvanceOptionsProps) {
  const {formField, isEditable, isAdvancedOptionsOpen, setIsAdvancedOptionsOpen, changeFormData, index} = props;
  const fieldAdvancedOptions = useRef(<></>);
  const advancedOptionsProps = {
    changeFormData,
    index,
    formField,
  }

  switch (formField.type) {
    case FormFieldType.Date:
      fieldAdvancedOptions.current = <DateAdvancedOptions {...advancedOptionsProps}/>
      break;
    case FormFieldType.Paragraph:
      fieldAdvancedOptions.current = <ParagraphAdvancedOptions {...advancedOptionsProps}/>
      break;
    case FormFieldType.List:
      fieldAdvancedOptions.current = <ListAdvancedOptions {...advancedOptionsProps}/>
      break;
    case FormFieldType.Numeric:
      fieldAdvancedOptions.current = <NumericAdvancedOptions {...advancedOptionsProps}/>
      break;
    default:
      fieldAdvancedOptions.current = <BasicAdvancedOptions {...advancedOptionsProps}/>
      break;
  }

  if (!isEditable) return null;

  return (
    <Stack justifyContent="center" alignItems="center" mt={1.5}>
      <Button
        variant="text"
        endIcon={<SystemIcons.ChevronDown
          stroke={theme.palette.neutral.dark}
          width={16}
          height={16}
          style={{
            transition: "transform ease 0.3s",
            transform: isAdvancedOptionsOpen ? "rotate(180deg)" : "rotate(0deg)"
          }}
        />}
        color="secondary"
        onClick={() => setIsAdvancedOptionsOpen(!isAdvancedOptionsOpen)}
        disableRipple
      >
        <Typography variant="body" color={theme.palette.neutral.dark}>{enFormsButton.advancedOptions}</Typography>
      </Button>
      <Collapse in={isAdvancedOptionsOpen} sx={{width: "100%"}}>
        <Stack sx={{
          borderRadius: 1,
          alignItems: "center",
          width: "100%",
          gap: 1,
        }}>
          {fieldAdvancedOptions.current}
        </Stack>
      </Collapse>
    </Stack>
  )
}

export default AdvanceOptions;