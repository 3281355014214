import React, {useState} from "react";
import theme from "theme/theme";
import {IconButton, Stack, SxProps, Theme, Typography} from "@mui/material";
import {enTaskLabel} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {Entity} from "enums/entity";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {SwiftIconButton} from "components/Button";

interface ListContainerProps {
  access: AccessType | null;
  children: React.ReactNode;
  requirementsCount: number;
  entity: Entity.FileRequirement | Entity.FormRequirement;
  displayCreate?: boolean;
  sxProps?: SxProps<Theme> | undefined
  onCreateButtonClick: () => void;
}

function ListContainer(props: ListContainerProps) {
  const {children, access, requirementsCount, sxProps, entity, displayCreate = true, onCreateButtonClick} = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  function toggleCollapse() {
    setIsExpanded(!isExpanded);
  }
  
  function handleOnCreateButtonClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
    onCreateButtonClick()
  }

  return (
    <Stack
      direction="column"
      gap={1}
      flex={1}
      sx={{
        bgcolor: theme.palette.background.swiftDefault,
        padding: 2,
        borderRadius: 1,
        marginTop: {xs: 1, sm: 1, md: 2, lg: 2},
        ...sxProps
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        onClick={toggleCollapse}
        sx={{width: 1, cursor: 'pointer'}}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <Typography variant="h4" sx={{ml: 1, mr: 1}}>
            {entity === Entity.FileRequirement ? enTaskLabel.files : enTaskLabel.forms}
          </Typography>
          <Typography
            variant="h4"
            color={theme.palette.neutral.dark}
            title={enTaskLabel.requirementCount(entity)}
          >
            {`${requirementsCount > 0 ? requirementsCount : ""}`}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center">
          {displayCreate && (
            <SwiftIconButton
              onClickFcn={handleOnCreateButtonClick}
              disabled={Boolean(!access?.[PermissionOperationKey.Create])}
              startIcon={(props) => <SystemIcons.PlusCircle stroke={theme.palette.primary.main} {...props} />}
            />
          )}
          <IconButton onClick={toggleCollapse}>
            {isExpanded ? <SystemIcons.ChevronUp stroke={theme.palette.neutral.dark}/>
              : <SystemIcons.ChevronDown stroke={theme.palette.neutral.dark}/>}
          </IconButton>
        </Stack>
      </Stack>
      {isExpanded && children}
    </Stack>
  )
}

export default ListContainer;