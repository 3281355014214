import {Box, Checkbox, Grid, Stack} from "@mui/material";
import theme from "theme/theme";
import React, {useEffect, useState} from "react";
import {Avatar, LongText} from "components/index";
import AccessRoleMenu from "components/Drawers/ManageAccessDrawer/Objects/AccessRoleMenu";
import {
  AccessListUserWithPermission
} from "components/Drawers/AddTeamOrPeopleDrawer/Objects/PeopleItem";
import {AccessRole} from "enums/index";

interface TeamItemProps {
  team: AccessListUserWithPermission;
  isSelected: boolean;
  setSelected: (id: string, checked: boolean, role?: AccessRole) => void;
  updateFromBulk?: boolean;
  isLoading?: boolean;
  access: any;
}

function TeamItem(props: TeamItemProps) {
  const {team, isSelected, access} = props;
  const {setSelected, isLoading = false, updateFromBulk = false} = props;

  const {"@id": id = "fillerId", name} = team;

  const [documentLoading, setDocumentLoading] = useState<boolean>(isLoading);
  const [role, setRole] = useState<AccessRole>(AccessRole.User);

  useEffect(() => {
    setDocumentLoading(isLoading);
  }, [isLoading]);


  // update local role if there is an update from bulk selection
  useEffect(() => {
    if (!updateFromBulk || !team.role || documentLoading) return;
    setRole(team.role);
  }, [updateFromBulk, team.role]);

  // if there is an update with the selected role, automatically set the checkbox to checked
  async function onUpdateAccessRole(selectedRole: AccessRole) {
    setSelected(id, role !== selectedRole, selectedRole);
    setRole(selectedRole);
  }

  return (
    <Box sx={{flexGrow: 1}} marginY={0}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        sx={{bgcolor: isSelected ? theme.palette.background.swiftDefault : null, borderRadius: 1}}
        py={0.5}
      >
        <Grid item xs>
          <Checkbox
            id={`${id}-team-item-checkbox`}
            checked={isSelected}
            onChange={() => setSelected(id, !isSelected, role)}
            disabled={documentLoading}
          />
        </Grid>
        <Grid item xs={9} justifyContent="center" alignItems="center">
          <Stack direction="row" gap={1} justifyContent="flex-start" alignItems="center">
            <Avatar
              {...team}
              isTeam={true}
              sx={{mt: "0px", fontSize: "12px"}}
            />
            <Stack direction="column" width={"70%"}>
              <LongText
                id={`${id}-team-item-name`}
                maxLines={1}
                variant="h5">
                {name}
              </LongText>
              <AccessRoleMenu
                id={`${id}-team-item-menu`}
                currentRole={role}
                onUpdateAccessRole={onUpdateAccessRole}
                enabled={!documentLoading}
                access={access}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs />
      </Grid>
    </Box>
  )
}

export default TeamItem;