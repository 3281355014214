import theme from "theme/theme";
import {Stack, Typography} from "@mui/material";
import React from "react";
import {Entity} from "enums/entity";
import EntityIcon from "../GlobalChat/utils/EntityIcon";
import {ENTITY_LABEL} from "language/en/common";

interface TagCounterProps {
  count?: number;
  entity: Entity;
}

function TagCounter(props: TagCounterProps) {
  const {count = 0, entity} = props;

  let entityLabel = ENTITY_LABEL[entity];
  if (entity === Entity.FileRequirement) {
    entityLabel = "File Req.";
  }
  if (entity === Entity.FormRequirement) {
    entityLabel = "Form Req.";
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <EntityIcon
        entity={entity}
        svgProps={{
          height: 16,
          width: 16,
          strokeWidth: 1,
          stroke: theme.palette.neutral.dark,
          fill: theme.palette.background.paper
        }}
      />
      <Typography variant="bodySmall" color="text.secondary" textAlign="center" ml={0.5} mr={1}>
        {entityLabel}:
      </Typography>
      <Stack alignItems="flex-end" flex={1}>
        <b><Typography variant="bodySmall">{count > 1000 ? "999+" : count}</Typography></b>
      </Stack>
    </Stack>
  )
}

export default TagCounter;