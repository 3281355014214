import {Timer as TimerType, Timer} from "types/Timer";
import {TimerState} from "types/Task";
import templateSubmitForm from "screens/utility/templateSubmitForm";
import {ActionType} from "enums/actionType";
import {emptyFunction} from "constants/index";
import {getRoundedTimestamp} from "components/FloatingTimer/RunningTimer";
import {DocumentReference, Timestamp} from "firebase/firestore";
import {submitForm} from "screens/utility";
import {SimpleTimer, SimpleTimerSetter} from "components/FloatingTimer/index";

export async function handleStartTimerClick(location: string | null, timer: Partial<Timer>, setTimer: SimpleTimerSetter, timerDocRef: DocumentReference, geolocation: GeolocationPosition | null) {
  const formData: Partial<TimerType> = {
    timerState: TimerState.Started,
    timerLocation: location ? location : null,
    startDateTime: getRoundedTimestamp(),
    lastTimerStartedDateTime: getRoundedTimestamp(),
    timerLocationLat: geolocation ? geolocation.coords.latitude : null,
    timerLocationLong: geolocation ? geolocation.coords.longitude : null,
    "@id": timerDocRef.id
  }

  setTimer((prev) => ({...prev, ...formData}))
  // dispatch(updateTimer(formData))

  // condition can be removed if backend automatically deletes the timer after being stopped
  if (timer.timerState !== TimerState.Stopped) {
    await templateSubmitForm(timerDocRef.path, ActionType.Update, emptyFunction, formData);
    return;
  }

  await templateSubmitForm(timerDocRef.path, ActionType.Create, emptyFunction, formData);
}

export async function handlePauseClick(location: string | null, timer: SimpleTimer, setTimer: SimpleTimerSetter, timerDocRef: DocumentReference) {

  const formData: Partial<Timer> = {
    timerState: TimerState.Paused,
    pausedDateTime: getRoundedTimestamp(),
    timerLocation: location ? location : null,
  }

  setTimer((prev) => ({
    ...prev,
    timerState: TimerState.Paused,
  }))

  await templateSubmitForm(
    timerDocRef.path,
    ActionType.Update,
    emptyFunction,
    formData
  )
}

export async function handleResumeClick(location: string | null, timer: Partial<Timer>, setTimer: SimpleTimerSetter, timerDocRef: DocumentReference) {
  const formData: Partial<Timer> = {
    timerState: TimerState.Resumed,
    lastTimerStartedDateTime: Timestamp.now(),
    timerLocation: location ? location : null,
  }

  setTimer((prev) => ({...prev, ...formData}))

  await submitForm<Partial<Timer>>(
    timerDocRef,
    ActionType.Update,
    emptyFunction,
    formData
  )
}

export async function handleStopTimerClick(location: string | null, timer: Partial<Timer>, setTimer: SimpleTimerSetter, timerDocRef: DocumentReference) {
  const formData: Partial<TimerType> = {
    timerState: TimerState.Stopped,
    timerLocation: location ? location : null,
    totalLoggedTimeInMs: 0,
    stopDateTime: getRoundedTimestamp(),
    lastTimerStartedDateTime: null
  }

  setTimer((prev) => ({...prev, ...formData}))

  await submitForm<Partial<TimerType>>(
    timerDocRef,
    ActionType.Update,
    emptyFunction,
    formData
  )
}