import {BaseProps} from "../BaseProps";
import {DotIndicator, PilledNavigationBar} from "components/index";
import {PillItemProps} from "components/PilledNavBar/PillItem";
import {SidebarIcons} from "assets/icons";
import theme from "theme/theme";
import {useNavigate, useParams} from "react-router-dom";
import {collection, doc} from "firebase/firestore";
import {useComponentToggler, useDocument, useRTDBDocField} from "hooks/index";
import React, {useEffect, useState} from "react";
import {SystemIcons} from "assets/icons/system/system.index";
import {Hidden, IconButton, Stack} from "@mui/material";
import {enCommonButton, enTaskLabel} from "constants/index";
import {NavBarMenu} from "./Menus";
import {Asset, FileRequirement} from "types/index";
import ChatDrawer from "components/ChatDrawer";
import {Entity, CounterFields} from "enums/index";
import ActivityFeed from "components/ActivityFeed";
import {db} from "../../firebase";
import {ManageFileRequirementDrawer} from "components/Drawers";
import {ChatIconButton, SwiftIconButton} from "components/Button";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {assetPath, getDocPath} from "../utility";
import useTemplateDocument from "hooks/useTemplateDocument";

interface HeaderProps extends BaseProps {
  requirementAccess: AccessType | null;
  downloadAccess: AccessType | null;
  documentRef: string;
}

export default function Header(props: HeaderProps) {
  const {toastProps, documentRef, uid, requirementAccess, downloadAccess} = props;
  const {orgId, projId, assetId, milestoneId, taskId} = useParams();

  const [fileRequirement] = useTemplateDocument<FileRequirement>(doc(db, documentRef));
  const [asset, setAssetRef] = useDocument<Asset>(null);

  // user specific data:
  const userDocPath = getDocPath(uid, documentRef);
  const hasNewActivity = useRTDBDocField<boolean>(userDocPath, "hasNewActivity") ?? false;
  const hasNewChat = useRTDBDocField<boolean>(userDocPath, "hasNewChat") ?? false;
  const chatEmpty = useRTDBDocField<boolean>(documentRef, "chatEmpty") ?? true;
  const filesCount = useRTDBDocField<number>(userDocPath, CounterFields.FilesCount) ?? 0;

  const {taskName, name} = fileRequirement ?? {};

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);
  const [isMenuShowing, setIsMenuShowing] = useState<boolean>(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [isChatDrawerOpen, {open: openChatDrawer, close: closeChatDrawer}] = useComponentToggler(false);
  const [isActivityFeedOpen, {open: openActivityFeed, close: closeActivityFeed}] = useComponentToggler(false);

  useEffect(() => {
    if (!assetId) return;
    setAssetRef(assetPath(orgId!, projId!, assetId!));
  }, [assetId]);

  const nav = useNavigate();

  const projectsPath = `/${orgId!}/projects/${projId!}/${assetId ? `assets/${assetId!}` : `project-level-tasks`}`;
  let NAVBAR_PILLS: PillItemProps[] = [
    {
      id: "header-org-name",
      label: "...",
      icon: <SidebarIcons.Folder fill={theme.palette.neutral.dark} width={16} height={16}/>,
      onClickFcn: () => nav(`${projectsPath}`),
      hoverLabel: `${asset?.orgName} / ${asset?.projectName}` // hoverLabel: `${orgName} / ${projectName}`,
    },
  ];

  if (assetId!) {
    NAVBAR_PILLS = [
      ...NAVBAR_PILLS,
      {
        id: "header-asset-name",
        label: asset?.name ?? "Asset Name",
        icon: <SidebarIcons.Layers width={16} height={16} color={theme.palette.neutral.dark}/>,
        onClickFcn: () => nav(`/${orgId!}/projects/${projId!}/assets/${assetId!}`),
        hoverLabel: asset?.name,
      },
    ]
  }

  const taskPath = `${projectsPath}/milestones/${milestoneId!}/tasks/${taskId}`;

  NAVBAR_PILLS = [
    ...NAVBAR_PILLS,
    {
      id: "header-task-name",
      label: taskName ?? "Task Name",
      icon: <SystemIcons.Tasks width={16} height={16} stroke={theme.palette.neutral.dark}/>,
      onClickFcn: () => nav(taskPath),
      hoverLabel: taskName
    },
    {
      id: "header-requirement-name",
      label: name ?? "Requirement Name",
      icon: <SystemIcons.Requirement width={16} height={16} stroke={theme.palette.neutral.dark}/>,
      onClickFcn: () => {
      },
      hoverLabel: name
    }
  ]

  function openMoreMenu(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    setMenuAnchor(e.currentTarget);
    setIsMenuShowing(true);
  }

  function closeMoreMenu() {
    setMenuAnchor(null);
    setIsMenuShowing(false);
  }

  function openEditDrawer() {
    setIsEditDrawerOpen(true);
  }

  function closeEditDrawer() {
    setIsEditDrawerOpen(false);
  }

  function onChatDrawerClick() {
    openChatDrawer();
  }

  return (
    <>
      <ActivityFeed
        uid={uid}
        onClose={closeActivityFeed}
        isOpen={isActivityFeedOpen}
        collectionReference={collection(doc(db, documentRef), "activityFeed")}
        entity={Entity.FileRequirement}
      />
      <ChatDrawer
        uid={props.uid}
        isOpen={isChatDrawerOpen}
        entity={Entity.FileRequirement}
        pathPair={[
          `${asset?.projectName} / ${asset?.name ? `${asset.name} /` : ""}${taskName}`,
          fileRequirement?.name ?? ""
        ]}
        onClose={closeChatDrawer}
        colRef={collection(doc(db, documentRef), "chatMessages")}
        toastProps={toastProps!}
      />
      {fileRequirement && (
        <ManageFileRequirementDrawer
          uid={props.uid}
          isOpen={isEditDrawerOpen}
          onClose={closeEditDrawer}
          fileRequirement={fileRequirement}
          toastProps={toastProps!}
          collectionRef={collection(db, doc(db, documentRef).parent.path!)}
        />
      )}
      <PilledNavigationBar pills={NAVBAR_PILLS}>
        <Stack direction="row" gap={0.5} alignItems={"flex-end"}>
          <Hidden mdDown>
            <SwiftIconButton
              id="requirement-nav-bar-edit-task-button"
              onClickFcn={openEditDrawer}
              disabled={Boolean(!requirementAccess?.[PermissionOperationKey.Update])}
              startIcon={SystemIcons.Edit}
            />
            <IconButton
              id="requirement-nav-bar-activity-feed-button"
              title={enTaskLabel.activityFeed}
              onClick={openActivityFeed}
            >
              <DotIndicator isVisible={Boolean(hasNewActivity)}>
                <SystemIcons.Notifications/>
              </DotIndicator>
            </IconButton>
            <ChatIconButton
              id="requirement-nav-bar-chat-button"
              entity={Entity.FileRequirement}
              withNewMessage={hasNewChat}
              chatEmpty={chatEmpty}
              onClickFcn={onChatDrawerClick}
            />
          </Hidden>
          <IconButton
            id="requirement-nav-bar-more-button"
            title={enCommonButton.more}
            onClick={openMoreMenu}
          >
            <SystemIcons.MoreVertical fill={theme.palette.text.secondary}/>
          </IconButton>
          {isMenuShowing && (
            <NavBarMenu
              filesCount={filesCount || 0}
              uid={props.uid!}
              downloadAccess={downloadAccess}
              requirementAccess={requirementAccess}
              anchorEl={menuAnchor}
              closeMenu={closeMoreMenu}
              toastProps={toastProps!}
              fileRequirement={fileRequirement}
              open={isMenuShowing && Boolean(menuAnchor)}
              documentRef={doc(db, documentRef)}
            />
          )}
        </Stack>
      </PilledNavigationBar>
    </>
  )
}
