import {FileUploadReducerState} from "./FileUploadReducerState";
import {UploadAction} from "./UploadAction";
import {UploadActionType} from "./UploadActionType";

function reducer(state: FileUploadReducerState, action: UploadAction) {
  switch (action.type) {
    case UploadActionType.addToProcessingFileList:
      const {payload: toBeAddedFile} = action;
      const processingFilesCopy = structuredClone(state.processingFiles.filter(file => !state.removedFileIds.some(p => p === file.id)));

      // check if the file is already in the list
      const fileExist = processingFilesCopy.filter(file => file.id === toBeAddedFile.id);
      if (fileExist.length > 0) {

        return {
          ...state,
          processingFiles: [...processingFilesCopy.map(file => file.id === toBeAddedFile.id ? {...toBeAddedFile, index: file.index} : file)],
        }
      }

      const currentIndex = state.lastProcessingIndex + 1;
      const newProcessingFiles = [...processingFilesCopy, {...toBeAddedFile, index: currentIndex}];
      return {
        ...state,
        processingFiles: newProcessingFiles,
        isPanelOpen: newProcessingFiles.length > 0,
        lastProcessingIndex: currentIndex,
      };

    case UploadActionType.addToErrorFileList:
      const {payload: errorFiles} = action;
      const errorFilesCopy = structuredClone(state.errorFiles.filter(file => file.id !== errorFiles.id && !state.removedFileIds.some(p => p === file.id)));
      const newErrorFiles = [...errorFilesCopy, errorFiles];

      // also remove from processingFiles list
      const filteredProcessingFiles = structuredClone(state.processingFiles.filter(file => file.id !== errorFiles.id && !state.removedFileIds.some(p => p === file.id)));
      return {
        ...state,
        processingFiles: filteredProcessingFiles,
        errorFiles: newErrorFiles,
      }

    case UploadActionType.closePanel:
      return {
        ...state,
        isPanelOpen: false,
      }

    case UploadActionType.openPanel:
      return {
        ...state,
        isPanelOpen: true
      }

    case UploadActionType.addToProcessingCount:
      const {payload: processingCount} = action;
      return {
        ...state,
        processingCount: state.processingCount + processingCount,
      }

    case UploadActionType.addToCompletedCount:
      const {payload: completedCount} = action;
      return {
        ...state,
        completedCount: state.completedCount + completedCount,
      }

    case UploadActionType.addToBatch:
      const {payload: batch} = action;
      const currentId = state.lastBatchId + 1;
      return {
        ...state,
        processingCount: state.processingCount + batch.length,
        uploadBatch: [...state.uploadBatch, {id: currentId, files: structuredClone(batch)}],
        lastBatchId: currentId,
      }

    case UploadActionType.removeBatch:
      const {batchId, id, queueId} = action.payload;
      const filteredBatch = state.uploadBatch.filter(batch => batch.id !== batchId);
      const completedFiles = [...state.completedFiles, {id, queueId}];

      return {
        ...state,
        uploadBatch: filteredBatch,
        isWorking: false,
        completedFiles: completedFiles,
      }

    case UploadActionType.addToProcessingBatchId:
      const {payload: processingBatchId} = action;
      return {
        ...state,
        processingBatchId: [...state.processingBatchId, processingBatchId],
        isWorking: true,
      }

    case UploadActionType.addToRemovedFiles:
      const {payload: removedFileId} = action;

      return {
        ...state,
        removedFileIds: [...state.removedFileIds, removedFileId],
        completedCount: state.completedCount + 1,
        processingCount: state.processingCount - 1,
      }

      case UploadActionType.addToDeletedIds:
        const {payload: deletedFileId} = action;
        const currentDeleted = state.deletedIds;
        currentDeleted.add(deletedFileId);

        return {
          ...state,
          deletedFileIds: currentDeleted,
        }
    default:
      return state;
  }
}


export function generateUniqueId() {
  const now = new Date();
  const date = new Date(now).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const time = new Date(now).toLocaleString("en-us", {
    hour: "2-digit",
    minute: "2-digit",
    second: "numeric",
    hour12: true,
  });


  return (date + time).split(' ').join('');
}

export default reducer;
