import React from "react";
import {Box, Stack} from "@mui/material";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {TeamCategory} from "types/TeamCategory";
import {LongText} from "components/index";
import {ActionType} from "enums/actionType";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {SwiftIconButton} from "components/Button";
import {enCommonLabel} from "constants/index";

interface TeamCategoryItemProps {
  categoryAccess: AccessType | null;
  teamCategory: TeamCategory
  onItemActionClick: (action: ActionType, teamCategory: TeamCategory) => void;
}

function TeamCategoryItem(props: TeamCategoryItemProps) {
  const {teamCategory, categoryAccess, onItemActionClick} = props;

  const {name = "", description = ""} = teamCategory;

  function onDeleteClick() {
    onItemActionClick(ActionType.Delete, teamCategory);
  }

  function onEditClick() {
    onItemActionClick(ActionType.Update, teamCategory);
  }

  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: "4px",
        px: 2,
        py: 1
      })}
      className={`team-category-item`}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" flexGrow={0}>
        <Stack direction="column" width="85%" justifyContent="flex-start" gap={0}>
          <LongText variant="h4" className="team-category-item-name">
            {name}
          </LongText>
          <LongText
            variant="body"
            align="left"
            color={theme.palette.text.secondary}
            className={"team-category-item-description"}
            maxWidth={"300ch"}
          >
            {!!description ? description : enCommonLabel.noDescription}
          </LongText>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <SwiftIconButton
            className="team-category-edit-button"
            onClickFcn={onEditClick}
            disabled={Boolean(!categoryAccess?.[PermissionOperationKey.Update])}
            startIcon={SystemIcons.Edit}
          />
          <SwiftIconButton
            className="team-category-delete-button"
            onClickFcn={onDeleteClick}
            disabled={Boolean(!categoryAccess?.[PermissionOperationKey.Delete])}
            startIcon={SystemIcons.DeleteOutlined}
          />
        </Stack>
      </Stack>
    </Box>
  )
}

export default TeamCategoryItem;