import {Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {enCommonLabel} from "constants/index";
import React from "react";
import {FormFieldType} from "enums/formFieldType";
import {formatDateTimeFirestoreDate} from "../../utility";
import {CollapsableText} from "components/index";

interface UnEditedBaseFieldProps {
  type?: FormFieldType;
  fieldId: string;
  title: string;
  responseIsRequired?: boolean;
  value?: any;
  defaultValue: string,
  selectMultiple?: boolean;
}

function UnEditedBaseField(props: UnEditedBaseFieldProps) {
  const {fieldId, type = FormFieldType.Text, title, responseIsRequired, value, defaultValue, selectMultiple} = props;

  function getDisplayValue() {
    if(typeof value === "undefined") return defaultValue;

    const isObject = typeof value === 'object';

    switch (type) {
      case FormFieldType.Text:
      case FormFieldType.Numeric:
      case FormFieldType.Paragraph:
        return isObject ? null: value;
      case FormFieldType.Date:
        if(!isObject) return;
        const { date = null, time = null } = value;
        if (!date) return defaultValue;
        const { month, day, year } = formatDateTimeFirestoreDate(date);
        return `${month} ${day} ${year} ${time || ''}`.trim();
      case FormFieldType.List:
        if(!isObject) return;
        if (selectMultiple || Array.isArray(value)) return value.join(', ');
        return;
      default:
        return;
    }
  }


  return (
    <Stack direction="column" paddingLeft={1} id={fieldId}>
      <Stack direction="row">
        <Typography color={theme.palette.neutral.dark}>
          {title} {!responseIsRequired ? ` (${enCommonLabel.optional})` : null}
        </Typography>
      </Stack>
      <Stack direction="row">
        {type === FormFieldType.Paragraph || type === FormFieldType.Text ? (
            <Typography color={theme.palette.secondary.main} variant="h5">
              <CollapsableText text={getDisplayValue()} id="text-description" overflowWrap={"anywhere"} fontSize = "16px"/>
            </Typography>
        ) : (
            <Typography color={theme.palette.secondary.main} variant="h4">
              {getDisplayValue()}
            </Typography>
        )}
      </Stack>
    </Stack>
  )
}

export default UnEditedBaseField;