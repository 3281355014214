import {BaseProps} from "../BaseProps";
import {Form} from "types/Form";
import {EmptyList, SortMenu} from "components/index";
import {Entity} from "enums/entity";
import theme from "theme/theme";
import React, {Dispatch} from "react";
import {FormsList} from "./FormsList";
import {AccessType} from "types/Permission";
import {Stack} from "@mui/material";
import {DirectionalOrder} from "enums/DirectionalOrder";
import SmartSearchInput from "components/inputs/SmartSearchInput";
import {formsPath} from "../utility";
import {useParams} from "react-router-dom";

interface FormContentProps extends BaseProps {
  isEmpty: boolean;
  displayedForms: Form[];
  fromAlgolia: boolean;
  orgFormsAccess: AccessType | null;
  sortOrder: DirectionalOrder;
  setSortOrder: Dispatch<DirectionalOrder>;
  setAlgoliaForms: Dispatch<Form[] | null>;
}

function FormContent(props: FormContentProps) {
  const {sortOrder, orgFormsAccess, setSortOrder, setAlgoliaForms} = props;
  const {orgId} = useParams();

  const algoliaColPathParams = !orgId ? [] : formsPath(orgId).path.split("/").slice(2);
  const algoliaColPath = algoliaColPathParams.join("/");

  if (props.isEmpty)
    return (
      <EmptyList
        entity={Entity.Form}
        logoProperties={{
          fill: theme.palette.neutral.medium,
          style: {
            marginLeft: 20,
            marginBottom: 10
          }
        }}
        sx={{
          height: "100%"
        }}
      />
    );

  return (
    <Stack gap={1}>
      <Stack
        direction={{xs: "column", sm: "row", md: "row", lg: "row", xl: "row"}}
        justifyContent="space-between"
        gap={{xs: 1, sm: "unset", md: "unset", lg: "unset", xl: "unset"}}
        alignItems={{xs: "flex-start", sm: "center", md: "center", lg: "center", xl: "center"}}
      >
        <SmartSearchInput resultCallback={setAlgoliaForms} colPath={algoliaColPath}/>
        <SortMenu currentOrder={sortOrder} setter={setSortOrder}/>
      </Stack>
      <FormsList
        uid={props.uid}
        forms={props.displayedForms}
        orgFormsAccess={orgFormsAccess!}
        toastProps={props.toastProps}
        isSearched={props.fromAlgolia}
      />
    </Stack>
  )
}

export default FormContent;
