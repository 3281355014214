import {TimerState} from "types/Task";
import {Timer} from "types/Timer";

export type TimerActions =
  { type: TimerActionTypes.updateTimerState, payload: TimerState } | { type: TimerActionTypes.updateTimer, payload: Partial<Timer>} | { type: TimerActionTypes.updateFromDb, payload: boolean}

export enum TimerActionTypes {
  updateTimerState,
  updateTimer,
  updateFromDb
}

export function updateTimerState(state: TimerState) : TimerActions{
  return {type: TimerActionTypes.updateTimerState, payload: state}
}

export function updateTimer(timer: Partial<Timer>) : TimerActions{
  return {type: TimerActionTypes.updateTimer, payload: timer}
}

export function updateFromDb(value: boolean) : TimerActions{
  return {type: TimerActionTypes.updateFromDb, payload: value}
}
