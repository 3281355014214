import {enMemberLabel, SELECTABLE_ROLES} from "constants/index";
import {onChangeInput} from "screens/utility";
import {SelectInput} from "components/index";
import React, {Dispatch} from "react";
import {AccessRole} from "enums/accessRole";

interface RoleSelectProps {
  role: AccessRole;
  setRole: Dispatch<AccessRole>;
  selectedOrg: string | undefined;
  fromSAP?: boolean;
}

function RoleSelect(props: RoleSelectProps) {
  const {role, setRole, selectedOrg, fromSAP = false} = props;

  if(fromSAP && selectedOrg === "withoutOrganization") return null;

  return (
    <SelectInput
      id="add-member-drawer-role-select"
      label={enMemberLabel.role}
      value={role}
      defaultValue={role}
      onChange={(e) => onChangeInput(e, setRole)}
      items={SELECTABLE_ROLES}
    />
  )
}

export default RoleSelect;