import {DrawerProps} from "components/SimpleFormDrawer";
import {Member} from "types/Member";
import {OverFlowBox, SimpleFormDrawer} from "components/index";
import {Checkbox, FormControlLabel, Stack, Typography} from "@mui/material";
import React, {useContext, useState} from "react";
import {enTeamsAndPeopleLabel} from "constants/index";
import theme from "theme/theme";
import {SidebarIcons} from "assets/icons/menu/menu.index";
import {CheckBoxOutlineBlank} from "@mui/icons-material";
import MemberItem from "components/Drawers/MemberSelectionDrawer/MemberItem";
import SmartSearchInput from "components/inputs/SmartSearchInput";
import {algoliaOrganizationMemberColPath} from "screens/utility/algoliaColPath";
import {useParams} from "react-router-dom";
import {AccessRole, Entity} from "enums/index";
import EmptySearchResults from "components/EmptySearchResults";
import {SelectedOrgContext} from "screens/SelectedOrgContextProvider";
import {sortObjectsBy} from "../../../screens/utility";

interface MembersSelectionDrawerProps extends Partial<DrawerProps> {
  members: Member[];
  selectedMembers: Set<string>;
  updateSelectedMembers: (newSet: Set<string>) => void;
  onMemberSelect: (uid: string) => void;
}

export default function MemberSelectionDrawer(props: MembersSelectionDrawerProps) {
  const {
    members,
    isOpen,
    onClose,
    isFormValid,
    isLoading,
    selectedMembers,
    onMemberSelect,
    updateSelectedMembers
  } = props;

  const {orgId} = useParams();
  const selectedOrgContext = useContext(SelectedOrgContext);
  const {selectedOrg} = selectedOrgContext!;
  const [algoliaMembers, setAlgoliaMembers] = useState<Member[] | null>(null);

  function onSelectAll(_: React.ChangeEvent, checked: boolean) {
    return checked ?
      updateSelectedMembers(new Set(members.map((member) => member.uid))) :
      updateSelectedMembers(new Set());
  }

  const activeAlgoliaMembers = (algoliaMembers ?? []).filter(member => Boolean(member.active) || member.role === AccessRole.Owner);
  const isSelectionDisplayed = algoliaMembers ? activeAlgoliaMembers.length > 0 : members.length > 0;

  const currentSelectedMembers = algoliaMembers ? activeAlgoliaMembers.filter(member => selectedMembers.has(member["@id"]!)) : Array.from(selectedMembers);
  const displayedMembers = sortObjectsBy(
    (algoliaMembers ? activeAlgoliaMembers : members).map(member => {
      const {entity = "OrganizationMember"} = member;
      // @ts-ignore
      const context = ((entity === "OrganizationMember" ? selectedOrg?.members : selectedOrg?.teams) || []).find(c => c["@id"] === member["@id"]);
      const memberInfo = { ...member, ...(context || {}) };
      return memberInfo;
    }),
    "name"
  );

  return (
    <SimpleFormDrawer
      isOpen={isOpen!}
      onClose={onClose!}
      id="membersSelectionDrawer"
      buttonId="saveMembers"
      title={enTeamsAndPeopleLabel.addTeamMembers}
      icon={<SidebarIcons.User fill={theme.palette.common.black}/>}
      iconProps={{p: 0}}
      isFormValid={isFormValid!}
      isLoading={isLoading!}
      hiddenFooter
      useBackButton
    >
      <SmartSearchInput<Member>
        resultCallback={setAlgoliaMembers}
        colPath={algoliaOrganizationMemberColPath(orgId!)}
        sx={{width: '100%', mb: 1}}
      />
      {isSelectionDisplayed && (
        <FormControlLabel
          sx={{mx: 0, mb: 1, userSelect: "none"}}
          control={<Checkbox
            icon={<CheckBoxOutlineBlank sx={{color: theme.palette.neutral.main}}/>}
            checked={currentSelectedMembers.length === (algoliaMembers ? algoliaMembers.length : members.length)}
            onChange={onSelectAll}
          />}
          label={
            <Typography
              variant="body"
              color={theme.palette.secondary.main}>
              <strong>
                {
                  currentSelectedMembers.length > 0 ?
                    enTeamsAndPeopleLabel.nPeopleSelected(currentSelectedMembers.length) :
                    enTeamsAndPeopleLabel.people
                }
              </strong>
            </Typography>
          }
        />)}
      <OverFlowBox>
        <Stack gap={1} sx={{mx: 1, userSelect: "none"}}>
          {(algoliaMembers !== null && displayedMembers.length === 0) &&
            <EmptySearchResults entity={Entity.AccessList} sx={{marginY: 10}}/>
          }
          {displayedMembers.map((member) => {
            return (
              <MemberItem
                key={`memberItem_${member["@id"]}`}
                member={member}
                onClick={onMemberSelect}
                isSelected={selectedMembers.has(member["@id"]!)}
              />
            )
          })}
        </Stack>
      </OverFlowBox>
    </SimpleFormDrawer>
  )
}
