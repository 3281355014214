import {toastProps} from "screens/BaseProps";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {IconButton} from "@mui/material";
import React, {useState} from "react";
import {DocumentReference} from "firebase/firestore";
import {ActionType, ProcessType, Severity, ViewStatus} from "enums/index";
import {InProgress} from "components/index";
import {emptyFunction, enCommonLabel} from "constants/index";
import templateSubmitForm from "screens/utility/templateSubmitForm";
import {statusSubmitHandler} from "screens/utility/statusSubmitHandler";
import {submitForm} from "screens/utility";

interface FavoriteButtonProps {
  favorite: boolean;
  docRef: DocumentReference;
  toastProps: toastProps;
  height?: number;
  width?: number;
  strokeWidth?: number;
  disabled: boolean;
  fromTemplate?: boolean;
  submitCallback?: () => void;
}

function FavoriteButton(props: FavoriteButtonProps) {
  const {favorite, docRef, toastProps, height = 24, width = 24, disabled, strokeWidth, fromTemplate = false, submitCallback} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function setFavorite(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setIsLoading(true);

    // if from templates, create the parents
    if (fromTemplate) {
      await templateSubmitForm(
        docRef.path,
        ActionType.Create,
        (status, data, isLastUpdate) => statusSubmitHandler({status, data, isLastUpdate, successCallback, errorCallback}),
        {favorite: false}
      );

      return;
    }
    await submitForm(docRef, ActionType.Update, statusHandler, {favorite: !favorite});
  }

  async function successCallback() {
    await submitForm(docRef, ActionType.Update, statusHandler, {favorite: !favorite});
  }

  function errorCallback(message: any) {
    showMessage(message, Severity.Error);
  }

  function statusHandler(status: ViewStatus, data: {favorite: boolean}, isLastUpdate: boolean) {
    if (!isLastUpdate) return;

    switch (status) {
      case ViewStatus.Finished:
        showMessage(Boolean(data.favorite) ? enCommonLabel.favoriteSuccess : enCommonLabel.unfavoriteSuccess, Severity.Success);
        submitCallback && submitCallback();
        break;
      case ViewStatus.ValidationError:
      case ViewStatus.SecurityError:
      case ViewStatus.Error:
        showMessage(enCommonLabel.errorProcess(ProcessType.Update), Severity.Error);
        break;
    }
  }

  function showMessage(message: string, severity: Severity) {
    setToastMessage(message);
    setToastSeverity(severity);
    setIsToastOpen(true);
    setIsLoading(false);
  }

  const iconProps = {
    height: height,
    width: width,
    strokeWidth: strokeWidth,
    stroke: disabled ? theme.palette.text.secondary : favorite ? theme.palette.favorite.main : undefined,
  }

  const favoriteIcon = favorite ?
    <SystemIcons.FavoriteFilled {...iconProps} fill={disabled ? theme.palette.text.secondary : theme.palette.favorite.main}/>
    : <SystemIcons.FavoriteOutlined {...iconProps} />;

  const icon = isLoading ? <InProgress size={height}/> : favoriteIcon;
  return (
    <IconButton
      id={`${docRef.id}-milestone-task-favorite-button`}
      onClick={disabled ? emptyFunction : setFavorite}
      disabled={isLoading}
      title={disabled ? enCommonLabel.noPermissionForAction : undefined}
      sx={{
        ...(disabled ? {
          cursor: "default",
          ":hover": {
            backgroundColor: "transparent"
          }
        } : {})
      }}
    >
      {icon}
    </IconButton>
  )
}

export default FavoriteButton;
