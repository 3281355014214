import {BaseProps} from "screens/BaseProps";
import {CollectionReference, doc} from "firebase/firestore";
import React, {useEffect, useState} from "react";
import {Input, SelectWithSearch, SimpleFormDrawer} from "components/index";
import {areObjectsEqual, getKeyValue, onChangeInput} from "screens/utility";
import {enCommonButton, enCommonLabel, enFormRequirementLabel} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {SelectItem} from "components/inputs/SelectWithSearch";
import DataTagInput from "components/DataTag/DataTagInput";
import {useCollection} from "hooks/index";
import {MenuItem, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {formsPath} from "screens/utility/FirebasePath";
import {Form, FormRequirement} from "types/index";
import {ActionType, Severity} from "enums/index";
import useCheckUniqueness from "hooks/useCheckUniqueness";
import handleEnterKeyPress from "screens/utility/handleEnterKeyPress";
import templateSubmitForm from "screens/utility/templateSubmitForm";
import {ErrorMessageType, statusSubmitHandler} from "screens/utility/statusSubmitHandler";

interface ManageFormRequirementDrawerProps extends BaseProps {
  formRequirement?: FormRequirement;
  formRequirementCollectionRef: CollectionReference;
  isDrawerOpen: boolean;
  onDrawerClose: () => void;
}

function ManageFormRequirementDrawer(props: ManageFormRequirementDrawerProps) {
  const {
    formRequirementCollectionRef,
    isDrawerOpen,
    formRequirement,
    onDrawerClose,
    toastProps
  } = props;
  const {setIsToastOpen, setToastSeverity, setToastMessage} = toastProps!;

  const {orgId, assetId} = useParams();

  const [initialRequirement, setInitialRequirement] = useState({
    name: formRequirement?.name ?? "",
    description: formRequirement?.description ?? "",
    formId: formRequirement?.formId,
    dataTags: formRequirement?.dataTagsIds ?? [],
  });

  const {templateId} = formRequirement ?? {};
  const readOnly = typeof templateId === "string" && templateId.trim() !== "" && !!assetId;

  const formRef = formRequirement && formRequirement["@id"] ? doc(formRequirementCollectionRef, formRequirement["@id"])
    : doc(formRequirementCollectionRef);
  const [forms] = useCollection<Form>(null, formsPath(orgId!));

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [name, setName] = useState<string>(initialRequirement.name);
  const [description, setDescription] = useState<string>(initialRequirement.description);
  const [formTemplateId, setFormTemplateId] = useState<string | undefined>(initialRequirement?.formId);
  const [dataTags, setDataTags] = useState<string[]>(initialRequirement.dataTags);
  const isNameUnique = useCheckUniqueness(formRequirementCollectionRef, "name", name, formRef.id)

  // validation messages
  const [nameValidationMessage, setNameValidationMessage] = useState<string>("");
  const [formValidationMessage, setFormValidationMessage] = useState<string>("");

  const selectItems: SelectItem[] = generateSelectableItems();

  function resetForm() {
    let newRequirement = {
      name: formRequirement?.name ?? "",
      description: formRequirement?.description ?? "",
      formId: formRequirement?.formId,
      dataTags: formRequirement?.dataTagsIds ?? [],
    }
    setInitialRequirement(newRequirement);

    setName(newRequirement.name);
    setDescription(newRequirement.description);
    setFormTemplateId(newRequirement.formId);
    setDataTags(newRequirement.dataTags);
  }

  useEffect(() => {
    if (!isDrawerOpen) return;
    resetForm();

    setIsLoading(false);

    setIsFormValid(false);
    setNameValidationMessage("");
    setFormValidationMessage("");
  }, [isDrawerOpen]);

  //validations for name requirement
  useEffect(() => {
    if (!isDrawerOpen) return;

    if (isLoading) return;

    setNameValidationMessage("");

    if (name.trim() === "") {
      setNameValidationMessage(enFormRequirementLabel.requiredName);
      return
    }

    if (!isNameUnique) {
      setNameValidationMessage(enFormRequirementLabel.uniqueNameError);
    }
  }, [name, isNameUnique]);

  useEffect(() => {
    setIsFormValid(!nameValidationMessage
      && name.trim() !== ""
      && !formValidationMessage
      && !!formTemplateId
      && isFormModified());
  }, [nameValidationMessage, formValidationMessage, name, description, formTemplateId, dataTags]);

  function isFormModified() {
    const formData = {
      name,
      description,
      formId: formTemplateId,
      dataTags
    }

    return !areObjectsEqual(initialRequirement, formData);
  }

  async function onSubmitFormRequirement() {
    setIsLoading(true);
    const action = !formRequirement ? ActionType.Create : ActionType.Update;

    await templateSubmitForm(formRef.path, action,
      (status, data, isLastUpdate) => statusSubmitHandler<FormRequirement>({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback
      }),
      {
        name: name.trim(),
        description: description.trim(),
        formId: formTemplateId,
        dataTagsIds: dataTags,
        hidden: false,
      },
      formRequirement
    );
  }

  function successCallback() {
    setIsLoading(false);
    setToastMessage(formRequirement ? enFormRequirementLabel.editSuccess
      : enFormRequirementLabel.createSuccess(name));
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    closeDrawer();
  }

  function handleSelectChange(selected: string) {
    if (!selected || !forms) return;

    const formData = (forms ?? []).filter(form => form["@id"] === selected)[0];
    if (!!formData) {
      setFormTemplateId(formData["@id"]);
      return;
    }

    setFormTemplateId(undefined);
  }

  function generateSelectableItems() {
    return (forms ?? []).map(form => {
      return {
        value: form["@id"] || "fillerId",
        searchable: form.name,
        displayItem: form.name,
      };
    })
  }

  function errorCallback(errorMessage: ErrorMessageType) {
    setIsLoading(false);
    if (typeof (errorMessage) === "object") {
      setNameValidationMessage(getKeyValue(errorMessage, "name", ""));
      setFormValidationMessage(getKeyValue(errorMessage, "form", ""));
      return;
    }

    setToastMessage(errorMessage || enFormRequirementLabel.formCreateError);
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
  }

  function closeDrawer() {
    resetForm();
    onDrawerClose();
  }

  return (
    <SimpleFormDrawer
      isOpen={isDrawerOpen}
      onClose={closeDrawer}
      id="manage-form-requirement-drawer"
      title={formRequirement ? enFormRequirementLabel.edit : enFormRequirementLabel.create}
      isFormValid={isFormValid}
      isLoading={isLoading}
      icon={formRequirement ? <SystemIcons.Edit/> : <SystemIcons.Plus/>}
      unsavedChanges={isFormModified()}
      rightButtonLabel={formRequirement ? enCommonButton.save : enCommonButton.create}
      onSubmit={onSubmitFormRequirement}
      bodySx={{gap: 1 / 2}}
    >
      <Input
        label={enCommonLabel.name}
        onChange={(e) => onChangeInput(e, setName)}
        value={name}
        optional={false}
        validationMessage={nameValidationMessage}
        disabled={readOnly}
        onKeyPress={(e) => handleEnterKeyPress(e, isFormValid, isLoading, onSubmitFormRequirement)}
      />
      <Input
        label={enCommonLabel.description}
        value={description}
        onChange={(e) => onChangeInput(e, setDescription)}
        multiline={true}
        minRows={4}
        maxRows={8}
        optional={true}
        disabled={readOnly}
        onKeyPress={(e) => handleEnterKeyPress(e, isFormValid, isLoading, onSubmitFormRequirement)}
      />
      <SelectWithSearch
        label={enFormRequirementLabel.form}
        optional={false}
        items={selectItems}
        defaultValue={formRequirement && formRequirement?.formId ? formRequirement?.formId : undefined}
        handleSelectCallback={handleSelectChange}
        searchPlaceholder={enFormRequirementLabel.createForm}
        disabled={readOnly}
        extraMenuItem={selectItems.length === 0 ?
          <MenuItem disabled={true}>
            <Typography>{enFormRequirementLabel.noFormsYet}</Typography>
          </MenuItem> : undefined}
      />
      <DataTagInput
        uid={props.uid}
        handleSelectCallback={setDataTags}
        toastProps={toastProps!}
        initialTags={dataTags}
        onKeyPress={(e) => handleEnterKeyPress(e, isFormValid, isLoading, onSubmitFormRequirement)}
      />
    </SimpleFormDrawer>
  )
}

export default ManageFormRequirementDrawer;
