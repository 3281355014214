import React from "react";
import {Box} from "@mui/material";
import theme from "theme/theme";
import {PreviewProps} from "./PreviewProps";
import RejectedOverlay from "./RejectedOverlay";

function RejectedImagePreview(props: PreviewProps) {
  const {downloadUrl, id = "n", onClick, title = ""} = props;

  return (
    <Box
      title={title}
      position="relative"
      width="64px"
      height="inherit"
      id={`rejected-image-preview-${id}`}
      className="file-preview-item"
      sx={{
        borderRadius: 2,
        backgroundColor: theme.palette.background.swiftDefault,
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
    >
      <img
        style={{
          width: "64px",
          height: "64px",
          objectFit: "contain",
        }}

        alt="file preview"
        src={downloadUrl}
      />
      <RejectedOverlay/>
    </Box>
  )
}

export default RejectedImagePreview;
