import React, {createContext, ReactElement, useState} from "react";
import {SelectedOrg} from "types/SelectedOrg";
import {defaultSelectedOrg} from "constants/defaultSelectedOrg";

type SelectedOrgContextType = {
  selectedOrg: SelectedOrg;
  setSelectedOrg: React.Dispatch<React.SetStateAction<SelectedOrg>>
} | null;

export const SelectedOrgContext = createContext<SelectedOrgContextType>(null);

function SelectedOrgContextProvider(props: {children: ReactElement}) {
  const [selectedOrg, setSelectedOrg] = useState<SelectedOrg>(defaultSelectedOrg);

  return (
    <SelectedOrgContext.Provider value={{selectedOrg, setSelectedOrg}}>
      {props.children}
    </SelectedOrgContext.Provider>
  )
}

export default SelectedOrgContextProvider;