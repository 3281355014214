import SidebarMenu from "./SidebarMenu";
import {Box, Drawer, useMediaQuery} from "@mui/material";
import {BaseProps} from "screens/BaseProps";
import {useEffect, useState} from "react";
import theme from "theme/theme";

interface SidebarProps extends BaseProps {
  isOpen: boolean;
  isSuperAdmin: boolean;
  toggleSidebar: () => void;
}

function Sidebar(props: SidebarProps) {
  const {isOpen, toggleSidebar} = props;
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const currentLocation = window.location.pathname;
    setIsVisible(currentLocation !== "/view-invite");
  }, [window.location.pathname]);

  if (!isVisible) return null;

  return isMobile ? (
    <Drawer
      open={isOpen}
      onClose={toggleSidebar}
      ModalProps={{
        keepMounted: true
      }}
      sx={(theme) => ({
        width: 280,
        minHeight: "100vh",
        maxHeight: "100vh",
        ".MuiPaper-root": {
          width: 280,
          backgroundColor: theme.palette.secondary.main,
        },
        position: "fixed",
        zIndex: 2,
      })}
    >
      <SidebarMenu {...props} />
    </Drawer>
  ) : (
    <Box
      sx={(theme) => ({
        width: 280,
        minHeight: "100vh",
        maxHeight: "100vh",
        backgroundColor: theme.palette.secondary.main,
        position: "fixed",
        zIndex: 2,
      })}
    >
      <SidebarMenu {...props} />
    </Box>
  )
}

export default Sidebar;
