import approved from "assets/arrayList/StatusObjectList/StatusObjects/approved";
import cancelled from "assets/arrayList/StatusObjectList/StatusObjects/cancelled";
import inProgress from "assets/arrayList/StatusObjectList/StatusObjects/inProgress";
import pending from "assets/arrayList/StatusObjectList/StatusObjects/pending";
import rejected from "assets/arrayList/StatusObjectList/StatusObjects/rejected";
import submitted from "assets/arrayList/StatusObjectList/StatusObjects/submitted";
import hasRejection from "assets/arrayList/StatusObjectList/StatusObjects/hasRejection";

const statusObjects = {approved, cancelled, inProgress, pending, rejected, submitted, hasRejection}

export default statusObjects