import {Stack, SxProps, Theme, Typography, Zoom} from "@mui/material";
import SystemIndex from "assets/icons/system/system.index";
import {Entity} from "enums/entity";
import {enCommonLabel} from "constants/index";
import theme from "theme/theme";
import {ENTITY_LABEL} from "language/en/common";

interface EmptySearchResultsProps{
  entity: Entity;
  sx?: SxProps<Theme>;
}

function EmptySearchResults(props: EmptySearchResultsProps){
  const {entity, sx} = props;
  return (
    <Stack justifyContent="center" alignItems="center" flex={1} sx={sx}>
      <Zoom in={true} timeout={100}>
        <Stack alignItems="center" gap={2}>
          <SystemIndex.SystemIcons.Search stroke={theme.palette.text.secondary} width={48} height={48}/>
          <Stack alignItems="center">
            <Typography variant="h2">{enCommonLabel.search.noResultsTitle}</Typography>
            <Typography variant="body1" color="text.secondary" textAlign="center">
              {enCommonLabel.search.noResultsSubtext(ENTITY_LABEL[entity])}
            </Typography>
          </Stack>
        </Stack>
      </Zoom>
    </Stack>
  )
}

export default EmptySearchResults