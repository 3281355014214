import {Box, Stack} from "@mui/material";
import LabelText from "./LabelText";
import {en} from "language/en";

function Labels() {
  return <Stack direction="row" paddingX={2} marginTop={2} display={{xs: "none", md: "flex"}}>
    <Box flex={2}>
      <LabelText>
        {en.screen.TimeLog.label.worker}
      </LabelText>
    </Box>

    <Box flex={1}>
      <LabelText>
        {en.screen.TimeLog.label.started}
      </LabelText>
    </Box>

    <Box flex={1}>
      <LabelText>
        {en.screen.TimeLog.label.ended}
      </LabelText>
    </Box>

    <Box flex={1}>
      <LabelText>
        {en.screen.TimeLog.label.duration}
      </LabelText>
    </Box>

    <Box flex={2}>
      <LabelText>
        {en.screen.TimeLog.label.location}
      </LabelText>
    </Box>
  </Stack>
}


export default Labels;