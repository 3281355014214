import useProgressCount from "hooks/useProgressCount";
import {CounterFields} from "enums/counterFields";
import {useEffect, useState} from "react";

interface Props {
  path: string;
}

interface HookReturns {
  isImport: boolean;
  isProcessing: boolean;
  progress: number;
}

function useImportBuild(props: Props): HookReturns {
  const {path} = props;
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isImport, setIsImport] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const {progress: importingProgressCount, isProcessing: isImportProcessing} = useProgressCount({
    path: path,
    counterField: CounterFields.ImportingProgressCount,
  });
  const {progress: buildingProgressCount, isProcessing: isBuildProcessing} = useProgressCount({
    path: path,
    counterField: CounterFields.BuildingProgressCount,
  });

  useEffect(() => {
    setIsImport(!!importingProgressCount);

    if (!!importingProgressCount) {
      setIsProcessing(isImportProcessing);
      setProgress(importingProgressCount);
      return;
    }

    setIsProcessing(isBuildProcessing);
    setProgress(buildingProgressCount);
  }, [importingProgressCount, buildingProgressCount]);

  return {isProcessing, progress, isImport};
}

export default useImportBuild;