function searchInArrayWithStringify(array: any[], searchText: string) {
  if (!array) return [];

  const lowerCaseSearchText = searchText.toLowerCase();

  return array.filter(obj => {
    // Stringify the object and perform a case-insensitive search
    return JSON.stringify(obj).toLowerCase().includes(lowerCaseSearchText);
  });
}

export default searchInArrayWithStringify;