import {BaseProps} from "screens/BaseProps";
import {Content, CustomTab, CustomTabs, InProgress, PageTitle, TabPanel} from "components/index";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {emptyFunction, enChatLabel} from "constants/index";
import {TabIndex} from "./enums/TabIndex";
import {Stack, Typography} from "@mui/material";
import {chatTabs} from "./constants/ChatTabs";
import Tab from "./Tab";
import {useRTDBDocField} from "hooks/index";
import {CounterFields} from "enums/counterFields";
import {userPath} from "../utility";

function GlobalChat(props: BaseProps) {
  const {selectedOrg, uid, setSelectedOrgId = emptyFunction} = props;
  const {orgId} = useParams();
  const navigate = useNavigate();

  const entityChatsCount = useRTDBDocField<number>(userPath(uid).path, CounterFields.EntityChatsCount) || 0;
  const [activeTab, setActiveTab] = useState<TabIndex>(TabIndex.RecentChats);

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  function handleTabChange(event: React.SyntheticEvent, newValue: number) {
    setActiveTab(newValue);
  }

  return (
    <Content>
      <Stack
        height="100%"
        maxWidth="100vw"
        sx={{
					gap: 1.5,
					pt: 2,
					px: {
						xs: 1,
						sm: 1,
						md: 0,
					},
        }}
      >
        <PageTitle
          title={enChatLabel.chats}
          count={entityChatsCount}
        />
        <CustomTabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{
            maxWidth: "100%",
          }}
        >
          {Object.entries(chatTabs).map(([key, value], index) => (
            <CustomTab
              key={`tab-label-${key}`}
              disableRipple
              label={
                <Stack direction="row" gap={0.5}>
                  <Typography variant="h5">{value}</Typography>
                </Stack>
              }
              sx={{ml: index === 0 ? 0 : 5}}
            />
          ))}
        </CustomTabs>
        {Object.entries(chatTabs).map(([key, value], index) => (
          <TabPanel
            tabIndex={index}
            value={activeTab}
            key={`tab-panel-${key}`}
          >
            <Tab tab={index} key={`tab-panel-content-${key}`} {...props} />
          </TabPanel>
        ))}
      </Stack>
    </Content>
  )
}

export default GlobalChat;