import {Template} from "types/Template";
import {EmptyList, InProgress, SortMenu} from "components/index";
import {BaseProps} from "../BaseProps";
import TemplatesList from "./TemplatesList";
import {Entity} from "enums/entity";
import {AccessType} from "types/Permission";
import {Stack} from "@mui/material";
import SmartSearchInput from "components/inputs/SmartSearchInput";
import {enTemplateLabel} from "constants/index";
import {algoliaTemplatesColPath} from "../utility/algoliaColPath";
import {useParams} from "react-router-dom";
import {DirectionalOrder} from "enums/DirectionalOrder";
import {Dispatch} from "react";

interface TemplatesViewProps extends BaseProps {
  access: AccessType | null;
  templates: Template [] | null;
  algoliaTemplates: Template [] | null;
  sortOrder: DirectionalOrder;
  setSortOrder: Dispatch<DirectionalOrder>;
  setAlgoliaTemplates: (template: Template [] | null) => void;
}

function TemplatesView(props: TemplatesViewProps) {
  const {templates, algoliaTemplates, sortOrder, setSortOrder, access, setAlgoliaTemplates} = props;
  const {orgId} = useParams();

  if ((!templates && !algoliaTemplates) || !access) return <InProgress/>;

  if (templates && templates.length === 0)
    return <EmptyList entity={Entity.Templates}/>;

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mt={4}>
        <SmartSearchInput<Template>
          placeholder={enTemplateLabel.search}
          resultCallback={setAlgoliaTemplates}
          colPath={algoliaTemplatesColPath(orgId!)}
        />
        <Stack direction="row" gap={1}>
          <SortMenu currentOrder={sortOrder} setter={setSortOrder}/>
        </Stack>
      </Stack>
      <TemplatesList
        fromAlgolia={algoliaTemplates !== null}
        {...props}
        access={access}
        templates={algoliaTemplates ?? templates ?? []}
      />
    </>
  )
}

export default TemplatesView;
