import {Box, Stack, Typography,} from "@mui/material";
import React, {useEffect} from "react";
import {enMilestoneLabel, enTaskLabel, enTemplateLabel} from "constants/index";
import {CollapsableText} from "components/index";
import {Milestone, Template} from "types/index";
import {SecondaryButton} from "components/Button";
import {useAccess, useComponentToggler, useDocument} from "hooks/index";
import {BaseProps} from "screens/BaseProps";
import {CreateTaskDrawer, ManageMilestoneDrawer} from "components/Drawers";
import {templatesMilestonePath, templatesPath} from "screens/utility/FirebasePath";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import {Entity} from "enums/entity";
import {doc} from "firebase/firestore";
import {AccessType, PermissionEntity, PermissionOperationKey} from "types/Permission";
import CardBadge, {OverlayType} from "components/CardBadge";
import useProgressCount from "hooks/useProgressCount";
import {CounterFields} from "enums/counterFields";

interface HeadProps extends BaseProps {
  template: Template;
  taskAccess: AccessType | null;
}

function PageHeader(props: HeadProps) {
  const {template, toastProps, taskAccess, uid} = props;
  const {"@id": templateId, name, description, orgId} = template!;

  const {progress: importingProgressCount} = useProgressCount({
    path: templatesPath(orgId!).path + "/" + template.id,
    counterField: CounterFields.ImportingProgressCount
  });

  const milestonesCollectionRef = templatesMilestonePath(orgId!, templateId!);

  const [miscMilestone, setMiscMilestoneDocRef] = useDocument<Milestone | null>(null);

  const [isTaskDrawerOpen, {open: openTaskDrawer, close: closeTaskDrawer}] = useComponentToggler(false);
  const [isMilestoneDrawerOpen, {open: openMilestoneDrawer, close: closeMilestoneDrawer}] = useComponentToggler(false);

  const [milestoneAccess] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationTemplateMilestone,
  });

  useEffect(() => {
    if (!orgId) return;
    setMiscMilestoneDocRef(doc(milestonesCollectionRef, "misc"));
  }, [orgId]);

  return (
    <Stack direction="row" display="flex" mb={1} py={0} gap={1}>
      <ManageMilestoneDrawer
        uid={uid}
        collectionRef={milestonesCollectionRef}
        isDrawerOpen={isMilestoneDrawerOpen}
        onClose={closeMilestoneDrawer}
        toastProps={toastProps!}
      />
      {miscMilestone && (
        <CreateTaskDrawer
          parentEntity={Entity.Templates}
          collectionRef={milestonesCollectionRef}
          milestone={miscMilestone}
          onDrawerClose={closeTaskDrawer}
          isDrawerShown={isTaskDrawerOpen}
          toastProps={toastProps!}
          uid={uid}
        />
      )}
      <Box flex={3}>
        <Stack direction="column" sx={{gap: 1}}>
          <Stack direction="row" sx={{gap: 1}} alignItems="center">
            <Typography variant="h1">
              {name}
            </Typography>
            <CardBadge
              id={`template-badge-${template.id}`}
              text={enTemplateLabel.importingTemplate}
              buildingProgressCount={importingProgressCount}
              sx={{
                position: "relative",
                borderTopLeftRadius: 4,
                borderBottomRightRadius: 4,
              }}
              toastProps={toastProps!}
              process={OverlayType.Importing}
            />
          </Stack>
          <CollapsableText text={description}/>
          <DataTagsDisplay
            uid={uid}
            dataTagsIds={template?.dataTagsIds || []}
            toastProps={toastProps!}
            documentRef={doc(templatesPath(orgId!), templateId!)}
            canEditDocumentRef={Boolean(taskAccess?.[PermissionOperationKey.Update])}
          />
        </Stack>
      </Box>
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-end"
        spacing={2}
      >
        <SecondaryButton
          id="asset-overview-head-create-milestone-button"
          onClickFcn={openMilestoneDrawer}
          disabled={!Boolean(milestoneAccess?.[PermissionOperationKey.Create])}
          label={enMilestoneLabel.new}
          sx={{height: "32px"}}
        />
        <SecondaryButton
          id="asset-overview-head-create-task-button"
          onClickFcn={openTaskDrawer}
          disabled={!Boolean(taskAccess?.[PermissionOperationKey.Create])}
          label={enTaskLabel.new}
          sx={{height: "32px"}}
        />
      </Stack>
    </Stack>
  );
}

export default PageHeader;
