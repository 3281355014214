import {Button, Collapse, IconButton, Stack, Typography} from "@mui/material";
import {en} from "language/en";
import {Timer} from "types/Timer";
import {doc, Timestamp} from "firebase/firestore";
import {myTimersPath} from "screens/utility/FirebasePath";
import SubmitForm from "screens/utility/submitForm";
import {ActionType} from "enums/actionType";
import {TimerState} from "types/Task";
import {Dispatch, ReactElement, useEffect} from "react";
import {ViewStatus} from "enums/viewStatus";
import SystemIndex from "assets/icons/system/system.index";
import {CollapseStates} from "components/Timers/TimersViews/MyTimersView";
import {baseListCount} from "components/Timers/constants";
import {emptyFunction} from "constants/index";

interface BaseTimersListProps extends TimerLoadFunctions {
  uid: string;
  timers: Timer[]
  timersComponentsMap: ReactElement[]
  timersListType: "Started" | "Paused"
  setIsListLoading: Dispatch<boolean>
  organizationTimer?: boolean
  collapsible?: boolean
  collapseStates?: CollapseStates
  setCollapseStates?: Dispatch<CollapseStates>
  totalCount: number
}

export interface TimerLoadFunctions {
  onLoadMoreClick: () => void
  onSeeLessClick: () => void
  listCount: number
}

const enTimers = en.screen.Sidebar.timers

function BaseTimersList(props: BaseTimersListProps) {
  const {
    timers,
    timersComponentsMap,
    timersListType,
    organizationTimer,
    setIsListLoading,
    onLoadMoreClick,
    onSeeLessClick,
    listCount,
    collapsible = false,
    setCollapseStates,
    collapseStates,
    totalCount
  } = props;

  const isListEndReached = listCount - timers.length > 0;

  const collapsedState = timersListType === "Started" ? collapseStates?.started : collapseStates?.paused

  useEffect(() => {
    const unfinishedTimer = timers.find(timer => timer["@form"]?.["@status"] !== ViewStatus.Finished);

    if (!unfinishedTimer) {
      setIsListLoading(false);
    }
  }, [timers])

  function onCollapseClick() {
    if (!setCollapseStates)
      return;

    if (!collapseStates)
      return

    if (timersListType === "Started") {
      setCollapseStates({...(collapseStates), started: !collapseStates?.started})
    } else {
      setCollapseStates({...(collapseStates), paused: !collapseStates?.paused})
    }
  }

  function onStopAllClick() {
    setIsListLoading(true)
    timers.forEach(timer => {
      const docRef = doc(myTimersPath(props.uid), timer.id)
      SubmitForm<Partial<Timer>>(docRef, ActionType.Update, emptyFunction, {
        timerState: TimerState.Stopped,
        stopDateTime: Timestamp.now(),
        timerLocation: timer.timerLocation
      })
    })
  }

  function onStartAllClick() {
    setIsListLoading(true)
    timers.forEach(timer => {
      const docRef = doc(myTimersPath(props.uid), timer.id)
      SubmitForm<Partial<Timer>>(docRef, ActionType.Update, emptyFunction, {
        timerState: TimerState.Resumed,
        lastTimerStartedDateTime: Timestamp.now(),
        timerLocation: timer.timerLocation
      })
    })
  }


  function onPauseAllClick() {
    setIsListLoading(true)
    timers.forEach(timer => {
      const docRef = doc(myTimersPath(props.uid), timer.id)
      SubmitForm<Partial<Timer>>(docRef, ActionType.Update, emptyFunction, {
        timerState: TimerState.Paused,
        pausedDateTime: Timestamp.now(),
        timerLocation: timer.timerLocation
      })
    })
  }

  function getTimerTitle(){
    if(!collapsible){

      if(timersListType === "Started")
        return enTimers.Started;

      return enTimers.Paused;
    }
    else{
      if(timersListType === "Started")
        return enTimers.StartedTimers

      return enTimers.PausedTimers
    }
  }

  if (!timers.length)
    return <></>

  return <Stack gap={1}>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center">
        <IconButton onClick={onCollapseClick}>
          {collapsedState ?
            <SystemIndex.SystemIcons.Plus width={16} height={16}/>
            :
            <SystemIndex.SystemIcons.Minus width={16} height={16}/>
          }
        </IconButton>
        <Typography variant="h5">
          {getTimerTitle()}{" "}
          <Typography variant="h5" component="span" color="text.secondary">{totalCount}</Typography>
        </Typography>
      </Stack>
      <Stack direction="row" gap={1}>
        {!organizationTimer &&
          <Button
            variant="contained"
            color={timersListType === "Started" ? "warning" : "primary"}
            sx={{fontSize: 14}}
            onClick={timersListType === "Started" ? onPauseAllClick : onStartAllClick}
          >
            <Typography color={"text.onDark"} variant="h5">
              {timersListType === "Started" ? enTimers.pauseAll : enTimers.startAll}
            </Typography>
          </Button>
        }

        {!organizationTimer &&
          <Button
            variant="outlined"
            color="error"
            sx={{fontSize: 14}}
            onClick={onStopAllClick}
          >
            {enTimers.stopAll}
          </Button>
        }
      </Stack>
    </Stack>

    <Collapse in={!collapsedState}>
      <Stack gap={1}>
        {timersComponentsMap}
        {(!isListEndReached) &&
          <Button onClick={onLoadMoreClick} variant="contained"
                  sx={{alignSelf: "center"}}>{en.common.label.seeMore}</Button>
        }
        {isListEndReached && timers.length > baseListCount - 1 &&
          <Button onClick={onSeeLessClick} variant="outlined"
                  sx={{alignSelf: "center"}}>{en.common.label.seeLess}</Button>
        }
      </Stack>
    </Collapse>


  </Stack>
}

export default BaseTimersList
