import UploadItem from "./UploadItem";
import {FileUploadStatus} from "enums/fileUploadStatus";
import FailedItem from "./FailedItem";
import {toastProps} from "../../../BaseProps";
import {FileUploadReducerState} from "hooks/useFileUpload/reducer/FileUploadReducerState";
import {UploadAction} from "hooks/useFileUpload/reducer/UploadAction";
import React, {RefObject} from "react";
import {sortObjectsBy} from "../../../utility";
import { Virtuoso } from "react-virtuoso";
import {EmptyList} from "components/index";
import {Entity} from "enums/entity";
import theme from "theme/theme";

interface UploadListProps {
  toastProps: toastProps;
  isUploadPanelShown: boolean;
  isMinimized: boolean;
  fileUploadState: FileUploadReducerState;
  fileUploadDispatch: React.Dispatch<UploadAction>;
  containerRef?: RefObject<any>;
}

/**
 * This component is responsible for rendering the list of files that are being uploaded.
 * @param props - where props are:
 * <li>files - the list of files that are in uploading/uploaded state</li>
 * @returns - JSX.Element
 */
function UploadList(props: UploadListProps) {
  const {toastProps, isUploadPanelShown, fileUploadState, containerRef, isMinimized} = props;
  const {processingFiles, errorFiles} = fileUploadState;

  const fileList = sortObjectsBy([...processingFiles, ...errorFiles], "index", "desc");

  if (fileList.length === 0){
    return (
      <EmptyList
        entity={Entity.Upload}
        logoProperties={{
          fill: "none",
          stroke: theme.palette.neutral.light,
        }}
        sx={{
          paddingY: "20%",
          flex: 1,
        }}
      />
    )
  }


  return (
    <Virtuoso
      data={fileList}
      style={{height: isMinimized ? "35vh": "90vh", maxWidth: "100hw", overflowX: "hidden"}}
      itemContent={(index, file) => {
        const {fileUploadStatus} = file;

        if (fileUploadStatus === FileUploadStatus.Failed) {
          return <FailedItem
            containerRef={containerRef}
            file={file}
            key={`${file["id"]!}-file-upload-item-${index}`}
          />
        }

        return (
          <UploadItem
            containerRef={containerRef}
            file={file}
            key={`${file["id"]!}-file-upload-item-${index}`}
            isUploadPanelShown={isUploadPanelShown}
            toastProps={toastProps}
          />
        )
      }}
    />
  )
}

export default UploadList;