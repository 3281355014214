export default function getTimeSlots(interval: number = 15) {
  const timeSlotsCount = 60 / interval;

  return Array.from(new Array(24 * timeSlotsCount)).map((_, index) => {
    let hours = Math.floor(index / timeSlotsCount) % 12;
    if (hours === 0) hours = 12;
    const minutes = (index * interval) % 60;
    return `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    } ${index < 12 * timeSlotsCount ? "AM" : "PM"}`;
  });
}
