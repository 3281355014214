import {Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {enAssetLabel} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {SecondaryButton} from "components/Button";

interface EmptyAssetsProps {
  canView: boolean;
}

function EmptyAssets(props: EmptyAssetsProps) {
  const {canView} = props;

  const navigate = useNavigate();
  return (
    <Stack alignItems="center" spacing={1} flex={1} justifyContent="flex-start" mt={8}>
      <SystemIcons.Assets height={64} width={64}/>
      <Typography variant="h2">No Assets yet</Typography>
      <Typography variant="body" color="text.secondary">
        {enAssetLabel.firstAsset}
      </Typography>
      <SecondaryButton
        onClickFcn={() => navigate(`../manage-assets`)}
        disabled={!canView}
        label={enAssetLabel.manageAsset}
        startIcon={SystemIcons.Settings}
      />
    </Stack>
  );
}

export default EmptyAssets;
