import {Form} from "types/Form";
import {Avatar, Checkbox, Divider, Stack} from "@mui/material";
import theme from "theme/theme";
import Icons from "assets/icons";
import {SystemIcons} from "assets/icons/system/system.index";
import {useNavigate, useParams} from "react-router-dom";
import {emptyFunction, enCommonLabel} from "constants/index";
import {LongText} from "components/index";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import {BaseProps} from "../../BaseProps";
import {AccessType, PermissionOperationKey} from "types/Permission";
import React, {useRef} from "react";
import {SwiftIconButton} from "components/Button";
import {formsPath} from "../../utility";
import {doc} from "firebase/firestore";

interface FormItemProps extends BaseProps {
  form: Form;
  orgFormsAccess: AccessType | null;
  isSelected: boolean;
  onItemSelectToggle: (formId: string, checked: boolean) => void;
  onDeleteClick: (formId: string) => void;
  onMenuOpen: (e: Element | undefined, id: string) => void;
}

export function FormItem(props: FormItemProps) {
  const {form, isSelected, onItemSelectToggle, orgFormsAccess, onDeleteClick, onMenuOpen, toastProps} = props;
  const {name, description, avatarColor, dataTagsIds = []} = form;
  const id = form["@id"]!;
  const {orgId} = useParams();
  const navigate = useNavigate();
  const formRef = useRef<HTMLDivElement | null>(null);

  const withDeleteAccess = Boolean(orgFormsAccess?.[PermissionOperationKey.Delete]);
  const withUpdateAccess = Boolean(orgFormsAccess?.[PermissionOperationKey.Update]);

  function handleDeleteForm() {
    onDeleteClick(id);   // Created a new function for simplicity in html part of the file.
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      px={1}
      alignItems="center"
      border={`1px solid ${theme.palette.neutral.light}`}
      borderRadius={1}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onMenuOpen(formRef.current!, id)
      }}
      sx={{
        cursor: withUpdateAccess ? "pointer" : "default",
        "&:hover": {
          borderColor: withUpdateAccess ? theme.palette.primary.main : "unset",
          borderWidth: 1,
        }
      }}
    >
      <Stack
        direction="row"
        gap={1.5}
        alignItems="center"
        flex={1}
        ref={formRef}
        onClick={withUpdateAccess ? () => navigate(`${id}/edit`) : emptyFunction}
      >
        <Checkbox
          sx={{padding: 0}}
          checked={isSelected}
          onClick={(e) => e.stopPropagation()}
          onChange={(e, checked) => {
            onItemSelectToggle(id, checked);
          }}
        />
        <Divider orientation="vertical" flexItem/>
        <Stack direction="row" gap={1.5} alignItems='center' py={1}>
          <Avatar
            variant="rounded"
            sx={{
              backgroundColor: avatarColor,
              padding: 2.5,
              boxSizing: "border-box",
              mt: 0
            }}
          >
            <Icons.SidebarIcons.AddFile
              fill={theme.palette.neutral.light}
              style={{
                minWidth: 16,
                minHeight: 20
              }}
            />
          </Avatar>
          <Stack gap={0} flex={1}>
            <LongText variant="h4" maxWidth="100%">{name}</LongText>
            <LongText variant="body" maxWidth="100%" maxLines={2}>
              {!!description ? description : enCommonLabel.noDescription}
            </LongText>
            <DataTagsDisplay
              dataTagsIds={dataTagsIds}
              uid={props.uid}
              toastProps={toastProps!}
              listSize="medium"
              canEditDocumentRef={withUpdateAccess}
              documentRef={doc(formsPath(orgId!), id)}
            />
          </Stack>
        </Stack>
      </Stack>
      <Divider orientation="vertical" flexItem sx={{mr: 1.5}}/>
      <Stack direction="row" gap={0}>
        <SwiftIconButton
          onClickFcn={() => navigate(`${id}/edit`)}
          disabled={!withUpdateAccess}
          startIcon={SystemIcons.Edit}
        />
        <SwiftIconButton
          onClickFcn={handleDeleteForm}
          disabled={!withDeleteAccess}
          startIcon={SystemIcons.DeleteOutlined}
        />
      </Stack>
    </Stack>
  )
}
