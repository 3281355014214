import {Button, Stack, Typography} from "@mui/material";
import {enCommonButton, enCommonManageAccess} from "constants/index";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import React from "react";

interface ManageAccessEmptyPageProps {
  entity: string,
  setIsAddTeamsAndPeopleDrawerOpen: () => void;
}

function ManageAccessEmptyPage(props: ManageAccessEmptyPageProps) {
  const {entity, setIsAddTeamsAndPeopleDrawerOpen} = props;

  return (
    <Stack flex={1} alignItems="center" justifyContent="center" direction="column" gap={1}>
      <Typography variant="h4" textAlign="center" width={"80%"}>
        {enCommonManageAccess.emptyManageAccessTitle(entity)}
      </Typography>
      <Typography textAlign="center" color={theme.palette.neutral.dark} variant="subtitle2">
        {enCommonManageAccess.emptyManageAccessSubtitle(entity)}
      </Typography>
      <Button
        onClick={setIsAddTeamsAndPeopleDrawerOpen}
        startIcon={<SystemIcons.AddUser stroke={theme.palette.primary.main} width={16} height={16}/>}
        id={"manage-access-drawer-add-button"}
        variant="outlined"
        sx={(theme) => ({
          borderColor: theme.palette.neutral.light,
          color: theme.palette.secondary.main,
          gap: 0.5
        })}
      >
        {enCommonButton.add}
      </Button>
    </Stack>
  )
}

export default ManageAccessEmptyPage;