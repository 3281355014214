import {Backdrop, Box, Menu, MenuItem, Stack, Typography} from "@mui/material";
import AssignableAvatar from "components/AssignableAvatar";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import toTitleCase from "../../../utility/toTitleCase";
import React, {useState} from "react";
import {User} from "types/User";
import {emptyFunction, enCommonLabel, enProfileAndSettingsLabel} from "constants/index";
import {Severity, ActionType, UserFileType} from "enums/index";
import {InProgress} from "components/index";
import {createPreviewFile, submitForm, userFilesPath, userPath} from "screens/utility";
import {doc} from "firebase/firestore";
import {statusSubmitHandler} from "../../../utility/statusSubmitHandler";
import {toastProps} from "../../../BaseProps";

interface AvatarProps {
  user: User;
  toastProps?: toastProps
}

function Avatar(props: AvatarProps) {
  const {user, toastProps} = props;

  const {setToastMessage, setIsToastOpen, setToastSeverity} = toastProps!;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  function handleClick(event: React.MouseEvent<HTMLDivElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setIsLoading(false);
    setAnchorEl(null);
    setIsDeleting(false);
  }

  function selectProfilePicture() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.addEventListener("change", () => {
      const file = input.files?.[0];
      if (!file) return;

      const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      // check file type before uploading
      if (file && !allowedExtensions.exec(file.name)) {
        setToastMessage(enCommonLabel.invalidFileType);
        setToastSeverity(Severity.Error);
        setIsToastOpen(true);
        handleClose();
        return;
      }

      createUserFile(file);
    });

    input.click();
  }

  async function createUserFile(file: File) {
    setAnchorEl(null);
    setIsLoading(true);

    const previewFile = await createPreviewFile(file);
    const userFileDoc = doc(userFilesPath(user["@id"]!));
    const docData = {
      id: userFileDoc.id,
      name: file.name,
      userId: user["@id"]!,
      "#previewValue": previewFile,
      length: file.size,
      contentType: file.type,
      type: UserFileType.AvatarPhoto,
    }

    await submitForm(userFileDoc, ActionType.Create,
      (status, data, isLastUpdate) => statusSubmitHandler<User>({
        status,
        data,
        isLastUpdate,
        successCallback: () => successCallback(enProfileAndSettingsLabel.profileUploadSuccess),
        errorCallback: () => errorCallback("Upload"),
      }),
      docData
    );
  }

  async function deleteAvatar() {
    setAnchorEl(null);
    setIsLoading(true);
    setIsDeleting(true);

    await submitForm<Partial<User>>(userPath(user["@id"]!), ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler<User>({
        status,
        data,
        isLastUpdate,
        successCallback: () => successCallback(enProfileAndSettingsLabel.profileDeleteSuccess),
        errorCallback: () => errorCallback("Delete")
      }),
      {avatarPhotoUrl: null});
  }

  function successCallback(message: string) {
    setToastMessage(message);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    handleClose();
  }

  function errorCallback(process: string) {
    setToastMessage(enCommonLabel.errorProcess(process));
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
    handleClose();
  }

  return <>
    <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
      <MenuItem onClick={selectProfilePicture}>
        <Stack direction="row" alignItems="center" gap={1}>
          <SystemIcons.Image/>
          <Typography variant="h4">{enProfileAndSettingsLabel.changeAvatar}</Typography>
        </Stack>
      </MenuItem>
      <MenuItem onClick={deleteAvatar}>
        <Stack direction="row" alignItems="center" gap={1}>
          <SystemIcons.DeleteOutlined/>
          <Typography variant="h4" color="error.main">{enProfileAndSettingsLabel.deleteAvatar}</Typography>
        </Stack>
      </MenuItem>
    </Menu>
    <Stack direction="row" alignItems="center" gap={2}>
      <Box position="relative">
        <Box width="64px" height="64px">
          <AssignableAvatar
            isYou
            initials={user?.initials}
            avatarPhotoUrl={user?.avatarPhotoUrl ?? undefined}
            name={user?.name}
            avatarColor={user?.avatarColor}
            sx={{margin: 0, height: 64, width: 64, fontSize: 24}}
          />
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              borderRadius: "100%",
              top: 0,
              left: 0,
              backgroundColor: theme.palette.neutral.dark,
              opacity: isLoading ? 0.8 : 0
            }}
          />
          <Backdrop
            open={isLoading}
            sx={{height: 64, width: 64, borderRadius: 15, position: "absolute", backgroundColor: "unset"}}
          >
            <InProgress size={20}/>
          </Backdrop>
        </Box>
        <Box
          key={"camera"}
          sx={{
            backgroundColor: open ? theme.palette.secondary.main : theme.palette.neutral.light,
            position: "absolute",
            height: 26,
            width: 26,
            display: "grid",
            placeItems: "center",
            borderRadius: "100%",
            bottom: -3,
            right: 0,
            border: `1px solid ${theme.palette.background.paper}`,
            cursor: isLoading || isDeleting ? undefined : "pointer"
          }}
          onClick={isLoading || isDeleting ? emptyFunction : handleClick}
        >
          <SystemIcons.Camera
            stroke={open ? theme.palette.background.paper : theme.palette.neutral.dark}
            height={16}
            width={16}
          />
        </Box>
      </Box>
      <Typography variant="h2">{toTitleCase(user?.name ?? "")}</Typography>
    </Stack>
  </>
}

export default Avatar;
