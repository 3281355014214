import {Previews} from "enums/supportedPreviews";

function getPreviewType(mime: string): Previews {
  const lowerCaseMime = mime.toLowerCase();
  if (lowerCaseMime.includes("image")) return Previews.Image;
  if (lowerCaseMime.includes("video")) return Previews.Video;
  if (lowerCaseMime.includes("pdf")) return Previews.PDF;
  if (lowerCaseMime.includes("zip")) return Previews.Zip;
  if (lowerCaseMime.includes("document") || lowerCaseMime.includes("word")) return Previews.Document;

  return Previews.File;
}

export function getPreviewFromMime(mime: string): Previews {
  return getPreviewType(mime);
}
