import {BaseProps} from "../BaseProps";
import {Template} from "types/Template";
import {Avatar, Checkbox, Divider, Stack} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import theme from "theme/theme";
import {emptyFunction, enCommonLabel, enTemplateLabel} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {SidebarIcons} from "assets/icons";
import {SwiftIconButton} from "components/Button";
import {useNavigate} from "react-router";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import {LongText} from "components/index";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {ActionType, CounterFields, FeatureType, Severity, ViewStatus} from "enums/index";
import {getDocumentStatus, submitForm, templatesPath} from "../utility";
import {doc} from "firebase/firestore";
import Toast from "components/Toast";
import CardBadge, {OverlayType} from "components/CardBadge";
import FeatureWrapper from "components/FeatureWrapper";
import useProgressCount from "hooks/useProgressCount";

interface TemplateCardProps extends BaseProps {
  template: Template;
  isSelected: boolean;
  access: AccessType;
  checkedTemplates: string[];
  setCheckedTemplates: (templates: string[]) => void;
  setSelectedTemplate: (template: Template) => void;
  deleteTemplate: (templateId: string) => void;
  onMenuOpen: (e: Element | undefined, id: string, anchorPosition?: { left: number, top: number }) => void;
}

function TemplateCard(props: TemplateCardProps) {
  const {template, access, checkedTemplates, toastProps, uid} = props;
  const {setSelectedTemplate, setCheckedTemplates, deleteTemplate, onMenuOpen} = props;

  const {id, name, avatarColor, description, orgId, importStatus} = template;

  const {progress: importingProgressCount, isProcessing} = useProgressCount({
    path: templatesPath(orgId!).path + "/" + template.id,
    counterField: CounterFields.ImportingProgressCount,
  });

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);

  const nav = useNavigate();
  const templateRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setIsChecked(checkedTemplates.includes(template["@id"]!));
  }, [checkedTemplates]);

  // listen to template update
  useEffect(() => {
    const status = getDocumentStatus(template);

    // if form status is not finished or toast is already open, skip this
    if (status !== ViewStatus.Finished || isToastOpen) return;

    // only show toast if import status is failed
    if (importStatus && importStatus === "Failed") {
      setIsToastOpen(true);
    }
  }, [template]);

  function onCardSelect(e: React.ChangeEvent, checked: boolean) {
    if (checked) {
      setCheckedTemplates([...checkedTemplates, id]);
    } else {
      setCheckedTemplates(
        checkedTemplates.filter((templateId) => templateId !== id)
      );
    }
  }

  function navigateToOverview(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();

    nav(`/${orgId}/templates/${id}`);
  }

  function onDeleteClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();

    deleteTemplate(template["@id"]!);
  }

  function onEditClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();

    setSelectedTemplate(template);
  }

  async function closeToast(e?: Event | React.SyntheticEvent<any, Event>) {
    e?.stopPropagation();
    e?.preventDefault();
    setIsToastOpen(false);
    await submitForm(doc(templatesPath(orgId!), template["@id"]!), ActionType.Update, emptyFunction, {importStatus: null});
  }

  return (
    <FeatureWrapper feature={FeatureType.Template} viewable>

      <Stack
        key={`template-card${id}`}
        px={2}
        sx={(theme) => ({
          border: `1px solid ${isChecked ? theme.palette.primary.main : theme.palette.divider}`,
          borderRadius: 1,
          display: "flex",
          position: "relative",
          overflow: "inherit",
          cursor: "pointer",
          ":hover": {border: !isProcessing ? `1px solid ${theme.palette.primary.main}` : undefined},
          ...(isProcessing ? {borderColor: theme.palette.warning.main}: {}),
        })}
        onClick={navigateToOverview}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onMenuOpen(templateRef.current!, id, {left: e.clientX, top: e.clientY})
        }}
      >
        <Toast
          open={isToastOpen}
          onClose={closeToast}
          messageComponent={<>{enCommonLabel.importFailed}</>}
          severity={Severity.Error}
          sx={{ml: "140px", position: "fixed"}}
          duration={null}
        />
        <CardBadge
          id={`template-badge-${id}`}
          text={enTemplateLabel.importingTemplate}
          buildingProgressCount={importingProgressCount}
          toastProps={toastProps!}
          process={OverlayType.Importing}
        />
        <Stack direction="row" gap="16px" alignItems="center" width="100%" ref={templateRef}>
          <Checkbox
            sx={{
              padding: 0,
              width: "20px",
              height: "20px",
            }}
            onClick={(e) => e.stopPropagation()}
            onChange={onCardSelect}
            checked={checkedTemplates.find((templateId) => templateId === id) !== undefined}
          />
          <Divider orientation="vertical" flexItem/>
          <Stack direction="row" alignItems="center" gap="16px" width="100%" py={1}>
            <Avatar
              variant="rounded"
              sx={{
                bgcolor: avatarColor,
                height: 40,
                width: 40,
                mt: 0.5,
              }}
            >
              <SidebarIcons.Templates
                height={18}
                width={18}
                fill={theme.palette.background.paper}
              />
            </Avatar>
            <Stack
              direction="row"
              gap="16px"
              flex={1}
              alignItems="center"
              justifyItems="start"
            >
              <Stack direction="column" alignItems="start">
                <LongText variant="h4" maxWidth="100%">{name}</LongText>
                <LongText
                  variant="body"
                  maxWidth="100%"
                  color={theme.palette.text.secondary}
                >
                  {!!description ? description : <i>{enCommonLabel.noDescription}</i>}
                </LongText>
                <DataTagsDisplay
                  uid={uid}
                  dataTagsIds={template.dataTagsIds || []}
                  toastProps={toastProps!}
                  listSize="medium"
                  canEditDocumentRef={Boolean(access?.[PermissionOperationKey.Update])}
                  documentRef={doc(templatesPath(orgId!), template["@id"]!)}
                />
              </Stack>
            </Stack>
          </Stack>
          <Divider orientation="vertical" flexItem/>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" py={1}>
            <SwiftIconButton
              onClickFcn={onEditClick}
              disabled={Boolean(!access[PermissionOperationKey.Update])}
              disabledHoverLabel={enTemplateLabel.cannotEdit}
              startIcon={SystemIcons.Edit}
              sx={{p: 0.5}}
            />
            <SwiftIconButton
              onClickFcn={onDeleteClick}
              disabled={Boolean(!access[PermissionOperationKey.Delete])}
              disabledHoverLabel={enTemplateLabel.cannotDelete}
              startIcon={SystemIcons.DeleteOutlined}
              sx={{p: 0.5}}
            />
          </Stack>
        </Stack>
      </Stack>
    </FeatureWrapper>
  )
}

export default TemplateCard;