import {BaseProps} from "../BaseProps";
import {
  defaultSelectedOrg,
  emptyFunction,
  enTemplate,
  enTemplateButton,
  enTemplateLabel
} from "constants/index";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import {Content, InProgress, PageTitle} from "components/index";
import {Box} from "@mui/system";
import {useAccess, useCollection, useComponentToggler, useRTDBDocField} from "hooks/index";
import {Template} from "types/index";
import {organizationPath, sortObjectsBy, templatesPath} from "../utility";
import TemplatesView from "./TemplatesView";
import {PrimaryButton} from "components/Button";
import ManageTemplateDrawer from "components/Drawers/ManageTemplateDrawer";
import {CounterFields, DirectionalOrder, Entity} from "enums/index";
import {PermissionEntity, PermissionOperationKey} from "types/Permission";

function Templates(props: BaseProps) {
  const {selectedOrg, setSelectedOrgId = emptyFunction, uid, toastProps} = props;

  const navigate = useNavigate();
  const {orgId} = useParams();

  const templatesCount = useRTDBDocField<number>(organizationPath(orgId!).path, CounterFields.TemplatesCount) ?? 0;

  const [sortOrder, setSortOrder] = useState(DirectionalOrder.asc);
  let [templates] = useCollection<Template>(null, templatesPath(orgId!));

  if (templates) templates = sortObjectsBy<Template>(templates, "name", sortOrder);

  const [algoliaTemplates, setAlgoliaTemplates] = useState<Template[] | null>(null);
  const [access] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationTemplate,
  });

  const [isDrawerOpen, {open: openDrawer, close: closeDrawer}] = useComponentToggler(false);

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined || orgId === defaultSelectedOrg.id)
      return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  if (!access)
    return (
      <Stack
        flex={1}
        height={"100vh"}
        justifyContent="center"
      >
        <InProgress/>
      </Stack>
    );

  return (
    <Content>
      <ManageTemplateDrawer
        template={null}
        uid={uid}
        isOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
        toastProps={toastProps!}
      />
      <Stack mt={2} flex={1}>
        <Box sx={{display: "flex", justifyContent: "space-between"}}>
          <PageTitle title={enTemplate.title} count={templatesCount ?? 0}/>
          <PrimaryButton
            id="create-new-template-button"
            disabledHoverLabel={enTemplateLabel.cannotCreate}
            label={enTemplateButton.create}
            onClickFcn={openDrawer}
            disabled={!access?.[PermissionOperationKey.Create]}
          />
        </Box>
        <TemplatesView
          access={access!}
          templates={templates}
          algoliaTemplates={algoliaTemplates}
          setAlgoliaTemplates={setAlgoliaTemplates}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          {...props}
        />
      </Stack>
    </Content>
  )
}

export default Templates;