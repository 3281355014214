import {SelectWithSearch} from "components/index";
import {enProjectLabel} from "constants/index";
import theme from "theme/theme";
import {MenuItem, SxProps, Theme, Typography} from "@mui/material";
import {useCollection} from "hooks/index";
import {Template} from "types/index";
import {useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {templatesPath} from "screens/utility";
import {SelectItem} from "components/inputs/SelectWithSearch";
import {ProjectTemplateInputLabel} from "./ProjectTemplateInputLabel";
import {useNavigate} from "react-router-dom";

type ProjectTemplateInputProps = {
  uid: string;
  initialValue: string | null;
  onTemplateSelect: (templateId: string | null) => void;
  optional?: boolean;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  role: string;
}

export function ProjectTemplateInput(props: ProjectTemplateInputProps) {
  const {initialValue, optional, sx, disabled = false, role, onTemplateSelect} = props;
  const {orgId} = useParams();
  const [templates, , setTemplatesColRef] = useCollection<Template>(null, null);
  const nav = useNavigate();

  useEffect(() => {
    if (!orgId || !!templates) return;
    setTemplatesColRef(templatesPath(orgId));
  }, [orgId]);

  const selectItems:SelectItem[] = (templates ?? []).map((template) => ({
    displayItem: template.name,
    value: template["@id"] ?? (template.id ?? ""),
    searchable: template.name
  }));

  function onGearClick() {
    nav(`/${orgId}/templates?adminPanel=true`)
  }

  return (
    <SelectWithSearch
      id="project-template-search"
      items={selectItems}
      handleSelectCallback={onTemplateSelect}
      defaultValue={initialValue ?? undefined}
      LabelComponent={<ProjectTemplateInputLabel onGearClick={onGearClick} optional={optional} role={role}/>}
      wrapperSx={{borderColor: theme.palette.neutral.medium, height: 40, paddingRight: "4px"}}
      iconProps={{width: 16, height: 16}}
      placeholder={<Typography variant="body" color={theme.palette.neutral.dark}>{enProjectLabel.chooseTemplate}</Typography>}
      extraMenuItem={!selectItems.length ? (
        <MenuItem disabled={true}>
          <Typography>{enProjectLabel.noTemplates}</Typography>
        </MenuItem>
      ): undefined}
      sx={sx}
      disabled={disabled}
    />
  )
}
