import {
  Typography, Box,
  Button,
  Drawer as MuiDrawer,
  IconButton,
  Divider, Stack,
} from "@mui/material";
import {useState, useEffect, useMemo} from "react";
import {LoadingButton} from "@mui/lab";
import {styled} from "@mui/material/styles";
import {SystemIcons} from "assets/icons/system/system.index";
import {MilestoneAssignedStatus as AssignedStatus} from "enums/milestoneAssignedStatus";
import {milestoneTaskandAssetStatus as TaskStatus} from "enums/milestoneTaskandAssetStatus";
import {MilestoneScheduleStatus as ScheduleStatus} from "enums/milestoneScheduleStatus";
import {BaseProps} from "screens/BaseProps";
import AssignedPanel from "./FilterDrawerPanels/AssignedPanel";
import StatusPanel from "./FilterDrawerPanels/StatusPanel";
import SchedulePanel from "./FilterDrawerPanels/SchedulePanel";
import DatePanel from "./FilterDrawerPanels/DatePanel";
import theme from "theme/theme";
import {enCommonButton, enCommonLabel} from "constants/index";

export interface TaskFilterProps {
  assignedTo: AssignedStatus[];
  taskStatus: TaskStatus[];
  scheduleStatus: ScheduleStatus[];
  dueDate: Date | null;
}

interface MilestonesFilterDrawerProps extends BaseProps {
  isDrawerOpen: boolean;
  taskFilters: TaskFilterProps;
  isLoading: boolean;
  closeFilterDrawer: () => void;
  applySelectedFilter: (props: TaskFilterProps) => void;
}

function MilestonesFilterDrawer(props: MilestonesFilterDrawerProps) {
  const {isDrawerOpen, taskFilters, isLoading, closeFilterDrawer, applySelectedFilter} = props;

  // filter related states
  const [selectedDateFilter, setSelectedDateFilter] = useState<Date | null>(null);
  const [selectedScheduleFilter, setSelectedScheduleFilter] = useState<ScheduleStatus[]>([]);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<TaskStatus[]>([]);
  const [selectedAssignedFilter, setSelectedAssignedFilter] = useState<AssignedStatus[]>([]);
  const [isClearAllEnabled, setIsClearAllEnabled] = useState<boolean>(false);

  // once component is rendered, let copy whatever is passed from the parent to the local values
  useEffect(() => {
    setSelectedDateFilter(taskFilters.dueDate)
    setSelectedScheduleFilter(taskFilters.scheduleStatus);
    setSelectedStatusFilter(taskFilters.taskStatus);
    setSelectedAssignedFilter(taskFilters.assignedTo);
  }, []);

  async function onApplyFilterButtonClick() {
    applySelectedFilter({
      dueDate: selectedDateFilter,
      scheduleStatus: selectedScheduleFilter,
      taskStatus: selectedStatusFilter,
      assignedTo: selectedAssignedFilter,
    });
  }

  async function clearAllFilters() {
    setSelectedDateFilter(null);
    setSelectedScheduleFilter([]);
    setSelectedStatusFilter([]);
    setSelectedAssignedFilter([]);
  }

  useMemo(() => {
    setIsClearAllEnabled(selectedDateFilter === null
      && selectedAssignedFilter.length === 0
      && selectedStatusFilter.length === 0
      && selectedScheduleFilter.length === 0
    )
  }, [selectedDateFilter, selectedAssignedFilter, selectedStatusFilter, selectedScheduleFilter]);

  return (
    <>
      <MuiDrawer id="milestones-filter-drawer" anchor="right" open={isDrawerOpen} onClose={closeFilterDrawer}>
        <BoxMain sx={{overflow: "hidden"}}>
          <Box sx={{display: "flex", alignSelf: "flex-end", gap: 1, pt: 1, mb: 0, mr: 0}}>
            <IconButton
              size="small"
              onClick={closeFilterDrawer}
              sx={{alignSelf: "flex-end"}}
            >
              <SystemIcons.Close
                id="milestone-filter-drawer-close-button"
                stroke={theme.palette.neutral.dark}
              />
            </IconButton>
          </Box>

          <HeaderIcon mt="-5%" pt="12px" pl="9px" mb={"3%"}>
            <SystemIcons.Filter height={24} width={24}/>
          </HeaderIcon>

          <Typography sx={{alignSelf: "center", mb: 4}} variant="h3">
            {enCommonLabel.filters}
          </Typography>

          <FilterBox>
            <Divider/>
            <AssignedPanel
              selectedFilter={selectedAssignedFilter}
              setSelected={(obj: AssignedStatus[]) => setSelectedAssignedFilter(obj)}
            />
            <Divider/>
            <StatusPanel
              selectedFilter={selectedStatusFilter}
              setSelected={(obj: TaskStatus[]) => setSelectedStatusFilter(obj)}
            />
            <Divider/>
            <SchedulePanel
              selectedFilter={selectedScheduleFilter}
              setSelected={(obj: ScheduleStatus[]) => setSelectedScheduleFilter(obj)}
            />
            <Divider/>
            <DatePanel
              selectedDateFilter={selectedDateFilter}
              setSelected={(obj: Date | null) => setSelectedDateFilter(obj)}
            />
          </FilterBox>

          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            sx={{
              pt: 1,
              mb: 0,
              mr: 0
            }}
          >
            <Button
              id="milestone-filter-clear-all-button"
              disabled={isClearAllEnabled}
              onClick={() => clearAllFilters()}
            >
              <Typography variant="h4">
                {enCommonButton.clearAll}
              </Typography>
            </Button>
            <Box sx={{display: "flex", gap: 1}}>
              <Button
                onClick={closeFilterDrawer}
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.secondary,
                })}
                id="milestone-drawer-cancel-btn"
              >
                <Typography variant="h4">
                  {enCommonButton.cancel}
                </Typography>
              </Button>
              <LoadingButton
                loading={isLoading}
                onClick={onApplyFilterButtonClick}
                variant="contained"
                id="milestone-drawer-apply-filter-btn"
              >
                <Typography variant="h4">
                  {enCommonButton.apply}
                </Typography>
              </LoadingButton>
            </Box>
          </Stack>
        </BoxMain>
      </MuiDrawer>
    </>
  );
}

/** styles start */
const BoxMain = styled(Box)({
  height: "100%",
  width: "360px",
  display: "flex",
  flexDirection: "column",
  padding: "7%",
});

const HeaderIcon = styled(Box)({
  backgroundColor: theme.palette.background.swiftDefault,
  height: "40px",
  width: "40px",
  borderRadius: "20px",
  justifyContent: "center",
  alignSelf: "center",
  alignItems: "center",
});

const FilterBox = styled(Box)({
  flex: 1,
  overflowY: "auto",
  overflowX: "hidden",
  '::-webkit-scrollbar': {width: 0}
});
/** styles end */

export default MilestonesFilterDrawer;
