import React, {Dispatch} from "react";
import {IconButton, Stack, Typography} from "@mui/material";
import SystemIndex, {SystemIcons} from "assets/icons/system/system.index";
import {enHomeButton, enHomeLabel} from "constants/index";
import {SecondaryButton} from "components/Button";
import TaskSearchAndFilters, {TaskSearchAndFiltersProps} from "./TaskSearchAndFilters";
import {TaskData} from "./queryAlgolia";

export interface CollapsableTaskViewProps extends TaskSearchAndFiltersProps {
  withTasks: boolean;
  myTasksCount: number;
  isTaskCollapsed: boolean;
  setIsTaskCollapsed: React.Dispatch<boolean>;
  setAlgoliaResults: Dispatch<TaskData[] | null>;
  showExportTasksDialog: () => void;
}

function CollapsableTaskView(props: CollapsableTaskViewProps) {
  const {withTasks, myTasksCount, setIsTaskCollapsed, isTaskCollapsed, showExportTasksDialog, ...rest} = props;

  return (
    <Stack gap={1} mb={1} sx={{maxWidth: "100vw"}}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={0.5}>
          <IconButton size="small" onClick={() => setIsTaskCollapsed(!isTaskCollapsed)}>
            {isTaskCollapsed ? <SystemIndex.SystemIcons.ChevronDown/> : <SystemIndex.SystemIcons.ChevronUp/>}
          </IconButton>
          <Stack direction="row" gap={0.5} alignItems="baseline">
            <Typography variant="h4">{enHomeLabel.tasks}</Typography>
            <Typography variant="h5" component="span" color="text.secondary">({myTasksCount})</Typography>
          </Stack>
        </Stack>
        {withTasks && !isTaskCollapsed && (
          <SecondaryButton
            label={enHomeButton.exportTasks}
            disabledHoverLabel={enHomeLabel.noTasksToExport}
            onClickFcn={showExportTasksDialog}
            disabled={false}
            startIcon={SystemIcons.Export}
          />
        )}
      </Stack>
      <TaskSearchAndFilters
        {...rest}
        visible={withTasks && !isTaskCollapsed}
      />
    </Stack>
  )
}

export default CollapsableTaskView;