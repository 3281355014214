import React from "react";
import {
  Collapse,
  Divider,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import {enCommonLabel} from "constants/index";
import theme from "theme/theme";
import {Entity, Severity} from "enums/index";
import {
  assetPath,
  fileRequirementPath,
  milestonePath,
  projectLevelFileRequirementPath,
  projectLevelMilestonePath,
  projectPath,
  taskPath
} from "screens/utility";
import {toastProps} from "screens/BaseProps";
import getEnvKey from "screens/utility/getEnvKey";
import {downloadUrlKey} from "constants/envKeys";
import {useParams} from "react-router-dom";
import {Buffer} from "buffer";

const DOWNLOAD_URL = getEnvKey(downloadUrlKey) || "";

interface DownloadOptionsMenuProps extends Partial<MenuProps> {
  uid: string;
  entity: Entity;
  filesCount: number;
  onClose: () => void;
  anchorEl?: Element | null;
  isCollapsible?: boolean;
  extraIds?: Record<string, string>;
  isDownloadOptionsCollapseOpen?: boolean;
  toastProps: toastProps;
}

  function DownloadOptionsMenu(props: DownloadOptionsMenuProps) {
  const {anchorEl, entity, isCollapsible, extraIds, isDownloadOptionsCollapseOpen, onClose} = props;
  const {setIsToastOpen, setToastSeverity, setToastMessage} = props.toastProps!;

  const {orgId, projId, assetId, milestoneId, taskId, fileRequirementId = extraIds?.requirementId ?? ''} = useParams();

  if (DOWNLOAD_URL === "") {
    setToastMessage("Download URL is not set in .env file");
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
    return null;
  }

  const reference = getDocumentReference();
  if (!reference) return null;

  const base64 = Buffer.from(reference.path, 'utf-8').toString('base64');
  const downloadUrl = DOWNLOAD_URL+`${base64}`;

  function getDocumentReference() {
    let reference = null;
    switch (entity) {
      case Entity.Asset:
        reference = assetPath(orgId!, projId!, assetId!);
        break;
      case Entity.Task:
      case Entity.PLTask:
        reference = taskPath(orgId!, projId!, assetId!, milestoneId!, taskId!);
        break;
      case Entity.Milestone:
        reference = milestonePath(orgId!, projId!, assetId!, milestoneId ?? (extraIds ?? {}).milestoneId);
        break;
      case Entity.FileRequirement:
        reference = fileRequirementPath(orgId!, projId!, assetId!, milestoneId!, taskId!, fileRequirementId!);
        break;
      case Entity.ProjectLevelTask:
        reference = projectPath(orgId!, projId!);
        break;
      case Entity.PLMilestone:
        reference = projectLevelMilestonePath(orgId!, projId!, milestoneId ?? (extraIds ?? {}).milestoneId);
        break;
      case Entity.PLFileRequirement:
        reference = projectLevelFileRequirementPath(orgId!, projId!, milestoneId!, taskId!, fileRequirementId!);
        break;
    }

    return reference;
  }

  if (isCollapsible) {
    return (
      <>
        <Collapse in={isDownloadOptionsCollapseOpen}>
          <Stack
            sx={{
              px: 2,
              backgroundColor: theme.palette.background.swiftDefault,
            }}
          >
            <ListItemText sx={{alignSelf: "center"}}>
              <Typography variant="bodySmall" py={0.5}>
                {enCommonLabel.downloadFilesWith}
              </Typography>
            </ListItemText>
            <Divider sx={{display: "block"}}/>
            <Stack sx={{my:  0.5}} gap={0.5}>
              <a
                href={`${downloadUrl}?useOriginal=true`}
                style={{textDecoration: "none", color: theme.palette.secondary.main}}
              >
                <MenuItem>
                  <Typography fontWeight={700}>{enCommonLabel.downloadOptions.originalFileName}</Typography>
                </MenuItem>
              </a>
              <a
                href={`${downloadUrl}?useOriginal=false`}
                style={{textDecoration: "none", color: theme.palette.secondary.main}}
              >
                <MenuItem>
                  <Typography fontWeight={700}>{enCommonLabel.downloadOptions.requirementFileName}</Typography>
                </MenuItem>
              </a>
            </Stack>
          </Stack>
        </Collapse>
      </>
    )
  }

  return (
    <Paper>
      <Menu
        {...props}
        open={!!anchorEl}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: "top",
          horizontal: 153
        }}
        onClose={onClose}
      >
        <ListItemText sx={{mx: 2}}>
          <Typography variant="bodySmall">
            {enCommonLabel.downloadFilesWith}
          </Typography>
        </ListItemText>
        <Divider sx={{mx: 2, display: "block"}}/>
        <a
          href={`${downloadUrl}?useOriginal=true`}
          style={{textDecoration: "none", color: theme.palette.secondary.main}}
        >
          <MenuItem>
            <Typography fontWeight={700}>{enCommonLabel.downloadOptions.originalFileName}</Typography>
          </MenuItem>
        </a>
        <a
          href={`${downloadUrl}?useOriginal=false`}
          style={{textDecoration: "none", color: theme.palette.secondary.main}}
        >
          <MenuItem>
            <Typography fontWeight={700}>{enCommonLabel.downloadOptions.requirementFileName}</Typography>
          </MenuItem>
        </a>
      </Menu>
    </Paper>
  )
}

export default DownloadOptionsMenu;