import {milestoneTaskandAssetStatus as TaskStatus} from "enums/milestoneTaskandAssetStatus";
import {RequirementStatus, ActionType, Severity, ProcessType} from "enums/index";
import {FileRequirement} from "types/FileRequirement";
import SubmitButton from "components/Button/SubmitButton";
import ApproveButton from "components/Button/ApproveButton";
import {StatusItem} from "types/StatusItem";
import React, {useState} from "react";
import submitForm from "../../utility/submitForm";
import {RejectionDialog} from "components/Dialogs";
import {DocumentReference} from "firebase/firestore";
import {toastProps} from "../../BaseProps";
import {enCommonLabel} from "constants/index";
import {statusSubmitHandler} from "../../utility/statusSubmitHandler";

interface TaskStatusButtonFunctionProps {
  isDisabled: boolean,
  selectedStatus: StatusItem | undefined,
  documentRef: DocumentReference;
  toastProps?: toastProps;
}

export default function TaskStatusButtonFunction(props: TaskStatusButtonFunctionProps) {
  const {selectedStatus, isDisabled, documentRef, toastProps} = props;
  const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps!;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRejectionDialogOpen, setIsRejectionDialogOpen] = useState<boolean>(false);

  async function onUpdateFileRequirementStatusClick(status: RequirementStatus) {
    switch (status) {
      case RequirementStatus.Rejected:
        setIsRejectionDialogOpen(true);
        break;
      default:
        setIsLoading(true);
        await updateFileRequirementStatus(status);
        break;
    }
  }

  async function updateFileRequirementStatus(requirementStatus: RequirementStatus, otherFields: any = {}) {
    await submitForm<FileRequirement>(documentRef, ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler({
        status, data, isLastUpdate,
        successCallback: () => successCallback(requirementStatus),
        errorCallback
      }), {
        fileRequirementStatus: requirementStatus,
        ...otherFields
      });
  }

  function successCallback(status: RequirementStatus) {
    setToastMessage(`${enCommonLabel.fileRequirement} ${status} ${enCommonLabel.successfullyUpdated}`);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    setIsLoading(false);
  }

  function errorCallback(message: any) {
    let toastMessage = enCommonLabel.errorProcess(ProcessType.Update);
    // if type of message is obj, get first obj value
    if (typeof message === "object" && (typeof Object.values(message)[0] === "string")) {
      toastMessage = Object.values(message)[0] as string;
    }

    setToastMessage(toastMessage);
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
    setIsLoading(false);
  }

  async function confirmRejectionDialog(reason: string) {
    await updateFileRequirementStatus(RequirementStatus.Rejected, {reasonForRejection: reason});
    setIsRejectionDialogOpen(false);
  }

  if (!selectedStatus) return <></>;

  return (
    <>
      <RejectionDialog
        isOpen={isRejectionDialogOpen}
        handleClose={() => setIsRejectionDialogOpen(false)}
        handleConfirm={(reason: string) => confirmRejectionDialog(reason)}
      />
      {selectedStatus.title === TaskStatus.Submitted && (
        <ApproveButton
          onClick={() => onUpdateFileRequirementStatusClick(RequirementStatus.Approved)}
          isLoading={isLoading}
          isDisabled={isDisabled}
        />
      )}
      {selectedStatus.title === TaskStatus.InProgress && (
        <SubmitButton
          onClick={() => onUpdateFileRequirementStatusClick(RequirementStatus.Submitted)}
          isLoading={isLoading}
          isDisabled={isDisabled}
        />
      )}
    </>
  )
}