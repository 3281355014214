import {Box, Stack, Typography} from "@mui/material";
import {HeadProps} from "./Objects/HeadProps";
import {enAssetLabel} from "constants/index";
import {PermissionOperationKey} from "types/Permission";
import {SecondaryButton} from "components/Button";

function Head({drawerOpener, access}: HeadProps) {

  return (
    <Stack direction="column" display="flex" py={2} gap={2}>
      <Stack direction="row" display="flex" gap={2}>
        <Box flex={3}>
          <Stack direction="column" sx={{gap: 1}}>
            <Stack direction="row" sx={{gap: 1}}>
              <Typography variant="h1">
                {enAssetLabel.manage}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Stack direction="column" sx={{gap: 1}}>
            <SecondaryButton
              id="newAssetBtn"
              onClickFcn={drawerOpener}
              label={enAssetLabel.new}
              disabled={!Boolean(access?.[PermissionOperationKey.Create])}
            />
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}

export default Head;
