import {Timestamp} from "firebase/firestore";

function calculateMsFromStartDate(lastTimerStartedDateTime: Timestamp | null | undefined) {

  if (!lastTimerStartedDateTime)
    return 0

  const startDate = lastTimerStartedDateTime

  const fireBaseTime =  new Date((startDate.seconds * 1000) + (startDate.nanoseconds / 1000000));
  const currentDate = new Date(Date.now());


  return currentDate.valueOf() - fireBaseTime.valueOf();
}

export default calculateMsFromStartDate
