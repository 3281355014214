import {collection, CollectionReference, QueryConstraint} from "firebase/firestore";
import {useCollection} from "hooks/index";
import {useEffect, useState} from "react";
import {db} from "../firebase";
import {TypeData} from "hooks/useCollectionData";
import {sortObjectsBy} from "../screens/utility";
import {getTemplateCopyPath} from "../screens/utility/templateSubmitForm";

interface useCollectionWithTemplateProps {
  colRef: CollectionReference | null
  projectConstraints?: QueryConstraint[] | null,
  templateConstraints?: QueryConstraint[] | null,
  templateId?: string
}

interface useCollectionWithTemplateReturn<T> {
  data: TemplateDataType<T>[] | null,
  updateProjectConstraints: (constraints: QueryConstraint[]) => void,
  updateTemplateContraints: (constraints: QueryConstraint[]) => void,
}

interface collectionRefData {
  docColRef: string
  fromTemplate: boolean
}

export type TemplateDataType<T> = collectionRefData & TypeData<T>;

function useCollectionWithTemplate<T>(props: useCollectionWithTemplateProps): useCollectionWithTemplateReturn<T> {
  const {colRef, projectConstraints, templateConstraints} = props;

  const [projectBasedCollections, projectUpdateProvidedConstrains] = useCollection<TemplateDataType<T>>(null, colRef, projectConstraints);
  const [templateBasedCollections, templateUpdateProvidedConstrains, setTemplateColRef] = useCollection<TemplateDataType<T>>(null, null, templateConstraints);
  const [mergedCollections, setMergedCollections] = useState<TemplateDataType<T>[] | null>(null);

  const templateColRef = colRef ? getTemplateCopyPath(colRef.path) : null

  //generate template collection reference
  useEffect(() => {
    if (!templateColRef)
      return;

    setTemplateColRef(collection(db, templateColRef))
  }, [templateColRef]);

  //merge collections
  useEffect(() => {
    if (!projectBasedCollections && !templateBasedCollections) {
      setMergedCollections(null)
    } else if (projectBasedCollections && !templateBasedCollections) {
      // const sortedCollection = sortObjectsBy<TemplateDataType<T>>(projectBasedCollections, "name")
      setMergedCollections(projectBasedCollections.map(item => ({
        ...item,
        docColRef: colRef?.path,
        fromTemplate: false
      })))
    } else if (!projectBasedCollections && templateBasedCollections) {
      // const sortedCollection = sortObjectsBy<TemplateDataType<T>>(templateBasedCollections, "name")
      setMergedCollections(templateBasedCollections.map(item => ({
        ...item,
        docColRef: templateColRef,
        fromTemplate: true
      })))
    } else {
      if (!projectBasedCollections || !templateBasedCollections)
        return;

      // const projectCollectionNames = projectBasedCollections.map((item) => item.name);
      const projectCollectionIds = projectBasedCollections.map((item) => item["@id"]);
      //filter same names and prioritize project collections
      const mergedCollections = [
        ...projectBasedCollections.map(item => ({...item, docColRef: colRef?.path, fromTemplate: false})),
        ...templateBasedCollections.filter((item) => !projectCollectionIds.includes(item["@id"]))
          .map(item => ({
            ...item,
            docColRef: templateColRef,
            fromTemplate: true
          }))
      ];
      const sortedCollection = sortObjectsBy<TemplateDataType<T>>(mergedCollections, "name")
      setMergedCollections(sortedCollection);
    }
  }, [projectBasedCollections, templateBasedCollections, templateColRef]);

  function updateProjectConstraints(constraints: QueryConstraint[]) {
    projectUpdateProvidedConstrains(constraints);
  }

  function updateTemplateContraints(constraints: QueryConstraint[]) {
    templateUpdateProvidedConstrains(constraints);
  }

  return {
    data: mergedCollections,
    updateProjectConstraints,
    updateTemplateContraints,
  }
}

export default useCollectionWithTemplate;