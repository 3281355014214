import {Timestamp} from "firebase/firestore";

export function getTimeDifferenceString(startDate: Timestamp, endDate?: Timestamp): string {
  // @ts-ignore - seconds and nanoseconds are different when they are from algolia
  const {seconds: fromSeconds, _seconds: _fromSeconds, nanoseconds: fromNanoSeconds, _nanoseconds: _fromNanoSeconds} = startDate;
  const startSeconds = fromSeconds || _fromSeconds || 0;
  const startNanoSeconds = fromNanoSeconds || _fromNanoSeconds || 0;
  const fromDate = new Date(startSeconds * 1000 + startNanoSeconds / 1000000);

  // @ts-ignore - seconds and nanoseconds are different when they are from algolia
  const {seconds: toSeconds, _seconds: _toSeconds, nanoseconds: toNanoSeconds, _nanoseconds: _toNanoSeconds} = endDate ?? Timestamp.now();
  const endSeconds = toSeconds || _toSeconds || 0;
  const endNanoSeconds = toNanoSeconds || _toNanoSeconds || 0;
  const toDate = new Date(endSeconds * 1000 + endNanoSeconds / 1000000);

  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerWeek = msPerDay * 7;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = toDate.getTime() - fromDate.getTime();

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + ' seconds ago';
  } else if (elapsed < msPerHour) {
    const minutes = Math.floor(elapsed / msPerMinute);
    return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
  } else if (elapsed < msPerDay) {
    const hours = Math.floor(elapsed / msPerHour);
    const minutes = Math.floor((elapsed % msPerHour) / msPerMinute);
    const hoursString = hours === 1 ? '1 hour' : `${hours} hours`;
    if(minutes === 0)
      return `${hoursString} ago`;

    const minutesString = minutes === 1 ? '1 minute' : `${minutes} minutes`;
    return `${hoursString} and ${minutesString} ago`;
  } else if (elapsed < msPerWeek) {
    const days = Math.floor(elapsed / msPerDay);
    const hours = Math.floor((elapsed % msPerDay) / msPerHour);
    const daysString = days === 1 ? '1 day' : `${days} days`;
    if(hours === 0)
      return `${daysString} ago`;

    const hoursString = hours === 1 ? '1 hour' : `${hours} hours`;
    return `${daysString} and ${hoursString} ago`;
  } else if (elapsed < msPerMonth) {
    const weeks = Math.floor(elapsed / msPerWeek);
    const days = Math.floor((elapsed % msPerWeek) / msPerDay);
    const weeksString = weeks === 1 ? '1 week' : `${weeks} weeks`;
    if(days === 0)
      return `${weeksString} ago`;

    const daysString = days === 1 ? '1 day' : `${days} days`;
    return `${weeksString} and ${daysString} ago`;
  } else if (elapsed < msPerYear) {
    const months = Math.floor(elapsed / msPerMonth);
    const weeks = Math.floor((elapsed % msPerMonth) / msPerWeek);
    const monthsString = months === 1 ? '1 month' : `${months} months`;
    if(weeks === 0)
      return `${monthsString} ago`;

    const weeksString = weeks === 1 ? '1 week' : `${weeks} weeks`;
    return `${monthsString} and ${weeksString} ago`;
  } else {
    const years = Math.floor(elapsed / msPerYear);
    const months = Math.floor((elapsed % msPerYear) / msPerMonth);
    const yearsString = years === 1 ? '1 year' : `${years} years`;
    if(months === 0)
      return `${yearsString} ago`;

    const monthsString = months === 1 ? '1 month' : `${months} months`;
    return `${yearsString} and ${monthsString} ago`;
  }
}
