import {FileUploadReducerState} from "./FileUploadReducerState";

export const initialState: FileUploadReducerState = {
  isPanelOpen: false,
  // files dropped and is currently processed
  processingFiles: [],
  // list of files with error message
  errorFiles: [],
  // list of file ids that has been removed
  removedFileIds: [],
  processingCount: 0,
  completedCount: 0,
  uploadBatch: [],
  processingBatchId: [],
  lastBatchId: 0,
  lastProcessingIndex: 0,
  isWorking: false,
  completedFiles: [],
  deletedIds: new Set<string>(),
}