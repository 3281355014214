import {ReferenceFileUsageProps} from "./index";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import OverflowBox from "components/OverflowBox";
import FilePreview from "./FilePreview";
import theme from "theme/theme";
import React, {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../../firebase";
import {enFileRequirementLabel} from "constants/index";
import downloadFile from "../../screens/utility/downloadFile";
import {LongText} from "components/index";
import {CarouselItem} from "components/ChatDrawer/ChatItemsView/ChatAttachmentCarousel";
import getEnvKey from "../../screens/utility/getEnvKey";
import {storageBucketKey} from "constants/envKeys";
import {Previews} from "enums/supportedPreviews";
import getContentType from "../../screens/utility/getContentType";

interface ReferenceFileModalProps extends ReferenceFileUsageProps {
  isOpen: boolean;
  onClose: () => void;
  fileUrl?: string;
  fileType: string;
  setCarouselItems: React.Dispatch<React.SetStateAction<CarouselItem[]>>;
  onFilePreviewClick: () => void;
}

export function ReferenceFileModal(props: ReferenceFileModalProps) {
  const {referenceFile, isOpen, onClose, onFilePreviewClick, setCarouselItems, fileType} = props;
  const {name, description, filepath, fileName} = referenceFile;
  const [isLoading, setIsLoading] = useState(true)
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);
  const [contentType, setContentType] = useState<Previews>(Previews.File);

  const storageBucket = getEnvKey(storageBucketKey);

  useEffect(() => {
    if ((typeof (filepath) !== "string" && filepath !== "") || !storageBucket) return;
    setContentType(getContentType(filepath));

    const fileReference = ref(storage, `${storageBucket}${filepath}`);
    getDownloadURL(fileReference).then(setFileUrl).catch(() => setFileUrl(undefined));
  }, [])

  useEffect(() => {
    if (fileUrl)
      setIsLoading(false);
  }, [fileUrl])

  function onDownloadFileClick() {
    downloadFile(fileUrl!, fileName ?? "")
  }

  function onPreviewClick() {
    if (!fileName || !fileUrl) return;
    if (contentType === Previews.File) {
      onDownloadFileClick();
      return;
    }

    setCarouselItems([{
      type: fileType,
      nameWithExt: fileName,
      source: fileUrl
    }]);
    onFilePreviewClick();
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md">
      <DialogTitle>
        <Stack direction={{xs: fileUrl ? "column-reverse" : "row", md: "row"}} justifyContent="space-between"
               alignItems="center">
          <LongText maxLines={2} variant="h3">
            {name}
          </LongText>
          <Stack
            direction="row"
            gap={1}
            sx={{
              justifyContent: {xs: "space-between", md: "none"},
              width: {xs: fileUrl ? "100%" : "auto", md: "auto"}
            }}>
            {fileUrl &&
              <LoadingButton
                onClick={onDownloadFileClick}
                loading={isLoading}
                variant="outlined"
                color="secondary"
                sx={{borderColor: theme.palette.divider}}
              >
                <Stack direction="row" gap={1} alignItems="center">
                  <SystemIcons.Download/>
                  <Typography variant="h4">
                    {enFileRequirementLabel.download}
                  </Typography>
                </Stack>
              </LoadingButton>
            }
            <IconButton onClick={onClose}>
              <SystemIcons.Close/>
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{minHeight: 400, minWidth: {xs: 300, md: 900}}}>
        <OverflowBox minWidth="md">
          <Stack direction="column" gap={2}>
            <Box sx={{marginX: "auto", cursor: "pointer"}} onClick={onPreviewClick}>
              <FilePreview referenceFile={referenceFile}/>
            </Box>
            {!!description && (
              <Typography fontSize={16} sx={{wordBreak: "break-word", whiteSpace: "pre-wrap"}}>
                {description.replace(/<br>/g, "\n")}
              </Typography>
            )}
          </Stack>
        </OverflowBox>
      </DialogContent>
    </Dialog>
  );
}
