import {useOutletContext, useParams} from "react-router-dom";
import {doc, DocumentReference, Timestamp} from "firebase/firestore";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {Project} from "types/Project";
import Header from "./Header";
import {emptyFunction, enCommonLabel, enProjectLabel} from "constants/index";
import {BaseProps} from "../BaseProps";
import {useNavigate} from "react-router";
import {useAccess, useComponentToggler, useDocument} from "hooks/index";
import {projectsPath, submitForm} from "../utility";
import {AccessRole, ActionType, Entity, ProcessType, Severity} from "enums/index";
import {ConfirmDialog, Content, InProgress} from "components/index";
import {PermissionEntity} from "types/Permission";
import {statusSubmitHandler} from "../utility/statusSubmitHandler";
import {SelectedOrgContext} from "../SelectedOrgContextProvider";
import OverviewContent from "./OverviewContent";
import {OutletContextType} from "components/RestrictedPage";
import {TemplateId} from "components/TemplateChecker";

function ProjectOverview(props: BaseProps) {
  const {isLoading} = useOutletContext<OutletContextType>();
  const {toastProps, uid, setSelectedOrgId = emptyFunction} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  const {orgId, projId} = useParams();

  const selectedOrgContext = useContext(SelectedOrgContext);
  const {selectedOrg} = selectedOrgContext!;

  const projectsRef = doc(projectsPath(orgId!), projId!);
  const [project] = useDocument<Project>(projectsRef);

  const [deleteRef, setDeleteRef] = useState<DocumentReference[]>([]);
  const [isDialogOpen, {open: openDialog, close: closeDialog}] = useComponentToggler(false);

  const navigate = useNavigate();

  // fetch permission
  const [access] = useAccess({
    uid: uid!,
    entity: Entity.Project,
    documentDocId: PermissionEntity.Project,
  });

  useMemo(() => {
    const storageTemplateIds= localStorage.getItem("templateIds");
    const objectifiedTemplateIds : Record<string, TemplateId> = storageTemplateIds ? JSON.parse(storageTemplateIds) : {}

    if (project?.projectAssetTemplateId) {
      localStorage.setItem("templateId", project?.projectAssetTemplateId);
      objectifiedTemplateIds[project?.["@id"]!] = ({id: project?.projectAssetTemplateId, dateCreated: Date.now()})
    } else {
      localStorage.setItem("templateId", "")
      delete objectifiedTemplateIds[project?.["@id"]!]
    }

    const stringified = JSON.stringify(objectifiedTemplateIds);
    localStorage.setItem("templateIds", stringified)
  }, [project?.projectAssetTemplateId]);

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);

    submitForm<Partial<Project>>(projectsRef, ActionType.Update, emptyFunction, {lastVisited: Timestamp.now()})
  }, []);

  function deleteProject() {
    const docReference = doc(projectsPath(orgId!), projId!);
    setDeleteRef([docReference]);
    openDialog();
  }

  async function startDelete() {
    await submitForm(deleteRef![0], ActionType.Delete,
      (status, data, isLastUpdate) => statusSubmitHandler<Project>({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback
      })
    );
  }

  function successCallback() {
    setToastSeverity(Severity.Success);
    setToastMessage(enProjectLabel.deleteSuccess);
    setIsToastOpen(true);
    navigate(`/${orgId}/projects`);
    closeDialog();
  }

  function errorCallback(message: any) {
    setToastSeverity(Severity.Error);
    setToastMessage(message || enCommonLabel.errorProcess(ProcessType.Delete));
    setIsToastOpen(true);
    closeDialog();
  }

  if (!project || !access || isLoading)
    return <Content><InProgress/></Content>;

  return (
    <>
      <ConfirmDialog
        handleClose={closeDialog}
        isOpen={isDialogOpen}
        handleConfirm={startDelete}
        title={enProjectLabel.deleteConfirmationTitle(project?.name)}
        text={enProjectLabel.deleteConfirmationText}
      />
      <Header
        project={project}
        isPrivate={project.isPrivate}
        toastProps={toastProps}
        access={access!}
        projectPath={projectsRef.path}
        role={selectedOrg?.accessRole || AccessRole.User}
        uid={uid!}
        deleteProject={deleteProject}
      />
      <OverviewContent
        projectDocPath={projectsRef.path}
        access={access}
        uid={uid}
        toastProps={toastProps!}
      />
    </>
  );
}

export default ProjectOverview;
