import React, {useEffect, useState} from "react";
import {BaseProps} from "screens/BaseProps";
import {Content, InProgress} from "components";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {FileRequirement, FormRequirement, Task} from "types/index";
import {getDocPath, organizationMembersPath, tasksPath} from "../utility";
import {Stack} from "@mui/material";
import Header from "./Header";
import Head from "./Head";
import FileRequirementsList from "./FileRequirementsList";
import {collection, doc} from "firebase/firestore";
import {CounterFields, Entity} from "enums/index";
import {useComponentToggler} from "hooks/index";
import {allStatus, emptyFunction} from "constants/index";
import ChatDrawer from "components/ChatDrawer";
import SearchAndFilter from "./Objects/SearchAndFilter";
import FormRequirementsList from "./FormRequirementsList";
import {ManageFileRequirementDrawer, ManageFormRequirementDrawer} from "components/Drawers";
import NoPermission from "../NoPermission";
import {PermissionEntity, PermissionOperationKey} from "types/Permission";
import StickySearchAndFilter from "components/StickySearchAndFilter";
import {UploadFile} from "hooks/useFileUpload/useFileUpload";
import PageNotFound from "../PageNotFound";
import useTemplateAccess from "hooks/useTemplateAccess";
import useTemplateDocument from "hooks/useTemplateDocument";
import useTemplateRTDBDocField from "hooks/useTemplateRTDBDocField";
import {OutletContextType} from "components/RestrictedPage";

export const enum REQUIREMENT_TYPE {
  fileRequirement = "file-requirements",
  formRequirement = "form-requirements"
}

interface TaskOverviewProps extends BaseProps {
  addFiles: (files: UploadFile[]) => void;
}

function TaskOverview(props: TaskOverviewProps) {
  const {toastProps, selectedOrg, uid, setSelectedOrgId = emptyFunction, ...rest} = props;
  const {isLoading} = useOutletContext<OutletContextType>();
  const {orgId, projId, assetId, milestoneId, taskId, templateId} = useParams();

  const navigate = useNavigate();

  const [statusFilter, setStatusFilter] = useState<string>(allStatus);
  const [algoliaResults, setAlgoliaResults] = useState<any[] | null>(null);
  const [algoliaFileReqs, setAlgoliaFileReqs] = useState<FileRequirement[] | null>(null);
  const [algoliaFormReqs, setAlgoliaFormReqs] = useState<FormRequirement[] | null>(null);

  const taskCollectionRef = tasksPath(orgId!, projId!, assetId!, milestoneId!, templateId!);
  const parentEntity = templateId ? Entity.Templates : assetId! ? Entity.Asset : Entity.ProjectLevelTask;
  const tasksDocRef = doc(taskCollectionRef, taskId!);

  const [task, setTaskDocRef, actualTaskRef] = useTemplateDocument<Task>(tasksDocRef);

  // counters:
  const rtdbPath = parentEntity === Entity.Templates ? tasksDocRef.path : getDocPath(uid, tasksDocRef.path);
  const filesCount = useTemplateRTDBDocField<number>(rtdbPath, CounterFields.FilesCount) ?? 0;
  const requirementsRejectedCount = useTemplateRTDBDocField<number>(rtdbPath, CounterFields.RequirementsRejectedCount) ?? 0;
  const requirementsCount = useTemplateRTDBDocField<number>(rtdbPath, CounterFields.RequirementsCount) ?? 0;
  const requirementsApprovedCount = useTemplateRTDBDocField<number>(rtdbPath, CounterFields.RequirementsApprovedCount) ?? 0;
  const formRequirementsCount = useTemplateRTDBDocField<number>(rtdbPath, CounterFields.FormRequirementsCount) ?? 0;
  const fileRequirementsCount = useTemplateRTDBDocField<number>(rtdbPath, CounterFields.FileRequirementsCount) ?? 0;

  const [selectedPath, setSelectedPath] = useState<REQUIREMENT_TYPE>(REQUIREMENT_TYPE.fileRequirement);
  const [selectedRequirement, setSelectedRequirement] = useState<FileRequirement | FormRequirement | null>(null);

  const [isHiddenShown, {toggle: setHiddenShown}] = useComponentToggler(false);

  // Access / Permissions
  const [fileRequirementsAccess] = useTemplateAccess({
    uid,
    entity: Entity.Task,
    documentDocId: parentEntity === Entity.Asset ? PermissionEntity.FileRequirement
      : parentEntity === Entity.ProjectLevelTask ? PermissionEntity.PLFileRequirement
        : PermissionEntity.OrganizationTemplateFileRequirement,
    accessListRef: parentEntity === Entity.Templates ? organizationMembersPath(orgId!) : collection(tasksDocRef, "accessList"),
  });

  const [formRequirementAccess] = useTemplateAccess({
    uid,
    entity: Entity.Task,
    documentDocId: parentEntity === Entity.Asset ? PermissionEntity.FormRequirement
      : parentEntity === Entity.ProjectLevelTask ? PermissionEntity.PLFormRequirement
        : PermissionEntity.OrganizationTemplateFormRequirement,
    accessListRef: parentEntity === Entity.Templates ? organizationMembersPath(orgId!) : collection(tasksDocRef, "accessList"),
  });

  const [taskAccess] = useTemplateAccess({
    uid,
    entity: parentEntity === Entity.Templates ? Entity.Organization : Entity.Task,
    documentDocId: parentEntity === Entity.Asset ? PermissionEntity.Task
      : parentEntity === Entity.ProjectLevelTask ? PermissionEntity.PLTask
        : PermissionEntity.OrganizationTemplateTask,
    accessListRef: parentEntity === Entity.Templates ? organizationMembersPath(orgId!) : collection(tasksDocRef, "accessList"),
  });

  const [isRequirementChatDrawerOpen, {
    open: openRequirementChatDrawer,
    close: closeRequirementChatDrawer
  }] = useComponentToggler(false);
  const [isFileReqDrawerOpen, {
    open: openFileReqDrawer,
    close: closeFileReqDrawer
  }] = useComponentToggler(false);
  const [isFormReqDrawerOpen, {
    open: openFormReqDrawer,
    close: closeFormReqDrawer,
  }] = useComponentToggler(false);

  //change document listened when changing task id
  useEffect(() => {
    const tasksDocRef = doc(taskCollectionRef, taskId!);
    setTaskDocRef(tasksDocRef);
  }, [taskId]);

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId!);
  }, []);

  // set hidden shown value based on task
  useEffect(() => {
    if (!task) return;

    if (task.hidden === undefined) return;

    if (task.hidden === isHiddenShown) return;

    setHiddenShown();
  }, [task?.hidden]);

  useEffect(() => {
    if (algoliaResults === null) {
      setAlgoliaFileReqs(null);
      setAlgoliaFormReqs(null);
      return;
    }

    setAlgoliaFormReqs(algoliaResults.filter(
      res => [Entity.FormRequirement, Entity.PLFormRequirement, Entity.OrganizationTemplateFormRequirement].includes(res.entity)
    ));
    setAlgoliaFileReqs(algoliaResults.filter(
      res => [Entity.FileRequirement, Entity.PLFileRequirement, Entity.OrganizationTemplateFileRequirement].includes(res.entity)
    ))
  }, [algoliaResults]);

  function handleStatusFilterChange(newFilter: string) {
    setStatusFilter(newFilter);
  }

  function openChatRequirementDrawer(requirement: FileRequirement | FormRequirement, type: REQUIREMENT_TYPE) {
    setSelectedPath(type);
    setSelectedRequirement(requirement);
    openRequirementChatDrawer();
  }

  if (task === null || isLoading)
    return <Content><InProgress/></Content>;

  if (actualTaskRef === null)
    return <Content><InProgress/></Content>;

  if (!!task?.templateTaskId || !!task?.templateId)
    return <Content>Temporarily Disabled {task.templateTaskId} {task.templateId}</Content>;

  // if task is undefined, it means that the task id is invalid, go back to previous path
  if (task === undefined) {
    navigate(-1);
    return null;
  }

  const taskProps = {
    toastProps: toastProps!,
    task,
    uid,
    taskCollectionRef,
    parentEntity,
    showHidden: isHiddenShown,
  }

  if (task.deleted) return <PageNotFound/>;

  const fromTemplates = actualTaskRef.path.split("/").includes('templates');
  const notViewable = formRequirementAccess && !formRequirementAccess?.[PermissionOperationKey.View]
    && fileRequirementsAccess && !fileRequirementsAccess?.[PermissionOperationKey.View]
      && !fromTemplates;

  return (
    <>
      <ManageFileRequirementDrawer
        uid={uid}
        fileRequirement={null}
        collectionRef={collection(tasksDocRef, "fileRequirements")}
        toastProps={toastProps!}
        isOpen={isFileReqDrawerOpen}
        onClose={closeFileReqDrawer}
      />
      <ManageFormRequirementDrawer
        uid={uid}
        formRequirementCollectionRef={collection(tasksDocRef, "formRequirements")}
        isDrawerOpen={isFormReqDrawerOpen}
        onDrawerClose={closeFormReqDrawer}
        toastProps={toastProps!}
      />
      {selectedRequirement && isRequirementChatDrawerOpen && (
        <ChatDrawer
          uid={uid}
          toastProps={toastProps!}
          navigateToOverview={() => navigate(`./${selectedPath}/${selectedRequirement["@id"]!}`)}
          isOpen={isRequirementChatDrawerOpen}
          entity={selectedPath === REQUIREMENT_TYPE.formRequirement ? Entity.FormRequirement : Entity.FileRequirement}
          pathPair={[selectedRequirement.projectName!, selectedRequirement.name]}
          onClose={closeRequirementChatDrawer}
          colRef={collection(taskCollectionRef, taskId!, selectedPath === REQUIREMENT_TYPE.fileRequirement ? "fileRequirements" : "formRequirements", selectedRequirement["@id"]!, "chatMessages")}
        />
      )}
      <Header
        {...taskProps}
        taskAccess={taskAccess}
        rtdbPath={rtdbPath}
        tasksDocPath={tasksDocRef.path}
        fromTemplates={fromTemplates}
      />
      <Content>
        <Stack>
          <Head
            {...taskProps}
            originalPath={tasksDocRef.path}
            taskCollectionRef={actualTaskRef?.parent!}
            filesCount={filesCount}
            requirementsRejectedCount={requirementsRejectedCount}
            requirementsApprovedCount={requirementsApprovedCount}
            requirementsCount={requirementsCount}
            openCreateFormRequirement={openFormReqDrawer}
            openCreateFileRequirement={openFileReqDrawer}
            fileRequirementsAccess={fileRequirementsAccess!}
            formRequirementAccess={formRequirementAccess!}
            taskAccess={taskAccess}
          />
          {notViewable ?
            <NoPermission fullHeight={false}/>
            : <>
              <StickySearchAndFilter>
                <SearchAndFilter
                  userId={uid}
                  tasksDocRef={tasksDocRef.path}
                  showHidden={isHiddenShown}
                  parentEntity={parentEntity}
                  statusFilter={statusFilter}
                  handleStatusFilterChange={handleStatusFilterChange}
                  searchResultCallback={setAlgoliaResults}
                  toggleHiddenReqs={setHiddenShown}
                />
              </StickySearchAndFilter>
              <Stack direction="column">
                <FormRequirementsList
                  {...taskProps}
                  formRequirementsCount={formRequirementsCount || 0}
                  openCreateFormRequirement={openFormReqDrawer}
                  statusFilter={statusFilter}
                  algoliaResults={algoliaFormReqs}
                  openRequirementDrawer={openChatRequirementDrawer}
                  formRequirementAccess={formRequirementAccess}
                  fromTemplates={fromTemplates}
                />
                <FileRequirementsList
                  {...taskProps}
                  {...rest}
                  fileRequirementsCount={fileRequirementsCount || 0}
                  openRequirementDrawer={openChatRequirementDrawer}
                  openCreateFileRequirement={openFileReqDrawer}
                  statusFilter={statusFilter}
                  algoliaResults={algoliaFileReqs}
                  fileRequirementsAccess={fileRequirementsAccess}
                  addFiles={props.addFiles}
                  fromTemplates={fromTemplates}
                />
              </Stack>
            </>}
        </Stack>
      </Content>
    </>
  )
}

export default TaskOverview;
