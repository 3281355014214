import React, {useEffect} from "react";
import {Stack, Typography} from "@mui/material";
import {enFormsLabel} from "constants/index";
import {BasicAdvancedOptions, FormFieldAdvancedOptionsProps, OptionColumnWrapper} from "./index";
import {Input, ValidationMessage} from "components/index";
import {FormField} from "types/FormField";
import {FormFieldType} from "enums/formFieldType";
import {StringDictionary} from "types/FormBase"

export function ParagraphAdvancedOptions(props: FormFieldAdvancedOptionsProps) {
  const {changeFormData, index, formField} = props;
  const {minChars = 0, maxChars = '', responseIsRequired, errors} = formField as FormField<FormFieldType.Paragraph>;
  const lowestNum = responseIsRequired ? 1 : 0;

  // If response is not required, minChars and maxChars must be 0
  useEffect(() => {
    if (!responseIsRequired) return;
    if (!minChars) changeFormData(lowestNum, "minChars", index, true);
  }, [responseIsRequired]);

  // If minChars is greater than maxChars, set maxChars to minChars
  useEffect(() => {
    const errorData: StringDictionary = {
      ...errors,
      maxChars: maxChars && minChars >= maxChars ? enFormsLabel.maximumCharactersError : ''
    };

    if (!errorData.maxChars) {
      delete errorData.maxChars;
    }

    changeFormData(errorData, "errors", index, true);
  }, [minChars, maxChars]);

  function preventNonNumeric(e: React.KeyboardEvent) {
    if (['e', 'E', '.', '+', '-'].includes(e.key)) {
      e.preventDefault();
    }
  }

  return (
    <>
      <BasicAdvancedOptions {...props}/>
      <OptionColumnWrapper>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">{enFormsLabel.minimumCharacters}</Typography>
          <Input
            onKeyPress={preventNonNumeric}
            onChange={
              (e: React.ChangeEvent<any>) => (
                changeFormData(parseInt(e.currentTarget.value) || '', "minChars", index, true)
              )}
            type="number"
            minimumNumber={lowestNum}
            value={minChars}
            sx={{
              width: "80px",
              ".MuiOutlinedInput-input": {
                paddingTop: "6px",
                paddingBottom: "6px",
              }
            }}
            
          />
        </Stack>
        <ValidationMessage validationMessage={errors?.minChars}/>
      </OptionColumnWrapper>
      <OptionColumnWrapper>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">{enFormsLabel.maximumCharacters}</Typography>
          <Input
            onKeyPress={preventNonNumeric}
            onChange={
              (e: React.ChangeEvent<any>) => (
                changeFormData(parseInt(e.currentTarget.value) || '', "maxChars", index, true)
              )}
            type="number"
            minimumNumber={lowestNum}
            value={maxChars}
            sx={{
              width: "80px",
              ".MuiOutlinedInput-input": {
                paddingTop: "6px",
                paddingBottom: "6px",
              }
            }}
          />
        </Stack>
        <ValidationMessage validationMessage={errors?.maxChars}/>
      </OptionColumnWrapper>
    </>
  )
}