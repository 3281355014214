import {Box, Stack, Typography} from "@mui/material";

interface StatProps {
  Icon: JSX.Element;
  count: string | number;
  label: string;
  id?: string;
  title?: string;
}

function StatisticsItem(props: StatProps) {
  const {Icon, count, label, id = `statItem_${Math.random().toString()}`, title} = props
  return (
    <Stack direction="row" gap={1}>
      <Box position="relative" top={6}>
        {Icon}
      </Box>
      <Stack>
        <Typography variant="h2" id={`${id}-count`} title={title ?? label}>{count}</Typography>
        <Typography variant="bodySmall">{label}</Typography>
      </Stack>
    </Stack>
  );
}

export default StatisticsItem;