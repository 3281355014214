import {SidebarIcons} from "assets/icons";
import {enSidebar} from "constants/index";
import {SVGProps} from "react";

export interface Link {
  Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  Label: string;
  Path: string;
  order: number;
  pathName: string;
  iconProps?: SVGProps<SVGSVGElement>;
}

export const Links: Link[] = [
  {
    Icon: SidebarIcons.AddFile,
    Label: enSidebar.links.forms,
    Path: `/forms`,
    order: 0,
    pathName: "forms",
  },
  {
    Icon: SidebarIcons.Folder,
    Label: enSidebar.links.projects,
    Path: `/projects`,
    order: 1,
    pathName: "projects",
  },
  {
    Icon: SidebarIcons.Layers,
    Label: enSidebar.links.assets,
    Path: `/organization-assets`,
    order: 2,
    pathName: "organization-assets",
  },
  {
    Icon: SidebarIcons.GroupPeople,
    Label: enSidebar.links.teamsAndPeople,
    Path: `/teams-and-people`,
    order: 3,
    pathName: "teams-and-people",
  },
  {
    Icon: SidebarIcons.File,
    Label: enSidebar.links.referencedFiles,
    Path: `/reference-files`,
    order: 4,
    pathName: "reference-files",
  },
];