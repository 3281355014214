import React, {ChangeEvent, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {doc, getDocs, query, Timestamp, where} from "firebase/firestore";
import theme from "theme/theme";
import {DataTag} from "types/DataTag";
import {StringDictionary} from "types/FormBase";
import {useDebounce} from "hooks/index";
import {ActionType} from "enums/actionType";
import {Severity} from "enums/severity";
import {enCommonButton, enCommonLabel, enOrgDataTagsLabel, quietPeriodMs} from "constants/index";
import {Input, OverFlowBox, SimpleFormDrawer, ValidationMessage} from "components/index";
import {SidebarIcons} from "assets/icons/menu/menu.index";
import {BaseProps} from "../BaseProps";
import {areObjectsEqual, organizationDataTagsPath} from "../utility";
import {submitForm} from "screens/utility";
import {statusSubmitHandler} from "../utility/statusSubmitHandler";
import ColorSelectorButton from "components/DataTag/DataTagInput/ColorSelectorButton";
import {datatagToneColor} from "enums/datatagToneColor";

interface ManageDataCardProps extends BaseProps {
  isOpen: boolean;
  onClose: () => void;
  dataTag: DataTag | null; // If this is passed, Drawer should function as Edit.
}

const DEFAULT_DATA: DataTag = {
  name: "",
  description: "",
  color: datatagToneColor.tone_0_0,
  timeCreated: Timestamp.now(),
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
};

export function ManageDataTagCardDrawer(props: ManageDataCardProps) {
  const {dataTag, isOpen, toastProps, onClose} = props;
  const MAX_LENGTHS = {name: 32, description: 128};
  const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps!;
  const {orgId} = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [localData, setLocalData] = useState<DataTag>(dataTag ?? DEFAULT_DATA);
  const [liveValidation, setLiveValidation] = useState<StringDictionary>({});
  const [localValidation, setLocalValidation] = useState<StringDictionary>({
    name: enOrgDataTagsLabel.maximumCharacters(MAX_LENGTHS.name),
    description: enOrgDataTagsLabel.maximumCharacters(MAX_LENGTHS.description)
  });

  useDebounce(validateDataTagName, quietPeriodMs, [localData.name]);

  useEffect(() => {
    setLocalData(dataTag ?? DEFAULT_DATA);
  }, [dataTag, isOpen]);

  useEffect(() => {
    setLocalValidation(prevValidation => ({
      ...prevValidation,
      name: getValidationMessage(localData.name, MAX_LENGTHS.name),
      description: getValidationMessage(localData.description, MAX_LENGTHS.description)
    }));

    const isNotEmptyAndNoError = localData.name?.trim() && !Object.keys(liveValidation).length;
    setIsFormValid(!!(dataTag ? isNotEmptyAndNoError && dataHasChanged() : isNotEmptyAndNoError));
  }, [localData, liveValidation]);

  async function validateDataTagName() {
    if (!isOpen) return;

    if (localData.name === "") {
        setLiveValidation(prevData => ({...prevData, name: enOrgDataTagsLabel.nameRequired}));
      return;
    }

    const docRef = !!dataTag ? doc(organizationDataTagsPath(orgId!), dataTag?.id): doc(organizationDataTagsPath(orgId!));

    const querySameName = query(
      organizationDataTagsPath(orgId!),
      where("name", "==", localData.name.trim().toLowerCase()),
      where("id", "!=", docRef.id!)
    );

    const docData = await getDocs(querySameName);
    const docs = docData.docs;

    if (docs.length) {
      setLiveValidation(prevData => ({...prevData, name: enOrgDataTagsLabel.nameExists}));
    }
  }

  function getValidationMessage (value: string, maxLength: number) {
    if (!value) return "";

    return value.length === 0 
      ? enOrgDataTagsLabel.maximumCharacters(maxLength)
      : enOrgDataTagsLabel.characterCounter(`${value.length}/${maxLength}`);
  }
  function dataHasChanged() {
    return localData.name?.trim() !== dataTag?.name?.trim() 
      || localData.description?.trim() !== dataTag?.description?.trim()
      || localData.color?.trim() !== dataTag?.color?.trim();
  }

  function onDrawerClose() {
    onClose();
    setLiveValidation({});
    setLocalData(DEFAULT_DATA);
  }

  function changeLocalDocData(key: "name" | "description", e: React.ChangeEvent) {
    const value = (e as React.ChangeEvent<HTMLInputElement>).target.value;

    if (value.length > MAX_LENGTHS[key]) return;

    setLocalData(prevData => ({...prevData, [key]: value}));

    setLiveValidation(({ [key]: _, ...rest }) => rest);
  }
  function onKeyPress(e: React.KeyboardEvent) {
    if (e.key === "Enter" && isFormValid && !isLoading) {
      onSubmitForm();
    }
  }

  function unsavedChanges() {
    return !!dataTag ? dataHasChanged() : !areObjectsEqual(localData, DEFAULT_DATA);
  }

  async function onSubmitForm() {
    if(!orgId) return;

    setIsLoading(true);
    const action = !!dataTag ? ActionType.Update: ActionType.Create;
    const docRef = !!dataTag ? doc(organizationDataTagsPath(orgId), dataTag?.id): doc(organizationDataTagsPath(orgId));

    await submitForm(docRef, action,
      (status, data, isLastUpdate) => statusSubmitHandler({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback
      }),
      localData
    );
  }
  function successCallback() {
    setToastMessage(!!dataTag ? enOrgDataTagsLabel.updateSuccess : enOrgDataTagsLabel.createSuccess);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    setIsLoading(false);

    onDrawerClose();
  }
  function errorCallback(message: any) {
    setLiveValidation(prevValidation => ({
      ...prevValidation,
      ...(typeof message === "object" ? message : {name: message})
    }));

    setIsLoading(false);
  }

  function setColor(color: datatagToneColor) {
    setLocalData(prevData => ({...prevData, color}));
  }

  return (
    <SimpleFormDrawer
      title={!!dataTag ? enOrgDataTagsLabel.editTag : enOrgDataTagsLabel.createTag}
      isOpen={isOpen}
      onClose={onDrawerClose}
      onSubmit={onSubmitForm}
      icon={<SidebarIcons.DataTags/>}
      isFormValid={isFormValid}
      isLoading={isLoading}
      rightButtonLabel={!!dataTag ? enCommonButton.save : enCommonButton.create}
      unsavedChanges={unsavedChanges()}
    >
      <OverFlowBox>
        <Input
          value={localData.name}
          onChange={(e) => changeLocalDocData("name", e as ChangeEvent)}
          label={enCommonLabel.name}
          validationMessage={liveValidation.name}
          onKeyPress={onKeyPress}
          sx={{
            width: "100%",
          }}
          textfieldSx={{
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0
          }}
          addon={<ColorSelectorButton setColor={setColor} color={dataTag?.color}/>}
        />

        <ValidationMessage validationMessage={localValidation.name} sx={{color: theme.palette.secondary.main}} />
        <Input
          value={localData.description}
          onChange={(e) => changeLocalDocData("description", e as ChangeEvent)}
          label={enCommonLabel.description}
          validationMessage={liveValidation.description}
          minRows={4}
          maxRows={15}
          sx={{mt: 4}}
          multiline
          optional={true}
        />
        <ValidationMessage validationMessage={localValidation.description} sx={{color: theme.palette.secondary.main}} />
      </OverFlowBox>
    </SimpleFormDrawer>
  )
}