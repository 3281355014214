import {useEffect, useState} from "react";
import {Navigate, useParams} from "react-router-dom";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "../../firebase";
import {InProgress} from "components/index";
import useMaintenance from "hooks/useMaintenance";

/**
 * checks if user is logged in, if yes, will pass to another component
 * that will check the validation
 * else, will display the login page
 */
function InvitationRoute() {
  const {invitationId} = useParams();
  useMaintenance(false);
  localStorage.setItem("invitationId", invitationId!);

  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  useEffect(() => {
    const getAuth = () => {
      onAuthStateChanged(auth, (firebaseUser) => {
        setIsLoggedIn(firebaseUser !== null);
      });
    }

    getAuth();

  }, []);

  if (isLoggedIn === null)
    return <InProgress />;

  if (isLoggedIn)
    return <Navigate to="/view-invite" />;

  return <Navigate to={"/login"} state={{invitationId}} />;
}

export default InvitationRoute;