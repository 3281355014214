import {collection, CollectionReference} from "firebase/firestore";
import {Entity} from "enums/entity";

export function getAccessList(entity: Entity, currentRef: CollectionReference): CollectionReference {
  switch(entity) {
    case Entity.FileRequirement:
    case Entity.PLFileRequirement:
    case Entity.FormRequirement:
    case Entity.PLFormRequirement:
      return collection(currentRef.parent!.parent.parent!, "accessList");
    case Entity.File:
    case Entity.PLFile:
      return collection(currentRef.parent!.parent!.parent!.parent!.parent!, "accessList");
    default:
      return collection(currentRef.parent!, "accessList");
  }
}