import {PermissionEntity, PermissionOperationKey} from "types/Permission";
import {PERMISSION_LABEL} from "screens/RolesAndPermissions/constants/permissionLabel";

export const rolesAndPermissions = {
  title: "Roles and Permissions",
  label: {
    search: "Search Permission..",
    team: "Team",
    user: "User",
    editSuccess: "Roles and Permissions has been updated successfully.",
    allowTo: (action: string) => `Allow to ${action}`,
  },
  operationLabel: {
    [PermissionOperationKey.View]: "View",
    [PermissionOperationKey.Create]: "Create",
    [PermissionOperationKey.Update]: "Update",
    [PermissionOperationKey.Delete]: "Delete",
    [PermissionOperationKey.ChangeStatus]: "Change Status",
    [PermissionOperationKey.OverrideStatus]: "Override Status",
    [PermissionOperationKey.ManageOwner]: "Manage Owner",
    [PermissionOperationKey.ManageAdmin]: "Manage Admin",
    [PermissionOperationKey.ManagePowerUser]: "Manage Power User",
    [PermissionOperationKey.ManageUser]: "Manage User",
    [PermissionOperationKey.ManageGuest]: "Manage Guest",
  },
  operation: (entity: PermissionEntity, operation: PermissionOperationKey) => {
    const article = /^[aeiou]/i.test(entity) ? "an" : "a";
    switch (operation) {
      case PermissionOperationKey.View:
      case PermissionOperationKey.Update:
      case PermissionOperationKey.Delete:
        return `${rolesAndPermissions.operationLabel[operation]} ${article} ${PERMISSION_LABEL[entity]}`;
      case PermissionOperationKey.Create:
        switch (entity) {
          case PermissionEntity.TaskExport:
          case PermissionEntity.TaskFilesDownload:
          case PermissionEntity.ProjectImport:
          case PermissionEntity.ProjectExport:
          case PermissionEntity.ProjectAssetImport:
          case PermissionEntity.ProjectAssetExport:
          case PermissionEntity.AssetFilesDownload:
          case PermissionEntity.FileRequirementFilesDownload:
          case PermissionEntity.MilestoneFilesDownload:
          case PermissionEntity.OrganizationTemplateImport:
          case PermissionEntity.OrganizationTemplateExport:
          case PermissionEntity.FormExport:
            return `${PERMISSION_LABEL[entity]}`;
          case PermissionEntity.SwiftFile:
            return `Upload ${PERMISSION_LABEL[entity]}`;
          default:
            return `Create ${article} ${PERMISSION_LABEL[entity]}`;
        }
      case PermissionOperationKey.ChangeStatus:
      case PermissionOperationKey.OverrideStatus:
        return `${rolesAndPermissions.operationLabel[operation]}`;
      case PermissionOperationKey.ManageOwner:
        return `Manage ${PERMISSION_LABEL[entity]} with Owner Role`;
      case PermissionOperationKey.ManageAdmin:
        return `Manage ${PERMISSION_LABEL[entity]} with Admin Role`;
      case PermissionOperationKey.ManagePowerUser:
        return `Manage ${PERMISSION_LABEL[entity]} with Power User Role `;
      case PermissionOperationKey.ManageUser:
        return `Manage ${PERMISSION_LABEL[entity]} with User Role`;
      case PermissionOperationKey.ManageGuest:
        return `Manage ${PERMISSION_LABEL[entity]} with Guest Role`;
      default:
        return operation;
    }
  }
}