import {Box, Stack, Typography} from "@mui/material";
import {Avatar} from "components/index";
import theme from "theme/theme";
import ChatAttachmentWrapper from "components/ChatDrawer/ChatAttachments/ChatAttachmentWrapper";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {auth} from "../../../firebase";
import {getTwelveHourTime} from "screens/utility";
import {useCollection} from "hooks/index";
import {ChatMessage, Member, SwiftFile, Team} from "types/index";
import {collection, CollectionReference, orderBy} from "firebase/firestore";
import {ChatWithMention} from "components/ChatDrawer/Objects/ChatWithMention";
import {ChatMessageWrapper} from "components/ChatDrawer/Objects/ChatWrapper";
import {CarouselItem} from "components/ChatDrawer/ChatItemsView/ChatAttachmentCarousel";
import ReadReceipts from "components/ChatDrawer/ChatItemsView/ReadReceipts";
import {ReadReceipt} from "types/ChatMessage";
import {SelectedOrgContext} from "../../../screens/SelectedOrgContextProvider";
import {UsersMentionedData} from "types/UsersMentionedData";

interface ChatItemProps {
  message: ChatMessage;
  colRef: CollectionReference;
  isLastItem: boolean;
  setSelectedChatAttachments: (attachments: CarouselItem[], selectedIndex: number) => void;
  readers: ReadReceipt[]
}

export default function ChatItem(props: ChatItemProps) {
  const {message, colRef, setSelectedChatAttachments, readers} = props;
  const {text, sender, timestamp, hasAttachments = false, "@id": id = "CHAT_ID", attachmentsCount = 0} = message;
  const [timeSent, setTimeSent] = useState("");
  const uid = auth.currentUser!.uid;
  const context = useContext(SelectedOrgContext);
  const selectedOrg = context?.selectedOrg ?? null
  const mentionedUsersData = convertToMentionedUsersData(selectedOrg?.teams ?? [], selectedOrg?.members ?? [])

  const [attachments, , setColRef] = useCollection<SwiftFile>(null, null, [orderBy("timeCreated", "asc")]);
  useMemo(() => {
    setTimeSent(getTwelveHourTime(timestamp));
  }, []);

  useEffect(() => {
    if (hasAttachments) {
      setColRef(collection(colRef, id, "files"));
    }
  }, [hasAttachments]);

  const isSelf = sender.id === uid;

  return (
    <>
      <Stack
        direction="row"
        justifyContent={isSelf ? "flex-end" : "flex-start"} mb={0} mx={0.5}
      >
        <Stack direction="row" gap={1}>
          {!isSelf && (
            <Box>
              <Avatar sx={{width: 32, height: 32, fontSize: 12, mt: 0, border: "none"}} {...sender}/>
            </Box>
          )}
          <Stack gap={0.5} alignItems={isSelf ? "flex-end" : "flex-start"}>
            <Stack gap={0.5} alignItems={isSelf ? "flex-end" : "flex-start"}>
              {!isSelf && <Typography variant="body" color={theme.palette.neutral.dark} lineHeight="14px">
                {sender.name}
              </Typography>}
              <Typography variant="caption" color={theme.palette.neutral.dark} lineHeight="12px">
                {timeSent}
              </Typography>
            </Stack>
            {attachmentsCount > 0 && (
              <ChatAttachmentWrapper
                attachmentsCount={attachmentsCount}
                attachments={attachments}
                sx={{
                  justifyContent: isSelf ? "flex-end" : "flex-start",
                  alignItems: isSelf ? "flex-end" : "flex-start",
                }}
                onClick={setSelectedChatAttachments}
                id={id}
                colRef={colRef}
              />
            )}
            {text && (
              <>
                <ChatMessageWrapper
                  sx={{
                    backgroundColor: isSelf ? theme.palette.secondary.main
                      : theme.palette.background.swiftDefault,
                    wordBreak: "break-word",
                  }}
                  color={isSelf ? theme.palette.text.onDark : theme.palette.secondary.main}
                >
                  <ChatWithMention chatMessage={text} mentionedUsersData={mentionedUsersData}/>
                </ChatMessageWrapper>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
      {readers.length > 0 && <ReadReceipts readers={readers} />}
      <Box mb={3}/>
    </>
  );
}

function convertToMentionedUsersData(teams: Team[], members: Member[]) {
  const mentionedUsersData: UsersMentionedData = {};

  teams.forEach(team => {
    mentionedUsersData[team["@id"]!] = {
      name: team.name,
      email: "",
      avatarInitials: team.initials!,
      avatarColor: team.avatarColor!
    }
  });

  members.forEach(member => {
    mentionedUsersData[member["@id"]!] = {
      name: member.name,
      email: member.email,
      avatarInitials: member.initials,
      avatarColor: member.avatarColor
    }
  });

  return mentionedUsersData;
}
