import React, {useState} from "react";
import {ActionOption, ConfirmDialog, MenuCard, MenuCardProps} from "components";
import {SystemIcons} from "assets/icons/system/system.index";
import {
  emptyFunction,
  enFileRequirementButton,
  enFileRequirementLabel,
  enMilestoneButton,
} from "constants/index";
import submitForm from "screens/utility/submitForm";
import {ActionType} from "enums/actionType";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {DocumentReference} from "firebase/firestore";
import {Severity} from "enums/severity";
import {FileRequirement} from "types/FileRequirement";
import DownloadOptionsMenu from "components/Dialogs/DownloadConfirmDialog/DownloadOptionsMenu";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {Entity} from "enums/entity";
import {toastProps} from "../../BaseProps";

interface NavBarMenuProps extends MenuCardProps {
  toastProps: toastProps;
  requirementAccess: AccessType | null;
  downloadAccess: AccessType | null;
  fileRequirement: FileRequirement | undefined | null;
  filesCount: number;
  documentRef: DocumentReference;
  uid: string
}

function NavBarMenu(props: NavBarMenuProps) {
  const {anchorEl, requirementAccess, filesCount, downloadAccess, open, toastProps, fileRequirement, documentRef} = props;

  const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps!;
  const {assetId} = useParams();
  const {closeMenu} = props;
  const location = useLocation();
  const previousPagePath = location.pathname.split('/').slice(0, -2).join('/');

  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isDownloadOptionsOpen, setIsDownloadOptionsOpen] = useState(false);

  const canDelete = Boolean(requirementAccess?.[PermissionOperationKey.Delete]);
  const canDownload = Boolean(downloadAccess?.[PermissionOperationKey.Create]);

  const navigate = useNavigate();

  function onDeleteCancel(e: React.MouseEvent<HTMLElement> | undefined) {
    setIsDeleteOpen(false);

    if (e !== undefined) closeMenu(e);
  }

  async function onDeleteTaskConfirm() {
    await submitForm(documentRef, ActionType.Delete, () => {
      setToastMessage(enFileRequirementLabel.deleteSuccess);
      setToastSeverity(Severity.Success);
      setIsToastOpen(true);

      navigate(previousPagePath);
    });
  }

  function onDownloadMenuClick() {
    if (!anchorEl) return;
    setIsDownloadOptionsOpen((prev) => !prev);
  }

  return (
    <>
      <MenuCard
        anchorEl={anchorEl}
        open={open}
        closeMenu={closeMenu}
      >
        <ActionOption
          id="requirement-overview-download-files-menu-button"
          Icon={<SystemIcons.Download height={16} width={16}/>}
          label={enMilestoneButton.download}
          onClick={canDownload ? onDownloadMenuClick : emptyFunction}
          disabled={!canDownload}
          isDropdown
        />
        <DownloadOptionsMenu
          uid={props.uid}
          entity={!!assetId ? Entity.FileRequirement : Entity.PLFileRequirement}
          filesCount={filesCount ?? 0}
          onClose={onDownloadMenuClick}
          isDownloadOptionsCollapseOpen={isDownloadOptionsOpen}
          isCollapsible
          toastProps={toastProps!}
        />
        <ActionOption
          id="requirement-overview-delete-menu-button"
          Icon={<SystemIcons.DeleteOutlined height={16} width={16}/>}
          label={enFileRequirementButton.deleteRequirement}
          labelSx={(theme) => ({color: theme.palette.error.main})}
          onClick={() => setIsDeleteOpen(true)}
          disabled={!!fileRequirement?.templateId || !canDelete}
        />
      </MenuCard>
      <ConfirmDialog
        isOpen={isDeleteOpen}
        handleClose={(e?: React.MouseEvent<HTMLElement>) => onDeleteCancel(e)}
        handleConfirm={onDeleteTaskConfirm}
        confirmButtonId="confirm-delete-button"
        cancelButtonId="cancel-delete-button"
        title={enFileRequirementLabel.deleteConfirmationTitle}
        text={enFileRequirementLabel.deleteConfirmationText}
      />
    </>
  )
}

export default NavBarMenu;