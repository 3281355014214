import {Dispatch, useReducer} from "react";
import {initialState} from "./initialState";
import reducer from "./reducer";
import {FileUploadReducerState} from "./FileUploadReducerState";
import {UploadAction} from "./UploadAction";

type useFileUploadReducerReturn = [
  state: FileUploadReducerState,
  dispatch: Dispatch<UploadAction>
];

export function useFileUploadReducer(): useFileUploadReducerReturn {
  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, initialState);
  return [state, dispatch] as useFileUploadReducerReturn;
}