import {Box, InputAdornment} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import BaseInput from "components/inputs/BaseInput";
import {InProgress} from "components/index";

export interface SearchInputProps {
  id: string;
  placeholder: string;
  inputSize?: string;
  sx?: any;
  searchFn: (searchText: string) => void;
  autoFocus?: boolean;
  textLabel?: string;
  loading?: boolean;
}

function BaseSearchInput(props: SearchInputProps) {
  const {id, placeholder, sx, searchFn, autoFocus, loading = false} = props;
  const inputSize = props.inputSize ?? "278px";

  return <BaseInput
    id={id}
    valid
    variant="outlined"
    value={props.textLabel}
    placeholder={placeholder}
    autoFocus={autoFocus}
    sx={{width: inputSize, ...sx}}
    onChange={(e) => {
      const el = e.target as HTMLInputElement;
      const value = el.value;
      if (searchFn)
        searchFn(value);
    }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="start" sx={{height: 16, width: 16}}>
          {loading ?
            <Box>
              <InProgress
                size={16}
              />
            </Box>
            :
            <SystemIcons.Search
              stroke={theme.palette.neutral.main}
              height={16}
              width={16}
            />
          }
        </InputAdornment>
      ),
    }}
  />
}

export default BaseSearchInput;
