import {Box, styled} from "@mui/material";
import theme from "theme/theme";

const DrawerHeaderIcon = styled(Box)({
  height: "40px",
  width: "40px",
  borderRadius: "20px",
  display: "flex",
  justifyContent: "center",
  alignSelf: "center",
  alignItems: "center",
  backgroundColor: theme.palette.background.swiftDefault,
  marginTop: "-5%",
  marginBottom: "1%"
});

export default DrawerHeaderIcon;
