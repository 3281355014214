import React from "react";
import ActionOption from "components/ActionOption";
import {SystemIcons} from "assets/icons/system/system.index";
import {
  emptyFunction,
  enCommonLabel,
  enTemplateButton,
  enTemplateLabel,
  previousPage
} from "constants/index";
import MenuCard from "components/MenuCard";
import {ConfirmDialog} from "components/index";
import {useNavigate} from "react-router-dom";
import {BaseProps} from "../BaseProps";
import {useAccess, useComponentToggler} from "hooks/index";
import {DocumentReference} from "firebase/firestore";
import submitForm from "../utility/submitForm";
import {
  ActionType,
  CounterFields,
  Entity,
  FeatureType,
  ProcessType,
  Severity,
  ViewStatus
} from "enums/index";
import FeatureComponent from "components/FeatureWrapper";
import {PermissionEntity, PermissionOperationKey} from "types/Permission";
import {auth} from "../../firebase";
import useProgressCount from "hooks/useProgressCount";

interface HeaderMoreOptionsMenuProps extends BaseProps {
  access: any;
  anchorEl: HTMLElement | null;
  open: boolean;
  documentRef: DocumentReference;
  closeMenu: (e: React.MouseEvent<HTMLElement>) => void;
  openImportDialog: () => void;
  openExportDialog: () => void;
}

function HeaderMoreOptionsMenu(props: HeaderMoreOptionsMenuProps) {
  const {access, open, anchorEl, toastProps, documentRef, closeMenu, openImportDialog, openExportDialog} = props;
  const {setToastSeverity, setIsToastOpen, setToastMessage} = toastProps!;
  const currentUserId = auth.currentUser!.uid;
  const {isProcessing} = useProgressCount({
    path: documentRef.path,
    counterField: CounterFields.ImportingProgressCount
  });

  const navigate = useNavigate();

  const [isDeleteOpen, {open: openDeleteDialog, close: closeDeleteDialog}] = useComponentToggler(false);

  const [exportAccess] = useAccess({
    uid: currentUserId,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationTemplateExport,
  });

  const [importAccess] = useAccess({
    uid: currentUserId,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationTemplateImport,
  });


  function onDeleteCancel(e: React.MouseEvent<HTMLElement> | undefined) {
    closeDeleteDialog();
    if (e !== undefined) closeMenu(e);
  }

  async function onDeleteTemplateConfirm() {
    await submitForm(documentRef, ActionType.Delete, statusHandler);
  }

  function statusHandler(status: ViewStatus, data: any, isLastUpdate: boolean) {
    if (!isLastUpdate) return;

    switch (status) {
      case ViewStatus.Finished:
        setToastSeverity(Severity.Success);
        setToastMessage(enTemplateLabel.deleteSuccess);
        setIsToastOpen(true);

        navigate(previousPage);
        break;
      case ViewStatus.ValidationError:
      case ViewStatus.SecurityError:
      case ViewStatus.Error:
        setToastSeverity(Severity.Error);
        setToastMessage(enCommonLabel.errorProcess(ProcessType.Delete));
        setIsToastOpen(true);
        break;
    }
  }

  return (
    <MenuCard open={open} anchorEl={anchorEl} closeMenu={closeMenu}>
      <FeatureComponent feature={FeatureType.ImportTemplate} viewable>
        <ActionOption
          id="template-overview-import-menu-button"
          Icon={<SystemIcons.Import height={16} width={16}/>}
          label={enTemplateButton.importTemplate}
          onClick={importAccess?.[PermissionOperationKey.Create] ? openImportDialog : emptyFunction}
          disabled={!importAccess?.[PermissionOperationKey.Create] || isProcessing}
        />
      </FeatureComponent>

      <FeatureComponent feature={FeatureType.ExportTemplate}>
        <ActionOption
          id="template-overview-export-menu-button"
          Icon={<SystemIcons.Export height={16} width={16}/>}
          label={enTemplateButton.exportTemplate}
          onClick={exportAccess?.[PermissionOperationKey.Create] ? openExportDialog : emptyFunction}
          disabled={!exportAccess?.[PermissionOperationKey.Create]}
        />
      </FeatureComponent>
      <ActionOption
        id="template-overview-delete-menu-button"
        Icon={<SystemIcons.DeleteOutlined height={16} width={16}/>}
        label={enTemplateButton.deleteTemplate}
        onClick={access?.[PermissionOperationKey.Delete] ? openDeleteDialog : emptyFunction}
        disabled={!access?.[PermissionOperationKey.Delete]}
        labelSx={(theme) => ({color: theme.palette.error.main})}
      />
      <ConfirmDialog
        isOpen={isDeleteOpen}
        handleClose={(e?: React.MouseEvent<HTMLElement>) => onDeleteCancel(e)}
        handleConfirm={onDeleteTemplateConfirm}
        title={enTemplateLabel.deleteDialogTitle(1)}
        text={enTemplateLabel.deleteConfirmationText}
      />
    </MenuCard>
  )
}

export default HeaderMoreOptionsMenu;
