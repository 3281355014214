import {StatusItem} from "types/StatusItem";
import StatusObjects from "assets/arrayList/StatusObjectList/StatusObjects";
import {RequirementStatus} from "enums/requirementStatus";

const RequirementStatusObjectList: Record<string, StatusItem> = {
  [RequirementStatus.Cancelled]: StatusObjects.cancelled,
  [RequirementStatus.Pending]: StatusObjects.pending,
  [RequirementStatus.InProgress]: StatusObjects.inProgress,
  [RequirementStatus.Submitted]: StatusObjects.submitted,
  [RequirementStatus.Rejected]: StatusObjects.rejected,
  [RequirementStatus.HasRejection]: StatusObjects.hasRejection,
  [RequirementStatus.Approved]: StatusObjects.approved,
}

export default RequirementStatusObjectList
