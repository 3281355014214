import React from "react";
import {Stack, SxProps} from "@mui/material";

interface TabPanelProps {
  tabIndex: number;
  children?: React.ReactNode;
  value: number;
  sx?: SxProps
}

function TabPanel(props: TabPanelProps) {
  const {tabIndex, children, value, sx} = props;

  return (
    <>
      {value === tabIndex && (
        <Stack
          role="tabpanel"
          id={`tabpanel-${tabIndex}`}
          aria-labelledby={`tab-${tabIndex}`}
          sx={{
            flex: 1,
            ...sx
          }}
        >
          {value === tabIndex && (
            <>
              {children}
            </>
          )}
        </Stack>
      )}
    </>
  )
}

export default TabPanel;