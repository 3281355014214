import {SwiftFile} from "types/index";
import {enCommonButton} from "constants/index";
import {Box, Stack} from "@mui/material";
import theme from "theme/theme";
import {LongText} from "components/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {CollectionReference, doc} from "firebase/firestore";
import {ActionType, ViewStatus} from "enums/index";
import submitForm from "screens/utility/submitForm";
import {FileStatus} from "enums/fileStatus";
import React, {useState} from "react";
import {SecondaryButton} from "components/Button";
import {formatDateTimeFirestoreDate} from "../../../utility";
import {displayFileName} from "./utils";

interface ApprovedFileCardProps {
  collectionRef: CollectionReference;
  enabled: boolean;
  file: SwiftFile;
  previewBlock: JSX.Element;
}

export default function ApprovedFileCard(props: ApprovedFileCardProps) {
  const {file, previewBlock, collectionRef, enabled} = props;

  const [isLoading, setIsLoading] = useState(false);
  const {shortDate, simpleTime} = formatDateTimeFirestoreDate(file.timeCreated)

  async function onFileUnApproveClick() {
    if (!enabled) return;

    setIsLoading(true);
    const docReference = doc(collectionRef, file["@id"]!);
    await submitForm(docReference, ActionType.Update, statusHandler, {fileStatus: FileStatus.Uploaded});
  }

  function statusHandler(status: ViewStatus, data: SwiftFile, isLastUpdate: boolean) {
    if (!isLastUpdate) return;
    setIsLoading(status === ViewStatus.Finished);
  }

  return (
    <Box
      maxWidth="296px"
      minHeight="298px"
      position="relative"
      sx={{
        borderRadius: "4px",
        border: `1px solid ${theme.palette.neutral.light}`,
        overflow: "hidden"
      }}
      className="file-list-item"
      id={`file-list-item-${file["@id"]!}`}
    >
      <Stack spacing={0}>
        {previewBlock}
        <Box
          sx={{padding: "4px 1rem", zIndex: 1}}
        >
          <LongText
            variant="body"
            textColor={theme.palette.neutral.dark}
            maxLines={1}
            tooltipText={`${file.name} - ${shortDate} ${simpleTime}`}
            id={`file-list-item-name-${file["@id"]}`}
          >
            {displayFileName(file.name)} - {shortDate} {simpleTime}
          </LongText>
        </Box>
        <Box flex={1}></Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} px={2} pb={1}>
          <SecondaryButton
            onClickFcn={onFileUnApproveClick}
            disabled={!enabled}
            startIcon={(props) => (
              <SystemIcons.Retake {...props}
                stroke={enabled ? theme.palette.success.main : theme.palette.neutral.medium}
                strokeWidth={4}
              />
            )}
            label={enCommonButton.unapprove}
            id={`file-list-item-unapprove-button-${file["@id"]!}`}
            loading={isLoading}
            sx={{width: "100%"}}
          />
        </Stack>
      </Stack>
    </Box>
  );
}
