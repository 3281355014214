import {emptyFunction, enCommonButton, enCommonLabel} from "constants/index";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import React, {useEffect, useState} from "react";
import {submitForm} from "screens/utility";
import {CollectionReference, doc, Timestamp} from "firebase/firestore";
import {
  ActionType,
  ExportFormType,
  ExportProjectType,
  ExportTaskType, ExportType,
  FileFormat,
  Severity,
} from "enums/index";
import {toastProps} from "screens/BaseProps";
import DownloadButton from "components/Button/DownloadButton";
import theme from "theme/theme";

type ExportTasksDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  toastProps: toastProps;
  title: string;
  exportingTitle: string;
  message: string;
  exportColRef: CollectionReference;
  exportType?: ExportFormType | ExportTaskType | ExportProjectType | ExportType;
  filteredTaskIds?: string[] | undefined;
}

type TypeState = Record<FileFormat, string | boolean | null>;
const DEFAULT_TYPE_STATE = {
  bool: {csv: false, xlsx: false},
  str: {csv: null, xlsx: null}
}

export function ExportDialog(props: ExportTasksDialogProps) {
  const {
    isOpen,
    toastProps,
    title,
    exportingTitle,
    message,
    exportColRef,
    exportType,
    filteredTaskIds,
    onClose
  } = props;
  const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps;
  const [currentLoading, setCurrentLoading] = useState<TypeState>({csv: false, xlsx: false});
  const [noData, setNoData] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) return;
    setCurrentLoading(DEFAULT_TYPE_STATE.bool);
    setNoData(false);
  }, [isOpen]);

  async function onExportClick(type: FileFormat) {
    const loaders = {...currentLoading};
    loaders[type] = true;
    setCurrentLoading(loaders);

    const exportRequestData = {
      fileType: type,
      ...(exportType && {type: exportType}),
      ...(filteredTaskIds ? {filteredTaskIds: filteredTaskIds} : {}),
      timeRequested: Timestamp.now(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    const exportRequestRef = doc(exportColRef);
    await submitForm(
      exportRequestRef,
      ActionType.Create,
      emptyFunction,
      exportRequestData
    );

    // once form is submitted, close
    setToastSeverity(Severity.Success);
    setToastMessage(enCommonLabel.successExporting);
    setIsToastOpen(true);
    onClose();
  }

  return (
    <Dialog open={isOpen}>
      <DialogTitle
        sx={{
          display: "flex",
          fontWeight: "bold",
          fontSize: 20,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {(currentLoading.csv || currentLoading.xlsx) ? exportingTitle : title}
        <IconButton onClick={onClose}>
          <SystemIcons.Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{minWidth: {sm: "unset", xs: "unset", md: "unset", lg: 450, xl: 450}}}>
        <DialogContentText
          sx={{fontSize: 14, color: "black", maxWidth: {sm: "auto", md: "24rem"}}}
          id="alert-dialog-description"
        >
          {!noData && message}
          {noData && (<Typography color={theme.palette.error.main}>{enCommonLabel.noDataToExport}</Typography>)}
        </DialogContentText>
        <DialogActions
          sx={{
            display: "flex",
            paddingLeft: 0,
            gap: 1.5,
            justifyContent: "space-between",
            paddingRight: 0,
          }}
        >
          <DownloadButton
            id="export-button-csv"
            isLoading={Boolean(currentLoading[FileFormat.CSV])}
            onClickFcn={() => onExportClick(FileFormat.CSV)}
            disabled={Boolean(currentLoading[FileFormat.CSV]) || noData}
            startIcon={SystemIcons.CSV}
            label={enCommonButton[FileFormat.CSV]}
          />
          <DownloadButton
            id="export-button-excel"
            isLoading={Boolean(currentLoading[FileFormat.Excel])}
            onClickFcn={() => onExportClick(FileFormat.Excel)}
            disabled={Boolean(currentLoading[FileFormat.Excel]) || noData}
            startIcon={SystemIcons.Excel}
            label={enCommonButton[FileFormat.Excel]}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
