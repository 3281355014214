import {BaseSearchInput} from "components/index";
import {useEffect, useState} from "react";
import useAlgoliaSearch from "hooks/useAlgoliaSearch";
import {SxProps} from "@mui/material";
import Theme from "theme/theme";


type SmartSearchInputProps<Type> = {
  resultCallback: (results: Type[] | null) => void
  colPath: string | string[];
  additionalFilters?: string[];
  placeholder?: string;
  id?: string;
  alternatePathName?: string;
  sx?: SxProps<typeof Theme>;
  byPassUsersAndTeamsFilter?: boolean;
}

function  SmartSearchInput<Type>(props: SmartSearchInputProps<Type>) {
  const {resultCallback, colPath, placeholder, id, alternatePathName, sx, additionalFilters} = props;
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false)
  const searchHits = useAlgoliaSearch<Type>(searchQuery, colPath, setLoading, alternatePathName, additionalFilters, props.byPassUsersAndTeamsFilter);

  useEffect(() => {
    resultCallback(searchHits);
  }, [searchHits])

  useEffect(() => {
  }, [searchQuery])

  return <BaseSearchInput
    sx={sx}
    id={id || "smart-search-input"}
    placeholder={placeholder || "Search"}
    searchFn={(searchText: string) => setSearchQuery(searchText)}
    loading={loading}
  />
}

export default SmartSearchInput
