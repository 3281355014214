import {Stack} from "@mui/material";
import {
  allStatus,
  emptyFunction,
  enCommonLabel,
  enFileRequirementLabel,
  enTaskLabel,
} from "constants/index";
import {FileRequirement} from "types/index";
import {useParams} from "react-router-dom";
import {InProgress} from "components/index";
import React, {useEffect, useMemo, useState} from "react";
import DeleteDialog from "components/Dialogs/DeleteDialog";
import {sortObjectsBy, submitForm} from "screens/utility";
import {ActionType, DirectionalOrder, Entity, ProcessType, Severity} from "enums/index";
import {collection, CollectionReference, doc, orderBy, where} from "firebase/firestore";
import {BaseProps} from "screens/BaseProps";
import {useComponentToggler} from "hooks/index";
import BulkActions from "../Objects/BulkActions";
import EmptySearchResults from "components/EmptySearchResults";
import EmptyList from "../Objects/EmptyList";
import ListContainer from "../Objects/ListContainer";
import NoPermission, {PermissionDisplayType} from "../../NoPermission";
import {REQUIREMENT_TYPE} from "../index";
import RequirementsList from "./RequirementsList";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {bulkStatusSubmitHandler, StatusSubmitHandler} from "../../utility/statusSubmitHandler";
import {UploadFile} from "hooks/useFileUpload/useFileUpload";
import CheckboxSwitch from "components/CheckboxSwitch";
import useCollectionWithTemplate from "hooks/useCollectionWithTemplate";
import TemplateRequirementsList from "./TemplateRequirementsList";

interface FileRequirementsListProps extends BaseProps {
  taskCollectionRef: CollectionReference;
  openRequirementDrawer: (requirement: FileRequirement, type: REQUIREMENT_TYPE) => void;
  fileRequirementsCount: number;
  statusFilter: string;
  algoliaResults: any[] | null;
  fileRequirementsAccess: AccessType | null;
  parentEntity: Entity;
  showHidden?: boolean;
  openCreateFileRequirement: () => void;
  addFiles: (files: UploadFile[]) => void;
  fromTemplates?: boolean;
}

export default function FileRequirementsList(props: FileRequirementsListProps) {
  const {taskId} = useParams();
  const {
    toastProps,
    taskCollectionRef,
    fileRequirementsCount,
    statusFilter,
    openRequirementDrawer,
    openCreateFileRequirement,
    algoliaResults,
    fileRequirementsAccess,
    parentEntity,
    showHidden = false,
    ...rest
  } = props;

  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedRequirements, setSelectedRequirements] = useState<Set<string>>(new Set());
  const [isAllRequirementsChecked, setIsAllRequirementsChecked] = useState<boolean>(false);
  const [isDeleteDialogOpen, {open: openDeleteDialog, close: closeDeleteDialog}] = useComponentToggler(false);
  const [isHideToggleLoading, {toggle: setIsHideToggleLoading}] = useComponentToggler(false);

  const fileRequirementsRef = collection(taskCollectionRef, taskId!, "fileRequirements");
  const {data: fileRequirements, updateProjectConstraints, updateTemplateContraints} = useCollectionWithTemplate<FileRequirement>({colRef: fileRequirementsRef});
  const [selectMultiple, setSelectMultiple] = useState(false);

  const displayedRequirements = (algoliaResults ? algoliaResults : fileRequirements) ?? [];
  const requirementWithoutTemplate = useMemo(() => {
    if (!fileRequirements) return [];
    if (parentEntity === Entity.Templates) return fileRequirements;
    return fileRequirements.filter(requirement => !requirement.templateId);
  }, [fileRequirements]);

  // update contraints based on received status
  useEffect(() => {
    const constraint = [
      ...(!showHidden ? [where("hidden", "==", showHidden)] : []),
      orderBy("name", DirectionalOrder.asc),
    ];

    const newConstraints = statusFilter === allStatus ? constraint
      : [...constraint, where("fileRequirementStatus", "==", statusFilter)];

    updateProjectConstraints([...newConstraints]);
    updateTemplateContraints([...newConstraints]);
  }, [statusFilter, showHidden]);

  // update loading indicator based on the fetched filerequirements
  useEffect(() => {
    setIsLoading(fileRequirements === null);
  }, [fileRequirements]);

  useEffect(() => {
    if (requirementWithoutTemplate.length === 0) return;
    setIsAllRequirementsChecked(selectedRequirements.size === requirementWithoutTemplate.length);
  }, [selectedRequirements, requirementWithoutTemplate]);

  if (fileRequirementsAccess && !fileRequirementsAccess?.[PermissionOperationKey.View] && !props.fromTemplates)
    return (
      <ListContainer
        access={fileRequirementsAccess}
        entity={Entity.FileRequirement}
        displayCreate={false}
        onCreateButtonClick={emptyFunction}
        requirementsCount={fileRequirementsCount}
      >
        <NoPermission fullHeight={false} displayType={PermissionDisplayType.LIST}/>
      </ListContainer>
    );

  // dynamic viewing below
  if (displayedRequirements.length === 0)
    return (
      <Stack display="flex" flex={1}>
        <EmptyList
          access={fileRequirementsAccess}
          entity={Entity.FileRequirement}
          onCreateButtonClick={openCreateFileRequirement}
          requirementsCount={fileRequirementsCount}
        />
      </Stack>
    );

  function onRequirementCheckboxToggle(checked: boolean, id: string) {
    if (!id) return;
    if (checked) {
      setSelectedRequirements((prev) => {
        const newSet = new Set(prev);
        newSet.add(id);
        return newSet;
      });
      return;
    }
    setSelectedRequirements((prev) => {
      const newSet = new Set(prev);
      newSet.delete(id);
      return newSet;
    });
  }

  function handleSelectAll(checked: boolean) {
    setSelectedRequirements(() => {
      if (checked) {
        let newSet = new Set<string>();
        (fileRequirements ?? []).forEach((fileRequirement) => {
          if (!fileRequirement["@id"]) return;
          if (parentEntity !== Entity.Templates && !!fileRequirement.templateId) return;

          newSet.add(fileRequirement["@id"]);
        });
        return newSet
      }
      return new Set();
    });
  }

  async function onDeleteSelectedRequirements() {
    let submittedData: StatusSubmitHandler<any> [] = [];
    const requirementsToDelete = Array.from(selectedRequirements)

    await Promise.all(
      requirementsToDelete.map(async (id) => {
        const docRef = doc(fileRequirementsRef, id);
        const requirement = displayedRequirements.find(requirement => requirement.id === id);
        const templateId = requirement ? requirement.templateId: null;

        if (!templateId || parentEntity === Entity.Templates) {
          await submitForm(
            docRef,
            ActionType.Delete,
            (status, data, isLastUpdate) => {
              isLastUpdate && submittedData.push({status, data, isLastUpdate});
            }
          );
        }
      })
    );

    bulkStatusSubmitHandler<any>({
      data: submittedData,
      successCallback: () => successCallback(enFileRequirementLabel.deleteXRequirementsSuccess(selectedRequirements.size)),
      errorCallback: () => errorCallback(ProcessType.Delete),
    });
  }

  function successCallback(message: string) {
    closeDeleteDialog();
    setSelectedRequirements(new Set<string>());
    setToastMessage(message);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
  }

  function errorCallback(process: ProcessType) {
    showMessage(enCommonLabel.errorProcess(process), Severity.Error);
    setSelectedRequirements(new Set<string>());
    setIsHideToggleLoading();
    closeDeleteDialog();
  }

  function showMessage(message: string, severity: Severity) {
    setToastMessage(message);
    setToastSeverity(severity);
    setIsToastOpen(true);
  }

  function getBulkCheckboxLabel() {
    return selectedRequirements.size > 0 ?
      `${selectedRequirements.size} Selected` :
      enTaskLabel.allRequirements
  }

  function openChatDrawer(requirement: FileRequirement) {
    openRequirementDrawer(requirement, REQUIREMENT_TYPE.fileRequirement)
  }

  async function toggleHideStatus() {
    setIsHideToggleLoading();
    let submittedData: StatusSubmitHandler<any> [] = [];
    selectedRequirements.forEach((id) => {
        const documentRef = doc(fileRequirementsRef, id);
        submitForm(documentRef, ActionType.Update,
          (status, data, isLastUpdate) => {
            isLastUpdate && submittedData.push({status, data, isLastUpdate});
          },
          {hidden: !showHidden}
        );
      }
    );
    bulkStatusSubmitHandler<any>({
      data: submittedData,
      successCallback: () => successCallback(showHidden ? enFileRequirementLabel.unHideSuccess : enFileRequirementLabel.hideSuccess),
      errorCallback: () => errorCallback(ProcessType.Update),
    });
  }

  function deleteRequirement(reqId: string) {
    setSelectedRequirements(new Set<string>([reqId]));
    openDeleteDialog();
  }

  if (isLoading || !fileRequirements)
    return (
      <ListContainer
        access={fileRequirementsAccess}
        entity={Entity.FileRequirement}
        onCreateButtonClick={openCreateFileRequirement}
        requirementsCount={fileRequirementsCount}
      >
        <InProgress/>
      </ListContainer>
    )

  if (displayedRequirements.length === 0 && (algoliaResults || statusFilter !== allStatus))
    return (
      <ListContainer
        access={fileRequirementsAccess}
        entity={Entity.FileRequirement}
        onCreateButtonClick={openCreateFileRequirement}
        requirementsCount={fileRequirementsCount}
      >
        <EmptySearchResults entity={Entity.FileRequirement}/>
      </ListContainer>
    )

  const requirementsProps = {
    uid: props.uid,
    parentEntity,
    displayedRequirements: sortObjectsBy(displayedRequirements, "name"),
    toastProps,
    onCheckboxToggle: onRequirementCheckboxToggle,
    fileRequirementsRef,
    fileRequirementsAccess,
    selectedRequirements,
    deleteRequirement,
  }

  return (
    <>
      <ListContainer
        access={fileRequirementsAccess}
        entity={Entity.FileRequirement}
        onCreateButtonClick={openCreateFileRequirement}
        requirementsCount={fileRequirementsCount}
      >
        <Stack direction="row">
          <CheckboxSwitch sx={{mt: 1}} isCheckboxShown={selectMultiple} toggleCheckbox={setSelectMultiple}/>
          {displayedRequirements.length > 0 && selectMultiple && (
            <BulkActions
              disabled={requirementWithoutTemplate.length === 0}
              ml={parentEntity === Entity.Templates ? 1.5 : 3.5}
              parentEntity={parentEntity}
              openDeleteDialog={openDeleteDialog}
              label={getBulkCheckboxLabel()}
              isAllRequirementsChecked={isAllRequirementsChecked}
              showBulkActionsButtons={selectedRequirements.size > 0}
              handleSelectAll={handleSelectAll}
              showHidden={showHidden}
              toggleHiddenStatus={{
                toggleLoading: isHideToggleLoading,
                toggleFunction: toggleHideStatus
              }}
            />
          )}
        </Stack>
        <TemplateRequirementsList {...requirementsProps} showCheckBox={selectMultiple}/>
        <RequirementsList
          {...requirementsProps}
          {...rest}
          taskCollectionRefPath={taskCollectionRef.path}
          fileRequirementAccess={fileRequirementsAccess}
          openRequirementDrawer={openChatDrawer}
          showCheckBox={selectMultiple}
        />
      </ListContainer>
      <DeleteDialog
        overlayId="delete-selected-dialog"
        confirmButtonId={`del_${selectedRequirements.size}_confirmBtn`}
        cancelButtonId={`del_${selectedRequirements.size}_cancelBtn`}
        isOpen={selectedRequirements.size > 0 && isDeleteDialogOpen}
        title={enFileRequirementLabel.deleteXRequirements(selectedRequirements.size)}
        text={enFileRequirementLabel.massDeleteConfirmationText}
        handleClose={closeDeleteDialog}
        handleConfirm={onDeleteSelectedRequirements}
      />
    </>
  )
}
