import {auth} from "../../../firebase";
import {enActivityFeedLabel} from "constants/index";
import {InvitedItemType} from "types/ActivityFeedTypes";

function generateInviteText(inviteItem: InvitedItemType){
  const user = auth.currentUser
  const {email} = user ?? {email: ""}

  const {details, orgName} = inviteItem;
  const {invite, by} = details;
  const {inviteeEmail, firstName} = invite ?? {email: "", firstName: ""};

  if (inviteeEmail === email || !invite)
    return enActivityFeedLabel.invitationToJoin(orgName, by.name)

  return enActivityFeedLabel.thirdPartyInvitationToJoin(orgName, by.name, firstName, inviteeEmail)
}

export default generateInviteText
