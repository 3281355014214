interface GroupedData<T> {
  [key: string]: T[];
}

export function groupBy<T>(arr: T[], key: keyof T): GroupedData<T> {
  return arr.reduce((acc: GroupedData<T>, obj: T) => {
    const property = obj[key];
    // @ts-ignore
    if (!acc[property]) {
      // @ts-ignore
      acc[property] = [obj];
    } else {
      // @ts-ignore
      acc[property].push(obj);
    }
    return acc;
  }, {} as GroupedData<T>);
}
