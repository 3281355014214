import {Typography} from "@mui/material";

export const organization = {
  title: "Organization",
  subtitle: "",
  description: "",
  label: {
    new: "Create New Organization",
    edit: "Edit Organization",
    manageMembers: "Manage Members",
    delete: "Delete Organization",
    explore: "Explore Organizations",
    search: "Search Organization...",
    searchPointOfContact: "Search point of contact",
    emptyTitle: "No Organizations yet",
    emptyDescription: "Create the first organization.",
    invites: "Invites",
    organizations: "Organizations",
    buildingOrganization: "Building Organization...",
    downloadOrganizations: "Download Organizations",
    createSuccess: (name: string) => `Organization ${name} was created successfully.`,
    updateSuccess: "Organization was updated successfully",
    deleteConfirmationTitle: (name: string) => `Are you sure you want to delete ${name} organization`,
    deleteConfirmationText: "These items will be deleted permanently. You can't undo this action",
    deleteSuccess: "Organization deleted successfully",
    pointOfContact: "Point of Contact",
    requiredProjectName: "Project name is required",
    requiredOrganizationName: "Organization name is required",
    invitationToJoin: (inviter: string, org?: string) =>
      <Typography variant="h4" fontWeight={400}>
        Invitation to join {org ? <strong>{org}</strong> : "organization"} by <strong>{inviter}</strong>
      </Typography>,
    invitationExpired: (inviter: string, org?: string) =>
      <Typography variant="h4" fontWeight={400}>
        Invitation to join {org ? <strong>{org}</strong> : "organization"} by <strong>{inviter}</strong> has been expired
      </Typography>,
    noPointOfContact: "No one",
    projects: "Projects",
    members: "Members",
    delayedTasks: "Delayed tasks",
    unassignedTasks: "Unassigned tasks",
    import: "Import",
    export: "Export",
    importing: "Importing",
    exportOrganizations: "Export Organizations",
    exportingText: "Exporting all Organizations",
    exportOrganizationsText: "Export all Organizations",
  },
  button: {
    create: "Create New Organization",
  }
}