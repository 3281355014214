import {CollectionReference, getCountFromServer, query, QueryConstraint} from "firebase/firestore";

export async function getCollectionCount(collectionRef: CollectionReference, constraints: QueryConstraint[] = []) {
  try {
    const collectionQuery = query(collectionRef, ...(constraints || []));
    const collectionSnapshot = await getCountFromServer(collectionQuery);
    return collectionSnapshot.data().count || 0;
  } catch (e) {
    console.error(e);
    return 0;
  }
}