import {organizationTimersPath} from "screens/utility/FirebasePath";
import BaseTimersView from "components/Timers/TimersViews/BaseTimersView";
import {useState} from "react";
import {CollapseStates} from "components/Timers/TimersViews/MyTimersView";
import useManualParams from "hooks/useManualParams";

function OrganizationTimersView({uid}: {uid: string}) {
  const [collapseStates, setCollapseStates] = useState<CollapseStates>({started: false, paused: false})
  const {organizations} = useManualParams();

  return (
    <BaseTimersView
      uid={uid}
      emptyPage
      colRef={organizationTimersPath(organizations ?? "fillerId")}
      organizationTimer
      collapseStates={collapseStates}
      setCollapseStates={setCollapseStates}
    />
  )
}

export default OrganizationTimersView
