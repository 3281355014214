import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, IconButton,
} from "@mui/material";
import {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {SystemIcons} from "assets/icons/system/system.index";

export interface DialogProps {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  title: string;
  text: string;
  id?: string;
  confirmButtonId?: string;
  cancelButtonId?: string;
  confirmButtonText?: any;
  confirmButtonSx?: any;
  isCloseButtonShown?: boolean;
  isDisabled?: boolean;
  confirmButtonIcon?:  React.ReactNode;
  disableConfirmButton?: boolean;
}

const ConfirmDialog = (props: DialogProps) => {
  const {
    id = "confirm-dialog",
    isOpen,
    handleClose,
    handleConfirm,
    text,
    title,
    confirmButtonId = "confirm-button",
    cancelButtonId = "cancel-button",
    confirmButtonText = "Delete",
    confirmButtonSx = {},
    isCloseButtonShown = false,
    isDisabled = false,
    confirmButtonIcon,
    disableConfirmButton = false,
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setIsLoading(false);
    }
  }, [isOpen]);

  return (
    <Dialog
      id={id}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          display: "flex",
          fontWeight: "bold",
          fontSize: 20,
          justifyContent: "space-between",
          alignItems: "center",
      }}
      >
        {title}
        {
          isCloseButtonShown && (
            <IconButton onClick={handleClose}>
              <SystemIcons.Close />
            </IconButton>
          )
        }
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{fontSize: 14, color: "black"}}
          id="alert-dialog-description"
        >
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id={cancelButtonId}
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.secondary,
          })}
          onClick={e => {
            e.stopPropagation();
            handleClose();
          }}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          id={confirmButtonId}
          sx={(theme) => ({
            backgroundColor: theme.palette.error.main,
            color: theme.palette.text.onDark,
            "&:hover": {
              backgroundColor: theme.palette.error.light,
              color: theme.palette.text.onDark,
            },
            ...confirmButtonSx
          })}
          onClick={e => {
            e.stopPropagation();
            setIsLoading(true);
            handleConfirm();
          }}
          startIcon={confirmButtonIcon}
          disabled={isDisabled || disableConfirmButton}
          autoFocus
        >
          {confirmButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
