import { db } from "../firebase";
import { onSnapshot, doc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { User } from "../types/User";

function useUser(uid: string | undefined ) {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        if (!uid) return;
        return onSnapshot(
            doc(db, "users", uid),
            (snapshot) => {
                if (!snapshot.exists) return;
                setUser(snapshot.data() as User);
            }
        )
    }, []);

    return user;
}

export default useUser;