import { Stack } from "@mui/material";

interface FormInputGroupProps {
    formTitle: JSX.Element;
    children: React.ReactNode;
    sx?: any;
    containerStyles?: any;
}

function FormInputGroup(props: FormInputGroupProps) {
    const { children, sx, containerStyles, formTitle } = props;
    return (
        <Stack
            direction="column"
            gap={0}
            sx={sx ?? {}}
        >
            {formTitle}
            <Stack
                direction="row"
                gap={2}
                sx={{
                    flex: 1,
                    display: "flex",
                    alignContent: "center",
                    ...(containerStyles ?? {})
                }}
            >
                {children}
            </Stack>
        </Stack>
    )
}

export default FormInputGroup;