import {Box, Card, CardContent, Divider, Grid, IconButton, Typography,} from "@mui/material";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Organization} from "types/Organization";
import {LongText} from "components/index";
import {ActionType, CounterFields, Entity} from "enums/index";
import {getDocPath, submitForm, userPath} from "../../utility";
import {emptyFunction, enOrganizationLabel} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {BaseProps} from "screens/BaseProps";
import {useAccess, useRTDBDocField} from "hooks/index";
import OrganizationCardContent from "./OrganizationCardContent";
import MoreOptionsMenu from "../OrganizationsCardObjects/MoreOptionsMenu";
import {PermissionEntity} from "types/Permission";
import AssignableAvatar from "components/AssignableAvatar";
import CardBadge, {OverlayType} from "components/CardBadge";
import {SelectedOrgContext} from "../../SelectedOrgContextProvider";
import useProgressCount from "hooks/useProgressCount";

interface OrganizationCardProps extends BaseProps {
  organization: Organization;
  updateSelected: (obj: Organization | null) => void;
  deleteOrg: (org: Organization) => void;
  parentPath: string;
  fromSAP?: boolean;
}

function OrganizationCard(props: OrganizationCardProps) {
  const {organization, uid, setSelectedOrgId = emptyFunction, updateSelected, deleteOrg} = props;

  const selectedOrgContext = useContext(SelectedOrgContext);
  const {selectedOrg, setSelectedOrg} = selectedOrgContext!;

  const {"@id": orgId, name, description} = organization;

  const {progress: buildingProgressCount, isProcessing} = useProgressCount({
    path: getDocPath(uid, props.parentPath, orgId),
    counterField: CounterFields.BuildingProgressCount,
  });
  const membersCount = useRTDBDocField<number>(getDocPath(uid, props.parentPath, orgId), CounterFields.MembersCount) ?? 0;

  const building = buildingProgressCount > 0 && buildingProgressCount < 100;

  const [hovered, setHovered] = useState<boolean>(false);
  const [isMenuShowing, setIsMenuShowing] = useState<boolean>(false);
  const moreButtonRef = useRef(null);

  const [access, role, updateOrgIds] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.Organization,
    ids: {orgId: orgId!}
  });

  const [projectAccess, , updateProjIds] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.Project,
    ids: {orgId: orgId!}
  });

  // Popover related properties
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const pointOfContact = organization.pointOfContact;

  const navigate = useNavigate();
  const popoverProps = {anchorEl, orgId: orgId!, open};

  useEffect(() => {
    updateOrgIds({orgId});
    updateProjIds({orgId})
  }, [orgId, open]);

  function updateSidebarView() {
    setSelectedOrgId(orgId!);

    let newSidebarView: any = {
      sidebarView: {
        selectedOrg: {
          id: orgId!,
          name,
          accessRole: role,
        },
      },
    }

    submitForm(userPath(uid), ActionType.Update,
      emptyFunction,
      newSidebarView
    );

    if (JSON.stringify(selectedOrg) !== JSON.stringify(newSidebarView.sidebarView.selectedOrg)) {
      setSelectedOrg({
        ...newSidebarView.sidebarView.selectedOrg,
        isAdminView: Boolean(props.fromSAP),
        members: selectedOrg.members,
        teams: selectedOrg.teams,
      });
      localStorage.setItem("lastSelectedOrg", JSON.stringify({...newSidebarView.sidebarView.selectedOrg, isAdminView: Boolean(props.fromSAP)}));
    }
  }

  async function onCardClick() {
    await updateSidebarView();
    navigate(`/${orgId}/projects`);
  }

  function handleMoreOptionsClick(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    e.preventDefault();

    setAnchorEl(moreButtonRef.current);
    setIsMenuShowing((prev) => !prev);
  }

  function handleMenuMoreOptionsClose(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();

    setIsMenuShowing(false);
    setAnchorEl(null);
  }

  function handleMenuEditOrganizationClick() {
    updateSelected(organization);
    setIsMenuShowing(false);
    setAnchorEl(null);
  }

  function handleDeleteOrganizationMenuClick() {
    deleteOrg(organization);
    setIsMenuShowing(false);
    setAnchorEl(null);
  }

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={4}
      xl={3}
      justifyContent="center"
      alignItems="center"
      paddingTop={5}
      paddingRight={1}
    >
      <Card
        id={`organization-card-${orgId}`}
        onClick={onCardClick}
        className="org-card"
        onMouseEnter={() => setHovered(!building)}
        onMouseLeave={() => setHovered(false)}
        onContextMenu={handleMoreOptionsClick}
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          position: "relative",
          overflow: "inherit",
          border: `1px solid ${hovered ? theme.palette.primary.main : "transparent"
          }`,
          height: "100%",
          cursor: "pointer",
          opacity: 1,
          ...(isProcessing ? {borderColor: theme.palette.warning.main}: {})
        })}
      >
        <CardBadge
          id={`organization-badge-${orgId}`}
          text={enOrganizationLabel.buildingOrganization}
          buildingProgressCount={buildingProgressCount}
          toastProps={props.toastProps!}
          process={OverlayType.Building}
        />
        <Box
          sx={(theme) => ({
            position: "absolute",
            border: `1px solid ${hovered ? theme.palette.primary.main : theme.palette.divider
            }`,
            borderRadius: "4px",
            width: 48,
            height: 48,
            display: "grid",
            placeItems: "center",
            top: "-24px",
            left: "16px",
            backgroundColor: building ? theme.palette.neutral.light : "white",
          })}
        >
          <Typography variant="h3">{(name ?? "O")[0].toUpperCase()}</Typography>
        </Box>
        <CardContent
          sx={{
            position: "relative",
            paddingTop: 3,
            flex: 1,
          }}
        >
          <LongText
            id="org-name"
            maxLines={2}
            className="org-name"
            gutterBottom
            variant="h3"
            title={name}
            maxWidth="250px"
          >
            {name}
          </LongText>
          <IconButton
            onClick={handleMoreOptionsClick}
            sx={{
              position: "absolute",
              right: "16px",
              top: "20px",
              borderRadius: "3px",
            }}
            id={`${orgId!}-more-options-button`}
            ref={moreButtonRef}
          >
            <SystemIcons.MoreVertical
              stroke={theme.palette.text.secondary}
              height={16}
              width={16}
            />
          </IconButton>
          <OrganizationCardContent
            uid={uid}
            orgId={orgId!}
            description={description}
            membersCount={membersCount}
            parentPath={props.parentPath}
            fromSAP={props.fromSAP}
          />
          {isMenuShowing && (
            <MoreOptionsMenu
              uid={uid}
              {...popoverProps}
              access={access}
              projectAccess={projectAccess}
              fromSAP={props.fromSAP}
              closeMenu={handleMenuMoreOptionsClose}
              editMenuClick={handleMenuEditOrganizationClick}
              deleteMenuClick={handleDeleteOrganizationMenuClick}
            />
          )}
        </CardContent>
        <Divider/>
        <Box display="flex" flexDirection="column" px={2} py={1} gap={0.5}>
          <Typography variant="caption" color="text.secondary">
            {enOrganizationLabel.pointOfContact}
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            {pointOfContact ?
              <>
                <AssignableAvatar
                  sx={{
                    mt: 0,
                    width: 24,
                    height: 24,
                    border: 0,
                    fontSize: 10
                  }}
                  {...pointOfContact}
                  nameComponent={<Typography variant="h5">{`${pointOfContact.name}`}</Typography>}
                />
              </> :
              <Typography variant="h5">{enOrganizationLabel.noPointOfContact}</Typography>
            }
          </Box>
        </Box>
      </Card>
    </Grid>
  );
}

export default OrganizationCard;
