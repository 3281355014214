import {ReactNode, useEffect, useRef, useState} from "react";
import {Box} from "@mui/material";
import theme from "theme/theme";

interface StickySearchAndFilterProps {
  topPosition?: number | undefined;
  children: ReactNode
}

function StickySearchAndFilter(props: StickySearchAndFilterProps) {
  const {children, topPosition = 68} = props;
  const [onTop, setOntop] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function listener() {
      if (ref.current) {
        const top = ref.current.getBoundingClientRect().top;
        setOntop(top < topPosition)
      } else {
        setOntop(false)
      }
    }

    window.addEventListener("scroll", listener)

    return () => window.removeEventListener("scroll", listener)
  }, [ref.current]);

  return <>
    <Box ref={ref} height={onTop ? ref.current?.clientHeight : 0} width={"100%"}></Box>
    <Box
      sx={{
        position: onTop ? "fixed" : "relative",
        width: onTop ? {sm: "100%", lg: "calc(100% - 280px)"} : "auto",
        // height: ref.current?.clientHeight,
        top: onTop ? `${topPosition}px` : 0,
        right: 0,
        boxShadow: onTop ? 1 : 0,
        zIndex: 2,
        backgroundColor: onTop ? theme.palette.background.paper : "transparent",
        paddingX: onTop ? {xs: "15%", sm: "15%", md: "10%", lg: "8%"} : 0,
        paddingY: 1,
      }}
    >
      {children}
    </Box>
  </>
}

export default StickySearchAndFilter;
