import {organizationDataTagsPath, referenceFilespath} from "screens/utility";
import React, {Dispatch, useRef} from "react";
import {Autocomplete, Button, createFilterOptions, SxProps, TextField} from "@mui/material";
import {LongText} from "components/index";
import {useCollection} from "hooks/index";
import {Entity} from "enums/entity";
import {DataTag} from "types/DataTag";
import {ReferenceFile} from "types/ReferenceFile";
import {useParams} from "react-router-dom";
import Theme from "theme/theme";
import {en} from "language/en";

interface AutoCompleteOption {
  id: string;
  label: string;
}

interface AutocompleteInputProps {
  setName: Dispatch<string>;
  defaultName: string;
  entity?: Entity;
  fieldSx?: SxProps<typeof Theme>;
  sx?: SxProps<typeof Theme>;
  onKeyPress?: (e: React.KeyboardEvent) => void;
  uid: string;
}

function AutocompleteInput(props: AutocompleteInputProps) {
  const {setName, defaultName, entity, fieldSx, sx, onKeyPress} = props;

  const {orgId} = useParams();

  let [availableDataTags] = useCollection<DataTag>(null, organizationDataTagsPath(orgId!));
  const [referenceFiles] = useCollection<ReferenceFile>(
    null,
    entity === Entity.FileRequirement || entity === Entity.Task ? referenceFilespath(orgId!) : null
  );

  const searchRef = useRef<HTMLInputElement>();

  const _filterOptions = createFilterOptions();
  const filterOptions = (options: AutoCompleteOption[], state: any) => {
    const results = _filterOptions(options, state) as AutoCompleteOption[];

    return results as AutoCompleteOption[];
  };

  function getAutocompleteOptions() {
    if (!availableDataTags) return [];
    const referenceFilesAsTags = (referenceFiles ?? []).map(refFile => ({
      id: refFile.id!,
      name: `ref:${refFile.name!}`,
      description: `Reference File: ${refFile.name!}`,
      type: "referenceFile"
    }));

    return [
      ...([
        ...availableDataTags,
        ...referenceFilesAsTags
      ]).map(dataTag => ({id: dataTag.id!, label: dataTag.name!})),
    ];
  }

  function onAutoCompleteChange(e: React.SyntheticEvent, value: string | { label: string } | null) {
    e.stopPropagation();
    e.preventDefault();
    setName(typeof value === "string" ? value : value?.label ?? "")
  }

  return (
    <Autocomplete
      filterOptions={filterOptions}
      sx={sx || {
        "& .MuiAutocomplete-option": {
          color: "red!",
        },
        "& .MuiOutlinedInput-root": {
          py: 1 / 2,
        },
      }}
      multiple={false}
      onChange={onAutoCompleteChange}
      onKeyPress={(e) => {
        if (e.key !== "Enter") return;
        // if inputValue is empty, trigger parent onKeyPress
        if (defaultName === "" && onKeyPress) return onKeyPress(e);

        // check if value is in options
        const option = getAutocompleteOptions().find(option => option.label === defaultName);
        // if yes, add it
        if (option) {
          setName("");
          return;
        }
      }}
      value={defaultName ?? ""}
      placeholder={en.dataTag.nameIsRequired}
      options={getAutocompleteOptions()}
      renderOption={(props, option) => {
        const {className, ...rest} = props
        //let's force to use button instead of li
        // @ts-ignore
        return <Button fullWidth {...rest} sx={{display: "inline-block", textAlign: "left"}}>
          <LongText>{option.label}</LongText>
        </Button>
      }}
      onKeyDown={onKeyPress}
      freeSolo={true}
      renderInput={(params) => (
        <TextField
          inputRef={searchRef}
          sx={{
            "& .MuiOutlinedInput-input": {
              padding: "9px 16px",
            },
            ...fieldSx
          }}
          {...params}
          autoFocus={true}
          onChange={(e) => setName(e.target.value)}
          InputProps={{...params.InputProps, inputProps: {...params.inputProps, maxLength: 32}}}
          value={defaultName}
        />
      )}
    />
  )
}

export default AutocompleteInput;