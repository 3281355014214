import {toastProps} from "screens/BaseProps";
import {useAccess, useDocument, useRTDBDocField} from "hooks/index";
import {db} from "../../../firebase";
import {doc} from "firebase/firestore";
import {Box, Checkbox, Divider, Grid, Hidden, Skeleton, Stack, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {LongText, SelectStatus} from "components/index";
import {CounterFields, Entity} from "enums/index";
import CardBadge, {OverlayType} from "components/CardBadge";
import {ProjectStatusWithIcons} from "assets/arrayList/ProjectStatus";
import theme from "theme/theme";
import UniqueAssetIcon from "assets/icons/UniqueAssetIcon";
import {enAssetLabel} from "constants/index";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import {PermissionEntity, PermissionOperationKey} from "types/Permission";
import {SystemIcons} from "assets/icons/system/system.index";
import {ChatIconButton} from "components/Button";
import {Asset} from "types/Asset";
import {useNavigate} from "react-router";
import {StatusItem} from "types/StatusItem";
import {getDocPath} from "screens/utility/getDocPath";
import useProgressCount from "hooks/useProgressCount";

interface AssetItemProps {
  uid: string;
  assetId: string;
  assetDocPath: string;
  isCheckboxShown: boolean;
  isChecked: boolean;
  toastProps: toastProps;
  openChatDrawer: (asset: Asset) => void;
  onAssetCheck: (id: string, checked: boolean) => void;
  onMenuOpen: (e: Element | undefined, asset: Asset, anchorPosition?: { left: number, top: number }) => void;
}

function AssetItem(props: AssetItemProps) {
  const {assetId, uid, assetDocPath, isCheckboxShown, openChatDrawer} = props;
  const nav = useNavigate();
  const assetRef = useRef<HTMLDivElement | null>(null);

  const [asset] = useDocument<Asset>(doc(db, assetDocPath));
  const [selectedStatus, setSelectedStatus] = useState<StatusItem | undefined>(undefined);
  const tasksCount = useRTDBDocField<number>(getDocPath(uid, assetDocPath), CounterFields.TasksCount) ?? 0;
  const tasksAprrovedCount = useRTDBDocField<number>(getDocPath(uid, assetDocPath), CounterFields.TasksApprovedCount) ?? 0;
  const tasksCancelledCount = useRTDBDocField<number>(getDocPath(uid, assetDocPath), CounterFields.TasksCancelledCount) ?? 0;

  // org data:
  const {progress: importingProgressCount} = useProgressCount({
    path: assetDocPath,
    counterField: CounterFields.ImportingProgressCount,
  });
  const {progress: buildingProgressCount} = useProgressCount({
    path: assetDocPath,
    counterField: CounterFields.BuildingProgressCount,
  });

  // user specific data:
  const hasNewChat = useRTDBDocField<boolean>(getDocPath(uid, assetDocPath), 'hasNewChat') ?? false;
  const chatEmpty = useRTDBDocField<boolean>(assetDocPath, 'chatEmpty') ?? true;

  const [access] = useAccess({
    uid,
    entity: Entity.Asset,
    documentDocId: PermissionEntity.Asset,
    ids: {assetId},
  });

  const canUpdateStatus = Boolean(access?.[PermissionOperationKey.ChangeStatus]) || Boolean(access?.[PermissionOperationKey.ChangeStatus]);

  useEffect(() => {
    // if the value is undefined, let it be
    if (asset?.assetStatus === undefined) return;

    // if the value is the same, don't update
    if (selectedStatus === ProjectStatusWithIcons[asset?.assetStatus]) return;

    setSelectedStatus(ProjectStatusWithIcons[asset?.assetStatus]);
  }, [asset?.assetStatus]);

  function onCardSelect(e: React.ChangeEvent, checked: boolean) {
    props.onAssetCheck(assetId, checked);
  }

  function navigateAsset() {
     nav(`../assets/${assetId}`)
  }

  if (asset === null) {
    return <Skeleton variant="rounded" height={100} sx={{marginY: 1}}/>
  }

  if (asset === undefined) return <div style={{height: 1}}/>;

  const isImporting = importingProgressCount > 0 && importingProgressCount < 100;
  const isBuilding = buildingProgressCount > 0 && buildingProgressCount < 100;

  return (
    <Stack
      key={`asset_${assetId}`}
      className="asset-row-item"
      direction="row"
      alignItems="center"
      paddingRight={0}
      py={0}
      my={1}
      sx={(theme) => ({
        border: `1px solid ${props.isChecked ? theme.palette.primary.main : theme.palette.divider}`,
        borderRadius: 1,
        display: "flex",
        position: "relative",
        overflow: "inherit",
        cursor: !(isImporting || isBuilding) ? "pointer" : "default",
        ":hover": {
          border: `1px solid ${(isImporting || isBuilding) ? theme.palette.warning.main : theme.palette.primary.main}`,
        },
      })}
      ref={assetRef}
      onContextMenu={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        props.onMenuOpen(assetRef.current!, asset, {left: e.clientX, top: e.clientY})
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        navigateAsset()
      }}
    >
      <CardBadge
        id={`asset-badge-${assetId}`}
        text={isImporting ? enAssetLabel.importingAssets : enAssetLabel.buildingAssets}
        buildingProgressCount={buildingProgressCount || importingProgressCount || 0}
        toastProps={props.toastProps!}
        process={(buildingProgressCount || 0) > 0 ? OverlayType.Building : OverlayType.Importing}
      />
      <Stack direction="row" alignSelf="stretch">
        {isCheckboxShown && (
          <Checkbox
            checked={props.isChecked}
            onClick={e => e.stopPropagation()}
            onChange={onCardSelect}
            id={`assetCheckbox_${assetId}`}
          />
        )}
        <SelectStatus
          toastProps={props.toastProps!}
          id="org-select-field"
          documentRef={doc(db, assetDocPath)}
          statusField="assetStatus"
          displayIconOnSelected={true}
          displayTextOnSelected={false}
          items={ProjectStatusWithIcons}
          selectItemSx={{variant: "body1"}}
          selected={selectedStatus}
          selectSx={{
            height: "100%",
            width: "100%",
            border: "none",
            "&:disabled": {
              border: "none"
            },
            "&:hover": {
              border: "none",
              backgroundColor: "transparent"
            },
            marginLeft: isCheckboxShown ? -1.5 : 0,
          }}
          isDisabled={!canUpdateStatus || isImporting || isBuilding}
          width="50px"
        />
      </Stack>
      <Divider orientation="vertical" flexItem sx={{marginRight: 1}}/>
      <Stack
        alignItems="center"
        direction="row"
        flex={1}
        spacing={2}
        py={1}
      >
        <Hidden mdDown>
          <Box sx={{alignSelf: "center", display: "flex"}}>
            <UniqueAssetIcon color={asset.avatarColor}/>
          </Box>
        </Hidden>
        <Stack flex={1} gap={0.5}>
          <LongText variant="h4" maxLines={2} maxWidth="100%" overflowWrap="anywhere">{asset.name}</LongText>
          <LongText color="text.secondary" variant="bodySmall" maxLines={2} maxWidth="100%" overflowWrap="anywhere">
            {!!asset.idCode ? `ID: ${asset.idCode}` : <i>{enAssetLabel.noIdCode}</i>}
          </LongText>
          {(asset.dataTagsIds ?? [].length > 0) && (
            <DataTagsDisplay
              listSize="medium"
              dataTagsIds={asset.dataTagsIds ?? []}
              toastProps={props.toastProps!}
              canEditDocumentRef={Boolean(access?.[PermissionOperationKey.Update])}
              uid={assetDocPath.split("/")[1]}
              documentRef={doc(db, assetDocPath)}
            />
          )}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        py={0}
        px={2}
        spacing={1}
        minHeight={100}
      >
        <Hidden mdDown>
          <Stack p={1} direction="row" gap={0.5} alignItems="center" alignSelf="center">
            <SystemIcons.Tasks
              stroke={theme.palette.text.secondary}
              height={16}
              width={16}
            />
            <Typography variant="h5" color="text.secondary">
              {tasksAprrovedCount}/{tasksCount - tasksCancelledCount}
            </Typography>
          </Stack>
          <Divider orientation="vertical" flexItem/>
        </Hidden>
        <Box alignSelf="center" height={"100%"}>
          <ChatIconButton
            id={`${asset["@id"]}-asset-item-chat-button`}
            entity={Entity.Asset}
            withNewMessage={hasNewChat}
            chatEmpty={chatEmpty}
            onClickFcn={(e) => {
              e.stopPropagation();
              openChatDrawer(asset)
            }}
          />
        </Box>
      </Stack>
    </Stack>
  )
}

export default AssetItem;