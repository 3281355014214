import {Input, SimpleFormDrawer} from "components/index";
import {User} from "types/User";
import {Avatar, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {enCommonLabel, enUserLabel} from "constants/index";
import {areObjectsEqual, onChangeInput, submitForm, validEmail} from "screens/utility";
import {StringDictionary} from "types/FormBase";
import {useCollection} from "hooks/index";
import {CollectionReference, doc} from "firebase/firestore";
import {ActionType, Severity, ProcessType} from "enums/index";
import {toastProps} from "screens/BaseProps";
import {statusSubmitHandler} from "screens/utility/statusSubmitHandler";

interface EditUserDrawerProps {
  isOpen: boolean;
  user: Partial<User>;
  collectionRef: CollectionReference;
  toastProps: toastProps;
  onClose: () => void;
}

function EditUserDrawer(props: EditUserDrawerProps) {
  const {isOpen, user, collectionRef, toastProps, onClose} = props;
  const {setIsToastOpen, setToastSeverity, setToastMessage} = toastProps;
  const {name, initials = "AA", avatarColor = "white"} = user;

  const [initialData, setInitialData] = useState({
    firstName: user.firstName ?? "",
    lastName: user.lastName ?? "",
    email: user.email ?? "",
  });

  const userDocRef = doc(collectionRef, user["@id"]! ?? "fillerId");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>(initialData.firstName);
  const [lastName, setLastName] = useState<string>(initialData.lastName);
  const [email, setEmail] = useState<string>(initialData.email);
  const [validationErrors, setValidationErrors] = useState<StringDictionary | null>(null);

  const [userDocs] = useCollection<User>(null, collectionRef);

  useEffect(() => {
    if (isLoading) return;

    let newData = {
      firstName: user.firstName ?? "",
      lastName: user.lastName ?? "",
      email: user?.email ?? "",
    }
    setInitialData(newData);
    setFirstName(newData.firstName);
    setLastName(newData.lastName);
    setEmail(newData.email);

    setIsLoading(false);
    setValidationErrors(null);
  }, [isOpen]);

  // check email validity
  useEffect(() => {
    let errorMessages = {...validationErrors};
    let emailError = "";

    if (email.trim() === "")
      emailError = enUserLabel.fieldIsRequired;

    if (email !== initialData.email && !validEmail(email))
      emailError = enUserLabel.invalidEmail;

    if (email !== initialData.email
      && (userDocs ?? []).filter(userDoc => userDoc.email === email.trim()).length > 0)
      emailError = enUserLabel.emailAlreadyExists;

    errorMessages = {...errorMessages, email: emailError};

    if (emailError === "") delete errorMessages.email;

    setValidationErrors(JSON.stringify(errorMessages) === "{}" ? null : errorMessages);
  }, [email]);

  // all fields are required
  useEffect(() => {
    let errorMessages = {...validationErrors};

    [{field: "firstName", value: firstName}, {field: "lastName", value: lastName}].forEach(entry => {
      const errorMessage = entry.value.trim() === "" ? enUserLabel.fieldIsRequired : "";

      if (errorMessage === "") {
        delete errorMessages[entry.field];
        return;
      }

      errorMessages = {
        ...errorMessages,
        [entry.field]: errorMessage
      }
    });

    setValidationErrors(JSON.stringify(errorMessages) === "{}" ? null : errorMessages);
  }, [firstName, lastName]);

  function isFormEdited() {
    const newUser = {
      firstName,
      lastName,
      email
    }
    return !areObjectsEqual(initialData, newUser);
  }

  function isFormValid() {
    return (isFormEdited() && validationErrors === null && !isLoading);
  }

  function successCallback() {
    setIsLoading(false);
    setToastMessage(enUserLabel.editSuccess);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    onClose();
  }

  function errorCallback(message: any) {
    setIsLoading(false);
    if (typeof (message) === "object") {
      setValidationErrors(message as StringDictionary);
      return;
    }

    setToastMessage(message || enCommonLabel.errorProcess(ProcessType.Update));
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
  }

  async function onSaveUser() {
    setIsLoading(true);

    await submitForm(userDocRef, ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler({status, data, isLastUpdate, successCallback, errorCallback}),
      {firstName,lastName, email}
    );
  }

  function onKeyPress(e: React.KeyboardEvent) {
    if (e.key === "Enter" && isFormValid() && !isLoading) onSaveUser();
  }

  return (
    <SimpleFormDrawer
      title={name ?? ""}
      icon={
        <Stack direction="row" justifyContent="center" mb={1}>
          <Avatar
            sx={(theme) => ({
              backgroundColor: avatarColor ?? theme.palette.common.black,
              width: 40,
              height: 40,
              mt: 0,
            })}
          >
            {initials}
          </Avatar>
        </Stack>
      }
      customIcon={true}
      onClose={onClose}
      isOpen={isOpen}
      isFormValid={isFormValid()}
      isLoading={isLoading}
      unsavedChanges={isFormEdited()}
      onSubmit={onSaveUser}
    >
      <Input
        label={enUserLabel.firstName}
        value={firstName}
        onChange={(e) => onChangeInput(e, setFirstName)}
        sx={{mb: 3}}
        id="first-name-input"
        validationMessage={validationErrors?.["firstName"] || ""}
        onKeyPress={onKeyPress}
      />
      <Input
        label={enUserLabel.lastName}
        value={lastName}
        onChange={(e) => onChangeInput(e, setLastName)}
        sx={{mb: 3}}
        id="last-name-input"
        validationMessage={validationErrors?.["lastName"] || ""}
        onKeyPress={onKeyPress}
      />
      <Input
        label={enUserLabel.email}
        value={email}
        onChange={(e) => onChangeInput(e, setEmail)}
        sx={{mb: 3}}
        id="email-name-input"
        validationMessage={validationErrors?.["email"] || ""}

        disabled
      />
    </SimpleFormDrawer>
  )
}

export default EditUserDrawer;