import {Checkbox, FormControlLabel, Stack} from "@mui/material";
import {ReferenceFileItem} from "./ReferenceFileItem";
import {EmptyList, SortMenu} from "components/index";
import theme from "theme/theme";
import {useComponentToggler} from "hooks/index";
import DeleteDialog from "components/Dialogs/DeleteDialog";
import {enCommonLabel, enReferencesLabel, enTemplateLabel} from "constants/index";
import {doc} from "firebase/firestore";
import {useParams} from "react-router-dom";
import React, {Dispatch, useState} from "react";
import {submitForm, referenceFilespath} from "../../utility";
import {ActionType, Entity, DirectionalOrder, ProcessType, Severity} from "enums/index";
import {BaseProps} from "../../BaseProps";
import EmptySearchResults from "components/EmptySearchResults";
import {ReferenceFile} from "types/ReferenceFile";
import {AccessType, PermissionOperationKey} from "types/Permission";
import DeleteButton from "components/Button/BulkActionButtons/DeleteButton";
import SmartSearchInput from "components/inputs/SmartSearchInput";
import {algoliaReferenceFilesPath} from "../../utility/algoliaColPath";
import {bulkStatusSubmitHandler, StatusSubmitHandler, statusSubmitHandler} from "../../utility/statusSubmitHandler";

interface FormsListProps extends BaseProps {
  referenceFiles: ReferenceFile[];
  orgReferenceFilesAccess: AccessType | null;
  isSearched: boolean;
  sortOrder: DirectionalOrder;
  setSortOrder: Dispatch<DirectionalOrder>;
  setAlgoliaResults: Dispatch<ReferenceFile[] | null>;
  onEditClick: (referenceFile: ReferenceFile) => void;
}

export function ReferencesList(props: FormsListProps) {
  const {
    referenceFiles,
    orgReferenceFilesAccess,
    sortOrder,
    setSortOrder,
    toastProps,
    isSearched,
    setAlgoliaResults,
    onEditClick
  } = props;
  const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps!;

  const {orgId} = useParams();

  const [deleteTargetId, setDeleteTargetId] = useState<string | null>(null);
  const [isDeleteDialogOpen, {open: openDeleteDialog, close: closeDeleteDialog}] = useComponentToggler(false);
  const [selectedReferenceFilesIds, setSelectedReferenceFilesIds] = useState<Set<string>>(new Set());
  const [isBulkAction, setIsBulkAction] = useState(false);

  function onDeleteClick(formId: string) {
    setDeleteTargetId(formId);
    openDeleteDialog();
  }

  function onDeleteConfirmClick() {
    if (isBulkAction) {
      let submittedData: StatusSubmitHandler<ReferenceFile>[] = [];
      const targetRefs = Array.from(selectedReferenceFilesIds).map((id) => doc(referenceFilespath(orgId!), id));
      targetRefs.forEach((ref) => submitForm(ref, ActionType.Delete,
        (status, data, isLastUpdate) => {
          isLastUpdate && submittedData.push({status, data, isLastUpdate});
        })
      );

      bulkStatusSubmitHandler<ReferenceFile>({
        data: submittedData,
        successCallback: () => successCallback(targetRefs.length),
        errorCallback
      })
      return;
    }

    if (!deleteTargetId) return;
    const docRef = doc(referenceFilespath(orgId!), deleteTargetId);
    submitForm(docRef, ActionType.Delete,
      (status, data, isLastUpdate) => statusSubmitHandler<ReferenceFile>({
        status,
        data,
        isLastUpdate,
        successCallback: () => successCallback(1),
        errorCallback
      }));
  }

  function successCallback(length: number) {
    setToastMessage(enReferencesLabel.bulkDeleteSuccess(length));
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    setSelectedReferenceFilesIds(new Set());
    setIsBulkAction(false);
    onDeleteDialogClose();
  }

  function errorCallback(message: any) {
    setToastMessage(message || enCommonLabel.errorProcess(ProcessType.Delete));
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
    setSelectedReferenceFilesIds(new Set());
    onDeleteDialogClose();
  }

  function onDeleteDialogClose() {
    setDeleteTargetId(null);
    closeDeleteDialog();
  }

  function onSelectAllClick(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    if (checked) {
      setSelectedReferenceFilesIds(new Set(referenceFiles.map((referenceFile) => referenceFile["@id"]!)));
    } else {
      setSelectedReferenceFilesIds(new Set());
    }
  }

  function onBulkDeleteClick() {
    setIsBulkAction(true);
    openDeleteDialog();
  }

  function onItemSelectToggle(id: string, checked: boolean) {
    if (checked) {
      setSelectedReferenceFilesIds(new Set(selectedReferenceFilesIds.add(id)));
    } else {
      const newSet = new Set(selectedReferenceFilesIds);
      newSet.delete(id);
      setSelectedReferenceFilesIds(newSet);
    }
  }

  function getContent() {
    if (!referenceFiles.length && isSearched) return (
      <EmptySearchResults entity={Entity.ReferenceFiles} sx={{height: "80%"}}/>
    )

    if (!referenceFiles.length && !isSearched) return (
      <EmptyList
        entity={Entity.ReferenceFiles}
        logoProperties={{
          fill: theme.palette.neutral.medium,
          style: {
            marginLeft: 20,
            marginBottom: 10
          }
        }}
        sx={{
          height: "100%"
        }}
      />
    );

    return (
      referenceFiles!.map((referenceFile) => (
        <ReferenceFileItem
          key={`referenceFile_${referenceFile["@id"]}`}
          referenceFile={referenceFile}
          orgReferenceFilesAccess={orgReferenceFilesAccess}
          onDeleteClick={onDeleteClick}
          onItemSelectToggle={onItemSelectToggle}
          isSelected={selectedReferenceFilesIds.has(referenceFile["@id"]!)}
          toastProps={toastProps!}
          onEditClick={onEditClick}
          uid={props.uid}
        />
      ))
    )
  }

  return (
    <Stack gap={1} height="100%">
      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        title={enReferencesLabel.deleteRefConfirmationTitle}
        text={enReferencesLabel.deleteRefConfirmationText}
        handleClose={onDeleteDialogClose}
        handleConfirm={onDeleteConfirmClick}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        gap={1}
        mt={2}
        display={
          isSearched ? "flex" : !!referenceFiles.length ? "flex" : "none"
        }
      >
        <SmartSearchInput<ReferenceFile>
          resultCallback={setAlgoliaResults}
          colPath={algoliaReferenceFilesPath(orgId!)}
        />
        <SortMenu currentOrder={sortOrder} setter={setSortOrder}/>
      </Stack>
      {
        !!referenceFiles.length && (
          <Stack direction="row" gap={2}>
            <FormControlLabel
              sx={{alignSelf: "flex-start", ml: 0.5}}
              control={
                <Checkbox
                  id="bulk-select-checkbox"
                  checked={selectedReferenceFilesIds.size === referenceFiles.length}
                  onChange={onSelectAllClick}
                />
              }
              label={<strong>{!selectedReferenceFilesIds.size ? enReferencesLabel.referenceFiles
                : enTemplateLabel.xSelected(selectedReferenceFilesIds.size)}</strong>
              }
            />
            {selectedReferenceFilesIds.size > 0 && (
              <DeleteButton
                disabled={Boolean(!orgReferenceFilesAccess?.[PermissionOperationKey.Delete])}
                onButtonClick={onBulkDeleteClick}
              />
            )}
          </Stack>
        )
      }
      <Stack gap={1} height="100%">
        {getContent()}
      </Stack>
    </Stack>
  )
}
