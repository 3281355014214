import BaseTimerCard from "components/Timers/TimerCard/BaseTimerCard";
import {doc, Timestamp} from "firebase/firestore";
import {myTimersPath} from "screens/utility/FirebasePath";
import SubmitForm from "screens/utility/submitForm";
import {ActionType} from "enums/actionType";
import {Timer} from "types/Timer";
import {TimerState} from "types/Task";
import SystemIndex from "assets/icons/system/system.index";
import theme from "theme/theme";
import {emptyFunction} from "constants/index";

interface StartedTimerCardProps {
  uid: string;
  timer: Timer
  isListLoading: boolean
  organizationTimer?: boolean
  collapsible: boolean
}

function StartedTimerCard(props: StartedTimerCardProps) {
  const {timer, organizationTimer, isListLoading, collapsible} = props;

  const PauseIcon = <SystemIndex.SystemIcons.Pause
    width={18}
    height={18}
    stroke={theme.palette.background.paper}
    fill={theme.palette.background.paper}
  />

  function onPauseClick() {
    const docRef = doc(myTimersPath(props.uid), timer.id)
    SubmitForm<Partial<Timer>>(docRef, ActionType.Update, emptyFunction,{
      timerState: TimerState.Paused,
      pausedDateTime: Timestamp.now(),
      timerLocation: timer.timerLocation
    })
  }

  return <BaseTimerCard
    uid={props.uid}
    timer={timer}
    onActionClick={onPauseClick}
    ActionIcon={PauseIcon}
    actionButtonColor="warning"
    organizationTimer={organizationTimer}
    isListLoading={isListLoading}
    collapsible={collapsible}
  />
}

export default StartedTimerCard
