import { DocumentReference, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Milestone } from "../types/Milestone";

type UseMilestoneHook = [
    Milestone | null,
    (newDocRef: DocumentReference | null) => void,
    (newAsset: Milestone | null) => void
];

function useMilestone(assetDocReference: DocumentReference | null): UseMilestoneHook {
    const [milestone, setMilestone] = useState<Milestone | null>(null);
    const [providedDocRef, setProvidedDocRef] = useState<DocumentReference | null>(assetDocReference);

    function setNewDocRef(newDocRef: DocumentReference | null) {
        setProvidedDocRef(newDocRef);
    }

    function setNewMilestone(newMilestone: Milestone | null) {
        setMilestone(newMilestone);
    }

    useEffect(() => {
        if (!providedDocRef) return;
        return onSnapshot(
            providedDocRef,
            (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.data() as any;
                    setMilestone(data);
                }
            }
        );
    }, [providedDocRef]);

    return [milestone, setNewDocRef, setNewMilestone];
}

export default useMilestone;
