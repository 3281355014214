import {Box, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Content, InProgress, PilledNavigationBar} from "components";
import ScreenHead from "./Head";
import {Asset, Organization, Project} from "types/index";
import {HeadProps} from "./Objects/HeadProps";
import AssetCreationDrawer from "components/Drawers/AssetCreationDrawer";
import {
  assetsPath,
  availableAssetsPath, organizationAssetsPath,
  organizationPath,
  projectPath, sortObjectsBy
} from "../utility";
import {BaseProps} from "../BaseProps";
import {useAccess, useCollection, useDocument} from "hooks/index";
import {PillItemProps} from "components/PilledNavBar/PillItem";
import Icons from "assets/icons";
import {SystemIcons} from "assets/icons/system/system.index";
import {emptyFunction, enAssetLabel} from "constants/index";
import AssetViews from "./Content/AssetViews";
import theme from "theme/theme";
import {Entity} from "enums/entity";
import {PermissionEntity} from "types/Permission";
import {orderBy, where} from "firebase/firestore";

function ManageProjectAssets(props: BaseProps) {
  const {toastProps, selectedOrg, uid, setSelectedOrgId = emptyFunction} = props;
  const {projId, orgId} = useParams();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const [project] = useDocument<Project>(projectPath(orgId!, projId!));
  const [organization] = useDocument<Organization>(organizationPath(orgId!));

  // const [allProjectAssets] = useCollection<Asset>(null, organizationAssetsPath(orgId!), [where("projectIds", "array-contains", projId!)]);
  // const [allAvailableAssets] = useCollection<Asset>(null, organizationAssetsPath(orgId!), [where("projectIds", "!=", null), where("projectIds", "array-contains-any", projId!)]);

  // const [allProjectAssets] = useCollection<Asset>(null, organizationAssetsPath(orgId!), [where("projectIds", "array-contains", projId!), orderBy("projectIds")]);
  // const [allAvailableAssets] = useCollection<Asset>(null, organizationAssetsPath(orgId!));

  const [assets] = useCollection<Asset>(null, organizationAssetsPath(orgId!));
  const [availableAssets, setAvailableAssets] = useState<Asset[]>([])
  const [projectAssets, setProjectAssets] = useState<Asset[]>([])

  useEffect(() => {
    if(!assets) return

    const newProjectAssets : Asset[] = []
    const newAvailableAssets : Asset[] = []
    assets.forEach(asset => {
      if(asset.projectIds?.includes(projId!)) {
        newProjectAssets.push(asset)
      } else {
        newAvailableAssets.push(asset)
      }
    })

    setAvailableAssets(newAvailableAssets);
    setProjectAssets(newProjectAssets);
  }, [assets]);

  const [access] = useAccess({
    uid,
    entity: Entity.Project,
    documentDocId: PermissionEntity.Asset,
  });

  const openDrawer = () => setIsDrawerOpen(true);
  const [headProps] = useState<HeadProps>({
    drawerOpener: openDrawer,
    access: access!,
  });

  const nav = useNavigate();
  const pills: PillItemProps[] = [
    {
      id: "org-name",
      label: `${project?.orgName ?? "Org"} / ${project?.name ?? "Proj"}`,
      icon: <Icons.SidebarIcons.Folder fill={theme.palette.neutral.dark} width={16} height={16}/>,
      onClickFcn: () => nav(`/${orgId}/projects/${projId}/overview`)
    },
    {
      id: "asset-name",
      label: enAssetLabel.manage,
      icon: <SystemIcons.Settings stroke={theme.palette.neutral.dark} width={16} height={16}/>,
      onClickFcn: () => {
      }
    }
  ];

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return nav("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  function closeDrawer() {
    setIsDrawerOpen(false);
  }

  if (!project || !organization) return (<Content><InProgress/></Content>);

  return (
    <Box flex={1}>
      <PilledNavigationBar pills={pills}/>
      <Content>
        <Stack spacing={-3} height="100%" direction="column">
          <AssetCreationDrawer
            drawerVisibility={isDrawerOpen}
            onDrawerClose={closeDrawer}
            organization={organization}
            orgName={project.orgName}
            projName={project.name}
            uid={uid}
            lastIdCode={projectAssets?.[projectAssets.length - 1]?.idCode || "0"}
            toastProps={toastProps!}
            collectionRef={assetsPath(orgId!, projId!)}
          />
          <ScreenHead {...headProps} access={access!}/>
          <AssetViews
            toastProps={toastProps!}
            access={access}
            uid={props.uid}
            allAvailableAssets={sortObjectsBy(availableAssets ?? [], "name")}
            allProjectAssets={sortObjectsBy(projectAssets ?? [], "name")}
          />
        </Stack>
      </Content>
    </Box>
  );
}

export default ManageProjectAssets;
