import {Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import React from "react";

interface CustomTabLabelProps {
  label: string
  count?: number
  id?: string
}

function CustomTabLabel(props: CustomTabLabelProps) {
  const {label, count, id = "tab-label"} = props

  return <Stack direction="row" gap={0.5} id={id}>
    <Typography variant="h5">{label}</Typography>
    {count ? <Typography
        id="all-organizations-counter"
        color={theme.palette.neutral.dark}
        variant="h5"
      >
        {count > 0 ? count : ""}
      </Typography>
      : <></>
    }
  </Stack>
}

export default CustomTabLabel;
