import MyTimersView from "components/Timers/TimersViews/MyTimersView";
import {Box, Divider, Stack} from "@mui/material";
import CollapsableTaskView, {CollapsableTaskViewProps} from "../../../CollapsableTaskView";
import React, {useEffect, useRef, useState} from "react";
import {TaskData} from "../../../queryAlgolia";
import {TaskItem} from "./TaskItem";
import {myTasksPath} from "../../../../utility";
import {doc, orderBy, where} from "firebase/firestore";
import {Task} from "types/Task";
import {TaskItemAction} from "../../../TaskItemAction";
import VirtualList from "components/VirtualList";
import {Entity} from "enums/entity";
import {useOnScreen} from "hooks/useOnScreen";

interface TaskAndTimersContentsProps extends CollapsableTaskViewProps {
  displayedTasks: TaskData[];
  onTaskItemDrawerOpen: (task: Task, action: TaskItemAction) => void;
  showActivityFeedDrawer?: (task: Task) => void;
}

export const DefaultTaskConstraints = [
  where("hidden", "==", false),
  where("deleted", "==", false),
  orderBy("dueDate", "asc"),
  orderBy("timeCreated", "asc"),
];

function TaskAndTimersContents(props: TaskAndTimersContentsProps) {
  const {uid, displayedTasks, isTaskCollapsed} = props;
  const {onTaskItemDrawerOpen, reFetchTotalCount, showActivityFeedDrawer} = props;
  const [brokenTasks, setBrokenTasks] = useState<string[]>([]);

  return (
    <Stack flex={1}>
      <MyTimersView
        collapsible={true}
        emptyPage={false}
        uid={uid}
      />
      <Divider flexItem/>
      <br/>
      <CollapsableTaskView {...props}/>
      {!isTaskCollapsed && (
        <VirtualList<TaskData>
          collectionPath={myTasksPath(uid)}
          fromAlgolia={props.isFiltered}
          defaultConstraints={DefaultTaskConstraints}
          entity={Entity.Task}
          isSearching={props.loading}
          displayedResults={[...displayedTasks]}
          hasOrderByTimestamp={true}
          listSx={{gap: 0}}
          renderElement={(itemData) => {
            if (!itemData.orgTaskRefPath) return null;

            // actual task id
            const itemId = itemData.taskId ?? itemData["@id"]!;
            // used for reading
            const docRef = doc(myTasksPath(uid), itemData["@id"]!);

            const taskReferencePath = itemData.orgTaskRefPath || docRef.path

            if(brokenTasks.includes(taskReferencePath))
              return null

            return (
              <Preloader
                element={<TaskItem
                  setBrokenTasks={setBrokenTasks}
                  isHomeTask={true}
                  actualTaskId={itemId}
                  myTasksId={itemData["@id"]!}
                  taskReferencePath={taskReferencePath}
                  onTaskItemDrawerOpen={onTaskItemDrawerOpen}
                  toastProps={props.toastProps!}
                  uid={uid}
                  fromSearch={props.isFiltered}
                  filterState={props.filters}
                  onRemoveFromListSuccess={reFetchTotalCount}
                  favoriteSubmitCallback={reFetchTotalCount}
                  openTaskActivityFeedDrawer={showActivityFeedDrawer}
                />}
              />
            )
          }}
        />
      )}
    </Stack>
  )
}

export function Preloader(props: { element: any, marginY?: (onScreen: boolean) => number, height?: number }) {
  const {element, marginY, height = false} = props
  const ref = useRef<HTMLDivElement>(null)
  const onScreen = useOnScreen(ref, "300px")
  const [savedHeight, setSavedHeight] = useState<number>(height || 10);

  useEffect(() => {
    if (onScreen && ref.current && !height)
      if (ref.current.getBoundingClientRect().height > savedHeight)
        setSavedHeight(ref.current.getBoundingClientRect().height)
  }, [onScreen, ref?.current?.getBoundingClientRect().height]);

  return <Box ref={ref} sx={{display: onScreen ? "block" : "block"}} minHeight={savedHeight} marginY={marginY ? marginY(onScreen) : 0}>
    {onScreen && element}
  </Box>
}

export default TaskAndTimersContents;