import {Invite} from "types/Invite";
import React, {useState} from "react";
import {Stack, Typography, Box} from "@mui/material";
import theme from "theme/theme";
import {InviteTag} from "../Tags";
import {enActivityFeedLabel, enCommonLabel} from "constants/index";
import {formatDateTimeFirestoreDate, submitForm} from "screens/utility";
import {Avatar} from "components/index";
import {BaseProps} from "screens/BaseProps";
import {userInvitePath} from "screens/utility/FirebasePath";
import useDocument from "hooks/useDocument";
import {ActionType, InvitationStatus, ProcessType, Severity} from "enums/index";
import StatusButtons from "screens/ActivityFeed/Objects/StatusButtons";
import StackItem from "components/StackItem";
import PinIconButton from "components/Button/PinIconButton";
import {statusSubmitHandler} from "../../screens/utility/statusSubmitHandler";

interface ActivityFeedInviteCardProps extends BaseProps {
  invitation: Invite;
  noAvatar?: boolean;
  noPin?: boolean;
  text?: React.ReactNode;
}

function ActivityFeedInviteCard(props: ActivityFeedInviteCardProps) {
  const {invitation, toastProps, noAvatar, noPin, text, uid} = props;
  const {setToastMessage, setIsToastOpen, setToastSeverity} = toastProps!;

  const {"@id": id = "", inviteDate, invitationStatus, invitedByUserData, organizationName} = invitation;
  const invitationRef = userInvitePath(id!, uid);
  const [invitationDoc] = useDocument<Invite>(invitationRef);
  const invitationDate = formatDateTimeFirestoreDate(inviteDate);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [processingStatus, setProcessingStatus] = useState<InvitationStatus | null>(null);

  async function processInvite(inviteStatus: InvitationStatus) {
    setIsLoading(true);
    setProcessingStatus(inviteStatus);

    await submitForm(invitationRef, ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler<Invite>({
        status,
        data,
        isLastUpdate,
        successCallback: () => successCallback(inviteStatus),
        errorCallback
      }),
      {invitationStatus: inviteStatus}
    )
    ;
  }

  function successCallback(invitationStatus: InvitationStatus) {
    const message = invitationStatus === InvitationStatus.Accepted ? enActivityFeedLabel.invitationAccepted : enActivityFeedLabel.invitationDeclined;
    showMessage(message, Severity.Success);
    setIsLoading(false);
  }

  function errorCallback(message: any) {
    showMessage(message || enCommonLabel.errorProcess(ProcessType.Update), Severity.Error);
    setIsLoading(false);
  }

  function showMessage(message: string, severity: Severity) {
    setToastMessage(message);
    setToastSeverity(severity);
    setIsToastOpen(true);
  }

  return (
    <StackItem
      key={`activity-invitation-item-${id}`}
      className="activity-invitation-item"
      direction="row"
      position="relative"
      spacing={1}
      sx={(theme) => ({
        cursor: isLoading ? "default" : "pointer",
        ":hover": {
          border: isLoading ? null : `1px solid ${theme.palette.primary.main}`,
        },
      })}
    >
      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        padding={1}
        sx={{
          flexWrap: {
            xs: 'wrap',
            sm: 'wrap',
            md: 'wrap',
            lg: 'nowrap'
          },
        }}
      >
        <Stack direction="column" justifyContent="center" gap={0}>
          <Stack
            direction="row"
            gap={1}
            sx={{
              flexWrap: {
                xs: 'wrap',
                sm: 'wrap',
                md: 'wrap',
                lg: 'nowrap'
              }
            }}
          >
            {!noAvatar && (
              <>
                <Avatar
                  isTeam={true}
                  initials={(organizationName ?? "O").charAt(0)}
                  avatarColor={theme.palette.secondary.light}
                  name={organizationName ?? "O"}
                  sx={{height: 24, width: 24, mt: 0, fontSize: "12px"}}
                />
                <InviteTag/>
              </>
            )}
            {text ?? enActivityFeedLabel.invitationToJoin(organizationName, invitedByUserData.name)}
          </Stack>
          <Typography
            color={theme.palette.neutral.dark}
            sx={{
              pt: {xs: 1, sm: 1, md: 1, lg: 0},
            }}
          >
            {`${invitationDate.date} ${invitationDate.time}`}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{
            marginTop: {
              lg: (invitationDoc?.invitationStatus ?? invitationStatus) === InvitationStatus.Sent ? 0 : '-4px'
            },
          }}
        >
          <Stack direction="row" gap={1} pr={noPin ? 0 : 5}>
            <StatusButtons
              isAccepting={isLoading && processingStatus === InvitationStatus.Accepted}
              isDeclining={isLoading && processingStatus === InvitationStatus.Declined}
              status={invitationDoc?.invitationStatus ?? invitationStatus}
              processInvite={(status) => processInvite(status)}
            />
          </Stack>
          {!noPin && (
            <Box
              sx={{
                position: 'absolute',
                right: 2,
                top: 2
              }}
            >
              <PinIconButton
                entity="Invite"
                toastProps={toastProps!}
                pinned={Boolean(invitation.pinned)}
                documentReference={invitationRef}
              />
            </Box>
          )}
        </Stack>
      </Stack>
    </StackItem>
  )
}

export default ActivityFeedInviteCard;
