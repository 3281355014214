import {useEffect, useState} from 'react';
import {ref, onValue, DataSnapshot} from 'firebase/database';
import {rtdb} from "../firebase";

type UseRTDBDocumentReturn<Type> = [
  Type | null | undefined,
  (docRef: string | null) => void,
    string | null,
];

/**
 * Listen to a document in RTDB and return its data.
 * @param docRef - The document reference path to listen to.
 * @param fieldsOfInterest - The fields of interest to listen to. If not provided, all fields will be listened to.
 * @returns [document, setProvidedDocRef, reference] - The document data, a function to set the document reference path, and the document reference path.
 */
function useRTDBDocument<Type>(docRef: string | null, fieldsOfInterest?: Array<keyof Type>): UseRTDBDocumentReturn<Type> {
  const [document, setDocument] = useState<Type | null | undefined>(null);
  const [reference, setReference] = useState<string | null>(docRef);

  function setProvidedDocRef(newDocRef: string | null) {
    setDocument(null);
    // just incase same reference is provided, force a re-render by setting reference to null first
    setReference(null);
    setReference(newDocRef);
  }

  function hasFieldOfInterestChanged(newData: Type, oldData: Type | null | undefined): boolean {
    if (!fieldsOfInterest || !oldData) return true;

    return fieldsOfInterest.some((field) => newData[field] !== oldData[field]);
  }

  useEffect(() => {
    if (!reference) return;

    const dataRef = ref(rtdb, reference);
    return onValue(dataRef, (snapshot: DataSnapshot) => {
      const newData = snapshot.val();

      if (!newData) {
        setDocument(undefined);
        return;
      }

      if (hasFieldOfInterestChanged(newData as Type, document)) {
        setDocument(newData as Type);
      }
    }, () => {
      setDocument(undefined);
    });
  }, [reference]);

  return [document, setProvidedDocRef, reference];
}

export default useRTDBDocument;
