import {PartialRequired} from "./utils";
import {Milestone} from "./Milestone";
import {DataTag} from "./DataTag";
import {milestoneTaskandAssetStatus} from "enums/milestoneTaskandAssetStatus";
import {FormBase, PartialUserData, Personnel} from "types/index";
import {taskTimelineScheduleStatus} from "enums/taskTimelineScheduleStatus";
import {Timestamp} from "firebase/firestore";
import {CounterFields} from "enums/counterFields";

export type Task = TaskForm & {
  "@form"?: FormBase & TaskForm;
}

type TaskForm = {
  "@id"?: string,
  name: string,
  taskStatus: milestoneTaskandAssetStatus,
  timeCreated: Timestamp,
  assetId: string,
  milestoneId: string,
  description?: string,
  milestone: PartialRequired<Milestone, "name">,
  lastChangedBy?: LastChangeBy,
  statusLastChangedBy?: LastChangeBy,
  assignedTo: Personnel,
  approver: Personnel,
  observer: Personnel,
  isPrivate?: boolean,
  deleted?: boolean;
  dueDate: Timestamp | null,
  dueTime?: string | undefined,
  dueTimeAllDay?: boolean,
  dataTags?: DataTag[],
  nameIdx: string,
  userId: string,
  orgId: string;
  orgName: string,
  projectId: string;
  projectName: string;
  assetName: string;
  timelineStatus: taskTimelineScheduleStatus;
  taskSchedule: {
    submittedBy?: PartialUserData;
    submittedAt?: Timestamp;
    scheduledBy?: PartialUserData;
    scheduledAt?: Timestamp;
    approvedBy?: PartialUserData;
    approvedAt?: Timestamp;
    rejectedBy?: PartialUserData;
    rejectedAt?: Timestamp;
    cancelledAt?: Timestamp;
    cancelledBy?: PartialUserData;
  },
  dataTagsIds?: string[],
  timerState: TimerState,
  timerLocation?: string | null, //fe,
  totalLoggedTimeInMs: number,
  timerCurrentDurationInMs:number,
  templateId?: string,
  templateName?: string,
  hidden?: boolean,
  currentTimerStartDateTime?: Timestamp | null,
  currentTimerTotalLoggedTimeInMs?: number,
  orgTaskRefPath?: string,
  favoriteUserIds?: string[],
  createdBy: PartialUserData & {id: string},
  assignedAt?: Timestamp,
  templateTaskId?: string | null,
  meetingLink?: string,
  building?: boolean,
  taskIndex?: number,
  timezone?: string,
  referenceFileId?: string | null,
  [CounterFields.BuildingProgressCount]? : number,
  "@allowedUsers": string[],
  taskId?: string, // only applicable to home tasks
}

export interface Avatar {
  initials: string;
  color: string;
}

export interface LastChangeBy {
  uid: string;
  email: string;
  firstName: string;
  lastName: string;
}

export enum TimerState {
  Started="Started",
  Running="Running",
  Paused="Paused",
  Resumed="Resumed",
  Stopped="Stopped",
}

export type TaskNotification = {
  hasNewChat?: boolean;
  hasNewActivity?: boolean;
  favorite?: boolean // optional as undefined == false,
  chatEmpty?: boolean,
}

export type TaskCounters = {
  [CounterFields.RequirementsCount]?: number,
  [CounterFields.FormRequirementsCount]?: number,
  [CounterFields.FileRequirementsCount]?: number,
  [CounterFields.HiddenReqCount]?: number,
  [CounterFields.ChatMessagesCount]?: number,
  [CounterFields.RequirementsApprovedCount]? : number,
  [CounterFields.RequirementsRejectedCount]? : number,
  [CounterFields.FilesCount]? : number,
  [CounterFields.FilesApprovedCount]? : number,
  [CounterFields.FilesRejectedCount]? : number,
  [CounterFields.FileRequirementsApprovedCount]? : number,
  [CounterFields.FormRequirementsApprovedCount]? : number,
}

export type UserTaskData = TaskNotification & TaskCounters;
