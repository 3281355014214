import {Stack} from "@mui/material";
import theme from "theme/theme";
import {UserProjectData} from "types/Project";
import {enAssetLabel} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {StatisticsItem} from "components";

interface StatisticsProps {
  userProject: UserProjectData;
}

function Statistics(props: StatisticsProps) {
  const {userProject} = props;
  const {
    assetsCount = 0,
    tasksApprovedCount = 0,
    tasksCount = 0,
    requirementsCount = 0,
    requirementsApprovedCount = 0,
    filesCount = 0,
  } = userProject;

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" gap={3} flexWrap="wrap">
        <StatisticsItem
          Icon={<SystemIcons.Assets stroke={theme.palette.text.secondary}/>}
          count={assetsCount}
          label={enAssetLabel.assets}
          id="assetsCount"
        />
        <StatisticsItem
          Icon={<SystemIcons.Tasks stroke={theme.palette.text.secondary}/>}
          count={tasksApprovedCount + "/" + tasksCount}
          label={enAssetLabel.tasks}
          title={enAssetLabel.approvedOverTotalTasks}
        />
        <StatisticsItem
          Icon={<SystemIcons.Requirement stroke={theme.palette.text.secondary}/>}
          count={requirementsApprovedCount + "/" + requirementsCount}
          label={enAssetLabel.requirements}
          title={enAssetLabel.approvedOverTotalRequirements}
        />
        <StatisticsItem
          Icon={<SystemIcons.FilesOutlined stroke={theme.palette.text.secondary}/>}
          count={filesCount}
          label={enAssetLabel.files}
        />
      </Stack>
    </Stack>
  );
}

export default Statistics;
