import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {projectPath} from "../screens/utility";
import {getDoc} from "firebase/firestore";
import {Project} from "types/Project";

const MAX_TEMPLATE_IDS = 10;

export interface TemplateId  {
  id: string,
  dateCreated: number
}

interface TemplateCheckerProps{
  children: JSX.Element
}

function TemplateChecker(props: TemplateCheckerProps){
  const {children} = props;
  const {orgId, projId} = useParams();

  useEffect(() => {
    async function saveTemplateId(){
      if(!orgId || !projId)
        return

      const projectRef = projectPath(orgId, projId);
      const projectDoc = await getDoc(projectRef);
      const projectData = projectDoc.data() as Project

      const templateId = projectData.projectAssetTemplateId
      const storageTemplateIds= localStorage.getItem("templateIds");
      const objectifiedTemplateIds : Record<string, TemplateId> = storageTemplateIds ? JSON.parse(storageTemplateIds) : {}

      if(templateId){
        localStorage.setItem("templateId", templateId)
        objectifiedTemplateIds[projId] = ({id: templateId, dateCreated: Date.now()})
        saveTempalateIds(objectifiedTemplateIds)
      }
      else{
        localStorage.setItem("templateId", "")
        delete objectifiedTemplateIds[projId]
        saveTempalateIds(objectifiedTemplateIds)

        //TODO: Delete old template ids when reached max template ids
      }
    }

    saveTemplateId()

  }, [orgId, projId]);

  return children;
}

function saveTempalateIds(objectifiedTemplateIds:  Record<string, TemplateId>){
  const stringified = JSON.stringify(objectifiedTemplateIds);
  localStorage.setItem("templateIds", stringified)
}
export default TemplateChecker;