import {Timer} from "types/Timer";
import BaseTimersList, {TimerLoadFunctions,} from "components/Timers/TimersList/BaseTimersList";
import StartedTimerCard from "components/Timers/TimerCard/StartedTimerCard";
import {Dispatch, useEffect, useState} from "react";
import {CollapseStates} from "components/Timers/TimersViews/MyTimersView";
import {CollectionReference, limit, QueryConstraint, where} from "firebase/firestore";
import {useCollection} from "hooks/index";
import {TimerState} from "types/Task";
import {baseListCount, listIncrementCount} from "components/Timers/constants";

interface StartedTimersListProps extends TimerLoadFunctions {
  uid: string;
  organizationTimer?: boolean
  collapsible?: boolean
  collapseStates?: CollapseStates
  setCollapseStates?: Dispatch<CollapseStates>
  colRef: CollectionReference
  totalStartedCount: number
}

function StartedTimersList(props: StartedTimersListProps) {
  const {
    organizationTimer,
    collapsible = false,
    collapseStates,
    setCollapseStates,
    colRef,
    totalStartedCount
  } = props;
  const [limitCount, setLimitCount] = useState(baseListCount);

  const [isListLoading, setIsListLoading] = useState(false);
  const [timers, setTimersConstraints, setTimersColRef] = useCollection<Timer>(null, null, undefined, false, true)

  const timersComponentsMap = (timers ? timers.slice(0, limitCount - 1) : []).map((timer) => <StartedTimerCard
    collapsible={collapsible}
    timer={timer}
    organizationTimer={organizationTimer}
    key={timer["@id"] + "started"}
    isListLoading={isListLoading}
    uid={props.uid}
  />)

  useEffect(() => {
    const queryConstraints: QueryConstraint[] = organizationTimer || collapsible ? [limit(limitCount)] : []
    queryConstraints.push(where("timerState", "in", [TimerState.Started, TimerState.Running, TimerState.Resumed]))
    setTimersConstraints(queryConstraints);
  }, [])

  useEffect(() => {
    setTimersColRef(colRef)
  }, [colRef])

  function onLoadMoreClick() {
    setLimitCount(limitCount + listIncrementCount);
    const queryConstraints: QueryConstraint[] = [limit(limitCount + listIncrementCount)]
    queryConstraints.push(where("timerState", "in", [TimerState.Started, TimerState.Running, TimerState.Resumed]))
    setTimersConstraints(queryConstraints)
  }

  function onSeeLessClick(){
    setLimitCount(baseListCount);
    const queryConstraints: QueryConstraint[] = [limit(baseListCount)]
    queryConstraints.push(where("timerState", "in", [TimerState.Started, TimerState.Running, TimerState.Resumed]))
    setTimersConstraints(queryConstraints)
  }

  return <BaseTimersList
    totalCount={totalStartedCount}
    collapseStates={collapseStates}
    setCollapseStates={setCollapseStates}
    collapsible={collapsible}
    listCount={limitCount}
    onLoadMoreClick={onLoadMoreClick}
    onSeeLessClick={onSeeLessClick}
    setIsListLoading={setIsListLoading}
    timers={timers ?? []}
    timersComponentsMap={timersComponentsMap}
    timersListType="Started"
    organizationTimer={organizationTimer}
    uid={props.uid}
  />
}


export default StartedTimersList
