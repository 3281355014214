import {ToastProps} from "../../BaseProps";
import {CreatedItemType} from "types/ActivityFeedTypes";
import BaseItem from "./BaseItem";
import SystemIndex from "assets/icons/system/system.index";
import IconBaseProps from "./IconBaseProps";
import generateAddedToText from "../utils/generateAddedToText";
import {useDocument} from "hooks/index";
import {doc} from "firebase/firestore";
import {userActivityFeedPath} from "../../utility";
import {SkeletonItem} from "components/index";

interface CreatedItemProps extends ToastProps {
  itemId: string;
  uid: string;
  index: number;
  updatePinnedValue: (index: number, pinned: boolean) => void;
}

function CreatedItem(props: CreatedItemProps) {
  const {toastProps, itemId, uid, updatePinnedValue, index} = props;

  const [activityFeed] = useDocument<CreatedItemType>(doc(userActivityFeedPath(uid), itemId));

  if (!activityFeed) {
    return <SkeletonItem height={"5rem"} sx={{marginTop: "5px", width: "80%"}}/>
  }

  return <BaseItem
    index={index}
    uid={uid}
    item={activityFeed}
    icon={<SystemIndex.SystemIcons.Plus {...IconBaseProps}/>}
    text={generateAddedToText(activityFeed)}
    toastProps={toastProps}
    updatePinnedValue={updatePinnedValue}
  />
}

export default CreatedItem
