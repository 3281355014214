import {Hidden, Stack} from "@mui/material";
import AssignableAvatar from "components/AssignableAvatar";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {LongText} from "components/index";
import {enCommonLabel} from "constants/index";
import React from "react";
import {UserPersonnel} from "types/UserPersonnel";

interface PersonnelAssignedProps {
  user: UserPersonnel,
  taskId: string
  roleText?: string;
}

function PersonnelAssigned({user, taskId, roleText}: PersonnelAssignedProps) {

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={2}
      sx={{width: "100%"}}
    >
      <AssignableAvatar
        sx={{marginTop: 0, width: 30, height: 30}}
        key={`personnel_${user.uid}_${taskId}-assignedTo`}
        {...user}
        id={user.id ? user.id : "fillerId"}
        avatarColor={user.id ? user.avatarColor : theme.palette.neutral.light}
        defaultIcon={<SystemIcons.Minus color={theme.palette.neutral.dark} height={12} width={12}/>}
        roleText={roleText}
        nameComponent={(
          <Stack direction="row" justifyContent="flex-start" sx={{width: "100%"}}>
            <Hidden smDown>
              <Stack>
                <LongText fontSize={12}>{user.name || enCommonLabel.unAssigned}</LongText>
              </Stack>
            </Hidden>
          </Stack>
        )}
      />
    </Stack>
  )
}

export default PersonnelAssigned;