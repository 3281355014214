import {Box, Link, Stack, Tooltip, Typography} from "@mui/material";
import {LongText} from "components/index";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import React from "react";
import { getTimeDifferenceString, userChatsPath} from "screens/utility";
import {EntityChat} from "types/EntityChat";
import {doc} from "firebase/firestore";
import {enChatLabel} from "constants/index";
import {ToastProps} from "screens/BaseProps";
import {parseMentions} from "../../utils/parseMentions";
import generateEntityBreadcrumbs from "screens/utility/generateEntityBreadcrumbs";
import EntityIcon from "../../utils/EntityIcon";
import BreadcrumbsDisplay from "components/BreadcrumbsDisplay";
import PinIconButton from "components/Button/PinIconButton";
import {ENTITY_LABEL} from "language/en/common";
import {useRTDBDocField} from "hooks/index";
import {ChatFilters} from "./index";

interface ChatCardProps extends ToastProps {
  uid: string;
  entityChat: EntityChat;
  onChatCardClick: (e: React.MouseEvent, chatItem: EntityChat) => void;
  chatFilter: ChatFilters
}

function ChatCard(props: ChatCardProps) {
  const {entityChat, uid, toastProps, onChatCardClick, chatFilter} = props;

  const {
    id,
    pinned,
    orgName,
    meetingLink,
    name = "",
    sender,
    text = "",
    timestamp,
    hasAttachments = false,
    chatEntity,
  } = entityChat;

  const dateTimeDiff = timestamp ? getTimeDifferenceString(timestamp) : "";
  const entityBreadcrumbs = generateEntityBreadcrumbs({...entityChat, entity: chatEntity});
  const read = useRTDBDocField<boolean>(`${userChatsPath(uid!).path}/${id}`, "read") ?? false;

  let chatText = !text && hasAttachments ? enChatLabel.attachedAFile : text;
  chatText = parseMentions({chatMessage: chatText});

  function onCardClick(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    onChatCardClick(e, entityChat);
  }

  // Prevent drawer from opening behavior
  function preventDrawerOpening<T extends HTMLElement>(e: React.MouseEvent<T>) {
    e.stopPropagation();
  }

  switch (chatFilter) {
    case ChatFilters.read:
      if (!read)
        return null
      break;
    case ChatFilters.unread:
      if (read)
        return null
      break;
  }

  return (
    <Stack
      id={`chat-card-item-${id}`}
      className="chat-card-item"
      sx={{
        flexDirection: {sm: "column", md: "row"},
        border: `1px solid ${!!meetingLink ? theme.palette.primary.main : theme.palette.divider}`,
        padding: 1,
        paddingX: 2,
        borderRadius: 1,
        position: "relative",
        cursor: "pointer",
        backgroundColor: read ? "inherit" : theme.palette.grey["100"],
      }}
      onClick={onCardClick}
    >
      <Tooltip title={ENTITY_LABEL[chatEntity] ?? chatEntity} arrow placement="top">
        <Box sx={{position: "absolute", top: theme.spacing(1.25), left: theme.spacing(1)}}>
          <EntityIcon
            entity={chatEntity}
            svgProps={{
              height: 18,
              width: 18,
              stroke: theme.palette.text.secondary
            }}
          />
        </Box>
      </Tooltip>
      <Stack
        flex={1}
        mx={2}
        sx={{
          gap: {
            xs: 0,
            md: 0.5,
          }
        }}
      >
        <Stack direction="row" alignItems="center" gap={0.5} flexWrap="wrap">
          <Stack direction="column" flex={1}>
            <Stack direction="row" gap={1}>
              <LongText variant={read ? "body1" : "h4"} overflowWrap={"anywhere"}>
                {name}
              </LongText>
              {
                !!meetingLink && (
                  <Link
                    onClick={preventDrawerOpening}
                    alignSelf="center"
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      padding: 0.2,
                      paddingX: 1,
                      borderRadius: 2,
                    }}
                    href={meetingLink}
                    target="_blank"
                  >
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <Typography variant="body" color="white" fontWeight={700}>{enChatLabel.join}</Typography>
                      <SystemIcons.Video fill="white" stroke="white" width={16} height={16}/>
                    </Stack>
                  </Link>
                )
              }
            </Stack>
            <BreadcrumbsDisplay entityBreadcrumbs={entityBreadcrumbs} orgName={orgName}/>
            <Stack direction="row" spacing={1}>
              <LongText maxWidth="100%" overflowWrap={"anywhere"}>
                <b>{sender?.name}&nbsp;</b>
                {chatText}
              </LongText>
            </Stack>
          </Stack>
        </Stack>
        <Typography
          color={theme.palette.text.secondary}
          sx={{
            display: {
              xs: "block",
              md: "none",
            }
          }}
          noWrap
        >
          <i>{dateTimeDiff}</i>
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          position: "absolute",
          top: theme.spacing(0),
          right: theme.spacing(0),
        }}
      >
        <Typography color={theme.palette.text.secondary} sx={{display: {xs: "none", md: "block"}}} noWrap>
          <i>{dateTimeDiff}</i>
        </Typography>
        <Box onClick={preventDrawerOpening}>
          <PinIconButton
            entity="Chat"
            pinned={pinned}
            documentReference={doc(userChatsPath(uid), entityChat["@id"])}
            toastProps={toastProps!}
          />
        </Box>
      </Stack>
    </Stack>
  )
}

export default ChatCard;
