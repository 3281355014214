import {Menu} from "@mui/material";
import React, {ReactNode} from "react";
import theme from "../theme/theme";

export interface MenuCardProps {
  anchorEl: HTMLElement | Element | null;
  open: boolean;
  closeMenu: (e: React.MouseEvent<HTMLElement>) => any;
  origin?: "left" | "right"
  anchorPosition?: { left: number, top: number }
  children?: ReactNode;
}

function MenuCard({children, open, closeMenu, anchorEl, origin = "right", anchorPosition}: MenuCardProps) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={closeMenu}
      onClick={closeMenu}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          border: `1px solid ${theme.palette.neutral.light}`,
        },
      }}
      transformOrigin={{horizontal: origin, vertical: "top"}}
      anchorOrigin={{horizontal: origin, vertical: "bottom"}}
      anchorPosition={anchorPosition}
      anchorReference={anchorPosition ? "anchorPosition" : "anchorEl"}
      sx={{padding: 0}}
      id="menu-card"
    >
      {children}
    </Menu>
  );
}

export default MenuCard;
