import {Task} from "types/Task";
import {milestoneTaskandAssetStatus} from "enums/milestoneTaskandAssetStatus";
import {formatDateTimeFirestoreDate} from "../../../screens/utility";
import {enCommonLabel, enCommonStatus} from "constants/index";

const emptyDate = {
  minimalDate: '',
  simpleTime: '',
  date: '',
}

export function getScheduleDateAndTime(task: Task) {
  const {
    taskStatus,
    taskSchedule = {},
    dueTimeAllDay = true,
    dueDate = null,
    dueTime = undefined,
    timeCreated,
  } = task;

  let {date, minimalDate: dueShortDate} = !!dueDate ? formatDateTimeFirestoreDate(dueDate!): emptyDate;
  const displayDueDate = dueTimeAllDay ? dueShortDate: [dueShortDate, dueTime].join(' ');
  const dueText = !!dueDate ? enCommonLabel.dueOn: '';

  switch (taskStatus) {
    case milestoneTaskandAssetStatus.HasRejection:
      return {
        statusText: '',
        actionText: '',
        dueText: dueText,
        dueDate: !!dueDate ? displayDueDate: enCommonLabel.unscheduled,
        scheduleDate: date || enCommonLabel.unscheduled,
        dueTime: '',
      }
    case milestoneTaskandAssetStatus.InProgress:
      const {
        date: inProgressDate,
        minimalDate: inProgressMinimalDate,
        simpleTime: inProgressSimpleTime
      } = !!timeCreated ? formatDateTimeFirestoreDate(timeCreated) : emptyDate;

      return {
        statusText: dueShortDate ? enCommonStatus.inProgress: '',
        actionText: '',
        dueText: dueText,
        statusDate: dueShortDate ? [inProgressMinimalDate, inProgressSimpleTime].join(' '): '',
        dueDate: !!dueDate ? displayDueDate: enCommonLabel.unscheduled,
        scheduleDate: inProgressDate || enCommonLabel.unscheduled,
        dueTime: inProgressSimpleTime,
      }
    case milestoneTaskandAssetStatus.Pending:
      const {
        date: pendingSheduledDate,
        minimalDate: pendingDate,
        simpleTime: pendingTime
      } = !!timeCreated ? formatDateTimeFirestoreDate(timeCreated) : emptyDate;

      return {
        statusText: dueShortDate ? enCommonStatus.pending: '',
        actionText: '',
        statusDate: dueShortDate ? [pendingDate, pendingTime].join(' '): '',
        dueText: dueShortDate ? dueText: '',
        dueDate: !!dueDate ? displayDueDate: enCommonLabel.unscheduled,
        dueTime: !!dueDate ? pendingTime: '',
        scheduleDate: !!dueDate ? pendingSheduledDate : enCommonLabel.unscheduled,
      }
    case milestoneTaskandAssetStatus.Rejected:
      const {
        date: rejectedSheduledDate,
        minimalDate: rejectedDate,
        simpleTime: rejectedTime
      } = !!taskSchedule.rejectedAt ? formatDateTimeFirestoreDate(taskSchedule.rejectedAt) : emptyDate;

      return {
        statusText: enCommonLabel.rejectedOn,
        actionText: enCommonLabel.rejectedBy(""),
        actionBy: task.taskSchedule.rejectedBy,
        statusDate: rejectedDate ? [rejectedDate, rejectedTime].join(' '): enCommonLabel.noRejectedDate,
        dueText: dueText,
        dueDate: !!dueDate ? displayDueDate: "",
        dueTime: rejectedTime,
        scheduleDate: rejectedSheduledDate,
      }
    case milestoneTaskandAssetStatus.Approved:
      const {
        date: approvedSheduledDate,
        minimalDate: approvedDate,
        simpleTime: approvedTime
      } = !!taskSchedule.approvedAt ? formatDateTimeFirestoreDate(taskSchedule.approvedAt) : emptyDate;

      return {
        statusText: enCommonLabel.approvedOn,
        actionText: enCommonLabel.approvedBy(""),
        actionBy: taskSchedule.approvedBy,
        statusDate: approvedDate ? [approvedDate, approvedTime].join(' '): enCommonLabel.noApprovedDate,
        dueText: dueText,
        dueDate: !!dueDate ? displayDueDate: "",
        dueTime: approvedTime,
        scheduleDate: approvedSheduledDate,
      }
    case milestoneTaskandAssetStatus.Submitted:
      const {
        date: submittedSheduledDate,
        minimalDate: submittedDate,
        simpleTime: submittedTime
      } = !!taskSchedule.submittedAt ? formatDateTimeFirestoreDate(taskSchedule.submittedAt) : emptyDate;

      return {
        statusText: enCommonLabel.submittedOn,
        actionText: enCommonLabel.submittedBy(""),
        actionBy: taskSchedule.submittedBy,
        statusDate: submittedDate ? [submittedDate, submittedTime].join(' '): enCommonLabel.noSubmissionDate,
        dueText: dueText,
        dueDate: !!dueDate ? displayDueDate: "",
        dueTime: submittedTime,
        scheduleDate: submittedSheduledDate,
      }
    case milestoneTaskandAssetStatus.Cancelled:
      const {
        date: cancelledSheduledDate,
        minimalDate: cancelledDate,
        simpleTime: cancelledTime
      } = !!taskSchedule.cancelledAt ? formatDateTimeFirestoreDate(taskSchedule.cancelledAt) : emptyDate;

      return {
        statusText: enCommonLabel.cancelledOn,
        actionText: enCommonLabel.cancelledBy(""),
        actionBy: taskSchedule.cancelledBy,
        statusDate: cancelledDate ? [cancelledDate, cancelledTime].join(' '): enCommonLabel.noCancellationDate,
        dueText: dueText,
        dueDate: !!dueDate ? displayDueDate: "",
        dueTime: cancelledTime,
        scheduleDate: cancelledSheduledDate,
      }
    default:
      return {
        statusText: '',
        actionText: '',
        dueText: dueText,
        dueDate: !!dueDate ? displayDueDate: enCommonLabel.unscheduled,
        scheduleDate: date || enCommonLabel.unscheduled,
        dueTime: '',
      }
  }
}