import {Form} from "types/Form";
import {Stack} from "@mui/material";
import {FormItem} from "./FormItem";
import {useComponentToggler} from "hooks/index";
import DeleteDialog from "components/Dialogs/DeleteDialog";
import {enCommonLabel, enFormsLabel} from "constants/index";
import {doc, DocumentReference} from "firebase/firestore";
import {useParams} from "react-router-dom";
import React, {useState} from "react";
import {submitForm, formsPath} from "../../utility";
import {ActionType, Entity, ProcessType, Severity} from "enums/index";
import {BaseProps} from "../../BaseProps";
import EmptySearchResults from "components/EmptySearchResults";
import BulkActions from "./BulkActions";
import {AccessType} from "types/Permission";
import FormItemMenu from "../Objects/FormItemMenu";
import {bulkStatusSubmitHandler, StatusSubmitHandler, statusSubmitHandler} from "../../utility/statusSubmitHandler";

interface FormsListProps extends BaseProps {
  forms: Form[];
  orgFormsAccess: AccessType | null;
  isSearched: boolean;
}

export function FormsList(props: FormsListProps) {
  const {forms, orgFormsAccess, toastProps, isSearched, uid} = props;
  const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps!;
  const {orgId} = useParams();
  const [deleteTargetId, setDeleteTargetId] = useState<string | null>(null);
  const [isDeleteDialogOpen, {open: openDeleteDialog, close: closeDeleteDialog}] = useComponentToggler(false);

  const [isBulkAction, setIsBulkAction] = useState<boolean>(false);
  const [selectedFormIds, setSelectedFormIds] = useState<Set<string>>(new Set());
  const [selectedDocRefs, setSelectedDocRefs] = useState<DocumentReference[]>([]);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [targetId, setTargetId] = useState<string | null>(null);
  const [targetRef, setTargetRef] = useState<Element | null>(null);

  function onDeleteClick(formId: string) {
    setDeleteTargetId(formId);
    openDeleteDialog();
  }

  function onDeleteConfirmClick() {
    if (isBulkAction) {
      const submittedData: StatusSubmitHandler<Form>[] = [];

      const targetRefs = Array.from(selectedFormIds).map((id) => doc(formsPath(orgId!), id));
      targetRefs.forEach((ref) => submitForm(
        ref,
        ActionType.Delete,
        (status, data, isLastUpdate) => {
          if(isLastUpdate) {
            submittedData.push({status, data, isLastUpdate});
          }
        }
      ));

      bulkStatusSubmitHandler<Form>({data: submittedData, successCallback, errorCallback});
      return;
    }

    if (!deleteTargetId) return;
    const docRef = doc(formsPath(orgId!), deleteTargetId);
    submitForm(
      docRef,
      ActionType.Delete,
      (status, data, isLastUpdate) => statusSubmitHandler<Form>({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback
      })
    )
  }

  function successCallback() {
    setToastMessage(enFormsLabel.bulkDeleteSuccess(selectedDocRefs.length));
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    resetStates();
  }

  function errorCallback(message: any) {
    setToastMessage(message || enCommonLabel.errorProcess(ProcessType.Delete));
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
    resetStates();
  }

  function resetStates() {
    setSelectedDocRefs([]);
    setSelectedFormIds(new Set());
    setIsBulkAction(false);
    onDeleteDialogClose();
  }

  function onDeleteDialogClose() {
    setDeleteTargetId(null);
    closeDeleteDialog();
  }

  function onSelectAllClick(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    if (checked) {
      setSelectedFormIds(new Set(forms.map((form) => form["@id"]!)));
    } else {
      setSelectedFormIds(new Set());
    }
  }

  function onBulkDeleteClick() {
    setIsBulkAction(true);
    openDeleteDialog();
  }

  function onItemSelectToggle(id: string, checked: boolean) {
    if (checked) {
      setSelectedFormIds(new Set(selectedFormIds.add(id)));
      return;
    }

    const newSet = new Set(selectedFormIds);
    newSet.delete(id);
    setSelectedFormIds(newSet);
  }

  function onMenuOpen(e: Element | undefined, id: string) {
    if (!e) return;

    setTargetId(id);
    setTargetRef(e);
    setIsMenuOpen(true);
  }

  function onCloseMenu() {
    setTargetRef(null);
    setIsMenuOpen(false);
  }

  if (!forms.length && isSearched) return (
    <EmptySearchResults entity={Entity.Form} sx={{height: "80%", marginY: "15%"}}/>
  )

  return (
    <>
      {isMenuOpen && Boolean(targetRef) && (
        <FormItemMenu
          isOpen={isMenuOpen}
          docRef={doc(formsPath(orgId!), targetId!)}
          closeMenu={onCloseMenu}
          anchorEl={targetRef!}
          orgFormsAccess={orgFormsAccess!}
          toastProps={toastProps!}
        />
      )}
      <BulkActions
        orgFormsAccess={orgFormsAccess}
        formsLength={forms.length}
        selectedFormIds={selectedFormIds}
        onSelectAllClick={onSelectAllClick}
        onBulkDeleteClick={onBulkDeleteClick}
      />
      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        title={enFormsLabel.deleteFormConfirmationText}
        text={enFormsLabel.deleteFormConfirmationText}
        handleClose={onDeleteDialogClose}
        handleConfirm={onDeleteConfirmClick}
      />
      <Stack gap={1}>
        {
          forms!.map((form) => (
            <FormItem
              uid={uid}
              key={`formCard_${form["@id"]}`}
              form={form}
              orgFormsAccess={orgFormsAccess}
              onDeleteClick={onDeleteClick}
              isSelected={selectedFormIds.has(form["@id"]!)}
              onItemSelectToggle={onItemSelectToggle}
              toastProps={toastProps!}
              onMenuOpen={onMenuOpen}
            />
          ))
        }
      </Stack>
    </>
  )
}