import {Box, Stack} from "@mui/material";
import theme from "theme/theme";
import TimestampTypo from "components/Typography/TimestampTypo";
import {LongText} from "components/index";
import {formatDateTimeFirestoreDate, userActivityFeedPath} from "../../utility";
import {memo, useEffect, useState} from "react";
import {ActivityItem} from "types/ActivityFeedTypes";
import {doc} from "firebase/firestore";
import {ToastProps} from "../../BaseProps";
import generateEntityBreadcrumbs, {BreadCrumbType} from "../../utility/generateEntityBreadcrumbs";
import generateEntityName from "../utils/generateEntityName";
import BreadcrumbsDisplay from "components/BreadcrumbsDisplay";
import PinIconButton from "components/Button/PinIconButton";

interface BaseItemProps extends ToastProps {
  uid: string;
  item: ActivityItem;
  icon: JSX.Element;
  text: JSX.Element | string;
  additionalText?: JSX.Element | string;
  updatePinnedValue: (index: number, pinned: boolean) => void;
  index: number;
}

const BaseItem = memo(function BaseItem(props: BaseItemProps) {
  const {item, icon, text, toastProps, additionalText, updatePinnedValue, index} = props;
  const {timestamp, entity, orgName, pinned, type} = item;
  const {date, time} = formatDateTimeFirestoreDate(timestamp);
  const [entityBreadcrumbs, setEntityBreadcrumbs] = useState<BreadCrumbType[]>([]);
  const [pinnedValue, setPinnedValue] = useState<boolean>(false);

  useEffect(() => {
    if (pinnedValue !== pinned) {
      setPinnedValue(pinned);
      updatePinnedValue(index, pinned);
    }
  }, [pinned]);

  //generate entity breadcrumbs on load
  useEffect(() => {
    setEntityBreadcrumbs(generateEntityBreadcrumbs(item));
  }, [item])

  return <Stack
    sx={{
      // flexDirection: {sm: "column", md: "row"},
      flexDirection: "row",
      border: `1px solid ${theme.palette.divider}`,
      padding: 1,
      paddingX: 3,
      marginRight: "20%",
      pr: 0,
      borderRadius: 1,
      position: "relative",
      marginY: 1,
    }}
  >
    <Box
      sx={{
        position: "absolute",
        top: theme.spacing(3 / 2),
        left: theme.spacing(1),
      }}
    >
      {icon}
    </Box>
    <Stack flex={1} flexDirection={{sx: "column", sm: "row"}}>
      <Stack mx={2} gap={0.5} flex={2}>
        <Stack
          direction={{xs: "column", md: "row"}}
          alignItems={{xs: "flex-start", md: "center"}}
          gap={1}
        >
          {generateEntityName(entity, type)}
          <LongText maxLines={1}>{text}</LongText>
        </Stack>
        {entityBreadcrumbs.length > 0 && <BreadcrumbsDisplay entityBreadcrumbs={entityBreadcrumbs} orgName={orgName}/>}
        {additionalText &&
          <LongText maxLines={1} variant="bodySmall" color="text.secondary">{additionalText}</LongText>}
      </Stack>
      <TimestampTypo marginY="auto" flex={1} variant="h5" fontWeight="normal">{`${date} ${time}`}</TimestampTypo>
    </Stack>
    <Box sx={{
      borderLeft: 1,
      borderColor: theme.palette.divider,
      marginY: -1,
      paddingX: {sx: 0, sm: 1},
      display: "grid",
      placeItems: "center"
    }}>
      <PinIconButton
        entity="Activity"
        pinned={pinned}
        documentReference={doc(userActivityFeedPath(props.uid), item["@id"])}
        toastProps={toastProps!}
      />
    </Box>
  </Stack>
})

export default BaseItem;
