import {ExportTaskType} from "enums/exportTaskType";
import {enHomeLabel} from "constants/index";

export const tabValues = {
  [ExportTaskType.AllTasks]: {
    title: enHomeLabel.exportAllTasksTitle,
    message: enHomeLabel.exportAllTasksText,
    exportingTitle: enHomeLabel.exportingAllTasksTitle
  },
  [ExportTaskType.TasksAssignedToMe]: {
    title: enHomeLabel.exportAssignedToMeTasksTitle,
    message: enHomeLabel.exportAllTasksText,
    exportingTitle: enHomeLabel.exportingAssignedToMeTasksTitle
  },
  [ExportTaskType.ImTheApprover]: {
    title: enHomeLabel.exportImTheApproverTasksTitle,
    message: enHomeLabel.exportAllTasksText,
    exportingTitle: enHomeLabel.exportingImTheApproverTasksTitle
  },
  [ExportTaskType.ImTheObserver]: {
    title: enHomeLabel.exportImTheObserverTasksTitle,
    message: enHomeLabel.exportAllTasksText,
    exportingTitle: enHomeLabel.exportingImTheObserverTasksTitle
  },
  [ExportTaskType.FavoriteTasks]: {
    title: enHomeLabel.exportFavoriteTasksTitle,
    message: enHomeLabel.exportAllTasksText,
    exportingTitle: enHomeLabel.exportingFavoriteTasksTitle
  },
  [ExportTaskType.TimeData]: {
    title: "",
    message: "",
    exportingTitle: ""
  },
  [ExportTaskType.FilteredTasks]: {
    title: "",
    message: "",
    exportingTitle: ""
  },
}