import {Stack} from "@mui/material";
import {SortMenu} from "components/index";
import {defaultTeamCategory, enManageTeamCategoriesLabel} from "constants/index";
import React, {useEffect, useState} from "react";
import {TeamCategory} from "types/index";
import {teamCategoriesPath} from "screens/utility/FirebasePath";
import {useCollection} from "hooks/index";
import {orderBy, where} from "firebase/firestore";
import {ActionType} from "enums/actionType";
import SmartSearchInput from "components/inputs/SmartSearchInput";
import {algoliaTeamCategoriesPath} from "../../utility/algoliaColPath";
import {useParams} from "react-router-dom";
import {AccessType} from "types/Permission";
import DynamicView from "./DynamicView";

interface TeamCategoryListProps {
  uid: string;
  categoryAccess: AccessType | null;
  organizationId: string;
  onItemActionClick: (action: ActionType, teamCategory: TeamCategory) => void;
}

function TeamCategoryList(props: TeamCategoryListProps) {
  const {organizationId, onItemActionClick, categoryAccess} = props;
  const {orgId} = useParams()

  const collectionRef = teamCategoriesPath(organizationId);
  const [categories, setCategoriesColConstraints] = useCollection<TeamCategory>(null, collectionRef);
  const [algoliaCategories, setAlgoliaCategories] = useState<TeamCategory[] | null>(null);

  const [sortFilter, setSortFilter] = useState<"asc" | "desc">("asc");

  useEffect(() => {
    setCategoriesColConstraints([
      orderBy("name", sortFilter),
      where("name", "!=", defaultTeamCategory.name)
    ]);
  }, [sortFilter]);

  function getTeamCategories() {
    if (algoliaCategories)
      return algoliaCategories;

    if (categories)
      return categories;

    return null;
  }

  return (
    <>
      <Stack
        direction={{xs: "column", sm: "column", md: "row", lg: "row", xl: "row"}}
        sx={{
          justifyContent: "space-between",
          mt: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2},
        }}
        gap={1}
        alignItems={{xs: "flex-start", sm: "flex-start", md: "center", lg: "center", xl: "center"}}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <SmartSearchInput<TeamCategory>
            resultCallback={setAlgoliaCategories}
            colPath={algoliaTeamCategoriesPath(orgId!)}
            placeholder={enManageTeamCategoriesLabel.search}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          <SortMenu
            changeOrder={() => {
            }}
            setter={setSortFilter}
          />
        </Stack>
      </Stack>
      <Stack mt={2} gap={1} flex={1}>
        <DynamicView teamCategories={getTeamCategories()} categoryAccess={categoryAccess} onItemActionClick={onItemActionClick}/>
      </Stack>
    </>
  )
}

export default TeamCategoryList;
