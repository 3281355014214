import {styled} from "@mui/material";
import theme from "theme/theme";
import {enActivityFeedButton} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {LoadingButton, LoadingButtonProps} from "@mui/lab";

function AcceptButton(props: LoadingButtonProps) {

  return (
    <AcceptButtonStyle
      startIcon={<SystemIcons.Check
        stroke={theme.palette.background.paper}
        height={16}
        width={16}
        strokeWidth={3}
      />}
      {...props}
    >
      {enActivityFeedButton.accept}
    </AcceptButtonStyle>
  )
}

const AcceptButtonStyle = styled(LoadingButton)({
  height: "32px",
  backgroundColor: theme.palette.success.main,
  border: `1px solid ${theme.palette.success.main}`,
  color: theme.palette.background.paper,
  width: "106px",
  "&:hover": {
    backgroundColor: theme.palette.success.dark
  }
});

export default AcceptButton;
