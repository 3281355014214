import {enCommonLabel, enFormsLabel} from "constants/index";
import theme from "theme/theme";
import {Typography} from "@mui/material";

interface RequiredResponseProps {
  isRequired?: boolean;
}

function RequiredResponse(props: RequiredResponseProps) {
  const {isRequired} = props;

  if (!isRequired)
    return <>&nbsp;{`(${enCommonLabel.optional})`}</>;

  return (
    <Typography
      color={theme.palette.error.main}
      title={enFormsLabel.responseRequired}
      variant="h4"
    >
      &nbsp;*
    </Typography>
  )
}

export default RequiredResponse