import {UpdatedItemType} from "types/ActivityFeedTypes";
import SystemIndex from "assets/icons/system/system.index";
import {enActivityFeedLabel} from "constants/index";
import {Box, Stack, Typography} from "@mui/material";
import TimestampTypo from "components/Typography/TimestampTypo";
import {formatDateTimeFirestoreDate} from "../../../screens/utility";
import ItemContainer from "components/ActivityFeed/ItemContainer";

interface UpdatedItemProps {
  item: UpdatedItemType
}

function UpdatedItem(props: UpdatedItemProps) {
  const {item} = props;
  const {details, entity, timestamp} = item;
  const {by, name, from, to, reasonForChange = ""} = details;
  const {date, time} = formatDateTimeFirestoreDate(timestamp);

  const reasonForChangeText = !!reasonForChange && !!reasonForChange.trim() && enActivityFeedLabel.reasonForChange(reasonForChange);
  const updatedChangesText = enActivityFeedLabel.updatedChangesDescription(from, to);

  const additionalText = <>
    <Typography>{updatedChangesText}</Typography>
    {reasonForChangeText && <Typography>{reasonForChangeText}</Typography>}
  </>

  return (
    <ItemContainer>
      <Box width={24} height={24}>
        <SystemIndex.SystemIcons.Edit width={24} height={24}/>
      </Box>
      <Stack direction="column">
        {enActivityFeedLabel.updated(entity, name, by.name ?? "")}
        {additionalText}
        <TimestampTypo>{`${date} ${time}`}</TimestampTypo>
      </Stack>
    </ItemContainer>
  );
}

export default UpdatedItem
