import {Box, Chip} from "@mui/material";
import {enOrgDataTagsLabel} from "constants/index";
import React from "react";

interface AddMoreChipProps {
  canEditDocumentRef: boolean;
  extraTagsChipRef: React.RefObject<any>;
  onAddDataTag: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function AddMoreChip(props: AddMoreChipProps) {
  const {canEditDocumentRef, extraTagsChipRef, onAddDataTag} = props;

  return (
    <Box>
      <Chip
        disabled={!canEditDocumentRef}
        ref={extraTagsChipRef}
        onClick={onAddDataTag}
        label={`+ ${enOrgDataTagsLabel.addMore}`}
        color="chip"
        sx={(theme) => ({
          borderRadius: 1,
          cursor: "pointer",
          ":hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.onDark,
          },
        })}
        size="small"
      />
    </Box>
  )
}

export default AddMoreChip;