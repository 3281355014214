import {Asset} from "types/index";
import {BaseProps} from "screens/BaseProps";
import React, {useEffect, useState} from "react";
import {CollectionReference, doc} from "firebase/firestore";
import {
  areObjectsEqual,
  organizationAssetsPath,
  sortStringsBy,
  submitForm
} from "screens/utility/index";
import {ActionType, Severity} from "enums/index";
import {enAssetLabel, enCommonLabel} from "constants/index";
import {checkLongLat, handleKeyPressNumeric, onChangeInput} from "screens/utility/formUtils";
import Input from "components/Input";
import DataTagInput from "components/DataTag/DataTagInput";
import {SimpleFormDrawer} from "components/index";
import SystemIndex from "assets/icons/system/system.index";
import {StringDictionary} from "types/FormBase";
import handleEnterKeyPress from "screens/utility/handleEnterKeyPress";
import GoogleAutocomplete from "components/inputs/GoogleAutocomplete";
import {statusSubmitHandler} from "screens/utility/statusSubmitHandler";
import {en} from "language/en";
import {removeExtraSpaces} from "screens/utility/removeExtraSpaces";
import useCheckUniqueness from "hooks/useCheckUniqueness";

interface EditAssetDrawerProps extends BaseProps {
  asset: Asset;
  isDrawerShown: boolean;
  onDrawerClose: () => void;
  collectionReference: CollectionReference;
}

function EditAssetDrawer(props: EditAssetDrawerProps) {
  const {asset, isDrawerShown, collectionReference, onDrawerClose, toastProps, uid} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialAsset, setInitialAsset] = useState({
    name: asset.name,
    idCode: asset.idCode,
    address: asset.address,
    longitude: asset.longitude ? parseFloat(String(asset.longitude)): null,
    latitude: asset.latitude ? parseFloat(String(asset.latitude)): null,
    dataTagsIds: sortStringsBy((asset.dataTagsIds || []))
  });

  //form values
  const [assetName, setAssetName] = useState<string>(initialAsset.name);
  const [assetIdCode, setAssetIdCode] = useState<string>(initialAsset.idCode);
  const [assetAddress, setAssetAddress] = useState<string>(initialAsset.address || "");
  const [assetLongitude, setAssetLongitude] = useState<number | null>(initialAsset.longitude || null);
  const [assetLatitude, setAssetLatitude] = useState<number | null>(initialAsset.latitude || null);
  const [dataTagsIds, setDataTagsIds] = useState<string[]>(initialAsset.dataTagsIds);

  const [assetNameMessage, setAssetNameMessage] = useState<string>("");
  const [assetIdCodeMessage, setAssetIdCodeMessage] = useState<string>("");
  const [assetLatitudeMessage, setAssetLatitudeMessage] = useState<string>("");
  const [assetLongitudeMessage, setAssetLongitudeMessage] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<StringDictionary>({});

  const isFormValid = isFormModified() && areMessagesEmpty()
  const isNameUnique = useCheckUniqueness(organizationAssetsPath(asset.orgId!), "name", assetName, asset["@id"]);

  useEffect(() => {
    if (!isNameUnique) {
      setAssetNameMessage(enAssetLabel.uniqueNameError);
      return;
    }

    if (assetName.trim() === "") {
      setAssetNameMessage(enAssetLabel.requiredName);
      return;
    }

    setAssetNameMessage("");
  }, [isNameUnique, assetName]);

  // reset form data when drawer is closed or there are changes in asset
  useEffect(() => {
    if (!isDrawerShown) return;

    let newAsset = {
      name: asset.name,
      idCode: asset.idCode,
      address: asset.address ?? "",
      longitude: asset.longitude ? parseFloat(String(asset.longitude)): null,
      latitude: asset.latitude ? parseFloat(String(asset.latitude)): null,
      dataTagsIds: sortStringsBy((asset.dataTagsIds || []))
    }

    setAssetName(newAsset.name);
    setAssetAddress(newAsset.address);
    setAssetIdCodeMessage(newAsset.idCode);
    setAssetLatitude(newAsset.latitude);
    setAssetLongitude(newAsset.longitude);
    setDataTagsIds(newAsset.dataTagsIds);
    setValidationErrors({});
    setIsLoading(false);
    setInitialAsset(newAsset);
  }, [isDrawerShown]);

  function areMessagesEmpty() {
    return (assetNameMessage === "" && assetIdCodeMessage === "" && assetLatitudeMessage === "" && assetLongitudeMessage === "");
  }

  useEffect(() => {
    setAssetNameMessage("");
    setAssetIdCodeMessage("");
    setAssetLatitudeMessage("");
    setAssetLongitudeMessage("");

    Object.keys(validationErrors).forEach((key) => {
      switch (key) {
        case "name":
          setAssetNameMessage(validationErrors[key]);
          break;
        case "idCode":
          setAssetIdCodeMessage(validationErrors[key]);
          break;
        case "latitude":
          setAssetLatitudeMessage(validationErrors[key]);
          break;
        case "longitude":
          setAssetLongitudeMessage(validationErrors[key]);
          break;
        default:
          break;
      }
    });
  }, [validationErrors]);

  useEffect(() => {
    setAssetNameMessage((assetName.trim()) ? "" : enAssetLabel.nameRequired);
  }, [assetName]);

  //check for latitude validations
  useEffect(() => {
    if (!assetLatitude && assetLongitude) {
      setAssetLatitudeMessage(enAssetLabel.latitudeRequired);
      return;
    }
    if (!assetLongitude && !assetLatitude) {
      setAssetLatitudeMessage("");
      setAssetLongitudeMessage("");
      return;
    }
    if (isNaN(assetLatitude ?? 0) || !assetLatitude) return;
    if (!checkLongLat("Latitude", assetLatitude.toString())) {
      setAssetLatitudeMessage(enAssetLabel.invalidInput);
    } else {
      setAssetLatitudeMessage("");
      if (!assetLongitude || isNaN(assetLongitude ?? 0)) {
        setAssetLongitude(() => 180);
      }
    }
  }, [assetLatitude]);

  //check for longitude validations
  useEffect(() => {
    if (!assetLongitude && assetLatitude) {
      setAssetLongitudeMessage(enAssetLabel.longitudeRequired);
      return;
    }
    if (!assetLongitude && !assetLatitude) {
      setAssetLatitudeMessage("");
      setAssetLongitudeMessage("");
      return;
    }
    if (isNaN(assetLongitude ?? 0) || !assetLongitude) return;
    if (!checkLongLat("Longitude", assetLongitude.toString())) {
      setAssetLongitudeMessage(enAssetLabel.invalidInput);
    } else {
      setAssetLongitudeMessage("");
      if (!assetLatitude || isNaN(assetLatitude ?? 0)) {
        setAssetLatitude(() => 90);
      }
    }
  }, [assetLongitude]);

  function isFormModified() {
    const newAsset = {
      name: removeExtraSpaces(assetName),
      idCode: assetIdCode,
      address: assetAddress,
      longitude: assetLongitude ? parseFloat(String(assetLongitude)): null,
      latitude: assetLatitude ? parseFloat(String(assetLatitude)): null,
      dataTagsIds: sortStringsBy(dataTagsIds)
    };

    return !areObjectsEqual(newAsset, initialAsset);
  }

  async function submitEditProject() {
    setIsLoading(true);
    const editedAsset: Partial<Asset> = {
      name: removeExtraSpaces(assetName),
      idCode: assetIdCode,
      address: assetAddress,
      dataTagsIds,
      latitude: assetLatitude ? assetLatitude: null,
      longitude: assetLongitude ? assetLongitude: null,
    }

    const assetDocRef = doc(collectionReference, asset["@id"]!);
    await submitForm<Partial<Asset>>(
      assetDocRef,
      ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler<Asset>({
        status,
        data,
        isLastUpdate,
        successCallback: () => {
          successCallback()
        },
        errorCallback
      }),
      editedAsset,
      asset
    );
  }

  function successCallback() {
    setIsLoading(false);
    setToastMessage(enAssetLabel.updateSuccess);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);

    onDrawerClose();
  }

  function errorCallback(message: any) {
    setIsLoading(false);
    if (typeof (message) === "object") {
      setValidationErrors(message as StringDictionary);
    } else {
      setToastMessage(message ?? en.common.validations.generalError);
      setToastSeverity(Severity.Error);
      setIsToastOpen(true);
    }
  }

  function onPlacesChange(place: string | {formatted_address: string, geometry: {location: {lat: () => number, lng: () => number}} }) {
    if (typeof place === "string") {
      setAssetAddress(place);
      return;
    }

    setAssetAddress(place.formatted_address);
    setAssetLatitude(place.geometry.location.lat());
    setAssetLongitude(place.geometry.location.lng());
  }

	function coordinatesHandleKeyPress (e: React.KeyboardEvent){
		const isNumeric = handleKeyPressNumeric(e);

		if (isNumeric || e.key === "Enter") {
			handleEnterKeyPress(e, isFormValid, isLoading, submitEditProject)
			return;
		}

		e.preventDefault();
	}

  return (
    <SimpleFormDrawer
      id="edit-project-asset"
      isOpen={isDrawerShown}
      title={enAssetLabel.edit}
      onClose={onDrawerClose}
      icon={<SystemIndex.SystemIcons.Edit/>}
      isFormValid={isFormValid}
      isLoading={isLoading}
      onSubmit={submitEditProject}
      unsavedChanges={isFormModified()}
      buttonId={"edit-project-asset-save-button"}
    >
      <Input
        label="Name"
        id="Name"
        onChange={(e) => onChangeInput(e, setAssetName)}
        sx={{mb: 1}}
        defaultValue={assetName}
        optional={false}
        validationMessage={assetNameMessage}
        placeholder=""
        onKeyPress={(e) => handleEnterKeyPress(e, isFormValid, isLoading, submitEditProject)}
      />
      <Input
        label={enAssetLabel.idCode}
        id="ID_Code"
        onChange={(e) => onChangeInput(e, setAssetIdCode)}
        sx={{mb: 1}}
        defaultValue={assetIdCode}
        optional={false}
        validationMessage={assetIdCodeMessage}
        placeholder=""
        onKeyPress={(e) => handleEnterKeyPress(e, isFormValid, isLoading, submitEditProject)}
      />
      <GoogleAutocomplete
        onPlacesChange={onPlacesChange}
        defaultValue={assetAddress}
      />
      <Input
        label={enCommonLabel.latitude}
        type="number"
        id="Latitude"
        onChange={(e) => onChangeInput(e, setAssetLatitude)}
        sx={{my: 1}}
        value={(assetLatitude) ? assetLatitude.toString() : ""}
        optional={true}
        validationMessage={assetLatitudeMessage}
        placeholder="Decimal -90 to +90"
				onKeyPress={coordinatesHandleKeyPress}
      />
      <Input
        label={enCommonLabel.longitude}
        type="number"
        id="Longitude"
        onChange={(e) => onChangeInput(e, setAssetLongitude)}
        sx={{mb: 1}}
        value={(assetLongitude) ? assetLongitude.toString() : ""}
        optional={true}
        validationMessage={assetLongitudeMessage}
        placeholder="Decimal -180 to +180"
				onKeyPress={coordinatesHandleKeyPress}
      />
      <DataTagInput
        handleSelectCallback={setDataTagsIds}
        initialTags={dataTagsIds}
        toastProps={toastProps!}
        uid={uid}
        onKeyPress={(e) => handleEnterKeyPress(e, isFormValid, isLoading, submitEditProject)}
      />
    </SimpleFormDrawer>
  )
}

export default EditAssetDrawer;
