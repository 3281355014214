import {styled} from "@mui/material";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {enActivityFeedButton} from "constants/index";
import {LoadingButton, LoadingButtonProps} from "@mui/lab";

function DeclineButton(props: LoadingButtonProps) {

  return (
    <DeclineButtonStyle
      startIcon={<SystemIcons.Close
        stroke={theme.palette.error.main}
        height={16}
        width={16}
        strokeWidth={3}
      />}
      {...props}
    >
      {enActivityFeedButton.decline}
    </DeclineButtonStyle>
  )
}

const DeclineButtonStyle = styled(LoadingButton)({
  color: theme.palette.secondary.main,
  border: `1px solid ${theme.palette.neutral.light}`,
  height: "32px",
  width: "106px",
});

export default DeclineButton;
