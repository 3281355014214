import {enCommonLabel} from "constants/index";
import {DotIndicator} from "components/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {IconButton} from "@mui/material";
import React, {SVGProps} from "react";
import theme from "theme/theme";

interface ChatIconButtonProps {
  id: string;
  withNewMessage?: boolean;
  chatEmpty?: boolean;
  onClickFcn: (e: any) => void;
  entity: string;
  onDark?: boolean;
  iconProps?: SVGProps<SVGSVGElement>
}

function ChatIconButton(props: ChatIconButtonProps) {
  const {id, withNewMessage = false, chatEmpty = true, entity, onDark = false, iconProps, onClickFcn} = props;

  const iconColor = onDark ? theme.palette.background.default : theme.palette.secondary.main;
  return (
    <IconButton
      id={id}
      title={enCommonLabel.entityChat(entity)}
      onClick={onClickFcn}>
      <DotIndicator isVisible={Boolean(withNewMessage)}>
        {Boolean(chatEmpty) ? <SystemIcons.ChatOutlined stroke={iconColor} {...iconProps}/>
          : <SystemIcons.ChatFilled stroke={iconColor} fill={iconColor} {...iconProps}/>}
      </DotIndicator>
    </IconButton>
  )
}

export default ChatIconButton;
