import {ReactElement, useEffect} from "react";
import {Snackbar, Slide, Alert} from "@mui/material";
import {Severity} from "enums/severity";
import positiveSfx from "../sounds/positive.mp3"
import negativeSfx from "../sounds/negative.mp3"
import useSoundWithSettings from "hooks/useSoundWithSettings";

interface ToastProps {
  messageComponent: ReactElement;
  open: boolean;
  onClose: (e?: Event | React.SyntheticEvent<any, Event>) => void;
  key?: string;
  severity?: Severity;
  duration?: number | null;
  sx?: any;
}

function Toast(props: ToastProps) {
  const {
    messageComponent,
    open,
    onClose,
    key,
    severity = "info",
    sx,
    duration=4000
  } = props;

  const playSuccessSfx = useSoundWithSettings(positiveSfx);
  const playErrorSfx = useSoundWithSettings(negativeSfx);

  useEffect(() => {
    if(!open) return;

    switch (severity) {
      case Severity.Success:
        playSuccessSfx();
        break;
      case Severity.Error:
        playErrorSfx()
        break;
    }
  }, [open]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={(e) => {
        if (e) {
          e.preventDefault();
          e.stopPropagation();
        }
        onClose(e);
      }}
      anchorOrigin={{vertical: "bottom", horizontal: "center"}}
      TransitionComponent={SlideTransition}
      key={key}
      sx={{
        position: "absolute",
        ...sx
      }}
      id="toastMessage"
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={(theme) => ({
          width: "100%",
          ...((severity === Severity.Success) && {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.text.onDark,
          }),
        })}
      >
        {messageComponent}
      </Alert>
    </Snackbar>
  );
}

const SlideTransition = (props: any) => {
  return <Slide {...props} direction="up"/>;
};

export default Toast;
