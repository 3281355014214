import {Menu, MenuItem, Typography} from "@mui/material";
import SystemIndex, {SystemIcons} from "assets/icons/system/system.index";
import {ManageFileRequirementDrawer, ManageFormRequirementDrawer} from "components/Drawers";
import React, {useRef, useState} from "react";
import {ActionOption, ConfirmDialog} from "components/index";
import {
  emptyFunction,
  enCommonLabel,
  enFileRequirementLabel,
  enFormRequirementLabel,
  enMilestoneButton
} from "constants/index";
import {submitForm} from "../../utility";
import {CollectionReference, doc} from "firebase/firestore";
import {ActionType} from "enums/actionType";
import {FileRequirement} from "types/FileRequirement";
import {FormRequirement} from "types/FormRequirement";
import {toastProps} from "../../BaseProps";
import {Severity} from "enums/severity";
import {ProcessType} from "enums/processType";
import {statusSubmitHandler} from "../../utility/statusSubmitHandler";
import DownloadOptionsMenu from "components/Dialogs/DownloadConfirmDialog/DownloadOptionsMenu";
import {Entity} from "enums/entity";

interface RightClickMenuProps {
  uid: string;
  anchorEl: HTMLDivElement | null;
  isOpen: boolean;
  onClose: () => void;
  toastProps: toastProps;
  collectionRef: CollectionReference;
  fileRequirement?: FileRequirement;
  formRequirement?: FormRequirement;
  anchorPosition: {top: number; left: number};
  entity?: Entity;
  filesCount: number;
  fromTemplates?: boolean;
}

function RightClickMenu(props: RightClickMenuProps) {
  const {
    anchorEl,
    isOpen,
    onClose,
    fileRequirement,
    formRequirement,
    collectionRef,
    anchorPosition,
    uid,
    entity,
    toastProps,
    fromTemplates = false
  } = props;
  const isDeleteing = useRef(false);
  const {setIsToastOpen, setToastMessage, setToastSeverity} = props.toastProps!;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showDownloadOptions, setShowDownloadOptions] = useState<boolean>(false);

  const typography = fileRequirement ? {
    edit: enFileRequirementLabel.editFileRequirement,
    delete: enFileRequirementLabel.deleteFileRequirement,
    confirmationTitle: enFileRequirementLabel.fileDeleteConfirmationTitle,
    confirmationText: enFileRequirementLabel.fileDeleteConfirmationText,
    deleteSuccess: enFileRequirementLabel.deleteSuccess
  } : {
    edit: enFormRequirementLabel.edit,
    delete: enFormRequirementLabel.delete,
    confirmationTitle: enFormRequirementLabel.formDeleteConfirmationTitle,
    confirmationText: enFormRequirementLabel.formDeleteConfirmationText,
    deleteSuccess: enFormRequirementLabel.deleteSuccess
  }

  function deleteRequirement() {
    let docRef = null;
    isDeleteing.current = true

    if (fileRequirement) {
      docRef = doc(collectionRef, fileRequirement["@id"])
    } else if (formRequirement) {
      docRef = doc(collectionRef, formRequirement["@id"])
    }

    if (docRef)
      submitForm(docRef, ActionType.Delete,
        (status, data, isLastUpdate) => statusSubmitHandler<any>({
          status,
          data,
          isLastUpdate,
          successCallback,
          errorCallback
        })
      )
  }

  function successCallback() {
    setToastMessage(typography.deleteSuccess);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    onClose();
  }

  function errorCallback(message: any) {
    let toastMessage = enCommonLabel.errorProcess(ProcessType.Delete);
    // if type of message is obj, get first obj value
    if (typeof message === "object" && (typeof Object.values(message)[0] === "string")) {
      toastMessage = Object.values(message)[0] as string;
    }

    setToastMessage(toastMessage);
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
    onClose();
  }

  function closeDialog() {
    setShowDownloadOptions(false);
    onClose();
  }

  return <>
    {fileRequirement && (
      <ManageFileRequirementDrawer
        uid={uid}
        fileRequirement={fileRequirement ?? null}
        onClose={() => setIsDrawerOpen(false)}
        isOpen={isDrawerOpen}
        toastProps={toastProps!}
        collectionRef={collectionRef}
      />
    )}
    {formRequirement && (
      <ManageFormRequirementDrawer
        uid={uid}
        formRequirement={formRequirement}
        isDrawerOpen={isDrawerOpen}
        onDrawerClose={() => setIsDrawerOpen(false)}
        toastProps={toastProps!}
        formRequirementCollectionRef={collectionRef}
      />
    )}
    <ConfirmDialog
      isOpen={isDialogOpen}
      handleClose={() => setIsDialogOpen(false)}
      handleConfirm={deleteRequirement}
      title={typography.confirmationTitle}
      text={typography.confirmationText}
    />
    <Menu
      open={isOpen}
      anchorEl={anchorEl}
      onClose={closeDialog}
      onClick={closeDialog}
      anchorPosition={anchorPosition}
      anchorReference={"anchorPosition"}
    >
      {fileRequirement && entity === Entity.FileRequirement && [
        <ActionOption
          sx={{px: 1.2, py: 0.3}}
          labelSx={{fontSize: 14}}
          id="requirement-overview-download-files-menu-button"
          Icon={<SystemIcons.Download />}
          label={enMilestoneButton.download}
          onClick={() => setShowDownloadOptions(prev => !prev)}
          disabled={false}
          isDropdown
        />,
        <DownloadOptionsMenu
          uid={uid}
          entity={entity!}
          filesCount={props.filesCount}
          onClose={emptyFunction}
          isDownloadOptionsCollapseOpen={showDownloadOptions}
          isCollapsible
          toastProps={toastProps!}
          extraIds={{requirementId: fileRequirement["@id"]!}}
        />
      ]}

      <MenuItem
        sx={{display: "flex", gap: 1}}
        onClick={() => setIsDrawerOpen(true)}
      >
        <SystemIndex.SystemIcons.Edit/>
        <Typography variant="h5">{typography.edit}</Typography>
      </MenuItem>
      <MenuItem
        sx={{display: "flex", gap: 1}}
        disabled={fromTemplates}
        onClick={() => setIsDialogOpen(true)}
      >
        <SystemIndex.SystemIcons.DeleteOutlined/>
        <Typography variant="h5" color="error.main">{typography.delete}</Typography>
      </MenuItem>
    </Menu>
  </>
}

export default RightClickMenu
