import {EmptyList, InProgress} from "components/index";
import {Stack, Typography} from "@mui/material";
import {Timer} from "types/Timer";
import {useCollection} from "hooks/index";
import {Dispatch, useEffect, useState} from "react";
import StartedTimersList from "components/Timers/TimersList/StartedTimersList";
import PausedTimersList from "components/Timers/TimersList/PausedTimersList";
import {CollectionReference, limit, QueryConstraint} from "firebase/firestore";
import {TimerState} from "types/Task";
import {EmptyPage} from "assets/arrayList/EmptyPage";
import {CollapseStates} from "components/Timers/TimersViews/MyTimersView";
import {Entity} from "enums/entity";
import theme from "theme/theme";

interface BaseTimersViewProps {
  uid: string;
  colRef: CollectionReference
  organizationTimer?: boolean
  collapsible?: boolean
  emptyPage?: boolean
  collapseStates?: CollapseStates
  setCollapseStates?: Dispatch<CollapseStates>
}

const baseListCount = 3;
const listIncrementCount = 10

function BaseTimersView(props: BaseTimersViewProps) {
  const {
    colRef,
    organizationTimer,
    collapsible = false,
    collapseStates,
    setCollapseStates,
    emptyPage,
  } = props;
  const queryConstraints: QueryConstraint[] = organizationTimer ? [limit(baseListCount)] : []
  const [timers, , setTimersColRef] = useCollection<Timer>(null, null, queryConstraints)
  const [timersCount, setTimersCount] = useState(baseListCount)
  const totalStartedCount = timers ? timers.filter((timer) => isTimerRunning(timer.timerState)).length : 0
  const totalPausedCount = timers ? timers.filter((timer) => !isTimerRunning(timer.timerState)).length : 0

  //listen when manual params gets the org id
  useEffect(() => {
    setTimersColRef(colRef)
  }, [colRef])

  function onLoadMoreClick() {
    setTimersCount(timersCount + listIncrementCount);
    // setTimersConstraints([limit(baseListCount + listIncrementCount)])
  }

  function onSeeLessClick() {
    setTimersCount(baseListCount);
    // setTimersConstraints([limit(baseListCount)])
  }

  if (!timers)
    return <InProgress/>

  if (!timers.length) {
    if (emptyPage)
      return <Stack
        sx={{
          justifyContent: "center",
          minHeight: 500,
          flex: 1
        }}
      >
        <EmptyList
          entity={Entity.Timers}
          sx={{
            pointerEvents: "",
            position: "relative",
          }}
          logoProperties={{stroke: theme.palette.neutral.light, fill: theme.palette.background.default}}
        />
      </Stack>
  }

  return <Stack gap={2} paddingTop={0}>
    <StartedTimersList
      uid={props.uid}
      totalStartedCount={totalStartedCount}
      colRef={colRef}
      setCollapseStates={setCollapseStates}
      collapseStates={collapseStates}
      collapsible={collapsible}
      listCount={timersCount}
      onSeeLessClick={onSeeLessClick}
      onLoadMoreClick={onLoadMoreClick}
      organizationTimer={organizationTimer}
    />
    <PausedTimersList
      uid={props.uid}
      totalPausedCount={totalPausedCount}
      colRef={colRef}
      setCollapseStates={setCollapseStates}
      collapseStates={collapseStates}
      collapsible={collapsible}
      listCount={timersCount}
      onSeeLessClick={onSeeLessClick}
      onLoadMoreClick={onLoadMoreClick}
      organizationTimer={organizationTimer}
    />

    {Object.values(collapseStates ?? {}).every(value => value === false) &&
      timers && timers.length === 0 &&
      <Typography variant="h4" color="text.secondary" margin="0 auto">{EmptyPage.Timers.title}</Typography>
    }
  </Stack>
}

function isTimerRunning(timerState: TimerState) {
  switch (timerState) {
    case TimerState.Resumed:
    case TimerState.Running:
    case TimerState.Started:
      return true
    default:
      return false;
  }
}

export default BaseTimersView
