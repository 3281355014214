import {TimeLog} from "types/TimeLog";
import Labels from "./Labels";
import TotalLoggedTime from "./TotalLoggedTime";
import {Stack} from "@mui/material";
import TimeLogGroup from "./TimeLogGroup";

interface TimeLogsListProps {
  timeLogs: TimeLog[];
}

function TimeLogsList(props: TimeLogsListProps) {
  const {timeLogs} = props;

  return <Stack gap={3}>
    <TotalLoggedTime />
    <Labels/>
    <TimeLogGroup timeLogs={timeLogs}/>
  </Stack>
}

export default TimeLogsList