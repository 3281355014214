import {BaseProps} from "../BaseProps";
import React, {Dispatch, useEffect} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import {emptyFunction, enCommonLabel} from "constants/index";
import {useNavigate} from "react-router";
import {FormRequirement} from "types/FormRequirement";
import Header from "./Header";
import {
  formRequirementPath,
  projectLevelTasksAccessListPath,
  projectLevelTasksFormRequirementPath,
  submitForm
} from "../utility";
import DeletedForm from "./Objects/DeletedForm";
import {Content, InProgress} from "components/index";
import PageHead from "./Objects/PageHead";
import {ActionType, Entity, RequirementStatus, Severity} from "enums/index";
import FormView from "./FormView";
import {PermissionEntity} from "types/Permission";
import useToast from "hooks/toasts/useToast";
import useTemplateDocument from "hooks/useTemplateDocument";
import useTemplateAccess from "hooks/useTemplateAccess";
import {collection} from "firebase/firestore";
import {OutletContextType} from "components/RestrictedPage";

interface FormRequirementOverviewProps extends BaseProps {
  uid: string;
}
function FormRequirementOverview(props: FormRequirementOverviewProps) {
  const {toastProps, selectedOrg, uid, setSelectedOrgId = emptyFunction} = props;
  const {isLoading} = useOutletContext<OutletContextType>();

  const navigate = useNavigate();
  const {orgId, projId, assetId, milestoneId, taskId, formRequirementId} = useParams();

  const showStatusUpdateSuccess = useToast(toastProps!, Severity.Success, enCommonLabel.statusUpdateSuccess)

  const documentRef = assetId! ? formRequirementPath(orgId!, projId!, assetId!, milestoneId!, taskId!, formRequirementId!)
    : projectLevelTasksFormRequirementPath(orgId!, projId!, milestoneId!, taskId!, formRequirementId!);

  const [formRequirement] = useTemplateDocument<FormRequirement | null>(documentRef);

  const [access] = useTemplateAccess({
    uid,
    entity: Entity.Task,
    documentDocId: assetId! ? PermissionEntity.FormRequirement : PermissionEntity.PLFormRequirement,
    accessListRef: assetId ? collection(documentRef, "accessList") : projectLevelTasksAccessListPath(orgId!, projId!),
  });

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  async function updateRequirementStatus(status: RequirementStatus, setLoading: Dispatch<boolean>, otherFields: any = {}) {
    await submitForm<FormRequirement>(documentRef, ActionType.Update, () => {
      setLoading(false)
      showStatusUpdateSuccess();
    }, {
      formRequirementStatus: status,
      ...otherFields
    });
  }

  if (!formRequirement || isLoading)
    return (
      <Content>
        <InProgress/>
      </Content>
    );

  if (!!formRequirement.templateFormReqId || !!formRequirement.templateId)
    return <Content>Temporarily Disabled {formRequirement.templateFormReqId} {formRequirement.templateId}</Content>;

  return (
    <>
      <Header
        uid={uid}
        toastProps={toastProps!}
        formRequirement={formRequirement}
        documentRef={documentRef.path}
        access={access}
      />
      <Content>
        <PageHead
          access={access}
          formRequirement={formRequirement}
          documentRef={documentRef}
          updateStatus={updateRequirementStatus}
          {...props}
        />
        {formRequirement.formDeleted
          ? <DeletedForm/>
          : (
            <FormView
              uid={uid}
              toastProps={toastProps!}
              documentRef={documentRef}
              formRequirement={formRequirement}
              access={access}
            />
          )}
      </Content>
    </>
  )
}

export default FormRequirementOverview;
