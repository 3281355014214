import {TimerReducerState} from "components/FloatingTimer/useTimerReducer/index";
import {TimerActions, TimerActionTypes} from "components/FloatingTimer/useTimerReducer/actions";
import {Timer} from "types/Timer";

function timerReducer(state: TimerReducerState, action: TimerActions): TimerReducerState {

  switch (action.type) {
    case TimerActionTypes.updateTimerState:
      return {...state, timerState: action.payload}
    case TimerActionTypes.updateTimer:
        return {...state, timer: { ...action.payload} as Timer}
    case TimerActionTypes.updateFromDb:
      return {...state, fromDb: action.payload}
  }

  return state
}

export default timerReducer
