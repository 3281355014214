/**
 * converts received array of T to array of arrays
 * where array size is based on the given length
 * @param array - single dimensional array
 * @param maxLength - max size of sub arrays
 */
export function convertToArrayOfArrays<T>(array: T [], maxLength: number) {
  let checkedCopy = [...array];
  let groupedSelected: T[][] = [];

  while (checkedCopy.length >= maxLength) {

    const group = checkedCopy.slice(0, maxLength);
    checkedCopy = checkedCopy.slice(maxLength);
    groupedSelected = [...groupedSelected, group];
  }

  if (checkedCopy.length > 0) {
    groupedSelected = [...groupedSelected, checkedCopy];
  }

  return groupedSelected;
}

