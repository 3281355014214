export const timeline = {
  title: "Timeline",
  subtitle: "",
  description: "",
  status: {
    notScheduled: "Not Scheduled",
    scheduled: "Scheduled",
    inProgress: "In Progress",
    submitted: "Submitted",
    approved: "Approved",
    rejected: "Rejected",
    cancelled: "Cancelled"
  },
  label: {

  },
  button: {

  }
}