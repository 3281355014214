import React, {useEffect, useState} from "react";
import {collection, CollectionReference, doc, orderBy, QueryConstraint, where} from "firebase/firestore";
import {useCollection, useComponentToggler, useDebounce} from "hooks/index";
import {EntityChat} from "types/EntityChat";
import {ActionType, Entity} from "enums/index";
import ChatDrawer from "components/ChatDrawer";
import {generateEntityBreadcrumbs, organizationsPath, submitForm} from "screens/utility";
import {BaseProps, ToastProps} from "screens/BaseProps";
import SearchAndFilters from "./SearchAndFilters";
import {TabIndex} from "../../enums/TabIndex";
import {Organization} from "types/Organization";
import ChatList from "./ChatList";
import {useNavigate} from "react-router";
import {generatePathPair} from "../../utils/generatePathPair";
import {algoliaIndex} from "../../../../algoliaConfig";
import {db} from "../../../../firebase";
import {emptyFunction} from "constants/index";

type TabContentsProps = ToastProps & BaseProps & {
  colRef: CollectionReference;
  constraints: QueryConstraint[];
  entity: Entity[];
  tab: TabIndex;
}

export enum ChatFilters  {
  read = "Read",
  unread = "Unread",
  none = "All Chats"
}

export function TabContents(props: TabContentsProps) {
  const {colRef, uid, constraints, toastProps, tab, entity} = props;

  const navigate = useNavigate();

  const defaultConstraints = [orderBy("pinned", "desc"), orderBy("timestamp", "desc"), ...constraints];
  const [chatItems, setChatItemsConstraints, setColRef] = useCollection<EntityChat>(null, colRef, defaultConstraints);
  const [organizations] = useCollection<Organization>(null, organizationsPath(), [where("@allowedUsers", "array-contains", uid)]);
  const [targetChatItem, setTargetChatItem] = useState<EntityChat | null>(null);
  const [chatFilter, setChatFilter] = useState<ChatFilters>(ChatFilters.none)

  const [currentOrgFilter, setCurrentOrgFilter] = useState<string>("allOrganizations");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isChatDrawerOpen, {open: openChatDrawer, close: closeChatDrawer}] = useComponentToggler(false);
  const [algoliaResult, setAlgoliaResult] = useState<EntityChat[] | null>(null);

  useEffect(() => {
    if (searchQuery.trim() !== "") return;
    setAlgoliaResult(null);
    setTargetChatItem(null);

    if (currentOrgFilter === "allOrganizations")
      return setChatItemsConstraints(defaultConstraints);

    setChatItemsConstraints([...defaultConstraints, where("orgId", "==", currentOrgFilter)]);
  }, [currentOrgFilter, searchQuery]);

  useEffect(() => {
    setColRef(colRef)
  }, [colRef?.path]);

  useDebounce(searchAlgolia, 500, [searchQuery]);

  async function searchAlgolia() {
    if (searchQuery.trim() === "") return;

    const algoliaPath = `entityChats`;
    let algoliaFilters: any = [
      `colPath:${algoliaPath}`,
      `userIds:${uid}`,
    ];

    if (currentOrgFilter !== "allOrganizations")
      algoliaFilters.push(`orgId:${currentOrgFilter}`);
    if (entity.length > 0)
      algoliaFilters.push(entity.map((e) => `chatEntity:${e}`));

    await algoliaIndex.search<EntityChat>(searchQuery, {
      hitsPerPage: 1000,
      facetFilters: algoliaFilters,
    }).then(({hits}) => {
      setAlgoliaResult(hits);
    });
  }

  function onChatCardClick(e: React.MouseEvent, chatItem: EntityChat) {
    e.stopPropagation();
    e.preventDefault();

    setTargetChatItem(chatItem);
    openChatDrawer();
    const docRef = doc(colRef, chatItem.id);
    submitForm<Partial<EntityChat>>(docRef, ActionType.Update, emptyFunction, {read: true})
  }

  function navigateToChatOverview() {
    if (!targetChatItem) return;

    const entityBreadcrumbs = generateEntityBreadcrumbs(targetChatItem);
    navigate(entityBreadcrumbs[entityBreadcrumbs.length - 1].path);
  }

  function onChatDrawerClose() {
    setTargetChatItem(null);
    closeChatDrawer();
  }

  const chatCollectionName = targetChatItem?.chatEntity === Entity.Project && targetChatItem?.isPL ? "projectLevelTasksChatMessages"
    : "chatMessages";

  return (
    <>
      {!!targetChatItem && (
        <ChatDrawer
          uid={uid}
          isOpen={isChatDrawerOpen}
          entity={targetChatItem.chatEntity}
          pathPair={generatePathPair(targetChatItem)}
          onClose={onChatDrawerClose}
          colRef={collection(doc(db, targetChatItem.entityRefPath!), chatCollectionName)}
          navigateToOverview={navigateToChatOverview}
          toastProps={toastProps!}
        />
      )}
      <SearchAndFilters
        tabIndex={tab}
        organizations={organizations}
        currentOrgFilter={currentOrgFilter}
        setCurrentOrgFilter={setCurrentOrgFilter}
        setSearchQuery={setSearchQuery}
        chats={algoliaResult ?? chatItems}
        fromAlgolia={algoliaResult !== null}
        toastProps={toastProps!}
        uid={props.uid}
        chatFilter={chatFilter}
        setChatFilter={setChatFilter}
      />
      <ChatList
        uid={uid}
        chatItems={algoliaResult ?? chatItems}
        onChatCardClick={onChatCardClick}
        fromAlgolia={algoliaResult !== null}
        toastProps={toastProps!}
        chatFilter={chatFilter}
      />
    </>
  )
}