import {useEffect, useState} from "react";
import {Stack, Button, Typography, Chip, Box} from "@mui/material";
import {MilestoneScheduleStatus, MilestoneScheduleProps} from "assets/arrayList/MilestoneScheduleStatus";
import {en} from "language/en";
import {MilestoneScheduleStatus as ScheduleStatus} from "enums/milestoneScheduleStatus";
import {FilterPanelProps} from "../FilterPanelProps";
import {MilestoneAssignedStatus as Status} from "enums/milestoneAssignedStatus";

function SchedulePanel(props: FilterPanelProps<ScheduleStatus>) {
  const {selectedFilter, setSelected} = props;

  const [isScheduleClearButtonEnabled, setIsScheduleClearButtonEnabled] = useState<boolean>(false);
  const [scheduleFilter, setScheduleFilter] = useState<MilestoneScheduleProps>(MilestoneScheduleStatus);

  // check if there is a previous selected (from parent), if yes, set selected to true
  useEffect(() => {
    let initialStatus = MilestoneScheduleStatus;
    let newStatus: any = {};

    setIsScheduleClearButtonEnabled(selectedFilter.length > 0);

    Object.entries(initialStatus).map(obj => {
      let statusLabel = obj[0];
      let found = selectedFilter.indexOf(statusLabel as unknown as ScheduleStatus);

      newStatus[statusLabel] = {selected: (found >= 0)}

      return true;
    });

    setScheduleFilter(newStatus);
  }, [selectedFilter]);

  function renderScheduleFilters() {
    const chipFilters: any = [];

    Object.entries(scheduleFilter).forEach((obj, index) => {
      let chipLabel: string = obj[0].toString();

      chipFilters.push(
        <Chip
          id={`schedule-item-${chipLabel.split(" ").join("-").toLowerCase()}`}
          label={chipLabel}
          key={index}
          sx={{margin: 0.5, padding: 1}}
          color={obj[1].selected ? "primary" : "default"}
          onClick={() => onChipClick(obj[0])}
        />
      );
    });

    return chipFilters;
  }

  function onChipClick(objectName: string) {
    let selected: ScheduleStatus[] = [];
    const updatedScheduleFilter = { ...scheduleFilter }; // Create a copy of the state

    updatedScheduleFilter[objectName].selected = !updatedScheduleFilter[objectName].selected;

    setIsScheduleClearButtonEnabled(Object.values(updatedScheduleFilter).some(obj => obj.selected));

    Object.entries(updatedScheduleFilter).forEach(([str, obj]) => {
      if (obj.selected) {
        selected.push(str as unknown as ScheduleStatus);
      }
    });

    setSelected(selected);
  }

  return (
    <Stack
      sx={{display: "flex", mt: 1, mb: 1}}
      direction="column"
      alignContent="center">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Typography
          variant="h4"
          sx={{fontSize: "14px"}}>
          {en.screen.Milestone.label.scheduleStatus}
        </Typography>
        <Button
          id="filter-schedule-clear-button"
          disabled={!isScheduleClearButtonEnabled}
          onClick={() => setSelected([])}>
          {en.common.button.clear}
        </Button>
      </Stack>
      <Box sx={{pb: 1, pt: 1}}>
        {renderScheduleFilters()}
      </Box>
    </Stack>
  );
}

export default SchedulePanel;