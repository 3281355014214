import {useState, useEffect} from "react";
import {LocalizationProvider, StaticDatePicker} from "@mui/x-date-pickers";
import {Stack, Chip} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {extractDate} from "screens/utility";
import {enCommonLabel} from "constants/index";
import dayjs from "dayjs";

interface DatePickerProps {
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
  disablePreviousDate: boolean;
}

function DatePicker(props: DatePickerProps) {
  const {selectedDate, disablePreviousDate} = props;
  const {setSelectedDate} = props;

  const [isTodaySelected, setIsTodaySelected] = useState<boolean>(false);
  const [isTomorrowSelected, setIsTomorrowSelected] = useState<boolean>(false);
  const [isYearSelectorOpen, setIsYearSelectorOpen] = useState<boolean>(false);

  const today = new Date();

  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  useEffect(() => {
    if (selectedDate !== null) {
      const todayDate = extractDate(today);
      const tomorrowDate = extractDate(tomorrow);

      const selectedDayJS = dayjs(selectedDate).toDate();
      const currentSelected = extractDate(selectedDayJS);

      setIsTodaySelected(todayDate === currentSelected);
      setIsTomorrowSelected(tomorrowDate === currentSelected);
      return;
    }

    setIsTomorrowSelected(false);
    setIsTodaySelected(false);
  }, [selectedDate]);

  return (
    <Stack
      direction="column"
      alignContent="center"
      id="date-picker"
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          openTo="day"
          value={selectedDate ? dayjs(selectedDate) : null} // Correctly set the value prop
          disablePast={disablePreviousDate}
          displayStaticWrapperAs="mobile"
          slots={{toolbar: () => null}}
          slotProps={{
            actionBar: {style: {display: "none"}}
          }}
          onChange={(newValue) => {
            // Check if newValue is not null and is an instance of Dayjs object
            // @ts-ignore
            if (newValue && newValue.$d instanceof Date) {
              setSelectedDate(newValue.toDate()); // Convert Dayjs object to Date and update state
            } else {
              setSelectedDate(null); // Clear the selected date
            }
          }}
          onViewChange={(view) => ["year", "day"].includes(view) && setIsYearSelectorOpen(!isYearSelectorOpen)}
        />
      </LocalizationProvider>
      <Stack
        justifyContent="center"
        sx={{
          pb: 1,
          pt: -1,
          mt: -1,
          transition: "opacity ease 0.3s",
          opacity: isYearSelectorOpen ? 0 : 1
        }}
        direction="row"
      >
        <Chip
          id="select-today-date-picker-button"
          label={enCommonLabel.today}
          sx={{margin: 1, padding: 1}}
          color={isTodaySelected ? "primary" : "default"}
          onClick={() => setSelectedDate(today)}
        />
        <Chip
          id="select-tomorrow-date-picker-button"
          label={enCommonLabel.tomorrow}
          sx={{margin: 1, padding: 1}}
          color={isTomorrowSelected ? "primary" : "default"}
          onClick={() => setSelectedDate(tomorrow)}
        />
      </Stack>
    </Stack>
  )
}

export default DatePicker;