import {Stack} from "@mui/material";
import ProjectLevelTasks from "./ProjectLevelTasks";
import Statistics from "./Statistics";
import {Project, UserProjectData} from "types/Project";
import {BaseProps} from "screens/BaseProps";
import {useRTDBDocField} from "hooks/index";
import {getDocPath} from "../../utility";
import {CounterFields} from "enums/counterFields";

interface AssetsAndTasksProps extends BaseProps {
  project: Project;
  projectDocPath: string;
}

function AssetsAndTasks(props: AssetsAndTasksProps) {
  const {...rest} = props;

  const userDocPath = getDocPath(props.uid, props.projectDocPath);
  const pltChatEmpty = useRTDBDocField<boolean>(props.projectDocPath, "pltChatEmpty") ?? true;
  const projectLevelTaskHasNewChat = useRTDBDocField<boolean>(userDocPath, "projectLevelTaskHasNewChat") ?? false;
  const projectLevelTasksCount = useRTDBDocField<number>(userDocPath, CounterFields.ProjectLevelTasksCount) ?? 0;
  const projectLevelTasksApprovedCount = useRTDBDocField<number>(userDocPath, CounterFields.ProjectLevelTasksApprovedCount) ?? 0;
  const projectLevelTasksCancelledCount = useRTDBDocField<number>(userDocPath, CounterFields.ProjectLevelTasksCancelledCount) ?? 0;
  const assetsCount = useRTDBDocField<number>(userDocPath, CounterFields.AssetsCount) ?? 0;
  const tasksApprovedCount = useRTDBDocField<number>(userDocPath, CounterFields.TasksApprovedCount) ?? 0;
  const tasksCount = useRTDBDocField<number>(userDocPath, CounterFields.TasksCount) ?? 0;
  const tasksCancelledCount = useRTDBDocField<number>(userDocPath, CounterFields.TasksCancelledCount) ?? 0;
  const requirementsCount = useRTDBDocField<number>(userDocPath, CounterFields.RequirementsCount) ?? 0;
  const requirementsApprovedCount = useRTDBDocField<number>(userDocPath, CounterFields.RequirementsApprovedCount) ?? 0;
  const filesCount = useRTDBDocField<number>(userDocPath, CounterFields.FilesCount) ?? 0;

  const userProject: Partial<UserProjectData> = {
    pltChatEmpty,
    projectLevelTaskHasNewChat,
    projectLevelTasksCount: projectLevelTasksCount - projectLevelTasksCancelledCount,
    projectLevelTasksApprovedCount,
    assetsCount,
    tasksApprovedCount,
    tasksCount: tasksCount - tasksCancelledCount,
    requirementsCount ,
    requirementsApprovedCount,
    filesCount,
  }

  return (
    <>
      <Stack gap={1}>
        <Statistics userProject={userProject as UserProjectData}/>
        <ProjectLevelTasks
          {...rest}
          pltChatEmpty={Boolean(userProject?.pltChatEmpty)}
          projectLevelTaskHasNewChat={Boolean(userProject?.projectLevelTaskHasNewChat)}
          projectLevelTasksCount={userProject?.projectLevelTasksCount}
          projectLevelTasksApprovedCount={userProject?.projectLevelTasksApprovedCount}
        />
      </Stack>
    </>
  );
}


export default AssetsAndTasks;
