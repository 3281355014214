import {IconButton, Stack, Typography} from "@mui/material";
import {enCommonLabel} from "constants/index";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import React from "react";
import {AccessRole} from "enums/accessRole";

export function ProjectTemplateInputLabel(props: {optional?: boolean, onGearClick?: () => void, role: string}) {
  const {optional, role, onGearClick} = props;
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Stack direction="row" gap={0.5}>
        <Typography variant="h5">
          {enCommonLabel.projectTemplate}
        </Typography>
        {optional && <Typography color={theme.palette.neutral.dark}>{enCommonLabel.optionalWithParentheses}</Typography>}
      </Stack>
      {
        (role === AccessRole.Admin || role === AccessRole.Owner) && (
          <IconButton sx={{padding: 0}} onClick={onGearClick}>
            <SystemIcons.Settings stroke={theme.palette.primary.main} width={16} height={16} strokeWidth={3}/>
          </IconButton>
        )
      }
    </Stack>
  )
}