import Item from "components/SidebarMenu/SidebarLinks/Item";
import {Divider, Stack} from "@mui/material";
import theme from "theme/theme";
import {Link, Links} from "components/SidebarMenu/SidebarLinks/Links";
import {useEffect, useState} from "react";
import {useAccess} from "hooks/index";
import {Entity} from "enums/entity";
import {PermissionEntity, PermissionOperationKey} from "types/Permission";
import {defaultSelectedOrg, enSidebar} from "constants/index";
import {SidebarIcons as MenuIcons} from "assets/icons/menu/menu.index";
import {SidebarIcons} from "assets/icons";
import {AccessRole} from "enums/accessRole";

interface SideBarProps {
  routeOrgId: string;
  orgRole?: AccessRole;
  uid: string;
  toggleSidebar: () => void;
}

function SideBar(props: SideBarProps) {
  const {routeOrgId, uid, orgRole, toggleSidebar} = props;
  const [links, setLinks] = useState<Link []>([...Links]);

  const [templateAccess, , updateTemplateIds] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationTemplate,
    ids: {orgId: routeOrgId}
  });

  const [dataTagAccess, , updateDataTagIds] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationDataTag,
    ids: {orgId: routeOrgId}
  });

  useEffect(() => {
    if (routeOrgId === "defaultOrg") return;

    updateTemplateIds({orgId: routeOrgId});
    updateDataTagIds({orgId: routeOrgId});
  }, [routeOrgId]);

  // append datatag links based on datatag access
  useEffect(() => {
    const withDataTagAccess = (orgRole === AccessRole.Owner) || (dataTagAccess && dataTagAccess?.[PermissionOperationKey.View]!);
    let newLinks = links.filter(link => link.Label !== enSidebar.links.dataTags);

    if (!withDataTagAccess) {
      setLinks([...newLinks]);
      return;
    }

    newLinks = [...newLinks, {
      Icon: MenuIcons.DataTags,
      Label: enSidebar.links.dataTags,
      Path: `/dataTags`,
      pathName: "dataTags",
      order: 5,
    }];

    setLinks(newLinks);
  }, [dataTagAccess, orgRole]);

  // append templates links based on template access
  // todo: uncomment this if BE template changes is completed
  /*useEffect(() => {
    const withTemplateAccess = (orgRole === AccessRole.Owner) || (templateAccess && templateAccess?.[PermissionOperationKey.View]!);
    let newLinks = [...links.filter(link => link.Label !== enSidebar.links.templates)];

    if (!withTemplateAccess) {
      setLinks([...newLinks]);
      return;
    }

    newLinks = [...newLinks, {
      Icon: SidebarIcons.Templates,
      Label: enSidebar.links.templates,
      Path: `/templates`,
      pathName: "templates",
      order: 6,
      iconProps: {
        style: {
          left: 23
        }
      }
    }];

    setLinks(newLinks);
  }, [templateAccess, orgRole]);*/

  const sortedLinks = links.sort((a, b) => a.order - b.order);

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: 0,
        width: 256,
        left: 0,
      }}
      gap={1}
    >
      {routeOrgId !== defaultSelectedOrg.id && sortedLinks.map((link, index) => {
        return (
          <>
            <Item
              {...link}
              Path={`/${routeOrgId}/${link.pathName}`}
              toggleSidebar={toggleSidebar}
              key={`sidebarLink_${link.Label.split(" ").join("_")}`}
              selectedBarId={`sidebarLink_${index}`}
            />
            {link.Label === "Timers" &&
                <Divider flexItem sx={{position: "relative", top: 825, left: 10, borderColor: theme.palette.divider}}/>}
          </>
        )
      })}
    </Stack>
  )
}

export default SideBar;
