import {AccessRole} from "enums/accessRole";
import {Divider, Stack, Typography} from "@mui/material";
import React from "react";
import ItemRole from "./ItemRole";

interface PanelItemProps {
  label: string;
  role: Record<AccessRole, boolean>;
  isView: boolean;
  isExpanded: boolean;
  updatePermissions: (role: AccessRole, checked: boolean) => void;
}

function PanelItem(props: PanelItemProps) {
  const {label, role, isExpanded, isView, updatePermissions} = props;

  if(!isExpanded) return null;

  return (
    <>
      <Stack direction={{xs: "column", sm: "column", md: "row"}} flex={1}>
        <Stack width={{xs: "100%", sm: "100%", md: "30%", lg: "50%"}} justifyContent="center">
          <Typography variant="body1" paddingLeft={{lg: "3%"}}>
            {label}
          </Typography>
        </Stack>
        <Stack
          direction={{xs: "column", sm: "column", md: "row"}}
          width={{xs: "100%", sm: "100%", md: "70%", lg: "50%"}}
          justifyContent="space-evenly"
        >
          <ItemRole
            accessRole={AccessRole.User}
            checked={role[AccessRole.User] ?? false}
            isView={isView}
            onChange={(checked) => updatePermissions(AccessRole.User, checked)}
          />
          <ItemRole
            accessRole={AccessRole.PowerUser}
            checked={role[AccessRole.PowerUser] ?? false}
            isView={isView}
            onChange={(checked) => updatePermissions(AccessRole.PowerUser, checked  )}
          />
          <ItemRole
            accessRole={AccessRole.Admin}
            checked={role[AccessRole.Admin] ?? false}
            isView={isView}
            onChange={(checked) => updatePermissions(AccessRole.Admin, checked)}
          />
          <ItemRole
            accessRole={AccessRole.Owner}
            checked={role[AccessRole.Owner] ?? false}
            isView={isView}
            onChange={(checked) => updatePermissions(AccessRole.Owner, checked)}
          />
          <ItemRole
            accessRole={AccessRole.Guest}
            checked={role[AccessRole.Guest] ?? false}
            isView={isView}
            onChange={(checked) => updatePermissions(AccessRole.Guest, checked)}
          />
        </Stack>
      </Stack>
      <Divider orientation="horizontal"/>
    </>
  )
}

export default PanelItem;