import Header from "./Header";
import {BaseProps} from "../BaseProps";
import TimeLogsView from "./TimeLogsView";
import {Content} from "components/index";
import {useEffect} from "react";
import {useNavigate} from "react-router";
import {useOutletContext, useParams} from "react-router-dom";
import {emptyFunction} from "constants/index";
import {OutletContextType} from "components/RestrictedPage";
import InProgress from "components/InProgress";

function TimeLogs(props: BaseProps) {
  const {selectedOrg, uid, setSelectedOrgId = emptyFunction} = props;
  const {isLoading} = useOutletContext<OutletContextType>();

  const navigate = useNavigate();
  const {orgId} = useParams();

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  if (isLoading) return (
    <Content>
      <InProgress/>
    </Content>
  );

  return (
    <>
      <Header uid={uid}/>
      <Content>
        <TimeLogsView/>
      </Content>
    </>
  )
}

export default TimeLogs