import SAPMenuOptions from "./SAPMenuOptions";
import React from "react";
import MenuOptions from "./MenuOptions";
import {AccessType} from "types/Permission";

interface MenuProps {
  uid: string;
  fromSAP?: boolean;
  anchorEl: HTMLElement | null;
  orgId: string;
  open: boolean;
  access: AccessType | null;
  projectAccess: AccessType | null;
  closeMenu: (e: React.MouseEvent<HTMLElement>) => void;
  editMenuClick: (e: React.MouseEvent<HTMLElement>) => void;
  deleteMenuClick: (e: React.MouseEvent<HTMLElement>) => void;
}
function MoreOptionsMenu(props: MenuProps) {
  const {fromSAP = false, ...rest} = props;
  if(fromSAP)
    return <SAPMenuOptions {...rest} />;

  return <MenuOptions {...rest} />;
}

export default MoreOptionsMenu;