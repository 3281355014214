import {BaseProps} from "../BaseProps";
import {Template} from "types/Template";
import React, {useEffect, useState} from "react";
import {emptyFunction, enTemplateLabel, firebaseMaxArrayQuerySize} from "constants/index";
import TemplateCard from "./TemplateCard";
import EmptySearchResults from "components/EmptySearchResults";
import {ActionType, Entity, ProcessType, Severity} from "enums/index";
import ManageTemplateDrawer from "components/Drawers/ManageTemplateDrawer";
import {useComponentToggler, useIfAnyDocIsProcessingInAnyCollection} from "hooks/index";
import ConfirmDialog from "components/ConfirmDialog";
import {doc, DocumentReference, getDocs, query, where} from "firebase/firestore";
import {convertToArrayOfArrays, submitForm, templatesPath} from "../utility";
import {useParams} from "react-router-dom";
import BulkActions from "./BulkActions";
import {Stack} from "@mui/material";
import {AccessType} from "types/Permission";
import TemplateCardMenu from "./TemplateCardMenu";

interface TemplatesListProps extends BaseProps {
  templates: Template [];
  fromAlgolia: boolean;
  access: AccessType;
  setAlgoliaTemplates: (templates: Template[] | null) => void;
}

function TemplatesList(props: TemplatesListProps) {
  const {templates = [], access, fromAlgolia, toastProps, setAlgoliaTemplates} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  const {orgId} = useParams();
  const collectionRef = templatesPath(orgId!);

  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
  const [isDrawerOpen, {open: openDrawer, close: closeDrawer}] = useComponentToggler(false);

  const [checkedTemplates, setCheckedTemplates] = useState<string []>([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [references, setReferences] = useState<DocumentReference[]>([]);
  const [processing, startProcessing, errorDocs] = useIfAnyDocIsProcessingInAnyCollection({
    references,
    toastProps: toastProps!,
    processType: ProcessType.Delete
  });

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [targetId, setTargetId] = useState<string | null>(null);
  const [targetRef, setTargetRef] = useState<Element | null>(null);
  const [contextMenuPos, setContextMenuPos] = useState<{ top: number; left: number }>({top: 0, left: 0});

  // open manage template drawer once selected template has been set
  useEffect(() => {
    if (!setSelectedTemplate) return;

    openDrawer();
  }, [selectedTemplate]);

  //
  useEffect(() => {
    if (!processing && isDeleting) {
      setIsDeleting(false);
      setIsDeleteDialogOpen(false);

      setCheckedTemplates([]);
      setReferences([]);

      if (fromAlgolia) {
        setAlgoliaTemplates(templates.filter(template =>
          !checkedTemplates.includes(template["@id"]!)
          && !errorDocs.has(template["@id"]!)
        ));
      }

      if (errorDocs.size === 0) {
        setToastSeverity(Severity.Success);
        setToastMessage(enTemplateLabel.deleteSuccess);
        setIsToastOpen(true);
      }
    }
  }, [processing, isDeleting]);

  function closeTemplateDrawer() {
    closeDrawer();
    setSelectedTemplate(null);
  }

  async function deleteSelected() {
    setIsDeleting(true);

    // group selected by 10, just in case user selected more than 10 templates on bulk actions
    const groupedTemplates = convertToArrayOfArrays([...checkedTemplates], firebaseMaxArrayQuerySize);

    let refs: DocumentReference [] = [];
    await Promise.all(
      groupedTemplates.map(async assetGroup => {
        const templatesToDeleteQuery = query(
          collectionRef,
          where("id", "in", assetGroup)
        );

        const templatesToDelete = await getDocs(templatesToDeleteQuery);
        return await Promise.all(
          templatesToDelete.docs.map(async (doc) => {
            refs = [...refs, doc.ref];
            return await submitForm(doc.ref, ActionType.Delete, emptyFunction);
          })
        );
      })
    );
    setReferences(refs);
    startProcessing();
  }

  function startSingleDelete(templateId: string) {
    setCheckedTemplates([templateId]);
    setIsDeleteDialogOpen(true);
  }

  function onMenuOpen(e: Element | undefined, id: string, anchorPosition?: { left: number, top: number }) {
    if(!e) return;
    setTargetId(id);
    setTargetRef(e);
    setIsMenuOpen(true);
    setContextMenuPos(anchorPosition ?? {left: 0, top: 0});
  }

  function onCloseMenu() {
    setIsMenuOpen(false);
    setTargetRef(null);
  }

  if (fromAlgolia && templates.length === 0)
    return <EmptySearchResults entity={Entity.Templates}/>;

  return (
    <Stack gap={1} mt={1}>
      {selectedTemplate && (
        <ManageTemplateDrawer
          uid={props.uid}
          template={selectedTemplate}
          isOpen={isDrawerOpen}
          closeDrawer={closeTemplateDrawer}
          toastProps={toastProps!}
        />
      )}
      {isMenuOpen && Boolean(targetRef) && (
        <TemplateCardMenu
          uid={props.uid}
          isOpen={isMenuOpen}
          docRef={doc(templatesPath(orgId!), targetId!)}
          anchorEl={targetRef!}
          closeMenu={onCloseMenu}
          access={access}
          toastProps={toastProps!}
          anchorPosition={contextMenuPos}
        />
      )}
      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        title={enTemplateLabel.deleteDialogTitle(checkedTemplates.length)}
        text={enTemplateLabel.deleteConfirmationText}
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleConfirm={deleteSelected}
      />
      <BulkActions
        access={access}
        templates={templates}
        checkedTemplates={checkedTemplates}
        setCheckedTemplates={(templates) => setCheckedTemplates(templates)}
        deleteSelectedTemplates={() => setIsDeleteDialogOpen(true)}
      />
      <Stack gap={1}>
        {templates.map((template) =>
          <TemplateCard
            key={`template-card-${template["@id"]!}`}
            template={template}
            isSelected={false}
            checkedTemplates={checkedTemplates}
            setCheckedTemplates={(templates) => setCheckedTemplates(templates)}
            setSelectedTemplate={(template) => setSelectedTemplate(template)}
            deleteTemplate={startSingleDelete}
            onMenuOpen={onMenuOpen}
            {...props}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default TemplatesList;
