import {Box, Pagination} from "@mui/material";
import React from "react";

const PAGE_ITEM_COUNT = 10;

export interface TablePaginationProps {
  assetCount: number;
  assetTypeIdx: string;
  listFromAlgolia: boolean;
  setCurrentPage: (page: number) => void;
}

function TablePagination(props: TablePaginationProps) {
  const {assetCount, assetTypeIdx, listFromAlgolia} = props;

  if (assetCount <= 10 || listFromAlgolia)
    return null;

  const paginationCount = Math.ceil(assetCount / PAGE_ITEM_COUNT);

  return (
    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" marginTop={2}>
      <Pagination
        id={`${assetTypeIdx}-pagination`}
        count={paginationCount}
        size="small"
        variant="outlined"
        shape="rounded"
        onChange={
        (e: React.ChangeEvent<unknown>, page: number) => props.setCurrentPage(page)}
      />
    </Box>
  );
}

export default TablePagination;