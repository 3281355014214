import {Organization} from "types/Organization";
import {Divider, Stack, Typography} from "@mui/material";
import React from "react";

interface OrgsListProps {
  organizations: Partial<Organization>[];
}

function OrgsList(props: OrgsListProps) {
  const {organizations} = props;

  if(organizations.length === 0) return null;

  const trimmedOrgs = organizations.slice(0, 2);

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {trimmedOrgs.map((org, index) => (
        <Stack direction="row" alignItems="center" gap={1}>
          {index > 0 && (<Divider sx={{height: 15}} orientation="vertical"/>)}
          <Typography variant="body" color="text.secondary">{org.name}</Typography>
        </Stack>
      ))}
      {organizations.length > 2 && (
        <Stack direction="row" alignItems="center" gap={1}>
          <Divider sx={{height: 15}} orientation="vertical"/>
          <Typography variant="body" color="text.secondary">{organizations.length - 2}</Typography>
        </Stack>
      )}
    </Stack>
  )
}

export default OrgsList;