import {BaseProps} from "screens/BaseProps";
import Head from "./Head";
import {organizationAssetPath, organizationAssetsProjectsPath} from "../utility";
import ProjectsView from "components/ProjectsView";
import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router";
import {Content, InProgress} from "components/index";
import {Entity} from "enums/index";
import {useAccess, useDocument} from "hooks/index";
import {Asset} from "types/Asset";
import Header from "./Header";
import {emptyFunction} from "constants/index";
import {PermissionEntity, PermissionOperationKey} from "types/Permission";
import {Stack} from "@mui/material";
import {useOutletContext} from "react-router-dom";
import {OutletContextType} from "components/RestrictedPage";

function OrgAssetsOverview(props: BaseProps) {
  const {toastProps, selectedOrg, uid, setSelectedOrgId = emptyFunction} = props;
  const {isLoading} = useOutletContext<OutletContextType>();

  const {orgId, assetId} = useParams();

  const assetRef = organizationAssetPath(orgId!, assetId!);
  const [asset] = useDocument<Asset>(assetRef);
  const [access] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationAsset,
    ids: {orgId, assetId},
  })

  const navigate = useNavigate();

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  if (!asset || !access || isLoading)
    return <Content><InProgress/></Content>;

  return (
    <>
      <Head
        asset={asset}
        toastProps={toastProps}
        access={access}
        uid={uid}
      />
      <Content>
        <Stack maxWidth="90vw" minHeight="90vh">
          {asset && (
            <Header
              uid={uid}
              asset={asset}
              toastProps={toastProps}
              assetRef={assetRef}
              canEdit={Boolean(access?.[PermissionOperationKey.Update])}
            />
          )}
          <ProjectsView
            uid={uid}
            projectsRefPath={organizationAssetsProjectsPath(orgId!, assetId!).path}
            parentEntity={Entity.Asset}
            toastProps={toastProps}
          />
        </Stack>
      </Content>
    </>

  )
}

export default OrgAssetsOverview;