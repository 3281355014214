import {Stack, Typography} from "@mui/material";
import {enManageTeamCategories, enManageTeamCategoriesButton} from "constants/index";
import React from "react";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {PrimaryButton} from "components/Button";
import {toastProps} from "../BaseProps";

interface HeadProps {
  teamCategoriesCount: number;
  categoryAccess: AccessType | null;
  onAddCategoryClick: () => void;
  toastProps?: toastProps;
}

function Head(props: HeadProps) {
  const {categoryAccess, teamCategoriesCount, onAddCategoryClick} = props;

  return (
    <Stack width={1} mb={1} mt={1} direction="row" justifyContent="space-between">
      <Typography variant="h1" id="task-name">
        {enManageTeamCategories.title + " "}
        <Typography
          id="projects-count"
          variant="body"
          sx={(theme) => ({
            display: "inline",
            color: theme.palette.text.secondary,
          })}
        >
          {(teamCategoriesCount) > 0 ? teamCategoriesCount - 1 : 0}
        </Typography>
      </Typography>
      <PrimaryButton
        id="create-new-team-category"
        onClickFcn={onAddCategoryClick}
        disabled={Boolean(!categoryAccess?.[PermissionOperationKey.Create])}
        label={enManageTeamCategoriesButton.create}
      />
    </Stack>
  )
}

export default Head;
