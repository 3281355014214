
export const withPreviewFiles = [
  // IMAGE
  "bmp",
  "gif",
  "ico",
  "jpe",
  "jpeg",
  "jpg",
  "png",
  "jfif",
  "webp",

  // VIDEO
  "3gp",
  "m4v",
  "mkv",
  "mov",
  "webm",
  "mp4",
];
