import {Permission, PermissionEntity, PermissionOperationKey} from "types/Permission";
import {AccessRole} from "enums/accessRole";

interface updatePermissionValueProps {
  localPermissions: Permission[];
  id: PermissionEntity;
  operation: PermissionOperationKey;
  role: AccessRole;
  checked: boolean;
}

/**
 * Updates localPermission values
 * @param props
 */
export function updatePermissionValue(props: updatePermissionValueProps) {
  const { localPermissions, id, operation, role, checked } = props;

  const newLocalPermissions = [...localPermissions];
  const indexToUpdate = newLocalPermissions.findIndex((permission) => permission.id === id);

  if (indexToUpdate === -1) return newLocalPermissions;

  const updatedPermission = { ...newLocalPermissions[indexToUpdate] };

  const operationObj = { ...updatedPermission[operation] };
  operationObj[role] = checked;

  // start: general update logic
  if (operation === PermissionOperationKey.View && !checked) {
    updatedPermission[operation] = operationObj;
    updatedPermission[PermissionOperationKey.Create][role] = false;

    if (id !== PermissionEntity.Asset) {
      updatedPermission[PermissionOperationKey.Update][role] = false;
      updatedPermission[PermissionOperationKey.Delete][role] = false;
    }
  } else {
    updatedPermission[operation] = operationObj;
    updatedPermission[PermissionOperationKey.View][role] = true;
  }
  // end: general update logic

  newLocalPermissions[indexToUpdate] = updatedPermission;

  // start: entity specific update logic
  switch (id) {
    case PermissionEntity.Project:
      // If Project.Update is false, uncheck ProjectImport.Create field row for the same role.
      if (operation === PermissionOperationKey.Update && !checked)
        updateSpecificPermission(PermissionEntity.ProjectImport, [PermissionOperationKey.Create], role, false);

      // Unchecking the Project.View should uncheck the ProjectExport.Create field row for the same role.
      if (operation === PermissionOperationKey.View && !checked)
        updateSpecificPermission(PermissionEntity.ProjectExport, [PermissionOperationKey.Create], role, false);

      // Checking the Project.OverrideStatus should check Project.ChangeStatus for the same role.
      if (operation === PermissionOperationKey.OverrideStatus && checked)
        updateSpecificPermission(PermissionEntity.Project, [PermissionOperationKey.ChangeStatus], role, true);
      break;
    case PermissionEntity.ProjectImport:
      // user checks the ProjectImport.Create, app should automatically check Project.Update and Project.View for the same role
      if (operation === PermissionOperationKey.Create && checked)
        updateSpecificPermission(PermissionEntity.Project, [PermissionOperationKey.Update, PermissionOperationKey.View], role, true);
      break;
    case PermissionEntity.ProjectExport:
      //  clicking the ProjectExport.Create should check Project.View for the same role
      if (operation === PermissionOperationKey.Create && checked)
        updateSpecificPermission(PermissionEntity.Project, [PermissionOperationKey.View], role, true);
      break;
    case PermissionEntity.MilestoneFilesDownload:
      // update the MilestoneFilesDownload.Create permission
      if (operation === PermissionOperationKey.Create && checked)
        updateSpecificPermission(PermissionEntity.Milestone, [PermissionOperationKey.View], role, true);
      break;
    case PermissionEntity.Milestone:
      // update the MilestoneFilesDownload.View permission if Milestone.View is unchecked
      if (operation === PermissionOperationKey.View && !checked)
        updateSpecificPermission(PermissionEntity.MilestoneFilesDownload, [PermissionOperationKey.Create], role, false);
      break;
    case PermissionEntity.Asset:
      // Also uncheck ProjectAssetImport.Create
      if (operation === PermissionOperationKey.Update && !checked)
        updateSpecificPermission(PermissionEntity.ProjectAssetImport, [PermissionOperationKey.Create], role, false);

      // If Asset.View is unchecked, uncheck asset.update for the same role
      if (operation === PermissionOperationKey.View && !checked) {
        updateSpecificPermission(PermissionEntity.Asset, [PermissionOperationKey.Update, PermissionOperationKey.View], role, false);

        // Unchecking Asset.View should uncheck ProjectAssetExport.Create
        updateSpecificPermission(PermissionEntity.ProjectAssetExport, [PermissionOperationKey.Create], role, false);

        // Unchecking Asset.View should uncheck AssetFilesDownload.Create for the same role
        updateSpecificPermission(PermissionEntity.AssetFilesDownload, [PermissionOperationKey.Create], role, false);
      }

      // If Asset.OverrideStatus is checked, check Asset.ChangeStatus for the same role
      if (operation === PermissionOperationKey.OverrideStatus && checked)
        updateSpecificPermission(PermissionEntity.Asset, [PermissionOperationKey.ChangeStatus], role, true);
      break;
    case PermissionEntity.ProjectAssetImport:
    case PermissionEntity.OrganizationTemplateImport:
      // auto check Asset.Update and Asset.View
      if (operation === PermissionOperationKey.Create && checked)
        updateSpecificPermission(PermissionEntity.OrganizationTemplate, [PermissionOperationKey.Update, PermissionOperationKey.View], role, true);
      break;
    case PermissionEntity.FormExport:
      // If Form.View is checked, checked Form.View for the same role
      if (operation === PermissionOperationKey.Create && checked)
        updateSpecificPermission(PermissionEntity.Form, [PermissionOperationKey.View], role, true);
      break;
    case PermissionEntity.FileRequirement:
      // If view is unchecked, uncheck all create, update,delete, changestatus, overridestatus, download
      if (operation === PermissionOperationKey.View && !checked) {
        updateSpecificPermission(PermissionEntity.FileRequirement, [PermissionOperationKey.Create, PermissionOperationKey.Update, PermissionOperationKey.Delete, PermissionOperationKey.ChangeStatus, PermissionOperationKey.OverrideStatus], role, false);
        updateSpecificPermission(PermissionEntity.FileRequirementFilesDownload, [PermissionOperationKey.Create], role, false);
      }
      // If create|update|delete|changestatus|overridestatus|download is checked, check View
      if ([PermissionOperationKey.Create, PermissionOperationKey.Update, PermissionOperationKey.Delete, PermissionOperationKey.ChangeStatus].includes(operation) && checked)
        updateSpecificPermission(PermissionEntity.FileRequirement, [PermissionOperationKey.View], role, true);
      // If overridestatus is checked, check changestatus
      if(operation === PermissionOperationKey.OverrideStatus && checked)
        updateSpecificPermission(PermissionEntity.FileRequirement, [PermissionOperationKey.View, PermissionOperationKey.ChangeStatus], role, true);
      break;
    case PermissionEntity.FileRequirementFilesDownload:
      // If create is checked, check View
      if (operation === PermissionOperationKey.Create && checked)
        updateSpecificPermission(PermissionEntity.FileRequirement, [PermissionOperationKey.View], role, true);
      break;
    case PermissionEntity.Form:
      // If Form.View is unchecked, unchecked FormExport.Create for the same role
      if (operation === PermissionOperationKey.View && !checked)
        updateSpecificPermission(PermissionEntity.FormExport, [PermissionOperationKey.Create], role, false);
      break;
    case PermissionEntity.FormRequirement:
      // If view is unchecked, uncheck all create, update,delete, changestatus, overridestatus
      if (operation === PermissionOperationKey.View && !checked)
        updateSpecificPermission(PermissionEntity.FormRequirement, [PermissionOperationKey.Create, PermissionOperationKey.Update, PermissionOperationKey.Delete, PermissionOperationKey.ChangeStatus, PermissionOperationKey.OverrideStatus], role, false);
      // If create|update|delete|changestatus is checked, check View
      if ([PermissionOperationKey.Create, PermissionOperationKey.Update, PermissionOperationKey.Delete, PermissionOperationKey.ChangeStatus].includes(operation) && checked)
        updateSpecificPermission(PermissionEntity.FormRequirement, [PermissionOperationKey.View], role, true);
      // If overridestatus is checked, check changestatus
      if(operation === PermissionOperationKey.OverrideStatus && checked)
        updateSpecificPermission(PermissionEntity.FormRequirement, [PermissionOperationKey.View, PermissionOperationKey.ChangeStatus], role, true);
      break;
    case PermissionEntity.OrganizationTemplateExport:
      // If OrganizationTemplateExport.Create is checked, check the OrganizationTemplate.View
      if (operation === PermissionOperationKey.Create && checked)
        updateSpecificPermission(PermissionEntity.OrganizationTemplate, [PermissionOperationKey.View], role, true);
      break;
    case PermissionEntity.OrganizationTemplate:
      if (operation === PermissionOperationKey.View && !checked) {
        // If OrganizationTemplate.View is unchecked, uncheck OrganizationTemplateExport.Create for the same role
        updateSpecificPermission(PermissionEntity.OrganizationTemplateExport, [PermissionOperationKey.Create], role, false);
        // If OrganizationTemplate.View is unchecked, uncheck OrganizationTemplate.update and OrganizationTemplateImport.Create
        updateSpecificPermission(PermissionEntity.OrganizationTemplateImport, [PermissionOperationKey.Create], role, false);
        updateSpecificPermission(PermissionEntity.OrganizationTemplate, [PermissionOperationKey.Update], role, false);
      }

      // If OrganizationTemplate.Update is false, uncheck OrganizationTemplateImport.Create row for the same role
      if (operation === PermissionOperationKey.Update && !checked)
        updateSpecificPermission(PermissionEntity.OrganizationTemplateImport, [PermissionOperationKey.Create], role, false);
      break;
    case PermissionEntity.TaskFilesDownload:
      // If TaskFilesDownload.Create is checked, auto check Task.View
      if (operation === PermissionOperationKey.Create && checked)
        updateSpecificPermission(PermissionEntity.Task, [PermissionOperationKey.View], role, true);
      break;
    case PermissionEntity.Task:
      // Task.View is false, uncheck TaskFilesDownload.Create row as well for the same role
      if (operation === PermissionOperationKey.View && !checked)
        updateSpecificPermission(PermissionEntity.TaskFilesDownload, [PermissionOperationKey.Create], role, false);

      // Checking Task.Override status should automatically check the Task.ChangeStatus for the same role
      if (operation === PermissionOperationKey.OverrideStatus && checked)
        updateSpecificPermission(PermissionEntity.Task, [PermissionOperationKey.ChangeStatus], role, true);
      break;
    case PermissionEntity.AssetFilesDownload:
    case PermissionEntity.ProjectAssetExport:
      // ProjectAssetExport.Create row is checked, update the Asset.View permission to true
      if (operation === PermissionOperationKey.Create && checked)
        updateSpecificPermission(PermissionEntity.Asset, [PermissionOperationKey.View], role, true);
      break;
  }
  // end: entity specific update logic

  return newLocalPermissions;

  function updateSpecificPermission(updateId: PermissionEntity, updateOperation: PermissionOperationKey[], updateRole: AccessRole, updateChecked: boolean) {
    const indexToUpdate = newLocalPermissions.findIndex((permission) => permission.id === updateId);
    if (indexToUpdate === -1) return;

    const updatedPermission = {...newLocalPermissions[indexToUpdate]};

    updateOperation.forEach((operation) => {
      const operationObj = {...updatedPermission[operation]};
      operationObj[updateRole] = updateChecked;
      updatedPermission[operation] = operationObj;
    });

    newLocalPermissions[indexToUpdate] = updatedPermission;
  }
}