import {AccessType, PermissionOperationKey} from "types/Permission";
import {SystemIcons} from "assets/icons/system/system.index";
import {emptyFunction, enAssetLabel, enCommonButton} from "constants/index";
import ActionOption from "components/ActionOption";
import React from "react";
import {MenuCard} from "components/index";
import theme from "theme/theme";

interface AssetItemMenuProps{
  isOpen: boolean;
  closeMenu: () => void;
  anchorEl?: Element | null | undefined;
  access: AccessType | null;
  openEditAsset: () => void;
  deleteAsset: () => void;
  anchorPosition?: { left: number, top: number }
}
function AssetItemMenu(props: AssetItemMenuProps) {
  const {isOpen, closeMenu, anchorEl, openEditAsset, deleteAsset, access, anchorPosition} = props;

  if (!isOpen || !anchorEl) return null;

  return (
    <MenuCard open={isOpen} anchorPosition={anchorPosition} anchorEl={anchorEl} closeMenu={closeMenu}>
      <ActionOption
        id="edit-asset-menu-button"
        Icon={<SystemIcons.Edit height={16} width={16}/>}
        label={enAssetLabel.edit}
        onClick={access?.[PermissionOperationKey.Create] ? openEditAsset : emptyFunction}
        disabled={!access?.[PermissionOperationKey.Create]}
      />
      <ActionOption
        id="delete-asset-menu-button"
        Icon={<SystemIcons.DeleteOutlined height={16} width={16}/>}
        label={enCommonButton.delete}
        labelSx={{color: theme.palette.error.main}}
        onClick={access?.[PermissionOperationKey.Delete] ? deleteAsset : emptyFunction}
        disabled={!access?.[PermissionOperationKey.Delete]}
      />
    </MenuCard>
  )
}

export default AssetItemMenu;