import { taskTimelineScheduleStatus as TimelineStatus} from "enums/taskTimelineScheduleStatus";
import {en} from "language/en";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {StatusIcons} from "assets/icons/status/status.index";

export type StatusItem = {
  title: string;
  icon: any;
  color: string;
}

export type TaskTimelineStatusProps = Record<string, StatusItem>;

const enTimeline = en.screen.Timeline.status;

export const TaskTimelineStatus: TaskTimelineStatusProps = {
  [TimelineStatus.NotScheduled]: {
    title: enTimeline.notScheduled,
    icon: SystemIcons.DownloadTimeData,
    color: theme.palette.secondary.main,
  },
  [TimelineStatus.Scheduled]: {
    title: enTimeline.scheduled,
    icon: SystemIcons.DownloadTimeData,
    color: theme.palette.secondary.main,
  },
  [TimelineStatus.InProgress]: {
    title: enTimeline.inProgress,
    icon: StatusIcons.InProgress,
    color: theme.palette.secondary.main,
  },
  [TimelineStatus.Submitted]: {
    title: enTimeline.submitted,
    icon: StatusIcons.Submitted,
    color: theme.palette.primary.main
  },
  [TimelineStatus.Approved]: {
    title: enTimeline.approved,
    icon: StatusIcons.Approved,
    color: theme.palette.success.main
  },
  [TimelineStatus.Rejected]: {
    title: enTimeline.rejected,
    icon: StatusIcons.Rejected,
    color: theme.palette.error.main
  },
  [TimelineStatus.Cancelled]: {
    title: enTimeline.cancelled,
    icon: StatusIcons.Cancelled,
    color: theme.palette.neutral.dark,
  }
}