import {enMemberLabel} from "constants/index";
import {SelectWithSearch, ValidationMessage} from "components/index";
import React, {Dispatch, useEffect, useState} from "react";
import {SelectItem} from "components/inputs/SelectWithSearch";
import {useCollection} from "hooks/index";
import {Organization} from "types/Organization";
import {organizationsPath} from "screens/utility";
import {Stack} from "@mui/material";

interface OrganizationSelectProps {
  isEmailExists: boolean;
  selectedOrg: string | undefined;
  setSelectedOrg: Dispatch<string>;
  uid: string;
  isAdminPanel: boolean;
}

const WITHOUT_ORGANIZATION = {
  name: enMemberLabel.withoutOrganization,
  value: "withoutOrganization",
  displayItem: enMemberLabel.withoutOrganization,
  searchable: enMemberLabel.withoutOrganization,
}

function OrganizationSelect(props: OrganizationSelectProps) {
  const {isEmailExists, selectedOrg, setSelectedOrg, isAdminPanel} = props;
  // todo: use uid to filter the displayed organizations
  const [organizations] = useCollection<Organization>(null, organizationsPath());
  const [defaultValue, setDefaultValue] = useState<string | undefined>(WITHOUT_ORGANIZATION.value);
  const [orgsList, setOrgsList] = useState<SelectItem[]>([]);

  // Set default value to "Without Organization" if user has no organization
  useEffect(() => {
    if (!organizations) {
      return setOrgsList([WITHOUT_ORGANIZATION]);
    }

    const orgItem = organizations.map(org => {
      return {
        value: org["@id"]!,
        displayItem: org.name,
        searchable: org.name,
      }
    });

    setOrgsList([WITHOUT_ORGANIZATION, ...orgItem]);
  }, [organizations]);

  // If the email already exist, disable without organization option and set default value to undefined
  useEffect(() => {
    const filteredList = orgsList.filter(org => org.value !== WITHOUT_ORGANIZATION.value);
    if (isEmailExists) {
      setOrgsList(filteredList);
      setDefaultValue(undefined);
      return;
    }

    setOrgsList([WITHOUT_ORGANIZATION, ...filteredList]);
    setDefaultValue(WITHOUT_ORGANIZATION.value);
  }, [isEmailExists]);

  if (!isAdminPanel) return null;

  return (
    <Stack sx={{mb: 3}} direction="column">
      <SelectWithSearch
        label={enMemberLabel.organization}
        items={orgsList}
        handleSelectCallback={setSelectedOrg}
        defaultValue={defaultValue}
      />
      <ValidationMessage
        validationMessage={selectedOrg === undefined ? enMemberLabel.selectAnOrganization : ""}
      />
    </Stack>
  )
}

export default OrganizationSelect;