export const teamsAndPeople = {
  title: "Teams & People",
  label: {
    teams: "Teams",
    people: "People",
    invites: "Invites",
    searchTeams: "Search Team...",
    searchInvites: "Search Invite...",
    searchCategory: "Search Category...",
    organizationRole: "Organization Role",
    teamCategory: (optional?:boolean) => `Team Category${optional ? ` (optional)` : ""}`,
    teamMembers: "Team Members",
    organizationRoleInfo: "Role that will be applied for the team",
    teamCreationSuccess: (name: string) => `Team ${name} was created successfully.`,
    teamUpdateSuccess: (name: string) => `Team ${name} was updated successfully.`,
    role: "Role",
    members: "Members",
    actions: "Actions",
    deleteDialogTitle: (teamName: string) => `Are you sure you want to delete team ${teamName}?`,
    deleteSuccess: "Team/s deleted successfully",
    nPeopleSelected: (n: number) => `${n} People Selected`,
    addTeamMembers: "Add Team Members",
    searchTeamOrPerson: "Search Team or Person...",
    editTeam: "Edit Team",
    emptyInviteDescription: "When you send an invite to other users, they will show up here.",
    nameRequired: "Team Name is Required.",
    teamMembersList: (name: string) => `${name} Team Members`,
    createFirst: "Create your first team",
    emptyTitle: "This category is empty",
    deleteXSuccess: (name: string) => `Team ${name} was deleted successfully.`,
    teamNameAlreadyExists: "Team name already exists",
  },
  button: {
    add: "Add Member",
    manageTeamCategory: "Manage Team Category",
    manageTeamMembers: "Manage Team Members",
    addTeam: "Add Team",
    inviteUser: "Invite User"
  },
  toastMessages: {
    bulkDeleteSuccess: "Successfully deleted selected teams"
  }
}