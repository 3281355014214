import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../firebase";
import {useEffect, useState} from "react";
import {AccessListUser} from "types/AccessListUser";
import {AccessUserType} from "enums/accessUserType";
import {Team} from "types/Team";
import {organizationMembersPath, teamsPath} from "screens/utility";
import {Member} from "types/Member";

function useTemplateAccessList(colRef: string | null) {
  const [currentColRef, setCurrentColRef] = useState<string | null>(colRef);
  const [accessList, setAccessList] = useState<AccessListUser[] | null>(null);
  const [accessListRefPath, setAccessListRef] = useState<string | null>(null);

  const [teams, setTeams] = useState<AccessListUser[] | undefined>(undefined);
  const [members, setMembers] = useState<AccessListUser[] | undefined>(undefined);
  const [mergedAccessList, setMergedAccessList] = useState<AccessListUser[] | null>(null);

  useEffect(() => {
    if (accessList === null || teams === undefined || members === undefined) {
      setMergedAccessList(null);
      return;
    }

    const mergedAccess = accessList.map(access => {
      const collectionToFind = (access.accessUserType === AccessUserType.Team ? teams : members) as AccessListUser[];

      if (!collectionToFind) {
        // force add accessUserType
        return {...access, c: AccessUserType.Person}
      }

      const existingAccess = collectionToFind.find(doc => doc["@id"] === access["@id"])

      if (existingAccess)
        return {...access, ...existingAccess} as AccessListUser

      return access
    });

    setMergedAccessList([...mergedAccess]);
  }, [accessList, teams, members])

  // generate accessListRef
  useEffect(() => {
    generateAccessListRef();
    fetchTeams();
    fetchMembers();
  }, [currentColRef]);

  async function generateAccessListRef() {
    if (currentColRef === null) return;

    let data = null;
    let runningPath = currentColRef;
    try {
      while (!data) {
        const accessList = await getDocs(collection(db, runningPath));
        if (accessList.empty) {
          const lastAccessList = getParentAccessList(runningPath);
          if (lastAccessList === undefined) {
            data = true;
            setAccessList(null);
            setAccessListRef(null);
            return;
          }

          runningPath = lastAccessList;

        } else {
          data = true;
          setAccessList(accessList.docs.map(doc => doc.data() as AccessListUser));
          setAccessListRef(runningPath);
        }
      }
    } catch (e) {
      console.error('Error in retrieving template access ', e, runningPath);
    }
  }

  async function fetchTeams() {
    if (currentColRef === null) return;

    const orgId = (currentColRef || "").split('/')[1] || undefined;

    if (!orgId) return;

    const teamsQuery = query(teamsPath(orgId));
    const teamsDocs = await getDocs(teamsQuery);
    const fetchedTeams = teamsDocs.docs.map(team => team.data() as Team);
    if (fetchedTeams.length === 0) {
      setTeams([]);
      return;
    }

    const formattedTeams = fetchedTeams.map(team => {
      return {
        ...team,
        accessUserType: AccessUserType.Team
      } as unknown as AccessListUser
    });

    setTeams([...formattedTeams]);
  }

  async function fetchMembers() {
    if (currentColRef === null) return;

    const orgId = (currentColRef || "").split('/')[1] || undefined;

    if (!orgId) return;

    const membersQuery = query(organizationMembersPath(orgId), where("active", "==", true))
    const membersDocs = await getDocs(membersQuery);
    const fetchedMembers = membersDocs.docs.map(member => member.data() as Member);
    if (fetchedMembers.length === 0) {
      setMembers([]);
      return;
    }

    const formattedMembers = fetchedMembers.map(member => {
      return {
        ...member,
        accessUserType: AccessUserType.Person
      } as unknown as AccessListUser
    });

    setMembers([...formattedMembers]);
  }

  function updateColRef(newColRef: string | null) {
    setAccessListRef(newColRef === null ? null : newColRef);
    setCurrentColRef(newColRef);
  }

  return {accessList : mergedAccessList, updateColRef, accessListRefPath}
}

export function getParentAccessList(colRef: string) {
  const splitted = colRef.split("/").slice(0, -3);

  if (splitted.length <= 1)
    return undefined;

  if (splitted.length === 2)
    return splitted.join("/") + "/members";

  return splitted.join("/") + "/accessList"
}

export default useTemplateAccessList