import {DrawerProps, SimpleFormDrawer} from "components/index";
import SystemIndex from "assets/icons/system/system.index";
import {OptionalType} from "types/OptionalType";
import {en} from "language/en";
import {useCollection} from "hooks/index";
import {ActivityItem} from "types/ActivityFeedTypes";
import {CollectionReference, doc, orderBy} from "firebase/firestore";
import {ActionType, ActivityType, Entity} from "enums/index";
import {useEffect} from "react";
import {submitForm} from "screens/utility";
import {emptyFunction} from "constants/index";
import {db} from "../../firebase";
import VirtualList from "components/VirtualList";
import StatusChangeItem from "components/ActivityFeed/ActivityItems/StatusChangeItem";
import AssignItem from "components/ActivityFeed/ActivityItems/AssignItem";
import NewAccessListMemberItem from "components/ActivityFeed/ActivityItems/NewAccessListMemberItem";
import ScheduledItem from "components/ActivityFeed/ActivityItems/ScheduledItem";
import UnassignedItem from "components/ActivityFeed/ActivityItems/UnassignedItem";
import CreatedItem from "components/ActivityFeed/ActivityItems/CreatedItem";
import OverdueItem from "components/ActivityFeed/ActivityItems/OverdueItem";
import DueSoonItem from "components/ActivityFeed/ActivityItems/DueSoonItem";
import RemovedAccessListMemberItem
  from "components/ActivityFeed/ActivityItems/RemovedAccessListMemberItem";
import FileStatusChangeItem from "components/ActivityFeed/ActivityItems/FileStatusChangeItem";
import UpdatedItem from "components/ActivityFeed/ActivityItems/UpdatedItem";
import AnsweredItem from "components/ActivityFeed/ActivityItems/AnsweredItem";
import DeletedItem from "components/ActivityFeed/ActivityItems/DeletedItem";

type ActivityFeedProps = OptionalType<DrawerProps, "icon" | "isLoading" | "isFormValid"> & {
  uid: string;
  collectionReference: CollectionReference;
  entity: Entity;
}

function ActivityFeed(props: ActivityFeedProps) {
  const {collectionReference, uid, entity, ...rest} = props;
  const {isOpen} = rest;

  let [activityCollection] = useCollection<ActivityItem>(null, collectionReference,
    [orderBy("timestamp", "desc")]);

  useEffect(() => {
    if (!isOpen) return;

    submitForm(
      doc(db, "users", uid, collectionReference.parent!.path),
      ActionType.Update,
      emptyFunction,
      entity === Entity.ProjectLevelTask ? {projectLevelTaskHasNewActivity: false} : {hasNewActivity: false}
    );
  }, [isOpen]);

  return <SimpleFormDrawer
    {...rest}
    icon={<SystemIndex.SystemIcons.Notifications width={28} height={28}/>}
    isLoading={false}
    isFormValid={true}
    hiddenFooter
    title={en.screen.ActivityFeed.title}
    wrapperSx={{padding: 2}}
  >
    <VirtualList<ActivityItem>
      collectionPath={collectionReference}
      defaultConstraints={[orderBy("timestamp", "desc")]}
      renderElement={activityItem => {
        switch (activityItem.type) {
          case ActivityType.StatusChange:
            return <StatusChangeItem item={activityItem} key={activityItem.id}/>
          case ActivityType.Assigned:
            return <AssignItem item={activityItem} key={activityItem.id}/>
          case ActivityType.AddedNewAccessListMember:
            return <NewAccessListMemberItem item={activityItem} key={activityItem.id}/>
          case ActivityType.Scheduled:
            return <ScheduledItem item={activityItem} key={activityItem.id}/>
          case ActivityType.Unassigned:
            return <UnassignedItem item={activityItem} key={activityItem.id}/>
          case ActivityType.Created:
            return <CreatedItem item={activityItem} key={activityItem.id} entity={entity}/>
          case ActivityType.Overdue:
            return <OverdueItem item={activityItem} key={activityItem.id}/>
          case ActivityType.DueSoon:
            return <DueSoonItem item={activityItem} key={activityItem.id}/>
          case ActivityType.RemovedAccesssListMember:
            return <RemovedAccessListMemberItem item={activityItem} key={activityItem.id}/>
          case ActivityType.FileUpload:
            return <FileStatusChangeItem item={activityItem} key={activityItem.id}/>
          case ActivityType.Updated:
            return <UpdatedItem item={activityItem} key={activityItem.id}/>
          case ActivityType.AnsweredForm:
            return <AnsweredItem item={activityItem} key={activityItem.id}/>;
          case ActivityType.Deleted:
            return <DeletedItem item={activityItem} key={activityItem.id}/>;
          default:
            return <></>
        }
      }}
    />
    {/*<DynamicView activityCollection={activityCollection} entity={entity}/>*/}
  </SimpleFormDrawer>
}

export default ActivityFeed
