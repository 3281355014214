import {Box} from "@mui/material";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import React from "react";

interface DocumentPreviewProps {
  id?: string;
  itemSx: any;
  iconSize: number;
  onClick: () => void;
  title?: string;
}

function DocumentPreview(props: DocumentPreviewProps) {
  const {id = "n", itemSx, iconSize, onClick, title = ""} = props;
  return <Box
    title={title}
    className="file-preview-item"
    id={`document-preview-${id}`}
    sx={{
      display: "grid",
      placeItems: "center",
      minWidth: 64,
      height: 64,
      backgroundColor: theme.palette.background.swiftDefault,
      borderRadius: 1,
      ...itemSx
    }}
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      onClick();
    }}
  >
    <SystemIcons.Document width={iconSize} height={iconSize}/>
  </Box>
}

export default DocumentPreview;