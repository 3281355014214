import {BaseProps} from "../BaseProps";
import {emptyFunction} from "constants/index";
import {organizationDataTagsPath, organizationPath, sortObjectsBy,} from "../utility";
import {useAccess, useRTDBDocField} from "hooks/index";
import {useParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router";
import {CounterFields, Entity} from "enums/index";
import {PermissionEntity, PermissionOperationKey} from "types/Permission";
import {AllOrder, DirectionalOrder, MiscOrder} from "enums/DirectionalOrder";
import PageHeader, {TagData} from "./PageHeader";
import PageFooter from "./PageFooter";
import {Virtuoso} from "react-virtuoso";
import useCollectionData from "hooks/useCollectionData";
import DataTagItem from "./DataTagItem";
import useProgressCount from "hooks/useProgressCount";

function OrganizationDataTags(props: BaseProps) {
  const {toastProps, selectedOrg, setSelectedOrgId = emptyFunction, uid} = props;

  const navigate = useNavigate();
  const {orgId} = useParams();

  const organizationRef = organizationPath(orgId!);
  const dataTagsCount = useRTDBDocField<number>(organizationRef.path, CounterFields.DataTagsCount)
  const {progress: importingProgressCount} = useProgressCount({
    path: organizationRef.path,
    counterField: CounterFields.DataTagsImportingProgressCount,
  });

  const [sortOrder, setSortOrder] = useState<AllOrder>(DirectionalOrder.asc);
  const [isCheckboxShown, setIsCheckboxShown] = useState<boolean>(false);
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  const [checkedDataTags, setCheckedDataTags] = useState<Set<string>>(new Set());

  const orgDatatagsRef = organizationDataTagsPath(orgId!);
  const {data: datatags, fetching, totalCount, loadMore} = useCollectionData<TagData>({
    collectionRef: orgDatatagsRef,
    constraints: [],
    returnedFields: ["@id", "name", "description", "timeCreated"],
    unsubscribeSnapshot: importingProgressCount > 1,
  });

  const [displayedDatatags, setDisplayedDataTags] = useState<TagData[]>([]);
  const [algoliaResults, setAlgoliaResults] = useState<TagData[] | null>(null);

  const [dataTagsAccess] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationDataTag,
  });

  // set displayed data tags
  useMemo(() => {
    if (datatags === null) return;

    let tags = [...(algoliaResults !== null ? algoliaResults : datatags)];
    sortDataTags(tags, sortOrder);
  }, [datatags, algoliaResults, sortOrder]);

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  function sortDataTags(tags: TagData[], sortOrder: AllOrder) {
    // if no data tags, do nothing
    if (tags.length === 0) {
      setDisplayedDataTags([]);
      return;
    }

    const tagsCopy = [...tags];

    if (sortOrder === MiscOrder.timeCreated || sortOrder === MiscOrder.lastVisited) {
      const sortedTags = sortObjectsBy(
        tagsCopy,
        "timeCreated",
        DirectionalOrder.desc
      );

      setDisplayedDataTags([...sortedTags]);
      return;
    }

    const sortedTags = sortObjectsBy(tagsCopy, "name", sortOrder);
    setDisplayedDataTags([...sortedTags]);
  }

  function isAtBottom() {
    if (algoliaResults !== null)
      return true;

    if (totalCount && totalCount <= displayedDatatags.length)
      return true;

    return false;
  }

  function onTagCheck(id: string, checked: boolean) {
    const newCheckedDataTags = new Set(checkedDataTags);
    // always delete it first
    newCheckedDataTags.delete(id);
    // append if checked
    if (checked) newCheckedDataTags.add(id);
    setCheckedDataTags(newCheckedDataTags);

    // if newCheckedDataTags is empty, uncheck isAllChecked
    setIsAllChecked(newCheckedDataTags.size > 0);
  }

  function onLoadMore() {
    // if algolia results are loaded, do not load more
    if (algoliaResults !== null) return;

    loadMore();
  }

  function updateIsAllChecked(checked: boolean) {
    setIsAllChecked(checked);

    if (checked) {
      const dataTagIds = displayedDatatags.map((dataTag) => dataTag["@id"]);
      setCheckedDataTags(new Set(dataTagIds));
      return;
    }

    setCheckedDataTags(new Set());
  }

  function onToggleCheckbox(shown: boolean) {
    setIsCheckboxShown(shown);

    // if checkbox is hidden, remove all selected data tags
    if (!shown) {
      setCheckedDataTags(new Set());
      setIsAllChecked(false);
    }
  }

  const virtuosoContext = {
    uid,
    atBottom: isAtBottom(),
    checkedDataTags,
    dataTagsAccess,
    organizationTagColRef: orgDatatagsRef.path,
    datagsCount: dataTagsCount || 0,
    dataTagsImportingProgressCount: importingProgressCount || 0,
    isAllChecked,
    isCheckboxShown,
    isEmpty: datatags && datatags.length === 0,
    isLoading: fetching,
    isSearchEmpty: algoliaResults && algoliaResults.length === 0,
    organizationRefPath: organizationRef.path,
    sortOrder,
    setSortOrder,
    setAlgoliaResults,
    setIsAllChecked: (checked: boolean) => updateIsAllChecked(checked),
    toastProps: toastProps!,
    toggleCheckbox: (isShown: boolean) => onToggleCheckbox(isShown),
  }

  return (
    <>
      <Virtuoso
        style={{height: "100vh", maxWidth: "100hw", paddingLeft: "10%", overflowX: "hidden"}}
        overscan={5}
        atBottomThreshold={100}
        data={displayedDatatags}
        context={virtuosoContext}
        components={{
          Header: VirtuosoHeader,
          Footer: VirtuosoFooter,
        }}
        endReached={onLoadMore}
        itemContent={(index, dataTag) => {
          return (
            <DataTagItem
              uid={uid}
              toastProps={toastProps!}
              key={dataTag["@id"]}
              id={dataTag["@id"]}
              onTagCheck={onTagCheck}
              isChecked={checkedDataTags.has(dataTag["@id"])}
              canUpdate={Boolean(dataTagsAccess?.[PermissionOperationKey.Update])}
              canDelete={Boolean(dataTagsAccess?.[PermissionOperationKey.Delete])}
              isCheckboxShown={isCheckboxShown}
              organizationTagColRef={orgDatatagsRef.path}
            />
          )
        }}
      />
    </>
  )
}

const VirtuosoHeader = ({context}: any) => {
  return <PageHeader {...context}/>;
}

const VirtuosoFooter = ({context}: any) => {
  return <PageFooter {...context}/>;
}

export default OrganizationDataTags;
