import {useEffect, useState} from "react";
import useNotPlayableMedia from "hooks/useNotPlayableMedia";
import useNoPreviewFile from "hooks/useNoPreviewFile";

function useFileTitle(fileName: string): string {
  const [message, setMessage] = useState<string>("");
  const notPlayableMessage = useNotPlayableMedia(fileName);
  const noPreviewMessage = useNoPreviewFile(fileName);

  useEffect(() => {
    setMessage(notPlayableMessage || noPreviewMessage);
  }, [notPlayableMessage, noPreviewMessage]);

  return message;
}

export default useFileTitle;