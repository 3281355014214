import {ConfirmDialog, EmptyList, InProgress} from "components/index";
import {ActionType, DirectionalOrder, Entity, ProcessType, Severity, ViewStatus} from "enums/index";
import {Project} from "types/Project";
import ProjectList from "components/ProjectsView/ProjectList";
import {Stack} from "@mui/material";
import {useCollection, useComponentToggler} from "hooks/index";
import React, {useEffect, useState} from "react";
import {collection, doc, getDoc, Timestamp, where} from "firebase/firestore";
import {allStatus, emptyFunction, enCommonLabel, enProjectLabel} from "constants/index";
import {milestoneTaskandAssetStatus} from "enums/milestoneTaskandAssetStatus";
import {BaseProps} from "screens/BaseProps";
import {useParams} from "react-router-dom";
import {formatDateTimeFirestoreDate, projectsPath, sortObjectsBy, submitForm} from "screens/utility";
import {ProjectAction} from "screens/Projects";
import SearchAndSort from "components/ProjectsView/SearchAndSort";
import {AllOrder, MiscOrder} from "enums/DirectionalOrder";
import {db} from "../../firebase";

interface ProjectsViewProps extends BaseProps {
  onSelectedClick?: (obj: Project | null, action: ProjectAction) => void;
  projectsRefPath: string;
  parentEntity?: Entity.Organization | Entity.Asset;
}

type ProjectData = Project & {
  lastVisitedStr?: string;
}

function ProjectsView(props: ProjectsViewProps) {
  const {projectsRefPath, toastProps, uid} = props;
  const {parentEntity = Entity.Organization, onSelectedClick = emptyFunction} = props;
  const {setIsToastOpen, setToastSeverity, setToastMessage} = toastProps!;

  const {orgId} = useParams();

  const [isMounted, setIsMounted] = useState(false);
  const [projects, , ,isLoading] = useCollection<Project>(
    null,
    collection(db, projectsRefPath),
    parentEntity === Entity.Organization ?
      [where("@allowedUsers", "array-contains", uid ?? "")]
      : null,
    false,
    false,
    isMounted
  );
  const [statusFilter, setStatusFilter] = useState<milestoneTaskandAssetStatus | string>(allStatus);
  const [currentOrder, setCurrentOrder] = useState<AllOrder>(DirectionalOrder.asc);

  const [algoliaProjects, setAlgoliaProjects] = useState<Project[] | null>(null);
  const [toBeDeletedProj, setToBeDeletedProj] = useState<Project | null>(null);
  const [isDialogOpen, {open: openDialog, close: closeDialog}] = useComponentToggler(false);
  const [displayedProjects, setDisplayedProjects] = useState<ProjectData[]>([]);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);
  
  useEffect(() => {
    if (currentOrder === MiscOrder.lastVisited) {
      const fetchLastVisited = async () => {
        // for each item in the projects list, get the last visited date
        const displayedProjectsCopy = [...displayedProjects];

        await Promise.all(
          displayedProjectsCopy.map(async (project, index) => {
            const docRef = doc(projectsPath(orgId!), project["@id"]);
            const projectDoc = await getDoc<{lastVisited?: Timestamp}>(doc(db, "users", uid, docRef.path));
            const docData = projectDoc.data() as {lastVisited?: Timestamp};
            if (docData && docData.lastVisited) {
              const {shortDate, time} = formatDateTimeFirestoreDate(docData.lastVisited);
              displayedProjectsCopy[index].lastVisitedStr = `${shortDate} ${time}`;
            } else {
              displayedProjectsCopy[index].lastVisitedStr = "";
            }
          })
        );

        const projectsWithoutLastVisited = displayedProjectsCopy.filter(project => !project.lastVisitedStr);
        const projectsWithLastVisited = displayedProjectsCopy.filter(project => project.lastVisitedStr);
        const newSortedProjects = sortObjectsBy(projectsWithLastVisited, "lastVisitedStr", DirectionalOrder.desc);
        setDisplayedProjects([...newSortedProjects, ...projectsWithoutLastVisited]);
      }

      fetchLastVisited();
      return;
    }

    // @ts-ignore
    setDisplayedProjects(sortObjectsBy([...displayedProjects], "name", currentOrder));
  }, [currentOrder]);

  useEffect(() => {
    const displayed = (algoliaProjects !== null ? algoliaProjects : projects) ?? [];
    // @ts-ignore
    setDisplayedProjects(sortObjectsBy([...displayed], "name", currentOrder));
  }, [algoliaProjects, projects]);

  function deleteProject(project: Project) {
    setToBeDeletedProj(project);
    openDialog();
  }

  async function deleteSelectedProj() {
    if (!toBeDeletedProj) return;
    const documentReference = doc(projectsPath(orgId!), toBeDeletedProj["@id"]);
    await submitForm(documentReference, ActionType.Delete, deleteStatusHandler);
  }

  function deleteStatusHandler(status: ViewStatus, data: Project, isLastUpdate: boolean) {
    if(!isLastUpdate) return;

    closeDialog();

    switch(status) {
      case ViewStatus.Finished:
        setToastSeverity(Severity.Success);
        setToastMessage(enProjectLabel.deleteSuccess);
        setIsToastOpen(true);
        toBeDeletedProj && setAlgoliaProjects(prev =>
            prev ? [...prev.filter(project => project['@id'] !== toBeDeletedProj['@id'])] : null
        );

        break;
      case ViewStatus.Error:
      case ViewStatus.SecurityError:
      case ViewStatus.ValidationError:
        setToastSeverity(Severity.Error);
        setToastMessage(enCommonLabel.errorProcess(ProcessType.Delete));
        setIsToastOpen(true);
        break;
    }
  }

  if (projects && !projects.length && statusFilter === allStatus && currentOrder !== MiscOrder.lastVisited)
    return <EmptyList entity={Entity.Project}/>;

  const properties = {
    parentEntity,
    projects: displayedProjects ?? [],
    onSelectedClick,
    toastProps,
    deleteProject,
    order: currentOrder,
    ...props
  }

  return (
    <Stack gap={1} flex={1}>
      <ConfirmDialog
        id="delete-project-dialog"
        isOpen={isDialogOpen}
        cancelButtonId="delete-project-dialog-cancel-button"
        confirmButtonId="delete-project-dialog-confirm-button"
        handleClose={closeDialog}
        handleConfirm={deleteSelectedProj}
        title={enProjectLabel.deleteConfirmationTitle(toBeDeletedProj?.name ?? "")}
        text={enProjectLabel.deleteConfirmationText}
      />
      <SearchAndSort
        parentEntity={parentEntity}
        projects={displayedProjects}
        currentOrder={currentOrder}
        setAlgoliaProjects={setAlgoliaProjects}
        setStatusFilter={setStatusFilter}
        setCurrentOrder={setCurrentOrder}
        statusFilter={statusFilter}
      />
      {isLoading ? <InProgress/>
        : (
          <ProjectList
            {...properties}
            statusFilter={statusFilter}
            fromAlgolia={algoliaProjects !== null}
            uid={uid}
          />
        )}
    </Stack>
  );
}

export default ProjectsView;
