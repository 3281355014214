import {FileStatus} from "enums/fileStatus";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../../../firebase";
import {SwiftFile} from "types/SwiftFile";
import {CarouselItem} from "components/ChatDrawer/ChatItemsView/ChatAttachmentCarousel";
import getEnvKey from "../../../screens/utility/getEnvKey";
import {storageBucketKey} from "constants/envKeys";

interface onAttachmentClickProps {
  attachment: SwiftFile;
  attachments: SwiftFile[];
  onClick:(attachments: CarouselItem[], selectedIndex: number) => void;
}
export async function onAttachmentClick(props: onAttachmentClickProps) {
  const {attachment, attachments, onClick} = props;
  const storageBucket = getEnvKey(storageBucketKey);

  const carouselItems: CarouselItem[] = [];
  const asyncHelper = new Promise<void>((resolve, reject) => {
    attachments.forEach(async (attachment, index, arr) => {
      if (attachment.fileStatus === FileStatus.Uploading) {
        return;
      }
      const previewReference = ref(storage, `${storageBucket}${attachment.destPath}`);
      carouselItems.push({
        source: await getDownloadURL(previewReference),
        type: attachment.contentType,
        nameWithExt: attachment.name
      });
      if (index === arr.length -1) resolve();
    });
  });
  asyncHelper.then(() => onClick(carouselItems, attachments.findIndex((file) => attachment["@id"] === file["@id"])));
}