import {FormBase} from "types/FormBase";
import {ViewStatus} from "enums/viewStatus";

interface PartialDocument {
  "@form"?: FormBase
}

export function getDocumentStatus(document: PartialDocument | null) {
  if(document && document["@form"] && document["@form"]["@status"])
    return document["@form"]["@status"];

  return ViewStatus.Finished;
}