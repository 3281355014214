import theme from "theme/theme";
import {enCommonButton, enCommonLabel, enTaskLabel} from "constants/index";
import {Task} from "types/Task";
import {milestoneTaskandAssetStatus as TaskStatus} from "enums/milestoneTaskandAssetStatus";
import {SystemIcons} from "assets/icons/system/system.index";
import {StatusItem} from "types/StatusItem";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {LoadingButton} from "@mui/lab";
import submitForm from "../../utility/submitForm";
import {ActionType, Severity, ProcessType} from "enums/index";
import {statusSubmitHandler} from "../../utility/statusSubmitHandler";
import {BaseProps} from "../../BaseProps";
import {DocumentReference} from "firebase/firestore";
import {useState} from "react";

interface TaskStatusButtonFunctionProps extends BaseProps {
  selectedStatus: StatusItem | undefined,
  task: Task,
  documentRef: DocumentReference<any>,
  access: AccessType | null;
}

export default function TaskStatusButtonFunction(props: TaskStatusButtonFunctionProps) {
  const {selectedStatus, uid, access, task, toastProps, documentRef} = props;
  const {setIsToastOpen, setToastSeverity, setToastMessage} = toastProps!;

  const [isLoading, setIsLoading] = useState(false);

  const canSubmit = access?.[PermissionOperationKey.ChangeStatus] || access?.[PermissionOperationKey.OverrideStatus];
  const canApprove = uid === task.approver?.uid || canSubmit;

  async function updateTaskStatus(status: TaskStatus, otherFields: any = {}) {
    setIsLoading(true);

    await submitForm(documentRef, ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler({
        status, data, isLastUpdate,
        successCallback,
        errorCallback
      }),
      {
        taskStatus: status,
        ...otherFields
      })
  }

  function successCallback() {
    setIsLoading(false);
    setToastSeverity(Severity.Success)
    setToastMessage(enTaskLabel.updateSuccess);
    setIsToastOpen(true);
  }

  function errorCallback(error: any) {
    let toastMessage = enCommonLabel.errorProcess(ProcessType.Update);
    // if type of message is obj, get first obj value
    if (typeof error === "object" && (typeof Object.values(error)[0] === "string")) {
      toastMessage = Object.values(error)[0] as string;
    }

    setIsLoading(false);
    setToastSeverity(Severity.Error)
    setToastMessage(toastMessage);
    setIsToastOpen(true);
  }

  if (!selectedStatus) return <></>;

  switch (selectedStatus.title) {
    case TaskStatus.InProgress:
      return <LoadingButton
        id="task-status-submit-button"
        variant="contained"
        sx={{
          maxHeight: 38
        }}
        startIcon={
          <SystemIcons.ArrowRight
            height={16}
            width={16}
            stroke={theme.palette.background.paper}
          />
        }
        loading={isLoading}
        disabled={!canSubmit}
        onClick={async () => canSubmit ? await updateTaskStatus(TaskStatus.Submitted) : undefined}
      >
        {enCommonButton.submit}
      </LoadingButton>
    case TaskStatus.Submitted:
      return (
        <LoadingButton
          loading={isLoading}
          id="task-status-approve-button"
          variant="contained"
          sx={{
            maxHeight: 38,
            backgroundColor: theme.palette.success.main,
            "&:hover": {
              backgroundColor: theme.palette.success.dark
            }
          }}
          startIcon={
            <SystemIcons.Check
              height={16}
              width={16}
              stroke={theme.palette.background.paper}
            />
          }
          onClick={async () => canApprove ? await updateTaskStatus(TaskStatus.Approved) : undefined}
          disabled={!canApprove}
          disableElevation
        >
          {enCommonButton.approve}
        </LoadingButton>
      )
    default:
      return <></>;
  }
}