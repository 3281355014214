import {BaseProps} from "screens/BaseProps";
import {FormBase, Organization, PartialRequired, Team, TeamCategory} from "types/index";
import React, {useEffect, useState} from "react";
import {Input, SelectInput, SelectWithSearch, SimpleFormDrawer} from "components/index";
import {Box, IconButton, Tooltip, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import {
  defaultTeamCategory,
  enCommonButton,
  enCommonLabel,
  enTeamsAndPeopleButton,
  enTeamsAndPeopleLabel,
  SELECTABLE_ROLES,
} from "constants/index";
import {areObjectsEqual, getKeyValue, onChangeInput, submitForm} from "screens/utility";
import theme from "theme/theme";
import {orgTeamCategoriesCollectionPath, teamsPath} from "screens/utility/FirebasePath";
import {useCollection} from "hooks/index";
import {doc} from "firebase/firestore";
import {AccessRole, ActionType, Severity, ViewStatus} from "enums/index";
import ManageMembersDrawer from "components/Drawers/ManageMembersDrawer/ManageMembersDrawer";
import handleEnterKeyPress from "screens/utility/handleEnterKeyPress";
import useCheckUniqueness from "hooks/useCheckUniqueness";

interface AddTeamFormDrawerProps extends BaseProps {
  org: Organization;
  isOpen: boolean,
  team: Team | null;
  onClose: () => void;
}

export default function ManageTeamFormDrawer(props: AddTeamFormDrawerProps) {
  const {org: selectedOrg, isOpen, toastProps, team, uid, onClose} = props;
  const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps!;

  const [initialTeam, setInitialTeam] = useState({
    description: team?.description ?? "",
    name: team?.name ?? "",
    role: team?.role ?? AccessRole.User,
    teamCategory: team ? team.teamCategory! : defaultTeamCategory,
    teamMemberUids: Array.from(new Set(team?.teamMemberUids)).sort(),
    teamMembersCount: (new Set(team?.teamMemberUids)).size,
  });
  const [name, setName] = useState<string>("");
  const [category, setCategory] = useState<PartialRequired<TeamCategory, "@id" | "name" | "id"> | null>(initialTeam.teamCategory);
  const [description, setDescription] = useState<string>(initialTeam.description);
  const [role, setRole] = useState<AccessRole>(initialTeam.role);
  const [selectedMembers, setSelectedMembers] = useState<Set<string>>(new Set(initialTeam.teamMemberUids));

  const [nameValidationError, setNameValidationError] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isUnique = useCheckUniqueness(teamsPath(selectedOrg!["@id"]!), "name", name, team?.["@id"] ?? undefined)

  const [categoriesCollection, ,
    setCategoriesReference] = useCollection<PartialRequired<TeamCategory, "@id" | "name" | "id">>(
    null, orgTeamCategoriesCollectionPath(selectedOrg!["@id"] ?? "undefined"));

  const searchableCategories = categoriesCollection ? categoriesCollection.map((category) => ({
    searchable: category.name,
    value: category["@id"],
    displayItem: category.name
  })) : []

  useEffect(() => {

  }, []);

  // name validations
  useEffect(() => {
    if(name.trim() === "") {
      setNameValidationError(enTeamsAndPeopleLabel.nameRequired)
      return;
    }

    if(!isUnique){
      setNameValidationError(enTeamsAndPeopleLabel.teamNameAlreadyExists)
      return
    }

    setNameValidationError("")
  }, [isUnique, name]);

  useEffect(() => {
    let newTeam = {
      description: team?.description ?? "",
      name: team?.name ?? "",
      role: team?.role ?? AccessRole.User,
      teamCategory: team ? team.teamCategory! : defaultTeamCategory,
      teamMemberUids: Array.from(new Set(team?.teamMemberUids)).sort(),
      teamMembersCount: (new Set(team?.teamMemberUids)).size,
    }
    setInitialTeam(newTeam);
    setName(newTeam.name);
    setCategory(newTeam.teamCategory || null);
    setDescription(newTeam.description || "");
    setRole(newTeam.role);
    setIsLoading(false);
    setSelectedMembers(new Set(newTeam.teamMemberUids));
    setNameValidationError("");
  }, [team, isOpen])

  useEffect(() => {
    if (selectedOrg) setCategoriesReference(orgTeamCategoriesCollectionPath(selectedOrg["@id"]!));
  }, [selectedOrg]);

  function handleDocumentError(data: FormBase) {
    const errorMessage = data?.["@messages"];

    if (typeof (errorMessage) === "object") {
      setNameValidationError(getKeyValue(errorMessage || {}, "name", ""));
      setToastMessage(getKeyValue(errorMessage || {}, "name", ""))
    } else {
      setToastMessage(errorMessage ?? enCommonLabel.securityError);
    }
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
  }

  async function onSubmit() {
    setIsLoading(true);

    const teamDocRef = team ? doc(teamsPath(selectedOrg!["@id"]!), team["@id"]!) : doc(teamsPath(selectedOrg!["@id"]!))
    const teamData: Team = {
      description,
      name,
      role,
      teamCategory: {
        id: category!["@id"],
        name: category!.name,
        "@id": category!["@id"],
      },
      id: teamDocRef.id,
      teamMemberUids: Array.from(selectedMembers),
      orgId: selectedOrg ? selectedOrg["@id"]! : ""
    }

    await submitForm(teamDocRef, team ? ActionType.Update : ActionType.Create, statusHandler, teamData);
  }

  function statusHandler(status: ViewStatus, data: Team, isLastUpdate: boolean) {
    if (!isLastUpdate) return;

    setIsLoading(false);

    switch (status) {
      case ViewStatus.Finished:
        const successMessageFunction = team ?
          enTeamsAndPeopleLabel.teamUpdateSuccess :
          enTeamsAndPeopleLabel.teamCreationSuccess;
        setToastMessage(successMessageFunction(name));
        setToastSeverity(Severity.Success);
        setIsToastOpen(true);
        resetForm();
        onClose();
        break;
      case ViewStatus.ValidationError:
      case ViewStatus.SecurityError:
        handleDocumentError(data as unknown as FormBase);
        break;
    }
  }

  function onCategorySelect(categoryId: string) {
    if (!categoriesCollection) return;
    setCategory(categoriesCollection!.find((cat) => cat["@id"] === categoryId)!);
  }

  function resetForm() {
    setName("");
    setCategory(defaultTeamCategory);
    setDescription("");
    setRole(AccessRole.User);
    setSelectedMembers(new Set());
    setNameValidationError("");
  }

  function onMemberSelect(uid: string) {
    return selectedMembers.has(uid) ? setSelectedMembers((prev) => {
      const newSet = new Set(prev);
      newSet.delete(uid);
      return newSet;
    }) : setSelectedMembers((prev) => (new Set(prev)).add(uid));
  }

  function updateSelectedMembers(newSet: Set<string>) {
    setSelectedMembers(newSet);
  }

  function isFormEdited() {
    const newTeam = {
      description,
      name,
      role,
      teamCategory: {"@id": category!["@id"], id: category!["id"], name: category!["name"]},
      teamMemberUids: Array.from(selectedMembers).sort(),
      teamMembersCount: selectedMembers.size,
    };
    return !areObjectsEqual(newTeam, initialTeam);
  }

  function getIsFormValid() {

    if (!isUnique) return false;

    if (name.trim() === "" || nameValidationError) return false;

    //validation for edit team
    return isFormEdited();
  }

  return (
    <SimpleFormDrawer
      isOpen={isOpen}
      onClose={onClose}
      id="addTeamDrawer"
      buttonId="addTeamSaveButton"
      icon={<SystemIcons.Teams/>}
      iconProps={{p: 0}}
      isFormValid={getIsFormValid()}
      isLoading={isLoading}
      rightButtonLabel={!!team ? enCommonButton.save : enTeamsAndPeopleButton.addTeam}
      title={!!team ? enTeamsAndPeopleLabel.editTeam : enTeamsAndPeopleButton.addTeam}
      onSubmit={onSubmit}
      unsavedChanges={isFormEdited()}
    >
      <Input
        id={"team-name"}
        label={enCommonLabel.name}
        onChange={(e) => {
          onChangeInput(e, setName);
          setNameValidationError("");
        }}
        onKeyPress={(e) => handleEnterKeyPress(e, getIsFormValid(), isLoading, onSubmit)}
        sx={{mb: 1}}
        value={name}
        validationMessage={nameValidationError}
      />

      <SelectWithSearch
        id="teamCategorySelectionWrapper"
        searchInputId="teamCategorySelection"
        searchPlaceholder={enTeamsAndPeopleLabel.searchCategory}
        items={searchableCategories}
        defaultValue={(team?.teamCategory!["@id"] ?? defaultTeamCategory["@id"])}
        handleSelectCallback={onCategorySelect}
        sx={{mb: 1}}
        LabelComponent={<Typography variant="h5">{enTeamsAndPeopleLabel.teamCategory()}</Typography>}
      />
      <Input
        id={"team-description"}
        label={enCommonLabel.description}
        maxRows={7}
        minRows={4}
        onChange={(e) => onChangeInput(e, setDescription)}
        sx={{mb: 1}}
        value={description}
        multiline
        optional
        onKeyPress={(e) => handleEnterKeyPress(e, getIsFormValid(), isLoading, onSubmit)}
      />
      <SelectInput
        LabelComponent={<Typography variant="h5" display="flex" alignItems="center">
          {enTeamsAndPeopleLabel.organizationRole} &nbsp;
          <Tooltip title={enTeamsAndPeopleLabel.organizationRoleInfo} placement="top">
            <IconButton size="small" sx={{p: 0}} disableRipple>
              <SystemIcons.Info
                width={16}
                height={16}
                fill={theme.palette.neutral.main}
                stroke={theme.palette.common.white}
              />
            </IconButton>
          </Tooltip>
        </Typography>}
        InputProps={{
          id: "organization-role-input"
        }}
        onChange={(role) => setRole(role.target.value as AccessRole)}
        items={SELECTABLE_ROLES}
        defaultValue={role}
        value={role}
        sx={{mb: 1}}
      />
      <ManageMembersDrawer
        selectedMembers={selectedMembers}
        onMemberSelect={onMemberSelect}
        updateSelectedMembers={updateSelectedMembers}
        withMemberSize
        uid={uid}
      />
      <Box flex={1}/>
    </SimpleFormDrawer>
  )
}
