import {enCommonLabel} from "constants/index";
import {useNavigate} from "react-router-dom";
import {Box, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {useEffect} from "react";
import {useDocument} from "hooks/index";
import {Config} from "types/Config";
import {configPath} from "./utility";

function Maintenance() {
  const navigate = useNavigate();
  const [config] = useDocument<Config>(configPath());

  useEffect(() => {
    if (!config) return;
    if (config.underMaintenance) return;

    navigate(`/login`);
  }, [config]);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{bgcolor: theme.palette.background.paper, height: '100vh'}}
    >
      <Box p={2}>
        <SystemIcons.Settings
          stroke={theme.palette.neutral.light}
          style={{
            width: 64,
            height: 64,
          }}/>
      </Box>
      <Typography variant="h4" color={theme.palette.secondary.main} maxWidth={350} align="center">
        {enCommonLabel.loginRestriction}
      </Typography>
    </Stack>
  )
}

export default Maintenance;