import {
  Box,
  MenuItem,
  Select, SelectChangeEvent,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import {ReactElement} from "react";

export interface SelectInputItem {
  name: string | ReactElement;
  value: string;
  validity?: boolean
}

interface SelectInputProps {
  label?: string;
  LabelComponent?: ReactElement;
  onChange: (e: SelectChangeEvent) => void;
  validator?: [validity: boolean, errorMessage: string];
  value?: string;
  dataTestId?: string;
  placeholder?: string;
  InputProps?: any;
  sx?: SxProps<Theme>;
  defaultValue?: string;
  id?: string;
  items: SelectInputItem[];
  selectLabel?: string;
}

function SelectInput(props: SelectInputProps) {
  const {
    label,
    LabelComponent,
    validator,
    sx,
    onChange,
    items,
    InputProps,
    value
  } = props;
  let [valid, errorMessage] = [true, ""];

  if (validator) {
    [valid, errorMessage] = validator;
  }

  return (
    <Box sx={{alignSelf: "stretch", position: "relative", ...sx}}>
      <Typography variant="h5">{label}</Typography>
      {LabelComponent}
      <Select
        sx={{
          height: 38.13,
          width: "100%"
        }}
        value={value}
        onChange={onChange}
        inputProps={InputProps}
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value} disabled={item.hasOwnProperty("validity") ? !item.validity : false}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {validator && (
        <Box sx={{height: "1.5rem"}}>
          <Typography
            sx={{
              mt: 0.5,
              minHeight: "1em",
              color: "#FF5230",
              alignSelf: "flex-start",
              width: "max-content",
              fontSize: "12px",
              lineHeight: "20px",
            }}
          >
            {validator && !valid && errorMessage}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default SelectInput;
