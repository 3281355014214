export const organizationDataTags = {
  label: {
    title: "Data Tags",
    createError: (m: string) => `An error occured while creating. ${m}`,
    updateError: (m: string) => `An error occured while updating. ${m}`,
    createSuccess: "Data tag successfully created!",
    updateSuccess: "Data tag successfully updated!",
    editTag: "Edit Data Tag",
    createTag: "Create Data Tag",
    noPermission: "You have no permission for this action.",
    emptyListTitle: "There are no Data tag(s) created.",
    emptyListText: "Create the first data tag",
    deleteTitleMultiple: `Are you sure you want to delete selected data tags?`,
    deleteTitle: "Are you sure you want to delete this Data Tag?",
    deleteText: "Deleting this data tag will also delete it's usages",
    search: "Search Data Tags",
    noMoreDatatags: "No more data tags to load",
    maximumCharacters: (max: number) => `Maximum ${max} characters`,
    characterCounter: (label: string) => `${label} characters`,
    addDataTags: "Add Data Tags",
    addMore: "Add More",
    nameExists: "Data Tag Name already exists",
    nameRequired:  `Data Tag Name is required`,
    deleteSuccess: "Data tag/s has been deleted successfully",
    deleteError: "An error occurred while deleting",
    encounteredAnError: "Encountered an error while deleting",
    export: "Export",
    exportDialogTitle: "Export Data Tags",
    exportingDialogTitle: "Exporting data tags data...",
    exportDialogText: "Download data tags data as CSV/Excel file.",
  },
  button: {
    createNew: "Create Data Tag",
    editTag: "Edit Data Tag",
    deleteTag: "Delete Data Tag",
  }
}