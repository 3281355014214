import {Typography} from "@mui/material";

export const member = {
  label: {
    title: "Members",
    emptyTitle: "No Members Yet",
    emptyDescription: "Add the first member.",
    searchMember: "Search Member...",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    role: "Role",
    organization: "Organization",
    allAreRequired: "All items are required.",
    successfullyCreated: "Successfully sent invitation",
    notEditable: "This member is not editable.",
    editSuccess: "This member has been successfully updated!",
    bulkEditSuccess: "Selected members has been successfully updated",
    invitationToJoin: (inviter: string) => (<Typography>
      Invitation to join organization by <strong>{inviter}</strong></Typography>),
    expiredInvitation: (inviter: string) => (<Typography>
      Invitation to join organization by <strong>{inviter}</strong> has been expired</Typography>),
    cancelledInvitation: (inviter: string) => (<Typography>Invitation to join organization by <strong>{inviter}</strong> has been cancelled</Typography>),
    deleteConfirmationTitle: (name: string) => (`Are you sure you want to delete ${name}?`),
    deleteConfirmationText: (name: string) => (`${name} will be deactivated immediately. You can't undo this action.`),
    bulkDeleteConfirmationTitle: (str: string) => `Are you sure you want to delete all selected ${str.toLowerCase()}?`,
    bulkDeleteConfirmationText: (str: string) => `The selected ${str.toLowerCase()} will be deactivated immediately. You can't undo this action.`,
    cannotDelete: "A user with this role can't be deleted.",
    cannotDeleteAssignNewOwner: "Assign a new Owner to delete this member.",
    cannotEdit: "A user with this role can't be edited.",
    deleteSuccess: "User/s deactivated successfully.",
    allMembers: "All members",
    selectedMembers: (selectedMembers: number) => `${selectedMembers} selected`,
    bulkChangeRoleDrawerTitle: "Change Selected Members Role",
    resendSuccess: "Successfully resent invitation",
    cancelSuccess: "Successfully cancelled invitation",
    emptyInviteDescription: "All invites for this organization will show up here.",
    withoutOrganization: "Without Organization",
    selectAnOrganization: "Select an Organization",
    cancelConfirmationTitle: "Are you sure you want to cancel this invite?",
    cancelConfirmationText: "This invite will be deleted immediately. You can't undo this action.",
  },
  button: {
    addNew: (str: string) => `Add New ${str}`,
    addNewMembers: "Add New Member",
    bulkChangeRoleBtn: "Change a role",
    bulkDeleteMembersBtn: "Delete Members",
  },
  validationMessages: {
    requiredFirstName: "First Name is required",
    requiredLastName: "Last Name is required",
    requiredEmail: "Email is required",
    invalidEmail: "Invalid email",
    emailAlreadyExists: "Email already exists in members list",
    emailSameAsLoggedIn: "Email is same as logged in user"
  },
}