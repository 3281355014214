import React, {useEffect, useState} from "react";
import {BaseProps} from "screens/BaseProps";
import {Content, CustomTab, CustomTabs, TabPanel} from "components/index";
import {Stack, Typography} from "@mui/material";
import {enTeamsAndPeopleLabel, enTeamsAndPeople, emptyFunction} from "constants/index";
import {Teams, People, Invites} from "./Tabs";
import {organizationPath} from "../utility";
import {useAccess, useRTDBDocField} from "hooks/index";
import theme from "theme/theme";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {Entity, CounterFields} from "enums/index";
import {PermissionEntity, PermissionOperationKey} from "types/Permission";

interface TeamsAndPeopleProps extends BaseProps {
}

enum TabIndex {
  Teams,
  People,
  Invites
}

function TeamsAndPeople(props: TeamsAndPeopleProps) {
  const {toastProps, selectedOrg, uid, setSelectedOrgId = emptyFunction} = props;

  const {orgId} = useParams();

  const [activeTab, setActiveTab] = useState<TabIndex>(TabIndex.Teams);

  const teamsCount = useRTDBDocField<number>(organizationPath(orgId!).path, CounterFields.TeamsCount) || 0;
  const membersCount = useRTDBDocField<number>(organizationPath(orgId!).path, CounterFields.MembersCount) || 0;
  const pendingInvitesCount = useRTDBDocField<number>(organizationPath(orgId!).path, CounterFields.PendingInvitesCount) || 0;

  const [access] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationInvite,
  });

  const navigate = useNavigate();

  // save local id once refreshed
  useEffect(() => {
    if(orgId === undefined) return navigate("/");

    if(orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  function handleTabChange(event: React.SyntheticEvent, newValue: number) {
    setActiveTab(newValue);
  }

  return (
    <Content>
      <Stack spacing={2} height="100%" flex={1}>
        <Typography variant="h1" color={theme.palette.secondary.main} mt={2}>
          {enTeamsAndPeople.title}
        </Typography>
        <CustomTabs
          value={activeTab}
          onChange={handleTabChange}
        >
          <CustomTab
            disableRipple
            label={
              <Stack direction="row" gap={0.5}>
                <Typography variant="h5">{enTeamsAndPeopleLabel.teams}</Typography>
                <Typography
                  id="teams-counter"
                  color={theme.palette.neutral.dark}
                  variant="h5"
                >
                  {((teamsCount ?? 0) > 0) && teamsCount}
                </Typography>
              </Stack>
            }
          />
          <CustomTab
            disableRipple
            label={
              <Stack direction="row" gap={0.5}>
                <Typography variant="h5">{enTeamsAndPeopleLabel.people}</Typography>
                <Typography
                  id="members-counter"
                  color={theme.palette.neutral.dark}
                  variant="h5"
                >
                  {((membersCount ?? 0) > 0) && membersCount}
                </Typography>
              </Stack>
            }
            sx={{ml: 5}}
          />
          {access?.[PermissionOperationKey.Create] && <CustomTab
            disableRipple
            label={
              <Stack direction="row" gap={0.5}>
                <Typography variant="h5">{enTeamsAndPeopleLabel.invites}</Typography>
                <Typography
                  id="pending-invites-counter"
                  color={theme.palette.neutral.dark}
                  variant="h5"
                >
                  {((pendingInvitesCount ?? 0) > 0) && pendingInvitesCount}
                </Typography>
              </Stack>
            }
            sx={{ml: 5}}
          />}
        </CustomTabs>
        <TabPanel tabIndex={TabIndex.Teams} value={activeTab}>
          <Teams
            toastProps={toastProps}
            selectedOrg={selectedOrg!}
            uid={uid!}
          />
        </TabPanel>
        <TabPanel tabIndex={TabIndex.People} value={activeTab}>
          <People
            toastProps={toastProps}
            selectedOrg={selectedOrg!}
            setSelectedOrgId={setSelectedOrgId!}
            uid={uid!}
          />
        </TabPanel>
        <TabPanel tabIndex={TabIndex.Invites} value={activeTab}>
          <Invites
            toastProps={toastProps}
            selectedOrgId={orgId! ?? ""}
            uid={uid!}
          />
        </TabPanel>
      </Stack>
    </Content>
  );
}

export default TeamsAndPeople;
