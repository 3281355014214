import {StatusItem} from "assets/arrayList/ProjectStatus";
import {StatusIcons} from "assets/icons/status/status.index";
import theme from "theme/theme";
import {en} from "language/en";

const enTask = en.screen.Task.status;

const approved: StatusItem = {
  title: enTask.approved,
  icon: StatusIcons.Approved,
  color: theme.palette.success.main,
  checked: false,
}

export default approved