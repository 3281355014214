import {useNavigate} from "react-router-dom";
import ActionOption from "components/ActionOption";
import React from "react";
import MenuCard from "components/MenuCard";
import {emptyFunction, enOrganizationLabel, enProject} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {BaseProps} from "screens/BaseProps";
import {AccessType, PermissionOperationKey} from "types/Permission";

interface ToolTipPopoverProps extends BaseProps {
  anchorEl: HTMLElement | null;
  orgId: string;
  open: boolean;
  access: AccessType | null;
  projectAccess: AccessType | null;
  closeMenu: (e: React.MouseEvent<HTMLElement>) => void;
  editMenuClick: (e: React.MouseEvent<HTMLElement>) => void;
  deleteMenuClick: (e: React.MouseEvent<HTMLElement>) => void;
}

function MenuOptions(props: ToolTipPopoverProps) {
  const {anchorEl, access, projectAccess, orgId, open} = props;
  const {closeMenu, editMenuClick, deleteMenuClick} = props;

  const navigate = useNavigate();

  const viewProjectMenuButtonClick = projectAccess?.[PermissionOperationKey.View] ? () => navigate(`/${orgId}/projects`)
    : emptyFunction;
  const editMenuButtonClick = access?.[PermissionOperationKey.Update] ? editMenuClick : emptyFunction;
  const deleteMenuButtonClick = access?.[PermissionOperationKey.Delete] ? deleteMenuClick : emptyFunction;

  return (
    <MenuCard anchorEl={anchorEl} open={open} closeMenu={closeMenu}>
      <ActionOption
        id={`${orgId}-view-projects-menu-item`}
        Icon={<SystemIcons.Projects height={16} width={16}/>}
        label={enProject.label.projects}
        onClick={viewProjectMenuButtonClick}
        disabled={!projectAccess?.[PermissionOperationKey.View]}
      />
      <ActionOption
        id={`${orgId}-edit-org-menu-item`}
        Icon={<SystemIcons.Edit height={16} width={16}/>}
        label={enOrganizationLabel.edit}
        onClick={editMenuButtonClick}
        disabled={!access?.[PermissionOperationKey.Update]}
      />
      <ActionOption
        id={`${orgId}-manage-members-menu-item`}
        Icon={<SystemIcons.Teams height={16} width={16}/>}
        label={enOrganizationLabel.manageMembers}
        onClick={() => navigate(`/${orgId}/teams-and-people`)}
      />
      <ActionOption
        id={`${orgId}-delete-org-menu-item`}
        Icon={<SystemIcons.DeleteOutlined height={16} width={16}/>}
        label={enOrganizationLabel.delete}
        labelSx={(theme) => ({color: theme.palette.error.main})}
        onClick={deleteMenuButtonClick}
        disabled={!access?.[PermissionOperationKey.Update]}
      />
    </MenuCard>
  );

}

export default MenuOptions;
