import {Asset} from "types/Asset";

export const initialAsset: Asset = {
  name: "",
  "@id": "",
  nameIdx: "",
  idCode: "",
  isChecked: true,
  orgId: "",
  projectId: "",
  projectName: "",
  orgName: "",
}