import {Stack, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import {Personnel} from "types/Personnel";
import {styled} from '@mui/material/styles';
import {Avatar} from "components/index";
import {enTaskLabel} from "constants/index";
import theme from "theme/theme";

interface AssignedItemProps {
  assigned: Personnel;
  isSelected: boolean;
  updateSelected: () => void;
}

function AssignedItem(props: AssignedItemProps) {
  const {assigned, isSelected, updateSelected} = props;

  const displayedName = assigned.name === null ? enTaskLabel.unassigned : assigned.name;

  return (
    <Stack
      id={(displayedName).replace(" ", "")}
      direction="row"
      justifyContent="space-between"
      alignContent="center"
      alignItems="center"
      height={"56px"}
      gap={1}>
      <Stack direction="row" alignContent="center" alignItems="center" gap={2}>
        <Avatar
          {...assigned}
          sx={{
            marginTop: 0,
            height: "32px",
            width: "32px",
            fontSize: "12px !important",
          }}
        />
        <Stack direction="row" gap={0.5}>
          <Label
            sx={{color: theme.palette.secondary.main}}>
            {displayedName}
          </Label>
          {
            assigned.isYou && <Label
                  sx={{color: theme.palette.neutral.dark}}>
              {`(${enTaskLabel.you})`}
              </Label>
          }
        </Stack>
      </Stack>
      <Stack>
        {
          isSelected ?
            <SystemIcons.Selected height={24} width={24} onClick={updateSelected}/>
            :
            <SystemIcons.Unselected id="select-personnel" height={24} width={24} onClick={updateSelected}/>
        }
      </Stack>
    </Stack>
  )
}

/** styles start */
export const Label = styled(Typography)({
  fontVariant: "h5",
  font: "Lato",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "22px"
});
/** styles end */

export default AssignedItem;