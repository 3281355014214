import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Typography
} from "@mui/material";
import {enProfileAndSettingsLabel} from "constants/index";
import SystemIndex from "assets/icons/system/system.index";
import theme from "theme/theme";
import {Dispatch, Fragment} from "react";

interface DataPolicyDialogProps {
  isDialogOpen: boolean;
  setIsDialogOpen: Dispatch<boolean>;
}

function DataPolicyDialog(props: DataPolicyDialogProps) {
  const {isDialogOpen, setIsDialogOpen} = props;

  return <Dialog open={isDialogOpen} scroll="paper" onClose={() => setIsDialogOpen(false)}>
    <DialogTitle>
      <Typography variant="h2">
        {enProfileAndSettingsLabel.dataPolicy}
      </Typography>
      <IconButton
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
        onClick={() => setIsDialogOpen(false)}
      >
        <SystemIndex.SystemIcons.Close stroke={theme.palette.neutral.dark}/>
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <DialogContentText color="text.primary" sx={{marginBottom: 3}}>
        <Typography>
          {enProfileAndSettingsLabel.dataPolicyDescription}
        </Typography>
        <br/>
        <Typography variant="h3">
          {enProfileAndSettingsLabel.understandingTypesOfInformation}
        </Typography>
        <br/>
        {enProfileAndSettingsLabel.understandingTypesOfInformationParagraphs.map((paragraph) => <Fragment
          key={Math.random()}>
          <Typography>
            {paragraph}
          </Typography>
          <br/>
        </Fragment>)
        }
        <Typography variant="h3">
          {enProfileAndSettingsLabel.yourActivity}
        </Typography>
        <List
          sx={{
            listStyleType: 'disc',
            pl: 2,
            '& .MuiListItem-root': {
              display: 'list-item',
            },
          }}>
          {enProfileAndSettingsLabel.activityList.map((activity) => <ListItem
            key={Math.random()}>{activity}</ListItem>)}
        </List>
        {"dummy data".repeat(1000)}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="secondary" sx={{marginRight: 1}} onClick={() => setIsDialogOpen(false)}>
        <Typography variant="h4" color="text.secondary">
          Close
        </Typography>
      </Button>
    </DialogActions>
  </Dialog>
}

export default DataPolicyDialog
