import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {Box} from "@mui/material";
import React from "react";

interface VideoPreviewProps {
  id?: string;
  itemSx: any;
  iconSize: number;
  onClick: () => void;
  withBorder?: boolean;
  title?: string;
}

function VideoPreview(props: VideoPreviewProps) {
    const {id = "n", itemSx, iconSize, onClick, withBorder, title = ""} = props;

    return <Box
        title={title}
        className="file-preview-item"
        id={`video-preview-${id}`}
        sx={{
            display: "grid",
            placeItems: "center",
            height: 64,
            minWidth: 64,
            backgroundColor: theme.palette.background.swiftDefault,
            borderRadius: 1,
            ...(withBorder && {
                border: `1px solid ${theme.palette.neutral.light}`,
            }),
            ...itemSx
        }}
        onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onClick();
        }}
    >
        <SystemIcons.Video fill="black" width={iconSize} height={iconSize}/>
    </Box>
}

export default VideoPreview;