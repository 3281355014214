import {BaseProps} from "screens/BaseProps";
import {TabIndex} from "../enums/TabIndex";
import {TabContents} from "./TabContents";
import {userChatsPath} from "../../utility";
import {Entity} from "enums/entity";
import {where} from "firebase/firestore";

interface TabProps extends BaseProps {
  tab: TabIndex;
}

function Tab(props: TabProps) {
  const {tab, uid, toastProps} = props;

  switch (tab) {
    case TabIndex.Projects:
      return (
        <TabContents
          uid={uid}
          tab={tab}
          colRef={userChatsPath(uid)}
          constraints={[where("chatEntity", "==", Entity.Project)]}
          entity={[Entity.Project]}
          toastProps={toastProps!}
        />
      );
    case TabIndex.Assets:
      return (
        <TabContents
          uid={uid}
          tab={tab}
          colRef={userChatsPath(uid)}
          constraints={[where("chatEntity", "==", Entity.Asset)]}
          entity={[Entity.Asset]}
          toastProps={toastProps!}
        />
      );
    case TabIndex.Tasks:
      return (
        <TabContents
          uid={uid}
          tab={tab}
          colRef={userChatsPath(uid)}
          constraints={[where("chatEntity", "in", [Entity.Task, Entity.ProjectLevelTask])]}
          entity={[Entity.Task, Entity.ProjectLevelTask]}
          toastProps={toastProps!}
        />
      );
    case TabIndex.Requirements:
      return (
        <TabContents
          uid={uid}
          tab={tab}
          colRef={userChatsPath(uid)}
          constraints={[where(
            "chatEntity",
            "in",
            [
              Entity.FormRequirement,
              Entity.FileRequirement,
              Entity.PLFormRequirement,
              Entity.PLFileRequirement
            ])]}
          entity={[
            Entity.FormRequirement,
            Entity.FileRequirement,
            Entity.PLFormRequirement,
            Entity.PLFileRequirement
          ]}
          toastProps={toastProps!}
        />
      );
    case TabIndex.Files:
      return (
        <TabContents
          uid={uid}
          tab={tab}
          colRef={userChatsPath(uid)}
          constraints={[where(
            "chatEntity",
            "in",
            [Entity.SwiftFile, Entity.PLFile,]
          )]}
          entity={[Entity.SwiftFile, Entity.PLFile,]}
          toastProps={toastProps!}
        />
      );
    default:
      return (
        <TabContents
          uid={uid}
          tab={tab}
          colRef={userChatsPath(uid)}
          constraints={[]}
          entity={[]}
          toastProps={toastProps!}
        />
      );
  }
}

export default Tab;