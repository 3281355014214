import {Box, Checkbox, Divider, Stack, Typography} from "@mui/material";
import {Avatar, LongText} from "components/index";
import theme from "theme/theme";
import SystemIndex from "assets/icons/system/system.index";
import {Team} from "types/Team";
import {doc, DocumentReference} from "firebase/firestore";
import {teamsPath} from "screens/utility/FirebasePath";
import DeleteDialog from "components/Dialogs/DeleteDialog";
import React, {useState} from "react";
import {en} from "language/en";
import {BaseProps} from "screens/BaseProps";
import {styled} from "@mui/system";
import {ACCESS_ROLE_LABEL} from "constants/accessRoleLabel";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {SwiftIconButton} from "components/Button";
import {useDocument, useRTDBDocField} from "hooks/index";
import {CounterFields} from "enums/counterFields";
import {useParams} from "react-router-dom";

const palette = theme.palette;

interface TeamCardProps extends BaseProps {
  access: AccessType | null;
  teamId: string;
  onEditClick: (tean: Team) => void;
  handleCheckTeam: () => void;
  isSelected: boolean;
  openTeamMembersDrawer: (team: Team) => void;
  handleDeleteTeam: (docReference: DocumentReference, teamId: string, name: string) => void
}

function TeamCard(props: TeamCardProps) {
  const {access, isSelected, onEditClick, openTeamMembersDrawer, handleDeleteTeam, handleCheckTeam, teamId} = props;

  const {orgId} = useParams();
  const [team] = useDocument<Team>(doc(teamsPath(orgId!), teamId));

  const {"@id": id = "fillerId", name, description, role} = team ?? {} as Team;
  const teamMembersCount = useRTDBDocField<number>(teamsPath(orgId!).path + "/" + id!, CounterFields.TeamMembersCount) || 0;

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const docReference = doc(teamsPath(orgId!), id);

  async function onDeleteConfirm() {
    handleDeleteTeam(docReference, id, name);
  }

  if(!team) return null

  return <>
    <DeleteDialog
      isOpen={isDeleteDialogOpen}
      title={en.screen.TeamsAndPeople.label.deleteDialogTitle(team.name)}
      text={en.common.label.deleteConfirmationText}
      handleConfirm={onDeleteConfirm}
      handleClose={() => setIsDeleteDialogOpen(false)}
    />

    <Stack
      sx={{
        backgroundColor: palette.background.paper,
        border: `${theme.spacing(0.125)} solid ${isSelected ? palette.primary.main : "transparent"}`,
        borderRadius: 1
      }}
      px={1} height="100%"
    >
      <Stack direction="row" alignItems="center" height="100%">
        <Stack direction="row" flex={4} gap={1} height="100%">
          <Checkbox
            className={"team-card-item-checkbox"}
            id={`team-card-item-checkbox-${id}`}
            checked={isSelected} onChange={() => handleCheckTeam()}
          />
          <Divider orientation="vertical" flexItem/>
          <Stack direction="row" flex={2} alignItems="center" gap={0.5} py={1} pl={1}>
            <Avatar
              {...team}
              isTeam={true}
              sx={{margin: 0, fontSize: "12px"}}
            />
            <Stack spacing={-1}>
              <LongText variant="h4">{name}</LongText>
              {!!description && (
                <LongText variant="body" color="text.secondary">
                  {description ?? ""}
                </LongText>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Typography variant="h5" flex={1} paddingLeft={2}>{ACCESS_ROLE_LABEL[role]}</Typography>
        <Box flex={1} sx={{display: "grid", placeItems: "center"}} paddingLeft={1}>
          <TeamMembersCountWrapper onClick={() => openTeamMembersDrawer(team)}>
            <Typography variant="h5" color="primary.main" textAlign="center">{teamMembersCount}</Typography>
          </TeamMembersCountWrapper>
        </Box>
        <Stack direction="row" flex={1} justifyContent="flex-end" height="100%" gap={0}>
          <Divider orientation="vertical" flexItem sx={{mr: 1}}/>
          <Stack direction="row" py={1}>
            <SwiftIconButton
              id={`${id}-edit-team-button`}
              onClickFcn={() => onEditClick(team)}
              disabled={Boolean(!access?.[PermissionOperationKey.Update])}
              startIcon={SystemIndex.SystemIcons.Edit}
            />
            <SwiftIconButton
              id={`${id}-delete-team-button`}
              onClickFcn={() => setIsDeleteDialogOpen(true)}
              disabled={Boolean(!access?.[PermissionOperationKey.Delete])}
              startIcon={SystemIndex.SystemIcons.DeleteOutlined}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  </>
}

const TeamMembersCountWrapper = styled(Box)({
  cursor: "pointer",
  borderRadius: 4,
  transition: "all ease 0.5s",
  minWidth: 40,
  "&:hover": {
    backgroundColor: theme.palette.neutral.light,
  }
})

export default TeamCard;
