import {Box, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import {enFormRequirementLabel} from "constants/index";
import theme from "theme/theme";

function DeletedForm() {
  return (
    <Box
      flex={1}
      id="empty-page"
      sx={{
        alignItems: "center",
        justify: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <SystemIcons.Form width={60} height={60} stroke={theme.palette.neutral.light}/>
      <Typography variant="h2">{enFormRequirementLabel.formDeleted}</Typography>
      <Typography
        variant="body"
        sx={(theme) => ({color: theme.palette.text.secondary})}>
        {enFormRequirementLabel.formDeletedDescription}
      </Typography>
    </Box>
  )
}

export default DeletedForm;