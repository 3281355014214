import {organization} from "./organization";
import {project} from "./project";
import {asset} from "./asset";
import {task} from "./task";
import {milestone} from "./milestone";
import {timeline} from "./timeline";
import {fileRequirement} from "./fileRequirement";
import {sidebar} from "./sidebar";
import {member} from "./member";
import {activityFeed} from "./activityFeed";
import {teamsAndPeople} from "./teamsAndPeople";
import {invite} from "./invite";
import {team} from "./team";
import {manageTeamCategories} from "./manageTeamCategories";
import {chat} from "./chat"
import {orgAssets} from "./orgAssets";
import {formRequirement} from "./formRequirement";
import {forms} from "./forms";
import {profileAndSettings} from "./profileAndSettings";
import {templates} from "./templates";
import {timeLog} from "./timeLog";
import {organizationDataTags} from "./organizationDataTags";
import {home} from "./home"
import {user} from "./user";
import {signup} from "./signup";
import {referenceFiles} from "./referenceFiles";
import {rolesAndPermissions} from "./rolesAndPermissions";

export const screen = {
  Chat: {...chat},
  Organization: {...organization},
  Project: {...project},
  Asset: {...asset},
  Task: {...task},
  Milestone: {...milestone},
  Timeline: {...timeline},
  FileRequirement: {...fileRequirement},
  Sidebar: {...sidebar},
  Member: {...member},
  ActivityFeed: {...activityFeed},
  TeamsAndPeople: {...teamsAndPeople},
  Forms: {...forms},
  Invite: {...invite},
  Team: {...team},
  ManageTeamCategories: {...manageTeamCategories},
  OrgAssets: {...orgAssets},
  FormRequirement: {...formRequirement},
  ProfileAndSettings: {...profileAndSettings},
  Template: {...templates},
  TimeLog: {...timeLog},
  User: {...user},
  OrganizationDataTags: organizationDataTags,
  Home: home,
  Signup: signup,
  ReferenceFiles: referenceFiles,
  RolesAndPermissions: rolesAndPermissions,
}