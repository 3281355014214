import {Entity} from "enums/entity";
import DeleteButton from "../Button/BulkActionButtons/DeleteButton";
import HideOrUnHideButton from "../Button/BulkActionButtons/HideOrUnHideButton";
import AssignedToButton from "../Button/BulkActionButtons/AssignedToButton";

interface ActionButtonsProps {
  atLeastOneSelected: boolean;
  parentEntity: Entity.ProjectLevelTask | Entity.Asset | Entity.Templates;
  showHidden: boolean;
  openDrawer: () => void;
  openDialog: () => void;
  toggleHiddenTasks: {
    toggleLoading: boolean;
    toggleFunction: () => void;
  };
}

function ActionButtons(props: ActionButtonsProps) {
  const {atLeastOneSelected, parentEntity, showHidden, openDrawer, openDialog, toggleHiddenTasks} = props;
  const {toggleLoading, toggleFunction} = toggleHiddenTasks;

  if (!atLeastOneSelected) return null;

  return (
    <>
      <HideOrUnHideButton
        parentEntity={parentEntity}
        isLoading={toggleLoading}
        onButtonClick={toggleFunction}
        showHidden={showHidden}
        disabled={toggleLoading}
      />
      <AssignedToButton
        parentEntity={parentEntity}
        onButtonClick={openDrawer}
        disabled={toggleLoading}
      />
      <DeleteButton
        onButtonClick={openDialog}
        disabled={toggleLoading}
      />
    </>
  )
}

export default ActionButtons;