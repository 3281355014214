import {Member, User} from "types/index";
import {SelectItem} from "components/inputs/SearchInput/SearchPopUp";
import generateSelectableUserItemFromUser from "screens/utility/SelectGenerators/generateSelectableUserItemFromUser";
import {noOne} from "constants/noOne";
import {userPath} from "screens/utility";
import {collection, getDoc, getDocs} from "firebase/firestore";
import {db} from "../../../../firebase";

interface generatePointOfContactsListProps {
  organizationId: string | null,
  members: Member[],
  isAdmin: boolean,
  uid: string,
}

export async function generatePointOfContactsList(props: generatePointOfContactsListProps) {
  const {organizationId, members, uid, isAdmin} = props;

  const currentUser = (await getDoc(userPath(uid))).data() as User;

  // during organization creation, org is null
  if (isAdmin) {
    const users = (await getDocs(collection(db, "users", uid, "users"))).docs.map((doc) => doc.data() as User);
    const selectItems = users.map((user) => generateSelectableUserItemFromUser(user));
    if (selectItems.some(item => item.value === currentUser.email)) {
      return [
        ...selectItems,
        noOne
      ]
    }

    return [
      generateSelectableUserItemFromUser(currentUser),
      ...selectItems,
      noOne
    ];
  }

  if (organizationId === null) {
    const filteredMembers = members.filter((member) => member["@id"] !== currentUser["@id"]);
    return [
      ...filteredMembers.map((member) => generateSelectableUserItemFromUser(member as unknown as User)),
      generateSelectableUserItemFromUser(currentUser),
      noOne
    ]
  }

  const selectItems: SelectItem[] = [...members.map((member) => generateSelectableUserItemFromUser(member as unknown as User)), noOne];

  return selectItems;
}