import {Entity} from "enums/entity";
import theme from "theme/theme";
import {EmptyList} from "components/index";
import {useEffect, useState} from "react";
import {enInviteLabel, enTeamsAndPeopleLabel} from "constants/index";

interface DisplayEmptyProps {
  entity: Entity;
}

function DisplayEmpty(props: DisplayEmptyProps) {
  const {entity} = props;

  const [emptyDescription, setEmptyDescription] = useState<string>("");

  useEffect(() => {
   if(entity === Entity.TeamsAndPeople || entity === Entity.Member)
     setEmptyDescription(enTeamsAndPeopleLabel.emptyInviteDescription);
   if(entity === Entity.Organization || entity === Entity.ActivityFeed)
     setEmptyDescription(enInviteLabel.emptyDescription);
  }, [entity]);

  return (
    <EmptyList
      entity={Entity.Invite}
      description={emptyDescription}
      logoProperties={{
        stroke: theme.palette.neutral.main,
        fill: theme.palette.background.paper,
        strokeWidth: 1.5,
        width: 64,
        height: 64
      }}
    />
  )
}

export default DisplayEmpty;