import {enCommonButton, enCommonLabel} from "constants/index";
import {useNavigate} from "react-router-dom";
import {Box, Button, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {SelectedOrg} from "types/SelectedOrg";

export enum PermissionDisplayType {
  PAGE = "page",
  LIST = "list",
}

interface NoPermissionProps {
  displayType?: PermissionDisplayType;
  fullHeight?: boolean;
  selectedOrg?: SelectedOrg;
}

function NoPermission(props: NoPermissionProps) {
  const {fullHeight, selectedOrg, displayType = PermissionDisplayType.PAGE} = props;
  const navigate = useNavigate();

  return (
    <Stack
      flex={1}
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{bgcolor: theme.palette.background.paper, height: fullHeight ? '100vh' : '50vh'}}
    >
      <Box p={2}>
        <SystemIcons.LockOutlined
          stroke={theme.palette.neutral.light}
          style={{
            width: 64,
            height: 64,
          }}
        />
      </Box>
      <Typography variant="h4" color={theme.palette.secondary.main} maxWidth={350} align="center">
        {enCommonLabel.noPermissionLine1(displayType)}
      </Typography>
      <Typography variant="h4" color={theme.palette.secondary.main} maxWidth={350} align="center" mb={4}>
        {enCommonLabel.noPermissionLine2}
      </Typography>
      {displayType === PermissionDisplayType.PAGE && (
        <Button
          variant="contained"
          endIcon={<SystemIcons.ChevronRight stroke={theme.palette.background.paper}/>}
          onClick={() => navigate(`/${selectedOrg?.id ?? "defaultOrg"}/explore-organizations`)}
        >
          {enCommonButton.viewAllOrgs}
        </Button>
      )}
    </Stack>
  )
}

export default NoPermission;