/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {Member, SelectedOrg} from "types/index";
import {doc} from "firebase/firestore";
import {organizationMembersPath, organizationsPath} from "screens/utility/FirebasePath";
import {useRTDBDocField} from "hooks/index";
import DynamicView from "./DynamicView";
import {BaseProps} from "screens/BaseProps";
import {ActionType, CounterFields, MemberActionType, ProcessType, Severity, ViewStatus} from "enums/index";
import {submitForm} from "screens/utility";
import {EditMemberDrawer} from "components/Drawers";
import DeleteDialog from "components/Dialogs/DeleteDialog";
import {emptyFunction, enCommonLabel, enMemberLabel} from "constants/index";
import {Avatar, Stack} from "@mui/material";
import {AccessType} from "types/Permission";
import {InProgress} from "components/index";
import {useNavigate} from "react-router-dom";

interface MembersViewProps extends BaseProps {
  screenName: string;
  selectedOrg: SelectedOrg;
  access: AccessType | null;
}

export default function MembersView(props: MembersViewProps) {
  const {toastProps, screenName, selectedOrg, access, uid, setSelectedOrgId = emptyFunction} = props;
  const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps!;

  const navigate = useNavigate();

  const membersCount = useRTDBDocField<number>(doc(organizationsPath(), selectedOrg.id).path, CounterFields.MembersCount) || 0;
  const [actionTargetMember, setActionTargetMember] = useState<Member | null>(null);
  const [targetMemberAction, setTargetMemberAction] = useState<MemberActionType | null>(null);
  const [editMemberIcon, setEditMemberIcon] = useState<React.ReactNode>(<></>);

  useEffect(() => {
    if (!actionTargetMember) return;

    setEditMemberIcon(
      <Stack direction="row" justifyContent="center" mb={1}>
        <Avatar
          sx={(theme) => ({
            backgroundColor: actionTargetMember.avatarColor ?? theme.palette.common.black,
            width: 40,
            height: 40,
          })}
        >
          {actionTargetMember.initials}
        </Avatar>
      </Stack>
    )
  }, [actionTargetMember]);

  function onMemberCardActionClick(member: Member | null, action: MemberActionType | null) {
    setActionTargetMember(member);
    setTargetMemberAction(action);
  }

  function closeMemberActionHandlers() {
    setActionTargetMember(null);
    setTargetMemberAction(null);
  }

  async function onMemberDeleteConfirm() {
    if (selectedOrg.id === undefined || !actionTargetMember) return;

    const docRef = doc(organizationMembersPath(selectedOrg.id), actionTargetMember.uid);
    await submitForm(docRef, ActionType.Delete, statusHandler);
  }

  function statusHandler(status: ViewStatus, data: Member, isLastUpdate: boolean) {
    if (!isLastUpdate) return;
    switch (status) {
      case ViewStatus.Finished:
        setToastMessage(enMemberLabel.deleteSuccess);
        setToastSeverity(Severity.Success);
        setIsToastOpen(true);

        // if one of the selected members is the current user, navigate to home
        if (actionTargetMember?.uid === uid) {
          setSelectedOrgId("defaultOrg");
          navigate("/defaultOrg/explore-organizations");
        }

        closeMemberActionHandlers();
        break;
      case ViewStatus.SecurityError:
      case ViewStatus.ValidationError:
      case ViewStatus.Error:
        setToastMessage(enCommonLabel.errorProcess(ProcessType.Delete));
        setToastSeverity(Severity.Error);
        setIsToastOpen(true);
        break;
    }
  }

  if (!access) return <InProgress/>;

  return (
    <>
      <DynamicView
        uid={uid}
        access={access}
        orgId={selectedOrg.id!}
        membersCount={membersCount}
        screenName={screenName}
        onMemberCardActionClick={onMemberCardActionClick}
        toastProps={toastProps}
        setSelectedOrgId={setSelectedOrgId}
      />
      <EditMemberDrawer
        open={Boolean(actionTargetMember && targetMemberAction === MemberActionType.EDIT)}
        actionTargetMember={actionTargetMember}
        editMemberIcon={editMemberIcon}
        closeEditMemberDrawer={closeMemberActionHandlers}
        {...props}
      />
      <DeleteDialog
        isOpen={Boolean(actionTargetMember && targetMemberAction === MemberActionType.DELETE)}
        text={enMemberLabel.deleteConfirmationText(actionTargetMember?.name ?? "")}
        title={enMemberLabel.deleteConfirmationTitle(actionTargetMember?.name ?? "")}
        handleClose={closeMemberActionHandlers}
        handleConfirm={onMemberDeleteConfirm}
      />
    </>
  )
}