import {ProjectStatus} from "enums/projectStatus";

export const overrideStatusCombinations = [
  `${ProjectStatus.Pending}${ProjectStatus.Completed}`,
  `${ProjectStatus.Completed}${ProjectStatus.Pending}`,
  `${ProjectStatus.Completed}${ProjectStatus.InProgress}`,
  `${ProjectStatus.InProgress}${ProjectStatus.Completed}`,
  `${ProjectStatus.Completed}${ProjectStatus.Cancelled}`,
  `${ProjectStatus.Cancelled}${ProjectStatus.Completed}`,
  `${ProjectStatus.Cancelled}${ProjectStatus.InProgress}`,
  `${ProjectStatus.InProgress}${ProjectStatus.Pending}`
];