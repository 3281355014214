import {ConfirmDialog} from "components/index";
import React from "react";
import {DialogProps} from "components/ConfirmDialog";
import theme from "theme/theme";
import {en} from "language/en";
import {OptionalType} from "types/OptionalType";

type UnsavedChangesDialogProps = OptionalType<DialogProps, "confirmButtonText" | "title" | "text">

export default function UnsavedChangesDialog(props: UnsavedChangesDialogProps) {
  return <ConfirmDialog
    {...props}
    title={en.screen.FileRequirement.dialog.title}
    text={en.common.label.unsavedChangesText}
    confirmButtonText={en.screen.FileRequirement.dialog.confirmButtonText}
    confirmButtonSx={{
      paddingX: 2,
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.text.onDark,
      },
    }}
  />
}