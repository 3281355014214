import React from "react";
import {SystemIcons} from "assets/icons/system/system.index";
import MenuCard from "components/MenuCard";
import ActionOption from "components/ActionOption";
import {emptyFunction, enProjectButton} from "constants/index";
import {AccessType, PermissionEntity, PermissionOperationKey} from "types/Permission";
import {useAccess} from "hooks/index";
import {Entity} from "enums/entity";
import {FeatureType} from "enums/featureType";
import FeatureWrapper from "components/FeatureWrapper";

interface OptionsProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  closeMenu: (e: React.MouseEvent<HTMLElement>) => void;
  deleteProject: (e: React.MouseEvent<HTMLElement>) => void;
  access: AccessType | null;
  usesTemplate: boolean;
  isImporting: boolean;
  uid: string;
  openImportDialog: () => void;
  downloadTimeData: () => void;
  openExportDialog: () => void;
}

function HeaderMoreOptionsMenu(props: OptionsProps) {
  const {open, anchorEl, access, usesTemplate, isImporting, uid} = props;
  const {closeMenu, deleteProject, downloadTimeData, openImportDialog, openExportDialog} = props;

  const [downloadAccess] = useAccess({
    uid,
    entity: Entity.Project,
    documentDocId: PermissionEntity.ProjectExport,
  });

  const [importAccess] = useAccess({
    uid,
    entity: Entity.Project,
    documentDocId: PermissionEntity.ProjectImport,
  });

  const isDownloadDisabled = Boolean(!downloadAccess?.[PermissionOperationKey.Create]);
  const isImportDisabled = Boolean(!importAccess?.[PermissionOperationKey.Create]) || usesTemplate || isImporting;
  const isDeleteDisabled = Boolean(!access?.[PermissionOperationKey.Delete]);

  return (
    <MenuCard open={open} anchorEl={anchorEl} closeMenu={closeMenu}>
      <ActionOption
        Icon={<SystemIcons.DownloadTimeData height={16} width={16}/>}
        label={enProjectButton.downloadTimeData}
        onClick={isDownloadDisabled ? emptyFunction : downloadTimeData}
        disabled={isDownloadDisabled}
      />

      <FeatureWrapper feature={FeatureType.ImportProject} viewable>
        <ActionOption
          Icon={<SystemIcons.Import height={16} width={16}/>}
          label={enProjectButton.importProjectData}
          onClick={(isImportDisabled) ? emptyFunction : openImportDialog}
          disabled={isImportDisabled}
        />
      </FeatureWrapper>

      <ActionOption
        Icon={<SystemIcons.Export height={16} width={16}/>}
        label={enProjectButton.exportProjectData}
        onClick={isDownloadDisabled ? emptyFunction : openExportDialog}
        disabled={isDownloadDisabled}
      />
      <ActionOption
        Icon={<SystemIcons.DeleteOutlined height={16} width={16}/>}
        label={enProjectButton.deleteProject}
        onClick={isDeleteDisabled ? emptyFunction : deleteProject}
        disabled={isDeleteDisabled}
        labelSx={(theme) => ({color: theme.palette.error.main})}
      />
    </MenuCard>
  );
}

export default HeaderMoreOptionsMenu;