import {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {enCommonButton, enTaskLabel} from "constants/index";
import {onChangeInput} from "screens/utility/formUtils";
import {LoadingButton} from "@mui/lab";

interface RejectionDialogProps {
  isOpen: boolean;
  handleClose: (e?: any) => void;
  handleConfirm: (reason: string) => void;
}

function RejectionDialog(props: RejectionDialogProps) {
  const {isOpen, handleClose, handleConfirm} = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const [validationMessage, setValidationMessage] = useState<string>("");

  useEffect(() => {
    if (!isOpen) {
      setIsLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if(reason === "") return;

    setValidationMessage((reason.trim()) ? "" : enTaskLabel.requiredRejectionReason);
  }, [reason]);

  function onSubmit() {
    setIsLoading(true);
    handleConfirm(reason);
  }

  return (
    <Dialog
      id="task-rejection-dialog"
      open={isOpen}
      onClick={(e) => e.stopPropagation()}
      onClose={handleClose}
    >
      <DialogTitle
        id={"task-rejection-dialog-title"}
        sx={{fontWeight: "bold", fontSize: 20}}
      >
        {enTaskLabel.rejectionReason}
      </DialogTitle>
      <DialogContent>
        <TextField
          id="task-rejection-dialog-reason"
          label=""
          type="text"
          variant="standard"
          sx={{width: "400px"}}
          multiline={true}
          placeholder=""
          onClick={(e) => e.stopPropagation()}
          error={Boolean(validationMessage.length)}
          helperText={validationMessage}
          onChange={(e) => onChangeInput(e, setReason)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          id="task-rejection-dialog-cancel-button"
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.secondary,
          })}
          onClick={handleClose}
          disabled={isLoading}
          >
          {enCommonButton.cancel}
        </Button>
        <LoadingButton
          loading={isLoading}
          id="task-rejection-dialog-submit-button"
          variant="contained"
          onClick={onSubmit}
          autoFocus
          disabled={!Boolean(reason.trim().length)}
          >
          {enCommonButton.submit}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default RejectionDialog;