export enum ActivityType {
  StatusChange = "StatusChange",
  Assigned = "Assigned",
  AddedNewAccessListMember = "AddedNewAccessListMember",
  Scheduled = "Scheduled",
  Unassigned = "Unassigned",
  Created = "Created",
  RemovedAccesssListMember = "RemovedAccessListMember",
  Overdue = "Overdue",
  DueSoon = "DueSoon",
  Invited = "Invited",
  Deleted = "Deleted",
  FileUpload = "FileUpload",
  Updated = "Updated",
  AnsweredForm = "AnsweredForm",
}
