import {Previews} from "enums/supportedPreviews";

export function getFileType(contentType: string | undefined) {
  if((/^image\//).test(contentType!)) {
    return Previews.Image;
  }

  if((/^video\//).test(contentType!)) {
    return Previews.Video;
  }

  if(contentType === "application/pdf") {
    return Previews.PDF;
  }

  if(contentType === "application/x-zip-compressed") {
    return Previews.Zip;
  }

  if (contentType === "application/msword" || contentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
    return Previews.Document;
  }

  return Previews.File;
}