export const referenceFiles = {
  label: {
    referenceFiles: "Reference Files",
    noPermission: "You do not have permission for this action",
    searchReferenceFile: "Search Reference File...",
    emptyTitle: "No Reference Files yet",
    emptyDescription: "Create the first reference file.",
    bulkDeleteSuccess: (x: number) => `${x} reference files deleted successfully`,
    deleteRefConfirmationTitle: "Are you sure you want to delete this reference file?",
    deleteRefConfirmationText: "You can't go back after deleting. This action is irreversible.",
    editReferenceFile: "Edit Reference File",
    updateSuccess: "Reference file updated successfully",
    createSuccess: "Reference file created successfully",
    nameNotUnique: "Reference file name must be unique",
    uploadError: "Encountered an error while uploading a reference file.",
    fileTypeNotSupported: "Selected file type not supported",
    noAvailableReferenceFiles: "No Available Reference Files"
  },
  button: {
    createNewReference: "Create New Reference File",
    addFile: "Add File",
    uploadingFile: "Uploading File...",
  }
}