import {FileRequirement} from "types/FileRequirement";
import {RequirementStatus} from "enums/requirementStatus";

export const initialRequirement: FileRequirement = {
  name: "",
  description: "",
  filesApprovedCount: 0,
  minimumFiles: 0,
  maximumFiles: 1,
  fileRequirementStatus: RequirementStatus.Pending,
  referenceFileId: null
}