import {Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import {emptyFunction, enCommonLabel} from "constants/index";
import {LoadingButton} from "@mui/lab";
import {BulkActionButtonProps} from "./BulkActionButtonProps";
import {Entity} from "enums/entity";
import theme from "theme/theme";

interface HideOrUnHideButtonProps extends BulkActionButtonProps {
  isLoading: boolean;
}

function HideOrUnHideButton(props: HideOrUnHideButtonProps) {
  const {parentEntity = Entity.Asset, disabled, showHidden = false, isLoading, onButtonClick} = props;

  if(parentEntity !== Entity.Templates) return null;
  const iconProps = disabled ? {stroke: theme.palette.text.secondary} : {};
  const startIcon = showHidden ? <SystemIcons.EyeOff {...iconProps} width={16}/>
    : <SystemIcons.Eye {...iconProps} width={16}/>;

  return (
    <LoadingButton
      size="small"
      color="secondary"
      loading={isLoading}
      onClick={disabled ? emptyFunction : onButtonClick}
      startIcon={startIcon}
      title={disabled ? enCommonLabel.noPermissionForAction : undefined}
      sx={{
        ...(disabled ? {
          cursor: "default",
          ":hover": {
            backgroundColor: "transparent"
          }
        } : {}),
      }}
    >
      <Typography variant="h5">
        {showHidden ? enCommonLabel.unHide : enCommonLabel.hide}
      </Typography>
    </LoadingButton>
  )
}

export default HideOrUnHideButton;