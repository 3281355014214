import {CollectionReference, doc, documentId, getDoc, getDocs, query, where} from "firebase/firestore";
import {useEffect, useRef, useState} from "react";
import {AccessRole, Entity, SystemRole} from "enums/index";
import {Member, User, UserAccess} from "types/index";
import {BaseProps} from "screens/BaseProps";
import {useParams} from "react-router-dom";
import {noAccess, ownerAccess,} from "constants/index";
import {
  assetAccessListPath,
  fileRequirementAccessListPath,
  formRequirementAccessListPath,
  organizationMembersPath,
  permissionDocumentPath,
  projectAccessListPath,
  taskAccessListPath,
  userPath,
} from "screens/utility/FirebasePath";
import {getPermissionValues} from "../screens/utility";
import {AccessType, PermissionEntity, PermissionOperationKey} from "types/Permission";

export interface useAccessProps extends BaseProps {
  uid: string;
  entity: Entity;
  documentDocId: PermissionEntity;
  ids?: any;
  fromParentPermission?: boolean;
  accessListRef?: CollectionReference | null;
}

/**
 * Gets roles (system/user) of the given user in the given document and its permission
 * @param props - contains the ff:
 * <ul style="list-style: none;">
 *   <li>uid: userid of the permission to be checked</li>
 *   <li>entity: will be used as basis for getting accesslist path</li>
 *   <li>documentDocId: permission document id</li>
 *   <li>*ids: key/value pairs stored in params</li>
 *   mainly used by popovers or instances where you want the check the permission of
 *   the document without selecting it, thus that key/id does not exist in the useParams.
 *   <li>accessListRef: collectionReference where to fetch the accesslist.</li>
 * </ul>
 * @returns - [access, role, setIds]
 * @updates -
 * <ul style="list-style: none;">
 *   <li> 12/5/2023 - The function now supports skipping the fetching of permissions based on the collection when the access role is identified as "Owner". In such cases, the access is set to ownerAccess, and the role is designated as SystemOwner. </li>
 * </ul>
 */

function useAccess(props: useAccessProps): [AccessType | null, string, (ids: any) => void, (ref: CollectionReference | null) => void] {
  const {uid, entity, documentDocId, ids = {}, fromParentPermission = false, accessListRef = undefined} = props;
  const {
    orgId: paramOrgId,
    projId: paramProjId,
    assetId: paramAssetId,
    milestoneId: paramMilestoneId,
    taskId: paramTaskId,
    fileRequirementId: paramFileRequirementId,
    formRequirementId: paramFormRequirementId,
  } = useParams();

  const isMounted = useRef(true);

  const [localIds, setLocalIds] = useState<any>(ids);

  // fetch role of the user in the organization
  const [orgRole, setOrgRole] = useState<AccessRole | null>(null);

  const [access, setAccess] = useState<AccessType | null>(null);
  const [role, setRole] = useState<string>(SystemRole.SystemUser);
  const [collectionRef, setCollectionRef] = useState<CollectionReference | null | undefined>(accessListRef);

  //update localIds if ids change
  useEffect(() => {
    //stringified as react doesn't detect changes in objects
    if (JSON.stringify(localIds) === JSON.stringify(ids)) return;

    setLocalIds(ids);
  }, [ids]);

  useEffect(() => {
    // if there is no orgId, then there is no need to fetch the orgRole
    if (!paramOrgId) return;

    if (paramOrgId === "defaultOrg") return;

    if (!isMounted.current) return;

    const getOrgRole = async () => {
      const member = await getDoc(doc(organizationMembersPath(paramOrgId), uid));
      const memberData = member.data() as Member;
      setOrgRole(memberData?.role ?? null);
    }

    getOrgRole();

    return () => {
      isMounted.current = false; // Set the component as unmounted
      // Cancel any ongoing asynchronous tasks or subscriptions here
    };
  }, []);

  useEffect(() => {
    if (orgRole === AccessRole.Owner) return;

    const getAccess = async () => {
      const orgId = localIds.orgId ?? paramOrgId;
      const projId = paramProjId ?? localIds.projId;
      const assetId = paramAssetId ?? localIds.assetId;
      const milestoneId = paramMilestoneId ?? localIds.milestoneId;
      const taskId = paramTaskId ?? localIds.taskId;
      const fileRequirementId = paramFileRequirementId ?? localIds.fileRequirementId;
      const formRequirementId = paramFormRequirementId ?? localIds.formRequirementId;

      if (!orgId || orgId === "defaultOrg") return;

      // get user systemRole
      const user = (await getDoc(userPath(uid))).data() as User;
      if (user.role === SystemRole.SystemOwner) {
        setAccess(ownerAccess);
        setRole(user.role);
        return;
      }

      // if currentColRef is set to null, then it means that the accessListRef is not yet set
      if (!accessListRef === null) return;

      switch (entity) {
        case Entity.Organization:
          setCollectionRef(organizationMembersPath(orgId!));
          break;
        case Entity.Project:
          setCollectionRef(fromParentPermission ? organizationMembersPath(orgId!)
            : projectAccessListPath(orgId!, projId!));
          break;
        case Entity.Asset:
          setCollectionRef(fromParentPermission ? projectAccessListPath(orgId!, projId!)
            : assetAccessListPath(orgId!, projId!, assetId!));
          break;
        case Entity.Task:
          setCollectionRef(fromParentPermission ? projectAccessListPath(orgId!, projId!)
            : taskAccessListPath(orgId!, projId!, assetId, milestoneId!, taskId!))
          break;
        case Entity.FileRequirement:
          setCollectionRef(fromParentPermission ? taskAccessListPath(orgId!, projId!, assetId, milestoneId!, taskId!)
            : fileRequirementAccessListPath(orgId!, projId!, assetId!, milestoneId!, taskId!, fileRequirementId!))
          break;
        case Entity.FormRequirement:
          setCollectionRef(fromParentPermission ? taskAccessListPath(orgId!, projId!, assetId, milestoneId!, taskId!)
            : formRequirementAccessListPath(orgId!, projId!, assetId, milestoneId!, taskId!, formRequirementId!))
          break;
        default:
          setCollectionRef(organizationMembersPath(orgId!));
          break;
      }
    }

    getAccess();
  }, [localIds]);

  // once the collection reference has been set, query accesslist
  useEffect(() => {
    // override access if user is an owner
    if (orgRole === AccessRole.Owner) {
      setAccess(ownerAccess);
      setRole(SystemRole.SystemOwner);
      return;
    }

    if (collectionRef === null) return;

    const getAccessList = async () => {
      if (!collectionRef) return;

      // get access as a person
      const accessList = await getDocs(query(collectionRef, where(documentId(), "==", uid)));

      let accessRole = AccessRole.User;
      const personDocs = accessList.docs.map(doc => doc.data() as UserAccess);

      // if not included in accessList, check if user is included in the teamIds
      if (personDocs.length === 0) {
        const teamAccessList = await getDocs(query(collectionRef, where("teamMemberUids", "array-contains", uid)));
        const teamDocs = teamAccessList.docs.map(doc => doc.data() as UserAccess).sort(sortByHierarchy);
        if (teamDocs.length === 0) {
          setAccess(noAccess);
          return;
        }
        const access = teamDocs[0];
        accessRole = access.role;
        setRole(access.role);

      } else {
        const access = personDocs[0];
        accessRole = access.role;
        setRole(access.role);
      }

      // get permission
      const permissionDoc = (await getDoc(permissionDocumentPath(documentDocId))).data();
      //console.log(permissionDoc, "permissionDoc", documentDocId, permissionDocumentPath(documentDocId).path);
      if (permissionDoc === undefined) {
        setAccess(noAccess);
        return;
      }

      const documentAccess = getPermissionValues(permissionDoc, accessRole, PermissionOperationKey);
      setAccess(documentAccess);
    }

    getAccessList();
  }, [collectionRef?.path, orgRole]);

  function updateIds(ids: any) {
    setLocalIds(ids);
  }

  function updateAccessListRef(ref: CollectionReference | null) {
    setAccess(null);
    if (ref === null) return;

    setCollectionRef(ref);
  }

  return [access, role, updateIds, updateAccessListRef];
}

function sortByHierarchy(a: UserAccess, b: UserAccess) {
  const hierarchy: string[] = ["Owner", "Admin", "PowerUser", "User", "Guest"];
  const roleAIndex: number = hierarchy.indexOf(a.role);
  const roleBIndex: number = hierarchy.indexOf(b.role);

  // Put unknown roles at the end
  if (roleAIndex === -1) {
    return 1;
  }

  // Put unknown roles at the end
  if (roleBIndex === -1) {
    return -1;
  }

  // Sort based on hierarchy index
  return roleAIndex - roleBIndex;
}

export default useAccess;