import theme from "theme/theme";
import {Grid, Stack, Typography} from "@mui/material";
import React from "react";
import {enTaskLabel} from "constants/index";
import {styled} from "@mui/material/styles";
import ListContainer from "./ListContainer";
import {Entity} from "enums/entity";
import {AccessType} from "types/Permission";
import {ENTITY_LABEL} from "language/en/common";

interface EmptyListProps {
  access: AccessType | null;
  onCreateButtonClick: () => void;
  requirementsCount: number;
  entity: Entity.FileRequirement | Entity.FormRequirement;
}

function EmptyList(props: EmptyListProps) {
  const {entity} = props;

  return (
    <ListContainer {...props} >
      <Grid
        container
        sx={{bgcolor: "#fff", mb: 1, borderRadius: 1, padding: "8px"}}
        justifyContent="space-between"
        direction="row"
        alignItems="center"
      >
        <Grid item xs={9} sm={8} justifyContent="flex-start" alignItems="center">
          <Item direction="column" textAlign="left" justifyContent="flex-start">
            <Typography
              sx={{color: theme.palette.secondary.main, fontWeight: 600}}
              variant="subtitle2"
              noWrap>
              {enTaskLabel.emptyRequirementTitle(ENTITY_LABEL[entity])}
            </Typography>
            <Typography sx={{color: theme.palette.neutral.dark}} variant="caption">
              {enTaskLabel.createFirstRequirement(ENTITY_LABEL[entity])}
            </Typography>
          </Item>
        </Grid>
      </Grid>
    </ListContainer>
  )
}

/** start of styles */
const Item = styled(Stack)(({theme}) => ({
  padding: theme.spacing(1),
  textAlign: 'left',
  alignContent: 'center',
  justifyContent: 'flex-start',
}));

/** end of styles */

export default EmptyList;