import {ListItemIcon, ListItemText, MenuItem, Typography} from "@mui/material";
import theme from "theme/theme";
import React from "react";
import {Variant} from "@mui/material/styles/createTypography";
import {SelectBase} from "components/SelectStatus/index";
import {milestoneTaskandAssetStatus as TaskStatus} from "enums/milestoneTaskandAssetStatus";

interface SelectMenuItemsProps<T extends SelectBase> {
  id: string;
  itemList: Record<string, T> | null;
  disabledStatuses?: string[];
  selectItemVariant?: Variant;
  selectItemSx?: any;
  setSelected: (obj: T) => void;
  onMenuToggle: (e: React.MouseEvent<HTMLElement>) => void;
}

function SelectMenuItems<T extends SelectBase>(props: SelectMenuItemsProps<T>) {
  const {id, itemList, disabledStatuses, selectItemVariant = "h5", selectItemSx = {}} = props;
  const {setSelected, onMenuToggle} = props;

  if (itemList === null) return <></>;

  const statusItem: any = [];

  function onSelectChange(e: React.MouseEvent<HTMLElement>, obj: T) {
    const isDisabled = obj.isSelected || disabledStatuses?.includes(obj.title) || false

    if (isDisabled) return;

    onMenuToggle(e);
    setSelected(obj);
  }

  Object.entries(itemList).forEach((obj, index) => {
    let objProperties = obj[1];

    const isDisabled = objProperties.isSelected || disabledStatuses?.includes(objProperties.title) || false

    statusItem.push(
      <MenuItem
        id={`${id}-${index}`}
        key={`select-status-${index}`}
        onClick={(e) => onSelectChange(e, obj[1])}
        sx={{padding: 1/2, margin: 0.5, ...selectItemSx, ...(obj[1].isHidden ? {display: "none"} : {})}}
      >
        {obj[1].icon !== undefined &&
            <ListItemIcon>
              {objProperties.icon({
                fill: isDisabled ? theme.palette.neutral.dark : objProperties.color,
                ...(objProperties.title === TaskStatus.Submitted ? {
                  strokeWidth: 6,
                  width: 17,
                  ...(isDisabled ? {stroke: theme.palette.neutral.dark}: {})
                }: {})
              })}
            </ListItemIcon>
        }
        <ListItemText>
          <Typography
            variant={selectItemVariant}
            color={isDisabled ? theme.palette.neutral.dark : theme.palette.secondary.main}
            className={isDisabled ? "select-status-item-disabled" : "select-status-item-enabled"}
          >
            {objProperties.title}
          </Typography>
        </ListItemText>
      </MenuItem>
    );
  })

  return statusItem;
}

export default SelectMenuItems;
