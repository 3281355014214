export default function downloadCSV(data: string, fileName?: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName || 'download.csv';
    a.click();
    URL.revokeObjectURL(url);
};

