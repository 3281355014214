import {Stack, Typography} from "@mui/material";
import React, {ReactElement} from "react";
import theme from "theme/theme";
import {CollapsableText} from "components/index";
import RequiredResponse from "../Objects/RequiredResponse";

interface FormFieldContainerProps {
  fieldId: string;
  title: string;
  responseIsRequired?: boolean;
  description?: string;
  children: ReactElement;
}

function FormFieldContainer(props: FormFieldContainerProps) {
  const {fieldId, title, responseIsRequired, description, children} = props;
  return (
    <Stack direction="row" paddingLeft={1} id={fieldId} gap={1}>
      <Stack direction="column">
        <Stack direction="row">
          <Typography
            color={theme.palette.secondary.main}
            variant="h5"
          >
            {title}
          </Typography>
          <RequiredResponse isRequired={responseIsRequired}/>
        </Stack>
        {description ? (
            <CollapsableText text={description} id="form-field-description" overflowWrap={"anywhere"}/>
        ) : (
            <></>
        )}
        {children}
      </Stack>
    </Stack>
  )
}

export default FormFieldContainer;