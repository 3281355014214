import {Box, Stack} from "@mui/material";
import SystemIndex from "assets/icons/system/system.index";
import {RemovedAccessListMemberItemType} from "types/ActivityFeedTypes";
import TimestampTypo from "components/Typography/TimestampTypo";
import {enActivityFeedLabel} from "constants/index";
import {formatDateTimeFirestoreDate} from "screens/utility/formatDateTimeFirestoreDate";
import ItemContainer from "components/ActivityFeed/ItemContainer";

interface RemovedAccessListMemberItemProps {
  item: RemovedAccessListMemberItemType
}

function RemovedAccessListMemberItem(props: RemovedAccessListMemberItemProps) {
  const {item} = props;
  const {details, timestamp} = item;
  const {name, by} = details;
  const {date, time} = formatDateTimeFirestoreDate(timestamp);

  return (
    <ItemContainer>
      <Box width={24} height={24}>
        <SystemIndex.SystemIcons.RemoveUser style={{minWidth: 24, height: 24}} fontWeight={700}/>
      </Box>
      <Stack>
        {enActivityFeedLabel.removedMember(name, by.name ?? "")}
        <TimestampTypo>{`${date} ${time}`}</TimestampTypo>
      </Stack>
    </ItemContainer>
  );
}

export default RemovedAccessListMemberItem;
