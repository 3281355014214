import {SelectedOrg} from "types/SelectedOrg";
import {AccessRole} from "enums/accessRole";

export const defaultSelectedOrg: SelectedOrg = {
  name: "Select Organization",
  accessRole: AccessRole.User,
  id: "defaultOrg",
  isAdminView: true,
  members: [],
  teams: [],
}