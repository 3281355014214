import {ActivityItem} from "types/ActivityFeedTypes";
import {Entity} from "enums/entity";
import {EntityChat} from "types/EntityChat";
import {Timer} from "types/Timer";

export interface BreadCrumbType {
  name: string,
  path: string
}

type AcceptedBreadcrumbs = ActivityItem | EntityChat | Timer;
type BreadcrumbProps = AcceptedBreadcrumbs & {
  chatEntity?: Entity,
  requirementName?: string,
  formRequirementName?: string,
  fileRequirementName?: string
};

function generateEntityBreadcrumbs(item: BreadcrumbProps): BreadCrumbType[] {
  if(!item) return []

  const {
    chatEntity,
    entity,
    orgName,
    projectName,
    assetName,
    taskName,
    requirementName,
    formRequirementName,
    fileRequirementName,
  } = item;

  // @ts-ignore
  const itemName = item?.name || "";

  let refPath = entity === undefined
    ? (item as Timer).orgTaskRefPath
    : (item as ActivityItem).entityRefPath;

  if (entity === Entity.UserInvite) return [];

  const breadCrumbNames = [orgName, projectName, assetName, taskName, requirementName ?? formRequirementName ?? fileRequirementName];

  // Ensure the refPath starts with a "/"
  const normalizedRefPath = refPath.charAt(0) === "/" ? refPath : "/" + refPath;

  // Split the path into segments and remove the first empty segment (due to leading "/")
  let slicedPaths = normalizedRefPath.split("/").slice(1);

  // replace the path with fileRequirements with file-requirements
  const fileReqIndex = slicedPaths.findIndex(path => path === "fileRequirements");
  if (fileReqIndex !== -1) slicedPaths[fileReqIndex] = "file-requirements";

  // replace the path with formRequirements with form-requirements
  const formReqIndex = slicedPaths.findIndex(path => path === "formRequirements");
  if (formReqIndex !== -1) slicedPaths[formReqIndex] = "form-requirements";

  if (entity === Entity.SwiftFile || entity === Entity.PLFile)
    slicedPaths = slicedPaths.slice(0, slicedPaths.indexOf("files"));

  const isProjectLevel = (entity?.startsWith("PL") || (chatEntity && chatEntity === Entity.PLTask)) || false;

  let breadCrumbs: BreadCrumbType[] = [];
  breadCrumbNames.forEach((name, index) => {
    if (name === undefined) return;
    let path: string [] = [""];

    switch (index) {
      case 0: // org
        path = slicedPaths.slice(1, 3);
        break;
      case 1: // project or template
        path = slicedPaths.slice(1, 4);
        break;
      case 2: // asset
        path = slicedPaths.slice(1, 6);
        break;
      case 3: // task
        path = slicedPaths.slice(1, isProjectLevel ? 8 : 10);
        break;
      case 4: // req
        path = slicedPaths.slice(1, isProjectLevel ? 13 : 12);
        break;
    }

    // if it's a project level entity, manually append project-level-tasks to the path
    if (isProjectLevel && index >= 1) {
      path.splice(3, 0, "project-level-tasks");
    }

    !!name && breadCrumbs.push({
      name: name,
      path:`/${path.join("/")}`
    })
  });

  // push template to breadcrumbs if it's a template
  const templateIndex = slicedPaths.findIndex(path => path === "templates");
  if (templateIndex !== -1 && !!itemName) {
    breadCrumbs.push({
      name: itemName,
      path: `/${slicedPaths.slice(1, templateIndex + 2).join("/")}`
    })
  }

  return breadCrumbs;
}

export default generateEntityBreadcrumbs;
