function AIcon(props: any) {
  return <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={40} height={40} rx={4} fill="#F8F8F8"/>
    <path
      d="M16.6665 21.7333H23.3332L24.5332 24.6666H27.3332L20.9998 10H18.9998L12.6665 24.6667H15.4665L16.6665 21.7333ZM19.9998 12.64L22.4931 19.3333H17.5064L19.9998 12.64Z"
      fill="#858585"
    />
    <path
      d="M28 29H12"
      stroke="#858585"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
}

export default AIcon
