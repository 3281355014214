import { onSnapshot, DocumentReference } from "firebase/firestore";
import { useEffect, useState } from "react";

function useDocumentOnly<Type>(docRef: DocumentReference) {
  const [document, setDocument] = useState<Type | null>(null);

  useEffect(() => {
    return onSnapshot(docRef, (querySnapshot) => {
      setDocument(querySnapshot.data() as Type);
    });
  }, []);

  return document;
}

export default useDocumentOnly;
