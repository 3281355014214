import {FormField} from "types/FormField";
import {FormFieldType} from "enums/formFieldType";
import {enFormsLabel} from "constants/index";

/**
 * Validates formfield
 * @param field to be checked
 * @returns a tuple [validity, validationMessage]
 */
export function isFormFieldValid(field: FormField<FormFieldType>): [valid: boolean, message: string] {
  const {type} = field;
  const {responseIsRequired = false} = field;

  if (!responseIsRequired && type !== FormFieldType.Paragraph) return [true, ""];
  if (responseIsRequired && typeof field.value === "undefined") return [false, enFormsLabel.responseRequired];

  switch (type) {
    case FormFieldType.Paragraph:
      const {minChars, maxChars, value: paragraphValue = ""} = field;
      let validStringValue = true;
      let stringMessage = "";
      if(paragraphValue === "" && !responseIsRequired) return [validStringValue, stringMessage];

      if (typeof (minChars) === "number" && paragraphValue.length < minChars) {
        validStringValue = false;
        stringMessage = enFormsLabel.minCharsError(minChars);

        return [validStringValue, stringMessage];
      }

      if (typeof (maxChars) === "number" && paragraphValue.length > maxChars) {
        validStringValue = false;
        stringMessage = enFormsLabel.maxCharsError;

        return [validStringValue, stringMessage];
      }

      return [validStringValue, stringMessage];
    case FormFieldType.Numeric:
      const {allowDecimal, value: numericValue} = field;

      let validNumberValue = true;
      let numberMessage = "";

      if(isNaN(Number(numericValue))) {
        validNumberValue = false;
        numberMessage = enFormsLabel.invalidInputValue;
      }

      if(!allowDecimal && (numericValue + "").indexOf('.') !== -1) {
        validNumberValue = false;
        numberMessage = enFormsLabel.wholeNumberError;
      }
      return [validNumberValue, numberMessage];
    case FormFieldType.Date:
      const {includeTime, value: dateValue = {date: null, time: null}} = field;
      let validDateValue = true;
      let dateMessage = "";

      if(!dateValue.date) {
        validDateValue = false;
        dateMessage = enFormsLabel.invalidInputValue;
      }

      if(includeTime && dateValue.time === null) {
        validDateValue = false;
        dateMessage = enFormsLabel.timeIsRequired;
      }

      return [validDateValue, dateMessage];
    case FormFieldType.List:
      const {selectMultiple, value: listValue = []} = field;
      if(selectMultiple && listValue.length <= 0 && responseIsRequired)
        return [false, enFormsLabel.selectedItemError];
      return [true, ""];
    default:
      return [true, ""];
  }
}