import {DialogActions, DialogContentText, Stack} from "@mui/material";
import {toastProps} from "screens/BaseProps";
import {BaseDialog} from "components/Dialogs/index";
import React, {useEffect, useState} from "react";
import {emptyFunction, enCommonButton, enCommonLabel} from "constants/index";
import DownloadButton from "components/Button/DownloadButton";
import {ActionType, Entity, ExportTaskType, FileFormat, Severity} from "enums/index";
import {SystemIcons} from "assets/icons/system/system.index";
import TextField from "@mui/material/TextField";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {CollectionReference, doc, Timestamp} from "firebase/firestore";
import {submitForm} from "screens/utility";

interface DownloadTimeDataDialogProps {
  isOpen: boolean;
  toastProps: toastProps;
  entity: Entity;
  closeDialog: () => void;
  downloadCollectionRef: CollectionReference;
}

type SelectedState = Record<FileFormat, { isLoading: boolean }>;

function DownloadTimeDataDialog(props: DownloadTimeDataDialogProps) {
  const {entity, downloadCollectionRef, toastProps, closeDialog} = props;
  const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps!;

  const [title, setTitle] = useState<string>("");
  const [selectedState, setSelectedState] = useState<SelectedState>({
    [FileFormat.CSV]: {isLoading: false},
    [FileFormat.Excel]: {isLoading: false}
  });

  const [beforeDate, setBeforeDate] = useState<Date | null>(null);
  const [afterDate, setAfterDate] = useState<Date | null>(null);

  useEffect(() => {
    setTitle(enCommonLabel.downloadTimeData(entity));
  }, [entity]);

  async function onDownloadClick(fileType: FileFormat) {
    setSelectedState((prevState) => ({
      ...prevState,
      [fileType]: {
        ...selectedState[fileType],
        isLoading: true,
      }
    }));

    const downloadData = {
      fileType,
      timeRequested: Timestamp.now(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      beforeDate,
      afterDate,
      type: ExportTaskType.TimeData,
    }

    const downloadRef = doc(downloadCollectionRef);
    await submitForm(
      downloadRef,
      ActionType.Create,
      emptyFunction,
      downloadData
    );

    // once form is submitted, close
    setToastSeverity(Severity.Success);
    setToastMessage(enCommonLabel.successExporting);
    setIsToastOpen(true);
    closeDialog();
  }

  return (
    <BaseDialog {...props} title={title}>
      <>
        <DialogContentText
          sx={{fontSize: 14, color: "black", maxWidth: {sm: "auto", md: "24rem"}}}
          id="alert-dialog-description"
        >
        </DialogContentText>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
        >
          <Stack flex={1} direction="row" gap={2} marginY={1} justifyContent="center">
            <DatePicker
              label="Before Date"
              value={beforeDate}
              disableFuture={true}
              onChange={(newValue: any) => {
                setBeforeDate(newValue);
              }}
              renderInput={(params: any) => (
                <TextField
                  size="small"
                  margin="dense"
                  sx={{maxWidth: "40%"}}
                  {...params}
                />
              )}
            />
            <DatePicker
              label="After Date"
              value={afterDate}
              disableFuture={true}
              onChange={(newValue: any) => {
                setAfterDate(newValue);
              }}
              renderInput={(params: any) => (
                <TextField
                  size="small"
                  margin="dense"
                  sx={{maxWidth: "40%"}}
                  {...params}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
        <DialogActions sx={{display: "flex", justifyContent: "center", paddingLeft: 0, paddingRight: 0, gap: 1.5}}>
          <DownloadButton
            id="download-button-csv"
            isLoading={selectedState[FileFormat.CSV].isLoading}
            onClickFcn={() => onDownloadClick(FileFormat.CSV)}
            label={enCommonButton[FileFormat.CSV]}
            disabled={selectedState[FileFormat.CSV].isLoading}
            startIcon={SystemIcons.CSV}
          />
          <DownloadButton
            id="download-button-csv"
            isLoading={selectedState[FileFormat.Excel].isLoading}
            onClickFcn={() => onDownloadClick(FileFormat.Excel)}
            label={enCommonButton[FileFormat.Excel]}
            disabled={selectedState[FileFormat.Excel].isLoading}
            startIcon={SystemIcons.Excel}
          />
        </DialogActions>
      </>
    </BaseDialog>
  )
}

export default DownloadTimeDataDialog;
