import {Box, IconButton, Popover, Typography} from "@mui/material";
import {enCommonLabel} from "constants/index";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {usePlacesWidget} from "react-google-autocomplete";
import React from "react";
import getEnvKey from "../../screens/utility/getEnvKey";
import {googleMapsApiKey, mapCountryRestrictionKey} from "constants/envKeys";

const MAPS_API_KEY = getEnvKey(googleMapsApiKey);
const MAPS_COUNTRY_RESTRICTION = getEnvKey(mapCountryRestrictionKey) || ["us"];

interface GoogleAutocompleteProps {
  defaultValue?: string;
  onPlacesChange: (place: string | { formatted_address: string, geometry: { location: { lat: () => number, lng: () => number } } }) => void;
}

function GoogleAutocomplete(props: GoogleAutocompleteProps) {
  const {onPlacesChange, defaultValue = ""} = props;

  const {ref} = usePlacesWidget<HTMLInputElement>({
    apiKey: MAPS_API_KEY,
    onPlaceSelected: onPlacesChange,
    options: {
      types: ["address"],
      fields: ["address_components", "formatted_address", "geometry"],
      componentRestrictions: {country: MAPS_COUNTRY_RESTRICTION},
    },
  });

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (ref === null) return null;

  return (
    <Box sx={{alignSelf: "stretch"}}>
      <Typography variant="h5">{enCommonLabel.address}&nbsp;
        <Typography variant="body" fontWeight={400} color={theme.palette.neutral.dark}>
          (optional)
        </Typography>
        &nbsp;
        <IconButton
          sx={{padding: 0}}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <SystemIcons.Info stroke={theme.palette.primary.main} width={16} height={16} strokeWidth={2}/>
        </IconButton>
      </Typography>
      <input
        ref={ref}
        style={{
          padding: "9px 16px",
          borderRadius: "4px",
          borderColor: theme.palette.neutral.medium,
          borderWidth: "1px",
          width: "100%",
        }}
        defaultValue={defaultValue}
        onChange={(e) => {
          onPlacesChange(e.target.value)
        }}
      />
      <Popover
        id="how-to-use-google-autocomplete"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{p: 1}}>
          {enCommonLabel.googleAutocompleteInfo}
        </Typography>
      </Popover>
    </Box>
  )
}

export default GoogleAutocomplete;