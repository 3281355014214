import {BaseSearchInput} from "components/index";
import React from "react";
import {enCommonLabel} from "constants/index";
import {SelectItem} from "components/inputs/SelectWithSearch";

interface SearchInputProps {
  items: SelectItem[];
  searchInputId?: string;
  searchPlaceholder?: string;
  setDisplayedItems: (items: SelectItem[]) => void;
}

function SearchInput(props: SearchInputProps) {
  const {items, searchPlaceholder, searchInputId, setDisplayedItems} = props;

  if(items.length <= 0) return null;

  function handleSearchFunction(searchText: string) {
    setDisplayedItems(items.filter((item) => {
      const regex = new RegExp(searchText, "i");
      return regex.test(item.searchable);
    }))
  }

  return (
    <BaseSearchInput
      autoFocus
      sx={{alignSelf: "center", marginBottom: 2}}
      id={searchInputId || "search-input"}
      placeholder={searchPlaceholder || enCommonLabel.defaultSearchPlaceHolder}
      searchFn={handleSearchFunction}
    />
  )
}

export default SearchInput;