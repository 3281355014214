export const templates = {
  title: "Templates",
  label: {
    exportDialogTitle: "Export Template",
    exportingDialogTitle: "Exporting template data...",
    exportDialogText: "Download template data as CSV/Excel file.",
    overview: "Overview",
    search: "Search Templates",
    allTemplates: "All Templates",
    editingTemplate: "Editing Template",
    emptyTitle: "No Templates yet",
    emptyDescription: "Create the first template.",
    deleteTitle: "Delete Template",
    cannotDelete: "You don't have enough permission to delete this template",
    cannotEdit: "You don't have enough permission to edit this template",
    cannotCreate: "You don't have enough permission to create a template",
    create: "Create New Template",
    edit: "Edit Template",
    requiredName: "Template name is required",
    uniqueNameError: "Template name must be unique",
    editSuccess: "Template edited successfully",
    deleteSuccess: "Template/s Deleted successfully.",
    deleteConfirmationText: "These items will be deleted permanently. You can't undo this action.",
    xSelected: (selected: number) => `${selected} Selected`,
    createSuccess: (name: string) => `Template ${name} created successfully`,
    deleteDialogTitle: (label: number) => `Are you sure you want to delete ${label > 1 ? label + " selected templates" : "this template"}?`,
    deleteFailed: (failedAndErrorMessage: string) => (
      `An error occured while deleting the following project(s): ${failedAndErrorMessage}`
    ),
    deleteProjectSuccess: "Project(s) has been unliked successfully.",
    deleteProjectsText: (count: number) => `Are you sure you want to unlink ${count > 1 ? count : "this"} project(s)?`,
    deleteProjectsTitle: "Unlink project(s)?",
    manageTemplates: "Manage templates",
    importingTemplate: "Importing Template Data...",
    manageTemplatesDescription: "Template used for this project:",
    fileRequirementsCount: "File Requirements Count",
    formRequirementsCount: "Form Requirements Count",
  },
  button: {
    create: "Create New Template",
    deleteTemplate: "Delete Template",
    importTemplate: "Import Template",
    exportTemplate: "Export Template",
    unlinkProject: "Unlink"
  }
}
