import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {enCommonButton} from "constants/index";
import {MouseEventHandler} from "react";
import {LoadingButton} from "@mui/lab";

interface SubmitButtonProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined
}

export default function SubmitButton(props: SubmitButtonProps) {
  const {isLoading = false, isDisabled = false, onClick} = props
  return <LoadingButton
    id="task-status-submit-button"
    variant="contained"
    sx={{
      minWidth: 114
    }}
    startIcon={isLoading ? undefined :
      <SystemIcons.ArrowRight
        height={16}
        width={16}
        stroke={theme.palette.background.paper}
      />
    }
    disableElevation
    onClick={isDisabled ? undefined : onClick}
    disabled={isLoading || isDisabled}
    loading={isLoading}
  >
    {enCommonButton.submit}
  </LoadingButton>
}
