export const sidebar = {
  links: {
    projects: "Projects",
    assets: "Assets",
    teamsAndPeople: "Teams & People",
    calendar: "Calendar",
    referencedFiles: "Reference Files",
    forms: "Forms",
    teams: "Teams",
    members: "Members",
    templates: "Templates",
    organization: "Organizations",
    exploreOrganization: "Explore Organizations",
    dataTags: "Data Tags",
    rolesAndPermissions: "Roles & Permissions",
    users: "Users",
    systemStatus: "System Status",
  },
  timers:{
    title: "Timers",
    myTimers: "My Timers",
    organizationTimers: "Organization Timers",
    emptyListDescription: "There are no running timers",
    StartedTimers: "Started Timers",
    Started: "Started",
    Paused: "Paused",
    PausedTimers: "Paused Timers",
    stopAll: "Stop All",
    startAll: "Start All",
    pauseAll: "Pause All",
  },
  adminPanel: "Admin Panel",
  backToWebsite: "Back to Website",
  switchToAdmin: "Switching to Admin Panel",
  switchToWebsite: "Switching back to Website",
}
