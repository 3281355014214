import {BaseProps} from "screens/BaseProps";
import {Template} from "types/Template";
import {Input, SimpleFormDrawer} from "components/index";
import React, {useEffect, useRef, useState} from "react";
import {enCommonButton, enCommonLabel, enTemplateLabel} from "constants/index";
import {
  getKeyValue,
  onChangeInput,
  submitForm,
  templatesPath
} from "screens/utility";
import DataTagInput from "components/DataTag/DataTagInput";
import {useDocument} from "hooks/index";
import {useParams} from "react-router-dom";
import {doc} from "firebase/firestore";
import {ActionType, Severity} from "enums/index";
import {SidebarIcons as MenuIcons} from "assets/icons";
import theme from "theme/theme";
import {ErrorMessageType, statusSubmitHandler} from "screens/utility/statusSubmitHandler";
import useCheckUniqueness from "hooks/useCheckUniqueness";

interface ManageTemplateDrawerProps extends BaseProps {
  template: Template | null;
  isOpen: boolean;
  closeDrawer: () => void;
}

function ManageTemplateDrawer(props: ManageTemplateDrawerProps) {
  const {template, isOpen, toastProps, uid, closeDrawer} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  const {orgId} = useParams();

  const [initialTemplate, setInitialTemplate] = useState({
    name: template?.name ?? "",
    description: template?.description ?? "",
    dataTags: template?.dataTagsIds?.sort() ?? [],
  })

  const templateRef = template && template["@id"] ? doc(templatesPath(orgId!), template["@id"])
    : doc(templatesPath(orgId!));
  const [templateDoc, setTemplateDoc] = useDocument<Template | null>(null);

  const [name, setName] = useState<string>(initialTemplate.name);
  const [description, setDescription] = useState<string>(initialTemplate.description);
  const [dataTags, setDataTags] = useState<string[]>(initialTemplate.dataTags);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [nameValidationMessage, setNameValidationMessage] = useState<string>("");
  const [isFormModified, setIsFormModified] = useState<boolean>(false);
  const firstLoad = useRef(true);

  const isNameUnique = useCheckUniqueness(templatesPath(orgId!), "name", name, template?.id);

  useEffect(() => {
    if (!isNameUnique) {
      setIsFormValid(false);
      setNameValidationMessage(enTemplateLabel.uniqueNameError);
      return;
    }

    if (name.trim() === "" && !firstLoad.current) {
      setIsFormValid(false);
      setNameValidationMessage(enTemplateLabel.requiredName);
      return;
    }

    firstLoad.current = false;

    setIsFormValid(isFormModified);
    setNameValidationMessage("");
  }, [isNameUnique, name, isFormModified]);

  useEffect(() => {
    if (!isOpen) return;

    let newTemplate = {
      name: template?.name ?? "",
      description: template?.description ?? "",
      dataTags: template?.dataTagsIds?.sort() ?? [],
    }

    setInitialTemplate(newTemplate);
    setName(newTemplate.name);
    setDescription(newTemplate.description);
    setDataTags(newTemplate.dataTags);
    setIsLoading(false);
    setIsFormValid(false);
    setNameValidationMessage("");
    setTemplateDoc(null);
    firstLoad.current = true;
  }, [isOpen]);

  useEffect(() =>  {
    if (!firstLoad) return;

    const templateData = {
      name,
      description,
      dataTags: dataTags.sort()
    }

    setIsFormModified(!(JSON.stringify(templateData) === JSON.stringify(initialTemplate)));
  }, [name, description, dataTags])

  function handleFormError(errorMessage: ErrorMessageType) {
    setIsLoading(false);

    if (typeof (errorMessage) === "object") {
      setNameValidationMessage(getKeyValue(errorMessage, "name", ""));
      return;
    }

    setToastMessage(errorMessage);
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
  }

  async function onSubmitForm() {
    setIsLoading(true);
    const action = !template ? ActionType.Create : ActionType.Update;

    if (!templateDoc) setTemplateDoc(templateRef);

    await submitForm<Partial<Template>>(templateRef!, action,
      (status, data, isLastUpdate) => statusSubmitHandler<Partial<Template>>({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback: (data) => handleFormError(data)
      }),
      {
        name: name.trim(),
        description: description.trim(),
        dataTagsIds: dataTags
      },
      template
    );
  }

  function successCallback() {
    setToastMessage(template ? enTemplateLabel.editSuccess
      : enTemplateLabel.createSuccess(name));
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    closeDrawer();
  }

  function onKeyPress(e: React.KeyboardEvent) {
    if (e.key === "Enter" && isFormValid && !isLoading) {
      onSubmitForm();
    }
  }

  return (
    <SimpleFormDrawer
      id="manage-template-drawer"
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={closeDrawer}
      title={template ? enTemplateLabel.edit : enTemplateLabel.create}
      isFormValid={isFormValid}
      unsavedChanges={isFormModified}
      icon={<MenuIcons.Templates fill={theme.palette.secondary.main}/>}
      rightButtonLabel={template ? enCommonButton.save : enCommonButton.create}
      onSubmit={onSubmitForm}
    >
      <Input
        label={enCommonLabel.name}
        onChange={(e) => {
          setIsFormValid(false);
          onChangeInput(e, setName)
        }}
        sx={{mb: 1}}
        value={name}
        optional={false}
        validationMessage={nameValidationMessage}
        onKeyPress={onKeyPress}

      />
      <Input
        label={enCommonLabel.description}
        onChange={(e) => onChangeInput(e, setDescription)}
        value={description}
        multiline={true}
        minRows={4}
        maxRows={8}
        optional={true}
        sx={{mb: 1}}
      />
      <DataTagInput
        uid={uid}
        initialTags={dataTags}
        sx={{mb: 1}}
        handleSelectCallback={setDataTags}
        toastProps={toastProps!}
        onKeyPress={onKeyPress}
      />
    </SimpleFormDrawer>
  )
}

export default ManageTemplateDrawer;