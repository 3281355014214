export enum Entity {
  AccessList = "AccessList",
  ActivityFeed = "ActivityFeed",
  Asset = "Asset",
  Chat = "Chat",
  DataTag = "DataTag",
  EntityChat = "EntityChat",
  Export = "Export",
  File = "File",
  FileRequirement = "FileRequirement",
  Form = "Form",
  FormRequirement = "FormRequirement",
  Invite = "Invite",
  ManageTeamCategories = "ManageTeamCategories",
  Member = "Member",
  Milestone = "Milestone",
  Organization = "Organization",
  OrganizationAsset = "OrganizationAsset",
  OrganizationInvite = "OrganizationInvite",
  OrganizationTemplate = "OrganizationTemplate",
  OrganizationTemplateFileRequirement = "OrganizationTemplateFileRequirement",
  OrganizationTemplateFormRequirement = "OrganizationTemplateFormRequirement",
  OrganizationTemplateMilestone = "OrganizationTemplateMilestone",
  OrganizationTemplateTask = "OrganizationTemplateTask",
  PLFile = "PLFile",
  PLFileRequirement = "PLFileRequirement",
  PLFormRequirement = "PLFormRequirement",
  PLMilestone = "PLMilestone",
  PLTask = "PLTask",
  Project = "Project",
  ProjectLevelTask = "ProjectLevelTask",
  ReferenceFiles = "ReferenceFiles",
  RolesAndPermissions = "RolesAndPermissions",
  SwiftFile = "SwiftFile",
  Task = "Task",
  TeamCategory = "Team Category",
  Teams = "Teams",
  TeamsAndPeople = "TeamsAndPeople",
  Templates = "Templates",
  TimeLog = "TimeLogs",
  Timers = "Timers",
  Upload = "Upload",
  User = "User",
  UserInvite = "UserInvite",
}
