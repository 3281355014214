import theme from "theme/theme";
import {enCommonLabel, enMilestoneLabel, enTaskLabel} from "constants/index";
import {BaseSearchInput} from "components/index";
import React from "react";
import {Stack, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import {styled} from "@mui/material/styles";
import Button, {ButtonProps} from "@mui/material/Button";

interface TaskSearchAndFilterProps {
  isCollapseAll: boolean;
  setIsCollapseAll: () => void;
  isLoading: boolean;
  setSearchText: (text: string) => void;
}

function TaskSearchAndFilter(props: TaskSearchAndFilterProps) {
  const {isCollapseAll, isLoading, setSearchText, setIsCollapseAll} = props;

  return (
    <Stack
      flex={1}
      direction={{xs: "column", sm: "column", md: "column", lg: "row"}}
      alignItems="flex-end"
      justifyContent="space-between"
      pt={1}
      sx={{backgroundColor: theme.palette.background.default, paddingY: 1}}
    >
      <BaseSearchInput
        id="search-task"
        placeholder={enTaskLabel.search}
        searchFn={setSearchText}
        loading={isLoading}
      />
      <ColorButton
        id="expand-milestones-btn"
        sx={{
          width: {xs: "100%", sm: "100%", md: "auto", lg: "auto"},
          justifyContent: {xs: "left", sm: "left", md: "right", lg: "right"}
        }}
        endIcon={
          isCollapseAll ? (
            <SystemIcons.Collapse
              stroke={theme.palette.neutral.dark}
              height={16}
              width={16}
            />
          ) : (
            <SystemIcons.Expand
              stroke={theme.palette.neutral.dark}
              height={16}
              width={16}
            />
          )
        }
        onClick={setIsCollapseAll}
      >
        <Typography
          variant="h4"
          sx={{ml: {lg: 1}, color: theme.palette.secondary.main}}
        >
          {isCollapseAll ? enCommonLabel.collapse : enCommonLabel.expand} {enMilestoneLabel.all}
        </Typography>
      </ColorButton>
    </Stack>
  )
}

/** start of styles */
const ColorButton = styled(Button)<ButtonProps>(({theme}) => ({
  "&:hover": {
    backgroundColor: theme.palette.neutral.light,
  },
  "&:active": {
    backgroundColor: theme.palette.neutral.light,
  },
}));

/** end of styles */

export default TaskSearchAndFilter;