import theme from "theme/theme";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import React from "react";

function DisabledCheckbox() {
  return (
    <DisabledByDefaultOutlinedIcon
        sx={{color: theme.palette.neutral.main, borderRadius: 5}}
    />
  );
}

export default DisabledCheckbox;