import {Stack, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {enSidebar} from "constants/index";
import {BaseProps} from "screens/BaseProps";
import {AdminSidebarView} from "types/User";
import {SidebarOrgLabelButton} from "components/SidebarMenu/SidebarOrgLabel/SidebarOrgLabelButton";
import {SelectedOrgContext} from "screens/SelectedOrgContextProvider";

export interface SidebarOrgLabelProps extends BaseProps {
  adminSidebarView: AdminSidebarView | undefined;
}

const MAX_CHARS = 20;

function SidebarOrgLabel(props: SidebarOrgLabelProps) {
  const {adminSidebarView} = props;

  const selectedOrgContext = useContext(SelectedOrgContext);
  const {selectedOrg} = selectedOrgContext!;

  const [displayedOrgName, setDisplayedOrgName] = useState(selectedOrg.name ?? enSidebar.links.exploreOrganization);

  const orgName = displayedOrgName ?? "Select Organization";

  useEffect(() => {
    const orgName = selectedOrg.name ?? "Select Organization";
    setDisplayedOrgName(orgName);
  }, [selectedOrg, adminSidebarView]);

  return (
    <Stack direction="column" gap={1} padding={2}>
      <Typography
        variant="body"
        sx={() => ({
          color: "#858585",
          fontFamily: "Lato",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: 12,
          lineHeight: "20px",
        })}
      >
        {enSidebar.links.exploreOrganization}
      </Typography>
      <Stack
        direction="row"
        sx={{
          borderRadius: "4px",
          background: "#2E2E2F",
          maxWidth: "100%",
        }}
        alignContent="center"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          title={orgName.length > MAX_CHARS ? orgName : undefined}
          id="sidebarLabel_ORGNAME"
          sx={{
            color: "#FFF",
            pl: 1,
          }}
        >
          {orgName.length > MAX_CHARS ? orgName.substring(0, MAX_CHARS) + "..." : orgName}
        </Typography>
        <SidebarOrgLabelButton
          {...props}
          selectedOrg={selectedOrg}
        />
      </Stack>
    </Stack>
  );
}

export default SidebarOrgLabel;
