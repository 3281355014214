import {Stack, Typography} from "@mui/material";
import {enCommonLabel, enOrgDataTagsButton, enOrgDataTagsLabel} from "constants/index";
import {PrimaryButton, SecondaryButton} from "components/Button";
import theme from "theme/theme";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {useComponentToggler} from "hooks/index";
import {en} from "language/en";
import {Dispatch, useEffect, useState} from "react";
import {Entity, ExportType, FeatureType, Severity} from "enums/index";
import {toastProps} from "../BaseProps";
import {SystemIcons} from "assets/icons/system/system.index";
import {ManageDataTagCardDrawer} from "./ManageDataTagCardDrawer";
import {ExportDialog, ImportDialog} from "components/Dialogs";
import {db} from "../../firebase";
import {collection, doc, Timestamp} from "firebase/firestore";
import {AllOrder} from "enums/DirectionalOrder";
import {Data} from "hooks/useCollectionData";
import SearchAndSort from "./SearchAndSort";
import BulkActions, {BulkActionsProps} from "./BulkActions";
import FeatureWrapper from "components/FeatureWrapper";

export interface TagData extends Data {
  timeCreated: Timestamp;
}

interface PageHeaderProps extends BulkActionsProps {
  dataTagsAccess: AccessType | null;
  dataTagsImportingProgressCount?: number;
  organizationRefPath: string;
  datagsCount: number;
  sortOrder: AllOrder;
  setSortOrder: Dispatch<AllOrder>;
  setAlgoliaResults: Dispatch<TagData[]| null>;
  toastProps: toastProps;
  uid: string;
}

function PageHeader(props: PageHeaderProps) {
  const {datagsCount = 0, dataTagsAccess, dataTagsImportingProgressCount, toastProps, organizationRefPath} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps;

  const [isImportDialogOpen, {open: openImportDialog, close: closeImportDialog}] = useComponentToggler(false);
  const [isExportDialogOpen, {open: openExportDialog, close: closeExportDialog}] = useComponentToggler(false);
  const [isManageDataTagOpen, {close: closeManageDataTag, open: openManageDataTag}] = useComponentToggler(false);
  const [importProgress, setImportProgress] = useState<number>(0);
  const [isImporting, setIsImporting] = useState<boolean>(false);

  useEffect(() => {
    if (!dataTagsImportingProgressCount) {
      setImportProgress(0);
      return;
    }

    const progressCount = Math.floor(dataTagsImportingProgressCount || 0);
    if (progressCount > 0 && progressCount < 100) {
      setIsImporting(true);
    }

    if (progressCount >= 100) {
      setImportProgress(0);
      if (isImporting) {
        setIsImporting(false);
        setToastMessage(enCommonLabel.importCompleted);
        setToastSeverity(Severity.Success);
        setIsToastOpen(true);
      }
      return;
    }

    setImportProgress(progressCount);
  }, [dataTagsImportingProgressCount]);

  function getImportLabel() {
    if (importProgress > 0) {
      return [
        en.screen.Organization.label.importing,
        `${importProgress}%`
      ].join(' ');
    }

    return en.screen.Organization.label.import;
  }

  return (
    <Stack
      paddingY={2}
      maxWidth={"100vw"}
      paddingRight={"20%"}
      flexDirection="column"
      gap={1}
    >
      <ImportDialog
        uid={props.uid}
        isOpen={isImportDialogOpen}
        entity={Entity.DataTag}
        toastProps={toastProps!}
        closeDialog={closeImportDialog}
        parentDocumentRef={doc(db, organizationRefPath)}
      />
      <ExportDialog
        isOpen={isExportDialogOpen}
        onClose={closeExportDialog}
        toastProps={toastProps!}
        title={enOrgDataTagsLabel.exportDialogTitle}
        exportingTitle={enOrgDataTagsLabel.exportingDialogTitle}
        message={enOrgDataTagsLabel.exportDialogText}
        exportType={ExportType.OrganizationDataTagExport}
        exportColRef={collection(db, organizationRefPath, "exports")}
      />
      <ManageDataTagCardDrawer
        uid={props.uid}
        isOpen={isManageDataTagOpen}
        onClose={closeManageDataTag}
        dataTag={null}
        toastProps={toastProps!}
      />
      <Stack
        sx={{display: "flex", justifyContent: "space-between"}}
        direction={{sm: "column", md: "row"}}
      >
        <Typography variant="h1" id="projects-title">
          {enOrgDataTagsLabel.title}{" "}
          <Typography
            id="projects-count"
            variant="body"
            sx={(theme) => ({
              display: "inline",
              color: theme.palette.text.secondary,
            })}
          >
            {datagsCount <= 0 ? "" : datagsCount}
          </Typography>
        </Typography>
        <Stack direction="row" gap={1}>
          <FeatureWrapper viewable feature={FeatureType.ImportDataTag}>
            <SecondaryButton
              sx={{px: 2}}
              id="import-datatag-button"
              onClickFcn={openImportDialog}
              disabled={importProgress > 0}
              startIcon={(props) => <SystemIcons.Upload stroke={theme.palette.primary.main} {...props} />}
              label={getImportLabel()}
              disabledHoverLabel={enCommonLabel.importInProgress}
            />
          </FeatureWrapper>

          <SecondaryButton
            sx={{px: 2}}
            id="export-datatag-button"
            onClickFcn={openExportDialog}
            disabled={isExportDialogOpen}
            startIcon={(props) => <SystemIcons.Download stroke={theme.palette.primary.main} {...props} />}
            label={enOrgDataTagsLabel.export}
            disabledHoverLabel={enCommonLabel.exportInProgress}
          />
          <PrimaryButton
            onClickFcn={openManageDataTag}
            label={enOrgDataTagsButton.createNew}
            disabledHoverLabel={enOrgDataTagsLabel.noPermission}
            disabled={!dataTagsAccess?.[PermissionOperationKey.Create]}
          />
        </Stack>
      </Stack>
      {datagsCount > 0 && (
        <>
          <SearchAndSort
            sortOrder={props.sortOrder}
            setSortOrder={props.setSortOrder}
            setAlgoliaResults={props.setAlgoliaResults}
          />
          <BulkActions
            canDelete={Boolean(dataTagsAccess?.[PermissionOperationKey.Delete])}
            toggleCheckbox={props.toggleCheckbox}
            isCheckboxShown={props.isCheckboxShown}
            isAllChecked={props.isAllChecked}
            setIsAllChecked={props.setIsAllChecked}
            checkedDataTags={props.checkedDataTags}
            organizationTagColRef={props.organizationTagColRef}
            toastProps={toastProps}
          />
        </>
      )}
    </Stack>
  )
}

export default PageHeader;