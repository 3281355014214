import {Entity} from "enums/entity";
import {enCommonLabel} from "constants/index";

export function getImportInstructions(entity: Entity) {
  let textInstructions: string[];

  switch (entity) {
    case Entity.Templates:
    case Entity.Asset:
    case Entity.Project:
      textInstructions = enCommonLabel.importInstructionsNotes.split("\n");
      break;
    case Entity.DataTag:
      textInstructions = enCommonLabel.importInstructionsDataTag.split("\n");
      break;
    case Entity.OrganizationAsset:
      textInstructions = enCommonLabel.importInstructionsOrganizationAsset.split("\n");
      break;
    case Entity.Form:
      textInstructions = enCommonLabel.importInstructionsForm.split("\n");
      break;
    default:
      textInstructions = [enCommonLabel.dummyInstructions]
  }

  return textInstructions;
}