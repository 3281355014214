import {Box, Paper} from "@mui/material";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import NotRunningTimer from "./NotRunningTimer";
import RunningTimer from "./RunningTimer";
import {TimerState} from "types/Task";
import {tasksTimersPath} from "screens/utility/FirebasePath";
import {useCollection} from "hooks/index";
import {doc, where} from "firebase/firestore";
import useManualParams from "hooks/useManualParams";
import {Timer} from "types/Timer";
import {TimerContextWrapper} from "components/FloatingTimer/useTimerReducer/useTimerContext";
import getEnvKey from "screens/utility/getEnvKey";
import {googleMapsApiKey} from "constants/envKeys";
import {PartialRequired} from "types/utils";

interface FloatingTimerProps {
  userId: string;
}

const initialTimer: SimpleTimer = {
  timerState: TimerState.Stopped,
  lastTimerStartedDateTime: null,
  totalLoggedTimeInMs: 0
}

export type SimpleTimer = PartialRequired<Timer, "timerState" | "lastTimerStartedDateTime" | "totalLoggedTimeInMs">
export type SimpleTimerSetter = Dispatch<SetStateAction<SimpleTimer>>

function FloatingTimer({userId}: FloatingTimerProps) {
  const [location, setLocation] = useState<null | string>(null);

  const params = useManualParams()
  const {
    organizations,
    projects,
    assets,
    milestones,
    tasks,
    "file-requirements": fileRequirements,
    "form-requirements": formRequirements
  } = params;

  const MAPS_API_KEY = getEnvKey(googleMapsApiKey);

  const [geolocation, setGeoLocation] = useState<null | GeolocationPosition>(null);
  const timerColRef = tasksTimersPath(organizations || "o", projects || "p", assets, milestones || "m", tasks || "t");

  // const [{timer, fromDb}, dispatch] = useTimerContext()!;
  const [timer, setTimer] = useState<SimpleTimer>(initialTimer)
  const [fromDb, setFromDb] = useState(false)
  const timerRef = timer.timerState !== TimerState.Stopped ? doc(timerColRef, timer["@id"]) : doc(timerColRef);

  const [permission, setPermission] = useState(false);
  const [timers, , updateTimersRef] = useCollection<Timer>(null, timerColRef, [where("userId", "==", userId)]);

  const isInsideTaskOverview = tasks && !fileRequirements && !formRequirements;
  const timerState = timer.timerState;

  useEffect(() => {
    updateTimersRef(tasksTimersPath(organizations || "o", projects || "p", assets, milestones || "m", tasks || "t"));
  }, [params]);

  // for initializing timer
  useEffect(() => {
    // only get the timer status once from db
    if (fromDb)
      return

    if (!timers) {
      setTimer(initialTimer)
      return;
    }

    if (timers.length) {
      const timer = timers[0];
      setTimer((prev) => ({...prev, ...timer}))
      setFromDb(true)
      return;
    }

    // dispatch(updateTimer({timerState: TimerState.Stopped}));
  }, [timers, fromDb]);

  //listen to permission change and set location
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((location) => {
      //transform the location into address when google api key is available
      setGeoLocation(location)
      const {latitude, longitude} = location.coords;
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${MAPS_API_KEY}`).then(async (res) => {
        const json = await res.json();
        if (!json.results[0]) return;
        setLocation(json.results[0].formatted_address)
      })
    })
  }, [permission])

  if (!isInsideTaskOverview) return <></>;

  return <Paper
    elevation={0}
    sx={{
      width: timer.timerState === TimerState.Stopped ? 140 : 360,
      display: "flex",
      position: "relative",
      transition: "width 500ms, height 300ms",
      pointerEvents: "auto",
      overflow: "hidden",
      height: "max-content"
    }}
  >
    <Box
      sx={{
        width: "100%",
      }}
    >
      {timerState === TimerState.Stopped &&
        <NotRunningTimer
          location={location}
          timerRef={timerRef}
          timer={timer}
          geolocation={geolocation}
          setTimer={setTimer}
        />
      }

      {timerState !== TimerState.Stopped &&
        <RunningTimer
          setPermission={setPermission}
          timer={timer}
          timerDocRef={timerRef}
          location={location}
          setTimer={setTimer}
        />
      }
    </Box>

  </Paper>
}

export default function ProvidedTimer({userId}: FloatingTimerProps) {
  return <TimerContextWrapper>
    <FloatingTimer userId={userId}/>
  </TimerContextWrapper>
}
