/* eslint-disable react-hooks/exhaustive-deps */
import {Stack, Typography} from "@mui/material";
import {BaseProps} from "screens/BaseProps";
import {CollapsableText, LongText, SelectStatus} from "components/index";
import {enFileRequirementLabel} from "constants/index";
import theme from "theme/theme";
import {DocumentReference} from "firebase/firestore";
import StatusSubText from "./StatusSubText";
import TaskStatusButtonFunction from "./TaskStatusButtonFunction";
import {FileRequirement} from "types/FileRequirement";
import {RequirementStatus} from "enums/requirementStatus";
import getStatusOptions from "../../utility/getStatusOptions";
import RequirementStatusObjectList from "assets/arrayList/StatusObjectList/RequirementStatusObjectList";
import React, {useEffect, useState} from "react";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import {AccessType, PermissionOperationKey} from "types/Permission";

interface HeadProps extends BaseProps {
  requirementAccess: AccessType | null;
  documentRef: DocumentReference;
  fileRequirement: FileRequirement;
  statusFilter: string;
  setStatusFilter: (status: string) => void;
}

function Head(props: HeadProps) {
  const {fileRequirement, requirementAccess, statusFilter, documentRef, setStatusFilter, toastProps, uid} = props;
  const {
    name,
    description,
    fileRequirementStatus,
  } = fileRequirement ?? {};

  const [options] = getStatusOptions(RequirementStatusObjectList, fileRequirementStatus);
  const selectedOption = options[fileRequirementStatus];
  const canChangeStatus = Boolean(requirementAccess?.[PermissionOperationKey.OverrideStatus]) || Boolean(requirementAccess?.[PermissionOperationKey.ChangeStatus]);

  // status filter related states
  const [selectedStatusFilter] = useState<string>(statusFilter);

  // listen to changes in the filter status
  useEffect(() => {
    setStatusFilter(selectedStatusFilter);
  }, [selectedStatusFilter]);


  return (
    <>
      <Stack direction="column" gap={2} mb={1}>
        <Stack width={1} direction={{xs: "column", sm: "column", md: "column", lg: "row", xl: "row"}} justifyContent="space-between">
          <Stack direction="column" gap={2}>
            <Stack direction="column">
              <LongText variant="h1" id="task-name" overflowWrap={"anywhere"}>
                {name ?? ""}
              </LongText>
              <CollapsableText text={description} id="task-description" overflowWrap={"anywhere"}/>
            </Stack>
            <DataTagsDisplay
              uid={uid!}
              dataTagsIds={fileRequirement?.dataTagsIds || []}
              toastProps={toastProps!}
              documentRef={documentRef}
              canEditDocumentRef={Boolean(requirementAccess?.[PermissionOperationKey.Update])}
            />
          </Stack>
          <Stack
            direction={{xs: "row", sm: "row", md: "row", lg: "column", xl: "column"}}
            alignItems={{xs: "baseline", sm: "baseline", md: "baseline", lg: "start", xl: "start"}}
            gap={{xs: 1, sm: 1, md: 1, lg: 0, xl: 0}}
            justifyContent="start"
          >
            <Typography variant="body" color={theme.palette.neutral.dark}>
              {enFileRequirementLabel.requirementStatus}
            </Typography>
            <Stack direction="column" spacing={0.5}>
              <Stack direction="row" spacing={2} flexWrap="wrap" gap={1}>
                <SelectStatus
                  toastProps={toastProps!}
                  documentRef={documentRef}
                  statusField="fileRequirementStatus"
                  displayIconOnSelected={true}
                  items={options}
                  selected={selectedOption}
                  width="180px"
                  isDisabled={!canChangeStatus}
                  disabledStatuses={[RequirementStatus.HasRejection]}
                />
                <TaskStatusButtonFunction
                  selectedStatus={selectedOption}
                  isDisabled={!canChangeStatus}
                  documentRef={documentRef}
                  toastProps={toastProps!}
                />
              </Stack>
              <StatusSubText
                uid={uid}
                selectedStatus={selectedOption}
                fileRequirement={fileRequirement}
                documentPath={documentRef.path}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}


export default Head;
