export interface RequirementFormError {
  name: string;
  minimumFiles: string;
  maximumFiles: string;
  dataTagsIds: string;
}

export const initialRequirementFormError: RequirementFormError = {
  name: "",
  minimumFiles: "",
  maximumFiles: "",
  dataTagsIds: "",
}