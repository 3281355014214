import {BaseProps} from "../BaseProps";
import {emptyFunction} from "constants/index";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAccess, useCollection, useComponentToggler, useRTDBDocField} from "hooks/index";
import {ReferenceFile} from "types/index";
import {organizationPath, referenceFilespath, sortObjectsBy} from "../utility";
import {Content, InProgress} from "components/index";
import {Box, Stack} from "@mui/material";
import Head from "./Head";
import {ReferencesList} from "./ReferencesList";
import {ManageReferenceFileDrawer} from "components/Drawers";
import {Entity, DirectionalOrder, CounterFields} from "enums/index";
import {PermissionEntity} from "types/Permission";

function ReferenceFiles(props: BaseProps) {
  const {selectedOrg, setSelectedOrgId = emptyFunction, uid, toastProps} = props;

  const navigate = useNavigate();
  const {orgId} = useParams();

  const referenceFilesCount = useRTDBDocField<number>(organizationPath(orgId!).path, CounterFields.ReferenceFilesCount) || 0;

  let [referenceFiles] = useCollection<ReferenceFile>(null, !orgId ? null : referenceFilespath(orgId));
  const [sortOrder, setSortOrder] = useState(DirectionalOrder.asc);

  const [algoliaResults, setAlgoliaResults] = useState<ReferenceFile[] | null>(null);
  const [isSortAndSearchVisible, setIsSortAndSearchVisible] = useState<boolean>(false);
  const [targetReferenceFile, setTargetReferenceFile] = useState<ReferenceFile | undefined>();
  const [isCreateEditDrawerOpen, {
    open: openCreateEditDrawer,
    close: closeCreateEditDrawer
  }] = useComponentToggler(false);

  if (referenceFiles) referenceFiles = sortObjectsBy<ReferenceFile>(referenceFiles, "name", sortOrder);

  const [orgReferenceFilesAccess] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.ReferenceFile,
  });

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  useEffect(() => {
    if (isSortAndSearchVisible) return;
    setIsSortAndSearchVisible((referenceFiles ?? []).length > 0);
  }, [referenceFiles]);

  function onEditClick(referenceFile: ReferenceFile) {
    setTargetReferenceFile(referenceFile);
    openCreateEditDrawer();
  }

  if (referenceFiles === null)
    return <Content><InProgress/></Content>

  return (
    <Content>
      <ManageReferenceFileDrawer
        isOpen={isCreateEditDrawerOpen}
        onClose={closeCreateEditDrawer}
        toastProps={toastProps!}
        referenceFile={targetReferenceFile}
        uid={props.uid}
        resetTargetReferenceFile={() => setTargetReferenceFile(undefined)}
      />
      <Stack gap={4} direction="column" pt={2}>
        <Head
          {...props}
          onCreate={openCreateEditDrawer}
          referenceFilesCount={referenceFilesCount}
          orgReferenceFilesAccess={orgReferenceFilesAccess!}
        />
      </Stack>
      <Box mt={2} height="100%">
        <ReferencesList
          referenceFiles={algoliaResults ?? referenceFiles ?? []}
          orgReferenceFilesAccess={orgReferenceFilesAccess!}
          toastProps={props.toastProps}
          setAlgoliaResults={setAlgoliaResults}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          isSearched={Boolean(algoliaResults)}
          onEditClick={onEditClick}
          uid={props.uid}
        />
      </Box>
    </Content>
  )
}

export default ReferenceFiles;
