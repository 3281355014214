import {Box} from "@mui/material";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import React from "react";
import RejectedOverlay from "./RejectedOverlay";

interface RejectedVideoPreviewProps {
  id?: string;
  onClick: () => void;
  iconSize: number;
  title?: string;
}

function RejectedVideoPreview(props: RejectedVideoPreviewProps) {
  const {id = "n", iconSize, onClick, title = ""} = props;

  return <Box
    title={title}
    className="file-preview-item"
    id={`rejected-video-preview-${id}`}
    position="relative"
    width={64}
    height="inherit"
    sx={{
      display: "grid",
      placeItems: "center",
      minWidth: 64,
      height: 64,
      backgroundColor: theme.palette.background.swiftDefault,
      borderRadius: 1,
      cursor: "pointer"
    }}
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      onClick();
    }}
  >
    <SystemIcons.Video fill="black" height={iconSize} width={iconSize}/>
    <RejectedOverlay/>
  </Box>
}

export default RejectedVideoPreview;