import React from "react";
import {Stack} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import {StatisticsItem} from "components/index";
import {BaseProps} from "screens/BaseProps";
import theme from "theme/theme";
import {emptyFunction, enTaskLabel,} from "constants/index";
import {Entity} from "enums/entity";
import StatisticsButtonGroup from "./StatisticsButtonGroup";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {DownloadIconButton} from "components/Button";
import {CounterFields} from "enums/counterFields";
import {useRTDBDocField} from "hooks/index";
import {getDocPath} from "screens/utility";

interface StatisticsProps extends BaseProps {
  parentEntity: Entity;
  canCreateAccess: boolean;
  downloadAccess: AccessType | null;
  milestonesCollectionRef: string;
  parentDocPath: string;
  canCreateMilestone: boolean;
}

function Statistics(props: StatisticsProps) {
  const {uid, toastProps, canCreateAccess, parentDocPath, downloadAccess} = props;

  const isDownloadEnabled = Boolean(downloadAccess?.[PermissionOperationKey.Create]);

  const counterPath = getDocPath(uid, parentDocPath);
  const tasksCount = useRTDBDocField<number>(counterPath, CounterFields.TasksCount) ?? 0;
  const tasksApprovedCount = useRTDBDocField<number>(counterPath, CounterFields.TasksApprovedCount) ?? 0;
  const tasksCancelledCount = useRTDBDocField<number>(counterPath, CounterFields.TasksCancelledCount) ?? 0;
  const requirementsCount = useRTDBDocField<number>(counterPath, CounterFields.RequirementsCount) ?? 0;
  const requirementsApprovedCount = useRTDBDocField<number>(counterPath, CounterFields.RequirementsApprovedCount) ?? 0;
  const filesCount = useRTDBDocField<number>(counterPath, CounterFields.FilesCount) ?? 0;
  const pLTasksCount = useRTDBDocField<number>(counterPath, CounterFields.ProjectLevelTasksCount) ?? 0;
  const pLTasksApprovedCount = useRTDBDocField<number>(counterPath, CounterFields.ProjectLevelTasksApprovedCount) ?? 0;
  const pLTasksCancelledCount = useRTDBDocField<number>(counterPath, CounterFields.ProjectLevelTasksCancelledCount) ?? 0;
  const pLRequirementsCount = useRTDBDocField<number>(counterPath, CounterFields.ProjectLevelRequirementsCount) ?? 0;
  const pLRequirementsApprovedCount = useRTDBDocField<number>(counterPath, CounterFields.ProjectLevelRequirementsApprovedCount) ?? 0;
  const pLFilesCount = useRTDBDocField<number>(counterPath, CounterFields.ProjectLevelFilesCount) ?? 0;

  const isPLT = props.parentEntity === Entity.Project;
  const displayedTasksCount = isPLT ? pLTasksCount - pLTasksCancelledCount : tasksCount - tasksCancelledCount;

  return (
    <Stack
      direction={{xs: "column", sm: "column", md: "column", lg: "row"}}
      justifyContent={{xs: "left", sm: "left", md: "left", lg: "space-between"}}
      sx={{width: 1}}
    >
      <Stack
        direction="row"
        gap={3}
        justifyContent="flex-start"
        alignItems="center"
      >
        <StatisticsItem
          id="tasks-counter"
          Icon={<SystemIcons.Tasks width={24} height={24} stroke={theme.palette.text.secondary}/>}
          title={enTaskLabel.tasksStatisticsCounter}
          count={(isPLT ? pLTasksApprovedCount : tasksApprovedCount) + "/" + displayedTasksCount}
          label={enTaskLabel.tasks}
        />
        <StatisticsItem
          Icon={<SystemIcons.Requirement width={24} height={24} stroke={theme.palette.text.secondary}/>}
          count={`${(isPLT ? pLRequirementsApprovedCount : requirementsApprovedCount)}/${(isPLT ? pLRequirementsCount : (requirementsCount ?? 0))}`}
          title={enTaskLabel.requirementsStatisticsCounter}
          label={enTaskLabel.requirements}
        />
        <Stack
          direction="row"
          alignItems="center"
        >
          <StatisticsItem
            Icon={<SystemIcons.FilesOutlined width={24} height={24} stroke={theme.palette.text.secondary}/>}
            count={isPLT ? pLFilesCount : filesCount}
            label={enTaskLabel.files}
          />
          <DownloadIconButton
            toastProps={props.toastProps!}
            uid={uid!}
            onClickFcn={emptyFunction}
            filesCount={isPLT ? pLFilesCount : filesCount}
            entity={Entity.Asset}
            disabled={!isDownloadEnabled}
            sx={{marginTop: -2}}
          />
        </Stack>
      </Stack>
      <StatisticsButtonGroup
        canCreateAccess={canCreateAccess}
        uid={uid!}
        toastProps={toastProps!}
        parentEntity={props.parentEntity}
        milestonesCollectionRef={props.milestonesCollectionRef}
        canCreateMilestone={props.canCreateMilestone}
      />
    </Stack>
  )
}

export default Statistics;