import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {emptyFunction, enChatLabel} from "constants/index";
import {Button} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Entity, ActionType} from "enums/index";
import {useRTDBDocField} from "hooks/index";
import {CollectionReference} from "firebase/firestore";
import {submitForm, getDocPath} from "screens/utility";

interface HasNewChatIndicatorProps {
  uid: string;
  displayNewChatIndicator: boolean;
  colRef: CollectionReference;
  entity: Entity;
  lastUnreadMessageIndex: number;
}

function HasNewChatIndicator(props: HasNewChatIndicatorProps) {
  const {displayNewChatIndicator, colRef, entity, lastUnreadMessageIndex} = props;

  const [hasNewChat, setHasNewChat] = useState<boolean>(false);

  const parentHasNewChat = useRTDBDocField<boolean>(getDocPath(props.uid, colRef.parent!.path), "hasNewChat") ?? false;
  const parentPLTHasNewChat = useRTDBDocField<boolean>(getDocPath(props.uid, colRef.parent!.path), "projectLevelTaskHasNewChat") ?? false;

  useEffect(() => {
    const withNewChat = entity === Entity.ProjectLevelTask ? parentPLTHasNewChat
      : parentHasNewChat;
    setHasNewChat(withNewChat);
  }, [parentHasNewChat, parentPLTHasNewChat]);

  function onScrollClick() {
    // update has newChat of parent doc
    submitForm(
      colRef.parent!,
      ActionType.Update,
      emptyFunction,
      entity === Entity.ProjectLevelTask ? {projectLevelTaskHasNewChat: false} : {hasNewChat: false}
    );
  }

  if (!hasNewChat || !displayNewChatIndicator || lastUnreadMessageIndex <= 0) return null;

  return (
    <Button
      sx={{
        position: "absolute",
        bottom: "10rem",
        alignSelf: "center",
        height: 32,
        fontSize: 12,
        backgroundColor: "rgba(0,0,0,0.5)",
        "&:hover": {
          backgroundColor: "rgba(0,0,0,0.8)",
          transform: "translateY(-4px)",
        },
        transition: "all ease 0.4s",
      }}
      startIcon={<SystemIcons.ChevronDown stroke={theme.palette.common.white} width={16} height={16}/>}
      variant="contained"
      onClick={onScrollClick}
    >
      {enChatLabel.scrollToNewMessage}
    </Button>
  )
}

export default HasNewChatIndicator;
