import {Box, Stack, styled} from "@mui/material";
import SystemIndex from "assets/icons/system/system.index";
import theme from "theme/theme";
import {enTaskLabel} from "constants/index";
import {Dispatch, useEffect} from "react";
import {TimerState} from "types/Task";
import {DocumentReference, Timestamp} from "firebase/firestore";
import {LoadingButton} from "@mui/lab";
import useTimerHook from "hooks/useTimerHook";
import {handlePauseClick, handleResumeClick, handleStopTimerClick} from "components/FloatingTimer/timer.utils";
import {LongText} from "components/index";
import {SimpleTimer, SimpleTimerSetter} from "components/FloatingTimer/index";

interface RunningTimerProps {
  location: string | null
  timerDocRef: DocumentReference
  timer: SimpleTimer
  setPermission: Dispatch<boolean>
  setTimer: SimpleTimerSetter
}

function RunningTimer(props: RunningTimerProps) {
  const {
    location,
    timerDocRef,
    timer,
    setPermission,
    setTimer
  } = props;
  const timerDetails = useTimerHook(timer);
  const {hours, minutes, seconds} = timerDetails;

  //create listener for permission change
  useEffect(() => {
    navigator.permissions.query({name: 'geolocation'}).then((permissionStatus) => {
      setPermission(permissionStatus.state === "granted")

      permissionStatus.onchange = () => {
        if (permissionStatus.state === "granted")
          setPermission(true)
        else
          setPermission(false)
      };
    })
  }, []);

  return <Stack gap={1} height="100%" minWidth={360} direction={"row"} sx={{height: "max-content"}}>
    <Stack direction="row">
      <Box marginTop={0.5}>
        <SystemIndex.SystemIcons.Navigation stroke={theme.palette.neutral.medium} height={16}/>
      </Box>
      {location ? <LongText maxLines={2} fontSize={12}>{location}</LongText> : <i style={{fontSize: 12}}>{enTaskLabel.notCaptured}</i>}
    </Stack>
    <Stack direction="row">
      <LoadingButton
        color="warning"
        variant="contained"
        fullWidth
        sx={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          "&:hover": {
            backgroundColor: theme.palette.warning.light
          }
        }}
        onClick={() => timer.timerState === TimerState.Paused ? handleResumeClick(location, timer, setTimer, timerDocRef) : handlePauseClick(location, timer, setTimer, timerDocRef)}
      >
        {timer.timerState === TimerState.Paused ? <SystemIndex.SystemIcons.Play/> : <SystemIndex.SystemIcons.Pause/>}
        {hours}:{minutes}:{seconds}
      </LoadingButton>
      <StopButton
        color="error"
        variant="contained"
        onClick={() => handleStopTimerClick(location, timer, setTimer, timerDocRef)}
      >
        <SystemIndex.SystemIcons.Stop stroke={theme.palette.background.paper} fill={theme.palette.background.paper}/>
      </StopButton>
    </Stack>
  </Stack>
}

export function getRoundedTimestamp(){
  return Timestamp.fromMillis(new Date(Date.now()).valueOf() - (Date.now() % 1000))
}

const StopButton = styled(LoadingButton)({
  padding: 0,
  maxWidth: '40px',
  minWidth: '40px',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
})

export default RunningTimer
