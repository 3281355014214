import {TeamCategory} from "types/TeamCategory";
import {Entity} from "enums/entity";
import EmptySearchResults from "components/EmptySearchResults";
import React from "react";
import TeamCategoryItem from "./TeamCategoryItem";
import {AccessType} from "types/Permission";
import {ActionType} from "enums/actionType";
import {InProgress} from "components/index";

interface DynamicViewProps {
  teamCategories: TeamCategory[] | null;
  categoryAccess: AccessType | null;
  onItemActionClick: (action: ActionType, teamCategory: TeamCategory) => void;
}

function DynamicView(props: DynamicViewProps) {
  const {teamCategories, categoryAccess, onItemActionClick} = props;

  if (!teamCategories)
    return <InProgress/>

  if (teamCategories.length === 0)
    return <EmptySearchResults sx={{position: "relative", bottom: 48}} entity={Entity.TeamCategory}/>

  return <>
    {teamCategories.map(category => {
      return <TeamCategoryItem
        categoryAccess={categoryAccess}
        key={category["@id"]!}
        teamCategory={category}
        onItemActionClick={onItemActionClick}
      />
    })}
  </>
}

export default DynamicView
