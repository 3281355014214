import {Stack, Typography} from "@mui/material";
import {StatusIcons} from "assets/icons/status/status.index";
import {CircularProgressWithLabel, LongText} from "components/index";
import theme from "theme/theme";
import {enCommonLabel} from "constants/index";
import {FileUploadStatus, FileUploadType, Severity} from "enums/index";
import {useEffect, useState, RefObject} from "react";
import {toastProps, UploadingFile} from "../../../BaseProps";
import useSound from "use-sound";
import loginSfx from "../../../../sounds/login.mp3";
import {formatDateTimeFirestoreDate} from "../../../utility";
import BreadcrumbsDisplay from "components/BreadcrumbsDisplay";
import {useNavigate} from "react-router-dom";
import TimestampTypo from "components/Typography/TimestampTypo";
import {ProcessingFile} from "hooks/useFileUpload/useFileUpload";

interface UploadItemProps {
  file: ProcessingFile;
  toastProps: toastProps;
  isUploadPanelShown: boolean;
  uploadingItem?: UploadingFile;
  containerRef?: RefObject<any>;
}

function UploadItem(props: UploadItemProps) {
  const {file, toastProps, isUploadPanelShown, uploadingItem, containerRef} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps;
  const navigate = useNavigate();

  const {id, fileUploadStatus, timeCreated, file: actualFile, breadCrumbs} = file;
  const {date, time} = formatDateTimeFirestoreDate(timeCreated);

  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [playSound] = useSound(loginSfx, {volume: 1, soundEnabled: true, playbackRate: 1});

  const [uploadProgress, setUploadProgress] = useState<number>(0);

  // Update upload progress
  useEffect(() => {
    const displayedItem = !!uploadingItem ? uploadingItem : file;

    const {length = 1} = file;
    const {lengthUploaded = 1} = displayedItem;
    const progress = Math.ceil((lengthUploaded / length) * 100);
    setUploadProgress(lengthUploaded >= length ? 100 : progress);
  }, [file?.lengthUploaded, file?.length, uploadingItem?.lengthUploaded]);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      return;
    }

    // since type is optional, if it is not present, we don't want to do anything
    if (!file.type) return;

    // @ts-ignore
    if(fileUploadStatus === FileUploadStatus.Uploaded && ![FileUploadType.ImportProject, FileUploadType.ImportTemplate, FileUploadType.ImportAsset].includes(file.type)) {
      playSound();
      if (!isUploadPanelShown) {
        setToastMessage(enCommonLabel.fileUpload(actualFile.name));
        setToastSeverity(Severity.Success);
        setIsToastOpen(true);
      }
    }
  }, [fileUploadStatus]);

  return (
    <Stack
      direction="column"
      padding={0}
      margin={0}
      className="file-upload-panel-list-item"
      sx={{borderBottom: "1px solid #e0e0e0"}}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        gap={1}
        paddingY={0.5}
        paddingX={2}
        onClick={() => breadCrumbs && breadCrumbs.length > 0 && navigate(breadCrumbs[breadCrumbs.length -1].path)}
      >
        <Stack direction="row">
          {uploadProgress === 100 ? <StatusIcons.Uploaded width={26} height={26} strokeWidth={2}/>
            : <CircularProgressWithLabel size={26} value={uploadProgress} />}
        </Stack>
        <Stack direction="column">
          <Typography
            id={`${id}-file-status`}
            variant="h5"
            color={theme.palette.secondary.main}
          >
            {uploadProgress === 100 ? enCommonLabel.uploadComplete : <i>{enCommonLabel.uploading}</i>}
          </Typography>
          <LongText
            maxLines={1}
            followCursor={true}
            containerRef={containerRef}
            variant="body2"
            fontSize={12}
          >
            {actualFile.name}
          </LongText>

          {breadCrumbs && breadCrumbs.length > 0 && (
            <BreadcrumbsDisplay
              entityBreadcrumbs={breadCrumbs}
              orgName={breadCrumbs[0].name ?? "fillerOrgName"}
            />
          )}
          <TimestampTypo>{`${date} ${time}`}</TimestampTypo>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default UploadItem;