import {Timestamp} from "firebase/firestore";

export function formatDateTimeFirestoreDate(datetime: Timestamp | undefined | null) {

  if (!datetime) {
    return {date: "", time: "", month: "", day: 0, year: 0, simpleTime: "", fireBaseTime: new Date(), shortDate: ""};
  }
  // @ts-ignore
  const {seconds, _seconds, nanoseconds, _nanoseconds} = datetime;

  const parsedSeconds = seconds ?? _seconds ?? 0;
  const parsedNanoSeconds = nanoseconds ?? _nanoseconds ?? 0;

  const fireBaseTime = new Date(parsedSeconds * 1000 + parsedNanoSeconds / 1000000);

  const date = fireBaseTime.toDateString();
  const twoDigitHourTime = fireBaseTime.toLocaleString("en-us", {hour: "2-digit", minute: "2-digit", hour12: true,});
  const time = fireBaseTime.toLocaleTimeString();
  const simpleTime = fireBaseTime.toLocaleTimeString("en", {timeStyle: "short"});
  const month = fireBaseTime.toLocaleTimeString("en", {month: "short"}).slice(0, 3);
  const day = fireBaseTime.getDate()
  const year = fireBaseTime.getFullYear();
  const monthDigit = fireBaseTime.getMonth();
  const shortDate = Intl.DateTimeFormat("en", {year: "numeric", month: "numeric", day: "numeric"}).format(fireBaseTime);
  const minimalDate = Intl.DateTimeFormat("en", {year: "2-digit", month: "numeric", day: "numeric"}).format(fireBaseTime);


  return {date, time, month, day, year, simpleTime, fireBaseTime, shortDate, minimalDate, monthDigit, twoDigitHourTime};
}
