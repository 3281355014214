import {Stack, Switch, Typography} from "@mui/material";
import {enFormsLabel} from "constants/index";
import {BasicAdvancedOptions, FormFieldAdvancedOptionsProps, OptionColumnWrapper} from "./index";
import {ValidationMessage} from "components/index";
import React from "react";
import {FormFieldType} from "enums/formFieldType";
import {FormField} from "types/FormField";

export function NumericAdvancedOptions(props: FormFieldAdvancedOptionsProps) {
  const {changeFormData, index, formField} = props;
  const {allowDecimal = false, errors} = formField as FormField<FormFieldType.Numeric>;

  return (
    <>
      <BasicAdvancedOptions {...props}/>
      <OptionColumnWrapper>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">{enFormsLabel.allowDecimal}</Typography>
          <Switch
            onChange={(_, checked) => changeFormData(checked, "allowDecimal", index, true)}
            checked={allowDecimal}
          />
        </Stack>
        <ValidationMessage validationMessage={errors?.allowDecimal}/>
      </OptionColumnWrapper>
    </>
  )
}