import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  styled,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import {ChevronRight} from "@mui/icons-material";
import {useState} from "react";
import theme from "theme/theme";

interface ActionOptionProps {
  onClick: (props?: any) => any;
  Icon: any;
  label: string;
  labelSx?: SxProps<Theme>;
  sx?: SxProps<Theme>;
  id?: string;
  disabled?: boolean;
  isDropdown?: boolean;
}

const StyledListItemIcon = styled(ListItemIcon)({
  marginRight: 12,
});

function ActionOption(props: ActionOptionProps) {
  const {onClick, Icon, label, labelSx, id, disabled = false, isDropdown, sx} = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  function onItemClick() {
    setIsDropdownOpen((prev) => !prev);
    onClick();
  }

  return (
    <MenuItem disableGutters sx={{padding: 0, margin: 0, width: "100%"}}>
      <ListItemButton
        id={id}
        onClick={(e) => {
          e.stopPropagation();
          onItemClick();
        }}
        sx={{padding: theme.spacing(3/4), py: 0.5}}
        disabled={disabled}
      >
        <Stack direction="row" alignItems="center" width="100%" sx={sx}>
          <StyledListItemIcon style={{minWidth: 16}}>
            {Icon}
          </StyledListItemIcon>
          <ListItemText
            sx={labelSx && labelSx && {ml: 0}}
            primary={<Typography variant="h4" sx={labelSx}>{label}</Typography>}
          />
          {
            isDropdown && (
              <StyledListItemIcon
                style={{
                  minWidth: 16,
                  marginRight: 0,
                  transition: "transform ease 0.3s",
                  transform: `rotate(${isDropdownOpen ? "90deg" : "0deg"})`
                }}>
                <ChevronRight/>
              </StyledListItemIcon>
            )
          }
        </Stack>
      </ListItemButton>
    </MenuItem>
  );
}

export default ActionOption;
