import {FeatureType} from "enums/featureType";
import useFeature from "hooks/useFeature";
import React from "react";
import {Stack, Tooltip} from "@mui/material";
import {enCommonLabel} from "constants/index";

interface FeatureComponentProps {
  feature: FeatureType;
  children: JSX.Element;
  initialDisplay?: boolean;
  viewable?: boolean;
}

function FeatureWrapper(props: FeatureComponentProps) {
  const {feature, children, initialDisplay = false, viewable = false} = props;
  const isEnabled = useFeature(feature);

  if (isEnabled) return children;

  if (initialDisplay) return (
    <Stack sx={{opacity: 0.5}}>{children}</Stack>
  );

  if (viewable) return (
    <Tooltip title={enCommonLabel.unavailableFeature}>
      <Stack
        sx={{opacity: 0.5}}
        onContextMenuCapture={(e) => {
          e.stopPropagation()
        }}
        onClickCapture={(e) => e.stopPropagation()}>{children}</Stack>
    </Tooltip>
  );

  return <></>;
}

export default FeatureWrapper
