import theme from "theme/theme";
import {Box, Collapse, Typography} from "@mui/material";
import React from "react";

type MessageBoxProps = {
  message: string | string[];
  isOpen: boolean;
  actionComponent?: JSX.Element;
  isVisible: boolean;
  backgroundColor?: string;

}

export function MessageBox(props: MessageBoxProps) {
  const {message, isOpen, actionComponent, isVisible, backgroundColor} = props;

  if (!isVisible) return <></>;
  return (
    <Collapse in={isOpen}>
      <Box
        sx={{
          color: theme.palette.common.white,
          backgroundColor: backgroundColor ?? theme.palette.error.light,
          padding: 2,
          borderRadius: 2
        }}>
        {/*<Typography variant="body1">*/}
        {/*  Your email is not yet verified.*/}
        {/*</Typography>*/}
        {/*<Typography variant="body1">*/}
        {/*  Please check your email for the verification link.*/}
        {/*</Typography>*/}
        {
          (typeof message === "object") ? message.map((msg) => (
            <Typography variant="body1">
              {msg}
            </Typography>
          )) : (
            <Typography variant="body1">
              {message}
            </Typography>
          )
        }
        {actionComponent}
      </Box>
    </Collapse>
  )
}