import {Box, Button, Stack, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {UserCounters} from "types/User";
import {Content, CustomTab, CustomTabs, TabPanel} from "components";
import theme from "theme/theme";
import {BaseProps} from "../BaseProps";
import {emptyFunction, enCommonLabel, enOrganizationLabel} from "constants/index";
import {InvitesList, OrganizationsList} from "./Tabs";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import ManageOrganizationDrawer from "components/Drawers/ManageOrganizationDrawer";
import {useComponentToggler, useRTDBDocField} from "hooks/index";
import {SelectedOrgContext} from "../SelectedOrgContextProvider";
import {userPath} from "../utility";
import {CounterFields} from "enums/counterFields";

enum TabIndex {
  OrganizationsList,
  InvitesList
}

interface ExploreOrganizationsProps extends BaseProps {
  isSuperAdmin: boolean;
}

function ExploreOrganizations(props: ExploreOrganizationsProps) {
  const {toastProps, uid, setSelectedOrgId = emptyFunction, isSuperAdmin} = props;

  const [activeTab, setActiveTab] = useState<TabIndex>(TabIndex.OrganizationsList);
  const [isDrawerOpen, {open: openDrawer, close: closeDrawer}] = useComponentToggler(false);
  const allCount = useRTDBDocField<UserCounters>(userPath(uid!).path, CounterFields.OrganizationsCount) ?? 0
  const pendingInvitesCount = useRTDBDocField<UserCounters>(userPath(uid!).path, CounterFields.PendingInvitesCount) ?? 0

  const selectedOrgContext = useContext(SelectedOrgContext);
  const {selectedOrg} = selectedOrgContext!;

  const navigate = useNavigate();
  const {orgId} = useParams();

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  function handleTabChange(event: React.SyntheticEvent, newValue: number) {
    setActiveTab(newValue);
  }

  return (
    <Content>
      {isDrawerOpen && (
        <ManageOrganizationDrawer
          organization={null}
          isDrawerOpen={isDrawerOpen}
          closeDrawer={closeDrawer}
          toastProps={toastProps!}
          setSelectedOrgId={setSelectedOrgId}
          uid={uid}
          fromSAP={Boolean(selectedOrg.isAdminView)}
        />
      )}
      <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap={1} mb={2} pt={2}>
        <Typography variant="h1" id="organizations-title">
          {enOrganizationLabel.explore}
        </Typography>
        <Button
          onClick={openDrawer}
          variant="contained"
          id="create-new-organization-button"
        >
          {enOrganizationLabel.new}
        </Button>
      </Box>
      <CustomTabs
        value={activeTab}
        onChange={handleTabChange}
      >
        <CustomTab
          disableRipple
          label={
            <Stack direction="row" gap={0.5}>
              <Typography variant="h5">{enCommonLabel.all}</Typography>
              <Typography
                id="all-organizations-counter"
                color={theme.palette.neutral.dark}
                variant="h5"
              >
                {allCount > 0 ? allCount : ""}
              </Typography>
            </Stack>
          }
        />
        {!isSuperAdmin && (
          <CustomTab
            disableRipple
            label={
              <Stack direction="row" gap={0.5}>
                <Typography variant="h5">{enCommonLabel.invites}</Typography>
                {(pendingInvitesCount && (pendingInvitesCount) > 0) ?
                  <Typography
                    id="all-invites-counter"
                    color={theme.palette.neutral.dark}
                    variant="h5"
                  >
                    {pendingInvitesCount}
                  </Typography> : <></>
                }
              </Stack>
            }
            sx={{ml: 5}}
          />
        )}
      </CustomTabs>
      <TabPanel tabIndex={TabIndex.OrganizationsList} value={activeTab}>
        {activeTab === TabIndex.OrganizationsList && <OrganizationsList {...props} fromSAP={isSuperAdmin}/>}
      </TabPanel>
      <TabPanel tabIndex={TabIndex.InvitesList} value={activeTab}>
        {activeTab === TabIndex.InvitesList && <InvitesList {...props}/>}
      </TabPanel>
    </Content>
  );
}

export default ExploreOrganizations;
