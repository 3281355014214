import {AccessRole} from "enums/accessRole";
import {FormBase} from "types/FormBase";

export enum PermissionOperationKey {
  View = "View",
  Create = "Create",
  Update = "Update",
  Delete = "Delete",
  ChangeStatus = "ChangeStatus",
  OverrideStatus = "OverrideStatus",
  ManageOwner = "ManageOwner",
  ManageAdmin = "ManageAdmin",
  ManagePowerUser = "ManagePowerUser",
  ManageUser = "ManageUser",
  ManageGuest = "ManageGuest",
}

export enum PermissionEntity {
  SystemOwnerUser = "SystemOwnerUser",
  SignUp = "SignUp",
  User = "User",
  UserInvite = "UserInvite",
  UserMyTask = "UserMyTask",
  UserMyTimer = "UserMyTimer",
  UserFile = "UserFile",
  Organization = "Organization",
  OrganizationActivityFeed = "OrganizationActivityFeed",
  OrganizationAsset = "OrganizationAsset",
  OrganizationInvite = "OrganizationInvite",
  OrganizationMember = "OrganizationMember",
  OrganizationDataTag = "OrganizationDataTag",
  OrganizationTemplate = "OrganizationTemplate",
  OrganizationTemplateProject = "OrganizationTemplateProject",
  OrganizationTemplateMilestone = "OrganizationTemplateMilestone",
  OrganizationTemplateTask = "OrganizationTemplateTask",
  OrganizationTemplateFileRequirement = "OrganizationTemplateFileRequirement",
  OrganizationTemplateFormRequirement = "OrganizationTemplateFormRequirement",
  OrganizationTemplateImport = "OrganizationTemplateImport",
  OrganizationTimer = "OrganizationTimer",
  Project = "Project",
  ProjectDataTag = "ProjectDataTag",
  ProjectAccessList = "ProjectAccessList",
  ProjectChat = "ProjectChat",
  ProjectChatFile = "ProjectChatFile",
  ProjectActivityFeed = "ProjectActivityFeed",
  ProjectExport = "ProjectExport",
  ProjectImport = "ProjectImport",
  PLChat = "PLChat",
  PLChatFile = "PLChatFile",
  PLTask = "PLTask",
  PLTaskTimer = "PLTaskTimer",
  PLTaskChat = "PLTaskChat",
  PLTaskChatFile = "PLTaskChatFile",
  PLTaskDataTag = "PLTaskDataTag",
  PLTaskAccessList = "PLTaskAccessList",
  PLTaskActivityFeed= "PLTaskActivityFeed",
  PLTaskTimeLog= "PLTaskTimeLog",
  PLAccessList = "PLAccessList",
  PLActivityFeed = "PLActivityFeed",
  PLMilestone = "PLMilestone",
  PLFileRequirement = "PLFileRequirement",
  PLFileRequirementDataTag = "PLFileRequirementDataTag",
  PLFileRequirementChat = "PLFileRequirementChat",
  PLFileRequirementChatFile = "PLFileRequirementChatFile",
  PLFileRequirementActivityFeed = "PLFileRequirementActivityFeed",
  PLMilestoneFilesDownload = "PLMilestoneFilesDownload",
  PLFile = "PLFile",
  PLFileChat = "PLFileChat",
  PLFileChatFile = "PLFileChatFile",
  PLDownload = "PLDownload",
  Asset = "Asset",
  AssetDataTag = "AssetDataTag",
  ProjectAssetChat = "ProjectAssetChat",
  ProjectAssetChatFile = "ProjectAssetChatFile",
  ProjectAssetExport = "ProjectAssetExport",
  ProjectAssetImport = "ProjectAssetImport",
  ProjectAssetAccessList = "ProjectAssetAccessList",
  AssetFilesDownload = "AssetFilesDownload",
  AvailableAsset = "AvailableAsset",
  ProjectAssetActivityFeed = "ProjectAssetActivityFeed",
  Milestone = "Milestone",
  MilestoneFilesDownload = "MilestoneFilesDownload",
  Task = "Task",
  TaskChat = "TaskChat",
  TaskChatFile = "TaskChatFile",
  TaskDataTag = "TaskDataTag",
  TaskTimer = "TaskTimer",
  TaskExport = "TaskExport",
  TaskActivityFeed = "TaskActivityFeed",
  TaskAccessList = "TaskAccessList",
  FileRequirement = "FileRequirement",
  FileRequirementDataTag = "FileRequirementDataTag",
  FileRequirementActivityFeed = "FileRequirementActivityFeed",
  FileRequirementChat = "FileRequirementChat",
  FileRequirementChatFile = "FileRequirementChatFile",
  FormRequirementChatFile = "FormRequirementChatFile",
  SwiftFile = "SwiftFile",
  SwiftFileChat = "SwiftFileChat",
  SwiftFileChatFile = "SwiftFileChatFile",
  Form = "Form",
  FormDataTag = "FormDataTag",
  FormActivityFeed = "FormActivityFeed",
  FormRequirement = "FormRequirement",
  FormRequirementDataTag = "FormRequirementDataTag",
  FormRequirementActivityFeed = "FormRequirementActivityFeed",
  PLFormRequirement = "PLFormRequirement",
  PLFormRequirementDataTag = "PLFormRequirementDataTag",
  PLFormRequirementActivityFeed = "PLFormRequirementActivityFeed",
  PLFormRequirementChat = "PLFormRequirementChat",
  PLFormRequirementChatFile = "PLFormRequirementChatFile",
  FileUpload = "FileUpload",
  FileRequirementFilesDownload = "FileRequirementFilesDownload",
  FileUploadChunk = "FileUploadChunk",
  AccessList = "AccessList",
  Algolia = "Algolia",
  PushFileUpload = "PushFileUpload",
  Permission = "Permission",
  Team = "Team",
  TeamMember = "TeamMember",
  Email = "Email",
  TeamCategory = "TeamCategory",
  TaskFilesDownload = "TaskFilesDownload",
  TaskTimeLog = "TaskTimeLog",
  FormRequirementChat = "FormRequirementChat",
  UserActivityFeed = "UserActivityFeed",
  FormExport = "FormExport",
  UserHomeExport = "UserHomeExport",
  OrganizationTemplateExport = "OrganizationTemplateExport",
  PushBuildSystemOwner = "PushBuildSystemOwner",
  EntityChat = "EntityChat",
  OrganizationExport = "OrganizationExport",
  ReferenceFile = "ReferenceFile",
  ResetPasswordRequest = "ResetPasswordRequest",
  AdminPanel = "AdminPanel",
}

export type PermissionBase = {
  [key in PermissionOperationKey]: {
    [key in AccessRole]: boolean;
  }
}

export type PermissionForm = PermissionBase & {
  id: PermissionEntity;
};

export type AccessType = {
  [key in PermissionOperationKey]?: boolean;
}

export type Permission = PermissionForm & {
  "@form"?: PermissionForm & FormBase;
};
