import {ViewStatus} from "enums/viewStatus";
import {emptyFunction} from "constants/index";
import {FormBase, NumberObjectsDictionary, StringDictionary} from "types/FormBase";
import {errorStatuses} from "constants/errorStatuses";

export type ErrorMessageType = string | StringDictionary | NumberObjectsDictionary | undefined;

interface FormType {
  "@form"? : FormBase,
  "@messages"?: ErrorMessageType,
}

export interface StatusSubmitHandler<T extends FormType> {
  status: ViewStatus,
  data: T,
  isLastUpdate: boolean,
  successCallback?: () => void,
  errorCallback?: (message: ErrorMessageType) => void,
  byPassIsLastUpdate?: boolean
}

export function statusSubmitHandler<T extends FormType>(props: StatusSubmitHandler<T>) {
  const {status, data, isLastUpdate, byPassIsLastUpdate = false, successCallback = emptyFunction, errorCallback = emptyFunction} = props;

  if (!isLastUpdate && !byPassIsLastUpdate) return;

  switch (status) {
    case ViewStatus.Finished:
      successCallback();
      break;
    case ViewStatus.Error:
    case ViewStatus.ValidationError:
    case ViewStatus.SecurityError:
      errorCallback(data?.["@messages"]);
      break;
  }
}

interface BulkStatusSubmitHandler<T extends FormType> {
  data: StatusSubmitHandler<T>[],
  successCallback?: () => void,
  errorCallback?: (message: string | StringDictionary | NumberObjectsDictionary | undefined) => void,
}
export function bulkStatusSubmitHandler<T extends FormType>(props: BulkStatusSubmitHandler<T>) {
  const {data, successCallback = emptyFunction, errorCallback = emptyFunction} = props;
  const statuses = data.map((item) => item.status);

  if (statuses.some(status => errorStatuses.includes(status))) {
    const errorData = data.find(item => errorStatuses.includes(item.status));
    errorCallback(errorData?.data?.["@form"]?.["@messages"]);
    return;
  }

  if (statuses.every(status => status === ViewStatus.Finished)) {
    successCallback();
  }
}