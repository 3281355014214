import {toastProps} from "../../screens/BaseProps";
import {Severity} from "enums/severity";
import {useCallback} from "react";

function useToast(props: toastProps, severity: Severity, message: string) {
  const {setToastMessage, setToastSeverity, setIsToastOpen} = props

  return useCallback(() => {
    setToastMessage(message)
    setIsToastOpen(true);
    setToastSeverity(severity);
  }, [message])
}

export default useToast
