import {BaseProps} from "../BaseProps";
import {emptyFunction} from "constants/index";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {useEffect} from "react";

function AdminPanel(props: BaseProps) {
  const {selectedOrg, setSelectedOrgId = emptyFunction} = props;

  const navigate = useNavigate();
  const {orgId} = useParams();

  // save local id once refreshed
  useEffect(() => {
    if(orgId === undefined) return navigate("/");

    if(orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  return <div>helloo world - Admin</div>;
}

export default AdminPanel;
