export const formRequirement = {
  label: {
    cannotDeleteWithTemplate: "Form Requirement from template cannot be deleted",
    create: "Create New Form Requirement",
    createForm: "Create Form",
    requiredName: "Form Requirement name is required",
    form: "Form",
    noFormsYet: "No Forms Yet",
    createSuccess: (name: string) => `Form Requirement ${name.trim()} was created successfully`,
    edit: "Edit Form Requirement",
    delete: "Delete Form Requirement",
    activityFeed: "Activity Feed",
    chat: "Chat",
    formDeleted: "Form Deleted",
    formDeletedDescription: "Looks like the form for this requirement was deleted.",
    editSuccess: "Form Requirement edited successfully",
    formCreateError: "Unable to create this form",
    filledFields: (fieldsCount: number, totalFieldsCount: number) => `${fieldsCount}/${totalFieldsCount} fields filled`,
    filledFieldsLabel: "Fields filled / Total Forms Fields Count",
    approvedForm: "Form is approved",
    allFilesApproved: "All files are approved",
    noChanges: "No changes",
    lastChangedBy: (name: string) => `Last changed by: ${name}`,
    editForm: "Edit Form",
    updateSuccess: "Form updated successfully",
    uniqueNameError: "Name must be unique",
    formDeleteConfirmationTitle: "Are you sure you want to delete this form?",
    formDeleteConfirmationText: "This item will be deleted immediately. You can't undo this action.",
    deleteSuccess: "Successfully deleted Form Requirement",
    singleHideSuccess: "Form requirement hidden",
    singleUnHideSuccess: "Form requirement unhidden",
  }
}
