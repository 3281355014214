import {ToastProps} from "../../BaseProps";
import {UpdatedItemType} from "types/ActivityFeedTypes";
import BaseItem from "./BaseItem";
import SystemIndex from "assets/icons/system/system.index";
import IconBaseProps from "./IconBaseProps";
import {enActivityFeedLabel} from "constants/index";
import {Typography} from "@mui/material";
import {useDocument} from "hooks/index";
import {doc} from "firebase/firestore";
import {userActivityFeedPath} from "../../utility";
import {SkeletonItem} from "components/index";
import React from "react";

interface UpdatedItemProps extends ToastProps {
  itemId: string;
  uid: string;
  index: number;
  updatePinnedValue: (index: number, pinned: boolean) => void;
}

function UpdatedItem(props: UpdatedItemProps) {
  const {toastProps, itemId, uid, updatePinnedValue, index} = props;

  const [activityFeed] = useDocument<UpdatedItemType>(doc(userActivityFeedPath(uid), itemId));

  if (!activityFeed) {
    return <SkeletonItem height={"5rem"} sx={{marginTop: "5px", width: "80%"}}/>
  }

  const {details, entity} = activityFeed;
  const {by, name, from, to, reasonForChange = ""} = details;

  const reasonForChangeText = !!reasonForChange && !!reasonForChange.trim() && enActivityFeedLabel.reasonForChange(reasonForChange);
  const updatedChangesText = enActivityFeedLabel.updatedChangesDescription(from, to);

  return <BaseItem
    index={index}
    uid={uid}
    item={activityFeed}
    icon={<SystemIndex.SystemIcons.Edit {...IconBaseProps}/>}
    text={enActivityFeedLabel.updated(entity, name, by.name ?? "")}
    additionalText={(
      <>
        <Typography>{updatedChangesText}</Typography>
        { reasonForChangeText && <Typography>{reasonForChangeText}</Typography>}
      </>
    )}
    toastProps={toastProps}
    updatePinnedValue={updatePinnedValue}
  />
}

export default UpdatedItem
