import {Box, Breadcrumbs, Link, Stack, Typography, useMediaQuery} from "@mui/material";
import theme from "theme/theme";
import {LongText} from "components/index";
import {BreadCrumbType} from "../screens/utility/generateEntityBreadcrumbs";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";

interface BreadcrumbsDisplayProps {
  entityBreadcrumbs: BreadCrumbType[];
  orgName?: string;
}

const orgIconWidth = 50
const arrowIconWidth = 50

function BreadcrumbsDisplay(props: BreadcrumbsDisplayProps) {
  const {entityBreadcrumbs, orgName} = props;
  const nav = useNavigate();
  const containerRef = useRef<HTMLElement>(null)
  const xsToMid = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const [maxWidth, setMaxWidth] = useState<string>("100%");

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const separatorTotalWidths = (entityBreadcrumbs.length) * entityBreadcrumbs.length;
      const extraSpace = xsToMid ? arrowIconWidth : arrowIconWidth + orgIconWidth;
      const newWidth = (containerWidth - separatorTotalWidths - extraSpace) / entityBreadcrumbs.length + "px";
      setMaxWidth(newWidth);
      return;
    }

    setMaxWidth("100%");
  }, [containerRef.current?.clientWidth, entityBreadcrumbs]);

  return <Stack
    direction="row"
    alignItems="center"
    width="100%"
    position="relative"
  >
    <SubdirectoryArrowRightIcon
      sx={{
        height: 13,
        color: theme.palette.primary.main,
        position: "relative",
        bottom: 1
      }}
    />
    <Breadcrumbs sx={{width: "100%", minWidth: "100%", maxWidth: "100%"}} ref={containerRef} separator={<Typography color="divider">|</Typography>}>
      {entityBreadcrumbs.map((breadcrumb, index) => {
          return <Link
            onClick={e => {
              e.stopPropagation();
              nav(breadcrumb.path)
            }}
            underline="hover"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              cursor: "pointer",
            }}
            color="text.primary"
            key={breadcrumb.path + index}
          >
            {index === 0 && orgName && <Box
              sx={{
                display: {xs: "none", md: "grid"},
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.text.onDark,
                height: 16,
                width: 16,
                placeItems: "center",
                borderRadius: 1,
                marginRight: 1,
              }}
            >
              <Typography
                variant={"caption"}
                sx={{lineHeight: "0px"}}
              >
                {orgName.slice(0, 1)}
              </Typography>
            </Box>}
            <LongText
              maxLines={1}
              variant="caption"
              textColor="text.secondary"
              maxWidth={maxWidth}
              breakAll
            >
              {breadcrumb.name}
            </LongText>
          </Link>
        }
      )}
    </Breadcrumbs>
  </Stack>
}

export default BreadcrumbsDisplay;
