import {Avatar} from "@mui/material";
import {ReadReceipt} from "types/ChatMessage";
import ExcessAvatarTooltip from "components/ChatDrawer/ChatItemsView/ExcessAvatarTooltip";

interface ExcessAvatarProps {
  excessReadReceipts: number;
  readReceiptsData: ReadReceipt[]
}

const MAX_DISPLAY_EXCESS = 99;

function ExcessAvatar(props: ExcessAvatarProps) {
  const {excessReadReceipts, readReceiptsData} = props;

  if (excessReadReceipts < 1) return null;

  return (
    <ExcessAvatarTooltip readReceiptsData={readReceiptsData}>
      <Avatar sx={{mt: 0}}>
        {`+${excessReadReceipts > MAX_DISPLAY_EXCESS ? MAX_DISPLAY_EXCESS: excessReadReceipts}`}
      </Avatar>
    </ExcessAvatarTooltip>
  )
}

export default ExcessAvatar;