import useSound from "use-sound";

function useSoundWithSettings(src: string) {

  const userSettings = JSON.parse(localStorage.getItem('userSettings') ?? JSON.stringify({volume: 0.5}));

  const [playSound] = useSound(src, userSettings);

  return playSound;
}

export default useSoundWithSettings;
