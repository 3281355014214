export const milestone = {
  title: "Milestone",
  subtitle: "",
  description: "",
  label: {
    emptyTitle: "This milestone is empty",
    search: "Search Milestone...",
    new: "New Milestone",
    create: "Create New Milestone",
    edit: "Edit Milestone",
    createFirst: "Create the first task.",
    nameRequired: "Milestone name is required.",
    createSuccess: "Milestone was successfully created.",
    createError: "Milestone creation failed.",
    updateSuccess: "Milestone updated successfully.",
    updateError: "Milestone update has failed.",
    deleteError: "Error deleting milestone",
    deleteSuccess: "Milestone deleted successfully",
    deleteConfirmationTitle: "Are you sure you want to delete this milestone?",
    deleteConfirmationText: "These items will be deleted immediately. You can't undo this action.",
    scheduleStatus: "Schedule Status",
    all: "All Milestones",
    activityFeed: "Activity Feed",
    chat: "Chat",
    hideEmptyMilestones: "Hide Empty Milestones",
    milestoneItemCounter: "Approved Task / Tasks Count",
    tasksCount: "Tasks Count",
    milestoneRequirementsCounter: "Approved Requirements / Requirements Count",
    milestoneFilesCounter: "Approved Files / Files Count",
    hideSuccess: "Requirements hidden",
    unHideSuccess: "Requirements unhidden",
    buildingMilestone: "Building Milestone...",
  },
  button: {
    download: "Download All Files",
    import: "Import Project Asset Data",
    export: "Export Project Asset Data",
    pltExport: "Export Project Level Data",
    delete: "Delete Milestone",
    edit: "Edit Milestone"
  },
}