import {FileFormat} from "enums/fileFormat";
import {firefoxCSVMimeType, mimeType} from "constants/mimeType";

export function isImportFileValid(file: File, fileFormat: FileFormat) {
  if (file.type === mimeType[fileFormat])
    return true;

  if (fileFormat === FileFormat.CSV && file.type === firefoxCSVMimeType)
    return true;

  return false;
}