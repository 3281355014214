import {Box, Checkbox, FormControlLabel, IconButton, Slider, Stack, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {Dispatch, forwardRef, useEffect, useImperativeHandle, useState} from "react";
import SettingsItem from "./SettingsItem";
import {enCommonLabel, enProfileAndSettingsLabel} from "constants/index";
import ChangePassword from "./ChangePassword";
import {useDocument} from "hooks/index";
import {submitForm, userPath} from "../../../utility";
import {User} from "types/User";
import TermsOfService from "./TermsOfService";
import {UserSetting} from "types/UserSettings";
import {ActionType, Severity, ProcessType} from "enums/index";
import {BaseProps} from "../../../BaseProps";
import {defaultUserSetting} from "constants/defaultUserSetting";
import {ValidationMessage} from "components/index";
import DataPolicy from "./DataPolicy";
import {statusSubmitHandler} from "../../../utility/statusSubmitHandler";
import getEnvKey from "../../../utility/getEnvKey";
import {versionKey} from "constants/envKeys";

const CURRENT_VERSION = getEnvKey(versionKey) || "1.0.0";

interface SettingsProps extends BaseProps {
  setIsSaveButtonValid: Dispatch<boolean>
  setLoading: Dispatch<boolean>
}

const Settings = forwardRef((props: SettingsProps, ref) => {
  const {setIsSaveButtonValid, uid, setLoading, toastProps} = props;
  const {setIsToastOpen, setToastSeverity, setToastMessage} = toastProps!;

  const [user] = useDocument<User>(userPath(uid));
  const [userSettingData, setUserSettingData] = useState<UserSetting>(user?.settings ?? defaultUserSetting);
  const [userSettingErrorData, setUserSettingErrorData] = useState<UserSetting>({} as UserSetting);
  const {
    soundNotificationsVolume,
    sendNotificationsToDevice,
    sendNotificationsToEmail,
    enableSoundNotifications,
  } = userSettingData;

  //reset button state on load
  useEffect(() => {
    setIsSaveButtonValid(false);
  }, []);

  // Use `useImperativeHandle` to expose functions to parent component
  useImperativeHandle(ref, () => ({
    saveSettings,
  }));

  //load userData
  useEffect(() => {
    if (!user) return;

    setUserSettingData(user.settings ?? defaultUserSetting);
    setLoading(false)
  }, [user]);

  //validate difference in fields to check ability of save button
  useEffect(() => {
    if (!user)
      return

    const previousSettings = user.settings ?? defaultUserSetting;

    if (previousSettings.sendNotificationsToEmail !== sendNotificationsToEmail) {
      setIsSaveButtonValid(true)
      return
    }

    if (previousSettings.sendNotificationsToDevice !== sendNotificationsToDevice) {
      setIsSaveButtonValid(true)
      return
    }

    if (previousSettings.enableSoundNotifications !== enableSoundNotifications) {
      setIsSaveButtonValid(true)
      return
    }

    if (previousSettings.soundNotificationsVolume !== soundNotificationsVolume) {
      setIsSaveButtonValid(true)
      return;
    }

    setIsSaveButtonValid(false)
  }, [userSettingData])

  const saveSettings = async () => {
    setLoading(true)
    await submitForm<Partial<User>>(userPath(uid), ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler<User>({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback
      }),
      {settings: userSettingData})
  }

  function successCallback() {
    setLoading(false);
    setToastMessage(enProfileAndSettingsLabel.saveSuccessToast);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
  }

  function errorCallback(message: any) {
    setLoading(false);
    if (typeof message === "object") {
      setUserSettingErrorData(message as UserSetting);
      return;
    }

    setToastSeverity(Severity.Error);
    setToastMessage(message || enCommonLabel.errorProcess(ProcessType.Update));
    setIsToastOpen(true);
  }

  function onMinusClick() {
    updateUserData("soundNotificationsVolume", Math.max(soundNotificationsVolume - 5, 0))
  }

  function onPlusClick() {
    updateUserData("soundNotificationsVolume", Math.min(soundNotificationsVolume + 5, 100))
  }

  function updateUserData(key: keyof UserSetting, value: any) {
    setUserSettingData({...userSettingData, [key]: value})
  }

  return <Stack sx={{backgroundColor: theme.palette.background.swiftDefault}} padding={1.5} borderRadius={1} gap={2}>

    <Stack gap={0.5}>
      <Typography variant="h5">{enProfileAndSettingsLabel.notifications}</Typography>
      <SettingsItem direction="column" alignItems="flex-start">
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e, checked) => updateUserData("sendNotificationsToDevice", checked)}
              checked={sendNotificationsToDevice}
            />
          }
          label={enProfileAndSettingsLabel.sendDeviceNotifications}/>
        <ValidationMessage validationMessage={userSettingErrorData.sendNotificationsToDevice?.toString()}/>
      </SettingsItem>

      <SettingsItem direction="column" alignItems="flex-start">
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e, checked) => updateUserData("sendNotificationsToEmail", checked)}
              checked={sendNotificationsToEmail}
            />
          }
          label={enProfileAndSettingsLabel.sendEmailNotifications}/>
        <ValidationMessage validationMessage={userSettingErrorData.sendNotificationsToEmail?.toString()}/>
      </SettingsItem>
    </Stack>

    <Stack gap={0.5}>
      <Typography variant="h5">{enProfileAndSettingsLabel.soundNotifications}</Typography>
      <SettingsItem direction="column" alignItems="stretch">
        <Stack direction={{xs: "column", md: "row"}} justifyContent="space-between">
          <FormControlLabel
            control={<Checkbox
              checked={enableSoundNotifications}
              onChange={(e, checked) => {
                updateUserData("enableSoundNotifications", checked)
              }}
            />}
            label={enProfileAndSettingsLabel.enableSoundNotifications}/>
          <Stack direction="row" alignItems="center">
            <Box sx={{
              paddingX: 2,
              display: "flex",
              alignItems: "center",
              border: `1px solid ${theme.palette.divider}`,
              borderBottomLeftRadius: 1,
              borderTopLeftRadius: 1,
              height: 30,
              flex: 1,
              minWidth: {xs: "auto", md: 360}
            }}>
              <Slider
                value={soundNotificationsVolume}
                disabled={!enableSoundNotifications}
                onChange={(event, value) => {
                  updateUserData("soundNotificationsVolume", value)
                }}
              />
            </Box>
            <Stack
              direction="row"
              gap={1}
              paddingX={2}
              alignItems="center"
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                borderLeft: "none",
                borderTopRightRadius: 1,
                borderBottomRightRadius: 1,
                height: 30,
              }}>
              <IconButton size="small" onClick={onMinusClick} disabled={!userSettingData.enableSoundNotifications}>
                <SystemIcons.Minus
                  stroke={enableSoundNotifications ? theme.palette.primary.main : theme.palette.neutral.medium}/>
              </IconButton>
              <Typography
                minWidth="5ch"
                color={enableSoundNotifications ? "text.primary" : "text.disabled"}
              >
                {soundNotificationsVolume}%
              </Typography>
              <IconButton size="small" onClick={onPlusClick} disabled={!enableSoundNotifications}>
                <SystemIcons.Plus
                  stroke={enableSoundNotifications ? theme.palette.primary.main : theme.palette.neutral.medium}/>
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
        <ValidationMessage validationMessage={userSettingErrorData.soundNotificationsVolume?.toString()}/>
        <ValidationMessage validationMessage={userSettingErrorData.enableSoundNotifications?.toString()}/>
      </SettingsItem>
    </Stack>

    <Stack gap={0.5}>
      <Typography variant="h5">{enProfileAndSettingsLabel.language}</Typography>
      <SettingsItem gap={1 / 2}>
        <Typography>{enProfileAndSettingsLabel.english}</Typography>
        <Typography component="span" color="neutral.dark">{enProfileAndSettingsLabel.englishSubText}</Typography>
      </SettingsItem>
    </Stack>

    <Stack gap={0.5}>
      <Typography variant="h5">{enProfileAndSettingsLabel.privacy}</Typography>
      <ChangePassword toastProps={toastProps!}/>
    </Stack>
    <Stack gap={0.5}>
      <Typography variant="h5">{enProfileAndSettingsLabel.about}</Typography>
      <SettingsItem>
        {enProfileAndSettingsLabel.version}: {CURRENT_VERSION}
      </SettingsItem>
      <DataPolicy/>
      <TermsOfService/>
    </Stack>
  </Stack>
});

export default Settings;
