import theme from "theme/theme";
import {Divider, IconButton, MenuItem, Select, Stack, Tooltip, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import {FIELD_TYPE_ICONS} from "./index";
import {FormFieldType} from "enums/formFieldType";
import {useState} from "react";
import {FormField} from "types/FormField";
import {enFormsLabel} from "constants/index";
import {DefaultFormField} from "./DefaultFormField";
import {ListFormField} from "./ListFormField";
import {DraggableProvided} from "react-beautiful-dnd";
import {DateFormField} from "./DateFormField";
import {ParagraphFormField} from "./ParagraphFormField";
import {useComponentToggler} from "hooks/index";
import AdvanceOptions from "./AdvancedOptions/AdvanceOptions";

type BaseFieldProps = {
  changeFormData: (value: any, key: any, index?: number, isFormFieldData?: boolean) => void;
  index: number;
  formField: FormField<FormFieldType>;
  isEditable: boolean;
  onEditClick: () => void;
  onDeleteClick: () => void;
  isDragging: boolean;
  draggableProvided: DraggableProvided;
  isDeleteDisabled: boolean;
  onFormKeyPress?: (e: React.KeyboardEvent) => void;
}

export type FormFieldProps = {
  changeFormData: (value: any, key: any, index?: number, isFormFieldData?: boolean) => void;
  formField: FormField<FormFieldType>;
  isEditable: boolean;
  index: number;
  onFormKeyPress?: (e: React.KeyboardEvent) => void;
}

export function BaseField(props: BaseFieldProps) {
  const {
    changeFormData,
    index,
    formField,
    isEditable,
    isDragging,
    onEditClick,
    onDeleteClick,
    draggableProvided,
    isDeleteDisabled,
  } = props;
  const [isAdvancedOptionsOpen, setIsAdvancedOptionsOpen] = useState<boolean>(false);
  const [isDisabledDeleteTooltipOpen, {
    open: showDisabledDeleteTooltip,
    close: hideDisabledDeleteTooltip
  }] = useComponentToggler(false);
  const fieldType = formField.type;

  return (
    <Stack
      id={`formField_${index}`}
      sx={{
        padding: "1.5rem",
        gap: 1.5,
        borderRadius: 1,
        backgroundColor: theme.palette.background.swiftDefault,
        outline: formField.errors && Object.keys(formField.errors).length > 0 ? `2px solid ${theme.palette.error.main}`: ''
      }}
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      data-is-dragging={isDragging}
      data-index={index}
    >
      <Stack direction="row" justifyContent="space-between" mb={1.5} alignItems="center">
        <Stack direction="row" gap={1.5} alignItems="center">
          <Typography variant="h4">{`${enFormsLabel.fieldType} `}</Typography>
          <Select
            size="small"
            value={fieldType}
            onChange={(e: any) => changeFormData(e.target.value, "type", index, true)}
            disabled={!isEditable}
            sx={{backgroundColor: theme.palette.common.white}}
          >
            {
              Object.entries(FIELD_TYPE_ICONS).map((entry, index) => (
                <MenuItem key={`fieldType_${index}`} value={entry[0]}>
                  <Stack direction="row" gap={1} alignItems="center">
                    {entry[1]}
                    <Typography variant="h4">{entry[0]}</Typography>
                  </Stack>
                </MenuItem>
              ))
            }
          </Select>
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <Tooltip
            title={enFormsLabel.atleastOneField}
            open={isDisabledDeleteTooltipOpen}
            onOpen={() => {
              return !isDeleteDisabled ? null : showDisabledDeleteTooltip()
            }}
            onClose={hideDisabledDeleteTooltip}
          >
            <span>
              <IconButton onClick={isEditable ? onDeleteClick : onEditClick} disabled={isDeleteDisabled}>
                {
                  isEditable ? (
                    <SystemIcons.DeleteOutlined
                      stroke={isDeleteDisabled ? theme.palette.neutral.main : undefined}/>
                  ) : (
                    <SystemIcons.Edit/>
                  )
                }
              </IconButton>
            </span>
          </Tooltip>
          <Divider orientation="vertical" sx={{height: 24}} color={theme.palette.divider}/>
          <IconButton {...draggableProvided.dragHandleProps} disableRipple>
            <SystemIcons.Drag/>
          </IconButton>
        </Stack>
      </Stack>
      <Stack gap={3}>
        <Stack gap={1.5}>
          <DefaultFormField {...props}/>
        </Stack>
        {
          fieldType === FormFieldType.List && (
            <ListFormField {...props}/>
          )
        }
        {
          fieldType === FormFieldType.Date && (
            <DateFormField {...props}/>
          )
        }
        {
          fieldType === FormFieldType.Paragraph && (
            <ParagraphFormField {...props}/>
          )
        }
      </Stack>
      <AdvanceOptions
        isEditable={isEditable}
        isAdvancedOptionsOpen={isAdvancedOptionsOpen}
        setIsAdvancedOptionsOpen={setIsAdvancedOptionsOpen}
        formField={formField}
        changeFormData={changeFormData}
        index={index}
      />
    </Stack>
  )
}

