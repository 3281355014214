import {Member} from "types/Member";
import {Checkbox, FormControlLabel, Stack, Typography} from "@mui/material";
import {CheckBoxOutlineBlank} from "@mui/icons-material";
import theme from "theme/theme";
import React from "react";
import {Avatar} from "components/index";
import {ACCESS_ROLE_LABEL} from "constants/accessRoleLabel";

interface MemberItemProps {
  onClick: (uid: string) => void;
  member: Member;
  isSelected?: boolean
}

export default function MemberItem(props: MemberItemProps) {
  const {member, isSelected, onClick} = props;
  const {name} = member;

  return (
    <FormControlLabel
      id={`memberItem_${member["@id"]}`}
      control={<Checkbox
        icon={
          <CheckBoxOutlineBlank
            sx={{
              color: theme.palette.neutral.main,
            }}
          />
        }
        checked={isSelected}
        onChange={() => {
          onClick(member["@id"]!)
        }}
      />} label={
      <Stack direction="row" gap={1.5} alignItems="center">
        <Avatar
          {...member}
          sx={{width: 32, height: 32, fontSize: 12, mt: 0, border: 0}}
          nameComponent={(
            <Stack>
              <Typography variant="h5">{name}</Typography>
              <Typography variant="bodySmall">{ACCESS_ROLE_LABEL[member.role]}</Typography>
            </Stack>
          )}
        />
      </Stack>
    }/>
  )
}
