import {ProjectStatus as Status} from "enums/projectStatus";
import {StatusIcons} from "assets/icons/status/status.index";
import theme from "theme/theme";
import {enCommonStatus} from "constants/index";

export type StatusItem = {
  title: string;
  icon: any;
  color: string;
  checked: boolean;
}

export type ProjectStatusProps = Record<string, StatusItem>;

export const ProjectStatusList: ProjectStatusProps = {
  [Status.InProgress]: {
    title: enCommonStatus.inProgress,
    icon: StatusIcons.InProgress,
    color: theme.palette.secondary.main,
    checked: false,
  },
  [Status.Pending]: {
    title: enCommonStatus.pending,
    icon: StatusIcons.Pending,
    color: theme.palette.secondary.main,
    checked: false,
  },
  [Status.Submitted]: {
    title: enCommonStatus.submitted,
    icon: StatusIcons.Submitted,
    color: theme.palette.secondary.main,
    checked: false,
  },
  [Status.Rejected]: {
    title: enCommonStatus.rejected,
    icon: StatusIcons.Rejected,
    color: theme.palette.error.main,
    checked: false,
  },
  [Status.Cancelled]: {
    title: enCommonStatus.cancelled,
    icon: StatusIcons.Cancelled,
    color: theme.palette.error.main,
    checked: false,
  },
  [Status.Approved]: {
    title: enCommonStatus.approved,
    icon: StatusIcons.Approved,
    color: theme.palette.success.main,
    checked: false,
  }
}

export const ProjectStatusWithIcons: Record<string, StatusItem> = {
  [Status.Cancelled]: {
    title: enCommonStatus.cancelled,
    icon: StatusIcons.Cancelled,
    color: theme.palette.error.main,
    checked: false,
  },
  [Status.Pending]: {
    title: enCommonStatus.pending,
    icon: StatusIcons.Pending,
    color: theme.palette.secondary.main,
    checked: false,
  },
  [Status.InProgress]: {
    title: enCommonStatus.inProgress,
    icon: StatusIcons.InProgress,
    color: theme.palette.secondary.main,
    checked: false,
  },
  [Status.Approved]: {
    title: enCommonStatus.approved,
    icon: StatusIcons.Approved,
    color: theme.palette.success.main,
    checked: false,
  }
}
