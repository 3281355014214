import {keyframes, Stack} from "@mui/material";
import {styled} from "@mui/system";
import theme from "theme/theme";

type BouncingDotsProps = {
  dotSize?: number;
  durationMs?: number;
  color?: string;
}

const DOT_DELAYS = [0, 200, 400];

export default function BouncingDots(props: BouncingDotsProps) {
  const {dotSize = 16, durationMs = 1000, color = theme.palette.neutral.main} = props;
  const dotStyle = {
    width: dotSize,
    height: dotSize,
    backgroundColor: color,
  }

  return (
    <Stack direction="row" gap={0.5} className="dots-wrapper">
      {DOT_DELAYS.map((delay) => (
        <Dot key={`delayed_${delay}`} style={dotStyle} sx={{
          animation: `${bounceFrames} ${durationMs}ms linear ${delay}ms infinite`
        }}/>
      ))}
    </Stack>
  )
}

const Dot = styled("div")({
  borderRadius: "50%",
  opacity: 1,
});

const bounceFrames = keyframes`
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 0.6;
    transform: translateY(-2px) scale(1.3);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;
