import theme from "theme/theme";
import {enMilestoneLabel} from "constants/index";
import {Typography} from "@mui/material";
import React from "react";
import {useParams} from "react-router-dom";

interface MilestoneCounterLabelProps {
  tasksApprovedCount: undefined | number;
  tasksCount: undefined | number;
}
function MilestoneCounterLabel(props: MilestoneCounterLabelProps) {
  const {tasksApprovedCount = 0, tasksCount = 0} = props;
  const {templateId} = useParams();
  const fromTemplate = typeof templateId === "string";

  const title = fromTemplate ? enMilestoneLabel.tasksCount : enMilestoneLabel.milestoneItemCounter;
  const text = fromTemplate ? tasksCount : `${tasksApprovedCount ?? 0}/${tasksCount ?? 0}`;

  return (
    <Typography
      variant="h4"
      color={theme.palette.neutral.dark}
      title={title}
    >
      {text}
    </Typography>
  )
}

export default MilestoneCounterLabel;