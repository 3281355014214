import {TimeLog} from "types/TimeLog";
import {useEffect, useState} from "react";
import {formatDateTimeFirestoreDate} from "../../utility";
import {Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import TimeLogComponent from "./TimeLogComponent";

interface TimeLogGroupProps {
  timeLogs: TimeLog[]
}

interface DateInfo {
  month: string,
  day: number,
  year: number
}

function TimeLogGroup(props: TimeLogGroupProps) {
  const {timeLogs} = props;
  const [groups, setGroups] = useState<Record<string, [TimeLog[], DateInfo]>>({})

  useEffect(() => {
    const newGroups: Record<string, [TimeLog[], DateInfo]> = {};

    timeLogs.forEach((timeLog) => {
      const {month, day, year} = formatDateTimeFirestoreDate(timeLog.startDateTime);
      const key = `${month}${day}${year}`
      if (!newGroups[key])
        newGroups[key] = [[], {month, day, year}]
      newGroups[key][0].push(timeLog)
    })
    setGroups(newGroups)

  }, [timeLogs])

  function sortGroups() {
    return Object.values(groups).sort((a, b) => {
      const [, dateA] = a;
      const [, dateB] = b;

      if (dateA.year > dateB.year)
        return -1
      if (dateA.year < dateB.year)
        return 1

      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const monthRankA = months.indexOf(dateA.month)
      const monthRankB = months.indexOf(dateB.month);

      if (monthRankA > monthRankB)
        return -1
      if (monthRankA < monthRankB)
        return 1

      if (dateA.day > dateB.day)
        return -1
      if (dateA.day < dateB.day)
        return 1

      return 0
    })
  }

  return <Stack gap={3}>
    {sortGroups().map(([timeLogs, DateInfo], index) => {
      const {month, day, year} = DateInfo;
      return (
        <Stack gap={2} key={`timelog-group-${index}`}>
          <Typography variant="h5" mt={1}>
            {month} {day}
            <Typography
              variant="h5"
              color={theme.palette.text.secondary}
              component="span">
              , {year}
            </Typography>
          </Typography>
          <Stack gap={1}>
            {timeLogs.map((timeLog) => <TimeLogComponent timeLog={timeLog} key={`timelog-component-${timeLog.id}`}/>)}
          </Stack>
        </Stack>
      )
    })}
  </Stack>

}

export default TimeLogGroup