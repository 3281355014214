export enum FormImportOptions {
  selectMultiple = "select_multiple",
  allowDecimal = "allow_decimal",
  minimumCharacters = "minimum_characters",
  maximumCharacters = "maximum_characters",
  includeTime = "include_time",
  items = "items",
}

export const formImportOptionsArray: FormImportOptions[] = [
  FormImportOptions.allowDecimal,
  FormImportOptions.includeTime,
  FormImportOptions.items,
  FormImportOptions.maximumCharacters,
  FormImportOptions.minimumCharacters,
  FormImportOptions.selectMultiple,
]

export const formImportCsvToFrontendMap: Record<FormImportOptions, string> = {
  [FormImportOptions.allowDecimal]: "allowDecimal",
  [FormImportOptions.includeTime]: "includeTime",
  [FormImportOptions.items]: "items",
  [FormImportOptions.maximumCharacters]: "maxChars",
  [FormImportOptions.minimumCharacters]: "minChars",
  [FormImportOptions.selectMultiple]: "selectMultiple",
}