import {FormFieldProps} from "./FormFieldProps";
import {enCommonLabel} from "constants/index";
import UnEditedBaseField from "./UnEditedBaseField";
import React, {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import theme from "theme/theme";
import NumberInput from "components/inputs/NumberInput";
import FormFieldContainer from "./FormFieldContainer";
import {isFormFieldValid} from "../utils";
import {FormFieldType} from "enums/formFieldType";
import {FormField} from "types/FormField";

interface NumericFormFieldProps extends FormFieldProps {
  field: FormField<FormFieldType.Numeric>
}

function NumericFormField(props: NumericFormFieldProps) {
  const {isEditMode, field, index, updateLocalField} = props;
  const {value, allowDecimal} = field;

  const [number, setNumber] = useState<number | undefined>(typeof value === "object" ? undefined: value);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const fieldId = `numeric-field-${index}`;

  useEffect(() => {
    setErrorMessage("");
  }, [isEditMode]);

  useEffect(() => {
    updateLocalField(number, index);
    const [, message] = isFormFieldValid({...field, value: number});
    setErrorMessage(message)
  }, [number]);

  if (!isEditMode) {
    return <UnEditedBaseField
      fieldId={fieldId}
      defaultValue={enCommonLabel.none}
      {...field}
    />
  }

  function handleInputChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | React.ClipboardEvent<HTMLDivElement>) {
    const wholeNumReg = /^[0-9\b]+$/;
    const decimalNumReg = /^[+-]?\d*\.\d+$|^[+-]?\d+(\.\d*)?$/;
    const targetValue = (e.target as HTMLInputElement).value;

    if(targetValue === "") {
      setNumber(undefined);
      return;
    }

    const regPattern = allowDecimal ? decimalNumReg : wholeNumReg;

    if(regPattern.test(targetValue))
      setNumber(Number(targetValue));
    else
      (e.target as HTMLInputElement).value = targetValue.slice(-1);
  }

  return (
    <FormFieldContainer fieldId={fieldId} {...field}>
      <Stack direction="row">
        <NumberInput
          onChange={handleInputChange}
          value={number}
          validationMessage={errorMessage}
          textfieldSx={{width: "300px", backgroundColor: theme.palette.background.paper}}
        />
      </Stack>
    </FormFieldContainer>
  )
}

export default NumericFormField;