import BaseTimerCard from "components/Timers/TimerCard/BaseTimerCard";
import {doc, Timestamp} from "firebase/firestore";
import {myTimersPath} from "screens/utility/FirebasePath";
import SubmitForm from "screens/utility/submitForm";
import {ActionType} from "enums/actionType";
import {Timer} from "types/Timer";
import {TimerState} from "types/Task";
import SystemIndex from "assets/icons/system/system.index";
import theme from "theme/theme";
import {emptyFunction} from "constants/index";

interface PausedTimerCardProps {
  uid: string;
  timer: Timer
  isListLoading: boolean
  organizationTimer?: boolean
  collapsible: boolean
}

function PausedTimerCard(props: PausedTimerCardProps) {
  const {timer, organizationTimer, isListLoading, collapsible} = props;

  const StartIcon = <SystemIndex.SystemIcons.Play
    width={18}
    height={18}
    stroke={theme.palette.background.paper}
    fill={theme.palette.background.paper}
  />

  function onPlayClick() {
    const docRef = doc(myTimersPath(props.uid), timer.id)
    SubmitForm<Partial<Timer>>(docRef, ActionType.Update, emptyFunction, {
      timerState: TimerState.Resumed,
      lastTimerStartedDateTime: Timestamp.now(),
      timerLocation: timer.timerLocation
    })
  }

  return <BaseTimerCard
    uid={props.uid}
    collapsible={collapsible}
    timer={timer}
    onActionClick={onPlayClick}
    ActionIcon={StartIcon}
    organizationTimer={organizationTimer}
    isListLoading={isListLoading}
  />
}

export default PausedTimerCard
