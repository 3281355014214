import {Input, SimpleFormDrawer} from "components/index";
import {BaseProps, toastProps} from "screens/BaseProps";
import {ReferenceFile} from "types/index";
import React, {useEffect, useRef, useState} from "react";
import {enCommonButton, enCommonLabel, enReferencesButton, enReferencesLabel} from "constants/index";
import DataTagInput from "components/DataTag/DataTagInput";
import {SystemIcons} from "assets/icons/system/system.index";
import {Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {areObjectsEqual, referenceFilespath, submitForm, userFilesPath} from "screens/utility";
import {ActionType, Severity, UserFileType} from "enums/index";
import {useParams} from "react-router";
import useCheckUniqueness from "hooks/useCheckUniqueness";
import {doc, Timestamp} from "firebase/firestore";
import ReferenceButton from "./ReferenceButton";
import {statusSubmitHandler} from "screens/utility/statusSubmitHandler";
import useFileUpload from "hooks/useFileUpload/useFileUpload";
import {generateUniqueId} from "hooks/useFileUpload/reducer/reducer";

type CreateEditDrawerProps = BaseProps & {
  isOpen: boolean;
  onClose: () => void;
  toastProps: toastProps;
  resetTargetReferenceFile: () => void;
  referenceFile?: ReferenceFile;
}

const INITIAL_DATA: ReferenceFile = {
  name: "",
  description: "",
  dataTagsIds: [],
  tempFilepath: "",
  fileName: "",
  tempFilePreviewPath: "",
}

function ManageReferenceFileDrawer(props: CreateEditDrawerProps) {
  const {isOpen, toastProps, referenceFile, uid} = props;
  const {onClose, resetTargetReferenceFile} = props;
  const {orgId} = useParams();

  const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [localReferenceFile, setLocalReferenceFile] = useState<ReferenceFile>(referenceFile ?? INITIAL_DATA);
  const firstLoadRef = useRef(true);
  const isEdit = !!referenceFile;

  const isNameUnique = useCheckUniqueness(referenceFilespath(orgId ?? "defaultOrg"), "name", localReferenceFile.name, isEdit ? referenceFile["@id"]! : undefined);
  const [validationMessages, setValidationMessages] = useState<Record<string, string>>({});

  // file related states
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadingId, setUploadingId] = useState<string | null>(null);
  const {errorFiles, fileUploadState, addFiles} = useFileUpload({toastProps: toastProps!, uid: uid!});
  const {completedFiles, processingFiles} = fileUploadState;

  // sets the local copy of the reference file to the reference file prop if IS EDIT
  useEffect(() => {
    if (!!referenceFile && firstLoadRef.current) {
      setLocalReferenceFile(referenceFile);
      firstLoadRef.current = false;
    }
  }, [referenceFile]);

  // sets the validation message for name if it is not unique
  useEffect(() => {
    if (!isNameUnique && !areObjectsEqual(localReferenceFile, referenceFile ?? INITIAL_DATA)) {
      setValidationMessages((prev) => ({...prev, name: enReferencesLabel.nameNotUnique}));
    }
  }, [isNameUnique]);

  useEffect(() => {
    // exit if no uploadingId is set yet
    if (uploadingId === null) return;

    if (errorFiles.filter(file => file.queueId === uploadingId).length > 0) {
      setIsUploading(false);
      setUploadingId(null);
      setIsLoading(false);
      setLocalReferenceFile(INITIAL_DATA);
      errorCallback(enReferencesLabel.uploadError);
      return;
    }

    const uploadComplete = completedFiles.filter(file => file.queueId === uploadingId);
    if (uploadComplete.length > 0) {
      const processingUploadFile = processingFiles.filter(file => file.id === uploadComplete[0].id);

      setIsUploading(false);
      setUploadingId(null);
      setIsLoading(false);

      setLocalReferenceFile((prev) => ({
        ...prev,
        fileName: processingUploadFile[0].file.name,
        tempFilepath: processingUploadFile[0].tempStorageFileName,
        tempFilePreviewPath: processingUploadFile[0].tempFilePreviewPath
      }));
      return;
    }
  }, [uploadingId, completedFiles, errorFiles]);

  function onDrawerClose() {
    setIsLoading(false);
    setLocalReferenceFile(INITIAL_DATA);
    resetTargetReferenceFile();
    setValidationMessages({});
    firstLoadRef.current = true;
    onClose();
  }

  function onChange(key: keyof ReferenceFile, value: any) {
    setLocalReferenceFile((prev) => ({
      ...prev,
      [key]: key === "description" ? value.replace(/\n/g, '<br>') : value
    }));
    setValidationMessages((prev) => ({...prev, [key]: ""}));
  }

  async function onSubmit() {
    setIsLoading(true);
    const docRef = !!referenceFile?.["@id"] ? doc(referenceFilespath(orgId!), referenceFile?.["@id"]) : doc(referenceFilespath(orgId!));
    await submitForm<Partial<ReferenceFile>>(docRef, isEdit ? ActionType.Update : ActionType.Create,
      (status, data, isLastUpdate) => statusSubmitHandler<ReferenceFile>({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback
      }),
      localReferenceFile,
      referenceFile
    );
  }

  function successCallback() {
    setToastMessage(isEdit ? enReferencesLabel.updateSuccess : enReferencesLabel.createSuccess);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    onDrawerClose();
  }

  function errorCallback(message: any) {
    if (typeof message === "object") {
      setValidationMessages(message as { [p: string]: string });
      setIsLoading(false);
      return;
    }

    setToastMessage(message);
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
    onDrawerClose();
  }

  function selectReferenceFile() {
    const input = document.createElement("input");
    input.type = "file";
    input.addEventListener("change", async () => {
      const file = input.files?.[0];
      // if no file provided, exit
      if (!file) return;

      // catch possible invalid file types
      const contentType = file.type;

      if (!contentType.startsWith("image/") && !contentType.startsWith("video/") && !contentType.startsWith("application/") && !contentType.startsWith("text/")) {
        setToastMessage(enReferencesLabel.fileTypeNotSupported);
        setToastSeverity(Severity.Error);
        setIsToastOpen(true);
        return;
      }

      await createUserFile(file);
    });

    input.click();
  }

  async function createUserFile(file: File) {
    // get preview if image
    setIsUploading(true);
    const uniqueId = generateUniqueId();
    const docData = {
      file: file,
      contentType: file.type,
      type: UserFileType.TempRefFile,
      timeCreated: Timestamp.now(),
      queueId: uniqueId,
      parentCollectionPath: userFilesPath(uid).path,
    }
    await addFiles([docData]);
    setUploadingId(uniqueId);
  }

  // create a function named onKeyPress that does the following:
  // if the key pressed is the enter key and isFormValid value from simpleformdrawer is true and is not loading, then call onSubmit function used in SimpleFormDrawer
  const isFormValid = (
    !isUploading &&
    !isLoading &&
    !!localReferenceFile.name &&
    localReferenceFile.name.trim() !== "" &&
    Object.values(validationMessages).every((message) => !message) &&
    !areObjectsEqual(localReferenceFile, referenceFile ?? INITIAL_DATA)
  );

  function onKeyPress(e: React.KeyboardEvent) {
    if (e.key === "Enter" && isFormValid && !isLoading) {
      onSubmit();
    }
  }

  return (
    <SimpleFormDrawer
      isOpen={isOpen}
      onClose={onDrawerClose}
      icon={<SystemIcons.FilesOutlined/>}
      title={isEdit ? enReferencesLabel.editReferenceFile : enReferencesButton.createNewReference}
      isFormValid={isFormValid}
      isLoading={isLoading}
      rightButtonLabel={isEdit ? enCommonButton.save : enCommonButton.create}
      unsavedChanges={!areObjectsEqual(localReferenceFile, referenceFile ?? INITIAL_DATA)}
      onSubmit={onSubmit}
    >
      <Input
        validationMessage={validationMessages.name}
        value={localReferenceFile.name}
        onChange={(e) => onChange("name", (e.target as HTMLInputElement).value)}
        label={enCommonLabel.name}
        sx={{mb: 1}}
        onKeyPress={onKeyPress}
      />
      <Input
        validationMessage={validationMessages.description}
        value={localReferenceFile.description?.replace(/<br>/g, "\n")}
        onChange={(e) => onChange("description", (e.target as HTMLInputElement).value)}
        label={enCommonLabel.description}
        minRows={5}
        maxRows={5}
        sx={{mb: 1}}
        multiline
        optional
      />
      <DataTagInput
        componentId="dataTagsInput"
        fieldSx={{
          ".MuiOutlinedInput-root": {
            padding: "2px 14px",
          },
        }}
        sx={{
          alignItems: "center",
          mb: 1
        }}
        uid={uid}
        toastProps={toastProps}
        handleSelectCallback={(ids) => onChange("dataTagsIds", ids)}
        initialTags={localReferenceFile.dataTagsIds}
        onKeyPress={onKeyPress}
      />
      <Stack>
        <Typography variant="h5">{enReferencesButton.addFile}
          <Typography variant="body" fontWeight={400} color={theme.palette.neutral.dark}>
            {` ${enCommonLabel.optionalWithParentheses}`}
          </Typography>
        </Typography>
        <ReferenceButton
          fileName={localReferenceFile.fileName}
          isUploading={isUploading}
          isLoading={isLoading}
          selectReferenceFile={selectReferenceFile}
        />
      </Stack>
    </SimpleFormDrawer>
  )
}

export default ManageReferenceFileDrawer;