import {DocumentReference, onSnapshot} from "firebase/firestore";
import {useEffect, useState} from "react";

export type UseDocumentReturn<Type> = [
  Type | null | undefined,
  (docRef: DocumentReference | null) => void,
  DocumentReference | null,
];

/**
 * Listen to a document and return its data.
 * @param docRef - The document reference to listen to.
 * @param fieldsOfInterest - The fields of interest to listen to. If not provided, all fields will be listened to.
 * @returns [document, setProvidedDocRef, reference] - The document data, a function to set the document reference, and the document reference.
 */
function useDocument<Type>(docRef: DocumentReference | null, fieldsOfInterest?: Array<keyof Type>): UseDocumentReturn<Type> {
  // null = loading; undefined = not found
  const [document, setDocument] = useState<Type | null | undefined>(null);
  const [reference, setReference] = useState<DocumentReference | null>(docRef);

  function setProvidedDocRef(docRef: DocumentReference | null) {
    setReference(docRef);
    setDocument(null);
  }

  function hasFieldOfInterestChanged(newData: Type, oldData: Type | null | undefined): boolean {
    if (!fieldsOfInterest || !oldData) return true;

    return fieldsOfInterest.some((field) => newData[field] !== oldData[field]);
  }

  useEffect(() => {
    if (!reference) return;

    if (reference.path.split("/").includes("defaultOrg")) {
      setDocument(undefined);
      return;
    }

    return onSnapshot(reference, (querySnapshot) => {
      const newData = querySnapshot.data();

      if (!newData) {
        setDocument(undefined);
        return;
      }

      if (hasFieldOfInterestChanged(newData as Type, document)) {
        setDocument(newData as Type);
      }
    }, () => {
      setDocument(undefined);
    });
  }, [reference]);

  return [document, setProvidedDocRef, reference];
}

export default useDocument;
