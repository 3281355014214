import {Box, Stack} from "@mui/material";
import SystemIndex from "assets/icons/system/system.index";
import {ScheduledItemType} from "types/ActivityFeedTypes";
import TimestampTypo from "components/Typography/TimestampTypo";
import {enActivityFeedLabel} from "constants/index";
import {formatDateTimeFirestoreDate} from "screens/utility/formatDateTimeFirestoreDate";
import ItemContainer from "components/ActivityFeed/ItemContainer";

interface ScheduledItemProps {
  item: ScheduledItemType
}

function ScheduledItem(props: ScheduledItemProps) {
  const {item} = props;
  const {details, timestamp} = item;
  const {by, schedule} = details;
  const {date, time} = formatDateTimeFirestoreDate(timestamp);

  return <ItemContainer>
    <Box width={24} height={24}>
      <SystemIndex.SystemIcons.Calendar width={24} height={24}/>
    </Box>
    <Stack>
      {enActivityFeedLabel.scheduled(schedule, by.name ?? "")}
      <TimestampTypo>{`${date} ${time}`}</TimestampTypo>
    </Stack>
  </ItemContainer>
}

export default ScheduledItem;
