export function getPermissionValues<T>(permissions: any, role: string, enumType: T) {

  let results: any = {};
  // @ts-ignore
  Object.entries(enumType).forEach(entry => {
    const enumKey = entry[0];
    const enumValue = entry[1];

    const found = Object.entries(permissions).filter(([key, value]) => key === enumValue);
    if(found.length > 0) {
      // @ts-ignore
      results = {...results, [enumKey]: found[0][1][role]}
    }
  });

  return results;
}