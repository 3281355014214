import {SelectedOrg} from "types/SelectedOrg";
import {Navigate} from "react-router-dom";
import {useEffect} from "react";
import {User} from "types/User";

interface RouteToSelectedProps {
  selectedOrg: SelectedOrg;
  setSelectedOrg: (org: SelectedOrg) => void;
  user: User;
}

function RouteToSelected(props: RouteToSelectedProps) {
  const {selectedOrg, setSelectedOrg, user} = props;

  useEffect(() => {
    if (!user) return;

    if (JSON.stringify(user.sidebarView.selectedOrg) === JSON.stringify(selectedOrg)) return;

    setSelectedOrg({...user.sidebarView.selectedOrg, isAdminView: false});
  }, [user]);

  return <Navigate to={`/${user.sidebarView.selectedOrg.id}/explore-organizations`} />
}

export default RouteToSelected;