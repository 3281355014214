import {Box} from "@mui/material";
import Icons from "assets/icons";
import React from "react";

interface ChevronProps {
  assetCount: number;
  assetTypeIdx: string;
  open: boolean;
  toggleCollapse: () => void;
}
function Chevron(props: ChevronProps) {
  const {assetCount, assetTypeIdx, open, toggleCollapse} = props;

  if (assetCount < 1) return null;

  const chevronRotation = {transform: (!open) ? "rotate(180deg)" : "none", cursor: "pointer"}

  return (
    <Box
      sx={chevronRotation}
      onClick={toggleCollapse}>
      <Icons.ManageAssetIcons.ChevronUp width={24} height={24} id={`${assetTypeIdx}-chev`}/>
    </Box>
  )
}

export default Chevron;