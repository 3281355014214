import {Checkbox, Stack, Typography} from "@mui/material";
import CheckboxSwitch from "components/CheckboxSwitch";
import DeleteButton from "components/Button/BulkActionButtons/DeleteButton";
import React, {Dispatch, useEffect, useState} from "react";
import {enCommonLabel, enOrgDataTagsLabel} from "constants/index";
import {ViewStatus} from "enums/viewStatus";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";
import {toastProps} from "screens/BaseProps";
import {Severity} from "enums/severity";
import submitForm from "../utility/submitForm";
import {ActionType} from "enums/actionType";
import {db} from "../../firebase";
import {doc} from "firebase/firestore";
import {statusSubmitHandler} from "../utility/statusSubmitHandler";

export interface BulkActionsProps {
  canDelete: boolean;
  checkedDataTags: Set<string>;
  organizationTagColRef: string;
  isAllChecked: boolean;
  isCheckboxShown: boolean;
  setIsAllChecked: Dispatch<boolean>;
  toastProps: toastProps;
  toggleCheckbox: (shown: boolean) => void;
}

function BulkActions(props: BulkActionsProps) {
  const {isCheckboxShown, canDelete, checkedDataTags, isAllChecked} = props;
  const {setIsAllChecked, toggleCheckbox, toastProps}  = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps;

  const [deleteLength, setDeleteLength] = useState<number>(0);
  const [viewStatus, setViewStatus] = useState<ViewStatus[]>([]);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    // if delete dialog is not open, return
    if (!isDeleteDialogOpen) return;

    // if nothing to delete, return
    if (deleteLength === 0) return;

    // if not status are pushed, return
    if (viewStatus.length !== checkedDataTags.size) return;

    setToastSeverity(Severity.Success);
    setToastMessage(enOrgDataTagsLabel.deleteSuccess);

    const withError = viewStatus.includes(ViewStatus.Error);
    if (withError) {
      setToastMessage(enOrgDataTagsLabel.encounteredAnError);
      setToastSeverity(Severity.Error);
    }

    setIsToastOpen(true);
    setIsDeleteDialogOpen(false);
    setIsAllChecked(false);
    setViewStatus([]);
    setDeleteLength(0);
    return;
  }, [deleteLength, viewStatus]);

  async function deleteDatatag() {
    if (checkedDataTags.size === 0) return;
    setDeleteLength(checkedDataTags.size);
    await Promise.all(
      Array.from(checkedDataTags)
        .map(async (id) => {
        const docRef = doc(db, props.organizationTagColRef, id);
        return await submitForm(
          docRef,
          ActionType.Delete,
          (status, isLastUpdate, data) => statusSubmitHandler({
            status,
            isLastUpdate,
            data,
            successCallback: () => {setViewStatus(prevState => [...prevState, ViewStatus.Finished])},
            errorCallback: () => setViewStatus((prev) => [...prev, ViewStatus.Error]),
          }),
        )
      })
    );
  }

  return (
    <Stack
      direction={{md: "column", lg: "row"}}
      alignItems={{md: "flex-start", lg:"center"}}
      height={{md: "unset", lg: "1.5rem"}}
      gap={{md: 0, lg: 1}}
    >
      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        title={enOrgDataTagsLabel.deleteTitleMultiple}
        text={enOrgDataTagsLabel.deleteText}
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleConfirm={deleteDatatag}
      />
      <CheckboxSwitch
        isCheckboxShown={isCheckboxShown}
        toggleCheckbox={toggleCheckbox}
      />
      {isCheckboxShown && (
        <Stack direction="row" alignItems="center" justifyContent="flex-start" justifyItems="center">
          <Checkbox
            onChange={(e) => setIsAllChecked(e.target.checked)}
            checked={isAllChecked}
          />
          <Typography variant="h5">
            {isAllChecked ? enCommonLabel.unselectAll("") : enCommonLabel.selectAll("")}
          </Typography>
          {checkedDataTags.size > 0 && (
            <DeleteButton
              disabled={!canDelete}
              onButtonClick={() => setIsDeleteDialogOpen(true)}
              sx={{marginLeft: 2}}
            />
          )}
        </Stack>
      )}
    </Stack>
  )
}

export default BulkActions;