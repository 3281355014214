import {PermissionEntity} from "types/Permission";

/**
 * The list of entities that are displayed in the permissions table.
 */
export const DISPLAYED_ENTITIES: PermissionEntity[] = [
  PermissionEntity.Asset,
  PermissionEntity.ProjectAssetAccessList,
  PermissionEntity.FileRequirementFilesDownload,
  PermissionEntity.ProjectAssetImport,
  PermissionEntity.ProjectAssetExport,
  PermissionEntity.AssetFilesDownload,
  PermissionEntity.FileRequirement,
  PermissionEntity.Form,
  PermissionEntity.FormRequirement,
  PermissionEntity.FormExport,
  PermissionEntity.Milestone,
  PermissionEntity.MilestoneFilesDownload,
  PermissionEntity.Organization,
  PermissionEntity.OrganizationAsset,
  PermissionEntity.OrganizationMember,
  PermissionEntity.OrganizationTemplate,
  PermissionEntity.OrganizationTemplateImport,
  PermissionEntity.OrganizationTemplateExport,
  PermissionEntity.Project,
  PermissionEntity.ProjectAccessList,
  PermissionEntity.ProjectImport,
  PermissionEntity.ProjectExport,
  PermissionEntity.ReferenceFile,
  PermissionEntity.SwiftFile,
  PermissionEntity.Task,
  PermissionEntity.TaskAccessList,
  PermissionEntity.TaskExport,
  PermissionEntity.TaskFilesDownload,
  PermissionEntity.Team,
  PermissionEntity.TeamCategory,
];