import {userInvitePath} from "../utility";
import useDocument from "hooks/useDocument";
import {Invite} from "types/Invite";
import React, {useEffect, useState} from "react";
import InvalidInvitation from "./InvalidInvitation";
import {InProgress} from "components/index";
import {Navigate} from "react-router-dom";
import {BaseProps} from "../BaseProps";

/**
 * checks invitation validity, if valid, will proceed to the invitation page
 * else, will display the invalid invitation
 */
function ViewInvitation(props: BaseProps) {
  const {selectedOrg, uid} = props;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [invitation, setInvitationRef] = useDocument<Invite>(null);
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    const invitationId = localStorage.getItem("invitationId");
    const invitationRef = userInvitePath(invitationId!, uid)
    setInvitationRef(invitationRef);
  }, [localStorage.getItem("invitationId")]);

  // update validity based on the invitation document
  useEffect(() => {
    if (invitation === null) return;
    setIsValid(Boolean(invitation));
    setIsLoading(false);
  }, [invitation]);

  if (isLoading) return <InProgress/>;

  return (
    <>
      {!isValid  ? <InvalidInvitation loggedIn={true} />
        : <Navigate to={`/${selectedOrg?.id}/home`} />
      }
    </>
  )
}

export default ViewInvitation;