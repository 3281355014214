import {Button, Divider, Popover, Stack, Typography} from "@mui/material";
import Input from "components/Input";
import React, {useEffect, useState} from "react";
import theme from "theme/theme";
import {en} from "language/en";
import {getKeyValue, onChangeInput, submitForm} from "screens/utility";
import {DataTag} from "types/DataTag";
import {doc} from "firebase/firestore";
import {organizationDataTagsPath} from "screens/utility/FirebasePath";
import {useParams} from "react-router-dom";
import {ActionType, ProcessType, ViewStatus} from "enums/index";
import {LoadingButton} from "@mui/lab";
import useSuccessToast from "hooks/toasts/useSuccessToast";
import {ToastProps} from "screens/BaseProps";
import {enCommonLabel} from "constants/index";
import AutocompleteInput from "components/DataTag/DataTagInput/AutocompleteInput";
import ColorSelectorButton from "components/DataTag/DataTagInput/ColorSelectorButton";
import {datatagToneColor} from "enums/datatagToneColor";

interface CreateDataTagPopup extends ToastProps {
  isOpen: boolean;
  anchorEl?: Element;
  onClose: () => void;
  tag: DataTag;
  uid: string;
}

function EditDataTagPopup(props: CreateDataTagPopup) {
  const {isOpen, anchorEl, onClose, tag, toastProps, uid} = props
  const {orgId} = useParams();

  //Fill an id for now
  const docRef = doc(organizationDataTagsPath(orgId!), tag.id || "fillerId")

  const [name, setName] = useState(tag.name);
  const [description, setDescription] = useState(tag.description);
  const [color, setColor] = useState<datatagToneColor>(tag.color ?? datatagToneColor.tone_0_0);
  const [isLoading, setIsLoading] = useState(false);
  const [documentError, setDocumentError] = useState<string | undefined>(undefined);
  const showSuccessToast = useSuccessToast(toastProps, en.common.label.dataTags.editSuccessToast(tag.name))

  //when data tag loads, set the name and description
  useEffect(() => {
    if (!isOpen) return;

    setName(tag.name);
    setDescription(tag.description);
  }, [isOpen]);

  //reset document errors on change
  useEffect(() => {
    setDocumentError(undefined)
  }, [name])

  //reset state after exiting popup
  useEffect(() => {
    if(isOpen){
      setDocumentError(undefined)
      setIsLoading(false)
    }
  }, [isOpen])

  async function submitEditDataTag(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    e.preventDefault();
    setIsLoading(true);
    const formData = {name, description, color};
    await submitForm<Partial<DataTag>>(docRef, ActionType.Update, statusHandler, formData);
  }

  function statusHandler(status: ViewStatus, data: any, isLastUpdate: boolean) {
    if (!isLastUpdate) return;

    switch (status) {
      case ViewStatus.Finished:
        setIsLoading(false);
        setName("")
        setDescription("");
        showSuccessToast();
        onClose();
        break;
      case ViewStatus.ValidationError:
      case ViewStatus.SecurityError:
      case ViewStatus.Error:
        setIsLoading(false);
        const errorMessage = data["@messages"];
        const error = typeof (errorMessage) === "object" ? getKeyValue(errorMessage, "name", undefined) : enCommonLabel.errorProcess(ProcessType.Create);
        setDocumentError(error);
        break;
    }
  }

  function validateForm() {
    //required fields
    if (name.trim() === "")
      return false

    //no errors
    if (documentError)
      return false

    //no changes
    return !(tag.name === name && tag.description === description && tag.color === color);
  }

  function closeEditPopup(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    e.preventDefault();
    onClose();
  }

  function onKeyPress(e: React.KeyboardEvent) {
    if (e.key === "Enter" && !isLoading && validateForm()) {
      e.stopPropagation();
      e.preventDefault();
      submitEditDataTag(e as any);
    }
  }

  return <Popover
    open={isOpen}
    anchorEl={anchorEl}
    onClose={closeEditPopup}
    PaperProps={{
      style: {
        minWidth: 300,
        width: anchorEl?.clientWidth,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        transform: `translateY(22px)`,
        border: `2px solid ${theme.palette.neutral.medium}`
      }
    }}
  >
    <Stack direction="row" justifyContent="space-between">
      <AutocompleteInput
        uid={uid}
        setName={setName}
        defaultName={name}
        onKeyPress={onKeyPress}
        sx={{
          width: "100%",
          "& .MuiAutocomplete-option": {
            color: "red!",
          },
          "& .MuiOutlinedInput-root": {
            py: 1 / 2,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }
        }}
      />
      <ColorSelectorButton setColor={setColor} color={tag.color}/>
    </Stack>
    <Typography color="text.secondary" sx={{marginLeft: 2}}>
      {name.length}/32
    </Typography>
    {documentError ?
        <>
          <Divider
            sx={{
              borderColor: theme.palette.error.main
            }}
          />
          <Typography color="error.main" marginLeft={2} marginY={1}>
            {documentError}
          </Typography>
        </> :
        <Divider/>
    }
    <Input
      value={description}
      onChange={(e) => onChangeInput(e, setDescription)}
      onClick={(e) => e.stopPropagation()}
      placeholder={en.common.label.description}
      multiline
      minRows={5}
      textfieldSx={{
        "& fieldset": {
          borderRadius: 0,
          border: 0,
        },
        '&:hover fieldset': {
          borderColor: theme.palette.neutral.medium,
        },
      }}
      InputProps={{
        maxLength: 128
      }}
      
    />
    <Stack
      direction="row"
      sx={{
        padding: "9px 16px",
        borderRadius: 1,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderTop: `1px solid ${theme.palette.neutral.medium}`
      }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography color="text.secondary">
        {description.length}/128
      </Typography>
      <Stack direction="row" gap={1}>
        <Button
          disabled={isLoading}
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.secondary,
          })}
          onClick={closeEditPopup}
        >
          {en.common.button.cancel}
        </Button>
        <LoadingButton
          loading={isLoading}
          disabled={!validateForm()}
          onClick={submitEditDataTag}
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.neutral.medium}`,
          })}
        >
          {en.common.button.save}
        </LoadingButton>
      </Stack>
    </Stack>
  </Popover>
}

export default EditDataTagPopup
