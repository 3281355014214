import {SystemIcons} from "assets/icons/system/system.index";
import {ActionOption, MenuCard, MenuCardProps} from "components";
import {emptyFunction, enMilestoneButton} from "constants/index";
import React, {useState} from "react";
import DownloadOptionsMenu from "components/Dialogs/DownloadConfirmDialog/DownloadOptionsMenu";
import {Entity} from "enums/entity";
import {toastProps} from "../BaseProps";
import FeatureWrapper from "components/FeatureWrapper";
import {FeatureType} from "enums/featureType";

interface HeaderMenuProps extends MenuCardProps {
  uid: string;
  entity: Entity.ProjectLevelTask | Entity.Asset;
  usesTemplate: boolean;
  canDownload: Boolean;
  canImport: Boolean;
  canExport: Boolean;
  filesCount?: number;
  openImportDialog?: () => void;
  openExportDialog?: () => void;
  toastProps: toastProps;
}

function HeaderMenu(props: HeaderMenuProps) {
  const {
    anchorEl,
    open,
    usesTemplate,
    entity,
    canDownload,
    canImport,
    canExport,
    filesCount = 0,
    closeMenu,
    openImportDialog = emptyFunction,
    openExportDialog = emptyFunction
  } = props;
  const [isDownloadOptionsOpen, setIsDownloadOptionsOpen] = useState(false);

  function onDownloadMenuClick() {
    if (!anchorEl || !canDownload) return;
    setIsDownloadOptionsOpen((prev) => !prev);
  }

  return (
    <MenuCard anchorEl={anchorEl} open={open} closeMenu={closeMenu}>
      <ActionOption
        onClick={onDownloadMenuClick}
        Icon={<SystemIcons.Download height={16} width={16}/>}
        label={enMilestoneButton.download}
        disabled={!canDownload}
        isDropdown
      />
      <DownloadOptionsMenu
        toastProps={props.toastProps!}
        uid={props.uid}
        entity={entity}
        filesCount={filesCount}
        onClose={onDownloadMenuClick}
        isDownloadOptionsCollapseOpen={isDownloadOptionsOpen}
        isCollapsible
      />
      {entity === Entity.Asset && (
        <FeatureWrapper feature={FeatureType.ImportProjectAsset} viewable>
            <ActionOption
              onClick={usesTemplate || !canImport ? emptyFunction : openImportDialog}
              Icon={<SystemIcons.Import height={16} width={16}/>}
              label={enMilestoneButton.import}
              disabled={usesTemplate || !canImport}
            />
        </FeatureWrapper>
      )}
      <FeatureWrapper feature={FeatureType.ExportProjectAsset} initialDisplay={true}>
        <ActionOption
          onClick={entity === Entity.Asset && !canExport ? emptyFunction : openExportDialog}
          Icon={<SystemIcons.Export height={16} width={16}/>}
          label={entity === Entity.Asset ? enMilestoneButton.export : enMilestoneButton.pltExport}
          disabled={entity === Entity.Asset && !canExport}
        />
      </FeatureWrapper>
    </MenuCard>
  )
}

export default HeaderMenu;
