import {Entity} from "enums/entity";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {Button, FormControlLabel, FormGroup, Stack, Switch, Typography} from "@mui/material";
import {enCommonLabel, enTaskLabel} from "constants/index";
import {FilterStatusMenu} from "../Menus";
import {styled} from "@mui/material/styles";
import {ButtonProps} from "@mui/material/Button";
import React, {useRef} from "react";
import {useComponentToggler, useRTDBDocField} from "hooks/index";
import {CounterFields} from "enums/counterFields";

interface FilterOptionsProps {
  statusFilter: string;
  showHidden: boolean;
  handleStatusFilterChange: (newFilter: string) => void;
  parentEntity: Entity.Asset | Entity.ProjectLevelTask | Entity.Templates;
  tasksDocRef: string;
  toggleHiddenReqs: () => void;
}

function FilterOptions(props: FilterOptionsProps) {
  const {showHidden, tasksDocRef, statusFilter, parentEntity, toggleHiddenReqs, handleStatusFilterChange} = props;

  const anchorEl = useRef<any>();
  const [isFilterMenuOpen, {open: openFilterMenu, close: closeFilterMenu}] = useComponentToggler(false);
  const hiddenRequirementsCount = useRTDBDocField<number>(tasksDocRef, CounterFields.HiddenReqCount) ?? 0;

  if (parentEntity === Entity.Templates)
    return (
      <FormGroup>
        <FormControlLabel
          control={<Switch onChange={toggleHiddenReqs}/>}
          checked={showHidden}
          labelPlacement="start"
          label={
            <Stack direction="row" gap={2} alignItems="center">
              <Typography variant="subtitle1" color="text.primary">
                {enTaskLabel.showHiddenReqs}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" sx={{ml: -1.5}}>
                {((hiddenRequirementsCount ?? 0) > 0) && hiddenRequirementsCount}
              </Typography>
            </Stack>
          }
        />
      </FormGroup>
    );

  return (
    <Stack
      direction="row"
      justifyContent={{xs: "left", sm: "center"}}
      alignItems="center"
      gap={2}
    >
      <ColorButton
        id="status-milestones-btn"
        endIcon={
          <SystemIcons.ChevronDown
            stroke={theme.palette.neutral.dark}
            height={16}
            width={16}
          />
        }
        ref={anchorEl}
        onClick={openFilterMenu}
      >
        <Typography
          variant="h4"
          sx={{ml: 1, color: theme.palette.text.secondary}}
        >
          {enCommonLabel.status}
        </Typography>
        <Typography
          variant="h4"
          sx={{ml: 1, color: theme.palette.secondary.main}}
        >
          {statusFilter}
        </Typography>
      </ColorButton>
      <FilterStatusMenu
        anchorEl={anchorEl.current}
        open={isFilterMenuOpen}
        closeMenu={closeFilterMenu}
        onClick={(status) => handleStatusFilterChange(status)}
      />
    </Stack>
  )
}

/** start of styles */
const ColorButton = styled(Button)<ButtonProps>(({theme}) => ({
  "&:hover": {
    backgroundColor: theme.palette.neutral.light,
  },
  "&:active": {
    backgroundColor: theme.palette.neutral.light,
  },
}));
/** end of styles */

export default FilterOptions;