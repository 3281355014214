import {Box, BoxProps, styled} from "@mui/material";

const Wrapper = styled(Box)({
  height: "100%",
  width: "360px",
  display: "flex",
  flexDirection: "column",
  padding: "24px",
  paddingBottom: 0,
  overflowX: "hidden",
  overflowY: "hidden"
});

export default function DrawerFieldsWrapper(props: BoxProps) {
  return <Wrapper {...props}/>;
}