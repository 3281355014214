import {Box, Chip, Stack} from "@mui/material";
import {en} from "language/en";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import React, {useState} from "react";

interface SeeLessChipProps {
  extraTagsChipRef: React.RefObject<any>;
  collapseTags: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function SeeLessChip(props: SeeLessChipProps) {
  const {extraTagsChipRef, collapseTags} = props;

  const [isSeeLessHovered, setIsSeeLessHovered] = useState<boolean>(false);

  return (
    <Box
      onMouseEnter={() => setIsSeeLessHovered(true)}
      onMouseLeave={() => setIsSeeLessHovered(false)}
    >
      <Chip
        ref={extraTagsChipRef}
        onClick={collapseTags}
        label={(
          <Stack direction="row">
            {en.common.label.seeLess}
            <SystemIcons.ChevronUp stroke={isSeeLessHovered ? theme.palette.text.onDark : theme.palette.text.secondary}/>
          </Stack>
        )}
        color="chip"
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          borderRadius: 1,
          cursor: "pointer",
          ":hover": {
            backgroundColor: theme.palette.neutral.dark,
            color: theme.palette.text.onDark,
          },
        })}
        size="small"
      />
    </Box>
  )
}

export default SeeLessChip;