import {useState} from "react";
import SettingsItem from "./SettingsItem";
import {Button, Typography} from "@mui/material";
import {enProfileAndSettingsLabel} from "constants/index";
import DataPolicyDialog from "components/Dialogs/DataPolicyDialog";

function DataPolicy() {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  return <>
    <DataPolicyDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen}/>
    <SettingsItem>
      <Button onClick={() => setIsDialogOpen(true)}>
        <Typography>{enProfileAndSettingsLabel.dataPolicy}</Typography>
      </Button>
    </SettingsItem>
  </>
}

export default DataPolicy;
