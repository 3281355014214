import {styled} from "@mui/material/styles";
import {TextField, TextFieldProps} from "@mui/material";

type StyledBaseInputProps = TextFieldProps & {
  valid?: boolean
}

const BaseInput = styled(TextField, {shouldForwardProp: (prop) => prop !== "valid"})<StyledBaseInputProps>
(({
    valid = true,
    theme
  }) => ({
  "& .MuiOutlinedInput-root": {
    padding: 0,
    "& fieldset": {
      borderColor: valid ? theme.palette.neutral.medium : theme.palette.error.main,
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "9px 16px",
  },
}));

export default BaseInput