import {Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {enTeamsAndPeopleButton, enTeamsAndPeopleLabel} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {useNavigate} from "react-router-dom";
import {Variant} from "@mui/material/styles/createTypography";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {SecondaryButton, PrimaryButton} from "components/Button";

interface HeaderProps {
  access: AccessType | null;
  categoryAccess: AccessType | null;
  onDrawerOpen: () => void;
  selectedOrgId: string;
  titleVariant: Variant;
}

function Header(props: HeaderProps) {
  const {onDrawerOpen, selectedOrgId, titleVariant, access, categoryAccess} = props;
  const navigate = useNavigate();

  return (
    <>
      <Stack mt={1} gap={3}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant={titleVariant} color={theme.palette.secondary.main}>
            {enTeamsAndPeopleLabel.teams}
          </Typography>
          <Stack direction="row" gap={2}>
            <SecondaryButton
              onClickFcn={() => navigate(`/${selectedOrgId}/manage-categories`)}
              disabled={Boolean(!categoryAccess?.[PermissionOperationKey.View])}
              label={enTeamsAndPeopleButton.manageTeamCategory}
              startIcon={(props) => <SystemIcons.Settings {...props} />}
            />
            <PrimaryButton
              id="add-team-button"
              onClickFcn={onDrawerOpen}
              disabled={Boolean(!access?.[PermissionOperationKey.Create])}
              startIcon={(props) => SystemIcons.Teams({width: 16, height: 16, stroke: theme.palette.common.white, ...props})}
              label={enTeamsAndPeopleButton.addTeam}
            />
          </Stack>
        </Stack>
      </Stack>
    </>

  )
}

export default Header;