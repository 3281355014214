import {SVGProps} from "react";

export const SwiftLogoOnly = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg id="swift-logo-only"
      data-name="SwiftLogoOnly"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 168.49 170.55"
      width={props.width ?? 24}
      height={props.height ?? 24}
    >
      <g id="swift-logo-only-1" data-name="SwiftLogoOnly 1">
        <g>
          <path fill={props.fill ?? "none"}
            stroke={props.stroke ?? "#1a1a1a"}
            strokeMiterlimit={10}
            strokeWidth={props.strokeWidth ?? "1px"}
            d="m35.34,96.9l14.04-28.52h0S82.94.19,82.94.19h-33.56C22.11.19,0,22.3,0,49.57c0,22.39,14.91,41.27,35.34,47.33Z"></path>
          <path
            fill={props.fill ?? "none"}
            stroke={props.stroke ?? "#1a1a1a"}
            strokeMiterlimit={10}
            strokeWidth={props.strokeWidth ?? "1px"} d="m130.52,73.84l-47.6,96.71h33.56c27.27,0,49.38-22.11,49.38-49.38,0-22.39-14.91-41.27-35.34-47.33Z"></path>
          <rect
            fill={props.fill ?? "none"}
            stroke={props.stroke ?? "#1a1a1a"}
            strokeMiterlimit={10}
            strokeWidth={props.strokeWidth ?? "1px"}
            x="7.53"
            y="106.48"
            width="67.88"
            height="64.07"></rect>
          <polygon
            fill={props.fill ?? "none"}
            stroke={props.stroke ?? "#1a1a1a"}
            strokeMiterlimit={10}
            strokeWidth={props.strokeWidth ?? "1px"}
            points="90.46 64.26 136.66 64.26 168.49 0 90.46 .19 90.46 64.26"></polygon>
        </g>
      </g>
    </svg>
  )
}