import {ChatMessage} from "types/index";
import {Box, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import React, {useEffect, useMemo, useState} from "react";
import {getDocumentStatus, getTwelveHourTime} from "screens/utility";
import {ViewStatus} from "enums/index";
import {useDocument} from "hooks/index";
import {CollectionReference, doc} from "firebase/firestore";
import {ChatMessageWrapper} from "components/ChatDrawer/Objects/ChatWrapper";
import ChatAttachmentWrapper from "components/ChatDrawer/ChatAttachments/ChatAttachmentWrapper";
import {ChatWithMention} from "components/ChatDrawer/Objects/ChatWithMention";
import {UnsentMessage} from "components/ChatDrawer/Types/UnsentMessageType";
import {CarouselItem} from "components/ChatDrawer/ChatItemsView/ChatAttachmentCarousel";
import {ReadReceipt} from "types/ChatMessage";
import ReadReceipts from "components/ChatDrawer/ChatItemsView/ReadReceipts";

interface ChatItemUnsentProps {
  message: UnsentMessage;
  colRef: CollectionReference;
  setSelectedChatAttachments: (attachments: CarouselItem[], selectedIndex: number) => void;
  readers: ReadReceipt[]
}

export default function ChatItemUnsent(props: ChatItemUnsentProps) {
  const {message, colRef, setSelectedChatAttachments: onClick, readers} = props;
  const {text, timestamp, uploadedFiles, "@id": id = "", "@form": messageForm} = message;

  const messageStatus = !messageForm || !messageForm["@status"] ? ViewStatus.Processing : messageForm["@status"];

  const chatReference = doc(colRef, id!);

  const [timeSent, setTimeSent] = useState("");
  const [chatMessage] = useDocument<ChatMessage>(chatReference);
  const [chatMessageStatus, setChatMessageStatus] = useState<ViewStatus>(messageStatus);

  useMemo(() => {
    setTimeSent(getTwelveHourTime(timestamp));
  }, []);

  useEffect(() => {
    if (!chatMessage) return;
    setChatMessageStatus(getDocumentStatus(chatMessage));
  }, [chatMessage]);

  // unsent is always from self
  return (
    <>
      <Stack direction="row" justifyContent="flex-end" mb={0} mx={0.5}>
        <Stack direction="row" gap={1}>
          <Stack gap={0.5} alignItems="flex-end">
            <Stack gap={0.5} alignItems="flex-end">
              <Typography variant="caption" color={theme.palette.neutral.dark} lineHeight="12px">
                {chatMessageStatus === ViewStatus.Finished ? timeSent : null}
              </Typography>
            </Stack>
            {uploadedFiles?.length > 0 && (
              <ChatAttachmentWrapper
                attachmentsCount={uploadedFiles?.length}
                documentRef={chatReference!}
                colRef={colRef}
                id={!!id ? id : undefined}
                attachments={uploadedFiles}
                sx={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
                isFromUnsent={true}
                onClick={onClick}
              />
            )}
            {!!text && (
              <ChatMessageWrapper
                sx={{
                  backgroundColor: theme.palette.secondary.main
                }}
                color={theme.palette.text.onDark}
              >
                <ChatWithMention chatMessage={text} mentionedUsersData={chatMessage?.usersMentionedData}/>
              </ChatMessageWrapper>
            )}
          </Stack>
        </Stack>
      </Stack>
      {readers.length > 0 && <ReadReceipts readers={readers} />}
      <Box mb={3}/>
    </>
  );
}