import React, {useEffect, useState} from "react";
import {Button, Typography} from "@mui/material";
import {enReferencesButton} from "constants/index";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {InProgress} from "components/index";

interface ReferenceButtonProps {
  fileName: string | undefined;
  isUploading: boolean;
  isLoading: boolean;
  selectReferenceFile: () => void;
}

function ReferenceButton(props: ReferenceButtonProps) {
  const {isUploading, isLoading, fileName, selectReferenceFile} = props;

  const [text, setText] = useState<string>(enReferencesButton.addFile);
  const [icon, setIcon] = useState<React.ReactNode>(<SystemIcons.FilesOutlined stroke={theme.palette.primary.main} width={16} height={16}/>);

  useEffect(() => {
    if(isUploading) {
      setIcon(<InProgress size={16} />);
      setText(enReferencesButton.uploadingFile);
      return;
    }

    if(!!fileName) {
      const extension = fileName.substring(fileName.lastIndexOf("."));
      let extractedName = fileName.substring(0, fileName.lastIndexOf("."));
      extractedName = extractedName.length > 20 ? extractedName.substring(0, 20) + ".." : extractedName;
      setIcon(<SystemIcons.FilesOutlined stroke={theme.palette.primary.main} width={16} height={16}/>);
      setText(`${extractedName}${extension}`);
      return;
    }

    setIcon(<SystemIcons.FilesOutlined stroke={theme.palette.primary.main} width={16} height={16}/>);
    setText(enReferencesButton.addFile);
  }, [isUploading, fileName]);

  return (
    <Button
      disabled={isUploading || isLoading}
      variant="outlined"
      onClick={selectReferenceFile}
      startIcon={icon}
      sx={{width: "100%", borderColor: theme.palette.neutral.light}}
    >
      <Typography fontWeight={700} color={theme.palette.text.primary}>{text}</Typography>
    </Button>
  )
}

export default ReferenceButton;