import {Entity} from "enums/entity";
import {enCommonLabel} from "constants/index";
import {getImportInstructions} from "components/Dialogs/utils/getImportInstructions";
import {
  allHeaders,
  entityInstructionsKeysDisplay,
  headerNames,
  ImportEntity,
  InstructionData,
  requiredImportHeadersNotes
} from "constants/headerConstants";
import {getImportInstructionRows} from "components/Dialogs/utils/getImportInstructionRows";

interface InstructionStep {
  name: string;
  header: string;
  description: string[];
}

export interface ImportInstructionData {
  dialogTitle: string;
  title: string;
  steps: InstructionStep[],
  notes: string[];
  upperTableLabel: string;
  upperTableHeaderFields: string[];
  upperTableFieldNotes: string[];
  tableFields: string[];
  tableRows: InstructionData[];
  belowTableLabel: string;
  belowTableNotes: string[];
  warningNotes: string[];
  allHeaders: headerNames[];
  fileName: string;
}

const splitByUpperCase = (str: string): string => str.replace(/(?<!^)([A-Z])/g, ' $1');

export function getImportInstructionsData(entity: ImportEntity): ImportInstructionData {
  const instructions: ImportInstructionData = {
    steps: [
      {
        name: enCommonLabel.step('1'),
        header: enCommonLabel.prepareData,
        description: enCommonLabel.prepareDataDescription.split("\n")
      },
      {
        name: enCommonLabel.step('2'),
        header: enCommonLabel.selectImportData,
        description: enCommonLabel.selectImportDataDescription.split("\n")
      },
      {
        name: enCommonLabel.step('3'),
        header: enCommonLabel.waitForDataImported,
        description: enCommonLabel.waitForDataImportedDescription.split("\n")
      },
    ],
    dialogTitle: splitByUpperCase(enCommonLabel.importEntity(entity)),
    title: splitByUpperCase(enCommonLabel.importHeader(entity)),
    notes: getImportInstructions(entity),
    upperTableLabel: "",
    upperTableHeaderFields: requiredImportHeadersNotes[entity],
    upperTableFieldNotes: [],
    tableFields: entityInstructionsKeysDisplay[entity],
    tableRows: getImportInstructionRows(entity),
    belowTableLabel: "",
    belowTableNotes: [],
    warningNotes: [],
    allHeaders: allHeaders[entity],
    fileName: `${entity}.csv`
  };

  switch (entity) {
    case Entity.Asset:
    case Entity.Templates:
      instructions.upperTableLabel = enCommonLabel.addingMilestones;
      instructions.upperTableFieldNotes = enCommonLabel.milestoneNameRequired.split("\n");
      instructions.belowTableLabel = enCommonLabel.ignoreMilestoneAndTask;
      instructions.belowTableNotes = enCommonLabel.ignoreMilestoneAndTaskText.split("\n");
      instructions.warningNotes = enCommonLabel.importDuplicateMilestoneAndTaskWarning.split("\n");
      break;
    case Entity.Project:
      instructions.upperTableLabel = enCommonLabel.addingAssets;
      instructions.upperTableFieldNotes = enCommonLabel.assetNameAndCodeRequired.split("\n");
      instructions.belowTableLabel = enCommonLabel.ignoreMilestoneAndTask;
      instructions.belowTableNotes = enCommonLabel.ignoreMilestoneAndTaskText.split("\n");
      instructions.warningNotes = enCommonLabel.importDuplicateMilestoneAndTaskWarning.split("\n");
      break;
    case Entity.DataTag:
      instructions.upperTableLabel = enCommonLabel.addingDatatags;
      instructions.upperTableFieldNotes = enCommonLabel.datatagNameRequired.split("\n");
      instructions.warningNotes = enCommonLabel.importDuplicateDatatagWarning.split("\n");
      break;
    case Entity.OrganizationAsset:
      instructions.upperTableLabel = enCommonLabel.addingOrganizationAssets;
      instructions.upperTableFieldNotes = enCommonLabel.organizationAssetNameRequired.split("\n");
      instructions.warningNotes = enCommonLabel.importDuplicateOrganizationAssetWarning.split("\n");
      break;
    case Entity.Form:
      instructions.upperTableLabel = enCommonLabel.addingForm;
      instructions.upperTableFieldNotes = enCommonLabel.formFieldsRequired.split("\n");
      instructions.warningNotes = enCommonLabel.importFormWarning.split("\n");
      break;
    default:
  }

  return instructions;
}