import SmartSearchInput from "components/inputs/SmartSearchInput";
import {Project} from "types/Project";
import {Stack} from "@mui/material";
import {SortMenu, SortStatusMenu} from "components/index";
import React, {Dispatch, useEffect, useState} from "react";
import {enProjectLabel} from "constants/index";
import {Entity} from "enums/entity";
import {algoliaOrgAssetProjectsColPath, algoliaProjectsColPath} from "screens/utility/algoliaColPath";
import {useParams} from "react-router-dom";
import {AllOrder, DirectionalOrder, MiscOrder} from "enums/DirectionalOrder";
import {milestoneTaskandAssetStatus} from "enums/milestoneTaskandAssetStatus";

interface SearchAndSortProps {
  parentEntity: Entity.Organization | Entity.Asset;
  projects: Project[] | null;
  currentOrder: AllOrder;
  setAlgoliaProjects: Dispatch<Project [] | null>;
  setStatusFilter: Dispatch<milestoneTaskandAssetStatus | string>;
  setCurrentOrder: Dispatch<DirectionalOrder>;
  statusFilter: string;
}

function SearchAndSort(props: SearchAndSortProps) {
  const {parentEntity, projects, currentOrder, setAlgoliaProjects, statusFilter, setStatusFilter, setCurrentOrder} = props;
  const {orgId, assetId} = useParams();

  const [isShown, setIsShown] = useState<boolean>(false);

  useEffect(() => {
    if(isShown) return;
    setIsShown(projects !== null && projects.length > 0);
  }, [projects]);

  if(!isShown) return null;

  const algoliaColPath = parentEntity === Entity.Organization ? algoliaProjectsColPath(orgId!)
    : algoliaOrgAssetProjectsColPath(orgId!, assetId!);

  return (
    <Stack
      direction={{xs: "column", sm: "column", md: "column", lg: "row", xl: "row"}}
      justifyContent={{xs: "left", sm: "left", md: "left", lg: "space-between", xl: "space-between"}}
    >
      <SmartSearchInput<Project>
        placeholder={enProjectLabel.search}
        resultCallback={setAlgoliaProjects}
        colPath={algoliaColPath}
        additionalFilters={statusFilter !== 'All' ? [`projectStatus: ${statusFilter}`] : undefined}
      />
      <Stack
        direction={{xs: "column", sm: "column", md: "row", lg: "row", xl: "row"}}
        gap={1}
        mt={{xs: 1, sm: 1, md: 1, lg: 1, xl: "unset"}}
      >
        <SortStatusMenu setter={setStatusFilter}/>
        <SortMenu
          extraMenu={[MiscOrder.lastVisited]}
          changeOrder={setCurrentOrder}
          currentOrder={currentOrder}
        />
      </Stack>
    </Stack>
  )
}

export default SearchAndSort;
