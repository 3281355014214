import {Box} from "@mui/material";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {SystemIcons} from "assets/icons/system/system.index";
import Input from "components/Input";
import {ActionType, CounterFields, Entity, ProcessType, Severity} from "enums/index";
import {Asset, Organization} from "types/index";
import {checkLongLat, handleKeyPressNumeric, onChangeInput} from "screens/utility/formUtils";
import {BaseProps} from "screens/BaseProps";
import {useRTDBDocField, useTempDocRef} from "hooks/index";
import submitForm from "screens/utility/submitForm";
import {enAssetLabel, enCommonLabel} from "constants/index";
import {CollectionReference} from "firebase/firestore";
import DataTagInput from "components/DataTag/DataTagInput";
import {SimpleFormDrawer} from "components/index";
import {StringDictionary} from "types/FormBase";
import handleEnterKeyPress from "screens/utility/handleEnterKeyPress";
import GoogleAutocomplete from "components/inputs/GoogleAutocomplete";
import {statusSubmitHandler} from "screens/utility/statusSubmitHandler";
import {organizationAssetsPath, organizationsPath} from "screens/utility";
import {useParams} from "react-router-dom";
import {removeExtraSpaces} from "screens/utility/removeExtraSpaces";
import useCheckUniqueness from "hooks/useCheckUniqueness";

interface DrawerProps extends BaseProps {
  onDrawerClose: () => void;
  drawerVisibility: boolean;
  projName: string;
  orgName: string;
  lastIdCode: string | undefined;
  organization?: Organization;
  collectionRef: CollectionReference;
  parentEntity?: Entity.Project | Entity.Organization;
}

function AssetCreationDrawer(props: DrawerProps) {
  const {
    drawerVisibility,
    onDrawerClose,
    toastProps,
    collectionRef,
    orgName,
    projName,
    uid,
    parentEntity = Entity.Project,
  } = props;
  const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps!;

  const {orgId} = useParams();

  //form data
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assetName, setAssetName] = useState<string>("");
  const [assetIdCode, setAssetIdCode] = useState<string>("");
  const [assetAddress, setAssetAddress] = useState<string>("");
  const [assetLongitude, setAssetLongitude] = useState<number | null>(null);
  const [assetLatitude, setAssetLatitude] = useState<number | null>(null);
  const [assetNameMessage, setAssetNameMessage] = useState<string>("");
  const [assetIdCodeMessage, setAssetIdCodeMessage] = useState<string>("");
  const [assetLatitudeMessage, setAssetLatitudeMessage] = useState<string>("");
  const [assetLongitudeMessage, setAssetLongitudeMessage] = useState<string>("");
  const [dataTagsIds, setDataTagsIds] = useState<string[]>([]);
  const orgAssetIdx = useRTDBDocField<number>(`${organizationsPath().path}/${orgId}`, CounterFields.OrgAssetIdxCount) ?? 0

  const [validationErrors, setValidationErrors] = useState<StringDictionary>({});

  const [tempAssetRef, setTempAssetRef] = useTempDocRef(collectionRef);
  const firstLoad = useRef(true);

  const isNameUnique = useCheckUniqueness(organizationAssetsPath(orgId!), "name", assetName);

  useEffect(() => {
    if (!isNameUnique) {
      setAssetNameMessage(enAssetLabel.uniqueNameError);
      return;
    }

    if (assetName.trim() === "" && !firstLoad.current) {
      setAssetNameMessage(enAssetLabel.requiredName);
      return;
    }

    firstLoad.current = false;

    setAssetNameMessage("");
  }, [isNameUnique, assetName]);

  function handleNameChange(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | React.ClipboardEvent<HTMLDivElement>) {
    const target = e.target as HTMLInputElement;
    if ((target.value.trim()).length > 0) {
      setAssetName(target.value);
      setAssetNameMessage("");
      return;
    }

    setAssetNameMessage(enAssetLabel.nameRequired);
    setAssetName(target.value);
  }

  function getDefaultIdCode() {
    const date = new Date();

    if (parentEntity === Entity.Project)
      return `${orgName}/${projName}/${date.getUTCMonth() + 1} ${date.getUTCFullYear()}/${orgAssetIdx + 1}`;
    return `${orgName}/${date.getUTCMonth() + 1} ${date.getUTCFullYear()}/${orgAssetIdx + 1}`;
  }

  function resetFormData() {
    setAssetName("");
    setAssetAddress("");
    setAssetIdCode(getDefaultIdCode());
    setAssetLongitude(null);
    setAssetLatitude(null);
    setAssetLatitudeMessage("");
    setAssetLongitudeMessage("");
    setDataTagsIds([]);
    setValidationErrors({});
  }

  function isFormValid() {
    return (assetNameMessage === ""
      && assetIdCodeMessage === ""
      && assetLatitudeMessage === ""
      && assetLongitudeMessage === ""
      && assetName !== "");
  }

  useEffect(() => {
    setAssetNameMessage("");
    setAssetIdCodeMessage("");
    setAssetLatitudeMessage("");
    setAssetLongitudeMessage("");

    Object.keys(validationErrors).forEach((key) => {
      switch (key) {
        case "name":
          setAssetNameMessage(validationErrors[key]);
          break;
        case "idCode":
          setAssetIdCodeMessage(validationErrors[key]);
          break;
        case "latitude":
          setAssetLatitudeMessage(validationErrors[key]);
          break;
        case "longitude":
          setAssetLongitudeMessage(validationErrors[key]);
          break;
        default:
          break;
      }
    });
  }, [validationErrors]);

  useEffect(() => {
    setAssetName("");
    setAssetIdCode(getDefaultIdCode());
    setAssetAddress("");
    setAssetLatitude(null);
    setAssetLongitude(null);
  }, [orgName, projName]);

  useEffect(() => {
    if (!assetLatitude && assetLongitude) {
      setAssetLatitudeMessage(enAssetLabel.latitudeRequired);
      return;
    }
    if (!assetLongitude && !assetLatitude) {
      setAssetLatitudeMessage("");
      setAssetLongitudeMessage("");
      return;
    }
    if (isNaN(assetLatitude ?? 0) || !assetLatitude) return;
    if (!checkLongLat("Latitude", assetLatitude.toString())) {
      setAssetLatitudeMessage(enAssetLabel.invalidInput);
      return;
    }

    setAssetLatitudeMessage("");
    if (!assetLongitude || isNaN(assetLongitude ?? 0)) {
      setAssetLongitude(() => 180);
    }
  }, [assetLatitude]);

  useEffect(() => {

    if (!assetLongitude && assetLatitude) {
      setAssetLongitudeMessage(enAssetLabel.longitudeRequired);
      return;
    }

    if (!assetLongitude && !assetLatitude) {
      setAssetLatitudeMessage("");
      setAssetLongitudeMessage("");
      return;
    }

    if (isNaN(assetLongitude ?? 0) || !assetLongitude) return;
    if (!checkLongLat("Longitude", assetLongitude.toString())) {
      setAssetLongitudeMessage(enAssetLabel.invalidInput);
      return;
    }

    setAssetLongitudeMessage("");
    if (!assetLatitude || isNaN(assetLatitude ?? 0)) {
      setAssetLatitude(() => 90);
    }
  }, [assetLongitude]);

  useEffect(resetFormData, [drawerVisibility]);

  function successCallback() {
    setIsLoading(false);
    setToastMessage(enAssetLabel.createSuccess);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);

    setTempAssetRef();
    resetFormData();
    onDrawerClose();
  }

  function errorCallback(message: any) {
    setIsLoading(false);

    if (typeof (message) === "object") {
      setValidationErrors(message as StringDictionary);
      return;
    }

    setToastMessage(message || enCommonLabel.errorProcess(ProcessType.Create));
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
  }

  function onPlacesChange(place: string | {
    formatted_address: string,
    geometry: { location: { lat: () => number, lng: () => number } }
  }) {
    if (typeof place === "string") {
      setAssetAddress(place);
      return;
    }

    // otherwise, it's a Place object
    setAssetAddress(place.formatted_address);
    setAssetLatitude(place.geometry.location.lat());
    setAssetLongitude(place.geometry.location.lng());
  }

  async function submitAsset() {
    setIsLoading(true);
    const asset: Asset = {
      name: removeExtraSpaces(assetName),
      idCode: assetIdCode,
      nameIdx: "",
      isChecked: true,
      address: assetAddress,
      latitude: assetLatitude ?? null,
      longitude: assetLongitude ?? null,
      dataTagsIds
    }

    await submitForm<Asset>(tempAssetRef!, ActionType.Create,
      (status, data, isLastUpdate) => statusSubmitHandler<Asset>({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback
      }),
      asset
    );
  }

  function coordinatesHandleKeyPress(e: React.KeyboardEvent) {
    const isNumeric = handleKeyPressNumeric(e);

    if (isNumeric || e.key === "Enter") {
      handleEnterKeyPress(e, isFormValid(), isLoading, submitAsset);
      return;
    }

    e.preventDefault();
  }


  return (
    <SimpleFormDrawer
      title={enAssetLabel.createParentAsset(parentEntity)}
      isOpen={drawerVisibility}
      onClose={onDrawerClose}
      icon={<SystemIcons.Assets width={24} height={24}/>}
      isFormValid={isFormValid()}
      isLoading={isLoading}
      onSubmit={submitAsset}
      unsavedChanges={(
        !!assetName ||
        assetIdCode !== getDefaultIdCode() ||
        dataTagsIds.length > 0
      ) && (!!assetAddress ||
        !!assetLatitude ||
        !!assetLongitude)}
    >
      <Input
        label={enCommonLabel.name}
        id="Name"
        onChange={handleNameChange}
        sx={{mb: 1}}
        value={assetName}
        optional={false}
        validationMessage={assetNameMessage}
        placeholder=""
        onKeyPress={(e) => handleEnterKeyPress(e, isFormValid(), isLoading, submitAsset)}
      />
      <Input
        label={enAssetLabel.idCode}
        id="ID_Code"
        onChange={(e) => onChangeInput(e, setAssetIdCode)}
        sx={{mb: 1}}
        value={assetIdCode}
        optional={false}
        validationMessage={assetIdCodeMessage}
        placeholder=""
        onKeyPress={(e) => handleEnterKeyPress(e, isFormValid(), isLoading, submitAsset)}
      />
      <GoogleAutocomplete
        onPlacesChange={onPlacesChange}
        defaultValue={assetAddress}
      />
      <Input
        label={enCommonLabel.latitude}
        type="number"
        id="Latitude"
        onChange={(e) => onChangeInput(e, setAssetLatitude)}
        sx={{mb: 1, mt: 1}}
        value={(assetLatitude) ? assetLatitude.toString() : ""}
        optional={true}
        validationMessage={assetLatitudeMessage}
        placeholder="Decimal -90 to +90"
        onKeyPress={coordinatesHandleKeyPress}
      />
      <Input
        label={enCommonLabel.longitude}
        type="number"
        id="Longitude"
        onChange={(e) => onChangeInput(e, setAssetLongitude)}
        sx={{mb: 1}}
        value={(assetLongitude) ? assetLongitude.toString() : ""}
        optional={true}
        validationMessage={assetLongitudeMessage}
        placeholder="Decimal -180 to +180"
        onKeyPress={coordinatesHandleKeyPress}
      />
      <DataTagInput
        handleSelectCallback={setDataTagsIds}
        toastProps={toastProps!}
        initialTags={dataTagsIds}
        sx={{mb: 1}}
        uid={uid!}
        onKeyPress={(e) => handleEnterKeyPress(e, isFormValid(), isLoading, submitAsset)}
      />
      <Box sx={{flex: 1}}/>
    </SimpleFormDrawer>
  );
}

export default AssetCreationDrawer;
