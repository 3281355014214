import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {Box, SxProps, Theme} from "@mui/material";
import React, {useState} from "react";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, {Dayjs} from 'dayjs';
import {renderTimeViewClock} from '@mui/x-date-pickers/timeViewRenderers';
import theme from "theme/theme";

interface Props {
  time: string | null | undefined;
  onTimeChange: (newValue: string | null | undefined) => void;
  error?: boolean;
  sx?: SxProps<Theme>;
}

function TimeSelector(props: Props) {
  const {time, onTimeChange, error = false, sx} = props;
  const [open, setOpen] = useState<boolean>(false);

  const [taskDueTime, setTaskDueTime] = useState<Dayjs | null>(() => {
    // Use dayjs function directly to parse the time string
    // Note: dayjs expects a standard format, you might need to adjust depending on your input
    return time ? dayjs(time, "hh:mm A") : null;
  });

  function doTimeChange(newValue: Dayjs | null) {
    onTimeChange(newValue?.format("hh:mm A"));
    setTaskDueTime(newValue);
  }

  function handleContainerClick() {
    if (open) return;
    setOpen(true);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box onClick={handleContainerClick} sx={{width: "max-content"}}>
        <TimePicker
          label="Time"
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={taskDueTime}
          onChange={doTimeChange}
          sx={{
            height: 38,
            "& .MuiOutlinedInput-root": {
              height: "inherit",
            },
            "& .MuiInputLabel-root": {
              top: !!taskDueTime ? 0: "-6px"
            },
            "& .MuiInputLabel-root.Mui-focused": {
              top: 0,
              color: error ? theme.palette.error.main: theme.palette.primary.main
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${(error ? theme.palette.error.main: theme.palette.neutral.medium)}`,
            },
            ...sx,
          }}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
        />
      </Box>
    </LocalizationProvider>
  )
}

export default TimeSelector;