import {useNavigate} from "react-router";
import {BaseProps} from "../BaseProps";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {emptyFunction} from "constants/index";

function Calendar(props: BaseProps) {
  const {selectedOrg, setSelectedOrgId = emptyFunction} = props;

  const navigate = useNavigate();
  const {orgId} = useParams();

  // save local id once refreshed
  useEffect(() => {
    if(orgId === undefined) return navigate("/");

    if(orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  return (
    <div
      style={{
        display: "relative",
      }}
    >
    </div>
  );
};

export default Calendar;
