import {BaseProps} from "../BaseProps";
import {Template} from "types/Template";
import {Box,} from "@mui/material";
import {ExportDialog, LongText, PilledNavigationBar} from "components/index";
import {emptyFunction, enProjectLabel, enTemplateLabel} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import HeaderMoreOptionsMenu from "./HeaderMoreOptionsMenu";
import React, {useState} from "react";
import {SwiftIconButton} from "components/Button";
import ManageTemplateDrawer from "components/Drawers/ManageTemplateDrawer";
import {useComponentToggler} from "hooks/index";
import {DocumentReference} from "firebase/firestore";
import {Entity} from "enums/entity";
import ImportDialog from "components/Dialogs/ImportDialog";
import {templatesExportsPath} from "../utility";
import {useParams} from "react-router-dom";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {useNavigate} from "react-router";
import {PillItemProps} from "components/PilledNavBar/PillItem";
import {SidebarIcons} from "assets/icons";

interface HeaderProps extends BaseProps {
  template: Template;
  access: AccessType | null;
  documentRef: DocumentReference;
}

function Header(props: HeaderProps) {
  const {template, access, documentRef, uid, toastProps} = props;
  const {name} = template;
  const {orgId, templateId} = useParams();
  const navigate = useNavigate();

  const [isActionsShowing, setIsActionsShowing] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [isDrawerOpen, {open: openDrawer, close: closeDrawer}] = useComponentToggler(false);
  const [isImportDialogOpen, {open: openImportDialog, close: closeImportDialog}] = useComponentToggler(false);
  const [isExportDialogOpen, {open: openExportDialog, close: closeExportDialog}] = useComponentToggler(false);

  const pills: PillItemProps[] = [
    {
      id: "org-name",
      label: ``,
      icon: (
        <>
          {enTemplateLabel.editingTemplate + ": "}
          <SidebarIcons.Templates fill={theme.palette.neutral.dark} width={16} height={16}/>
          <LongText variant={"h5"}>{name}</LongText>
        </>
      ),
      onClickFcn: emptyFunction
    }
  ];


  function handleMenuOpen(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
    setIsActionsShowing(true);
  }

  function handleMenuClose(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();

    setAnchorEl(null);
    setIsActionsShowing(false);
  }

  function onImportClose() {
    closeImportDialog();
  }

  function onImportManualClose() {
    closeImportDialog();
    navigate(`/${orgId}/templates/${templateId}`);
  }

  return (
    <>
      <ImportDialog
        isOpen={isImportDialogOpen}
        entity={Entity.Templates}
        closeDialog={onImportClose}
        manualClose={onImportManualClose}
        parentDocumentRef={documentRef}
        toastProps={toastProps!}
        uid={uid}
      />
      <ExportDialog
        isOpen={isExportDialogOpen}
        onClose={closeExportDialog}
        toastProps={toastProps!}
        title={enTemplateLabel.exportDialogTitle}
        exportingTitle={enTemplateLabel.exportingDialogTitle}
        message={enTemplateLabel.exportDialogText}
        exportColRef={templatesExportsPath(orgId!, templateId!)}
      />
      <ManageTemplateDrawer
        uid={uid}
        template={template}
        isOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
        toastProps={toastProps!}
      />
      <PilledNavigationBar pills={pills}>
        <Box
          sx={() => ({
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
          })}
        >
          <Box sx={{display: "flex", gap: 0.5}}>
            <SwiftIconButton
              id="template-overview-header-edit-button"
              onClickFcn={openDrawer}
              disabled={!access?.[PermissionOperationKey.Update]}
              disabledHoverLabel={enTemplateLabel.cannotEdit}
              startIcon={SystemIcons.Edit}
            />
            <SwiftIconButton
              id="template-overview-header-more-options-button"
              title={enProjectLabel.moreOptions}
              onClickFcn={(e) => handleMenuOpen(e)}
              disabled={false}
            >
              <SystemIcons.MoreVertical stroke={theme.palette.text.secondary}/>
            </SwiftIconButton>
            {isActionsShowing &&
              <HeaderMoreOptionsMenu
                open={isActionsShowing}
                anchorEl={anchorEl}
                closeMenu={handleMenuClose}
                access={access}
                uid={uid}
                documentRef={documentRef}
                toastProps={toastProps!}
                openImportDialog={openImportDialog}
                openExportDialog={openExportDialog}
              />
            }
          </Box>
        </Box>
      </PilledNavigationBar>
    </>
  )
}

export default Header;
