import { DocumentReference, doc, CollectionReference } from "firebase/firestore";
import { useState } from "react";

function useTempDocRef(collectionReference: CollectionReference):[DocumentReference, (newDocRef?: DocumentReference) => void] {
    const [tempDocRef, setTempDocRef] = useState<DocumentReference>(doc(collectionReference));
    const createNewTempAssetRef = (newDocRef?: DocumentReference) => {
        setTempDocRef(
            newDocRef ?? doc(collectionReference)
        );
    }
    return [tempDocRef, createNewTempAssetRef]; // TODO: Create a listener that'll listen for tempDocRef.
                                                // Generate a new doc reference when tempDocRef is null. (Suggested by Dixie)
}

export default useTempDocRef;