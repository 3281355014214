import {ButtonProps} from "types/ButtonProps";
import {DisabledButton} from "components/Button/index";
import {Button, IconButton} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import React from "react";
import theme from "theme/theme";


function PrimaryButton(props: ButtonProps) {
  const {disabled, label, id = "button", onClickFcn, startIcon} = props;

  if (disabled) return <DisabledButton {...props} id={id}/>

  return (
    <>
      <Button
        id={id}
        variant="contained"
        onClick={() => onClickFcn()}
        color="primary"
        startIcon={startIcon ? startIcon({}) : undefined}
        sx={{display: {xs: "none", sm: "none", md: "inline-flex", lg: "inline-flex", xl: "inline-flex"}}}
      >
        {label}
      </Button>
      <IconButton
        id={id}
        onClick={() => onClickFcn()}
        sx={{
          display: {xs: "inline-block", sm: "inline-block", md: "none", lg: "none", xl: "none"},
          backgroundColor: theme.palette.primary.main,
          height: 40,
          width: 40,
          borderRadius: 2,
          "&.MuiButtonBase-root:hover": {
            bgcolor: theme.palette.primary.dark
          },
        }}
      >
        <SystemIcons.Plus stroke={theme.palette.background.paper}/>
      </IconButton>
    </>
  )
}

export default PrimaryButton;
