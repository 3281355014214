import {BaseProps} from "screens/BaseProps";
import {FileRequirement} from "types/FileRequirement";
import TemplateRequirementItem from "./TemplateRequirementItem";
import React from "react";
import {useComponentToggler, useDocument} from "hooks/index";
import {CollectionReference, doc} from "firebase/firestore";
import {Entity} from "enums/index";
import ManageFileRequirementDrawer from "components/Drawers/ManageFileRequirementDrawer";
import {AccessType} from "types/Permission";

interface TemplateRequirementsListProps extends BaseProps {
  parentEntity: Entity;
  fileRequirementsAccess: AccessType | null;
  displayedRequirements: FileRequirement[];
  selectedRequirements: Set<string>;
  onCheckboxToggle: (checked: boolean, id: string) => void;
  fileRequirementsRef: CollectionReference;
  deleteRequirement: (req: string) => void;
  showCheckBox: boolean;
}

function TemplateRequirementsList(props: TemplateRequirementsListProps) {
  const {
    displayedRequirements,
    fileRequirementsRef,
    selectedRequirements,
    parentEntity,
    fileRequirementsAccess,
    showCheckBox
  } = props;
  const {onCheckboxToggle, deleteRequirement, toastProps} = props;

  const [selectedRequirement, setSelectedRequirement] = useDocument<FileRequirement | null>(null);

  const [isDrawerOpen, {open: openDrawer, close: closeDrawer}] = useComponentToggler(false);

  function editRequirement(requirement: FileRequirement) {
    const docRef = doc(fileRequirementsRef, requirement["@id"]!);
    setSelectedRequirement(docRef);
    openDrawer();
  }

  if (parentEntity !== Entity.Templates) return null;

  return (
    <>
      {selectedRequirement && (
        <ManageFileRequirementDrawer
          uid={props.uid}
          isOpen={isDrawerOpen}
          onClose={closeDrawer}
          fileRequirement={selectedRequirement}
          collectionRef={fileRequirementsRef}
          toastProps={toastProps!}
        />
      )}
      {displayedRequirements.map(fileRequirement => {
        return (
          <TemplateRequirementItem
            key={fileRequirement["@id"]!}
            fileRequirement={fileRequirement}
            isChecked={selectedRequirements.has(fileRequirement["@id"] ?? "")}
            onCheckboxToggle={onCheckboxToggle}
            collectionRef={fileRequirementsRef}
            toastProps={toastProps}
            editRequirement={editRequirement}
            deleteRequirement={deleteRequirement}
            uid={props.uid}
            fileRequirementsAccess={fileRequirementsAccess}
            showCheckBox={showCheckBox}
          />
        )
      })}
    </>
  )
}

export default TemplateRequirementsList;