import AIcon from "assets/icons/AIcon";
import SystemIndex from "assets/icons/system/system.index";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../../../firebase";
import {useEffect, useState} from "react";
import {Box} from "@mui/material";
import theme from "theme/theme";
import getEnvKey from "../../utility/getEnvKey";
import {storageBucketKey} from "constants/envKeys";

function useGenerateFileReferenceIcon(filepath?: string) {

  const [currentFilepath, setCurrentFilepath] = useState<string | undefined>(undefined);
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);
  const storageBucket = getEnvKey(storageBucketKey);

  useEffect(() => {
    if (filepath === currentFilepath) return;
    setCurrentFilepath(filepath);

    if ((typeof (filepath) !== "string" && filepath !== "") || !storageBucket) return;

    //get file url from file preview path
    const previewReference = ref(storage, `${storageBucket}${filepath}`);
    try {
      getDownloadURL(previewReference).then(setFileUrl);
    } catch (e) {
      setFileUrl(undefined)
    }
  }, [filepath]);


  if ((typeof (filepath) !== "string" && filepath !== "") || fileUrl === undefined) return <AIcon/>

  const imageExtensions = /(.jpg|.jpeg|.png)$/i;
  const isImage = imageExtensions.exec(filepath);

  if (isImage) {
    return <img src={fileUrl} style={{height: "42px", width: "42px", borderRadius: 4, objectFit: "cover"}} alt="file preview"/>
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.swiftDefault,
        borderRadius: 1,
        width: 42,
        height: 42,
        display: "grid",
        placeItems: "center"
      }}
    >
      <SystemIndex.SystemIcons.FilesFilled/>
    </Box>
  )
}

export default useGenerateFileReferenceIcon;
