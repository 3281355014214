import {Box, Stack} from "@mui/material";
import SystemIndex from "assets/icons/system/system.index";
import {AssignItemType} from "types/ActivityFeedTypes";
import TimestampTypo from "components/Typography/TimestampTypo";
import {enActivityFeedLabel} from "constants/index";
import {formatDateTimeFirestoreDate} from "screens/utility/formatDateTimeFirestoreDate";
import {getActivityName} from "components/ActivityFeed/utils";
import {ENTITY_LABEL} from "language/en/common";
import ItemContainer from "components/ActivityFeed/ItemContainer";

interface AssignItemProps {
  item: AssignItemType
}

function AssignItem(props: AssignItemProps) {
  const {item} = props;
  const {details, timestamp, entity} = item;
  const {by, name} = details;
  const {date, time} = formatDateTimeFirestoreDate(timestamp);

  return <ItemContainer>
    <Box width={24} height={24}>
      <SystemIndex.SystemIcons.ArrowRight width={24} height={24}/>
    </Box>
    <Stack direction="column">
      {enActivityFeedLabel.assigned(name, by.name ?? "", getActivityName(item), ENTITY_LABEL[entity])}
      <TimestampTypo>{`${date} ${time}`}</TimestampTypo>
    </Stack>
  </ItemContainer>
}

export default AssignItem;
