import {SwiftFile} from "types/index";
import {enCommonButton, enCommonLabel, enFileRequirementLabel} from "constants/index";
import {Box, Stack} from "@mui/material";
import theme from "theme/theme";
import {LongText} from "components/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {CollectionReference, doc} from "firebase/firestore";
import submitForm from "screens/utility/submitForm";
import React, {useState} from "react";
import {ActionType, FileStatus, ProcessType, Severity, ViewStatus} from "enums/index";
import {BaseProps} from "screens/BaseProps";
import {SecondaryButton} from "components/Button";
import {formatDateTimeFirestoreDate} from "../../../utility";
import {displayFileName} from "./utils";

interface UploadedFileCardProps extends BaseProps {
  enabled: boolean;
  collectionRef: CollectionReference;
  file: SwiftFile;
  previewBlock: JSX.Element;
}

export default function UploadedFileCard(props: UploadedFileCardProps) {
  const {file, previewBlock, collectionRef, toastProps, enabled} = props;
  const {setToastMessage, setIsToastOpen, setToastSeverity} = toastProps!;

  const docReference = doc(collectionRef, file["@id"]!);

  const [isRejectButtonLoading, setIsRejectButtonLoading] = useState(false);
  const [isApproveButtonLoading, setIsApproveButtonLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const {shortDate, simpleTime} = formatDateTimeFirestoreDate(file.timeCreated)

  function statusHandler(fileStatus: FileStatus, status: ViewStatus, data: SwiftFile, isLastUpdate: boolean) {
    if(!isLastUpdate) return;
    switch (status) {
      case ViewStatus.Finished:
        const message = fileStatus === FileStatus.Rejected ? enFileRequirementLabel.rejectFileSuccess : enFileRequirementLabel.approveFileSuccess;
        showMessage(message, Severity.Success);
        break;
      case ViewStatus.Error:
      case ViewStatus.SecurityError:
      case ViewStatus.ValidationError:
        showMessage(enCommonLabel.errorProcess(ProcessType.Update), Severity.Error);
        break;
    }

    fileStatus === FileStatus.Rejected && setIsRejectButtonLoading(false);
    fileStatus === FileStatus.Approved && setIsApproveButtonLoading(false);
  }

  function showMessage(message: string, severity: Severity) {
    setToastMessage(message);
    setToastSeverity(severity);
    setIsToastOpen(true);
  }

  async function onActionClick(fileStatus: FileStatus.Approved | FileStatus.Rejected) {
    setIsProcessing(true);
    fileStatus === FileStatus.Rejected && setIsRejectButtonLoading(true);
    fileStatus === FileStatus.Approved && setIsApproveButtonLoading(true);
    await submitForm(docReference, ActionType.Update,
      (status, data, isLastUpdate) => statusHandler(fileStatus, status, data, isLastUpdate),
      {fileStatus: fileStatus},
    );
  }

  return (
    <Box
      maxWidth="296px"
      minHeight="298px"
      position="relative"
      sx={{
        borderRadius: "4px",
        border: `1px solid ${theme.palette.neutral.light}`,
        overflow: "hidden"
      }}
      className="file-list-item"
      id={`file-list-item-${file["@id"]}`}
    >
      <Stack spacing={0}>
        {previewBlock}
        <Box
          sx={{padding: "4px 1rem", zIndex: 1}}
        >
          <LongText
            variant="body"
            textColor={theme.palette.neutral.dark}
            maxLines={1}
            id={`file-list-item-name-${file["@id"]}`}
            tooltipText={`${file.name} - ${shortDate} ${simpleTime}`}
          >
            {displayFileName(file.name)} - {shortDate} {simpleTime}
          </LongText>
        </Box>

        <Box flex={1}></Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} px={2} pb={1}>
          <SecondaryButton
            onClickFcn={() => onActionClick(FileStatus.Rejected)}
            disabled={!enabled || isProcessing}
            startIcon={(props) => <SystemIcons.Close strokeWidth={4} {...props}
                                                     stroke={enabled ? theme.palette.error.main : theme.palette.neutral.medium}/>}
            label={enCommonButton.reject}
            id={`file-list-item-reject-button-${file["@id"]}`}
            loading={isRejectButtonLoading}
            sx={{width: "100%", minHeight: "38px"}}
          />
          <SecondaryButton
            onClickFcn={() => onActionClick(FileStatus.Approved)}
            disabled={!enabled || isProcessing}
            startIcon={(props) => <SystemIcons.Check strokeWidth={4} {...props}
                                                     stroke={enabled ? theme.palette.success.main : theme.palette.neutral.medium}/>}
            label={enCommonButton.approve}
            id={`file-list-item-approve-button-${file["@id"]}`}
            loading={isApproveButtonLoading}
            sx={{width: "100%", minHeight: "38px"}}
          />
        </Stack>
      </Stack>
    </Box>
  );
}
