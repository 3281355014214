import {useEffect, useRef, useState} from "react";
import {Box, Stack} from "@mui/material";
import {CollectionReference, getDocs, limit, orderBy, query, QueryConstraint, startAfter} from "firebase/firestore";
import {useCollection, useOnScreen} from "hooks/index";
import {EmptyList, InProgress} from "components/index";
import {Entity} from "enums/entity";
import {QueryDocumentSnapshot} from "firebase/firestore";
import EmptySearchResults from "components/EmptySearchResults";
import {sortObjectsBy} from "../screens/utility";
import {DirectionalOrder} from "enums/DirectionalOrder";
import {EmptyListProps} from "components/EmptyList";
import {StackProps} from "@mui/material/Stack/Stack";

interface VirtualListWrapperProps<Type> {
  collectionPath: CollectionReference
  renderElement: (item: Type, index: number) => JSX.Element | null;
  defaultConstraints?: QueryConstraint[];
  entity?: Entity;
  hasOrderByTimestamp?: boolean;
  fetchLimit?: number;
  fromAlgolia?: boolean;
  displayedResults?: Type[],
  sortByField?: keyof Type | null,
  sortOrder?: DirectionalOrder,
  selectAllIds?: (ids: string[]) => void,
  emptyListProps?: EmptyListProps,
  listSx?: StackProps,
  isSearching?: boolean;
}

/**
 * Return a virtualized list
 * @param props - contains the ff:
 * <li> collectionPath {CollectionReference} - to be queried</li>
 * <li> renderElement {(item: T, index: number) => JSX.Element | null} - element to be rendered for each item</li>
 * <li> defaultConstraints {Query Constraint[] - *optional*} - constraints to be passed when fetching the records from the collection reference</li>
 * <li> entity {Entity - *optional*} - name to be displayed if empty</li>
 * <li> hasOrderByTimestamp {boolean - *optional*} - set true if you passed the orderBy("timestamp") already in the defaultConstraints, default value is true</li>
 * <li> fetchLimit {number - *optional*} - number of records to be fetched, default 10</li>
 * <li> fromAlgolia {boolean - *optional*} - bypass record fetching if set to true. default false </li>
 * <li> displayedResults {T[] - *optional*} - if fromAlgolia is set to true, this is are the records that will be displayed. </li>
 * <li> sortByField {keyof T | null - *optional*} - sort records by this field</li>
 * <li> sortOrder {DirectionalOrder - *optional*} -</li>
 * <li> emptyListProps {EmptyListProps - *optional*} - allow user to customize the emptylist design</li>
 * <li> listSx {StackProps - *optional*} - allow overwrite for stack container </li>
 * <li> isSearching {boolean - *optional*} - to prevent showing no results found while FE is still fetching from algolia</li>
 * @return: JSX.Element
 */

function VirtualList<Type>(props: VirtualListWrapperProps<Type>) {
  const {renderElement, collectionPath, entity, sortByField} = props;
  const {
    hasOrderByTimestamp = true,
    fetchLimit = 10,
    defaultConstraints = [],
    fromAlgolia = false,
    displayedResults = [],
    sortOrder = DirectionalOrder.asc,
    emptyListProps = {},
    listSx = {},
    isSearching = false,
  } = props;

  const [collectionLimit, setCollectionLimit] = useState(fetchLimit);
  const [isLoading, setIsLoading] = useState(true);

  // actual collection docs fetched
  const [collectionDocs, setCollectionDocs] = useState<QueryDocumentSnapshot[] | null>(null);
  const [noMoreData, setNoMoreData] = useState(false);

  // displayed docs in list
  const [data, setData] = useState<Type[] | null>(null);

  // constraints to be used when fetching for the last inserted
  const constraints = [
    ...defaultConstraints,
    // to avoid error, if timestamp is already added in default constraints, no need to add it here
    ...(hasOrderByTimestamp ? [] : [orderBy("timeCreated", "desc")]),
    limit(1),
  ]
  // listen to the very last inserted
  const [lastDoc] = useCollection<Type>(null, collectionPath, constraints);
  // list of manually inserted docs from lastDoc
  const [insertedDocs, setInsertedDocs] = useState<Type | null>(null);

  const lastElementRef = useRef(null);
  const onScreen = useOnScreen(lastElementRef);

  useEffect(() => {
    getInitialDocs();
  }, []);

  useEffect(() => {
    // no need to refetch if from algolia
    if (fromAlgolia) return;

    if (data?.length === 0) return;

    if (!onScreen) return;

    if (noMoreData) return;

    if (isLoading) return;

    setCollectionLimit(collectionLimit + 10);
    setIsLoading(true);
  }, [onScreen]);

  // if there are changes from the collectionDocs, copy
  useEffect(() => {
    // copy from the collectionDocs
    const originalData = [...(collectionDocs ?? [])].map(doc => doc.data() as Type);
    // inserted docs - manually inserted docs but not fetched
    // @ts-ignore - all of the records has an "@id" field
    const newlyInsertedData = insertedDocs === null || originalData.some(data => data["@id"] !== insertedDocs["@id"]) ? [] : [insertedDocs];
    const newData = [
      ...originalData,
      ...newlyInsertedData
    ];

    setData(
      !!sortByField ? [...sortObjectsBy<Type>([...newData], sortByField, sortOrder)]
        : newData
    );
  }, [collectionDocs]);

  // set displayed data based on the fromAlgolia value
  useEffect(() => {
    if (fromAlgolia) {
      setData([...displayedResults]);
      return;
    }

    const newData = [...(collectionDocs ?? [])].map(doc => doc.data() as Type);
    setData(
      !!sortByField ? [...sortObjectsBy<Type>([...newData], sortByField, sortOrder)]
      : newData
    );
  }, [fromAlgolia, JSON.stringify(displayedResults)]);

  // if there are changes in the last doc inserted, insert it in the data
  useEffect(() => {
    if (lastDoc === null) return;

    // if we are not done fetching the data yet, return
    if (data === null) return;

    // means that the collection is empty
    if (lastDoc.length === 0) {
      setData([]);
      setCollectionDocs([]);
      return;
    }

    // if the initially fetched data is empty then for some reason, a new last doc is inserted, refetch initial docs
    if (data.length === 0) {
      getInitialDocs();
      return;
    }

    // @ts-ignore
    if (insertedDocs && lastDoc[0]["@id"] === insertedDocs["@id"]) return;

    // check if id of lastDoc already exists in the displayed, if yes, return
    // @ts-ignore - all of the records has an "@id" field
    if (data !== null && data.some(a => a["@id"] === lastDoc[0]["@id"])) return;

    // append to inserted docs
    setInsertedDocs(lastDoc[0]);

    // // append to displayed data
    const newData = [...(data || []), lastDoc[0]];
    setData(
      !!sortByField ? [...sortObjectsBy<Type>([...newData], sortByField, sortOrder)]
        : newData
    );
  }, [JSON.stringify(lastDoc)]);

  // if there are changes in sortOrder, sort list
  useEffect(() => {
    // if no field is provided
    if (!sortByField) return;

    const newData = [...(data || []), ...(lastDoc ? [lastDoc[0]] : [])];
    setData(
      !!sortByField ? [...sortObjectsBy<Type>([...newData], sortByField, sortOrder)]
        : newData
    );
  }, [sortOrder]);

  // fetch more docs if the value of collection limit is changed
  useEffect(() => {
    async function getMoreDocs() {
      if (!collectionDocs?.length) {
        setIsLoading(false);
        return;
      }

      const lastDoc = collectionDocs[collectionDocs.length - 1];
      const initialQuery = query(collectionPath, ...defaultConstraints, startAfter(lastDoc), limit(fetchLimit));
      const snapshot = await getDocs(initialQuery);
      const docs = snapshot.docs;
      if (docs.length === 0)
        setNoMoreData(true);

      setCollectionDocs((prev) => prev === null ? docs : [...prev, ...docs]);
      setIsLoading(false);
    }

    getMoreDocs();
  }, [collectionLimit]);

  async function getInitialDocs() {
    const initialQuery = query(collectionPath, limit(fetchLimit), ...defaultConstraints);
    const snapshot = await getDocs(initialQuery);
    const docs = snapshot.docs;
    // if docs fetched length is within the fetch limit, no more data to be fetched
    if (docs.length < fetchLimit)
      setNoMoreData(true);

    setCollectionDocs(docs);
  }

  if (collectionDocs === null || isSearching)
    return <Stack flex={1}><InProgress/></Stack>

  if (data !== null && entity && data.length === 0 && !fromAlgolia)
    return <EmptyList entity={entity} {...emptyListProps}/>

  if (entity && displayedResults.length === 0 && fromAlgolia)
    return <EmptySearchResults entity={entity} />

  return (
    <Stack gap={1} flex={1} {...listSx}>
      {(data || []).map((element, index) => renderElement(element, index))}
      {isLoading && !fromAlgolia && <InProgress/>}
      <Box height={50} width="100%" ref={lastElementRef}></Box>
    </Stack>
  )
}

export default VirtualList;
