import {Action, ResetActionItem, SearchActionItem, UpdateActionItem} from "./action";
import {ReducerActionType} from "./actionType";
import {updatePermissionValue} from "../utils/updatePermissionValue";
import {ReducerState} from "./initialState";

export function reducer(state: ReducerState, action: Action) {
  const {type} = action;

  switch (type) {
    case ReducerActionType.Update:
      const {payload: updatePayload} = action as UpdateActionItem;

      const {permissionEntity, operation, role, checked} = updatePayload;
      if (!state.localPermissions) return state;

      const updatePermissions = updatePermissionValue({
        localPermissions: structuredClone(state.localPermissions),
        id: permissionEntity,
        operation,
        role,
        checked
      });

      if(!updatePermissions) return state;

      let searchResult = state.searchResult;
      // reflect changes in the search results as well
      if(state.searchResult && state.searchResult.length > 0) {
        searchResult = state.searchResult.map(permission => updatePermissions.filter(updatePermission => updatePermission.id === permission.id)[0] ?? permission);
      }

      return !updatePermissions ? state : {
        ...state,
        localPermissions: updatePermissions,
        searchResult: searchResult
      };
    case ReducerActionType.Reset:
      const {payload: resetPayload} = action as ResetActionItem;

      return {
        localPermissions: structuredClone(resetPayload.permissions),
        searchText: null,
        searchResult: null,
      };
    case ReducerActionType.Search:
      const {payload: searchPayload} = action as SearchActionItem;
      const {searchText} = searchPayload;

      const searchResults = (state.localPermissions ?? [])
        .filter(permission => permission.id.toLowerCase().includes(searchText.toLowerCase()));

      return {
        ...state,
        searchText,
        searchResult: searchResults
      }
  }
}