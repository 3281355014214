import {Box, Stack, Typography} from "@mui/material";
import {en} from "language/en";

interface TeamCardTitleProps {
  teamsCount: number;
}

function TeamCardTitle(props: TeamCardTitleProps) {
  const {teamsCount} = props;

  if (teamsCount === 0) return null;

  return (
    <Stack direction="row" mb={1}>
      <Stack flex={4} direction="row" ml={2}>
        <Box width={60}></Box>
        <Typography variant="body" color="text.secondary" flex={2}>Name</Typography>
      </Stack>
      <Typography
        variant="body"
        color="text.secondary"
        flex={1}
        mr={1}
      >
        {en.screen.TeamsAndPeople.label.role}
      </Typography>
      <Typography
        variant="body"
        color="text.secondary"
        flex={1}
        textAlign="center"
      >
        {en.screen.TeamsAndPeople.label.members}
      </Typography>
      <Typography
        variant="body"
        color="text.secondary"
        flex={1}
        textAlign="end"
        sx={{position: "relative", right: "30px"}}
      >
        {en.screen.TeamsAndPeople.label.actions}
      </Typography>
    </Stack>
  )
}

export default TeamCardTitle;