import {Entity} from "enums/entity";
import {CollectionReference} from "firebase/firestore";
import {BaseProps} from "screens/BaseProps";
import RequirementItem from "./RequirementItem";
import {FormRequirement} from "types/FormRequirement";
import {AccessType} from "types/Permission";

interface RequirementsListProps extends BaseProps {
  formRequirementAccess: AccessType | null;
  parentEntity: Entity;
  displayedRequirements: FormRequirement[];
  selectedRequirements: Set<string>;
  onCheckboxToggle: (checked: boolean, id: string) => void;
  formRequirementsRef: CollectionReference;
  deleteRequirement: (requirement: string) => void;
  openRequirementDrawer: (requirement: FormRequirement) => void;
  showCheckBox: boolean;
}

function RequirementsList(props: RequirementsListProps) {
  const {parentEntity, displayedRequirements, selectedRequirements, showCheckBox, ...rest} = props;

  if (parentEntity === Entity.Templates) return null;

  return (
    <>
      {displayedRequirements.map((formRequirement) => {
        return (
          <RequirementItem
            key={formRequirement["@id"]!}
            formRequirement={formRequirement}
            isChecked={selectedRequirements.has(formRequirement["@id"] ?? "")}
            showCheckBox={showCheckBox}
            {...rest}
          />
        )
      })}
    </>
  )
}

export default RequirementsList;