import {emptyFunction, enCommonLabel} from "constants/index";
import theme from "theme/theme";
import {ButtonProps} from "types/ButtonProps";
import {LoadingButton} from "@mui/lab";

function DisabledButton(props: ButtonProps) {
  const {disabledHoverLabel = enCommonLabel.noPermissionForAction, label = "", id, loading, startIcon} = props;

  return (
    <LoadingButton
      {...props}
      id={id}
      variant="contained"
      onClick={emptyFunction}
      title={disabledHoverLabel}
      color="neutral"
      disableRipple
      loading={loading}
      disableElevation={true}
      sx={{
        "&.MuiButtonBase-root:hover": {
          bgcolor: theme.palette.neutral.main
        },
        color: theme.palette.neutral.dark
      }}
      startIcon={startIcon ? startIcon({stroke: theme.palette.neutral.dark}) : undefined}
    >
      {label}
    </LoadingButton>
  )
}

export default DisabledButton;