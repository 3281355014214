import {Button, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {enFormsButton, enFormsLabel} from "constants/index";
import {Input} from "components/index";
import {FormFieldType} from "enums/formFieldType";
import {SystemIcons} from "assets/icons/system/system.index";
import {useState} from "react";
import {BaseField} from "./BaseField";
import {DEFAULT_TEXT_NUMERIC_FIELD, ReducerForm} from "../FormCreationReducer";
import DataTagInput from "components/DataTag/DataTagInput";
import {BaseProps} from "screens/BaseProps";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import {reorderList} from "../../utility";
import {FormField} from "types/FormField";
import handleEnterKeyPress from "../../utility/handleEnterKeyPress";

export const FIELD_TYPE_ICONS = {
  [FormFieldType.Text]: <SystemIcons.TextFormat width={16} height={16}/>,
  [FormFieldType.Numeric]: <SystemIcons.Numeric width={16} height={16}/>,
  [FormFieldType.Paragraph]: <SystemIcons.Paragraph width={16} height={16}/>,
  [FormFieldType.Date]: <SystemIcons.Calendar width={16} height={16}/>,
  [FormFieldType.List]: <SystemIcons.Menu width={16} height={16}/>
}

interface FormInputsProps extends BaseProps {
  validationMessages: Record<string, string>;
  formData: ReducerForm;
  isEdit?: boolean;
  changeFormData: (value: any, key: any, index?: number, isFormFieldData?: boolean) => void;
  deleteFormField: (index: number) => void;
  areFieldsFilled: boolean;
  submitFormData: () => void;
}

export function FormInputs(props: FormInputsProps) {
  const {validationMessages, formData, areFieldsFilled, changeFormData, deleteFormField, toastProps, isEdit, submitFormData} = props;
  // Better implementation for advanced options is every form field type is different component. and this should be inside
  const [focusedFieldIndex, setFocusedFieldIndex] = useState<number>(0);
  function onDeleteClick(index: number) {
    deleteFormField(index);
    // This will focus the field above if the delete index is selected.
    if (index === focusedFieldIndex) setFocusedFieldIndex((index - 1 < 0 ? 0 : index - 1));
  }

  function onAddFieldClick() {
    const newLength = formData.fields.push(DEFAULT_TEXT_NUMERIC_FIELD);
    changeFormData(formData.fields, "fields");
    setFocusedFieldIndex(newLength - 1);
  }

  function onFieldDragEnd(result: DropResult) {
    if (!result.destination) return;
    const source = result.source.index;
    const dest = result.destination.index;
    const fields = [...formData.fields];
    changeFormData(reorderList<FormField<FormFieldType>>(fields, source, dest), "fields");

    /*Potential bug fixes & UX Improvements*/
    // This will focus the destIndex if source is the focused field.
    if (source === focusedFieldIndex) return setFocusedFieldIndex(dest);

    // This will focus focusedIndex-1 if source is less than focusedIndex
    // and focusedIndex+1 if source is greater than focusedIndex
    // only if dest is equal to focusedFieldIndex
    // This will prevent the glitchy focused field index when targeting the focusedFieldIndex
    if (dest === focusedFieldIndex) return setFocusedFieldIndex(source < focusedFieldIndex ? focusedFieldIndex - 1 : focusedFieldIndex + 1);

    // This will focus focusedIndex+1 if destIndex is less than focusedIndex and if focusedIndex isn't the last index;
    // This is for QoL making the focusedFieldIndex transitioning smooth
    // when focusedFieldIndex is in between source and dest indexes.
    if (dest < focusedFieldIndex && focusedFieldIndex !== fields.length - 1) return setFocusedFieldIndex(focusedFieldIndex + 1);

    // This will focus focusedIndex-1 if destIndex is less than focusedIndex and if focusedIndex isn't the first index;
    // This is for QoL making the focusedFieldIndex transitioning smooth
    // when focusedFieldIndex is in between source and dest indexes.
    if (dest > focusedFieldIndex && focusedFieldIndex !== 0) return setFocusedFieldIndex(focusedFieldIndex - 1);
  }

  function onFormKeyPress(e: React.KeyboardEvent){
    handleEnterKeyPress(e, areFieldsFilled, !areFieldsFilled, submitFormData)
  }

  return (
    <Stack gap={3}>
      <Stack sx={{
        padding: "1.5rem",
        gap: 1.5,
        borderRadius: 1,
        border: `1px solid ${theme.palette.neutral.light}`,
        transition: "all ease 0.5s",
      }}
      >
        <Input
          onChange={(e: any) => changeFormData(e.target.value, "name")}
          value={formData.name}
          label={enFormsLabel.formTitle}
          validationMessage={validationMessages.name}
          onKeyPress={onFormKeyPress}
        />
        <Input
          onChange={(e: any) => changeFormData(e.target.value, "description")}
          value={formData.description}
          label={enFormsLabel.formDescription}
          onKeyPress={onFormKeyPress}
          optional
        />
        <DataTagInput
          uid={props.uid}
          componentId="dataTagInput"
          handleSelectCallback={(dataTagsIds) => changeFormData(dataTagsIds, "dataTagsIds")}
          fieldSx={{
            ".MuiOutlinedInput-root": {
              padding: "2px 14px",
            },
          }}
          sx={{
            alignItems: "center",
          }}
          toastProps={toastProps}
          initialTags={formData.dataTagsIds ?? []}
          onKeyPress={onFormKeyPress}
        />
      </Stack>
      <DragDropContext onDragEnd={onFieldDragEnd}>
        <Droppable droppableId="formFieldsList">
          {(droppableProvided) => (
            <Stack gap={3} {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
              {
                formData.fields.map((field, index) => (
                  <Draggable draggableId={`formField_${index}`} index={index} key={`formField_${index}`}>
                    {(
                      draggableProvided: DraggableProvided,
                      draggableSnapshot: DraggableStateSnapshot,
                    ) => (
                      <BaseField
                        isDeleteDisabled={formData.fields.length <= 1}
                        changeFormData={changeFormData}
                        index={index}
                        formField={field}
                        isEditable={focusedFieldIndex === index}
                        onEditClick={() => setFocusedFieldIndex(index)}
                        onDeleteClick={() => onDeleteClick(index)}
                        isDragging={draggableSnapshot.isDragging}
                        draggableProvided={draggableProvided}
                        onFormKeyPress={onFormKeyPress}
                      />
                    )}
                  </Draggable>
                ))
              }
              {droppableProvided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
      <Stack alignItems="center">
        <Button
          startIcon={<SystemIcons.Plus
            stroke={(areFieldsFilled || isEdit) ? theme.palette.primary.main : theme.palette.neutral.medium}/>}
          variant="outlined"
          disabled={(!areFieldsFilled && !isEdit)}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            color: theme.palette.secondary.main,
            borderColor: (areFieldsFilled || isEdit) ? theme.palette.neutral.medium : theme.palette.neutral.light
          }}
          onClick={onAddFieldClick}
        >
          {enFormsButton.addField}
        </Button>
        {
          (!areFieldsFilled && !isEdit) && (
            <Typography variant="body" color={theme.palette.neutral.dark}>
              {enFormsLabel.fillBeforeAdd}
            </Typography>
          )
        }
      </Stack>
    </Stack>
  )
}
