import ThumbnailView from "components/ChatDrawer/ChatItemsView/ThumbnailView";
import React, {useEffect, useState} from "react";
import {CarouselItem} from "components/ChatDrawer/ChatItemsView/ChatAttachmentCarousel";

interface ThumbnailProps {
  selected: boolean;
  onClick: () => void;
  item: CarouselItem;
  viewable: boolean;
}

function Thumbnail(props: ThumbnailProps) {
  const {selected, onClick, item, viewable}  = props;
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  useEffect(() => {
    const videoPlayer = document.createElement('video');
    videoPlayer.src = item.source;
    videoPlayer.preload = "metadata";
    videoPlayer.crossOrigin = "anonymous";
    videoPlayer.load();
    videoPlayer.addEventListener('loadedmetadata', () => {
      if (videoPlayer.duration < 0.0) {
        return;
      }
      setTimeout(() => {
        videoPlayer.currentTime = 0.0;
      }, 200);
      videoPlayer.addEventListener('seeked', () => {
        const canvas = document.createElement("canvas");
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        const ctx = canvas.getContext("2d")!;
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        setPreviewUrl(ctx.canvas.toDataURL(
          "image/jpeg",
          0.75
        ));
      });
    });
  }, [item]);

  if (!viewable) {
    return null;
  }

  return (
    <div
      className={`embla__slide embla__slide--thumb ${
        selected ? "is-selected" : ""
      }`}
      style={{}}
    >
      <button
        onClick={onClick}
        className="embla__slide__inner embla__slide__inner--thumb"
        type="button"
      >
        <ThumbnailView
          item={item}
          previewUrl={previewUrl}
        />
      </button>
    </div>
  )
}

export default Thumbnail;