function convertMilliseconds(milliseconds: number) {
  const seconds = Math.floor(milliseconds / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)

  return {seconds: displayTimeNumber(seconds % 60), minutes: displayTimeNumber(minutes % 60), hours: displayTimeNumber(hours)}
}

function displayTimeNumber(time: number) {
  if(isNaN(time))
    return `00`

  if (time < 10)
    return `0${time}`

  return time
}

export default convertMilliseconds
