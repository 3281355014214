import {Stack, Tooltip, Typography} from "@mui/material";
import theme from "theme/theme";
import {PartialUserData} from "types/PartialUserData";
import {Avatar} from "components/index";

interface EmailTooltipProps {
  user: PartialUserData;
  children: JSX.Element;
  roleText?: string;
  isTeam?: boolean;
  // these are props the are used if other info are already fetched, no need to fetch again
  fetchedAvatarUrl?: string | undefined;
}

function EmailTooltip(props: EmailTooltipProps) {
  const {user, children, roleText, isTeam = false, fetchedAvatarUrl} = props;
  const {"@id": id = "", name, avatarColor, email} = user;

  return (
    <Tooltip
      placement="top"
      title={
        <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
          <Avatar
            sx={{
              bgcolor: avatarColor,
              mt: 0,
              color: id === "fillerId" || "" ? theme.palette.neutral.dark : theme.palette.background.paper,
            }}
            {...user}
            wrapWithTooltip={false}
            isTeam={isTeam}
            fetchedAvatarUrl={fetchedAvatarUrl}
          />
          <Stack direction="column">
            <Typography variant="h5" color={theme.palette.common.black}>
              {name}
            </Typography>
            <Tooltip
              title="Copy Email"
              componentsProps={{
                tooltip: {
                  id: "tooltipCopy"
                }
              }}
            >
              <Typography
                variant="body2"
                color={theme.palette.neutral.dark}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    color: theme.palette.primary.main
                  }
                }}
                onClick={
                  async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    await navigator.clipboard.writeText(email);
                    document.getElementById("tooltipCopy")!.innerText = "Copied!";
                  }
                }
              >
                {email}
              </Typography>
            </Tooltip>
            {roleText &&
              <Typography
                variant="body2"
                color={theme.palette.neutral.dark}
                fontWeight="bold"
              >
                {roleText}
              </Typography>
            }
          </Stack>
        </Stack>
      }
      componentsProps={{
        tooltip: {
          sx: {
            padding: 1,
            boxShadow: "4px 4px 16px -8px rgba(0,0,0,0.75)",
            backgroundColor: theme.palette.background.swiftDefault,
            ".MuiTooltip-arrow": {
              color: theme.palette.background.swiftDefault,
            }
          },
        },
      }}
      arrow
    >
      {children}
    </Tooltip>
  )
}

export default EmailTooltip;
