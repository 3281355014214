import {MenuItem, Typography} from "@mui/material";
import React from "react";
import {enCommonLabel} from "constants/index";
import defaultDisplayedNumberSelectSearch from "constants/defaultDisplayedNumberSelectSearch";

interface ExpandableLabelProps {
  expandable: boolean;
  displayedItemsLength: number;
  previousDisplayedItemsNum: number;
  setDisplayedItemsNum: (num: number) => void;
}

function ExpandableLabel(props: ExpandableLabelProps) {
  const {expandable, displayedItemsLength, previousDisplayedItemsNum, setDisplayedItemsNum} = props;
  if (expandable)
    return (
      <MenuItem onClick={() => setDisplayedItemsNum(previousDisplayedItemsNum + defaultDisplayedNumberSelectSearch)}>
        <Typography color="primary.main">
          {enCommonLabel.seeMore}
        </Typography>
      </MenuItem>
    );

  if(!expandable && displayedItemsLength > defaultDisplayedNumberSelectSearch)
  return (
    <MenuItem onClick={() => setDisplayedItemsNum(defaultDisplayedNumberSelectSearch)}>
      <Typography color="primary.main">
        {enCommonLabel.seeLess}
      </Typography>
    </MenuItem>
  )

  return null;
}

export default ExpandableLabel;