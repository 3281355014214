import React from "react";
import {Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import StackItem from "components/StackItem";
import {formatDateTimeFirestoreDate, orgInvitesCollectionPath, submitForm} from "screens/utility";
import {Invite} from "types/Invite";
import {enCommonLabel, enMemberLabel} from "constants/index";
import {doc, Timestamp} from "firebase/firestore";
import {Avatar, ConfirmDialog} from "components";
import {ActionType, InvitationStatus, ProcessType, Severity} from "enums/index";
import {BaseProps} from "screens/BaseProps";
import {useComponentToggler, useDocument} from "hooks/index";
import InviteContent from "components/InvitesView/InviteContent";
import {statusSubmitHandler} from "screens/utility/statusSubmitHandler";

interface InviteCardProps extends BaseProps {
  invitation: Invite;
  text?: string;
}

export default function InviteCard(props: InviteCardProps) {
  const {invitation, toastProps} = props;
  const {setToastMessage, setIsToastOpen, setToastSeverity} = toastProps!;

  const inviteRef = doc(orgInvitesCollectionPath(invitation.orgId), invitation["@id"]);
  const [invite] = useDocument<Invite>(inviteRef);

  const [isDialogOpen, {open: openDialog, close: closeDialog}] = useComponentToggler(false);

  if (!invite) return null;

  const {inviteeEmail, inviteDate, inviteeUserData} = invite;
  const invitationDate = formatDateTimeFirestoreDate(inviteDate);

  const inviteeData = inviteeUserData ?? {
    email: inviteeEmail,
    avatarColor: theme.palette.primary.main
  };

  async function cancelInvite() {
    await submitForm(
      inviteRef,
      ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler<Invite>({
        status,
        data,
        isLastUpdate,
        successCallback: () => successCallback(enMemberLabel.cancelSuccess),
        errorCallback
      }),
      {invitationStatus: InvitationStatus.Cancelled}
    );
  }

  async function submitResendInvite() {
    await submitForm(
      inviteRef,
      ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler<Invite>({
        status,
        data,
        isLastUpdate,
        successCallback: () => successCallback(enMemberLabel.resendSuccess),
        errorCallback
      }),
      {resend: true, dateResent: Timestamp.now()}
    )
  }

  function successCallback(message: string) {
    closeDialog();
    showMessage(message, Severity.Success);
  }

  function errorCallback(message: any) {
    const errorMessage = typeof message === "string" ? message
      : (message && message.resend) || enCommonLabel.errorProcess(ProcessType.Update);
    closeDialog();
    showMessage(errorMessage, Severity.Error);
  }

  function showMessage(message: string, severity: Severity) {
    setToastMessage(message);
    setToastSeverity(severity);
    setIsToastOpen(true);
  }

  return (
    <StackItem
      className="activity-invitation-item"
      direction={{sm: "column", md: "row"}}
      spacing={1}
    >
      <ConfirmDialog
        isOpen={isDialogOpen}
        handleClose={closeDialog}
        handleConfirm={cancelInvite}
        title={enMemberLabel.cancelConfirmationTitle}
        text={enMemberLabel.cancelConfirmationText}
      />
      <Stack direction="column" flex={1} p="4px 16px 4px 16px">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <InviteContent
            invitation={invite}
            submitResendInvite={submitResendInvite}
            openCancelDialog={openDialog}
          />
        </Stack>
        <Stack direction={{sm: "column", md: "row"}} alignItems={{sm: "left", md: "center"}} gap={0.5}>
          <Typography color={theme.palette.neutral.dark} style={{ width: '180px' }}>
            {`${invitationDate.date} ${invitationDate.twoDigitHourTime}`}
          </Typography>
          <Stack direction="row" gap={0.5}>
            {enCommonLabel.for}
            <Avatar
              {...inviteeData}
              isTeam={false}
              sx={{
                width: 24,
                height: 24,
                fontSize: 8,
                mt: 0
              }}
            />
            {inviteeData.email}
          </Stack>
        </Stack>
      </Stack>
    </StackItem>
  )
}