import {Button, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {emptyFunction, enCommonLabel} from "constants/index";
import {BulkActionButtonProps} from "./BulkActionButtonProps";
import {Entity} from "enums/entity";

function AssignedToButton(props: BulkActionButtonProps) {
  const {parentEntity = Entity.Asset, disabled, onButtonClick} = props;

  if (parentEntity === Entity.Templates) return null;
  const startIcon = disabled ? <SystemIcons.Teams width={16} stroke={theme.palette.text.secondary}/>
    : <SystemIcons.Teams width={16} stroke={theme.palette.primary.main}/>;

  return (
    <Button
      size="small"
      color="secondary"
      onClick={disabled ? emptyFunction : onButtonClick}
      startIcon={startIcon}
      title={disabled ? enCommonLabel.noPermissionForAction : undefined}
      sx={{
        alignSelf: {xs: "flex-start", sm: "flex-start", md: "center", lg: "center", xl: "center"},
        ...(disabled ? {
          cursor: "default",
          ":hover": {
            backgroundColor: "transparent"
          }
        } : {}),
      }}
    >
      <Typography variant="h5">{enCommonLabel.assignedTo}</Typography>
    </Button>
  )
}

export default AssignedToButton;