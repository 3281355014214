import {browser} from "constants/browser";

function getBrowser() {
  const userAgent = navigator.userAgent;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    return browser.Chrome;
  } else if (userAgent.match(/firefox|fxios/i)) {
    return browser.Firefox;
  } else if (userAgent.match(/safari/i)) {
    return browser.Safari;
  } else if (userAgent.match(/opr\//i)) {
    return browser.Opera;
  } else if (userAgent.match(/edg/i)) {
    return browser.Edge;
  } else if (userAgent.match(/msie|trident/i)) {
    return browser.InternetExplorer;
  }
  return  browser.Unknown;
}

export default getBrowser;