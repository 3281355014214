import {Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {Project} from "types/Project";
import {useNavigate, useParams} from "react-router-dom";
import {enTemplateLabel} from "constants/index";
import {LongText} from "components/index";
import {SecondaryButton} from "components/Button";
import React, {useContext} from "react";
import {SelectedOrgContext} from "../../SelectedOrgContextProvider";
import {AccessRole} from "enums/accessRole";

interface TemplateIndicatorProps {
  project: Project
}

function TemplateIndicator(props: TemplateIndicatorProps) {
  const {project} = props;
  const {projectAssetTemplateName, projectAssetTemplateId} = project;

  const navigate = useNavigate();
  const {orgId} = useParams();

  const selectedOrgContext = useContext(SelectedOrgContext);
  const {selectedOrg} = selectedOrgContext!;

  if (!selectedOrg) return null;

  if (![AccessRole.Owner, AccessRole.Admin].includes(selectedOrg.accessRole)) return null;

  function navigateToTemplate() {
    navigate(`/${orgId}/templates/${projectAssetTemplateId}`)
  }

  return <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    padding={1}
    marginY={1}
    borderRadius={1}
    sx={{backgroundColor: theme.palette.background.swiftDefault}}
  >
    <Stack direction="row" gap={1 / 4}>
      <Typography color={theme.palette.neutral.dark}>
        {enTemplateLabel.manageTemplatesDescription}
      </Typography>
      <LongText maxLines={1} color={theme.palette.secondary.main} maxWidth={"75%"}>
        {projectAssetTemplateName}
      </LongText>
    </Stack>
    <Stack>
    <SecondaryButton
      onClickFcn={navigateToTemplate}
      disabled={false}
      startIcon={SystemIcons.Settings}
      label={enTemplateLabel.manageTemplates}
      sx={{backgroundColor: theme.palette.background.paper, display: "inline-flex", whiteSpace: "nowrap"}}
    />
    </Stack>
  </Stack>
}

export default TemplateIndicator
