import {Grid, Stack, Typography} from "@mui/material";
import theme from "theme/theme"
import LabelText from "./LabelText";
import {TimeLog} from "types/TimeLog";
import {formatDateTimeFirestoreDate} from "screens/utility";
import {en} from "language/en";
import convertMilliseconds from "screens/utility/convertMilliseconds";
import AssignableAvatar from "components/AssignableAvatar";

interface TimeLogComponentProps {
  timeLog: TimeLog
}

function TimeLogComponent(props: TimeLogComponentProps) {
  const {timeLog} = props;

  const {startDateTime, endDateTime, assigneeData, durationInMs, location} = timeLog

  const {simpleTime: startTime} = formatDateTimeFirestoreDate(startDateTime)

  const {seconds, minutes, hours} = convertMilliseconds(durationInMs)

  function getEndTime(){
    if(!endDateTime)
      return "--"

    const {simpleTime: endTime} = formatDateTimeFirestoreDate(endDateTime)
    return endTime;
  }

  return <Stack gap={1}>
    <Grid
      container
      sx={{borderColor: theme.palette.divider}}
      border={1} padding={2}
      borderRadius={1}
      alignItems="center"
      flex={0}
      rowGap={2}
    >
      <Grid item md={24 / 7} xs={12}>
        <LabelText showOnMobile>{en.screen.TimeLog.label.worker}</LabelText>
        <Stack direction="row" alignItems="center" gap={1}>
          <AssignableAvatar
            {...assigneeData}
            sx={{mt: 0}}
            nameComponent={<Typography>{assigneeData.name}</Typography>}
          />
        </Stack>
      </Grid>
      <Grid item md={12 / 7} xs={12}>
        <LabelText showOnMobile>{en.screen.TimeLog.label.started}</LabelText>
        <Typography variant="h5">{startTime}</Typography>
      </Grid>
      <Grid item md={12 / 7} xs={12}>
        <LabelText showOnMobile>en.screen.TimeLog.label.ended</LabelText>
        <Typography variant="h5">{getEndTime()}</Typography>
      </Grid>
      <Grid item md={12 / 7} xs={12}>
        <LabelText showOnMobile>{en.screen.TimeLog.label.duration}</LabelText>
        <Typography variant="h5">{hours}:{minutes}:{seconds}</Typography>
      </Grid>
      <Grid item md={24 / 7} xs={12}>
        <LabelText showOnMobile>{en.screen.TimeLog.label.location}</LabelText>
        <Typography color={theme.palette.text.secondary}>
          {location}
        </Typography>
      </Grid>
    </Grid>
  </Stack>
}

export default TimeLogComponent
