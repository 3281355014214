import {ButtonProps} from "types/ButtonProps";
import {Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import React from "react";
import {enCommonLabel} from "constants/index";
import {LoadingButton} from "@mui/lab";

function SecondaryButton(props: ButtonProps) {
  const {
    id = "secondary-button",
    label = "",
    disabled,
    disabledHoverLabel = enCommonLabel.noPermissionForAction,
    onClickFcn,
    startIcon = SystemIcons.PlusCircle,
    sx={},
    loading
  } = props;

  return (
    <LoadingButton
      id={id}
      onClick={(e) => disabled ? undefined : onClickFcn(e)}
      startIcon={loading ? undefined : startIcon({
        height: 16,
        width: 16,
        stroke: disabled ? theme.palette.neutral.medium : theme.palette.primary.main,
      })}
      loading={loading}
      sx={{
        border: `1px solid ${theme.palette.neutral.light}`,
        ...(disabled ? {
          cursor: "default",
          ":hover": {
            backgroundColor: "transparent"
          }
        } : {}),
        ...sx
      }}
      title={disabled ? disabledHoverLabel : undefined}
      disableRipple={disabled}
    >
      <Typography
        variant="h4"
        minHeight={16}
        sx={{color: disabled ? theme.palette.neutral.medium : theme.palette.secondary.main}}
      >
        {!loading && label}
      </Typography>
    </LoadingButton>
  );
}

export default SecondaryButton;
