import {Content, EmptyList, InProgress} from "components/index";
import {emptyFunction, enAssetLabel, enOrgDataTagsLabel} from "constants/index";
import {BaseProps} from "../BaseProps";
import {useAccess, useDocument, useRTDBDocField} from "hooks/index";
import React, {useEffect, useState} from "react";
import {Asset, Organization} from "types/index";
import {
  organizationAssetPath,
  organizationAssetsPath,
  organizationPath,
  submitForm
} from "../utility";
import {
  ActionType,
  CounterFields,
  DirectionalOrder,
  Entity,
  Severity,
  ViewStatus
} from "enums/index";
import {useNavigate} from "react-router";
import {useOutletContext, useParams} from "react-router-dom";
import {PermissionEntity, PermissionOperationKey} from "types/Permission";
import PageHeader from "./PageHeader";
import AssetItem from "./AssetItem";
import AssetItemMenu from "components/AssetView/AssetItemMenu";
import {EditAssetDrawer} from "components/Drawers";
import ConfirmDialog from "components/ConfirmDialog";
import {statusSubmitHandler} from "../utility/statusSubmitHandler";
import {AssetData} from "./SearchAndSort";
import {OutletContextType} from "components/RestrictedPage";
import useProgressCount from "hooks/useProgressCount";
import {en} from "language/en";
import VirtualList from "components/VirtualList";

function OrgAssets(props: BaseProps) {
  const {toastProps, selectedOrg, uid, setSelectedOrgId = emptyFunction} = props;
  const {isLoading} = useOutletContext<OutletContextType>();
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;
  const {orgId} = useParams();

  const organizationRef = organizationPath(orgId!);
  const [sortOrder, setSortOrder] = useState<DirectionalOrder>(DirectionalOrder.asc);
  const [organization] = useDocument<Organization>(organizationRef);
  const organizationAssetsCount = useRTDBDocField<number>(organizationRef.path, CounterFields.OrganizationAssetsCount) || 0;

  const progressCount = useProgressCount({
    path: organizationRef.path,
    counterField: CounterFields.OrganizationAssetsImportingProgressCount,
    processLabel: en.screen.Organization.label.import,
    processingLabel: en.screen.Organization.label.importing,
  });

  const [algoliaResults, setAlgoliaResults] = useState<AssetData[] | null>(null);
  const [isCheckboxShown, setIsCheckboxShown] = useState<boolean>(false);
  const [checkedAssets, setCheckedAssets] = useState<Set<string>>(new Set());

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [targetAsset, setTargetAsset] = useState<Asset | null>(null);
  const [contextMenuPos, setContextMenuPos] = useState<{ top: number; left: number }>({top: 0, left: 0});
  const [targetRef, setTargetRef] = useState<Element | null>(null);

  const [deleteLength, setDeleteLength] = useState<number>(0);
  const [viewStatus, setViewStatus] = useState<ViewStatus[]>([]);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isEditAssetDrawerShown, setIsEditAssetDrawerShown] = useState<boolean>(false);
  // copy of ids displayed in ui
  const [idsDisplayed, setIdsDisplayed] = useState<string[]>([]);

  const [access] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationAsset
  });

  const navigate = useNavigate();

  useEffect(() => {
    // if delete dialog is not open, return
    if (!isDeleteDialogOpen) return;

    // if nothing to delete, return
    if (deleteLength === 0) return;

    // if not status are pushed, return
    if (viewStatus.length !== checkedAssets.size) return;

    setToastSeverity(Severity.Success);
    setToastMessage(enOrgDataTagsLabel.deleteSuccess);

    const withError = viewStatus.includes(ViewStatus.Error);
    if (withError) {
      setToastMessage(enOrgDataTagsLabel.encounteredAnError);
      setToastSeverity(Severity.Error);
    }

    setIsToastOpen(true);
    setIsDeleteDialogOpen(false);
    return;
  }, [deleteLength, viewStatus]);

  useEffect(() => {
    if (isDeleteDialogOpen) return;
    setCheckedAssets(new Set([]));
    setViewStatus([]);
    setDeleteLength(0);
  }, [isDeleteDialogOpen]);

  // save local id once refreshed
  useEffect(() => {
    if (orgId === undefined) return navigate("/");

    if (orgId !== selectedOrg!.id) setSelectedOrgId(orgId);
  }, []);

  async function deleteSelected() {
    if (checkedAssets.size === 0) return;
    setDeleteLength(checkedAssets.size);
    await Promise.all(
      Array.from(checkedAssets).map(async (assetId) => {
        const docRef = organizationAssetPath(orgId!, assetId);
        return await submitForm(
          docRef,
          ActionType.Delete,
          (status, isLastUpdate, data) => statusSubmitHandler({
            status,
            isLastUpdate,
            data,
            successCallback: () => {
              setViewStatus(prevState => [...prevState, ViewStatus.Finished])
            },
            errorCallback: () => setViewStatus((prev) => [...prev, ViewStatus.Error]),
          }),
        )
      })
    );
  }

  function onToggleCheckbox(shown: boolean) {
    setIsCheckboxShown(shown);
    if (!shown) {
      setCheckedAssets(new Set());
    }
  }

  function updateAllChecked(checked: boolean) {
    if (checked) {
      setCheckedAssets(new Set(idsDisplayed));
      return;
    }

    setCheckedAssets(new Set());
  }

  function onAssetCheck(assetId: string, checked: boolean) {
    const newCheckedAssets = new Set(checkedAssets);
    if (checked) newCheckedAssets.add(assetId);
    else newCheckedAssets.delete(assetId);

    setCheckedAssets(newCheckedAssets);
  }

  function onCloseMenu() {
    setIsMenuOpen(false);
    setTargetRef(null);
  }

  function startSingleDelete(assetId: string) {
    setCheckedAssets(new Set([assetId]));
    setIsDeleteDialogOpen(true);
  }

  function onEditAssetDrawerOpen(asset: Asset) {
    setTargetAsset(asset);
    setIsEditAssetDrawerShown(true);
    setIsMenuOpen(false);
  }

  function onMenuOpen(e: Element | undefined, asset: Asset, anchorPosition?: { left: number, top: number }) {
    if (!e) return;
    setTargetAsset(asset);
    setTargetRef(e);
    setIsMenuOpen(true);
    setContextMenuPos(anchorPosition ?? {left: 0, top: 0});
  }

  function onEditAssetOpen(asset: Asset) {
    setTargetAsset(asset);
    setIsEditAssetDrawerShown(true);
  }

  function onEditAssetDrawerClose() {
    setTargetAsset(null);
    setIsEditAssetDrawerShown(false);
  }

  if (!organization || !access || isLoading)
    return <Content><InProgress/></Content>

  const componentProps = {
    isCheckboxShown,
    toastProps: toastProps!,
    uid: uid!,
  }

  return (
    <Content>
      <PageHeader
        canCreate={Boolean(access?.[PermissionOperationKey.Create])}
        canDelete={Boolean(access?.[PermissionOperationKey.Delete])}
        checkedAssets={checkedAssets}
        isAllChecked={checkedAssets.size > 0}
        lastAssetIdCode={(organizationAssetsCount || 1).toString()}
        openDeleteDialog={() => setIsDeleteDialogOpen(true)}
        organization={organization}
        organizationAssetsCount={organizationAssetsCount || 0}
        organizationRefPath={organizationRef.path}
        setAlgoliaResults={setAlgoliaResults}
        setIsAllChecked={updateAllChecked}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        toggleCheckbox={onToggleCheckbox}
        progressCount={progressCount}
        {...componentProps}
      />
      {organizationAssetsCount === 0 ? <EmptyList entity={Entity.OrganizationAsset}/> : (
        <VirtualList<AssetData>
        collectionPath={organizationAssetsPath(orgId!)}
        displayedResults={(algoliaResults || [])}
        fetchLimit={20}
        fromAlgolia={algoliaResults !== null}
        hasOrderByTimestamp={false}
        //selectAllIds={setIdsDisplayed}
        sortByField={"name"}
        sortOrder={sortOrder}
        renderElement={item => {
          return (
            <AssetItem
              key={item["@id"]!}
              assetId={item["@id"]!}
              canEdit={Boolean(access?.[PermissionOperationKey.Update])}
              isChecked={checkedAssets.has(item["@id"]!)}
              onAssetCheck={onAssetCheck}
              onDeleteAssetClick={startSingleDelete}
              onEditAssetClick={onEditAssetOpen}
              onMenuOpen={onMenuOpen}
              {...componentProps}
            />
          )
        }}
      />
      )}
      {isMenuOpen && !!targetAsset && (
        <AssetItemMenu
          isOpen={isMenuOpen}
          anchorEl={targetRef}
          closeMenu={onCloseMenu}
          openEditAsset={() => onEditAssetDrawerOpen(targetAsset)}
          access={access}
          anchorPosition={contextMenuPos}
          deleteAsset={() => startSingleDelete(targetAsset["@id"]!)}
        />
      )}
      {targetAsset && (
        <EditAssetDrawer
          uid={uid}
          asset={targetAsset}
          isDrawerShown={isEditAssetDrawerShown}
          onDrawerClose={onEditAssetDrawerClose}
          toastProps={toastProps!}
          collectionReference={organizationAssetsPath(orgId!)}
        />
      )}
      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        title={enAssetLabel.deleteConfirmationTitle(checkedAssets.size)}
        text={enAssetLabel.deleteConfirmationText}
        handleClose={() => {
          if (!!targetAsset) {
            setCheckedAssets(new Set([]));
            onCloseMenu();
          }
          setIsDeleteDialogOpen(false);
        }}
        handleConfirm={deleteSelected}
      />
    </Content>
  );
}

export default OrgAssets;
