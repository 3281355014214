import {enCommonButton, enCommonLabel, enFormRequirementLabel} from "constants/index";
import {Button, Stack, Typography} from "@mui/material";
import {PartialUserData} from "types/PartialUserData";
import React, {useEffect, useState} from "react";
import theme from "theme/theme";
import {LoadingButton} from "@mui/lab";
import {FormField} from "types/FormField";
import {areObjectsEqual} from "../utility";
import {isFormFieldValid} from "./utils";
import {FormFieldType} from "enums/formFieldType";
import {AccessType, PermissionOperationKey} from "types/Permission";

interface FormViewHeaderProps {
  access: AccessType | null;
  formFields: FormField<FormFieldType>[];
  localFields: FormField<FormFieldType>[];
  isLoading: boolean;
  isEditMode: boolean;
  lastChangedBy?: PartialUserData;
  enableEdit: () => void;
  cancelChanges: () => void;
  saveChanges: () => void;
  setIsFormViewValid: (isValid: boolean) => void;
}

function FormViewHeader(props: FormViewHeaderProps) {
  const {formFields, localFields, isLoading, isEditMode, access, lastChangedBy, setIsFormViewValid} = props;
  const {enableEdit, cancelChanges, saveChanges} = props;

  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  useEffect(() => {
    if(areObjectsEqual(formFields, localFields)) {
      setIsFormValid(false);
      return;
    }

    const withInvalidField = localFields.some((field) => {
      const [valid] = isFormFieldValid(field);
      return !valid;
    });

    setIsFormValid(!withInvalidField);
  }, [localFields]);

  // pass isFormValid to parent component
  useEffect(() => setIsFormViewValid(isFormValid), [isFormValid]);

  if(isEditMode) {
    return (
      <Stack direction="row" alignItems="center" gap={2} justifyContent="flex-end">
        <Button
          onClick={cancelChanges}
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.secondary,
          })}
          disabled={isLoading}
        >
          {enCommonButton.cancel}
        </Button>
        <LoadingButton
          loading={isLoading}
          onClick={saveChanges}
          disabled={isLoading || !isFormValid}
          variant="contained"
        >
          <Typography variant="h4">{enCommonButton.save}</Typography>
        </LoadingButton>
      </Stack>
    )
  }

  return (
    <Stack direction="row" alignItems="center" gap={2} justifyContent="flex-end">
      <Typography color={theme.palette.neutral.dark}>
        {typeof(lastChangedBy) === "undefined"
          ? enFormRequirementLabel.noChanges
          : enFormRequirementLabel.lastChangedBy(lastChangedBy.name)}
      </Typography>
      <LoadingButton
        onClick={enableEdit}
        variant="contained"
        id="edit-form-template-button"
        loading={isLoading}
        disabled={Boolean(!access?.[PermissionOperationKey.Update])}
        title={Boolean(!access?.[PermissionOperationKey.Update]) ? enCommonLabel.noPermissionForAction : undefined}
      >
        <Typography variant="h4">{enFormRequirementLabel.editForm}</Typography>
      </LoadingButton>
    </Stack>
  )
}

export default FormViewHeader;