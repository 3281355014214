export function getParentId(parentCollectionPath: string) {
  const parentCollectionPathArray = parentCollectionPath.split('/');

  if (parentCollectionPathArray.length === 0)
    return null;

  if (parentCollectionPathArray.length === 1)
    return parentCollectionPathArray[0];

  return parentCollectionPathArray[parentCollectionPathArray.length - 2];
}

export function groupBy<T>(array: T[], size: number): T[][] {
  return array.reduce((acc, _, i) => {
    if (i % size === 0) acc.push(array.slice(i, i + size));
    return acc;
  }, [] as T[][]);
}