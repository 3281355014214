import {AccessType, PermissionOperationKey} from "types/Permission";

export const noAccess: AccessType = {
  [PermissionOperationKey.Create]: false,
  [PermissionOperationKey.Update]: false,
  [PermissionOperationKey.Delete]: false,
  [PermissionOperationKey.View]: false,
  [PermissionOperationKey.ChangeStatus]: false,
  [PermissionOperationKey.OverrideStatus]: false,
  [PermissionOperationKey.ManageOwner]: false,
  [PermissionOperationKey.ManageAdmin]: false,
  [PermissionOperationKey.ManagePowerUser]: false,
  [PermissionOperationKey.ManageUser]: false,
  [PermissionOperationKey.ManageGuest]: false,
};