import algoliasearch from "algoliasearch";
import getEnvKey from "./screens/utility/getEnvKey";
import {algoliaApiKey, algoliaAppIdKey, algoliaIndexKey} from "constants/envKeys";

const appId = getEnvKey(algoliaAppIdKey);
const apiKey = getEnvKey(algoliaApiKey);
export const client = algoliasearch(appId, apiKey);

export const orgIndex = client.initIndex("organizations");

const appIndex = getEnvKey(algoliaIndexKey);
export const algoliaIndex = client.initIndex(appIndex);
