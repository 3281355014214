import {Dispatch, FC} from "react";
import {Box, MenuItem, Select, Typography} from "@mui/material";
import {en} from "language/en";
import {onChangeInput} from "screens/utility/formUtils";
import {ProjectStatus} from "enums/projectStatus"
import {allStatus} from "constants/index";

interface SortMenuProps {
    setter: Dispatch<any>;
    currentValue?: any;
}

const statusValues: ProjectStatus[] = [
  ProjectStatus.Cancelled,
  ProjectStatus.Pending,
  ProjectStatus.InProgress,
  ProjectStatus.Approved
];

const SortStatusMenu: FC<SortMenuProps> = (props) => {
    const {setter, currentValue} = props;
    return (
        <Box sx={{display: "flex", alignItems: "center"}}>
            <Typography
                sx={(theme) => ({
                    display: "inline-block",
                    color: theme.palette.text.secondary,
                })}
                variant="body"
            >{en.common.label.status}</Typography>
            <Select
                id="org-select-field"
                defaultValue={allStatus}
                sx={{
                    width: "min-content",
                    padding: 0,
                    ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                    },
                    ".MuiOutlinedInput-input": {
                        padding: "0px 4px",
                    },
                }}
                onChange={(e) => {
                    onChangeInput(e, setter);
                }}
                value={currentValue}
            >
                <MenuItem value={allStatus} id="menu-all">
                    <Typography
                        sx={(theme) => ({
                            color: theme.palette.secondary.main,
                        })}
                        variant="body"
                    >
                        All
                    </Typography>
                </MenuItem>
                {statusValues.map(element => 
                (<MenuItem value={element} id={`menu-${element}`} key={`menu_${element}`}>
                    <Typography variant="body">{element}</Typography>
                </MenuItem>)
                )}
            </Select>
        </Box>
    );
};

export default SortStatusMenu;
