import {useEffect} from 'react';
import {useDocument} from "hooks/index";
import {Config} from "types/Config";
import {configPath} from "../screens/utility";
import {useNavigate} from "react-router-dom";


function useMaintenance(isDeveloper: boolean = false) {
  const navigate = useNavigate();
  const [config] = useDocument<Config>(configPath());

   useEffect(() => {
     if (isDeveloper) return;
    if (!config) return;
    if (!config.underMaintenance) return;

    navigate(`/under-maintenance`);
  }, [config]);

  return config;
}

export default useMaintenance;
