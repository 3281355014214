import {EnvKey} from "types/EnvKeys";

export default function getEnvKey(env: EnvKey){
  if(env.type === Object){
    return JSON.parse(process.env[env.name] ?? "")
  }

  else if(env.type === Boolean){
    const value = process.env[env.name] as string
    if(value === "false")
      return false

    return true
  }

  return process.env[env.name] as string
}
