import {PartialUserData} from "types/PartialUserData";

export const defaultUser: PartialUserData = {
  avatarColor: "#000",
  email: "",
  firstName: "Default",
  "@id": "fillerId",
  id: "fillerId",
  initials: "DU",
  lastName: "User",
  name: "Default User",
  avatarPhotoUrl: null,
  active: false
}
