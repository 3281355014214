import {CircularProgress, IconButton} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import React, {useState} from "react";
import {toastProps} from "../screens/BaseProps";
import {DocumentReference} from "firebase/firestore";
import {submitForm} from "../screens/utility";
import {ActionType} from "enums/actionType";
import {statusSubmitHandler} from "../screens/utility/statusSubmitHandler";
import {enCommonLabel} from "constants/index";
import {Severity} from "enums/severity";
import {ProcessType} from "enums/processType";

interface VisibilityIconProps {
	hidden?: boolean;
	documentRef: DocumentReference;
	hiddenMessage?: string;
	unHiddenMessage?: string;
	errorMessage?: string;
	toastProps?: toastProps;
}

function VisibilityIcon(props: VisibilityIconProps) {
	const {
		toastProps,
		hidden = false,
		documentRef,
		hiddenMessage = enCommonLabel.successfullyUpdated,
		unHiddenMessage = enCommonLabel.successfullyUpdated,
		errorMessage = enCommonLabel.errorProcess(ProcessType.Update),
	} = props;
	const [isChangingVisibility, setIsChangingVisibility] = useState<boolean>(false);
	const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps!;

	async function handleVisibility(e: React.MouseEvent<HTMLButtonElement>) {
		e.preventDefault();
		e.stopPropagation();

		setIsChangingVisibility(true);
		await submitForm(
			documentRef,
			ActionType.Update,
			(status, data, isLastUpdate) => statusSubmitHandler({
				status,
				data,
				isLastUpdate,
				successCallback,
				errorCallback
			}),
			{hidden: !hidden},
		);
	}

	function successCallback() {
		setIsChangingVisibility(false);
		setToastMessage(!hidden ? hiddenMessage: unHiddenMessage);
		setToastSeverity(Severity.Success);
		setIsToastOpen(true);
	}

	function errorCallback() {
		setIsChangingVisibility(false);
		setToastMessage(errorMessage);
		setToastSeverity(Severity.Error);
		setIsToastOpen(true);
	}
	if (isChangingVisibility) return (
		<CircularProgress sx={{mt: 1, mx: 1}} color="primary" size={16} />
	);

	return (
		<IconButton color="primary" onClick={handleVisibility}>
			{hidden
				? <SystemIcons.EyeOff width={16} height={16}/>
				: <SystemIcons.Eye width={16} height={16}/>}
		</IconButton>
	);
}

export default  VisibilityIcon;