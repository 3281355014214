import {Box, Drawer} from "@mui/material";
import {useEffect, useMemo, useRef, useState} from "react";
import {ActionType, CounterFields, Entity, MessageType} from "enums/index";
import {CollectionReference, doc, limit, orderBy, where} from "firebase/firestore";
import Header from "components/ChatDrawer/Header";
import Footer from "components/ChatDrawer/Footer";
import {styled} from "@mui/material/styles";
import {ChatMessage} from "types/index";
import {useCollection, useRTDBDocField} from "hooks/index";
import {InProgress} from "components/index";
import ChatItemsView from "components/ChatDrawer/ChatItemsView";
import {VirtuosoHandle} from "react-virtuoso";
import {emptyFunction} from "constants/index";
import {getDocPath, submitForm} from "screens/utility";
import {BaseProps} from "screens/BaseProps";
import {db} from "../../firebase";

interface ChatDrawerProps extends BaseProps {
  isOpen: boolean;
  entity: Entity;
  pathPair: string[];
  onClose: () => void;
  colRef: CollectionReference;
  navigateToOverview?: () => void;
  organizationId?: string;
  projectId?: string;
}

const INITIAL_CONSTRAINTS = [
  where("type", "!=", MessageType.Typing),
  orderBy("type"),
  orderBy("timestamp", "desc"),
  limit(20)
];

export default function ChatDrawer(props: ChatDrawerProps) {
  const {isOpen, pathPair, entity, colRef, uid} = props;
  const {onClose, navigateToOverview = emptyFunction, toastProps} = props;
  const [title, subtitle] = pathPair;

  const [isOpenState, setIsOpenState] = useState<boolean>(isOpen);
  const [initialMessages, updateMessageConstraints, updateColRef, , , lastDoc] = useCollection<ChatMessage>(null, null, INITIAL_CONSTRAINTS);
  const [messagesLength, setMessagesLength] = useState<number>(0);
  const [lastSentMessage, setLastSentMessage] = useState<ChatMessage | null>(null);
  const [displayNewChatIndicator, setDisplayNewChatIndicator] = useState<boolean>(false);
  const [lastUnreadMessageIndex, setLastUnreadMessageIndex] = useState<number>(0);

  const chatSubscribersCount = useRTDBDocField<number>(colRef.parent!.path, CounterFields.ChatSubscribersCount);
  const hasNewChat = useRTDBDocField<boolean>(getDocPath(uid, colRef.parent!.path), 'hasNewChat');
  const projectLevelTaskHasNewChat = useRTDBDocField<boolean>(getDocPath(uid, colRef.parent!.path), 'projectLevelTaskHasNewChat');

  const virtuosoRef = useRef<VirtuosoHandle>(null);

  useMemo(() => {
    if (!isOpen) {
      updateColRef(null);
      return;
    }

    updateColRef(colRef);
    updateMessageConstraints(INITIAL_CONSTRAINTS);
  }, [colRef, isOpen]);

  // update has new chat
  useEffect(() => {
    if (!isOpen) {
      setIsOpenState(isOpen);
      return;
    }

    setIsOpenState(isOpen);

    async function updateReadChat() {
      if (!initialMessages) return;

      const hasNewChatValue = entity === Entity.ProjectLevelTask ? (projectLevelTaskHasNewChat ?? false)
        : hasNewChat ?? false;

      // if no new chat, abort
      if (!hasNewChatValue) return;
      // submit false to new chat
      submitForm(
        doc(db, "users", uid, colRef.parent!.path),
        ActionType.Update,
        emptyFunction,
        entity === Entity.ProjectLevelTask ? {projectLevelTaskHasNewChat: false} : {hasNewChat: false});
    }

    updateReadChat()
  }, [isOpen, hasNewChat, projectLevelTaskHasNewChat, initialMessages])

  //update last read message
  useEffect(() => {
    if (!initialMessages)
      return

    if (!initialMessages[0])
      return

    const collectionName = entity === Entity.ProjectLevelTask ? "PLChatSubscribers" : "chatSubscribers"
    const docRef = doc(db, colRef.parent!.path, `${collectionName}/${uid}`)

    const lastMessage = !lastSentMessage ? initialMessages[0] :
      lastSentMessage.timestamp >= initialMessages[0].timestamp ? lastSentMessage
        : initialMessages[0];

    // update last message read
    submitForm(
      docRef,
      ActionType.Update,
      emptyFunction,
      {lastMessageReadId: lastMessage["@id"]}
    )

  }, [JSON.stringify(initialMessages), lastSentMessage]);

  function onChatClose() {
    // reset states
    setMessagesLength(0);
    setLastSentMessage(null);
    setDisplayNewChatIndicator(false);
    setLastUnreadMessageIndex(0);
    setLastSentMessage(null);
    onClose();
  }

  return (
    <Drawer
      id="chatDrawer"
      anchor="right"
      open={isOpenState}
      onClose={onChatClose}
    >
      <BoxMain>
        <Header
          toastProps={toastProps!}
          entity={entity}
          title={title}
          subtitle={subtitle}
          onClose={onClose}
          navigateToOverview={navigateToOverview}
          documentReference={doc(db, (colRef.parent?.path)!)}
          chatSubscribersCount={chatSubscribersCount || 0}
        />
        {initialMessages === null ? <InProgress/>
          : <ChatItemsView
            entity={entity}
            virtuosoRef={virtuosoRef}
            colRef={colRef}
            initialMessages={initialMessages}
            setMessagesLength={setMessagesLength}
            lastSentMessage={lastSentMessage}
            displayNewChatIndicator={displayNewChatIndicator}
            setDisplayNewChatIndicator={setDisplayNewChatIndicator}
            setLastUnreadMessageIndex={setLastUnreadMessageIndex}
            lastDoc={lastDoc}
          />
        }
        <Footer
          messagesLength={messagesLength}
          virtuosoRef={virtuosoRef}
          colRef={colRef}
          entity={entity}
          uid={uid}
          setLastSentMessage={(message) => setLastSentMessage(message)}
          toastProps={toastProps!}
          displayNewChatIndicator={displayNewChatIndicator}
          lastUnreadMessageIndex={lastUnreadMessageIndex}
        />
      </BoxMain>
    </Drawer>
  );
}

const BoxMain = styled(Box)({
  height: "100%",
  width: "360px",
  display: "flex",
  flexDirection: "column",
  padding: "0px",
})