import {Stack} from "@mui/material";
import SmartSearchInput from "components/inputs/SmartSearchInput";
import {SortMenu, SortStatusMenu} from "components/index";
import React, {useState} from "react";
import {algoliaAssetsPath} from "../../utility/algoliaColPath";
import {DirectionalOrder} from "enums/DirectionalOrder";
import {milestoneTaskandAssetStatus} from "enums/milestoneTaskandAssetStatus";
import {allStatus} from "constants/index";
import {Data} from "hooks/useCollectionData";

interface SearchAndFilterProps {
  orgId: string;
  projId: string;
  sortOrder: DirectionalOrder;
  setSortOrder: (order: DirectionalOrder) => void;
  setAlgoliaAssets: (assets: Data[] | null) => void;
}

function SearchAndFilter(props: SearchAndFilterProps) {
  const {orgId, projId, sortOrder, setSortOrder, setAlgoliaAssets} = props;

  const [statusFilter, setStatusFilter] = useState<milestoneTaskandAssetStatus | string>(allStatus);

  return (
    <Stack
      display="flex"
      alignItems={{xs: "left", sm: "left", md: "center", lg: "center", xl: "center"}}
      justifyContent="space-between"
      mt={{xs: 0, sm: 0, md: 1, lg: 1, xl: 1}}
      gap={2}
      direction={{xs: "column", sm: "column", md: "row", lg: "row", xl: "row"}}
    >
      <SmartSearchInput<Data>
        resultCallback={setAlgoliaAssets}
        colPath={algoliaAssetsPath(orgId!, projId!)}
        additionalFilters={statusFilter !== allStatus ? [`assetStatus:${statusFilter}`] : []}
      />
      <Stack direction={{xs: "column", sm: "row", md: "row", lg: "row", xl: "row"}} gap={1}>
        <SortStatusMenu currentValue={statusFilter} setter={setStatusFilter}/>
        <SortMenu currentOrder={sortOrder} setter={setSortOrder}/>
      </Stack>
    </Stack>
  )
}

export default SearchAndFilter;