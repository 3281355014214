//built in select states just need to handle the selectCallback for getting the selected value

import {
  Box,
  Divider,
  IconButton,
  Popover,
  styled,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import React, {ReactElement, ReactNode, SVGProps, useEffect, useRef, useState} from "react";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import defaultDisplayedNumberSelectSearch from "constants/defaultDisplayedNumberSelectSearch";
import ExpandableLabel from "components/inputs/SelectWithSearch/PopOverObjects/ExpandableLabel";
import SearchInput from "components/inputs/SelectWithSearch/PopOverObjects/SearchInput";
import MenuItemsList from "components/inputs/SelectWithSearch/PopOverObjects/MenuItemsList";
import {LongText} from "components/index";

export interface SelectItem {
  displayItem: string | ReactElement;
  value: string;
  searchable: string;
}

export interface SelectInputProps {
  label?: string;
  LabelComponent?: ReactNode;
  value?: string;
  dataTestId?: string;
  placeholder?: string | ReactNode;
  searchPlaceholder?: string;
  InputProps?: any;
  sx?: SxProps<Theme>;
  wrapperSx?: SxProps<Theme>;
  defaultValue?: string;
  id?: string;
  items: SelectItem[];
  selectLabel?: string;
  handleSelectCallback: (selected: string) => void;
  searchInputId?: string;
  extraMenuItem?: ReactNode;
  iconProps?: SVGProps<SVGSVGElement>;
  disabled?: boolean;
  clearable?: boolean;
  optional?: boolean;
}

function SelectWithSearch(props: SelectInputProps) {
  const {
    label,
    LabelComponent,
    sx,
    items,
    defaultValue,
    handleSelectCallback,
    searchInputId,
    placeholder,
    searchPlaceholder,
    extraMenuItem,
    wrapperSx,
    iconProps,
    disabled = false,
    id = "searchWithSelect",
    clearable = false,
    optional = true
  } = props;
  const [selected, setSelected] = useState(defaultValue || "");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [displayedItems, setDisplayedItems] = useState(items);
  const [displayedItemsNum, setDisplayedItemsNum] = useState(defaultDisplayedNumberSelectSearch)
  const selectRef = useRef<HTMLElement>();

  const selectedDisplay = items.find((item) => item.value === selected);
  const expandable = displayedItems.length > displayedItemsNum;

  function handleSelectItem(selection: string) {
    setSelected(selection);
    setIsPopoverOpen(false)
  }

  //listen when item loads
  useEffect(() => {
    setDisplayedItems(items)
  }, [items])

  //listen when default loads
  useEffect(() => {
    if (defaultValue)
      setSelected(defaultValue)
  }, [defaultValue])

  // give the select value on the selectCallback
  useEffect(() => {
    handleSelectCallback(selected)
  }, [selected])

  //reset displayed items number after closing the select
  useEffect(() => {
    setDisplayedItemsNum(defaultDisplayedNumberSelectSearch)
  }, [isPopoverOpen]);

  function onPopOverClose() {
    setDisplayedItems(items);
    setIsPopoverOpen(false);
  }

  return (
    <Box sx={{position: "relative", overflow: "hidden", ...sx}}>
      {label && (
        <Typography variant="h5">
          {`${label} `}
          {!optional && (
            <Typography
              component="span"
              variant="body"
              color={theme.palette.error.main}>
               *
            </Typography>
          )}
        </Typography>
      )}
      {LabelComponent}
      <ClickableWrapper
        ref={selectRef}
        onClick={(e) => {
          e.stopPropagation();
          !disabled && setIsPopoverOpen(true)
        }}
        id={id}
        display="flex"
        flexDirection={"row"}
        justifyContent="space-between"
        sx={{
          ...(wrapperSx ?? {}),
          backgroundColor: disabled ? theme.palette.background.swiftDefault : theme.palette.background.paper,
        }}
      >

        <LongText textColor={disabled ? theme.palette.neutral.dark : undefined}>
          {selectedDisplay?.displayItem || placeholder}
        </LongText>

        <IconButton
          sx={{
            alignSelf: "flex-end",
          }}
          disabled
        >
          <SystemIcons.ChevronDown
            stroke={disabled ? theme.palette.neutral.dark : theme.palette.secondary.main}
            {...iconProps}
          />
        </IconButton>
      </ClickableWrapper>
      <Popover
        open={isPopoverOpen}
        anchorEl={selectRef.current}
        onClose={onPopOverClose}
        id="selectWithSearch-popover"
        PaperProps={{
          style: {
            width: selectRef.current?.clientWidth,
            padding: "12px 4px",
            display: "flex",
            flexDirection: "column"
          },
        }}
        sx={{
          maxHeight: 340
        }}
      >
        <SearchInput
          items={items}
          searchInputId={searchInputId}
          searchPlaceholder={searchPlaceholder}
          setDisplayedItems={setDisplayedItems}
        />
        <MenuItemsList
          selected={selected ?? undefined}
          displayedItems={displayedItems}
          displayedItemsNum={displayedItemsNum}
          itemsLength={items.length}
          handleSelectItem={handleSelectItem}
          clearable={clearable}
        />
        <ExpandableLabel
          expandable={expandable}
          displayedItemsLength={displayedItems.length}
          previousDisplayedItemsNum={displayedItemsNum}
          setDisplayedItemsNum={setDisplayedItemsNum}
        />

        {extraMenuItem && (
          <>
            {displayedItems.length > 0 && <Divider/>}
            {extraMenuItem}
          </>
        )}
      </Popover>
    </Box>
  );
}

const ClickableWrapper = styled(Box)({
  flex: 1,
  display: "flex",
  alignItems: "center",
  paddingLeft: 16,
  paddingRight: 8,
  cursor: "pointer",
  border: `1px solid ${theme.palette.neutral.medium}`,
  borderRadius: 3,
});

export default SelectWithSearch;
