import {Box, styled, Typography} from "@mui/material";
import React, {useContext} from "react";
import {SidebarIcons} from "assets/icons";
import theme from "theme/theme";
import {CounterFields} from "enums/counterFields";
import Timers from "components/Timers";
import {useComponentToggler, useRTDBDocField} from "hooks/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {userPath} from "screens/utility";
import {UploadContext} from "hooks/useFileUpload/context/UploadContext";
import {UploadActionType} from "hooks/useFileUpload/reducer/UploadActionType";
import {NotificationsActiveOutlined} from "@mui/icons-material";

interface SidebarFootProps {
  uid: string;
}

function SidebarFoot(props: SidebarFootProps) {
  const {uid} = props;

  const [isTimersDrawerOpen, {open: openTimersDrawer, close: closeTimersDrawer}] = useComponentToggler(false)
  const activeTimersCount = useRTDBDocField<number>(userPath(uid).path, CounterFields.ActiveTimersCount) ?? 0;

  const uploadContext = useContext(UploadContext);
  const {fileUploadDispatch} = uploadContext!;

  function displayActiveTimerCount(count: number) {
    if (count < 0)
      return 0;

    if (count > 9)
      return "9+";

    return count
  }

  return (
    <>
      <Timers
        uid={uid}
        isOpen={isTimersDrawerOpen}
        icon={<SystemIcons.DownloadTimeData/>}
        isFormValid
        hiddenFooter
        isLoading={false}
        onClose={closeTimersDrawer}
      />
      <BottomFrameWrapper>
        <TimerWrapper
          id="timerUI"
          onClick={() => fileUploadDispatch({type: UploadActionType.openPanel})}
          sx={{marginBottom: -2}}
        >
          <NotificationsActiveOutlined width={24} height={24} sx={{fill: theme.palette.text.secondary}}/>
          <Typography
            variant="h5"
            sx={{
              position: "relative",
              width: 44,
              height: 24,
              color: theme.palette.neutral.dark,
            }}
          >
            Processes
          </Typography>
        </TimerWrapper>
        <TimerWrapper id="timerUI" onClick={openTimersDrawer}>
          <SidebarIcons.Clock width={24} height={24}/>
          <Typography
              variant="h5"
              sx={{
                position: "relative",
                width: 44,
                height: 24,
                color: theme.palette.neutral.dark,
              }}
          >
              Timers
          </Typography>
          {activeTimersCount > 0 && (
            <Box
              sx={{
                position: "absolute",
                width: 24,
                height: 24,
                left: 116,
                backgroundColor: theme.palette.secondary.light,
                borderRadius: "50%",
                display: "grid",
                placeItems: "center",
              }}
            >
              <TimerCounter>
                {displayActiveTimerCount(activeTimersCount || 1)}
              </TimerCounter>
            </Box>
          )}
      </TimerWrapper>
      </BottomFrameWrapper>
    </>
  )
}

const BottomFrameWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: 0,
  position: "relative",
  width: 280,
  left: 0,
  flex: "1 1 auto",
  justifySelf: "flex-end",
  justifyContent: "flex-end"
});

const TimerWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  position: "relative",
  order: 0,
  padding: "16px 20px",
  cursor: "pointer",
  gap: 16,
});

const TimerCounter = styled(Typography)({
  position: "absolute",
  height: 24,
  top: 1,
  fontSize: 12,
  fontWeight: "bold",
  fontFamily: "Lato",
  color: theme.palette.neutral.dark,
  textAlign: "center",
});

export default SidebarFoot;