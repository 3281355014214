import {DrawerProps} from "components/SimpleFormDrawer";
import {emptyFunction, enCommonLabel} from "constants/index";
import {Box, Drawer, IconButton, Stack, styled, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import React, {useState} from "react";
import {collection, doc, getDoc} from "firebase/firestore";
import {useCollection} from "hooks/index";
import {AccessUserType} from "enums/accessUserType";
import {AccessListUser} from "types/AccessListUser";
import {EmptyList, InProgress, OverFlowBox} from "components/index";
import EmptySearchResults from "components/EmptySearchResults";
import {Entity} from "enums/entity";
import ListItem from "components/ChatDrawer/MembersDrawer/ListItem";
import {auth, db} from "../../../firebase";
import {submitForm} from "screens/utility";
import {ActionType} from "enums/actionType";
import {ChatSubscriber} from "types/ChatSubscriber";
import {LoadingButton} from "@mui/lab";
import {toastProps} from "../../../screens/BaseProps";
import useToast from "hooks/toasts/useToast";
import {Severity} from "enums/severity";
import {statusSubmitHandler} from "../../../screens/utility/statusSubmitHandler";

interface MembersDrawerProps extends Partial<DrawerProps>{
  collectionPath: string;
  toastProps: toastProps;
}

function MembersDrawer(props: MembersDrawerProps) {
  const {isOpen, onClose = emptyFunction, collectionPath, toastProps} = props;
  const uid = auth.currentUser?.uid;

  const [subscribers] = useCollection<ChatSubscriber>(null, collection(db, collectionPath));
  const [algoliaAccessList, setAlgoliaAccessList] = useState<AccessListUser[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const subscribeToast = useToast(toastProps, Severity.Success, enCommonLabel.subscribeToastSuccess);
  const unsubscribeToast = useToast(toastProps, Severity.Success, enCommonLabel.unsubscribeToastSuccess);

  const displayedPeople = algoliaAccessList?.filter(item => item.accessUserType === AccessUserType.Person) ?? subscribers?.filter(item => item.accessUserType === AccessUserType.Person);
  const displayedTeam = algoliaAccessList?.filter(item => item.accessUserType === AccessUserType.Team) ?? subscribers?.filter(item => item.accessUserType === AccessUserType.Team);

  const subscription = subscribers ? subscribers.find(person => person["@id"] === uid ?? "fillerId") : null;
  const subscribed = subscription ? subscription.subscribe : false;

  async function onClick() {
    setIsLoading(true);
    const docRef = doc(collection(db, collectionPath), uid);
    const actionType = subscription ? ActionType.Update : ActionType.Create
    const userDoc = await getDoc(doc(collection(db, collectionPath).parent!, "accessList", uid!))
    const userData = userDoc.data();
    const formData = {...userData, subscribe: !subscribed};

    const successCallback = () => {
      setIsLoading(false);

      if(!subscribed)
        subscribeToast()
      else
        unsubscribeToast()
    }

    submitForm<Partial<ChatSubscriber>>(docRef, actionType, (status, data, isLastUpdate) => statusSubmitHandler({
      status,
      data,
      isLastUpdate,
      successCallback,
      errorCallback: () => setIsLoading(false),
    }), formData)
  }

  return (
    <Drawer id="chat-members-drawer" anchor="right" open={isOpen} onClose={onClose}>
      <DrawerFieldsWrapper>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
          <Stack direction="row" gap={1} alignItems="center">
            <IconButton
              size="small"
              disabled={false}
              onClick={onClose}
              sx={{alignSelf: "flex-start"}}
              id="back-button"
            >
              <SystemIcons.ArrowLeft
                id="chat-members-drawer-back-head-button"
                stroke={theme.palette.neutral.dark}
              />
            </IconButton>
            <Typography sx={{alignSelf: "center"}} variant="h3">
              {enCommonLabel.subscribers}
            </Typography>
            <Typography color="text.secondary" variant="h3">
              {subscribers ? subscribers.length : 0}
            </Typography>
          </Stack>
          <LoadingButton loading={isLoading} onClick={onClick} variant="contained">
            {subscribed ? enCommonLabel.unsubscribe : enCommonLabel.subscribe}
          </LoadingButton>
        </Stack>
        <OverFlowBox sx={{height: "100%"}}>
          {subscribers === null && (
            <InProgress/>
          )}
          {(subscribers && subscribers.length) === 0 &&
            <Box height="100%" sx={{display: "grid", placeItems: "center"}}><EmptyList entity={Entity.Member}/></Box>}
          {/*{((people ?? []).length > 0) && (*/}
          {/*  <SmartSearchInput<AccessListUser>*/}
          {/*    id="chat-member-drawer-search"*/}
          {/*    placeholder={enCommonLabel.searchChatMembers + "..."}*/}
          {/*    resultCallback={setAlgoliaAccessList}*/}
          {/*    colPath={collectionReference.path.split("/").slice(2).join("/")}*/}
          {/*    sx={{mx: 1, mb: 2, width: "310px"}}*/}
          {/*  />*/}
          {/*)}*/}
          {algoliaAccessList !== null && algoliaAccessList.length === 0 ? (
            <Box sx={{transform: "translateY(50%)"}}>
              <EmptySearchResults entity={Entity.Member}/>
            </Box>
          ) : (
            <>
              {displayedPeople && displayedPeople.length > 0 && (
                <Stack direction="column" gap={1}>
                  {displayedPeople.map((people) => <ListItem member={people}
                                                             key={`chat-member-drawer-people-${people["@id"]}`}/>)}
                </Stack>
              )}
              {displayedTeam && displayedTeam.length > 0 && (
                <Stack direction="column" gap={1}>
                  {displayedTeam.map((people) => <ListItem member={people}
                                                             key={`chat-member-drawer-people-${people["@id"]}`}/>)}
                </Stack>
              )}
            </>
          )}
        </OverFlowBox>
      </DrawerFieldsWrapper>
    </Drawer>
  )
}

/** css **/
const DrawerFieldsWrapper = styled(Box)({
  height: "100%",
  width: "360px",
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  overflowX: "hidden",
  overflowY: "hidden"
});
/** css **/

export default MembersDrawer;
