import theme from "theme/theme";
import {SidebarIcons} from "assets/icons";
import {styled} from "@mui/system";
import {IconButton} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {SelectedOrg} from "types/SelectedOrg";

interface SidebarOrgLabelButtonProps {
  selectedOrg: SelectedOrg;
}

export function SidebarOrgLabelButton(props: SidebarOrgLabelButtonProps) {
  const {selectedOrg} = props;

  const nav = useNavigate();

  return (
    <LabelButton
      id="sidebarLink_EXPLORE_ORGS"
      onClick={() => nav(`/${selectedOrg.id}/explore-organizations`)}
    >
      <SidebarIcons.ArrowRight
        width={16}
        height={16}
      />
    </LabelButton>
  )
}

const LabelButton = styled(IconButton)({
  borderRadius: "4px",
  width: 32,
  height: 32,
  backgroundColor: theme.palette.primary.main,
  cursor: "pointer",
  ":hover": {
    backgroundColor: "#2579db",
  },
  ":disabled": {
    backgroundColor: theme.palette.neutral.dark
  }
})