import {Checkbox, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {Organization, Team, TeamCategory} from "types/index";
import TeamCard from "./TeamCard";
import {BaseProps} from "screens/BaseProps";
import {DocumentReference} from "firebase/firestore";
import {AccessType} from "types/Permission";
import {LongText} from "components/index";
import {useRTDBDocField} from "hooks/index";
import {teamCategoriesPath} from "screens/utility";
import {CounterFields} from "enums/counterFields";
import TeamCardTitle from "./TeamCardTitle";
import EmptyTeamCategory from "./EmptyTeamCategory";

const palette = theme.palette;

interface TeamCategoryCardProps extends BaseProps {
  access: AccessType | null;
  teamCategory: TeamCategory;
  orgDoc: Organization;
  onEditTeamClick: (team: Team) => void;
  handleCheckTeam: (teamId: string, teams: Team[], teamCategoryId: string) => void;
  handleCheckTeamCategory: (checked: boolean, teams: Team[], teamCategoryId: string) => void;
  checkIsTeamSelected: (teamId: string) => boolean;
  checkIsTeamCategorySelected: (teams: Team[]) => boolean;
  teams: Team[];
  openTeamMembersDrawer: (team: Team) => void;
  handleDeleteTeam: (docReference: DocumentReference, teamId: string, name: string) => void
}

function TeamCategoryCard(props: TeamCategoryCardProps) {
  const {
    access,
    teamCategory,
    toastProps,
    handleCheckTeam,
    handleCheckTeamCategory,
    checkIsTeamSelected,
    checkIsTeamCategorySelected,
    onEditTeamClick,
    openTeamMembersDrawer,
    teams,
    handleDeleteTeam,
    uid
  } = props;

  const {name, description, "@id": id = "fillerId"} = teamCategory;
  const teamsCount = useRTDBDocField<number>(teamCategoriesPath(props.orgDoc!["@id"]!).path + "/" + id!, CounterFields.TeamsCount) || 0;

  const isTeamCategorySelected = !!teams ? checkIsTeamCategorySelected(teams) : false;

  return (
    <Stack
      padding={1}
      borderRadius={1}
      sx={{
        backgroundColor: palette.background.swiftDefault,
        border: `${theme.spacing(0.25)} solid ${isTeamCategorySelected ? palette.primary.main : "transparent"}`,
      }}
    >
      <Stack direction="row" alignItems="center" gap={0}>
        {teamsCount > 0 && (
          <Checkbox
            sx={{marginLeft: 1}}
            id={`team-category-card-checkbox-${id}`}
            className="team-category-card-checkbox"
            checked={isTeamCategorySelected}
            onChange={(_, checked) => handleCheckTeamCategory(checked, teams || [], id)}
          />
        )}
        <Stack direction="row" gap={1} ml={3} alignItems="center" justifyContent="flex-start">
          <LongText variant="h4">{name}</LongText>
          <Typography variant="h5" color="text.secondary">{teamsCount}</Typography>
          <Stack marginLeft={1} maxWidth="80%">
            <LongText variant="body" color="text.secondary">
              {description ?? ""}
            </LongText>
          </Stack>
        </Stack>
      </Stack>
      <TeamCardTitle teamsCount={teamsCount} />
      {teamsCount === 0 && <EmptyTeamCategory />}
      <Stack direction="column" gap={1}>
        {teams.map((team) =>
          <TeamCard
            access={access}
            isSelected={checkIsTeamSelected(team["@id"]!)}
            toastProps={toastProps}
            handleCheckTeam={() => handleCheckTeam(team["@id"]!, teams, id)}
            teamId={team["@id"]!}
            onEditClick={onEditTeamClick}
            openTeamMembersDrawer={openTeamMembersDrawer}
            key={team["@id"]!}
            uid={uid}
            handleDeleteTeam={handleDeleteTeam}
          />)}
      </Stack>
    </Stack>
  )
}

export default TeamCategoryCard
