/* eslint-disable react-hooks/exhaustive-deps */
import {Input, SimpleFormDrawer} from "components/index";
import {SystemIcons} from "assets/icons/system/system.index";
import React, {useEffect, useState} from "react";
import {
  emailRegex,
  enCommonButton,
  enCommonLabel,
  enMemberButton,
  enMemberLabel,
  enMemberValidation,
} from "constants/index";
import {Box} from "@mui/material";
import {AccessRole, ActionType, Severity} from "enums/index";
import {
  areObjectsEqual,
  getKeyValue,
  onChangeInput,
  sapInvitesPath,
  submitForm,
  userPath,
} from "screens/utility";
import {useDebounce} from "hooks/index";
import {Invite, SelectedOrg} from "types/index";
import {organizationMembersPath, orgInvitesCollectionPath} from "screens/utility/FirebasePath";
import {collection, doc, getDocs, query, Timestamp, where} from "firebase/firestore";
import {BaseProps} from "screens/BaseProps";
import RoleSelect from "./RoleSelect";
import OrganizationSelect from "components/Drawers/AddMemberDrawer/OrganizationSelect";
import useCheckUniqueness from "hooks/useCheckUniqueness";
import {statusSubmitHandler} from "screens/utility/statusSubmitHandler";
import {auth} from "../../../firebase";
import {common} from "language/en/common";

interface AddMemberDrawerProps extends BaseProps {
  isAddDrawerOpen: boolean;
  onDrawerClose: () => void;
  selectedOrg: SelectedOrg;
  fromSAP?: boolean;
}

const initialMember = {
  email: "",
  role: AccessRole.User,
}

const WITHOUT_ORGANIZATION = "withoutOrganization";

function AddMemberDrawer(props: AddMemberDrawerProps) {
  const {email: authEmail} = auth.currentUser ?? {email: ""};

  const {isAddDrawerOpen, selectedOrg, fromSAP = false, uid, onDrawerClose, toastProps} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const membersRef = organizationMembersPath(selectedOrg.id);

  const [organization, setOrganization] = useState<string | undefined>(WITHOUT_ORGANIZATION);

  // form data
  const [email, setEmail] = useState<string>(initialMember.email);
  const [role, setRole] = useState<AccessRole>(initialMember.role);

  const userCollectionRef = collection(userPath(uid), "users");
  const isEmailDoesNotExists = useCheckUniqueness(userCollectionRef, "email", email.trim());

  const [isCheckingEmail, setIsCheckingEmail] = useState<boolean>(false);
  const [emailValidationMessage, setEmailValidationMessage] = useState<string>("");

  useDebounce(validateEmailExistence, 300, [email, authEmail, organization]);

  async function validateEmailExistence() {
    setIsCheckingEmail(true);
    setEmailValidationMessage("");

    if (email === "") {
      setEmailValidationMessage(enMemberValidation.requiredEmail);
      setIsCheckingEmail(false);
      return;
    }

    if (email === authEmail) {
      setEmailValidationMessage(enMemberValidation.emailSameAsLoggedIn);
      setIsCheckingEmail(false);
      return;
    }

    if (!emailRegex.test(email)) {
      setEmailValidationMessage(enMemberValidation.invalidEmail);
      setIsCheckingEmail(false);
      return;
    }

    if (organization === WITHOUT_ORGANIZATION) {
      setIsCheckingEmail(false);
      return;
    }

    const querySameName = query(membersRef, where("email", "==", email.trim().toLowerCase()));
    const docData = await getDocs(querySameName);
    if (docData.docs.length) {
      setEmailValidationMessage(enMemberValidation.emailAlreadyExists);
      setIsCheckingEmail(false);
      return;
    }

    setIsCheckingEmail(false);
  }

  useEffect(() => {
    if (!isAddDrawerOpen) return;

    setEmailValidationMessage("");
    setIsLoading(false);
  }, [isAddDrawerOpen]);

  // if organization has not been set, set it to undefined if email exists
  useEffect(() => {
    if (isEmailDoesNotExists) return setOrganization(organization === undefined ? WITHOUT_ORGANIZATION : organization);

    setOrganization(organization === WITHOUT_ORGANIZATION ? undefined : organization);
  }, [isEmailDoesNotExists, organization]);

  async function onAddMember() {
    if (!selectedOrg) return;

    if (fromSAP && !uid) {
      setToastMessage(enCommonLabel.noPermissionForAction);
      setToastSeverity(Severity.Error);
      setIsToastOpen(true);
      return;
    }

    setIsLoading(true);

    const newMember: Partial<Invite> = {
      ...(!fromSAP ? {role} : {}),
      ...(fromSAP ? {orgId: organization} : {}),
      ...(fromSAP && organization !== WITHOUT_ORGANIZATION ? {role} : {}),
      inviteeEmail: email.trim(),
      inviteDate: Timestamp.now(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }

    let inviteRef = doc(orgInvitesCollectionPath(selectedOrg.id));
    if (fromSAP && organization === WITHOUT_ORGANIZATION)
      inviteRef = doc(sapInvitesPath());
    if (fromSAP && organization !== WITHOUT_ORGANIZATION)
      inviteRef = doc(orgInvitesCollectionPath(WITHOUT_ORGANIZATION));

    await submitForm<Partial<Invite>>(inviteRef, ActionType.Create,
      (status, data, isLastUpdate) => statusSubmitHandler<Invite>({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback
      }),
      newMember
    );
  }

  function successCallback() {
    setIsLoading(false);
    setIsToastOpen(true);
    setToastSeverity(Severity.Success);
    setToastMessage(enMemberLabel.successfullyCreated);
    onClose();
  }

  function errorCallback(message: any) {
    setIsLoading(false);

    if (typeof (message) === "object") {
      setEmailValidationMessage(getKeyValue(message, "email", "")
        || getKeyValue(message, "name", "")
        || getKeyValue(message, "user", ""));
      return;
    }

    setToastMessage(message ?? common.validations.generalError);
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
  }

  function onClose() {
    onDrawerClose();
    setEmail("");
  }

  function onKeyPress(event: React.KeyboardEvent) {
    if (event.key === "Enter" && !emailValidationMessage && !isCheckingEmail && !isLoading) {
      onAddMember();
    }
  }

  return (
    <SimpleFormDrawer
      isOpen={isAddDrawerOpen}
      onClose={onClose}
      id="add-member-drawer"
      buttonId="add-member-drawer-save-button"
      icon={<SystemIcons.AddUser/>}
      isFormValid={!emailValidationMessage && !isCheckingEmail}
      isLoading={isLoading}
      onSubmit={onAddMember}
      rightButtonLabel={enCommonButton.add}
      title={enMemberButton.addNew(fromSAP ? "Users" : "Member")}
      subtitle={enMemberLabel.allAreRequired}
      unsavedChanges={!areObjectsEqual(initialMember, {email, role})}
    >
      <Input
        label={enMemberLabel.email}
        id="add-member-drawer-email-input"
        onChange={(e) => {
          setIsCheckingEmail(true);
          onChangeInput(e, setEmail);
        }}
        sx={{mb: 1}}
        value={email}
        optional={false}
        validationMessage={emailValidationMessage}
        onKeyPress={onKeyPress}
      />
      <OrganizationSelect
        selectedOrg={organization}
        isEmailExists={!isEmailDoesNotExists}
        uid={uid!}
        setSelectedOrg={setOrganization}
        isAdminPanel={fromSAP}
      />
      <RoleSelect fromSAP={fromSAP} role={role} setRole={setRole} selectedOrg={organization}/>
      <Box flex={1}/>
    </SimpleFormDrawer>
  )
}

export default AddMemberDrawer;