import {datatagToneColor} from "enums/datatagToneColor";

export type Tone  = {
  background: string;
  color: string;
  name: string;
}

export const datatagColor: Record<datatagToneColor, Tone> = {
  [datatagToneColor.tone_0_0]: {
    background: "#e6ecf2",
    color: "#565b5e",
    name: "Black 1",
  },
  [datatagToneColor.tone_1_0]: {
    background: "#E6F6CF",
    color: "#4DA400",
    name: "Green 1",
  },
  [datatagToneColor.tone_2_0]: {
    background: "#D8F7F3",
    color: "#45818E",
    name: "Teal 1",
  },
  [datatagToneColor.tone_3_0]: {
    background: "#E0F1FB",
    color: "#3D85C6",
    name: "Blue 1",
  },
  [datatagToneColor.tone_4_0]: {
    background: "#FCE5F1",
    color: "#DC5766",
    name: "Pink 1",
  },
  [datatagToneColor.tone_5_0]: {
    background: "#FFEE9C",
    color: "#B45F06",
    name: "Yellow 1",
  },
  [datatagToneColor.tone_6_0]: {
    background: "#F7E9C1",
    color: "#B45F06",
    name: "Brown 1",
  },


  [datatagToneColor.tone_0_1]: {
    background: "#BABABA",
    color: "#444",
    name: "Black 2",
  },
  [datatagToneColor.tone_1_1]: {
    background: "#B7E281",
    color: "#444",
    name: "Green 2",
  },
  [datatagToneColor.tone_2_1]: {
    background: "#92E1D5",
    color: "#444",
    name: "Teal 2",
  },
  [datatagToneColor.tone_3_1]: {
    background: "#A6E0FC",
    color: "#444",
    name: "Blue 2",
  },
  [datatagToneColor.tone_4_1]: {
    background: "#FFC8EA",
    color: "#444",
    name: "Pink 2",
  },
  [datatagToneColor.tone_5_1]: {
    background: "#FED74A",
    color: "#444",
    name: "Yellow 2",
  },
  [datatagToneColor.tone_6_1]: {
    background: "#E0C378",
    color: "#444",
    name: "Brown 2",
  },


  [datatagToneColor.tone_0_2]: {
    background: "#878787",
    color: "#FFF",
    name: "Black 3",
  },
  [datatagToneColor.tone_1_2]: {
    background: "#7DBD36",
    color: "#FFF",
    name: "Green 3",
  },
  [datatagToneColor.tone_2_2]: {
    background: "#25BEB2",
    color: "#FFF",
    name: "Teal 3",
  },
  [datatagToneColor.tone_3_2]: {
    background: "#42A3DF",
    color: "#FFF",
    name: "Blue 3",
  },
  [datatagToneColor.tone_4_2]: {
    background: "#FF7BC3",
    color: "#FFF",
    name: "Pink 3",
  },
  [datatagToneColor.tone_5_2]: {
    background: "#FF7123",
    color: "#FFF",
    name: "Yellow 3",
  },
  [datatagToneColor.tone_6_2]: {
    background: "#CE6700",
    color: "#FFF",
    name: "Brown 3",
  },

  [datatagToneColor.tone_0_3]: {
    background: "#4D4D4D",
    color: "#FFF",
    name: "Black 4",
  },
  [datatagToneColor.tone_1_3]: {
    background: "#409600",
    color: "#FFF",
    name: "Green 4",
  },
  [datatagToneColor.tone_2_3]: {
    background: "#2F9890",
    color: "#FFF",
    name: "Teal 4",
  },
  [datatagToneColor.tone_3_3]: {
    background: "#0070E4",
    color: "#FFF",
    name: "Blue 4",
  },
  [datatagToneColor.tone_4_3]: {
    background: "#DC0083",
    color: "#FFF",
    name: "Pink 4",
  },
  [datatagToneColor.tone_5_3]: {
    background: "#E30000",
    color: "#FFF",
    name: "Yellow 4",
  },
  [datatagToneColor.tone_6_3]: {
    background: "#8D5100",
    color: "#FFF",
    name: "Brown 4",
  },

  [datatagToneColor.tone_0_4]: {
    background: "#1A1A1A",
    color: "#FFF",
    name: "Black 5",
  },
  [datatagToneColor.tone_1_4]: {
    background: "#246512",
    color: "#FFF",
    name: "Green 5",
  },
  [datatagToneColor.tone_2_4]: {
    background: "#00665E",
    color: "#FFF",
    name: "Teal 5",
  },
  [datatagToneColor.tone_3_4]: {
    background: "#0050A1",
    color: "#FFF",
    name: "Blue 5",
  },
  [datatagToneColor.tone_4_4]: {
    background: "#900052",
    color: "#FFF",
    name: "Pink 5",
  },
  [datatagToneColor.tone_5_4]: {
    background: "#8E1600",
    color: "#FFF",
    name: "Yellow 5",
  },
  [datatagToneColor.tone_6_4]: {
    background: "#553000",
    color: "#FFF",
    name: "Brown 5",
  },
}