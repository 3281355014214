import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow, Tooltip,
  Typography
} from "@mui/material";
import {enCommonLabel} from "constants/index";
import React from "react";
import {datatagColor, Tone} from "constants/datatagColor";
import {chunkArray} from "screens/utility";

function DataTagColors() {
  const colorTones = chunkArray(Object.entries(datatagColor), 7);

  async function copyToClipboard(e: React.MouseEvent<HTMLButtonElement>, key: string) {
    e.preventDefault();
    e.stopPropagation();
    await navigator.clipboard.writeText(key);
    document.getElementById("tooltipCopy"+key)!.innerText = "Copied!";
  }

  return (
    <>
      <Typography variant="h5">{enCommonLabel.dataTagColors}</Typography>
      {enCommonLabel.importDataTagColorInstruction.split("\n").map(instruction => (
        <Typography>{instruction}</Typography>
      ))}
      <TableContainer component={Paper} sx={{mb: 2}}>
        <Table sx={{minWidth: 650}}>
          <TableBody>
            {colorTones.map((colorTone, index) => (
              <TableRow key={index}>
                {colorTone.map(([key, value]: [string, Tone]) => (
                  <TableCell key={key}>
                    <Tooltip
                      title={enCommonLabel.clickToCopy}
                      componentsProps={{
                        tooltip: {id: "tooltipCopy"+key}
                      }}
                    >
                      <Button
                        sx={{
                          ...value,
                          py: 0.5,
                          px: 1.5,
                          minWidth: "auto",
                          borderRadius: 1,
                          fontWeight: 500,
                          "&:hover": {
                            background: value.background,
                            color: value.color,
                            opacity: 0.8
                          }
                        }}
                        onClick={(e) => copyToClipboard(e, key)}
                      >
                        {key}
                      </Button>
                    </Tooltip>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
export default DataTagColors;