import {Box, Checkbox, Divider, Hidden, Stack, Typography} from "@mui/material";
import {SwiftIconButton} from "components/Button";
import {SystemIcons} from "assets/icons/system/system.index";
import React, {useEffect, useState} from "react";
import {enOrgDataTagsLabel} from "constants/index";
import {useComponentToggler, useDocument} from "hooks/index";
import {DataTag, DataTagCounters} from "types/DataTag";
import {db} from "../../firebase";
import {doc} from "firebase/firestore";
import {Avatar, SkeletonItem} from "components/index";
import TagDescription from "./TagDescription";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";
import {areObjectsEqual, submitForm} from "../utility";
import {ActionType, CounterFields, Entity, Severity} from "enums/index";
import {toastProps} from "../BaseProps";
import {statusSubmitHandler} from "../utility/statusSubmitHandler";
import {ManageDataTagCardDrawer} from "./ManageDataTagCardDrawer";
import TagCounter from "./TagCounter";
import useRTDBDocument from "hooks/useRTDBDocument";
import {datatagColor} from "constants/datatagColor";
import {datatagToneColor} from "enums/datatagToneColor";

interface DataTagItemProps {
  uid: string;
  id: string;
  isChecked: boolean;
  organizationTagColRef: string;
  isCheckboxShown: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  toastProps: toastProps;
  onTagCheck: (id: string, checked: boolean) => void;
  tag?: DataTag;
}

function DataTagItem(props: DataTagItemProps) {
  const {id, isChecked, isCheckboxShown, organizationTagColRef, toastProps, onTagCheck} = props;
  const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps!;

  const [datatag, setDatatag] = useState<DataTag | null | undefined>(!!props.tag ? props.tag : null);
  const [datatagDoc, setDataTagRef, tagRef] = useDocument<DataTag>(doc(db, organizationTagColRef, id));

  // intentional, this rtdb document does not contain any sub collection
  const [counters] = useRTDBDocument<DataTagCounters>(`${organizationTagColRef}/${id}`);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isManageDataTagOpen, {close: closeManageDataTag, open: openManageDataTag}] = useComponentToggler(false);

  useEffect(() => {
    setDataTagRef(doc(db, organizationTagColRef, id));
  }, [id]);

  useEffect(() => {
    if (datatagDoc === null) return;

    if (datatagDoc === undefined) {
      setDatatag(undefined);
      return;
    }

    if (areObjectsEqual(props.tag ?? {}, datatagDoc)) return;

    setDatatag(datatagDoc);
  }, [datatagDoc]);

  async function deleteDatatag() {
    if (!tagRef) return;

    await submitForm(
      tagRef,
      ActionType.Delete,
      (status, data, isLastUpdate) => statusSubmitHandler({status, data, isLastUpdate, successCallback, errorCallback}),
    );
  }

  function successCallback() {
    setIsToastOpen(true);
    setToastMessage(enOrgDataTagsLabel.deleteSuccess);
    setToastSeverity(Severity.Success);
  }

  function errorCallback(error: any) {
    let message = enOrgDataTagsLabel.deleteError;
    if (typeof error === "string" && error !== "")
      message = error;

    setIsToastOpen(true);
    setToastMessage(message);
    setToastSeverity(Severity.Error);
  }

  function onCardSelect(e: React.ChangeEvent, checked: boolean) {
    onTagCheck(id, checked)
  }

  if (datatag === null) {
    return <SkeletonItem height={"5rem"} sx={{marginTop: "5px", width:"80%"}}/>;
  }

  if (datatag === undefined) return <div/>;

  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${isChecked ? theme.palette.primary.main : theme.palette.divider}`,
        borderRadius: "4px",
        px: 1,
        py: 0,
        marginRight: "20%",
        marginY: 1
      })}
    >
      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        title={enOrgDataTagsLabel.deleteTitle}
        text={enOrgDataTagsLabel.deleteText}
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleConfirm={deleteDatatag}
      />
      <ManageDataTagCardDrawer
        uid={props.uid}
        isOpen={isManageDataTagOpen}
        onClose={closeManageDataTag}
        dataTag={datatag!}
        toastProps={toastProps!}
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between" flexGrow={0}>
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          flex={1}
        >
          {isCheckboxShown && (
            <>
              <Checkbox
                onChange={onCardSelect}
                checked={isChecked}
              />
              <Divider orientation="vertical" flexItem/>
            </>
          )}
          <Stack direction="column" gap={0} alignItems="flex-start" py={1} pl={1}>
            <Typography variant="h4"
              sx={{
                ...(datatagColor[datatag.color ?? datatagToneColor.tone_0_0] ?? {}),
                px: 1,
                borderRadius: 1,
                fontSize: 13,
                paddingBottom: 0.2,
              }}
            >
              {datatag.name}
            </Typography>
            <TagDescription description={datatag.description}/>
            <Hidden smDown>
              <Avatar
                {...datatag.createdBy}
                sx={{mt: 0.5, height: "1.3rem", width: "1.3rem", mr: 1, borderWidth: 0.5, fontSize: "0.7rem"}}
                nameComponent={(
                  <Typography variant="h5" mt={0.5}>
                    {datatag.createdBy?.name}
                  </Typography>
                )}
              />
            </Hidden>
          </Stack>
        </Stack>
        <Hidden mdDown>
          <Divider orientation="vertical" flexItem sx={{mr: 1.5}}/>
          <Stack direction="row" gap={1} alignItems="center" justifyContent="center" mr={1.5}>
            <Stack direction="column">
              <TagCounter count={counters?.[CounterFields.ProjectsCount] ?? 0} entity={Entity.Project} />
              <TagCounter count={counters?.[CounterFields.AssetsCount] ?? 0} entity={Entity.Asset} />
            </Stack>
            <Stack direction="column">
              <TagCounter count={counters?.[CounterFields.TasksCount] ?? 0} entity={Entity.Task} />
              <TagCounter count={counters?.[CounterFields.FileRequirementsCount] ?? 0} entity={Entity.FileRequirement} />
            </Stack>
            <Stack direction="column">
              <TagCounter count={counters?.[CounterFields.FormRequirementsCount] ?? 0} entity={Entity.FormRequirement} />
              <TagCounter count={counters?.[CounterFields.FormsCount] ?? 0} entity={Entity.Form} />
            </Stack>
            <Stack direction="column">
              <TagCounter count={counters?.[CounterFields.ReferenceFilesCount] ?? 0} entity={Entity.ReferenceFiles} />
              <TagCounter count={counters?.[CounterFields.TemplatesCount] ?? 0} entity={Entity.Templates} />
            </Stack>
          </Stack>
        </Hidden>
        <Divider orientation="vertical" flexItem sx={{mr: 1.5}}/>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={0}>
          <SwiftIconButton
            disabled={!props.canUpdate}
            onClickFcn={openManageDataTag}
            startIcon={SystemIcons.Edit}
          />
          <SwiftIconButton
            disabled={!props.canDelete}
            onClickFcn={() => setIsDeleteDialogOpen(true)}
            startIcon={SystemIcons.DeleteOutlined}
          />
        </Stack>
      </Stack>
    </Box>
  )
}



export default DataTagItem;