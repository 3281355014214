export enum ViewStatus {
    Submit = "Submit",
    Processing = "Processing",
    SecurityError = "SecurityError",
    ValidationError = "ValidationError",
    Error = "Error",
    Delay = "Delay",
    Cancel = "Cancel",
    Cancelled = "Cancelled",
    Submitted = "Submitted",
    Finished = "Finished",
}
