import {Stack, Typography} from "@mui/material";
import {enActivityFeedLabel} from "constants/index";

export default function InviteSentTag() {
  return (
    <Stack height="32px" width="auto" justifyContent="center">
      <Typography variant="h4" sx={{wordBreak: "keep-all"}}>
        {enActivityFeedLabel.inviteSent}
      </Typography>
    </Stack>
  )
}