import {SxProps, Theme, Typography} from "@mui/material";
import React from "react";

interface PageTitleProps {
  title: string;
  count?: number;
  sx?: SxProps<Theme>
}

function PageTitle(props: PageTitleProps) {
  const {title, count = 0, sx} = props;
  const id = title.toLowerCase().replace(" ", "-");

  return (
    <Typography variant="h1" id={`${id}-title`} sx={sx}>
      {title + " "}
      <Typography
        id={`${id}-count`}
        variant="body"
        sx={(theme) => ({
          display: "inline",
          color: theme.palette.text.secondary,
        })}
      >
        {count > 0 ? count : ""}
      </Typography>
    </Typography>
  )
}

export default PageTitle;