import {Timestamp} from "firebase/firestore";

export default function getTwelveHourTime(timeStamp: Timestamp) {
  const date = timeStamp.toDate();
  const hour = date.getHours();
  const minute = date.getMinutes();

  let hourFormatted;
  if (hour === 0) {
    hourFormatted = "12"; // Midnight should be 12 AM
  } else if (hour > 12) {
    hourFormatted = `0${hour - 12}`.slice(-2); // Convert 24-hour format to 12-hour format
  } else {
    hourFormatted = `0${hour}`.slice(-2); // Keep hour as is for AM times
  }

  const minuteFormatted = `0${minute}`.slice(-2);
  return `${hourFormatted}:${minuteFormatted} ${hour >= 12 ? "PM" : "AM"}`;
}
