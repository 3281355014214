import {Tooltip, TooltipProps, Typography, TypographyProps} from "@mui/material";
import React, {ReactNode, useEffect, useRef, useState, RefObject} from "react";
import theme from "theme/theme";

interface LongTextProps extends TypographyProps {
  maxLines?: number;
  maxWidth?: number | string;
  display?: string;
  delay?: number;
  placement?: TooltipProps["placement"]
  maxChar?: number;
  textColor?: string;
  children: ReactNode;
  tooltipText?: string;
  overflowWrap?: string;
  followCursor?: boolean;
  containerRef?: RefObject<any>
  breakAll?: boolean
}

function LongText(props: LongTextProps) {
  const {
    children, delay = 0, placement = "bottom-start", maxLines = 1, textColor,
    tooltipText, overflowWrap, containerRef,
    breakAll = false, ...rest
  } = props;
  const [overFlowing, setOverFlowing] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (containerRef && containerRef.current) {
        const containerBounds = containerRef.current.getBoundingClientRect();
        const tooltipBounds = ref.current?.getBoundingClientRect();
        if (tooltipBounds && (tooltipBounds.top < containerBounds.top || tooltipBounds.bottom > containerBounds.bottom)) {
          setTooltipOpen(false);
        }
      }
    }

    containerRef?.current?.addEventListener('scroll', handleScroll);
    return () => {
      containerRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef, ref]);

  useEffect(() => {
    if (!ref.current)
      return

    if (tooltipText) {
      setOverFlowing(true)
      return;
    }

    const overFlowingCalculation = ref.current?.clientWidth < ref.current?.scrollWidth || ref.current?.clientHeight < ref.current?.scrollHeight
    setOverFlowing(overFlowingCalculation)
  }, [ref, ref.current, ref.current?.children]);

  if (typeof children !== "string") {
    return (
      <Tooltip
        open={tooltipOpen}
        onOpen={() => setTooltipOpen(true)}
        onClose={() => setTooltipOpen(false)}
        enterDelay={overFlowing ? delay : 9999999}
        title={<Typography>{children}</Typography>}
        arrow
        placement={placement}
        enterNextDelay={overFlowing ? delay : 9999999}
        PopperProps={{
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                boundary: containerRef?.current,
              },
            },
          ],
        }}
      >
        <Typography
          ref={ref}
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: maxLines,
            overflow: "hidden",
            textOverflow: overFlowing ? "ellipsis" : "normal",
            WebkitBoxOrient: "vertical",
            maxWidth: props.maxWidth ?? "100%",
            overflowWrap: (overFlowing ? "break-word" : "anywhere"),
            color: textColor ?? theme.palette.text.primary,
            wordBreak: breakAll ? "break-all" : "break-word"
          }}
          {...rest}
          title={undefined}
        >
          {children}
        </Typography>
      </Tooltip>
    )
  }

  return (
    <Typography
      ref={ref}
      sx={{
        display: "-webkit-box",
        WebkitLineClamp: maxLines,
        overflow: "hidden",
        textOverflow: overFlowing ? "ellipsis" : "normal",
        WebkitBoxOrient: "vertical",
        maxWidth: props.maxWidth ?? "100%",
        overflowWrap: overflowWrap || (overFlowing ? "break-word" : "anywhere"),
        color: textColor ?? theme.palette.text.primary,
        wordBreak: breakAll ? "break-all" : "break-word"
      }}
      {...rest}
      title={tooltipText ? tooltipText : children}
    >
      {children}
    </Typography>
  )
}

export default LongText;