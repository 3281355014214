import theme from "theme/theme";
import {Button, IconButton, Popover, Stack} from "@mui/material";
import {enCommonLabel} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import React, {useRef, useState} from "react";
import useComponentToggler from "hooks/useComponentToggler";
import {Box} from "@mui/system";
import {datatagColor, Tone} from "constants/datatagColor";
import {datatagToneColor} from "enums/datatagToneColor";

interface Props {
  setColor: (color: datatagToneColor) => void;
  color?: datatagToneColor;
}

function ColorSelectorButton(props: Props) {
  const {setColor, color = datatagToneColor.tone_0_0} = props;
  const [isOpen, {open, close}] = useComponentToggler(false);
  const boxRef = useRef<Element>();
  const [tone, setTone] = useState<Tone>(datatagColor[color]);

  function onColorSelect(sx: Tone, color: datatagToneColor) {
    setColor(color);
    setTone(sx);
    close();
  }

  return (
    <>
      <Box ref={boxRef}>
        <IconButton
          title={enCommonLabel.chooseColor}
          onClick={open}
          sx={{
            backgroundColor: tone.background,
            borderRadius: 0,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            border: "1px solid #c4c4c4",
            borderLeft: "none",
            height: "100%",
            "&:hover": {
              backgroundColor: tone.background,
              opacity: 0.8
            }
          }}
        >
        <SystemIcons.ColorBucket width={16} height={16} color={tone.color}/>
      </IconButton>
      </Box>

      <Popover
        open={isOpen}
        anchorEl={boxRef.current}
        onClose={close}
        PaperProps={{
          style: {
            width: "280px",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            transform: `translateY(22px)`,
            border: `2px solid ${theme.palette.neutral.medium}`
          },
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Stack
          direction="row"
          gap={0.7}
          justifyContent="space-between"
          flexWrap="wrap"
          sx={{padding: 1}}
        >
          {Object.entries(datatagColor).map(([color, sx]) => (
            <Button
              onClick={() => onColorSelect(sx, color as datatagToneColor)}
              sx={{
                ...sx,
                py: 0.5,
                px: 1.5,
                minWidth: "auto",
                borderRadius: 1,
                fontWeight: 500,
                "&:hover": {
                  background: sx.background,
                  color: sx.color,
                  opacity: 0.8
                }
              }}>a</Button>
          ))}
        </Stack>
      </Popover>
      </>
  )
}

export default ColorSelectorButton;