import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {Stack, Typography} from "@mui/material";
import {enActivityFeedButton} from "constants/index";

function DeclinedTag() {
  return (
    <Stack direction="row" height="32px" width="auto" flex={0} alignItems="center" gap={1}>
      <SystemIcons.Close stroke={theme.palette.error.main} height={16} width={16} strokeWidth={3}/>
      <Typography variant="h4">
        {enActivityFeedButton.declined}
      </Typography>
    </Stack>
  )
}

export default DeclinedTag;