import {Personnel} from "types/Personnel";
import theme from "theme/theme";

export const defaultAssignee: Personnel = {
  isTeam: false,
  name: null,
  initials: "",
  avatarColor: theme.palette.background.paper,
  uid: "",
  id: "",
  active: true
}
