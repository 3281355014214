import {Stack, Typography} from "@mui/material";
import {Entity} from "enums/entity";
import theme from "theme/theme";
import {EmptyList, InProgress} from "components/index";
import EmptySearchResults from "components/EmptySearchResults";
import React from "react";
import {enOrgDataTagsLabel} from "constants/index";

interface PageFooterProps {
  atBottom: boolean;
  isEmpty: boolean;
  isSearchEmpty: boolean;
  isLoading: boolean;
}

function PageFooter(props: PageFooterProps) {
  const {atBottom, isEmpty, isSearchEmpty, isLoading} = props;

  if (isEmpty) {
    return (
      <Stack justifyContent="center" height="80vh" paddingRight={'20%'}>
        <EmptyList
          entity={Entity.DataTag}
          sx={{flex: 1, maxHeight: "65%"}}
          logoProperties={{width: 80, height: 80, fill: theme.palette.neutral.light}}
        />
      </Stack>
    )
  }

  if (isSearchEmpty) {
    return (
      <EmptySearchResults
        entity={Entity.DataTag}
        sx={{marginRight: "20%", flex: 1, marginY: "15%"}}
      />
    )
  }

  if (atBottom) {
    return (
      <div
        style={{
          paddingRight: '20%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="body" textAlign="center" color="text.secondary" paddingY={1}>
          {enOrgDataTagsLabel.noMoreDatatags}
        </Typography>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div
        style={{
          paddingRight: '20%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <InProgress/>
      </div>
    )
  }

  return <div style={{height: 50}}/>;
}

export default PageFooter;