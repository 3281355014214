import React from "react";
import {Stack} from "@mui/material";
import theme from "theme/theme";

interface Props {
  children: React.ReactNode;
}

function ItemContainer({children}: Props) {
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      gap={1}
      sx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
        paddingBottom: 1.5,
        paddingTop: 0.5,
      }}
    >
      {children}
    </Stack>
  );
}

export default ItemContainer;
