import {Permission} from "types/Permission";

export interface ReducerState {
  localPermissions: Permission[] | null;
  searchText: string | null;
  searchResult: Permission[] | null;
}

export const initialState: ReducerState = {
  localPermissions: null,
  searchText: null,
  searchResult: null
}