import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {Avatar, Stack} from "@mui/material";
import {defaultUser, enTaskLabel, unAssigned} from "constants/index";
import {useEffect, useState} from "react";
import {storage} from "../firebase";
import {getDownloadURL, ref} from "firebase/storage";
import EmailTooltip from "components/EmailTooltip";
import getEnvKey from "../screens/utility/getEnvKey";
import {storageBucketKey} from "constants/envKeys";

interface AssignableAvatarProps {
  id?: string;
  avatarColor?: string;
  initials?: string;
  isTeam?: boolean;
  isYou?: boolean;
  avatarPhotoUrl?: string | null;
  name?: string | null;
  sx?: any;
  defaultIcon?: JSX.Element;
  roleText?: string;
  wrapWithTooltip?: boolean;
  // these are props the are used if other info are already fetched, no need to fetch again
  fetchedAvatarUrl?: string | undefined;
  nameComponent?: JSX.Element;
}

function AssignableAvatar(props: AssignableAvatarProps) {
  const {
    id = "",
    avatarColor = unAssigned.avatarColor,
    isTeam = false,
    avatarPhotoUrl,
    defaultIcon = <SystemIcons.User color={theme.palette.neutral.dark} height={12} width={12}/>,
    roleText,
    wrapWithTooltip = true,
    fetchedAvatarUrl,
    nameComponent = null,
  } = props;

  const initials = (props.initials ?? "").trim().toUpperCase();

  const name = props.name ?? unAssigned.name;
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined);
  const storageBucket = getEnvKey(storageBucketKey);

  useEffect(() => {
    // fetchedAvatarUrl is used when the avatar url is already fetched from the database
    if (typeof fetchedAvatarUrl !== "undefined") {
      setAvatarUrl(fetchedAvatarUrl);
      return;
    }

    if (typeof avatarPhotoUrl === "undefined" || avatarPhotoUrl === null || avatarPhotoUrl === "" || !storageBucket) {
      setAvatarUrl(undefined);
      return;
    }

    const previewRef = ref(storage, `${storageBucket}${avatarPhotoUrl}`);
    getDownloadURL(previewRef).then(setAvatarUrl);
  }, [avatarPhotoUrl, fetchedAvatarUrl]);

  if (!wrapWithTooltip) {
    return (
      <Avatar
        className="avatar"
        sx={{
          mt: 0,
          borderWidth: initials === "" ? 0 : "2px",
          bgcolor: avatarColor,
          borderColor: name === enTaskLabel.unassigned || initials === "" ? theme.palette.neutral.light : null,
          borderStyle: "solid",
          ...(props.sx ? props.sx : {}),
        }}
        src={avatarUrl}
        variant={isTeam ? "rounded" : "circular"}
      >
        {name === enTaskLabel.unassigned || initials === "" ?
          defaultIcon
          : initials
        }
      </Avatar>
    )
  }

  const {sx = {}, ...rest} = props;

  return (
    <EmailTooltip
      user={{
        ...defaultUser,
        ...rest,
        name,
        "@id": id || "fillerId",
      }}
      roleText={roleText}
      isTeam={isTeam}
      fetchedAvatarUrl={fetchedAvatarUrl || avatarUrl}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Avatar
          className="avatar"
          sx={{
            mt: 0,
            borderWidth: initials === "" ? 0 : "2px",
            bgcolor: avatarColor,
            borderColor: name === enTaskLabel.unassigned || initials === "" ? theme.palette.neutral.light : null,
            borderStyle: "solid",
            ...sx,
          }}
          src={avatarUrl}
          variant={isTeam ? "rounded" : "circular"}
        >
          {name === enTaskLabel.unassigned || initials === "" ?
            defaultIcon
            : initials
          }
        </Avatar>
        {nameComponent}
      </Stack>
    </EmailTooltip>
  )
}

export default AssignableAvatar;