import {Task} from "types/Task";
import {FiltersState} from "screens/Home/TaskSearchAndFilters";
import {MilestoneAssignedStatus} from "enums/milestoneAssignedStatus";
import {MilestoneScheduleStatus} from "enums/milestoneScheduleStatus";
import {milestoneTaskandAssetStatus as TaskStatus} from "enums/milestoneTaskandAssetStatus";
import {Timestamp} from "firebase/firestore";
import {formatDateTimeFirestoreDate} from "screens/utility";

export function filterTaskBySearch(tasks: Task[], filter: FiltersState, uid: string) {
  return tasks.filter(task => {
    // Check status
    if (filter.status.length > 0 && !filter.status.includes(task.taskStatus)) {
      return false;
    }

    // Check assigned
    const isAssignedFiltered = filter.assigned.some(status => {
      switch (status) {
        case MilestoneAssignedStatus.ToMe: return task.assignedTo.uid === uid;
        case MilestoneAssignedStatus.ByMe: return task.userId.includes(uid);
        case MilestoneAssignedStatus.ToMyTeam: return task.assignedTo.isTeam && (task.assignedTo.teamMemberUids || []).includes(uid);
        case MilestoneAssignedStatus.ToNoOne: return !task.assignedTo.uid;
        case MilestoneAssignedStatus.ToAnyone: return !!task.assignedTo.uid;
        case MilestoneAssignedStatus.ImTheApprover: return task.approver.id === uid;
        case MilestoneAssignedStatus.ImTheObserver: return task.observer.id === uid;
        case MilestoneAssignedStatus.FavoriteTasks: return (task.favoriteUserIds || []).includes(uid);
        default: return false;
      }
    });

    if (filter.assigned.length > 0 && !isAssignedFiltered) {
      return false;
    }

    // Check schedule
    const isScheduleFiltered = filter.schedule.some(status => {
      switch (status) {
        case MilestoneScheduleStatus.PastDue: return !!task.dueDate && task.dueDate < Timestamp.now();
        case MilestoneScheduleStatus.Upcoming: return !!task.dueDate && task.dueDate > Timestamp.now();
        case MilestoneScheduleStatus.NotScheduled: return !task.dueDate;
        case MilestoneScheduleStatus.Scheduled: return !!task.dueDate;
        case MilestoneScheduleStatus.Complete: return task.taskStatus === TaskStatus.Approved;
        default: return false;
      }
    });

    if (filter.schedule.length > 0 && !isScheduleFiltered) {
      return false;
    }

    // Check date filters
    if (!!filter.date && !!task.dueDate) {
      const {monthDigit, day, year} = formatDateTimeFirestoreDate(task.dueDate);
      const newDate = `${year}${monthDigit}${day}`;
      return newDate === filter.date;
    }

    return true;
  });
}
