export function areObjectsEqual(obj1: Object, obj2: Object) {
  const sortedObj1 = sortObjectParams(obj1);
  const sortedObj2 = sortObjectParams(obj2);

  return (JSON.stringify(sortedObj1) === JSON.stringify(sortedObj2));
}

// @ts-ignore
export function sortObjectParams(obj: Object) {
  return Object.keys(obj).sort().reduce(
    (ordered: Record<string, unknown>, key) => {
      // @ts-ignore
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // @ts-ignore
        ordered[key] = sortObjectParams(obj[key]);
      } else {
        // @ts-ignore
        ordered[key] = obj[key];
      }
      return ordered;
    },
    {}
  );
}