import {Input} from "components/index";
import {enCommonLabel} from "constants/index";
import {Typography} from "@mui/material";
import theme from "theme/theme";
import {FormFieldProps} from "../BaseField";
import {FormField} from "types/FormField";
import {FormFieldType} from "enums/formFieldType";

export function DefaultFormField(props: FormFieldProps) {
  const {changeFormData, formField, isEditable, index, onFormKeyPress} = props;
  const {errors} = formField as FormField<FormFieldType.Text>;

  if (!isEditable)
    return (
      <>
        <Typography variant="h3">{formField.title}</Typography>
        <Typography variant="body">{formField.description}</Typography>
      </>
    );

  return (
    <>
      <Input
        onKeyPress={onFormKeyPress}
        sx={{".MuiOutlinedInput-input": {backgroundColor: theme.palette.common.white}}}
        onChange={(e: any) => changeFormData(e.target.value, "title", index, true)}
        value={formField.title}
        label={enCommonLabel.title}
        validationMessage={errors?.title}
      />
      <Input
        sx={{".MuiOutlinedInput-input": {backgroundColor: theme.palette.common.white}}}
        onChange={(e: any) => changeFormData(e.target.value, "description", index, true)}
        value={formField.description}
        label={enCommonLabel.description}
        optional
        onKeyPress={onFormKeyPress}
      />
    </>
  )
}
