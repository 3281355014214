import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import React from "react";
import {ButtonProps} from "types/ButtonProps";
import {enCommonButton} from "constants/index";
import {LoadingButton} from "@mui/lab";

interface DownloadButtonProps extends ButtonProps {
  isLoading: boolean;
  isImportAvailable: boolean;
}

function ImportButton(props: DownloadButtonProps) {
  const {id = "import-button", isLoading, startIcon = SystemIcons.Upload, isImportAvailable, disabled, onClickFcn, label} = props;

  const displayedIcon = isImportAvailable && !isLoading ?
    <SystemIcons.Upload stroke={theme.palette.background.paper}/>
    : startIcon({fill: theme.palette.primary.main});

  const buttonVariant = isImportAvailable || isLoading ? "contained" : "outlined";
  return (
    <LoadingButton
      variant={buttonVariant}
      sx={{flex: 1}}
      disabled={isLoading || disabled}
      loading={isLoading}
      onClick={onClickFcn}
      id={id}
      startIcon={displayedIcon}
    >
      {isImportAvailable ? `${enCommonButton.upload} ${label}` : label}
    </LoadingButton>
  )
}

export default ImportButton;