import React, {useEffect, useRef, useState} from 'react';
import {Box, Divider, IconButton, Stack} from "@mui/material";
import {emptyFunction, enChatLabel} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import DrawerFieldsWrapper from "components/DrawerFieldsWrapper";
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  getDoc,
  Timestamp
} from "firebase/firestore";
import {createPreviewFile, getFileType, submitForm} from "screens/utility";
import {
  ActionType,
  CounterFields,
  Entity,
  MessageType,
  Previews,
  Severity,
  ViewStatus
} from "enums/index";
import {useDebounce, useUser} from "hooks/index";
import {OverFlowBox} from "components/index";
import {VirtuosoHandle} from "react-virtuoso";
import {ChatMessage} from "types/index";
import ChatAttachmentPreview from "components/ChatDrawer/ChatAttachments/ChatAttachmentPreview";
import {Mention, MentionItem, MentionsInput} from 'react-mentions';
import TypingLabel from "components/ChatDrawer/Objects/TypingLabel";
import {createPortal} from "react-dom";
import {UnsentMessage} from "components/ChatDrawer/Types/UnsentMessageType";
import {TempFile} from "components/ChatDrawer/Types/TempFile";
import {BaseProps} from "screens/BaseProps";
import HasNewChatIndicator from "components/ChatDrawer/Objects/HasNewChatIndicator";
import {generateMentionables} from "components/ChatDrawer/utility/generateMentionables";
import SendChatButton from "components/ChatDrawer/Objects/SendChatButton";
import {getAccessList} from "components/ChatDrawer/utility/getAccessList";
import {statusSubmitHandler} from "../../screens/utility/statusSubmitHandler";
import useFileUpload, {UploadFile} from "hooks/useFileUpload/useFileUpload";
import {generateUniqueId} from "hooks/useFileUpload/reducer/reducer";
import MentionSuggestionItem from "components/ChatDrawer/Objects/MentionSuggestionItem";
import templateSubmitForm, {
  doesDocExist,
  getTemplateCopyPath
} from "../../screens/utility/templateSubmitForm";
import {db} from "../../firebase";
import useTemplateAccessList from "hooks/useTemplateAccessList";

interface FooterProps extends BaseProps {
  colRef: CollectionReference;
  entity: Entity;
  setLastSentMessage: (message: UnsentMessage) => void;
  virtuosoRef: React.RefObject<VirtuosoHandle>;
  messagesLength: number;
  displayNewChatIndicator: boolean;
  lastUnreadMessageIndex: number;
}

function Footer(props: FooterProps) {
  const {colRef, uid, virtuosoRef, messagesLength, entity, displayNewChatIndicator, lastUnreadMessageIndex} = props;
  const {setLastSentMessage, toastProps} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  const userData = useUser(uid);

  // since this path is collection, remove last part
  const templateCopyPath = getTemplateCopyPath(colRef.path).split("/").slice(0, -1).join("/");
  const [fromTemplates, setFromTemplates] = useState<boolean>(false);

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [textFieldValue, setTextFieldValue] = useState<string>("");
  const [userIdsMentioned, setUserIdsMentioned] = useState<string[]>([]);

  const [filesList, setFilesList] = useState<TempFile[]>([]); // list of files during selection
  const [renderedAttachments, setRenderedAttachments] = useState<JSX.Element[]>([]);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const {accessList: mentionables} = useTemplateAccessList(getAccessList(entity, colRef).path)

  // fetch the actual doc path used
  useEffect(() => {
    checkIfFromTemplates();
  }, [colRef.path]);

  async function checkIfFromTemplates() {
    const currentColRefExists = await fetchColRefExists(colRef.parent!.path);
    if (currentColRefExists) {
      setFromTemplates(false);
      return;
    }

    const templateColRefExists = await fetchColRefExists(templateCopyPath);
    if (templateColRefExists) {
      setFromTemplates(true);
      return;
    }
  }

  async function fetchColRefExists(ref: string) {
    const exists = await doesDocExist(ref);
    return exists;
  }

  const typingDocRef = doc(colRef, uid!);
  const suggestionsRef = useRef<Element>();

  const {addFiles} = useFileUpload({toastProps: toastProps!, uid: uid!});

  // typing indicator
  useDebounce(async () => {
    if (!userData) return;

    // if from templates, abort sending typing doc
    if (fromTemplates) return;

    await submitForm(typingDocRef,
      !!typingDocRef ? ActionType.Create : ActionType.Update,
      emptyFunction,
      {
        type: MessageType.Typing,
        sender: {
          name: userData!.name,
          uid: uid!,
          initials: userData!.initials,
          avatarColor: userData!.avatarColor
        },
        timestamp: Timestamp.now(),
      });
  }, 300, [textFieldValue], true);

  useEffect(() => {
    setRenderedAttachments([]);
    filesList.forEach((fileObj) => {
      const {id, contentType, "#previewValue": source = "", name = ""} = fileObj;
      (contentType === Previews.Image) ? addRenderAttachment(source, id, name)
        : addRenderAttachment(contentType, id, name);
    });
  }, [filesList]);

  function addRenderAttachment(source: string, id: string, name: string) {
    setRenderedAttachments(prev => [
      ...prev,
      <ChatAttachmentPreview
        id={id}
        name={name}
        source={source}
        onDelete={deleteAttached}
      />
    ]);
  }

  function handleTextFieldChange(newValue: string, mentions: MentionItem[]) {
    if(isLoadingState)
      return;

    setTextFieldValue(newValue)
    const mentionIds = mentions.map((mention) => mention.id);
    setUserIdsMentioned(mentionIds);
  }

  function resetInputs() {
    setIsLoadingState(false);
    setTextFieldValue("");
    setFilesList([]);
    setUserIdsMentioned([]);
  }

  async function sendMessage() {
    const docRef = doc(colRef);
    if (!docRef) return;
    setIsLoadingState(true);

    const message: ChatMessage = {
      type: MessageType.Message,
      text: textFieldValue,
      sender: {
        name: userData!.name,
        id: uid!,
        uid: uid!,
        initials: userData!.initials,
        avatarColor: userData!.avatarColor
      },
      timestamp: Timestamp.now(),
      hasAttachments: filesList.length > 0,
      userIdsMentioned,
      [CounterFields.AttachmentsCount]: filesList.length
    }

    setLastSentMessage({
      "@id": docRef.id,
      "@form": {
        "@status": ViewStatus.Submitted,
        ...message,
      },
      ...message,
      uploadedFiles: filesList,
    });

    await templateSubmitForm(docRef.path, ActionType.Create,
      (status, data, isLastUpdate) => statusSubmitHandler({
        status, data, isLastUpdate,
        successCallback: () => successCallback(messagesLength, filesList, docRef),
        errorCallback
      }),
      message
    );

    resetInputs();
  }

  async function successCallback(length: number, filesList: TempFile[], docRef: any) {
    // once sent, automatically scroll to the last sent
    scrollToIndex(length);
    await triggerStartFileUploads(filesList, docRef);
    resetInputs();
  }

  function errorCallback(message: any) {
    setToastMessage(message || "Error sending message");
    setIsLoadingState(false);
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
  }

  async function triggerStartFileUploads(files: TempFile[], reference: DocumentReference) {
    if (files.length === 0) return;

    const fileList: UploadFile[] = files.map((fileObj, index) => ({
        ...fileObj,
        queueId: generateUniqueId()+index,
        parentCollectionPath: collection(reference, "files").path,
        timeCreated: Timestamp.now(),
        overwrite: false,
      }));

    if (!fromTemplates) {
      await addFiles(fileList);
      return;
    }

    // if from templates, create the parent doc
    if (fromTemplates) {
      const parentDoc = await getDoc(doc(db, templateCopyPath));
      await templateSubmitForm(
        colRef.parent!.path,
        ActionType.Create,
        (status, data, isLastUpdate) => statusSubmitHandler({
          status, data, isLastUpdate,
          successCallback: async() => await addFiles(fileList),
        }),
        {...(parentDoc.data() || {})}
      );
      return;
    }
  }

  function onAttachFileButtonClick() {
    fileInputRef!.current!.click();
  }

  function deleteAttached(id: string) {
    const newFiles = filesList.filter(files => files.id !== id);
    setFilesList(newFiles);
  }

  async function onFileInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const targetFiles = e.target.files;

    if (targetFiles && targetFiles[0]!) {
      const filesCopy: TempFile[] = await Promise.all(
        Array.from(targetFiles).map(async targetFile => {
          const contentType = getFileType(targetFile.type);
          const previewValue = contentType === Previews.Image ? await createPreviewFile(targetFile) : undefined;

          return {
            name: targetFile.name,
            file: targetFile,
            id: randomId(),
            contentType: contentType,
            "#previewValue": previewValue,
          }
        }));
      const currentFilesList = [...filesList, ...filesCopy];
      setFilesList(currentFilesList);

      fileInputRef!.current!.value = "";
    }
  }

  function randomId() {
    return Math.floor(Math.random() * 16777215).toString(16).toString();
  }

  function scrollToIndex(index: number) {
    virtuosoRef.current?.scrollToIndex({
      index,
      behavior: "smooth"
    })
  }

  async function onTextFieldPressEnter(e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) {
    if (e.key === "Enter" && !e.shiftKey && (!!textFieldValue.trim() || filesList.length)) {
      e.preventDefault();
      e.stopPropagation();
      await sendMessage();
    }
  }

  return (
    <>
      <TypingLabel colRef={colRef}/>
      <DrawerFieldsWrapper
        minHeight={
          renderedAttachments.length > 0 ?
            renderedAttachments.length > 5 ?
              "284px" : "220px" :
            "150px"
        }
        maxHeight={renderedAttachments.length > 0 ?
          renderedAttachments.length > 5 ?
            "284px" : "220px" :
          "150px"}
        gap={0}
        sx={{padding: 0}}
        id="chatInputContent"
        flex={1}>
        <Box
          ref={suggestionsRef}
          sx={{
            position: "absolute",
            bottom: "8rem",
            left: "0.5rem",
          }}
        >
        </Box>
        <Stack height="100%" justifyContent="flex-end">
          <HasNewChatIndicator
            uid={props.uid}
            colRef={colRef}
            entity={entity}
            displayNewChatIndicator={displayNewChatIndicator}
            lastUnreadMessageIndex={lastUnreadMessageIndex}
          />
          <Divider/>
          {(renderedAttachments.length > 0) && (
            <OverFlowBox
              sx={{
                backgroundColor: theme.palette.neutral.light
              }}
            >
              <Stack
                p={1}
                gap={0.7}
                direction="row"
                maxHeight={164}
                flexWrap="wrap"
                justifyContent={renderedAttachments.length >= 5 ? "center" : "flex-start"}
              >
                {renderedAttachments}
              </Stack>
            </OverFlowBox>
          )}
          <MentionsInput
            onKeyDown={onTextFieldPressEnter}
            style={{
              flex: 1,
              lineHeight: 1,
              overflowY: "hidden",
              padding: "15px",
              "input": {
                overflow: "auto",
                borderWidth: 0,
                padding: "1rem",
                lineHeight: 1,
                color: isLoadingState ? theme.palette.text.disabled : theme.palette.text.primary
              },
              "highlighter": {
                overflow: "inherit",
              },
            }}
            id="mentionsInput"
            placeholder={enChatLabel.typeAMessage}
            value={textFieldValue}
            onChange={(e, _a, _b, mentions) => handleTextFieldChange(e.target.value, mentions)}
            customSuggestionsContainer={(children) => {
              return createPortal(
                <OverFlowBox
                  sx={{
                    padding: "0.75rem",
                    backgroundColor: theme.palette.background.swiftDefault,
                    borderRadius: "8px",
                    width: "300px",
                    boxShadow: `2px 2px 4px 1px ${theme.palette.neutral.light}`,
                    overflowX: "hidden",
                  }}
                >
                  <Stack
                    sx={{
                      maxHeight: "12.8rem",
                      listStyleType: "none"
                    }}
                  >
                    {children}
                  </Stack>
                </OverFlowBox>, suggestionsRef.current!
              )
            }}
            allowSpaceInQuery
          >
            <Mention
              trigger="@"
              data={generateMentionables(mentionables)}
              displayTransform={(_, display) => `@${display}`}
              style={{
                color: theme.palette.primary.main,
                zIndex: 1,
                position: "relative",
                backgroundColor: "rgba(37, 150, 190, 0.1)",
              }}
              renderSuggestion={(suggestion) => {
                const currentSuggestion = (mentionables ?? []).find((user) => user["@id"] === suggestion.id)!;
                return <MentionSuggestionItem suggestion={suggestion} currentSuggestion={currentSuggestion} mentionablesLength={(mentionables ?? []).length} />
                }
              }
              appendSpaceOnAdd
            />
          </MentionsInput>
          <Stack direction="row" justifyContent="space-between">
            <IconButton color="primary" onClick={onAttachFileButtonClick}>
              <SystemIcons.Attach stroke={theme.palette.text.secondary}/>
            </IconButton>
            <SendChatButton
              disabled={(textFieldValue.trim().length < 1 && (filesList).length < 1) || isLoadingState}
              isLoadingState={isLoadingState}
              sendMessage={sendMessage}
            />
          </Stack>
        </Stack>
      </DrawerFieldsWrapper>
      <input
        id="file"
        type="file"
        ref={fileInputRef}
        style={{display: "none"}}
        onChange={onFileInputChange}
        multiple={true}
      />
    </>
  )
}

export default Footer;
