import {
  Box,
  Divider,
  Drawer as MuiDrawer,
  Stack,
  Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useEffect, useState} from "react";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import AssignedItem from "./AssignedItem";
import {Personnel, AccessListUser} from "types/index";
import {LoadingButton} from "@mui/lab";
import {defaultAssignee, enTaskLabel, enCommonButton} from "constants/index";
import {useCollection} from "hooks/index";
import {CollectionReference, where} from "firebase/firestore";
import {AccessUserType} from "enums/accessUserType";
import EmptySearchResults from "components/EmptySearchResults";
import AssignedItemList from "components/AssigneeSelectionDrawer/AssignedItemList/AssignedItemList";
import {Entity} from "enums/entity";
import SmartSearchInput from "components/inputs/SmartSearchInput";
import {
  algoliaAssetAccessListPath,
  algoliaProjectLevelAccessListPath,
} from "screens/utility/algoliaColPath";
import {useParams} from "react-router-dom";

// ----------------------
// INTERFACE DEFINITIONS
// ----------------------
interface AssigneeSelectionDrawerProps {
  drawerLabel: string;
  isDrawerOpen: boolean;
  taskAssignee: Personnel;
  setter: (obj: Personnel) => void;
  closeDrawer: () => void;
  setAssignedTo?: (obj: Personnel) => void;
  onSaveClick: () => any;
  accessListRef: CollectionReference;
}

// Query constraints
const activeConstraint = where("active", "==", true);

// ----------------------
// MAIN COMPONENT
// ----------------------
function BulkAssigneeSelectionDrawer(props: AssigneeSelectionDrawerProps) {
  // Retrieving URL parameters
  const {orgId, projId, assetId} = useParams();

  // Determining Algolia collection path based on assetId
  const algoliaColPath = assetId ? algoliaAssetAccessListPath(orgId!, projId!, assetId!) : algoliaProjectLevelAccessListPath(orgId!, projId!)

  // State management
  const [algoliaPersonnel, setAlgoliaPersonnel] = useState<AccessListUser[] | null>([])
  const {isDrawerOpen, taskAssignee, drawerLabel, accessListRef, onSaveClick} = props;
  const {setter, closeDrawer, setAssignedTo} = props;
  const [assigned, setAssigned] = useState<Personnel>(taskAssignee);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  // Fetching personnel and teams from Firestore
  const [personnel] = useCollection<AccessListUser>(
    null,
    accessListRef,
    [where("accessUserType", "==", AccessUserType.Person), activeConstraint]
  );
  const [teams] = useCollection<AccessListUser>(
    null,
    accessListRef,
    [where("accessUserType", "==", AccessUserType.Team), activeConstraint]
  );

  // Helper functions for rendering
  function getTeamsToRender() {
    if (algoliaPersonnel)
      return algoliaPersonnel.filter((personnel) => personnel?.accessUserType === AccessUserType.Team && personnel.active)

    return teams || [];
  }

  function getPeopleToRender() {
    if (algoliaPersonnel) {
      return algoliaPersonnel.filter((personnel) => personnel?.accessUserType === AccessUserType.Person && personnel.active)
    }

    return personnel ?? []
  }

  function checkNoSearchResults() {
    if (!algoliaPersonnel)
      return false;

    return algoliaPersonnel.length <= 0;
  }

  function closeAssignedToDrawer() {
    setAssigned(defaultAssignee);
    closeDrawer();
  }

  function handleOnSaveClick() {
    setIsLoading(true);
    onSaveClick();
  }

  // Effects to handle drawer lifecycle and item assignment
  useEffect(() => {
    // Reset the drawer state when it's opened
    setIsLoading(false);
    setDisabled(true);
    setAssigned(defaultAssignee);
  }, [isDrawerOpen])

  useEffect(() => {
    // Handle item selection
    if (assigned !== null) {
      setter(assigned);
      if (setAssignedTo !== undefined) {
        setAssignedTo(assigned);
      }

      setDisabled(false);
    }
  }, [assigned]);

  // Render logic for the component
  return (
    <MuiDrawer id="manage-access-drawer" anchor="right" open={isDrawerOpen} onClose={closeAssignedToDrawer}>
      <BoxMain gap={3}>
      
        <Stack direction="row" justifyContent="flex-start" alignItems="center" alignContent="center">
          <SystemIcons.ArrowLeft
            onClick={closeAssignedToDrawer}
            height={24}
            width={24}
            color={theme.palette.neutral.dark}/>
          <Typography
            variant="h4"
            sx={{alignSelf: "flex-start", marginLeft: 2, color: theme.palette.secondary.main}}>
            {drawerLabel}
          </Typography>
        </Stack>
        <Stack>
          <SmartSearchInput<AccessListUser>
            id="milestone-create-task-search-assigned"
            placeholder={enTaskLabel.searchAssignee}
            sx={{minWidth: "310px"}}
            resultCallback={setAlgoliaPersonnel}
            colPath={algoliaColPath}
          />
        </Stack>

        <AssignedItem
          assigned={defaultAssignee}
          isSelected={taskAssignee.name === null}
          updateSelected={() => setAssigned(defaultAssignee)}/>
        <Divider/>
        {checkNoSearchResults() && <EmptySearchResults entity={Entity.TeamsAndPeople}/>}

        {!checkNoSearchResults() && <>
          <AssignedItemList
            personnelList={getTeamsToRender()}
            assigned={assigned}
            updateSelected={(team) => setAssigned(team)}
            label={enTaskLabel.teams}
            isListTeams={true}
          />
          <AssignedItemList
            personnelList={getPeopleToRender()}
            assigned={assigned}
            updateSelected={(person) => setAssigned(person)}
            label={enTaskLabel.people}
            isListTeams={false}
          />
        </>}
      </BoxMain>
      <Box sx={{padding: "7%", paddingTop: 0}}>
        <Divider sx={{mb: 1}}/>
        <Box sx={{display: "flex", justifyContent: "flex-end", gap: 1}}>
          <LoadingButton
            onClick={handleOnSaveClick}
            loading={isLoading}
            disabled={disabled} 
            variant="contained">
            {enCommonButton.save}
          </LoadingButton> 
        </Box>
      </Box>
    </MuiDrawer>
  )
}

// ----------------------
// STYLING
// ----------------------

const BoxMain = styled(Box)({
  height: "100%",
  width: "360px",
  display: "flex",
  flexDirection: "column",
  padding: "7%",
  overflowX: "hidden",
  overflowY: "scroll"
});


// ----------------------
// EXPORTS
// ----------------------

export default BulkAssigneeSelectionDrawer;