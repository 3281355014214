import {PermissionOperationKey} from "types/Permission";

/**
 * The list of operations that are displayed listed in an order in which
 * they are displayed in the permissions table.
 */
export const PERMISSION_ORDER = [
  PermissionOperationKey.View,
  PermissionOperationKey.Create,
  PermissionOperationKey.Update,
  PermissionOperationKey.Delete,
  PermissionOperationKey.ChangeStatus,
  PermissionOperationKey.OverrideStatus,
  PermissionOperationKey.ManageOwner,
  PermissionOperationKey.ManageAdmin,
  PermissionOperationKey.ManagePowerUser,
  PermissionOperationKey.ManageUser,
  PermissionOperationKey.ManageGuest,
];