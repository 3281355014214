import {toastProps} from "screens/BaseProps";
import {Milestone} from "types/Milestone";
import React, {useEffect, useRef, useState} from "react";
import theme from "theme/theme";
import {Hidden, IconButton, Stack} from "@mui/material";
import {useDocument, useRTDBDocField} from "hooks/index";
import {CounterFields, Entity} from "enums/index";
import {CreateTaskDrawer, ManageMilestoneDrawer} from "components/Drawers";
import {collection, doc} from "firebase/firestore";
import {db} from "../../../firebase";
import {LongText, SkeletonItem} from "components/index";
import MilestoneCounterLabel from "components/MilestoneView/MilestoneCounterLabel";
import {SwiftIconButton} from "components/Button";
import {SystemIcons} from "assets/icons/system/system.index";
import MilestoneMenu from "components/MilestoneView/MilestoneMenu";
import {AccessType, PermissionOperationKey} from "types/Permission";
import MilestoneTaskList from "./MilestoneTaskList";
import {Task} from "types/Task";
import EmptyItem from "components/MilestoneView/EmptyItem";

export interface MilestoneData {
  "@id": string;
  name: string;
  docColRef?: string
}

interface MilestoneItemProps {
  milestoneId: string;
  milestonesCollectionRefPath: string;
  toastProps: toastProps;
  uid: string;
  isExpanded: boolean;
  displayedTasks: { taskId: string; milestoneId: string }[];
  showHidden: boolean;
  expandMilestone: () => void;
  milestoneAccess: AccessType | null;
  isCheckboxShown: boolean;
  checkedTasks: { taskId: string; milestoneId: string }[];
  checkTask: (taskId: string, milestoneId: string) => void;
  showDeleteDialog: (milestone: Milestone) => void;
  deleteTask: (task: Task) => void;
  fromSearch: boolean;
}

function MilestoneItem(props: MilestoneItemProps) {
  const {milestoneId, isExpanded, milestonesCollectionRefPath, milestoneAccess} = props;
  const milestoneItemRef = useRef(null);

  const [milestoneData, setRef, ref] = useDocument<Milestone>(null);

  const milestonePath = `${milestonesCollectionRefPath}/${milestoneData?.["@id"] || milestoneId}`;
  const tasksCount = useRTDBDocField<number>(milestonePath, CounterFields.TasksCount) ?? 0;
  const tasksApprovedCount = useRTDBDocField<number>(milestonePath, CounterFields.TasksApprovedCount) ?? 0;

  const [isCreateTaskFormOpen, setIsCreateTaskFormOpen] = useState<boolean>(false);
  // anchor element for the menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMilestoneOpen, setIsMilestoneOpen] = useState(false);

  useEffect(() => {
    if (!milestoneId) return;
    setRef(doc(db, milestonesCollectionRefPath, milestoneId));
  }, [milestoneId, milestonesCollectionRefPath]);

  function onMenuButtonClick(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }

  function onCloseMenu(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    setAnchorEl(null);
  }

  // while still fetching milestone data
  if (milestoneData === null) {
    return <SkeletonItem height={"3rem"} />
  }

  // if for some reason milestone data is not found
  if (milestoneData === undefined) {
    return null;
  }

  return (
    <div ref={milestoneItemRef}>
      <CreateTaskDrawer
        parentEntity={Entity.Templates}
        collectionRef={collection(db, milestonesCollectionRefPath)}
        milestone={milestoneData!}
        onDrawerClose={() => setIsCreateTaskFormOpen(false)}
        isDrawerShown={isCreateTaskFormOpen && !!milestoneData}
        toastProps={props.toastProps!}
        uid={props.uid}
      />
      <Stack
        direction="column"
        gap={0}
        marginY={0.5}
        sx={{
          bgcolor: theme.palette.background.swiftDefault,
          padding: 1,
          borderRadius: 1,
          position: "relative",
          overflow: "inherit",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{width: 1, cursor: 'pointer', flex: 1}}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            props.expandMilestone();
          }}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            flex={1}
            gap={1}
          >
            <LongText variant="h4" sx={{ml: 1}} maxLines={1}>
              {milestoneData.name}
            </LongText>
            <Stack direction="row" gap={1} width={{lg:"60%"}} alignItems="baseline">
              <MilestoneCounterLabel
                tasksApprovedCount={tasksApprovedCount || 0}
                tasksCount={tasksCount || 0}
              />
              {!!milestoneData.description && (
                <Hidden lgDown>
                  <LongText maxLines={1} width={"100%"}>
                    {milestoneData.description}
                  </LongText>
                </Hidden>
              )}
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Hidden smDown>
              <SwiftIconButton
                onClickFcn={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  milestoneAccess?.[PermissionOperationKey.Create] && setIsCreateTaskFormOpen(true);
                }}
                id="milestone-item-add-task-button"
                disabled={!Boolean(milestoneAccess?.[PermissionOperationKey.Create])}
                startIcon={(props) => <SystemIcons.PlusCircle stroke={theme.palette.primary.main} {...props} />}
              />
            </Hidden>
            <SwiftIconButton
              onClickFcn={onMenuButtonClick}
              id={`milestoneMenuBtn_${milestoneId}`}
              disabled={Boolean(milestoneData?.building)}
              className="milestone-item-menu-button"
              startIcon={(props) => <SystemIcons.MoreVertical stroke={theme.palette.neutral.dark} {...props} />}
            />
            <Hidden smDown>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.expandMilestone();
                }}
                id="milestone-item-expand-button">
                {isExpanded ?
                  <SystemIcons.ChevronUp stroke={theme.palette.neutral.dark}/>
                  : <SystemIcons.ChevronDown stroke={theme.palette.neutral.dark}/>}
              </IconButton>
            </Hidden>
          </Stack>
        </Stack>
        {((tasksCount || 0) === 0) ? <EmptyItem/> : (
          <MilestoneTaskList
            milestoneDocumentRef={ref?.path!}
            toastProps={props.toastProps!}
            isExpanded={isExpanded}
            isCheckboxShown={props.isCheckboxShown}
            uid={props.uid}
            checkedTasks={props.checkedTasks}
            checkTask={props.checkTask}
            showHidden={props.showHidden}
            taskAccess={milestoneAccess}
            displayedTasks={props.displayedTasks}
            tasksCount={tasksCount || 0}
            deleteTask={props.deleteTask}
            fromSearch={props.fromSearch}
          />
        )}
      </Stack>
      <MilestoneMenu
        uid={props.uid}
        anchorEl={anchorEl}
        id={milestoneId}
        toastProps={props.toastProps!}
        closeMenu={onCloseMenu}
        openEditMilestone={() => {
          setIsMilestoneOpen(true);
          setAnchorEl(null);
        }}
        openDeleteMilestoneDialog={() => {
          props.showDeleteDialog(milestoneData!);
          setAnchorEl(null);
        }}
        open={Boolean(anchorEl)}
        parentEntity={Entity.Templates}
        milestoneAccess={milestoneAccess}
        filesCount={1}
        readOnly={typeof milestoneData.templateMilestoneId === "string"}
      />
      <ManageMilestoneDrawer
        uid={props.uid}
        collectionRef={collection(db, milestonesCollectionRefPath)}
        isDrawerOpen={isMilestoneOpen && !!milestoneData}
        onClose={() => setIsMilestoneOpen(false)}
        milestone={milestoneData!}
        toastProps={props.toastProps!}
        isEdit={true}
      />
    </div>
  )
}

export default MilestoneItem;