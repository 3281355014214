import {FormFieldProps} from "./FormFieldProps";
import UnEditedBaseField from "./UnEditedBaseField";
import {enCommonLabel} from "constants/index";
import React, {useEffect, useState} from "react";
import {Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import Input from "components/Input";
import FormFieldContainer from "./FormFieldContainer";
import {isFormFieldValid} from "../utils";
import {FormFieldType} from "enums/formFieldType";
import {FormField} from "types/FormField";

interface ParagraphFormFieldProps extends FormFieldProps {
  field: FormField<FormFieldType.Paragraph>
}
function ParagraphFormField(props: ParagraphFormFieldProps) {
  const {isEditMode, field, index, updateLocalField} = props;
  const {value = "", maxChars = 0} = field;

  const fieldId = `paragraph-field-${index}`;

  const [text, setText] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    setErrorMessage("");
  }, [isEditMode]);

  useEffect(() => {
    if (text === value || text === null) return;
    updateLocalField(text, index);

    const [, message] = isFormFieldValid({...field, value: text});

    setErrorMessage(message);
  }, [text]);

  useEffect(() => {
    if (typeof value === "object") {
      setText("");
      return;
    }
    setText(value);
  }, [value]);

  if (!isEditMode) {
    return (
      <UnEditedBaseField
        fieldId={fieldId}
        defaultValue={enCommonLabel.none}
        {...field}
      />
    )
  }

  function onInputChange(e:  React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | React.ClipboardEvent<HTMLDivElement>) {
    const el = e.target as HTMLInputElement;
    const value = el.value;

    if(maxChars > 0) {
      if(value.length > maxChars) {
        setText(text);
        return;
      }

      setText(value);
      return;
    }

    setText(value);
  }

  return (
    <FormFieldContainer fieldId={fieldId} {...field}>
      <Stack direction="row">
        <Stack direction="column">
          <Input
            value={text ?? ""}
            maxRows={10}
            minRows={4}
            onChange={onInputChange}
            validationMessage={errorMessage}
            multiline
            textfieldSx={{width: "300px", backgroundColor: theme.palette.background.paper}}
          />
          {maxChars > 0 && (
            <Typography
              sx={{
                mt: 0.5,
                minHeight: "1em",
                color: theme.palette.neutral.dark,
                alignSelf: "flex-start",
                width: "max-content",
                fontSize: "12px",
                lineHeight: "20px",
                ml: 1,
              }}
            >
              {`${value.length}/${maxChars} characters`}
            </Typography>
          )}
        </Stack>
      </Stack>
    </FormFieldContainer>
  )
}

export default ParagraphFormField;