import {SVGProps} from "react";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";

const Home = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 20.065v-5a3 3 0 0 0-6 0v5H2a2 2 0 0 1-2-2V7.197a2 2 0 0 1 .971-1.715l8-4.8a2 2 0 0 1 2.058 0l8 4.8A2 2 0 0 1 20 7.197v10.868a2 2 0 0 1-2 2h-5Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
)

const Chat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19 9.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.379 8.379 0 0 1-3.8-.9L1 19l1.9-5.7A8.38 8.38 0 0 1 2 9.5a8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5Z"
      fill={props.fill ?? "#858585"}
      stroke={props.fill ?? "#858585"}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const ActivityFeed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.243 1.757A6 6 0 0 1 15 6c0 7 3 9 3 9H0s3-2 3-9a6 6 0 0 1 10.243-4.243ZM8.135 18.498a1 1 0 0 0-1.73 1.004 3 3 0 0 0 5.19 0 1 1 0 0 0-1.73-1.004 1 1 0 0 1-1.73 0Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
)

const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.333 8h9.333M8 3.333 12.667 8 8 12.667"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const Folder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 16"
    {...props}
  >
    <path
      d="M10.83 2H17a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h5c1.306 0 2.417.835 2.83 2Z"
      fill={props.fill ?? "#FFF"}
    />
  </svg>
)

const Layers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={24}
    fill="none"
    viewBox="0 0 22 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.114 8.284a1 1 0 0 1 0 1.6l-2.058 1.543a1 1 0 0 0 0 1.6l2.058 1.544a1 1 0 0 1 0 1.6l-9.513 7.133a1 1 0 0 1-1.2 0L.887 16.17a1 1 0 0 1 0-1.6l2.058-1.544a1 1 0 0 0 0-1.6L.887 9.884a1 1 0 0 1 0-1.6l9.514-7.133a1 1 0 0 1 1.2 0l9.513 7.133Zm-9.94 12.819 7.681-5.732-2.441-1.802-5.24 3.898-5.239-3.93-2.44 1.834 7.68 5.732Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
)

const GroupPeople = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 8a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM20 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM4 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM6 8.676c-.968-.774-1.845-.672-2.964-.672C1.362 8.004 0 9.326 0 10.95v4.765c0 .705.59 1.278 1.315 1.278 3.131 0 2.754.056 2.754-.131 0-3.377-.41-5.853 1.931-8.185ZM13.07 9.04c-1.937-.122-3.62 0-5.072.905-2.43 1.467-1.962 3.443-1.962 5.86 0 .64.69 1.17 1.551 1.17 9.348 0 9.72.228 10.275-.698.182-.313.132-.214.132-3.209 0-2.379-2.732-4.029-4.925-4.029ZM20.964 8.004c-1.125 0-1.997-.101-2.964.672 2.323 2.315 1.931 4.622 1.931 8.184 0 .189-.313.132 2.707.132.751 0 1.362-.594 1.362-1.324v-4.72c0-1.623-1.362-2.944-3.036-2.944Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
)

const Calendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 3.5v2a2 2 0 1 0 4 0v-2h6v2a2 2 0 1 0 4 0v-2a3 3 0 0 1 3 3v2H0v-2a3 3 0 0 1 3-3Zm17 5v8a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-8h20Zm-5-8a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1ZM5 .5a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
)

const File = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0h7l7 7v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2Zm12 7H9V2l1.875 1.875L11 4l1 1 2 2Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
)

const AddFile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z"
      stroke={props.stroke ?? "#1D1D1E"}
      strokeWidth={props.strokeWidth ?? "2"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 2V8H20"
      stroke={props.stroke ?? "#1D1D1E"}
      strokeWidth={props.strokeWidth ?? "2"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 18V12"
      stroke={props.stroke ?? "#1D1D1E"}
      strokeWidth={props.strokeWidth ?? "2"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 15H15"
      stroke={props.stroke ?? "#1D1D1E"}
      strokeWidth={props.strokeWidth ?? "2"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>

  /*<svg
    width={16}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0h7v6a1 1 0 0 0 1 1h6v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2Zm9 1 4 4h-4V1ZM8 9a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2H9v2a1 1 0 1 1-2 0v-2H5a1 1 0 1 1 0-2h2v-2a1 1 0 0 1 1-1Z"
      fill={props.fill ?? "#858585"}
      stroke={props.stroke ?? "#1D1D1E"}
    />
  </svg>*/
)

const Clock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10Zm-9-6a1 1 0 1 0-2 0v6a1 1 0 0 0 .553.894l4 2a1 1 0 1 0 .894-1.788L11 9.382V4Z"
      fill="#858585"
    />
  </svg>
)

const Wrench = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m.474 16.032 8.29-8.29c.317.795.781 1.5 1.394 2.112a6.208 6.208 0 0 0 2.112 1.394L3.968 19.55c-.3.3-.666.45-1.096.45a1.56 1.56 0 0 1-1.107-.45l-1.29-1.315a1.447 1.447 0 0 1-.463-1.096c0-.43.154-.8.462-1.107ZM19.884 5.648a.355.355 0 0 0-.285-.121c-.073 0-.36.144-.859.432-.499.288-1.049.616-1.65.986l-.96.59-2.35-1.302V3.506l3.566-2.057c.13-.09.195-.203.195-.341s-.065-.252-.195-.341c-.357-.244-.793-.432-1.308-.566A5.882 5.882 0 0 0 14.558 0c-1.5 0-2.785.534-3.852 1.6-1.067 1.068-1.6 2.352-1.6 3.853 0 1.502.533 2.786 1.6 3.853 1.067 1.067 2.351 1.6 3.853 1.6 1.144 0 2.193-.338 3.147-1.016a5.354 5.354 0 0 0 2.002-2.647c.186-.544.28-.974.28-1.29a.453.453 0 0 0-.104-.305Z"
      fill="#858585"
    />
  </svg>
)

const ArrowRightAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#fff" d="M0 0h16v16H0z"/>
    <path
      d="M5 11.36V4.64c0-.493.456-.8.82-.552l4.91 3.36c.36.247.36.857 0 1.104l-4.91 3.36c-.364.248-.82-.059-.82-.552Z"
      fill="#CBCBCB"
    />
  </svg>
)

const ChevronUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m18 15-6-6-6 6"
      stroke="#858585"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const User = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ?? 24}
      height={props.height ?? 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12Z"
        fill={props.fill ?? "#858585"}/>
      <path
        d="M13.0694 13.0394C11.133 12.9176 9.44984 13.0408 7.99805 13.9446C5.56857 15.4122 6.03611 17.3882 6.03611 19.8056C6.03611 20.4452 6.72611 20.9753 7.58706 20.9753C16.9354 20.9753 17.3074 21.2027 17.8618 20.2769C18.0436 19.9638 17.9938 20.0633 17.9938 17.0682C17.9938 14.6892 15.2624 13.0394 13.0694 13.0394Z"
        fill={props.fill ?? "#858585"}/>
    </svg>
  )
}

const Templates = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? 24}
    height={props.height ?? 24}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 22V10h9v9a3 3 0 0 1-3 3h-6Zm-2 0H6a3 3 0 0 1-3-3v-9h7v12ZM21 8H3V5a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v3Z"
      fill={props.fill ?? "#858585"}
    />
  </svg>
);

const Teams = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? "24"}
    height={props.height ?? "24"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_22_300)">
      <path
        d="M12 12.0001C14.2091 12.0001 16 10.2092 16 8.00006C16 5.79092 14.2091 4.00006 12 4.00006C9.79086 4.00006 8 5.79092 8 8.00006C8 10.2092 9.79086 12.0001 12 12.0001Z"
        fill={props.fill ?? "#858585"}
      />
      <path
        d="M20 11.0001C21.1046 11.0001 22 10.1046 22 9.00006C22 7.89549 21.1046 7.00006 20 7.00006C18.8954 7.00006 18 7.89549 18 9.00006C18 10.1046 18.8954 11.0001 20 11.0001Z"
        fill={props.fill ?? "#858585"}
      />
      <path
        d="M4 11.0001C5.10457 11.0001 6 10.1046 6 9.00006C6 7.89549 5.10457 7.00006 4 7.00006C2.89543 7.00006 2 7.89549 2 9.00006C2 10.1046 2.89543 11.0001 4 11.0001Z"
        fill={props.fill ?? "#858585"}
      />
      <path
        d="M6 12.676C5.03197 11.9021 4.15529 12.0045 3.03599 12.0045C1.36195 12.0045 0 13.3257 0 14.9492V19.7141C0 20.4191 0.589761 20.9924 1.315 20.9924C4.44605 20.9924 4.06886 21.0477 4.06886 20.8607C4.06886 17.4843 3.65902 15.0082 6 12.676Z"
        fill={props.fill ?? "#858585"}
      />
      <path
        d="M13.0694 13.0395C11.133 12.9177 9.44984 13.0409 7.99805 13.9446C5.56857 15.4122 6.03611 17.3883 6.03611 19.8057C6.03611 20.4453 6.72611 20.9754 7.58706 20.9754C16.9354 20.9754 17.3074 21.2028 17.8618 20.277C18.0436 19.9639 17.9938 20.0634 17.9938 17.0682C17.9938 14.6892 15.2624 13.0395 13.0694 13.0395Z"
        fill={props.fill ?? "#858585"}
      />
      <path
        d="M20.964 12.0044C19.8386 12.0044 18.9667 11.903 18 12.6759C20.3235 14.9907 19.9311 17.2978 19.9311 20.8604C19.9311 21.0486 19.618 20.9921 22.6381 20.9921C23.3892 20.9921 24 20.3983 24 19.6684V14.949C24 13.3255 22.6381 12.0044 20.964 12.0044Z"
        fill={props.fill ?? "#858585"}
      />
    </g>
    <defs>
      <clipPath id="clip0_22_300">
        <rect width={props.width ?? "24"} height={props.height ?? "24"} fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

const LightClock = (props: SVGProps<SVGSVGElement>) => {
  return <SystemIcons.DownloadTimeData
    stroke={theme.palette.neutral.medium}
    {...props}
  />
}

const SidebarIcons = {
  Home,
  Chat,
  ActivityFeed,
  ArrowRight,
  Folder,
  Layers,
  GroupPeople,
  Calendar,
  File,
  AddFile,
  Clock,
  Wrench,
  User,
  Templates,
  Teams,
  LightClock
};

const ManageAssetIcons = {
  ArrowRightAlt,
  ChevronUp
}

const Icons = {
  SidebarIcons,
  ManageAssetIcons,
}
export {
  SidebarIcons
};

export default Icons;