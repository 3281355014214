import {BaseProps} from "../BaseProps";
import {FormRequirement} from "types/FormRequirement";
import theme from "theme/theme";
import {Stack} from "@mui/material";
import {ActionType, FormFieldType, ProcessType, Severity} from "enums/index";
import React, {useEffect, useState} from "react";
import {FormField} from "types/FormField";
import FormViewHeader from "./FormViewHeader";
import DateFormField from "./FormFields/DateFormField";
import TextFormField from "./FormFields/TextFormField";
import ListFormField from "./FormFields/ListFormField";
import NumericFormField from "./FormFields/NumericFormField";
import ParagraphFormField from "./FormFields/ParagraphFormField";
import {UnsavedChangesDialog} from "components/Dialogs";
import {areObjectsEqual} from "../utility";
import {DocumentReference, Timestamp} from "firebase/firestore";
import {enCommonLabel, enFormRequirementLabel} from "constants/index";
import {AccessType} from "types/Permission";
import {statusSubmitHandler} from "../utility/statusSubmitHandler";
import templateSubmitForm from "../utility/templateSubmitForm";

interface FormViewProps extends BaseProps {
  documentRef: DocumentReference;
  formRequirement: FormRequirement;
  access: AccessType | null;
}

function FormView(props: FormViewProps) {
  const {formRequirement, documentRef, toastProps, access} = props;
  const {formTemplate = {fields: []}} = formRequirement;
  const {fields} = formTemplate;

  const {setToastMessage, setIsToastOpen, setToastSeverity} = toastProps!;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [localFields, setLocalFields] = useState<FormField<FormFieldType>[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const [isUnsavedChangesDialogOpen, setIsUnsavedChangesOpen] = useState<boolean>(false);
  const [isFormViewValid, setIsFormViewValid] = useState<boolean>(false);

  useEffect(() => {
    setLocalFields(structuredClone(fields ?? []));
  }, [fields]);

  function cancelChanges() {
    if (areObjectsEqual(fields, localFields)) {
      setLocalFields(structuredClone(fields));
      setIsEditMode(false);
      return;
    }

    setIsUnsavedChangesOpen(true);
  }

  async function saveChanges() {
    setIsLoading(true);

    const formData = {
      formTemplate: {
        ...formTemplate,
        fields: localFields.map(field => {
          if (field.type === FormFieldType.Date && !!field.value) {
            // @ts-ignore
            return {
              ...field,
              value: {
                date: field.value.date !== null ? new Timestamp(field.value.date.seconds, field.value.date.nanoseconds) : null,
                time: field.value.time
              }
            }
          }

          // additional checking if type is list, string must be distinct to avoid error in BE
          if(field.type === FormFieldType.List && !!field.value && !field.selectMultiple) {
            const fieldValue = new Set(field.value);
            return {
              ...field,
              value: Array.from(fieldValue)
            }
          }

          if(!!field.value) return field;

          const {value, ...rest} = field;
          return rest;
        }),
      }
    }

    await templateSubmitForm(
      documentRef.path,
      ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler<FormRequirement>({status, data, isLastUpdate, successCallback, errorCallback}),
      formData
    );
  }

  function successCallback() {
    setIsLoading(false);
    setToastSeverity(Severity.Success);
    setToastMessage(enFormRequirementLabel.updateSuccess);
    setIsToastOpen(true);
    setIsEditMode(false);
  }

  function errorCallback(message: any) {
    setIsLoading(false);
    setToastSeverity(Severity.Error);
    setToastMessage(message || enCommonLabel.errorProcess(ProcessType.Update));
    setIsToastOpen(true);
  }

  function updateLocalField(fieldValue: any, index: number) {
    let tempFields = structuredClone(localFields);
    let newFieldValue = fieldValue;

    if (tempFields[index].type === FormFieldType.Date) {
      newFieldValue = {
        date: fieldValue.date !== null ? fieldValue.date as Timestamp : fieldValue.date,
        time: fieldValue.time
      };
    }

    tempFields[index].value = newFieldValue;

    setLocalFields([...tempFields]);
  }

  function handleUnsavedChangesConfirm() {
    setLocalFields(structuredClone(fields));
    setIsUnsavedChangesOpen(false);
    setIsEditMode(false);
  }

  function onKeyPress(event: React.KeyboardEvent) {
    if (event.key === 'Enter' && !isLoading && isFormViewValid) {
      event.preventDefault();
    }
  }

  return (
    <>
      <UnsavedChangesDialog
        isOpen={isUnsavedChangesDialogOpen}
        handleClose={() => setIsUnsavedChangesOpen(false)}
        handleConfirm={handleUnsavedChangesConfirm}
      />
      <Stack
        direction="column"
        gap={1}
        padding={2.5}
        sx={{
          bgcolor: theme.palette.background.swiftDefault,
          borderRadius: 1,
          mb: 3,
        }}
      >
        <FormViewHeader
          access={access}
          formFields={fields}
          localFields={localFields}
          isLoading={isLoading}
          lastChangedBy={formRequirement.formLastChangedBy}
          isEditMode={isEditMode}
          enableEdit={() => setIsEditMode(true)}
          cancelChanges={cancelChanges}
          saveChanges={saveChanges}
          setIsFormViewValid={setIsFormViewValid}
        />
        <Stack direction="column" gap={2}>
          {localFields.map((field, index) => {
            const {type} = field;
            const formFieldProps = {
              isLoading,
              isEditMode,
              index,
              updateLocalField
            }

            switch (type) {
              case FormFieldType.Date:
                return (
                  <DateFormField
                    {...formFieldProps}
                    field={field as FormField<FormFieldType.Date>}
                    formField={fields[index] as FormField<FormFieldType.Date>}
                  />
                );
              case FormFieldType.Paragraph:
                return (
                  <ParagraphFormField
                    {...formFieldProps}
                    field={field as FormField<FormFieldType.Paragraph>}
                  />
                );
              case FormFieldType.List:
                return (
                  <ListFormField
                    {...formFieldProps}
                    field={field as FormField<FormFieldType.List>}
                  />
                );
              case FormFieldType.Numeric:
                return (
                  <NumericFormField
                    {...formFieldProps}
                    field={field as FormField<FormFieldType.Numeric>}
                  />
                );
              default:
                return (
                  <TextFormField
                    {...formFieldProps}
                    field={field as FormField<FormFieldType.Text>}
                    onKeyPress={onKeyPress}
                  />
                );
            }
          })}
        </Stack>
      </Stack>
    </>
  )
}

export default FormView;