import {collection, CollectionReference} from "firebase/firestore";
import {Entity} from "enums/entity";
import {organizationMembersPath, organizationTeamsPath} from "./FirebasePath";
import {db} from "../../firebase";

export function getParentAccess(ref: CollectionReference, entity: Entity, parentEntity?: Entity) {
  switch (entity) {
    case Entity.Project:
      return getOrganizationTeamsAndMembers(ref.path);
    default:
      return getAccesslistCollection(ref, entity, parentEntity);
  }
}

function getOrganizationTeamsAndMembers(colPath: string) {
  const orgId = colPath.split('/')[1];
  return [
    organizationTeamsPath(orgId),
    organizationMembersPath(orgId)
  ];
}

function getAccesslistCollection(ref: CollectionReference, entity: Entity, parentEntity?: Entity) {
  let accessListPath = "";

  switch (entity) {
    case Entity.Task:
      let taskBasePath = ref.parent?.parent.parent?.parent.parent?.path;
      if (parentEntity === Entity.ProjectLevelTask) {
        accessListPath = taskBasePath + "/projectLevelTasksAccessList";
        break;
      }

      accessListPath = taskBasePath + "/accessList";
      break;
    case Entity.ProjectLevelTask:
      let pltBasePath = ref.parent?.path;
      accessListPath = pltBasePath + "/accessList";
      break;
    default:
      accessListPath = ref.parent?.parent.parent?.path + "/accessList";
  }

  const collectionPath = collection(db, accessListPath);
  return [collectionPath, collectionPath];
}