import SidebarLinks from "components/SidebarMenu/SidebarLinks";
import SidebarFoot from "components/SidebarMenu/SidebarFoot";
import {BaseProps} from "screens/BaseProps";
import {Stack} from "@mui/material";

function MainAndBottom(props: BaseProps) {
  const {uid, toggleSidebar} = props;

  return (
    <Stack flex="1 1 auto" gap={4} justifyContent="space-between">
      <SidebarLinks toggleSidebar={toggleSidebar!} uid={uid!}/>
      <SidebarFoot uid={uid!} />
    </Stack>
  );
}

export default MainAndBottom;