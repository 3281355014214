// /* eslint-disable react-hooks/exhaustive-deps */

import {onChangeInput} from "screens/utility/formUtils";
import {enMemberLabel} from "constants/index";
import {SelectInput, SimpleFormDrawer} from "components/index";
import React, {useEffect, useState} from "react";
import {AccessRole} from "enums/accessRole";
import {SystemIcons} from "assets/icons/system/system.index";
import {Box} from "@mui/material";
import ACCESS_ROLES from "assets/arrayList/ACCESS_ROLES";

interface BulkChangeRoleDrawerProps {
  isOpen: boolean
  closeDrawer: () => void;
  onSubmit: () => void;
  isLoading: boolean;
  handleSelectRole: (selectedRole: AccessRole) => void
}

function BulkChangeRoleDrawer(props: BulkChangeRoleDrawerProps) {
  const {isOpen, closeDrawer, onSubmit, isLoading, handleSelectRole} = props;
  const [newRole, setNewRole] = useState<AccessRole>(AccessRole.User)

  useEffect(() => {
    handleSelectRole(newRole);
  },[newRole])

  return <>
    <SimpleFormDrawer
      isOpen={isOpen}
      isLoading={isLoading}
      id={"bulk-change-role-drawer"}
      isFormValid={true}
      buttonId={"bulk-change-role-button"}
      icon={<SystemIcons.User/>}
      onClose={closeDrawer}
      onSubmit={onSubmit}
      title={enMemberLabel.bulkChangeRoleDrawerTitle}
    >
      <SelectInput
        onChange={(e) => onChangeInput(e, setNewRole)}
        items={ACCESS_ROLES}
        value={newRole}
        defaultValue={newRole}
        label={enMemberLabel.role}
      />
      <Box flex={1}/>
    </SimpleFormDrawer>
  </>
}

export default BulkChangeRoleDrawer