import {Box, Button, Checkbox, FormControlLabel, Stack, Typography} from "@mui/material";
import {en} from "language/en";
import theme from "theme/theme";
import {FormInputGroup, Input} from "components/index";
import {CheckBoxOutlineBlank} from "@mui/icons-material";
import React, {useEffect, useRef, useState} from "react";
import DueDatePopover from "./DueDatePopover";
import {formatDateTime} from "screens/utility";
import TimeSelector from "components/TimeSelector";

interface TaskDueDateFieldProps {
  isClearButtonVisible: boolean;
  timeSlots: string[];
  onClearButtonClick: () => void;
  onDueDateChange: (date: Date | null) => void;
  onTimeChange: (newValue: string | null | undefined) => void;
  onAllDayChange: (checked: boolean) => void;
  isAllDay?: boolean;
  dueDateId?: string;
  dueTimeId?: string;
  dueDate?: Date | null;
  dueTime?: string | null | undefined;
  defaultValue?: boolean;
}

const enCommon = en.common;

function DueDateField(props: TaskDueDateFieldProps) {
  const {
    isClearButtonVisible,
    isAllDay,
    dueDate,
    dueTime,
    dueDateId,
    defaultValue = false
  } = props;
  const {onClearButtonClick, onDueDateChange, onTimeChange, onAllDayChange} = props;
  const [taskDueDate, setTaskDueDate] = useState<Date | null>(dueDate ?? null);
  const [taskDueDateString, setTaskDueDateString] = useState<string | null>(dueDate ? formatDateTime(dueDate).date : null);
  const [taskDueTime, setTaskDueTime] = useState<string | null>(dueTime ?? null);
  const [isAllDayBoxChecked, setIsAllDayBoxChecked] = useState<boolean>(isAllDay ?? defaultValue);

  const [isDueDatePopoverOpen, setIsDueDatePopoverOpen] = useState<boolean>(false);
  const firstDueDateSelect = useRef(true);

  const showTimePicker = !isAllDayBoxChecked && (!firstDueDateSelect.current || !!taskDueTime);

  useEffect(() => {
    if (taskDueDate === null) {
      setTaskDueDateString("");
      return;
    }

    const displayedDate = formatDateTime(taskDueDate)

    setTaskDueDateString(displayedDate.date);
  }, [taskDueDate]);

  useEffect(() => {
    onTimeChange(taskDueTime);
    onDueDateChange(taskDueDate);
    onAllDayChange(isAllDayBoxChecked);
  }, [isAllDayBoxChecked, taskDueTime, taskDueDate]);

  function onTimeSelect(time : string | null | undefined) {
    setTaskDueTime(time!);
    onTimeChange(time);
  }

  function doDueDateChange(date: Date | null) {
    onDueDateChange(date);
    setTaskDueDate(date);
    if (!taskDueTime && !isAllDayBoxChecked)
      setTaskDueTime("12:00 PM")

    if(!isAllDayBoxChecked){
      setIsAllDayBoxChecked(true);
    }
  }

  function doAllDayBoxAction(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    onAllDayChange(checked);
    setIsAllDayBoxChecked(checked);

    if (checked) {
      setTaskDueTime(null);
      return;
    }
    else{
      firstDueDateSelect.current = false;
      return;
    }
  }

  function doClearButtonClick() {
    firstDueDateSelect.current = true;

    onClearButtonClick();
    setTaskDueDate(null);
    setTaskDueDateString(null);
    setTaskDueTime(null);
    setIsAllDayBoxChecked(false);
  }

  function handleCloseDueDatePopOver() {
    setIsDueDatePopoverOpen(false);
  }

  function handleDueDateFocus() {
    setIsDueDatePopoverOpen(true);
  }

  return (
    <>
      <FormInputGroup
        sx={{mb: 1}}
        containerStyles={{
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 1,
        }}
        formTitle={
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="h5">
              {enCommon.label.dueDate}
              <Typography variant="body" fontWeight={400} color={theme.palette.neutral.dark}>
                &nbsp;({enCommon.label.optional})
              </Typography>
            </Typography>

            {isClearButtonVisible && (
              <Button
                id="due-date-clear-button"
                onClick={doClearButtonClick}
                sx={{padding: 0}}
              >
                {enCommon.button.clear}
              </Button>
              )}
          </Stack>
        }
      >
        <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={0.5}>
          <Input
            id={dueDateId ?? "taskDueDate"}
            onClick={handleDueDateFocus}
            onChange={() => {}}
            value={taskDueDateString ?? ""}
            placeholder={enCommon.label.date}
            error={!taskDueDate && isAllDayBoxChecked}
            sx={{maxWidth: 185}}
          />
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  id="isAllDayBox"
                  onChange={doAllDayBoxAction}
                  checked={isAllDayBoxChecked}
                  sx={{py: 0}}
                  icon={
                    <CheckBoxOutlineBlank
                      sx={{
                        color: theme.palette.neutral.main,
                        borderWidth: "1px",
                      }}
                    />
                  }
                />
              }
              label={enCommon.label.allDay}
              sx={{ textWrap: "nowrap", mx: 0,}}
            />
          </Box>
        </Stack>

        {showTimePicker && (
          <TimeSelector
            onTimeChange={onTimeSelect}
            time={taskDueTime}
            error={!taskDueTime && !!taskDueDate}
            sx={{maxWidth: 185}}
          />
        )}
      </FormInputGroup>
      {isDueDatePopoverOpen && (
        <DueDatePopover
          isOpen={isDueDatePopoverOpen}
          selectedDate={taskDueDate}
          disablePreviousDate={false}
          anchorElement={document.getElementById(dueDateId ?? "taskDueDate")}
          setSelectedDate={doDueDateChange}
          closePopover={handleCloseDueDatePopOver}
        />
      )}
    </>
  )
}

export default DueDateField;