import SmartSearchInput from "components/inputs/SmartSearchInput";
import {enOrgDataTagsLabel} from "constants/index";
import {algoliaDataTagsColPath} from "../utility/algoliaColPath";
import {Stack} from "@mui/material";
import {SortMenu} from "components/index";
import {AllOrder, MiscOrder} from "enums/DirectionalOrder";
import {TagData} from "./PageHeader";
import {Dispatch} from "react";
import {useParams} from "react-router-dom";

interface SearchAndSortProps {
  sortOrder: AllOrder;
  setSortOrder: Dispatch<AllOrder>;
  setAlgoliaResults: (results: TagData[] | null) => void;
}


function SearchAndSort(props: SearchAndSortProps) {
  const {sortOrder, setSortOrder, setAlgoliaResults} = props;
  const {orgId} = useParams();

  return (
    <Stack
      direction={{xs: "column", md: "row"}}
      justifyContent={{xs: "left", md: "space-between"}}
      gap={{xs: 1, md: "unset"}}
      marginTop={2}
      marginBottom={1}
    >
      <SmartSearchInput<TagData>
        placeholder={enOrgDataTagsLabel.search}
        resultCallback={setAlgoliaResults}
        colPath={algoliaDataTagsColPath(orgId!)}
      />
      <Stack direction="row" gap={1}>
        <SortMenu
          currentOrder={sortOrder}
          setter={setSortOrder}
          extraMenu={[MiscOrder.timeCreated]}
        />
      </Stack>
    </Stack>
  )
}

export default SearchAndSort;