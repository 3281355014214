import {Box, Stack} from "@mui/material";
import SystemIndex from "assets/icons/system/system.index";
import {UnassignedItemType} from "types/ActivityFeedTypes";
import TimestampTypo from "components/Typography/TimestampTypo";
import {enActivityFeedLabel} from "constants/index";
import {formatDateTimeFirestoreDate} from "screens/utility/formatDateTimeFirestoreDate";
import ItemContainer from "components/ActivityFeed/ItemContainer";

interface UnassignedItemProps {
  item: UnassignedItemType
}

function UnassignedItem(props: UnassignedItemProps) {
  const {item} = props;
  const {details, timestamp} = item;
  const {by} = details;
  const {date, time} = formatDateTimeFirestoreDate(timestamp);

  return (
    <ItemContainer>
      <Box width={24} height={24}>
        <SystemIndex.SystemIcons.ArrowRight width={24} height={24}/>
      </Box>
      <Stack direction="column">
        {enActivityFeedLabel.unassigned(by.name ?? "")}
        <TimestampTypo>{`${date} ${time}`}</TimestampTypo>
      </Stack>
    </ItemContainer>
  );
}

export default UnassignedItem;
