import {Stack, Switch, Typography} from "@mui/material";
import {enFormsLabel} from "constants/index";
import {FormFieldAdvancedOptionsProps, OptionColumnWrapper} from "./index";
import {ValidationMessage} from "components/index";
import {FormField} from "types/FormField";
import {FormFieldType} from "enums/formFieldType";
import React from "react";

export function BasicAdvancedOptions(props: FormFieldAdvancedOptionsProps) {
  const {changeFormData, index, formField} = props;
  const {errors, responseIsRequired} = formField as FormField<FormFieldType.Text>;

  return (
    <OptionColumnWrapper>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">{enFormsLabel.responseRequired}</Typography>
        <Switch
          onChange={(_, checked) => changeFormData(checked, "responseIsRequired", index, true)}
          checked={responseIsRequired ?? false /*Defaulted to false to supress MUI error regarding controlled components*/}
        />
        <ValidationMessage validationMessage={errors?.responseRequired}/>
      </Stack>
    </OptionColumnWrapper>
  )
}