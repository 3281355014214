import { useState, useEffect, MutableRefObject  } from "react";

export function useOnScreen(ref: MutableRefObject<any>, rootMargin: string = "0px"): boolean {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        rootMargin
      }
    );

    const currentElement = ref?.current;

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement === null) {
        setIsVisible(false);
        return;
      }

      observer.unobserve(currentElement);
    };
  });

  return isVisible;
}