import React, {useEffect, useState} from "react";
import {Alert, Box, Hidden, Stack, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {styled} from "@mui/system";
import {signInAnonymously, signOut} from "firebase/auth";
import {doc} from "firebase/firestore";
import {app, auth} from "../../firebase";
import {SwiftLogoOnly} from "assets/icons/SwiftLogo";
import theme from "theme/theme";
import {emailRegex, enCommonButton, enCommonLabel} from "constants/index";
import {ActionType, Severity, ViewStatus} from "enums";
import useQuery from "hooks/useQuery";
import {AlertMessage} from "types/Alert";
import {ValidationMessage} from "components/index";
import FormContainer from "components/FormContainer";
import StandardInput from "components/inputs/StandardInput";
import {resetPasswordRequestsPath, submitForm} from "screens/utility";
import {statusSubmitHandler} from "screens/utility/statusSubmitHandler";
import {ResetPasswordConfirm} from "./ResetPasswordConfirm";
import useMaintenance from "hooks/useMaintenance";
import {initClient} from "emberflow-web-client/lib";

export function ResetPassword() {
  useMaintenance(false);

  const params = useQuery();
  const token = params.get("token");
  const [email, setEmail] = useState<string>("");
  const [emailValidationError, setEmailValidationError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertMessage>({
    show: false,
    message: null,
    severity: Severity.Error
  });

  useEffect(() => {
    const isEmailValid = emailRegex.test(email);
    if (isEmailValid || !email) return resetMessages();
    if (!isEmailValid) return setEmailValidationError(enCommonLabel.enterValidEmail);
  }, [email]);

  async function submitResetPasswordHandler(uid: string) {
    const ref = doc(resetPasswordRequestsPath(uid!))
    await submitForm(
      ref,
      ActionType.Create,
      (status, data, isLastUpdate) => statusSubmitHandler({status, data, isLastUpdate, successCallback, errorCallback}),
      {email},
    );
  }

  function submitResetPassword() {
    setIsLoading(true);
    resetMessages();
    signInAnonymously(auth)
    .then(async (userData) => {
      const {uid} = userData.user;

      initClient(
        app,
        uid!,
        undefined,
        {
          "submit": ViewStatus.Submit,
          "submitted": ViewStatus.Submitted,
          "validation-error": ViewStatus.ValidationError,
          "security-error": ViewStatus.SecurityError,
          "finished": ViewStatus.Finished,
          "delay": ViewStatus.Delay,
          "cancel": ViewStatus.Cancel,
          "cancelled": ViewStatus.Cancelled,
          "error": ViewStatus.Error,
        }
      );

      await submitResetPasswordHandler(uid!);
    })
    .catch((error) => {
      errorCallback(error);
    });
  }

  async function successCallback() {
    setIsLoading(false);
    setAlert({ 
      show: true,  
      message: <Typography variant="body" color={theme.palette.success.main}>{enCommonLabel.resetPasswordInstruction} <b>{email}</b></Typography>,
      severity: Severity.Success
    });
    await signOut(auth);
  }

  async function errorCallback(message: any) {
    setIsLoading(false);
    const messageContent = (typeof (message) === "string")? message: message.email;
    setAlert({ 
      show: true,  
      message: <Typography variant="body" color={theme.palette.error.main}>{messageContent}</Typography>,
      severity: Severity.Error
    });
    await signOut(auth);
  }

  function resetMessages() {
    setAlert({
      show: false,
      message: null,
      severity: Severity.Error
    });
    setEmailValidationError("");
  }

  if (!!token) return <ResetPasswordConfirm token={token}/>;

  function onKeyPress(e: React.KeyboardEvent) {
    if (e.key === "Enter" && !!email && !emailValidationError) {
      submitResetPassword();
    }
  }

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.secondary.main,
        zIndex: "-2!important",
        backgroundImage: `url("/assets/Artboard6.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{height: "100vh"}}
      >
        <FormContainer
          sx={{
            maxWidth: {xs: "unset", sm: "unset", md: "30%", lg: "20%", xl: "20%"},
          }}
        >
          <Stack direction="column" gap={1} justifyContent="center" textAlign="center" py="2%" flex={1}>
            <Box
              sx={{
                alignSelf: "center",
                display: "flex",
                gap: 1
              }}
            >
              <SwiftLogoOnly
                height={70}
                width={70}
                fill={theme.palette.secondary.main}
                stroke={theme.palette.secondary.main}
              />
            </Box>
            <Stack direction="column" marginTop={3}>
              <Typography variant="h2">
                {enCommonLabel.passwordReset}
              </Typography>
              <hr
                style={{
                  borderRadius: 5,
                  width: "25%",
                  height: "5px",
                  border: "none",
                  backgroundColor: theme.palette.secondary.main
                }}
              />
            </Stack>
            <Stack gap={1} marginTop={3}>
              <Typography variant="body">
                {enCommonLabel.resetPasswordText}
              </Typography>
            </Stack>
            <Stack gap={3.5}>
              {alert.show && <Alert severity={alert.severity} icon={false}>{alert.message}</Alert>}
              <Stack gap={0}>
                <StandardInput
                  id="email-field"
                  label={enCommonLabel.email}
                  type="text"
                  variant="standard"
                  onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
                  value={email}
                  onKeyPress={onKeyPress}
                />
                <ValidationMessage validationMessage={emailValidationError}/>
              </Stack>
              <LoadingButton
                variant="contained"
                onClick={submitResetPassword}
                disabled={!email || !!emailValidationError}
                loading={isLoading}
                sx={{marginTop: 2, borderRadius: 5, backgroundColor: theme.palette.secondary.main, padding: 1}}
              >
                {enCommonButton.sendEmail}
              </LoadingButton>
              <Typography variant="body" align="center">
                <StyledAnchor href="login">{enCommonButton.backToLogin}</StyledAnchor>
              </Typography>
            </Stack>
          </Stack>
        </FormContainer>
      </Stack>
      <Hidden smDown>
        <Box sx={{position: "absolute", bottom: "2%", right: "2%", zIndex: 2}}>
          <SwiftLogoOnly
            height={40}
            width={40}
            stroke={theme.palette.background.default}
            fill={theme.palette.background.default}
          />
        </Box>
      </Hidden>
    </Stack>
  );
}

export const StyledAnchor = styled('a')`text-decoration: none;
  color: ${theme.palette.primary.main};`