import {ReferenceFile} from "types/ReferenceFile";
import React, {useEffect, useState} from "react";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../../firebase";
import SystemIndex from "assets/icons/system/system.index";
import {Box, CardMedia, Tooltip} from "@mui/material";
import theme from "theme/theme";
import {InProgress} from "components/index";
import getEnvKey from "../../screens/utility/getEnvKey";
import {storageBucketKey} from "constants/envKeys";
import {Previews} from "enums/supportedPreviews";
import getContentType from "../../screens/utility/getContentType";
import {enCommonLabel} from "constants/index";
interface FilePreviewProps {
  referenceFile: ReferenceFile;
}

function FilePreview({referenceFile}: FilePreviewProps) {
  const {filepath, fileName} = referenceFile;
  const [fileUrl, setFileUrl] = useState<string | undefined>();
  const storageBucket = getEnvKey(storageBucketKey);
  const [contentType, setContentType] = useState<Previews>(Previews.File);
  const commonStyle = {
    maxWidth: 358,
    maxHeight: 480,
    objectPosition: "center",
    borderRadius: 1,
  };

  useEffect(() => {
    if (!filepath) return;

    const previewReference = ref(storage, `${storageBucket}${filepath}`);
    getDownloadURL(previewReference).then(setFileUrl);
    setContentType(getContentType(filepath));
  }, [filepath]);


  function RenderImage() {
    return (
      <CardMedia
        sx={commonStyle}
        component="img"
        image={fileUrl}
        alt={fileName}
      />
    )
  }

  function RenderVideo () {
    return (
      <CardMedia
        sx={commonStyle}
        component="video"
        controls
        src={fileUrl}
      />
    )
  }

  function RenderDefault() {
    return (
      <Tooltip title={enCommonLabel.clickToDownload}>
        <Box sx={{
          width: 358,
          height: 240,
          display: "grid",
          placeItems: "center",
          borderRadius: 1,
          backgroundColor: theme.palette.background.paper,
          transition: "background-color 0.3s", // smooth transition
          '&:hover': {
            backgroundColor: theme.palette.background.swiftDefault,
          },
        }}>
          <SystemIndex.SystemIcons.FilesFilled fill={theme.palette.neutral.dark} width={64} height={64}/>
        </Box>
      </Tooltip>
    )
  }

  if (!filepath) return <></>;
  if (!fileUrl) return <InProgress/>;

  switch (contentType) {
    case Previews.Image:
      return <RenderImage/>;
    case Previews.Video:
      return <RenderVideo/>;
    default:
      return <RenderDefault/>;
  }
}

export default FilePreview
