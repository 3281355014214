import {BaseProps} from "screens/BaseProps";
import theme from "theme/theme";
import {enTeamsAndPeopleLabel} from "constants/index";
import {Stack, Typography} from "@mui/material";
import {orgInvitesCollectionPath} from "screens/utility/FirebasePath";
import {Entity} from "enums/index";
import InvitesView from "components/InvitesView";

interface InvitesProps extends BaseProps {
  selectedOrgId: string,
}

function Invites(props: InvitesProps) {
  const {selectedOrgId, toastProps, uid} = props;

  return (
    <>
      <Stack mt={1} gap={1} flex={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h2" color={theme.palette.secondary.main}>
            {enTeamsAndPeopleLabel.invites}
          </Typography>
        </Stack>
        <InvitesView
          uid={uid}
          invitationsReference={orgInvitesCollectionPath(selectedOrgId)}
          entity={Entity.Member}
          toastProps={toastProps}
          withSearch={true}
        />
      </Stack>
    </>
  )
}

export default Invites;