import {BaseProps} from "screens/BaseProps";
import {Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {enTeamsAndPeopleButton, enTeamsAndPeopleLabel} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {useState} from "react";
import {AddMemberDrawer} from "components/Drawers";
import MembersView from "./MembersView";
import {SelectedOrg} from "types/index";
import {PermissionEntity, PermissionOperationKey} from "types/Permission";
import {useAccess} from "hooks/index";
import {Entity} from "enums/entity";
import {PrimaryButton} from "components/Button";

interface PeopleProps extends BaseProps {
  selectedOrg: SelectedOrg;
}

function People(props: PeopleProps) {
  const {selectedOrg, toastProps, uid, setSelectedOrgId} = props;
  const [addMemberDrawerOpen, setAddMemberDrawerOpen] = useState<boolean>(false);

  const [access] = useAccess({
    uid,
    entity: Entity.Organization,
    documentDocId: PermissionEntity.OrganizationMember,
  });

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mt={1}>
        {selectedOrg.id !== "" && (
          <AddMemberDrawer
            uid={uid}
            isAddDrawerOpen={addMemberDrawerOpen}
            onDrawerClose={() => setAddMemberDrawerOpen(false)}
            selectedOrg={selectedOrg}
            toastProps={toastProps}
          />
        )}
        <Typography variant="h2" color={theme.palette.secondary.main}>
          {enTeamsAndPeopleLabel.people}
        </Typography>
        <PrimaryButton
          onClickFcn={() => setAddMemberDrawerOpen(true)}
          disabled={Boolean(!access?.[PermissionOperationKey.Create])}
          label={enTeamsAndPeopleButton.add}
          startIcon={(props) => (
            <SystemIcons.AddUser
              stroke={theme.palette.background.paper}
              height={16}
              width={16}
              {...props}
            />
          )}
        />
      </Stack>
      <Stack mt={3} flex={1}>
        <MembersView
          uid={uid}
          screenName="People"
          selectedOrg={selectedOrg}
          toastProps={toastProps}
          setSelectedOrgId={setSelectedOrgId!}
          access={access}
        />
      </Stack>
    </>
  )
}

export default People;