import {MenuCardProps} from "components/MenuCard";
import {DocumentReference} from "firebase/firestore";
import {FormRequirement} from "types/FormRequirement";
import {SystemIcons} from "assets/icons/system/system.index";
import {
  emptyFunction,
  enCommonLabel,
  enFileRequirementButton,
  enFileRequirementLabel,
  previousPage
} from "constants/index";
import {ActionOption, ConfirmDialog, MenuCard} from "components/index";
import React, {useState} from "react";
import {ActionType, ProcessType, Severity} from "enums/index";
import {useNavigate} from "react-router-dom";
import submitForm from "../../utility/submitForm";
import {PermissionOperationKey} from "types/Permission";
import {statusSubmitHandler} from "../../utility/statusSubmitHandler";
import {toastProps} from "../../BaseProps";

interface NavBarMenuProps extends MenuCardProps {
  formRequirement: FormRequirement | undefined | null
  documentRef: DocumentReference;
  access: any;
  toastProps?: toastProps;
}

function NavBarMenu(props: NavBarMenuProps) {
  const {anchorEl, open, toastProps, formRequirement, documentRef, access, closeMenu} = props;
  const {setIsToastOpen, setToastMessage, setToastSeverity} = toastProps!;

  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  function onDeleteCancel(e: React.MouseEvent<HTMLElement> | undefined) {
    setIsDeleteOpen(false);

    if (e !== undefined) closeMenu(e);
  }

  async function onDeleteTaskConfirm() {
    await submitForm(
      documentRef,
      ActionType.Delete,
      (status, data, isLastUpdate) => statusSubmitHandler<FormRequirement>({status, data, isLastUpdate, successCallback, errorCallback})
    );
  }

  function successCallback() {
    setToastMessage(enFileRequirementLabel.deleteSuccess);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);

    navigate(previousPage);
  }

  function errorCallback(message: any) {
    setToastMessage(message || enCommonLabel.errorProcess(ProcessType.Delete));
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
    setIsDeleteOpen(false);
  }

  return (
    <>
      <MenuCard
        anchorEl={anchorEl}
        open={open}
        closeMenu={closeMenu}
      >
        <ActionOption
          disabled={!access?.[PermissionOperationKey.Delete] || !!formRequirement?.templateId}
          id="requirement-overview-delete-menu-button"
          Icon={<SystemIcons.DeleteOutlined height={16} width={16}/>}
          label={enFileRequirementButton.deleteRequirement}
          labelSx={(theme) => ({color: theme.palette.error.main})}
          onClick={() => !access?.[PermissionOperationKey.Delete] ? emptyFunction() : setIsDeleteOpen(true)}
        />
      </MenuCard>
      <ConfirmDialog
        isOpen={isDeleteOpen}
        handleClose={(e?: React.MouseEvent<HTMLElement>) => onDeleteCancel(e)}
        handleConfirm={onDeleteTaskConfirm}
        confirmButtonId="confirm-delete-button"
        cancelButtonId="cancel-delete-button"
        title={enFileRequirementLabel.deleteConfirmationTitle}
        text={enFileRequirementLabel.deleteConfirmationText}
      />
    </>
  )
}

export default NavBarMenu;
