import {Box, Checkbox, IconButton, Stack, Typography} from "@mui/material";
import {AccessListUser} from "types/AccessListUser";
import theme from "theme/theme";
import {enCommonLabel, enCommonManageAccess} from "constants/index";
import React, {useEffect, useState} from "react";
import {Avatar, DisabledCheckbox, EmailTooltip, InProgress} from "components/index";
import AccessRoleMenu, {AccessRoleMenuType} from "components/Drawers/ManageAccessDrawer/Objects/AccessRoleMenu";
import {SystemIcons} from "assets/icons/system/system.index";
import {auth} from "../../../../firebase";
import {AccessRole, ActionType, ProcessType, Severity} from "enums/index";
import {CheckBoxOutlineBlank} from "@mui/icons-material";
import {CollectionReference, doc} from "firebase/firestore";
import {BaseProps} from "screens/BaseProps";
import {AccessType} from "types/Permission";
import {statusSubmitHandler} from "screens/utility/statusSubmitHandler";
import {PartialUserData} from "types/PartialUserData";
import templateSubmitForm from "screens/utility/templateSubmitForm";

export interface AccessListUserWithPermission extends AccessListUser {
  canEdit?: boolean;
  canDelete?: boolean;
}

interface ListItemProps extends BaseProps {
  collectionRef: CollectionReference;
  member: AccessListUserWithPermission;
  isLoading: boolean;
  isSelected: boolean;
  allowSelect: boolean;
  allowRemove: boolean;
  access: AccessType;
  deleteSelected: (item: AccessListUser) => void;
  setSelected: (id: string, checked: boolean, role?: AccessRole) => void;
  type: AccessRoleMenuType;
  teamMemberUids: string[];
}

function ListItem(props: ListItemProps) {
  const {
    member,
    isSelected,
    allowSelect,
    allowRemove,
    access,
    isLoading,
    collectionRef,
    type,
    teamMemberUids,
  } = props;
  const {setSelected, deleteSelected, toastProps} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;
  const {"@id": id = "fillerId", name, canEdit, canDelete} = member;
  const currUserId = auth.currentUser?.uid;

  const [documentLoading, setDocumentLoading] = useState<boolean>(false);
  const [role, setRole] = useState<AccessRole | undefined>(undefined);

  const [checkboxIcon, setCheckboxIcon] = useState<JSX.Element>(<CheckBoxOutlineBlank
    sx={{color: theme.palette.neutral.main}}/>);

  const [isMemberSelected, setIsMemberSelected] = useState<boolean>(false);

  useEffect(() => {
    setDocumentLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setIsMemberSelected(!documentLoading && isSelected);
  }, [documentLoading, isSelected])

  // update checkbox icon based on member role and received access
  useEffect(() => {
    setRole(allowSelect && !allowRemove ? undefined : member.role);
    setCheckboxIcon(canEdit && canDelete && allowRemove && allowSelect
      ? <CheckBoxOutlineBlank sx={{color: theme.palette.neutral.main}}/>
      : <DisabledCheckbox/>)
  }, [access, JSON.stringify(member)]);

  async function onUpdateAccessRole(selectedRole: AccessRole) {
    setDocumentLoading(true);
    const memberRef = doc(collectionRef, id);
    const formData = {
      role: selectedRole,
      bulkActions: {
        [id]: {
          role: selectedRole,
          ...(type === AccessRoleMenuType.Team ? {teamMemberUids} : {}),
        }
      }
    }

    await templateSubmitForm(memberRef.path, ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler({status, data, isLastUpdate, successCallback, errorCallback}),
      formData
    );
  }

  function successCallback() {
    setDocumentLoading(false);
    setToastSeverity(Severity.Success);
    setToastMessage(enCommonManageAccess.editSuccess(AccessRoleMenuType[type]));
    setIsToastOpen(true);
  }

  function errorCallback(message: any) {
    let messageString = typeof message === "string" ? message : enCommonLabel.errorProcess(ProcessType.Update);
    // if object, return first object field value
    if (typeof message === "object") {
      messageString = Object.values(message)[0] as string;
    }
    setDocumentLoading(false);
    setToastMessage(messageString);
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
  }

  function updateSelected(checked: boolean) {
    setSelected(id, checked, role);
  }

  function removeMember() {
    deleteSelected(member);
  }

  return (
    <Box
      sx={{flexGrow: 1}}
      marginY={0}
      className="people-list-item"
      id={`member-list-item-${member["@id"]}`}>

      <Stack
        direction="row"
       alignContent="center"
       sx={{bgcolor: isMemberSelected ? theme.palette.background.swiftDefault : null, borderRadius: 1}}
       py={0.5}
      >

        {allowSelect && (
          <Stack>
            <Checkbox
              icon={documentLoading ? <InProgress size={16}/> : checkboxIcon}
              id={`${id}-member-item-checkbox`}
              checked={isMemberSelected}
              disabled={!(canEdit && canDelete && allowRemove && allowSelect)}
              onChange={() => updateSelected(!isSelected)}
              title={!canEdit ? enCommonManageAccess.cannotEditNotEnoughPermission("member")
                : undefined}
            />
          </Stack>
        )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{width: "100%"}}>
          <Stack direction="row" gap={1 / 2} justifyContent="flex-start" alignItems="center" width={"100%"}>
            <Avatar
              {...member}
              isTeam={type === AccessRoleMenuType.Team}
              sx={{mt: "0px", fontSize: "12px"}}
            />
            <Stack direction="column">
              <Stack direction="row" gap={0}>
                <EmailTooltip user={{...member as unknown as PartialUserData}}>
                  <Typography
                    id={`${id}-member-item-name`}
                    variant="h5">
                    {name}
                  </Typography>
                </EmailTooltip>
                <Typography variant="h5" color={theme.palette.neutral.medium}>
                  {currUserId === id && `(${enCommonLabel.you})`}
                </Typography>
              </Stack>
              <AccessRoleMenu
                id={`${id}-member-item-menu`}
                currentRole={role}
                onUpdateAccessRole={onUpdateAccessRole}
                enabled={allowSelect && canEdit && !documentLoading}
                access={access}
              />
            </Stack>
          </Stack>
          {allowRemove && !documentLoading && canDelete && (
            <IconButton
              size="small"
              onClick={removeMember}
              id={`${id}-person-item-remove-button`}
              sx={{alignSelf: "center"}}
            >
              <SystemIcons.Close
                  id={`person-item-delete-${id}`}
                  stroke={theme.palette.neutral.dark}
              />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}

export default ListItem;
