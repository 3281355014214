import ChatCard from "./ChatCard";
import {EmptyList, InProgress} from "components/index";
import {Entity} from "enums/entity";
import {Stack} from "@mui/material";
import React from "react";
import {EntityChat} from "types/EntityChat";
import {BaseProps} from "screens/BaseProps";
import EmptySearchResults from "components/EmptySearchResults";
import {ChatFilters} from "./index";

interface ChatListProps extends BaseProps {
  chatItems: EntityChat[] | null;
  fromAlgolia: boolean;
  onChatCardClick: (e: React.MouseEvent, chatItem: EntityChat) => void;
  chatFilter: ChatFilters
}

function ChatList(props: ChatListProps) {
  const {chatItems, fromAlgolia, onChatCardClick, toastProps, chatFilter} = props;

  if (chatItems === null) return <InProgress/>;

  if (chatItems.length === 0) {
    return (
      <Stack height="100%">
        {fromAlgolia ? <EmptySearchResults entity={Entity.Chat}/>
          : <EmptyList entity={Entity.Chat} logoProperties={{stroke: "none"}}/>}
      </Stack>
    );
  }

  return (
    <Stack gap={1} height="100%">
      {chatItems.map((item) => (
        <ChatCard
          chatFilter={chatFilter}
          uid={props.uid}
          key={`chat-card-${item.id}`}
          entityChat={item}
          onChatCardClick={onChatCardClick}
          toastProps={toastProps!}
        />
      ))}
    </Stack>
  )
}

export default ChatList;