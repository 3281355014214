import {BaseProps} from "../BaseProps";
import {Box} from "@mui/system";
import {enFormsButton, enFormsLabel} from "constants/index";
import {useNavigate} from "react-router-dom";
import {PrimaryButton} from "components/Button";
import {PageTitle} from "components/index";
import {AccessType, PermissionOperationKey} from "types/Permission";

interface HeadProps extends BaseProps {
  formsCount: number;
  orgFormsAccess: AccessType | null;
}

export default function Head(props: HeadProps) {
  const {formsCount, orgFormsAccess} = props;
  const nav = useNavigate();

  return (
    <Box sx={{display: "flex", justifyContent: "space-between"}}>
      <PageTitle title={enFormsLabel.forms} count={formsCount} />
      <PrimaryButton
        id="create-new-form-button"
        label={enFormsButton.createNewForm}
        onClickFcn={() => nav("create")}
        disabledHoverLabel={enFormsLabel.noPermission}
        disabled={Boolean(!orgFormsAccess?.[PermissionOperationKey.Create])}
      />
    </Box>
  )
}