import {FileRequirement} from "types/FileRequirement";
import {CollectionReference, doc} from "firebase/firestore";
import {BaseProps} from "screens/BaseProps";
import {Box, Checkbox, Divider, Stack} from "@mui/material";
import {LongText} from "components/index";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import {SwiftIconButton} from "components/Button";
import {enCommonLabel, enFileRequirementLabel, enTemplateLabel} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import React, {useEffect, useRef, useState} from "react";
import {AccessType, PermissionOperationKey} from "types/Permission";
import RightClickMenu from "../components/RightClickMenu";
import VisibilityIcon from "components/VisibilityIcon";

interface TemplateRequirementItemProps extends BaseProps {
  fileRequirement: FileRequirement;
  isChecked: boolean;
  fileRequirementsAccess: AccessType | null;
  onCheckboxToggle: (checked: boolean, id: string) => void;
  collectionRef: CollectionReference;
  editRequirement: (requirement: FileRequirement) => void;
  deleteRequirement: (requirement: string) => void;
  showCheckBox: boolean;
}

function TemplateRequirementItem(props: TemplateRequirementItemProps) {
  const {fileRequirement, isChecked, toastProps, fileRequirementsAccess, collectionRef, showCheckBox} = props;
  const {onCheckboxToggle, editRequirement, deleteRequirement} = props;
  const {"@id": id = "fillerId", name, description, hidden} = fileRequirement;
  const anchorEl = useRef(null)

  const [isRequirementChecked, setIsRequirementChecked] = useState(isChecked);
  const [isRightClickMenuOpen, setIsRightClickMenuOpen] = useState(false)
  const [anchorPosition, setAnchorPosition] = useState<{ top: number, left: number }>({top: 0, left: 0})

  useEffect(() => {
    setIsRequirementChecked(isChecked);
  }, [isChecked]);

  function onCheckboxChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    onCheckboxToggle(checked, fileRequirement["@id"] ?? "");
    setIsRequirementChecked(checked);
  }

  function onTaskCardRightClick(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    setIsRightClickMenuOpen(true);
    setAnchorPosition({top: event.clientY, left: event.clientX});
  }

  return (<>
      <RightClickMenu
        filesCount={0}
        uid={props.uid}
        anchorPosition={anchorPosition}
        anchorEl={anchorEl.current}
        isOpen={isRightClickMenuOpen}
        onClose={() => setIsRightClickMenuOpen(false)}
        toastProps={toastProps!}
        collectionRef={collectionRef}
        fileRequirement={fileRequirement}
      />

      <Stack direction="row">
        {showCheckBox && <Checkbox
            checked={isRequirementChecked}
            onClick={(e) => e.stopPropagation()}
            onChange={onCheckboxChange}
            sx={{alignSelf: "center"}}
        />}
        <Stack
          onContextMenu={onTaskCardRightClick}
          ref={anchorEl}
          key={`requirement-item-${id}`}
          className="requirement-item"
          sx={(theme) => ({
            border: `1px solid ${isRequirementChecked ? theme.palette.primary.main : theme.palette.divider}`,
            borderRadius: 1,
            cursor: "default",
            ":hover": {
              border: `1px solid ${theme.palette.primary.main}`,
            },
            backgroundColor: hidden ? undefined : theme.palette.background.default
          })}
          flex={1}
        >
          <Stack direction="row" alignItems="center">
            <Stack direction="row" flex={1} gap={1}>
              <Box sx={{alignSelf: "center", marginLeft: 1, justifyContent: "center"}}>
                <VisibilityIcon
                  hidden={hidden}
                  documentRef={doc(collectionRef, id!)}
                  hiddenMessage={enFileRequirementLabel.singleHideSuccess}
                  unHiddenMessage={enFileRequirementLabel.singleUnHideSuccess}
                  toastProps={toastProps}
                />
              </Box>
              <Divider flexItem orientation="vertical"/>
              <Stack
                direction="column"
                gap={0.5}
                alignItems="flex-start"
                flex={1}
                ml={1}
                pr={1}
                py={0.5}
              >
                <LongText variant="h4" maxLines={2} maxWidth="100%" overflowWrap={"anywhere"}>{name}</LongText>
                <LongText
                  color="text.secondary"
                  variant="bodySmall"
                  maxLines={2}
                  maxWidth="100%"
                  mb={1}
                  overflowWrap={"anywhere"}
                >
                  {!!description ? description : enCommonLabel.noDescription}
                </LongText>
                <DataTagsDisplay
                  dataTagsIds={fileRequirement.dataTagsIds || []}
                  toastProps={toastProps!}
                  listSize="medium"
                  uid={props.uid}
                  isParentHidden={hidden}
                  canEditDocumentRef={Boolean(fileRequirementsAccess?.[PermissionOperationKey.Update])}
                  documentRef={doc(collectionRef, id)}
                />
              </Stack>
              <Divider flexItem orientation="vertical"/>
              <Stack direction="column" alignItems="center">
                <Stack direction="row" alignItems="center" flex={1}>
                  <SwiftIconButton
                    onClickFcn={() => editRequirement(fileRequirement)}
                    disabled={!Boolean(fileRequirementsAccess?.[PermissionOperationKey.Update])}
                    disabledHoverLabel={enTemplateLabel.cannotEdit}
                    startIcon={(props) => <SystemIcons.Edit stroke={theme.palette.secondary.main} {...props} />}
                  />
                  <SwiftIconButton
                    onClickFcn={() => deleteRequirement(fileRequirement["@id"]!)}
                    disabled={!Boolean(fileRequirementsAccess?.[PermissionOperationKey.Delete])}
                    disabledHoverLabel={enTemplateLabel.cannotDelete}
                    startIcon={(props) => <SystemIcons.DeleteOutlined stroke={theme.palette.error.main} {...props} />}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default TemplateRequirementItem;