import {IconButton, InputAdornment, TextFieldProps} from "@mui/material";
import React, {useState} from "react";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import StandardInput from "components/inputs/StandardInput";

type StandardInputProps = TextFieldProps & {
  id?: string;
  type: "text" | "password" | "number";
}

function StandardPasswordInput(props: StandardInputProps) {
  const {type, ...rest} = props;

  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
  const [typeState, setTypeState] = useState<"password" | "text">("password");

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => {
          setIsPasswordShown(!isPasswordShown);
          setTypeState(typeState === "text" ? "password" : "text");
        }}
        sx={{mr: "0px"}}
        edge="end"
        id="toggle-password-btn"
      >
        {isPasswordShown ? (
          <SystemIcons.Eye id="show-password-icon" stroke={theme.palette.neutral.dark}/>
        ) : (
          <SystemIcons.EyeOff id="show-password-icon" stroke={theme.palette.neutral.dark}/>
        )}
      </IconButton>
    </InputAdornment>
  );

  return (
    <StandardInput
      {...rest}
      type={typeState}
      InputProps={{endAdornment}}
    />
  );
}

export default StandardPasswordInput;