import {useRTDBDocField} from "hooks/index";
import {getTemplateCopyPath} from "../screens/utility/templateSubmitForm";

/**
 * Cater
 * @param docRef {string} - doc ref path
 * @param field {string} - specific field to be fetched
 * @returns {T | null | undefined} :
 *  - T: passed type
 *  - null: initial value/unable to fetch
 *  - undefined: no permission issue but field does not exist
 */
function useTemplateRTDBDocField<T>(docRef: string | null, field: string) : T | null | undefined {
  const templateDocRef = docRef ? getTemplateCopyPath(docRef) : null;

  const projectValue = useRTDBDocField<T>(docRef, field);
  const templateValue = useRTDBDocField<T>(docRef === templateDocRef ? null: templateDocRef, field);

  if(typeof projectValue === "number" && typeof templateValue === "number")
    { // @ts-ignore
      return (projectValue ?? 0) + (templateValue ?? 0);
    }

  return projectValue !== null ? projectValue : templateValue
}

export default useTemplateRTDBDocField;