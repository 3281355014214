import {
  CollectionReference,
  limit,
  onSnapshot,
  query,
  QueryConstraint,
  startAfter
} from "firebase/firestore";
import {useEffect, useState} from "react";
import {getCollectionCount, sortObjectsBy} from "../screens/utility";
import {DirectionalOrder} from "enums/DirectionalOrder";

export interface Data {
  index: number;
  "@id": string;
  name: string;
}

const defaultReturnedFields: (keyof Data)[] = ["name"];

interface HookReturn<T> {
  data: T[] | null;
  totalCount: number | null;
  fetching: boolean;
  loadMore: () => void;
  reFetchTotalCount: () => void;
}

interface UseCollectionDataRef<T> {
  collectionRef: CollectionReference;
  constraints: QueryConstraint[] | null;
  returnedFields?: string [];
  sortKey?: keyof T;
  sortDirection?: DirectionalOrder;
  unsubscribeSnapshot?: boolean;
}

const QUERY_LIMIT = 100;

export type TypeData<T> = T & Data;

function useCollectionData<T>(props: UseCollectionDataRef<T>): HookReturn<T> {
  const {collectionRef, constraints, returnedFields = defaultReturnedFields, sortKey = "name", sortDirection = DirectionalOrder.asc} = props;

  const [data, setData] = useState<TypeData<T>[] | null>(null);
  const [lastDoc, setLastDoc] = useState<any>(null);
  const [fetching, setFetching] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number | null>(null);

  // Fetch data on mount
  useEffect(() => {

    if (fetching) return;

    const initialData = async () => {
      await fetchData();

      // fetch collection count
      const initialTotalCount = await getTotalCount();
      setTotalCount(initialTotalCount || 0);
      if (initialTotalCount === 0) {
        setFetching(false);
        setData([]);
      }
    }

      initialData();
  }, []);

  async  function getTotalCount() {
    return await getCollectionCount(collectionRef, [...(constraints || [])]);
  }

  async function fetchData() {
    if (totalCount !== null && totalCount <= (data?.length ?? 0)) {
      setFetching(false);
      return;
    }

    setFetching(true);
    const collectionQuery = query(
      collectionRef,
      ...(constraints || []),
      limit(QUERY_LIMIT),
      ...(lastDoc ? [startAfter(lastDoc)] : [])
    );

    const unsubscribe = onSnapshot(collectionQuery, (querySnapshot) => {
      if (data === null || (data ?? []).length <= (totalCount ?? 0)) {
        const collectionData = querySnapshot.docs.map((doc) => {
          const docData = doc.data();
          let returnedData: any = {};
          returnedFields.forEach((field: string) => {
            returnedData[field] = docData[field];
          });

          return {"@id": docData.id || docData["@id"], ...returnedData} as unknown as TypeData<T>;
        });

        if (collectionData.length === 0 && ((totalCount || 0) === 0)) {
          setFetching(false);
          setLastDoc(null);
          setData([]);
          return;
        }

        const sortedData = sortObjectsBy(collectionData, sortKey, sortDirection)
          .map((data, index) => ({...data, index}));

        setData(prev => [
          ...prev ?? [],
          ...sortedData
            .filter((item) => (prev ?? []).findIndex((prevItem) => prevItem["@id"] === item["@id"]) === -1)
            .map((item) => ({...item, index: (prev ?? []).length + 1}))
        ]);

        // save last doc
        if (querySnapshot.docs.length) {
          setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }

        setFetching(false);
        return;
      }

      querySnapshot.docChanges().forEach((change) => {
        const docData = change.doc.data();

        setData(prev => {
          // if the data is not null, then we need to update the data based on query type
          let currentDataCopy = structuredClone([...(prev ?? [])]);
          switch (change.type) {
            case "added":
              let newData: any = {};
              // still catch posible duplicate
              const addedIndex = currentDataCopy.findIndex((item) => item["@id"] === docData["@id"]);
              if (addedIndex === -1) {
                returnedFields.forEach((field: string) => {
                  newData[field] = docData[field];
                });
                currentDataCopy.push({...newData, index: currentDataCopy.length + 1});
              }
              break;
            case "modified":
              const modifiedIndex = currentDataCopy.findIndex((item) => item["@id"] === docData["@id"]);
              if (modifiedIndex !== -1) {
                let updatedData: any = {};
                returnedFields.forEach((field: string) => {
                  updatedData[field] = docData[field];
                });
                currentDataCopy[modifiedIndex] = updatedData;
              }
              break;
            case "removed":
              currentDataCopy = currentDataCopy.filter((item) => item["@id"] !== docData["@id"]);
              break;
            default:
              break;
          }
          return currentDataCopy.map((data, index) => ({...data, index})) as TypeData<T>[];
        });
        setFetching(false);
      });
    });

    if (props.unsubscribeSnapshot) {
      unsubscribe();
    }
  }

  async function loadMore() {
    // if we have already fetched all the data, then don't do anything
    const currentTotalCount = await getTotalCount();
    if (totalCount !== null && currentTotalCount !== totalCount) {
      setTotalCount(currentTotalCount);
    }

    await fetchData();
  }

  async function reFetchTotalCount() {
    const totalCount = await getTotalCount();
    setTotalCount(totalCount || 0);
  }

  return {data, fetching, totalCount, loadMore, reFetchTotalCount};
}


export default useCollectionData;