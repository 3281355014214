export const manageTeamCategories = {
  title: "Manage Team Categories",
  description: "Manage Team Categories description",
  label: {
    emptyTitle: "No team categories yet",
    emptyDescription: "Add the first category.",
    search: "Search Team Categories...",
    createTeamCategoryTitle: "Add Team Category",
    editTeamCategoryTitle: "Edit Team Category",
    deleteTitle: "Delete this Team Category?",
    deleteText: (catName: string) => `Are you sure you want to delete "${catName}"? This action is permanent and irreversible.`,
    deleteSuccess: (catName: string) => `Category "${catName}" has been deleted successfully.`,
    emptyListDescription: "There are no team categories",
  },
  button: {
    create: "Create Team Category",
  },
  toasts:{
    createSuccess: (teamCategory: string) => `Successfully created ${teamCategory}`,
    editSuccess: (teamCategory: string) => `Successfully edited: ${teamCategory}`
  }
}
