import React, {useState} from "react";
import {collection, DocumentReference} from "firebase/firestore";
import {ListItemIcon, ListItemText, MenuItem, Menu, Typography} from "@mui/material";
import {useComponentToggler} from "hooks/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {enFormsButton, enFormsLabel} from "constants/index";
import {ExportFormType, Severity} from "enums/index";
import {toastProps} from "../../BaseProps";
import {ExportDialog} from "components/Dialogs";
import {AccessType, PermissionOperationKey} from "types/Permission";

interface FormItemMenuProps {
  isOpen: boolean;
  docRef: DocumentReference | null;
  closeMenu: () => void;
  orgFormsAccess:  AccessType | null;
  anchorEl?: Element | null | undefined;
  toastProps?: toastProps;
}

function FormItemMenu(props: FormItemMenuProps) {
  const {isOpen, closeMenu, anchorEl, docRef, orgFormsAccess, toastProps} = props;
  const {setIsToastOpen, setToastSeverity, setToastMessage} = toastProps!;

  const [isExportDialogOpen, {open: showExportDialog, close: closeExportDialog}] = useComponentToggler(false);

  const [exportType, setExportType] = useState<ExportFormType>(ExportFormType.FormData);

  const isDisabled = !orgFormsAccess || !orgFormsAccess[PermissionOperationKey.Update];


  function onExport(type: ExportFormType) {
    if (!docRef) {
      setToastMessage(enFormsLabel.exportNoLiveData);
      setToastSeverity(Severity.Error);
      setIsToastOpen(true);
      return;
    }
    setExportType(type);
    showExportDialog();
  }

  function onCloseExportDialog() {
    closeExportDialog();
    closeMenu();
  }

  if (!isOpen || !anchorEl) return null;

  return (
    <>
      {docRef && (
        <ExportDialog
          isOpen={isExportDialogOpen}
          onClose={onCloseExportDialog}
          toastProps={toastProps!}
          title={
            exportType === ExportFormType.FormData ?
              enFormsButton.exportForm :
              enFormsButton.exportFormResponses
          }
          exportingTitle={
            exportType === ExportFormType.FormData ?
              enFormsLabel.exportingFormTitle :
              enFormsLabel.exportingFormResponsesTitle
          }
          message={
            exportType === ExportFormType.FormData ?
              enFormsLabel.exportFormText :
              enFormsLabel.exportFormResponsesText}
          exportColRef={collection(docRef, "exports")}
          exportType={exportType}
        />
      )}
      <Menu open={isOpen} anchorEl={anchorEl!} onClose={closeMenu}>
        <MenuItem
          onClick={() => isDisabled ? undefined :  onExport(ExportFormType.FormData)}
          disabled={isDisabled}
        >
          <ListItemIcon>
            <SystemIcons.Export/>
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h4">
              {enFormsButton.exportForm}
            </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => isDisabled ? undefined : onExport(ExportFormType.FormResponses)}
          disabled={isDisabled}
        >
          <ListItemIcon>
            <SystemIcons.Export/>
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h4">
              {enFormsButton.exportFormResponses}
            </Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export default FormItemMenu;