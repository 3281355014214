import {Divider, Stack, styled} from "@mui/material";
import theme from "theme/theme";

function EmptyTimelineItem() {
  return (
    <>
      <Stack direction="column" sx={{minHeight: "60px"}} />
      <EmptySeparator orientation="horizontal"/>
    </>
  )
}

/** start of styles */
export const EmptySeparator = styled(Divider)({
  margin: 0,
  padding: 0,
  marginTop: "-5.8px",
  borderTop: `3px solid ${theme.palette.neutral.light}`,
  borderBottom: 0,
});
/** end of styles */

export default EmptyTimelineItem;