export const timeLog = {
  title: "Time log",
  emptyListTitle: "No time logged yet",
  emptyListDescription: "Start tracking your time and the list of logged time will be displayed here.",

  label: {
    totalLoggedTime: "Total Logged time",
    worker: "Worker",
    started: "Started",
    ended: "Ended",
    duration: "Duration",
    location: "Location",
  },
  button: {

  }
}