import {Box, SxProps, Typography} from "@mui/material";
import {Entity} from "enums/entity";
import {EmptyPage} from "assets/arrayList/EmptyPage";
import theme from "theme/theme";
import {SVGProps} from "react";
import Theme from "theme/theme";

interface LogoProperties extends SVGProps<any> {
  fill?: string;
  stroke?: string;
  width?: number;
  height?: number;
}

type TypographyVariant = "body" |
  "button" |
  "caption" |
  "h1" |
  "h2" |
  "h3" |
  "h4" |
  "h5" |
  "h6" |
  "inherit" |
  "overline" |
  "subtitle1" |
  "subtitle2" |
  "body1" |
  "body2" |
  "bodySmall";

interface TypographyVariants {
  title?: TypographyVariant;
  subtitle?: TypographyVariant;
}

export interface EmptyListProps {
  title?: string;
  description?: string;
  entity: Entity;
  logoProperties?: LogoProperties;
  typographyVariants?: TypographyVariants;
  sx?: SxProps<typeof Theme>;
}

function EmptyList(props: EmptyListProps) {
  const {entity, description, logoProperties: iconProps, typographyVariants, sx} = props;

  const logoProperties = {
    fill: theme.palette.neutral.light,
    width: 59,
    height: 60,
    ...iconProps
  }

  const componentLogo= EmptyPage[entity].icon(logoProperties);
  const componentTitle = EmptyPage[entity].title;
  const componentDescription = description ?? EmptyPage[entity].description;

  return (
    <Box
      flex={1}
      id="empty-page"
      sx={{
        alignItems: "center",
        justify: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        ...sx
      }}
    >
      {componentLogo}
      <Typography variant={typographyVariants?.title ?? "h2"}>{componentTitle}</Typography>
      <Typography
        variant={typographyVariants?.subtitle ?? "body"}
        sx={(theme) => ({color: theme.palette.text.secondary})}>
        {componentDescription}
      </Typography>
    </Box>
  );
}


export default EmptyList;
