import theme from "theme/theme";
import {Box, Hidden, Stack} from "@mui/material";
import {enCommonLabel, enFileRequirementLabel} from "constants/index";
import React, {useEffect, useState} from "react";
import {ReferenceFile} from "types/ReferenceFile";
import {LongText} from "components/index";
import useGenerateFileReferenceThumbnail
  from "../../screens/FileRequirementOverview/utils/useGenerateFileReferenceThumbnail";
import {CarouselItem} from "components/ChatDrawer/ChatItemsView/ChatAttachmentCarousel";
import LazyCarousel from "components/LazyCarousel";
import {SwiftFile} from "types/SwiftFile";
import {Timestamp} from "firebase/firestore";
import {ReferenceFileModal} from "components/ReferenceFileUsage/ReferenceFileModal";
import {useComponentToggler} from "hooks/index";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../../firebase";
import {storageBucketKey} from "constants/envKeys";
import getEnvKey from "../../screens/utility/getEnvKey";
import getContentType from "../../screens/utility/getContentType";
import SystemIndex from "assets/icons/system/system.index";
import {referenceFilespath} from "../../screens/utility";
import {useParams} from "react-router-dom";

export type ReferenceFileUsageProps = {
  referenceFile: ReferenceFile;
  setCarouselItems: React.Dispatch<React.SetStateAction<CarouselItem[]>>;
  onFilePreviewClick: () => void;
}

export function ReferenceFileUsage(props: ReferenceFileUsageProps) {
  const {referenceFile, setCarouselItems} = props;
  const {previewPath, description, filepath, fileName, id, timeCreated} = referenceFile;
  const [showCarousel, setShowCarousel] = useState<boolean>(false);
  const [referenceSwiftFile, setReferenceSwiftFile] = useState<SwiftFile | null>(null);
  const [fileUrl, setFileUrl] = useState<string | undefined>();
  const [fileType, setFileType] = useState<string>("image/*");

  const {orgId} = useParams()

  const [isReferenceFileModalOpen, {
    open: openReferenceFileModal,
    close: closeReferenceFileModal
  }] = useComponentToggler(false);
  const storageBucket = getEnvKey(storageBucketKey);

  useEffect(() => {
    if (!filepath) return;

    const previewReference = ref(storage, `${storageBucket}${previewPath}`);
    getDownloadURL(previewReference).then(setFileUrl);

    const filePathArr = filepath.split(".");
    const fileExtension = filePathArr[filePathArr.length - 1];
    switch (fileExtension) {
      case "pdf":
        setFileType("application/pdf");
        break;
      case "mp4":
      case "mov":
      case "avi":
      case "mkv":
        setFileType("video/*");
        break;
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
        setFileType("image/*");
        break;
      default:
        setFileType("text/plain");
    }
  }, [filepath]);

  useEffect(() => {
    if (!id || !fileName || !filepath || !timeCreated) return;

    const file: SwiftFile = {
      id: id!,
      name: fileName!,
      nameIdx: fileName!,
      contentType: getContentType(filepath),
      previewPath: previewPath!,
      "#previewValue": "",
      destPath: filepath!,
      length: 0,
      lengthUploaded: 0,
      userId: "",
      timeCreated: timeCreated as unknown as Timestamp,
      timeDrop: timeCreated as unknown as Timestamp
    }
    setReferenceSwiftFile(file);
  }, [id, fileName, filepath, timeCreated]);

  return (
    <>
      {showCarousel && <LazyCarousel
        toggleAttachmentCarousel={() => {
          openReferenceFileModal();
          setShowCarousel(false);
        }}
        clickedIndex={0}
        filesColRef={referenceFilespath(orgId!)}
        displayedFiles={referenceSwiftFile ? [referenceSwiftFile]: null}
      />}

      <ReferenceFileModal
        isOpen={isReferenceFileModalOpen}
        onClose={closeReferenceFileModal}
        referenceFile={referenceFile}
        fileUrl={fileUrl}
        fileType={fileType}
        onFilePreviewClick={() => {
          setShowCarousel(true);
          closeReferenceFileModal();
        }}
        setCarouselItems={setCarouselItems}
      />

      <Stack
        onClick={openReferenceFileModal}
        maxWidth={452}
        minWidth={{xs: 200, sm: 300, md: 452, lg: 452}}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding={1}
        height={76}
        sx={{
          cursor: "pointer",
          border: `1px solid ${theme.palette.neutral.light}`,
          borderRadius: 1,
          "&:hover": {
            borderColor: theme.palette.primary.dark
          },
        }}
      >
        <Stack direction="column" ml={1}>
          <LongText variant="h5">
            {enFileRequirementLabel.seeReferenceFile}
          </LongText>
          <LongText variant="body" color={theme.palette.text.secondary} maxLines={2}>
            {!!description ? description.replace(/<br>/g, " ") : enCommonLabel.noDescription}
          </LongText>
        </Stack>
        <Hidden smDown>
          {useGenerateFileReferenceThumbnail(
            referenceFile,
            <Box sx={{
              backgroundColor: theme.palette.background.swiftDefault,
              width: 100,
              height: 60,
              display: "grid",
              placeItems: "center",
              borderRadius: 1
            }}><SystemIndex.SystemIcons.CorruptedFileOutline/></Box>
          )}
        </Hidden>
      </Stack>
    </>
  );
}