/* eslint-disable react-hooks/exhaustive-deps */
import {Input, SelectWithSearch, SimpleFormDrawer} from "components/index";
import {SystemIcons} from "assets/icons/system/system.index";
import React, {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import {Task} from "types/Task";
import {useNavigate, useParams} from "react-router-dom";
import {collection, CollectionReference, doc, getDoc, orderBy,} from "firebase/firestore";
import {Milestone, ReferenceFile} from "types/index";
import {useCollection} from "hooks/index";
import {ActionType, Entity, ProcessType, Severity} from "enums/index";
import {BaseProps} from "screens/BaseProps";
import OverFlowBox from "components/OverflowBox";
import {enCommonButton, enCommonLabel, enMilestone, enTaskLabel,} from "constants/index";
import DataTagInput from "components/DataTag/DataTagInput";
import {areObjectsEqual, getKeyValue, onChangeInput, referenceFilespath, tasksPath} from "screens/utility";
import {db} from "../../../firebase";
import handleEnterKeyPress from "screens/utility/handleEnterKeyPress";
import {statusSubmitHandler} from "screens/utility/statusSubmitHandler";
import FileReferenceInput from "components/inputs/FileReferenceInput";
import {SelectItem} from "components/inputs/SearchInput/SearchPopUp";
import useCollectionWithTemplate from "hooks/useCollectionWithTemplate";
import templateSubmitForm from "screens/utility/templateSubmitForm";
import {submitForm} from "screens/utility";

interface EditTaskDrawerProps extends BaseProps {
  isOpen: boolean;
  onClose: () => void;
  task: Task;
  parentEntity?: Entity;
  fromTemplates?: boolean;
  taskCollectionRef: CollectionReference;
}

function EditTaskDrawer(props: EditTaskDrawerProps) {
  const {isOpen, toastProps, task, taskCollectionRef, uid, parentEntity = Entity.Milestone, fromTemplates = false} = props;
  const {"@id": taskId = "fillerId", milestoneId} = task;
  const {onClose} = props;

  const {setIsToastOpen, setToastSeverity, setToastMessage} = toastProps!;
  const {orgId, projId, templateId, assetId} = useParams();

  // since there is a possibility that we are processing tasks from templates, check the fromTemplates indicator
  // if false, derive the tasks path from params
  const collectionRef = fromTemplates || !!templateId ? taskCollectionRef : tasksPath(orgId!, projId!, assetId!, milestoneId!, undefined);
  const taskDocRef = doc(collectionRef, taskId);

  const navigate = useNavigate();
  const milestonesCollectionRef = collection(db, taskCollectionRef.parent?.parent.path!);

  const {data: milestones} = useCollectionWithTemplate<Milestone>({
    colRef: milestonesCollectionRef,
    templateConstraints: [orderBy("timeCreated")],
    projectConstraints: [orderBy("timeCreated")],
  });
  const selectableMilestones: SelectItem[] = milestones ? milestones.map((milestone) => ({
    searchable: milestone.name,
    value: milestone["@id"]!,
    displayItem: milestone.name
  })) : [];

  const readOnly = parentEntity !== Entity.Templates && Boolean(task.templateId);

  const [taskMilestone, setTaskMilestone] = useState<Milestone | null>(null);
  const [taskName, setTaskName] = useState<string>("");
  const [taskDescription, setTaskDescription] = useState<string>("");
  const [isTaskAllDay, setIsTaskAllDay] = useState<boolean | undefined>(false);
  const [isTaskPrivate, setIsTaskPrivate] = useState<boolean | undefined>(false);
  const [dataTagsIds, setDataTagsIds] = useState<string[]>([]);
  const [previousMilestoneId, setPreviousMilestoneId] = useState<string | null>(null);
  const [referenceFileId, setReferenceFileId] = useState<string | null>(task.referenceFileId ?? null);

  const [timezone, setTimezone] = useState<string | undefined>();

  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [referenceFiles] = useCollection<ReferenceFile>(null, referenceFilespath(orgId!));

  // Form Validation messages
  const [taskNameError, setTaskNameError] = useState<string>("");

  useEffect(() => {
    if (!milestones || taskMilestone) return;

    setTaskMilestone(
      milestones.find(milestone => milestone["@id"] === task!.milestoneId) ??
      milestones.find(milestone => milestone.name === "Misc")!
    );
  }, [milestones]);

  useEffect(() => {
    if (!isOpen) return;
    if (!task) return;
    setTaskName(task.name ?? "");
    setTaskDescription(task.description ?? "");
    setIsTaskAllDay(task.dueTimeAllDay ?? false);
    setIsTaskPrivate(task.isPrivate ?? false);
    setDataTagsIds(task.dataTagsIds?.filter(val => val !== task.referenceFileId).sort() ?? []);
    setTaskMilestone(task.milestoneId ? (milestones ?? []).find(milestone => milestone["@id"] === task!.milestoneId) ?? null : null);
    setPreviousMilestoneId(task.milestoneId);
    setTimezone(task.timezone);
    setReferenceFileId(task.referenceFileId || null);
  }, [isOpen, task]);

  // Form effects - Validation logic here
  useEffect(() => {
    if (!(taskName.trim())) {
      setTaskNameError(enTaskLabel.nameRequired);
    } else {
      setTaskNameError("");
    }
  }, [taskName]);

  function onSelectedMilestoneChange(selectedValue: string) {
    if (!milestones) return;

    const selectedMilestone = milestones.find(
      (milestone) => milestone["@id"]! === selectedValue
    );

    if (!selectedMilestone) return;

    setTaskMilestone(selectedMilestone!);
  }

  async function onSubmit() {
    if (!taskMilestone) return;
    setIsFormSubmitting(true);

    const updatedTask: Partial<Task> = {
      name: taskName,
      description: taskDescription,
      dueTimeAllDay: isTaskAllDay,
      milestoneId: taskMilestone["@id"]!,
      isPrivate: isTaskPrivate ?? false,
      ...(!!timezone && {timezone}),
      ...(taskMilestone["@id"] !== previousMilestoneId && {deleted: true}),
    }

    if (referenceFileId) {
      updatedTask.referenceFileId = referenceFileId;
      updatedTask.dataTagsIds = [...dataTagsIds, referenceFileId]
    } else {
      updatedTask.referenceFileId = null;
      updatedTask.dataTagsIds = dataTagsIds.filter(id => id !== task.referenceFileId);
    }

    // asset/assetid/milestone/milestoneid/task/taskid
    // if its new milestone selected, check if the newly selected milestone is already present in the asset
    if (taskMilestone["@id"] !== previousMilestoneId) {
      const newMilestoneDocRef = doc(milestonesCollectionRef, taskMilestone["@id"]!);
      const newMilestoneDoc = await getDoc(newMilestoneDocRef);
      if (!newMilestoneDoc.exists()) {
        const newMilestone = newMilestoneDoc.data() as Milestone;
        await templateSubmitForm(newMilestoneDocRef.path, ActionType.Create,
          (status, data, isLastUpdate) => statusSubmitHandler<Milestone>({
            status,
            data,
            isLastUpdate,
            successCallback: async () => {
              await submitForm(taskDocRef, ActionType.Update,
                (status, data, isLastUpdate) => statusSubmitHandler<Task>({
                  status,
                  data,
                  isLastUpdate,
                  successCallback: () => successCallback(data),
                  errorCallback
                }),
                updatedTask,
                task
              );
            },
          }),
          {...newMilestone, templateId: task.templateId ?? null}
        )
        return;
      }
    }

    await templateSubmitForm(taskDocRef.path, ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler<Task>({
        status,
        data,
        isLastUpdate,
        successCallback: () => successCallback(data),
        errorCallback
      }),
      updatedTask,
      task
    );
  }

  function successCallback(data: Task) {
    setToastMessage(enTaskLabel.editSuccess);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    setIsFormSubmitting(false);

    if (data?.milestoneId !== previousMilestoneId) {
      parentEntity === Entity.Templates ? navigate(`/${orgId}/templates/${templateId}/overview`)
        : !!assetId ? navigate(`/${orgId}/projects/${projId}/assets/${assetId}`)
          : navigate(`/${orgId}/projects/${projId}/project-level-tasks`);
    }

    onClose();
  }

  function errorCallback(message: any) {
    setIsFormSubmitting(false);

    if (typeof (message) === "object") {
      setTaskNameError(getKeyValue(message, "name", ""));
      return;
    }

    setToastMessage(message || enCommonLabel.errorProcess(ProcessType.Update));
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
    onClose();
  }

  function isDrawerModified() {
    if (!taskMilestone || !task) return;

    task.referenceFileId = task.referenceFileId || null;
    const sortedDataTag = [
      ...dataTagsIds,
      ...(referenceFileId ? [referenceFileId] : []),
    ].sort();


    const updatedTask: Task = {
      ...task,
      name: taskName,
      description: taskDescription,
      milestoneId: taskMilestone["@id"]!,
      dataTagsIds: sortedDataTag,
      isPrivate: isTaskPrivate,
    }

    let taskDataTags = task.dataTagsIds ? task.dataTagsIds : [];
    if (task.referenceFileId)
      taskDataTags.push(task.referenceFileId);
    taskDataTags = taskDataTags.sort()

    return !areObjectsEqual(updatedTask, {
      ...task,
      dataTagsIds: taskDataTags
    })
  }

  const isFormValid = !taskNameError && Boolean(isDrawerModified()) && !isFormSubmitting;

  return (
    <>
      <SimpleFormDrawer
        title={enTaskLabel.edit}
        buttonId="editTaskSubmit"
        icon={<SystemIcons.Edit width={24} height={24}/>}
        id="editTaskDrawer"
        isFormValid={isFormValid}
        isLoading={isFormSubmitting}
        isOpen={isOpen}
        rightButtonLabel={enCommonButton.save}
        leftButtonLabel={enCommonButton.cancel}
        onSubmit={onSubmit}
        onClose={onClose}
        unsavedChanges={isDrawerModified()}
      >
        <OverFlowBox>
          <Input
            label={enCommonLabel.name}
            placeholder={enCommonLabel.name}
            value={taskName}
            validationMessage={taskNameError}
            sx={{mb: 1}}
            onClick={e => e.stopPropagation()}
            onChange={(e) => onChangeInput(e, setTaskName)}
            disabled={readOnly}
            onKeyPress={(e) => handleEnterKeyPress(e, isFormValid, isFormSubmitting, onSubmit)}
          />
          <Input
            label={enCommonLabel.description}
            id="taskDescription"
            value={taskDescription}
            sx={{mb: 1}}
            optional={true}
            placeholder=""
            maxRows={5}
            minRows={5}
            onChange={(e) => onChangeInput(e, setTaskDescription)}
            multiline
            disabled={readOnly}
            onClick={e => e.stopPropagation()}
            onKeyPress={(e) => handleEnterKeyPress(e, isFormValid, isFormSubmitting, onSubmit)}
          />
          <SelectWithSearch
            id="selectWithSearch"
            searchInputId="searchMilestone"
            searchPlaceholder={enMilestone.label.search}
            LabelComponent={
              <Typography variant="h5">
                {enMilestone.title}
              </Typography>
            }
            defaultValue={!taskMilestone ? undefined : taskMilestone["@id"]}
            items={selectableMilestones}
            handleSelectCallback={onSelectedMilestoneChange}
            sx={{mb: 1}}
            disabled={readOnly}
          />
          <FileReferenceInput
            referenceFiles={referenceFiles ?? []}
            handleSelectCallback={(value) => {
              if (value) {
                setReferenceFileId(value)
                const referenceFileIdsArray = referenceFiles?.map(ref => ref.id);
                const clearedTags = dataTagsIds.filter(id => !referenceFileIdsArray?.includes(id))
                setDataTagsIds(clearedTags);
              } else {
                setReferenceFileId(null)
                setDataTagsIds(dataTagsIds.filter(id => id !== task.referenceFileId))
              }
            }}
            defaultValue={task?.referenceFileId ?? undefined}
          />
          <DataTagInput
            uid={uid}
            toastProps={toastProps}
            initialTags={dataTagsIds}
            handleSelectCallback={setDataTagsIds}
            entity={Entity.Task}
            componentId={"tasksDataTags"}
            onKeyPress={(e) => handleEnterKeyPress(e, isFormValid, isFormSubmitting, onSubmit)}
          />
        </OverFlowBox>
      </SimpleFormDrawer>
    </>
  )
}

export default EditTaskDrawer
