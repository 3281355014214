import {Entity} from "enums/entity";
import {Grid, Hidden, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {defaultAssignee, enTaskLabel} from "constants/index";
import {Avatar, EmailTooltip} from "components/index";
import TimelineSchedule from "../TimelineSchedule";
import {BaseProps} from "screens/BaseProps";
import {CollectionReference} from "firebase/firestore";
import TaskScheduleDrawer from "../../../components/Drawers/TaskScheduleDrawer";
import {useComponentToggler} from "hooks/index";
import {PartialUserData, Task} from "types/index";

interface TaskTimelineAndScheduleProps extends BaseProps {
  task: Task;
  canUpdate: boolean;
  taskCollectionRef: CollectionReference;
  parentEntity: Entity;
}

function TaskTimelineAndSchedule(props: TaskTimelineAndScheduleProps) {
  const {task, parentEntity, uid, taskCollectionRef, toastProps, canUpdate} = props;
  const {timelineStatus, taskSchedule, assignedTo, approver, observer, taskStatus} = task;

  const taskAssignedTo = assignedTo ?? defaultAssignee;
  const taskApprover = approver ?? defaultAssignee;
  const taskObserver = observer ?? defaultAssignee;

  const [isDrawerOpen, {open: openDrawer, close: closeDrawer}] = useComponentToggler(false);

  if (parentEntity === Entity.Templates) return null;

  const taskScheduleData = {
    canUpdate,
    task,
    taskCollectionRef,
    timelineStatus,
    taskStatus,
    taskSchedule: {...taskSchedule},
    toastProps: toastProps!,
  }

  return (<>
      {isDrawerOpen && (
          <TaskScheduleDrawer
              uid={uid}
              toastProps={toastProps!}
              task={task!}
              isDrawerOpen={isDrawerOpen}
              onClose={closeDrawer}
              collectionRef={taskCollectionRef}
          />
      )}
    <Hidden xsDown>
              <Stack direction="row" gap={5}>
                {/*Assigned To*/}
                <Stack direction="column" alignItems="left" gap={0} spacing={0} mt={1}>
                  <Stack justifyContent="left">
                    <Typography
                        variant="body"
                        color={theme.palette.text.secondary}
                        sx={{fontSize: "12px", margin: 0, padding: 0, textWrap: "nowrap"}}>
                      {enTaskLabel.assignedTo}
                    </Typography>
                  </Stack>
                  <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="left"
                      gap={1}
                      sx={{margin: 0, padding: 0, cursor: taskScheduleData.canUpdate ? "pointer" : "default", textWrap: "nowrap"}}
                      onClick={taskScheduleData.canUpdate ? openDrawer : undefined}
                  >
                    <Avatar
                        {...taskAssignedTo}
                        sx={{
                          marginTop: 0,
                          width: 20,
                          height: 20,
                          fontSize: '8px'
                        }}
                        nameComponent={(
                              <Typography variant="h5" id="task-assigned-to">
                                {!taskAssignedTo?.name ? enTaskLabel.unassigned : taskAssignedTo.name}
                              </Typography>
                        )}
                    />
                  </Stack>
                </Stack>

                {/*Approver*/}
                <Stack direction="column" alignItems="left" gap={0} spacing={0} mt={1}>
                  <Stack justifyContent="left">
                    <Typography
                        variant="body"
                        color={theme.palette.text.secondary}
                        sx={{fontSize: "12px", margin: 0, padding: 0, textWrap: "nowrap"}}>
                      {enTaskLabel.approver}
                    </Typography>
                  </Stack>
                  <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="left"
                      gap={1}
                      sx={{margin: 0, padding: 0, cursor: taskScheduleData.canUpdate ? "pointer" : "default", textWrap: "nowrap"}}
                      onClick={taskScheduleData.canUpdate ? openDrawer : undefined}
                  >
                    <Avatar
                        {...taskApprover}
                        sx={{
                          marginTop: 0,
                          width: 20,
                          height: 20,
                          fontSize: '8px'
                        }}
                        nameComponent={(
                            <Typography variant="h5" id="task-assigned-to">
                              {!taskApprover?.name ? enTaskLabel.unassigned : taskApprover.name}
                            </Typography>
                        )}
                    />
                  </Stack>
                </Stack>

                {/*Observer*/}
                <Stack direction="column" alignItems="left" gap={0} spacing={0} mt={1}>
                  <Stack justifyContent="left">
                    <Typography
                        variant="body"
                        color={theme.palette.text.secondary}
                        sx={{fontSize: "12px", margin: 0, padding: 0, textWrap: "nowrap"}}>
                      {enTaskLabel.observer}
                    </Typography>
                  </Stack>
                  <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="left"
                      gap={1}
                      sx={{margin: 0, padding: 0, cursor: taskScheduleData.canUpdate ? "pointer" : "default", textWrap: "nowrap"}}
                      onClick={taskScheduleData.canUpdate ? openDrawer : undefined}
                  >
                    <Avatar
                        {...taskObserver}
                        sx={{
                          marginTop: 0,
                          width: 20,
                          height: 20,
                          fontSize: '8px'
                        }}
                        nameComponent={(
                            <Typography variant="h5" id="task-assigned-to">
                              {!taskObserver?.name ? enTaskLabel.unassigned : taskObserver.name}
                            </Typography>
                        )}
                    />
                  </Stack>
                </Stack>
              </Stack>
    </Hidden>
  </>)
}

export default TaskTimelineAndSchedule;
