import {toastProps} from "screens/BaseProps";
import {db} from "../../../firebase";
import {Task} from "types/Task";
import {collection, doc} from "firebase/firestore";
import {useCollection, useComponentToggler} from "hooks/index";
import React, {useEffect, useState} from "react";
import {Box, Checkbox, Collapse, Skeleton, Stack} from "@mui/material";
import {sortObjectsBy} from "../../utility";
import TaskItem from "./TaskItem";
import {AccessType} from "types/Permission";
import {Entity} from "enums/entity";
import EditTaskDrawer from "components/Drawers/EditTaskDrawer/EditTaskDrawer";

interface MilestoneTaskListProps {
  milestoneDocumentRef: string;
  toastProps: toastProps;
  isExpanded: boolean;
  isCheckboxShown: boolean;
  taskAccess: AccessType | null;
  uid: string;
  showHidden: boolean;
  checkedTasks: { taskId: string, milestoneId: string }[];
  displayedTasks: { taskId: string, milestoneId: string }[];
  checkTask: (taskId: string, milestoneId: string) => void;
  tasksCount: number | undefined;
  deleteTask: (task: Task) => void;
  fromSearch: boolean;
}

function MilestoneTaskList(props: MilestoneTaskListProps) {
  const {milestoneDocumentRef, isCheckboxShown, showHidden, tasksCount} = props;

  const taskCollectionRef = collection(db, milestoneDocumentRef, "tasks");
  const [tasks, , setTasksRef] = useCollection<Task>(null, taskCollectionRef, [], false, true);
  const [displayedTasks, setDisplayedTasks] = useState<Task[] | null>(null);

  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [isDrawerOpen, {open: openDrawer, close: closeDrawer}] = useComponentToggler(false);

  useEffect(() => {
    if (props.fromSearch) return;

    setTasksRef(taskCollectionRef);
  }, [props.fromSearch, props.isExpanded]);

  useEffect(() => {
    if (tasks === null) return;

    if (props.fromSearch) {
      const newTasks: Task[] = [];
      props.displayedTasks.forEach(displayedTask => {
        const taskItem = tasks?.find(task => task["@id"] === displayedTask.taskId);
        if (!taskItem) return;
        newTasks.push(taskItem);
      });

      setDisplayedTasks(showHidden ? newTasks : newTasks.filter(task => !task.hidden));
      return;
    }

    const newTasks = sortObjectsBy([...tasks], "name", "asc");
    setDisplayedTasks(showHidden ? newTasks : newTasks.filter(task => !task.hidden));
  }, [tasks, showHidden]);

  function editTask(task: Task) {
    setSelectedTask(task);
    openDrawer();
  }

  if (!props.isExpanded) return null;

  if (displayedTasks === null) {
    return <Skeleton variant="rounded" height={(tasksCount || 2) * 100} animation="wave" />;
  }

  return (
    <div>
      <Collapse
        in={props.isExpanded}
        sx={{transitionDelay: props.isExpanded ? '500ms' : '0ms'}}
        timeout={1000}
      >
        <div>
          {displayedTasks.map(task => {
            if (!task["@id"] || task.deleted) return null;

            const taskRef = doc(db, milestoneDocumentRef, "tasks", task["@id"]!);

            return (
              <Stack
                key={`milestone-task-list-${task["@id"!]}`}
                direction="row"
              >
                {isCheckboxShown && (
                  <Stack justifyContent="center" alignItems="center">
                    <Checkbox
                      className="task-checkbox"
                      checked={props.checkedTasks.some(checkedTask => checkedTask.taskId === task["@id"])}
                      sx={{paddingLeft: 1}}
                      onChange={() => props.checkTask(task["@id"]!, task.milestoneId!)}
                    />
                  </Stack>
                )}
                <Box
                  border={0}
                  borderColor="transparent"
                  borderRadius={1}
                  flex={1}
                  sx={{cursor: "pointer", maxWidth: "100%",}}
                  marginY={-0.5}
                  marginLeft={0}
                  className="milestone-task-item"
                >
                  <TaskItem
                    taskId={task["@id"]!}
                    taskRefPath={taskRef.path}
                    isChecked={false}
                    isMilestoneCheckboxShown={false}
                    toastProps={props.toastProps!}
                    taskAccess={props.taskAccess!}
                    uid={props.uid}
                    deleteTask={props.deleteTask}
                    editTask={editTask}
                    checkTask={(taskId) => props.checkTask(taskId, task.milestoneId!)}
                  />
                </Box>
              </Stack>
            )
          })}
        </div>
      </Collapse>
      {selectedTask && (
        <EditTaskDrawer
          fromTemplates={true}
          taskCollectionRef={taskCollectionRef}
          parentEntity={Entity.Templates}
          isOpen={isDrawerOpen}
          onClose={closeDrawer}
          task={selectedTask}
          uid={props.uid}
          toastProps={props.toastProps!}
        />
      )}
    </div>
  )
}

export default MilestoneTaskList;