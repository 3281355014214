/* eslint-disable react-hooks/exhaustive-deps */
import {CollectionReference, doc} from "firebase/firestore"
import React, {ChangeEvent, Dispatch, useEffect, useState} from "react"
import {SystemIcons} from "assets/icons/system/system.index"
import {Input, SimpleFormDrawer} from "components/index"
import {ActionType, Severity} from "enums/index"
import {useTempDocRef} from "hooks/index";
import {Milestone} from "types/index"
import {onChangeInput, submitForm} from "screens/utility"
import {BaseProps} from "screens/BaseProps";
import {MilestoneAction, MilestoneActionType} from "hooks/useMilestoneReducer";
import {emptyFunction, enCommonButton, enCommonLabel, enMilestoneLabel,} from "constants/index";
import useCheckUniqueness from "hooks/useCheckUniqueness";
import {en} from "language/en";
import {StringDictionary} from "types/FormBase";
import handleEnterKeyPress from "../../../screens/utility/handleEnterKeyPress";
import {statusSubmitHandler} from "../../../screens/utility/statusSubmitHandler";

interface DrawerProps extends BaseProps {
  collectionRef: CollectionReference;
  onDrawerClose: () => void;
  drawerVisibility: boolean;
  isEdit?: boolean;
  milestone?: Milestone | null;
  index?: number;
  dispatch?: Dispatch<MilestoneAction>;
}

function Drawer(props: DrawerProps) {
  const {collectionRef, drawerVisibility, isEdit, milestone, index} = props;
  const {onDrawerClose, toastProps, dispatch = emptyFunction} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [milestoneName, setMilestoneName] = useState<string>("");
  const [milestoneDescription, setMilestoneDescription] = useState<string>("");
  const [projectTemplateKey, setProjectTemplateKey] = useState<string>("");
  const [milestoneNameMessage, setMilestoneNameMessage] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<StringDictionary>({});

  const [tempDocRef, setTempDocRef] = useTempDocRef(collectionRef);
  const isNameUnique = useCheckUniqueness(collectionRef, "name", milestoneName, tempDocRef.id);
  const readOnly = typeof (milestone?.templateMilestoneId) === "string";

  function handleNameChange(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | React.ClipboardEvent<HTMLDivElement>) {
    const target = e.target as HTMLInputElement;
    if (target.value.trim().length > 0) {
      setMilestoneName(target.value);
      setMilestoneNameMessage("");
    } else {
      setMilestoneNameMessage(enMilestoneLabel.nameRequired);
      setMilestoneName(target.value);
    }
  }

  function resetFormData() {
    const isDrawerEdit = (isEdit && !!milestone);
    setMilestoneName(isDrawerEdit ? milestone?.name : "");
    setMilestoneDescription(isDrawerEdit ? (milestone?.description ?? "") : "");
    setProjectTemplateKey(isDrawerEdit ? (milestone?.projectTemplateKey ?? "") : "");
    setValidationErrors({});
    setIsValid(false);
    isDrawerEdit ? setTempDocRef(doc(collectionRef, milestone!["@id"])) : emptyFunction();
  }

  useEffect(() => {
    resetFormData();
  }, [drawerVisibility]);

  useEffect(() => {
    setMilestoneNameMessage("");
    Object.keys(validationErrors).forEach((key) => {
      switch (key) {
        case "name":
          setMilestoneNameMessage(validationErrors[key]);
          break;
        default:
          break; // MORE VALIDATIONS HERE
      }
    });

    if (!isNameUnique) {
      setMilestoneNameMessage(en.common.validations.uniqueName);
    }
  }, [validationErrors, isNameUnique]);

  useEffect(() => {
    setIsValid(
      (isEdit && !!milestone) ? (
        !milestoneNameMessage && (
          milestoneName !== (milestone?.name ?? "") ||
          milestoneDescription !== (milestone?.description ?? "") ||
          projectTemplateKey !== (milestone?.projectTemplateKey ?? "")
        )
      ) : (milestoneNameMessage === "" && milestoneName !== "")
    );

    if (!isNameUnique) {
      setIsValid(false)
      return
    }
  }, [milestoneName, milestoneDescription, projectTemplateKey, isNameUnique]);

  async function onFormSubmit() {
    setIsLoading(true);
    const newMilestone: Partial<Milestone> = {
      name: milestoneName,
      description: milestoneDescription,
      projectTemplateKey,
    }

    const actionType = (isEdit) ? ActionType.Update : ActionType.Create;
    await submitForm(
      tempDocRef!,
      actionType,
      (status, data, isLastUpdate) => statusSubmitHandler({
        status,
        data,
        isLastUpdate,
        successCallback: () => successCallback({...data, "@id": tempDocRef!.id}),
        errorCallback
      }),
      newMilestone,
      milestone
    );
  }

  function successCallback(data: any) {
    //manually update milestones
    if (isEdit && dispatch && index !== undefined) {
      dispatch({type: MilestoneActionType.updateMilestone, payload: {index: index, milestone: data}})
    } else if (!isEdit && dispatch) {
      dispatch({type: MilestoneActionType.addMilestone, payload: data})
    }
    setIsLoading(false);
    setToastMessage((isEdit) ? enMilestoneLabel.updateSuccess : enMilestoneLabel.createSuccess);
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    setTempDocRef();
    onDrawerClose();
  }

  function errorCallback(message: any) {
    setIsLoading(false);
    if (typeof (message) === "object") {
      setValidationErrors(message as StringDictionary);
      setTempDocRef();
      return;
    }

    setToastMessage(message);
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
  }

  function isUnsavedChangesOpen() {
    return (isEdit && !!milestone) ? (
      milestoneName !== milestone.name ||
      milestoneDescription !== milestone.description ||
      projectTemplateKey !== milestone.projectTemplateKey
    ) : (
      !!milestoneName ||
      !!milestoneDescription ||
      !!projectTemplateKey
    )
  }

  return (
    <>
      <SimpleFormDrawer
        id="create-milestone"
        buttonId="create-milestone-btn"
        rightButtonLabel={isEdit ? enCommonButton.save : enCommonButton.create}
        title={(!isEdit) ?
          enMilestoneLabel.create :
          enMilestoneLabel.edit}
        isOpen={drawerVisibility}
        onClose={onDrawerClose}
        icon={(!isEdit) ?
          <SystemIcons.Milestone/> :
          <SystemIcons.Edit/>}
        isFormValid={isValid}
        isLoading={isLoading}
        onSubmit={onFormSubmit}
        unsavedChanges={isUnsavedChangesOpen()}
      >
        <Input
          label={enCommonLabel.name}
          id="Name"
          onChange={handleNameChange}
          sx={{mb: 1}}
          value={milestoneName}
          optional={false}
          validationMessage={milestoneNameMessage}
          placeholder=""
          disabled={readOnly}
          onKeyPress={(e) => handleEnterKeyPress(e, isValid, isLoading, onFormSubmit)}
        />
        <Input
          label={enCommonLabel.description}
          id="milestoneDescription"
          onChange={(e) => onChangeInput(e, setMilestoneDescription)}
          sx={{mb: 1}}
          value={milestoneDescription}
          optional={true}
          placeholder=""
          disabled={readOnly}
          onKeyPress={(e) => handleEnterKeyPress(e, isValid, isLoading, onFormSubmit)}
        />
      </SimpleFormDrawer>
    </>
  )
}

export default Drawer;
