import {Box, Stack, Typography} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {CollapsableText, SelectStatus} from "components/index";
import {enProjectLabel} from "constants/index";
import {doc} from "firebase/firestore";
import {ProjectStatusWithIcons} from "assets/arrayList/ProjectStatus";
import React, {useEffect, useState} from "react";
import {StatusItem} from "types/StatusItem";
import {toastProps} from "../../BaseProps";
import {db} from "../../../firebase";
import {Project} from "types/Project";
import CardBadge, {OverlayType} from "components/CardBadge";
import {projectPath} from "../../utility";
import useImportBuild from "hooks/useImportBuild";

export interface TitleAndStatusProps {
  canUpdateStatus: boolean;
  projectDocPath: string;
  project: Project;
  toastProps: toastProps;
}

function TitleAndStatus(props: TitleAndStatusProps) {
  const {project, canUpdateStatus, toastProps} = props;
  const {orgName, isPrivate, name, description, orgId, "@id": id} = project!;

  const {progress, isImport} = useImportBuild({
    path: projectPath(orgId!, id!).path,
  });

  const [selectedStatus, setSelectedStatus] = useState<StatusItem | undefined>(undefined);

  // set selected option
  useEffect(() => {
    // if the value is the same, don't update
    if (selectedStatus === ProjectStatusWithIcons[project.projectStatus]) return;

    setSelectedStatus(ProjectStatusWithIcons[project.projectStatus]);
  }, [project.projectStatus]);

  return (
    <Stack
      direction={{xs: "column", sm: "column", md: "column", lg: "row", xl: "row"}}
      display="flex"
      gap={2}
      justifyContent="space-between"
    >
      <Stack direction="column" sx={{gap: 1}}>
        <Stack direction="row" sx={{gap: 1}}>
          {isPrivate && (
            <>
              <Box sx={{display: "flex", alignItems: "start", pt: 1}}>
                <SystemIcons.LockFilled
                  fill={theme.palette.neutral.dark}
                  stroke={theme.palette.neutral.dark}
                />
              </Box>
            </>
          )}
          <Stack direction="row" justifyContent="center" gap={1} alignItems="center">
            <Typography variant="h1" sx={{overflowWrap: "anywhere"}}>
              {`${orgName}/${name}`}
            </Typography>
            <CardBadge
              id={`project-badge-${project["@id"]}`}
              text={isImport ? enProjectLabel.importingProjectData: enProjectLabel.buildingProjectData}
              buildingProgressCount={progress}
              toastProps={props.toastProps!}
              process={isImport ? OverlayType.Importing: OverlayType.Building}
              sx={{
                height: "fit-content",
                position: "relative",
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
              }}
            />
          </Stack>
        </Stack>
        <CollapsableText text={description} overflowWrap="anywhere"/>
      </Stack>
      <Stack
        alignSelf="left"
        direction="row"
        alignItems="end"
        gap={2}
        sx={{flexWrap: {xs: "wrap", md: "noWrap"}}}
      >
        <Stack
          direction={{xs: "row", sm: "row", md: "row", lg: "column", xl: "column"}} sx={{gap: 0.5}}
          alignItems={{xs: "center", sm: "center", md: "center", lg: "flex-start", xl: "flex-start"}}
        >
          <Typography
            variant="subtitle2"
            sx={(theme) => ({color: theme.palette.text.secondary})}
          >
            {enProjectLabel.status}
          </Typography>

          <SelectStatus
            toastProps={toastProps!}
            id="menu"
            documentRef={doc(db, props.projectDocPath)}
            statusField="projectStatus"
            items={ProjectStatusWithIcons}
            selectItemSx={{padding: 0.5, variant: "body1"}}
            selected={selectedStatus}
            isDisabled={!canUpdateStatus}
            selectIconSx={{marginLeft: 1}}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default TitleAndStatus;