import {Box, Stack} from "@mui/material";
import {styled} from "@mui/material/styles";
import theme from "theme/theme";
import {LongText} from "components/index";

export interface PillItemProps {
  label: string;
  onClickFcn: () => void;
  icon: JSX.Element;
  hoverLabel?: string;
  isLast?: boolean;
  id: string;
}

function PillItem(props: PillItemProps) {
  const {label, onClickFcn, icon, isLast} = props;

  return (
    <Pill
      onClick={() => onClickFcn()}
      sx={{
        backgroundColor: isLast ?
          theme.palette.background.swiftDefault :
          null,
        ...(!isLast ? {cursor: "pointer"} : {cursor: "default"}),
      }}
    >
      <Stack direction={"row"} alignItems="center" gap={0.5}>
        {icon}
        <LongText
          maxLines={1}
          maxWidth="24ch"
          variant="body"
          color={isLast ? theme.palette.neutral.dark : theme.palette.secondary.main}
        >
          {label}
        </LongText>
      </Stack>
    </Pill>
  )
}

const Pill = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  borderRadius: "40px",
  padding: "5px 16px",
  border: `1px solid ${theme.palette.divider}`,
  alignItems: "center",
});

export default PillItem;