import React from "react";
import {Paper, SxProps} from "@mui/material";

interface FormContainerProps {
  children: React.ReactNode;
  sx?: SxProps;
}

function FormContainer(props: FormContainerProps) {
  const {children, sx = {}} = props;

  return (
    <Paper
      sx={{
        position: "relative",
        padding: 4,
        display: "flex",
        zIndex: 2,
        justifyContent: "center",
        borderRadius: 5,
        alignItems: "center",
        minWidth: {xs: "unset", sm: "unset", md: "30%", lg: "20%", xl: "20%"},
        minHeight: {xs: "unset", sm: "unset", md: "60%", lg: "60%", xl: "60%"},
        height: {xs: "80%", sm: "60%", md: "unset", lg: "unset", xl: "unset"},
        width: {xs: "80%", sm: "60%", md: "unset", lg: "unset", xl: "unset"},
        ...sx
      }}
    >
      {children}
    </Paper>
  )
}

export default FormContainer;