import {useMemo} from "react";
import {useParams} from "react-router-dom";

function useGetProjectBasedPath(docRef: string | null){
  const {assetId, projId} = useParams();

  return useMemo(() => {
    if(!docRef)
      return null

    if(!assetId ||  !projId)
      return docRef

    const regex = /templates\/\w+/
    return docRef.replace(regex, `projects/${projId}/assets/${assetId}`)
  }, [assetId, projId])
}

export default useGetProjectBasedPath