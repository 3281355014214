import {List, Menu,} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import {defaultMilestoneId, emptyFunction, enMilestoneButton} from "constants/index";
import ActionOption from "components/ActionOption";
import React, {MouseEvent, useState} from "react";
import DownloadOptionsMenu from "components/Dialogs/DownloadConfirmDialog/DownloadOptionsMenu";
import {Entity} from "enums/entity";
import {AccessType, PermissionEntity, PermissionOperationKey} from "types/Permission";
import {useAccess} from "hooks/index";
import {assetAccessListPath, organizationMembersPath, projectLevelTasksAccessListPath} from "screens/utility";
import {useParams} from "react-router-dom";
import {toastProps} from "screens/BaseProps";

interface ToolTipPopoverProps {
  anchorEl: HTMLElement | null;
  id: string;
  open: boolean;
  openEditMilestone: () => void;
  closeMenu: (e: MouseEvent<HTMLElement>) => void;
  openDeleteMilestoneDialog: () => void;
  milestoneAccess: AccessType | null;
  parentEntity: Entity.ProjectLevelTask | Entity.Asset | Entity.Templates;
  filesCount: number;
  readOnly: boolean;
  uid: string;
  toastProps: toastProps;
}

function ToolTipPopover(props: ToolTipPopoverProps) {
  const {anchorEl, id, open, uid, parentEntity, readOnly, filesCount, milestoneAccess} = props;
  const {closeMenu, openEditMilestone, openDeleteMilestoneDialog} = props;

  const {orgId, projId, assetId} = useParams();

  // Download related states
  const [isDownloadOptionsOpen, setIsDownloadOptionsOpen] = useState(false);

  const [downloadAccess] = useAccess({
    uid,
    entity: parentEntity === Entity.ProjectLevelTask ? Entity.Project
      : parentEntity === Entity.Asset ? Entity.Task
        : Entity.Organization,
    documentDocId: parentEntity === Entity.ProjectLevelTask ? PermissionEntity.PLMilestoneFilesDownload
      : PermissionEntity.MilestoneFilesDownload,
    accessListRef: parentEntity === Entity.ProjectLevelTask ? projectLevelTasksAccessListPath(orgId!, projId!)
      : parentEntity === Entity.Asset ? assetAccessListPath(orgId!, projId!, assetId!)
        : organizationMembersPath(orgId!),
  });

  const isEditDisabled = id === defaultMilestoneId || readOnly || Boolean(!milestoneAccess?.[PermissionOperationKey.Update]);
  const onEditClick = isEditDisabled ? emptyFunction : openEditMilestone;
  const isDeleteDisabled = id === defaultMilestoneId || readOnly || Boolean(!milestoneAccess?.[PermissionOperationKey.Delete]);
  const onDeleteClick = isDeleteDisabled ? emptyFunction : openDeleteMilestoneDialog;
  const isDownloadDisabled = Boolean(!downloadAccess?.[PermissionOperationKey.Create]);
  const onDownloadClick = isDownloadDisabled ? emptyFunction : onDownloadMenuClick;

  function onDownloadMenuClick() {
    if (!anchorEl) return;
    setIsDownloadOptionsOpen((prev) => !prev);
  }

  return (
    <Menu
      id={`positioned-menu-${id}`}
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={open}
      onClose={closeMenu}
      onClick={closeMenu}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          margin: 0,
        },
      }}
      MenuListProps={{
        disablePadding: true
      }}
      transformOrigin={{horizontal: 'right', vertical: 'top'}}
      anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
    >
      <List sx={{padding: 0}}>
        <ActionOption
          Icon={<SystemIcons.Edit height={16} width={16}/>}
          label={enMilestoneButton.edit}
          onClick={onEditClick}
          disabled={isEditDisabled}
        />
        {parentEntity !== Entity.Templates && (
          <>
            <ActionOption
              Icon={<SystemIcons.Download height={16} width={16}/>}
              label={enMilestoneButton.download}
              onClick={onDownloadClick}
              disabled={isDownloadDisabled}
              isDropdown
            />
            <DownloadOptionsMenu
              uid={uid}
              entity={!!assetId ? Entity.Milestone : Entity.PLMilestone}
              filesCount={filesCount}
              onClose={onDownloadMenuClick}
              extraIds={{milestoneId: id}}
              isDownloadOptionsCollapseOpen={isDownloadOptionsOpen}
              isCollapsible
              toastProps={props.toastProps!}
            />
          </>
        )}
        <ActionOption
          id="delete-milestone-button"
          Icon={<SystemIcons.DeleteOutlined height={16} width={16}/>}
          label={enMilestoneButton.delete}
          labelSx={(theme) => ({color: theme.palette.error.main})}
          onClick={onDeleteClick}
          disabled={isDeleteDisabled}
        />
      </List>
    </Menu>
  );
}

export default ToolTipPopover;