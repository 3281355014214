import React from "react";
import {SystemIcons} from "assets/icons/system/system.index";
import {ActionOption, MenuCard} from "components/index";
import {emptyFunction, enProjectButton, enProjectLabel} from "constants/index";
import {AccessType, PermissionEntity, PermissionOperationKey} from "types/Permission";
import {useAccess} from "hooks/index";
import {Entity} from "enums/entity";

interface ProjectMenuProps {
  uid: string;
  projId: string;
  anchorEl: HTMLElement | null;
  open: boolean;
  navigateProject: () => any;
  navigateToProjectLevelTasks: () => any;
  navigateToManageAssets: () => void;
  closeMenu: (e: React.MouseEvent<HTMLElement>) => void;
  editProject: () => any;
  downloadTimeData: () => void;
  deleteProject: () => void;
  openManageAccess: () => void;
  access: AccessType | null;
  usesTemplate: boolean;
  isImporting: boolean;
  canViewManageAsset: boolean;
  openImportDialog: () => void;
  openExportDialog: () => void;
}

function ProjectMenu(props: ProjectMenuProps) {
  const {anchorEl, open, access, usesTemplate, isImporting, uid, projId} = props;
  const {navigateProject, navigateToProjectLevelTasks, closeMenu, editProject, deleteProject, openManageAccess, openImportDialog, downloadTimeData, openExportDialog} = props;

  const [assetAccess] = useAccess({
    uid: uid,
    entity: Entity.Project,
    documentDocId: PermissionEntity.Asset,
    ids: {projId},
  });

  const [importAccess] = useAccess({
    uid,
    entity: Entity.Project,
    documentDocId: PermissionEntity.ProjectImport,
    ids: {projId},
  });

  const [exportAccess] = useAccess({
    uid,
    entity: Entity.Project,
    documentDocId: PermissionEntity.ProjectExport,
    ids: {projId},
  });

  const openProjectMenuButtonClick = !assetAccess?.[PermissionOperationKey.View] ? emptyFunction : navigateProject;
  const openProjectLevelTasksMenuButtonClick = !assetAccess?.[PermissionOperationKey.View] ? emptyFunction : navigateToProjectLevelTasks;
  const editProjectMenuButtonClick = !access?.[PermissionOperationKey.Update] ? emptyFunction : editProject;
  const deleteProjectMenuButtonClick = !access?.[PermissionOperationKey.Delete] ? emptyFunction : deleteProject;

  const importProjectMenuButtonClick = !importAccess?.[PermissionOperationKey.Create] || usesTemplate ? emptyFunction : openImportDialog;
  const exportProjectMenuButtonClick = !exportAccess?.[PermissionOperationKey.Create] ? emptyFunction : openExportDialog;
  const onDownloadMenuButtonClick = !exportAccess?.[PermissionOperationKey.Create] ? emptyFunction : downloadTimeData;

  return (
    <MenuCard open={open} anchorEl={anchorEl} closeMenu={closeMenu}>
      <ActionOption
        id="project-overview-button"
        disabled={!assetAccess?.[PermissionOperationKey.View]}
        Icon={<SystemIcons.Projects height={16} width={16}/>}
        label={enProjectLabel.overview}
        onClick={openProjectMenuButtonClick}
      />
      <ActionOption
        id="project-overview-button"
        disabled={!assetAccess?.[PermissionOperationKey.View]}
        Icon={<SystemIcons.Tasks height={16} width={16}/>}
        label={enProjectLabel.projectLevelTasks}
        onClick={openProjectLevelTasksMenuButtonClick}
      />
      <ActionOption
        id="project-edit-button"
        disabled={!access?.[PermissionOperationKey.Update]}
        Icon={<SystemIcons.Edit height={16} width={16}/>}
        label={enProjectLabel.edit}
        onClick={editProjectMenuButtonClick}
      />
      <ActionOption
        id="project-manage-access-button"
        Icon={<SystemIcons.Teams height={16} width={16}/>}
        label={enProjectLabel.manageAccess}
        onClick={openManageAccess}
      />
      <ActionOption
        id="project-manage-assets-button"
        Icon={<SystemIcons.Assets height={16} width={16}/>}
        label={enProjectLabel.manageAsset}
        disabled={!props.canViewManageAsset}
        onClick={props.navigateToManageAssets}
      />
      <ActionOption
        id="project-download-time-data-button"
        Icon={<SystemIcons.DownloadTimeData height={16} width={16}/>}
        label={enProjectButton.downloadTimeData}
        onClick={onDownloadMenuButtonClick}
        disabled={!exportAccess?.[PermissionOperationKey.Create]}
      />
      <ActionOption
        id="project-import-project-data-button"
        Icon={<SystemIcons.Import height={16} width={16}/>}
        label={enProjectButton.importProjectData}
        onClick={importProjectMenuButtonClick}
        disabled={usesTemplate || !importAccess?.[PermissionOperationKey.Create] || isImporting}
      />
      <ActionOption
        id="project-export-project-data-button"
        Icon={<SystemIcons.Export height={16} width={16}/>}
        label={enProjectButton.exportProjectData}
        onClick={exportProjectMenuButtonClick}
        disabled={!exportAccess?.[PermissionOperationKey.Create]}
      />
      <ActionOption
        id="project-delete-button"
        Icon={<SystemIcons.DeleteOutlined height={16} width={16}/>}
        disabled={!access?.[PermissionOperationKey.Delete]}
        label={enProjectButton.deleteProject}
        onClick={deleteProjectMenuButtonClick}
        labelSx={(theme) => ({color: theme.palette.error.main})}
      />
    </MenuCard>
  );
}

export default ProjectMenu;
