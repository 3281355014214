import React, {useState} from "react";
import {Box, Hidden, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {SystemIcons} from "assets/icons/system/system.index";
import {DotIndicator, ExportDialog, PilledNavigationBar} from "components";
import {Project} from "types/Project";
import {BaseProps} from "screens/BaseProps";
import HeaderMoreOptionsMenu from "./HeaderMoreOptionsMenu";
import {
  projectAccessListPath,
  projectChatMessagesPath,
  projectExportsPath,
  projectOverviewActivityFeedPath,
  projectPath,
} from "screens/utility/FirebasePath";
import {Entity, ExportProjectType} from "enums/index";
import {enProjectLabel} from "../../constants";
import theme from "theme/theme";
import {ManageAccessDrawer, ManageProjectDrawer} from "components/Drawers";
import ChatDrawer from "components/ChatDrawer";
import ActivityFeed from "components/ActivityFeed"
import {useComponentToggler, useRTDBDocField} from "hooks/index";
import {DownloadTimeDataDialog, ImportDialog} from "components/Dialogs";
import {ChatIconButton, SwiftIconButton} from "components/Button";
import {useNavigate} from "react-router";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {PillItemProps} from "components/PilledNavBar/PillItem";
import {SidebarIcons} from "assets/icons/menu/menu.index";
import {getDocPath} from "../utility";
import useImportBuild from "hooks/useImportBuild";

interface HeaderProps extends BaseProps {
  project: Project;
  projectPath: string;
  isPrivate: boolean | undefined;
  access: AccessType | null;
  deleteProject: () => void;
  role: string;
}

function Header(props: HeaderProps) {
  const {isPrivate, toastProps, access, project, uid, role, deleteProject} = props;
  const {name: projectName = "", orgName: organizationName = ""} = project;

  const [isActionsShowing, setIsActionsShowing] = useState<boolean>(false);
  const [isEditProjDrawerOpen, setIsEditProjDrawerOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // user specific fields
  const hasNewChat = useRTDBDocField<boolean>(getDocPath(uid, props.projectPath), 'hasNewChat') ?? false;
  const hasNewActivity = useRTDBDocField<boolean>(getDocPath(uid, props.projectPath), 'hasNewActivity') ?? false;
  const chatEmpty = useRTDBDocField<boolean>(props.projectPath, 'chatEmpty') ?? true;

  const [isManageAccessDrawerOpen, setIsManageAccessDrawerOpen] = useState<boolean>(false);
  const [isChatDrawerOpen, setIsChatDrawerOpen] = useState<boolean>(false);
  const [isActivityFeedOpen, setIsActivityFeedOpen] = useState<boolean>(false);
  const [isImportDialogOpen, {open: openImportDialog, close: closeImportDialog}] = useComponentToggler(false);
  const [isDownloadTimeDataOpen, {
    open: openDownloadTimeData,
    close: closeDownloadTimeData
  }] = useComponentToggler(false);
  const [isExportDialogOpen, {open: openExportDialog, close: closeExportDialog}] = useComponentToggler(false);

  const {projId, orgId} = useParams();
  const {isProcessing} = useImportBuild({
    path: projectPath(orgId!, projId!).path,
  });

  const exportsPath = projectExportsPath(orgId!, projId!);

  const navigate = useNavigate();

  const pills: PillItemProps[] = [
    {
      id: "org-name",
      label: `${project.orgName} / ${project.name}`,
      icon: <>
        {isPrivate ?
          <Tooltip
            title={
              <Typography id="tooltip-private-project">
                {enProjectLabel.private}
              </Typography>
            }
            placement="bottom"
          >
            <Box sx={{display: "flex", alignItems: "center"}}>
              <SystemIcons.LockFilled
                fill={theme.palette.neutral.dark}
                stroke={theme.palette.neutral.dark}
                width={16}
                height={16}
              />
            </Box>
          </Tooltip> :
          <SidebarIcons.Projects fill={theme.palette.neutral.dark} width={16} height={16}/>
        }
      </>,
      onClickFcn: () => navigate(`/${orgId}/projects/${projId}/overview`)
    }
  ];

  function handleMenuOpen(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
    setIsActionsShowing(true);
  }

  function handleMenuClose(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();

    setAnchorEl(null);
    setIsActionsShowing(false);
  }

  function handleDeleteProject() {
    setAnchorEl(null);
    setIsActionsShowing(false);
    deleteProject();
  }

  function onChatDrawerOpen() {
    setIsChatDrawerOpen(true);
  }

  return (
    <>
      <ImportDialog
        uid={uid}
        isOpen={isImportDialogOpen}
        entity={Entity.Project}
        toastProps={toastProps!}
        closeDialog={closeImportDialog}
        parentDocumentRef={projectPath(orgId!, projId!)}
      />
      <ExportDialog
        isOpen={isExportDialogOpen}
        onClose={closeExportDialog}
        toastProps={toastProps!}
        title={enProjectLabel.exportProjectData}
        exportingTitle={enProjectLabel.exportingProjectData}
        message={enProjectLabel.exportProjectDataText}
        exportColRef={exportsPath}
        exportType={ExportProjectType.ProjectData}
      />
      {isDownloadTimeDataOpen && (
        <DownloadTimeDataDialog
          isOpen={isDownloadTimeDataOpen}
          toastProps={toastProps!}
          entity={Entity.Project}
          closeDialog={closeDownloadTimeData}
          downloadCollectionRef={exportsPath}
        />
      )}
      <ActivityFeed
        uid={uid}
        onClose={() => setIsActivityFeedOpen(false)}
        isOpen={isActivityFeedOpen}
        collectionReference={projectOverviewActivityFeedPath(orgId!, projId!)}
        entity={Entity.Project}
      />
      <ChatDrawer
        uid={uid}
        isOpen={isChatDrawerOpen}
        entity={Entity.Project}
        pathPair={[organizationName, projectName]}
        onClose={() => setIsChatDrawerOpen(false)}
        colRef={projectChatMessagesPath(orgId!, projId!)}
        toastProps={toastProps!}
      />
      <ManageProjectDrawer
        selectedProject={project}
        isOpen={project && isEditProjDrawerOpen}
        closeDrawer={() => setIsEditProjDrawerOpen(false)}
        toastProps={toastProps!}
        role={role}
        uid={uid}
      />
      <ManageAccessDrawer
        uid={uid}
        isOpen={isManageAccessDrawerOpen}
        entity={Entity.Project}
        documentId={projId!}
        collectionRef={projectAccessListPath(orgId!, projId!)}
        onDrawerClose={() => setIsManageAccessDrawerOpen(false)}
        toastProps={toastProps!}
      />

      <PilledNavigationBar pills={pills}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{backgroundColor: "white", zIndex: 1}}
        >
          <Box
            sx={() => ({
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative",
            })}
          >
            <Box sx={{display: "flex", gap: 0.5}}>
              <Hidden mdDown>
                <SwiftIconButton
                  id="project-overview-header-edit-button"
                  label={enProjectLabel.edit}
                  onClickFcn={() => setIsEditProjDrawerOpen(true)}
                  disabled={Boolean(!access?.[PermissionOperationKey.Update])}
                  startIcon={SystemIcons.Edit}
                />
                <IconButton
                  id="project-overview-header-manage-access-button"
                  title={enProjectLabel.manageAccess}
                  onClick={() => setIsManageAccessDrawerOpen(true)}>
                  <SystemIcons.Teams/>
                </IconButton>
                <IconButton
                  id="project-overview-header-notifications-button"
                  title={enProjectLabel.notifications}
                  onClick={() => setIsActivityFeedOpen(true)}>
                  <DotIndicator isVisible={Boolean(hasNewActivity)}>
                    <SystemIcons.Notifications/>
                  </DotIndicator>
                </IconButton>
                <ChatIconButton
                  id="project-overview-header-chat-button"
                  entity={Entity.Project}
                  withNewMessage={hasNewChat}
                  chatEmpty={chatEmpty}
                  onClickFcn={onChatDrawerOpen}
                />
              </Hidden>
              <IconButton
                id="project-overview-header-more-options-button"
                title={enProjectLabel.moreOptions}
                onClick={(e) => handleMenuOpen(e)}
              >
                <SystemIcons.MoreVertical stroke={theme.palette.text.secondary}/>
              </IconButton>
              {isActionsShowing && (
                <HeaderMoreOptionsMenu
                  uid={uid!}
                  open={isActionsShowing}
                  anchorEl={anchorEl}
                  closeMenu={handleMenuClose}
                  deleteProject={handleDeleteProject}
                  access={access}
                  isImporting={isProcessing}
                  downloadTimeData={openDownloadTimeData}
                  openImportDialog={openImportDialog}
                  openExportDialog={openExportDialog}
                  usesTemplate={!!project.projectAssetTemplateId}
                />
              )}
            </Box>
          </Box>
        </Stack>
      </PilledNavigationBar>
    </>
  );
}

export default Header
