import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { FC, ReactElement } from "react";

export interface Action {
  Icon: ReactElement<any>;
  label: string;
  onClick: () => any;
  labelSx?: SxProps<Theme>;
}

interface CardOptionsProps {
  isShowing: boolean;
  actions: Action[];
  ParentElement: FC<any>;
}

const CardOptions = ({
  isShowing,
  actions,
  ParentElement,
}: CardOptionsProps) => {
  return (
    <Box style={{ position: "relative" }}>
      {ParentElement}
      <Paper
        elevation={2}
        sx={{
          zIndex: 1,
          position: "absolute",
          right: "16px",
          top: "40px",
          display: isShowing ? "block" : "none",
        }}
      >
        <List
          sx={{
            padding: 0,
          }}
        >
          {actions.map(({ Icon, label, onClick, labelSx }) => (
            <ActionOption
              Icon={Icon}
              label={label}
              onClick={onClick}
              labelSx={labelSx && labelSx}
              key={`action_${label}`}
            />
          ))}
        </List>
      </Paper>
    </Box>
  );
};

interface ActionOptionProps {
  onClick: () => any;
  Icon: ReactElement<any>;
  label: string;
  labelSx?: SxProps<Theme>;
}
const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: "auto",
  marginRight: 10,
});

const ActionOption = ({ onClick, Icon, label, labelSx }: ActionOptionProps) => (
  <ListItem disablePadding>
    <ListItemButton
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <StyledListItemIcon>{Icon}</StyledListItemIcon>
      <ListItemText
        sx={{ ...labelSx, whiteSpace: "nowrap" }}
        primary={<Typography variant="h4">{label}</Typography>}
      />
    </ListItemButton>
  </ListItem>
);

export default CardOptions;
