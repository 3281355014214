import {FormFieldProps} from "../BaseField";
import {Stack, Typography} from "@mui/material";
import {enFormsLabel} from "constants/index";
import {FormFieldType} from "enums/formFieldType";
import {FormField} from "types/FormField";

export function ParagraphFormField(props: FormFieldProps) {
  const {isEditable, formField} = props;

  const {minChars, maxChars} = formField as FormField<FormFieldType.Paragraph>;

  if (!isEditable) {
    return (
      <Stack direction="row" gap={4}>
        {
          !!minChars && (
            <Stack>
              <Typography variant="body">
                {enFormsLabel.minimumCharacters}
              </Typography>
              <Typography variant="h4">
                {minChars}
              </Typography>
            </Stack>
          )
        }
        {
          !!maxChars && (
            <Stack>
              <Typography variant="body">
                {enFormsLabel.maximumCharacters}
              </Typography>
              <Typography variant="h4">
                {maxChars}
              </Typography>
            </Stack>
          )
        }
      </Stack>
    )
  }
  // Edit mode additional fields
  return <></>
}
