import {Entity} from "enums/index";
import {ToastProps} from "screens/BaseProps";
import {Stack, Typography} from "@mui/material";
import {enTaskLabel} from "constants/index";
import React from "react";
import theme from "theme/theme";
import {SelectStatus} from "components/index";
import {DocumentReference} from "firebase/firestore";
import {milestoneTaskandAssetStatus as TaskStatus} from "enums/milestoneTaskandAssetStatus";
import TaskStatusButtonFunction from "../Head/TaskStatusButtonFunction";
import {StatusItem, Task} from "types/index";
import {AccessType, PermissionOperationKey} from "types/Permission";

interface TaskStatusAndButtonsViewProps extends ToastProps {
  uid: string;
  task: Task;
  documentRef: DocumentReference;
  taskStatusOption: Record<string, StatusItem> | null;
  selectedStatus: StatusItem | undefined;
  access: AccessType | null;
  formRequirementAccess: AccessType | null;
  fileRequirementsAccess: AccessType | null;
  parentEntity: Entity;
  openCreateFileRequirement: () => void;
  openCreateFormRequirement: () => void;
  requirementsRejectedCount: number;
  filesCount: number;
}

function TaskStatusAndButtonsView(props: TaskStatusAndButtonsViewProps) {
  const {access, task, taskStatusOption, selectedStatus, documentRef, parentEntity, toastProps} = props;

  const canUpdateStatus = Boolean(access?.[PermissionOperationKey.ChangeStatus]) || Boolean(access?.[PermissionOperationKey.OverrideStatus]);

  if (parentEntity === Entity.Templates)
    return null;

  return (
    <>
      <Typography variant="body" color={theme.palette.neutral.dark}>
        {enTaskLabel.status}
      </Typography>
      <Stack direction="column" spacing={0.5}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column">
            {taskStatusOption !== null && (
              <SelectStatus
                toastProps={toastProps!}
                documentRef={documentRef}
                displayIconOnSelected={true}
                statusField="taskStatus"
                items={taskStatusOption}
                selected={selectedStatus}
                width="185px"
                disabledStatuses={access && access.ChangeStatus ? [] : [TaskStatus.Approved]}
                isDisabled={!canUpdateStatus}
								selectIconSx={{marginLeft: 1}}
              />
            )}
          </Stack>
          <TaskStatusButtonFunction
            uid={props.uid}
            documentRef={documentRef}
            selectedStatus={selectedStatus}
            task={task}
            access={access}
            toastProps={toastProps!}
          />
        </Stack>
      </Stack>
    </>
  )
}

export default TaskStatusAndButtonsView;
