import theme from "theme/theme";
import {Stack, StackProps} from "@mui/material";

function SettingsItem(props: StackProps) {
  return <Stack
    direction="row"
    alignItems="center"
    px={1}
    py={0}
    sx={{backgroundColor: theme.palette.background.paper}}
    {...props}
  >
  </Stack>
}

export default SettingsItem;
