import SystemIndex from "assets/icons/system/system.index";
import theme from "theme/theme";
import {enTaskLabel} from "constants/index";
import {Timer} from "types/Timer";
import {DocumentReference} from "firebase/firestore";
import {LoadingButton} from "@mui/lab";
import {handleStartTimerClick} from "components/FloatingTimer/timer.utils";
import {SimpleTimerSetter} from "components/FloatingTimer/index";

interface NotRunningTimerProps {
  location: string | null
  timerRef: DocumentReference
  timer: Partial<Timer>
  geolocation: GeolocationPosition | null
  setTimer: SimpleTimerSetter
}

function NotRunningTimer(props: NotRunningTimerProps) {
  const {location, timerRef, timer, geolocation, setTimer} = props;

  return <LoadingButton
    onClick={() => handleStartTimerClick(location, timer, setTimer, timerRef, geolocation)}
    color="primary"
    variant="contained"
    sx={{
      minHeight: "100%",
      minWidth: "100%"
    }}
  >
    <SystemIndex.SystemIcons.Play
      stroke={theme.palette.background.paper}
      fill={theme.palette.background.paper}
      height={16}
    />
    {enTaskLabel.startTimer}
  </LoadingButton>
}

export default NotRunningTimer
