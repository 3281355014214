import TeamCategoryCard from "./TeamCategoryCard";
import {Member, Organization, Team, TeamCategory} from "types/index";
import {BaseProps} from "screens/BaseProps";
import {Checkbox, FormControlLabel, Stack, Typography} from "@mui/material";
import React, {Dispatch, useEffect} from "react";
import {en} from "language/en";
import {teamMembersPath, teamsPath} from "screens/utility/FirebasePath";
import ManageTeamFormDrawer from "components/Drawers/ManageTeamFormDrawer/ManageTeamFormDrawer";
import {useCollection, useComponentToggler,} from "hooks/index";
import {submitForm} from "screens/utility";
import {DocumentReference, orderBy} from "firebase/firestore";
import {ActionType, ViewStatus, Severity, ProcessType} from "enums/index";
import MembersList from "../MembersList";
import {AccessType, PermissionOperationKey} from "types/Permission";
import DeleteButton from "components/Button/BulkActionButtons/DeleteButton";
import {enCommonLabel, enTeamsAndPeople} from "constants/index";
import useCollectionData from "hooks/useCollectionData";

interface TeamCategoryListProps extends BaseProps {
  access: AccessType | null;
  teamCategories: TeamCategory[];
  orgDoc: Organization;
  algoliaTeams?: Team[] | null;
  selectedTeam: Team | null;
  selectedTeamsId: Set<string>;
  selectedTeamCategoriesId: Set<string>;
  setSelectedTeamsId: Dispatch<Set<string>>;
  setAlgoliaTeams: (teams: Team[] | null) => void;
  setSelectedTeam: Dispatch<Team | null>;
  setSelectedTeamCategoriesId: Dispatch<Set<string>>;
  deleteTeams: () => void;
  handleCheckTeamCategory: (checked: boolean, teams: Team[], teamCategoryId: string) => void;
  handleCheckTeam: (teamId: string, teams: Team[], teamCategoryId: string) => void;
}

function TeamCategoryList(props: TeamCategoryListProps) {
  const {
    access,
    selectedTeamsId,
    teamCategories,
    orgDoc,
    toastProps,
    algoliaTeams,
    selectedTeam,
    selectedTeamCategoriesId,
    uid,
    setSelectedTeamsId,
    setAlgoliaTeams,
    deleteTeams,
    setSelectedTeamCategoriesId,
    setSelectedTeam,
    handleCheckTeam,
    handleCheckTeamCategory
  } = props;

  const {data: teams} = useCollectionData<Team>({collectionRef: teamsPath(orgDoc["@id"]!), constraints: null, returnedFields: ["teamCategory", "name"], sortKey: "name"})

  const [membersCollection, , setColRef] = useCollection<Member>(null, null);
  const [isTeamMembersDrawerOpen, {
    open: openTeamMembersDrawerFn,
    close: closeTeamMembersDrawerFn
  }] = useComponentToggler(false);

  const [isManageTeamDrawerOpen, {
    open: openManageTeamDrawer,
    close: closeManageTeamDrawer
  }] = useComponentToggler(false);

  const {setIsToastOpen, setToastSeverity, setToastMessage} = toastProps!;

  function getTeamsOfCategory(teamCategoryId: string) {
    if (!teams) return

    if (algoliaTeams)
      return algoliaTeams.filter(team => team.teamCategory!["@id"] === teamCategoryId)

    return teams.filter(team => team.teamCategory!["@id"] === teamCategoryId)
  }

  function onEditTeamClick(team: Team) {
    setSelectedTeam(team);
    openManageTeamDrawer();
  }

  function checkIsTeamSelected(teamId: string) {
    return selectedTeamsId.has(teamId);
  }

  function checkIsTeamCategorySelected(teams: Team[]) {
    return teams.every((team) => selectedTeamsId.has(team["@id"]!)) && teams.length > 0;
  }

  async function handleSelectAllCheck(checked: boolean) {
    if (!teams) return;

    if (checked) {
      const teamIds = teams.map(team => team["@id"]!);
      setSelectedTeamsId(new Set(teamIds));
      setSelectedTeamCategoriesId(new Set(teamCategories.map(teamCategory => teamCategory["@id"]!)))
    } else {
      setSelectedTeamsId(new Set());
      setSelectedTeamCategoriesId(new Set())
    }
  }

  function openTeamMembersDrawer(team: Team) {
    setSelectedTeam(team);
    setColRef(teamMembersPath(orgDoc["@id"]!, team["@id"]!));
  }

  useEffect(() => {
    if (membersCollection !== null) openTeamMembersDrawerFn();
  }, [membersCollection])

  async function closeTeamMembersDrawer() {
    setColRef(null);
    closeTeamMembersDrawerFn();
  }

  async function handleDeleteTeam(docReference: DocumentReference, teamId: string, name: string) {
    await submitForm(
      docReference,
      ActionType.Delete,
      (status, data, isLastUpdate) => statusHandler(teamId, name, status, data, isLastUpdate)
    );
  }

  function statusHandler(teamId: string, name: string, status: ViewStatus, data: Team, isLastUpdate: boolean) {
    if (!isLastUpdate) return;

    switch (status) {
      case ViewStatus.Finished:
        setToastSeverity(Severity.Success);
        setToastMessage(enTeamsAndPeople.label.deleteXSuccess(name));
        setIsToastOpen(true);
        break;
      case ViewStatus.Error:
      case ViewStatus.SecurityError:
      case ViewStatus.ValidationError:
        setToastSeverity(Severity.Error);
        setToastMessage(enCommonLabel.errorProcess(ProcessType.Delete));
        setIsToastOpen(true);
        break;
    }

    if (algoliaTeams) {
      setAlgoliaTeams(algoliaTeams.filter((algoliaTeam) => algoliaTeam["@id"] !== teamId))
    }
  }

  return <>
    <ManageTeamFormDrawer
      toastProps={toastProps}
      org={orgDoc}
      isOpen={isManageTeamDrawerOpen}
      team={selectedTeam}
      onClose={closeManageTeamDrawer}
      uid={uid}
    />
    <MembersList
      members={membersCollection ?? []}
      isOpen={isTeamMembersDrawerOpen}
      teamName={selectedTeam?.name ?? ""}
      onClose={closeTeamMembersDrawer}
    />
    <Stack direction="row">
      {teamCategories.length > 0 && <FormControlLabel
        sx={{alignSelf: "flex-start", position: "relative", left: 30}}
        control={
          <Checkbox
            id={"bulk-select-checkbox"}
            checked={teamCategories.every(teamCategory => selectedTeamCategoriesId.has(teamCategory["@id"]!))}
            onChange={(_, checked) => handleSelectAllCheck(checked)}
          />
        }
        label={<Typography fontWeight={700}>
          {selectedTeamsId.size ? en.common.label.selectedItems(selectedTeamsId.size) : en.common.label.selectAll("Teams")}
        </Typography>}
      />}
      {selectedTeamsId.size > 0 && (
        <DeleteButton
          sx={{position: "relative", left: 30}}
          id="bulk-delete-category-button"
          disabled={Boolean(!access?.[PermissionOperationKey.Delete])}
          onButtonClick={deleteTeams}
        />
      )}
    </Stack>

    {teamCategories.map((teamCategory) =>
      <TeamCategoryCard
        uid={uid}
        access={access}
        teams={getTeamsOfCategory(teamCategory["@id"]!) || []}
        checkIsTeamCategorySelected={checkIsTeamCategorySelected}
        handleCheckTeamCategory={handleCheckTeamCategory}
        handleCheckTeam={handleCheckTeam}
        toastProps={toastProps}
        key={teamCategory["@id"]!}
        orgDoc={orgDoc}
        teamCategory={teamCategory}
        checkIsTeamSelected={checkIsTeamSelected}
        openTeamMembersDrawer={openTeamMembersDrawer}
        onEditTeamClick={onEditTeamClick}
        handleDeleteTeam={handleDeleteTeam}
      />)}
  </>
}

export default TeamCategoryList
