import { Previews } from "enums/supportedPreviews";

type ExtensionMap = {
  [key: string]: Previews
};

const extensionToPreview: ExtensionMap = {
  "pdf": Previews.PDF,
  "mp4": Previews.Video,
  "mov": Previews.Video,
  "avi": Previews.Video,
  "mkv": Previews.Video,
  "webm": Previews.Video,
  "png": Previews.Image,
  "jpg": Previews.Image,
  "jpeg": Previews.Image,
  "gif": Previews.Image
};

export default function getContentType(filepath: string): Previews {
  const fileExtension = filepath.split(".").pop() || "";
  return extensionToPreview[fileExtension] || Previews.File;
}
