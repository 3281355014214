import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {Button, Stack, Typography} from "@mui/material";
import {enCommonLabel, enFileRequirementLabel} from "constants/index";
import {FilterStatusMenu} from "../Menus";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import {ButtonProps} from "@mui/material/Button";

interface StatusFilterProps {
  statusFilter: string;
  setStatusFilter: (status: string) => void;
}

function StatusFilter(props: StatusFilterProps) {
  const {statusFilter, setStatusFilter} = props;
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState<HTMLElement | null>(null);

  function changeFilter(newFilter: string){
    setStatusFilter(newFilter);
  }

  return <Stack
    direction="row"
    sx={{
      justifyContent: "space-between",
      alignItems: "center"
    }}
  >
    <Typography variant="body" color="text.secondary">
      {enFileRequirementLabel.allFiles}
    </Typography>
    <ColorButton
      id="status-milestones-btn"
      endIcon={
        <SystemIcons.ChevronDown
          stroke={theme.palette.neutral.dark}
          height={16}
          width={16}
        />
      }
      onClick={(e) => setFilterMenuAnchorEl(e.currentTarget)}
    >
      <Typography
        variant="h4"
        sx={{ml: 1, color: theme.palette.text.secondary}}
      >
        {enCommonLabel.status}
      </Typography>
      <Typography
        variant="h4"
        sx={{ml: 1, color: theme.palette.secondary.main}}
      >
        {statusFilter}
      </Typography>
    </ColorButton>
    <FilterStatusMenu
      anchorEl={filterMenuAnchorEl}
      open={Boolean(filterMenuAnchorEl)}
      closeMenu={() => setFilterMenuAnchorEl(null)}
      onClick={changeFilter}
    />
  </Stack>
}

/** start of styles */
const ColorButton = styled(Button)<ButtonProps>(({theme}) => ({
  "&:hover": {
    backgroundColor: theme.palette.neutral.light,
  },
  "&:active": {
    backgroundColor: theme.palette.neutral.light,
  },
}));

/** end of styles */

export default StatusFilter