import {ButtonProps} from "types/ButtonProps";
import {emptyFunction, enAssetLabel, enTaskButton} from "constants/index";
import {SystemIcons} from "assets/icons/system/system.index";
import {SwiftIconButton} from "components/Button/index";
import React, {useState} from "react";
import {ButtonGroup as MUIButtonGroup, SxProps, Theme} from "@mui/material";
import theme from "theme/theme";
import DownloadOptionsMenu from "components/Dialogs/DownloadConfirmDialog/DownloadOptionsMenu";
import {Entity} from "enums/entity";
import {toastProps} from "screens/BaseProps";

interface DownloadIconButtonProps extends ButtonProps {
  entity: Entity;
  uid: string;
  filesCount: number;
  toastProps: toastProps;
  requirementId?: string;
  sx?: SxProps<Theme>;
  withDropdown?: boolean;
}

function DownloadIconButton(props: DownloadIconButtonProps) {
  const {filesCount, disabled, entity, toastProps, uid, requirementId = '', sx, withDropdown = true} = props;

  const [downloadOptionsAnchor, setDownloadOptionsAnchor] = useState<Element | null>(null);

  if (filesCount < 1)
    return (
      <SwiftIconButton
        onClickFcn={emptyFunction}
        disabled={true}
        disabledHoverLabel={enAssetLabel.noFilesToDownload}
        startIcon={SystemIcons.Download}
        sx={{...sx}}
      />
    );

  return (
    <>
      <MUIButtonGroup variant="text">
        <SwiftIconButton
          onClickFcn={(e) => setDownloadOptionsAnchor(e.currentTarget)}
          disabled={disabled}
          sx={{width: 40, height: 40, marginTop: 1, ...sx}}
          title={enTaskButton.downloadAllFiles}
          startIcon={(props) => <SystemIcons.Download width={24} height={24} stroke={theme.palette.primary.main} {...props} />}
        />
        {withDropdown && <SwiftIconButton
          sx={{marginLeft: -2, width: 40, height: 40, marginTop: 1, ...sx}}
          onClickFcn={(e) => setDownloadOptionsAnchor(e.currentTarget)}
          title={enTaskButton.downloadAllFilesWith}
          disabled={disabled}
          startIcon={(props) => <SystemIcons.ChevronDown width={16} height={16} stroke={theme.palette.neutral.main} {...props} />}
        />}
      </MUIButtonGroup>
      <DownloadOptionsMenu
        uid={uid!}
        entity={entity}
        filesCount={filesCount}
        anchorEl={downloadOptionsAnchor}
        onClose={() => setDownloadOptionsAnchor(null)}
        toastProps={toastProps!}
        extraIds={{requirementId}}
      />
    </>
  )
}

export default DownloadIconButton;