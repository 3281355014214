import {Box} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import React, {Dispatch, useEffect, useState} from "react";
import {BaseProps} from "screens/BaseProps";
import {CollectionReference, where} from "firebase/firestore";
import {Entity, Severity, AccessUserType} from "enums/index";
import {useAccess, useCollection} from "hooks/index";
import {AccessListUser} from "types/AccessListUser";
import { SimpleFormDrawer} from "components/index";
import {enCommonManageAccess} from "constants/index";
import {AddTeamOrPeopleDrawer} from "components/Drawers";
import DynamicView from "components/Drawers/ManageAccessDrawer/Objects/DynamicView";
import {PermissionEntity} from "types/Permission";
import {SecondaryButton} from "components/Button";
import {ENTITY_LABEL} from "language/en/common";

interface ManageAccessProps extends BaseProps {
  isOpen: boolean;
  entity: Entity;
  parentEntity?: Entity;
  collectionRef: CollectionReference; // current displayed list
  actualCollectionRef?: CollectionReference; // actual collection to update
  documentId: string;
  onDrawerClose: () => void;
}

function ManageAccessDrawer(props: ManageAccessProps) {
  const {isOpen, entity, collectionRef, parentEntity, uid, toastProps, documentId} = props;
  const {onDrawerClose} = props;
  const {setToastMessage, setIsToastOpen, setToastSeverity} = toastProps!;

  const [processedPeopleMessage, setProcessedPeopleMessage] = useState<string>("");
  const [processedTeamMessage, setProcessedTeamMessage] = useState<string>("");
  const [addMemberMessage, setAddMemberMessage] = useState<string>("");

  const [teams, setTeamsConstraints, updateTeamColRef] = useCollection<AccessListUser>(
    null,
    collectionRef,
    [where("accessUserType", "==", AccessUserType.Team)]
  );
  const [people, setPeopleConstraints, updatePeopleColRef] = useCollection<AccessListUser>(
    null,
    collectionRef,
    [where("accessUserType", "==", AccessUserType.Person)]
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAddTeamsAndPeopleDrawerOpen, setIsAddTeamsAndPeopleDrawerOpen] = useState<boolean>(false);

  // instead of querying access repeatedly
  const [access, , updateIds, updateAccessListRef] = useAccess({
    uid,
    entity: entity,
    documentDocId: entity === Entity.Project ? PermissionEntity.ProjectAccessList
      : entity === Entity.Asset ? PermissionEntity.ProjectAssetAccessList
        : PermissionEntity.TaskAccessList,
    ids: {projId: documentId}
  });

  // update used ids in useAccess
  useEffect(() => {
    updateIds({projId: documentId});
  }, [documentId]);

  useEffect(() => {
    updateAccessListRef(collectionRef);
  }, [collectionRef.path]);

  // update collection ref if there are changes in the drawer state or collectionRef.path
  useEffect(() => {
    if (!isOpen) return;
    setIsLoading(true);
    updatePeopleColRef(collectionRef);
    updateTeamColRef(collectionRef);

    setTeamsConstraints([where("accessUserType", "==", AccessUserType.Team)]);
    setPeopleConstraints([where("accessUserType", "==", AccessUserType.Person)]);
  }, [collectionRef.path, isOpen]);

  useEffect(() => {
    setIsLoading(teams === null && people === null);

    if (
      handleToast(addMemberMessage, setAddMemberMessage) ||
      handleToast(processedPeopleMessage, setProcessedPeopleMessage) ||
      handleToast(processedTeamMessage, setProcessedTeamMessage)
    ) return;

  }, [teams, people]);

  function handleToast(message: string, setMessage: Dispatch<string>) {
    if (!message) return false;
    setToastSeverity(Severity.Success);
    setToastMessage(message);
    setIsToastOpen(true);
    setMessage("");
    return true;
  }

  function onAddSuccess(message: string) {
    setIsLoading(true);
    setAddMemberMessage(message);
    setIsAddTeamsAndPeopleDrawerOpen(false);
  }

  return (
    <>
      <SimpleFormDrawer
        title={enCommonManageAccess.title(ENTITY_LABEL[entity])}
        isOpen={isOpen}
        onClose={onDrawerClose}
        icon={<SystemIcons.Teams/>}
        isFormValid={true}
        isLoading={false}
        customFooter={
          ((teams ?? []).length > 0 || (people ?? []).length > 0) && (
            <Box justifySelf="flex-end">
              <Box sx={{display: "flex", justifyContent: "flex-end"}} gap={1}>
                <SecondaryButton
                  id="manage-access-drawer-add-button"
                  onClickFcn={() => setIsAddTeamsAndPeopleDrawerOpen(true)}
                  disabled={false}
                  startIcon={(props) => <SystemIcons.AddUser stroke={theme.palette.primary.main} {...props} />}
                  label={enCommonManageAccess.addNew}
                />
              </Box>
            </Box>
          )
        }
      >
        <DynamicView
          uid={uid}
          isLoading={isLoading}
          toastProps={toastProps!}
          teams={teams}
          people={people}
          collectionRef={props.actualCollectionRef || collectionRef}
          entity={entity}
          access={access}
          openAddTeamsAndPeopleDrawer={() => setIsAddTeamsAndPeopleDrawerOpen(true)}
          setProcessedPeopleMessage={setProcessedPeopleMessage}
          setProcessedTeamMessage={setProcessedTeamMessage}
        />
      </SimpleFormDrawer>
      {isAddTeamsAndPeopleDrawerOpen && (
        <AddTeamOrPeopleDrawer
          teams={(teams ?? [])}
          people={(people ?? [])}
          entity={entity}
          parentEntity={parentEntity}
          collectionReference={collectionRef}
          access={access}
          onDrawerClose={() => setIsAddTeamsAndPeopleDrawerOpen(false)}
          toastProps={toastProps!}
          onAddSuccess={onAddSuccess}
        />
      )}
    </>
  )
}

export default ManageAccessDrawer;
