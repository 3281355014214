import {Checkbox, Stack, Typography} from "@mui/material";
import PeopleItem, {AccessListUserWithPermission} from "./PeopleItem";
import theme from "theme/theme";
import {emptyFunction, enCommonLabel} from "constants/index";
import React, {useEffect, useState} from "react";
import {InProgress} from "components/index";
import {AccessListUser} from "types/index";
import {AccessRole} from "enums/index";
import {SelectedItem} from "components/Drawers/AddTeamOrPeopleDrawer";
import AccessRoleMenu from "components/Drawers/ManageAccessDrawer/Objects/AccessRoleMenu";
import {AccessType} from "types/Permission";
import {sortObjectsBy} from "../../../../screens/utility";

interface PeopleListProps {
  people: AccessListUser[] | null;
  allowSelect?: boolean;
  setSelectedMembers?: (newSelected: SelectedItem[]) => void;
  access: AccessType;
}

function PeopleList(props: PeopleListProps) {
  const {people, access} = props;
  const {allowSelect = true, setSelectedMembers = emptyFunction} = props;

  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [selectedPeople, setSelectedPeople] = useState<SelectedItem[]>([]);

  const [localPeople, setLocalPeople] = useState<AccessListUserWithPermission[] | null>(people);
  const [updateFromBulk, setUpdateFromBulk] = useState<boolean>(false);

  useEffect(() => {
    const peopleCopy = (people ?? []).map((person, index) => {
      // @ts-ignore
      const canAddPermission = access[`Manage${person.role?.replace(" ", "")}`] && person.role !== AccessRole.Owner;

      return {
        ...person,
        canAdd: canAddPermission,
      }
    });

    setLocalPeople(sortObjectsBy(peopleCopy, "name"));
  }, [JSON.stringify(people), access]);

  // update isAllSelected based on changed in the list of selectedPeople
  useEffect(() => {
    if (localPeople === null) return;
    setIsAllSelected(selectedPeople.length === localPeople.length && selectedPeople.length > 0);
    setSelectedMembers(selectedPeople);
  }, [JSON.stringify(selectedPeople)]);

  function selectAllPeople() {
    if (localPeople === null) return;

    if (isAllSelected) {
      // delete everything
      setSelectedPeople([]);
      return;
    }

    const newSelectedPeople = localPeople.map(person => {
      let obj: SelectedItem = {"@id": person["@id"], role: AccessRole.User};
      return obj;
    });

    setSelectedPeople(newSelectedPeople);
  }

  function updateSelectedList(id: string, checked: boolean, role?: AccessRole) {
    let selectedCopy = selectedPeople.map(person => ({...person}));

    selectedCopy = selectedCopy.filter(person => person["@id"] !== id);
    if (checked) {
      selectedCopy = [...selectedCopy, {"@id": id, role}];
    }

    setSelectedPeople(selectedCopy);
  }

  function chooseRoleBulk(selected: AccessRole) {
    let selectedCopy = selectedPeople.map(person => ({...person, role: selected}));
    let peopleCopy = (localPeople ?? []).map(person => {
      return {...person, role: selected};
    });

    setLocalPeople(peopleCopy);
    setSelectedMembers(selectedCopy);
    setUpdateFromBulk(true);

    return selectedCopy;
  }

  if (localPeople === null) return <InProgress/>;
  if (localPeople.length === 0) return <></>;

  return (
    <Stack direction="column" id={"people-list"}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center">
          <Checkbox
            id={"select-all-people-checkbox"}
            checked={isAllSelected}
            onChange={selectAllPeople}
          />
          <Typography
            variant="h5"
            id={"people-list-label"}
            color={theme.palette.secondary.main}>
            {selectedPeople.length === 0 ? `${enCommonLabel.people} ` : `${selectedPeople.length}  ${enCommonLabel.selected}`}
            <Typography
              variant="body1"
              sx={{display: "inline", color: theme.palette.text.secondary}}>
              {selectedPeople.length === 0 ? localPeople.length : null}
            </Typography>
          </Typography>
        </Stack>
        {selectedPeople.length > 1 &&
          <AccessRoleMenu
            onUpdateAccessRole={chooseRoleBulk}
            displayChevron={false}
            updateLabelOnSelect={false}
            selectSx={{width: "100px", color: "primary"}}
            textVariant={"h5"}
            access={access}
          />}
      </Stack>
      {
        localPeople.map((person, index) => {
          let selected = (selectedPeople.filter(people => person["@id"] === people["@id"]).length > 0);
          return <PeopleItem
            {...props}
            key={`people-${index}`}
            index={index}
            person={person}
            isSelected={selected}
            setSelected={updateSelectedList}
            allowSelect={allowSelect}
            updateFromBulk={updateFromBulk}
          />
        })
      }
    </Stack>
  )
}

export default PeopleList;