import {
  Autocomplete,
  Box, Chip, Stack, TextField, Tooltip,
  Typography,
} from "@mui/material";
import {en} from "language/en";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {enCommonLabel} from "constants/index";

interface DataTagInputProps {
  componentId: string,
  size?: "small" | "medium",
  sx?: any
}

function DataTagInput(props: DataTagInputProps) {
  const {componentId, size, sx} = props;

  return (
    <Autocomplete
      multiple
      freeSolo
      id="tags-outlined"
      options={[]}
      size={size}
      sx={sx}
      renderInput={params => (
        <Stack>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Typography variant="h5">{en.dataTag.label}</Typography>
            <Typography
              variant="h5"
              sx={(theme) => ({
                color: theme.palette.neutral.dark,
                display: "inline",
                ml: "3px",
                mr: 1,
              })}
            >
              ({enCommonLabel.optional})
            </Typography>
            <Tooltip title={en.dataTag.tooltip}>
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  cursor: "pointer",
                }}
              >
                <SystemIcons.Info
                  stroke={theme.palette.neutral.main}
                  height={16}
                  width={16}
                />
              </Box>
            </Tooltip>
          </Box>
          <TextField
            {...params}
            variant="outlined"
            id={componentId}
            sx={{mb: 1}}
            onChange={() => {
            }}
          />
        </Stack>
      )}
      renderTags={(tagValue) => {
        return tagValue.map((option) => (
          <Chip
            label={option}
            sx={(theme) => ({
              display: "flex",
              borderRadius: 1,
              color: theme.palette.primary.dark,
              backgroundColor: theme.palette.background.default,
              textTransform: "capitalize",
            })}
            className="data-tag-item"
            deleteIcon={<SystemIcons.Close stroke={theme.palette.neutral.dark}/>}
          />
        ));
      }
      }
    />
  );
}

export default DataTagInput;
