import {Grid} from "@mui/material";
import {Organization} from "types/Organization";
import OrganizationCard from "./OrganizationCard";
import {BaseProps} from "screens/BaseProps";
import {emptyFunction} from "constants/index";

interface OrganizationListProps extends BaseProps {
  organizations: Organization[];
  updateSelected: (obj: Organization | null) => void;
  deleteOrg: (org: Organization) => void;
  fromSAP?: boolean;
  parentPath: string;
}

function OrganizationsList(props: OrganizationListProps) {
  const {setSelectedOrgId = emptyFunction, organizations} = props;

  return (
    <Grid container mt={0}>
      {organizations.map((org, index) =>
        <OrganizationCard
          key={`organization-card-${org["@id"]!}-${index}`}
          organization={org}
          setSelectedOrgId={setSelectedOrgId}
          {...props}
        />
      )}
    </Grid>
  );
}

export default OrganizationsList;
