import Pin from "components/Pin";
import React, {useState} from "react";
import {DocumentReference} from "firebase/firestore";
import {submitForm} from "../../screens/utility";
import {ActionType, Severity} from "enums/index";
import {ToastProps} from "../../screens/BaseProps";
import {ProcessType} from "enums/processType";
import {enCommonLabel} from "constants/index";
import {statusSubmitHandler} from "../../screens/utility/statusSubmitHandler";

interface PinIconButtonProps extends ToastProps {
  entity: string,
  pinned: boolean;
  documentReference: DocumentReference;
  sx?: any;
}

function PinIconButton(props: PinIconButtonProps) {
  const {pinned, sx = {}, entity, documentReference, toastProps} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function onPinClick() {
    setIsLoading(true);
    await submitForm<Partial<any>>(
      documentReference,
      ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler<any>({
        status,
        data,
        isLastUpdate,
        successCallback,
        errorCallback
      }),
      {pinned: !pinned}
    );
  }

  function successCallback() {
    setIsLoading(false);
    setToastMessage(`${entity} ${pinned ? "UnPinned": "Pinned"}`)
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
  }

  function errorCallback(message: any) {
    let toastMessage = enCommonLabel.errorProcess(ProcessType.Update);
    // if type of message is obj, get first obj value
    if (typeof message === "object" && (typeof Object.values(message)[0] === "string")) {
      toastMessage = Object.values(message)[0] as string;
    }

    setIsLoading(false);
    setToastMessage(toastMessage);
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
  }

  return (
    <Pin
      loading={isLoading}
      onPinClick={onPinClick}
      pinned={Boolean(pinned)}
      sx={sx}
    />
  )
}

export default PinIconButton;