import {CollapsableText} from "components/index";
import {enCommonLabel} from "constants/index";

interface CoordinatesProps {
  latitude?: number | null | undefined;
  longitude?: number | null | undefined;
}

function Coordinates(props: CoordinatesProps) {
  const {latitude, longitude} = props;

  if (latitude && longitude) return (
    <CollapsableText
      gap={0}
      text={`${enCommonLabel.latitude}: ${latitude}, ${enCommonLabel.longitude}: ${longitude}`}
      overflowWrap={"anywhere"}
    />
  );

  return (
    <CollapsableText
      gap={0}
      text={`${enCommonLabel.coordinatesNotAvailable}`}
      overflowWrap={"anywhere"}
    />
  );
}

export default Coordinates;