import {ToastProps} from "../../BaseProps";
import {AnsweredFormItemType} from "types/ActivityFeedTypes";
import BaseItem from "./BaseItem";
import SystemIndex from "assets/icons/system/system.index";
import IconBaseProps from "./IconBaseProps";
import {enActivityFeedLabel} from "constants/index";
import {useDocument} from "hooks/index";
import {doc} from "firebase/firestore";
import {userActivityFeedPath} from "../../utility";
import {SkeletonItem} from "components/index";
import React from "react";

interface AnsweredFormItemProps extends ToastProps {
  itemId: string;
  uid: string;
  index: number;
  updatePinnedValue: (index: number, pinned: boolean) => void;
}

function AnsweredFormItem(props: AnsweredFormItemProps) {
  const {toastProps, itemId, uid, updatePinnedValue, index} = props;

  const [activityFeed] = useDocument<AnsweredFormItemType>(doc(userActivityFeedPath(uid), itemId));

  if (!activityFeed) {
    return <SkeletonItem height={"5rem"} sx={{marginTop: "5px", width: "80%"}}/>
  }

  const {details} = activityFeed;
  const {by, name} = details;

  return <BaseItem
    index={index}
    uid={uid}
    item={activityFeed}
    icon={<SystemIndex.SystemIcons.Edit {...IconBaseProps}/>}
    text={enActivityFeedLabel.answeredForm(name, by.name ?? "")}
    toastProps={toastProps}
    updatePinnedValue={updatePinnedValue}
  />
}

export default AnsweredFormItem
