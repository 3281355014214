import {
  IconButton,
  List,
  ListItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import {BaseDialog} from "components/Dialogs/index";
import PrimaryButton from "../Button/PrimaryButton";
import {enCommonButton, enCommonLabel} from "constants/index";
import React from "react";
import downloadCSV from "../../screens/utility/downloadCsv";
import {getImportInstructionsData} from "components/Dialogs/utils/getImportInstructionsData";
import {headerNames, ImportEntity, instructionRows} from "constants/headerConstants";
import {useComponentToggler} from "hooks/index";
import DataTagColors from "components/Dialogs/DataTagColors";
import {Entity} from "enums/entity";

interface Props {
  entity: ImportEntity;
}

function ImportButtonInfoDialog(props: Props) {
  const{entity} = props;
  const instructionsData = getImportInstructionsData(entity);
  const [openState, {open, close}] = useComponentToggler(false)

  async function downloadSampleFile() {
    downloadCSV(
      instructionsData.allHeaders.join(","),
      instructionsData.fileName
    );
  }

  return (
    <>
      <IconButton onClick={open}>
        <SystemIcons.Info stroke={theme.palette.primary.main}/>
      </IconButton>
      <BaseDialog
        maxWidth="md"
        title={instructionsData.title}
        isOpen={openState}
        closeDialog={close}
        additionalActions={(
          <PrimaryButton
            onClickFcn={downloadSampleFile}
            disabled={false}
            startIcon={() => SystemIcons.Download({width: 16, height: 16, stroke: theme.palette.common.white})}
            label={enCommonButton.downloadSampleFile}
          />
        )}
      >
        <>
          <Stack direction="row" justifyContent="space-between">
            {instructionsData.steps.map((instruction, index) => {
              return (
                <Stack
                  sx={{
                    width: "32%",
                    backgroundColor: "#d3d3d3",
                    borderRadius: 1,
                    padding: 1,
                    pb: 5
                  }}
                  key={`import-instruction-step-${index}`}
                >
                  <Typography variant="h5" sx={{mb: 1}}>
                    {instruction.name}
                  </Typography>
                  <Typography variant="h4" sx={{mb: 1}}>
                    {instruction.header}
                  </Typography>
                  {instruction.description.map((description, descIndex) => (
                    <Typography
                      sx={{mb: 1}}
                      key={`import-instruction-step-${index}-description-${descIndex}`}
                    >
                      {description}
                    </Typography>
                  ))}
                </Stack>
              )
            })}
          </Stack>
          <Typography variant="h5" sx={{mt: 3}}>
            {enCommonLabel.notes}
          </Typography>
          <List sx={{listStyleType: 'disc', pl: 5, pb: 2}} dense={true}>
            {instructionsData.notes.map((note, index) => (
              <ListItem
                sx={{display: 'list-item', padding: 0}}
                key={`import-instruction-note-${index}`}
              >
                {note}
              </ListItem>
            ))}
          </List>
          <Paper sx={{borderRadius: 1, padding: 1, mb: 3}}>
            <Typography variant="h5">{instructionsData.upperTableLabel}</Typography>
            <Typography>
              {`${enCommonLabel.headers}: `}
              <Typography component={"span"} sx={{fontWeight: "bold"}}>
                [{instructionsData.upperTableHeaderFields.join(', ')}]
              </Typography>
            </Typography>

            <List sx={{listStyleType: 'disc', pl: 5, pb: 2}} dense={true}>
              {instructionsData.upperTableFieldNotes.map((note, index) => (
                <ListItem sx={{display: 'list-item', padding: 0}} key={`upper-table-note-fields-${index}`}>
                  {note}
                </ListItem>
              ))}
            </List>
            <TableContainer component={Paper}>
              <Table sx={{minWidth: 650}}>
                <TableHead>
                  <TableRow>
                    {instructionsData.tableFields.map((tableField, index) => (
                      <TableCell sx={{fontSize: "16px"}} key={`table-fields-${index}`}>
                        {instructionRows[tableField as headerNames].headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    {instructionsData.tableRows.map((tableRow, index) => {
                      return (
                        <TableCell component="th" scope="row" key={`${index}-cell-name`}>
                          {tableRow.example}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {instructionsData.belowTableLabel && (
              <>
                <Typography  sx={{mt: 2}}>
                  {instructionsData.belowTableLabel}
                </Typography>
                <List sx={{listStyleType: 'disc', pl: 5, pb: 2}} dense={true}>
                  {instructionsData.belowTableNotes.map((note, index) => (
                    <ListItem
                      sx={{display: 'list-item', padding: 0}}
                      key={`below-table-note-${index}`}
                    >
                      {note}
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </Paper>
          {entity === Entity.DataTag && <DataTagColors />}

          {instructionsData.warningNotes && (
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                backgroundColor: theme.palette.warning.main,
                borderRadius: 1,
                mt: 1,
                padding: 2,
                gap: 2
              }}
            >
              <Stack >
                <SystemIcons.Warning
                  height={24}
                  width={24}
                  stroke={theme.palette.error.main}
                />
              </Stack>
              <Stack>
                <Typography variant="h5" sx={{mt: 1}}>
                  {enCommonLabel.warning}
                </Typography>
                {instructionsData.warningNotes.map((note, index) => (
                  <Typography key={`warning-notes-${index}`}>{note}</Typography>
                ))}
              </Stack>
            </Stack>
          )}
        </>
      </BaseDialog>
    </>
  )
}

export default ImportButtonInfoDialog;