import {
  Box,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import React, {
  ChangeEvent,
  ReactElement,
  FocusEvent,
  MouseEvent
} from "react";
import theme from "theme/theme";

interface InputProps {
  label?: string;
  LabelComponent?: ReactElement;
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | React.ClipboardEvent<HTMLDivElement>) => void;
  validator?: [validity: boolean, errorMessage: string];
  value?: number;
  dataTestId?: string;
  placeholder?: string;
  InputProps?: any;
  sx?: SxProps<Theme>;
  textfieldSx?: SxProps<Theme>;
  defaultValue?: string;
  id?: string;
  optional?: boolean;
  validationMessage?: string;
  onFocus?: (e: FocusEvent) => void;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  minimumNumber?: number;
  disabled?: boolean;
  onKeyPress?: (e: React.KeyboardEvent) => void;
}

function NumberInput(props: InputProps) {
  const {
    label,
    LabelComponent,
    onChange,
    validator, // todo: remove this
    value,
    dataTestId,
    placeholder,
    InputProps,
    sx,
    textfieldSx,
    defaultValue,
    id,
    optional,
    validationMessage,
    onFocus,
    onClick,
    minimumNumber,
    disabled = false,
    onKeyPress
  } = props;

  let [valid, errorMessage] = [true, ""];

  if (validator) {
    [valid, errorMessage] = validator;
  }
  if (validationMessage) {
    valid = false;
  }

  return (
    <Box sx={{alignSelf: "stretch", ...sx}}>
      <Typography variant="h5">{label} {(optional && label) ?
        <Typography variant="body" color={theme.palette.neutral.dark}>(optional)</Typography> : null}</Typography>
      {LabelComponent}
      <TextField
        onKeyPress={onKeyPress}
        id={id}
        placeholder={placeholder}
        inputProps={{
          "data-testid": dataTestId,
        }}
        InputProps={{
          inputProps: {
            min: minimumNumber,
            ...InputProps,
          }
        }}
        value={value}
        defaultValue={defaultValue}
        InputLabelProps={{shrink: true}}
        type={"number"}
        fullWidth
        onChange={(e) => onChange(e)}
        onPaste={(e) => onChange(e)}
        onFocus={onFocus}
        onClick={onClick}
        required={!optional}
        disabled={disabled}
        sx={{
          ...textfieldSx,
          "& .MuiOutlinedInput-root": {
            padding: 0,
            "& fieldset": {
              borderColor: valid ? theme.palette.neutral.medium : theme.palette.error.main,
            },
          },
          "& .MuiInputBase-root": {
            color: disabled ? theme.palette.secondary.light : valid ? "primary" : "error",
            backgroundColor: disabled ? theme.palette.background.swiftDefault : theme.palette.background.paper,
          },
          "& .MuiOutlinedInput-input": {
            padding: "9px 16px",
          },
        }}
      />

      {(validator || validationMessage) && (
        <Box sx={{height: 0, marginBottom: "20px"}}>
          <Typography
            sx={{
              mt: 0.5,
              minHeight: "1em",
              color:theme.palette.error.main,
              alignSelf: "flex-start",
              width: "max-content",
              fontSize: "12px",
              lineHeight: "20px",
            }}
            id={id + "-validation-error"}
          >
            {validator && !valid && errorMessage}
            {validationMessage}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default NumberInput;
