import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import React from "react";
import {ButtonProps} from "types/ButtonProps";
import {LoadingButton} from "@mui/lab";

interface DownloadButtonProps extends ButtonProps {
  isLoading: boolean;
  variant?: "text" | "outlined" | "contained";
}

function DownloadButton(props: DownloadButtonProps) {
  const {
    id = "download-button",
    isLoading,
    disabled,
    startIcon = SystemIcons.Download,
    onClickFcn,
    label,
    variant = "outlined"
  } = props;

  return (
    <LoadingButton
      variant={variant}
      sx={{flex: 1}}
      loading={isLoading}
      onClick={onClickFcn}
      id={id}
      disabled={isLoading || disabled}
      startIcon={startIcon({fill: variant === "outlined" ? theme.palette.primary.main : "#fff"})}
    >
      {label}
    </LoadingButton>
  )
}

export default DownloadButton;