import {useEffect, useMemo, useState} from 'react';
import {
  CollectionReference,
  getCountFromServer,
  limit,
  orderBy,
  query,
  QueryConstraint
} from 'firebase/firestore';
import {useCollection} from "hooks/index";

interface WithId {
  "@id": string;
}

interface UseCollectionCountOptions {
  collectionRef: CollectionReference;
  constraints?: QueryConstraint[];
  hasOrderByTimestamp?: boolean;
}

function useCollectionCount<Type extends WithId>(props: UseCollectionCountOptions) {
  const {
    collectionRef,
    constraints = [],
    hasOrderByTimestamp = true,
  } = props;

  const [count, setCount] = useState<number>(0);
  const [error, setError] = useState<any>(null);

  const [lastDoc, updateProvidedConstraints, updateColRef] = useCollection<Type>(null, collectionRef, [
    ...constraints,
    ...(hasOrderByTimestamp ? [] : [orderBy("timeCreated", "desc")]),
    limit(1)
  ]);

  const lastDocId = useMemo<string | undefined>(() => {
    if (lastDoc === null) return;
    if (lastDoc.length === 0) return;
    return lastDoc[0]["@id"];
  }, [lastDoc]);

  useEffect(() => {
    updateProvidedConstraints([
      ...constraints,
      ...(hasOrderByTimestamp ? [] : [orderBy("timeCreated", "desc")]),
      limit(1)
    ]);
  }, [JSON.stringify(constraints), hasOrderByTimestamp]);

  useEffect(() => updateColRef(collectionRef), [collectionRef.path]);

  async function getTotalCount() {
    try {
      const collectionQuery = query(collectionRef, ...(constraints || []));
      const collectionSnapshot = await getCountFromServer(collectionQuery);
      setCount(collectionSnapshot.data().count || 0);
    } catch (e) {
      setError(e)
      return 0;
    }
  }

  async function reFetchTotalCount() {
    await getTotalCount();
  }

  useEffect(() => {
    getTotalCount();
  }, [lastDocId]);

  return {count, error, reFetchTotalCount};
}

export default useCollectionCount;
