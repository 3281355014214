import React from "react";
import {styled, SxProps, Tabs, Theme} from "@mui/material";
import theme from "theme/theme";

/** start of styles */
interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  sx?: SxProps<Theme>;
}

export default styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    variant="scrollable"
    TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
  />
))({
  '& .MuiButtonBase-root': {
    minWidth: 0,
    padding: 0,
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginTop: -10,
  },
  '& .MuiTabs-indicatorSpan': {
    // maxWidth: 26,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    marginTop: -3,
    height: 2,
  },
});
/** end of styles */