import ConfirmDialog from "components/ConfirmDialog";
import {DocumentReference} from "firebase/firestore";
import {submitForm} from "../../../screens/utility";
import {ActionType} from "enums/actionType";
import {emptyFunction} from "constants/index";

interface DeleteDialogProps {
  isOpen: boolean;
  title: string;
  text: string;
  handleClose: () => void;
  handleConfirm?: () => void;
  overlayId?: string;
  confirmButtonId?: string;
  cancelButtonId?: string;
  docToDelete?: DocumentReference;
  confirmButtonText?: string;
  disableConfirmButton?: boolean;
}

function DeleteDialog(props: DeleteDialogProps) {
  const {isOpen, title, text, overlayId, confirmButtonId, cancelButtonId, docToDelete, confirmButtonText, disableConfirmButton = false} = props;
  const {handleClose, handleConfirm} = props;

  async function deleteDocument(){
    if(!docToDelete) return;
    await submitForm(docToDelete, ActionType.Delete, emptyFunction);
  }

  let callableFunction = handleConfirm;
  if(docToDelete){
    callableFunction = deleteDocument;
  }

  return (
    <ConfirmDialog
      confirmButtonText={confirmButtonText}
      confirmButtonId={confirmButtonId}
      cancelButtonId={cancelButtonId}
      id={overlayId}
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={callableFunction!}
      title={title}
      text={text}
      disableConfirmButton={disableConfirmButton}
    />
  );
}

export default DeleteDialog;
