import {Entity} from "enums/entity";
import {ActivityType} from "enums/ActivityType";
import {Box, Typography} from "@mui/material";
import theme from "theme/theme";

function generateEntityName(entity: Entity, activityType: ActivityType) {
  const isInvite = entity === Entity.OrganizationInvite || entity === Entity.UserInvite;

  function getEntityName() {

    switch (entity) {
      case Entity.SwiftFile:
      case Entity.PLFile:
        return "File";
      case Entity.FileRequirement:
      case Entity.PLFileRequirement:
      case Entity.FormRequirement:
      case Entity.PLFormRequirement:
        return "Reqs"
      case Entity.Project:
        return "Project"
      case Entity.OrganizationInvite:
      case Entity.UserInvite:
        return "Invite"
      case Entity.Task:
      case Entity.PLTask:
        return "Tasks"
      case Entity.Asset:
        return "Assets"
      case Entity.PLMilestone:
        return "Milestones"
      case Entity.OrganizationTemplateMilestone:
      case Entity.OrganizationTemplateTask:
      case Entity.OrganizationTemplate:
        return "Templates"
      default:
        return entity
    }
  }

  return <Box sx={{
    p: 1 / 2,
    border: `1px solid ${theme.palette.neutral.light}`,
    borderRadius: 1,
    alignSelf: "flex-start",
    backgroundColor: isInvite ? theme.palette.primary.main : "none"
  }}>
    <Typography color={isInvite ? theme.palette.text.onDark : theme.palette.text.primary}>
      {getEntityName()}
    </Typography>
  </Box>
}

export default generateEntityName;
