import React, {useMemo, useState} from "react";
import {Hidden, IconButton, Stack} from "@mui/material";
import {Task} from "types/Task";
import {BaseProps} from "screens/BaseProps";
import {DotIndicator, PilledNavigationBar} from "components";
import {PillItemProps} from "components/PilledNavBar/PillItem";
import {SidebarIcons} from "assets/icons/menu/menu.index";
import {SystemIcons} from "assets/icons/system/system.index";
import {useNavigate, useParams} from "react-router-dom";
import {NavBarMenu} from "./Menus";
import theme from "theme/theme";
import Icons from "assets/icons";
import {enCommonButton, enTaskLabel} from "constants/index";
import {useComponentToggler, useDocument, useRTDBDocField} from "hooks/index";
import {collection, CollectionReference} from "firebase/firestore";
import {Entity} from "enums/entity";
import ChatDrawer from "components/ChatDrawer";
import {ManageAccessDrawer} from "components/Drawers";
import ActivityFeed from "components/ActivityFeed";
import {DownloadTimeDataDialog} from "components/Dialogs";
import {ChatIconButton, SwiftIconButton} from "components/Button";
import {AccessType, PermissionOperationKey} from "types/Permission";
import {Asset} from "types/Asset";
import {assetPath, assetsPath} from "../utility";
import EditTaskDrawer from "components/Drawers/EditTaskDrawer/EditTaskDrawer";

interface HeaderProps extends BaseProps {
  task: Task;
  taskAccess: AccessType | null;
  taskCollectionRef: CollectionReference;
  parentEntity: Entity;
  rtdbPath: string;
  tasksDocPath: string;
  fromTemplates: boolean;
}

function Header(props: HeaderProps) {
  const {task, toastProps, rtdbPath, taskCollectionRef, parentEntity, taskAccess} = props;
  const {orgId, projId, assetId, templateId, taskId} = useParams();

  const {orgName, name, projectName} = task;
  const navigate = useNavigate();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);
  const [isMenuShowing, setIsMenuShowing] = useState<boolean>(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const [asset, setAssetRef] = useDocument<Asset>(null);

  // notifs
  const hasNewActivity = useRTDBDocField<boolean>(rtdbPath, "hasNewActivity") ?? false;
  const hasNewChat = useRTDBDocField<boolean>(rtdbPath, "hasNewChat") ?? false;
  const chatEmpty = useRTDBDocField<boolean>(props.tasksDocPath, "chatEmpty") ?? true;

  const [isChatDrawerOpen, {open: openChatDrawer, close: closeChatDrawer}] = useComponentToggler(false);
  const [isManageAccessDrawerOpen, {
    open: openManageAccessDrawer,
    close: closeManageAccessDrawer
  }] = useComponentToggler(false);
  const [isTaskActivityFeedOpen, {
    open: openTaskActivityFeed,
    close: closeTaskActivityFeed
  }] = useComponentToggler(false);
  const [isDownloadTimeDataOpen, {
    open: openDownloadTimeData,
    close: closeDownloadTimeData
  }] = useComponentToggler(false);

  useMemo(() => {
    if (!assetId) return;
    setAssetRef(assetPath(orgId!, projId!, assetId!));
  }, [assetId]);

  const firstPillName = !!templateId ? ""
    : !!projectName ? " / " +projectName
      : " / " + asset?.projectName;

  let pills: PillItemProps[] = [
    {
      id: "org-name",
      label: `${orgName ?? "OrgName"} ${firstPillName ?? "/ ProjName"}`,
      icon: <Icons.SidebarIcons.Folder fill={theme.palette.neutral.dark} width={16} height={16}/>,
      onClickFcn: () => !!templateId ? navigate(`/${orgId!}/templates/${templateId!}/overview`)
        : navigate(`/${orgId!}/projects/${projId!}/overview`)
    },
  ];

  if (assetId !== undefined) {
    pills = [...pills, {
      id: "asset-name",
      label: asset?.name ?? "AssetName",
      icon: <SidebarIcons.Assets width={16} height={16}/>,
      onClickFcn: () => navigate(`/${orgId!}/projects/${projId!}/assets/${assetId!}`)
    }];
  }

  pills = [...pills, {
    id: "task-name",
    label: name ?? "TaskName",
    icon: <SystemIcons.Tasks width={16} height={16} stroke={theme.palette.neutral.dark}/>,
    onClickFcn: () => {
    }
  }];

  function openEditDrawer() {
    setIsEditDrawerOpen(true);
  }

  function onEditDrawerClose() {
    setIsEditDrawerOpen(false);
  }

  function openMoreMenu(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    setMenuAnchor(e.currentTarget);
    setIsMenuShowing(true);
  }

  function closeMoreMenu() {
    setMenuAnchor(null);
    setIsMenuShowing(false);
  }

  function onChatDrawerClick() {
    openChatDrawer();
  }

  return (
    <>
      {isDownloadTimeDataOpen && (
        <DownloadTimeDataDialog
          isOpen={isDownloadTimeDataOpen}
          toastProps={toastProps!}
          entity={Entity.Task}
          closeDialog={closeDownloadTimeData}
          downloadCollectionRef={collection(taskCollectionRef, taskId!, "exports")}
        />
      )}

      {parentEntity !== Entity.Templates && (
        <>
          <ActivityFeed
            uid={props.uid}
            isOpen={isTaskActivityFeedOpen}
            onClose={closeTaskActivityFeed}
            collectionReference={collection(taskCollectionRef, taskId!, "activityFeed")}
            entity={Entity.Task}
          />
          <ManageAccessDrawer
            uid={props.uid}
            isOpen={isManageAccessDrawerOpen}
            entity={Entity.Task}
            parentEntity={parentEntity}
            documentId={projId!}
            collectionRef={props.fromTemplates ? collection(assetsPath(orgId!, projId!), assetId!, "accessList")
              : collection(taskCollectionRef, taskId!, "accessList")}
            actualCollectionRef={collection(taskCollectionRef, taskId!, "accessList")}
            onDrawerClose={closeManageAccessDrawer}
            toastProps={toastProps!}
          />
          <ChatDrawer
            uid={props.uid}
            isOpen={isChatDrawerOpen}
            entity={Entity.Task}
            pathPair={[
              `${task?.orgName} / ${task?.projectName} / ${task?.assetName ?? ""}`,
              task?.name ?? ""
            ]}
            onClose={closeChatDrawer}
            colRef={collection(taskCollectionRef, taskId!, "chatMessages")}
            toastProps={toastProps!}
          />
        </>
      )}
      <EditTaskDrawer
        uid={props.uid}
        taskCollectionRef={taskCollectionRef}
        isOpen={isEditDrawerOpen}
        onClose={() => onEditDrawerClose()}
        task={task!}
        toastProps={toastProps}
        parentEntity={parentEntity}
      />
      <PilledNavigationBar {...props} pills={pills}>
        <Stack direction="row" gap={0.5}>
          <Hidden mdDown>
            <SwiftIconButton
              id="task-nav-bar-edit-task-button"
              onClickFcn={openEditDrawer}
              disabled={Boolean(!taskAccess?.[PermissionOperationKey.Update])}
              label={enTaskLabel.edit}
              startIcon={SystemIcons.Edit}
            />
            {parentEntity !== Entity.Templates && (
              <>
                <IconButton
                  id="task-nav-bar-download-time-log-button"
                  title={enTaskLabel.timeLog}
                  onClick={() => navigate("./time-logs")}
                >
                  <SystemIcons.DownloadTimeData/>
                </IconButton>
                <IconButton
                  id="task-nav-bar-manage-access-button"
                  title={enTaskLabel.manageAccess}
                  onClick={openManageAccessDrawer}
                >
                  <SystemIcons.Teams/>
                </IconButton>
                <IconButton
                  id="task-nav-bar-activity-feed-button"
                  title={enTaskLabel.activityFeed}
                  onClick={openTaskActivityFeed}
                >
                  <DotIndicator isVisible={Boolean(hasNewActivity)}>
                    <SystemIcons.Notifications/>
                  </DotIndicator>
                </IconButton>
                <ChatIconButton
                  id="task-nav-bar-chat-button"
                  entity={Entity.Task}
                  withNewMessage={hasNewChat}
                  chatEmpty={chatEmpty}
                  onClickFcn={onChatDrawerClick}
                />
              </>
            )}
          </Hidden>
          <IconButton
            id="task-nav-bar-more-button"
            title={enCommonButton.more}
            onClick={openMoreMenu}>
            <SystemIcons.MoreVertical fill={theme.palette.text.secondary}/>
          </IconButton>
          {isMenuShowing && (
            <NavBarMenu
              uid={props.uid}
              taskAccess={taskAccess}
              taskCollectionRef={taskCollectionRef}
              task={task!}
              toastProps={toastProps!}
              open={Boolean(menuAnchor)}
              anchorEl={menuAnchor}
              parentEntity={parentEntity}
              closeMenu={closeMoreMenu}
              downloadTimeData={openDownloadTimeData}
            />
          )}
        </Stack>
      </PilledNavigationBar>
    </>
  )
}

export default Header;