import {Tab, TabGroup, TabPanel} from "components/index";
import {enProjectLabel} from "constants/index";
import {Stack, Typography} from "@mui/material";
import TitleAndStatus from "./Overview/TitleAndStatus";
import {useDocument, useRTDBDocField} from "hooks/index";
import {Project} from "types/Project";
import {db} from "../../firebase";
import {doc} from "firebase/firestore";
import {toastProps} from "screens/BaseProps";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import AssetsAndTasks from "./AssetsAndTasks";
import TemplateIndicator from "./Overview/TemplateIndicator";
import {Data} from "hooks/useCollectionData";
import SearchAndFilter from "./Overview/SearchAndFilter";
import {CounterFields, DirectionalOrder} from "enums/index";
import BulkActions, {BulkActionsProps} from "./Overview/BulkActions";
import {getDocPath} from "../utility";

// needs to be revised if the other tabs will be implemented
const isActive = true;

enum TabIndex {
  Overview,
}

type PageHeaderProps = BulkActionsProps & {
  orgId: string;
  projId: string;
  canUpdate: boolean;
  uid: string;
  canUpdateStatus: boolean;
  projectDocPath: string;
  toastProps: toastProps;
  sortOrder: DirectionalOrder;
  setSortOrder: (order: DirectionalOrder) => void;
  setAlgoliaAssets: (assets: Data[] | null) => void;
}

function PageHeader(props: PageHeaderProps) {
  const {orgId, projId, canUpdate, uid, ...rest} = props;

  const [project] = useDocument<Project>(doc(db, props.projectDocPath));
  const assetCounts = useRTDBDocField<number>(getDocPath(uid, props.projectDocPath), CounterFields.AssetsCount)

  if (!project) return null;

  return (
    <Stack
      paddingY={2}
      maxWidth={"100vw"}
      paddingRight={"20%"}
      flexDirection={"column"}
      gap={1}
    >
      <TabGroup id="projectOverviewTabination">
        <Tab
          to={`/${orgId}/projects/${projId}/overview`}
          isActive
        >
          <Typography
            variant="h5"
            color={isActive ? "secondary.main" : "text.secondary"}
          >
            {enProjectLabel.overview}
          </Typography>
        </Tab>
      </TabGroup>
      <TabPanel tabIndex={TabIndex.Overview} value={TabIndex.Overview}>
        <Stack gap={1} flex={1} direction="column">
          <TitleAndStatus project={project} {...rest}/>
          <DataTagsDisplay
            uid={uid}
            dataTagsIds={project.dataTagsIds || []}
            toastProps={props.toastProps!}
            documentRef={doc(db, props.projectDocPath)}
            canEditDocumentRef={canUpdate}
          />
          <AssetsAndTasks
            uid={uid}
            project={project}
            toastProps={props.toastProps!}
            projectDocPath={props.projectDocPath}
          />
          {project.projectAssetTemplateId && <TemplateIndicator project={project}/>}
          {(assetCounts ?? 0) > 0 && (
            <>
              <SearchAndFilter
                orgId={orgId}
                projId={projId}
                sortOrder={props.sortOrder}
                setSortOrder={props.setSortOrder}
                setAlgoliaAssets={props.setAlgoliaAssets}
              />
              <BulkActions
                checkedAssets={props.checkedAssets}
                checkAllAssets={props.checkAllAssets}
                isCheckboxShown={props.isCheckboxShown}
                toggleCheckbox={props.toggleCheckbox}
                canView={props.canView}
                canDelete={props.canDelete}
                openDeleteDialog={props.openDeleteDialog}
                isLoading={props.isLoading}
              />
            </>
          )}
        </Stack>
      </TabPanel>
    </Stack>
  );
}

export default PageHeader;