import {DocumentReference, onSnapshot,} from "firebase/firestore";
import {useEffect, useState} from "react";
import {ViewStatus, ProcessType, Severity} from "enums/index";
import {Unsubscribe} from "firebase/firestore";
import {FormBase} from "types/FormBase";
import {toastProps} from "screens/BaseProps";
import {enCommonLabel} from "constants/index";

interface Entity {
  "@id": string,
  "@form"?: FormBase
}

type ProcessingReturns  = [
  finished: boolean,
  startProcessing: () => void,
  errorDocs: Set<string>
];

interface ProcessingHookProps {
  references: DocumentReference[],
  toastProps: toastProps,
  processType: ProcessType,
}

function useIfAnyDocIsProcessingInAnyCollection(props: ProcessingHookProps): ProcessingReturns {
  const {references, processType, toastProps} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps;

  const [startChecking, setStartChecking] = useState(false);
  const [anyDocIsProcessing, setAnyDocIsProcessing] = useState(true);
  const [finishedDocs, setFinishedDocs] = useState<Set<string>>(new Set([]));
  const [errorDocs, setErrorDocs] = useState<Set<string>>(new Set([]));
  const [subscriptions, setSubscriptions] = useState<Unsubscribe[]>([]);

  useEffect(() => {
    references.forEach((reference) => {
        const unsubscriber = onSnapshot(reference, async (snapshot) => {
          if (!snapshot.exists()) {
            setFinishedDocs((prevSet) => new Set([...Array.from(prevSet), reference.id]));
            return;
          }

          const doc = await snapshot.data() as Entity;
          const docStatus = (!doc["@form"] || !doc["@form"]["@status"]) ? ViewStatus.Finished : doc["@form"]["@status"];
          switch (docStatus) {
            case ViewStatus.Finished:
              setFinishedDocs((prevSet) => new Set([...Array.from(prevSet), doc["@id"]]));
              break;
            case ViewStatus.SecurityError:
            case ViewStatus.ValidationError:
              setErrorDocs((prevSet) => new Set([...Array.from(prevSet), doc["@id"]]));
              break;
          }
        })
        setSubscriptions([...subscriptions, unsubscriber]);
      }
    )

    return () => {
      setAnyDocIsProcessing(true)
      subscriptions.forEach((unsub) => unsub())
    };

  }, [JSON.stringify(references), references.length]);

  useEffect(() => {
    if ((errorDocs.size + finishedDocs.size) >= references.length && startChecking) {

      setAnyDocIsProcessing(false);
      subscriptions.forEach((unsub) => unsub());
      setStartChecking(false);
      setFinishedDocs(new Set());

      if(errorDocs.size > 0) {
        setToastMessage(enCommonLabel.errorProcess(processType));
        setToastSeverity(Severity.Error);
        setIsToastOpen(true);
      }
    }
  }, [finishedDocs.size, references.length, errorDocs.size])

  return [anyDocIsProcessing, () => {
    setFinishedDocs(new Set())
    setStartChecking(true)
  }, errorDocs];
}

export default useIfAnyDocIsProcessingInAnyCollection;
