import {Stack, styled} from "@mui/material";
import theme from "theme/theme";
import {FormField} from "types/FormField";
import {FormFieldType} from "enums/formFieldType";

export {BasicAdvancedOptions}from "./BasicAdvancedOptions";
export {ParagraphAdvancedOptions}from "./ParagraphAdvancedOptions";
export {DateAdvancedOptions}from "./DateAdvancedOptions";
export {ListAdvancedOptions} from "./ListAdvancedOptions";

export type FormFieldAdvancedOptionsProps = {
  changeFormData: (value: any, key: any, index?: number, isFormFieldData?: boolean) => void;
  index: number;
  formField: FormField<FormFieldType>;
}

export const AdvancedOptionWrapper = styled(Stack)({
  flexDirection: "row",
  justifyContent: "space-between",
  backgroundColor: theme.palette.common.white,
  width: "100%",
  alignItems: "center",
  padding: "0.5rem 1rem",
  borderRadius: "4px"
});

export const OptionColumnWrapper = styled(Stack)({
  flexDirection: "column",
  backgroundColor: theme.palette.common.white,
  width: "100%",
  alignItems: "left",
  padding: "0.5rem 1rem",
  borderRadius: "4px"
});