import {Box, Card, CardContent, Divider, Grid, IconButton, Stack, Typography,} from "@mui/material";
import React, {MouseEvent, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Project} from "types/Project";
import {useLocation} from "react-router";
import ProjectMenu from "./ProjectMenu";
import {LongText} from "components/index";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {ProjectStatusList} from "assets/arrayList/ProjectStatus";
import {ActionType, CounterFields, Entity, Severity} from "enums/index";
import {emptyFunction, enCommonLabel, enProjectLabel} from "constants/index";
import {useAccess, useDocument, useRTDBDocField} from "hooks/index";
import {ToastProps} from "screens/BaseProps";
import DataTagsDisplay from "components/DataTag/DataTagsDisplay";
import {PermissionEntity, PermissionOperationKey} from "types/Permission";
import {getDocPath, projectPath, submitForm} from "screens/utility";
import CardBadge, {OverlayType} from "components/CardBadge";
import useImportBuild from "hooks/useImportBuild";
import {collection, doc} from "firebase/firestore";
import {db} from "../../firebase";

interface ProjectCardProps extends ToastProps {
  project: Project;
  parentPath: string;
  updateSelected: (obj: Project | null, button: MENU_BUTTONS) => void;
  deleteProject: (obj: Project) => void;
  uid: string;
}

export enum MENU_BUTTONS {
  EDIT = "edit",
  MANAGE_ACCESS = "manageAccess",
  IMPORT = "import",
  EXPORT = "export",
  DOWNLOAD_TIME_DATA = "download",
  EMPTY = "",
}

function ProjectCard(props: ProjectCardProps) {
  const {project, parentPath, updateSelected, deleteProject, toastProps, uid} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;
  const {"@id": projectId} = project;

  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {orgId} = useParams();

  const [hovered, setHovered] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const moreButtonRef = useRef(null);

  const [projectDoc] = useDocument<Project>(doc(collection(db, parentPath), projectId));

  const {isProcessing, progress, isImport} = useImportBuild({path: projectPath(orgId!, projectId!).path});

  // fetch project counters
  const assetsCount = useRTDBDocField<number>(getDocPath(uid, props.parentPath, projectId), CounterFields.AssetsCount);

  const [access, , updateIds] = useAccess({
    uid,
    entity: Entity.Project,
    documentDocId: PermissionEntity.Project,
    ids: {projId: projectId!},
  });

  const [assetAccess, , updateAssetAccessId] = useAccess({
    uid,
    entity: Entity.Project,
    documentDocId: PermissionEntity.Asset,
  });

  useEffect(() => {
    if (!projectId) return;

    updateIds({projId: projectId!});
    updateAssetAccessId({projId: projectId!});
  }, [projectId, open]);

  useEffect(() => {
    // catch possible invalid import path
    if (!orgId || !projectId) return;

    if (!projectDoc) return;

    if (projectDoc.importStatus !== "Failed") return;

    setToastMessage(enCommonLabel.importFailed);
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);

    submitForm(projectPath(orgId!, projectId!), ActionType.Update, emptyFunction, {importStatus: null});
  }, [projectDoc?.importStatus]);

  function openMenu(e: MouseEvent<HTMLElement>) {
    e.stopPropagation();
    e.preventDefault();

    setAnchorEl(moreButtonRef.current);
    setOpen(true);
  }

  function handleMenuMoreOptionsClose(e: MouseEvent<HTMLElement>) {
    e.stopPropagation();

    updateSelected(null, MENU_BUTTONS.EMPTY);
    setAnchorEl(null);
    setOpen(false);
  }

  function handleMenuClick(button: MENU_BUTTONS) {
    updateSelected(project, button);
    setOpen(false);
    setAnchorEl(null);
  }

  function navigateToProjectsOverview() {
    if (!!projectDoc?.projectAssetTemplateId) {
      setToastMessage("Project that uses a template are temporarily disabled.");
      setToastSeverity(Severity.Error);
      setIsToastOpen(true);
      return;
    }

    navigate(`${pathname}/${projectId}/overview`);
  }

  function navigateToProjectLevelTasks() {
    navigate(`${pathname}/${projectId}/project-level-tasks`);
  }

  const actualProject = projectDoc || project;
  const {name, description, isPrivate, projectStatus} = actualProject;
  const displayedTags = actualProject?.dataTagsIds || [];

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={4}
      xl={3}
      minHeight={theme.spacing(20)}
      justifyContent="center"
      alignItems="center"
      paddingTop={0}
      paddingRight={1}
      mt={1.5}
    >
      {open && access && (
        <ProjectMenu
          closeMenu={handleMenuMoreOptionsClose}
          navigateProject={navigateToProjectsOverview}
          navigateToProjectLevelTasks={navigateToProjectLevelTasks}
          anchorEl={anchorEl}
          open={open}
          openManageAccess={() => handleMenuClick(MENU_BUTTONS.MANAGE_ACCESS)}
          deleteProject={() => deleteProject(project)}
          editProject={() => handleMenuClick(MENU_BUTTONS.EDIT)}
          access={access}
          uid={uid!}
          projId={project["@id"]!}
          navigateToManageAssets={() => navigate(`${projectId}/manage-assets`)}
          downloadTimeData={() => handleMenuClick(MENU_BUTTONS.DOWNLOAD_TIME_DATA)}
          openImportDialog={() => handleMenuClick(MENU_BUTTONS.IMPORT)}
          openExportDialog={() => handleMenuClick(MENU_BUTTONS.EXPORT)}
          usesTemplate={!!project.projectAssetTemplateId}
          canViewManageAsset={Boolean(assetAccess?.[PermissionOperationKey.View])}
          isImporting={isProcessing}
        />
      )}
      <Card
        key={projectId + "-options"}
        id={"project-card-" + projectId}
        className="proj-card"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onContextMenu={openMenu}
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          position: "relative",
          overflow: "inherit",
          border: `1px solid ${hovered ? theme.palette.primary.main : "transparent"}`,
          height: "100%",
          cursor: "pointer",
          ...(isProcessing ? {borderColor: theme.palette.warning.main}: {})
        })}
        onClick={navigateToProjectsOverview}
      >
        <CardBadge
          id={`project-badge-${projectId}`}
          text={!!projectDoc?.projectAssetTemplateId ? "Temporarily disabled" : isImport ? enProjectLabel.importingProjectData: enProjectLabel.buildingProjectData}
          buildingProgressCount={progress}
          toastProps={toastProps!}
          process={isImport ? OverlayType.Importing: OverlayType.Building}
          disabled={!!projectDoc?.projectAssetTemplateId}
        />
        <CardContent
          sx={{
            // display: "flex",
            flexDirection: "column",
            position: "relative",
            paddingTop: 2,
            flex: 1,
            gap: 1,
            paddingBottom: `0.5rem!important`,
            padding: 0
          }}
        >
          <Stack
            sx={{
              flexDirection: "column",
              position: "relative",
              flex: 1,
              gap: 1,
              padding: 1.5
            }}
          >
            <Stack direction="row" flex={1} justifyContent="space-between" m={0}>
              <LongText
                variant="h3"
                maxLines={2}
                className="org-name"
                id={`project-name-${projectId}`}
                onClick={navigateToProjectsOverview}
                overflowWrap="anywhere"
              >
                {name}
              </LongText>
              <IconButton
                id="more-options"
                onClick={openMenu}
                sx={{
                  height: "30px",
                  borderRadius: "3px",
                }}
                ref={moreButtonRef}
              >
                <SystemIcons.MoreVertical
                  stroke={theme.palette.text.secondary}
                  height={16}
                  width={16}
                />
              </IconButton>
            </Stack>
            <Stack direction="row" alignItems="flex-start" flex={1}>
              <LongText
                  variant="body"
                  textColor="text.secondary"
                  id={`project-desc-${projectId}`}
                  maxLines={2}
                  overflowWrap="anywhere"
              >
                {!!description ? description : enCommonLabel.noDescription}
              </LongText>
            </Stack>
            <DataTagsDisplay
              dataTagsIds={displayedTags}
              toastProps={toastProps}
              uid={uid}
              listSize="short"
              projId={projectId}
              canEditDocumentRef={Boolean(access?.[PermissionOperationKey.Update])}
              maxChipWidth={100}
              documentRef={projectPath(orgId!, projectId!)}
            />
          </Stack>
        </CardContent>
        <Divider/>
        <Box
          display="flex"
          gap={1}
          alignItems="center"
          justifyContent="space-between"
          onClick={navigateToProjectsOverview}
          minHeight={32}
          px={1.5}
          py={1}
        >
          <Box sx={{display: "flex", gap: 2, alignItems: "center"}}>
            {isPrivate && (
              <>
                <Box
                  id={`project-private-icon-${projectId}`}
                  sx={{display: "flex", alignItems: "center", gap: 1}}
                  title={enProjectLabel.private}
                >
                  <SystemIcons.LockFilled
                    stroke={theme.palette.neutral.dark}
                    fill={theme.palette.neutral.dark}
                    height={16}
                    width={16}
                  />
                </Box>
                <Divider flexItem orientation="vertical"/>
              </>
            )}
            <Box
              id={`project-assets-icon-${projectId}`}
              sx={{display: "flex", alignItems: "center", gap: 1}}
              title={enProjectLabel.assets}
            >
              <SystemIcons.Assets
                height={16}
                width={16}
                stroke={theme.palette.neutral.dark}
              />
              <Typography
                id={`project-asset-count-${projectId}`}
                variant="h5"
                sx={(theme) => ({color: theme.palette.text.secondary})}
              >
                {assetsCount || 0}
              </Typography>
            </Box>
          </Box>
          <Box sx={{display: "flex", gap: 1, alignItems: "center"}}>
            {ProjectStatusList[projectStatus].icon({fill: ProjectStatusList[projectStatus].color})}
            <Typography variant="h5" id={`project-status-${projectId}`}>
              {ProjectStatusList[projectStatus].title}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
}

export default ProjectCard;
