import React from 'react';
import theme from "theme/theme";
import {UsersMentionedData} from "types/UsersMentionedData";
import {EmailTooltip} from "components/index";
import {PartialUserData} from "types/PartialUserData";
import {Box} from "@mui/system";
import {linkify} from "screens/utility/linkify";

interface ChatWithMentionProps {
  chatMessage: string;
  mentionedUsersData?: UsersMentionedData;
}

const MENTIONED_STYLE = {
  cursor: "pointer",
  color: theme.palette.primary.light,
  fontWeight: 700,
  display: "inline"
};

export function ChatWithMention({chatMessage, mentionedUsersData}: ChatWithMentionProps): JSX.Element {

  const extractMentions = (message: string) => {
    const regex = /@\[.+?\]\(.+?\)/gm;
    return message.match(regex)?.map(m => {
      const id = m.match(/\(.+?\)/g)?.[0].replace(/[()]/g, "") ?? "";
      const display = m.match(/@\[.+?\]/g)?.[0].replace(/@\[|\]/g, "") ?? "";
      return {id, display};
    }) ?? [];
  };

  const createMessageFragments = (message: string, mentions: { id: string; display: string }[]) => {
    const splitMessage = message.split(/@\[.+?\]\(.+?\)/gm);
    return splitMessage.flatMap((part, partIndex) => {
      // Process each line within the part
      const formattedPart = part.split(/\n\r?/g).flatMap((line, lineIndex, arr) => {
        // Determine if this is the very first line of the entire content
        const isFirstLineOfMessage = partIndex === 0 && lineIndex === 0;
        // Linkify the line
        const linkedLine = linkify(line);
        // Conditionally add a <br /> except for the very last line or if it's the first line of the message
        const shouldAddBr = lineIndex < arr.length - 1 || (line === "" && !isFirstLineOfMessage);
        const lineContent = [
          linkedLine,
          shouldAddBr ? <React.Fragment key={lineIndex}><br/></React.Fragment> : null,
        ].filter(Boolean);

        return lineContent;
      });

      // Retrieve the mention element if applicable
      const mentionElement = mentions[partIndex] && mentionedUsersData?.[mentions[partIndex].id] ? (
        <EmailTooltip
          key={partIndex}
          user={{
            ...(mentionedUsersData[mentions[partIndex].id] as unknown as PartialUserData),
            initials: mentionedUsersData[mentions[partIndex].id].avatarInitials,
            name: mentions[partIndex].display
          }}
        >
          <Box style={MENTIONED_STYLE}>@{mentions[partIndex].display}</Box>
        </EmailTooltip>
      ) : null;

      // Return the combined content for this part
      return (
        <React.Fragment key={`fragment-${partIndex}`}>
          {formattedPart}
          {mentionElement}
        </React.Fragment>
      );
    }).filter(Boolean); // Filter out any nulls which might have been added
  };


  const mentions = extractMentions(chatMessage);
  return (
    <Box sx={{overflowWrap: "break-word"}}>
      {createMessageFragments(chatMessage, mentions)}
    </Box>
  );
}