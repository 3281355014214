import {ReactNode} from "react";
import {Stack, SxProps, Theme, Typography} from "@mui/material";

interface DateTypoProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
}

function DateTypo(props: DateTypoProps) {
  const {children, sx} = props;
  return (
    <Stack alignItems="center" marginY={1}>
      <Typography color="text.secondary" variant="h5" mb={1} sx={sx}>
        {children}
      </Typography>
    </Stack>
  )
}

export default DateTypo