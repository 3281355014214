import PageHeader from "./PageHeader";
import {BaseProps} from "screens/BaseProps";
import {templatesMilestonePath} from "screens/utility/FirebasePath";
import {useParams} from "react-router";
import {Stack} from "@mui/material";
import React from "react";
import {Template} from "types/Template";
import MilestonesView from "./MilestonesView";
import {AccessType} from "types/Permission";
import HideTaskSwitch from "./HideTaskSwitch";
import {useComponentToggler} from "hooks/index";

interface OverviewProps extends BaseProps {
  template: Template;
  taskAccess: AccessType | null;
  hiddenTasksCount: number;
}

function Overview(props: OverviewProps) {
  const {toastProps, uid, hiddenTasksCount} = props;
  const {orgId, templateId} = useParams();

  const templatesMilestonesPath = templatesMilestonePath(orgId!, templateId!);
  const [isHiddenShown, {toggle: toggleHidden}] = useComponentToggler(false);

  return (
    <Stack flex={1}>
      <PageHeader {...props} />
      <HideTaskSwitch
        showHidden={isHiddenShown}
        toggleHiddenTasks={toggleHidden}
        hiddenTasksCount={hiddenTasksCount || 0}
      />
      <Stack direction="column" gap={1}>
        <MilestonesView
          toastProps={toastProps!}
          milestonesCollectionRefPath={templatesMilestonesPath.path}
          showHidden={isHiddenShown}
          uid={uid}
        />
      </Stack>
    </Stack>
  );
}

export default Overview;
