import {Entity} from "enums/entity";
import {Grid, Hidden, Stack, Typography} from "@mui/material";
import theme from "theme/theme";
import {defaultAssignee, enTaskLabel} from "constants/index";
import {Avatar, EmailTooltip} from "components/index";
import TimelineSchedule from "../TimelineSchedule";
import {BaseProps} from "screens/BaseProps";
import {CollectionReference} from "firebase/firestore";
import TaskScheduleDrawer from "../../../components/Drawers/TaskScheduleDrawer";
import {useComponentToggler} from "hooks/index";
import {PartialUserData, Task} from "types/index";

interface TaskTimelineAndScheduleProps extends BaseProps {
  task: Task;
  canUpdate: boolean;
  taskCollectionRef: CollectionReference;
  parentEntity: Entity;
}

function TaskTimelineAndSchedule(props: TaskTimelineAndScheduleProps) {
  const {task, parentEntity, uid, taskCollectionRef, toastProps, canUpdate} = props;
  const {timelineStatus, taskSchedule, assignedTo, approver, observer, taskStatus} = task;

  const [isDrawerOpen, {open: openDrawer, close: closeDrawer}] = useComponentToggler(false);

  if (parentEntity === Entity.Templates) return null;

  const taskScheduleData = {
    canUpdate,
    task,
    taskCollectionRef,
    timelineStatus,
    taskStatus,
    taskSchedule: {...taskSchedule},
    toastProps: toastProps!,
  }

  return (<>
    {isDrawerOpen && (
      <TaskScheduleDrawer
        uid={uid}
        toastProps={toastProps!}
        task={task!}
        isDrawerOpen={isDrawerOpen}
        onClose={closeDrawer}
        collectionRef={taskCollectionRef}
      />
    )}
    <Hidden xsDown>
      <Grid item xs={12}>
        <TimelineSchedule openDrawer={openDrawer} {...taskScheduleData} uid={uid}/>
      </Grid>
    </Hidden>
    </>
  )
}

export default TaskTimelineAndSchedule;
