import React, {useEffect, useState} from "react";
import {Button, Popover, Stack} from "@mui/material";
import theme from "theme/theme";
import handleEnterKeyPress from "screens/utility/handleEnterKeyPress";
import DataTagInput from "components/DataTag/DataTagInput/index";
import {toastProps} from "screens/BaseProps";
import {en} from "language/en";
import {LoadingButton} from "@mui/lab";
import {DocumentReference} from "firebase/firestore";
import {ActionType, Severity} from "enums/index";
import {statusSubmitHandler} from "screens/utility/statusSubmitHandler";
import {enOrgDataTagsLabel} from "constants/index";
import templateSubmitForm from "screens/utility/templateSubmitForm";

interface DataTagInputPopupProps {
  isOpen: boolean;
  anchorEl?: Element;
  onClose: () => void;
  uid: string;
  toastProps: toastProps;
  initialTags?: string[],
  canEdit: boolean;
  documentRef: DocumentReference;
  fromTemplates?: boolean;
  originalPath?: string;
}

function DataTagInputPopup(props: DataTagInputPopupProps) {
  const {isOpen, anchorEl, onClose, canEdit, documentRef, uid, toastProps, fromTemplates = false} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataTagsIds, setDataTagsIds] = useState<string[]>([]);

  // update data tags
  useEffect(() => {
    const tags = [...props.initialTags ?? [], ...dataTagsIds];
    setDataTagsIds(Array.from(new Set(tags)));
  }, [props.initialTags]);

  async function submitDatatags() {
    if (!canEdit) {
      showToastMessage(Severity.Error, en.common.label.dataTags.noPermissionToAdd)
      return;
    }

    if (!documentRef) {
      showToastMessage(Severity.Error, en.common.label.dataTags.noDocumentRef)
      return;
    }

    setIsLoading(true);
    await templateSubmitForm(
      (fromTemplates ? props.originalPath : documentRef.path) || documentRef.path,
      ActionType.Update,
      (status, data, isLastUpdate) => statusSubmitHandler({status, data, isLastUpdate, successCallback, errorCallback}),
      {dataTagsIds}
    );
  }

  function successCallback() {
    setIsLoading(false);
    showToastMessage(Severity.Success, enOrgDataTagsLabel.updateSuccess);
    onClose();
  }

  function errorCallback(error: any) {
    setIsLoading(false);
    const message = typeof error === 'string' ? error : 'Failed to update data tags';
    showToastMessage(Severity.Error, enOrgDataTagsLabel.updateError(message));
  }

  function showToastMessage(severity: Severity, message: string) {
    setToastSeverity(severity);
    setToastMessage(message);
    setIsToastOpen(true);
  }

  function handleRightClick(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  }
  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      PaperProps={{
        style: {
          width: "310px",
          padding: "8px",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          transform: `translateY(22px)`,
          border: `2px solid ${theme.palette.neutral.medium}`
        },
      }}
      onClick={(e) => e.stopPropagation()}
      onContextMenu={handleRightClick}
    >
      <DataTagInput
        sx={{mb: 1}}
        handleSelectCallback={(ids) => setDataTagsIds(ids)}
        initialTags={dataTagsIds}
        toastProps={toastProps!}
        uid={uid}
        subText=" "
        onKeyPress={(e) => handleEnterKeyPress(e, true, false, submitDatatags)}
      />
      <Stack direction="row" gap={1} justifyContent="flex-end">
        <Button
          disabled={isLoading}
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.secondary,
          })}
          onClick={onClose}
        >
          {en.common.button.cancel}
        </Button>
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          onClick={submitDatatags}
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.neutral.medium}`,
          })}
        >
          {en.common.button.save}
        </LoadingButton>
      </Stack>
    </Popover>
  )
}

export default DataTagInputPopup;