import {collection, doc} from "firebase/firestore";
import {db} from "../../firebase";
import {PermissionEntity} from "types/Permission";

export function sapInvitesPath() {
  // wrapped in try because there is a possibility that the superuid is not provided
  try {
    return collection(db, "organizations", "withoutOrganization", "invites");
  } catch (e) {
    throw new Error("Super user id not provided");
  }
}

export function userExportsPath(uid: string) {
  return collection(db, "users",  uid, "exports");
}

export function usersPath() {
  return collection(db, "users");
}

export function userPath(uid: string) {
  return doc(db, "users", uid);
}

export function userFilesPath(uid: string) {
  return collection(db, "users", uid, "files");
}

export function organizationPath(orgId: string) {
  return doc(db, "organizations", orgId);
}

export function organizationsPath() {
  return collection(db, "organizations");
}

export function organizationMembersPath(orgId: string) {
  return collection(
    db,
    "organizations", orgId,
    "members"
  );
}

export function organizationAssetPath(orgId: string, assetId: string) {
  return doc(
    db,
    "organizations", orgId,
    "assets", assetId,
  );
}

export function organizationAssetsPath(orgId: string) {
  return collection(
    db,
    "organizations", orgId,
    "assets"
  );
}

export function organizationAssetsProjectsPath(orgId: string, assetId: string) {
  return collection(
    db,
    "organizations", orgId,
    "assets", assetId,
    "projects"
  );
}

export function organizationTeamsPath(orgId: string) {
  return collection(
    db,
    "organizations", orgId,
    "teams"
  );
}

export function projectPath(orgId: string, projId: string) {
  return doc(
    db,
    "organizations", orgId,
    "projects", projId
  );
}

export function projectsPath(orgId: string) {
  return collection(
    db,
    "organizations", orgId,
    "projects"
  );
}

export function projectAccessListPath(orgId: string, projectId: string) {
  return collection(
    db,
    "organizations", orgId,
    "projects", projectId,
    "accessList"
  );
}

export function assetsPath(orgId: string, projectId: string) {
  return collection(
    db,
    "organizations", orgId,
    "projects", projectId,
    "assets"
  );
}

export function projectAssetExportsPath(orgId: string, projectId: string, assetId: string) {
  return collection(
    db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "exports"
  );
}

export function projectLevelExportsPath(orgId: string, projectId: string) {
  return collection(
    db,
    "organizations", orgId,
    "projects", projectId,
    "plExports"
  );
}

export function assetPath(orgId: string, projectId: string, assetId?: string | undefined) {
  if (!assetId) {
    return doc(
      db,
      "organizations", orgId,
      "projects", projectId,
    );
  }

  return doc(
    db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId
  );
}

export function assetAccessListPath(orgId: string, projectId: string, assetId: string) {
  return collection(
    db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "accessList"
  );
}

export function availableAssetsPath(orgId: string, projectId: string) {
  return collection(
    db,
    "organizations", orgId,
    "projects", projectId,
    "availableAssets"
  );
}

export function projectMilestonesPath(orgId: string, projectId: string) {
  return collection(
    db,
    "organizations", orgId,
    "projects", projectId,
    "milestones"
  );
}

export function milestonesPath(orgId: string, projectId: string, assetId: string) {
  return collection(
    db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "milestones"
  );
}

export function milestonePath(orgId: string, projectId: string, assetId: string, milestoneId: string) {
  return doc(
    db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "milestones", milestoneId
  );
}

export function tasksPath(orgId: string, projectId: string, assetId: string | undefined, milestoneId: string, templateId: string | undefined) {
  if (templateId)
    return collection(
      db,
      "organizations", orgId,
      "templates", templateId,
      "milestones", milestoneId,
      "tasks"
    );

  if (!assetId)
    return collection(
      db,
      "organizations", orgId,
      "projects", projectId,
      "milestones", milestoneId,
      "tasks"
    );

  return collection(
    db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "milestones", milestoneId,
    "tasks"
  );
}

export function taskPath(orgId: string, projectId: string, assetId: string | undefined, milestoneId: string, taskId: string) {
  if (!assetId)
    return doc(
      db,
      "organizations", orgId,
      "projects", projectId,
      "milestones", milestoneId,
      "tasks", taskId
    );

  return doc(
    db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "milestones", milestoneId,
    "tasks", taskId
  );
}

export function taskAccessListPath(orgId: string, projectId: string, assetId: string | undefined, milestoneId: string, taskId: string) {
  if (typeof assetId === "undefined")
    return collection(
      db,
      "organizations", orgId,
      "projects", projectId,
      "milestones", milestoneId,
      "tasks", taskId,
      "accessList"
    );

  return collection(
    db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "milestones", milestoneId,
    "tasks", taskId,
    "accessList"
  );
}

export function fileRequirementsPath(orgId: string, projectId: string, assetId: string, milestoneId: string, taskId: string) {
  return collection(
    db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "milestones", milestoneId,
    "tasks", taskId,
    "fileRequirements"
  );
}

export function fileRequirementPath(orgId: string, projectId: string, assetId: string | undefined, milestoneId: string, taskId: string, requirementId: string) {
  if (assetId === undefined)
    return doc(
      db,
      "organizations", orgId,
      "projects", projectId,
      "milestones", milestoneId,
      "tasks", taskId,
      "fileRequirements", requirementId
    );

  return doc(
    db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "milestones", milestoneId,
    "tasks", taskId,
    "fileRequirements", requirementId
  );
}

export function fileRequirementAccessListPath(orgId: string, projectId: string, assetId: string | undefined, milestoneId: string, taskId: string, requirementId: string) {
  if (assetId === undefined)
    return collection(
      db,
      "organizations", orgId,
      "projects", projectId,
      "milestones", milestoneId,
      "tasks", taskId,
      "fileRequirements", requirementId,
      "accessList"
    );

  return collection(
    db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "milestones", milestoneId,
    "tasks", taskId,
    "fileRequirements", requirementId,
    "accessList"
  );
}

export function formRequirementsPath(orgId: string, projectId: string, assetId: string | undefined, milestoneId: string, taskId: string) {
  if (typeof assetId === "undefined")
    return collection(
      db,
      "organizations", orgId,
      "projects", projectId,
      "milestones", milestoneId,
      "tasks", taskId,
      "formRequirements"
    );

  return collection(
    db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "milestones", milestoneId,
    "tasks", taskId,
    "formRequirements"
  );
}

export function formRequirementPath(orgId: string, projectId: string, assetId: string | undefined, milestoneId: string, taskId: string, requirementId: string) {
  if (typeof assetId === "undefined")
    return doc(
      db,
      "organizations", orgId,
      "projects", projectId,
      "milestones", milestoneId,
      "tasks", taskId,
      "formRequirements", requirementId
    );

  return doc(
    db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "milestones", milestoneId,
    "tasks", taskId,
    "formRequirements", requirementId
  );
}

export function formRequirementAccessListPath(orgId: string, projectId: string, assetId: string | undefined, milestoneId: string, taskId: string, requirementId: string) {
  if (typeof assetId === "undefined")
    return collection(
      db,
      "organizations", orgId,
      "projects", projectId,
      "milestones", milestoneId,
      "tasks", taskId,
      "formRequirements", requirementId,
      "accessList"
    );

  return collection(
    db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "milestones", milestoneId,
    "tasks", taskId,
    "formRequirements", requirementId,
    "accessList"
  );
}

export function fileUploadsPath(uid: string) {
  return collection(db, "users", uid, "fileUploads");
}

export function fileUploadDonePath(uid: string) {
  return collection(db, "users", uid, "fileUploadDone");
}

export function fileUploadsFileChunksPath(filedId: string, uid: string) {
  return collection(db, "users", uid, "fileUploads", filedId, "chunks");
}

export function fileRequirementFilesPath(orgId: string, projectId: string, assetId: string, milestoneId: string, taskId: string, fileRequirementId: string) {
  return collection(db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "milestones", milestoneId,
    "tasks", taskId,
    "fileRequirements", fileRequirementId,
    "files"
  );
}

export function fileRequirementDownloadsPath(orgId: string, projectId: string, assetId: string, milestoneId: string, taskId: string, fileRequirementId: string) {
  return collection(db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "milestones", milestoneId,
    "tasks", taskId,
    "fileRequirements", fileRequirementId,
    "downloads"
  );
}

export function permissionsPath() {
  return collection(db,
    "permissions"
  );
}

export function permissionDocumentPath(entity: PermissionEntity) {
  return doc(db,
    "permissions", entity
  );
}

export function orgInvitesCollectionPath(orgId: string) {
  return collection(
    db,
    "organizations",
    orgId,
    "invites"
  )
}

export function orgTeamCategoriesCollectionPath(orgId: string) {
  return collection(
    db,
    "organizations",
    orgId,
    "teamCategories"
  )
}

export function userInvitesPath(uid: string) {
  return collection(
    db,
    "users", uid,
    "invites"
  );
}

export function userInvitePath(invitationId: string, uid: string) {
  return doc(
    db,
    "users", uid,
    "invites", invitationId
  );
}

export function teamCategoriesPath(orgId: string) {
  return collection(
    db,
    "organizations",
    orgId,
    "teamCategories"
  );
}

export function teamsPath(orgId: string) {
  return collection(
    db,
    "organizations",
    orgId,
    "teams",
  );
}

export function teamMembersPath(orgId: string, teamId: string) {
  return collection(
    db,
    "organizations",
    orgId,
    "teams",
    teamId,
    "teamMembers",
  );
}

export function assetFilesDownloadPath(orgId: string, projectId: string, assetId: string) {
  return collection(db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "downloads"
  );
}

export function taskFilesDownloadPath(orgId: string, projectId: string, assetId: string | undefined, milestoneId: string, taskId: string) {
  if (typeof assetId === "undefined")
    return collection(db,
      "organizations", orgId,
      "projects", projectId,
      "milestones", milestoneId,
      "tasks", taskId,
      "downloads"
    );

  return collection(db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "milestones", milestoneId,
    "tasks", taskId,
    "downloads"
  );
}

export function organizationDataTagsPath(orgId: string) {
  return collection(db,
    "organizations", orgId,
    "dataTags"
  );
}

export function projectChatMessagesPath(orgId: string, projectId: string) {
  return collection(db,
    "organizations", orgId,
    "projects", projectId,
    "chatMessages"
  );
}

export function projectLevelTasksChatMessagesPath(orgId: string, projectId: string) {
  return collection(db,
    "organizations", orgId,
    "projects", projectId,
    "projectLevelTasksChatMessages"
  );
}

export function assetChatMessagesPath(orgId: string, projectId: string, assetId: string) {
  return collection(db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "chatMessages"
  );
}

export function projectOverviewActivityFeedPath(orgId: string, projectId: string) {
  return collection(db,
    "organizations", orgId,
    "projects", projectId,
    "activityFeed"
  );
}

export function projectExportsPath(orgId: string, projectId: string) {
  return collection(db,
    "organizations", orgId,
    "projects", projectId,
    "exports"
  );
}

export function projectAssetActivityFeedPath(orgId: string, projectId: string, assetId: string) {
  return collection(db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "activityFeed"
  );
}

export function projectLevelTasksAccessListPath(orgId: string, projectId: string) {
  return collection(db,
    "organizations", orgId,
    "projects", projectId,
    "projectLevelTasksAccessList"
  );
}

export function projectLevelTasksFileRequirementPath(orgId: string, projectId: string, milestoneId: string, tasksId: string, fileRequirementId: string) {
  return doc(db,
    "organizations", orgId,
    "projects", projectId,
    "milestones", milestoneId,
    "tasks", tasksId,
    "fileRequirements", fileRequirementId,
  );
}

export function projectLevelTasksFormRequirementPath(orgId: string, projectId: string, milestoneId: string, tasksId: string, fileRequirementId: string) {
  return doc(db,
    "organizations", orgId,
    "projects", projectId,
    "milestones", milestoneId,
    "tasks", tasksId,
    "formRequirements", fileRequirementId,
  );
}

export function projectLevelTasksDownloadsPath(orgId: string, projId: string) {
  return collection(db,
    "organizations", orgId!,
    "projects", projId!,
    "projectLevelTasksDownloads"
  );
}

export function milestonesDownloadsPath(orgId: string, projId: string, assetId: string, milestoneId: string) {
  return collection(db,
    "organizations", orgId!,
    "projects", projId!,
    "assets", assetId!,
    "milestones", milestoneId!,
    "downloads"
  );
}

export function projectLevelMilestonePath(orgId: string, projId: string, milestoneId: string) {
  return doc(db,
    "organizations", orgId!,
    "projects", projId!,
    "milestones", milestoneId!
  );
}

export function projectLevelMilestonesDownloadsPath(orgId: string, projId: string, milestoneId: string) {
  return collection(db,
    "organizations", orgId!,
    "projects", projId!,
    "milestones", milestoneId!,
    "downloads"
  );
}

export function projectLevelFileRequirementPath(orgId: string, projId: string, milestoneId: string, taskId: string, requirementId: string) {
  return doc(db,
    "organizations", orgId!,
    "projects", projId!,
    "milestones", milestoneId!,
    "tasks", taskId,
    "fileRequirements", requirementId
  );
}

export function projectLevelFileRequirementsDownloadsPath(orgId: string, projId: string, milestoneId: string, taskId: string, requirementId: string) {
  return collection(db,
    "organizations", orgId!,
    "projects", projId!,
    "milestones", milestoneId!,
    "tasks", taskId,
    "fileRequirements", requirementId,
    "downloads"
  );
}

export function formsPath(orgId: string) {
  return collection(db,
    "organizations", orgId!,
    "forms"
  );
}

export function referenceFilespath(orgId: string) {
  return collection(db,
    "organizations", orgId!,
    "referenceFiles"
  );
}

export function templatesPath(orgId: string) {
  return collection(db,
    "organizations", orgId,
    "templates"
  );
}

export function templatesExportsPath(orgId: string, templateId: string) {
  return collection(db,
    "organizations", orgId,
    "templates", templateId,
    "exports"
  );
}

export function templatesMilestonePath(orgId: string, templateId: string) {
  return collection(db,
    "organizations", orgId,
    "templates", templateId,
    "milestones"
  );
}

export function templateProjectsPath(orgId: string, templateId: string) {
  return collection(db,
    "organizations", orgId,
    "templates", templateId,
    "projects"
  );
}

export function timeLogsPath(orgId: string, projectId: string, assetId: string | undefined, milestoneId: string, tasksId: string) {
  if (!assetId)
    return collection(db,
      "organizations", orgId,
      "projects", projectId,
      "milestones", milestoneId,
      "tasks", tasksId,
      "timeLogs",
    );

  return collection(db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "milestones", milestoneId,
    "tasks", tasksId,
    "timeLogs",
  )
}

export function myTimersPath(uid: string) {
  return collection(db,
    "users", uid,
    "myTimers"
  )
}

export function organizationTimersPath(orgId: string) {
  return collection(db,
    "organizations", orgId,
    "timers"
  )
}

export function tasksTimersPath(orgId: string, projectId: string, assetId: string  | undefined, milestoneId: string, tasksId: string) {
  if (typeof assetId === "undefined")
    return collection(db,
      "organizations", orgId,
      "projects", projectId,
      "milestones", milestoneId,
      "tasks", tasksId,
      "timers",
    );

  return collection(db,
    "organizations", orgId,
    "projects", projectId,
    "assets", assetId,
    "milestones", milestoneId,
    "tasks", tasksId,
    "timers",
  )
}

export function myTasksPath(uid: string) {
  return collection(db,
    "users", uid,
    "myTasks"
  )
}

export function homeExportsPath(uid: string) {
  return collection(db,
    "users", uid,
    "homeExports"
  )
}

export function featuresPath() {
  return collection(db,
    "features"
  )
}

export function userActivityFeedPath(uid: string) {
  return collection(db,
    "users", uid,
    "activityFeed"
  )
}

export function resetPasswordRequestsPath(userId: string) {
  return collection(db,
    "users", userId,
    "resetPasswordRequests"
  )
}

export function userChatsPath(uid: string) {
  return collection(db,
    "users", uid,
    "entityChats"
  )
}

export function resendVerificationEmailPath(userId: string) {
  return collection(db,
    "users", userId,
    "resendVerificationEmail"
  )
}

export function configPath() {
  return doc(db,
    "config",
    "configDoc",
  );
}

