import {FileUploadItemType} from "types/ActivityFeedTypes";
import {FileStatus} from "enums/fileStatus";
import {Box, Stack} from "@mui/material";
import {enActivityFeedLabel, spinTransform} from "constants/index";
import TimestampTypo from "components/Typography/TimestampTypo";
import {StatusIcons} from "assets/icons/status/status.index";
import {formatDateTimeFirestoreDate} from "../../../screens/utility";
import IconBaseProps from "../../../screens/ActivityFeed/ActivityItems/IconBaseProps";
import {SystemIcons} from "assets/icons/system/system.index";
import React from "react";
import {FileUploadStatus} from "enums/fileUploadStatus";
import ItemContainer from "components/ActivityFeed/ItemContainer";


interface FileUploadItemProps {
  item: FileUploadItemType;
}

function FileStatusChangeItem(props: FileUploadItemProps) {
  const {item} = props;
  const {details, timestamp} = item;
  const {fileStatus, fileUploadStatus} = details.fileUpload;
  const {date, time} = formatDateTimeFirestoreDate(timestamp);
  const createdTime = formatDateTimeFirestoreDate(details.fileUpload.timeCreated)

  let Icon = <></>;
  let text = <></>;

  switch (fileStatus) {
    case FileStatus.Approved:
      Icon = <StatusIcons.Approved {...IconBaseProps}/>;
      text = enActivityFeedLabel.fileStatusChange(fileStatus, details.by.name || "", details.name);
      break;
    case FileStatus.Failed:
      Icon = <StatusIcons.Rejected {...IconBaseProps}/>;
      text = enActivityFeedLabel.failedUpload(details.by.name || "", details.name);
      break;
    case FileStatus.Rejected:
      Icon = <StatusIcons.Rejected {...IconBaseProps}/>;
      text = enActivityFeedLabel.fileStatusChange(fileStatus, details.by.name || "", details.name);
      break;
    case FileStatus.Uploaded:
      Icon = <StatusIcons.Uploaded {...IconBaseProps}/>;
      text = enActivityFeedLabel.profileUploadSuccess(fileStatus, details.by.name || "", details.name, `${createdTime.date} ${createdTime.time}`);
      break;
    case FileStatus.Uploading:
      Icon = (
        <Box sx={{animation: `${spinTransform} 1s infinite linear`}}>
          <SystemIcons.LoaderOnWhite {...IconBaseProps}/>
        </Box>
      );
      text = <>{enActivityFeedLabel.uploading}</>
      break;
  }

  if (fileUploadStatus && fileUploadStatus === FileUploadStatus.Failed) {
    Icon = <StatusIcons.Rejected {...IconBaseProps}/>;
    text = enActivityFeedLabel.failedUpload(details.by.name || "", details.name);
  }

  return (
    <ItemContainer>
      <Box width={24} height={24}>
        {Icon}
      </Box>
      <Stack direction="column">
        {text}
        <TimestampTypo>{`${date} ${time}`}</TimestampTypo>
      </Stack>
    </ItemContainer>
  );
}

export default FileStatusChangeItem;
