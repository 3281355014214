import theme from "theme/theme";

interface ChatWithMentionProps {
  chatMessage: string,
}

export function parseMentions(props: ChatWithMentionProps) {
  const {chatMessage} = props;
  let regex = /@\[.+?\]\(.+?\)/gm;
  let displayRegex = /@\[.+?\]/g;
  let idRegex = /\(.+?\)/g;
  let matches = chatMessage.match(regex);
  let arr: { id: string, display: string }[] = [];

  matches && matches.forEach(m => {
    // @ts-ignore
    let id = m.match(idRegex)[0]
      .replace("(", "")
      .replace(")", "");
    // @ts-ignore
    let display = m.match(displayRegex)[0]
      .replace("@[", "")
      .replace("]", "");

    arr.push({id: id, display: display});
  });
  let newChatMessage = chatMessage.split(regex);
  let output: any = [];
  let repeated = false;
  newChatMessage.forEach((c, i) => {
    const chat = !!c ?
      c.split(/\n\r?/g)
        .flatMap((item, _, arr) => (
          (arr.length > 1 && !!item) ? [item, <br/>] : [item]
        )) : c;
    if (i === newChatMessage.length - 1) output.push(chat);
    else {
      const mentionedUsers = arr.map((mentioned, i) => {
        const mentionedUser = mentioned.display;
        if (repeated) return null;
        repeated = i === (arr.length - 1);
        return <span style={{color: theme.palette.primary.main}}>@{mentionedUser}</span>
      });
      output.push([
        chat,
        mentionedUsers
      ]);
    }
  });

  return output
}
