import {Box, Slide, Stack} from "@mui/material";
import {FC, ReactElement, useEffect} from "react";
import {styled} from "@mui/material/styles";
import theme from "theme/theme";

const Content: FC = ({children}) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack direction="row" justifyContent="center">
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <Stack
          flex={1}
          sx={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
          paddingTop={1}
          mb={5}
          marginX={{xs: "5%", sm: "5%", md: "5%", lg: "10%"}}
        >
          <BoxMain>
            {children as ReactElement<any>}
          </BoxMain>
        </Stack>
      </Slide>
    </Stack>
  );
};

const BoxMain = styled(Box)({
  mb: theme.spacing(2),
  py: theme.spacing(2),
  mt: theme.spacing(2),
  flex: 1,
  display: "flex",
  flexDirection: "column",
  maxWidth: "100vw",
});

export default Content;
