export enum CounterFields {
  AccessListPeopleCount = "accessListPeopleCount",
  AccessListTeamsCount = "accessListTeamsCount",
  ActiveTimersCount = "activeTimersCount",
  AssetsCount = "assetsCount",
  AttachmentsCount = "attachmentsCount",
  AvailableAssetsCount = "availableAssetsCount",
  BuildingProgressCount = "buildingProgressCount",
  ChatMessagesCount = "chatMessagesCount",
  ChatSubscribersCount = "chatSubscribersCount",
  DataTagsCount = "dataTagsCount",
  DataTagsImportingProgressCount = "dataTagsImportingProgressCount",
  DelayedTasksCount = "delayedTasksCount",
  EntityChatsCount = "entityChatsCount",
  FavoriteTasksCount = "favoriteTasksCount",
  FieldsFilledCount = "fieldsFilledCount",
  FileRequirementsApprovedCount = "fileRequirementsApprovedCount",
  FileRequirementsCount = "fileRequirementsCount",
  FilesApprovedCount = "filesApprovedCount",
  FilesCount = "filesCount",
  FilesRejectedCount = "filesRejectedCount",
  FormsCount = "formsCount",
  FormRequirementsApprovedCount = "formRequirementsApprovedCount",
  FormRequirementsCount = "formRequirementsCount",
  HiddenReqCount = "hiddenReqCount",
  HiddenTasksCount = "hiddenTasksCount",
  ImportingProgressCount = "importingProgressCount",
  InvitesCount = "invitesCount",
  LengthUploaded = "lengthUploaded",
  MembersCount = "membersCount",
  MeetingParticipantsCount = "meetingParticipantsCount",
  MyActivityFeedCount = "myActivityFeedCount",
  MyTimersCount = "myTimersCount",
  OrganizationAssetsCount = "organizationAssetsCount",
  OrganizationAssetsImportingProgressCount = "organizationAssetsImportingProgressCount",
  OrganizationTimersCount = "organizationTimersCount",
  OrganizationsCount = "orgsCount",
  OrgAssetIdxCount = "orgAssetIdxCount",
  PendingInvitesCount = "pendingInvitesCount",
  ProjectLevelFilesCount = "projectLevelFilesCount",
  ProjectLevelRequirementsApprovedCount = "projectLevelRequirementsApprovedCount",
  ProjectLevelRequirementsCount = "projectLevelRequirementsCount",
  ProjectLevelTasksAccessListPeopleCount = "projectLevelTasksAccessListPeopleCount",
  ProjectLevelTasksAccessListTeamsCount = "projectLevelTasksAccessListTeamsCount",
  ProjectLevelTasksApprovedCount = "projectLevelTasksApprovedCount",
  ProjectLevelTasksCancelledCount = "projectLevelTasksCancelledCount",
  ProjectLevelTasksCount = "projectLevelTasksCount",
  ProjectsCount = "projectsCount",
  ReferenceFilesCount = "referenceFilesCount",
  RequirementsApprovedCount = "requirementsApprovedCount",
  RequirementsCount = "requirementsCount",
  RequirementsRejectedCount = "requirementsRejectedCount",
  TasksApprovedCount = "tasksApprovedCount",
  TasksAssignedToMeCount = "tasksAssignedToMeCount",
  TasksCount = "tasksCount",
  TasksCancelledCount = "tasksCancelledCount",
  TasksCreatedByMeCount = "tasksCreatedByMeCount",
  TeamCategoriesCount = "teamCategoriesCount",
  TeamMembersCount = "teamMembersCount",
  TeamsCount = "teamsCount",
  TemplatesCount = "templatesCount",
  TotalFieldsCount = "totalFieldsCount",
  TotalUploadFilesCount = "totalUploadFilesCount",
  UnassignedTasksCount = "unassignedTasksCount",
  UploadedCount = "uploadedCount",
  UploadingCount = "uploadingCount",
}