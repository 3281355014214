import {useEffect, useState} from "react";
import {child, onValue, ref} from 'firebase/database';
import {rtdb} from "../firebase";

/**
 * Listen to a specific field of a document in RTDB and return its data.
 * @param docRef
 * @param field
 */
function useRTDBDocField<T>(docRef: string | null, field: string): T | null | undefined{
  const [value, setValue] = useState<T | null | undefined>(null);

  useEffect(() => {
    if (!docRef || !field) return;

    const parentRef = ref(rtdb, docRef);
    const fieldRef = child(parentRef, field);
    const unsubscribe = onValue(fieldRef, (snapshot) => {
      if (snapshot.exists()) {
        setValue(snapshot.val());
        return;
      }
      setValue(null);
    }, (error) => {
      console.error("Firebase RTDB fetch error: ",docRef);
      setValue(null);
    });

    return () => unsubscribe();
  }, [docRef, field]);

  return value;
}

export default useRTDBDocField;