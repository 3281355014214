import {useEffect, useState} from "react";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../../../firebase";
import {ReferenceFile} from "types/ReferenceFile";
import SystemIndex from "assets/icons/system/system.index";
import {Box} from "@mui/material";
import theme from "theme/theme";
import {InProgress} from "components/index";
import getEnvKey from "../../utility/getEnvKey";
import {storageBucketKey} from "constants/envKeys";

function useGenerateFileReferenceThumbnail(referenceFile: ReferenceFile, defaultElement: JSX.Element = <></>) {
  const {filepath} = referenceFile;
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);
  const storageBucket = getEnvKey(storageBucketKey);

  //get file url from file preview path
  useEffect(() => {
    if ((typeof (filepath) !== "string" && filepath !== "") || !storageBucket) return;

    const previewReference = ref(storage, `${storageBucket}${filepath}`);
    getDownloadURL(previewReference).then(setFileUrl).catch(() => setFileUrl(undefined));
  }, [])

  if (!filepath) return defaultElement;

  const imageExtensions = /(.jpg|.jpeg|.png)$/i;
  const isImage = imageExtensions.exec(filepath);

  if (isImage) {
    if (!fileUrl)
      return <InProgress size={22} sx={{marginY: 0, marginX: 2}}/>

    return <img src={fileUrl} style={{maxWidth: 120, maxHeight: 60, borderRadius: 4}} alt="file preview"/>
  }

  return <Box sx={{
    backgroundColor: theme.palette.background.swiftDefault,
    width: 100,
    height: 60,
    display: "grid",
    placeItems: "center",
    borderRadius: 1
  }}><SystemIndex.SystemIcons.FilesFilled/></Box>
}

export default useGenerateFileReferenceThumbnail;
