import {Checkbox, FormControlLabel, Stack} from "@mui/material";
import {enTemplateLabel} from "constants/index";
import React from "react";
import {Template} from "types/Template";
import {AccessType, PermissionOperationKey} from "types/Permission";
import DeleteButton from "components/Button/BulkActionButtons/DeleteButton";

interface BulkActionsProps {
  access: AccessType;
  templates: Template[];
  checkedTemplates: string[];
  setCheckedTemplates: (template: string[]) => void;
  deleteSelectedTemplates: () => void;
}

function BulkActions(props: BulkActionsProps) {
  const {access, templates, checkedTemplates, setCheckedTemplates, deleteSelectedTemplates} = props;

  const isCheckedBulkActions = checkedTemplates.length === templates.length && checkedTemplates.length > 0;

  function selectAllTemplates(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    if (checked) {
      setCheckedTemplates(templates.map((template) => template["@id"] ?? ""));
      return;
    }

    setCheckedTemplates([]);
  }

  if (templates.length === 0) return null;

  return (
    <Stack direction="row" marginY={0} sx={{marginLeft: 2}}>
      <FormControlLabel
        sx={{alignSelf: "flex-start"}}
        control={
          <Checkbox
            id="bulk-select-checkbox"
            checked={isCheckedBulkActions}
            onChange={selectAllTemplates}
          />
        }
        label={<strong>{checkedTemplates.length === 0 ? enTemplateLabel.allTemplates
          : enTemplateLabel.xSelected(checkedTemplates.length)}</strong>
        }
      />
      {checkedTemplates.length > 0 && (
        <DeleteButton
          disabled={Boolean(!access[PermissionOperationKey.Delete])}
          onButtonClick={deleteSelectedTemplates}
        />
      )}
    </Stack>
  )
}

export default BulkActions;
