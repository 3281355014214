import {myTimersPath} from "screens/utility/FirebasePath";
import BaseTimersView from "components/Timers/TimersViews/BaseTimersView";
import {Collapse, IconButton, Stack, Typography} from "@mui/material";
import SystemIndex from "assets/icons/system/system.index";
import {useEffect, useState} from "react";
import {useCollection} from "hooks/index";
import {Timer} from "types/Timer";
import {EmptyList, InProgress} from "components/index";
import {Entity} from "enums/entity";
import theme from "theme/theme";

interface MyTimersViewProps {
  uid: string;
  collapsible?: boolean;
  emptyPage?: boolean;
}

export interface CollapseStates {
  started: boolean
  paused: boolean
}

function MyTimersView(props: MyTimersViewProps) {
  const {collapsible, emptyPage, uid} = props;
  const [collapseStates, setCollapseStates] = useState<CollapseStates>({started: false, paused: false})
  const [timers, _, updateColRef] = useCollection<Timer>(null, null, null);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (!uid) return;
    updateColRef(myTimersPath(uid));
  }, [uid]);

  function onCollapseClick() {
    if (isCollapsed) {
      setIsCollapsed(false)
    } else {
      setIsCollapsed(true)
    }
  }

  if(timers === null)
    return <InProgress/>;

  if (!timers.length) {
    if (emptyPage)
      return <Stack
        sx={{
          justifyContent: "center",
          minHeight: 500,
          flex: 1
        }}
      >
        <EmptyList
          entity={Entity.Timers}
          sx={{
            pointerEvents: "",
            position: "relative",
          }}
          logoProperties={{stroke: theme.palette.neutral.light, fill: theme.palette.background.default}}
        />
      </Stack>
  }

  return <>
    {collapsible && <Stack direction="row" gap={0.5} alignItems="center">
      <IconButton size="small" onClick={onCollapseClick}>
        {!isCollapsed ?
          <SystemIndex.SystemIcons.ChevronUp width={24} height={24}/>
          :
          <SystemIndex.SystemIcons.ChevronDown width={24} height={24}/>
        }
      </IconButton>
      <Stack direction="row" gap={0.5} alignItems="baseline">
        <Typography variant="h4">Timers</Typography>
        <Typography variant="h5" component="span" color="text.secondary">({timers ? timers.length : 0})</Typography>
      </Stack>
    </Stack>
    }
    <Collapse in={!isCollapsed}>
      <BaseTimersView
        uid={uid}
        collapseStates={collapseStates}
        setCollapseStates={setCollapseStates}
        collapsible={collapsible}
        colRef={myTimersPath(uid)}
        emptyPage={emptyPage}
      />
    </Collapse>
  </>
}

export default MyTimersView
