import {Input, ProjectTemplateInput, SimpleFormDrawer} from "components/index";
import {Project} from "types/Project";
import {BaseProps} from "screens/BaseProps";
import SystemIndex from "assets/icons/system/system.index";
import React, {Dispatch, useEffect, useState} from "react";
import {enCommonButton, enCommonLabel, enProjectLabel, quietPeriodMs} from "constants/index";
import {Box, Checkbox, FormControlLabel, SelectChangeEvent, Typography} from "@mui/material";
import DataTagInput from "components/DataTag/DataTagInput";
import {areObjectsEqual, submitForm, projectsPath} from "screens/utility";
import {useDebounce} from "hooks/index";
import {doc, getDocs, query, Timestamp, where} from "firebase/firestore";
import {ActionType, Severity, ProcessType} from "enums/index";
import {useParams} from "react-router-dom";
import handleEnterKeyPress from "screens/utility/handleEnterKeyPress";
import {statusSubmitHandler} from "screens/utility/statusSubmitHandler";

interface ManageProjectDrawerProps extends BaseProps {
  selectedProject: Project | null;
  isOpen: boolean;
  closeDrawer: () => void;
  role: string;
}

function ManageProjectDrawer(props: ManageProjectDrawerProps) {
  const {selectedProject, isOpen, toastProps, role, uid, closeDrawer} = props;
  const {setToastMessage, setToastSeverity, setIsToastOpen} = toastProps!;

  const [initialProject, setInitialProject] = useState({
    name: selectedProject?.name ?? "",
    description: selectedProject?.description ?? "",
    isPrivate: selectedProject?.isPrivate ?? false,
    dataTagsIds: (selectedProject?.dataTagsIds ?? []).sort(),
    templateId: selectedProject?.projectAssetTemplateId ?? ""
  });
  const [projName, setProjName] = useState<string>(initialProject.name);
  const [projDescription, setProjDescription] = useState<string>(initialProject.description);
  const [projIsPrivate, setProjIsPrivate] = useState<boolean>(initialProject.isPrivate);
  const [dataTagsIds, setDataTagsIds] = useState<string[]>(initialProject.dataTagsIds);

  const [templateId, setTemplateId] = useState<string | null>(initialProject.templateId ?? null);

  const {orgId} = useParams();
  const projectReference = selectedProject && selectedProject["@id"] ? doc(projectsPath(orgId!), selectedProject["@id"])
    : doc(projectsPath(orgId!));
  const [documentValidationError, setDocumentValidationError] = useState<any | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userIsTyping, setUserIsTyping] = useState<boolean>(false);

  useEffect(() => {
    let newProjectState = {
      name: selectedProject?.name ?? "",
      description: selectedProject?.description ?? "",
      isPrivate: selectedProject?.isPrivate ?? false,
      dataTagsIds: (selectedProject?.dataTagsIds ?? []).sort(),
      templateId: selectedProject?.projectAssetTemplateId ?? ""
    }

    setInitialProject(newProjectState);
    setProjName(newProjectState.name);
    setProjDescription(newProjectState.description);
    setProjIsPrivate(newProjectState.isPrivate);
    setDataTagsIds(newProjectState.dataTagsIds);
    setTemplateId(newProjectState.templateId);
    setDocumentValidationError(null);
    setIsLoading(false);
  }, [isOpen]);

  useDebounce(validateProjectName, quietPeriodMs, [projName]);

  async function validateProjectName() {
    if (projName === "" && userIsTyping) {
      setDocumentValidationError({name: enProjectLabel.requiredProjectName});
      return;
    }

    const querySameName = query(
      projectsPath(orgId!),
      where("nameIdx", "==", projName.trim().toLowerCase()),
      where("id", "!=", projectReference.id!)
    );

    const docData = await getDocs(querySameName);

    const docs = docData.docs;

    //there are no similar doc
    if (docs.length) {
      setDocumentValidationError({name: enProjectLabel.projectNameAlreadyExists});
    }
  }

  async function onSaveProject() {
    setIsLoading(true);
    setDocumentValidationError(null);
    const action = !selectedProject ? ActionType.Create : ActionType.Update;

    await submitForm<Partial<Project>>(
      projectReference,
      action,
      (status, data, isLastUpdate) => statusSubmitHandler({status, data, isLastUpdate, successCallback, errorCallback}),
      {
        name: projName.trim(),
        description: projDescription.trim(),
        isPrivate: projIsPrivate,
        dataTagsIds: dataTagsIds,
        lastVisited: Timestamp.now(),
        ...(!!templateId && {projectAssetTemplateId: templateId}),
      },
      selectedProject,
    );

  }

  function successCallback() {
    setToastMessage(!selectedProject ? enProjectLabel.createSuccess(projName)
      : enProjectLabel.editSuccess(projName));
    setToastSeverity(Severity.Success);
    setIsToastOpen(true);
    setIsLoading(false);
    handleCloseDrawer();
  }

  function errorCallback(message: any) {
    setIsLoading(false);
    if (typeof (message) === "object") {
      setDocumentValidationError(message);
      return;
    }

    setToastMessage(message || enCommonLabel.errorProcess(ProcessType.Update));
    setToastSeverity(Severity.Error);
    setIsToastOpen(true);
  }

  function handleNameInputChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | React.ClipboardEvent<HTMLDivElement> | SelectChangeEvent) {
    handleInputChange(e, setProjName);
    setUserIsTyping(true);
    setDocumentValidationError(null);
  }

  function handleInputChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | React.ClipboardEvent<HTMLDivElement> | SelectChangeEvent, setter: Dispatch<any>) {
    const element = e.target as HTMLInputElement;
    const value = element.value;
    setter(value);
  }

  function isFormEdited() {
    const newProj = {
      name: projName.trim(),
      description: projDescription.trim(),
      isPrivate: projIsPrivate,
      dataTagsIds: dataTagsIds.sort(),
      templateId,
    }
    return !areObjectsEqual(newProj, initialProject)
  }

  function isFormValid() {
    const formValidated = !!selectedProject ? (isFormEdited() && projName.trim() !== "") : projName.trim() !== "";
    return (formValidated && documentValidationError === null && !isLoading)
  }

  function handleCloseDrawer() {
    setUserIsTyping(false);
    closeDrawer();
  }

  return (
    <SimpleFormDrawer
      title={!selectedProject ? enProjectLabel.new : enProjectLabel.edit}
      isOpen={isOpen}
      onClose={handleCloseDrawer}
      icon={!selectedProject ? <SystemIndex.SystemIcons.Projects/>
        : <SystemIndex.SystemIcons.Edit/>
      }
      isFormValid={isFormValid()}
      isLoading={isLoading}
      onSubmit={onSaveProject}
      rightButtonLabel={selectedProject ? enCommonButton.save : enCommonButton.create}
      unsavedChanges={isFormEdited()}
      id="manage-project-drawer"
      buttonId="create-or-save-btn"
    >
      <Input
        id="proj-name-field"
        sx={{mb: 1}}
        label={enCommonLabel.name}
        onChange={handleNameInputChange}
        value={projName}
        validationMessage={
          documentValidationError ? documentValidationError.name : null
        }
        onKeyPress={(e) => handleEnterKeyPress(e, isFormValid(), isLoading, onSaveProject)}
      />
      <Input
        id="proj-desc-field"
        sx={{mb: 1}}
        LabelComponent={
          <Typography variant="h5">
            {`${enCommonLabel.description}`}
            <Typography
              sx={(theme) => ({
                color: theme.palette.neutral.dark,
                display: "inline",
              })}
            >
              {` (${enCommonLabel.optional})`}
            </Typography>
          </Typography>
        }
        value={projDescription}
        onChange={(e) => handleInputChange(e, setProjDescription)}
        multiline
        minRows={4}
        maxRows={10}
        onKeyPress={(e) => handleEnterKeyPress(e, isFormValid(), isLoading, onSaveProject)}
      />
      <DataTagInput
        sx={{mb: 1}}
        handleSelectCallback={setDataTagsIds}
        initialTags={dataTagsIds}
        toastProps={toastProps!}
        uid={uid}
        onKeyPress={(e) => handleEnterKeyPress(e, isFormValid(), isLoading, onSaveProject)}
      />
      <ProjectTemplateInput
        initialValue={templateId}
        onTemplateSelect={setTemplateId}
        sx={{mb: 1}}
        role={role}
        optional
        uid={uid}
      />
      <FormControlLabel
        control={
          <Checkbox
            onChange={(e) => setProjIsPrivate(e.target.checked)}
            checked={projIsPrivate}
            id="is-private-checkbox"
          />
        }
        label={enCommonLabel.private}
      />
      <Box sx={{flex: 1}}/>
    </SimpleFormDrawer>
  )
}

export default ManageProjectDrawer;
