import {ButtonProps} from "types/ButtonProps";
import {IconButton} from "@mui/material";
import React from "react";
import {enCommonLabel} from "constants/index";
import theme from "theme/theme";

function SwiftIconButton(props: ButtonProps) {
  const {
    className = "icon-button",
    id = "icon-button",
    title = "",
    disabled,
    startIcon,
    disabledHoverLabel = enCommonLabel.noPermissionForAction,
    onClickFcn,
    children,
    sx={}
  } = props;

  const icon = startIcon !== undefined ? disabled ? startIcon({stroke: theme.palette.text.secondary}) : startIcon({}) : children;

  return (
    <IconButton
      id={id}
      className={className}
      onClick={!disabled ? (e) => onClickFcn(e) : undefined}
      title={disabled ? disabledHoverLabel : title}
      sx={{
        ...sx,
        ...(disabled ? {
          cursor: "default",
          ":hover": {
            backgroundColor: "transparent"
          }
        } : {})
      }}
      disableRipple={disabled}
    >
      {icon}
    </IconButton>
  )
}

export default SwiftIconButton;