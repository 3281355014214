import {useNavigate} from "react-router-dom";
import {Box, Button, Hidden, Stack, styled, Typography} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import theme from "theme/theme";
import {SystemIcons} from "assets/icons/system/system.index";
import {InProgress} from "components/index";
import {collection, doc} from "firebase/firestore";
import {submitForm} from "../utility";
import {ActionType} from "enums/actionType";
import useQuery from "hooks/useQuery";
import {signInWithCustomToken} from "firebase/auth";
import {app, auth, db} from "../../firebase";
import TokenExpired from "./TokenExpired";
import {User as FirebaseUser} from "@firebase/auth";
import {OtherError} from "./OtherError";
import FormContainer from "components/FormContainer";
import {SwiftLogoOnly} from "assets/icons/SwiftLogo";
import {emptyFunction, enCommonButton, enCommonLabel} from "constants/index";
import {emailSupport} from "constants/email";
import useMaintenance from "hooks/useMaintenance";
import {initClient} from "emberflow-web-client/lib";
import {ViewStatus} from "enums/viewStatus";

export default function VerifyToken() {
  useMaintenance(false);
  const queryParams = useQuery();

  const token = queryParams.get("token");
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [user, setUser] = useState<{uid: string, email: string}>({uid: "", email: ""});

  // handle other errors aside from token expired and used
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // if token is not present, redirect to root route.
    if (!token) return navigate("/");

    if (!!auth.currentUser) {
      auth.signOut();
    }

    // sign-in using the received token
    signInWithCustomToken(auth, token ?? "")
      .then(async ({user}) => {
        const {uid, email} = user as unknown as FirebaseUser;
        if (!uid) {
          setErrorMessage("Unable to get user id from the token.");
          setIsError(true);
          setIsLoading(false);
          return;
        }

        if (!isLoading) return;

        setUser({uid, email: email ?? ""});
        initClient(
          app,
          uid,
          undefined,
          {
            "submit": ViewStatus.Submit,
            "submitted": ViewStatus.Submitted,
            "validation-error": ViewStatus.ValidationError,
            "security-error": ViewStatus.SecurityError,
            "finished": ViewStatus.Finished,
            "delay": ViewStatus.Delay,
            "cancel": ViewStatus.Cancel,
            "cancelled": ViewStatus.Cancelled,
            "error": ViewStatus.Error,
          }
        );
        await submitForm(doc(collection(db, "users"), uid), ActionType.Update, emptyFunction, {isVerified: true});
        setIsLoading(false);
      })
      .catch((e) => {
        if (e.code === "auth/invalid-custom-token") {
          setIsTokenExpired(true)
          setIsLoading(false);
          return;
        }

        setErrorMessage("Unable to use the given token. Please contact the administrator for assistance. " + e);
        setIsError(true);
        setIsLoading(false);
      });
  }, [token]);

  useMemo(() => {
    if (isLoading && !auth.currentUser) return;
    if (isTokenExpired) return;

    auth.signOut();
  }, [isTokenExpired, isError, isLoading]);

  if (isTokenExpired)
    return <TokenExpired user={user}/>

  if (isError)
    return <OtherError errorMessage={errorMessage}/>

  if (isLoading && !isError && !isTokenExpired)
    return <Stack justifyContent="center" sx={{height: "100vh"}}><InProgress/></Stack>

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.secondary.main,
        zIndex: "-2!important",
        backgroundImage: `url("/assets/Artboard6.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{height: "100vh"}}
      >
        <FormContainer
          sx={{
            minHeight: {xs: "95%", sm: "80%", md: "60%", lg: "60%", xl: "60%"},
            maxWidth: "393px",
          }}
        >
          <Stack justifyContent="center" gap={2} textAlign="center">
            <Stack direction="row" justifyContent="center">
              <SystemIcons.Selected fill={theme.palette.primary.main} width={48} height={48}/>
            </Stack>
            <Typography variant="h2" mb={2}>You made it!</Typography>
            <Stack>
              <Typography variant="body">{enCommonLabel.alreadyVerified}</Typography>
              <Typography variant="body">{enCommonLabel.returnToLogin}</Typography>
            </Stack>

            <Typography variant="body">
              {enCommonLabel.facingProblems}&nbsp;
              <StyledAnchor href={`mailto:${emailSupport}`}>
                <b>{enCommonLabel.contactUs}</b>
              </StyledAnchor>.
            </Typography>
            <Button
              variant="outlined"
              onClick={async () => {
                await auth.signOut();
                navigate(`/defaultOrg/home?adminPanel=false`);
              }}
              sx={{
                marginTop: 2,
                borderRadius: 5,
                color: theme.palette.secondary.main,
                borderColor: theme.palette.secondary.main,
                padding: 1,
                "&:hover": {
                  color: theme.palette.primary.main,
                }
              }}
            >
              {enCommonButton.backToLogin}
            </Button>
          </Stack>
        </FormContainer>
      </Stack>
      <Hidden smDown>
        <Box sx={{position: "absolute", bottom: "2%", right: "2%", zIndex: 2}}>
          <SwiftLogoOnly
            height={40}
            width={40}
            stroke={theme.palette.background.default}
            fill={theme.palette.background.default}
          />
        </Box>
      </Hidden>
    </Stack>
  )
}

const StyledAnchor = styled('a')`text-decoration: none;
  color: ${theme.palette.primary.main};`