import {Divider, Hidden, IconButton, Stack, Typography} from "@mui/material";
import {AccessRole} from "enums/accessRole";
import {ACCESS_ROLE_LABEL} from "constants/accessRoleLabel";
import {SystemIcons} from "assets/icons/system/system.index";
import theme from "theme/theme";
import React, {Dispatch} from "react";

interface PanelTitleProps {
  panelName: string;
  isExpanded: boolean;
  setIsExpanded: Dispatch<boolean>;
}

function PanelTitle(props: PanelTitleProps) {
  const {panelName, isExpanded, setIsExpanded} = props;

  return (
    <>
      <Stack direction="row" flex={1} mb={1} mt={3}>
        <Stack width={{xs: "100%", sm: "100%", md: "30%", lg: "50%"}}>
          <Typography variant="h5" paddingLeft={{lg: "3%"}}>
            <IconButton onClick={() => setIsExpanded(!isExpanded)} sx={{marginLeft: -2}}>
              {isExpanded ?
                <SystemIcons.ChevronUp stroke={theme.palette.neutral.dark}/>
                : <SystemIcons.ChevronDown stroke={theme.palette.neutral.dark}/>}
            </IconButton>
            {panelName}
          </Typography>
        </Stack>
        <Hidden mdDown>
          <Stack direction="row" width={{md: "70%", lg: "50%"}} justifyContent="space-evenly">
            <Typography variant="h5">
              {ACCESS_ROLE_LABEL[AccessRole.User]}
            </Typography>
            <Typography variant="h5">
              {ACCESS_ROLE_LABEL[AccessRole.PowerUser]}
            </Typography>
            <Typography variant="h5">
              {ACCESS_ROLE_LABEL[AccessRole.Admin]}
            </Typography>
            <Typography variant="h5">
              {ACCESS_ROLE_LABEL[AccessRole.Owner]}
            </Typography>
            <Typography variant="h5">
              {ACCESS_ROLE_LABEL[AccessRole.Guest]}
            </Typography>
          </Stack>
        </Hidden>
      </Stack>
      <Divider orientation="horizontal"/>
    </>
  )
}

export default PanelTitle;