import InviteCard from "components/InvitesView/InviteCard";
import {Invite} from "types/Invite";
import {Stack} from "@mui/material";
import {ToastProps} from "../../screens/BaseProps";
import EmptySearchResults from "components/EmptySearchResults";
import {Entity} from "enums/entity";
import {InvitationStatus} from "enums/invitationStatus";
import {allStatus, enActivityFeedLabel, enOrganizationLabel} from "constants/index";
import ActivityFeedInviteCard from "components/InvitesView/ActivityFeedInviteCard";
import {MiscOrder} from "enums/DirectionalOrder";

interface InvitesListProps extends ToastProps {
  uid: string;
  invites: Invite[];
  fromAlgolia: boolean;
  entity: Entity.TeamsAndPeople | Entity.Member | Entity.ActivityFeed | Entity.Organization;
  statusFilter: InvitationStatus | string;
}

function InvitesList(props: InvitesListProps) {
  const {invites, toastProps, fromAlgolia, entity, statusFilter} = props;

  if (fromAlgolia && invites.length === 0 && (fromAlgolia || statusFilter !== allStatus))
    return <EmptySearchResults entity={Entity.Invite}/>;

  switch (entity) {
    case Entity.TeamsAndPeople:
    case Entity.Member:
      return (
        <Stack gap={1}>
          {invites.map(invite => {
            return (
              <InviteCard
                uid={props.uid}
                key={`invite-card-${invite["@id"]}`}
                invitation={invite}
                toastProps={toastProps}
              />
            )
          })}
        </Stack>
      )

    case Entity.ActivityFeed:
      return (
        <Stack gap={1}>
          {(invites ?? []).map(invite => {
            const {organizationName, invitationStatus, invitedByUserData} = invite;
            return (
              <ActivityFeedInviteCard
                uid={props.uid}
                key={invite["@id"]}
                invitation={invite}
                toastProps={toastProps}
                text={invitationStatus === InvitationStatus.Expired ? enOrganizationLabel.invitationExpired(invitedByUserData.name, organizationName)
                  : enActivityFeedLabel.invitationToJoin(organizationName, invitedByUserData.name)}
                noAvatar={false}
                noPin={false}
              />
            );
          })}
        </Stack>
      )
    case Entity.Organization:
      return (
        <Stack gap={1}>
          {(invites ?? []).map(invite => {
            const {organizationName, invitationStatus, invitedByUserData} = invite;
            return (
              <ActivityFeedInviteCard
                uid={props.uid}
                key={invite["@id"]}
                invitation={invite}
                toastProps={toastProps}
                text={invitationStatus === InvitationStatus.Expired ? enOrganizationLabel.invitationExpired(invitedByUserData.name, organizationName)
                  : enActivityFeedLabel.invitationToJoin(organizationName, invitedByUserData.name)}
                noAvatar={true}
                noPin={true}
              />
            );
          })}
        </Stack>
      )
    default: return null;
  }

}

export default InvitesList
