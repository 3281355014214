import {Stack, Typography} from "@mui/material";
import {enCommonManageAccess} from "constants/index";
import theme from "theme/theme";
import React from "react";

interface TeamOrPeopleEmptyPageProps {
  entity: string;
}

function TeamOrPeopleEmptyPage(props: TeamOrPeopleEmptyPageProps) {
  const {entity} = props;

  return (
    <Stack flex={1} alignItems="center" justifyContent="center" direction="column" gap={1}>
      <Typography variant="h4" textAlign="center" width={"70%"}>
        {enCommonManageAccess.ooops}
      </Typography>
      <Typography textAlign="center" color={theme.palette.neutral.dark} variant="subtitle2">
        {enCommonManageAccess.allAreAdded(entity)}
      </Typography>
    </Stack>
  )
}

export default TeamOrPeopleEmptyPage;