import theme from "theme/theme";
import {EmptyList} from "components/index";
import {Entity} from "enums/entity";
import {Stack} from "@mui/material";
import React from "react";
import StatusFilter from "../Head/StatusFilter";
import FileItem from "./FileItem";
import {CollectionReference} from "firebase/firestore";
import EmptySearchResults from "components/EmptySearchResults";
import {BaseProps} from "screens/BaseProps";
import {AccessType} from "types/Permission";
import {SwiftFile, Task} from "types/index";
import StickySearchAndFilter from "components/StickySearchAndFilter";
import {emptyFunction} from "constants/index";

interface DynamicViewProps extends BaseProps {
  task: Task | null;
  fileAccess: AccessType | null;
  collectionRef: CollectionReference;
  filesCollection: any [];
  isFileUploadFocus: boolean;
  statusFilter: string;
  handleStatusChange: (status: string) => void;
  handleDrag: (e: React.DragEvent<HTMLElement>) => void;
  openChatDrawer: (file: SwiftFile) => void;
  onFilePreviewClick: (index: number) => void;
  reUploadFile: (file: File, targetRef: string) => void;
  isDownloading: Record<number, boolean> | null;
}

function DynamicView(props: DynamicViewProps) {
  const {filesCollection = [], fileAccess, isFileUploadFocus, statusFilter, collectionRef, toastProps} = props;
  const {handleDrag, handleStatusChange, openChatDrawer, onFilePreviewClick, isDownloading = null} = props;

  if (!filesCollection) return null;

  if (filesCollection.length === 0) {
    if (statusFilter === "All")
      return <Stack
        direction="row"
        spacing={2}
        onDragEnter={handleDrag}
        border={isFileUploadFocus ? 1 : 0}
        borderColor={isFileUploadFocus ? theme.palette.primary.main : theme.palette.divider}
        minHeight={"40vh"}
      >
        <EmptyList entity={Entity.File}/>
      </Stack>;

    return (
      <Stack>
        <StatusFilter
          statusFilter={statusFilter}
          setStatusFilter={handleStatusChange}
        />
        <Stack
          onDragEnter={handleDrag}
          border={isFileUploadFocus ? 1 : 0}
          borderColor={isFileUploadFocus ? theme.palette.primary.main : theme.palette.divider}
          minHeight={"40vh"}
        >
          <EmptySearchResults entity={Entity.File}/>
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack>
      <StickySearchAndFilter>
        <StatusFilter
          statusFilter={statusFilter}
          setStatusFilter={handleStatusChange}
        />
      </StickySearchAndFilter>

      <Stack spacing={1}>
        <Stack
          direction="row"
          gap={2}
          onDragEnter={handleDrag}
          border={isFileUploadFocus ? 1 : 0}
          borderColor={isFileUploadFocus ? theme.palette.primary.main : theme.palette.divider}
          flexWrap="wrap"
          maxWidth="100%"
        >
          {filesCollection.map((file) => {
            const {fromLocal = false} = file;
            if (fromLocal) {
              return (
                <FileItem
                  uid={props.uid}
                  fileAccess={fileAccess}
                  task={props.task}
                  key={file.id}
                  collectionRef={collectionRef}
                  processingFile={file}
                  openFileChatDrawer={openChatDrawer}
                  toastProps={toastProps!}
                  onFilePreviewClick={emptyFunction}
                  reUploadFile={emptyFunction}
                />
              )
            }

            const currentIndex = filesCollection.findIndex(f => f["@id"] === file["@id"]);
            return (
              <FileItem
                uid={props.uid}
                fileAccess={fileAccess}
                task={props.task}
                key={file["@id"]!}
                file={file}
                collectionRef={collectionRef}
                reUploadFile={props.reUploadFile}
                openFileChatDrawer={openChatDrawer}
                toastProps={toastProps!}
                onFilePreviewClick={() => onFilePreviewClick(currentIndex)}
                isLoading={isDownloading?.[currentIndex] ?? false}
              />
            )
          })}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default DynamicView;
