import {FileUploadReducerState} from "hooks/useFileUpload/reducer/FileUploadReducerState";
import React, {createContext, ReactElement} from "react";
import {useFileUploadReducer} from "hooks/useFileUpload/reducer/useFileUploadReducer";
import {UploadAction} from "hooks/useFileUpload/reducer/UploadAction";

type UploadContextType = {fileUploadState: FileUploadReducerState; fileUploadDispatch: React.Dispatch<UploadAction>} | null;

export const UploadContext = createContext<UploadContextType>(null);

export function UploadContextProvider(props: {children: ReactElement}) {
  const [state, dispatch] = useFileUploadReducer();

  return (
    <UploadContext.Provider
      value={{fileUploadState: state, fileUploadDispatch: dispatch}}
    >
      {props.children}
    </UploadContext.Provider>
  );
}