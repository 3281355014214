import Box from "@mui/material/Box";
import theme from "theme/theme";
import {Stack, Typography} from "@mui/material";
import {StyledAnchor} from "./index";
import React from "react";
import FormContainer from "components/FormContainer";
import {SystemIcons} from "assets/icons/system/system.index";
import {enCommonButton, enCommonLabel} from "constants/index";

interface TokenErrorProps {
  tokenError: string;
}

function TokenError(props: TokenErrorProps) {
  const {tokenError} = props;

  return (
    <FormContainer>
      <Stack direction="column" gap={2} justifyContent="center" textAlign="center" py="2%" flex={1}>
        <Box
          sx={{
            backgroundColor: theme.palette.neutral.light,
            borderRadius: "50%",
            alignSelf: "center",
            display: "flex",
            padding: 1,
          }}
        >
          <SystemIcons.Close stroke={theme.palette.error.main} width={48} height={48}/>
        </Box>
        <Typography variant="h2" mb={2}>Invalid Token</Typography>
        <Box
          sx={{
            backgroundColor: `${theme.palette.error.main}40`,
            padding: 2,
            borderRadius: 1,
          }}
        >
          <Typography variant="body" color={theme.palette.error.dark}>
            {enCommonLabel.cannotFindAccount}
            <br/>
            <b>{tokenError}</b>
          </Typography>
        </Box>
        <StyledAnchor href="/login">{enCommonButton.backToLogin}</StyledAnchor>
      </Stack>
    </FormContainer>
  )
}

export default TokenError;